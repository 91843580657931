import Bold from '@tiptap/extension-bold'
import Image from '@tiptap/extension-image'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useState } from 'react'
import { Loader } from 'src/components/loader/loader'
import { DangerModal } from 'src/components/modals/danger-modal'
import { DeleteIco, DescriptionIco } from 'src/components/svg-icons'
import { Box, Button } from 'src/components/utility'

export function DescriptionField({
    description,
    updateSectionDescription,
    mt,
    ml,
    width,
    id,
    isReadOnly,
    deleteSection,
}: {
    description: string
    updateSectionDescription: (description: string | null) => void
    mt?: FourPixelGridSystem
    ml?: FourPixelGridSystem
    width?: string | BreakPoints
    id: string
    isReadOnly?: boolean
    deleteSection?: () => void
}) {
    const [newDescription, setNewDescription] = useState(description)
    const [isHovered, setIsHovered] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [
        showDeleteDescriptionFieldModal,
        setShowDeleteDescriptionFieldModal,
    ] = useState(false)

    const editor = useEditor(
        {
            onUpdate: (e) => {
                setNewDescription(e.editor.getText())
                // !FIXME: Switch away from sending the description to the server as a stringified html. (security issue)
                updateSectionDescription(e.editor.getHTML())
            },
            editable: !isReadOnly,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: 'Enter the description here',
                }),
                Image,
                Bold,
                Italic,
                Link,
            ],
            content: newDescription,
        },
        []
    )

    return (
        <Box
            display="grid"
            gridTemplateColumns={'auto 1fr 28px'}
            width={width || '90%'}
            mt={mt}
            ml={ml}
            gridGap="8px"
            bg="white"
            border={isHovered ? '1px solid #B8B8B8' : '1px solid #fff'}
            borderRadius="4px"
            p="4px"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            id={id}
        >
            <DescriptionIco />
            <EditorContent
                style={{
                    width: '100%',
                    fontSize: '14px',
                    marginTop: '4px',
                }}
                editor={editor}
                readOnly={isReadOnly}
                autoFocus
            />
            {isHovered && !isReadOnly ? (
                isDeleting ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Loader outline="#FF2C18" size="18px" />
                    </Box>
                ) : (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowDeleteDescriptionFieldModal(true)
                        }}
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        bg="#F0F2F3"
                    >
                        <DeleteIco />
                    </Button>
                )
            ) : null}
            {showDeleteDescriptionFieldModal && (
                <DangerModal
                    onRemove={() => {
                        setIsDeleting(true)
                        updateSectionDescription(null)
                        deleteSection && deleteSection()
                    }}
                    onClose={() => setShowDeleteDescriptionFieldModal(false)}
                    isModal={showDeleteDescriptionFieldModal}
                    heading={`Are you sure you want to delete “description” details of this section?`}
                    subHeading={
                        'Deleting this sub-section will permanently delete all the details within it. '
                    }
                    title={'Delete Section Detail'}
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                />
            )}
        </Box>
    )
}
