import { PdfFileTypes, _3DFileTypes } from './enums'

export function createRedirectionUrl({
    path,
    gid,
    ref = 'todo',
}: {
    path: string
    gid?: string
    ref?: string
}) {
    const pathTokens = path.split('.')
    const extension = pathTokens[pathTokens.length - 1].toLowerCase()
    if (isFile3D(extension)) {
        return `/file-preview`
    }
    if (isFilePDForAi(extension)) {
        return `/pdf-viewer?q=${gid}&ref=${ref}`
    }
    if (isFileExcel(extension)) {
        return `/excel-viewer?path=${gid}&ref=${ref}`
    }
    return '/file-preview'
}

export function isNewTabOn({ name }: { name: string }) {
    const pathTokens = name.split('.')
    const extension = pathTokens[pathTokens.length - 1].toLowerCase()
    if (
        isFile3D(extension) ||
        extension == 'jpg' ||
        extension === 'png' ||
        extension === 'jpeg'
    ) {
        return true
    }
    return false
}

export function isFile3D(extension: string) {
    if (!extension) return false
    return _3DFileTypes.includes(extension)
}
export function isFilePDForAi(extension: string) {
    if (!extension) return false
    return PdfFileTypes.includes(extension.toLowerCase())
}
export function isFileExcel(extension: string) {
    if (!extension) return false
    return extension === 'xls' || extension === 'xlsx'
}

export function isFilePPT(extension: string) {
    if (!extension) return false
    return extension === 'ppt' || extension === 'pptx'
}
