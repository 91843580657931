import React, { useEffect, useRef, useState } from 'react'
import { Slider } from 'src/components/slider/slider'
import { VirtuLogo } from 'src/components/svg-icons/virtu-logo'
import { Box, Text } from 'src/components/utility'
import OrganisationSliderElements from './organisation-slider-elements'
import OrganisationEntityList from './organisation-entity-list'
import { useSearchParams } from 'react-router-dom'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { type roles } from 'src/helpers'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import { RoleDropDownActions } from './dropdowns/role.dropdown'
import { TeamsDropDownActions } from './dropdowns/team.dropdown'
import CreateTeamModal from './modal/create-team'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import DropDownActions from './dropdowns/collaborator.dropdown'
import InviteCollaborator from './modal/invite-collaborator/invite-collaborator'
import { IconButton } from 'src/components/buttons/icon-button'
import { useFetchEntityCount, useFetchOrganistionMembers } from './hooks'
import useStore from 'src/store/hooks/useStore'
import { useOrganizationContext } from './organisation.context'
import { getTeamsData } from './function'

export function OrganisationAndAnalytics() {
    return <Header></Header>
}

const sorts: Record<string, string> = {
    // 'az' | 'za' | 'roles' | 'lh' | 'hl
    az: 'Alphabetical A-Z',
    za: 'Alphabetical Z-A',
    roles: 'Roles',
    lh: 'Completion Rate: Low to High',
    hl: 'Completion Rate: High to Low',
}
const rolesSort: Record<string, string> = {
    all: 'All',
    admin: 'Admin',
    manager: 'Manager',
    designer: 'Designer',
}
const teamsSort: Record<string, string> = {
    LTO: 'Latest to Oldest',
    OTL: 'Oldest to Latest',
    LTM: 'No. of Collections: Least to Most',
    MTL: 'No. of Collections: Most to Least',
    LTH: 'Completion Rate: Low to High',
    HTL: 'Completion Rate: High to Low',
}

function Header() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { getEntityCount } = useFetchEntityCount()

    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const [isRoleDropDownActive, setIsRoleDropDownActive] = useState(false)
    const [isTeamsDropDownActive, setIsTeamsDropDownActive] = useState(false)
    const { setTeam, team, setIndividualContributors } =
        useOrganizationContext()
    const { fetchOrganistionMembers, isLoaded } = useFetchOrganistionMembers()
    const [isModalActive, setIsModalActive] = useState<{
        type: 'collaborator' | 'team'
        active: boolean
    }>({
        type: 'collaborator',
        active: false,
    })

    const [entity, setEntity] = useState({
        teams: {
            count: 0,
            name: 'Collection Teams',
        },
        collaborators: {
            count: 0,
            name: 'Collaborators',
        },
    })

    const activeSort = searchParams.get('sort') || 'az'
    const activeRoleSort = searchParams.get('role') || 'all'
    const activeTeamsSort = searchParams.get('sort') || 'LTO'
    const query = searchParams.get('q') || ''

    const dropdownButton = useRef<any>(null)
    const roleDropdownButton = useRef<any>(null)
    const teamsDropdownButton = useRef<any>(null)
    const refetchRef = useRef<any>(null)

    const [dropdownButtonText, setDropdownButtonText] = useState<string>(
        sorts[activeSort]
    )
    const [roleDropdownButtonText, setRoleDropdownButtonText] =
        useState<string>(rolesSort[activeRoleSort])
    const [teamsDropdownButtonText, setTeamsDropdownButtonText] =
        useState<string>(teamsSort[activeTeamsSort])

    useEffect(() => {
        if (!searchParams.get('role')) {
            setRoleDropdownButtonText(rolesSort['all'])
        }
    }, [searchParams.get('role')])

    useEffect(() => {
        setDropdownButtonText(sorts[activeSort])
        setTeamsDropdownButtonText(teamsSort[activeTeamsSort])
    }, [searchParams])

    const dropdownRef = useClickedOutside(() => setIsDropDownActive(false))
    const roleDropdownRef = useClickedOutside(() =>
        setIsRoleDropDownActive(false)
    )
    const teamDropdownRef = useClickedOutside(() =>
        setIsTeamsDropDownActive(false)
    )
    const setActiveSort = (
        sort:
            | 'az'
            | 'za'
            | 'roles'
            | 'lh'
            | 'hl'
            | 'LTO'
            | 'OTL'
            | 'LTM'
            | 'MTL'
            | 'LTH'
            | 'HTL'
    ) => {
        searchParams.set('sort', sort)
        setSearchParams(searchParams)
    }

    const setActiveRoleSort = (
        role: typeof roles.admin | typeof roles.manager | typeof roles.designer
    ) => {
        searchParams.set('role', role)
        setSearchParams(searchParams)
    }
    async function onDropDownClick(
        dropDownBtnTxt: (val: string) => void,
        setIsDropdown: (bool: boolean) => void,
        value: string,
        activeSort?: string
    ) {
        if (searchParams.get('tab') === 'teams') {
            const data = await getTeamsData({
                searchParam: searchParams.get('q') || '',
                currentPage: Number(searchParams.get('page')) || 1,
                setisLoading: () => {},
                sortParam: searchParams.get('sort') || 'name',
            })
            setTeam(data?.data)
            setEntity({
                ...entity,
                teams: {
                    count: data?.count,
                    name: 'Collection Teams',
                },
            })
        }
        if (searchParams.get('tab') === 'collaborators') {
            const data = await fetchOrganistionMembers({
                contributorsCurrentPage: Number(searchParams.get('page')) || 1,
                role:
                    searchParams.get('sort') === 'roles'
                        ? searchParams.get('role') || ''
                        : '',
                searchString: searchParams.get('q') || '',
                status:
                    value.toLowerCase() === 'all'
                        ? 'all'
                        : searchParams.get('status') || 'all',
                sort:
                    value.toLowerCase() === 'all'
                        ? undefined
                        : (activeSort as 'az' | 'za' | 'lh' | 'hl'),
            })
            setIndividualContributors(data?.data)
            setEntity({
                ...entity,
                collaborators: {
                    count: data?.count,
                    name: 'Collaborators',
                },
            })
        }

        setIsDropdown(false)
        dropDownBtnTxt(value)
    }

    const { contributorsTotalCount, teamListTotalCount } = useStore()

    function onClickHandler() {
        if (searchParams.get('tab') === 'teams')
            return setIsModalActive({
                type: 'team',
                active: true,
            })
        else
            return setIsModalActive({
                type: 'collaborator',
                active: true,
            })
    }

    useEffect(() => {
        getEntityCount()
    }, [])

    console.log({
        isTrue:
            activeSort === 'roles' &&
            query.length > 0 &&
            searchParams.get('status') !== 'all',
    })
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx="24px"
            >
                <Box
                    mb="28px"
                    mt="24px"
                    gridColumnGap="6px"
                    display="flex"
                    alignItems="center"
                >
                    <Box
                        bg="secondary"
                        p="8px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="max-content"
                        borderRadius="50%"
                    >
                        <VirtuLogo />
                    </Box>
                    <Text my="0px" fontSize="24px">
                        Virtu
                    </Text>
                </Box>
                <IconButton
                    onClick={onClickHandler}
                    variant="primary"
                    iconLeft={<PlusIcoSm color="#fff" />}
                >
                    {searchParams.get('tab') === 'teams'
                        ? 'Create New'
                        : 'Add New'}
                </IconButton>
            </Box>
            <Box
                py="24px"
                px="24px"
                pt="28px"
                height="24px"
                borderRadius="12px"
                borderTop="1px solid #A7B9D1"
                borderWidth={1}
            >
                <Box display="flex" justifyContent="space-between">
                    {searchParams.get('tab') === 'teams' ? (
                        <Text color="primary" fontSize="20px">
                            {teamListTotalCount}{' '}
                            {teamListTotalCount > 1
                                ? 'Collection Team'
                                : 'Collection Teams'}
                        </Text>
                    ) : (
                        <Text color="primary" fontSize="20px">
                            {contributorsTotalCount}{' '}
                            {contributorsTotalCount > 1
                                ? 'Collaborator'
                                : 'Collaborators'}
                        </Text>
                    )}
                    {/* Collaborators */}
                    {searchParams.get('tab') === 'collaborators' && (
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="18px"
                        >
                            {activeSort === 'roles' && (
                                <Box
                                    id="sort-dropdown"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gridColumnGap="29px"
                                    position="relative"
                                    width="max-content"
                                    ref={roleDropdownRef}
                                >
                                    <Box
                                        id="dropdown_badge"
                                        ref={roleDropdownButton}
                                        // width="375px"
                                        // display="flex"
                                        // justifyContent="end"
                                        onClick={() =>
                                            setIsRoleDropDownActive(
                                                !isRoleDropDownActive
                                            )
                                        }
                                    >
                                        <IconDropdownButton>
                                            Choose Role:{' '}
                                            {roleDropdownButtonText}
                                        </IconDropdownButton>
                                    </Box>

                                    {isRoleDropDownActive && (
                                        <RoleDropDownActions
                                            setActiveSort={setActiveRoleSort}
                                            activeSort={activeRoleSort}
                                            setIsDropdownActive={
                                                setIsRoleDropDownActive
                                            }
                                            onDropDownClick={(val) =>
                                                onDropDownClick(
                                                    setRoleDropdownButtonText,
                                                    setIsRoleDropDownActive,
                                                    val
                                                )
                                            }
                                            active={isRoleDropDownActive}
                                        />
                                    )}
                                </Box>
                            )}
                            <Box
                                id="sort-dropdown"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gridColumnGap="29px"
                                position="relative"
                                width="max-content"
                                ref={dropdownRef}
                            >
                                <Box
                                    id="dropdown_badge"
                                    ref={dropdownButton}
                                    width="max-content"
                                    display="flex"
                                    justifyContent="end"
                                    onClick={() =>
                                        setIsDropDownActive(!isDropDownActive)
                                    }
                                >
                                    <IconDropdownButton>
                                        Sort by: {dropdownButtonText}
                                    </IconDropdownButton>
                                </Box>

                                {isDropDownActive && (
                                    <DropDownActions
                                        setActiveSort={setActiveSort}
                                        activeSort={activeSort}
                                        setIsDropdownActive={
                                            setIsDropDownActive
                                        }
                                        onDropDownClick={(val, sort) => {
                                            onDropDownClick(
                                                setDropdownButtonText,
                                                setIsDropDownActive,
                                                val,
                                                sort
                                            )
                                        }}
                                        active={isDropDownActive}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Teams */}
                    {searchParams.get('tab') === 'teams' && (
                        <Box
                            id="sort-dropdown"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gridColumnGap="29px"
                            position="relative"
                            width="max-content"
                            ref={teamDropdownRef}
                        >
                            <Box
                                id="dropdown_badge"
                                ref={teamsDropdownButton}
                                width="375px"
                                display="flex"
                                justifyContent="end"
                                onClick={() =>
                                    setIsTeamsDropDownActive(
                                        !isTeamsDropDownActive
                                    )
                                }
                            >
                                <IconDropdownButton>
                                    Sort By: {teamsDropdownButtonText}
                                </IconDropdownButton>
                            </Box>

                            {isTeamsDropDownActive && (
                                <TeamsDropDownActions
                                    setActiveSort={setActiveSort}
                                    activeSort={activeTeamsSort}
                                    setIsDropdownActive={
                                        setIsTeamsDropDownActive
                                    }
                                    onDropDownClick={(val) => {
                                        onDropDownClick(
                                            setTeamsDropdownButtonText,
                                            setIsTeamsDropDownActive,
                                            val
                                        )
                                    }}
                                    active={isTeamsDropDownActive}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Box>
                    <Slider
                        element={
                            <OrganisationSliderElements
                                teamsCount={teamListTotalCount}
                                collaboratorsCount={
                                    searchParams.get('tab') ===
                                        'collaborators' &&
                                    (activeSort === 'roles' ||
                                        query.length > 0 ||
                                        searchParams.get('status') !== 'all')
                                        ? entity.collaborators.count
                                        : contributorsTotalCount
                                }
                            />
                        }
                    />
                    <OrganisationEntityList
                        contributorsTotalCount={
                            searchParams.get('tab') === 'collaborators' &&
                            (activeSort === 'roles' ||
                                query.length > 0 ||
                                searchParams.get('status') !== 'all')
                                ? entity.collaborators.count
                                : contributorsTotalCount
                        }
                        ref={refetchRef}
                        entityOnLoad={(entity) => setEntity(entity)}
                        isLoading={false}
                        activeTab={searchParams.get('tab') || 'teams'}
                    />
                </Box>
            </Box>
            {isModalActive.type === 'team' && (
                <CreateTeamModal
                    refetch={refetchRef.current.fetchCb}
                    isModalActive={isModalActive.active}
                    setIsModalActive={(isActive) =>
                        setIsModalActive({ type: 'team', active: isActive })
                    }
                />
            )}
            {isModalActive.type === 'collaborator' && (
                <InviteCollaborator
                    isModalOpen={isModalActive.active}
                    onClose={(isActive) => {
                        setIsModalActive({
                            type: 'collaborator',
                            active: isActive,
                        })
                    }}
                />
            )}
        </>
    )
}
