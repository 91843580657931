import * as React from 'react'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Image, Input, Text } from 'src/components/utility'
import CalendarDropdown from '../dropdowns/calendar'
import StatusDropdown from '../dropdowns/status'
import { CollectionIco } from 'src/components/svg-icons/collection-ico'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import type { TSearchedTodos } from 'src/components-v2/global-search/@types/search'
import {
    copyToClipBoard,
    debounce,
    getImageUrl,
    useUser,
    uuid,
} from 'src/helpers'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useNavigate } from 'react-router-dom'
import useStore from 'src/store/hooks/useStore'
import type { TGqlTodos } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import MeatBallDropdown from '../dropdowns/meatball-dropdown'
import { DangerModal } from 'src/components/modals/danger-modal'
import { useDeleteTodo } from '../hooks/useUpdateTodo'
import { Users } from 'src/screens/products/sub-screens/product-form'
import { UpdateTodo } from 'src/services/graphql/entities/todo/todo.mutation'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { GetOrgMemberByEmail } from 'src/services/graphql/query/get-team-by-id'
import { GetTodosByUserId } from 'src/services/graphql/query/organisations-queries'
import { toast } from 'react-toastify'
import UploadThumbnailMenuDropdown from 'src/components-v2/upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { getLibraryFileUrl, getLocalImageUrl } from 'src/helpers/get-image-url'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { Loader } from 'src/components/loader/loader'
import useUpdateTodo from 'src/hooks/useUpdateTodo'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import { ExpandReferencePicModal } from 'src/components-v2/todo/subtodo-card/modals/expand-reference-pic.modal'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { TodoBadge } from 'src/components-v2/badges/todo-badge'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import { Link } from 'react-router-dom'
import { GetProductTodos } from 'src/services/graphql/entities/todo/todo.queries'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'

interface ITodoCardProps {
    entity: TSearchedTodos | TGqlTodos
    updateDate: (date: Date) => void
    updateStatus: (status: string) => void
    navigateTo: string
    onClick?: () => void
    refetchTodo?: () => void
    refetchProduct?: () => void
    isTabView?: boolean
    dashboardView?: boolean
    isSplitMode?: boolean
    isCompareSection?: boolean
}

const TodoCard = (props: ITodoCardProps) => {
    const {
        entity,
        onClick,
        isSplitMode,
        isTabView = false,
        dashboardView = false,
        navigateTo,
        isCompareSection,
    } = props
    const { globalSearchFilters, removeTodo, updateGlobalSearchResults } =
        useStore()
    const client = useApolloClient()
    const [query, setQuery] = React.useState(entity.user?.name || '')
    const { deleteTodo } = useDeleteTodo()
    const { updateTodoStatus, updateTodoDueDate } = useUpdateTodo()
    const [isModalActive, setIsModalActive] = React.useState(false)
    const [isDropdownActive, setIsDropdownActive] = React.useState(false)
    const navigate = useNavigate()
    const [managers, setManagers] = React.useState<any[]>([])
    const [listOpen, setListOpen] = React.useState(false)
    const [isEditable, setIsEditable] = React.useState(false)
    const [coverPic, setCoverPic] = React.useState('/image-placeholder.jpg')
    const [updateTodoMutation] = useMutation(UpdateTodo)
    const [findSearchUser] = useLazyQuery(GetOrgMemberByEmail)
    const [isThumbnailUploading, setIsThumbnailUploading] =
        React.useState(false)
    const { setLastOpened, getLastOpenedTab, removeLastOpenedTab } =
        useCardExpandedState()
    const { onFileUpload } = useUploadFile(String(entity.id), 'todos')
    const { assignFilesToSection } = useAssignFile()
    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()
    const [showThumbnailExpandView, setShowThumbnailExpandView] =
        React.useState(false)

    const [showUploadThumbnailDropdown, setShowUploadThumbnailDropdown] =
        React.useState(false)
    function updateTodoHandler(payload: any) {
        updateTodoMutation({
            variables: {
                id: entity.id,
                payload,
            },
            refetchQueries: [
                {
                    query: GetTodosByUserId,
                    variables: {
                        userId: entity.user?.id,
                    },
                },
                {
                    query: GetProductTodos,
                    variables: {
                        id: entity.product?.id,
                    },
                },
                'getProduct',
            ],
            onCompleted: (data) => {
                if (!data.update_todos_by_pk) {
                    return toast('Failed to update todo', {
                        className: 'custom-toaster toaster-error',
                    })
                }
                props.refetchTodo && props.refetchTodo()
                updateGlobalSearchResults({
                    id: entity.id,
                    type: 'todos',
                    updatedKey: 'user',
                    value: managers.find(
                        (manager) => manager.user.id === payload.user_id
                    )?.user,
                })
            },
        })
    }

    function onDropdownClick(option: string) {
        switch (option) {
            case 'Delete':
                setIsModalActive(true)
                break
            case 'Copy Link':
                copyToClipBoard(window.location.host + '/todo/' + entity.id)
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            default:
                break
        }
    }
    const debouncedFetchMembers = debounce(
        (searchStr: string) => {
            setListOpen(true)
            if (!searchStr) {
                setManagers([])
                setListOpen(false)
                return
            }
            findSearchUser({
                variables: {
                    searchStr: `%${searchStr}%`,
                },
                fetchPolicy: 'no-cache',
                onCompleted: (data: any) => {
                    const users = data?.organisation_members
                    setManagers(users)
                },
            })
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    React.useEffect(() => {
        if (entity.other_data?.cover_pic?.gid) {
            if (entity.other_data.cover_pic.fromLibrary) {
                setCoverPic(getLibraryFileUrl(entity.other_data.cover_pic.gid))
            } else {
                setCoverPic(getImageUrl(entity.other_data.cover_pic.gid))
            }
        } else if (
            entity.product?.other_data?.cover_pic &&
            entity.product?.other_data?.cover_pic?.gid
        ) {
            if (entity.product.other_data.cover_pic.gid.length <= 0)
                return setCoverPic('/image-placeholder.jpg')
            if (entity.product.other_data.cover_pic.fromLibrary) {
                setCoverPic(
                    getLibraryFileUrl(entity.product.other_data.cover_pic.gid)
                )
            } else {
                setCoverPic(
                    getImageUrl(entity.product.other_data.cover_pic.gid)
                )
            }
        } else {
            setCoverPic('/image-placeholder.jpg')
        }
    }, [entity.other_data])

    // Get the high quality image for the thumbnail to show in the modal
    const expandedCoverPic = entity.other_data?.cover_pic
        ? entity.other_data.cover_pic.fromLibrary
            ? getLibraryFileUrl(entity.other_data.cover_pic.gid)
            : getImageUrl(entity.other_data.cover_pic.gid)
        : entity.product?.other_data?.cover_pic
        ? entity.product.other_data.cover_pic.fromLibrary
            ? getLibraryFileUrl(entity.product.other_data.cover_pic.gid)
            : getImageUrl(entity.product.other_data.cover_pic.gid)
        : '/image-placeholder.jpg'

    const isCoverPicPresent = coverPic && coverPic !== '/image-placeholder.jpg'

    async function uploadImageHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        todoId: number
    ) {
        setShowUploadThumbnailDropdown(false)
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)

            const file = e.target.files[0]
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: file,
                            id: uuid(),
                            name: file.name,
                            path: getLocalImageUrl(file) || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            const fileId = data?.resolvedArr[0].id
            if (fileId) {
                await assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityId: String(todoId),
                    entityName: 'todos',
                    fileId: [data?.resolvedArr[0].id],
                })
                client.refetchQueries({
                    include: [
                        'getProduct',
                        'GetTodos',
                        'GetProductTodos',
                        'getTodosData',
                        'GetTodosByUserId',
                        'GetUserTodos',
                        'GetDashboardTodos',
                    ],
                })
            }

            setIsThumbnailUploading(false)
        } catch {
            setIsThumbnailUploading(false)
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    const tab = getLastOpenedTab('todo')
    React.useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>
        if (tab) {
            const card = document.getElementById(`todo_card_${tab}`)
            if (card) {
                card.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                // // Clear the last opened tab after scrolling
                timeoutId = setTimeout(removeLastOpenedTab, 1000)
            }
        }
        // Clear timeout on component unmount
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    let name = entity?.product?.name
    if (dashboardView) {
        name = convertIntoElipses(entity?.product?.name || '', 10)
    } else {
        const screenSize = window.innerWidth
        if (screenSize <= 1450 && screenSize > 1400) {
            name = convertIntoElipses(entity?.product?.name || '', 40)
        } else if (screenSize <= 1400 && screenSize > 1250) {
            name = convertIntoElipses(entity?.product?.name || '', 30)
        } else if (screenSize > 1450) {
            name = convertIntoElipses(entity?.product?.name || '', 64)
        } else {
            name = convertIntoElipses(entity?.product?.name || '', 30)
        }
    }

    const { userId } = useUser()
    const isCollectionManager =
        entity.product?.collection?.manager_id === Number(userId)

    return (
        <>
            <Link
                to={navigateTo}
                style={{ textDecoration: 'none', color: 'inherit' }}
                onClick={(e) => {
                    if (isCompareSection) {
                        e.preventDefault()
                    }
                }}
            >
                <Box
                    px="16px"
                    py="12px"
                    display="grid"
                    id={`todo_card_${entity.id}`}
                    gridTemplateColumns={
                        isSplitMode
                            ? '1fr auto'
                            : `${dashboardView ? 'max-content' : '1fr'} 1fr ${
                                  dashboardView ? '0px' : 'auto'
                              }`
                    }
                    border="solid"
                    borderColor="gray250"
                    alignItems="center"
                    borderWidth={1}
                    className={
                        tab === entity.id
                            ? 'card-hover card-bg cursor-pointer'
                            : 'card-hover cursor-pointer'
                    }
                    onClick={() => {
                        setLastOpened({
                            entityId: Number(entity.id),
                            type: 'todo',
                        })
                        onClick && onClick()
                    }}
                >
                    <Box
                        display="flex"
                        position="relative"
                        gridColumnGap="12px"
                    >
                        {!isSplitMode && (
                            <Box
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setShowUploadThumbnailDropdown(
                                        !showUploadThumbnailDropdown
                                    )
                                }}
                                className="cursor-pointer"
                                id="thumbnail-button"
                                position="relative"
                            >
                                {isThumbnailUploading &&
                                coverPic === '/image-placeholder.jpg' ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        width="53px"
                                        height="53px"
                                    >
                                        <Loader outline="#022143" />
                                    </Box>
                                ) : (
                                    <Box
                                        width="53px"
                                        height={'53px'}
                                        position="relative"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {isCoverPicPresent && (
                                            <Box
                                                position="absolute"
                                                top="0px"
                                                right="0px"
                                            >
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        setLastOpened({
                                                            entityId: Number(
                                                                entity.id
                                                            ),
                                                            type: 'todo',
                                                        })
                                                        setShowThumbnailExpandView(
                                                            !showThumbnailExpandView
                                                        )
                                                    }}
                                                    bg="transparent"
                                                    border="none"
                                                    fontStyle="Rubik"
                                                    fontSize="10px"
                                                    color="#027AC3"
                                                    position="relative"
                                                    width="max-content"
                                                    id="subtodo-reference-menu-button"
                                                >
                                                    <FullScreenIco size="10" />
                                                </Button>
                                                {showThumbnailExpandView && (
                                                    <ExpandReferencePicModal
                                                        onClose={() =>
                                                            setShowThumbnailExpandView(
                                                                false
                                                            )
                                                        }
                                                        url={expandedCoverPic}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        <Image
                                            width="53px"
                                            height="53px"
                                            borderRadius="4px"
                                            src={coverPic}
                                        />
                                        {coverPic.length >= 0 &&
                                            isThumbnailUploading && (
                                                <Box
                                                    width="53px"
                                                    height={'53px'}
                                                    bg="#02214350"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    position="absolute"
                                                >
                                                    <Loader outline="#fff" />
                                                </Box>
                                            )}
                                    </Box>
                                )}
                                {showUploadThumbnailDropdown && (
                                    <UploadThumbnailMenuDropdown
                                        active={showUploadThumbnailDropdown}
                                        onClose={() => {
                                            setShowUploadThumbnailDropdown(
                                                false
                                            )
                                        }}
                                        entityId={Number(entity.id)}
                                        productId={
                                            Number(entity.product?.id) || 0
                                        }
                                        entityName="todos"
                                        onUpload={(e) =>
                                            uploadImageHandler(
                                                e,
                                                Number(entity.id)
                                            )
                                        }
                                        refetchProduct={() => {
                                            props.refetchTodo &&
                                                props.refetchTodo()
                                            client.refetchQueries({
                                                include: [
                                                    'getProduct',
                                                    'GetTodos',
                                                    'getTodosData',
                                                    'GetUserTodos',
                                                ],
                                            })
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                        >
                            <Box
                                gridColumnGap="8px"
                                alignItems="center"
                                display="flex"
                            >
                                <TodoBadge />

                                <Text
                                    color="textSecondaryDarkBlue"
                                    maxWidth="200px"
                                    style={{
                                        wordBreak: 'break-word',
                                    }}
                                    fontSize={{ md: '12px', xl: '14px' }}
                                    my="0px"
                                >
                                    {isTabView
                                        ? convertIntoElipses(entity.name, 16)
                                        : entity.name}
                                </Text>

                                <HtmlTooltip
                                    placement="bottom-start"
                                    arrow
                                    title={
                                        entity?.product?.name &&
                                        entity?.product?.name?.length > 16 ? (
                                            <ToolTipHeader
                                                text={
                                                    entity?.product?.name || ''
                                                }
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    <Text
                                        color="primary"
                                        fontSize={{ md: '12px', xl: '14px' }}
                                        my="0px"
                                    >
                                        {name || 'No Product'}
                                    </Text>
                                </HtmlTooltip>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="18px"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gridColumnGap="4px"
                                >
                                    <CollectionIco />
                                    <HtmlTooltip
                                        placement="bottom-start"
                                        arrow
                                        title={
                                            entity?.product?.collection?.name &&
                                            entity?.product?.collection?.name
                                                ?.length > 16 ? (
                                                <ToolTipHeader
                                                    text={
                                                        entity?.product
                                                            ?.collection
                                                            ?.name || ''
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    >
                                        <Text
                                            color={'textSecondaryDarkBlue'}
                                            fontSize="12px"
                                            my="0px"
                                        >
                                            {convertIntoElipses(
                                                entity.product?.collection
                                                    ?.name || '',
                                                dashboardView ? 10 : 16
                                            ) || 'No Collection'}
                                        </Text>
                                    </HtmlTooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        width={dashboardView ? 'max-content' : '100%'}
                        height={'100%'}
                        justifySelf={dashboardView ? 'end' : 'start'}
                        justifyContent={dashboardView ? 'end' : 'start'}
                        flexDirection={
                            dashboardView
                                ? 'column'
                                : isTabView
                                ? 'column-reverse'
                                : 'row'
                        }
                        gridRowGap={'12px'}
                        gridColumnGap={
                            dashboardView
                                ? '8px'
                                : isSplitMode
                                ? '32px'
                                : '50px'
                        }
                    >
                        <CalendarDropdown
                            left={dashboardView ? '-255px' : '0px'}
                            date={entity.due_date}
                            setDate={(date) =>
                                updateTodoDueDate({
                                    id: Number(entity.id),
                                    due_date: date.toISOString(),
                                    onCompleted: () => {
                                        globalSearchFilters.isGlobalSearchActive &&
                                            updateGlobalSearchResults({
                                                id: entity.id,
                                                type: 'todos',
                                                updatedKey: 'due_date',
                                                value: date.toISOString(),
                                            })
                                    },
                                })
                            }
                            isDelayed={isEntityDueDateDelayed(
                                entity.due_date
                                    ? (entity.due_date as unknown as string)
                                    : null,
                                entity.status
                            )}
                        />
                        <StatusDropdown
                            exclude={['revisionRequested', 'toBeReviewed']}
                            onStatusChange={(status) =>
                                updateTodoStatus({
                                    id: Number(entity.id),
                                    status,
                                    onCompleted: () => {
                                        globalSearchFilters.isGlobalSearchActive &&
                                            updateGlobalSearchResults({
                                                id: entity.id,
                                                type: 'todos',
                                                updatedKey: 'status',
                                                value: status,
                                            })
                                    },
                                })
                            }
                            isManager={isCollectionManager}
                            status={entity.status}
                        />
                        {!isSplitMode && (
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="8px"
                                position="relative"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setLastOpened({
                                        entityId: Number(entity.id),
                                        type: 'todo',
                                    })
                                    e.stopPropagation()
                                    setIsEditable(true)
                                }}
                                height={'100%'}
                            >
                                {!isTabView &&
                                    !isEditable &&
                                    (entity.user ||
                                        ('assignee' in entity &&
                                            entity.assignee)) && (
                                        <ProfileImg
                                            fontSize="12px"
                                            size="20px"
                                            src={getImageUrl(
                                                'user' in entity && entity.user
                                                    ? entity.user?.other_data
                                                          ?.profile_pic
                                                    : 'assignee' in entity
                                                    ? entity.assignee
                                                          ?.other_data
                                                          ?.profile_pic
                                                    : ''
                                            )}
                                            name={
                                                'user' in entity && entity.user
                                                    ? entity.user?.name || ''
                                                    : 'assignee' in entity
                                                    ? entity.assignee?.name ||
                                                      ''
                                                    : ''
                                            }
                                        />
                                    )}
                                {!isTabView && !isEditable && (
                                    <Button
                                        border="none"
                                        bg="transparent"
                                        height="100%"
                                        fontSize="12px"
                                        my="0px"
                                    >
                                        {'user' in entity
                                            ? entity.user?.name || 'No Assignee'
                                            : 'assignee' in entity
                                            ? entity.assignee?.name ||
                                              'No Assignee'
                                            : 'No Assignee'}
                                    </Button>
                                )}
                                {isEditable && (
                                    <Input
                                        autoComplete="false"
                                        required
                                        bg="transparent"
                                        value={query}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setLastOpened({
                                                entityId: Number(entity.id),
                                                type: 'todo',
                                            })
                                        }}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            e.stopPropagation()
                                            setQuery(e.target.value)
                                            debouncedFetchMembers(
                                                e.target.value
                                            )
                                        }}
                                        color="primary"
                                        border="none"
                                        fontSize="16px"
                                        placeholder="Assignee Name"
                                        className="placeholder-light"
                                        width="120px"
                                    />
                                )}
                                {listOpen && (
                                    <Users
                                        users={managers}
                                        onClickUser={(manager: ManagerUser) => {
                                            setLastOpened({
                                                entityId: Number(entity.id),
                                                type: 'todo',
                                            })
                                            updateTodoHandler({
                                                user_id: manager.user.id,
                                            })
                                            setListOpen(false)
                                            setIsEditable(false)
                                            setQuery('')
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    {!dashboardView && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                        >
                            {!isSplitMode && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setLastOpened({
                                            entityId: Number(entity.id),
                                            type: 'todo',
                                        })
                                        setIsDropdownActive(!isDropdownActive)
                                    }}
                                    bg="transparent"
                                    border="none"
                                >
                                    <Dots active={false} />
                                </Button>
                            )}
                            {isDropdownActive && (
                                <MeatBallDropdown
                                    left="-78px"
                                    isActive={isDropdownActive}
                                    onClick={(option) => {
                                        setLastOpened({
                                            entityId: Number(entity.id),
                                            type: 'todo',
                                        })
                                        onDropdownClick(option)
                                    }}
                                    setIsActive={(isActive) =>
                                        setIsDropdownActive(isActive)
                                    }
                                    onClose={() => setIsDropdownActive(false)}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Link>

            <DangerModal
                heading={`Delete ${entity.name}`}
                isModal={isModalActive}
                onClose={() => setIsModalActive(false)}
                onRemove={() =>
                    deleteTodo({
                        todoId: Number(entity.id),
                        refetchQueries: [
                            'GetUserTodos',
                            'getCollections',
                            'getProduct',
                            'GetTodosByUserId',
                        ],
                        onComplete: () => {
                            globalSearchFilters.isGlobalSearchActive &&
                                removeTodo({
                                    id: Number(entity.id),
                                })
                            toast('Todo Deleted Successfully', {
                                className: 'custom-toaster toaster-success',
                            })
                        },
                    })
                }
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                title={`Are you sure you want to delete '${entity.name}?'`}
                subHeading={`Deleting this will remove this To-Do's and it's associated data. This action cannot be undone.`}
            />
        </>
    )
}

export default React.memo(TodoCard)
