import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { CollectionIco, ProductIco } from 'src/components/svg-icons'
import { ProductIcoSm } from 'src/components/svg-icons/product-ico'
import { Box, Image, Text } from 'src/components/utility'
import type { TSearchedProduct } from '../@types/search'
import useStore from 'src/store/hooks/useStore'
import { getImageUrl } from 'src/helpers'
import { useRecentSearch } from '../hooks/useRecentSearch'
import { useNavigate } from 'react-router-dom'
import { VariantBadge } from 'src/components-v2/badges/variant-badge'
import { convertIntoElipses } from 'src/components/list-item/list-item'

export function ProductVariant({
    isVariant,
    entity,
    type = 'original',
    onCardClick,
}: {
    isVariant?: boolean
    type: 'variant' | 'original'
    onCardClick?: () => void
    entity: TSearchedProduct
}) {
    const { globalSearchFilters, setGlobalSearchActive } = useStore()
    const { addRecentSearch } = useRecentSearch()
    const navigate = useNavigate()
    if (!entity) return
    return (
        <Box
            color="textSecondaryDarkBlue"
            display="flex"
            gridRowGap="8px"
            className="cursor-pointer"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            py="8px"
            px="4px"
            onClick={() => {
                setGlobalSearchActive({ isGlobalSearchActive: false })
                onCardClick && onCardClick()
                addRecentSearch({
                    entity_name:
                        type === 'variant' ? 'product_variants' : 'products',
                    entity_id: entity.id,
                    search_query: globalSearchFilters.query,
                })
                navigate(
                    '/collections/product/' + (entity?.primary_id || entity.id)
                )
            }}
            justifyContent="space-between"
        >
            <Box display="flex" flexDirection="column" gridRowGap="8px">
                <Box display="flex" gridColumnGap="12px" alignItems="center">
                    <ProductIco />
                    {type === 'variant' && <VariantBadge />}
                    <Highlighted
                        search={globalSearchFilters.query}
                        text={entity.name}
                        unHighlighted="textSecondaryDarkBlue"
                    />
                    {isVariant && <Badge />}
                </Box>
                <Box display="flex" alignItems="center" gridColumnGap="16px">
                    <Box
                        display="flex"
                        gridColumnGap="12px"
                        alignItems="center"
                    >
                        <CollectionIco color="#778CA2" />
                        <Text my="0px" fontSize="14px">
                            {convertIntoElipses(entity.collection?.name, 40) ||
                                'No Collection'}
                        </Text>
                    </Box>
                    <Box display="flex" gridColumnGap="8px" alignItems="center">
                        {entity.collection?.other_data.manager ? (
                            <>
                                <ProfileImg
                                    fontSize="12px"
                                    size="20px"
                                    src={getImageUrl(
                                        entity.collection.other_data.manager
                                            ?.user?.other_data?.profile_pic
                                    )}
                                    name={
                                        entity.collection.other_data.manager
                                            ?.user?.name || ''
                                    }
                                />
                                <Text my="0px" fontSize="14px">
                                    {
                                        entity.collection.other_data.manager
                                            ?.user?.name
                                    }
                                </Text>
                            </>
                        ) : (
                            <Text my="0px" fontSize="12px">
                                No Manager
                            </Text>
                        )}
                    </Box>
                </Box>
            </Box>
            <Image
                borderRadius="4px"
                width="auto"
                height="52px"
                src={getImageUrl(entity.other_data?.cover_pic?.gid)}
            />
        </Box>
    )
}

function Badge() {
    return (
        <Box
            bg="textSecondaryDarkBlue"
            p="4px"
            display="flex"
            borderRadius="4px"
            alignItems="center"
            gridColumnGap="4px"
        >
            <ProductIcoSm />
            <Text my="0px" color="white" fontSize="10px">
                Variant
            </Text>
        </Box>
    )
}
