import { useSearchParams } from 'react-router-dom'
import { base64ToJson, jsonToBase64 } from '../hash-converts'
import { getLocalStorage, setLocalStorage } from '../localstorage'

enum Options {
    product = 'product',
    collection = 'collection',
    todo = 'todo',
    comment = 'comment',
}

type TAddValuePayload = {
    [k in Options]: {
        [id: number]: boolean
    }
}

type TPayload = TAddValuePayload & {
    lastOpened: {
        type: 'product' | 'collection' | 'todo' | 'comment' | null
        entityId: number | null
    }
}
export function useCardExpandedState() {
    const [searchParams, setSearchParams] = useSearchParams()

    function addValuesToExpanded(
        data: {
            id: number
            isExpanded: boolean
        },
        type: 'product' | 'collection' | 'todo' | 'comment'
    ) {
        const expandedHash = searchParams.get('e_hash')
        const json = base64ToJson(expandedHash || '') as unknown as TPayload
        if (expandedHash && json) {
            if (!json) return searchParams.delete('e_hash')
            if (json[type]) {
                json[type] = {
                    ...json[type],
                    [data.id]: data.isExpanded,
                }
                json.lastOpened = {
                    type,
                    entityId: json.lastOpened.entityId,
                }
            } else {
                json[type] = {
                    [data.id]: data.isExpanded,
                }
                json.lastOpened = {
                    entityId: null,
                    type: null,
                }
            }
            const base64 = jsonToBase64(json)
            if (base64) {
                searchParams.set('e_hash', base64)
                setSearchParams(searchParams, { replace: true })
            }
        } else {
            searchParams.delete('e_hash')
            const json = {
                [type]: {
                    [data.id]: data.isExpanded,
                },
            } as TPayload
            json.lastOpened = {
                entityId: null,
                type: null,
            }
            const base64 = jsonToBase64(json)
            if (base64) {
                searchParams.set('e_hash', base64)
                setSearchParams(searchParams, { replace: true })
            }
        }
    }

    function getExpandedStates() {
        const hash = searchParams.get('e_hash')
        if (hash) {
            return base64ToJson(hash) as unknown as TPayload
        } else {
            return null
        }
    }

    // Store last opened in local storage
    function setLastOpened(payload: {
        entityId: number
        type: 'product' | 'collection' | 'todo' | 'comment'
    }) {
        setLocalStorage(
            'lastOpened',
            JSON.stringify({
                entityId: payload.entityId,
                entityName: payload.type,
            })
        )
    }

    function getLastOpenedTab(
        type: 'collection' | 'product' | 'todo' | 'comment'
    ) {
        const lastOpened = getLocalStorage('lastOpened')
        if (lastOpened && JSON.parse(lastOpened).entityName === type) {
            return JSON.parse(lastOpened).entityId
        } else {
            return null
        }
    }

    // Remove last opened tab from local storage
    function removeLastOpenedTab() {
        localStorage.removeItem('lastOpened')
    }

    return {
        addValuesToExpanded,
        getExpandedStates,
        setLastOpened,
        getLastOpenedTab,
        removeLastOpenedTab,
    }
}
