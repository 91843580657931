export const tabNames = {
    yourteams: "yourteams",
    individualcontributors: "individualcontributors"
}

export const dropdownNames = {
    name: "Name",
    teamsLH: "Teams L-H",
    teamsHL: "Teams H-L",
    admin: "Admin",
    manager: "Manager",
    designer: "Designer",
    any: "Any",
    lh: "lh",
    hl: "hl",
    delay: "Delay",
    onTime: "On-Time",
    _onTime: "onTime",
    dateCreated: "Date Created",
    tabSearch: "tabSearch",
    memberSearch: "memberSearch",
    sortTeam: "sortTeam"
}