import * as React from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { CrossSm } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'
import { getImageUrl, getSentenceCase } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { isEmailValid } from '../../function'

export interface ITagInputProps {
    onDelete: (index: number) => void
    tags: ISuggestion[]
    setTags: (tags: ISuggestion[], tag: ISuggestion) => void
    setNewUsers?: (user: { email: string }) => void
    suggestions: ISuggestion[]
    onQuery: (query: string) => void
    placeholder?: string
    showTags?: boolean
    border?: string
    borderColor?: string
}

function TagInput(props: ITagInputProps, ref: any) {
    const {
        onDelete,
        onQuery,
        suggestions,
        tags,
        setTags,
        setNewUsers,
        placeholder = 'Enter Email Addresses',
        border = 'none',
        showTags = true,
        borderColor = 'none',
    } = props
    const [query, setQuery] = React.useState('')
    const [isDropDownActive, setIsDropDownActive] = React.useState(false)

    React.useEffect(() => {
        if (query.length > 0 && suggestions.length > 0) {
            setIsDropDownActive(true)
        }
    }, [suggestions.length, query.length])

    React.useImperativeHandle(ref, () => ({
        setQuery,
    }))

    const dropdownRef = useClickedOutside(() => setIsDropDownActive(false))

    return (
        <Box width="100%" display="flex" alignItems="center">
            <Box
                px="16px"
                py="8px"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                width="100%"
                gridRowGap="8px"
                border={border}
                borderWidth={1}
                borderColor={borderColor}
            >
                {showTags &&
                    tags.map((tag, index) => (
                        <TagComponent
                            key={index}
                            suggestion={tag}
                            onDelete={() => onDelete(Number(tag.id))}
                        />
                    ))}
                <Box width="100%" ref={dropdownRef} position="relative">
                    <Input
                        bg="transparent"
                        border="none"
                        placeholder={placeholder}
                        fontSize="14px"
                        width="100%"
                        onChange={(e) => {
                            setQuery(e.target.value)
                            onQuery(e.target.value)
                        }}
                        value={query}
                        onKeyDown={(e) => {
                            e.key === 'Backspace' &&
                                query.length <= 0 &&
                                setTags(
                                    tags.slice(0, tags.length - 1),
                                    tags[tags.length - 1]
                                )
                        }}
                    ></Input>
                    {isDropDownActive && (
                        <Dropdown active={isDropDownActive}>
                            <Box maxHeight="400px" overflowX="auto">
                                {/* <Box display="flex" flexDirection="column"> */}
                                {suggestions.map((item, index) => (
                                    <DropdownButtonWrapper width="100%">
                                        <Button
                                            alignItems="center"
                                            height="100%"
                                            fontFamily="Rubik"
                                            color="primary"
                                            bg="transparent"
                                            border="none"
                                            py="10px"
                                            fontSize="14px"
                                            onClick={() => {
                                                setIsDropDownActive(false)
                                                setQuery('')
                                                if (
                                                    tags.find(
                                                        (tag) =>
                                                            tag.id === item.id
                                                    )
                                                )
                                                    return
                                                setTags([...tags, item], item)
                                            }}
                                            // opacity={activeSort === roles.designer ? '0.5' : '1'}
                                        >
                                            {'user' in item && (
                                                <Box
                                                    display="flex"
                                                    gridColumnGap="12px"
                                                >
                                                    <ProfileImg
                                                        src={getImageUrl(
                                                            item.user.other_data
                                                                .profile_pic
                                                        )}
                                                        name={item.user.name}
                                                    />
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        alignItems="start"
                                                        gridRowGap="4px"
                                                    >
                                                        <Highlighted
                                                            unHighlighted="#778CA2"
                                                            search={query}
                                                            text={
                                                                item.user.name
                                                            }
                                                        />
                                                        <Text my="0px">
                                                            {item.user.email}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Button>
                                    </DropdownButtonWrapper>
                                ))}
                                {suggestions.length <= 0 &&
                                    query.length > 0 && (
                                        <DropdownButtonWrapper width="100%">
                                            <Button
                                                alignItems="center"
                                                height="100%"
                                                fontFamily="Rubik"
                                                color="primary"
                                                bg="transparent"
                                                border="none"
                                                py="10px"
                                                fontSize="14px"
                                                onClick={() => {
                                                    if (
                                                        isEmailValid(query) &&
                                                        setNewUsers
                                                    ) {
                                                        setIsDropDownActive(
                                                            false
                                                        )
                                                        setQuery('')
                                                        setNewUsers({
                                                            email: query,
                                                        })
                                                    }
                                                }}
                                                // opacity={activeSort === roles.designer ? '0.5' : '1'}
                                            >
                                                <Box
                                                    display="flex"
                                                    gridColumnGap="12px"
                                                >
                                                    <ProfileImg
                                                        src={''}
                                                        name={query}
                                                    />
                                                    <Box
                                                        display="flex"
                                                        // flexDirection="column"
                                                        alignItems="center"
                                                        gridColumnGap="4px"
                                                    >
                                                        <Text
                                                            my="0px"
                                                            fontWeight={500}
                                                        >
                                                            Invite :{' '}
                                                        </Text>
                                                        <Highlighted
                                                            unHighlighted="#778CA2"
                                                            search={query}
                                                            text={query}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Button>
                                        </DropdownButtonWrapper>
                                    )}
                            </Box>
                            {/* </Box> */}
                        </Dropdown>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export function TagComponent(props: {
    suggestion: ISuggestion
    onDelete: (id: number) => void
}) {
    console.log({ suggestion: props.suggestion })
    return (
        <Box display="inline-block" mr={'8px'}>
            <Box
                px="8px"
                width="max-content"
                borderRadius="20px"
                py="4px"
                display="flex"
                fontFamily="Rubik"
                fontSize="12px"
                color="textTags"
                alignItems="center"
                justifyContent="space-between"
                bg={'#FFE395'}
            >
                {'user' in props.suggestion
                    ? props.suggestion.user.name +
                      ` (${
                          getSentenceCase(
                              props.suggestion.role ||
                                  props.suggestion.user
                                      ?.organisation_members?.[0]?.role ||
                                  '',
                              'NAME'
                          ) || ''
                      })`
                    : props.suggestion.email}
                <Button
                    onClick={() => props.onDelete(Number(props.suggestion.id))}
                    bg="transparent"
                    display="flex"
                    type="button"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CrossSm />
                </Button>
            </Box>
        </Box>
    )
}
function Suggestion(props: any) {
    return (
        <Box width="100%" color="primary" fontFamily="Rubik">
            {props.item.name}
        </Box>
    )
}

export default React.forwardRef(TagInput)
