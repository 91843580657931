import type { SetStateAction } from 'react'

const defaultState = {
	collectionsNew: false,
	collectionsInProgess: false,
	collectionsCompleted: false,
	individualContributors: false,
	yourTeam: false
}
export function handleActiveTab(
	tabName: TabName,
	setNavTabs: React.Dispatch<SetStateAction<NavTabs>> | any
) {
	switch (tabName) {
		case 'YOUR-TEAMS':
		case 'YOUR_TEAMS':
			return setNavTabs({
				...defaultState,
				yourTeam: true
			})
		case 'INDIVIDUAL-CONTRIBUTORS':
		case "INDIVIDUAL_CONTRIBUTORS":
			return setNavTabs({
				collectionsNew: false,
				collectionsInProgess: false,
				collectionsCompleted: false,
				yourTeam: false,
				individualContributors: true
			})
		case 'NEW':
			return setNavTabs({
				collectionsInProgess: false,
				collectionsCompleted: false,
				yourTeam: false,
				individualContributors: false,
				collectionsNew: true,
			})
		case 'IN-PROGRESS':
			return setNavTabs({
				collectionsNew: false,
				collectionsCompleted: false,
				collectionsInProgess: true,
				yourTeam: false,
				individualContributors: false,
			})
		case 'COMPLETED':
			return setNavTabs({
				collectionsNew: false,
				collectionsInProgess: false,
				yourTeam: false,
				individualContributors: false,
				collectionsCompleted: true,
			})
	}
}


export function logoutUser() {
	window.localStorage.clear()
	window.sessionStorage.clear()
}