import { useLazyQuery } from '@apollo/client'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Slider } from 'src/components/slider/slider'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { TabPanel } from 'src/components/tab-panel/tab-panel'
import { Box, Button, Text } from 'src/components/utility'
import { statuses, useUser } from 'src/helpers'
import { GetCollectionsByUserID } from 'src/services'
import { EmptyStateCollection } from './empty-state'
import CreateCollectionModal from 'src/components-v2/modals/create-collection-modal'
import type { DashboardCollections } from '../../@types/dashboard'
import CollectionCard from 'src/components-v2/cards/collection/collection.card'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'
import { DivisionLine } from 'src/components'

export const ManagerCollectionsList = ({
    boxHeight,
    role,
}: {
    boxHeight: string
    role: string
}) => {
    let userId: string | null = null

    const user = useUser()
    if (user) userId = user.userId
    const [value, setValue] = useState(0)
    const [sortText, applySort] = useState<string>('Date Created')
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const commonVar = {
        user_id: Number(userId),
        created_at: sortText === 'Date Created' ? 'desc' : null,
        name: sortText === 'Name' ? 'asc' : null,
        due_date: sortText === 'Due Date' ? 'asc' : null,
        limit: 5,
    }
    const { updateCollection } = useUpdateCollection()

    const [getNewCollection, newCollectionsData] = useLazyQuery(
        GetCollectionsByUserID,
        {
            variables: {
                ...commonVar,
                status: statuses.new,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const [getInProgressCollection, inProgressCollectionsData] = useLazyQuery(
        GetCollectionsByUserID,
        {
            variables: {
                ...commonVar,
                status: statuses.inProgress,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const [getCompletedCollection, completedCollectionsData] = useLazyQuery(
        GetCollectionsByUserID,
        {
            variables: {
                ...commonVar,
                status: statuses.complete,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const newCollection: DashboardCollections[] =
        newCollectionsData?.data?.collections
    const inProgressCollection: DashboardCollections[] =
        inProgressCollectionsData?.data?.collections
    const completedCollection: DashboardCollections[] =
        completedCollectionsData?.data?.collections

    function a11yProps(index: number) {
        return {
            id: `collection-tab-${index}`,
            'aria-controls': `collection-tabpanel-${index}`,
        }
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    }

    function refethAll() {
        if (newCollectionsData.data) {
            newCollectionsData.refetch()
        }
        if (inProgressCollectionsData.data) {
            inProgressCollectionsData?.refetch()
        }
        if (completedCollectionsData.data) {
            completedCollectionsData?.refetch()
        }
    }

    useEffect(() => {
        if (userId && userId !== '') {
            getNewCollection()
            getInProgressCollection()
            getCompletedCollection()
        }
    }, [userId])

    useEffect(() => {
        refethAll()
    }, [sortText])

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
        >
            <Box
                display="flex"
                mb="12px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    className="pointer"
                    onClick={() => {
                        navigate('/collections')
                    }}
                >
                    <Text fontSize={'16px'} my="0px" mr="12px">
                        {' '}
                        Your Collections{' '}
                    </Text>
                    <RightArrow />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gridColumnGap={{ md: '12px', lg: '12px', xl: '24px' }}
                    position="relative"
                >
                    <CustomDropdown
                        label="Sort:"
                        selectedText={sortText}
                        items={['Name', 'Due Date', 'Date Created']}
                        onItemClicked={applySort}
                    />
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        color="links"
                        position="relative"
                        onClick={() => setIsModalOpen(true)}
                    >
                        + Create
                    </Button>
                </Box>
            </Box>

            <BasicCard>
                <>
                    <Box pt="16px" mb="12px" px={'16px'}>
                        {' '}
                        <Slider
                            element={
                                <Box>
                                    <Tabs
                                        value={value}
                                        variant="scrollable"
                                        scrollButtons={false}
                                        onChange={handleChange}
                                        aria-label="collection-tabs"
                                    >
                                        <Tab
                                            label={`New (${
                                                newCollection?.length || 0
                                            })`}
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label={`In Progress (${
                                                inProgressCollection?.length ||
                                                0
                                            })`}
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label={`Completed (${
                                                completedCollection?.length || 0
                                            })`}
                                            {...a11yProps(0)}
                                        />
                                    </Tabs>
                                </Box>
                            }
                        />{' '}
                    </Box>

                    {newCollectionsData.loading ||
                    inProgressCollectionsData.loading ||
                    completedCollectionsData.loading ? (
                        <Box height={boxHeight} position="relative" p={'16px'}>
                            <Box position="relative" width="100%" height="100%">
                                <Skeleton
                                    height="48px"
                                    count={6}
                                    containerClassName="loader"
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            height={boxHeight}
                            pb={'16px'}
                            px={'16px'}
                            className="scrollbar_none"
                            overflowY={'scroll'}
                        >
                            <TabPanel
                                tabName="collection"
                                value={value}
                                index={0}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gridRowGap="16px"
                                >
                                    {newCollection?.map(
                                        (
                                            item: DashboardCollections,
                                            index: number
                                        ) => {
                                            return (
                                                <Box key={item.id}>
                                                    <Box key={item.id}>
                                                        {
                                                            <>
                                                                <CollectionCard
                                                                    dashboardView={
                                                                        true
                                                                    }
                                                                    tabView={
                                                                        true
                                                                    }
                                                                    updateDate={(
                                                                        date
                                                                    ) =>
                                                                        updateCollection(
                                                                            Number(
                                                                                item.id
                                                                            ),
                                                                            {
                                                                                due_date:
                                                                                    new Date(
                                                                                        date
                                                                                    ).toISOString(),
                                                                            },
                                                                            [
                                                                                'GetDueCollections',
                                                                                'getCollectionsByUserId',
                                                                            ]
                                                                        )
                                                                    }
                                                                    updateStatus={(
                                                                        status
                                                                    ) =>
                                                                        updateCollection(
                                                                            Number(
                                                                                item.id
                                                                            ),
                                                                            {
                                                                                status: status,
                                                                            },
                                                                            [
                                                                                'GetDueCollections',
                                                                                '',
                                                                            ]
                                                                        )
                                                                    }
                                                                    entity={{
                                                                        due_date:
                                                                            item.due_date,
                                                                        id: item.id,
                                                                        manager:
                                                                            {
                                                                                email:
                                                                                    item
                                                                                        ?.manager
                                                                                        ?.email ||
                                                                                    '',
                                                                                id: String(
                                                                                    item
                                                                                        .manager
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    .manager
                                                                                    ?.name,
                                                                                other_data:
                                                                                    {
                                                                                        profile_pic:
                                                                                            item
                                                                                                .manager
                                                                                                ?.other_data
                                                                                                ?.profile_pic,
                                                                                        thumbnail:
                                                                                            item
                                                                                                .manager
                                                                                                ?.other_data
                                                                                                ?.thumbnail,
                                                                                    },
                                                                            },
                                                                        status: item.status,
                                                                        name: item.name,
                                                                        products:
                                                                            {
                                                                                totalCompleted:
                                                                                    item
                                                                                        .totalCompleted
                                                                                        .aggregate
                                                                                        .count,
                                                                                delayed:
                                                                                    item
                                                                                        .delayedProducts
                                                                                        .aggregate
                                                                                        .count,
                                                                                total: item
                                                                                    .products_aggregate
                                                                                    .aggregate
                                                                                    .count,
                                                                                complete:
                                                                                    item
                                                                                        .completed
                                                                                        .aggregate
                                                                                        .count,
                                                                            },
                                                                        tags: item.tags,
                                                                        team_id:
                                                                            item.team_id,
                                                                        totalCompleted:
                                                                            item.totalCompleted,
                                                                        totalProducts:
                                                                            item.products_aggregate,
                                                                    }}
                                                                />
                                                                {newCollection.length -
                                                                    1 !==
                                                                index ? (
                                                                    <DivisionLine />
                                                                ) : null}
                                                            </>
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    )}
                                </Box>

                                {(!newCollection ||
                                    newCollection?.length <= 0) && (
                                    <EmptyStateCollection
                                        role={role}
                                        boxHeight={boxHeight}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel
                                tabName="collection"
                                value={value}
                                index={1}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gridRowGap="16px"
                                >
                                    {inProgressCollection?.map(
                                        (item: DashboardCollections) => {
                                            return (
                                                <CollectionCard
                                                    dashboardView={true}
                                                    tabView={true}
                                                    updateDate={(date) =>
                                                        updateCollection(
                                                            Number(item.id),
                                                            {
                                                                due_date:
                                                                    new Date(
                                                                        date
                                                                    ).toISOString(),
                                                            },
                                                            [
                                                                'GetDueCollections',
                                                                'getCollectionsByUserId',
                                                            ]
                                                        )
                                                    }
                                                    updateStatus={(status) =>
                                                        updateCollection(
                                                            Number(item.id),
                                                            {
                                                                status: status,
                                                            },
                                                            [
                                                                'GetDueCollections',
                                                                '',
                                                            ]
                                                        )
                                                    }
                                                    entity={{
                                                        due_date: item.due_date,
                                                        id: item.id,
                                                        manager: {
                                                            email:
                                                                item.manager
                                                                    ?.email ||
                                                                '',
                                                            id: String(
                                                                item.manager?.id
                                                            ),
                                                            name: item.manager
                                                                ?.name,
                                                            other_data: {
                                                                profile_pic:
                                                                    item.manager
                                                                        ?.other_data
                                                                        ?.profile_pic,
                                                                thumbnail:
                                                                    item.manager
                                                                        ?.other_data
                                                                        ?.thumbnail,
                                                            },
                                                        },
                                                        status: item.status,
                                                        name: item.name,
                                                        products: {
                                                            totalCompleted:
                                                                item.completed
                                                                    .aggregate
                                                                    .count,

                                                            delayed:
                                                                item
                                                                    .delayedProducts
                                                                    .aggregate
                                                                    .count,
                                                            total: item
                                                                .products_aggregate
                                                                .aggregate
                                                                .count,
                                                            complete:
                                                                item.completed
                                                                    .aggregate
                                                                    .count,
                                                        },
                                                        tags: item.tags,
                                                        team: item.team,
                                                        team_id: item.team_id,
                                                        totalCompleted:
                                                            item.completed,
                                                        totalProducts:
                                                            item.products_aggregate,
                                                    }}
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                                {(!inProgressCollection ||
                                    inProgressCollection?.length <= 0) && (
                                    <EmptyStateCollection
                                        role={role}
                                        boxHeight={boxHeight}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel
                                tabName="collection"
                                value={value}
                                index={2}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gridRowGap="16px"
                                >
                                    {completedCollection?.map(
                                        (item: DashboardCollections) => {
                                            return (
                                                <CollectionCard
                                                    dashboardView={true}
                                                    tabView={true}
                                                    updateDate={(date) =>
                                                        updateCollection(
                                                            Number(item.id),
                                                            {
                                                                due_date:
                                                                    new Date(
                                                                        date
                                                                    ).toISOString(),
                                                            },
                                                            [
                                                                'GetDueCollections',
                                                                'getCollectionsByUserId',
                                                            ]
                                                        )
                                                    }
                                                    updateStatus={(status) =>
                                                        updateCollection(
                                                            Number(item.id),
                                                            {
                                                                status: status,
                                                            },
                                                            [
                                                                'GetDueCollections',
                                                                '',
                                                            ]
                                                        )
                                                    }
                                                    entity={{
                                                        due_date: item.due_date,
                                                        id: item.id,
                                                        manager: {
                                                            email:
                                                                item.manager
                                                                    ?.email ||
                                                                '',
                                                            id: String(
                                                                item.manager?.id
                                                            ),
                                                            name: item.manager
                                                                ?.name,
                                                            other_data: {
                                                                profile_pic:
                                                                    item.manager
                                                                        ?.other_data
                                                                        ?.profile_pic,
                                                                thumbnail:
                                                                    item.manager
                                                                        ?.other_data
                                                                        ?.thumbnail,
                                                            },
                                                        },
                                                        status: item.status,
                                                        name: item.name,
                                                        products: {
                                                            totalCompleted:
                                                                item
                                                                    .totalCompleted
                                                                    .aggregate
                                                                    .count,
                                                            delayed:
                                                                item
                                                                    .delayedProducts
                                                                    .aggregate
                                                                    .count,
                                                            total: item
                                                                .products_aggregate
                                                                .aggregate
                                                                .count,
                                                            complete:
                                                                item.completed
                                                                    .aggregate
                                                                    .count,
                                                        },
                                                        team: item.team,
                                                        tags: item.tags,
                                                        team_id: item.team_id,
                                                        totalCompleted:
                                                            item.totalCompleted,
                                                        totalProducts:
                                                            item.products_aggregate,
                                                    }}
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                                {(!completedCollection ||
                                    completedCollection?.length <= 0) && (
                                    <EmptyStateCollection
                                        role={role}
                                        boxHeight={boxHeight}
                                    />
                                )}
                            </TabPanel>
                        </Box>
                    )}
                </>
            </BasicCard>
            {isModalOpen && (
                <CreateCollectionModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </Box>
    )
}
