import { useMutation } from '@apollo/client'
import type { SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AddFilesDropdownMenu from 'src/components-v2/add-files/add-files-dropdown-menu'
import FilePreviewModal from 'src/components-v2/file-preview-modal/file-preview-modal'
import AddFilesToLibraryModal from 'src/components-v2/modals/add-files-to-library-modal'
import { LibraryDestinationForFilesModal } from 'src/components-v2/modals/library-destination-for-files-modal/library-destination-for-files-modal'
import RenameFileModal from 'src/components-v2/modals/rename-file-modal'
import { useTodoSectionContext } from 'src/components-v2/todo/todo-section/todo-section.context'
import { getThumbnail } from 'src/components/annotation-comments/annotation-comments.helper'
import { DragDropArea } from 'src/components/drag-drop/drag-drop'
import { FileDisplay2 } from 'src/components/file-display/file-display-2'
import { Loader } from 'src/components/loader/loader'
import { DangerModal } from 'src/components/modals/danger-modal'
import { Modal } from 'src/components/modals/modal'
import { DeleteIco, DownloadIco, PlusIco } from 'src/components/svg-icons'
import AddToLibraryIco from 'src/components/svg-icons/add-to-library-ico'
import AttachmentIco from 'src/components/svg-icons/attachment-ico'
import { Box, Button, Text } from 'src/components/utility'
import { deleteFile, getFileRequestId, uuid } from 'src/helpers'
import { checkFileType } from 'src/helpers/check-file-type'
import { deleteFilesFromEntity } from 'src/helpers/delete-file-from-entity'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import { InsertDownloadFilesRequest } from 'src/services/graphql/entities/todo/todo.mutation'

function FileField({
    files,
    sectionId,
    refetchTodo,
    deleteSection,
    id,
    isReadOnly,
    todoId,
    productId,
}: {
    files: Array<any>
    sectionId: number
    refetchTodo: () => void
    deleteSection: () => void
    id: string
    isReadOnly?: boolean
    todoId: number
    productId: number
}) {
    const [searchParams, _] = useSearchParams()
    const [showAddFilesToLibraryModal, setShowAddFilesToLibraryModal] =
        useState(false)
    const [showLibraryDestinationModal, setShowLibraryDestinationModal] =
        useState(false)
    const [showAddFilesDropdown, setShowAddFilesDropdown] = useState(false)
    const [removeTodoModal, setRemoveTodoModal] = useState<{
        show: boolean
        type: 'single' | 'multiple'
        fileId?: number | null
    }>({
        show: false,
        type: 'single',
        fileId: null,
    })

    const [renameFileModal, setRenameFileModal] = useState<{
        show: boolean
        fileId: number | null
        fileName: string
        isLibraryFile: boolean
    }>({
        show: false,
        fileId: null,
        fileName: '',
        isLibraryFile: false,
    })

    const [fileModal, setFileModal] = useState<{
        sectionId: number
        files: {
            name: string
            path: string
            blob: Blob
            id: string
            progress?: number
            isUploaded?: boolean
        }[]
    }>({
        sectionId: sectionId,
        files: [],
    })
    const [showFileModal, setShowFileModal] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<Array<any>>([])
    const {
        setActiveFileId,
        setActiveMode,
        setFiles,
        setSectionId,
        activeFileId,
        isSplitMode,
    } = useTodoSectionContext()
    const [selectedFilesAdditionalDetails, setSelectedFilesAdditionalDetails] =
        useState<{
            brand: string
            company: string
            colors: Array<any>
            tags: Array<any>
        }>({
            brand: '',
            company: '',
            colors: [],
            tags: [],
        })

    const [isDeleting, setIsDeleting] = useState(false)
    const [showFilePreviewModal, setShowFilePreviewModal] = useState(
        String(searchParams.get('sectionId')) === String(sectionId) &&
            activeFileId
            ? true
            : false
    )
    const [isHovered, setIsHovered] = useState(false)
    const [showDeleteFilesFieldModal, setShowDeleteFilesFieldModal] =
        useState(false)

    const { assignFilesToSection } = useAssignFile(() => {
        setFileModal((prev) => ({ ...prev, isFileModal: false }))
        refetchTodo()
    })
    const [insertDownloadFilesRequest] = useMutation(InsertDownloadFilesRequest)

    useEffect(() => {
        if (!searchParams.get('sectionId')) {
            setShowFilePreviewModal(false)
        }
        if (searchParams.get('fileId')) {
            if (String(sectionId) === searchParams.get('sectionId')) {
                setFiles(files)
            }
        }
    }, [searchParams.get('fileId'), searchParams.get('sectionId')])

    // Reset file modal when files are added or removed
    useEffect(() => {
        setFileModal({
            sectionId: sectionId,
            files: [],
        })
        setShowFileModal(false)
    }, [files.length])

    const deleteFileFromTodo = async (
        id: number,
        type: 'todos' | 'sub_todos' | 'sub_todo_sections',
        entity_id: string
    ) => {
        try {
            const { request_id } = await getFileRequestId({
                action: 'DELETE',
                entity_id: entity_id,
                entity_name: type,
                number_of_files: 1,
            })
            await deleteFile(null, id, request_id)
            refetchTodo()
        } catch (e: any) {
            let message: any = "Couldn't delete file."
            toast(message, { className: 'custom-toaster toaster-error' })
        }
    }

    async function deleteAllFilesAndSection() {
        try {
            setIsDeleting(true)
            const { request_id } = await getFileRequestId({
                action: 'DELETE',
                entity_id: String(sectionId),
                entity_name: 'sub_todo_sections',
                number_of_files: files.length,
            })
            await Promise.all(
                files.map(
                    async (file) => await deleteFile(null, file.id, request_id)
                )
            )
            deleteSection()
        } catch {
            let message: any = "Couldn't delete section."
            toast(message, { className: 'custom-toaster toaster-error' })
        } finally {
            setIsDeleting(false)
        }
    }

    function fileClickHandler(fileId: number) {
        if (isSplitMode) {
            setActiveMode('file_preview')
            setActiveFileId(fileId)
            setSectionId(sectionId)
            setFiles(files)
        } else {
            setShowFilePreviewModal(true)
            setActiveFileId(fileId)
            setSectionId(sectionId)
            setFiles(files)
        }
    }

    const isSelectMode = selectedFiles.length > 0

    async function downloadSelectedFiles({ files }: { files: Array<any> }) {
        const filesArray = files.map((file) => {
            return file.library_file
                ? {
                      id: file.library_file.id,
                      gid: file.library_file.gid,
                      name: file.library_file.name,
                      is_library: true,
                  }
                : file.file
                ? {
                      id: file.file.id,
                      gid: file.file.gid,
                      name: file.file.name,
                      is_library: false,
                  }
                : null
        })
        insertDownloadFilesRequest({
            variables: {
                entity_id: sectionId,
                entity_name: 'sub_todo_sections',
                download_files: filesArray,
            },
            onCompleted: () => {
                toast(
                    'Please wait, We have started zipping your files, you will be notified when its done.',
                    {
                        className: 'custom-toaster toaster-success',
                    }
                )
            },
            onError: () => {
                toast('Failed to Zip files.', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }

    function deleteFileHandler() {
        if (removeTodoModal.type === 'single') {
            deleteFileFromTodo(
                Number(removeTodoModal.fileId),
                'sub_todo_sections',
                sectionId.toString()
            )
        } else {
            deleteFilesFromEntity({
                entityName: 'sub_todo_sections',
                entityId: String(sectionId),
                fileIds: selectedFiles.map((file: any) => file.id),
                onComplete: () => {
                    setSelectedFiles([])
                    refetchTodo()
                    toast('Files deleted successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                },
            })
        }
    }

    const { isUploading, onFileUpload } = useUploadFile(
        fileModal?.sectionId?.toString() || '0',
        'sub_todo_sections'
    )

    async function handleOnFilesAded(
        file: {
            sectionId?: number
            files: {
                name: string
                path: string
                blob: Blob
                id: string
                progress?: number
                isUploaded?: boolean
            }[]
        },
        newFiles: {
            name: string
            path: string
            blob: Blob
            id: string
            progress?: number
            isUploaded?: boolean
        }[]
    ) {
        const arr = await onFileUpload(
            {
                ...file,
                files: newFiles,
            },
            'document',
            (progress, path) => {
                setFileModal((fileModal) => ({
                    ...file,
                    sectionId: fileModal.sectionId,
                    files: fileModal.files.map((file) => {
                        return file.path === path
                            ? {
                                  ...file,
                                  progress,
                                  isUploaded: progress === 100,
                              }
                            : file
                    }),
                }))
            }
        )
        if (arr?.resolvedArr) {
            assignFilesToSection({
                assignedAs: 'attachment',
                entityId: String(fileModal.sectionId) || '',
                entityName: 'sub_todo_sections',
                fileId: arr.resolvedArr.map((file) => file.id),
            })
        }
    }

    function onFileDragged(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        const newFiles: any = []
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            if (!checkFileType(event.dataTransfer.files[i].name)) {
                toast('File type not supported', {
                    className: 'toaster-error',
                })
                continue
            }
            const objURL = URL.createObjectURL(event.dataTransfer.files[i])
            newFiles.push({
                name: event.dataTransfer.files[i].name,
                path: objURL,
                blob: event.dataTransfer.files[i],
                progress: 0,
                id: uuid(),
            })
        }
        setFileModal((fileModal: any) => ({
            ...fileModal,
            files: [...fileModal.files, ...newFiles],
        }))
        handleOnFilesAded(
            {
                ...fileModal,
                files: [...fileModal.files, ...newFiles],
            },
            newFiles
        )
    }

    return (
        <Box
            display={isSelectMode ? 'flex' : 'grid'}
            flexDirection="column"
            gridTemplateColumns={'auto 1fr 28px'}
            gridGap="8px"
            bg="white"
            p="4px"
            borderRadius="4px"
            height="max-content"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            border={isHovered ? '1px solid #B8B8B8' : '1px solid #fff'}
            onDragOver={(event) => {
                event.preventDefault()
            }}
            onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                onFileDragged(event)
            }
            id={id}
        >
            {isSelectMode ? (
                <Box
                    display="grid"
                    gridTemplateColumns="auto 1fr"
                    gridGap="8px"
                >
                    <AttachmentIco />
                    <Box
                        display="flex"
                        px="16px"
                        py="2px"
                        border="1px solid #E8ECEF"
                        justifyContent="space-between"
                        alignItems="center"
                        bg="#E8ECEF"
                        borderRadius="4px"
                    >
                        <Text fontSize={'12px'} color="textSecondary" my="0px">
                            {selectedFiles.length} Selected
                        </Text>
                        <Button
                            height="20px"
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="links"
                            position="relative"
                            maxWidth="max-content"
                            onClick={() => {
                                setSelectedFiles(files)
                            }}
                            px="0px"
                        >
                            Select All
                        </Button>
                        <Button
                            height="20px"
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="danger"
                            position="relative"
                            maxWidth="max-content"
                            onClick={() => {
                                setSelectedFiles([])
                            }}
                            px="0px"
                        >
                            Clear Selection
                        </Button>
                        <Button
                            onClick={() =>
                                downloadSelectedFiles({ files: selectedFiles })
                            }
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                            bg="transparent"
                            px={'0px'}
                        >
                            <DownloadIco color="#778CA2" />
                        </Button>

                        <Button
                            onClick={() => setShowAddFilesToLibraryModal(true)}
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                            bg="transparent"
                            px="0px"
                        >
                            <AddToLibraryIco />
                        </Button>

                        <Button
                            onClick={() => {
                                setRemoveTodoModal({
                                    show: true,
                                    type: 'multiple',
                                })
                            }}
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                            bg="transparent"
                            px="0px"
                        >
                            <DeleteIco />
                        </Button>
                    </Box>
                </Box>
            ) : (
                <AttachmentIco />
            )}

            <Box display="flex" flexWrap="wrap" gridGap="6px">
                {fileModal.files.map((file, key) => {
                    return (
                        <FileDisplay2
                            id="file_display_key"
                            key={key}
                            onClick={() => {}}
                            name={file.name}
                            url={file.path}
                            thumbnail={getThumbnail(file)}
                            width="80px"
                            height="84px"
                            isDeletePermitted={false}
                            onDragStart={(e) => {
                                e.dataTransfer.setData(
                                    'application/json',
                                    JSON.stringify(file)
                                )
                            }}
                            fileId={0}
                            // @ts-ignore
                            showLoader={isUploading}
                            percentage={
                                file?.progress ? String(file.progress) : '0'
                            }
                        />
                    )
                })}
                {files?.map((file: any) => {
                    const fileData = file.library_file
                        ? {
                              ...file.library_file,
                              from_library: true,
                          }
                        : file.file
                        ? {
                              ...file.file,
                              from_library: false,
                          }
                        : null
                    if (!fileData) return <></>
                    return (
                        <FileDisplay2
                            isDownloadable
                            key={file.id}
                            onClick={() =>
                                !isReadOnly ? fileClickHandler(file.id) : null
                            }
                            name={fileData.name}
                            url={
                                fileData.from_library
                                    ? getLibraryFileUrl(fileData.gid)
                                    : getImageUrl(fileData.gid)
                            }
                            thumbnail={getThumbnail(fileData)}
                            onDelete={() =>
                                setRemoveTodoModal({
                                    show: true,
                                    type: 'single',
                                    fileId: file.id,
                                })
                            }
                            isRenamePermitted={!isReadOnly}
                            onRename={() => {
                                setRenameFileModal({
                                    show: true,
                                    fileId: fileData.id,
                                    fileName: fileData.name,
                                    isLibraryFile: fileData.from_library,
                                })
                            }}
                            width="80px"
                            height="84px"
                            isDeletePermitted={!isReadOnly}
                            onDragStart={(e) => {
                                e.dataTransfer.setData(
                                    'application/json',
                                    JSON.stringify(fileData)
                                )
                            }}
                            fileId={file.id}
                            onSelectHandler={(value: boolean) => {
                                if (value) {
                                    setSelectedFiles([...selectedFiles, file])
                                } else {
                                    setSelectedFiles(
                                        selectedFiles.filter(
                                            (_file) => _file.id !== file.id
                                        )
                                    )
                                }
                            }}
                            enableSelect={!isReadOnly}
                            isSelected={selectedFiles.some(
                                (_file) => _file.id === file.id
                            )}
                            id={`file_display_${file.id}`}
                        />
                    )
                })}
                <Box position="relative">
                    {!isReadOnly && (
                        <Button
                            type="button"
                            className="dashed__border"
                            bg="transparent"
                            border="none"
                            width="80px"
                            height="80px"
                            onClick={() =>
                                setShowAddFilesDropdown(!showAddFilesDropdown)
                            }
                            minWidth="72px"
                            id="add-files-dropdown-button"
                        >
                            {' '}
                            <PlusIco />
                        </Button>
                    )}
                    {showAddFilesDropdown && (
                        <AddFilesDropdownMenu
                            setShowDropdown={setShowAddFilesDropdown}
                            showDropdown={showAddFilesDropdown}
                            sectionId={sectionId}
                            setShowFileModal={setShowFileModal}
                        />
                    )}
                </Box>
                {showFilePreviewModal && (
                    <FilePreviewModal
                        isOpen={showFilePreviewModal}
                        onClose={() => setShowFilePreviewModal(false)}
                        productId={productId}
                    />
                )}
                {removeTodoModal.show && (
                    <DangerModal
                        heading={`Are you sure you want to delete the file${
                            removeTodoModal.type === 'multiple' ? 's' : ''
                        }?`}
                        isModal={removeTodoModal.show}
                        onClose={() => {
                            setRemoveTodoModal({
                                show: false,
                                type: 'single',
                                fileId: null,
                            })
                        }}
                        onRemove={deleteFileHandler}
                        primaryButtonText="Yes, Remove"
                        secondaryButtonText="Cancel"
                        subHeading="It will no longer be accessible "
                        title="Delete File"
                    />
                )}

                {renameFileModal.show && renameFileModal.fileId && (
                    <RenameFileModal
                        fileName={renameFileModal.fileName}
                        fileId={renameFileModal.fileId}
                        isLibraryFile={renameFileModal.isLibraryFile}
                        isOpen={renameFileModal.show}
                        onClose={() => {
                            setRenameFileModal({
                                show: false,
                                fileId: null,
                                fileName: '',
                                isLibraryFile: false,
                            })
                        }}
                    />
                )}

                <DragDropModal
                    entity_id={fileModal?.sectionId?.toString() || '0'}
                    entity_name="sub_todo_sections"
                    onClick={(ids) => {
                        if (ids.length <= 0)
                            return toast('No files were selected.', {
                                className: 'custom-toaster toaster-error',
                            })
                        assignFilesToSection({
                            assignedAs: 'attachment',
                            entityId: String(fileModal.sectionId) || '',
                            entityName: 'sub_todo_sections',
                            fileId: ids,
                        })
                    }}
                    fileModal={fileModal}
                    setFileModal={setFileModal}
                    setShowFileModal={setShowFileModal}
                    showFileModal={showFileModal}
                />
            </Box>
            {showAddFilesToLibraryModal && selectedFiles.length > 0 && (
                <AddFilesToLibraryModal
                    isOpen={showAddFilesToLibraryModal}
                    onClose={() => {
                        setShowAddFilesToLibraryModal(false)
                        setSelectedFilesAdditionalDetails({
                            brand: '',
                            company: '',
                            colors: [],
                            tags: [],
                        })
                    }}
                    onClick={() => {
                        setShowAddFilesToLibraryModal(false)
                        setShowLibraryDestinationModal(true)
                    }}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    selectedFilesAdditionalDetails={
                        selectedFilesAdditionalDetails
                    }
                    setSelectedFilesAdditionalDetails={
                        setSelectedFilesAdditionalDetails
                    }
                />
            )}
            {showLibraryDestinationModal && (
                <LibraryDestinationForFilesModal
                    isModal={showLibraryDestinationModal}
                    onClose={() => setShowLibraryDestinationModal(false)}
                    entityName="sub_todo_sections"
                    entityId={sectionId}
                    additionalDetails={selectedFilesAdditionalDetails}
                    selectedFiles={selectedFiles.filter(
                        (file: any) => !!file.file && !file.file.moved_to_lib
                    )}
                    onSuccess={() => {
                        refetchTodo()
                        setSelectedFiles([])
                        setSelectedFilesAdditionalDetails({
                            brand: '',
                            company: '',
                            colors: [],
                            tags: [],
                        })
                    }}
                />
            )}
            {isHovered && !isSelectMode && !isReadOnly ? (
                isDeleting ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Loader outline="#FF2C18" size="18px" />
                    </Box>
                ) : (
                    <Button
                        onClick={() => setShowDeleteFilesFieldModal(true)}
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        bg="#F0F2F3"
                    >
                        <DeleteIco />
                    </Button>
                )
            ) : null}
            {showDeleteFilesFieldModal && (
                <DangerModal
                    onRemove={deleteAllFilesAndSection}
                    onClose={() => setShowDeleteFilesFieldModal(false)}
                    isModal={showDeleteFilesFieldModal}
                    heading={`Are you sure you want to delete “attachment” details of this section?`}
                    subHeading={
                        'Deleting this section detail will permanently delete all the files attached. '
                    }
                    title={'Delete Section Detail'}
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                />
            )}
        </Box>
    )
}

export default FileField

export function DragDropModal({
    setFileModal,
    fileModal,
    onClick,
    entity_name,
    entity_id,
    title = 'Upload from Computer',
    primaryButtonTitle = 'Upload',
    setShowFileModal,
    showFileModal,
}: {
    setFileModal: React.Dispatch<
        SetStateAction<{
            sectionId: number
            files: {
                name: string
                path: string
                blob: Blob
                id: string
                progress?: number
                isUploaded?: boolean
            }[]
        }>
    >
    fileModal: {
        sectionId: number
        files: {
            name: string
            path: string
            blob: Blob
            id: string
            progress?: number
            isUploaded?: boolean
        }[]
    }
    onClick: (gids: string[]) => void
    entity_name: string
    entity_id: string
    title?: string
    primaryButtonTitle?: string
    showFileModal: boolean
    setShowFileModal: React.Dispatch<SetStateAction<boolean>>
}) {
    const { isUploading, onFileUpload } = useUploadFile(entity_id, entity_name)
    const [fileData, setFileData] = useState<
        {
            id: string
            path?: string
        }[]
    >([])

    async function handleOnFilesAded(
        file: {
            sectionId?: number
            files: {
                name: string
                path: string
                blob: Blob
                id: string
                progress?: number
                isUploaded?: boolean
            }[]
        },
        newFiles: {
            name: string
            path: string
            blob: Blob
            id: string
            progress?: number
            isUploaded?: boolean
        }[]
    ) {
        const arr = await onFileUpload(
            {
                ...file,
                files: newFiles,
            },
            'document',
            (progress, path) => {
                setFileModal((fileModal) => ({
                    ...file,
                    sectionId: fileModal.sectionId,
                    files: fileModal.files.map((file) => {
                        return file.path === path
                            ? {
                                  ...file,
                                  progress,
                                  isUploaded: progress === 100,
                              }
                            : file
                    }),
                }))
            }
        )
        if (arr?.resolvedArr) {
            onClick(arr.resolvedArr.map((file) => file.id))
        }
    }

    return (
        <Modal
            onClose={() => {
                setFileData([])
                setShowFileModal(false)
            }}
            isModal={showFileModal}
            onClick={() => onClick(fileData.map((file) => file.id))}
            primaryButtonTitle={primaryButtonTitle}
            secondaryButtonTitle="Cancel"
            title={title}
            disableActions={true}
        >
            <Box
                p="24px"
                width="100%"
                height="330px"
                overflowY={fileModal.files.length > 8 ? 'auto' : 'hidden'}
                onDragOver={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                }}
                onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                    event.stopPropagation()
                }
            >
                <DragDropArea
                    onFilesAdded={(file, newFiles) =>
                        handleOnFilesAded(file, newFiles)
                    }
                    onDeleteUploadedFile={(path) => {
                        setFileData((data) =>
                            data.filter((file) => file.path !== path)
                        )
                    }}
                    height="100%"
                    fileModal={fileModal}
                    setFileModal={setFileModal}
                />
            </Box>
        </Modal>
    )
}
