import type { DrawerTab, storeState } from './@types/slider-types'

export const createProductDrawerSlice = (set: Function) => ({
    drawerTabs: [] as DrawerTab[],
    currentTabId: '',
    showDrawerDock: false,
    setCurrentTabId: (id: string) => {
        set((state: storeState) => {
            return {
                ...state,
                currentTabId: id,
            }
        })
    },
    clearDrawerTabs: () => {
        set((state: storeState) => {
            return {
                ...state,
                drawerTabs: [],
                showDrawerDock: false,
            }
        })
    },
    addDrawerTab: (payload: DrawerTab) => {
        set((state: storeState) => {
            const isTabPresent = state.drawerTabs.find(
                (tab) => tab.id === payload.id && tab.type === payload.type
            )
            if (isTabPresent) {
                return {
                    ...state,
                    currentTabId: payload.id,
                    showDrawerDock: false,
                }
            } else {
                return {
                    ...state,
                    drawerTabs: [...state.drawerTabs, payload],
                    currentTabId: payload.id,
                    showDrawerDock: false,
                }
            }
        })
    },
    addDrawerTabOne: (payload: DrawerTab) => {
        set((state: storeState) => {
            const isTabPresent = state.drawerTabs.find(
                (tab) => tab.id === payload.id && tab.type === payload.type
            )
            if (isTabPresent) {
                return {
                    ...state,
                    currentTabId: payload.id,
                    callback: payload.callback,
                    showDrawerDock: false,
                }
            } else {
                return {
                    ...state,
                    drawerTabs: [payload],
                    currentTabId: payload.id,
                    callback: payload.callback,
                    showDrawerDock: false,
                }
            }
        })
    },
    removeDrawerTab: ({ id }: { id: string }) => {
        set((state: storeState) => {
            const currentTabIndex = state.drawerTabs.findIndex(
                (tab) => tab.id === state.currentTabId
            )
            return {
                ...state,
                drawerTabs: state.drawerTabs.filter((tab) => tab.id !== id),
                currentTabId:
                    state.currentTabId === id
                        ? state.currentTabId === state.drawerTabs[0].id &&
                          state.drawerTabs.length > 1
                            ? state.drawerTabs[1].id
                            : state.drawerTabs[
                                  currentTabIndex == 0
                                      ? currentTabIndex
                                      : currentTabIndex - 1
                              ].id
                        : state.currentTabId,
            }
        })
    },
    removeCurrentTab: () => {
        set((state: storeState) => {
            const currentTabIndex = state.drawerTabs.findIndex(
                (tab) => tab.id === state.currentTabId
            )
            return {
                ...state,
                drawerTabs: state.drawerTabs.filter(
                    (tab) => tab.id !== state.currentTabId
                ),
                currentTabId:
                    state.currentTabId === state.drawerTabs[0]?.id &&
                    state.drawerTabs.length > 1
                        ? state.drawerTabs[1]?.id
                        : state.drawerTabs[
                              currentTabIndex == 0
                                  ? currentTabIndex
                                  : currentTabIndex - 1
                          ]?.id,
            }
        })
    },
    updateDrawerTab: (payload: DrawerTab) => {
        set((state: storeState) => {
            const currentTabIndex = state.drawerTabs.findIndex(
                (tab) => tab.id === state.currentTabId
            )
            return {
                ...state,
                drawerTabs: state.drawerTabs.map((tab) =>
                    tab.id === payload.id ? payload : tab
                ),
                currentTabId: payload.id,
            }
        })
    },
    setShowDrawerDock: (value: boolean) => {
        set((state: storeState) => {
            return { ...state, showDrawerDock: value }
        })
    },
})
