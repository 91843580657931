import React, { useState } from 'react'
import { Box, Button, Input } from 'src/components/utility'
import CircleIco from 'src/components/svg-icons/circle-ico'
import { debounce } from 'src/helpers'
import { DeleteIco } from 'src/components/svg-icons'
import AddLinks from 'src/components-v2/add-links/add-links'
import { DescriptionField } from 'src/components-v2/todo/subtodo-card/subtodo-section/fields/description-field'
import FileField from 'src/components-v2/todo/subtodo-card/subtodo-section/fields/files-field'
import ColorsField from 'src/components-v2/todo/subtodo-card/subtodo-section/fields/colors-field'
import SubtodoSectionFieldsBar from 'src/components-v2/todo/subtodo-card/subtodo-section/subtodo-section-fields-bar'
import useUpdateTemplate from 'src/hooks/useUpdateTemplate.hook'

function TemplateSubtodoSection({
    section,
    refetchTemplate,
    isLastSection,
    subtodoId,
    isDeletable,
    isReadOnly,
    isTechPack,
    templateId,
}: {
    section: any
    refetchTemplate: () => void
    templateId: number
    isLastSection: boolean
    subtodoId: number
    isDeletable: boolean
    isReadOnly?: boolean
    isTechPack: boolean
}) {
    const [sectionData, setSectionData] = useState({
        name: section.name,
    })
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const {
        insertTemplateSubtodoSection,
        updateTemplateSection,
        debouncedUpdateTemplateSectionHandler,
    } = useUpdateTemplate()

    const createNewSubSection = debounce(
        () => {
            insertTemplateSubtodoSection({
                templateId,
                templateSubtodoId: subtodoId,
                onCompleted: () => {
                    refetchTemplate()
                },
            })
        },
        1000,
        setTimeOutRef,
        timeOutRef
    )

    const handleSectionNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSectionData({
            name: e.target.value,
        })
        debouncedUpdateTemplateSectionHandler({
            payload: { name: e.target.value },
            sectionId: section.id,
        })
    }

    return (
        <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            width="100%"
            gridGap="8px"
            py="8px"
            id={`subtodo_section_${section.id}`}
        >
            {isTechPack && (
                <Box>
                    <CircleIco />
                </Box>
            )}
            <Box display="flex" flexDirection="column" gridGap="12px">
                <Box display="flex" justifyContent="space-between">
                    <Input
                        placeholder="Add detail"
                        bg="transparent"
                        border="none"
                        width="100%"
                        value={sectionData.name}
                        onChange={handleSectionNameChange}
                        readOnly={isReadOnly}
                    />
                    {isDeletable && !isReadOnly && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                updateTemplateSection({
                                    payload: {
                                        deleted_at: new Date().toISOString(),
                                    },
                                    sectionId: section.id,
                                })
                            }}
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                        >
                            <DeleteIco />
                        </Button>
                    )}
                </Box>
                {section.description !== null && (
                    <DescriptionField
                        description={section.description}
                        updateSectionDescription={(description) => {
                            debouncedUpdateTemplateSectionHandler({
                                payload: { description },
                                sectionId: section.id,
                            })
                        }}
                        ml="0px"
                        width="100%"
                        id={`description_${section.id}`}
                        isReadOnly={isReadOnly}
                    />
                )}
                {section.other_data?.files && (
                    <FileField
                        files={section.template_files}
                        sectionId={section.id}
                        refetchTodo={refetchTemplate}
                        deleteSection={() =>
                            updateTemplateSection({
                                payload: {
                                    other_data: {
                                        files: null,
                                    },
                                },
                                sectionId: section.id,
                            })
                        }
                        isReadOnly={isReadOnly}
                        todoId={0}
                        productId={0}
                        id={`files_${section.id}`}
                        // isTemplate={true}
                    />
                )}
                {section.colors && (
                    <ColorsField
                        colors={section.colors}
                        refetchTodo={refetchTemplate}
                        sectionId={section.id}
                        deleteSection={() =>
                            updateTemplateSection({
                                payload: {
                                    colors: null,
                                },
                                sectionId: section.id,
                            })
                        }
                        id={`colors_${section.id}`}
                        isReadOnly={isReadOnly}
                        isTemplate={true}
                    />
                )}
                {section.links && (
                    <AddLinks
                        links={section.links}
                        isEditPermitted={!isReadOnly}
                        deleteSection={() =>
                            updateTemplateSection({
                                payload: {
                                    links: null,
                                },
                                sectionId: section.id,
                            })
                        }
                        refetchTodo={refetchTemplate}
                        sectionId={section.id}
                        id={`links_${section.id}`}
                        isTemplate={true}
                    />
                )}
                {!isReadOnly && (
                    <SubtodoSectionFieldsBar
                        isColorsPresent={!!section.colors}
                        isLinksPresent={!!section.links}
                        isDescriptionPresent={section.description !== null}
                        isFilesPresent={!!section.other_data?.files}
                        subtodoSectionId={section.id}
                        refetchTodo={refetchTemplate}
                        onClick={() => {
                            if (isLastSection) {
                                createNewSubSection()
                            }
                        }}
                        sectionId={section.id}
                        isTemplate={true}
                    />
                )}
            </Box>
        </Box>
    )
}

export default TemplateSubtodoSection
