import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import CommentsDropdown from 'src/components-v2/comments-dropdown/comments-dropdown'
import { CommentsIco } from 'src/components/svg-icons/comments-ico'
import { Box, Button, Text } from 'src/components/utility'
import { GetProductVariantTeamById } from 'src/services/graphql/entities/product/product.queries'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'
import useCommentEditor from '../comments-dropdown/useCommentEditor'
import useFetchComments from 'src/hooks/useFetchComments.hook'

function FileComments({
    fileId,
    entityName,
    productId,
}: {
    fileId: number
    entityName:
        | 'files'
        | 'library_files'
        | 'assigned_files'
        | 'product_approval_files'
    productId?: number
}) {
    useFetchComments({
        entityId: fileId,
        entityName: 'assigned_files',
    })

    const location = useLocation()
    const [searchParams] = useSearchParams()
    const { isCommentNotification } = location.state || {}
    const [showComments, setShowComments] = useState(
        Number(searchParams.get('showComments')) === 1
            ? true
            : isCommentNotification
            ? isCommentNotification
            : false
    )

    const { data: teamData } = useQuery(GetProductVariantTeamById, {
        variables: {
            id: productId,
        },
    })

    const team = teamData?.product_variants_by_pk?.team || []
    const { editor, onComment, onDelete } = useCommentEditor({
        team,
        entityId: fileId,
        entityName,
    })

    const { comments: commentsFromSocket, status } = useCommentsStore()

    const entityComments = commentsFromSocket?.find(
        (comment: any) =>
            comment.entity_id === String(fileId) &&
            comment.entity_name === entityName
    )

    // Sort comments by to show latest comment come at the end (nearest to input box)
    // !!FIXME: This is a temporary solution until we have a better way to handle this from backend
    const comments = entityComments?.comments.sort(
        (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )

    return (
        <Box position="relative">
            {comments && comments.length > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    top="-10px"
                    right="0"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {comments.length}
                    </Text>
                </Box>
            )}
            <Button
                onClick={() => setShowComments(!showComments)}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                id="comment-button"
            >
                <CommentsIco active={showComments} />
            </Button>
            {showComments && (
                <CommentsDropdown
                    comments={comments}
                    loading={status !== 'active'}
                    setShowComments={setShowComments}
                    height="80vh"
                    editor={editor}
                    onComment={() => onComment(editor)}
                    onDelete={(commentId: string) => onDelete(commentId)}
                />
            )}
        </Box>
    )
}

export default React.memo(FileComments)
