import React from 'react'
import { Box } from 'src/components/utility'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProductSection from '../../components-v2/product/product-section'
import CompareEntityTopbar from 'src/components-v2/todo/todo-topbar/compare-entity-topbar'
import EntityCompareSection from 'src/components-v2/entity-compare-section/entity-compare-section'

function Product() {
    const { productId } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { compareEntity, compareId } = Object.fromEntries(
        searchParams.entries()
    )

    if (!productId || isNaN(Number(productId))) {
        navigate('/404')
        return null
    }

    const isSplitMode =
        !!compareId && (compareEntity === 'todo' || compareEntity === 'product')

    return (
        <Box
            height="100%"
            display="grid"
            gridTemplateRows="auto 1fr"
            width={'100%'}
            bg="white"
        >
            <CompareEntityTopbar />
            <Box
                display="grid"
                gridTemplateColumns={isSplitMode ? '1fr 1fr' : '1fr'}
                height={'100%'}
                overflow={'hidden'}
            >
                <ProductSection
                    key={productId}
                    productId={Number(productId)}
                    isSplitMode={isSplitMode}
                />
                {isSplitMode && (
                    <EntityCompareSection
                        compareEntity={compareEntity}
                        compareId={compareId}
                        key={`${compareEntity}-${compareId}`}
                    />
                )}
            </Box>
        </Box>
    )
}

export default Product
