import * as React from 'react'

export const FilledCloseIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 3c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9Z"
            fill="#778CA2"
        />
        <path
            d="M9.88 8.885a.704.704 0 0 0-.995.995l2.12 2.12-2.12 2.12a.704.704 0 0 0 .995.995l2.12-2.12 2.12 2.12a.704.704 0 0 0 .995-.995L12.995 12l2.12-2.12a.704.704 0 1 0-.995-.995L12 11.005l-2.12-2.12Z"
            fill="#fff"
        />
    </svg>
)
