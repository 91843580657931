import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { Box } from 'src/components/utility'
import { getTitleCase } from 'src/helpers/get-title-case'
import CollectionListCard from 'src/components-v2/entity-list-card/collection-list.card'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'

import {
    isCollectionEntity,
    isProductEntity,
    isTodoEntity,
} from './entity-list.helper'
import type { EntityListProps } from './entity-list.types'
import { ENTITIES } from 'src/helpers/enums'
import ProductVariantFilterContainer from 'src/components-v2/product-variants/product-list-variant-filter-container/product-variant-filter-container'

export default function EntityList({
    entityList,
    activeGroup,
    refetchProduct,
    currentTab,
}: EntityListProps) {
    return (
        <Box display="flex" flexDirection="column" gridGap="8px" mt="8px">
            {entityList.length > 0 ? (
                entityList.map((entity) => {
                    switch (activeGroup) {
                        case ENTITIES.collections:
                            if (isCollectionEntity(entity, activeGroup)) {
                                return (
                                    <CollectionListCard
                                        key={entity.id}
                                        collection={{
                                            id: entity.id,
                                            name: entity.name,
                                            productsCount: {
                                                total:
                                                    entity.total_products
                                                        .aggregate?.count || 0,
                                                completed:
                                                    entity.completed_products
                                                        .aggregate?.count || 0,
                                            },
                                            dueDate: entity.due_date,
                                            status: entity.status,
                                            isDelayed: entity.is_delayed,
                                            team: entity.team,
                                        }}
                                    />
                                )
                            }
                            break
                        case ENTITIES.products:
                            if (isProductEntity(entity, activeGroup)) {
                                return (
                                    <ProductVariantFilterContainer
                                        key={entity.id}
                                        status={currentTab}
                                        product={entity}
                                        refetch={() =>
                                            refetchProduct && refetchProduct()
                                        }
                                    />
                                )
                            }
                            break
                        case ENTITIES.todos:
                            if (
                                isTodoEntity(entity, activeGroup) &&
                                entity.product_variant?.collection?.id
                            ) {
                                return (
                                    <TodoListCard
                                        key={entity.id}
                                        todo={{
                                            id: entity.id,
                                            name: entity.name,
                                            coverPic:
                                                entity.other_data?.cover_pic,
                                            collection: {
                                                id: entity.product_variant
                                                    ?.collection?.id,
                                                name:
                                                    entity.product_variant
                                                        ?.collection?.name ||
                                                    '',
                                                manager_id:
                                                    entity.product_variant
                                                        ?.collection
                                                        ?.manager_id,
                                            },
                                            product: {
                                                id: entity.product_variant?.id,
                                                name:
                                                    entity.product_variant
                                                        ?.name || '',
                                                coverPic:
                                                    entity.product_variant
                                                        ?.other_data?.cover_pic,
                                            },
                                            dueDate: entity.due_date,
                                            isDelayed: entity.is_delayed,
                                            status: entity.status,
                                            assignee: entity.assignee,
                                        }}
                                        navigateTo={`/todo/${entity.id}`}
                                    />
                                )
                            }
                            break
                        default:
                            return null
                    }
                })
            ) : (
                <EntityEmptyState entityName={getTitleCase(activeGroup)} />
            )}
        </Box>
    )
}
