/**
 * @param {Date} date
 * @returns formatted date `31 Oct’21`
 * @default PLAIN
 */

export function getFormattedDate(
    date: Date | string,
    type?: 'PLAIN' | 'FORMATTED',
    hideYear?: boolean
) {
    if (!date || (typeof date !== 'string' && !(date instanceof Date))) {
        throw new Error('Invalid date input')
    }

    const dateObj = new Date(date)
    if (isNaN(dateObj.getTime())) {
        throw new Error('Invalid date input')
    }
    const dateSplit = new Date(date).toDateString().split(' ')

    if (type !== 'FORMATTED') {
        return (
            dateSplit[2] +
            ' ' +
            dateSplit[1] +
            (hideYear ? '' : ' ' + dateSplit[3])
        )
    }

    return (
        dateSplit[2] +
        ' ' +
        dateSplit[1] +
        (hideYear ? '' : ' ' + dateSplit[3]?.substring(2))
    )
}

export const getFormattedDueDateTitle = ({
    dueDate,
}: {
    dueDate: Date | [Date | null, Date | null] | null
}) => {
    if (!dueDate) return
    if (Array.isArray(dueDate)) {
        return `${dueDate[0] ? getFormattedDate(dueDate[0]) : ''} - ${
            dueDate[1] ? getFormattedDate(dueDate[1]) : ''
        }`
    } else {
        return getFormattedDate(dueDate)
    }
}
