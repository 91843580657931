import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

import { ColoredButton } from 'src/components/buttons/colored-button'
import { Box, Text, TextArea, Button } from 'src/components/utility'
import { PlusIco } from 'src/components/svg-icons'
import { Modal, FileDisplay } from 'src/components'
import { CREATE_TICKET } from 'src/services/api/endpoints'
import { debugLog } from 'src/helpers'
import { getLocalStorage } from 'src/helpers'
import { isNewTabOn } from 'src/helpers/create-redirection-url'

function DragDropModal({
    onClick,
    isLoading,
    isModal,
    setModal,
}: {
    onClick: (files: any) => void
    isLoading: boolean
    isModal: boolean
    setModal: (x: boolean) => void
}) {
    const [fileModalData, setFileModalData] = useState<{
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        subTodoId: null,
        files: [],
    })

    return (
        <Modal
            onClose={() => {
                setModal(false)
            }}
            isModal={isModal}
            onClick={() => {
                onClick(fileModalData)
            }}
            primaryButtonTitle="Upload"
            secondaryButtonTitle="Cancel"
            title="Upload Files"
            isLoading={isLoading}
            disablePrimaryButton={isLoading || !fileModalData.files.length}
        >
            <Box
                p="24px"
                width="100%"
                height="330px"
                overflowY={fileModalData.files.length > 8 ? 'auto' : 'hidden'}
            >
                <></>
            </Box>
        </Modal>
    )
}

const Support = () => {
    const [query, setQuery] = useState('')
    const [openModal, setModal] = useState(false)
    const [sendQuery, setSendQuery] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [fileModal, setFileModal] = useState<{
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        subTodoId: null,
        files: [],
    })

    const recieveData = (files: {
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }) => {
        const new_files = files.files
        const existing_files = fileModal.files
        const samefiles: any = {}
        let filesAlreadyExists = false

        new_files.forEach((element) => {
            samefiles[element.name] = element
            let find = existing_files.findIndex(
                (item) => item.name === element.name
            )
            if (find !== -1) {
                filesAlreadyExists = true
            }
        })

        existing_files.forEach((element) => {
            samefiles[element.name] = element
        })

        if (filesAlreadyExists) {
            const _cnfMsg = `One or more files already exists in this location. Do you want to replace the existing files with a new version?`
            const _confirmed: boolean = confirm(_cnfMsg)
            if (!_confirmed) {
                return
            }
        }

        let _vals: {
            name: string
            path: string
            blob: Blob
            id: string
        }[] = Object.values(samefiles)

        const finalFiles = filesAlreadyExists
            ? _vals
            : [...existing_files, ...new_files]
        setModal(false)
        setFileModal((fileModal) => ({
            ...fileModal,
            isFileModal: false,
            files: finalFiles,
        }))
    }

    const onDelete = (id: string) => {
        setFileModal((fileModal) => ({
            ...fileModal,
            files: fileModal.files.filter((file) => file.id !== id),
        }))
    }

    const createTicket = async () => {
        try {
            setIsLoading(true)
            setSendQuery('')
            const description = query
            const payloadFiles = fileModal.files

            let data: FormData = new FormData()
            for (const pf of payloadFiles) {
                data.append('attachments', pf.blob)
            }
            data.append('description', description)

            const responseData = await axios.request({
                url: CREATE_TICKET,
                method: 'POST',
                data,
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })
            const resposnse = responseData?.data
            setIsLoading(false)
            setQuery('')
            setFileModal({
                isFileModal: false,
                subTodoId: null,
                files: [],
            })
            toast(`Support ticket has been raised successfully`, {
                className: 'custom-toaster toaster-success',
            })
        } catch (e) {
            debugLog(e)
            setIsLoading(false)
        }
    }

    return (
        <>
            <Box mt="40px">
                <Text
                    fontFamily="Rubik"
                    fontSize="24px"
                    color="primary"
                    position="relative"
                    my={'0px'}
                    lineHeight="32px"
                >
                    Support
                </Text>

                <Text>We are here to help you!</Text>

                <Box display="flex">
                    <Text my="0px" color="primary" fontSize="14px">
                        Please explain your concern{' '}
                    </Text>
                    <Text
                        my="0px"
                        ml="8px"
                        fontSize="14px"
                        color="textSecondary"
                    >
                        &#40;we will get back in 24 hours&#41;
                    </Text>
                </Box>

                <Box
                    bg="grey250"
                    pl="10px"
                    pb="10px"
                    pr="10px"
                    borderRadius="8px"
                    border="grey250"
                    width="70%"
                >
                    <Box mt="20px">
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            color="textSecondary"
                            alignItems="center"
                        >
                            Assign Files
                        </Box>
                    </Box>

                    <Box display="flex">
                        <Box>
                            <Button
                                type="button"
                                mt="12px"
                                onClick={() => {
                                    setModal(true)
                                }}
                                className="dashed__border"
                                bg="transparent"
                                border="none"
                                width="72px"
                                height="72px"
                                // ml="36px"
                            >
                                <PlusIco />
                            </Button>
                        </Box>

                        {openModal && (
                            <DragDropModal
                                isLoading={false}
                                isModal={openModal}
                                setModal={setModal}
                                onClick={(files: any) => recieveData(files)}
                            />
                        )}

                        {fileModal?.files && (
                            <Box
                                mt="8px"
                                ml="24px"
                                display="grid"
                                gridColumnGap="2px"
                                gridRowGap="4px"
                                gridTemplateColumns="130px 130px 130px 130px 130px 130px"
                            >
                                {fileModal?.files?.map((file, index) => {
                                    if (file.name.length <= 0) return <></>
                                    return (
                                        <FileDisplay
                                            redirect={false}
                                            isDownloadable
                                            key={'key+' + index}
                                            name={file.name}
                                            url={file.path}
                                            isUrlRequired={false}
                                            onDelete={() => {
                                                onDelete(file.id)
                                            }}
                                            openFileInNewTab={isNewTabOn({
                                                name: file.name,
                                            })}
                                        />
                                    )
                                })}
                            </Box>
                        )}
                    </Box>
                    <TextArea
                        bg="white"
                        value={query}
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                            {
                                setQuery(e.target.value),
                                    setSendQuery(e.target.value)
                            }
                        }}
                        borderColor="gray350"
                        borderRadius="4px"
                        mt="12px"
                        cols={100}
                        rows={10}
                        width="100%"
                        placeholder="Start here"
                        px="16px"
                        py="12px"
                        fontFamily="Rubik"
                    ></TextArea>
                    <Box mt="16px" display="flex" gridColumnGap="16px">
                        <ColoredButton
                            loader={isLoading}
                            disabled={sendQuery.length <= 0}
                            variant="primary"
                            onClick={() => {
                                createTicket()
                            }}
                        >
                            Send
                        </ColoredButton>
                        <ColoredButton
                            variant="Secondary"
                            onClick={() => {
                                setQuery(''), setSendQuery('')
                            }}
                        >
                            Clear
                        </ColoredButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default Support
