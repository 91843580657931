import React, { useEffect } from 'react'
import CommentCard from 'src/components/comments-v2/comment-card'
import CommentsEmptyState from 'src/components/comments-v2/comments-empty-state'
import { Loader } from 'src/components/loader/loader'
import { Box } from 'src/components/utility'
import { getImageUrl, useUser } from 'src/helpers'

function CommentContainer({
    loading,
    comments,
    onDelete,
}: {
    loading: boolean
    comments: any
    onDelete: (commentId: string) => void
}) {
    const { userId } = useUser()
    const lastCommentId = comments?.[comments.length - 1]?.id
    useEffect(() => {
        if (lastCommentId) {
            const container = document.getElementById('comment-container')
            const lastComment = document.getElementById(
                `comment-card-${lastCommentId}`
            )

            if (container && lastComment) {
                const containerRect = container.getBoundingClientRect()
                const lastCommentRect = lastComment.getBoundingClientRect()

                // Check if the last comment is not fully visible
                if (
                    lastCommentRect.top < containerRect.top ||
                    lastCommentRect.bottom > containerRect.bottom
                ) {
                    lastComment.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                    })
                }
            }
        }
    }, [lastCommentId])
    return (
        <Box
            display="flex"
            flexDirection="column"
            gridGap="6px"
            mt="8px"
            px="24px"
            pb="8px"
            height="100%"
            overflowY="auto"
            style={{ scrollBehavior: 'smooth' }}
            id="comment-container"
        >
            {!loading && !!comments ? (
                comments.length > 0 ? (
                    comments.map((comment: any) => {
                        return (
                            <CommentCard
                                id={comment.id}
                                key={comment.id}
                                commenter={{
                                    name: comment?.user?.name || '',
                                    img:
                                        comment?.user?.other_data &&
                                        comment?.user?.other_data?.profile_pic
                                            ? getImageUrl(
                                                  comment.user.other_data
                                                      .profile_pic
                                              )
                                            : '',
                                }}
                                comment={comment.comment}
                                createdAt={
                                    comment?.created_at || comment.createdAt
                                }
                                isAllowedToDelete={comment.user?.id === userId}
                                onDelete={() => onDelete(comment.id)}
                                commentBlocks={comment?.blocks}
                            />
                        )
                    })
                ) : (
                    <CommentsEmptyState />
                )
            ) : (
                <Box
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Loader outline="black" size="42px" />
                </Box>
            )}
        </Box>
    )
}

export default CommentContainer
