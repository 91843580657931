import { useState } from 'react'
import { IconButton } from '../buttons/icon-button'
import { PlusIcoSm } from '../svg-icons/plus-ico-sm'
import { Box, Text } from '../utility'
import { CreateNewDropdown } from './actions/create-new.tsx/create-new-dropdown'
import { roles, useUser } from 'src/helpers'
import CreateCollectionModal from 'src/components-v2/modals/create-collection-modal'
import CreateProductModal from 'src/components-v2/modals/create-product-modal'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'

export function CollectionHeader() {
    const [showCreateNewDropdown, setShowCreateNewDropdown] = useState(false)
    const [activeCreateEntityModal, setActiveCreateEntityModal] = useState<
        'collection' | 'product' | 'todo' | null
    >(null)
    const { role } = useUser()

    const hasCreatePermission = role !== roles.designer

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            bg="#fff"
            alignItems="center"
            p="24px"
            height="max-content"
        >
            <Text
                fontFamily="Rubik"
                fontSize="24px"
                color="primary"
                position="relative"
                my={'0px'}
                lineHeight="28.4px"
            >
                Collections & Products
            </Text>
            {
                <Box position="relative">
                    <IconButton
                        id="header-collection-createbutton"
                        variant={'primary'}
                        iconLeft={<PlusIcoSm color="#ffffff" />}
                        onClick={() => setShowCreateNewDropdown(true)}
                    >
                        Create New
                    </IconButton>
                    {showCreateNewDropdown && (
                        <CreateNewDropdown
                            active={showCreateNewDropdown}
                            setIsDropdownActive={setShowCreateNewDropdown}
                            setActiveModal={setActiveCreateEntityModal}
                        />
                    )}
                </Box>
            }
            {activeCreateEntityModal === 'collection' && (
                <CreateCollectionModal
                    isOpen={activeCreateEntityModal === 'collection'}
                    onClose={() => setActiveCreateEntityModal(null)}
                />
            )}
            {activeCreateEntityModal === 'product' && (
                <CreateProductModal
                    isOpen={activeCreateEntityModal === 'product'}
                    onClose={() => setActiveCreateEntityModal(null)}
                />
            )}
            {activeCreateEntityModal === 'todo' && (
                <CreateTodoModal
                    isOpen={activeCreateEntityModal === 'todo'}
                    onClose={() => setActiveCreateEntityModal(null)}
                />
            )}
        </Box>
    )
}
