import * as React from 'react'
import { DivisionLine } from 'src/components/division-line/division-line'
import { CloseIco } from 'src/components/svg-icons/close-ico'
import { Box, Button, Image, Input, Link, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import type { MeshCustomMaterial } from '../material/custom-material'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { DeleteIco } from 'src/components/svg-icons'
import { UVScale } from '../graphic/uvscale'
import { Checkbox } from 'src/components/checkbox/checkbox'
import { captureImage } from '../../helper/create-material'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export interface IFabricControlPanelProps {
    mapName: string
    mapCode: TMapsKeys
    isFabricControlPanel: boolean
    setIsFabricControlPanelActive: (value: boolean) => void
}

export default function FabricControlPanel({
    isFabricControlPanel,
    mapCode,
    mapName,
    setIsFabricControlPanelActive,
}: IFabricControlPanelProps) {
    const { highlights, selectedFabric, setSelectedFabric, advancedMode } =
        useConfiguratorContext()
    const [isApplyAll, setIsApplyAll] = React.useState(false)
    const [currentMap, setCurrentMap] = React.useState(
        selectedFabric?.maps.find((map) => map.code === mapCode)
    )
    const [isLinked, setIsLinked] = React.useState({
        isOffsetLinked: false,
        isRepeatLinked: false,
    })
    const mesh = highlights[0].mesh
    const material = mesh.material as MeshCustomMaterial
    const [heroImage, setHeroImage] = React.useState()
    const inputRef = React.useRef<HTMLInputElement>(null)
    if (!isFabricControlPanel) return <></>

    React.useEffect(() => {
        setHeroImage(captureImage(mesh, mapCode) || currentMap?.map)
    }, [selectedFabric])

    const clickedOutSideRef = useClickedOutside(() =>
        setIsFabricControlPanelActive(false)
    )

    const map = selectedFabric?.maps.find((map) => map.code === mapCode)

    function updateConfigs(
        value: number,
        key: 'u' | 'v',
        property: 'repeat' | 'offset' | 'rotation'
    ) {
        highlights.map((highlight) => {
            const mesh = highlight.mesh
            const material = mesh.material as MeshCustomMaterial
            if (!map) return
            const mapCode = map.code
            if (isApplyAll) {
                selectedFabric?.maps.map((map) => {
                    const mapCode = map.code
                    if (material[mapCode] && property !== 'rotation') {
                        material[mapCode][property][key === 'u' ? 'x' : 'y'] =
                            value
                    } else if (material[mapCode] && property === 'rotation') {
                        material[mapCode][property] = value
                    }
                })
                return
            } else {
                if (material[mapCode] && property !== 'rotation') {
                    material[mapCode][property][key === 'u' ? 'x' : 'y'] = value
                } else if (material[mapCode] && property === 'rotation') {
                    material[mapCode][property] = value
                }
            }
        })
        setSelectedFabric((fabric) => {
            if (!fabric) return fabric
            return {
                ...fabric,
                maps: fabric.maps.map((map) => {
                    if (isApplyAll) {
                        if (property === 'repeat') {
                            return {
                                ...map,
                                config: {
                                    ...map.config,
                                    ['scale']: {
                                        u: value,
                                        v: value,
                                    },
                                },
                            }
                        } else if (property === 'offset') {
                            return {
                                ...map,
                                config: {
                                    ...map.config,
                                    ['offset']: {
                                        u: value,
                                        v: value,
                                    },
                                },
                            }
                        } else {
                            return {
                                ...map,
                                config: {
                                    ...map.config,
                                    rotation: value,
                                },
                            }
                        }
                    } else {
                        if (map.code === map?.code) {
                            if (property === 'repeat') {
                                return {
                                    ...map,
                                    config: {
                                        ...map.config,
                                        ['scale']: isLinked.isRepeatLinked
                                            ? {
                                                  u: value,
                                                  v: value,
                                              }
                                            : {
                                                  ...map.config['scale'],
                                                  [key]: value,
                                              },
                                    },
                                }
                            } else if (property === 'offset') {
                                return {
                                    ...map,
                                    config: {
                                        ...map.config,
                                        ['offset']: isLinked.isOffsetLinked
                                            ? {
                                                  u: value,
                                                  v: value,
                                              }
                                            : {
                                                  ...map.config['offset'],
                                                  [key]: value,
                                              },
                                    },
                                }
                            } else {
                                return {
                                    ...map,
                                    config: {
                                        ...map.config,
                                        rotation: value,
                                    },
                                }
                            }
                        }
                    }

                    return map
                }),
            }
        })
    }

    return (
        <Box
            top="65px"
            right={advancedMode ? '370px' : '355px'}
            position="absolute"
            bg="white"
            width="310px"
            p="8px"
            px="0px"
            // height="100%"
            overflow={'auto'}
            className="scrollbar_none"
            borderRadius="4px"
            ref={clickedOutSideRef}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my="8px"
                px="8px"
            >
                <Text my="0px">{mapName}</Text>
                <Button
                    onClick={() => setIsFabricControlPanelActive(false)}
                    bg="transparent"
                    border="none"
                >
                    <CloseIco />
                </Button>
            </Box>
            <DivisionLine color="gray350" />
            <Box mt="4px" px="8px">
                <Image
                    className="img-clear"
                    width="100%"
                    height="100px"
                    src={heroImage}
                />
                <Box display="flex" alignItems="center" gridColumnGap="20px">
                    <Button
                        p="0px"
                        bg="transparent"
                        border="none"
                        onClick={() =>
                            inputRef.current &&
                            inputRef.current.click &&
                            inputRef.current.click()
                        }
                    >
                        <UploadFile />
                    </Button>
                    <Input
                        autoComplete="false"
                        type="file"
                        ref={inputRef}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ) => {}}
                        onClick={(e: any) => {
                            e.target.value = null
                        }}
                        display="none"
                        bg="none"
                    />

                    <Link
                        href={heroImage}
                        download={mapCode + '.png'}
                        bg="white"
                        style={{
                            border: 'none',
                            borderRadius: '4px',
                            padding: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                        className="download-link"
                    >
                        <Download />
                    </Link>
                    <Button
                        onClick={() => {}}
                        p="0px"
                        bg="transparent"
                        border="none"
                    >
                        <DeleteIco />
                    </Button>
                </Box>
            </Box>
            <DivisionLine color="gray350" />
            <Box px="8px" mb="8px">
                <Box mb="8px">
                    <Text fontSize="12px">Scale</Text>
                    <UVScale
                        maxValue={1}
                        minValue={-1}
                        sensitivity={0.001}
                        isLinkActive={isLinked.isRepeatLinked}
                        onLinkClick={() =>
                            setIsLinked({
                                isOffsetLinked: isLinked.isOffsetLinked,
                                isRepeatLinked: !isLinked.isRepeatLinked,
                            })
                        }
                        u={map?.config.scale.u || 0}
                        v={map?.config.scale.v || 0}
                        onChange={(value, key) => {
                            updateConfigs(value, key, 'repeat')
                        }}
                    />
                </Box>
                <DivisionLine color="gray350" />
                <Box>
                    <Text fontSize="12px">Offset</Text>
                    <UVScale
                        maxValue={1}
                        minValue={-1}
                        sensitivity={0.001}
                        isLinkActive={isLinked.isOffsetLinked}
                        onLinkClick={() =>
                            setIsLinked({
                                isOffsetLinked: !isLinked.isOffsetLinked,
                                isRepeatLinked: isLinked.isRepeatLinked,
                            })
                        }
                        u={map?.config.offset.u || 0}
                        v={map?.config.offset.v || 0}
                        onChange={(value, key) => {
                            updateConfigs(value, key, 'offset')
                        }}
                    />
                </Box>
            </Box>
            <DivisionLine color="gray350" />
            <Box px="8px" mt="8px">
                <Checkbox
                    label="Apply Changes to All Maps"
                    checked={isApplyAll}
                    onChange={(isApplied) => {
                        setIsApplyAll(isApplied)
                    }}
                />
            </Box>
        </Box>
    )
}
