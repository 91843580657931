import type { SetStateAction } from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Button } from 'src/components/utility'
import { roles } from 'src/helpers'

export const RoleDropDownActions = ({
    active,
    onDropDownClick,
    setActiveSort,
    activeSort,
    setIsDropdownActive,
}: {
    active: Boolean
    onDropDownClick: (value: string) => void
    setActiveSort: (
        active:
            | typeof roles.admin
            | typeof roles.manager
            | typeof roles.designer
    ) => void
    activeSort: string
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
}) => {
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="40px"
            left="0px"
            // right="0px"
        >
            <>
                <DropdownButtonWrapper
                    width="100%"
                    onClick={(x) => {
                        setActiveSort(roles.all)
                        onDropDownClick(x)
                    }}
                    value="All"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === roles.all ? '0.5' : '1'}
                    >
                        All
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    width="100%"
                    onClick={(x) => {
                        setActiveSort(roles.admin)
                        onDropDownClick(x)
                    }}
                    value="Admin"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === roles.admin ? '0.5' : '1'}
                    >
                        Admin
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort(roles.manager)
                        onDropDownClick(x)
                    }}
                    value="Manager"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === roles.manager ? '0.5' : '1'}
                    >
                        Manager
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort(roles.designer)
                        onDropDownClick(x)
                    }}
                    value="Designer"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === roles.designer ? '0.5' : '1'}
                    >
                        Designer
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
