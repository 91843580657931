import React, { useEffect, useState } from 'react'
import { Box, Button, Input } from 'src/components/utility'
import SubtodoSectionFieldsBar from './subtodo-section-fields-bar'
import FileField from './fields/files-field'
import ColorsField from './fields/colors-field'
import CircleIco from 'src/components/svg-icons/circle-ico'
import { useMutation } from '@apollo/client'
import {
    InsertSubtodoSection,
    UpdateSubtodoSection,
} from '../../../../services/graphql/entities/todo/todo.mutation'
import { debounce } from 'src/helpers'
import { DescriptionField } from './fields/description-field'
import { DeleteIco } from 'src/components/svg-icons'
import AddLinks from 'src/components-v2/add-links/add-links'
import ReferenceCircle from 'src/components-v2/reference-circle/reference-circle'
import CircleSelectedIco from 'src/components/svg-icons/circle-selected.ico'
import { useSearchParams } from 'react-router-dom'
import type { GetTodoQuery } from 'src/generated/graphql'
import { DangerModal } from 'src/components/modals/danger-modal'

function SubtodoSection({
    section,
    refetchTodo,
    isLastSection,
    subtodoId,
    isDeletable,
    isReadOnly,
    todoId,
    productId,
    referencePointIndex,
    onAssignReferencePointToSection,
    isTechPack,
    assignReferenceToSection,
    isFocussedView,
}: {
    section: NonNullable<
        GetTodoQuery['todos_by_pk']
    >['sub_todos'][number]['sub_todo_sections'][number]
    refetchTodo: () => void
    isLastSection: boolean
    subtodoId: number
    isDeletable: boolean
    isReadOnly?: boolean
    todoId: number
    isTechPack: boolean
    productId?: number
    referencePointIndex: number
    onAssignReferencePointToSection: () => void
    assignReferenceToSection: number | null
    isFocussedView?: boolean
}) {
    const [sectionData, setSectionData] = useState({
        name: section.name,
    })
    const [searchParams, setSearchParams] = useSearchParams()
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [timeOutRefForCreateSection, setTimeOutRefForCreateSection] =
        useState<ReturnType<typeof setTimeout>>()
    const [updateSubtodoSection] = useMutation(UpdateSubtodoSection)
    const [createSubtodoSection] = useMutation(InsertSubtodoSection)
    const [showDeleteSubtodoSectionModal, setShowDeleteSubtodoSectionModal] =
        useState(false)

    function updateSection({
        payload,
        sectionId,
    }: {
        payload: any
        sectionId: number
    }) {
        updateSubtodoSection({
            variables: {
                id: sectionId,
                payload,
            },
            onCompleted: () => {
                refetchTodo()
            },
        })
    }

    const debouncedUpdateSectionHandler = debounce(
        updateSection,
        1500,
        setTimeOutRef,
        timeOutRef
    )

    const createNewSubSection = debounce(
        () => {
            createSubtodoSection({
                variables: {
                    object: {
                        sub_todo_id: subtodoId,
                        todo_id: todoId,
                    },
                },
                onCompleted: () => {
                    refetchTodo()
                },
            })
        },
        1000,
        setTimeOutRefForCreateSection,
        timeOutRefForCreateSection
    )

    const handleSectionNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSectionData({
            name: e.target.value,
        })
        if (isLastSection) {
            createNewSubSection()
        }
        debouncedUpdateSectionHandler({
            payload: { name: e.target.value },
            sectionId: section.id,
        })
    }

    const sectionId = searchParams.get('section_id')

    // Scroll to the section if it is present in the URL
    useEffect(() => {
        if (sectionId) {
            const element =
                document.getElementById(
                    `subtodo_focused_section_${sectionId}`
                ) || document.getElementById(`subtodo_section_${sectionId}`)
            if (element) {
                element.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                })
                searchParams.delete('section_id')
                setSearchParams(searchParams, { replace: true })
            }
        }
    }, [])

    return (
        <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            width="100%"
            gridGap="8px"
            py="8px"
            id={
                isFocussedView
                    ? `subtodo_focused_section_${section.id}`
                    : `subtodo_section_${section.id}`
            }
        >
            {isTechPack &&
            !!section.reference_points &&
            section.reference_points.length > 0 ? (
                <ReferenceCircle
                    index={referencePointIndex}
                    referenceId={section.reference_points[0].id}
                    referencePoints={{
                        x: section.reference_points[0].coordinates[0],
                        y: section.reference_points[0].coordinates[1],
                    }}
                    refetchTodo={refetchTodo}
                    scalingFactor={{ x: 1, y: 1 }}
                    sectionId={section.id}
                    isAnnotationOnImage={false}
                />
            ) : (
                <Box
                    onClick={
                        isTechPack ? onAssignReferencePointToSection : undefined
                    }
                    className={isTechPack ? 'cursor-pointer' : ''}
                >
                    {assignReferenceToSection === section.id && isTechPack ? (
                        <CircleSelectedIco />
                    ) : (
                        <CircleIco />
                    )}
                </Box>
            )}
            <Box display="flex" flexDirection="column" gridGap="12px">
                <Box display="flex" justifyContent="space-between">
                    <Input
                        placeholder="Add detail"
                        bg="transparent"
                        border="none"
                        width="100%"
                        value={sectionData.name || ''}
                        onChange={handleSectionNameChange}
                        readOnly={isReadOnly}
                    />
                    {isDeletable && !isReadOnly && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowDeleteSubtodoSectionModal(true)
                            }}
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                        >
                            <DeleteIco />
                        </Button>
                    )}
                </Box>
                {section.description !== null && (
                    <DescriptionField
                        description={section.description || ''}
                        updateSectionDescription={(description) => {
                            debouncedUpdateSectionHandler({
                                payload: { description },
                                sectionId: section.id,
                            })
                        }}
                        ml="0px"
                        width="100%"
                        id={`description_${section.id}`}
                        isReadOnly={isReadOnly}
                    />
                )}
                {section.other_data?.files && (
                    <FileField
                        files={section.files}
                        sectionId={section.id}
                        refetchTodo={refetchTodo}
                        deleteSection={() =>
                            updateSection({
                                payload: {
                                    other_data: {
                                        files: null,
                                    },
                                },
                                sectionId: section.id,
                            })
                        }
                        isReadOnly={isReadOnly}
                        todoId={todoId}
                        productId={productId as number}
                        id={`files_${section.id}`}
                    />
                )}
                {section.colors && (
                    <ColorsField
                        colors={section.colors}
                        refetchTodo={refetchTodo}
                        sectionId={section.id}
                        deleteSection={() =>
                            updateSection({
                                payload: {
                                    colors: null,
                                },
                                sectionId: section.id,
                            })
                        }
                        id={`colors_${section.id}`}
                        isReadOnly={isReadOnly}
                    />
                )}
                {section.links && (
                    <AddLinks
                        links={section.links}
                        isEditPermitted={!isReadOnly}
                        deleteSection={() =>
                            updateSection({
                                payload: {
                                    links: null,
                                },
                                sectionId: section.id,
                            })
                        }
                        refetchTodo={refetchTodo}
                        sectionId={section.id}
                        id={`links_${section.id}`}
                    />
                )}
                {!isReadOnly && (
                    <SubtodoSectionFieldsBar
                        isColorsPresent={!!section.colors}
                        isLinksPresent={!!section.links}
                        isDescriptionPresent={section.description !== null}
                        isFilesPresent={!!section.other_data?.files}
                        subtodoSectionId={section.id}
                        refetchTodo={refetchTodo}
                        onClick={() => {
                            if (isLastSection) {
                                createNewSubSection()
                            }
                        }}
                        sectionId={section.id}
                    />
                )}
                {showDeleteSubtodoSectionModal && (
                    <DangerModal
                        onRemove={() => {
                            updateSection({
                                payload: {
                                    deleted_at: new Date().toISOString(),
                                },
                                sectionId: section.id,
                            })
                        }}
                        onClose={() => setShowDeleteSubtodoSectionModal(false)}
                        isModal={showDeleteSubtodoSectionModal}
                        heading={`Are you sure you want to delete sub-section ${
                            section?.name ? `"${section.name}"` : ''
                        }?`}
                        subHeading={
                            'Deleting this sub-section will permanently delete all the details within it. '
                        }
                        title={'Delete Sub-Section'}
                        primaryButtonText="Delete"
                        secondaryButtonText="Cancel"
                    />
                )}
            </Box>
        </Box>
    )
}

export default SubtodoSection
