import styled, { css } from 'styled-components'
import { Box } from 'src/components/utility'
import _theme from 'src/_theme'

const StyledDropdownBadge = styled(Box)`
    cursor: pointer;

    ${(props: any) => props.shouldHover && css`
        &:hover {
            background-color: ${_theme.colors.gray250};
        }
    `}
   
`
export default StyledDropdownBadge
