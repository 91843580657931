import create from 'zustand'

type Store = {
    activeAnnotationPoint: [number, number, number] | null
    isComparePanelVisible: boolean
    isTemporaryAnnotation: boolean
    activeCameraPosition: [number, number, number] | null
    setActiveAnnotationPoint: (
        activeAnnotationPoint: [number, number, number] | null
    ) => void
    setIsComparePanelVisible: (isComparePanelVisible: boolean) => void
    setIsTemporaryAnnotation: (isTemporaryAnnotation: boolean) => void
    setActiveCameraPosition: (
        activeCameraPosition: [number, number, number] | null
    ) => void
}

export const useAnnotationCommentsStore = create<Store>((set: Function) => ({
    activeAnnotationPoint: null,
    setActiveAnnotationPoint: (activeAnnotationPoint) =>
        set({ activeAnnotationPoint }),
    isComparePanelVisible: false,
    setIsComparePanelVisible: (isComparePanelVisible) =>
        set({ isComparePanelVisible }),
    isTemporaryAnnotation: false,
    setIsTemporaryAnnotation: (isTemporaryAnnotation) =>
        set({ isTemporaryAnnotation }),
    activeCameraPosition: null,
    setActiveCameraPosition: (activeCameraPosition) =>
        set({ activeCameraPosition }),
}))
