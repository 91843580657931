import { useState } from 'react'
import { NoCommentsIcon } from 'src/components/svg-icons/dashboard/no-comments'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Text } from 'src/components/utility'
import Skeleton from 'react-loading-skeleton'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'
import CommentCard from './cards/comment'
import { Slider } from 'src/components/slider/slider'
import CommentSlider from './comment-slider'

export const RecentCommentsList = () => {
    const [currentTab, setCurrentTab] = useState('all')

    const { comments, annotation, mention, all } = useCommentsStore()

    return (
        <>
            <Box display="flex" mb="12px" alignItems="center">
                <Box
                    display="flex"
                    alignItems="center"
                    className={comments?.length ? 'cursor-pointer' : ''}
                >
                    <Text fontSize={'16px'} my="4px" mr="12px">
                        {' '}
                        Recent Comments
                    </Text>
                    <Box>
                        <RightArrow />
                    </Box>
                </Box>
            </Box>
            <Box bg="white">
                <Box
                    py={'16px'}
                    bg={'white'}
                    width="100%"
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    height="100%"
                >
                    <Box px="16px">
                        <Slider
                            element={
                                <CommentSlider
                                    commentsCounts={{
                                        annotation: annotation.length || 0,
                                        mentions: mention.length || 0,
                                        total: all.length || 0,
                                    }}
                                    currentTab={currentTab}
                                    setCurrentTab={(val) => setCurrentTab(val)}
                                />
                            }
                        />
                    </Box>
                    {!comments && (
                        <Box position="relative" width="100%" height="100%">
                            <Skeleton
                                height="32px"
                                count={6}
                                containerClassName="loader"
                            />
                        </Box>
                    )}

                    <Box
                        className="scrollbar_none"
                        overflowY={'auto'}
                        height={'500px'}
                    >
                        {currentTab === 'all' &&
                            all.length > 0 &&
                            all.map((item, index: number) => {
                                if (!item) return
                                return (
                                    <CommentCard
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {currentTab === 'mentions' &&
                            mention.length > 0 &&
                            mention.map((item, index: number) => {
                                if (!item) return
                                return (
                                    <CommentCard
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {currentTab === 'annotations' &&
                            annotation.length > 0 &&
                            annotation.map((item, index: number) => {
                                if (!item) return
                                return (
                                    <CommentCard
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {all.length <= 0 ? (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minHeight="100%"
                                width="100%"
                                textAlign="center"
                            >
                                <Box>
                                    <NoCommentsIcon />
                                    <Text
                                        fontSize="14px"
                                        color="textSecondaryDarkBlue"
                                    >
                                        {' '}
                                        No comments yet!
                                    </Text>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
