import React from 'react'

function InReviewIco({ title }: { title?: string }) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {title && <title>{title}</title>}
            <path
                d="M3.87187 9.6H5.45187L10.4919 4.62C10.5719 4.54 10.6119 4.44 10.6119 4.32C10.6119 4.2 10.5719 4.1 10.4919 4.02L9.39188 3.02C9.32521 2.95333 9.24187 2.92 9.14188 2.92C9.04187 2.92 8.95854 2.95333 8.89188 3.02L3.87187 8.12V9.6ZM7.17188 9.6H13.4719V8.4H8.37187L7.17188 9.6ZM0.671875 16V1.2C0.671875 0.88 0.791875 0.6 1.03187 0.36C1.27187 0.12 1.55187 0 1.87187 0H15.4719C15.7919 0 16.0719 0.12 16.3119 0.36C16.5519 0.6 16.6719 0.88 16.6719 1.2V11.6C16.6719 11.92 16.5519 12.2 16.3119 12.44C16.0719 12.68 15.7919 12.8 15.4719 12.8H3.87187L0.671875 16ZM3.35187 11.6H15.4719V1.2H1.87187V13.2L3.35187 11.6Z"
                fill="#088981"
            />
        </svg>
    )
}

export default InReviewIco
