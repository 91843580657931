export function checkColorFormat(input: string): 'Hex' | 'RGB' | null {
    if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(input)) {
        return 'Hex'
    } else if (
        /^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3})(,(\d{1}|0?\.\d+))?\)$/.test(
            input
        )
    ) {
        return 'RGB'
    } else {
        console.error('Invalid input:', input)
        return null
    }
}
export function convertColor(input: string): {
    hex: string
    rgb: { r: number; g: number; b: number }
} {
    let hex = '#ffffff'
    let rgb = { r: 0, g: 0, b: 0 }

    // Remove all whitespace from input
    input = input.replace(/\s/g, '')

    // Check if input is in hex format
    if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(input)) {
        // Convert hex to rgb
        const tempHex = input.replace('#', '')
        const r = parseInt(tempHex.substring(0, 2), 16)
        const g = parseInt(tempHex.substring(2, 4), 16)
        const b = parseInt(tempHex.substring(4, 6), 16)
        rgb = { r, g, b }
        hex = input
    } else if (
        /^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3})(,(\d{1}|0?\.\d+))?\)$/.test(
            input
        )
    ) {
        // Convert rgba to hex and rgb
        const rgbaValues = input
            .substring(input.indexOf('(') + 1, input.lastIndexOf(')'))
            .split(',')
        if (rgbaValues.length >= 3) {
            const r = parseInt(rgbaValues[0])
            const g = parseInt(rgbaValues[1])
            const b = parseInt(rgbaValues[2])
            hex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
                .toString(16)
                .slice(1)}`
            rgb = { r, g, b }
        }
    } else {
        console.error('Invalid input:', input)
    }

    return { hex, rgb }
}
