/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react'
import {
    RecentActivityEmptyStateIco,
    RecentActivityIco,
    StrokedArrow,
} from '../svg-icons'
import { Box, Button, Text } from '../utility'
import { ActivityFeed } from './activity-feed'

export const RecentActivity = ({ activity }: { activity?: Activity[] }) => {
    const [activityPosition, setActivityPosition] = useState('0px')
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const recentActivity = useRef<any>()

    function recentActivityClick(e: Event) {
        if (recentActivity && recentActivity.current) {
            const isClickInsideElement = recentActivity.current.contains(
                e.target
            )
            if (!isClickInsideElement) {
                setActivityPosition('0px')
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', recentActivityClick)
        window.addEventListener('resize', (e) => {
            setWindowSize(window.innerWidth)
        })
    }, [])

    if (windowSize <= 1460) {
        return (
            <Box
                position="absolute"
                top="0px"
                right="0px"
                zIndex={5}
                width={activityPosition === '290px' ? '100vw' : '0vw'}
                className={activityPosition === '290px' ? 'bg-black' : ''}
                height="100%"
                bg={`#00000000`}
            >
                {
                    <Button
                        border="none"
                        onClick={() =>
                            setActivityPosition((activityPosition) =>
                                activityPosition === '0px' ? '-15%' : '0px'
                            )
                        }
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p="16px"
                        bg="panelBackground"
                        borderRadius="8px"
                        position="absolute"
                        right="0px"
                        top="90px"
                    >
                        <RecentActivityIco />
                    </Button>
                }

                <Box
                    height="100%"
                    px={activityPosition === '0px' ? '0px' : '24px'}
                    pt={'36px'}
                    width={activityPosition}
                    bg="panelBackground"
                    position="absolute"
                    right="0px"
                    top="61px"
                    fontFamily="Rubik"
                    ref={recentActivity}
                    className="transition-5"
                    pb="36px"
                >
                    <Box>
                        <Button
                            border="none"
                            onClick={() =>
                                setActivityPosition((activityPosition) =>
                                    activityPosition === '0px' ? '290px' : '0px'
                                )
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p="16px"
                            bg="panelBackground"
                            borderRadius="8px"
                            position="absolute"
                            left="-45px"
                            top="25px"
                        >
                            <RecentActivityIco />
                        </Button>
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            justifyContent="start"
                            alignItems="center"
                        >
                            <RecentActivityIco />
                            <Text
                                display="block"
                                fontWeight={500}
                                color="primary"
                            >
                                Recent Activity
                            </Text>
                            <StrokedArrow />
                        </Box>
                    </Box>
                    {activity && activity?.length > 0 && (
                        <Box
                            pb="36px"
                            height="80%"
                            className="scrollbar_none recent-activity"
                            overflowY={'scroll'}
                            mt="12px"
                            display="flex"
                            flexDirection="column"
                            gridRowGap="16px"
                        >
                            <ActivityFeed activity={activity} />
                        </Box>
                    )}
                    {(!activity || activity?.length <= 0) && (
                        <Box
                            minHeight="50%"
                            overflow="hidden"
                            display=" flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <Box
                                display="flex"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <RecentActivityEmptyStateIco />
                                <Text
                                    fontSize="14px"
                                    color="textSecondaryDarkBlue"
                                >
                                    No recorded activity!
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }
    return (
        <Box
            maxWidth="290px"
            height="100%"
            px="24px"
            pt="36px"
            width="290px"
            bg="panelBackground"
            position="absolute"
            right="0px"
            top="61px"
            fontFamily="Rubik"
        >
            <Box
                display="flex"
                gridColumnGap="12px"
                justifyContent="start"
                alignItems="center"
            >
                <RecentActivityIco />
                <Text fontWeight={500} color="primary">
                    Recent Activity
                </Text>
                <StrokedArrow />
            </Box>
            {activity && activity?.length > 0 && (
                <Box
                    className="scrollbar_none recent-activity"
                    mt="12px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="16px"
                >
                    <ActivityFeed activity={activity} />
                </Box>
            )}
            {(!activity || activity?.length <= 0) && (
                <Box
                    minHeight="50%"
                    overflow="hidden"
                    display=" flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        overflow="hidden"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <RecentActivityEmptyStateIco />
                        <Text fontSize="14px" color="textSecondaryDarkBlue">
                            No recorded activity!
                        </Text>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

// function ActivityFeed({ activity }: { activity: Activity[] }) {
//     return <>{activity.map((feed, key: number) => {
//         const distance = formatDistance(subDays(new Date(feed.created_at), 0), new Date(), { addSuffix: true })
//         const _time = format(new Date(feed.created_at), 'hh:mm a')
//         return <Box key={key} display='flex' gridColumnGap="12px">
//             <ProfileImg
//                 src={feed.user && feed.user.other_data && feed.user?.other_data?.profile_pic ? getImageUrl(feed.user.other_data.profile_pic) : ""}
//                 name={feed.user.name} size='24px' />
//             <Box display='flex' alignItems='center' justifyContent='start'>
//                 <Text my="0px" fontSize="12px" lineHeight="1.5" color="primary">
//                     <NotificationParser comment={feed.display_string} />
//                     <span style={{ color: "#817E7E", fontSize: "12px", marginLeft: "4px" }}> {_time.trim()}, {distance} </span>
//                 </Text>
//             </Box>
//         </Box>
//     })}</>
// }
