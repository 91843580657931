import React from 'react'

function CircleCross({
    bg = '#FF2C18',
    color = 'white',
}: {
    bg?: string
    color?: string
}) {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7306 2.5C6.52489 2.5 3.10303 5.86463 3.10303 10C3.10303 14.1354 6.52489 17.5 10.7306 17.5C14.9363 17.5 18.3582 14.1354 18.3582 10C18.3582 5.86463 14.9363 2.5 10.7306 2.5Z"
                fill={bg}
            />
            <path
                d="M8.92649 7.39595C8.69639 7.1697 8.32739 7.1697 8.09729 7.39595C7.86163 7.62767 7.86163 8.00755 8.09729 8.23926L9.88705 9.9991L8.09729 11.7589C7.86163 11.9906 7.86163 12.3705 8.09729 12.6022C8.32739 12.8285 8.69639 12.8285 8.92649 12.6022L10.7305 10.8284L12.5345 12.6022C12.7646 12.8285 13.1336 12.8285 13.3637 12.6022C13.5993 12.3705 13.5993 11.9906 13.3637 11.7589L11.5739 9.9991L13.3637 8.23926C13.5993 8.00755 13.5993 7.62767 13.3637 7.39595C13.1336 7.1697 12.7646 7.1697 12.5345 7.39595L10.7305 9.16977L8.92649 7.39595Z"
                fill={color}
            />
        </svg>
    )
}

export default CircleCross
