import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'
import { debounce } from 'src/helpers'
import TemplatesSection from './templates-sections'
import { TodoTypeDropdown } from '../save-template/todo-type-dropdown'
import { TodoCategoryDropdown } from '../save-template/todo-category-dropdown'
import { InsertSubtodo } from 'src/services/graphql/entities/todo/todo.mutation'

function ChooseTemplate({
    todoId,
    refetchTodo,
}: {
    todoId: number
    refetchTodo: () => void
}) {
    const [addSubtodo, { loading }] = useMutation(InsertSubtodo)

    const [searchString, setSearchString] = useState('')
    const [activeFilters, setActiveFilters] = useState<{
        type: { id: number | null; type: string }
        category: { id: number | null; name: string }
        searchQuery: string
    }>({
        type: { id: null, type: '' },
        category: { id: null, name: '' },
        searchQuery: '%%',
    })
    const [dropdownVisibility, setDropdownVisibility] = useState({
        type: false,
        category: false,
    })
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>()

    function chooseDefaultTemplateHandler(todoId: number) {
        const object = {
            todo_id: todoId,
            heading: '',
            design_color: '#D6E0EA',
            sub_todo_sections: {
                data: [
                    {
                        name: '',
                        todo_id: todoId,
                    },
                ],
            },
            sub_todo_sequences: {
                data: [{ sequence: 0 }],
            },
        }
        addSubtodo({
            variables: {
                object,
            },
            onCompleted: () => {
                toast('Assigned default template successfully', {
                    className: 'custom-toaster toaster-success',
                })
                refetchTodo()
            },
            onError: () => {
                toast('Error occurred while creating subtodo', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }

    const debouncedSearch = debounce(
        (text: string) => {
            setActiveFilters((prev) => ({ ...prev, searchQuery: `%${text}%` }))
        },
        500,
        setTimeoutRef,
        timeoutRef
    )

    function searchHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchString(e.target.value)
        debouncedSearch(e.target.value)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gridGap="24px"
            py="12px"
            style={{ borderRadius: '12px 12px 0px 0px' }}
        >
            <Box display="flex" flexDirection="column" gridGap="16px">
                <Box borderBottom="1px solid #DDDDDD" py="16px">
                    <Text fontWeight={500} fontSize="14px" my="0px">
                        Choose how you want to add details
                    </Text>
                </Box>
                <ColoredButton
                    variant="primary"
                    onClick={() => chooseDefaultTemplateHandler(todoId)}
                    width="max-content"
                    loader={loading}
                    disabled={loading}
                >
                    Choose Default Template
                </ColoredButton>
            </Box>
            <Box display="flex" flexDirection="column" gridGap="8px">
                <Text my="0px" color="#606060" fontSize="14px">
                    Or, choose from pre-saved templates,
                </Text>
                <Box
                    display="flex"
                    gridGap="8px"
                    borderRadius="4px"
                    bg="white"
                    border="1px solid #DDDDDD"
                    py="8px"
                    px="12px"
                    width="80%"
                    alignItems="center"
                >
                    <SearchIco />
                    <Input
                        placeholder="Search templates"
                        border="none"
                        bg="transparent"
                        value={searchString}
                        onChange={searchHandler}
                        width="100%"
                    />
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setSearchString('')
                            debouncedSearch('')
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                    >
                        <CrossSm />
                    </Button>
                </Box>
                <Box display="flex" gridGap="8px" alignItems="center">
                    <Box position="relative" width="max-content">
                        <IconDropdownButton
                            onClick={() =>
                                setDropdownVisibility({
                                    ...dropdownVisibility,
                                    type: !dropdownVisibility.type,
                                })
                            }
                            isActive={!!activeFilters.type?.id}
                            width="max-content"
                            maxWidth="240px"
                            id="todo-type-dropdown-button"
                        >
                            <Text
                                my="0px"
                                maxWidth="200px"
                                overflow="hidden"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                id="todo-type-dropdown-text"
                            >
                                To-do Type: {activeFilters.type?.type || ''}
                            </Text>
                        </IconDropdownButton>
                        {dropdownVisibility.type && (
                            <TodoTypeDropdown
                                active={dropdownVisibility.type}
                                onDropDownClick={({ id, type }) => {
                                    setActiveFilters({
                                        ...activeFilters,
                                        type: {
                                            id: id,
                                            type: type,
                                        },
                                        category: {
                                            id: null,
                                            name: '',
                                        },
                                    })
                                    setDropdownVisibility({
                                        ...dropdownVisibility,
                                        type: false,
                                    })
                                }}
                                setIsDropdownActive={
                                    // @ts-ignore
                                    (value) => setDropdownVisibility(value)
                                }
                                showOthers={false}
                            />
                        )}
                    </Box>
                    {activeFilters.type?.id && (
                        <Box position="relative" width="max-content">
                            <IconDropdownButton
                                onClick={() =>
                                    setDropdownVisibility({
                                        ...dropdownVisibility,
                                        category: !dropdownVisibility.category,
                                    })
                                }
                                isActive={!!activeFilters.category?.id}
                                width="max-content"
                                maxWidth="240px"
                                id="todo-category-dropdown-button"
                            >
                                <Text
                                    my="0px"
                                    maxWidth="200px"
                                    overflow="hidden"
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                    id="todo-category-dropdown-button"
                                >
                                    Category:{' '}
                                    {activeFilters.category?.name || ''}
                                </Text>
                            </IconDropdownButton>
                            {dropdownVisibility.category && (
                                <TodoCategoryDropdown
                                    active={dropdownVisibility.category}
                                    onDropDownClick={(category) => {
                                        setActiveFilters({
                                            ...activeFilters,
                                            category: {
                                                id: category.id,
                                                name: category.name,
                                            },
                                        })
                                        setDropdownVisibility({
                                            ...dropdownVisibility,
                                            category: false,
                                        })
                                    }}
                                    setIsDropdownActive={(value) =>
                                        setDropdownVisibility({
                                            ...dropdownVisibility,
                                            // @ts-ignore
                                            category: value,
                                        })
                                    }
                                    showOthers={false}
                                    templateTypeId={activeFilters.type.id}
                                />
                            )}
                        </Box>
                    )}
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        onClick={() => {
                            setActiveFilters({
                                ...activeFilters,
                                type: {
                                    id: null,
                                    type: '',
                                },
                                category: {
                                    id: null,
                                    name: '',
                                },
                            })
                        }}
                    >
                        Reset Filter
                    </Button>
                </Box>
            </Box>
            {/* !TODO: Add favourite templates section once backend is ready */}
            {/* <FavouriteTemplatesSection /> */}
            <TemplatesSection
                todoId={todoId}
                refetchTodo={refetchTodo}
                activeFilters={activeFilters}
            />
        </Box>
    )
}

export default ChooseTemplate
