import { useState } from 'react'
import { Loader } from '../loader/loader'
import { Box } from '../utility'
import { ButtonVariants } from './button-variants'
import { handleRipple } from './helpers/ripples'

export const ColoredButton = ({
    variant,
    loader,
    onClick,
    children,
    width,
    height = '100%',
    disabled = false,
    type = 'submit',
    borderRadius = '4px',
    id = 'colored-button',
    style = { alt: '' },
}: Button) => {
    const [ripples, setRipples] = useState<Ripple[]>([])
    return (
        <ButtonVariants
            variant={variant}
            onMouseDown={(e: Event) => handleRipple(e, setRipples)}
            onClick={
                onClick as (e?: React.MouseEvent<HTMLButtonElement>) => void
            }
            width={width}
            type={type}
            height={height}
            disabled={disabled}
            textAlign="center"
            borderRadius={borderRadius}
            className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
            id={id}
        >
            <Box
                style={style}
                id="colored-btn"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridColumnGap="8px"
            >
                {' '}
                {loader && <Loader />} {children}{' '}
            </Box>

            {ripples.map(({ left, right }, key) => {
                return (
                    <span
                        key={key}
                        style={{
                            left: left,
                            right: right,
                        }}
                    ></span>
                )
            })}
        </ButtonVariants>
    )
}
