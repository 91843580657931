import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { DivisionLine } from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Text } from 'src/components/utility'
import { GetDueCollections } from 'src/services'
import { EmptyStateCollection } from './empty-state'
import type { DueCollections } from '../../@types/dashboard'
import CollectionCard from 'src/components-v2/cards/collection/collection.card'
import type { statuses } from 'src/helpers'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'

export const DueCollectionsList = ({
    boxHeight,
    role,
}: {
    boxHeight: string
    role: string
}) => {
    const [getCollections, dueCollections] = useLazyQuery(GetDueCollections)
    const [collection, setCollections] = useState<DueCollections[]>([])
    const navigate = useNavigate()
    const { updateCollection } = useUpdateCollection()

    useEffect(() => {
        if (
            dueCollections &&
            dueCollections.data &&
            dueCollections.data.collections
        ) {
            setCollections(dueCollections.data.collections)
        }
    }, [dueCollections])

    useEffect(() => {
        const today = new Date()
        getCollections({
            variables: {
                due: today,
            },
        })
    }, [])

    return (
        <>
            <Box
                display="flex"
                mb="12px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    className="pointer"
                    onClick={() => {
                        navigate('/collections?group=collections')
                    }}
                >
                    <Text fontSize={'16px'} my="0px" mr="12px">
                        {' '}
                        Collections - Due Soon
                    </Text>
                    <RightArrow />
                </Box>
            </Box>

            <BasicCard>
                <>
                    {collection?.length ? (
                        <Box
                            className="scrollbar_none"
                            height={boxHeight}
                            pb={'16px'}
                            px={'16px'}
                            overflowY={'scroll'}
                        >
                            {collection.map(
                                (item: DueCollections, index: number) => {
                                    return (
                                        <Box pt="12px" key={item.id}>
                                            {
                                                <>
                                                    <CollectionCard
                                                        dashboardView={true}
                                                        updateDate={(date) =>
                                                            updateCollection(
                                                                Number(item.id),
                                                                {
                                                                    due_date:
                                                                        new Date(
                                                                            date
                                                                        ).toISOString(),
                                                                },
                                                                [
                                                                    'GetDueCollections',
                                                                ]
                                                            )
                                                        }
                                                        updateStatus={(
                                                            status
                                                        ) =>
                                                            updateCollection(
                                                                Number(item.id),
                                                                {
                                                                    status: status,
                                                                },
                                                                [
                                                                    'GetDueCollections',
                                                                ]
                                                            )
                                                        }
                                                        entity={{
                                                            due_date: new Date(
                                                                item.due_date
                                                            ),
                                                            id: item.id,
                                                            manager: {
                                                                email: item
                                                                    ?.manager
                                                                    ?.email,
                                                                id: String(
                                                                    item
                                                                        ?.manager
                                                                        ?.id
                                                                ),
                                                                name: item
                                                                    ?.manager
                                                                    ?.name,
                                                                other_data: {
                                                                    profile_pic:
                                                                        item
                                                                            ?.manager
                                                                            ?.other_data
                                                                            ?.profile_pic,
                                                                    thumbnail:
                                                                        item
                                                                            ?.manager
                                                                            ?.other_data
                                                                            ?.thumbnail,
                                                                },
                                                            },
                                                            status: item.status as (typeof statuses)[keyof typeof statuses],
                                                            name: item.name,
                                                            products: {
                                                                totalCompleted:
                                                                    item
                                                                        .completedProducts
                                                                        .aggregate
                                                                        .count,
                                                                delayed:
                                                                    item
                                                                        .delayedProducts
                                                                        .aggregate
                                                                        .count,
                                                                total: item
                                                                    .products_aggregate
                                                                    .aggregate
                                                                    .count,
                                                                complete:
                                                                    item
                                                                        .completed
                                                                        .aggregate
                                                                        .count,
                                                            },
                                                            tags: item.tags,
                                                            team: item.team,
                                                            team_id:
                                                                item.team_id,
                                                            totalCompleted:
                                                                item.completedProducts,
                                                            totalProducts:
                                                                item.products_aggregate,
                                                        }}
                                                    />
                                                    {collection.length - 1 !==
                                                    index ? (
                                                        <DivisionLine />
                                                    ) : null}
                                                </>
                                            }
                                        </Box>
                                    )
                                }
                            )}
                        </Box>
                    ) : dueCollections.loading ? (
                        <Box height={boxHeight} position="relative" p={'16px'}>
                            <Box
                                position="relative"
                                width="100%"
                                height={'100%'}
                            >
                                <Skeleton
                                    height="48px"
                                    count={6}
                                    containerClassName="loader"
                                />
                            </Box>
                        </Box>
                    ) : (
                        <EmptyStateCollection
                            role={role}
                            boxHeight={boxHeight}
                        />
                    )}
                </>
            </BasicCard>
        </>
    )
}
