import { useEffect, useState } from 'react'
import { Box, Text, Button } from 'src/components/utility'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { CloseIco } from '../svg-icons'
import useStore from 'src/store/hooks/useStore'

export const DangerModal = ({
    isModal,
    heading,
    subHeading,
    title,
    primaryButtonText,
    secondaryButtonText,
    onRemove,
    onClose,
}: {
    isModal: boolean
    heading: string
    subHeading: string
    title: string
    primaryButtonText: string
    secondaryButtonText: string
    onRemove: () => void
    onClose?: () => void
}) => {
    const [isOpen, setIsOpen] = useState<string>('')
    const [isVisible, setIsVisible] = useState<string>('none')
    const { setIsModal } = useStore()
    const { name } = useStore((state) => {
        return { name: state.name, id: state.id }
    })

    function handleClose(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        setIsOpen('close-modal')
        setTimeout(() => {
            setIsVisible('none')
            setIsModal({ isModalOpen: false, name: '', id: '' })
            onClose && onClose()
        }, 100)
    }

    useEffect(() => {
        if (isModal) {
            setIsOpen('')
            setIsVisible('flex')
        }
    }, [isModal])

    function handleRemove(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        onRemove()
        handleClose(e)
    }

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            display={isVisible}
            bg="#02214370"
            justifyContent="center"
            alignItems="center"
            zIndex={10}
            className={isOpen}
        >
            <Box width="600px" bg="backgroundColor" borderRadius="4px">
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="solid"
                    borderColor="gray250"
                    borderWidth={1}
                    bg="white"
                >
                    <Text
                        fontSize="20px"
                        ml="24px"
                        fontFamily="Rubik"
                        color="primary"
                    >
                        {title}
                    </Text>
                    <Button
                        mr="24px"
                        bg="transparent"
                        border="none"
                        color="primary"
                        onClick={handleClose}
                    >
                        <CloseIco color="#022143" />
                    </Button>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    gridRowGap="8px"
                    justifyContent="start"
                    alignItems="start"
                    borderBottom="solid"
                    borderColor="gray250"
                    borderWidth={1}
                    pb="64px"
                >
                    <Text
                        fontSize="20px"
                        ml="24px"
                        mr="32px"
                        fontFamily="Rubik"
                        color="primary"
                    >
                        {heading.replace('{name}', `'${name}'`)}
                    </Text>
                    <Text
                        color="textSecondary"
                        my="0px"
                        ml="24px"
                        mr="32px"
                        textAlign="start"
                        fontFamily="Rubik"
                    >
                        {subHeading}
                    </Text>
                </Box>
                <Box ml="24px" py="12px" display="flex" gridColumnGap="16px">
                    <ColoredButton onClick={handleRemove} variant="colored">
                        {primaryButtonText}
                    </ColoredButton>
                    <ColoredButton onClick={handleClose} variant="secondary">
                        {secondaryButtonText}
                    </ColoredButton>
                </Box>
            </Box>
        </Box>
    )
}
