import * as React from 'react'
import Teams from './tab-data/teams'
import Collaborators from './tab-data/collaborators'
import Analytics from './tab-data/analytics'
import { useSearchParams } from 'react-router-dom'

export interface IOrgEntityListProps {
    activeTab: string
    isLoading: boolean
    contributorsTotalCount: number
    entityOnLoad: ({
        teams,
        collaborators,
    }: {
        teams: { count: number; name: string }
        collaborators: { count: number; name: string }
    }) => void
}

function OrganisationEntityList(props: IOrgEntityListProps, ref: any) {
    const [searchParam, _] = useSearchParams()
    const [fetchCb, setFetchCb] = React.useState<null | Function>(null)

    const [entity, setEntity] = React.useState({
        teams: {
            count: 0,
            name: 'Collection Teams',
        },
        collaborators: {
            count: 0,
            name: 'Collaborators',
        },
    })

    React.useImperativeHandle(ref, () => ({
        fetchCb,
    }))

    React.useEffect(() => {
        props.entityOnLoad(entity)
    }, [entity])

    switch (props.activeTab) {
        case 'teams':
            return (
                <Teams
                    onLoad={(count, refetch) => {
                        setFetchCb(() => (q: string) => refetch(q))
                        setEntity((entity) => ({
                            ...entity,
                            teams: { count, name: 'Collection Teams' },
                        }))
                    }}
                />
            )
        case 'collaborators':
            return (
                <Collaborators
                    totalCount={props.contributorsTotalCount}
                    onLoad={(count) => {
                        setEntity((entity) => ({
                            ...entity,
                            collaborators: { count, name: 'Collaborators' },
                        }))
                    }}
                />
            )
        case 'analytics':
            return <Analytics />
    }
}

// create a forward ref of this component
export default React.forwardRef(OrganisationEntityList)
