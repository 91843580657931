import { useApolloClient, useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { DivisionLine } from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { CloseIconRed } from 'src/components/svg-icons/library/close-ico-red'
import { GoBackLibrary } from 'src/components/svg-icons/library/go-back'
import { Box, Button, Span, Text } from 'src/components/utility'
import { getLocalStorage, uuid } from 'src/helpers'
import { LibraryPerPage } from 'src/helpers/enums'
import { ASSIGN_FILE_FROM_LIBRARY } from 'src/services'
import {
    FetchAllCategoryFiles,
    FetchAllFolders,
} from 'src/services/graphql/query/library.queries'
import useStore from 'src/store/hooks/useStore'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { Actions } from './sub-screens/actions'
import AppliedFiltersTags from './sub-screens/applied-filters-tags'
import EmptyFolderView from './sub-screens/empty-folder-view'
import { FileCard } from './sub-screens/file-card'
import { FolderFilesView } from './sub-screens/folders-files-view'
import { useCreateTodoFormStore } from 'src/store/hooks/useCreateTodoFormStore'
import { GetTodo } from 'src/services/graphql/entities/todo/todo.queries'
import { appendSearchParamsToUrl } from 'src/helpers/append-search-param-to-url'
;(window as any).global = window
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer

export const SelectLibraryFiles = () => {
    const params = useParams()
    const folderId = params?.folderId
    const entityName = params.entityName
    const entityId = params.entityId

    const navigate = useNavigate()
    const location = useLocation()
    const [locationState] = useState<{
        from: string
        id: number
        action: string
        showDrawer?: boolean
    }>(location.state)

    const [searchParams, setSearchParams] = useSearchParams()
    const [listView, setListView] = useState(false)
    const [attaching, setAttaching] = useState(false)
    const client = useApolloClient()

    const [currentPage, setCurrentPage] = useState(1)
    // const [foldersList, setFoldersList] = useState([])
    const [fetchAllFoldersList, allFoldersListData] =
        useLazyQuery(FetchAllFolders)
    // const [fetchFiles, filesListData] = useLazyQuery(FetchFilesByFolderId)
    const [fetchFiles, filesListData] = useLazyQuery(FetchAllCategoryFiles)
    const [filterObj, setFilterObj] = useState<any>({})
    const [selectedFiles, setSelectedFiles] = useState<any>({})

    const searchStr = searchParams.get(libraryDefaultKeys.searchStr)
    const sortStr = searchParams.get(libraryDefaultKeys.sort)
    const parentTodoId = searchParams.get('parentTodoId')

    const filtersHash = searchParams.get(libraryDefaultKeys.filters) || ''
    const filesList = filesListData?.data?.library_files || []
    const categoryfilesList: any[] = []
    const foldersList: any[] =
        allFoldersListData.data && allFoldersListData.data.folders
            ? allFoldersListData.data.folders
            : []
    const filesCount: number =
        filesList?.data?.library_files_aggregate.aggregate.count || 0

    const setFiltersProperly = () => {
        let finalFilters: any = ''
        if (filtersHash) {
            const buff = Buffer.from(filtersHash, 'base64')
            const preFilters = buff.toString('ascii')
            if (preFilters) {
                finalFilters = JSON.parse(preFilters)
                setFilterObj(finalFilters)
            } else {
                setFilterObj({})
            }
        } else {
            setFilterObj({})
        }
    }

    useEffect(() => {
        setFiltersProperly()
    }, [filtersHash])

    useEffect(() => {
        if (searchParams.has(libraryDefaultKeys.searchStr)) {
            searchParams.delete(libraryDefaultKeys.searchStr)
            setSearchParams(searchParams, { replace: true })
        }
    }, [])

    const onInit = () => {
        getFilesData()
        fetchAllFolders()
    }

    useEffect(() => {
        onInit()
    }, [folderId, searchStr, sortStr, filterObj, currentPage])

    const getFilesData = () => {
        const common = {
            searchStr: searchStr ? `%${searchStr}%` : '%%',
            name: sortStr === libraryDefaultKeys.name ? 'asc_nulls_last' : null,
            created_at:
                !sortStr ||
                sortStr === '' ||
                sortStr === libraryDefaultKeys.dateCreated
                    ? 'desc'
                    : null,
        }

        let whereObjFiles: any = {
            name: { _ilike: common.searchStr },
            deleted_at: { _is_null: true },
            file_type: { _eq: 'document' },
        }

        if (
            (folderId && folderId !== '0') ||
            filterObj?.selectedFolders?.length
        ) {
            if (folderId && folderId !== '0') {
                whereObjFiles.folder_id = {
                    _eq: folderId,
                }
            }
            if (filterObj?.selectedFolders?.length) {
                whereObjFiles['folder_id'] = {
                    _in: filterObj.selectedFolders,
                }
            }
        } else {
            // whereObjFiles.category_id = { _is_null: false };
            // whereObjFiles.folder_id = { _is_null: true }
        }

        if (filterObj?.selectedCreators?.length) {
            if (!whereObjFiles['creator']) {
                whereObjFiles['creator'] = {}
            }
            whereObjFiles['creator']['id'] = {
                _in: filterObj.selectedCreators,
            }
        }

        if (filterObj?.selectedColors?.length) {
            whereObjFiles['color'] = {
                _in: filterObj.selectedColors,
            }
        }

        if (filterObj?.selectedCompanies?.length) {
            whereObjFiles['company'] = {
                _in: filterObj.selectedCompanies,
            }
        }

        if (filterObj?.selectedTags?.length) {
            if (!whereObjFiles['tag_relations']) {
                whereObjFiles['tag_relations'] = {}
            }
            whereObjFiles['tag_relations']['tag_id'] = {
                _in: filterObj?.selectedTags,
            }
        }

        if (
            !searchStr &&
            !whereObjFiles['folder_id'] &&
            !whereObjFiles['tag_relations'] &&
            !whereObjFiles['company'] &&
            !whereObjFiles['color'] &&
            !whereObjFiles['creator']
        ) {
            whereObjFiles.folder_id = { _is_null: true }
        }

        fetchFiles({
            variables: {
                name: common.name,
                created_at: common.created_at,
                limit: currentPage * LibraryPerPage,
                whereObj: whereObjFiles,
            },
            fetchPolicy: 'cache-and-network',
        })
    }

    const fetchAllFolders = () => {
        const common = {
            searchStr: searchStr ? `%${searchStr}%` : '%%',
            name: sortStr === libraryDefaultKeys.name ? 'asc_nulls_last' : null,
            created_at:
                !sortStr ||
                sortStr === '' ||
                sortStr === libraryDefaultKeys.dateCreated
                    ? 'desc'
                    : null,
        }

        const whereObj: any = {
            name: { _ilike: common.searchStr },
            deleted_at: { _is_null: true },
        }

        if (folderId && folderId !== '0') {
            whereObj.parent_id = {
                _eq: folderId,
            }
        }

        if (filterObj?.selectedFolders?.length) {
            whereObj['id'] = {
                _in: filterObj.selectedFolders,
            }
        }

        if (filterObj?.selectedCreators?.length) {
            whereObj['created_by'] = {
                _in: filterObj.selectedCreators,
            }
        }

        if (filterObj?.selectedTags?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            if (!whereObj['library_files']['tag_relations']) {
                whereObj['library_files']['tag_relations'] = {}
            }
            whereObj['library_files']['tag_relations']['tag_id'] = {
                _in: filterObj?.selectedTags,
            }
        }

        if (filterObj?.selectedCompanies?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            whereObj['library_files']['company'] = {
                _in: filterObj.selectedCompanies,
            }
        }

        if (filterObj?.selectedColors?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            whereObj['library_files']['color'] = {
                _in: filterObj.selectedColors,
            }
        }

        if (
            !searchStr &&
            !whereObj['id'] &&
            !whereObj['created_by'] &&
            !whereObj['library_files'] &&
            !folderId &&
            !whereObj.parent_id
        ) {
            whereObj.parent_id = {
                _is_null: true,
            }
        }

        fetchAllFoldersList({
            variables: {
                name: common.name,
                created_at: common.created_at,
                whereObj,
            },
            fetchPolicy: 'cache-and-network',
        })
    }

    const goBackProperly = () => {
        // console.log({history : history.back()});
        navigate(-1)
    }

    useEffect(() => {
        setListView(false)
        if (searchParams.has(libraryDefaultKeys.listview)) {
            let l = searchParams.get(libraryDefaultKeys.listview)
            setListView(l === '1' ? true : false)
        }
    }, [searchParams])

    const runChangeCheckBox = (
        checked: boolean,
        id: number,
        fileObj: FileObject
    ) => {
        const tempColor: any = { ...selectedFiles }
        if (!tempColor[id]) {
            tempColor[id] = {}
        }
        tempColor[id] = {
            ...fileObj,
            is_library_file: true,
            checked,
        }
        setSelectedFiles(tempColor)
    }

    const makeFilesArray = () => {
        const choosenFiles = []
        for (const key in selectedFiles) {
            if (selectedFiles[key]?.checked) {
                choosenFiles.push(selectedFiles[key])
            }
        }
        return choosenFiles
    }

    const { updateSubTodo } = useStore()
    const { addSubtodoFiles } = useCreateTodoFormStore()
    const cameFrom = searchParams.get('from')
    const collectionId = searchParams.get('collectionId')
    const productId = searchParams.get('productId')

    const attachFilesToTodForm = () => {
        const choosenFiles = makeFilesArray()
        updateSubTodo({
            payload: {
                todoId: parentTodoId || 0,
                subTodoId: entityId?.toString() || uuid(),
                data: choosenFiles,
            },
            action: 'file',
        })

        if (locationState.from) {
            navigate(locationState.from, {
                state: {
                    showDrawer: locationState.showDrawer
                        ? locationState.showDrawer
                        : false,
                },
                replace: true,
            })
        } else if (collectionId && collectionId !== '0') {
            let _url = `/collections/${collectionId}`
            if (productId && productId !== '0') {
                _url += `/product/edit-product/${productId}`
            } else {
                _url += `/create-products`
            }
            navigate(_url, { replace: true })
        }
    }

    const attachFilesToSubtodo = () => {
        const choosenFiles = makeFilesArray()
        addSubtodoFiles({
            subtodoId: locationState.id,
            filesToAdd: choosenFiles.map((file) => ({
                id: file.id,
                path: file.path,
                name: file.name,
                thumbnail: file.thumbnail,
                gid: file.gid,
                from_library: true,
            })),
        })
        if (locationState.from) {
            navigate(
                appendSearchParamsToUrl({
                    url: locationState.from,
                    searchParams: { key: 'section_id', value: params.entityId },
                }),
                { replace: true }
            )
        } else if (collectionId && collectionId !== '0') {
            let _url = `/collections/${collectionId}`
            if (productId && productId !== '0') {
                _url += `/product/edit-product/${productId}`
            } else {
                _url += `/create-products`
            }
            navigate(_url, { replace: true })
        }
    }

    const filesAssigning = async () => {
        if (locationState?.action === 'createSubtodo') {
            attachFilesToSubtodo()
            return
        } else if (cameFrom === 'createTodo') {
            attachFilesToTodForm()
            return
        }

        setAttaching(true)

        try {
            const choosenFiles = makeFilesArray()
            const choosenFileIds = choosenFiles.map((item: any) => item.id)

            const payload: any = {
                entity_name: entityName,
                entity_id: entityId,
                library_file_ids: choosenFileIds,
                type: 'document',
                assigned_as: 'attachments',
            }

            if (entityName === 'todos') {
                if (parentTodoId && parentTodoId !== '0') {
                    payload.entity_name = 'sub_todos'
                }
            }

            const response = await axios.post(
                ASSIGN_FILE_FROM_LIBRARY,
                payload,
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )

            if (response.status === 200) {
                await client.refetchQueries({
                    include: [GetTodo],
                })
                if (locationState?.from) {
                    navigate(
                        appendSearchParamsToUrl({
                            url: locationState.from,
                            searchParams: {
                                key: 'section_id',
                                value: params.entityId,
                            },
                        }),
                        { replace: true }
                    )
                } else if (entityName === 'todos') {
                    if (parentTodoId && parentTodoId !== '0') {
                        navigate(
                            appendSearchParamsToUrl({
                                url: '/todo/' + parentTodoId,
                                searchParams: {
                                    key: 'section_id',
                                    value: params.id,
                                },
                            }),
                            { replace: true }
                        )
                    } else {
                        navigate(
                            appendSearchParamsToUrl({
                                url: '/todo/' + entityId,
                                searchParams: {
                                    key: 'section_id',
                                    value: params.id,
                                },
                            }),
                            { replace: true }
                        )
                    }
                }
            }
        } catch (error: any) {
            console.log({ error })
            let message: any = 'Failed to attach the files.'
            if (error?.error?.message) {
                message = error.error.message
            }
            if (error?.message) {
                message = error?.message
            }
            toast.error(message, { className: 'custom-toaster toaster-error' })
        }

        setAttaching(false)
    }

    const goBackToPrev = () => {
        if (parentTodoId) {
            navigate(`/todo/${parentTodoId}`, { replace: true })
        } else if (locationState && locationState.from) {
            navigate(locationState.from, {
                state: {
                    showDrawer: locationState.showDrawer
                        ? locationState.showDrawer
                        : false,
                },
                replace: true,
            })
        } else if (cameFrom === 'createTodo') {
            if (collectionId && collectionId !== '0') {
                let _url = `/collections/${collectionId}`
                if (productId && productId !== '0') {
                    _url += `/product/edit-product/${productId}`
                } else {
                    _url += `/create-products`
                }
                navigate(_url, { replace: true })
            }
            return
        } else if (entityName === 'todos') {
            if (parentTodoId && parentTodoId !== '0') {
                navigate('/todo/' + parentTodoId + `?focus=${params.entityId}`)
            } else {
                navigate('/todo/' + entityId + `?focus=${params.entityId}`)
            }
        }
    }

    return (
        <>
            <Box
                pt={'40px'}
                pb={'200px'}
                pl="60px"
                pr={'24px'}
                overflowY="scroll"
                bg="#F8FAFB"
                position="relative"
                height="100vh"
            >
                <Box
                    display="flex"
                    width="100%"
                    mb={'12px'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text
                        fontFamily="Rubik"
                        className="pointer"
                        onClick={() => {
                            goBackToPrev()
                        }}
                        fontSize={'24px'}
                        my="0px"
                        mr="12px"
                    >
                        <Span mr={'4px'}>
                            {' '}
                            <GoBackLibrary />{' '}
                        </Span>
                        Select Files from Library
                    </Text>
                </Box>

                <Actions
                    selectFilesView={true}
                    setListView={setListView}
                    listView={listView}
                    refreshContent={() => {}}
                />

                <DivisionLine />

                <Box width="100%" height="max-content" pr={'0px'} py={'24px'}>
                    <AppliedFiltersTags />

                    {!foldersList.length && !filesList.length ? (
                        <>
                            {folderId && folderId !== '0' && (
                                <Button
                                    color="links"
                                    fontSize="12px"
                                    mt={'0px'}
                                    mb={'24px'}
                                    bg="transparent"
                                    border="none"
                                    onClick={() => {
                                        goBackProperly()
                                    }}
                                    px="0px"
                                    fontFamily="Rubik"
                                >
                                    &lt; Back
                                </Button>
                            )}

                            <Box
                                height="calc(100vh - 400px)"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box>
                                    <EmptyFolderView
                                        onlyFiles={true}
                                        onlyFolders={true}
                                        disableActions={true}
                                        openCreateFolderModal={() => {}}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box mb={'24px'}>
                                {folderId && folderId !== '0' && (
                                    <Button
                                        color="links"
                                        fontSize="12px"
                                        mt={'0px'}
                                        mb={'24px'}
                                        bg="transparent"
                                        border="none"
                                        onClick={() => goBackProperly()}
                                        px="0px"
                                        fontFamily="Rubik"
                                    >
                                        &lt; Back
                                    </Button>
                                )}

                                {
                                    <>
                                        <Box mb={'24px'}>
                                            <Text
                                                fontSize={'16px'}
                                                color="textTags"
                                                mt="0px"
                                                mb="16px"
                                            >
                                                {' '}
                                                Files{' '}
                                            </Text>

                                            {!filesList.length &&
                                            !filesListData.loading ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Box>
                                                        <EmptyFolderView
                                                            onlyFiles={true}
                                                            onlyFolders={false}
                                                            openCreateFolderModal={() => {}}
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                    gridGap="24px"
                                                >
                                                    {filesList?.map(
                                                        (
                                                            item: FileObject,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <FileCard
                                                                        isFavorite={
                                                                            false
                                                                        }
                                                                        data={
                                                                            item
                                                                        }
                                                                        key={
                                                                            item.name +
                                                                            'file2' +
                                                                            index +
                                                                            item.id
                                                                        }
                                                                        isSelectable={
                                                                            true
                                                                        }
                                                                        runSelection={(
                                                                            id: any,
                                                                            checked: any
                                                                        ) => {
                                                                            runChangeCheckBox(
                                                                                checked,
                                                                                id,
                                                                                item
                                                                            )
                                                                        }}
                                                                        isFileChecked={
                                                                            selectedFiles[
                                                                                item
                                                                                    ?.id
                                                                            ]
                                                                                ?.checked ||
                                                                            false
                                                                        }
                                                                        typeCheckbox={
                                                                            true
                                                                        }
                                                                        librarySelect={
                                                                            true
                                                                        }
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    )}

                                                    {filesCount >
                                                        filesList?.length && (
                                                        <Box
                                                            width={'120px'}
                                                            display={'flex'}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            height={'135px'}
                                                        >
                                                            <Text
                                                                fontSize={
                                                                    '16px'
                                                                }
                                                                textAlign="center"
                                                                color="textTags"
                                                                className="pointer"
                                                                onClick={() => {
                                                                    setCurrentPage(
                                                                        currentPage +
                                                                            1
                                                                    )
                                                                }}
                                                                my="0px"
                                                            >
                                                                Load more
                                                            </Text>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>

                                        <Box>
                                            <Text
                                                fontSize={'16px'}
                                                color="textTags"
                                                mt="0px"
                                                mb="16px"
                                            >
                                                {' '}
                                                Folders{' '}
                                            </Text>
                                            {!foldersList.length &&
                                            !allFoldersListData.loading ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Box>
                                                        <EmptyFolderView
                                                            onlyFiles={false}
                                                            onlyFolders={true}
                                                            addFileToFolder={() => {}}
                                                            openCreateFolderModal={() => {}}
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <FolderFilesView
                                                    name="mainFolders"
                                                    listView={listView}
                                                    folders={foldersList}
                                                    selectFilesFlow={true}
                                                />
                                            )}
                                        </Box>
                                    </>
                                }
                            </Box>
                        </>
                    )}
                </Box>

                <Box
                    bg="#022143"
                    height={'180px'}
                    width="100%"
                    position="fixed"
                    bottom="0px"
                    left="0px"
                    right="0px"
                    display="flex"
                    alignItems="center"
                    pl={'60px'}
                    pr={'36px'}
                    zIndex={10}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={'100%'}
                    >
                        <Box
                            display="flex"
                            width={'85%'}
                            className="library_scroll"
                            maxWidth={'85%'}
                            alignItems="center"
                            gridColumnGap={'12px'}
                        >
                            {makeFilesArray().length ? (
                                <Box
                                    display="flex"
                                    overflowX={'auto'}
                                    py={'12px'}
                                    pr={'24px'}
                                    width="100%"
                                    gridGap="24px"
                                >
                                    {makeFilesArray().map(
                                        (item: FileObject, index: number) => {
                                            return (
                                                <>
                                                    <Box position="relative">
                                                        <label
                                                            style={{
                                                                display:
                                                                    'block',
                                                            }}
                                                            onClick={() => {
                                                                runChangeCheckBox(
                                                                    false,
                                                                    item.id,
                                                                    item
                                                                )
                                                            }}
                                                            htmlFor={
                                                                'radio' +
                                                                item.id
                                                            }
                                                        >
                                                            <Box
                                                                className="pointer"
                                                                position="absolute"
                                                                height={'20px'}
                                                                zIndex={10}
                                                                top="-7px"
                                                                right="-6px"
                                                            >
                                                                <CloseIconRed />
                                                            </Box>
                                                        </label>
                                                        <FileCard
                                                            isFavorite={false}
                                                            data={item}
                                                            key={
                                                                item.name +
                                                                'file2' +
                                                                index +
                                                                item.id
                                                            }
                                                            width={'120px'}
                                                            height={'120px'}
                                                            librarySelect={true}
                                                        />
                                                    </Box>
                                                </>
                                            )
                                        }
                                    )}
                                </Box>
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="1px dashed #778CA2"
                                    color="#778CA2"
                                    borderRadius="4px"
                                    minWidth={'124px'}
                                    width={'124px'}
                                    fontSize="12px"
                                    height="114px"
                                >
                                    <Text my={'0px'} fontSize="12px">
                                        {' '}
                                        Selected files{' '}
                                    </Text>
                                </Box>
                            )}
                        </Box>

                        <Box>
                            <Box mb={'16px'}>
                                <ColoredButton
                                    disabled={
                                        attaching || !makeFilesArray().length
                                    }
                                    type="button"
                                    onClick={() => {
                                        filesAssigning()
                                    }}
                                    loader={attaching}
                                    variant={'secondary'}
                                >
                                    <Box
                                        display="flex"
                                        gridColumnGap={'8px'}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        Attach
                                    </Box>
                                </ColoredButton>
                            </Box>
                            <Box>
                                <ColoredButton
                                    disabled={attaching}
                                    type="button"
                                    onClick={() => {
                                        goBackToPrev()
                                    }}
                                    variant={'tertiaryOutlineTwo'}
                                >
                                    <Box
                                        display="flex"
                                        gridColumnGap={'8px'}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        Cancel
                                    </Box>
                                </ColoredButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
