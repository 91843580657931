import { useQuery } from '@apollo/client'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
    DivisionLine,
    Dropdown,
    DropdownButtonWrapper,
    StackedImage,
} from 'src/components'
import { Box, Button, Input } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { GetIndividualTeams } from 'src/services'
import { useTeam } from './hooks/use-team'

function TeamDropdown(
    {
        onClose,
        onSelect,
        searchInDropdown = true,
    }: {
        onClose: () => void
        onSelect: (teamId: number) => void
        searchInDropdown?: boolean
    },
    ref: any
) {
    const [teamInput, setTeamInput] = useState('')
    const { data } = useQuery(GetIndividualTeams)
    const filteredTeams = data?.teams?.filter((team: any) =>
        !!teamInput
            ? team.name.toLowerCase().includes(teamInput.toLowerCase())
            : true
    )
    const { addCollaborator, createTeam, createdTeamId } = useTeam()
    const [viewMore, setViewMore] = useState(false)

    const [isDropdownActive, setIsDropdownActive] = useState(false)

    const formDropDown = useRef<HTMLDivElement | null>(null)
    const wrapperRef = useClickedOutside(() => {
        onClose()
        setIsDropdownActive(false)
    })

    async function createNewTeam() {
        const teamId = await createTeam(teamInput)
        if (teamId) {
            onSelect(teamId)
        }
    }

    useEffect(() => {
        if (teamInput.length) {
            setIsDropdownActive(true)
        }
    }, [teamInput])

    useImperativeHandle(
        ref,
        () => {
            return {
                teamInput,
            }
        },
        [teamInput]
    )

    return (
        <Box>
            {!searchInDropdown && (
                <Input
                    bg="transparent"
                    border="none"
                    fontSize={'16px'}
                    px={'16px'}
                    value={teamInput}
                    onChange={(e) => setTeamInput(e.target.value)}
                    placeholder="Add team"
                    width="100%"
                />
            )}
            {teamInput.length > 0 && isDropdownActive && (
                <Dropdown
                    ref={formDropDown}
                    top="34px"
                    width="250px"
                    active={isDropdownActive}
                    maxHeight="250px"
                    overflowY="auto"
                    uniqueId="dropdown__focus"
                >
                    <Box
                        ref={wrapperRef}
                        display="flex"
                        flexDirection="column"
                        gridGap="8px"
                        width={'100%'}
                    >
                        {searchInDropdown && (
                            <>
                                {' '}
                                <Input
                                    bg="transparent"
                                    border="none"
                                    fontSize={'16px'}
                                    px={'16px'}
                                    value={teamInput}
                                    onChange={(e) =>
                                        setTeamInput(e.target.value)
                                    }
                                    placeholder="Enter team name"
                                    width="100%"
                                />
                                <DivisionLine color="#E8ECEF" width={1} />{' '}
                            </>
                        )}
                        <Box width="100%">
                            {/* Show no results found if the filteredTeam is empty */}
                            {filteredTeams?.length === 0 && (
                                <Button
                                    fontFamily="Rubik"
                                    bg="transparent"
                                    textAlign="left"
                                    color="links"
                                    fontSize="14px"
                                    px="16px"
                                    border="none"
                                    onClick={createNewTeam}
                                >
                                    + Create "{teamInput}"
                                </Button>
                            )}
                            {filteredTeams?.map((team: any, key: number) => {
                                const imageList = team.team_members.map(
                                    (members: any) => {
                                        return {
                                            src: members.user.files[0]?.gid
                                                ? getImageUrl(
                                                      members.user.files[0]?.gid
                                                  )
                                                : '',
                                            name: members.user.name,
                                        }
                                    }
                                )
                                if (key < 4) {
                                    return (
                                        <DropdownButtonWrapper
                                            onClick={() => {
                                                onSelect(team.id)
                                                onClose()
                                            }}
                                            name={team.name}
                                            overflow="visible"
                                            position="relative"
                                            display="flex"
                                            key={key}
                                            width="100%"
                                        >
                                            <Box
                                                position="relative"
                                                display="flex"
                                                width="100%"
                                                gridColumnGap="12px"
                                                justifyContent="space-between"
                                            >
                                                <Button
                                                    height="auto"
                                                    fontFamily="Rubik"
                                                    color="primary"
                                                    bg="transparent"
                                                    border="none"
                                                    fontSize="14px"
                                                    display="inline-block"
                                                    maxWidth="152px"
                                                    overflow="hidden"
                                                    gridColumnGap="12px"
                                                    className="text-overflow"
                                                    type="button"
                                                >
                                                    {team.name}
                                                </Button>
                                                <StackedImage
                                                    spacing={14}
                                                    size={'24px'}
                                                    width="max-content"
                                                    list={imageList}
                                                    key={key}
                                                ></StackedImage>
                                            </Box>
                                        </DropdownButtonWrapper>
                                    )
                                }
                            })}

                            {viewMore &&
                                filteredTeams?.map((team: any, key: number) => {
                                    const imageList = team.team_members.map(
                                        (members: any) => {
                                            return {
                                                src: members.user.files[0]?.gid
                                                    ? getImageUrl(
                                                          members.user.files[0]
                                                              ?.gid
                                                      )
                                                    : '',
                                                name: members.user.name,
                                            }
                                        }
                                    )
                                    if (key > 4) {
                                        return (
                                            <DropdownButtonWrapper
                                                name={team.name}
                                                onClick={() => {
                                                    onSelect(team.id)
                                                    onClose()
                                                }}
                                                overflow="visible"
                                                position="relative"
                                                display="flex"
                                                key={key}
                                                width="100%"
                                            >
                                                <Box
                                                    position="relative"
                                                    display="flex"
                                                    width="100%"
                                                    gridColumnGap="12px"
                                                    justifyContent="space-between"
                                                >
                                                    <Button
                                                        height="auto"
                                                        fontFamily="Rubik"
                                                        color="primary"
                                                        bg="transparent"
                                                        border="none"
                                                        fontSize="14px"
                                                        display="inline-block"
                                                        maxWidth="152px"
                                                        overflow="hidden"
                                                        className="text-overflow"
                                                        type="button"
                                                    >
                                                        {team.name}
                                                    </Button>
                                                    <StackedImage
                                                        fontSize="14px"
                                                        spacing={14}
                                                        size={'24px'}
                                                        width="max-content"
                                                        list={imageList}
                                                        key={key}
                                                    ></StackedImage>
                                                </Box>
                                            </DropdownButtonWrapper>
                                        )
                                    }
                                })}
                            {!viewMore && filteredTeams?.length > 0 && (
                                <Box mt="12px" px="16px" width="100%">
                                    <DivisionLine
                                        color="#E8ECEF"
                                        width={1}
                                    ></DivisionLine>
                                </Box>
                            )}
                            {!viewMore && filteredTeams?.length > 0 && (
                                <DropdownButtonWrapper hover={false}>
                                    <Button
                                        id="dropdown__focus"
                                        type="button"
                                        color="links"
                                        onClick={() => setViewMore(true)}
                                        fontFamily="Rubik"
                                        bg="transparent"
                                        border="none"
                                    >
                                        View all
                                    </Button>
                                </DropdownButtonWrapper>
                            )}
                        </Box>
                    </Box>
                </Dropdown>
            )}
        </Box>
    )
}

export const TeamDropdownSelector = React.forwardRef(TeamDropdown)
