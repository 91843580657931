import { TodoIco } from '../../components/svg-icons'
import { Box, Text } from '../../components/utility'

export function TodoBadge() {
    return (
        <Box
            bg="#F5BCAA"
            color="#022143"
            display="flex"
            gridGap="4px"
            borderRadius="4px"
            p="4px"
            alignItems="center"
            minWidth="max-content"
            height="max-content"
        >
            <TodoIco size={'xs'} color="#022143" />
            <Text fontSize="12px" my="0px">
                To-do
            </Text>
        </Box>
    )
}
