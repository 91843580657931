import React from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Completed, InProgress, New } from 'src/components/status'
import { Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

function CollectionStatusDropdown({
    active,
    setIsDropdownActive,
    onUpdate,
}: {
    active: boolean
    setIsDropdownActive: React.Dispatch<React.SetStateAction<boolean>>
    onUpdate: (status: 'new' | 'inProgress' | 'complete') => void
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['status-dropdown-btn']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="24px"
            left="0px"
            width="182px"
        >
            <Box ref={wrapperRef} width="100%" px="16px">
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('new')
                        setIsDropdownActive(false)
                    }}
                >
                    <New />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('inProgress')
                        setIsDropdownActive(false)
                    }}
                >
                    <InProgress />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('complete')
                        setIsDropdownActive(false)
                    }}
                >
                    <Completed />
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}

export default CollectionStatusDropdown
