import React from 'react'

function CompareIco() {
    return (
        <svg
            width="29"
            height="32"
            viewBox="0 0 29 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7364 28V25.8182H6.13636C5.7 25.8182 5.31818 25.6545 4.99091 25.3273C4.66364 25 4.5 24.6182 4.5 24.1818V7.81818C4.5 7.38182 4.66364 7 4.99091 6.67273C5.31818 6.34545 5.7 6.18182 6.13636 6.18182H12.7364V4H14.3727V28H12.7364ZM6.13636 23.0364H12.7364V15.4818L6.13636 23.0364ZM16.0091 25.8182V15.5909L22.5 23.0364V7.81818H16.0091V6.18182H22.5C22.9364 6.18182 23.3182 6.34545 23.6455 6.67273C23.9727 7 24.1364 7.38182 24.1364 7.81818V24.1818C24.1364 24.6182 23.9727 25 23.6455 25.3273C23.3182 25.6545 22.9364 25.8182 22.5 25.8182H16.0091Z"
                fill="#778CA2"
            />
        </svg>
    )
}

export default CompareIco
