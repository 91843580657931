import React from 'react'

function CircleIco({
    color = 'black',
    title,
}: {
    color?: string
    title?: string
}) {
    return (
        <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {title && <title>{title}</title>}
            <g filter="url(#filter0_d_16482_300810)">
                <circle
                    cx="11.89844" // Center x-coordinate
                    cy="11.5" // Center y-coordinate
                    r="8.5" // Radius
                    stroke={color}
                    strokeWidth="1"
                />
            </g>
        </svg>
    )
}

export default CircleIco
