import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { debounce } from 'src/helpers'
import { SearchTagsByName } from 'src/services/graphql/query/get-tags'

export function useSearchTags() {
    const [searchTagsByName, searchedTags] = useLazyQuery<{
        tags: {
            id: string
            tag: string
        }[]
    }>(SearchTagsByName)

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const searchTags = debounce(searchTagsFn, 500, setTimeOutRef, timeOutRef)

    function searchTagsFn(query: string) {
        searchTagsByName({
            variables: {
                name: `%${query}%`,
            },
        })
    }
    return { searchTags, searchedTags: searchedTags.data?.tags || [] }
}
