import { gql } from '@apollo/client'

export const InsertProduct = gql`
mutation MyMutation($object:products_insert_input! = object) {
    insert_products_one(object: $object) {
      description
      id
    }
  }
`
