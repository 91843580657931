import tippy from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import { MentionList } from './mention-list'

export default {
    render: () => {
        let reactRenderer: any
        let popup: any

        return {
            onStart: (props: any) => {
                reactRenderer = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'top-start',
                })
            },
            onUpdate(props: any) {
                reactRenderer.updateProps(props)
                if (!props.clientRect) {
                    return
                }
                popup[0]?.setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props: any) {
                if (props.event.key === 'Escape') {
                    popup[0]?.hide()
                    return true
                }
                return reactRenderer?.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0]?.destroy()
                reactRenderer.destroy()
            },
        }
    },
}
