import { gql } from '@apollo/client'

export const CreateTeam = gql`
    mutation CreateTeam($name: String) {
        insert_teams_one(object: { name: $name }) {
            id
            name
            team_members {
                user {
                    email
                    name
                    other_data
                    id
                }
            }
        }
    }
`
