import { gql } from '@apollo/client'

export const GetTodosByUserId = gql`
    query GetTodosByUserId(
        $limit: Int = 100
        $offset: Int = 0
        $status: status_enum = new
        $order_by: [todos_order_by!] = { name: asc }
        $due_date: timestamptz_comparison_exp = {}
        $user_id: bigint!
        $organisation_id: bigint!
        $todo_filter: todos_bool_exp! = {}
    ) {
        new: todos_aggregate(
            where: {
                status: { _eq: new }
                due_date: $due_date
                user_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: todos_aggregate(
            where: {
                status: { _eq: inProgress }
                due_date: $due_date
                user_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
        requestedForResubmission: todos_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                due_date: $due_date
                user_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: todos_aggregate(
            where: {
                status: { _eq: inReview }
                due_date: $due_date
                user_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
        toBeReviewed: todos_aggregate(
            where: {
                status: { _eq: inReview }
                due_date: $due_date
                product_variant: {
                    collection: { manager_id: { _eq: $user_id } }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        totalCompleted: todos_aggregate(
            where: {
                status: { _eq: complete }
                due_date: $due_date
                user_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
        todos(
            limit: $limit
            offset: $offset
            where: $todo_filter
            order_by: $order_by
        ) {
            id
            name
            other_data
            due_date
            status
            is_delayed
            product_variant {
                id
                name
                other_data
                collection {
                    id
                    name
                    manager_id
                }
            }
            assignee {
                id
                name
                email
                other_data
                organisation_members(
                    where: { organisation_id: { _eq: $organisation_id } }
                ) {
                    role
                }
            }
        }
    }
`
