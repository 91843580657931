import * as React from 'react'
import * as THREE from 'three'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Dots } from 'src/components/svg-icons'
import { Box, Button } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { useConfiguratorContext } from '../../configurator.context'
import type { MeshCustomMaterial } from '../material/custom-material'

export interface IDiscardDropdownProps {
    id: string
    destination: TDestinationColors
    source: TSourceColors
    emphasis: TEmphasis
    sensitivity: TSensitivity
}

export default function DiscardDropdown({
    destination,
    emphasis,
    id,
    sensitivity,
    source,
}: IDiscardDropdownProps) {
    const [isDropdownActive, setIsDropdownActive] = React.useState(false)
    const dropdownRef = useClickedOutside(() => setIsDropdownActive(false))
    const { colorLayers, setColorLayers, highlights } = useConfiguratorContext()

    const mesh = highlights[0].mesh as THREE.Mesh

    function onDeleteLayer(id: string) {
        const layers = { ...colorLayers }
        layers[mesh.id] = layers[mesh.id].filter((layer) => layer.id !== id)

        const material = mesh.material as MeshCustomMaterial

        material[destination].setHex(16711680)
        material[source].setHex(new THREE.Color('#ffffff00').getHex())

        material[emphasis] = 0.01
        material[sensitivity] = 0.5

        setColorLayers(layers)
    }

    return (
        <Box position="relative">
            <Button
                p="0px"
                bg="transparent"
                border="none"
                className="vertical"
                onClick={(e) => {
                    e.stopPropagation()
                    setIsDropdownActive(!isDropdownActive)
                }}
            >
                <Dots active={isDropdownActive} />
            </Button>
            {isDropdownActive && (
                <Dropdown
                    active={isDropdownActive}
                    left="-60px"
                    ref={dropdownRef}
                >
                    <DropdownButtonWrapper>
                        <Button
                            color="delay"
                            bg="transparent"
                            border="none"
                            onClick={(e) => {
                                e.stopPropagation()
                                onDeleteLayer(id)
                            }}
                        >
                            Discard
                        </Button>
                    </DropdownButtonWrapper>
                </Dropdown>
            )}
        </Box>
    )
}
