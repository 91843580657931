import ReactTags from 'react-tag-autocomplete'

import type { SetStateAction } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Text } from '../utility'
import { GetTags } from 'src/services'
import { useQuery } from '@apollo/client'
import { CrossSm } from '../svg-icons'
import { uuid } from 'src/helpers'
import { TAGS_CHAR_LIMIT } from 'src/helpers/constants'
import { toast } from 'react-toastify'

function Suggestion(props: any) {
    return (
        <Box width="100%" color="primary" fontFamily="Rubik">
            {props.item.name}
        </Box>
    )
}

function TagComponent(props: any) {
    return (
        <Box display="inline-block" mr={'8px'} mb={'8px'}>
            <Box
                px="8px"
                width="max-content"
                borderRadius="20px"
                py="4px"
                display="flex"
                fontFamily="Rubik"
                fontSize="12px"
                color="textTags"
                alignItems="center"
                justifyContent="space-between"
                bg={props.tag.color || '#FFE395'}
            >
                {props.tag.name}
                <Button
                    onClick={props.onDelete}
                    bg="transparent"
                    display="flex"
                    type="button"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CrossSm />
                </Button>
            </Box>
        </Box>
    )
}

export const TagAutoCommplete = ({
    setTags,
    tags,
    storeDispatch,
    allowNew = true,
    TagSuggestionComponent,
    noSuggestionTags,
    placeholder,
    onTagSelect,
    suggestions,
    updateTag,
    removeTag,
}: {
    suggestions?: { id: number; name: string }[]
    placeholder?: string
    noSuggestionTags?: string
    TagSuggestionComponent?: React.FC
    allowNew?: boolean
    onTagSelect?: (tag: { id: string; name: string; color: string }) => void
    setTags?: React.Dispatch<
        SetStateAction<{ id: string; name: string; color: string }[]>
    >
    removeTag?: (tagName: string) => void
    storeDispatch?: (
        {
            id,
            name,
            color,
        }: { id: string | number; name: string; color: string },
        action: 'ADD' | 'DELETE'
    ) => void
    updateTag?: (tag: { id: string; name: string; color: string }) => void
    tags: { id: string | number; name: string; color: string }[]
}) => {
    const { data } = useQuery(GetTags)
    const [tagSuggestions, setTagSuggestions] = useState<
        { id: number; name: string; color: string }[]
    >([])
    const colors = [
        '#FED5DD',
        '#FFE2D1',
        '#F5F6A9',
        '#FFE395',
        '#DDEFB2',
        '#A9F6BF',
        '#A9F6F2',
        '#C8E8FF',
        '#D8D5FD',
        '#E7D1F8',
        '#FFDDFF',
        '#BDDBFF',
        '#FABBCD',
        '#D6E0EA',
    ]

    useEffect(() => {
        if (data) {
            setTagSuggestions(
                data.tags.map((tag: Tags) => ({
                    name: tag.tag,
                    id: tag.id,
                    color: tag.tag_relationships.length
                        ? tag.tag_relationships[
                              tag.tag_relationships.length - 1
                          ]?.color
                        : null,
                }))
            )
        }
    }, [data])

    function addNewTag(createdTag: any) {
        if (!tags.find((tag) => tag.name === createdTag.name)) {
            const foundTag = tagSuggestions.find(
                (tags) => createdTag.name === tags.name
            )
            const tag = {
                name: createdTag.name,
                id: foundTag?.id?.toString() || uuid(),
                color:
                    foundTag?.color ||
                    colors[Math.floor(Math.random() * colors.length - 1)],
            }
            if (tagSuggestions.find((tag) => tag.name === createdTag.name)) {
                if (setTags) {
                    setTags((tags) => [...tags, tag])
                } else if (updateTag) {
                    updateTag(tag)
                } else if (storeDispatch) {
                    storeDispatch(tag, 'ADD')
                }
            } else {
                if (setTags) {
                    setTags((tags) => [...tags, tag])
                } else if (updateTag) {
                    updateTag(tag)
                } else if (storeDispatch) {
                    storeDispatch(tag, 'ADD')
                }
                setTagSuggestions((tags: any) => [...tags, createdTag])
            }

            onTagSelect && onTagSelect(tag)
        } else {
            toast('Tag already exists', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    function onDelete(index: number) {
        const tagName = tags[index]?.name || ''
        if (setTags) {
            setTags((tags) => tags.filter((tag) => tag.name !== tagName))
        } else if (removeTag) {
            removeTag(tagName)
        } else if (storeDispatch) {
            const foundTag = tags.find((tag) => tag.name === tagName)
            if (foundTag) {
                storeDispatch(foundTag, 'DELETE')
            }
        }
    }

    const [query, setQuery] = useState<string>('')
    const reactTags = useRef<any>()
    return (
        <Box display="flex" flexDirection="column" gridGap="4px">
            <ReactTags
                tagComponent={TagSuggestionComponent || TagComponent}
                onInput={(input) => {
                    setQuery(input)
                }}
                suggestionComponent={Suggestion}
                allowNew={allowNew || true}
                placeholderText={placeholder || 'Add Tags'}
                autoresize
                allowBackspace
                tags={tags}
                removeButtonText={'X'}
                onAddition={(tag) => {
                    addNewTag(tag)
                    setQuery('')
                }}
                onDelete={onDelete}
                ref={reactTags}
                suggestions={suggestions || tagSuggestions}
                onValidate={(tag) => tag.name.length <= TAGS_CHAR_LIMIT}
            />
            {query.length > 0 && (
                <Text
                    color={
                        query.length > TAGS_CHAR_LIMIT ? '#FF2C18' : 'inherit'
                    }
                    fontSize="12px"
                    my="0px"
                >
                    {query.length}/{TAGS_CHAR_LIMIT} characters used.{' '}
                    {query.length > TAGS_CHAR_LIMIT && 'Tag is too long.'}
                </Text>
            )}
        </Box>
    )
}
