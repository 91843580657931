import type {
    GetCollectionsQuery,
    GetProductsQuery,
    GetTodosQuery,
} from 'src/generated/graphql'

export function isCollectionEntity(
    entity: any,
    activeGroup: 'collections' | 'products' | 'todos'
): entity is Pick<GetCollectionsQuery, 'collections'>['collections'][number] {
    return activeGroup === 'collections' && entity?.id && entity?.name
}

export function isProductEntity(
    entity: any,
    activeGroup: 'collections' | 'products' | 'todos'
): entity is Pick<GetProductsQuery, 'products'>['products'][number] {
    return activeGroup === 'products' && entity?.id
}

export function isTodoEntity(
    entity: any,
    activeGroup: 'collections' | 'products' | 'todos'
): entity is Pick<GetTodosQuery, 'todos'>['todos'][number] {
    return activeGroup === 'todos' && entity?.id && entity?.name
}
