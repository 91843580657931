import React from 'react'
import { Box, Text } from 'src/components/utility'
import TodoPopoverCard from './todo-popover-card'
import { useQuery } from '@apollo/client'
import { Loader } from 'src/components/loader/loader'
import type {
    GetProductTodosQuery,
    GetProductTodosQueryVariables,
} from 'src/generated/graphql'
import { GetProductTodos } from 'src/services/graphql/entities/todo/todo.queries'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'

function ProductTodosPopover({
    totalTodos,
    variantId,
}: {
    totalTodos: number
    variantId: number
}) {
    const {
        data: productDetails,
        loading: isProductDetailsLoading,
        refetch: refetchProductDetails,
    } = useQuery<GetProductTodosQuery, GetProductTodosQueryVariables>(
        GetProductTodos,
        {
            variables: {
                id: variantId,
            },
            fetchPolicy: 'network-only',
        }
    )
    const product = productDetails?.product_variants_by_pk
    const todos = product?.todos
    return (
        <Box
            position="absolute"
            top="28px"
            left="0px"
            width={'50vw'}
            maxHeight="35vh"
            bg="white"
            zIndex={10}
            px="8px"
            py="16px"
            boxShadow="0px 2px 12px 0px #999BA840"
            border="1px solid"
            borderColor="primary"
            borderRadius="4px"
            display="grid"
            gridTemplateRows={'auto 1fr'}
            gridGap="8px"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            {isProductDetailsLoading ? (
                <Loader />
            ) : todos ? (
                <>
                    <Text my="0px">To-Dos({totalTodos})</Text>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gridGap="8px"
                        overflow={'auto'}
                    >
                        {todos.map((todo) => (
                            <TodoPopoverCard
                                key={todo.id}
                                id={todo.id}
                                name={todo.name}
                                assignee={todo.assignee}
                                dueDate={todo.due_date}
                                isDelayed={isEntityDueDateDelayed(
                                    todo.due_date,
                                    todo.status
                                )}
                                productVariant={{
                                    id: product.id,
                                    name: product.name,
                                }}
                                status={todo.status}
                                types={todo.types}
                                refetch={refetchProductDetails}
                            />
                        ))}
                    </Box>
                </>
            ) : (
                <Text textAlign="center">Failed to fetch the todos</Text>
            )}
        </Box>
    )
}

export default ProductTodosPopover
