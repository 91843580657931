import { forwardRef } from 'react'
import { DropDownArrow } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import StyledDropdownBadge from './styled.dropdown.badge'

export const DropdownBadge = forwardRef(
    (
        props: {
            label: string
            labelFontSize?: FontSize
            highlightedTextFontSize?: FontSize
            highlightedText: string | JSX.Element
            onclick: any
            noStyle?: boolean
            showArrow?: boolean
            border?: string
            height?: string
            maxWidth?: string
            disabled?: boolean
            id?: string
            bg?: string
            borderWidth?: BorderWidth
            borderColor?: string
            px?: string
        },
        ref
    ) => {
        const {
            label,
            highlightedText,
            onclick,
            noStyle,
            showArrow,
            labelFontSize,
            highlightedTextFontSize,
            border,
            height,
            maxWidth,
            disabled,
            id,
            bg = 'transparent',
            borderWidth,
            borderColor,
        } = props

        return (
            <StyledDropdownBadge
                ref={ref}
                display="flex"
                bg={bg || 'gray250'}
                gridColumnGap="8px"
                alignItems="center"
                py={!noStyle ? '4px' : '0px'}
                px={!noStyle ? '12px' : '0px'}
                borderRadius="4px"
                onClick={onclick}
                shouldHover={!noStyle}
                border={border || 'unset'}
                height={height || 'auto'}
                borderColor={borderColor || 'gray250'}
                borderWidth={borderWidth || 1}
                disabled={disabled}
                className={disabled ? `cursor-disabled` : ''}
                id={id}
            >
                {label ? (
                    <Text
                        fontFamily="Rubik"
                        color={disabled ? '#817E7E' : 'textTags'}
                        fontSize={labelFontSize ? labelFontSize : '12px'}
                        lineHeight="14px"
                        my="0px"
                    >
                        {label}
                    </Text>
                ) : null}
                <Box
                    fontFamily="Rubik"
                    color="primary"
                    fontSize="12px"
                    lineHeight="18px"
                    my="0px"
                    display="flex"
                    alignItems="center"
                    gridColumnGap="10px"
                    className={disabled ? `cursor-not-allowed` : ''}
                >
                    <Text
                        fontFamily="Rubik"
                        color={disabled ? '#817E7E' : 'primary'}
                        fontSize={
                            highlightedTextFontSize
                                ? highlightedTextFontSize
                                : '12px'
                        }
                        lineHeight="18px"
                        my="0px"
                        className="text-ellipsis"
                        maxWidth={maxWidth || '110px'}
                        id={id}
                    >
                        {highlightedText}
                    </Text>
                    {(!noStyle || (noStyle && showArrow)) && (
                        <Box display="flex" id={id}>
                            <DropDownArrow
                                color={disabled ? '#817E7E' : '#000000'}
                            />
                        </Box>
                    )}
                </Box>
            </StyledDropdownBadge>
        )
    }
)

DropdownBadge.displayName = 'DropdownBadge'
