import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'src/helpers'
import styled from 'styled-components'
import { SearchIco, CloseIco } from '../svg-icons'
import { Box, Button, Input } from '../utility'

const StyledSearch = styled(Box)`
    transition: 0.3s ease-in-out;
    & input {
        transition: 0.3s ease-in-out;
    }
`

export const BasicSearch = ({
    placeholder = 'Search Teams',
    setSearch,
    searchValue = '',
}: {
    placeholder?: string
    setSearch: (str: string) => void
    searchValue?: string
}) => {
    const [expanded, setExpanded] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const dropdown = useRef<any>(null)
    const [query, setQuery] = useState('')
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const debounceFn = debounce(
        (str: string) => {
            setSearch(str)
        },
        500,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        setQuery(searchValue)
        if (searchValue) {
            setExpanded(true)
        }
    }, [searchValue])

    if (expanded) {
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    function disableDropdown(event: Event) {
        if (dropdown !== null && dropdown.current !== null) {
            const isClickInsideElement = dropdown.current.contains(event.target)
            if (!isClickInsideElement) {
                setExpanded(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    return (
        <StyledSearch
            ref={dropdown}
            className="search__input"
            position="relative"
            bg={expanded || query ? 'gray250' : 'transparent'}
            borderRadius="4px"
            display="flex"
            alignItems="center"
            p="4px"
            gridColumnGap="4px"
        >
            <Button
                className="search__input"
                onClick={() => setExpanded(true)}
                display="flex"
                alignItems="center"
                bg="transparent"
                border="none"
                p="0px"
            >
                <SearchIco id="search__input" color="#778CA2" />
            </Button>
            <Input
                autoComplete="false"
                className="search__input"
                value={query}
                ref={inputRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setQuery(e.target.value)
                    debounceFn(e.target.value)
                }}
                onFocus={() => setExpanded(true)}
                width={expanded || query ? '150px' : '0px'}
                textAlign="left"
                border="none"
                bg="transparent"
                placeholder={placeholder}
            />
            {query && expanded && (
                <Button
                    bg="transparent"
                    border="none"
                    color="primary"
                    onClick={() => {
                        setQuery('')
                        debounceFn('')
                    }}
                >
                    <CloseIco color="#778CA2" />
                </Button>
            )}
        </StyledSearch>
    )
}
