import { useEffect, useState } from 'react'
import { Line } from 'rc-progress'
import { Logo } from 'src/assets/svgs-components'
import { Box, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { useNavigate } from 'react-router-dom'
import { getTokens } from 'src/config/firebase'

const Loading = () => {
    const [percentage, setPercentage] = useState(10)
    const { setIsLoading } = useStore()
    const navigate = useNavigate()
    useEffect(() => {
        ;(async function () {
            try {
                await getTokens(
                    () => {},
                    (pc) => setPercentage(pc)
                )
            } catch (e) {
                navigate('/login')
            }
            setIsLoading(false)
        })()
        // const intervalRef = setTimeout(() => {
        //     if (percentage < 100) {
        //         setPercentage((percentage) => percentage + 3)
        //     } else {
        //         clearTimeout(intervalRef)
        //         setIsLoading(false)
        //     }
        // }, 10)

        // return () => clearTimeout(intervalRef)
    }, [])

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="primary"
            zIndex={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridRowGap="16px"
                width="100%"
            >
                <Logo />
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gridColumnGap="8px"
                >
                    <Line
                        percent={percentage}
                        strokeWidth={1}
                        trailWidth={1}
                        style={{ width: '35%' }}
                        strokeColor="#EAFC40"
                        trailColor="#EBECEF10"
                    />
                    <Text color="textSecondaryDarkBlue" fontFamily="Rubik">
                        {percentage}%
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default Loading
