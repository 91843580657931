import { startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'
import { defaultDropdownList } from './enums';


export const durationFilter = (key: string = defaultDropdownList.thisMonth) => {

    let currentDate = new Date();

    const lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const last3Month = new Date();
    last3Month.setDate(1);
    last3Month.setMonth(last3Month.getMonth() - 3);

    const last6Month = new Date();
    last6Month.setDate(1);
    last6Month.setMonth(last6Month.getMonth() - 6);

    const lastYear = new Date();
    lastYear.setDate(1);
    lastYear.setFullYear(lastYear.getFullYear() - 1);

    switch (key) {

        case defaultDropdownList.thisMonth:
            return {
                from: startOfMonth(currentDate),
                to: endOfMonth(currentDate)
            }

        case defaultDropdownList.pastMonth:
            return {
                from: startOfMonth(lastMonth),
                to: endOfMonth(lastMonth)
            }

        case defaultDropdownList.past3Months:
            return {
                from: startOfMonth(last3Month),
                to: endOfMonth(lastMonth)
            }

        case defaultDropdownList.past6Months:
            return {
                from: startOfMonth(last6Month),
                to: endOfMonth(lastMonth)
            }

        case defaultDropdownList.pastYear:
            return {
                from: startOfYear(lastYear),
                to: endOfYear(lastYear)
            }

        case defaultDropdownList.allTime:
            return {
                from: "",
                to: ""
            }

        default:
            return {
                from: startOfMonth(currentDate),
                to: endOfMonth(currentDate)
            }

    }

}

// import { format, formatDistance, formatRelative, subDays, startOfMonth } from 'date-fns'
// let allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// let allWeeksNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
