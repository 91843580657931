import * as React from 'react'
import { VCalendar } from 'src/components/calendar/calendar'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { CalendarIcoSm } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { getFormattedDate } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'

interface ICalendarDropdownProps {
    date?: Date | null
    setDate: (date: Date) => void
    isDelayed?: boolean
    left?: string
    minDate?: Date
    isListCard?: boolean
}

export default function CalendarDropdown(props: ICalendarDropdownProps) {
    const { left = '0px' } = props
    const [isCalendarActive, setCalendarActive] = React.useState(false)
    const dropdownRef = useClickedOutside(() => setCalendarActive(false))
    const { isDelayed = false } = props
    return (
        <Box position="relative" ref={dropdownRef} width={'max-content'}>
            <HtmlTooltip
                placement="bottom"
                arrow
                title={<ToolTipHeader text={'Due Date'} />}
            >
                <Box
                    border="solid"
                    borderColor={isDelayed ? 'delay' : 'primary'}
                    borderRadius={props.isListCard ? '2px' : '4px'}
                    px="4px"
                    py="4px"
                    borderWidth={1}
                    display="flex"
                    alignItems="center"
                    minWidth={{ md: '100px', xl: '115px' }}
                    width={{ md: '100px', xl: '115px' }}
                    gridColumnGap="4px"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        console.log({ date: props.date })
                        setCalendarActive(true)
                    }}
                    bg={isDelayed ? '#FF2C181A' : 'inherit'}
                >
                    <CalendarIcoSm />
                    <Text
                        my="0px"
                        fontSize={
                            props.isListCard
                                ? { md: '10px', xl: '12px' }
                                : '12px'
                        }
                        color={'primary'}
                    >
                        {props.date
                            ? getFormattedDate(props.date, 'FORMATTED')
                            : 'No Due Date'}
                    </Text>
                </Box>
            </HtmlTooltip>
            <VCalendar
                date={
                    props.date
                        ? new Date(props?.date || '') ||
                          new Date(new Date().setUTCHours(24, 59, 59, 999))
                        : null
                }
                minDate={props.minDate || new Date('1900-01-01')}
                setDate={(date) => props.setDate(date)}
                disableCallback={() => setCalendarActive(false)}
                left={left}
                top="28px"
                isCalendarVisible={isCalendarActive}
            />
        </Box>
    )
}
