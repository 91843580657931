const DEBUG = process.env["REACT_APP_ENVIORNMENT"] === 'production' ? false : true

/**
 * @param args
 * @type `any[]`
 * @description extension of debug_log function to handle production environment logging.
 */
export function debugLog(...args: any[]) {
    if (DEBUG) {
        console.log.apply({}, args)
    }
}