import React, { useEffect, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import ProductHeader from './product-header'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import { SortDropdown } from 'src/components/collections/actions/sort/sort-dropdown'
import { DueDateDropdown } from 'src/components/collections/actions/due-date.tsx/due-date-dropdown'
import { ManagerDropdown } from 'src/components/collections/actions/manager/manager-dropdown'
import { Slider } from 'src/components/slider/slider'
import { Tab, Tabs } from '@mui/material'
import { statuses, useUser } from 'src/helpers'
import { CardLoader } from 'src/components/collections/card-loader'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GetProductVariantDetails } from '../../services/graphql/entities/product/product.queries'
import HeaderLoader from '../header-loader/header-loader'
import TodoListCard from '../entity-list-card/todo-list.card/todo-list.card'
import { sortObject } from './product-section.helper'
import type {
    GetProductQuery,
    GetProductQueryVariables,
} from 'src/generated/graphql'

function ProductSection({
    productId,
    isSplitMode,
    isCompareSection,
}: {
    productId: number
    isSplitMode: boolean
    isCompareSection?: boolean
}) {
    const { organization_id } = useUser()
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentTab, setCurrentTab] = useState('all')
    const [isCreateTodoModalVisible, setIsCreateTodoModalVisible] =
        useState(false)
    const [dueDate, setDueDate] = useState<
        [Date | null, Date | null] | Date | null
    >(null)
    const [resourcesCount, setResourcesCount] = useState<
        Record<string, number>
    >({
        new: 0,
        inProgress: 0,
        inReview: 0,
        complete: 0,
    })
    const [managers, setManagers] = useState<{ id: number; name: string }[]>([])
    const { sort: activeSort = 'created_at' } = Object.fromEntries(
        searchParams.entries()
    )

    const navigate = useNavigate()

    const {
        data: productDetails,
        loading: isProductLoading,
        refetch,
    } = useQuery<GetProductQuery, GetProductQueryVariables>(
        GetProductVariantDetails,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                id: productId,
                organisation_id: organization_id,
                order_by:
                    sortObject[
                        activeSort as 'due_date' | 'name' | 'created_at'
                    ],
                due_date: dueDate
                    ? Array.isArray(dueDate)
                        ? {
                              _gte: dueDate[0]?.toISOString(),
                              _lt: dueDate[1]?.toISOString(),
                          }
                        : {
                              _eq: new Date(
                                  new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                              ).toISOString(),
                          }
                    : undefined,
                assignee_ids:
                    managers.length > 0
                        ? { _in: managers.map((manager) => manager.id) }
                        : {},
            },
            onCompleted: (data) => {
                // If the product is not found, navigate to 404
                if (!data.product_variants) {
                    if (!isCompareSection) {
                        navigate('/404', { replace: true })
                    } else {
                        searchParams.delete('compareEntity')
                        searchParams.delete('compareId')
                        setSearchParams(searchParams, { replace: true })
                    }
                }
            },
        }
    )

    const product = productDetails?.product_variants[0]
    const todos = product?.todos
    const currentTabTodos = todos?.filter((todo) => {
        if (currentTab === 'all') {
            return true
        } else if (todo.status === currentTab) {
            return true
        } else {
            return false
        }
    })
    const totalTodos = todos?.length || 0

    const resetFilters = () => {
        setManagers([])
        setDueDate(null)
    }

    if (
        !isProductLoading &&
        productDetails?.product_variants &&
        productDetails?.product_variants.length <= 0
    ) {
        navigate('/404', { replace: true })
    }

    useEffect(() => {
        if (todos) {
            setResourcesCount({
                new: todos.filter((todo) => todo.status === statuses.new)
                    .length,
                inProgress: todos.filter(
                    (todo) => todo.status === statuses.inProgress
                ).length,
                inReview: todos.filter(
                    (todo) => todo.status === statuses.inReview
                ).length,
                complete: todos.filter(
                    (todo) => todo.status === statuses.complete
                ).length,
            })
        }
    }, [todos])

    function todoCardClickHandler(todoId: number) {
        if (isCompareSection) {
            searchParams.set('compareEntity', 'todo')
            searchParams.set('compareId', todoId.toString())
            setSearchParams(searchParams, { replace: true })
        }
    }

    return (
        <Box
            borderRight={
                isSplitMode && !isCompareSection ? '4px solid' : 'none'
            }
            borderColor="primary"
        >
            {product ? (
                <ProductHeader
                    key={product?.id}
                    product={product}
                    isSplitMode={isSplitMode}
                    refetchProduct={refetch}
                    isCompareSection={isCompareSection}
                />
            ) : isProductLoading ? (
                <HeaderLoader />
            ) : null}
            <Box
                bg="#F8FAFB"
                display="grid"
                height="100%"
                overflow="hidden"
                gridTemplateRows={isSplitMode ? '1fr' : '33px 1fr'}
                gridGap="20px"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                py="20px"
                px="24px"
            >
                {/* Sorting and filters */}
                {!isSplitMode && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box display="flex" gridGap="16px" alignItems="center">
                            <Text my="0px" color="primary" fontSize="16px">
                                {totalTodos} To-dos
                            </Text>
                            <>
                                <Button
                                    height="100%"
                                    bg="transparent"
                                    fontStyle="Rubik"
                                    color="links"
                                    position="relative"
                                    onClick={() =>
                                        setIsCreateTodoModalVisible(true)
                                    }
                                    border="none"
                                >
                                    + Create New
                                </Button>
                                {isCreateTodoModalVisible && (
                                    <CreateTodoModal
                                        isOpen={isCreateTodoModalVisible}
                                        onClose={() =>
                                            setIsCreateTodoModalVisible(false)
                                        }
                                        initialParenProduct={
                                            !!product
                                                ? {
                                                      id: product.id,
                                                      name: product.name,
                                                  }
                                                : undefined
                                        }
                                        initialParentCollection={
                                            !!product
                                                ? {
                                                      id: product.collection.id,
                                                      name: product.collection
                                                          .name,
                                                  }
                                                : undefined
                                        }
                                    />
                                )}
                            </>
                        </Box>
                        <Box display="flex" gridGap="16px" alignItems="center">
                            {(managers.length > 0 || dueDate) && (
                                <Button
                                    height="20px"
                                    bg="transparent"
                                    border="none"
                                    fontStyle="Rubik"
                                    fontSize="12px"
                                    color="links"
                                    position="relative"
                                    width="max-content"
                                    onClick={resetFilters}
                                >
                                    Reset Filters
                                </Button>
                            )}
                            <SortDropdown />
                            <DueDateDropdown
                                setDueDate={setDueDate}
                                dueDate={dueDate}
                            />
                            <ManagerDropdown
                                setManagers={setManagers}
                                managers={managers}
                            />
                        </Box>
                    </Box>
                )}
                <Box
                    maxHeight="100%"
                    overflow="hidden"
                    display="grid"
                    gridTemplateRows="33.5px 1fr"
                >
                    <Slider
                        element={
                            <Box>
                                <Tabs
                                    value={currentTab}
                                    variant="scrollable"
                                    scrollButtons={false}
                                    onChange={(_event: any, newValue: any) => {
                                        setCurrentTab(newValue)
                                    }}
                                    aria-label="collection-tabs"
                                    classes={{
                                        root: 'collection-slider-root',
                                    }}
                                >
                                    <Tab
                                        label={`All (${todos?.length || 0})`}
                                        value={'all'}
                                    />
                                    <Tab
                                        label={`New (${resourcesCount.new})`}
                                        value={statuses.new}
                                    />
                                    <Tab
                                        label={`In Progress (${resourcesCount.inProgress})`}
                                        value={statuses.inProgress}
                                    />
                                    {(resourcesCount.inReview ||
                                        resourcesCount.inReview === 0) && (
                                        <Tab
                                            label={`In Review (${resourcesCount.inReview})`}
                                            value={statuses.inReview}
                                        />
                                    )}
                                    <Tab
                                        label={`Completed (${resourcesCount.complete})`}
                                        value={statuses.complete}
                                    />
                                </Tabs>
                            </Box>
                        }
                    />
                    <Box height="100%" className="scrollbar_none">
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            mt="8px"
                            height={window.innerHeight - 350 + 'px'}
                            overflowY="auto"
                        >
                            {product && currentTabTodos ? (
                                currentTabTodos.length > 0 ? (
                                    currentTabTodos.map((todo) => {
                                        return (
                                            <TodoListCard
                                                key={todo.id}
                                                todo={{
                                                    id: todo.id,
                                                    name: todo.name,
                                                    status: todo.status,
                                                    product: {
                                                        id: product.id,
                                                        name: product.name,
                                                        coverPic:
                                                            product.other_data
                                                                ?.cover_pic,
                                                    },
                                                    collection: {
                                                        id: product?.collection
                                                            ?.id,
                                                        name: product
                                                            ?.collection?.name,
                                                        manager_id:
                                                            product?.collection
                                                                ?.manager_id,
                                                    },
                                                    assignee: todo.assignee,
                                                    coverPic:
                                                        todo.other_data
                                                            ?.cover_pic,
                                                    dueDate: todo.due_date,
                                                    isDelayed: todo.is_delayed,
                                                }}
                                                isFullCard={!isSplitMode}
                                                onClick={() =>
                                                    todoCardClickHandler(
                                                        todo.id
                                                    )
                                                }
                                                navigateTo={
                                                    !isCompareSection
                                                        ? `/todo/${
                                                              todo.id
                                                          }?${searchParams.toString()}`
                                                        : `/todo/${todo.id}`
                                                }
                                                isCompareSection={
                                                    isCompareSection
                                                }
                                            />
                                        )
                                    })
                                ) : (
                                    <EntityEmptyState entityName="Todos" />
                                )
                            ) : isProductLoading ? (
                                <CardLoader />
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ProductSection
