export function SearchIco({ color, id }: { color?: string; id?: string }) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            id={id}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.663 4C6.99 4 4 6.99 4 10.663c0 3.671 2.99 6.662 6.663 6.662a6.627 6.627 0 004.212-1.508l3.978 3.978a.666.666 0 10.942-.942l-3.978-3.978a6.627 6.627 0 001.508-4.212C17.325 6.99 14.335 4 10.663 4zm0 1.333a5.32 5.32 0 015.33 5.33 5.32 5.32 0 01-5.33 5.33 5.32 5.32 0 01-5.33-5.33 5.32 5.32 0 015.33-5.33z"
                fill={color || '#C2CFE0'}
            />
        </svg>
    )
}
