import * as React from 'react'
import {
    Checkbox,
    Dropdown,
    DropdownBadge,
    DropdownButtonWrapper,
} from 'src/components'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { SearchIco } from 'src/components/svg-icons'
import { Box, Input, Text } from 'src/components/utility'
import {
    useCollectionFilter,
    useSearchCollection,
} from '../hooks/use-collections'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export interface ICollectionSearchProps {
    selectedCollections: {
        id: string
        name: string
    }[]
    onSelect: (collections: { id: string; name: string }) => void
    onUnselect: (collections: { id: string; name: string }) => void
    type?: 'product' | 'todos'
}

export default function CollectionSearch(props: ICollectionSearchProps) {
    const {
        onSelect,
        selectedCollections,
        onUnselect,
        type = 'product',
    } = props
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
    const { searchFunction, searchedCollections } = useSearchCollection()
    const dropdownRef = useClickedOutside(() => setIsDropdownOpen(false))
    const { filters, addFilter, updateFilters } = useCollectionFilter(type)

    React.useEffect(() => {
        updateFilters(selectedCollections)
    }, [selectedCollections])

    return (
        <Box width="100%" ref={dropdownRef} position="relative">
            <DropdownBadge
                label="Search Collections"
                onclick={() => setIsDropdownOpen(true)}
                maxWidth="100%"
                highlightedText={
                    selectedCollections.length ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="4px"
                        >
                            <Text my="0px" fontSize="12px">
                                {convertIntoElipses(
                                    selectedCollections[0].name,
                                    10
                                )}
                            </Text>
                            {selectedCollections.length > 1 && (
                                <Text my="0px" fontSize="12px">
                                    +{selectedCollections.length - 1}
                                </Text>
                            )}
                        </Box>
                    ) : (
                        'No collection selected'
                    )
                }
            ></DropdownBadge>
            {isDropdownOpen && (
                <Dropdown right="0px" active={isDropdownOpen}>
                    <>
                        <DropdownButtonWrapper width="100%" hover={false}>
                            <Box
                                border="solid"
                                borderWidth={1}
                                borderRadius="4px"
                                borderColor="gray250"
                                display="flex"
                                alignItems="center"
                                px="12px"
                                py="12px"
                                gridColumnGap="12px"
                                width="100%"
                            >
                                <SearchIco />
                                <Input
                                    width="100%"
                                    border="none"
                                    placeholder="Search"
                                    bg="white"
                                    className="placeholder-light"
                                    onChange={(e) =>
                                        searchFunction(
                                            e.target.value,
                                            filters[type]
                                                ? filters[type]?.map(
                                                      (filter) => filter.id
                                                  )
                                                : undefined
                                        )
                                    }
                                />
                            </Box>
                        </DropdownButtonWrapper>
                        <Box
                            maxHeight="400px"
                            overflowY="scroll"
                            className="scrollbar_none"
                            mt="8px"
                            width="100%"
                        >
                            {selectedCollections.map((collection) => {
                                return (
                                    <DropdownButtonWrapper
                                        // onClick={() => onSelect(collection)}
                                        width="100%"
                                    >
                                        <Checkbox
                                            key={collection.id + 'checkbox'}
                                            label={convertIntoElipses(
                                                collection.name,
                                                100
                                            )}
                                            onChange={() =>
                                                onUnselect(collection)
                                            }
                                            checkBoxId={collection.id}
                                            checked={selectedCollections.includes(
                                                collection
                                            )}
                                        />
                                    </DropdownButtonWrapper>
                                )
                            })}
                            {searchedCollections?.map((collection) => {
                                const isSelected =
                                    selectedCollections.includes(collection)
                                if (isSelected) return <></>
                                return (
                                    <DropdownButtonWrapper width="100%">
                                        <Checkbox
                                            label={convertIntoElipses(
                                                collection.name,
                                                50
                                            )}
                                            onChange={() => {
                                                onSelect(collection)
                                                addFilter(collection)
                                            }}
                                            checkBoxId={collection.id}
                                            checked={isSelected}
                                        />
                                    </DropdownButtonWrapper>
                                )
                            })}
                        </Box>
                    </>
                </Dropdown>
            )}
        </Box>
    )
}
