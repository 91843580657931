import { useEffect } from 'react'
import { getImageUrl, getLocalStorage } from 'src/helpers'
import useStore from 'src/store/hooks/useStore'
import { ProfileImg } from '../profile-img/profile-img'
import { Box, Text } from '../utility'
import { CONFIG_VARS } from 'src/config/constants.config'
export const SidebarProfile = ({
    setIsModal,
    showName,
}: {
    setIsModal: (x: boolean) => void
    showName?: boolean
}) => {
    const authToken = getLocalStorage('authToken')

    const { profileImage, profileName, setProfile } = useStore()

    useEffect(() => {
        const user = getLocalStorage('user')
        if (user) {
            const data = JSON.parse(user)
            setProfile({
                profileImage: data.profileImage
                    ? getImageUrl(data.profileImage)
                    : '',
                profileName: data.name,
            })
        }
    }, [])

    return (
        <Box
            display="flex"
            gridColumnGap="8.35px"
            pl="20px"
            width="100%"
            alignItems="center"
            fontFamily="Rubik"
            justifyContent="start"
            justifySelf="flex-end"
            flexDirection="row"
            color="#fff"
            borderColor="borderLine"
            borderStyle="solid"
            borderLeft="none"
            borderWidth={1}
            py="12px"
            className="cursor-pointer"
            onClick={() => {
                setIsModal(true)
            }}
        >
            <ProfileImg
                src={
                    CONFIG_VARS.API_ENDPOINT &&
                    profileImage.includes(CONFIG_VARS.API_ENDPOINT)
                        ? profileImage
                        : profileImage.length > 0
                        ? getImageUrl(profileImage)
                        : ''
                }
                name={profileName}
            ></ProfileImg>

            <Text
                overflow="hidden"
                width="100px"
                className="overflow__elipses"
                cursor="pointer"
                ml={showName ? '0px' : '200px'}
            >
                {profileName}
            </Text>
        </Box>
    )
}
