import { Box } from '../utility'

export const DivisionLine = ({
    width,
    color,
}: {
    width?: 1 | 2 | 3 | 'none'
    color?: string
}) => {
    return (
        <Box
            border="solid"
            borderWidth={width || 1}
            borderColor={color || '#E8ECEF'}
            borderBottom="none"
            borderRight="none"
            borderLeft="none"
            width="100%"
        ></Box>
    )
}
