import { debugLog } from 'src/helpers'
import type { IndividualContributors, Team } from './@types/people-types'
import type { storeState } from './@types/slider-types'

const aggregatedDetails = {
    aggregate: {
        count: 0,
    },
    nodes: [
        {
            id: 0,
            name: '',
        },
    ],
}
const aggregatedTodos = {
    todos_aggregate: {
        aggregate: {
            count: 0,
        },
    },
}

const TeamListInitialState = [
    {
        collectionsCompleted: aggregatedDetails,
        collectionsDelayed: aggregatedDetails,
        productsCompleted: aggregatedDetails,
        productsDelayed: aggregatedDetails,
        totalProducts: aggregatedDetails,
        todos_aggregate: [aggregatedTodos],
        todosCompleted: [aggregatedTodos],
        todosDelayed: [aggregatedTodos],
        team_members: [
            {
                id: 0,
                user: {
                    email: '',
                    name: '',
                    id: 0,
                    files: [
                        {
                            path: '',
                        },
                    ],
                    assigned_todos: [
                        {
                            name: '',
                            status: '',
                        },
                    ],
                    organisation_members: [
                        {
                            role: '',
                        },
                    ],
                },
            },
        ],
        id: 0,
        name: '',
    },
]

const createPeopleSlice = (set: Function) => ({
    teamList: TeamListInitialState,
    individualContributorList: [
        {
            role: '',
            id: 0,
            createdAt: '',
            organisation_id: 0,
            user: {
                role: '',
                email: '',
                name: '',
                id: 0,
                team_members: [
                    {
                        team: [
                            {
                                name: '',
                            },
                        ],
                    },
                ],
                todosCompleted: aggregatedDetails,
                todosDelayed: aggregatedDetails,
                totalTodos: aggregatedDetails,
                todos: [
                    {
                        name: '',
                        status: '',
                        due_date: '',
                        tags: [''],
                    },
                ],
                files: [
                    {
                        path: '',
                    },
                ],
            },
        },
    ],
    cloneOfIndividualContributors: [],
    teamListTotalCount: 0,
    contributorsTotalCount: 0,

    setTeams: (payload: { team: TeamAggregated }) => {
        set((state: any) => ({
            ...state,
            teamList: payload.team,
        }))
    },

    setTeamsListCount: (payload: { teamListTotalCount: number }) => {
        set((state: any) => ({
            ...state,
            teamListTotalCount: payload.teamListTotalCount,
        }))
    },

    setContributorsCount: (payload: { contributorsTotalCount: number }) => {
        set((state: any) => ({
            ...state,
            contributorsTotalCount: payload.contributorsTotalCount,
        }))
    },

    setIndividualContributors: (payload: {
        individualContributorList: IndividualContributors
    }) => {
        set((state: any) => ({
            ...state,
            individualContributorList: payload.individualContributorList,
            cloneOfIndividualContributors: payload.individualContributorList,
        }))
    },

    removeIndividualContributor: (payload: { id: string }) => {
        set((state: any) => {
            return {
                ...state,
                individualContributorList:
                    state.individualContributorList.filter(
                        (contributor: any) => contributor.id !== payload.id
                    ),
            }
        })
    },
    removeTeam: (payload: { id: string }) => {
        set((state: storeState) => {
            return {
                ...state,
                teamList: state.teamList.filter(
                    (team: any) => team.id !== payload.id
                ),
            }
        })
    },
    sort: () => {
        set((state: storeState) => {
            return {
                ...state,
                teamList: state.teamList
                    .slice()
                    .sort((a: Team, b: Team) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    ),
            }
        })
    },
    sortIndividualContributor: (
        payload: 'LOW-TO-HIGH' | 'HIGH-TO-LOW' | 'NAME'
    ) => {
        set((state: any) => {
            if (payload === 'HIGH-TO-LOW') {
                return {
                    ...state,
                    individualContributorList: state.individualContributorList
                        .slice()
                        .sort(
                            (a: any, b: any) =>
                                b.user.team_members.length -
                                a.user.team_members.length
                        ),
                }
            } else if (payload === 'LOW-TO-HIGH') {
                debugLog('LOW TO HIGH')
                return {
                    ...state,
                    individualContributorList: state.individualContributorList
                        .slice()
                        .sort((a: any, b: any) => {
                            return (
                                a.user.team_members.length -
                                b.user.team_members.length
                            )
                        }),
                }
            } else if (payload === 'NAME') {
                return {
                    ...state,
                    individualContributorList: state.individualContributorList
                        .slice()
                        .sort((a: any, b: any) =>
                            a.user.name
                                .toLowerCase()
                                .localeCompare(b.user.name.toLowerCase())
                        ),
                }
            }
        })
    },
    sortIndividualContributorRoles: (
        payload: 'ALL' | 'ADMIN' | 'MANAGER' | 'DESIGNER'
    ) => {
        set((state: any) => {
            if (payload === 'ALL') {
                return {
                    ...state,
                    individualContributorList:
                        state.cloneOfIndividualContributors,
                }
            } else if (payload === 'ADMIN') {
                return {
                    ...state,
                    individualContributorList:
                        state.cloneOfIndividualContributors
                            .slice()
                            .filter(
                                (contributor: any) =>
                                    contributor.role === 'admin'
                            ),
                }
            } else if (payload === 'MANAGER') {
                return {
                    ...state,
                    individualContributorList:
                        state.cloneOfIndividualContributors
                            .slice()
                            .filter(
                                (contributor: any) =>
                                    contributor.role === 'manager'
                            ),
                }
            } else {
                return {
                    ...state,
                    individualContributorList:
                        state.cloneOfIndividualContributors
                            .slice()
                            .filter(
                                (contributor: any) =>
                                    contributor.role === 'designer'
                            ),
                }
            }
        })
    },
})

export default createPeopleSlice
