import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { debounce } from 'src/helpers'
import {
    InsertTemplateSubtodoSection,
    UpdateTemplateSubtodo,
    UpdateTemplateSubtodoSection,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'

function useUpdateTemplate() {
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [updateSubtodoMutation] = useMutation(UpdateTemplateSubtodo)
    const [insertTemplateSubtodoSectionMutation] = useMutation(
        InsertTemplateSubtodoSection
    )
    const [updateTemplateSubtodoSection] = useMutation(
        UpdateTemplateSubtodoSection
    )

    function updateTemplateSubtodo({
        id,
        payload,
        refetchQueries,
        onCompleted,
    }: {
        id: number
        payload: any
        refetchQueries?: any
        onCompleted?: () => void
    }) {
        updateSubtodoMutation({
            variables: {
                id,
                object: payload,
            },
            refetchQueries: refetchQueries,
            onCompleted: onCompleted,
        })
    }

    const debouncedUpdateTemplateSubtodo = debounce(
        updateTemplateSubtodo,
        400,
        setTimeOutRef,
        timeOutRef
    )

    function insertTemplateSubtodoSection({
        templateId,
        templateSubtodoId,
        refetchQueries,
        onCompleted,
    }: {
        templateId: number
        templateSubtodoId: number
        refetchQueries?: any
        onCompleted?: () => void
    }) {
        insertTemplateSubtodoSectionMutation({
            variables: {
                object: {
                    template_id: templateId,
                    template_sub_todo_id: templateSubtodoId,
                },
            },
            refetchQueries: refetchQueries,
            onCompleted: onCompleted,
        })
    }

    function updateTemplateSection({
        payload,
        sectionId,
        onCompleted,
    }: {
        payload: any
        sectionId: number
        onCompleted?: () => void
    }) {
        updateTemplateSubtodoSection({
            variables: {
                id: sectionId,
                payload,
            },
            refetchQueries: ['GetTodoTemplateById'],
            onCompleted,
        })
    }

    const debouncedUpdateTemplateSectionHandler = debounce(
        updateTemplateSection,
        400,
        setTimeOutRef,
        timeOutRef
    )

    return {
        updateTemplateSubtodo,
        debouncedUpdateTemplateSubtodo,
        insertTemplateSubtodoSection,
        updateTemplateSection,
        debouncedUpdateTemplateSectionHandler,
    }
}

export default useUpdateTemplate
