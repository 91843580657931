import React from 'react'

function AttachmentIco() {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5855 4C13.6004 4 12.6162 4.39029 11.8782 5.16885L6.52376 10.8172C4.5217 12.9299 4.5217 16.3261 6.52376 18.4389C6.58548 18.5039 6.65965 18.5559 6.74185 18.5917C6.74316 18.5921 6.74446 18.5925 6.74577 18.593C8.76287 20.5044 11.9092 20.483 13.8464 18.4389L17.6128 14.4648C17.6765 14.4017 17.727 14.3265 17.7611 14.2436C17.7952 14.1607 17.8123 14.0718 17.8113 13.9821C17.8104 13.8925 17.7914 13.8039 17.7556 13.7217C17.7197 13.6396 17.6677 13.5654 17.6026 13.5038C17.5375 13.4421 17.4607 13.3942 17.3767 13.3628C17.2927 13.3314 17.2033 13.3172 17.1137 13.3211C17.0241 13.325 16.9363 13.3468 16.8553 13.3854C16.7743 13.4239 16.702 13.4783 16.6424 13.5454L12.876 17.5194C11.3703 19.1084 9.00107 19.1084 7.4954 17.5194C5.98967 15.9305 5.98967 13.3256 7.4954 11.7366L12.8486 6.08825C13.828 5.05498 15.3444 5.05498 16.3238 6.08825C17.3031 7.12142 17.3031 8.82674 16.3238 9.85991L11.4512 15.0015C10.9972 15.4801 10.3346 15.4798 9.88142 15.0015C9.42822 14.5233 9.42822 13.718 9.88142 13.2398L13.8006 9.10375C13.8644 9.04072 13.9148 8.9655 13.9489 8.88259C13.9831 8.79967 14.0001 8.71074 13.9992 8.62109C13.9982 8.53144 13.9793 8.44289 13.9434 8.36072C13.9076 8.27854 13.8555 8.20442 13.7905 8.14275C13.7254 8.08108 13.6486 8.03313 13.5646 8.00175C13.4806 7.97037 13.3911 7.9562 13.3016 7.96008C13.212 7.96396 13.1241 7.98581 13.0432 8.02434C12.9622 8.06287 12.8898 8.11728 12.8303 8.18434L8.90977 12.3204C7.96012 13.3225 7.96012 14.9188 8.90977 15.9209C8.97316 15.9876 9.04963 16.0405 9.1344 16.0763C10.0977 16.8811 11.5367 16.8538 12.4215 15.9209L17.2941 10.7793C18.7092 9.28635 18.7296 6.94988 17.4313 5.38564C17.4 5.3052 17.3534 5.23158 17.2941 5.16885C16.5562 4.39029 15.5707 4 14.5855 4Z"
                fill="#778CA2"
            />
        </svg>
    )
}

export default AttachmentIco
