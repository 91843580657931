import { formatDistance, subDays } from 'date-fns'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAnnotationFileImageUrl } from 'src/components/annotation-comments/annotation-comments.helper'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Image, Text } from 'src/components/utility'
import { commentParser, getImageUrl, useUser } from 'src/helpers'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'

import EntityInfoList from 'src/screens-v2/notifications/card/entity-info-list'
import { entityParserV2 } from 'src/screens/dashboard/sub-screens/comments/cards/parser'
import { scrollToCard } from 'src/helpers/scroll-to-card'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'
import StarterKit from '@tiptap/starter-kit'
import { CustomMention } from 'src/components/comments/mention/mention'
import type { Content } from '@tiptap/react'
import { EditorContent, useEditor } from '@tiptap/react'

interface ICommentCardProps {
    comment: TAnnotationComment | TMentionedComment
    type: 'annotations' | 'comments'
    userSelfComment?: boolean
    index?: number
}
type TRouteReturnType = {
    path: string
    key: keyof TNotifcationEntityInfo
}

function CommentCard(props: ICommentCardProps, ref: any) {
    const { comment, type, userSelfComment } = props
    const { getLastOpenedTab, setLastOpened } = useCardExpandedState()

    const entityInfo = entityParserV2({
        text: comment.entity_info,
    }) as TNotifcationEntityInfo

    const tab = getLastOpenedTab('comment')

    let user = comment.user
    if (userSelfComment) {
        const selfUser = useUser()
        user = {
            name: selfUser.name,
            id: selfUser.userId,
            email: selfUser.email,
            created_at: selfUser.created_at,
            updated_at: null,
            other_data: { profile_pic: selfUser.profileImage, thumbnail: '' },
        }
    }

    React.useEffect(() => {
        if (!tab || tab !== Number(comment.id)) return
        scrollToCard(`dashboard_comment_card_${comment.id}`)
    }, [tab])

    const navigate = useNavigate()

    function getRoute(entityName: string): TRouteReturnType | undefined {
        if (entityName === 'todos') {
            return { path: '/todo', key: 'todo' }
        }
        if (entityName === 'product' || entityName === 'product_variants') {
            return { path: '/collections/product', key: 'product_variant' }
        }
        if (entityName === 'collections') {
            return { path: '/collections', key: 'collection' }
        }
        if (entityName === 'sub_todo_sections') {
            return { path: '/todo', key: 'todo' }
        }
        if (entityName === 'teams') {
            return { path: '/team', key: 'team' }
        }
        if (entityName === 'assigned_files') {
            return { path: '/todo', key: 'todo' }
        }
        return
    }

    function createUrl(
        entity: TMentionedComment | TAnnotationComment,
        dimension?: '2d' | '3d'
    ) {
        let URL = new URLSearchParams()
        const BASE_PATH = getRoute(entity.entity_name)
        if (dimension === '2d') {
            URL.append('sectionId', comment.entity_id)
            URL.append('commentId', comment.id)
            if (comment?.file_id || comment?.library_file_id) {
                URL.append(
                    'fileId',
                    comment.file_id || comment.library_file_id || ''
                )
            }
            URL.append('isLibraryFile', String(!!comment.library_file_id))
            URL.append('mode', 'annotate')
            if (BASE_PATH) {
                const ID = entityInfo[BASE_PATH?.key]?.id
                return BASE_PATH.path + '/' + ID + '?' + URL.toString()
            }
            return null
        } else {
            const file = entityInfo['file'] || entityInfo['library_file']

            if (file) {
                URL.append('gid', file.id)
                URL.append('fileId', comment.library_file_id || comment.file_id)
                URL.append('name', file.name)
                URL.append('entityName', comment.entity_name)
                URL.append('entityId', comment.entity_id)
                URL.append('commentId', comment.id)
                URL.append('productId', entityInfo['product_variant']?.id || '')

                URL.append(
                    'ref',
                    !!comment.library_file_id ? 'library' : 'notifications'
                )
                URL.append('mode', 'annotation')
                return '/file-preview?' + URL.toString()
            }
        }
    }

    function createNavigator() {
        const BASE_PATH = getRoute(comment.entity_name)
        const FILE = entityInfo['file'] || entityInfo['library_file']

        switch (comment.self_name) {
            case 'annotation':
            case 'annotations':
                const dimension = comment.file_dimention_type
                if (dimension) {
                    const route = createUrl(
                        comment,
                        comment.file_dimention_type
                    )
                    if (route) {
                        return navigate(route)
                    }
                }
                return
            case 'comments':
            case 'status':
            case 'others':
                if (!BASE_PATH?.key) return
                let URL = BASE_PATH?.path + '/' + entityInfo[BASE_PATH?.key]?.id
                const SEARCH_PARAMS = new URLSearchParams()
                const isAssignedFile = comment.entity_name === 'assigned_files'
                const isSubTodoSection =
                    comment.entity_name === 'sub_todo_sections' ||
                    isAssignedFile
                if (isSubTodoSection) {
                    SEARCH_PARAMS.append(
                        'sectionId',
                        isAssignedFile
                            ? entityInfo['sub_todo_section']?.id || ''
                            : comment.entity_id.toString()
                    )
                    SEARCH_PARAMS.append('showComments', '1')
                    if (FILE) {
                        if (comment?.file_id || comment?.library_file_id) {
                            SEARCH_PARAMS.append(
                                'fileId',
                                comment.file_id || comment.library_file_id || ''
                            )
                        } else if (isAssignedFile) {
                            SEARCH_PARAMS.append(
                                'fileId',
                                entityInfo['file']?.id || ''
                            )
                        }
                        SEARCH_PARAMS.append(
                            'isLibraryFile',
                            String(!!comment.library_file_id)
                        )
                    }
                }
                navigate(URL + '?' + SEARCH_PARAMS.toString(), {
                    state:
                        isSubTodoSection || isAssignedFile
                            ? undefined
                            : {
                                  isCommentNotification: true,
                              },
                })
        }
    }

    function createSubText(comment: TAnnotationComment | TMentionedComment) {
        // Self comment
        if (userSelfComment) {
            if (type === 'annotations') {
                return `${
                    comment.repliedComment
                        ? 'replied to an annotation on'
                        : 'annotated on'
                } "${
                    entityInfo['file']?.name || entityInfo['library_file']?.name
                }"`
            } else {
                return `commented on ${
                    entityInfo.collection?.name ||
                    entityInfo.product_variant?.name ||
                    entityInfo.todo?.name ||
                    entityInfo.team?.name ||
                    entityInfo.sub_todo_section?.name ||
                    ''
                } ${
                    entityInfo.collection?.name
                        ? 'collection'
                        : entityInfo.product_variant?.name
                        ? 'product'
                        : entityInfo.todo?.name
                        ? 'todo'
                        : entityInfo.team?.name
                        ? 'team'
                        : entityInfo.sub_todo_section?.name
                        ? 'sub todo section'
                        : ''
                }`
            }
        }

        // Other comment
        if (type === 'annotations') {
            return comment.repliedComment
                ? 'replied to your annotation'
                : `annotated on "${
                      entityInfo['file']?.name ||
                      entityInfo['library_file']?.name
                  }"`
        } else {
            return comment.repliedComment
                ? 'replied to your comment'
                : 'mentioned you in the comment'
        }
    }

    const distance = comment.created_at
        ? formatDistance(subDays(new Date(comment.created_at), 0), new Date(), {
              addSuffix: true,
          })
        : ''

    const extensions = [StarterKit, CustomMention]

    const editor = useEditor(
        {
            extensions,
            content: {
                type: 'doc',
                content: comment.blocks,
            } as Content,
            editable: false,
        },
        [comment]
    )

    if (!comment.id) return <></>
    return (
        <Box
            ref={ref}
            className={
                String(getLastOpenedTab('comment')) === String(comment.id)
                    ? 'card-hover card-bg cursor-pointer'
                    : 'card-hover cursor-pointer'
            }
            id={`dashboard_comment_card_${comment.id}`}
            border="solid"
            borderColor="gray250"
            borderWidth={1}
            px="16px"
            py="12px"
            onClick={() => {
                setLastOpened({
                    entityId: Number(comment.id),
                    type: 'comment',
                })
                createNavigator()
            }}
            display="flex"
            gridColumnGap="8px"
        >
            <Box display="flex" alignItems="start" pt="12px">
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    <Box
                        bg="#4D7CFE"
                        borderRadius="50%"
                        width="6px"
                        height="6px"
                    />
                    <ProfileImg
                        name={user?.name || ''}
                        src={getImageUrl(user?.other_data?.thumbnail)}
                    />
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                gridColumnGap="8px"
                mt="12px"
                width="100%"
                gridGap="16px"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                >
                    <Box display="flex" gridColumnGap="8px" alignItems="center">
                        <Text
                            fontSize="14px"
                            fontWeight={500}
                            my="0px"
                            gridColumnGap="8px"
                        >
                            {userSelfComment ? 'You' : user?.name || ''}
                        </Text>
                        <Text my="0px" fontSize="14px">
                            {createSubText(comment)}
                        </Text>
                    </Box>
                    <Text
                        color="textSecondaryDarkBlue"
                        fontSize="12px"
                        my="0px"
                    >
                        {distance}
                    </Text>
                </Box>
                {type === 'annotations' &&
                    comment.repliedComment &&
                    'parent' in comment && (
                        <Text
                            fontSize="14px"
                            color="textSecondaryDarkBlue"
                            mt="12px"
                            mb="4px"
                        >
                            "
                            {
                                commentParser(comment?.parent.comment || '')
                                    .parsed
                            }
                            "
                        </Text>
                    )}
                <Box>
                    {type === 'comments' ? (
                        <EditorContent
                            style={{
                                width: '100%',
                                height: '50px !important',
                                maxHeight: '200px',
                                textAlign: 'left',
                                overflow: 'auto',
                                fontSize: '14px',
                                wordBreak: 'break-all',
                                color: '#606060',
                            }}
                            editor={editor}
                            readOnly={true}
                        />
                    ) : (
                        <Text
                            fontSize="14px"
                            my={
                                !(
                                    type === 'annotations' &&
                                    comment.repliedComment &&
                                    'parent' in comment
                                )
                                    ? '12px'
                                    : '0px'
                            }
                        >
                            "{commentParser(comment?.comment || '').parsed}"
                        </Text>
                    )}
                    <Box display="flex" gridColumnGap="8px">
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_media.map(
                                (media, key) => {
                                    if (key < 2) {
                                        const nameChunks = media.name.split('.')
                                        const extension =
                                            nameChunks[nameChunks.length - 1]
                                        if (
                                            extension !== 'jpg' &&
                                            extension !== 'jpeg' &&
                                            extension !== 'png'
                                        ) {
                                            return (
                                                <HtmlTooltip
                                                    placement="bottom-start"
                                                    arrow
                                                    title={
                                                        <ToolTipHeader
                                                            text={
                                                                media?.name ||
                                                                ''
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        my="4px"
                                                    >
                                                        <ExtensionIcon
                                                            size="lg"
                                                            type={extension}
                                                        />
                                                    </Box>
                                                </HtmlTooltip>
                                            )
                                        }
                                        return (
                                            <HtmlTooltip
                                                placement="bottom-start"
                                                arrow
                                                title={
                                                    <ToolTipHeader
                                                        text={media?.name || ''}
                                                    />
                                                }
                                            >
                                                <Image
                                                    src={getAnnotationFileImageUrl(
                                                        media.gid,
                                                        'media'
                                                    )}
                                                    alt={media.name}
                                                    width="48px"
                                                    height="48px"
                                                    borderRadius="4px"
                                                />
                                            </HtmlTooltip>
                                        )
                                    }
                                    return <></>
                                }
                            )}
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_file_media &&
                            comment.other_data?.assigned_media?.length <= 0 &&
                            comment.other_data?.assigned_file_media.map(
                                (media, key) => {
                                    if (key < 2) {
                                        return (
                                            <Image
                                                src={getAnnotationFileImageUrl(
                                                    media.gid,
                                                    'file_media'
                                                )}
                                                alt={media.name}
                                                width="48px"
                                                height="48px"
                                                borderRadius="4px"
                                            />
                                        )
                                    }
                                    return <></>
                                }
                            )}
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_media?.length > 2 && (
                                <Box
                                    border="solid"
                                    borderRadius="4px"
                                    borderWidth={1}
                                    borderColor="textSecondaryDarkBlue"
                                    bg="gray250"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize="12px"
                                    width="48px"
                                    height="48px"
                                >
                                    +{' '}
                                    {comment.other_data.assigned_media.length +
                                        comment.other_data.assigned_file_media
                                            ?.length -
                                        2}
                                </Box>
                            )}
                    </Box>
                    <Box mt="8px" display="flex" gridColumnGap="12px">
                        <EntityInfoList entityInfo={entityInfo} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default React.forwardRef(CommentCard)
