import React from 'react'
import ProfileImgWithName from 'src/components-v2/UI/profile-img-with-name'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { CollectionIco, TodoIco } from 'src/components/svg-icons'
import { Box, Image, Text } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'

function TodoDropdownCard({
    name,
    collectionName,
    creator,
    imageUrl,
    onClick,
}: {
    name: string
    collectionName: string
    creator: {
        id: number
        name: string
        other_data?: {
            profile_pic?: string
        }
    } | null
    imageUrl: string
    onClick: () => void
}) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            pb="4px"
            borderBottom="1px solid"
            borderColor="gray250"
            className="cursor-pointer"
            onClick={onClick}
            gridGap="8px"
        >
            <Box display="flex" flexDirection="column" gridGap="4px">
                <Box display="flex" gridGap="8px" alignItems="center">
                    <TodoIco />
                    <Text my="0px" color="textSecondary">
                        {name}
                    </Text>
                </Box>
                <Box display="flex" gridGap="16px">
                    <Box display="flex" gridGap="12px" alignItems="center">
                        <CollectionIco />
                        <Text
                            my="0px"
                            color="textSecondary"
                            fontSize={'14px'}
                            maxWidth="300px"
                            title={collectionName}
                            className="text-ellipsis"
                        >
                            {convertIntoElipses(collectionName, 30)}
                        </Text>
                    </Box>
                    {creator && (
                        <ProfileImgWithName
                            imageSrc={
                                creator.other_data?.profile_pic
                                    ? getImageUrl(
                                          creator.other_data?.profile_pic
                                      )
                                    : ''
                            }
                            name={creator.name}
                            textColor="textSecondary"
                        />
                    )}
                </Box>
            </Box>
            <Box width={'52px'} height={'52px'}>
                <Image
                    src={imageUrl ? imageUrl : '/image-placeholder.jpg'}
                    alt="image"
                    width={'100%'}
                    height={'100%'}
                />
            </Box>
        </Box>
    )
}

export default TodoDropdownCard
