import { gql } from '@apollo/client'

export const UpdateTodo = gql`
    mutation UpdateTodo($id: bigint!, $payload: todos_set_input!) {
        update_todos_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
            status
            due_date
        }
    }
`

export const InsertSubtodo = gql`
    mutation InsertSubtodo($object: sub_todos_insert_input!) {
        insert_sub_todos_one(object: $object) {
            id
        }
    }
`

export const UpdateSubtodo = gql`
    mutation UpdateSubtodo($id: bigint!, $payload: sub_todos_set_input!) {
        update_sub_todos_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
        }
    }
`
export const UpdateSubtodoSection = gql`
    mutation UpdateSubtodoSection(
        $id: bigint!
        $payload: sub_todo_sections_set_input!
    ) {
        update_sub_todo_sections_by_pk(
            pk_columns: { id: $id }
            _set: $payload
        ) {
            id
        }
    }
`
export const InsertSubtodoSection = gql`
    mutation InsertSubtodoSection($object: sub_todo_sections_insert_input!) {
        insert_sub_todo_sections_one(object: $object) {
            id
            name
            reference_points {
                id
                coordinates
            }
        }
    }
`

export const UpdateSubtodoSequences = gql`
    mutation UpdateSubtodoSequences(
        $objects: [sub_todo_sequences_insert_input!]!
    ) {
        insert_sub_todo_sequences(objects: $objects) {
            affected_rows
        }
    }
`

export const DeleleSubtodoSequences = gql`
    mutation DeleleSubtodoSequences($subtodoIds: [bigint!]!) {
        delete_sub_todo_sequences(
            where: { sub_todo_id: { _in: $subtodoIds } }
        ) {
            returning {
                id
            }
        }
    }
`

export const UpdateTodoDueDate = gql`
    mutation UpdateTodoDueDate($id: bigint!, $due_date: timestamptz!) {
        update_todos_by_pk(
            pk_columns: { id: $id }
            _set: { due_date: $due_date }
        ) {
            id
            due_date
        }
    }
`

export const InsertReferencePointForSection = gql`
    mutation InsertReferencePointForSection(
        $object: reference_points_insert_input!
    ) {
        insert_reference_points_one(object: $object) {
            id
            coordinates
            sub_todo_id
            sub_todo_section_id
        }
    }
`

export const UpdateTodoStatus = gql`
    mutation UpdateTodoStatus($id: bigint!, $status: status_enum!) {
        update_todos_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
            __typename
            id
            status
        }
    }
`

export const UpdateTodoAssignee = gql`
    mutation UpdateTodoAssignee($id: bigint!, $user_id: bigint!) {
        update_todos_by_pk(
            pk_columns: { id: $id }
            _set: { user_id: $user_id }
        ) {
            id
            user_id
            assignee {
                id
                name
                other_data
            }
        }
    }
`

export const UpdateReferencePointForSection = gql`
    mutation UpdateReferencePointForSection(
        $id: bigint!
        $payload: reference_points_set_input!
    ) {
        update_reference_points_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
        }
    }
`

export const UpdateTodoStatusAction = gql`
    mutation UpdateTodoStatusAction($status: String!, $todo_id: String!) {
        updateStatus(arg1: { status: $status, todo_id: $todo_id }) {
            stage
            todoStatus
            __typename
        }
    }
`

export const InsertDownloadFilesRequest = gql`
    mutation InsertDownloadFilesRequest(
        $entity_id: bigint!
        $entity_name: String!
        $download_files: jsonb!
    ) {
        insert_download_requests(
            objects: {
                entity_id: $entity_id
                entity_name: $entity_name
                download_files: $download_files
            }
        ) {
            affected_rows
        }
    }
`
