import parser from 'html-react-parser'
import { entityParserV2 } from 'src/screens/dashboard/sub-screens/comments/cards/parser'

const createTag = (
    userId: string,
    userName: string,
    type?: {
        status?: string
        color: string
    }
) => {
    if (!userName) return ''
    if (!userId) return ''
    const label =
        userName.length <= 50 ? userName : userName.slice(0, 50) + '...'
    if (type && type?.status === 'status') {
        return `<span onClick={clickHandler} style="color:#${type.color}" data-user-id=${userId} className="elipses">"${label}"</span>`
    }
    if (type && type?.status === 'file') {
        return `<span onClick={clickHandler} style="color:#022143;font-weight:500;" data-user-id=${userId} className="elipses">"${label}"</span>`
    }
    if (type && type?.status === 'notification') {
        return `<span onClick={clickHandler} style="color:#022143;font-weight:500;" data-user-id=${userId} className="elipses">${label}</span>`
    }
    return `<span onClick={clickHandler} data-user-id=${userId} className="span-link">@${label}</span>`
}

// Parses comment in form of [string] [<@{TAG+{ID}}&{NAME}>]
export const commentParser = (comment: string, type?: string) => {
    let parsedComment = ''
    let pattern: string[] = []
    let flag = false

    comment.split('').forEach((letter) => {
        if (letter === '<') {
            flag = true
        }
        if (flag && !letter.includes('>')) {
            pattern.push(letter)
        } else if (flag && letter.includes('>')) {
            flag = false
            const raw = pattern.join('').replace('<@', '').replace('>', '')
            const characterSplit = raw.split('&')
            if (raw.includes('ASSIGNED_FILE') || raw.includes('FILE')) {
                const entity = entityParserV2({ text: pattern.join('') + '>' })
                console.log({ entity: entity })
                if (entity) {
                    parsedComment += createTag(
                        entity['assigned_file'].id,
                        entity['assigned_file'].name,
                        {
                            status: 'file',
                            color: '',
                        }
                    )
                }
            } else {
                const firstSplit = characterSplit[0]
                const secondSplit = characterSplit[1]
                parsedComment += characterSplit[0].includes('ASSIGNED_FILE')
                    ? createTag(
                          firstSplit.replace('ASSIGNED_FILE', ''),
                          secondSplit,
                          {
                              status: 'file',
                              color: '',
                          }
                      )
                    : firstSplit.includes('STATUS')
                    ? createTag(
                          raw.split('#')[0].replace('USER', ''),
                          secondSplit,
                          {
                              status: 'status',
                              color: raw.split('#')[1].split('&')[0],
                          }
                      )
                    : createTag(firstSplit.replace('USER', ''), secondSplit, {
                          color: '',
                          status: type,
                      })
            }
            pattern = []
        } else if (letter.includes('\n')) {
            parsedComment += '<br/>'
        } else {
            parsedComment += letter
        }
    })
    return {
        text: `<span>${parsedComment}</span>`,
        parsed: parser(`<span style="color:#606060;">${parsedComment}</span>`),
    }
}
