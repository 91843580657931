import { gql } from '@apollo/client'

export const GetTodoTemplateTypeNames = gql`
    query GetTodoTemplateTypeNames {
        template_types {
            id
            type
        }
    }
`

export const GetTodoTemplateCategories = gql`
    query GetTodoTemplateCategories($template_type_id: bigint!) {
        template_categories(
            where: { template_type_id: { _eq: $template_type_id } }
        ) {
            id
            name
        }
    }
`

export const GetTemplateCategoriesWithTemplates = gql`
    query GetTemplateCategoriesWithTemplates(
        $template_type_id: bigint_comparison_exp = {}
        $category_search_str: String = "%%"
        $template_search_str: String = "%%"
    ) {
        template_categories(
            where: {
                template_type_id: $template_type_id
                name: { _ilike: $category_search_str }
            }
            order_by: { created_at: desc }
        ) {
            id
            name
            templates(
                where: { name: { _ilike: $template_search_str } }
                order_by: { created_at: desc }
            ) {
                id
                name
                design
                created_by
                assigned_templates_aggregate {
                    aggregate {
                        count
                    }
                }
                user {
                    id
                    name
                    other_data
                }
                favourite {
                    id
                }
                template_sub_todos(order_by: { created_at: asc }) {
                    id
                    heading
                    design_color
                    template_sub_todo_sections(order_by: { created_at: asc }) {
                        id
                        name
                        links
                        colors
                        other_data
                        description
                    }
                }
            }
            template_type {
                id
                type
            }
            user {
                id
                name
                other_data
            }
        }
    }
`

export const GetTodoTemplates = gql`
    query GetTodoTemplates(
        $template_category_id: bigint_comparison_exp = {}
        $template_type_id: bigint_comparison_exp = {}
        $searchQuery: String = "%%"
    ) {
        templates(
            where: {
                template_category_id: $template_category_id
                template_category: { template_type_id: $template_type_id }
                name: { _ilike: $searchQuery }
            }
            order_by: { created_at: desc }
        ) {
            id
            name
            template_category {
                id
                name
                template_type {
                    id
                    type
                }
            }
            design
            favourite {
                id
            }
            template_sub_todos(order_by: { created_at: asc }) {
                id
                heading
                design_color
                template_sub_todo_sections(order_by: { created_at: asc }) {
                    id
                    name
                    links
                    colors
                    other_data
                    description
                }
            }
        }
    }
`

export const GetTodoTemplateById = gql`
    query GetTodoTemplateById($id: bigint!) {
        templates_by_pk(id: $id) {
            id
            name
            template_category {
                id
                name
                template_type {
                    id
                    type
                }
            }
            favourite {
                id
            }
            template_sub_todos(order_by: { sequence_id: asc }) {
                id
                heading
                design_color
                files: template_files(
                    where: { assigned_as: { _eq: "reference_pic" } }
                    order_by: { id: desc }
                ) {
                    id
                    file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                        moved_to_lib
                        created_at
                        user {
                            id
                            name
                            other_data
                        }
                    }
                    library_file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                        created_at
                        creator {
                            id
                            name
                            other_data
                        }
                    }
                }
                template_sub_todo_sections(order_by: { created_at: asc }) {
                    id
                    name
                    links
                    colors
                    other_data
                    description
                    reference_points
                    template_files(order_by: { created_at: desc }) {
                        id
                        file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                            moved_to_lib
                            created_at
                            user {
                                id
                                name
                                other_data
                            }
                        }
                        library_file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                            created_at
                            creator {
                                id
                                name
                                other_data
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GetAssignedTemplateByTodo = gql`
    query GetAssignedTemplateByTodo($todoId: bigint = "") {
        assigned_templates_aggregate(where: { todo_id: { _eq: $todoId } }) {
            aggregate {
                count
            }
        }
    }
`

export const GetFavouriteTemplates = gql`
    query GetFavouriteTemplates {
        user_favourite(
            where: { entity_name: { _eq: "templates" } }
            order_by: { created_at: desc }
        ) {
            id
            templates {
                id
                name
                design
                created_by
                assigned_templates_aggregate {
                    aggregate {
                        count
                    }
                }
                user {
                    id
                    name
                    other_data
                }
                template_category {
                    id
                    name
                    template_type {
                        id
                        type
                    }
                }
                favourite {
                    id
                }
                template_sub_todos(order_by: { created_at: asc }) {
                    id
                    heading
                    design_color
                    template_sub_todo_sections(order_by: { created_at: asc }) {
                        id
                        name
                        links
                        colors
                        other_data
                        description
                    }
                }
            }
        }
    }
`
