import { DropDownArrow } from '../svg-icons'
import { Button } from '../utility'

export function IconDropdownButton({
    children,
    isActive = false,
    bg = '#ffffff',
    onClick,
    width,
    maxWidth,
    id,
    variant = 'primary',
}: {
    children: React.ReactNode
    isActive?: boolean
    bg?: string
    onClick?: () => void
    width?: string
    maxWidth?: string
    id?: string
    variant?: 'primary' | 'secondary'
}) {
    return (
        <Button
            bg={
                variant === 'secondary'
                    ? 'transparent'
                    : isActive
                    ? '#022143'
                    : bg
            }
            color={
                variant === 'secondary'
                    ? 'inherit'
                    : isActive
                    ? '#ffffff'
                    : 'primary'
            }
            border={variant === 'primary' ? '1px solid #A7B9D1' : 'none'}
            p="8px"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontSize="14px"
            onClick={onClick}
            width={width}
            maxWidth={maxWidth}
            overflow="hidden"
            style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                gap: '8px',
            }}
            id={id || 'dropdown-button'}
        >
            {children}
            <DropDownArrow color={isActive ? '#ffffff' : '#000000'} />
        </Button>
    )
}
