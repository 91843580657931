import * as React from 'react'

type TOrganizationContext = {
    team: ITeams[]
    setTeam: React.Dispatch<React.SetStateAction<ITeams[]>>
    individualContributors: IIndividualCollaborators[]
    setIndividualContributors: React.Dispatch<
        React.SetStateAction<IIndividualCollaborators[]>
    >
}

const CreateOrganizationContext = React.createContext<TOrganizationContext>({
    team: [],
    setTeam: () => {},
    individualContributors: [],
    setIndividualContributors: () => {},
})

export interface IAppProps {}

export default function OrganizationContext({
    children,
}: {
    children: JSX.Element
}) {
    const [team, setTeam] = React.useState<ITeams[]>([])
    const [individualContributors, setIndividualContributors] = React.useState<
        IIndividualCollaborators[]
    >([])
    return (
        <CreateOrganizationContext.Provider
            value={{
                team,
                setTeam,
                individualContributors,
                setIndividualContributors,
            }}
        >
            {children}
        </CreateOrganizationContext.Provider>
    )
}

export function useOrganizationContext() {
    return React.useContext(CreateOrganizationContext)
}
