import { gql } from "@apollo/client";

export const UpdateTodoStatus = gql`
mutation updateTodoStatus($todoId:String!,$status:String!) {
  updateStatus(arg1: {status: $status, todo_id: $todoId}) {
    collectionStatus
    productStatus
    todoStatus
  }
}
`