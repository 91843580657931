import * as React from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Button } from 'src/components/utility'

export interface ICollaboratorDropdownProps {
    collaboratorDropdown: Boolean
    onDropDownClick: (value: string) => void
    status: string
}

export default function CollaboratorDropdown(
    props: ICollaboratorDropdownProps
) {
    const { collaboratorDropdown, onDropDownClick, status } = props
    return (
        <Dropdown left="unset" right="0px" active={collaboratorDropdown}>
            <>
                {status.toLowerCase() === 'active' && (
                    <>
                        <DropdownButtonWrapper
                            value="remove_user"
                            onClick={(val) => onDropDownClick(val)}
                            width="100%"
                        >
                            <Button
                                textAlign="left"
                                width="100%"
                                color="delay"
                                bg="transparent"
                                border="none"
                            >
                                Archive User
                            </Button>
                        </DropdownButtonWrapper>
                    </>
                )}
                {status.toLowerCase() === 'invited' && (
                    <>
                        <DropdownButtonWrapper
                            value="resend_invite"
                            onClick={(val) => onDropDownClick(val)}
                        >
                            <Button
                                textAlign="left"
                                width="100%"
                                bg="transparent"
                                border="none"
                            >
                                Resend Invite
                            </Button>
                        </DropdownButtonWrapper>
                        <DropdownButtonWrapper
                            value="cancel_invite"
                            onClick={(val) => onDropDownClick(val)}
                        >
                            <Button
                                textAlign="left"
                                width="100%"
                                bg="transparent"
                                color="delay"
                                border="none"
                            >
                                Cancel Invite
                            </Button>
                        </DropdownButtonWrapper>
                    </>
                )}
                {status.toLowerCase() === 'archived' && (
                    <DropdownButtonWrapper
                        value="reactivate-user"
                        onClick={(val) => onDropDownClick(val)}
                    >
                        <Button
                            textAlign="left"
                            width="100%"
                            bg="transparent"
                            border="none"
                        >
                            Re-activate User
                        </Button>
                    </DropdownButtonWrapper>
                )}
            </>
        </Dropdown>
    )
}
