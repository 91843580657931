import type { SetStateAction } from 'react'
import { forwardRef } from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Button } from 'src/components/utility'

const DropDownActions = ({
    active,
    onDropDownClick,
    setActiveSort,
    activeSort,
    setIsDropdownActive,
}: {
    active: Boolean
    onDropDownClick: (value: string, sort?: string) => void
    setActiveSort: (active: 'az' | 'za' | 'roles' | 'lh' | 'hl') => void
    activeSort: string
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
}) => {
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="40px"
            left="undefined"
            right="0px"
        >
            <>
                <DropdownButtonWrapper
                    width="100%"
                    onClick={(x) => {
                        setActiveSort('az')
                        onDropDownClick(x, 'az')
                    }}
                    value="Alphabetical A-Z"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'az' ? '0.5' : '1'}
                    >
                        Alphabetical A-Z
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('za')
                        onDropDownClick(x, 'za')
                    }}
                    value="Alphabetical Z-A"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'za' ? '0.5' : '1'}
                    >
                        Alphabetical Z-A
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('roles')
                        onDropDownClick(x, 'roles')
                    }}
                    value="Roles"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'roles' ? '0.5' : '1'}
                    >
                        Roles
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('lh')
                        onDropDownClick(x, 'lh')
                    }}
                    value="Completion Rate: Low to High"
                    width="100%"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'lh' ? '0.5' : '1'}
                    >
                        Completion Rate: Low to High
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('hl')
                        onDropDownClick(x, 'hl')
                    }}
                    value="Completion Rate: High to Low"
                    width="100%"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'hl' ? '0.5' : '1'}
                    >
                        Completion Rate: High to Low
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}

export default forwardRef(DropDownActions)
