import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { statuses } from 'src/helpers'
import {
    NotificationAggregateAll,
    NotificationAggregateComments,
    NotificationAggregateOtherUpdate,
    NotificationAggregateStatusUpdate,
    NotificationAggregateUnread,
} from 'src/services/graphql/query/notifications'
import type { TNotificationAggregate, TTabs } from '../@types'

const PillBadges = {
    all: [
        { label: 'All', value: 'all', count: 0 },
        { label: 'In-Review', value: 'inReview', count: 0 },
        {
            label: '@mentions & Annotations',
            value: 'mentionAndAnnotation',
            count: 0,
        },
        { label: 'Others', value: 'others', count: 0 },
    ],
    unread: [
        { label: 'All', value: 'all', count: 0 },
        { label: 'To-Dos', value: 'todos', count: 0 },
        { label: 'Products', value: 'products', count: 0 },
        { label: 'Collections', value: 'collections', count: 0 },
    ],
    comments: [
        { label: 'All', value: 'all', count: 0 },
        {
            label: '@mentions & Annotations',
            value: 'mentionAndAnnotation',
            count: 0,
        },
        { label: 'Others', value: 'others', count: 0 },
    ],
    statusUpdate: [
        { label: 'All', value: 'all', count: 0 },
        { label: 'Completed', value: statuses.complete, count: 0 },
        { label: 'In Progress', value: statuses.inProgress, count: 0 },
        { label: 'In-Review', value: 'inReview', count: 0 },
        { label: 'Delayed', value: statuses.delayed, count: 0 },
    ],
    othersUpdate: [
        { label: 'All', value: 'all', count: 0 },
        { label: 'To-Dos', value: 'todos', count: 0 },
        { label: 'Products', value: 'products', count: 0 },
        { label: 'Collections', value: 'collections', count: 0 },
        { label: 'Others', value: 'others', count: 0 },
    ],
}

export function useNotificationAggregate(activeTab: TTabs) {
    const [pillBadges, setPillBadges] = useState(PillBadges)
    const [getAllAggregate] = useLazyQuery<TNotificationAggregate>(
        NotificationAggregateAll
    )
    const [getUnreadAggregate] = useLazyQuery<TNotificationAggregate>(
        NotificationAggregateUnread
    )
    const [getCommentsggregate] = useLazyQuery<TNotificationAggregate>(
        NotificationAggregateComments
    )
    const [getStatusUpdateggregate] = useLazyQuery<TNotificationAggregate>(
        NotificationAggregateStatusUpdate
    )
    const [getOtherUpdateggregate] = useLazyQuery<TNotificationAggregate>(
        NotificationAggregateOtherUpdate
    )

    function updateBadgeCount(
        data: TNotificationAggregate,
        category:
            | 'all'
            | 'unread'
            | 'comments'
            | 'statusUpdate'
            | 'othersUpdate'
    ) {
        const keys = Object.keys(
            data
        ) as unknown as (keyof TNotificationAggregate)[]
        const pillBadgeClone = { ...pillBadges }
        pillBadgeClone[category] = pillBadgeClone[category].map((badge) => {
            const newBadge = { ...badge }
            keys.map((key) => {
                if (key === badge.value) {
                    newBadge.count = data[key].aggregate.count
                }
            })
            return newBadge
        })
        return pillBadgeClone
    }

    useEffect(() => {
        switch (activeTab) {
            case 'all':
                getAllAggregate({
                    onCompleted: (data) => {
                        setPillBadges(updateBadgeCount(data, 'all'))
                    },
                })
                break
            case 'unread':
                getUnreadAggregate({
                    onCompleted: (data) => {
                        setPillBadges(updateBadgeCount(data, 'unread'))
                    },
                })
                break
            case 'comments':
                getCommentsggregate({
                    onCompleted: (data) => {
                        setPillBadges(updateBadgeCount(data, 'comments'))
                    },
                })
                break
            case 'statusUpdate':
                getStatusUpdateggregate({
                    onCompleted: (data) => {
                        setPillBadges(updateBadgeCount(data, 'statusUpdate'))
                    },
                })
                break
            case 'othersUpdate':
                getOtherUpdateggregate({
                    onCompleted: (data) => {
                        setPillBadges(updateBadgeCount(data, 'othersUpdate'))
                    },
                })
                break
        }
    }, [activeTab])

    return { pillBadges }
}
