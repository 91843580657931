import * as React from 'react'
import {
    Box,
    Text,
    Image as Img,
    Button,
    Input,
    Link,
} from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { DeleteIco, StrokedArrow } from 'src/components/svg-icons'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { createMaterial } from '../../helper/create-material'
import type { MeshCustomMaterial } from '../material/custom-material'
import { DivisionLine } from 'src/components/division-line/division-line'

export interface IGraphicMapsProps {}

export default function GraphicMaps(props: IGraphicMapsProps) {
    const { graphicMaps, setGraphicMaps, highlights, graphic } =
        useConfiguratorContext()
    const [graphicMapsStatus, setGraphicMapsStatus] = React.useState<
        { id: number; isOpen: boolean }[]
    >(
        graphicMaps.map((map) => {
            return { id: map.id, isOpen: false }
        })
    )
    const inputRefs = graphicMaps.map((map) => React.createRef())
    function openFileDialogueBox(ref: React.RefObject<HTMLInputElement>) {
        if (ref && ref.current) {
            ref.current.click()
        }
    }

    function updateGraphicMap(
        code:
            | 'mapLayer1'
            | 'normalMapLayer1'
            | 'roughnessMapLayer1'
            | 'metalnessMapLayer1',
        file: File
    ) {
        if (highlights.length === 1) {
            const mesh = highlights[0].mesh
            if (!graphic) return
            mesh.material[code] = createMaterial(getLocalImageUrl(file) || '', {
                offset: {
                    u: graphic.config.offset.u || 1,
                    v: graphic.config.offset.v || 1,
                },
                scale: {
                    u: graphic.config.repeat.u || 1,
                    v: graphic.config.repeat.v || 1,
                },
                rotation: graphic.config.rotation || 0,
            }).plain
        }

        setGraphicMaps((graphicMaps) => {
            return graphicMaps.map((map) => {
                if (map.code === code) {
                    return {
                        ...map,
                        file,
                    }
                }
                return map
            })
        })
    }

    const mesh = highlights[0]?.mesh
    React.useEffect(() => {
        if (mesh) {
            const material = mesh.material as MeshCustomMaterial
            setGraphicMaps((graphicMaps) => {
                return graphicMaps.map((map) => {
                    if (map.code === 'mapLayer1' && !material[map.code])
                        return map
                    return {
                        ...map,
                        file: material[map.code]?.source?.data?.currentSrc,
                    }
                })
            })
        }
    }, [highlights])

    return (
        <Box>
            {graphicMaps.map((map, key) => {
                return (
                    <>
                        <Accordion
                            style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                boxShadow: 'none',
                                // padding: '24px 0px',
                            }}
                            onChange={() => {
                                setGraphicMapsStatus(
                                    graphicMapsStatus.map((mapStatus) => {
                                        return mapStatus.id === map.id
                                            ? {
                                                  ...mapStatus,
                                                  isOpen: !mapStatus.isOpen,
                                              }
                                            : { ...mapStatus, isOpen: false }
                                    })
                                )
                            }}
                            expanded={graphicMapsStatus[key].isOpen}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <Box className="vertical">
                                        <StrokedArrow />
                                    </Box>
                                }
                            >
                                <Box>
                                    <Text my="0px" fontSize="12px">
                                        {map.type}
                                    </Text>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Img
                                        className="img-clear"
                                        width="100%"
                                        height="100px"
                                        src={
                                            map.file
                                                ? typeof map.file !== 'string'
                                                    ? getLocalImageUrl(map.file)
                                                    : map.file
                                                : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
                                        }
                                    />
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gridColumnGap="20px"
                                    >
                                        <Button
                                            p="0px"
                                            bg="transparent"
                                            border="none"
                                            onClick={() => {
                                                inputRefs[key] &&
                                                    openFileDialogueBox(
                                                        inputRefs[
                                                            key
                                                        ] as React.RefObject<HTMLInputElement>
                                                    )
                                            }}
                                        >
                                            <UploadFile />
                                        </Button>
                                        <Input
                                            autoComplete="false"
                                            type="file"
                                            multiple
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                if (
                                                    e.target.files &&
                                                    e.target.files.length > 0
                                                ) {
                                                    updateGraphicMap(
                                                        map.code,
                                                        e.target.files[0]
                                                    )
                                                }
                                            }}
                                            onClick={(e: any) => {
                                                e.target.value = null
                                            }}
                                            display="none"
                                            ref={inputRefs[key]}
                                            bg="none"
                                        />
                                        <Link
                                            href={
                                                map.file
                                                    ? typeof map.file !==
                                                      'string'
                                                        ? map.file
                                                        : map.file
                                                    : ''
                                            }
                                            download={map.type + '.png'}
                                            bg="white"
                                            // border="none"
                                            style={{
                                                border: 'none',
                                                borderRadius: '4px',
                                                padding: '8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                            }}
                                            className="download-link"
                                        >
                                            <Download />
                                        </Link>
                                        <Button
                                            p="0px"
                                            bg="transparent"
                                            border="none"
                                            onClick={() => {
                                                if (!highlights[0].mesh) return
                                                highlights[0].mesh.material[
                                                    map.code
                                                ] = null
                                                setGraphicMaps(
                                                    (graphicMaps) => {
                                                        return graphicMaps.map(
                                                            (graphicMap) => {
                                                                if (
                                                                    graphicMap.code ===
                                                                    map.code
                                                                ) {
                                                                    return {
                                                                        ...graphicMap,
                                                                        file: null,
                                                                    }
                                                                }
                                                                return graphicMap
                                                            }
                                                        )
                                                    }
                                                )
                                            }}
                                        >
                                            <DeleteIco />
                                        </Button>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Box my="4px">
                            <DivisionLine />
                        </Box>
                    </>
                )
            })}
        </Box>
    )
}
