import React, { useEffect, useState } from 'react'
import { Box, Input, Text } from 'src/components/utility'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { Authentication, InlineError, Loader } from 'src/components'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { getLocalStorage } from 'src/helpers'
import { Failure } from 'src/helpers/toast-text-messages'
const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({
        text: '',
        isError: false,
    })
    const navigate = useNavigate()
    const auth = getAuth()
    const user = getLocalStorage('user')
    const authToken = getLocalStorage('authToken')
    useEffect(() => {
        if (user && authToken && user.length > 0 && authToken.length > 0) {
            navigate('/')
        }
    }, [])

    async function handlePasswordReset(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (email.length <= 0)
            return setError({
                isError: true,
                text: Failure.conditionChecks.email.noEmail,
            })
        if (!email.toString().match(emailRegex))
            return setError({
                isError: true,
                text: Failure.conditionChecks.email.noEmail,
            })
        setError({
            isError: false,
            text: '',
        })
        try {
            setIsLoading(true)
            await sendPasswordResetEmail(auth, email.toString())
            setIsLoading(false)
            navigate('/password-reset-link', {
                state: email,
            })
        } catch (e: any) {
            setIsLoading(false)
            if (e && e.code === 'auth/user-not-found') {
                navigate('/password-reset-link', {
                    state: email,
                })
            } else {
                setError({
                    isError: true,
                    text: Failure.error.base,
                })
            }
        }
    }
    return (
        <Authentication>
            <form onSubmit={handlePasswordReset}>
                <Box
                    minWidth="360px"
                    display="flex"
                    gridRowGap="16px"
                    flexDirection="column"
                >
                    <Box mb="56px">
                        <Text
                            fontFamily="Rubik"
                            fontWeight={500}
                            color="primary"
                            fontSize="32px"
                            textAlign="left"
                            mb="0px"
                        >
                            Forgot Password
                        </Text>
                        <Text
                            fontFamily="Rubik"
                            color="textSecondary"
                            mt="12px"
                            mb="0px"
                        >
                            To reset your password, provide your registered
                            email.
                        </Text>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gridRowGap="12px"
                        fontSize="14px"
                    >
                        <Text
                            mt="0px"
                            mb="0px"
                            fontFamily="Rubik"
                            color="primary"
                            textAlign="left"
                            width="100%"
                        >
                            Email Address
                        </Text>
                        <Input
                            autoComplete="false"
                            placeholder="Enter your email"
                            bg="white"
                            borderRadius="4px"
                            px="16px"
                            py="16px"
                            minWidth="360px"
                            fontSize="14px"
                            border="solid"
                            borderWidth={1}
                            borderColor="gray350"
                            className="placeholder-darkgray"
                            onKeyDown={() =>
                                setError({
                                    text: '',
                                    isError: false,
                                })
                            }
                            id="fp-account-email"
                            width="100%"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                        {error.isError && (
                            <Box width="100%" mb="8px">
                                <InlineError text={error.text} width="auto" />
                            </Box>
                        )}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        mt="8px"
                        alignContent="start"
                    >
                        <ColoredButton
                            variant="primary"
                            onClick={() => {}}
                            disabled={isLoading}
                            type="submit"
                            id="fp-submit-btn"
                        >
                            <Box
                                display="flex"
                                gridColumnGap="8px"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {isLoading && <Loader />} Continue
                            </Box>
                        </ColoredButton>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gridColumnGap="4px"
                        >
                            <Text
                                textAlign="center"
                                fontFamily="Rubik"
                                color="textSecondary"
                            >
                                Changed your mind? Back to
                            </Text>
                            <Link className="links" to="/login">
                                Login
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Authentication>
    )
}
export default ForgotPassword
