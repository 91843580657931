import { gql } from '@apollo/client'
export const GetFileInfoByGID = gql`
    query getFileInfo($gid: String) {
        files(where: { gid: { _eq: $gid } }) {
            path
            size
        }
        library_files(where: { gid: { _eq: $gid } }) {
            path
            size
        }
    }
`

export const GetFileDataByGID = gql`
    query getFileById($gid: String) {
        files(where: { gid: { _eq: $gid } }) {
            name
            gid
            id
        }
    }
`
export const GetFileDataGIDFromLibrary = gql`
    query getGid($fileId: String) {
        library_files(where: { gid: { _eq: $fileId } }) {
            name
            gid
            id
        }
    }
`

export const GetFileVersions = gql`
    query getVersions($fileId: bigint, $entityName: String) {
        file_versions(
            where: {
                entity_name: { _eq: $entityName }
                entity_id: { _eq: $fileId }
            }
            limit: 5
            order_by: { id: desc }
        ) {
            gid
            id
            name
            path
            size
            type
            versions
        }
    }
`
