import React from 'react'
import { StackedImage } from 'src/components/stacked-image/stacked-image'
import { FilledCloseIco } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'

function TeamBadge({
    teamName = '',
    teamMembers = [],
    onRemove,
}: {
    teamName: string
    teamMembers: any
    onRemove?: () => void
    isAllowedToRemove?: boolean
}) {
    return (
        <Box
            display="flex"
            position="relative"
            alignItems="center"
            pl={'4px'}
            gridGap="4px"
            border="1px solid #E8ECEF"
            borderRadius="4px"
            minHeight={'28px'}
        >
            <Text
                my="0px"
                maxWidth="100px"
                overflow="hidden"
                className="text-overflow input-field"
                fontSize="14px"
                fontFamily="Rubik"
                title={teamName}
            >
                {teamName}
            </Text>
            <StackedImage
                width="auto"
                fontSize="14px"
                spacing={14}
                size="20px"
                list={teamMembers.map((member: any) => {
                    return {
                        src: getImageUrl(member?.user?.other_data?.thumbnail),
                        name: member.user.name,
                    }
                })}
            />
            {onRemove && (
                <Box id="dropdown__focus">
                    <Button
                        border="none"
                        borderRadius="50%"
                        onClick={(e) => {
                            e.stopPropagation()
                            onRemove()
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        type="button"
                        bg="transparent"
                        // @ts-ignore
                        ml={'-8px'}
                    >
                        <FilledCloseIco />
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default TeamBadge
