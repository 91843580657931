import React from 'react'
import TodoSection from '../todo/todo-section/todo-section'
import ProductSection from '../product/product-section'
import { TodoSectionContextProvider } from '../todo/todo-section/todo-section.context'

function EntityCompareSection({
    compareEntity,
    compareId,
    onClick,
    activeSplitSectionId,
}: {
    compareEntity: 'todo' | 'product'
    compareId: string
    onClick?: () => void
    activeSplitSectionId?: number
}) {
    if (compareEntity === 'todo') {
        return (
            <TodoSectionContextProvider>
                <TodoSection
                    todoId={Number(compareId)}
                    isSplitMode={true}
                    isCompareSection={true}
                    onClick={onClick}
                    activeSplitSectionId={activeSplitSectionId}
                />
            </TodoSectionContextProvider>
        )
    } else if (compareEntity === 'product') {
        return (
            <ProductSection
                productId={Number(compareId)}
                isSplitMode={true}
                isCompareSection={true}
            />
        )
    } else {
        return null
    }
}

export default EntityCompareSection
