import { Button } from 'src/components/utility'
import { DropdownButtonWrapper, Dropdown } from 'src/components'
import type { SetStateAction } from 'react'
import { useQuery } from '@apollo/client'
import { GetTodoTemplateCategories } from 'src/services/graphql/entities/todo/todo-templates.queries'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export const TodoCategoryDropdown = ({
    active,
    onDropDownClick,
    setIsDropdownActive,
    templateTypeId,
    showOthers = true,
}: {
    active: Boolean
    onDropDownClick: (value: { id: number | null; name: string }) => void
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
    templateTypeId: number | null
    showOthers?: Boolean
}) => {
    const templateCategories = useQuery(GetTodoTemplateCategories, {
        skip: !templateTypeId,
        variables: {
            template_type_id: templateTypeId,
        },
        fetchPolicy: 'no-cache',
    })

    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['todo-category-dropdown-button', 'todo-category-dropdown-text']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="36px"
            width="100%"
            ref={wrapperRef}
        >
            <>
                {templateCategories?.data?.template_categories?.map(
                    ({ id, name }: { id: number; name: string }) => (
                        <DropdownButtonWrapper
                            onClick={() => {
                                onDropDownClick({ id, name })
                            }}
                            value={name}
                            width="100%"
                            key={id}
                        >
                            <Button
                                fontFamily="Rubik"
                                color="primary"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                overflow="hidden"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                title={name}
                            >
                                {name}
                            </Button>
                        </DropdownButtonWrapper>
                    )
                )}

                {showOthers && (
                    <DropdownButtonWrapper
                        onClick={() => {
                            onDropDownClick({ id: null, name: 'Others' })
                        }}
                        value="Others"
                        width="100%"
                    >
                        <Button
                            fontFamily="Rubik"
                            color="primary"
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                        >
                            Others
                        </Button>
                    </DropdownButtonWrapper>
                )}
            </>
        </Dropdown>
    )
}
