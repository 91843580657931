import { EmptyFolderIco } from 'src/components/svg-icons/library/empty-folder'
import { Box, Button, Text } from 'src/components/utility'

const EmptyFolderView = ({
    openCreateFolderModal,
    addFileToFolder,
    onlyFiles,
    onlyFolders,
    disableActions = false,
}: {
    openCreateFolderModal: (x: boolean) => void
    addFileToFolder?: () => void
    onlyFiles: boolean
    onlyFolders: boolean
    disableActions?: boolean
}) => {
    return (
        <>
            <Box textAlign="center">
                {' '}
                <EmptyFolderIco />{' '}
            </Box>

            <Text fontSize={'14px'} textAlign="center" color="textSecondary">
                {onlyFiles && onlyFolders
                    ? 'No Folders/Files'
                    : onlyFiles
                    ? 'No Files'
                    : 'No Folders'}
            </Text>

            {!disableActions && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap={'24px'}
                >
                    {onlyFiles && onlyFolders && (
                        <Button
                            height="20px"
                            px={'0px'}
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                            color="links"
                            position="relative"
                            onClick={() => {
                                addFileToFolder && addFileToFolder()
                            }}
                        >
                            + Add Files
                        </Button>
                    )}
                    {onlyFiles && onlyFolders && (
                        <Button
                            height="20px"
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                            color="links"
                            px={'0px'}
                            position="relative"
                            onClick={() => {
                                openCreateFolderModal(true)
                            }}
                        >
                            + Create Folder
                        </Button>
                    )}
                </Box>
            )}
        </>
    )
}
export default EmptyFolderView
