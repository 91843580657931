import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Box, Button, Input, Text } from 'src/components/utility'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { debounce, getImageUrl } from 'src/helpers'
import TodoDropdownCard from './todo-dropdown-card'
import { GetTodosByProductId } from '../compare-entity-topbar.queries'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'

function TodoDropdown({
    onClose,
    productId,
    onSelect,
    collectionName,
    isCompareEntitySelector,
}: {
    onClose: () => void
    productId: number | undefined
    onSelect: (product: { id: number; name: string; types: string }) => void
    collectionName: string
    isCompareEntitySelector?: boolean
}) {
    const [searchInput, setSearchInput] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const { data } = useQuery(GetTodosByProductId, {
        variables: {
            searchQuery: `%${searchQuery}%`,
            id: productId,
        },
        skip: !productId,
        fetchPolicy: 'no-cache',
    })

    const wrapperRef = useClickedOutside(
        () => onClose(),
        ['topbar-todo-dropdown-button']
    )
    const [timeoutRef, setTimeoutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debouncedUpdateSearch = debounce(
        (query: string) => {
            setSearchQuery(query)
        },
        400,
        setTimeoutRef,
        timeoutRef
    )

    function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchInput(e.target.value)
        debouncedUpdateSearch(e.target.value)
    }

    return (
        <Box
            position="absolute"
            width={'615px'}
            height={'314px'}
            bg="white"
            top="24px"
            right={
                isCompareEntitySelector
                    ? '-80px'
                    : collectionName.length < 20
                    ? '-300px'
                    : '-200px'
            }
            zIndex={15}
            border="1px solid #E0E0E0"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            p="16px"
            overflow={'auto'}
            gridGap="16px"
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            ref={wrapperRef}
        >
            <Text my="0px" fontSize={'12px'} color="textSecondary">
                Choose todo
            </Text>
            <Box
                display="flex"
                gridGap="8px"
                borderRadius="4px"
                bg="white"
                border="1px solid #DDDDDD"
                py="8px"
                px="12px"
                width="100%"
                alignItems="center"
            >
                <SearchIco />
                <Input
                    placeholder="Search"
                    border="none"
                    bg="transparent"
                    value={searchInput}
                    width="100%"
                    onChange={searchChangeHandler}
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setSearchInput('')
                        debouncedUpdateSearch('')
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <CrossSm />
                </Button>
            </Box>
            {data && data?.todos.length > 0 ? (
                data?.todos?.map((todo: any) => {
                    const productCoverPic =
                        todo.product_variant?.other_data?.cover_pic &&
                        (todo.product_variant.other_data.cover_pic.fromLibrary
                            ? getLibraryFileUrl(
                                  todo.product_variant.other_data.cover_pic.gid
                              )
                            : getImageUrl(
                                  todo.product_variant.other_data.cover_pic.gid
                              ))

                    const coverPic = todo.other_data?.cover_pic
                        ? todo.other_data.cover_pic.fromLibrary
                            ? getLibraryFileUrl(todo.other_data.cover_pic.gid)
                            : getImageUrl(todo.other_data.cover_pic.gid)
                        : !!productCoverPic
                        ? productCoverPic
                        : '/image-placeholder.jpg'
                    return (
                        <TodoDropdownCard
                            key={todo.id}
                            name={todo.name}
                            collectionName={
                                todo?.product_variant?.collection?.name || ''
                            }
                            creator={todo?.assignee}
                            imageUrl={coverPic}
                            onClick={() => {
                                onSelect({
                                    id: todo.id,
                                    name: todo.name,
                                    types: todo.types,
                                })
                            }}
                        />
                    )
                })
            ) : (
                <Text textAlign="center">No todos found</Text>
            )}
        </Box>
    )
}

export default TodoDropdown
