import axios from 'axios'
import { useState } from 'react'
import { getLocalStorage, type statuses } from 'src/helpers'
import { GLOBAL_SEARCH } from 'src/services/api/endpoints'
import type { TGlobalSearchResults } from '../@types/search'
import useStore from 'src/store/hooks/useStore'
import { PerPage } from 'src/helpers/enums'

type SearchType = {
    query: string
    filters: {
        collaborators: string[]
        tags: string[]
        status: (typeof statuses)[keyof typeof statuses][]
        file_types: string[]
        from?: Date | string
        to?: Date | string
    }
}

type Keys = 'collaborators' | 'tags' | 'status' | 'file_types' | 'from' | 'to'

export function useGlobalSearch() {
    const [searchedData, setSearchedData] = useState<TGlobalSearchResults>()
    const { setGlobalSearchResults, setResults } = useStore()

    function makeRequestURL(
        search: SearchType,
        {
            offset,
        }: {
            offset?: number
        }
    ) {
        let GlobalSearchString =
            GLOBAL_SEARCH +
            `?search=${search.query}&offset=${offset}&limit=${PerPage}`
        return Object.keys(search.filters).reduce((acc, key) => {
            if (search.filters[key as Keys] === undefined) return acc
            let filterValue = search.filters[key as Keys]
            if (Array.isArray(filterValue)) {
                acc += `&${key}=${JSON.stringify(filterValue)}`
            } else if (filterValue instanceof Date) {
                acc += `&${key}=${filterValue.toISOString()}`
            } else {
                acc += `&${key}=${''}`
            }
            return acc
        }, GlobalSearchString)
    }

    async function getGlobalSearchData(
        search: SearchType,
        paginate?: {
            offset: number
        }
    ) {
        try {
            const data = await axios.get<{ data: TGlobalSearchResults }>(
                makeRequestURL(search, {
                    offset: paginate?.offset || 1,
                }),
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({
                        filters: search.filters,
                    }),
                }
            )
            if (data.data) {
                setSearchedData(data.data.data)
                setGlobalSearchResults({
                    results: data.data.data,
                })
                setResults({
                    results: Number(
                        Object.keys(data.data.data).reduce((acc, curr) => {
                            return (
                                acc +
                                data.data.data[
                                    curr as keyof TGlobalSearchResults
                                ].count
                            )
                        }, 0)
                    ),
                })
            }
        } catch (e) {
            console.log(e)
        }
    }
    return { getGlobalSearchData, searchedData }
}
