import { useMutation, useQuery } from '@apollo/client'
import type { KeyboardEvent } from 'react'
import React, { useState } from 'react'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'
import { FetchFoldersWithFilters } from 'src/services/graphql/query/library.queries'
import CategoryListItem from './category-list-item'
import { InsertFolder } from 'src/services'
import { toast } from 'react-toastify'
import { debounce } from 'src/helpers'
import Skeleton from 'react-loading-skeleton'

function SelectFolderSection({
    onSelect,
    onAdd,
    parentId,
    parentType,
    breadcrumbs,
    showInsertInputField,
    setShowInsertInputField,
}: {
    onSelect: (folder: { id: number; name: string }) => void
    parentType: 'category' | 'folder'
    parentId: number
    onAdd: (folderId: number) => void
    breadcrumbs: Array<{ id: number; name: string }>
    showInsertInputField: boolean
    setShowInsertInputField: (value: boolean) => void
}) {
    const [inputValue, setInputValue] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [insertFolderMutation] = useMutation(InsertFolder)
    const {
        data: foldersDataByCategoryId,
        loading,
        refetch,
    } = useQuery(FetchFoldersWithFilters, {
        variables: {
            whereObj:
                parentType === 'category'
                    ? {
                          category_id: { _eq: parentId },
                          parent_id: { _is_null: true },
                          name: {
                              _ilike: `%%`,
                          },
                      }
                    : {
                          parent_id: { _eq: parentId },
                          name: {
                              _ilike: `%%`,
                          },
                      },
        },
    })

    const folders = foldersDataByCategoryId?.folders

    function searchHandler(searchQuery: string) {
        refetch({
            whereObj:
                parentType === 'category'
                    ? {
                          category_id: { _eq: parentId },
                          parent_id: { _is_null: true },
                          name: {
                              _ilike: `%${searchQuery}%`,
                          },
                      }
                    : {
                          parent_id: { _eq: parentId },
                          name: {
                              _ilike: `%${searchQuery}%`,
                          },
                      },
        })
    }

    const debouncedSearch = debounce(
        searchHandler,
        400,
        setTimeOutRef,
        timeOutRef
    )

    function handlerSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchQuery(e.target.value)
        debouncedSearch(e.target.value)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            insertFolderMutation({
                variables: {
                    name: inputValue,
                    category_id:
                        parentType === 'category'
                            ? parentId
                            : breadcrumbs[0].id,
                    parent_id: parentType === 'folder' ? parentId : null,
                },
                onCompleted: () => {
                    setInputValue('')
                    toast('Folder added successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                    setShowInsertInputField(false)
                    refetch({
                        whereObj:
                            parentType === 'category'
                                ? {
                                      category_id: { _eq: parentId },
                                      parent_id: { _is_null: true },
                                      name: {
                                          _ilike: `%${searchQuery}%`,
                                      },
                                  }
                                : {
                                      parent_id: { _eq: parentId },
                                      name: {
                                          _ilike: `%${searchQuery}%`,
                                      },
                                  },
                    })
                },
            })
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gridGap="8px"
            height={'100%'}
            overflow={'auto'}
            key={`${parentType}-${parentId}`}
        >
            <Box
                display="flex"
                gridGap="8px"
                borderRadius="4px"
                bg="white"
                border="1px solid #DDDDDD"
                py="8px"
                px="12px"
                width="100%"
                alignItems="center"
            >
                <SearchIco />
                <Input
                    placeholder="Search"
                    border="none"
                    bg="transparent"
                    value={searchQuery}
                    onChange={handlerSearchChange}
                    width="100%"
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setSearchQuery('')
                        debouncedSearch('')
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <CrossSm />
                </Button>
            </Box>
            <Text color="primary" my="0px" fontSize={'12px'}>
                {parentType === 'category'
                    ? `${breadcrumbs[0].name} > All Assets`
                    : breadcrumbs.map(({ name }) => name).join(' > ')}
            </Text>
            <Box
                display="flex"
                flexDirection="column"
                gridGap="8px"
                maxHeight={'500px'}
                overflow={'auto'}
            >
                {loading ? (
                    <Skeleton count={5} />
                ) : folders?.length > 0 ? (
                    folders.map(({ id, name }: any) => {
                        return (
                            <CategoryListItem
                                key={id}
                                name={name}
                                onClick={() => {
                                    onSelect({ id, name })
                                }}
                                onAdd={() => {
                                    onAdd(id)
                                }}
                            />
                        )
                    })
                ) : (
                    <Text>No folders found</Text>
                )}
                {showInsertInputField && (
                    <Input
                        placeholder="Name new folder"
                        bg="#F0F2F3"
                        py="4px"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        border="none"
                        onKeyDown={handleKeyDown}
                    />
                )}
            </Box>
        </Box>
    )
}

export default SelectFolderSection
