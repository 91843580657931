import LinkIco from 'src/components/svg-icons/link-ico'
import { Box, Button, Input, Text } from 'src/components/utility'

export function UVScale({
    u,
    v,
    onChange,
    sensitivity = 0.1,
    maxValue = 1,
    minValue = 1,
    onLinkClick,
    isLinkActive = false,
}: {
    u: number
    v: number
    onChange: (u: number, key: 'u' | 'v') => void
    sensitivity?: number
    maxValue?: number
    minValue?: number
    onLinkClick?: () => void
    isLinkActive?: boolean
}) {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gridRowGap={onLinkClick ? '0px' : '8px'}
        >
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gridRowGap="4px"
            >
                <Text fontSize="12px" my="0px">
                    U
                </Text>
                <Box display="flex" justifyContent="space-between">
                    <Input
                        type="number"
                        value={u}
                        bg="transparent"
                        onChange={(e) => onChange(Number(e.target.value), 'u')}
                        border="solid"
                        borderWidth={1}
                        borderColor="secondaryLighterBlue"
                        borderRadius="4px"
                        width="71px"
                        height="32px"
                        step={sensitivity}
                        min={minValue}
                        max={maxValue}
                    />
                    <Input
                        mt="12px"
                        className="intensity-slider"
                        width="60%"
                        value={u}
                        onChange={(e) => onChange(Number(e.target.value), 'u')}
                        type="range"
                        min={minValue}
                        step={sensitivity}
                        max={maxValue}
                        bg="trasparent"
                    ></Input>
                </Box>
            </Box>
            {onLinkClick && (
                <Button
                    bg="transparent"
                    border="none"
                    p="0px"
                    display="flex"
                    justifyContent="end"
                    onClick={onLinkClick}
                >
                    <LinkIco color={isLinkActive ? '#022143' : '#778CA2'} />
                </Button>
            )}
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gridRowGap="4px"
            >
                <Text fontSize="12px" my="0px">
                    V
                </Text>
                <Box display="flex" justifyContent="space-between">
                    <Input
                        type="number"
                        value={v}
                        bg="transparent"
                        onChange={(e) => onChange(Number(e.target.value), 'v')}
                        border="solid"
                        borderWidth={1}
                        borderColor="secondaryLighterBlue"
                        borderRadius="4px"
                        width="71px"
                        height="32px"
                        min={minValue}
                        step={sensitivity}
                        max={maxValue}
                    />
                    <Input
                        mt="12px"
                        className="intensity-slider"
                        width="60%"
                        value={v}
                        onChange={(e) => onChange(Number(e.target.value), 'v')}
                        type="range"
                        step={sensitivity}
                        max={maxValue}
                        min={minValue}
                        bg="trasparent"
                    ></Input>
                </Box>
            </Box>
        </Box>
    )
}

export function Slider({
    value,
    onChange,
    label,
    minValue = 0,
    maxValue = 1,
    sensitivity = 0.1,
    unit = 'number',
}: {
    value: number
    onChange: (value: number) => void
    label: string
    minValue?: number
    maxValue?: number
    sensitivity?: number
    unit?: 'number' | 'percentage'
}) {
    return (
        <Box>
            <Text my="0px" fontSize="12px" mb="8px">
                {label}
            </Text>
            <Box display="flex" justifyContent="space-between">
                <Input
                    type="number"
                    value={
                        unit === 'number'
                            ? Math.abs(value || 0)
                            : Math.abs(value || 0) * 100
                    }
                    bg="transparent"
                    onChange={(e) => onChange(Number(e.target.value))}
                    border="solid"
                    borderWidth={1}
                    borderColor="secondaryLighterBlue"
                    borderRadius="4px"
                    width="71px"
                    step={sensitivity}
                    height="32px"
                    max={maxValue}
                    min={minValue}
                />
                <Input
                    mt="12px"
                    className="intensity-slider"
                    width="60%"
                    value={value || 0}
                    onChange={(e) => onChange(Number(e.target.value))}
                    type="range"
                    step={sensitivity}
                    bg="trasparent"
                    max={maxValue}
                    min={minValue}
                ></Input>
            </Box>
        </Box>
    )
}
