import * as React from 'react'
export const CommentAnnotation = ({ size = 18 }: { size?: number }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
    >
        <path
            fill="#778CA2"
            d="m4.166 10.502 1.58.034 5.145-4.872a.407.407 0 0 0 .126-.297.407.407 0 0 0-.113-.303L9.825 4.041a.34.34 0 0 0-.247-.105.34.34 0 0 0-.253.094L4.198 9.022l-.032 1.48Zm3.3.07 6.298.134.026-1.2-5.099-.108-1.225 1.174ZM.83 16.832l.315-14.796c.007-.32.133-.598.378-.832.245-.235.527-.35.847-.343l13.597.29c.32.007.598.133.832.378.235.245.35.528.342.847l-.221 10.398a1.15 1.15 0 0 1-.378.832 1.151 1.151 0 0 1-.847.342l-11.598-.247-3.267 3.131Zm2.773-4.342 12.117.258.222-10.397-13.597-.29L2.09 14.06l1.514-1.569Z"
        />
    </svg>
)
export const CommentAnnotationLG = ({
    fill = '#fff',
    stroke = '#778CA2',
}: {
    fill?: string
    stroke?: string
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
        {' '}
        <rect width={32} height={32} rx={4} fill={fill} />
        <path
            fill={stroke}
            d="M11.2 17.6h1.58l5.04-4.98c.08-.08.12-.18.12-.3s-.04-.22-.12-.3l-1.1-1a.34.34 0 0 0-.25-.1.34.34 0 0 0-.25.1l-5.02 5.1v1.48Zm3.3 0h6.3v-1.2h-5.1l-1.2 1.2ZM8 24V9.2c0-.32.12-.6.36-.84S8.88 8 9.2 8h13.6c.32 0 .6.12.84.36s.36.52.36.84v10.4c0 .32-.12.6-.36.84s-.52.36-.84.36H11.2L8 24Zm2.68-4.4H22.8V9.2H9.2v12l1.48-1.6Z"
        />
    </svg>
)
