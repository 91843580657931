import { Box, Input, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { DivisionLine } from 'src/components/division-line/division-line'
import { useState } from 'react'
import { degToRad } from 'three/src/math/MathUtils'
import type { MeshCustomMaterial } from '../material/custom-material'
import { Box3, Vector3 } from 'three'
import type { Mesh } from 'three'
import { createMaterial } from '../../helper/create-material'
import { UVScale } from './uvscale'

export function GraphicHelpers() {
    const { graphic, setGraphic, highlights } = useConfiguratorContext()

    const [isLinked, setIsLinked] = useState({
        isScaleLinked: true,
        isOffsetLinked: true,
    })

    function updateRepeatAndOffset(
        value: number,
        key: 'u' | 'v',
        type: 'offset' | 'repeat'
    ) {
        const mesh = highlights[0].mesh
        const material = mesh.material as MeshCustomMaterial
        if (graphic) {
            switch (type) {
                case 'offset':
                    if (isLinked.isOffsetLinked) {
                        mesh.material.mapLayer1.offset.x = value
                        mesh.material.mapLayer1.offset.y = value
                        if (mesh.material.normalMapLayer1) {
                            mesh.material.normalMapLayer1.offset.x = value
                            mesh.material.normalMapLayer1.offset.y = value
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                offset: {
                                    ...graphic.config.offset,
                                    u: value,
                                    v: value,
                                },
                            },
                        })
                    } else {
                        if (key === 'u') {
                            mesh.material.mapLayer1.offset.x = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.offset.x = value
                            }
                        } else {
                            mesh.material.mapLayer1.offset.y = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.offset.y = value
                            }
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                offset: {
                                    ...graphic.config.offset,
                                    [key]: value,
                                },
                            },
                        })
                    }
                    material.mapLayer1.center.set(
                        material.mapLayer1.offset.x -
                            0.5 * (1 - material.mapLayer1.repeat.x),
                        material.mapLayer1.offset.y -
                            0.5 * (1 - material.mapLayer1.repeat.y)
                    )
                    return
                case 'repeat':
                    if (isLinked.isScaleLinked) {
                        mesh.material.mapLayer1.repeat.x = value
                        mesh.material.mapLayer1.repeat.y = value
                        if (mesh.material.normalMapLayer1) {
                            mesh.material.normalMapLayer1.repeat.x = value
                            mesh.material.normalMapLayer1.repeat.y = value
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                repeat: {
                                    ...graphic.config.repeat,
                                    u: value,
                                    v: value,
                                },
                            },
                        })
                    } else {
                        if (key === 'u') {
                            mesh.material.mapLayer1.repeat.x = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.repeat.x = value
                            }
                        } else {
                            mesh.material.mapLayer1.repeat.y = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.repeat.y = value
                            }
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                repeat: {
                                    ...graphic.config.repeat,
                                    [key]: value,
                                },
                            },
                        })
                    }
                    return
            }
        }
    }

    function updateSubstanceness(value: number) {
        if (!graphic) return
        const mesh = highlights[0].mesh
        mesh.material.substancenessLayer1 = value
        setGraphic({
            ...graphic,
            config: {
                ...graphic.config,
                substanceNess: value,
            },
        })
    }

    function updateTransparencyAndRotation(
        value: number,
        type: 'rotation' | 'tranparency'
    ) {
        if (!graphic) return
        const mesh = highlights[0].mesh
        const material = highlights[0].mesh as MeshCustomMaterial

        switch (type) {
            case 'tranparency':
                mesh.material.transparencyLayer1 = value
                return setGraphic({
                    ...graphic,
                    config: {
                        ...graphic.config,
                        transparency: value,
                    },
                })
            case 'rotation':
                mesh.material.mapLayer1.rotation = degToRad(value)
                if (mesh.material.normalMapLayer1) {
                    mesh.material.normalMapLayer1.rotation = degToRad(value)
                }
                setGraphic({
                    ...graphic,
                    config: {
                        ...graphic.config,
                        rotation: value,
                    },
                })
                return
        }
    }

    return (
        <>
            <DivisionLine />
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gridRowGap="12px"
                mt="16px"
            >
                <Box>
                    <Text my="0px" fontSize="12px" mb="8px">
                        Blend with Background%
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                        <Input
                            type="number"
                            value={(graphic?.config.substanceNess || 0) * 100}
                            bg="transparent"
                            onChange={(e) =>
                                updateSubstanceness(
                                    Number(e.target.value) / 100
                                )
                            }
                            border="solid"
                            borderWidth={1}
                            borderColor="secondaryLighterBlue"
                            borderRadius="4px"
                            width="71px"
                            height="32px"
                            max="1"
                        />
                        <Input
                            mt="12px"
                            className="intensity-slider"
                            width="60%"
                            value={graphic?.config.substanceNess || 0}
                            onChange={(e) =>
                                updateSubstanceness(Number(e.target.value))
                            }
                            type="range"
                            min="0"
                            step="0.01"
                            max="1"
                            bg="trasparent"
                        ></Input>
                    </Box>
                </Box>

                <Box>
                    <Text my="0px" fontSize="12px" mb="8px">
                        Scale
                    </Text>
                    <UVScale
                        onLinkClick={() =>
                            setIsLinked({
                                ...isLinked,
                                isScaleLinked: !isLinked.isScaleLinked,
                            })
                        }
                        isLinkActive={isLinked.isScaleLinked}
                        sensitivity={0.00001}
                        u={graphic?.config.repeat.u || 0}
                        v={graphic?.config.repeat.v || 0}
                        onChange={(value, key) =>
                            updateRepeatAndOffset(value, key, 'repeat')
                        }
                        minValue={-1}
                        maxValue={1}
                    />
                </Box>
                <Box>
                    <Text my="0px" fontSize="12px" mb="8px">
                        Offset
                    </Text>
                    <UVScale
                        onLinkClick={() =>
                            setIsLinked({
                                ...isLinked,
                                isOffsetLinked: !isLinked.isOffsetLinked,
                            })
                        }
                        isLinkActive={isLinked.isOffsetLinked}
                        maxValue={1}
                        minValue={-1}
                        sensitivity={0.00001}
                        u={graphic?.config.offset.u || 0}
                        v={graphic?.config.offset.v || 0}
                        onChange={(value, key) =>
                            updateRepeatAndOffset(value, key, 'offset')
                        }
                    />
                </Box>
                <Box>
                    <Text my="0px" fontSize="12px" mb="8px">
                        Transparency%
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                        <Input
                            type="number"
                            value={
                                Math.abs(graphic?.config.transparency || 0) *
                                100
                            }
                            bg="transparent"
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'tranparency'
                                )
                            }
                            border="solid"
                            borderWidth={1}
                            borderColor="secondaryLighterBlue"
                            borderRadius="4px"
                            width="71px"
                            height="32px"
                            max="1"
                        />
                        <Input
                            mt="12px"
                            className="intensity-slider"
                            width="60%"
                            value={graphic?.config.transparency || 0}
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'tranparency'
                                )
                            }
                            type="range"
                            min="0"
                            step="0.1"
                            max="1"
                            bg="trasparent"
                        ></Input>
                    </Box>
                </Box>
                <Box>
                    <Text my="0px" fontSize="12px" mb="8px">
                        Rotation
                    </Text>
                    <Text fontSize="12px" my="0px" mb="4px">
                        Degrees
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                        <Input
                            type="number"
                            value={graphic?.config.rotation || 0}
                            bg="transparent"
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'rotation'
                                )
                            }
                            border="solid"
                            borderWidth={1}
                            borderColor="secondaryLighterBlue"
                            borderRadius="4px"
                            width="71px"
                            height="32px"
                            max="100"
                        />
                        <Input
                            mt="12px"
                            className="intensity-slider"
                            width="60%"
                            value={graphic?.config.rotation || 0}
                            onChange={(e) =>
                                updateTransparencyAndRotation(
                                    Number(e.target.value),
                                    'rotation'
                                )
                            }
                            type="range"
                            min="0"
                            step="1"
                            max="360"
                            bg="trasparent"
                        ></Input>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export function calculateCurrentUv(mesh: Mesh) {
    if (mesh) {
        const uvAttribute = mesh.geometry.attributes.uv
        if (uvAttribute) {
            let minU = Infinity,
                maxU = -Infinity
            let minV = Infinity,
                maxV = -Infinity

            for (let i = 0; i < uvAttribute.count; i++) {
                const u = uvAttribute.getX(i)
                const v = uvAttribute.getY(i)

                if (u < minU) minU = u
                if (u > maxU) maxU = u
                if (v < minV) minV = v
                if (v > maxV) maxV = v
            }

            return {
                x: maxU - minU,
                y: maxV - minV,
            }
        }
    }
    return { x: 1, y: 1 }
}

export function getImageDimensions(
    path: string
): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
        const img = new Image()

        img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight })
        }

        img.onerror = reject

        img.src = path
    })
}
export function getShrinkFactor(
    boundingBox: Vector3,
    currentUVSize: { x: number; y: number },
    imageDimensions: { width: number; height: number }
) {
    const { width, height } = imageDimensions

    // Normalize current UV size relative to the bounding box
    const normalizedUVSize = {
        x: currentUVSize.x / boundingBox.x,
        y: currentUVSize.y / boundingBox.y,
    }

    // Calculate the aspect ratio
    const meshAspectRatio = boundingBox.x / boundingBox.y
    const imageAspectRatio = width / height

    // Calculate shrink factors for U and V
    let shrinkFactorU = normalizedUVSize.x / width
    let shrinkFactorV = normalizedUVSize.y / height

    // Adjust shrink factors based on aspect ratios
    if (meshAspectRatio > imageAspectRatio) {
        shrinkFactorU *= imageAspectRatio / meshAspectRatio
    } else {
        shrinkFactorV *= meshAspectRatio / imageAspectRatio
    }

    // Apply manual scaling factor to balance UVs
    const manualScalingFactor = 0.5
    shrinkFactorU *= manualScalingFactor
    shrinkFactorV *= manualScalingFactor

    return {
        u: shrinkFactorU,
        v: -shrinkFactorV, // Negative to account for V-flip
    }
}

export function createBoundingBox(mesh: Mesh) {
    if (mesh) {
        const box = new Box3().setFromObject(mesh)
        const size = box.getSize(new Vector3())
        return size
    }
    return null
}

export function calculateOffset(scalingFactor: { x: number; y: number }) {
    // Center the texture
    const offsetX = (1 - scalingFactor.x) / 2
    const offsetY = (1 - scalingFactor.y) / 2

    // Ensure offsets are within bounds
    return {
        x: Math.min(Math.max(offsetX, 0), 1),
        y: Math.min(Math.max(offsetY, 0), 1),
    }
}

export async function addGraphic(
    boundingBox: Vector3,
    mesh: Mesh,
    currentUVSize: { x: number; y: number },
    path: string
) {
    if (boundingBox && mesh) {
        const imageDimensions = await getImageDimensions(path)
        // const shrinkFactor = 5.126107
        const shrinkFactor = 4.07489
        const { u, v } = getShrinkFactor(
            boundingBox,
            currentUVSize,
            imageDimensions
        )

        const scalingFactor = {
            x: (boundingBox.x / currentUVSize.x) * shrinkFactor,
            y: (boundingBox.y / currentUVSize.y) * shrinkFactor,
        }
        const offset = calculateOffset(scalingFactor)

        // const scalingFactor = {
        //     x: u,
        //     y: v,
        // }
        // const offset = calculateOffset({ x: u, y: v })

        // Apply the texture repeat and offset
        const texture = createMaterial(path, {
            offset: {
                u: offset.x,
                v: offset.y,
            },
            scale: {
                u: scalingFactor.x,
                v: scalingFactor.y,
            },
        }).plain
        // Apply the texture to the selected mesh
        // @ts-ignore
        const material = mesh.material as MeshCustomMaterial

        material.mapLayer1 = texture
        // @ts-ignore
        material.needsUpdate = true
        return {
            offset: {
                u: offset.x,
                v: offset.y,
            },
            repeat: {
                u: scalingFactor.x,
                v: scalingFactor.y,
            },
        }
    }
}
