import Skeleton from 'react-loading-skeleton'
import { Box } from '../utility'

export function CardLoader({
    skeletonCount = 3,
    cards = 1,
}: {
    skeletonCount?: number
    cards?: number
}) {
    return (
        <Box
            id="card-loader"
            width="100%"
            position="relative"
            p="16px"
            display="flex"
            flexDirection="column"
            gridGap="20px"
        >
            {[...Array(cards)].map((_, index) => {
                return (
                    <Box bg="white" border="1px solid #dddddd">
                        <Skeleton
                            key={index}
                            count={skeletonCount}
                            height={'100%'}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}
