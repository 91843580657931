import { Box } from 'src/components/utility'
import { InProgressIco, WarningIco } from 'src/components/svg-icons'
export function ProgressIcons({
    completed,
    remaining,
    primaryIcon,
    secondaryIcon,
    fontSize,
    isDisabled = false,
}: {
    completed: string | number
    remaining: string | number
    isDisabled?: boolean
    fontSize?: FontSize
    primaryIcon?: JSX.Element
    secondaryIcon?: JSX.Element
}) {
    return (
        <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            gridColumnGap="12px"
            fontFamily="Rubik"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap="6px"
                fontSize={fontSize}
                color={isDisabled ? 'textSecondaryDarkBlue' : 'primary'}
            >
                {primaryIcon || (
                    <InProgressIco color={isDisabled ? '#778CA2' : undefined} />
                )}{' '}
                {completed}{' '}
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap="4px"
                fontSize={fontSize}
                color={isDisabled ? 'textSecondaryDarkBlue' : 'primary'}
            >
                {secondaryIcon || (
                    <WarningIco color={isDisabled ? '#778CA2' : undefined} />
                )}{' '}
                {remaining}
            </Box>
        </Box>
    )
}
