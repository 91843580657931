import * as React from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export interface IMeatBallDropdownProps {
    type?: 'team' | 'product' | 'todo' | 'collection' | 'variant'
    onClick: (label: string) => void
    isActive: boolean
    left?: string
    right?: string
    setIsActive: (isActive: boolean) => void
    onClose: () => void
}

export default function MeatBallDropdown(props: IMeatBallDropdownProps) {
    const {
        onClick,
        isActive,
        setIsActive,
        left = '32px',
        type = 'collection',
        right = '0px',
        onClose,
    } = props

    const dropdownRef = useClickedOutside(onClose)

    return (
        <Dropdown left={left} right={right} active={isActive} ref={dropdownRef}>
            <>
                <DropdownButtonWrapper
                    onClick={() => {
                        setIsActive(!isActive)
                        onClick('Copy Link')
                    }}
                    width="100%"
                >
                    <Button
                        onClick={(e) => e.preventDefault()}
                        width="100%"
                        bg="transparent"
                        border="none"
                        textAlign="left"
                    >
                        Copy Link
                    </Button>
                </DropdownButtonWrapper>
                {(type === 'team' || type === 'variant') && (
                    <DropdownButtonWrapper
                        onClick={() => {
                            setIsActive(!isActive)
                            onClick('Rename')
                        }}
                        width="100%"
                    >
                        <Button
                            onClick={(e) => e.preventDefault()}
                            width="100%"
                            bg="transparent"
                            border="none"
                            textAlign="left"
                        >
                            Rename
                        </Button>
                    </DropdownButtonWrapper>
                )}
                {type === 'variant' && (
                    <DropdownButtonWrapper
                        onClick={() => {
                            setIsActive(!isActive)
                            onClick('Make a Copy')
                        }}
                        width="100%"
                    >
                        <Button
                            onClick={(e) => e.preventDefault()}
                            width="100%"
                            bg="transparent"
                            border="none"
                            textAlign="left"
                        >
                            Make a Copy
                        </Button>
                    </DropdownButtonWrapper>
                )}
                <DropdownButtonWrapper
                    width="100%"
                    onClick={() => {
                        setIsActive(!isActive)
                        onClick('Delete')
                    }}
                >
                    <Button
                        onClick={(e) => e.preventDefault()}
                        width="100%"
                        bg="transparent"
                        color="delay"
                        textAlign="left"
                        border="none"
                    >
                        Delete
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
