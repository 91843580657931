import { Box } from 'src/components/utility'
import { ManagerCollectionsList } from './collections-list'
import { DueCollectionsList } from './due-collections'

export const YourCollectionsList = ({ role }: { role: string }) => {
    const boxHeight = '500px'
    return (
        <>
            <Box width="100%">
                {role === 'admin' ? (
                    <DueCollectionsList role={role} boxHeight={boxHeight} />
                ) : null}
                {role !== 'admin' ? (
                    <ManagerCollectionsList role={role} boxHeight={boxHeight} />
                ) : null}
            </Box>
        </>
    )
}
