import { uuid } from 'src/helpers'

export async function convertImageURIToObject(
    imageURI: string,
    imageName: string
) {
    // Fetch the image data from the URI
    const response = await fetch(imageURI)
    const blob = await response.blob()

    // Create the object
    const imageObject = {
        name: imageName,
        path: imageURI,
        blob: blob,
        id: uuid(),
    }

    return imageObject
}
