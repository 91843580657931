import { useState, useRef } from 'react'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Box } from 'src/components/utility'
import { DropDownActions } from './dropdown-action'
import { getFormattedDueDateTitle } from 'src/helpers/get-formatted-date'

export const DueDateDropdown = ({
    dueDate,
    setDueDate,
    rightOffset,
}: {
    dueDate: [Date | null, Date | null] | Date | null
    setDueDate: React.Dispatch<
        React.SetStateAction<[Date | null, Date | null] | Date | null>
    >
    rightOffset?: string
}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const dropdownButton = useRef<any>(null)

    return (
        <Box
            id="duedate-dropdown"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap="29px"
            position="relative"
        >
            <Box
                ref={dropdownButton}
                onClick={() => setIsDropDownActive(!isDropDownActive)}
            >
                <IconDropdownButton isActive={!!dueDate}>
                    {!!dueDate
                        ? 'Due on ' + getFormattedDueDateTitle({ dueDate })
                        : 'Choose Date Range'}
                </IconDropdownButton>
            </Box>

            {isDropDownActive && (
                <DropDownActions
                    setIsDropdownActive={setIsDropDownActive}
                    setDate={setDueDate}
                    active={isDropDownActive}
                    date={dueDate}
                    rightOffset={rightOffset}
                    selectRange={true}
                />
            )}
        </Box>
    )
}
