import type { TooltipProps } from '@mui/material'
import { styled, Tooltip, tooltipClasses } from '@mui/material'
import React from 'react'
import { getFormattedDate } from 'src/helpers'
import { DivisionLine } from '../division-line/division-line'
import { ProfileImg } from '../profile-img/profile-img'
import { SmPencilIco } from '../svg-icons/sm-pencil'
import { SmPersonIco } from '../svg-icons/sm-person-ico'
import { Box, Text, Span } from '../utility'

const areEqual = (prevList: any, nextList: any) => {
    return JSON.stringify(prevList) !== JSON.stringify(nextList)
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF !important',
        padding: '0px',
        fontFamily: 'Rubik',
        fontWeight: 400,
        boxShadow: '0px 2px 12px 0px #999BA840',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#fff',
    },
}))

export const StackedImage = React.memo(
    ({
        list,
        width,
        size,
        spacing,
        fontSize,
        onClick,
        mr = '4px',
    }: {
        list: {
            src: string
            name: string
            role?: string
            email?: string
            joined?: string
        }[]
        width?: string
        size?: string
        spacing?: number
        fontSize?: FontSize
        onClick?: () => void
        mr?: FourPixelGridSystem
    }) => {
        let flag = false
        return (
            <Box
                position="relative"
                width={width || '128px'}
                minWidth={`${
                    list?.length > 5
                        ? 5 * (spacing || 24) + 'px'
                        : list?.length * (spacing || 24) + 'px'
                }`}
                height={size}
                className={onClick ? 'cursor-pointer' : ''}
                onClick={onClick ? onClick : () => {}}
                mr={mr}
            >
                {list
                    .sort((a, b) => a.name.localeCompare(b.name))
                    ?.map(({ src, name, role, email, joined }, index) => {
                        if (index < 3) {
                            return (
                                <Box
                                    position="absolute"
                                    left={index * (spacing || 24) + 'px'}
                                >
                                    <HtmlTooltip
                                        placement="bottom-start"
                                        arrow
                                        title={
                                            <React.Fragment>
                                                <Box
                                                    boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
                                                    display="flex"
                                                    flexDirection="column"
                                                    p="0px"
                                                    zIndex={10}
                                                    overflowX="hidden"
                                                    bg="white"
                                                    borderRadius="4px"
                                                    className="img-details"
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        p="12px"
                                                    >
                                                        <ProfileImg
                                                            key={index}
                                                            src={src}
                                                            name={name}
                                                            size={size}
                                                            style={{
                                                                width:
                                                                    size ||
                                                                    '32px',
                                                                height:
                                                                    size ||
                                                                    '32px',
                                                                borderRadius:
                                                                    '50%',
                                                            }}
                                                            fontSize={fontSize}
                                                            className="img-clear-border"
                                                        />
                                                        <Box pl={'4px'}>
                                                            <Text
                                                                fontSize={
                                                                    email
                                                                        ? '16px'
                                                                        : fontSize
                                                                }
                                                                mb={
                                                                    role
                                                                        ? '4px'
                                                                        : '0px'
                                                                }
                                                                mt={'0px'}
                                                                color="primary"
                                                                fontWeight={500}
                                                            >
                                                                {' '}
                                                                {name}{' '}
                                                            </Text>
                                                            {role && (
                                                                <Text
                                                                    fontSize={
                                                                        '12px'
                                                                    }
                                                                    my="0px"
                                                                    className="text-capitalize"
                                                                    color="primary"
                                                                >
                                                                    {' '}
                                                                    {role}{' '}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Box>

                                                    {email && (
                                                        <>
                                                            <DivisionLine />
                                                            <Box
                                                                px="12px"
                                                                py={'8px'}
                                                            >
                                                                <Text
                                                                    mb={'8px'}
                                                                    mt="0px"
                                                                    fontSize={
                                                                        '12px'
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    <Span
                                                                        display="inline-block"
                                                                        mr={
                                                                            '8px'
                                                                        }
                                                                    >
                                                                        <SmPencilIco />
                                                                    </Span>
                                                                    {email}
                                                                </Text>
                                                                {joined && (
                                                                    <Text
                                                                        fontSize={
                                                                            '12px'
                                                                        }
                                                                        my="0px"
                                                                        color="primary"
                                                                    >
                                                                        <Span
                                                                            display="inline-block"
                                                                            mr={
                                                                                '8px'
                                                                            }
                                                                        >
                                                                            <SmPersonIco />
                                                                        </Span>
                                                                        Member
                                                                        since{' '}
                                                                        {getFormattedDate(
                                                                            joined,
                                                                            'PLAIN'
                                                                        )}
                                                                    </Text>
                                                                )}
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <Box>
                                            <ProfileImg
                                                key={index}
                                                src={src}
                                                name={name}
                                                size={size}
                                                style={{
                                                    width: size || '32px',
                                                    height: size || '32px',
                                                    borderRadius: '50%',
                                                }}
                                                fontSize={fontSize}
                                                className="img-clear-border"
                                            />
                                        </Box>
                                    </HtmlTooltip>
                                </Box>
                            )
                        } else if (!flag) {
                            flag = true
                            return (
                                <Box
                                    key={index}
                                    width={size || '32px'}
                                    height={size || '32px'}
                                    borderRadius="50%"
                                    position="absolute"
                                    border="solid"
                                    borderWidth={2}
                                    borderColor="gray350"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    bg="white"
                                    left={index * (spacing || 24) + 'px'}
                                    fontFamily="Rubik"
                                    color="textSecondaryDarkBlue"
                                    fontSize={fontSize || '16px'}
                                >
                                    +{list?.length - 3}
                                </Box>
                            )
                        }
                        return null
                    })}
            </Box>
        )
    },
    (prev: { list: ImageList }, next: { list: ImageList }) => {
        return window?.location?.pathname.includes('create')
            ? !areEqual(prev, next)
            : false
    }
)

type ImageList = {
    src: string
    name: string
    role?: string
    email?: string
    joined?: string
}[]
