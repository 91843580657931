import StyledLayout from './styled-layout'
import { Box } from '../utility'
import { Nav } from '../nav/nav'
import useStore from 'src/store/hooks/useStore'
import { ProfileModal } from '../sidebar/profile/profile-modal'
import { Sidebar } from '../sidebar/sidebar'
import GlobalSearch from 'src/screens-v2/global-search/global-search'

type LayoutProps = {
    children?: React.ReactNode | null
    paddingX?: FourPixelGridSystem
    paddingBottom?: FourPixelGridSystem
}

export const Layout = ({
    children,
    paddingX = '24px',
    paddingBottom = '24px',
}: LayoutProps) => {
    const {
        isProfileModalOpen,
        setIsModalProfileModalOpen,
        globalSearchFilters: { isGlobalSearchActive },
    } = useStore()
    return (
        <>
            <ProfileModal
                isModal={isProfileModalOpen}
                setIsModal={(val) => setIsModalProfileModalOpen(val)}
            />
            <StyledLayout
                background="#F8FAFB"
                fontFamily="Rubik"
                position="relative"
                className="sitefontnormal"
            >
                <Box
                    display="grid"
                    gridTemplateColumns="auto 1fr"
                    gridTemplateRows="60px auto"
                >
                    <Sidebar />
                    <Nav />
                    <Box
                        gridColumn={2}
                        gridRow={2}
                        width="100%"
                        height="100%"
                        bg="backgroundColor"
                        overflow="hidden"
                    >
                        <Box
                            px={
                                !isGlobalSearchActive
                                    ? paddingX || '24px'
                                    : '0px'
                            }
                            height="calc(100vh - 60px)"
                            overflowY="auto"
                            id="layoutContainer2"
                            bg={
                                isGlobalSearchActive
                                    ? 'white'
                                    : 'backgroundColor'
                            }
                            pb={paddingBottom}
                        >
                            {!isGlobalSearchActive ? (
                                children
                            ) : (
                                <GlobalSearch />
                            )}
                        </Box>
                    </Box>
                </Box>
            </StyledLayout>
        </>
    )
}
