import { Box } from '../utility'
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    tabName?: string
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, tabName, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${tabName}-tabpanel-${index}`}
            aria-labelledby={`${tabName}-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}
