import type { Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export function CommentMenuDropdown({
    active,
    setIsDropdownActive,
    onDelete,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    onDelete: () => void
}) {
    const wrapperRef = useClickedOutside(() => setIsDropdownActive(false))

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="32px"
            left="none"
            right="0px"
            width="150px"
        >
            <Box ref={wrapperRef} width="100%">
                {/* <DropdownButtonWrapper value="Collections" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="#022143"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            setIsDropdownActive(false)
                        }}
                    >
                        Edit
                    </Button>
                </DropdownButtonWrapper> */}
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="#FF2C18"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            setIsDropdownActive(false)
                            onDelete()
                        }}
                    >
                        Delete
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}
