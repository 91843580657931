import { useApolloClient, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import TodoTemplateCard from 'src/components-v2/todo-templates/todo-template-card/todo-template-card'
import type { TodoTemplateType } from 'src/components-v2/todo-templates/todo-templates.types'
import { Box, Text } from 'src/components/utility'
import { GetTodoTemplates } from 'src/services/graphql/entities/todo/todo-templates.queries'
import axios from 'axios'
import { ASSING_TEMPLATE } from 'src/services/api/endpoints'
import { getLocalStorage } from 'src/helpers'
import { toast } from 'react-toastify'
import PreviewEditTemplateModal from '../preview-edit-template-modal/preview-edit-template-modal'
import type { GetTodoTemplatesQuery } from 'src/generated/graphql'

function TemplatesSection({
    todoId,
    refetchTodo,
    activeFilters,
}: {
    todoId: number
    refetchTodo: () => void
    activeFilters: {
        category: { id: number | null; name: string }
        type: { id: number | null; type: string }
        searchQuery: string
    }
}) {
    const [selectedTemplate, setSelectedTemplate] =
        useState<TodoTemplateType | null>(null)
    const client = useApolloClient()

    const { data: templatesData, loading: templatesLoading } =
        useQuery<GetTodoTemplatesQuery>(GetTodoTemplates, {
            variables: {
                template_category_id: activeFilters.category.id
                    ? { _eq: activeFilters.category.id }
                    : {},
                template_type_id: activeFilters.type.id
                    ? { _eq: activeFilters.type.id }
                    : {},
                searchQuery: activeFilters.searchQuery,
            },
        })

    const templates = templatesLoading ? null : templatesData?.templates

    async function assignTemplateToTodoHandler(
        todoId: number,
        templateId: number
    ) {
        try {
            const response = await axios.post(
                ASSING_TEMPLATE,
                {
                    todo_id: todoId,
                    template_id: templateId,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                refetchTodo()
                client.refetchQueries({
                    include: [
                        'GetTemplateCategoriesWithTemplates',
                        'GetTodoTemplates',
                        'GetFavoriteTemplates',
                    ],
                })
                toast('Assigned template successfully', {
                    className: 'custom-toaster toaster-success',
                })
            } else {
                toast('Error occurred while assigning template', {
                    className: 'custom-toaster toaster-error',
                })
            }
        } catch {
            toast('Error occurred while assigning template', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return (
        <Box display="flex" flexDirection="column" gridGap="8px">
            <Text my="0px" fontSize="14px" color="textTags">
                Templates
            </Text>
            <Box display="flex" gridGap="16px" flexWrap="wrap">
                {templates && templates?.length > 0 ? (
                    templates.map((template) => (
                        <TodoTemplateCard
                            key={template.id}
                            template={template}
                            onClick={() => {
                                setSelectedTemplate(template)
                            }}
                        />
                    ))
                ) : (
                    <Box>
                        <Text my="0px" fontSize="14px" color="textTags">
                            No templates found
                        </Text>
                    </Box>
                )}
            </Box>
            {selectedTemplate && (
                <PreviewEditTemplateModal
                    isOpen={!!selectedTemplate}
                    onClose={() => {
                        setSelectedTemplate(null)
                    }}
                    isLoading={false}
                    onClick={() => {
                        assignTemplateToTodoHandler(todoId, selectedTemplate.id)
                    }}
                    template={selectedTemplate}
                    templateId={selectedTemplate.id}
                />
            )}
        </Box>
    )
}

export default TemplatesSection
