import { useNavigate } from 'react-router-dom'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { CollectionIco, ProductIco, TodoIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'

export function EntityDetails({
    entityInfo,
    iconColor = '#C2CFE0',
    textColor = 'textSecondaryDarkBlue',
    nameLimit = 40,
}: {
    entityInfo: {
        entityName: string
        name: string
        entityId: string
    }
    iconColor?: string
    textColor?: string
    nameLimit?: number
}) {
    const navigate = useNavigate()
    if (!entityInfo?.entityName) return <></>
    if (entityInfo.name.length <= 0) return <></>

    switch (entityInfo.entityName) {
        case 'PRODUCTS':
        case 'PRODUCT':
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/collections/product/${entityInfo.entityId}`)
                    }}
                >
                    <ProductIco color={iconColor} />
                    <Text color={textColor} fontSize="12px" my="0px">
                        {convertIntoElipses(entityInfo.name, nameLimit)}
                    </Text>
                </Box>
            )
        case 'PRODUCT_VARIANTS':
        case 'PRODUCT_VARIANT':
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/collections/product/${entityInfo.entityId}`)
                    }}
                >
                    <ProductIco color={iconColor} />
                    <Text color={textColor} fontSize="12px" my="0px">
                        {convertIntoElipses(entityInfo.name, nameLimit)}
                    </Text>
                </Box>
            )
        case 'COLLECTIONS':
        case 'COLLECTION':
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/collections/${entityInfo.entityId}`)
                    }}
                >
                    <CollectionIco color={iconColor} />
                    <Text color={textColor} fontSize="12px" my="0px">
                        {convertIntoElipses(entityInfo.name, nameLimit)}
                    </Text>
                </Box>
            )
        case 'TODOS':
        case 'TODO':
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/todo/${entityInfo.entityId}`)
                    }}
                >
                    <TodoIco color={iconColor} />
                    <Text color={textColor} fontSize="12px" my="0px">
                        {convertIntoElipses(entityInfo.name, nameLimit)}
                    </Text>
                </Box>
            )
    }
}
