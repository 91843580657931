import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { StackedImage } from 'src/components/stacked-image/stacked-image'
import { ProductIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import type { TSearchedTeams } from '../@types/search'
import { useRecentSearch } from '../hooks/useRecentSearch'
import useStore from 'src/store/hooks/useStore'
import { useNavigate } from 'react-router-dom'

type TTeamVariantProps = {
    entity: TSearchedTeams
    onCardClick?: () => void
}

export function TeamVariant(props: TTeamVariantProps) {
    const { addRecentSearch } = useRecentSearch()
    const { setGlobalSearchActive } = useStore()
    const { globalSearchFilters } = useStore()
    const navigate = useNavigate()
    return (
        <Box
            color="textSecondaryDarkBlue"
            display="flex"
            flexDirection="column"
            className="cursor-pointer"
            gridRowGap="8px"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            onClick={() => {
                setGlobalSearchActive({ isGlobalSearchActive: false })
                props.onCardClick && props.onCardClick()
                addRecentSearch({
                    entity_name: 'teams',
                    entity_id: props.entity.id,
                    search_query: globalSearchFilters.query,
                })
                navigate('/teams/' + props.entity.id)
            }}
            py="8px"
            px="4px"
        >
            <Box display="flex" gridColumnGap="8px" alignItems="start">
                <StackedImage
                    size="20px"
                    fontSize="12px"
                    spacing={12}
                    width="max-content"
                    list={[
                        {
                            name: 'Ishan',
                            src: '',
                        },
                        {
                            name: 'Jack',
                            src: '',
                        },
                        {
                            name: 'Test',
                            src: '',
                        },
                    ]}
                />
                <Highlighted
                    search="Ha"
                    text="Hats_Final"
                    unHighlighted="textSecondaryDarkBlue"
                />
            </Box>
            <Box display="flex" alignItems="center" gridColumnGap="8px">
                <ProductIco />
                <Text my="0px" fontSize="12px">
                    Products Team
                </Text>
            </Box>
        </Box>
    )
}
