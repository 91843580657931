import TemplateIco from 'src/components/svg-icons/template-ico'
import { Box, Button, Text } from 'src/components/utility'
import type { TodoTemplateType } from '../todo-templates.types'

function TodoTemplateCard({
    template,
    onClick,
}: {
    template: TodoTemplateType
    onClick: () => void
}) {
    return (
        <Button
            display="grid"
            style={{ flexDirection: 'column', gap: '12px' }}
            py="12px"
            px="16px"
            bg="white"
            border="1px solid #E8ECEF"
            width="180px"
            onClick={onClick}
        >
            <Box
                display="grid"
                gridTemplateColumns={'auto 1fr'}
                alignItems="flex-start"
                gridGap="8px"
            >
                <TemplateIco />
                <Text
                    my="0px"
                    textAlign="left"
                    style={{ wordBreak: 'break-all' }}
                >
                    {template.name}
                </Text>
            </Box>
            <Box display="flex">
                <Text
                    my="0px"
                    color="textSecondary"
                    fontSize="10px"
                    textAlign="left"
                    style={{ wordBreak: 'break-word' }}
                >{`${template.template_category.template_type.type} > ${template.template_category.name}`}</Text>
            </Box>
        </Button>
    )
}

export default TodoTemplateCard
