import React, { useState } from 'react'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Modal } from 'src/components/modals/modal'
import { Box, Input, Text } from 'src/components/utility'
import { TodoTypeDropdown } from './todo-type-dropdown'
import { TodoCategoryDropdown } from './todo-category-dropdown'
import { useApolloClient, useMutation } from '@apollo/client'
import axios from 'axios'
import { CREATE_TEMPLATE } from 'src/services/api/endpoints'
import { getLocalStorage } from 'src/helpers'
import { toast } from 'react-toastify'
import {
    CreateTemplateCategory,
    CreateTodoTemplateType,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'
import {
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplateTypeNames,
} from 'src/services/graphql/entities/todo/todo-templates.queries'

export default function SaveTemplateDetailsModal({
    onClose,
    isOpen,
    todoId,
    templateRef,
}: {
    onClose: () => void
    isOpen: boolean
    todoId: number | null
    templateRef?: 'NEW' | 'TODO'
}) {
    const [isTodoTypeDropdownActive, setIsTodoTypeDropdownActive] =
        useState(false)
    const [isCategoryDropdownActive, setIsCategoryDropdownActive] =
        useState(false)
    const [saveTemplateDetails, setSaveTemplateDetails] = useState<{
        name: string
        type: { id: number | null; type: string }
        category: { id: number | null; name: string }
    }>({
        name: '',
        type: { id: null, type: '' },
        category: { id: null, name: '' },
    })
    const client = useApolloClient()

    const [newTodoType, setNewTodoType] = useState('')
    const [newCategoryName, setNewCategoryName] = useState('')

    const [
        createTodoTemplateTypeMutation,
        { loading: isTemplateTypeCreating },
    ] = useMutation(CreateTodoTemplateType)

    const [
        createTodoTemplateCategoryMutation,
        { loading: isTemplateCategoryCreating },
    ] = useMutation(CreateTemplateCategory)

    async function createTemplateHandler({
        name,
        template_type_id,
        template_category_id,
        todo_id,
        ref = 'TODO',
    }: {
        name: string
        template_type_id: number
        template_category_id: number
        todo_id: number | null
        ref?: 'TODO' | 'NEW'
    }) {
        try {
            const payload = {
                name: name,
                template_type_id: template_type_id,
                template_category_id: template_category_id,
                reference: ref,
                todo_id: todo_id,
            }
            const response = await axios.post(CREATE_TEMPLATE, payload, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })
            if (response.status === 200) {
                client.refetchQueries({
                    include: [
                        'GetTemplateCategoriesWithTemplates',
                        'GetTodoTemplates',
                        'GetFavoriteTemplates',
                    ],
                })
                toast('Template created successfully', {
                    className: 'custom-toaster toaster-success',
                })
                onClose()
            }
        } catch {
            toast('Error occurred while creating template', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    function validateFields() {
        if (saveTemplateDetails.name.trim().length <= 0) {
            toast('Please enter template name', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.type.id &&
            saveTemplateDetails.type.type !== 'Others'
        ) {
            toast('Please select Todo Type', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.type.id &&
            saveTemplateDetails.type.type == 'Others' &&
            newTodoType.trim().length <= 0
        ) {
            toast('Please enter new Todo Type', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.category.id &&
            saveTemplateDetails.category.name !== 'Others'
        ) {
            toast('Please select Todo Category', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.category.id &&
            saveTemplateDetails.category.name == 'Others' &&
            newCategoryName.trim().length <= 0
        ) {
            toast('Please select Todo Category', {
                className: 'custom-toaster toaster-error',
            })
        }

        return (
            saveTemplateDetails.name.trim().length > 0 &&
            (saveTemplateDetails.type.id ||
                (!saveTemplateDetails.type.id &&
                    saveTemplateDetails.type.type !== 'Others') ||
                (!saveTemplateDetails.type.id &&
                    saveTemplateDetails.type.type === 'Others' &&
                    newTodoType.trim().length > 0)) &&
            (saveTemplateDetails.category.id ||
                (!saveTemplateDetails.category.id &&
                    saveTemplateDetails.category.name !== 'Others') ||
                (!saveTemplateDetails.category.id &&
                    saveTemplateDetails.category.name === 'Others' &&
                    newCategoryName.trim().length > 0))
        )
    }

    async function SaveClickHandler() {
        try {
            if (validateFields()) {
                if (
                    saveTemplateDetails.name.trim().length > 0 &&
                    saveTemplateDetails.type.id &&
                    saveTemplateDetails.category.id
                ) {
                    createTemplateHandler({
                        name: saveTemplateDetails.name,
                        template_type_id: saveTemplateDetails.type.id,
                        template_category_id: saveTemplateDetails.category.id,
                        todo_id: todoId,
                        ref: templateRef,
                    })
                } else {
                    if (
                        saveTemplateDetails.type.id &&
                        !saveTemplateDetails.category.id &&
                        saveTemplateDetails.category.name === 'Others' &&
                        newCategoryName.trim().length > 0
                    ) {
                        const {
                            data: { insert_template_categories_one },
                        } = await createTodoTemplateCategoryMutation({
                            variables: {
                                object: {
                                    name: newCategoryName,
                                    template_type_id:
                                        saveTemplateDetails.type.id,
                                },
                            },
                            refetchQueries: [GetTodoTemplateTypeNames],
                        })
                        createTemplateHandler({
                            name: saveTemplateDetails.name,
                            template_type_id: saveTemplateDetails.type.id,
                            template_category_id:
                                insert_template_categories_one.id,
                            todo_id: todoId,
                            ref: templateRef,
                        })
                    } else if (
                        !saveTemplateDetails.type.id &&
                        !saveTemplateDetails.category.id &&
                        saveTemplateDetails.type.type === 'Others' &&
                        saveTemplateDetails.category.name === 'Others' &&
                        newTodoType.trim().length > 0 &&
                        newCategoryName.trim().length > 0
                    ) {
                        const {
                            data: { insert_template_types_one },
                        } = await createTodoTemplateTypeMutation({
                            variables: {
                                object: { type: newTodoType },
                            },
                            refetchQueries: [GetTodoTemplateTypeNames],
                        })
                        const {
                            data: { insert_template_categories_one },
                        } = await createTodoTemplateCategoryMutation({
                            variables: {
                                object: {
                                    name: newCategoryName,
                                    template_type_id:
                                        insert_template_types_one.id,
                                },
                            },
                            refetchQueries: [
                                GetTemplateCategoriesWithTemplates,
                            ],
                        })
                        createTemplateHandler({
                            name: saveTemplateDetails.name,
                            template_type_id: insert_template_types_one.id,
                            template_category_id:
                                insert_template_categories_one.id,
                            todo_id: todoId,
                            ref: templateRef,
                        })
                    }
                }
            } else {
                return
            }
        } catch {
            toast('Error occurred while creating template', {
                className: 'custom-toaster toaster-error',
            })
            onClose()
        }
    }

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={SaveClickHandler}
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            title="Name To-do Template"
            isLoading={isTemplateTypeCreating || isTemplateCategoryCreating}
            bg="white"
        >
            <Box
                width="600px"
                display="flex"
                flexDirection="column"
                px="16px"
                py="24px"
                gridGap="24px"
            >
                <Box display="flex" flexDirection="column" gridGap="8px">
                    <Text color="primary" fontSize="16px" my="0px">
                        Name
                    </Text>
                    <Input
                        placeholder="Enter template name"
                        bg="#F2F4F699"
                        border="0.1px solid #00000033"
                        px="16px"
                        py="8px"
                        borderRadius="4px"
                        value={saveTemplateDetails.name}
                        onChange={(e) => {
                            setSaveTemplateDetails({
                                ...saveTemplateDetails,
                                name: e.target.value,
                            })
                        }}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gridGap="8px"
                    width="100%"
                >
                    <Text color="primary" fontSize="16px" my="0px">
                        To-do Type
                    </Text>
                    <Box position="relative" width="100%">
                        <IconDropdownButton
                            bg="#F2F4F699"
                            onClick={() =>
                                setIsTodoTypeDropdownActive(
                                    !isTodoTypeDropdownActive
                                )
                            }
                            width="100%"
                            id="todo-type-dropdown-button"
                        >
                            <Text
                                my="0px"
                                textAlign="left"
                                id="todo-type-dropdown-text"
                            >
                                {saveTemplateDetails.type.type || 'Select'}
                            </Text>
                        </IconDropdownButton>
                        {isTodoTypeDropdownActive && (
                            <TodoTypeDropdown
                                active={isTodoTypeDropdownActive}
                                onDropDownClick={(type) => {
                                    setSaveTemplateDetails({
                                        ...saveTemplateDetails,
                                        type,
                                        category: { id: null, name: '' },
                                    })
                                    setIsTodoTypeDropdownActive(false)
                                }}
                                setIsDropdownActive={
                                    setIsTodoTypeDropdownActive
                                }
                            />
                        )}
                    </Box>
                    {saveTemplateDetails.type.type === 'Others' &&
                        saveTemplateDetails.type.id === null && (
                            <Input
                                placeholder="Enter To-do Type name"
                                bg="#F2F4F699"
                                border="0.1px solid #00000033"
                                px="16px"
                                py="8px"
                                borderRadius="4px"
                                value={newTodoType}
                                onChange={(e) => {
                                    setNewTodoType(e.target.value)
                                }}
                            />
                        )}
                </Box>
                {(saveTemplateDetails.type?.id ||
                    saveTemplateDetails.type?.type === 'Others') && (
                    <Box display="flex" flexDirection="column" gridGap="8px">
                        <Text color="primary" fontSize="16px" my="0px">
                            Category
                        </Text>
                        <Box position="relative" width="100%">
                            <IconDropdownButton
                                bg="#F2F4F699"
                                onClick={() => {
                                    setIsCategoryDropdownActive(
                                        !isCategoryDropdownActive
                                    )
                                }}
                                width="100%"
                                id="todo-category-dropdown-button"
                            >
                                <Text
                                    my="0px"
                                    textAlign="left"
                                    id="todo-category-dropdown-text"
                                >
                                    {saveTemplateDetails.category.name ||
                                        'Select'}
                                </Text>
                            </IconDropdownButton>
                            {isCategoryDropdownActive && (
                                <TodoCategoryDropdown
                                    active={isCategoryDropdownActive}
                                    onDropDownClick={(category) => {
                                        setSaveTemplateDetails({
                                            ...saveTemplateDetails,
                                            category,
                                        })
                                        setIsCategoryDropdownActive(false)
                                    }}
                                    setIsDropdownActive={
                                        setIsCategoryDropdownActive
                                    }
                                    templateTypeId={saveTemplateDetails.type.id}
                                />
                            )}
                        </Box>
                        {saveTemplateDetails.category.name === 'Others' &&
                            saveTemplateDetails.category.id === null && (
                                <Input
                                    placeholder="Enter Category name"
                                    bg="#F2F4F699"
                                    border="0.1px solid #00000033"
                                    px="16px"
                                    py="8px"
                                    borderRadius="4px"
                                    value={newCategoryName}
                                    onChange={(e) => {
                                        setNewCategoryName(e.target.value)
                                    }}
                                />
                            )}
                    </Box>
                )}
            </Box>
        </Modal>
    )
}
