import * as React from 'react'
import type { Shade } from 'shade-generator'
import ShadeGenerator from 'shade-generator'
import { SuccessTickSm } from 'src/components/svg-icons'
import { Box } from 'src/components/utility'
import { debounce } from 'src/helpers'

export interface IShadesProps {
    color: string | null
    onClick: (color: string) => void
}

export default function Shades({ color, onClick }: IShadesProps) {
    const [shades, setShades] = React.useState(
        ShadeGenerator.hue('#000000').shadesMap('hex')
    )
    const [selectedColor, setSelectedColor] = React.useState(color)
    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()

    const shadeArr = Object.keys(shades).slice(0, 10) as Shade[]

    const debounceFn = debounce(
        (color: string) => {
            setSelectedColor(color)
        },
        400,
        setTimeOutRef,
        timeOutRef
    )
    const colorGenerator = React.useCallback(() => {
        if (!color) return
        setShades(ShadeGenerator.hue(color).shadesMap('hex'))
        debounceFn(color)
    }, [color])

    React.useEffect(() => {
        colorGenerator()
    }, [color])

    return (
        <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
            flexWrap="wrap"
            gridColumnGap="8px"
            gridRowGap="8px"
            justifyContent="center"
            id="shades"
        >
            {shadeArr.map((shade: Shade) => {
                return (
                    <ShadeTile
                        onClick={(shade) => onClick(shade)}
                        selectedShade={selectedColor}
                        shade={shades[shade]}
                    />
                )
            })}
        </Box>
    )
}

export function ShadeTile({
    selectedShade,
    shade,
    onClick,
}: {
    selectedShade: string | null
    shade: string
    onClick: (shade: string) => void
}) {
    return (
        <Box
            borderRadius="4px"
            border="solid"
            borderWidth={1}
            borderColor={
                selectedShade === shade.toLowerCase() ? 'primary' : 'white'
            }
        >
            <Box
                bg={shade}
                height="48px"
                borderRadius="4px"
                border="solid"
                borderWidth={1}
                borderColor="white"
                onClick={() => {
                    onClick(shade)
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {selectedShade === shade.toLowerCase() && (
                    <SuccessTickSm color="#C2CFE0" />
                )}
            </Box>
        </Box>
    )
}
