import { gql } from '@apollo/client'

export const GetCollectionTodos = gql`
    query GetCollectionTodos(
        $collectionId: bigint!
        $order_by: [todos_order_by!] = { id: desc }
        $due_date: timestamptz_comparison_exp = {}
        $assignee_ids: bigint_comparison_exp = {}
    ) {
        todos(
            where: {
                product_variant: { collection_id: { _eq: $collectionId } }
                due_date: $due_date
                user_id: $assignee_ids
            }
            order_by: $order_by
        ) {
            id
            name
            status
            other_data
            due_date
            is_delayed
            product_variant {
                id
                name
                other_data
                collection {
                    id
                    name
                    manager_id
                }
            }
            assignee {
                id
                name
                email
                other_data
            }
        }
    }
`
