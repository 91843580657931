import * as React from 'react'
import { Box } from 'src/components/utility'
import DashboardHeader from './dashboard.header'
import DashboardCardContainer from './dashbord-card.container'

export interface IDashboardProps {}

export default function Dashboard(props: IDashboardProps) {
    return (
        <Box mt="40px">
            <DashboardHeader />
            <DashboardCardContainer />
        </Box>
    )
}
