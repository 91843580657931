import { Tab, Tabs } from '@mui/material'
import * as React from 'react'

export interface IAddCollaboratorSliderProps {
    currentTab: string
    onTabChange: (x: { tab: string }) => void
    tabList: {
        name: string
        value: string
        noOfItems: number
    }[]
}

export default function AddCollaboratorSlider(
    props: IAddCollaboratorSliderProps
) {
    return (
        <Tabs
            value={props.currentTab}
            variant="scrollable"
            scrollButtons={false}
            onChange={(_event: any, newValue: any) => {
                props.onTabChange({
                    tab: newValue,
                })
            }}
            aria-label="collection-tabs"
            classes={{
                root: 'collection-slider-root',
            }}
        >
            {props.tabList.map((tab) => (
                <Tab
                    label={tab.name + `(${tab.noOfItems})`}
                    value={tab.value}
                />
            ))}
        </Tabs>
    )
}
