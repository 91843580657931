import { gql } from '@apollo/client'

export const activityFragment = gql`
    fragment ActivityFragment on activity {
        action
        created_at
        display_string
        entity_id
        entity_name
        id
        user_id
        user {
            other_data
            name
            id
            email
        }
    }
`

export const SubscribeIndividualCollectionActivities = gql`
    subscription CollectionActivities(
        $id: bigint!
        $created_at_filter: timestamptz_comparison_exp!
    ) {
        activity(
            where: {
                entity_name: { _eq: "collections" }
                entity_id: { _eq: $id }
                created_at: $created_at_filter
            }
            order_by: { created_at: desc }
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeIndividualProductActivities = gql`
    subscription ProductActivities(
        $id: bigint
        $created_at_filter: timestamptz_comparison_exp!
    ) {
        activity(
            where: {
                entity_name: { _eq: "products" }
                entity_id: { _eq: $id }
                created_at: $created_at_filter
            }
            order_by: { created_at: desc }
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeIndividualTodoActivities = gql`
    subscription CollectionActivities(
        $id: bigint
        $created_at_filter: timestamptz_comparison_exp!
    ) {
        activity(
            where: {
                entity_name: { _eq: "todos" }
                entity_id: { _eq: $id }
                created_at: $created_at_filter
            }
            order_by: { created_at: desc }
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeIndividualActivities = gql`
    subscription ProductActivities(
        $id: bigint
        $name: String
        $user_created_at: timestamptz!
    ) {
        activity(
            where: {
                entity_name: { _eq: $name }
                entity_id: { _eq: $id }
                created_at: { _gte: $user_created_at }
            }
            order_by: { created_at: desc }
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeToIndividualUsers = gql`
    subscription SubscribeToIndividualUsers(
        $id: bigint
        $name: String
        $user_created_at: timestamptz!
    ) {
        activity(
            where: {
                user_id: { _eq: $id }
                created_at: { _gte: $user_created_at }
            }
            order_by: { created_at: desc }
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

/**
 * @param id `bigint`
 * @param name `string`
 */

export const OrganisationActivities = gql`
    subscription OrganisationActivities(
        $id: bigint
        $name: String
        $user_created_at: timestamptz!
        $limit: Int! = 250
        $offset: Int! = 0
    ) {
        activity(
            where: {
                organisation_id: { _eq: $id }
                entity_name: { _eq: $name }
                created_at: { _gte: $user_created_at }
            }
            order_by: { created_at: desc }
            limit: $limit
            offset: $offset
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeToLibraryActivities = gql`
    subscription libraryActivity(
        $id: bigint
        $user_created_at: timestamptz!
        $limit: Int! = 250
        $offset: Int! = 0
        $entity_name: String_comparison_exp
        $entity_id: bigint_comparison_exp
    ) {
        library_activity(
            where: {
                organisation_id: { _eq: $id }
                created_at: { _gte: $user_created_at }
                entity_name: $entity_name
                entity_id: $entity_id
            }
            order_by: { created_at: desc }
            limit: $limit
            offset: $offset
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeToNotifications = gql`
    subscription Notifications(
        $offset: Int = 1
        $where: notifications_bool_exp = {}
    ) {
        notifications(
            order_by: { created_at: desc }
            limit: 10
            offset: $offset
            where: $where
        ) {
            id
            display_string
            opened
            other_data
            created_at
            type
            entity_id
            entity_info
            entity_name
            type_details
            action_performer
            action_done_by {
                name
                email
                id
                other_data
            }
            body
            user {
                name
                id
                other_data
            }
        }
    }
`

export const MarkNotificationAsOpened = gql`
    mutation MarkNotificationAsOpened($id: bigint) {
        update_notifications(
            where: { id: { _eq: $id } }
            _set: { opened: true }
        ) {
            affected_rows
        }
    }
`

export const DeleteNotification = gql`
    mutation deleteNotification($id: bigint!, $deleted_at: timestamptz!) {
        update_notifications(
            where: { id: { _eq: $id } }
            _set: { deleted_at: $deleted_at }
        ) {
            affected_rows
        }
    }
`

export const DashboardActivity = gql`
    subscription dashboardActivity(
        $user_created_at: timestamptz!
        $id: bigint
        $limit: Int! = 10
        $offset: Int = 10
    ) {
        activity(
            order_by: { created_at: desc }
            limit: $limit
            where: {
                organisation_id: { _eq: $id }
                created_at: { _gte: $user_created_at }
            }
            offset: $offset
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`

export const SubscribeToRoleChange = gql`
    subscription roleChange($id: bigint, $orgId: bigint) {
        organisation_members(
            where: { user_id: { _eq: $id }, organisation_id: { _eq: $orgId } }
        ) {
            id
            organisation_id
            role
            deleted_at
        }
    }
`

export const GetCommentsSubscription = gql`
    subscription getComments(
        $organisation_id: bigint_comparison_exp
        $entity_name: String_comparison_exp
        $entity_id: bigint_comparison_exp
        $limit: Int
    ) {
        comments(
            order_by: { created_at: desc }
            where: {
                entity_name: $entity_name
                entity_id: $entity_id
                organisation_id: $organisation_id
            }
            limit: $limit
        ) {
            comment
            created_at
            id
            user {
                email
                name
                other_data
            }
        }
    }
`
