import * as React from 'react'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Box } from 'src/components/utility'
import DashboardSlider from '../dashboard-card.slider'
import CollectionEntityList from './collection-entity-list.dashboard'
import { useCollections } from '../hooks/use-collections'
import { useSearchParams } from 'react-router-dom'
import { useUser } from 'src/helpers'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { scrollToSection } from 'src/helpers/scroll-to-section'

export interface ICollectionDashboardCardProps {
    height?: string
}
type TDashboardCollectionStatus = 'inProgress' | 'new' | 'completed'
export default function CollectionDashboardCard(
    props: ICollectionDashboardCardProps
) {
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get('collectionTab')
    const { height } = props
    const [activeSort, setActiveSort] = React.useState('Due Date')
    const [activeTab, setActiveTab] = React.useState(tab || 'new')
    const { getLastOpenedTab } = useCardExpandedState()

    const collectionType =
        !searchParams.get('myCollections') ||
        searchParams.get('myCollections') === 'true'
            ? 'Your Collections'
            : 'All Collections'
    const [fetchCollectionType, setFetchCollectionType] =
        React.useState(collectionType)
    const { role } = useUser()
    const [pageNumber, setPageNumber] = React.useState(0)
    const [statusChanged, setStatusChanged] = React.useState(false)
    const [isPaginating, setIsPaginatingChanged] = React.useState(false)
    // React.useEffect(() => {
    //     getDashboardCollections({
    //         offset: String(0),
    //         sort: activeSort,
    //         status: activeTab as TDashboardCollectionStatus,
    //         myCollections: fetchCollectionType === 'Your Collections',
    //     })
    // }, [])

    const {
        collections,
        collectionCounts,
        fetchAggregate,
        isLoading,
        fetchMore,
        networkStatus,
    } = useCollections({
        offset: String(pageNumber),
        pageLimit: role === 'admin' ? 20 : 15,
        sort: activeSort,
        status: activeTab as TDashboardCollectionStatus,
        statusChange: statusChanged,
        myCollections: fetchCollectionType === 'Your Collections',
        isPaginating,
    })

    // Scroll to the section if the last opened tab is collection
    const isLastOpenedTabCollection = getLastOpenedTab('collection')
    React.useEffect(() => {
        if (isLastOpenedTabCollection) {
            scrollToSection('dashboard_collections')
        }
    }, [isLastOpenedTabCollection])

    return (
        <Box width="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                id="dashboard_collections"
            >
                <Box>
                    <CustomDropdown
                        label={''}
                        labelMaxWidth="200px"
                        highlightedTextFontSize="1rem"
                        selectedText={fetchCollectionType}
                        onItemClicked={(item) => {
                            const isMyCollection = item === 'Your Collections'
                            searchParams.set(
                                'myCollections',
                                `${isMyCollection}`
                            )
                            setSearchParams(searchParams, { replace: true })
                            setFetchCollectionType(item)
                        }}
                        items={['Your Collections', 'All Collections']}
                    />
                </Box>
                <CustomDropdown
                    onItemClicked={(activeSort) => {
                        setActiveSort(activeSort)
                        // getDashboardCollections({
                        //     offset: String(0),
                        //     sort: activeSort,
                        //     status: activeTab as TDashboardCollectionStatus,
                        //     myCollections:
                        //         fetchCollectionType === 'Your Collections',
                        // })
                        fetchAggregate({
                            myCollections:
                                fetchCollectionType === 'Your Collections',
                        })
                    }}
                    selectedText={activeSort}
                    items={['Name', 'Due Date', 'Date Created']}
                    label="Sort:"
                />
            </Box>
            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                borderRadius={'4px'}
                mt="12px"
                pt="16px"
                bg="white"
                minHeight="500px"
            >
                <DashboardSlider
                    tabs={[
                        {
                            label: 'New',
                            value: 'new',
                            count: collectionCounts.new,
                        },
                        {
                            label: 'In Progress',
                            value: 'inProgress',
                            count: collectionCounts.inProgress,
                        },
                        {
                            label: 'Completed',
                            value: 'complete',
                            count: collectionCounts.complete,
                        },
                    ]}
                    activeTab={activeTab}
                    setActiveTab={(updatedTab) => {
                        setPageNumber(0)
                        setActiveTab(updatedTab)
                        searchParams.set('collectionTab', updatedTab)
                        setSearchParams(searchParams, { replace: true })
                        setStatusChanged(true)
                    }}
                />
                <CollectionEntityList
                    height={height}
                    networkStatus={networkStatus}
                    isLoading={isLoading}
                    onPageChange={() => {
                        setStatusChanged(true)
                        setIsPaginatingChanged(true)
                        if (
                            collections.length <
                            collectionCounts[
                                activeTab as 'new' | 'inProgress' | 'complete'
                            ]
                        ) {
                            fetchMore()
                        }
                    }}
                    collections={collections}
                />
            </Box>
        </Box>
    )
}
