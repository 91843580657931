import create from 'zustand'

const initialCollectionForm: {
    name: string
    description: string
    dueDate: Date | null
    tags: Array<{ id: string; name: string; color: string }>
    manager: any
    company: string
    team: any | null
    brand: string
    development_cycle: string
} = {
    name: '',
    description: '',
    dueDate: null,
    tags: [],
    manager: null,
    company: '',
    team: null,
    brand: '',
    development_cycle: '',
}

export const useCreateCollectionFormStore = create((set: Function) => ({
    collectionForm: initialCollectionForm,
    setCollectionForm: (data: any) => {
        set((state: any) => ({
            ...state,
            collectionForm: data,
        }))
    },
    clearCollectionForm: () => {
        set((state: any) => ({
            ...state,
            collectionForm: initialCollectionForm,
        }))
    },
    updateCollectionName: (updatedName: string) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                name: updatedName,
            },
        }))
    },
    updateCollectionDescription: (updatedDescription: string) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                description: updatedDescription,
            },
        }))
    },
    updateCollectionDueDate: (date: Date) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                dueDate: date,
            },
        }))
    },
    updateCollectionTags: ({
        type,
        payload,
    }: {
        type: 'ADD' | 'DELETE' | 'UPDATE'
        payload: any
    }) => {
        if (type === 'ADD') {
            set((state: any) => ({
                ...state,
                collectionForm: {
                    ...state.collectionForm,
                    tags: [...state.collectionForm.tags, payload],
                },
            }))
        } else if (type === 'DELETE') {
            set((state: any) => ({
                ...state,
                collectionForm: {
                    ...state.collectionForm,
                    tags: state.collectionForm.tags.filter(
                        (tag: any) => tag.name !== payload
                    ),
                },
            }))
        } else if (type === 'UPDATE') {
            set((state: any) => ({
                ...state,
                collectionForm: {
                    ...state.collectionForm,
                    tags: state.collectionForm.tags.map((tag: any) =>
                        tag.id === payload.id ? payload : tag
                    ),
                },
            }))
        }
    },
    updateCollectionBrand: (updatedBrand: string) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                brand: updatedBrand,
            },
        }))
    },
    updateCollectionCompany: (updatedCompany: string) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                company: updatedCompany,
            },
        }))
    },
    updateCollectionDevelopmentCycle: (dvelopmentCycle: string) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                development_cycle: dvelopmentCycle,
            },
        }))
    },
    updateCollectionTeam: (team: any) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                team: team,
            },
        }))
    },
    updateCollectionManager: (manager: any) => {
        set((state: any) => ({
            ...state,
            collectionForm: {
                ...state.collectionForm,
                manager: manager,
            },
        }))
    },
}))
