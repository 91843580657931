import styled from 'styled-components'
import { layout, color, flexbox, position } from 'styled-system'

type Props = {
  childern?: React.ReactNode | null;
  position?: string;
  zIndex?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  overflow?: string;
  background?: string;
  fontFamily?: FontFamily;
};

const StyledLayout = styled.div<Props>`
    ${layout}
    ${flexbox}
    ${position}
    ${color}
`

export default StyledLayout
