import type { SetStateAction } from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Button } from 'src/components/utility'

export const TeamsDropDownActions = ({
    active,
    onDropDownClick,
    setActiveSort,
    activeSort,
    setIsDropdownActive,
}: {
    active: Boolean
    onDropDownClick: (value: string) => void
    setActiveSort: (
        active: 'LTO' | 'OTL' | 'LTM' | 'MTL' | 'LTH' | 'HTL'
    ) => void
    activeSort: string
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
}) => {
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="55px"
            left="undefined"
            right="0px"
        >
            <>
                <DropdownButtonWrapper
                    width="100%"
                    onClick={(x) => {
                        setActiveSort('LTO')
                        onDropDownClick(x)
                    }}
                    value="Latest to Oldest"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'LTO' ? '0.5' : '1'}
                    >
                        Latest to Oldest
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('OTL')
                        onDropDownClick(x)
                    }}
                    value="Oldest to Latest"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'OTL' ? '0.5' : '1'}
                    >
                        Oldest to Latest
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('LTM')
                        onDropDownClick(x)
                    }}
                    value="No. of Collections: Least to Most"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'LTM' ? '0.5' : '1'}
                    >
                        No. of Collections: Least to Most
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('MTL')
                        onDropDownClick(x)
                    }}
                    value="No. of Collections: Most to Least"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'MTL' ? '0.5' : '1'}
                    >
                        No. of Collections: Most to Least
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('LTH')
                        onDropDownClick(x)
                    }}
                    value="Completion Rate: Low to High"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'LTH' ? '0.5' : '1'}
                    >
                        Completion Rate: Low to High
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('HTL')
                        onDropDownClick(x)
                    }}
                    value="Completion Rate: High to Low"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'HTL' ? '0.5' : '1'}
                    >
                        Completion Rate: High to Low
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
