import React from 'react'
import { useNavigate } from 'react-router-dom'
import TemplateFavouritesContainer from 'src/components-v2/todo-templates/template-favourites-container/template-favourites-container'
import { Box, Button } from 'src/components/utility'

function TodoTemplatesFavourites() {
    const navigate = useNavigate()
    return (
        <Box>
            <Box>
                <Box py="24px">
                    <Button
                        color="links"
                        fontSize="12px"
                        mt={'0px'}
                        bg="transparent"
                        border="none"
                        onClick={() => navigate(-1)}
                        px="0px"
                        fontFamily="Rubik"
                    >
                        &lt; Back
                    </Button>
                </Box>
            </Box>
            <TemplateFavouritesContainer isFullPage={true} />
        </Box>
    )
}

export default TodoTemplatesFavourites
