import Bold from '@tiptap/extension-bold'
import Image from '@tiptap/extension-image'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useState } from 'react'
import { DescriptionIco, TagsIco } from 'src/components/svg-icons'
import { TagAutoCommplete } from 'src/components/tag-autocomplete/tag-autocomplete'
import { Box, Input, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

function AdditionalInfoDropdown({
    creatorName,
    createdDate,
    updatedDate,
    description: initialDescription,
    onClose,
    onDescriptionUpdate,
    tags = [],
    onTagsUpdate,
    onUpdateBrand,
    onUpdateCompany,
    brand,
    company,
    isCollection,
}: {
    creatorName: string
    createdDate: string
    updatedDate: string
    description: string | null
    onClose: () => void
    onDescriptionUpdate: (description: string) => void
    tags: Array<{ tag: string; color: string }> | null
    onTagsUpdate: (tags: Array<any>) => void
    onUpdateBrand?: (brand: string) => void
    onUpdateCompany?: (company: string) => void
    brand?: string | null
    company?: string | null
    isCollection?: boolean
}) {
    const [description, setDescription] = useState(initialDescription)
    const [additionalDetails, setAdditionalDetails] = useState({
        brand: brand || '',
        company: company || '',
    })

    const wrapperRef = useClickedOutside(onClose, [
        'additional-info-dropdown-button',
    ])

    const editor = useEditor(
        {
            onUpdate: (e) => {
                setDescription(e.editor.getText())
                // !FIXME: Switch away from sending the description to the server as a stringified html. (security issue)
                onDescriptionUpdate(e.editor.getHTML())
            },
            editable: true,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: 'Enter the description here',
                }),
                Image,
                Bold,
                Italic,
                Link,
            ],
            content: description,
        },
        []
    )

    return (
        <Box
            position="absolute"
            top="calc(100% - 16px)"
            right="24px"
            width={'95%'}
            p={'24px'}
            bg="panelBackground"
            borderRadius="4px"
            zIndex={10}
            display="flex"
            flexDirection="column"
            gridGap="16px"
            ref={wrapperRef}
            boxShadow="0px 2px 12px 0px #999BA840"
        >
            <Text fontSize={'14px'} my="0px" fontWeight={500}>
                Additional Information
            </Text>
            <Box
                display="flex"
                flexDirection="column"
                gridGap="16px"
                p="16px"
                bg="white"
                borderBottom="1px solid"
                borderColor="secondaryLighterBlue"
            >
                <Box display="flex" gridGap="36px">
                    <Box display="flex" gridGap="8px" flexDirection="column">
                        <Text color="textTags" my="0px" fontSize={'12px'}>
                            Created by
                        </Text>
                        <Text
                            color="primary"
                            fontWeight={500}
                            my="0px"
                            fontSize={'12px'}
                        >
                            {creatorName}
                        </Text>
                    </Box>
                    <Box display="flex" gridGap="8px" flexDirection="column">
                        <Text color="textTags" my="0px" fontSize={'12px'}>
                            Date Created
                        </Text>
                        <Text
                            color="primary"
                            fontWeight={500}
                            my="0px"
                            fontSize={'12px'}
                        >
                            {createdDate}
                        </Text>
                    </Box>
                    <Box display="flex" gridGap="8px" flexDirection="column">
                        <Text color="textTags" my="0px" fontSize={'12px'}>
                            Last Modified
                        </Text>
                        <Text
                            color="primary"
                            fontWeight={500}
                            my="0px"
                            fontSize={'12px'}
                        >
                            {updatedDate}
                        </Text>
                    </Box>
                    {isCollection && (
                        <Box
                            display="flex"
                            gridGap="8px"
                            flexDirection="column"
                        >
                            <Text color="textTags" my="0px" fontSize={'12px'}>
                                Brand
                            </Text>
                            <Input
                                value={additionalDetails.brand}
                                placeholder="Brand name"
                                bg="transparent"
                                onChange={(e) => {
                                    if (onUpdateBrand) {
                                        setAdditionalDetails({
                                            ...additionalDetails,
                                            brand: e.target.value,
                                        })
                                        onUpdateBrand(e.target.value)
                                    }
                                }}
                                fontSize="12px"
                                border="none"
                                width="80px"
                                py={'0px'}
                                style={{ fontWeight: 500 }}
                            />
                        </Box>
                    )}
                    {isCollection && (
                        <Box
                            display="flex"
                            gridGap="8px"
                            flexDirection="column"
                        >
                            <Text color="textTags" my="0px" fontSize={'12px'}>
                                Company
                            </Text>
                            <Input
                                value={additionalDetails.company}
                                placeholder="Company name"
                                bg="transparent"
                                onChange={(e) => {
                                    if (onUpdateCompany) {
                                        setAdditionalDetails({
                                            ...additionalDetails,
                                            company: e.target.value,
                                        })
                                        onUpdateCompany(e.target.value)
                                    }
                                }}
                                fontSize="12px"
                                border="none"
                                width="120px"
                                style={{ fontWeight: 500 }}
                            />
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="flex-start" gridGap="8px">
                    <DescriptionIco />
                    <EditorContent
                        style={{
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            fontSize: '14px',
                            marginTop: '4px',
                        }}
                        editor={editor}
                    />
                </Box>
                <Box display="flex" alignItems="center" gridGap="8px">
                    <TagsIco />
                    <TagAutoCommplete
                        tags={
                            tags?.map((tag) => ({
                                id: tag.tag,
                                name: tag.tag,
                                color: tag.color,
                            })) || []
                        }
                        updateTag={(tag) => {
                            onTagsUpdate(
                                !!tags
                                    ? [
                                          ...tags,
                                          { tag: tag.name, color: tag.color },
                                      ]
                                    : [{ tag: tag.name, color: tag.color }]
                            )
                        }}
                        removeTag={(tagName) => {
                            onTagsUpdate(
                                tags!.filter((tag) => tag.tag !== tagName)
                            )
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default AdditionalInfoDropdown
