import { MeshCustomMaterial } from '../panel-functions/material/custom-material'
import * as THREE from 'three'
export const cloneMaterial = ({
    oldMaterial,
}: {
    oldMaterial: THREE.MeshPhysicalMaterial | THREE.MeshStandardMaterial
}) => {
    let newMaterial = new MeshCustomMaterial({
        color: 0xffffff,
    })
    console.log({ oldMaterial })
    const meshMaterial = oldMaterial as MeshCustomMaterial

    newMaterial.color = new THREE.Color(meshMaterial.color)
    // VVV Important for alpha maps
    newMaterial.transparent = true
    newMaterial.map = meshMaterial.map
    // if (meshMaterial.map && newMaterial.map?.offset) {
    //     newMaterial.map.offset = new THREE.Vector2(
    //         meshMaterial.map.offset.x,
    //         meshMaterial.map.offset.y
    //     )
    // }
    // if (meshMaterial.map && newMaterial.map?.repeat) {
    //     newMaterial.map.repeat = new THREE.Vector2(
    //         meshMaterial.map.repeat.x,
    //         meshMaterial.map.repeat.y
    //     )
    // }
    // if (
    //     newMaterial.map &&
    //     newMaterial.map?.wrapS &&
    //     newMaterial.map?.wrapT &&
    //     meshMaterial.map?.wrapS &&
    //     meshMaterial.map?.wrapT
    // ) {
    //     newMaterial.map.wrapS = meshMaterial.map.wrapS
    //     newMaterial.map.wrapT = meshMaterial.map.wrapT
    //     newMaterial.map.anisotropy = 2
    //     newMaterial.map.colorSpace = THREE.SRGBColorSpace
    //     newMaterial.map.flipY = false
    //     newMaterial.map.generateMipmaps = true
    //     newMaterial.map.needsUpdate = true
    // }
    newMaterial.normalMap = meshMaterial.normalMap
    newMaterial.normalScale = meshMaterial.normalScale

    newMaterial.roughnessMap = meshMaterial.roughnessMap
    newMaterial.displacementMap = meshMaterial.displacementMap
    newMaterial.metalnessMap = meshMaterial.metalnessMap
    newMaterial.specularColorMap = meshMaterial.specularColorMap
    newMaterial.specularIntensity = meshMaterial.specularIntensity

    newMaterial.aoMap = meshMaterial.aoMap
    newMaterial.alphaMap = meshMaterial.alphaMap
    newMaterial.alphaHash = meshMaterial.alphaHash
    newMaterial.alphaToCoverage = meshMaterial.alphaToCoverage
    newMaterial.alphaTest = meshMaterial.alphaTest
    newMaterial.roughness = meshMaterial.roughness
    newMaterial.metalness = meshMaterial.metalness
    newMaterial.clearcoatNormalMap = meshMaterial.clearcoatNormalMap
    newMaterial.clearcoatMap = meshMaterial.clearcoatMap
    newMaterial.clearcoat = meshMaterial.clearcoat
    newMaterial.metalness = meshMaterial.metalness
    newMaterial.clearcoatRoughness = meshMaterial.clearcoatRoughness
    newMaterial.clearcoatRoughnessMap = meshMaterial.clearcoatRoughnessMap

    newMaterial.normalBlendingLayer1 = 1
    newMaterial.cutoutColor1_Layer1 = 0.5
    console.log({ newMaterial })
    return newMaterial
}
