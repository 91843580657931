/**
 * @param sentence
 * @returns String with first word capatilised
 */

export function getSentenceCase(text: string, type: 'NAME' | 'SENTENCE') {
    const splitName = text.split('.')
    let str = ''

    if (type === 'NAME') {
        splitName.forEach((sentence) => {
            str =
                str +
                sentence.trim().charAt(0).toUpperCase() +
                sentence.trim().slice(1)
        })
    } else {
        splitName.forEach((sentence) => {
            str =
                str +
                sentence.trim().charAt(0).toUpperCase() +
                sentence.trim().slice(1) +
                '. '
        })
    }

    return str
}
