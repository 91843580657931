import { CrossSm } from 'src/components/svg-icons'
import { Box, Button } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { ImageZoomer } from 'src/screens/library/sub-screens/zoomer/zoomer'

export const ExpandReferencePicModal = ({
    onClose,
    url,
}: {
    url: string
    onClose: () => void
}) => {
    const wrapperRef = useClickedOutside(onClose, [
        'expand-reference-pic-modal',
    ])

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="#02214370"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={2}
            onClick={(e) => e.stopPropagation()}
        >
            <Box
                width="80vw"
                height={'85vh'}
                bg="backgroundColor"
                borderRadius="4px"
                ref={wrapperRef}
            >
                <Box
                    width={'100%'}
                    textAlign="center"
                    height="100%"
                    position="relative"
                    bg="#e8ecef"
                >
                    <Box p="8px">
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onClose()
                            }}
                            bg="#e8ecef"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            width="100%"
                            textAlign="right"
                        >
                            <CrossSm />
                        </Button>
                    </Box>
                    <ImageZoomer
                        height="92%"
                        url={url}
                        enableFullScreenMode={false}
                        showToolbar={false}
                    />
                </Box>
            </Box>
        </Box>
    )
}
