import { NetworkStatus } from '@apollo/client'
import * as React from 'react'
import CollectionListCard from 'src/components-v2/entity-list-card/collection-list.card'
import { CardLoader } from 'src/components/collections/card-loader'
import { Box } from 'src/components/utility'
import { useUser } from 'src/helpers'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'
import type { TGqlCollections } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import { EmptyTodoState } from 'src/screens/dashboard/sub-screens/todos/empty-state-todo'

export interface ICollectionEntityListProps {
    collections: TGqlCollections[]
    networkStatus: NetworkStatus
    // setPage: (page: number) => void
    // page: number
    onPageChange: () => void
    height?: string
    isLoading: boolean
}

export default function CollectionEntityList(
    props: ICollectionEntityListProps
) {
    const { collections, onPageChange, networkStatus, height, isLoading } =
        props
    const { isIntersecting, lastElementRef } = useIntersectionObserver({
        isLoading,
        threshold: 0,
    })
    const { role } = useUser()

    React.useEffect(() => {
        if (isIntersecting) {
            onPageChange()
        }
    }, [isIntersecting])

    return (
        <Box overflowY="auto" height={height || '500px'}>
            {collections.map((collection) => {
                return (
                    <Box
                        key={collection.name + collection.id}
                        ref={lastElementRef}
                        id={`collection_card_${collection.id}`}
                    >
                        <CollectionListCard
                            key={collection.id + collection.name}
                            cardId=""
                            collection={{
                                dueDate: collection.due_date
                                    ? new Date(
                                          collection.due_date
                                      ).toISOString()
                                    : null,
                                id: Number(collection.id),
                                name: collection.name,
                                isDelayed: collection?.is_delayed || false,
                                productsCount: {
                                    completed: Number(
                                        collection.totalProducts.aggregate.count
                                    ),
                                    total: Number(
                                        collection.totalCompleted.aggregate
                                            .count
                                    ),
                                },
                                status: collection.status,
                                team: {
                                    id: Number(collection?.team?.id),
                                    name: collection?.team?.name,
                                    team_members:
                                        collection?.team?.team_members,
                                },
                            }}
                            isFullCard={role === 'admin' ? true : false}
                        />
                    </Box>
                )
            })}
            {networkStatus === NetworkStatus.fetchMore && (
                <CardLoader skeletonCount={3} />
            )}
            {collections.length <= 0 && (
                <Box>
                    <EmptyTodoState
                        boxHeight={'500px'}
                        title={'No Collections Yet!'}
                        description={
                            'Try to assign more tasks to your teammates'
                        }
                        enableCreateButton={true}
                    />
                </Box>
            )}
        </Box>
    )
}
