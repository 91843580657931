import React, { useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import type { TodoTemplateType } from '../todo-templates.types'
import {
    GetFavouriteTemplates,
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplates,
} from 'src/services/graphql/entities/todo/todo-templates.queries'
import {
    DeleteTemplateById,
    UpdateTemplateDesignById,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ASSING_TEMPLATE } from 'src/services/api/endpoints'
import { getLocalStorage } from 'src/helpers'
import PreviewEditTemplateModal from 'src/components/drawer/todo-tab/preview-edit-template-modal/preview-edit-template-modal'
import SaveTemplateDetailsModal from 'src/components/drawer/todo-tab/save-template/save-template-details-modal'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import TemplateCard from '../template-card'
import { useNavigate } from 'react-router-dom'
import type { GetFavouriteTemplatesQuery } from 'src/generated/graphql'
import { GetTodo } from 'src/services/graphql/entities/todo/todo.queries'

function TemplateFavouritesContainer({
    isFullPage = false,
}: {
    isFullPage?: boolean
}) {
    const [templateModal, setTemplateModal] = useState<{
        isOpen: boolean
        mode: 'preview' | 'edit' | 'delete' | 'duplicate' | null
        activeTemplate: TodoTemplateType | null
    }>({
        isOpen: false,
        mode: null,
        activeTemplate: null,
    })

    const navigate = useNavigate()

    const client = useApolloClient()

    const [saveTemplateModal, setSaveTemplateModal] = useState(false)
    const [showCreateTodoModal, setShowCreateTodoModal] = useState(false)

    const [deleteTemplateMutation] = useMutation(DeleteTemplateById)
    const [updateTemplateDesignMutation] = useMutation(UpdateTemplateDesignById)

    const { data: favouriteTemplatesData } =
        useQuery<GetFavouriteTemplatesQuery>(GetFavouriteTemplates, {
            fetchPolicy: 'cache-and-network',
        })
    const favouriteTemplates = favouriteTemplatesData?.user_favourite || []

    function deleteTemplateHandler() {
        if (!templateModal.activeTemplate) return
        deleteTemplateMutation({
            variables: {
                id: templateModal.activeTemplate.id,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries: [
                GetTemplateCategoriesWithTemplates,
                GetTodoTemplates,
                GetFavouriteTemplates,
            ],
            onCompleted: () => {
                setTemplateModal({
                    isOpen: false,
                    mode: null,
                    activeTemplate: null,
                })
                toast('Template deleted successfully', {
                    className: 'custom-toaster toaster-success',
                })
            },
        })
    }

    function editTemplateHandler(updatedTemplate: TodoTemplateType) {
        if (!templateModal.activeTemplate) return
        updateTemplateDesignMutation({
            variables: {
                id: templateModal.activeTemplate.id,
                design: updatedTemplate.design,
            },
            refetchQueries: [
                GetTemplateCategoriesWithTemplates,
                GetTodoTemplates,
                GetFavouriteTemplates,
            ],
            onCompleted: () => {
                setTemplateModal({
                    isOpen: false,
                    mode: null,
                    activeTemplate: null,
                })
                toast('Template updated successfully', {
                    className: 'custom-toaster toaster-success',
                })
            },
        })
    }

    function useTemplateHandler() {
        if (!templateModal.activeTemplate) return
        setTemplateModal({
            ...templateModal,
            isOpen: false,
        })
        setShowCreateTodoModal(true)
    }

    async function assignTemplateToTodo(todoId: number) {
        if (!templateModal.activeTemplate) return
        try {
            const response = await axios.post(
                ASSING_TEMPLATE,
                {
                    todo_id: todoId,
                    template_id: templateModal.activeTemplate.id,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                client.refetchQueries({
                    include: [GetTodo],
                })
            }
        } catch {
            toast('Error occurred while assigning template', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    const totalFavourites = favouriteTemplates?.length || 0
    const templatesToRender = isFullPage
        ? favouriteTemplates
        : favouriteTemplates.slice(0, 3)

    return (
        <Box display="flex" flexDirection="column" gridGap="16px" px="24px">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gridGap="8px">
                    <Text
                        my="0px"
                        fontSize={isFullPage ? '24px' : '12px'}
                        color={isFullPage ? 'primary' : 'textSecondary'}
                    >
                        Favourites
                    </Text>
                    <Text
                        my="0px"
                        fontSize={'12px'}
                        alignItems="center"
                        color={'textSecondary'}
                    >
                        ({totalFavourites} Result
                        {totalFavourites > 1 ? 's' : ''})
                    </Text>
                </Box>
                {!isFullPage && (
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        onClick={() => {
                            navigate('/library/todo-templates/favourites')
                        }}
                    >
                        View all
                    </Button>
                )}
            </Box>
            <Box
                display="grid"
                gridTemplateColumns={
                    templatesToRender?.length > 0 ? '1fr 1fr 1fr' : 'auto'
                }
                gridGap="16px"
                mb="16px"
            >
                {templatesToRender?.length > 0 ? (
                    templatesToRender.map((template) => {
                        const _template = template.templates[0]
                        if (!_template) return null
                        return (
                            <TemplateCard
                                // @ts-ignore
                                template={_template}
                                onClick={({ isOpen, mode }) =>
                                    setTemplateModal({
                                        isOpen,
                                        mode,
                                        activeTemplate: _template,
                                    })
                                }
                                key={template.id}
                            />
                        )
                    })
                ) : (
                    <Box
                        py="16px"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <Text color="textSecondary">
                            You Have No Favorite Template Yet
                        </Text>
                    </Box>
                )}
            </Box>
            {templateModal.isOpen &&
                templateModal.activeTemplate &&
                templateModal.mode && (
                    <PreviewEditTemplateModal
                        isOpen={!!templateModal.isOpen}
                        onClose={() => {
                            setTemplateModal({
                                isOpen: false,
                                mode: null,
                                activeTemplate: null,
                            })
                        }}
                        template={templateModal.activeTemplate}
                        onDelete={deleteTemplateHandler}
                        onEdit={(updatedTemplate) =>
                            editTemplateHandler(updatedTemplate)
                        }
                        onClick={useTemplateHandler}
                        mode={templateModal.mode}
                        templateId={templateModal.activeTemplate.id}
                    />
                )}
            {saveTemplateModal && templateModal.activeTemplate && (
                <SaveTemplateDetailsModal
                    isOpen={saveTemplateModal}
                    onClose={() => setSaveTemplateModal(false)}
                    todoId={null}
                    templateRef={'NEW'}
                />
            )}
            {showCreateTodoModal && (
                <CreateTodoModal
                    isOpen={showCreateTodoModal}
                    onClose={() => setShowCreateTodoModal(false)}
                    onComplete={assignTemplateToTodo}
                />
            )}
        </Box>
    )
}

export default TemplateFavouritesContainer
