import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box } from 'src/components/utility'
import { fabric } from 'fabric'
import ImageAnnotatorInput from './image-annotator-input'
import { useQuery } from '@apollo/client'
import {
    GetAnnotatedComments,
    GetAnnotatedCommentsForLibraryFile,
} from 'src/services/graphql/query/get-annotated-comments'
import { useParams, useSearchParams } from 'react-router-dom'
import { GetTodoProductTeam } from 'src/services/graphql/entities/todo/todo.queries'
import type {
    AnnotatedComment,
    CoordinateWithCameraPosition,
} from 'src/components/3d-renderer/annotation.types'
import { uuid } from 'src/helpers'

function ImageAnnotator({
    imageUrl,
    entityId,
    entityName,
    fileId,
    isLibraryFile,
    setZoomFunctions,
}: {
    imageUrl: string
    entityId: number
    entityName: string
    fileId: number
    isLibraryFile?: boolean
    setZoomFunctions?: React.Dispatch<
        React.SetStateAction<{
            zoomIn: () => void
            zoomOut: () => void
        }>
    >
}) {
    const canvasRef: React.RefObject<HTMLCanvasElement> = useRef(null)
    const [loading, setLoading] = useState(true)
    const fabricCanvasRef: any = useRef(null)
    const [activePoint, setActivePoint] = useState<{
        x: number
        y: number
        offsetX: number
        offsetY: number
    } | null>(null)
    const [imageObject, setImageObject] = useState<fabric.Image | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const libraryFile =
        isLibraryFile ?? searchParams.get('isLibraryFile') === 'true'
    const { data: annotationCommentsData, refetch: refetchComments } = useQuery(
        GetAnnotatedComments,
        {
            skip: libraryFile,
            variables: {
                entity_id: entityId || searchParams.get('sectionId'),
                entity_name: entityName,
                file_id: fileId,
                type: '2d',
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const {
        data: annotatedCommentsDataForLibraryFile,
        refetch: refetchAnnotatedCommentsForLibraryFile,
    } = useQuery(GetAnnotatedCommentsForLibraryFile, {
        skip: !libraryFile,
        variables: {
            entity_name: entityName,
            entity_id: entityId || searchParams.get('sectionId'),
            library_file_id: fileId,
            type: '2d',
        },
        fetchPolicy: 'cache-and-network',
    })

    const annotationComments =
        annotationCommentsData?.annotations ||
        annotatedCommentsDataForLibraryFile?.annotations ||
        []

    // Set the active point if the commentId is present in the URL (for deeplinking)
    const commentId = searchParams.get('commentId')
    useEffect(() => {
        const activeComment = annotationComments.find(
            (comment: any) => String(comment.id) === commentId
        )
        if (commentId && activeComment && imageObject) {
            const scaleFactorX = imageObject.scaleX!
            const scaleFactorY = imageObject.scaleY!
            setActivePoint({
                x: activeComment.coordinates[0],
                y: activeComment.coordinates[1],
                offsetX:
                    imageObject.left! +
                        activeComment.coordinates[0] * scaleFactorX || 0,
                offsetY:
                    imageObject.top! +
                        activeComment.coordinates[1] * scaleFactorY || 0,
            })
            searchParams.delete('commentId')
            setSearchParams(searchParams, { replace: true })
        }
    }, [commentId, annotationComments, imageObject])

    const annotationPoints: Array<CoordinateWithCameraPosition> =
        useMemo(() => {
            if (annotationComments) {
                const uniqueCoordinates = new Map()
                annotationComments.forEach((comment: any) => {
                    if (comment.coordinates) {
                        const key = comment.coordinates.join(',')
                        if (!uniqueCoordinates.has(key)) {
                            uniqueCoordinates.set(key, {
                                coordinates: comment.coordinates,
                            })
                        }
                    }
                })
                return Array.from(uniqueCoordinates.values())
            } else {
                return []
            }
        }, [annotationComments])

    const { todoId } = useParams()

    const { data: TodoProductTeamData } = useQuery(GetTodoProductTeam, {
        variables: {
            id: todoId,
        },
    })
    const productTeam = TodoProductTeamData?.todos_by_pk?.product_variant?.team

    const previewId = uuid()

    // Initialize the canvas and load the image
    useEffect(() => {
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: canvasRef.current?.parentElement?.offsetWidth,
            height: canvasRef.current?.parentElement?.offsetHeight,
            hoverCursor: 'pointer',
            stopContextMenu: true, // Disable right-click context menu
            fireRightClick: true, // Enable right-click event
            selection: false, // Disable selection
            controlsAboveOverlay: false, // Render controls above overlay
        })
        fabricCanvasRef.current = canvas
        fabric.Image.fromURL(imageUrl, (img) => {
            // Calculate the scale factor to fit the image within the canvas
            if (canvas?.width && canvas?.height && img.width && img.height) {
                const scaleFactor = Math.min(
                    canvas.width / img.width,
                    canvas.height / img.height
                )

                img.setOptions({
                    zIndex: -1,
                    left: canvas.width / 2 - (img.width * scaleFactor) / 2,
                    top: canvas.height / 2 - (img.height * scaleFactor) / 2,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    selectable: false, // Disable selection on the image
                    hasControls: false, // Disable controls (rotate, resize)
                    hasBorders: false, // Disable borders
                })

                canvas.add(img)
                setImageObject(img)
                setLoading(false)
            }
        })

        return () => {
            canvas.dispose()
        }
    }, [])

    // Handle changes to the mode and annotationPoints
    useEffect(() => {
        const canvas = fabricCanvasRef.current
        if (!imageObject) return
        const scaleFactorX = imageObject?.scaleX
        const scaleFactorY = imageObject?.scaleY
        if (canvas && scaleFactorX && scaleFactorY) {
            annotationPoints.forEach((annotation, index) => {
                const scaledX =
                    annotation.coordinates[0] * scaleFactorX + imageObject.left!
                const scaledY =
                    annotation.coordinates[1] * scaleFactorY + imageObject.top!
                const circleObject = new fabric.Circle({
                    radius: 18 * scaleFactorX * 1.5,
                    fill: 'black',
                    left: scaledX,
                    top: scaledY,
                    selectable: false,
                })
                const textObject = new fabric.Text(String(index + 1), {
                    fontSize: 18 * scaleFactorX * 1.5,
                    fill: 'white',
                    selectable: false,
                })

                const circleCenterX = circleObject.left! + circleObject.radius!
                const circleCenterY = circleObject.top! + circleObject.radius!
                textObject.left = circleCenterX - textObject.width! / 2
                textObject.top = circleCenterY - textObject.height! / 2

                const group = new fabric.Group([circleObject, textObject], {
                    selectable: false,
                })

                group.on('mousedown', (options) => {
                    setActivePoint({
                        x: annotation.coordinates[0],
                        y: annotation.coordinates[1],
                        offsetX: options.e.offsetX,
                        offsetY: options.e.offsetY,
                    })
                })

                canvas.add(group)
            })
            canvas.renderAll()

            let isMouseDown = false
            let isMouseRightClick = false

            // Add annotation functionality on click
            canvas.on('mouse:down', (options: any) => {
                const event = options.e as MouseEvent
                if (options.target && options.target.type === 'image') {
                    isMouseDown = true
                }
                if (event.button === 2) {
                    isMouseRightClick = true
                    event.preventDefault()
                }
                // Disable annotation popup when ctrl key or right click is pressed
                if (!event.ctrlKey && !isMouseRightClick) {
                    if (options.target && options.target.type === 'image') {
                        const pointer = canvas.getPointer(options.e)
                        const image = options.target
                        const scaleFactorX = image.scaleX!
                        const scaleFactorY = image.scaleY!
                        const x = (pointer.x - image.left!) / scaleFactorX
                        const y = (pointer.y - image.top!) / scaleFactorY
                        const offsetX = options.e.offsetX
                        const offsetY = options.e.offsetY
                        setActivePoint({
                            x: x,
                            y: y,
                            offsetX: offsetX,
                            offsetY: offsetY,
                        })
                    } else if (
                        options.target &&
                        options.target.type === 'circle'
                    ) {
                        const pointer = canvas.getPointer(options.e)
                        setActivePoint({
                            x: pointer.x,
                            y: pointer.y,
                            offsetX: options.e.offsetX,
                            offsetY: options.e.offsetY,
                        })
                    }
                }
            })

            canvas.on('mouse:up', () => {
                isMouseDown = false
                isMouseRightClick = false
            })

            canvas.on('mouse:move', (options: fabric.IEvent) => {
                const event = options.e as MouseEvent
                if ((isMouseDown && event.ctrlKey) || isMouseRightClick) {
                    const event = options.e as MouseEvent
                    const delta = new fabric.Point(
                        event.movementX,
                        event.movementY
                    )
                    canvas.relativePan(delta)
                }
            })

            canvas.on('mouse:wheel', (options: fabric.IEvent) => {
                setActivePoint(null)
                const event = options.e as WheelEvent
                let delta = event.deltaY * -1

                // Heuristic to differentiate between a trackpad and a mouse wheel
                let inputSource
                if (Math.abs(delta) < 50) {
                    inputSource = 'trackpad'
                } else {
                    inputSource = 'mouse wheel'
                }

                let zoom = canvas.getZoom()
                const zoomPoint = new fabric.Point(event.offsetX, event.offsetY)

                const ZOOM_FACTOR = 0.0008

                // Calculate the new zoom level based on the delta and the zoom factor
                let targetZoom = zoom + delta * ZOOM_FACTOR

                // Ensure that the new zoom level is not less than the minimum allowed zoom level
                targetZoom = Math.max(targetZoom, 0.01) // Set lower limit to 0.01

                // Set the new zoom level
                if (inputSource === 'trackpad') {
                    canvas.zoomToPoint(zoomPoint, targetZoom)
                } else {
                    const steps = 10
                    const stepChange = (targetZoom - zoom) / steps
                    let currentStep = 0

                    const intervalId = setInterval(() => {
                        if (currentStep < steps) {
                            zoom += stepChange
                            canvas.zoomToPoint(zoomPoint, zoom)
                            currentStep++
                        } else {
                            clearInterval(intervalId)
                        }
                    }, 20)
                }
                options.e.preventDefault()
            })
        }

        return () => {
            if (fabricCanvasRef.current) {
                fabricCanvasRef.current.off('mouse:down')
                fabricCanvasRef.current.off('mouse:up')
                fabricCanvasRef.current.off('mouse:move')
                fabricCanvasRef.current.off('mouse:wheel')
            }
            if (canvas) {
                canvas.getObjects().forEach((object: fabric.Object) => {
                    if (object.type === 'group') {
                        canvas.remove(object)
                    }
                })
            }
        }
    }, [annotationPoints, fabricCanvasRef.current, imageObject])

    function zoomInHandler() {
        const canvas = fabricCanvasRef.current
        const zoom = canvas.getZoom()
        const targetZoom = zoom + 0.1
        const center = new fabric.Point(canvas.width / 2, canvas.height / 2)

        const steps = 10
        const stepChange = (targetZoom - zoom) / steps
        let currentStep = 0

        const intervalId = setInterval(() => {
            if (currentStep < steps) {
                canvas.zoomToPoint(center, zoom + stepChange * currentStep)
                currentStep++
            } else {
                clearInterval(intervalId)
            }
        }, 20)
    }

    function zoomOutHandler() {
        const canvas = fabricCanvasRef.current
        const zoom = canvas.getZoom()

        // Set the minimum zoom level to a very small positive number
        const minZoom = 0.01

        // Ensure the target zoom level is not less than the minimum zoom level
        const targetZoom = Math.max(zoom - 0.1, minZoom)
        const center = new fabric.Point(canvas.width / 2, canvas.height / 2)

        const steps = 10
        const stepChange = (zoom - targetZoom) / steps
        let currentStep = 0

        const intervalId = setInterval(() => {
            if (currentStep < steps) {
                const newZoom = Math.max(
                    zoom - stepChange * currentStep,
                    minZoom
                )
                canvas.zoomToPoint(center, newZoom)
                currentStep++
            } else {
                clearInterval(intervalId)
            }
        }, 20)
    }

    // Set the zoom functions to the parent component
    useEffect(() => {
        if (setZoomFunctions && fabricCanvasRef.current) {
            setZoomFunctions({
                zoomIn: zoomInHandler,
                zoomOut: zoomOutHandler,
            })
        }
    }, [setZoomFunctions, fabricCanvasRef.current, imageObject])

    return (
        <Box
            position="relative"
            width={'100%'}
            height={'100%'}
            id="annotation-input-container"
        >
            {loading && (
                <Box
                    position="absolute"
                    width={'100%'}
                    display="flex"
                    borderRadius="4px"
                    alignItems="center"
                    justifyContent="center"
                    height={'100%'}
                    id={`loader-${previewId}`}
                >
                    <Box className="model-loader"></Box>
                </Box>
            )}
            <canvas ref={canvasRef} />

            {activePoint && imageObject && (
                <ImageAnnotatorInput
                    key={JSON.stringify(activePoint)}
                    activePoint={activePoint}
                    onClose={() => setActivePoint(null)}
                    comments={
                        annotationComments.filter(
                            (comment: AnnotatedComment) =>
                                JSON.stringify(comment.coordinates) ===
                                JSON.stringify([activePoint.x, activePoint.y])
                        ) || []
                    }
                    refetch={() => {
                        if (!isLibraryFile) {
                            refetchComments()
                        } else {
                            refetchAnnotatedCommentsForLibraryFile()
                        }
                    }}
                    entityId={
                        entityId ||
                        (Number(searchParams.get('sectionId')) as number)
                    }
                    entityName={entityName}
                    fileId={fileId}
                    team={productTeam}
                    isLibraryFile={isLibraryFile}
                />
            )}
        </Box>
    )
}

export default ImageAnnotator
