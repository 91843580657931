import { toast } from 'react-toastify'
import { deleteFile, getFileRequestId } from './file-operations'

export async function deleteFileFromEntity({
    entityId,
    entityName,
    fileId,
    onComplete,
}: {
    entityId: string
    entityName: string
    fileId: number
    onComplete?: () => void
}) {
    try {
        const { request_id } = await getFileRequestId({
            action: 'DELETE',
            entity_id: entityId,
            entity_name: entityName,
            number_of_files: 1,
        })
        await deleteFile(null, fileId, request_id)
        onComplete && onComplete()
    } catch (e: any) {
        let message: any = "Couldn't delete file."
        toast(message, { className: 'custom-toaster toaster-error' })
    }
}

export async function deleteFilesFromEntity({
    entityId,
    entityName,
    fileIds,
    onComplete,
}: {
    entityId: string
    entityName: string
    fileIds: number[]
    onComplete?: () => void
}) {
    try {
        const { request_id } = await getFileRequestId({
            action: 'DELETE',
            entity_id: entityId,
            entity_name: entityName,
            number_of_files: fileIds.length,
        })
        for (const fileId of fileIds) {
            await deleteFile(null, fileId, request_id)
        }
        onComplete && onComplete()
    } catch (e: any) {
        let message: any = "Couldn't delete file(s)."
        toast(message, { className: 'custom-toaster toaster-error' })
    }
}
