import { gql } from '@apollo/client'

export const DeleteTodoById = gql`
    mutation DeleteTodoById($id: bigint!, $deleted_at: timestamptz) {
        update_todos_by_pk(
            _set: { deleted_at: $deleted_at }
            pk_columns: { id: $id }
        ) {
            id
        }
    }
`

export const UpdateProductTags = gql`
    mutation UpdateProductTags($id: bigint!, $tags: jsonb) {
        update_products_by_pk(pk_columns: { id: $id }, _set: { tags: $tags }) {
            tags
        }
    }
`

export const DeletFile = gql`
    mutation DeleteFile($path: String) {
        delete_files(where: { path: { _eq: $path } }) {
            affected_rows
        }
    }
`
export const DeletFileFromSubTodo = gql`
    mutation DeleteFileFromSubTodo($id: bigint!, $other_data: jsonb) {
        update_sub_todos_by_pk(
            pk_columns: { id: $id }
            _set: { other_data: $other_data }
        ) {
            id
        }
    }
`

export const CreateProduct = gql`
    mutation createProduct($object: products_insert_input!) {
        insert_products_one(object: $object) {
            id
            created_at
            name
            team_id
            is_delayed
            description
            due_date
            approver_due_date
            tags
            status
            approver_id
            product_approver {
                id
                name
                email
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
            }
            todos_aggregate {
                aggregate {
                    count
                }
            }
            product_creator {
                name
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
            }
            team {
                id
                name
                team_members {
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
            collection {
                id
                name
                manager_id
                manager {
                    other_data
                    name
                    id
                }
            }
        }
    }
`

export const UpdateProduct = gql`
    mutation updateProduct(
        $id: bigint!
        $approver_id: bigint
        $approver_due_date: timestamptz
        $description: String
        $tags: jsonb
        $due_date: timestamptz
        $name: String
    ) {
        update_products_by_pk(
            pk_columns: { id: $id }
            _set: {
                approver_id: $approver_id
                approver_due_date: $approver_due_date
                description: $description
                tags: $tags
                due_date: $due_date
                name: $name
            }
        ) {
            id
            name
        }
    }
`

export const UpdateProductField = gql`
    mutation UpdateProductField($id: bigint!, $object: products_set_input!) {
        update_products_by_pk(pk_columns: { id: $id }, _set: $object) {
            id
        }
    }
`

export const UpdateTodo = gql`
    mutation MyMutation(
        $where: todos_bool_exp = { id: { _eq: "7" } }
        $todo: todos_set_input = {
            description: ""
            due_date: ""
            duration: ""
            name: ""
            tags: ""
            updated_at: ""
            user_id: ""
        }
    ) {
        update_todos(where: $where, _set: $todo) {
            affected_rows
        }
    }
`
