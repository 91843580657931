import type { storeState } from './@types/slider-types'
const createProfileSlice = (set: Function) => ({
	profileImage: '',
	profileName: '',
	setProfile: ({ profileImage, profileName }: { profileImage: boolean | string, profileName: string }) => {
		set((state: storeState) => {
			return { ...state, profileImage: profileImage, profileName: profileName }
		})
	}, setProfileImage: ({ image }: { image: string }) => {
		set((state: storeState) => {
			return {
				...state,
				profileImage: image
			}
		})
	},
	setProfileName: ({ name }: { name: string }) => {
		set((state: storeState) => {
			return {
				...state,
				profileName: name
			}
		})
	}
})

export default createProfileSlice
