export function isValidHexaCode(str: string): boolean {
    if (str[0] !== '#') {
        return false
    }

    if (!(str.length === 4 || str.length === 7)) {
        return false
    }

    for (let i = 1; i < str.length; i++) {
        if (
            !(
                (str[i] >= '0' && str[i] <= '9') ||
                (str[i] >= 'a' && str[i] <= 'f') ||
                (str[i] >= 'A' && str[i] <= 'F')
            )
        ) {
            return false
        }
    }

    return true
}
