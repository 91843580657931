import React, { useState } from 'react'
import { Loader } from 'src/components'
import { ProductBadge } from 'src/components-v2/badges/product-badge'
import {
    CollectionIco,
    Dots,
    DropDownArrow,
    TeamIco,
} from 'src/components/svg-icons'
import { Box, Button, Image, Input, Text } from 'src/components/utility'
import { getFormattedDate, uuid } from 'src/helpers'
import ProductComments from '../products/product-comments/product-comments'
import ProductActivities from '../products/product-activities/product-activities'
import AdditionalInfoDropdown from '../dropdowns/additional-info-dropdown/additional-info-dropdown'
import ProductMenuDropdown from 'src/components/drawer/product-tab/product-menu-dropdown'
import UploadProductThumbnailMenuDropdown from '../upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { toast } from 'react-toastify'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import ProductStatus from './product-status/product-status'
import useUpdateProduct from '../cards/hooks/useUpdateProduct'
import TeamBadge from '../team-badge/team-badge'
import AssignUser from '../dropdown-badges/assign-user/assign-user'
import { VariantBadge } from '../badges/variant-badge'
import { ArrowDown } from 'src/assets/svgs-components'
import VariantsDropdown from '../product-variants/variants-dropdown'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { Link } from 'react-router-dom'
import useStore from 'src/store/hooks/useStore'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import type { GetProductQuery, Status_Enum } from 'src/generated/graphql'
import { getThumbnailPicture } from 'src/helpers/get-thumbnail-picture'
import { AddCollaboratorModal } from 'src/screens-v2/organisations-analytics/modal/add-collaborator/add-collaborator'
import { useUpdateCollection } from '../cards/hooks/useUpdateCollection'
import ThumbnailFullView from '../thumbnail-badge/thumbnail-full-view'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import CalendarDropdown from '../cards/dropdowns/calendar'

function ProductHeader({
    product,
    isSplitMode,
    isCompareSection,
    refetchProduct,
}: {
    product: NonNullable<GetProductQuery['product_variants']>[0]
    isSplitMode?: boolean
    isCompareSection?: boolean
    refetchProduct: () => void
}) {
    const {
        debouncedUpdateProductVariantHandler,
        updateProductVariantHandler,
        updateProductVariantDueDate,
    } = useUpdateProduct()
    const { updateCollectionTeamAndItsProductsTeamHandler } =
        useUpdateCollection()
    const { onFileUpload } = useUploadFile(
        String(product?.id),
        'product_variants'
    )
    const { assignFilesToSection } = useAssignFile(() => {
        refetchProduct && refetchProduct()
    })
    const [componentVisibility, setComponentVisibility] = useState({
        calendarDropdown: false,
        additionalInfo: false,
        productMenu: false,
        addThumbnailDropdown: false,
        variantsDropdown: false,
        expandViewModal: false,
        teamDropdown: false,
    })

    const { updateGlobalSearchResults } = useStore()

    const [name, setName] = useState(product?.name)

    function updateNameHandler(name: string) {
        setName(name)
        if (name.trim().length > 0) {
            debouncedUpdateProductVariantHandler({
                productId: product?.id,
                payload: { name: name.trimStart() },
            })
        }
    }

    const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)

    async function uploadImageHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        productId: number
    ) {
        setComponentVisibility({
            ...componentVisibility,
            addThumbnailDropdown: false,
        })
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)
            const file = e.target.files[0]
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: file,
                            id: uuid(),
                            name: file.name,
                            path: getLocalImageUrl(file) || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            const fileId = data?.resolvedArr[0].id
            if (fileId) {
                assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityId: String(productId),
                    entityName: 'product_variants',
                    fileId: [data?.resolvedArr[0].id],
                })
            }
        } catch {
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsThumbnailUploading(false)
        }
    }

    const coverPic = getThumbnailPicture({
        coverPic: product.other_data?.cover_pic,
        fallbackPlaceholder: false,
    })

    return (
        <Box
            display="flex"
            gridGap="16px"
            px="24px"
            py="12px"
            position="relative"
        >
            <Box
                display="flex"
                position="relative"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridGap="4px"
                p="8px"
                border={
                    componentVisibility.variantsDropdown
                        ? '1px solid black'
                        : '1px solid #E8ECEF'
                }
                borderRadius="4px"
                width="82px"
                className="cursor-pointer"
            >
                <Box
                    id="thumbnail-button"
                    onClick={() =>
                        setComponentVisibility({
                            ...componentVisibility,
                            addThumbnailDropdown:
                                !componentVisibility.addThumbnailDropdown,
                        })
                    }
                >
                    {coverPic && (
                        <ThumbnailFullView
                            gid={product.other_data.cover_pic.gid}
                            fromLibrary={
                                product.other_data.cover_pic.fromLibrary
                            }
                        />
                    )}
                    {isThumbnailUploading ? (
                        <Loader outline="black" size="36px" />
                    ) : (
                        <Image
                            src={coverPic || '/image-placeholder.jpg'}
                            alt="image"
                            width="53px"
                            height="53px"
                            borderRadius="4px"
                            id="thumbnail-image"
                        />
                    )}
                    {componentVisibility.addThumbnailDropdown && (
                        <UploadProductThumbnailMenuDropdown
                            active={componentVisibility.addThumbnailDropdown}
                            onClose={() => {
                                setComponentVisibility({
                                    ...componentVisibility,
                                    addThumbnailDropdown: false,
                                })
                            }}
                            entityId={product.id}
                            entityName="product_variants"
                            productId={product.id}
                            onUpload={(e) => uploadImageHandler(e, product.id)}
                            refetchProduct={refetchProduct}
                        />
                    )}
                </Box>
                <Box position="relative" style={{ cursor: 'default' }}>
                    <Button
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ gap: '4px' }}
                        border="none"
                        bg="transparent"
                        textAlign="left"
                        p="0px"
                        id="variants-dropdown-button"
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation()
                            setComponentVisibility({
                                ...componentVisibility,
                                variantsDropdown:
                                    !componentVisibility.variantsDropdown,
                            })
                        }}
                    >
                        <Text
                            fontSize={'10px'}
                            my="0px"
                            mt="4px"
                            style={{ wordBreak: 'break-all' }}
                            title={product.name}
                            id="variant-name-badge"
                        >
                            {convertIntoElipses(product.name, 6)}
                        </Text>
                        <Box pt="4px">
                            <ArrowDown id="variant-dropdown-arrow" />
                        </Box>
                    </Button>
                    {componentVisibility.variantsDropdown && (
                        <VariantsDropdown
                            productId={product.product_id}
                            productName={product.product?.name || ''}
                            activeVariantId={product.id}
                            onClose={() =>
                                setComponentVisibility({
                                    ...componentVisibility,
                                    variantsDropdown: false,
                                })
                            }
                            isCompareSection={isCompareSection}
                            isSplitMode={isSplitMode}
                        />
                    )}
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                py="8px"
                width="100%"
                gridGap="8px"
            >
                <Box
                    display="flex"
                    gridGap="16px"
                    width="100%"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        width="100%"
                        gridGap="16px"
                        alignItems="center"
                    >
                        {product.is_primary ? (
                            <ProductBadge />
                        ) : (
                            <VariantBadge />
                        )}
                        <Input
                            value={name}
                            title={name}
                            placeholder="Enter product name"
                            bg="transparent"
                            onChange={(e) => updateNameHandler(e.target.value)}
                            fontSize="16px"
                            border="none"
                            width="100%"
                            className="border-on-hover"
                            py="4px"
                            px="4px"
                        />
                    </Box>
                    <Box display="flex" alignItems="center" gridGap="8px">
                        <ProductComments
                            productId={product.id}
                            productName={product.name}
                            productTeam={product.team}
                        />
                        <ProductActivities product={product} />
                        <Box position="relative">
                            <Button
                                onClick={() =>
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        productMenu:
                                            !componentVisibility.productMenu,
                                    })
                                }
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="links"
                                position="relative"
                                width="max-content"
                                id="product-menu-button"
                            >
                                <Dots
                                    active={componentVisibility.productMenu}
                                />
                            </Button>
                            {componentVisibility.productMenu && (
                                <ProductMenuDropdown
                                    active={componentVisibility.productMenu}
                                    setIsDropdownActive={(value: boolean) => {
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            productMenu: value,
                                        })
                                    }}
                                    product={{
                                        id: product.id,
                                        name: product.name,
                                        collection: {
                                            id: product.collection.id,
                                            name: product.collection.name,
                                        },
                                    }}
                                    isDetailedPage={true}
                                    isCompareSection={isCompareSection}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    gridGap="16px"
                    width="100%"
                    alignItems="center"
                >
                    {!isSplitMode && product?.collection && (
                        <Box
                            to={`/collections/${product.collection.id}`}
                            as={Link}
                            display="flex"
                            gridGap="8px"
                            alignItems="center"
                            style={{ textDecoration: 'none' }}
                            color="links"
                        >
                            <CollectionIco color="#027AC3" />
                            <Text my="0px" fontSize="14px">
                                {product.collection.name}
                            </Text>
                        </Box>
                    )}
                    {!isSplitMode && (
                        <AssignUser
                            assignee={product.product_variant_approver}
                            onRemove={() => {
                                updateProductVariantHandler({
                                    productId: product.id,
                                    payload: {
                                        approver_id: null,
                                    },
                                })
                            }}
                            onAssign={(user) => {
                                updateProductVariantHandler({
                                    productId: product.id,
                                    payload: {
                                        approver_id: user.id,
                                    },
                                })
                            }}
                            role="Approver"
                        />
                    )}
                    {!isSplitMode && product.team?.id ? (
                        <Box
                            display="flex"
                            gridGap="12px"
                            alignItems="center"
                            onClick={() => {
                                setComponentVisibility({
                                    ...componentVisibility,
                                    teamDropdown: true,
                                })
                            }}
                            className="cursor-pointer"
                        >
                            <Box
                                bg="#F8FAFB"
                                py="2px"
                                px="4px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="4px"
                                width={'32px'}
                                height={'32px'}
                            >
                                <TeamIco />
                            </Box>
                            <TeamBadge
                                teamName={product.team.name}
                                teamMembers={product.team.team_members}
                                onRemove={() => {
                                    updateCollectionTeamAndItsProductsTeamHandler(
                                        {
                                            teamId: null,
                                            collectionId: product.collection.id,
                                            onCompleted: () => {
                                                refetchProduct()
                                            },
                                        }
                                    )
                                }}
                            />
                        </Box>
                    ) : (
                        <Box display="flex" gridGap="12px" alignItems="center">
                            <TeamIco />
                            <Button
                                bg="transparent"
                                fontStyle="Rubik"
                                fontSize="14px"
                                color="textSecondary"
                                className="input-border"
                                py="4px"
                                onClick={() => {
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        teamDropdown: true,
                                    })
                                }}
                            >
                                Add Team
                            </Button>
                        </Box>
                    )}
                    {componentVisibility.teamDropdown && (
                        <AddCollaboratorModal
                            onRoleUpdate={() => {}}
                            teamMembers={[]}
                            onCollaboratorsAdded={() => {}}
                            teamId={String(product.team?.id || 0)}
                            onTeamAdded={(id) => {
                                updateCollectionTeamAndItsProductsTeamHandler({
                                    teamId: id,
                                    collectionId: product.collection.id,
                                    onCompleted: () => {
                                        refetchProduct()
                                    },
                                })
                                setComponentVisibility({
                                    ...componentVisibility,
                                    teamDropdown: false,
                                })
                            }}
                            isModal={componentVisibility.teamDropdown}
                            onClose={(value) => {
                                setComponentVisibility({
                                    ...componentVisibility,
                                    teamDropdown: value,
                                })
                            }}
                        />
                    )}
                    <Box display="flex" gridGap="8px" alignItems="center">
                        <CalendarDropdown
                            date={
                                product?.due_date
                                    ? new Date(product.due_date)
                                    : null
                            }
                            left={'0px'}
                            setDate={(date) => {
                                updateProductVariantDueDate({
                                    id: product.id,
                                    dueDate: date.toISOString(),
                                    onCompleted: () => {
                                        updateGlobalSearchResults({
                                            type: 'products',
                                            updatedKey: 'due_date',
                                            value: date.toISOString(),
                                            id: String(product.id),
                                        })
                                    },
                                })
                            }}
                            isDelayed={isEntityDueDateDelayed(
                                product.due_date,
                                product.status as Status_Enum
                            )}
                        />
                        <ProductStatus
                            productId={product.id}
                            productStatus={product.status}
                            approverId={product.approver_id}
                        />
                    </Box>
                </Box>
                {!isSplitMode && (
                    <Box
                        display="flex"
                        justifyContent="end"
                        gridGap="8px"
                        width="100%"
                        borderBottom="1px solid #E8ECEF"
                    >
                        <Box
                            width="35px"
                            height="16px"
                            borderRadius="50px"
                            bg="#E8ECEF"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            border="1px solid #C2CFE0"
                            // @ts-expect-error
                            mb="-8px"
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        additionalInfo:
                                            !componentVisibility.additionalInfo,
                                    })
                                }}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="#027AC3"
                                position="relative"
                                width="max-content"
                                mt="4px"
                                id="additional-info-dropdown-button"
                            >
                                <DropDownArrow
                                    rotate={componentVisibility.additionalInfo}
                                    color="#778CA2"
                                />
                            </Button>
                            {componentVisibility.additionalInfo && (
                                <AdditionalInfoDropdown
                                    creatorName={
                                        product.product_variant_creator?.name ||
                                        ''
                                    }
                                    createdDate={getFormattedDate(
                                        product.created_at
                                    )}
                                    updatedDate={getFormattedDate(
                                        product.updated_at
                                    )}
                                    description={product.description || ''}
                                    onClose={() =>
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            additionalInfo: false,
                                        })
                                    }
                                    onDescriptionUpdate={(description) => {
                                        debouncedUpdateProductVariantHandler({
                                            productId: product.id,
                                            payload: {
                                                description,
                                            },
                                            onComplete: () => {
                                                refetchProduct()
                                            },
                                        })
                                    }}
                                    onTagsUpdate={(tags) => {
                                        updateProductVariantHandler({
                                            productId: product.id,
                                            payload: {
                                                tags,
                                            },
                                            onComplete: () => {
                                                refetchProduct()
                                            },
                                        })
                                    }}
                                    tags={product.tags}
                                />
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default ProductHeader
