import React from 'react'
import TemplateFolderIco from 'src/components/svg-icons/template-folder-ico'
import { Box, Text } from 'src/components/utility'
import type { Template_Categories } from 'src/generated/graphql'

function TemplateFolderCard({
    onClick,
    category,
}: {
    onClick: () => void
    category: Template_Categories
}) {
    const templatesInCategoryCount = category.templates.length

    return (
        <Box
            display="grid"
            gridTemplateRows={'auto 1fr auto'}
            onClick={onClick}
            gridGap="8px"
            p="24px"
            bg="white"
            border="1px solid #E8ECEF"
            borderRadius="4px"
            width="212px"
            height="auto"
            className="cursor-pointer"
        >
            <TemplateFolderIco />
            <Text my="0px" fontSize="14px" color="primary" mt="8px">
                {category.name}
            </Text>
            <Text my="0px" color="textSecondary" fontSize="12px">
                {templatesInCategoryCount} Template
                {templatesInCategoryCount > 1 ? 's' : ''}
            </Text>
        </Box>
    )
}

export default TemplateFolderCard
