import * as React from 'react'

export const CommentsIco = ({ active = true }: { active?: boolean }) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 4a1 1 0 0 0-1 1v8.202a1 1 0 0 0 1 1h1.55v2.62a.3.3 0 0 0 .489.233l.548-.442 3.008-2.411h5.157a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H4Zm.275 1.275h10.202v7.652h-5.32l-.18.139-2.151 1.714v-1.853h-2.55V5.275Zm13.39 1.275a.638.638 0 0 0 0 1.276h1.913v7.651h-2.55v1.853l-2.332-1.853H9.914l-1.149.919a.2.2 0 0 0 .125.356h5.368l3.557 2.856a.3.3 0 0 0 .488-.234v-2.622h1.55a1 1 0 0 0 1-1V7.55a1 1 0 0 0-1-1h-2.188Z"
            fill={active ? '#022143' : '#778CA2'}
        />
    </svg>
)
