import * as React from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Button } from 'src/components/utility'

export interface ITeamDropdownProps {
    teamDropdown: Boolean
    onDropDownClick: (value: string) => void
    alignment?: any
}

export default function TeamDropdown(props: ITeamDropdownProps) {
    const { teamDropdown, onDropDownClick } = props
    return (
        <Dropdown
            left="unset"
            right="0px"
            {...props.alignment}
            active={teamDropdown}
        >
            <>
                <DropdownButtonWrapper
                    value="rename_team"
                    onClick={(val) => onDropDownClick(val)}
                    width="100%"
                >
                    <Button
                        textAlign="left"
                        width="100%"
                        bg="transparent"
                        border="none"
                    >
                        Rename Team
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="edit_team"
                    onClick={(val) => onDropDownClick(val)}
                    width="100%"
                >
                    <Button
                        textAlign="left"
                        width="100%"
                        bg="transparent"
                        border="none"
                    >
                        Edit Team
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="delete_team"
                    onClick={(val) => onDropDownClick(val)}
                    width="100%"
                >
                    <Button
                        textAlign="left"
                        width="100%"
                        color="delay"
                        bg="transparent"
                        border="none"
                    >
                        Delete Team
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
