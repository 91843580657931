import { Box, Text } from 'src/components/utility'
import { roles, useUser } from 'src/helpers'
import { YourCollectionsList } from './sub-screens/collections/your-collections-list'
import { OwnerStats } from './sub-screens/stats/owner-stats'
import './dashboard.css'
import { YourTodosList } from './sub-screens/todos/your-todos-list'
import { YourTeamsList } from './sub-screens/team/your-team'
import YourProductList from './sub-screens/products/your-product-list'
import { RecentCommentsList } from './sub-screens/comments/recent-comments'

const Dashboard = () => {
    const { role, name } = useUser()

    return (
        <>
            <Box py={'40px'}>
                <Text mt={'0px'} fontSize="24px" color="primary">
                    Hello {name} 👋🏻
                </Text>

                {role === roles.admin && (
                    <>
                        <OwnerStats />
                        <Box width="100%" mt={'24px'}>
                            <YourCollectionsList role={role} />
                        </Box>
                    </>
                )}

                {/* 0------------0 */}
                {role === roles.designer && (
                    <Box
                        display="grid"
                        gridTemplateColumns={'minmax(auto, 1fr)'}
                        gridTemplateRows={'minmax(auto, 1fr)'}
                        flexDirection="column"
                        gridRowGap="24px"
                    >
                        <Box display="flex" gridColumnGap="24px">
                            <Box height="100%" width="100%">
                                <YourTodosList allowPagination={false} />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                height="100%"
                                width="100%"
                            >
                                <RecentCommentsList />
                            </Box>
                        </Box>
                        <Box display="flex" gridColumnGap="24px">
                            <YourCollectionsList role={role} />
                            <Box>
                                <YourTeamsList />
                            </Box>
                        </Box>
                    </Box>
                )}
                {role === roles.manager && (
                    <Box
                        display="grid"
                        gridTemplateColumns={'1fr'}
                        gridTemplateRows={'1fr'}
                        flexDirection="column"
                        gridRowGap="24px"
                    >
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="24px"
                        >
                            <Box width="100%">
                                <YourTodosList allowPagination={false} />
                            </Box>
                            <Box width="100%">
                                <YourProductList />
                            </Box>
                        </Box>
                        <Box
                            gridTemplateColumns="1fr 1fr"
                            display="grid"
                            gridColumnGap="24px"
                        >
                            <YourCollectionsList role={role} />
                            <Box width="100%">
                                <RecentCommentsList />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}

export default Dashboard
