import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Dots } from 'src/components/svg-icons'
import { FolderIco } from 'src/components/svg-icons/library/folder'
import { Box, Button, Text, Image } from 'src/components/utility'
import { toast } from 'react-toastify'
import { copyToClipBoard, getImageUrl, roles, useUser } from 'src/helpers'
import { AddFolderModal } from './modals/create-new-folder'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import { DownloadZip, RemoveLibraryThumbnailById } from 'src/services'
import { useMutation } from '@apollo/client'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'

export const FolderCard = ({
    data,
    deleteFolderModal,
    runFavMutation,
    runDeleteFavMutation,
    isFavorite = false,
    selectFilesFlow = false,
    highlightFolder = false,
}: {
    data: FolderObject
    deleteFolderModal?: (folderId: number) => void
    runFavMutation?: (folderId: number | null, fileId: number | null) => void
    runDeleteFavMutation?: (id: number) => void
    isFavorite?: boolean
    selectFilesFlow?: boolean
    highlightFolder?: boolean
}) => {
    const { role } = useUser()
    const params = useParams()
    const entityName = params.entityName
    const entityId = params.entityId

    const [isDropDown, setIsDropdown] = useState(false)
    const dropDownRef = useRef<any>(null)

    const _isFavorite = data?.library_favourites?.length ? true : isFavorite

    const dropItems =
        roles.designer === role
            ? [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'Download Files', id: 'DOWNLOAD_FILES' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
              ]
            : [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'Download Files', id: 'DOWNLOAD_FILES' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  { name: 'Edit Details', id: 'EDIT_DETAILS' },
                  // { name: "Archive Folder", id: "ARCHIVED_FOLDER" },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
                  { name: 'Remove thumbnail', id: 'REMOVE_THUMBNAIL' },
                  { name: 'Delete Folder', id: 'DELETE_FOLDER' },
              ]

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [folder, setFolder] = useState<any>(null)
    const showFolderId = searchParams.get(libraryDefaultKeys.showFolderDetails)
    const [open, openModal] = useState(false)
    const [DownloadZipMutation] = useMutation(DownloadZip)
    const [removeFolderThumbnailById] = useMutation(RemoveLibraryThumbnailById)

    const noOfFiles = folder?.noOfFiles?.aggregate?.count || 0
    const noOfFolders = folder?.noOfFolders?.aggregate?.count || 0

    const parentTodoId = searchParams.get('parentTodoId')
    const cameFrom = searchParams.get('from')
    const collectionId = searchParams.get('collectionId')

    useEffect(() => {
        if (data) {
            setFolder(data)
        }
    }, [data])

    if (searchParams.get('folderId') === String(folder?.id)) {
        const ele: HTMLElement | null = window.document.getElementById(
            'folder_' + folder.id
        )
        ele?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
    }

    function disableDropdown(event: any) {
        if (dropDownRef !== null && dropDownRef.current !== null) {
            const isClickInsideElement = dropDownRef.current.contains(
                event.target
            )
            if (
                !isClickInsideElement &&
                event.target?.tagName !== 'BUTTON' &&
                event.target?.tagName !== 'svg' &&
                event.target?.tagName !== 'path'
            ) {
                setIsDropdown(false)
            }
        }
    }

    function removeFolderThumbnailMutation(folderId: number) {
        removeFolderThumbnailById({
            variables: {
                folderId,
            },
            onCompleted: () => {
                toast.success('Folder thumbnail removed successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                const message: string = error.message
                toast.error(message, {
                    className: 'custom-toaster toaster-danger toaster-error',
                })
            },
        })
    }

    const downLoadFiles = () => {
        if (!noOfFiles && !noOfFolders) {
            toast("Can't download the empty folder.", {
                className: 'custom-toaster toaster-info',
            })
            return
        }
        DownloadZipMutation({
            variables: {
                entity_name: 'folders',
                entity_id: data.id,
            },
            onCompleted: (data) => {
                toast(
                    'Please wait, We have started zipping your files, you will be notified when its done.',
                    {
                        className: 'custom-toaster toaster-info',
                    }
                )
            },
            onError: (data) => {
                toast('Failed to download files. Please try again.', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }

    const handleDropItems = (item: any) => {
        if (!item.id) {
            return
        }

        switch (item.id) {
            case 'VIEW_DETAILS':
                if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
                    searchParams.delete(libraryDefaultKeys.showFileDetails)
                }
                searchParams.set(
                    libraryDefaultKeys.showFolderDetails,
                    data.id.toString()
                )
                setSearchParams(searchParams)
                const ele: HTMLElement | null =
                    window.document.getElementById('layoutContainer2')
                ele?.scrollTo(0, 0)
                break

            case 'COPY_LINK':
                const url = new URL(window.location.href)
                url.searchParams.set('folderId', String(data.id))
                copyToClipBoard(url.href)
                toast.success('Link copied to clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            case 'DELETE_FOLDER':
                deleteFolderModal && deleteFolderModal(data.id)
                break
            case 'REMOVE_THUMBNAIL':
                removeFolderThumbnailMutation(data.id)
                break
            case 'EDIT_DETAILS':
                openModal(true)
                break

            case 'MARK_AS_FAV':
                runFavMutation && runFavMutation(folder.id, null)
                break

            case 'UN_MARK_FROM_FAV':
                let favId = folder.favourite_id
                if (data.library_favourites && data.library_favourites[0]) {
                    favId = data.library_favourites[0].id
                }
                favId && runDeleteFavMutation && runDeleteFavMutation(favId)
                break

            case 'DOWNLOAD_FILES':
                downLoadFiles()
                break

            default:
                break
        }
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    const routerChanged = () => {
        searchParams.delete('folderId')
        searchParams.delete('fileId')
        setSearchParams(searchParams)
        if (selectFilesFlow) {
            let _url: string = `/library/selectfiles/${entityId}/${entityName}/${data.id}`
            if (parentTodoId) {
                _url += `?parentTodoId=${parentTodoId}`
                if (cameFrom) {
                    _url += `&from=${cameFrom}`
                }
                if (collectionId && collectionId !== '0') {
                    _url += `&collectionId=${collectionId}`
                }
            }
            navigate(_url)
            return
        }
        const categoryId =
            data.category_id || searchParams.get(libraryDefaultKeys.categoryId)
        navigate(
            `/library/folder/${data.id}?${libraryDefaultKeys.categoryId}=${
                categoryId || 'all'
            }&tabName=${searchParams.get('tabName')}`
        )
    }

    const showImage = () => {
        return folder?.user?.other_data?.profile_pic
            ? getImageUrl(folder?.user?.other_data?.profile_pic)
            : ''
    }

    return (
        <>
            {folder && (
                <Box
                    maxWidth="276px"
                    onMouseLeave={() => setIsDropdown(false)}
                    className={`folderCard cursor-pointer ${
                        showFolderId &&
                        showFolderId == folder?.id.toString() &&
                        'viewdetails'
                    }`}
                    id={`folder_${folder.id}`}
                    position="relative"
                    width="100%"
                >
                    {!selectFilesFlow && (
                        <Box
                            position="absolute"
                            top="8px"
                            zIndex={2}
                            right="8px"
                            className="folderDropItem"
                        >
                            <Box position="relative">
                                <Button
                                    onClick={() => setIsDropdown(!isDropDown)}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="none"
                                    py="8px"
                                    borderRadius="4px"
                                    px="8px"
                                    id="folderDetailsIconId"
                                    bg={!isDropDown ? 'transparent' : 'gray250'}
                                >
                                    <Dots active={isDropDown} />
                                </Button>
                                {isDropDown && (
                                    <Dropdown
                                        ref={dropDownRef}
                                        top="42px"
                                        active={isDropDown}
                                        right="0px"
                                        left="unset"
                                    >
                                        <>
                                            {dropItems.map((item: any) => {
                                                if (
                                                    !data.thumbnail &&
                                                    item.id ===
                                                        'REMOVE_THUMBNAIL'
                                                ) {
                                                    return null
                                                }
                                                return (
                                                    <DropdownButtonWrapper
                                                        onClick={() => {
                                                            setIsDropdown(false)
                                                            handleDropItems(
                                                                item
                                                            )
                                                        }}
                                                        hover={true}
                                                        width="100%"
                                                        key={item.id}
                                                    >
                                                        <Button
                                                            color={
                                                                item.id ===
                                                                'DELETE_FOLDER'
                                                                    ? 'delay'
                                                                    : 'primary'
                                                            }
                                                            fontFamily="Rubik"
                                                            border="none"
                                                            bg="transparent"
                                                        >
                                                            {item.name}
                                                        </Button>
                                                    </DropdownButtonWrapper>
                                                )
                                            })}
                                        </>
                                    </Dropdown>
                                )}
                            </Box>
                        </Box>
                    )}

                    <Box
                        position="relative"
                        zIndex={1}
                        title={folder?.name}
                        onClick={() => {
                            routerChanged()
                        }}
                    >
                        {searchParams.get('folderId') && (
                            <Box
                                position="absolute"
                                top="0"
                                left="0"
                                className={
                                    searchParams.get('folderId') ===
                                    String(folder?.id)
                                        ? 'card-bg'
                                        : ''
                                }
                                zIndex={1}
                                width="100%"
                                height="100%"
                            ></Box>
                        )}
                        <BasicCard>
                            <Box
                                px={'24px'}
                                zIndex={1}
                                position="relative"
                                className="bgCard"
                                py="16px"
                            >
                                {!folder?.thumbnail ? (
                                    <FolderIco />
                                ) : (
                                    <Image
                                        borderRadius="4px"
                                        height={'42px'}
                                        width="48px"
                                        style={{ objectFit: 'cover' }}
                                        src={getLibraryFileUrl(
                                            folder?.thumbnail
                                        )}
                                    ></Image>
                                )}
                                <Text
                                    mb={'8px'}
                                    mt="12px"
                                    maxWidth="85%"
                                    className="text-ellipsis"
                                    fontSize={'14px'}
                                >
                                    {folder?.name}{' '}
                                </Text>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondaryDarkBlue"
                                    >
                                        {noOfFolders || 0} folder
                                        {noOfFolders > 1 || noOfFolders == 0
                                            ? 's'
                                            : ''}{' '}
                                        {noOfFiles > 1
                                            ? noOfFiles + ' files'
                                            : noOfFiles > 0
                                            ? noOfFiles + ' file'
                                            : ''}
                                    </Text>
                                    <ProfileImg
                                        src={showImage()}
                                        fontSize={'12px'}
                                        name={folder?.user?.name || ''}
                                        size="20px"
                                    />
                                </Box>
                            </Box>
                        </BasicCard>
                    </Box>
                </Box>
            )}

            {open && (
                <AddFolderModal
                    isModal={open}
                    setModal={openModal}
                    edit={true}
                    existingFolder={folder}
                    refetchFolders={(response: string) => {
                        setFolder((f: FolderObject | undefined) => ({
                            ...f,
                            name: response,
                        }))
                    }}
                />
            )}
        </>
    )
}
