import * as React from 'react'
import { Box } from 'src/components/utility'
import { CollectionVariant } from './collection.variant'
import { FileVariant } from './file.variant'
import { ProductVariant } from './product.variant'
import { UserVariant } from './user.variant'
import { TeamVariant } from './team.variant'
import { TodoVariant } from './todo.variant'
import type {
    ConditionalType,
    TSearchedCollaborators,
    TSearchedCollection,
    TSearchedTeams,
    TSearchedTodos,
} from '../@types/search'

interface ICardVariantsProps {
    type?: 'variant' | 'original'
    variant: 'file' | 'collection' | 'product' | 'user' | 'team' | 'todo'
    onCardClick?: () => void
    entity:
        | TSearchedTeams
        | TSearchedCollaborators
        | TSearchedTeams
        | TSearchedCollection
        | FileObject
        | TSearchedTodos
}

export default function CardVariants(props: ICardVariantsProps) {
    const { onCardClick, type = 'original' } = props
    switch (props.variant) {
        case 'file':
            return (
                <FileVariant
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        case 'collection':
            return (
                <CollectionVariant
                    onCardClick={onCardClick}
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        case 'product':
            return (
                <ProductVariant
                    type={type}
                    onCardClick={onCardClick}
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        case 'user':
            return (
                <UserVariant
                    onCardClick={onCardClick}
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        case 'team':
            return (
                <TeamVariant
                    onCardClick={onCardClick}
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        case 'todo':
            return (
                <TodoVariant
                    onCardClick={onCardClick}
                    entity={
                        props.entity as ConditionalType<typeof props.variant>
                    }
                />
            )
        default:
            return <Box>Invalid Variant</Box>
    }
}
