import styled from 'styled-components'
import {
	flexbox,
	space,
	layout,
	color,
	border,
	typography
} from 'styled-system'

const TextArea = styled.textarea<InputTypes>`
    ${flexbox}
    ${space}
    ${layout}
    ${color}
    ${border}
    ${typography}
    box-sizing: border-box;
    line-height: 1.5;
    &::disabled {
        background-color: #fff;
    }
    &{
        resize:none;
    }
    & {
        outline: transparent;
    }
`

export default TextArea
