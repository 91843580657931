import * as React from 'react'
import { Text } from 'src/components/utility'
import { useUser } from 'src/helpers'

export interface IDashboardHeaderProps {}

export default function DashboardHeader(props: IDashboardHeaderProps) {
    const { name } = useUser()
    return <Text fontSize={'24px'}>Hello {name}👋</Text>
}
