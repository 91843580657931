import * as React from 'react'
import { DivisionLine, Modal, StackedImage } from 'src/components'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Slider } from 'src/components/slider/slider'
import { Box, Button, Text } from 'src/components/utility'
import AddCollaboratorSlider from './add-collaborator-slider'
import { debounce, getImageUrl, roles, uuid } from 'src/helpers'
import AddCollaboratorTab from './add-collaborator-tab'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { SearchMemebers } from 'src/services/graphql/query/get-users'
import {
    useAddCollaborators,
    useFetchCollaboratorByTeamId,
    useInviteUsers,
    useTeam,
} from '../../hooks'
import { CrossSm, DropDownArrow, TeamIco } from 'src/components/svg-icons'
import TagInput, { TagComponent } from './tag-input'
import { toast } from 'react-toastify'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { isEmailValid } from '../../function'
import { TeamDropdownSelector } from 'src/components-v2/team-dropdown-selector/team-dropdown-selector'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { CheckInvitationExist } from 'src/services/graphql/query/get-invitations'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useTeam as useTeamDropdownSelector } from 'src/components-v2/team-dropdown-selector/hooks/use-team'

export interface IAddCollaboratorModalProps {
    onRoleUpdate?: () => void
    allowTeamChange?: boolean
    team?: {
        id: number
        name: string
        team_members: ITeams['team_members']
    }
    teamId?: string
    onCollaboratorsAdded: (members: ITeamMembers[]) => void
    isModal: boolean
    onTeamAdded?: (id: number) => void
    teamMembers: ITeamMembers[]
    onClose: (isOpen: boolean) => void
    inviteExternalUsers?: boolean
}

function AddCollaborator(props: IAddCollaboratorModalProps, ref: any) {
    const {
        team: fetchedTeam,
        teamMembers: collaborators,
        allowTeamChange = true,
    } = props
    const { fetchTeammembers, membersById } = useFetchCollaboratorByTeamId()
    const [activeTab, setActiveTab] = React.useState({
        tab: 'all',
    })
    const [teamMembers, setTeamMembers] = React.useState<ITeamMembers[]>(
        collaborators || []
    )
    const [timeOutRef, setTimeoutRef] = React.useState<
        ReturnType<typeof setTimeout> | undefined
    >()
    const { createTeam } = useTeamDropdownSelector()
    const searchFn = debounce(handleOnQuery, 300, setTimeoutRef, timeOutRef)
    const [dropdownDisabled, setDropdownDisabled] = React.useState(true)
    const [getInvitation, invitationExists] = useLazyQuery<{
        invitations: Array<{ email: string }>
        users: Array<{ email: string }>
    }>(CheckInvitationExist)

    const [activeRole, setActiveRole] = React.useState('Admin')
    const { inviteUser } = useInviteUsers()
    const [query, setQuery] = React.useState('')
    const [tags, setTags] = React.useState<ISuggestion[]>([])
    const [newUsers, setNewUsers] = React.useState<{ email: string }[]>([])
    const { addCollaborator } = useAddCollaborators()
    const [teamId, setTeamId] = React.useState(props.teamId)
    const { fetchTeam, team: teamData } = useTeam()
    const [team, setTeam] = React.useState<typeof teamData>(teamData)
    const client = useApolloClient()
    const tagInputRef = React.useRef<{
        setQuery: (val: string) => void
    }>()
    const teamSelectorDropdownRef = React.useRef<{
        teamInput: string
    }>()
    const [isTeamDropdownEnabled, setTeamDropdownEnabled] =
        React.useState(false)
    const teamDropdownRef = useClickedOutside(() =>
        setTeamDropdownEnabled(false)
    )

    // if (!props.isModal) return <></>
    React.useEffect(() => {
        if (!fetchedTeam || !fetchedTeam.id) return () => {}
        fetchTeammembers(String(fetchedTeam?.id))
    }, [])

    React.useEffect(() => {
        setTeam(teamData)
    }, [teamData])

    React.useEffect(() => {
        if (!teamId && teamId === '') return
        if (teamId !== undefined && teamId !== '') {
            fetchTeam(teamId)
        }
    }, [teamId])

    const [searchMembers, members] = useLazyQuery<{
        organisation_members: {
            id: string
            user_id: string
            user: {
                name: string
                email: string
                other_data: {
                    profile_pic: string
                    thumbnail: string
                }
                organisation_members: {
                    role: string
                }[]
            }
        }[]
    }>(SearchMemebers)

    React.useEffect(() => {
        if (team && teamId) {
            fetchTeam(String(teamId))
        }
        if (fetchedTeam) {
            setTeamMembers(collaborators)
            setTeamId(String(fetchedTeam.id))
        }
        if (membersById.length > 0) {
            setTeamMembers(membersById)
        }
    }, [collaborators, membersById, teamId, fetchedTeam])

    function handleOnQuery(query: string) {
        if (query.length <= 0) return
        searchMembers({
            variables: {
                query: `%${query}%`,
            },
        })
    }

    async function handleOnAddCollaborator() {
        const filteredTags = tags.filter((tag) => 'email' in tag) as {
            email: string
            role: string
            id: string
            invited: boolean
        }[]
        const collaborators = tags.filter((tag) => 'user' in tag)
        if (
            !team &&
            teamId &&
            teamSelectorDropdownRef.current &&
            teamSelectorDropdownRef.current?.teamInput.length > 0
        ) {
            const teamId = await createTeam(
                teamSelectorDropdownRef.current?.teamInput
            )
            if (teamId) {
                addCollaborator(collaborators, teamId)
            }
            if (filteredTags.length > 0) {
                await inviteUser(
                    filteredTags.map((tag) => ({
                        email: tag.email,
                        role: tag.role.toLocaleLowerCase(),
                    })),
                    String(teamId)
                )
            }
            props.onTeamAdded && props.onTeamAdded(Number(teamId))
            fetchTeammembers(String(teamId))
            setTags([])
            return
        }
        if (!team) return
        if (
            !allowTeamChange &&
            collaborators.length <= 0 &&
            filteredTags.length <= 0
        ) {
            return toast('Please add collaborators to send invite.', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (collaborators.length > 0) {
            await addCollaborator(collaborators, String(team.id), [
                'getTeamDetailsById',
                'getProduct',
                'fetchCollectionById',
            ])
        }
        if (filteredTags.length > 0) {
            await inviteUser(
                filteredTags.map((tag) => ({
                    email: tag.email,
                    role: tag.role.toLocaleLowerCase(),
                })),
                String(team.id)
            )
        }
        props.onTeamAdded && props.onTeamAdded(Number(team.id))
        fetchTeammembers(String(team.id))
        setTags([])
    }

    React.useEffect(() => {
        if (membersById.length > 0) {
            props.onCollaboratorsAdded(membersById)
        }
    }, [membersById])

    function onAddHandler() {
        if (query.length <= 0) return

        const user = members.data?.organisation_members.find(
            (member) => member.user.email
        )
        if (user) {
            setTags([...tags, user])
        } else {
            if (isEmailValid(query)) {
                getInvitation({
                    variables: {
                        email: query.toLowerCase(),
                    },
                    onCompleted: (data) => {
                        if (data.invitations.length > 0) {
                            toast('User already invited to the organization ', {
                                className: 'custom-toaster toaster-error',
                            })
                        } else {
                            setTags([
                                ...tags,
                                {
                                    email: query,
                                    role: activeRole,
                                    id: uuid(),
                                    invited: true,
                                },
                            ])
                            setQuery('')
                        }
                    },
                })
            }
        }
        setQuery('')
        if (tagInputRef.current) tagInputRef.current.setQuery('')
    }
    React.useEffect(() => {
        if (isEmailValid(query)) {
            if (
                members.data?.organisation_members.find(
                    (member) => member.user.email === query
                )
            ) {
                setDropdownDisabled(true)
            } else {
                setDropdownDisabled(false)
            }
        } else {
            setDropdownDisabled(true)
        }
    }, [query])

    return (
        <Modal
            onClick={() => handleOnAddCollaborator()}
            title={
                fetchedTeam
                    ? `Invite collaborators to ${fetchedTeam.name}`
                    : `Add Team & Invite Collaborators`
            }
            isModal={props.isModal}
            onClose={() => {
                setTags([])
                if (tagInputRef.current) tagInputRef.current.setQuery('')
                isTeamDropdownEnabled && setTeam(undefined)
                client.cache.evict({
                    id: 'getTeamDetailsById',
                })
                props.onClose(false)
            }}
            disableActions={false}
            primaryButtonTitle="Add Team & Invite"
            secondaryButtonTitle="Cancel"
            bg="white"
            width="600px"
            maxWidth="600px"
        >
            <Box px="16px" py="16px" width="100%" alignItems="center">
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    <TeamIco color="#022143" />
                    <Text color="primary" my="0px">
                        Team
                    </Text>
                </Box>
                <Box
                    bg="#E8ECEF50"
                    display="flex"
                    py={'4px'}
                    gridColumnGap="8px"
                    px="8px"
                    width="100%"
                    alignItems="center"
                    mt="12px"
                    height="32px"
                    position="relative"
                    ref={teamDropdownRef}
                    onClick={() => {
                        allowTeamChange && setTeamDropdownEnabled(true)
                    }}
                >
                    {team && !isTeamDropdownEnabled && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Box
                                display="flex"
                                gridColumnGap="8px"
                                position="relative"
                                alignItems="center"
                            >
                                <StackedImage
                                    width="max-content"
                                    size="24px"
                                    spacing={15}
                                    list={team.team_members.map((member) => ({
                                        name: member?.user?.name || '',
                                        src: getImageUrl(
                                            member.user?.other_data?.profile_pic
                                        ),
                                    }))}
                                />
                                <Text my="0px">{team.name}</Text>
                            </Box>
                            {allowTeamChange && <DropDownArrow />}
                        </Box>
                    )}
                    {(isTeamDropdownEnabled || !team) && (
                        <TeamDropdownSelector
                            ref={teamSelectorDropdownRef}
                            searchInDropdown={false}
                            onClose={() => {}}
                            onSelect={(teamId: number) => {
                                if (teamId) {
                                    setTeamId(String(teamId))
                                    setTeamDropdownEnabled(false)
                                    fetchTeam(String(teamId))
                                }
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Box
                borderTop="solid"
                borderColor="secondaryLighterBlue"
                borderWidth={1}
                bg="backgroundColor"
                width="100%"
                px="16px"
                py="24px"
            >
                <Box width="100%">
                    <Text my="0px" mb="8px">
                        Add Collaborators
                    </Text>
                    <Box alignItems="center" display="flex" gridColumnGap="8px">
                        <Box
                            display="flex"
                            position="relative"
                            bg="#F2F4F660"
                            width="100%"
                            justifyContent="space-between"
                            gridRowGap="4px"
                            border="solid"
                            borderWidth={1}
                            borderColor="borderColor"
                        >
                            {/* <Input
                                px="16px"
                                py="8px"
                                bg="transparent"
                                border="none"
                                placeholder="Enter Email Addresses"
                                fontSize="14px"
                            ></Input> */}
                            <TagInput
                                ref={tagInputRef}
                                showTags={false}
                                placeholder="Add Collaborators (Name or Email) "
                                setNewUsers={(user) =>
                                    setNewUsers([...newUsers, user])
                                }
                                tags={tags}
                                setTags={(newTags, tag) => {
                                    if (!tags.includes(tag)) {
                                        if (
                                            tag &&
                                            'user' in tag &&
                                            teamMembers.find(
                                                (member) =>
                                                    member.user.email ===
                                                    tag.user.email
                                            )
                                        ) {
                                            return toast(
                                                'User already exists',
                                                {
                                                    className:
                                                        'custom-toaster toast-error',
                                                }
                                            )
                                        } else {
                                            setQuery('')
                                            setTags(newTags)
                                        }
                                    }
                                }}
                                suggestions={
                                    members.data?.organisation_members || []
                                }
                                onQuery={(val) => {
                                    setQuery(val)
                                    searchFn(val)
                                }}
                                onDelete={(val) => {
                                    setTags(
                                        tags.filter(
                                            (tag) =>
                                                String(tag.id) !== String(val)
                                        )
                                    )
                                }}
                            />

                            <CustomDropdown
                                disabled={dropdownDisabled}
                                height="100%"
                                label=""
                                highlightedTextFontSize="14px"
                                selectedText={activeRole}
                                items={['Admin', 'Manager', 'Designer']}
                                onItemClicked={(val) => setActiveRole(val)}
                            />
                        </Box>
                        <ColoredButton
                            variant="primary"
                            disabled={query.length <= 0}
                            onClick={() => onAddHandler()}
                        >
                            Add
                        </ColoredButton>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    maxWidth="600px"
                    gridRowGap="8px"
                    maxHeight={'100px'}
                    overflowY="auto"
                    flexWrap="wrap"
                    mt="24px"
                    mb="24px"
                >
                    {tags.map((tag, key) => {
                        if ('user' in tag) {
                            return (
                                <TagComponent
                                    onDelete={() => {
                                        setTags(
                                            tags.filter(
                                                (tagItem) =>
                                                    String(tagItem.id) !==
                                                    String(tag.id)
                                            )
                                        )
                                    }}
                                    suggestion={tag}
                                    key={key}
                                />
                            )
                        } else {
                            return (
                                <Box display="inline-block" mr={'8px'}>
                                    <Box
                                        px="8px"
                                        width="max-content"
                                        borderRadius="20px"
                                        py="4px"
                                        display="flex"
                                        fontFamily="Rubik"
                                        fontSize="12px"
                                        color="textTags"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        bg={'warning'}
                                    >
                                        {tag.email}({tag.role})
                                        <Button
                                            onClick={() =>
                                                setTags(
                                                    tags.filter(
                                                        (tagItem) =>
                                                            String(
                                                                tagItem.id
                                                            ) !== String(tag.id)
                                                    )
                                                )
                                            }
                                            bg="transparent"
                                            display="flex"
                                            type="button"
                                            justifyContent="center"
                                            alignItems="center"
                                            border="none"
                                        >
                                            <CrossSm />
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }
                    })}
                </Box>
                <DivisionLine color="gray250" />
                <Box mt="24px">
                    <Slider
                        element={
                            <AddCollaboratorSlider
                                tabList={[
                                    {
                                        name: 'All',
                                        value: 'all',
                                        noOfItems:
                                            team?.team_members.length || 0,
                                    },
                                    {
                                        name: 'Designer',
                                        value: 'designer',
                                        noOfItems:
                                            team?.team_members.filter(
                                                (member) =>
                                                    member.user
                                                        ?.organisation_members[0]
                                                        ?.role ===
                                                    roles.designer
                                            ).length || 0,
                                    },
                                    {
                                        name: 'Manager',
                                        value: 'manager',
                                        noOfItems:
                                            team?.team_members.filter(
                                                (member) =>
                                                    member.user
                                                        ?.organisation_members[0]
                                                        ?.role === roles.manager
                                            ).length || 0,
                                    },
                                    {
                                        name: 'Admin',
                                        value: 'admin',
                                        noOfItems:
                                            team?.team_members.filter(
                                                (member) =>
                                                    member.user
                                                        ?.organisation_members[0]
                                                        ?.role === roles.admin
                                            ).length || 0,
                                    },
                                ]}
                                currentTab={activeTab.tab}
                                onTabChange={(val) => setActiveTab(val)}
                            />
                        }
                    />
                </Box>
                <Box
                    minHeight="200px"
                    height={window.innerHeight < 800 ? '100px' : '300px'}
                    overflowX="auto"
                >
                    {team?.team_members && (
                        <AddCollaboratorTab
                            onRefetch={() => {
                                if (props.onRoleUpdate) {
                                    props.onRoleUpdate()
                                }
                            }}
                            activeTab={activeTab.tab}
                            teamId={team.id}
                            collaborator={team?.team_members.map((member) => ({
                                email: member.user?.email,
                                id: String(member.user?.id),
                                name: convertIntoElipses(member.user?.name, 20),
                                other_data: member.user?.other_data,
                                role:
                                    member.user?.organisation_members[0]
                                        ?.role || '',
                            }))}
                        />
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

// Create a forward ref for this component with default export

export const AddCollaboratorModal = React.forwardRef(AddCollaborator)
