import { gql } from '@apollo/client'

export const GetTeamDataByUserID = gql`
    query get_teams {
        teams {
            name
            id
            collections {
                due_date
                id
                name
            }
            products {
                due_date
                id
                name
                todos {
                    id
                    name
                    status
                }
            }
            team_members {
                id
                user {
                    email
                    name
                    id
                    files(where: { type: { _eq: "profile_pic" } }) {
                        path
                    }
                    assigned_todos {
                        name
                        status
                    }
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`

export const GetCommentsByUserID = gql`
    query getCommentsByUserId($user_id: bigint!) {
        comments(
            where: { user_id: { _eq: $user_id } }
            order_by: { id: desc }
        ) {
            comment
            entity
            entity_id
            created_at
            id
            updated_at
            user_id
            entity_info
            user {
                email
                name
                id
            }
        }
    }
`

export const GetCollectionsByUserID = gql`
    query getCollectionsByUserId(
        $user_id: bigint!
        $status: collection_status_enum = complete
        $created_at: order_by
        $due_date: order_by
        $name: order_by
        $limit: Int!
    ) {
        collections(
            order_by: {
                name: $name
                due_date: $due_date
                created_at: $created_at
            }
            limit: $limit
            where: {
                status: { _eq: $status }
                _or: [
                    { manager_id: { _eq: $user_id } }
                    { created_by: { _eq: $user_id } }
                    { team: { team_members: { user_id: { _eq: $user_id } } } }
                ]
            }
        ) {
            name
            id
            tags
            due_date
            status
            manager {
                other_data
                name
                email
                id
            }
            products_aggregate {
                aggregate {
                    count
                }
            }
            inProgressProducts: products_aggregate(
                where: {
                    status: {
                        _in: [inProgress, inReview, requestedForResubmission]
                    }
                }
            ) {
                aggregate {
                    count
                }
            }
            totalCompleted: products_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
            completed: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
            delayedProducts: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: true } }
            ) {
                aggregate {
                    count
                }
            }
            team {
                id
                name
                team_members {
                    id
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
            products_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`

export const GetManagersList = gql`
    query getManagersList($limit: Int! = 10, $organisation_id: bigint!) {
        organisation_members(
            where: {
                organisation_id: { _eq: $organisation_id }
                role: { _eq: manager }
                user: { id: { _is_null: false } }
                deleted_at: { _is_null: true }
            }
            order_by: { user_id: desc }
            limit: $limit
        ) {
            role
            organisation_id
            deleted_at
            user {
                email
                name
                id
                other_data
                onGoingCollections: manage_collections_aggregate(
                    where: { status: { _eq: inProgress } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
        organisation_members_aggregate(
            where: {
                organisation_id: { _eq: $organisation_id }
                role: { _eq: manager }
                user: { id: { _is_null: false } }
                deleted_at: { _is_null: true }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetDueCollections = gql`
    query GetDueCollections($due: timestamptz!, $limit: Int! = 20) {
        collections(
            order_by: { due_date: asc }
            limit: $limit
            where: { due_date: { _gte: $due }, status: { _neq: complete } }
        ) {
            due_date
            id
            name
            status
            is_delayed
            development_cycle
            products_aggregate {
                aggregate {
                    count
                }
            }
            inProgressProducts: products_aggregate(
                where: {
                    status: {
                        _in: [inProgress, inReview, requestedForResubmission]
                    }
                }
            ) {
                aggregate {
                    count
                }
            }
            completedProducts: products_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
            completed: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
            delayedProducts: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: true } }
            ) {
                aggregate {
                    count
                }
            }
            team {
                id
                name
                team_members {
                    id
                    user {
                        id
                        name
                        other_data
                    }
                }
            }
            manager {
                id
                name
                other_data
                email
            }
        }
    }
`

/// Todos Queries
export const GetTodosData = gql`
    query getTodosData(
        $status: [status_enum!] = [new]
        $created_at: order_by
        $updated_at: order_by
        $due_date: order_by
        $name: order_by
        $user_id: bigint!
        $is_delayed: [Boolean!] = [false, true]
        $product_id_one: Boolean!
        $product_id_two: Boolean!
        $created_by: bigint
        $searchStr: String!
        $organisation_id: bigint
    ) {
        todos(
            where: {
                name: { _ilike: $searchStr }
                status: { _in: $status }
                assignee: { id: { _eq: $user_id } }
                product_variant_id: { _is_null: false }
                is_delayed: { _in: $is_delayed }
                _or: [
                    { product_id: { _is_null: $product_id_one } }
                    { product_id: { _is_null: $product_id_two } }
                ]
            }
            order_by: {
                name: $name
                due_date: $due_date
                created_at: $created_at
                updated_at: $updated_at
            }
        ) {
            created_at
            created_by
            id
            name
            user_id
            tags
            product_id
            other_data
            product: product_variant {
                name
                id
                status
                collection {
                    name
                    id
                    is_delayed
                    manager_id
                    due_date
                    manager {
                        name
                        email
                        id
                        other_data
                    }
                }
                other_data
            }
            status
            due_date
            description
            duration
            is_delayed
            assignee {
                email
                name
                id
                other_data
            }
        }
        todos_aggregate(
            where: {
                name: { _ilike: $searchStr }
                product_variant_id: { _is_null: false }
                status: { _in: $status }
                assignee: { id: { _eq: $user_id } }
                is_delayed: { _in: $is_delayed }
                _or: [
                    { product_id: { _is_null: $product_id_one } }
                    { product_id: { _is_null: $product_id_two } }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetReviewTodos = gql`
    query getSpecialTodosData(
        $status: [status_enum!] = [inReview]
        $user_id: bigint!
        $product_id_one: Boolean!
        $product_id_two: Boolean!
        $created_at: order_by
        $updated_at: order_by
        $due_date: order_by
        $name: order_by
        $searchStr: String!
        $organisation_id: bigint
    ) {
        todos(
            where: {
                name: { _ilike: $searchStr }
                product_variant_id: { _is_null: false }
                status: { _in: $status }
                _or: [
                    {
                        product_id: { _is_null: $product_id_one }
                        product: {
                            collection: { manager_id: { _eq: $user_id } }
                        }
                    }
                    {
                        product_id: { _is_null: $product_id_two }
                        created_by: { _eq: $user_id }
                    }
                ]
            }
            order_by: {
                name: $name
                due_date: $due_date
                created_at: $created_at
                updated_at: $updated_at
            }
        ) {
            created_at
            updated_at
            id
            name
            user_id
            tags
            status
            due_date
            description
            duration
            is_delayed
            product_id
            product {
                name
                id
                collection {
                    name
                    id
                }
            }
            assignee {
                email
                name
                id
            }
        }
        todos_aggregate(
            where: {
                name: { _ilike: $searchStr }
                product_variant_id: { _is_null: false }
                status: { _in: $status }
                _or: [
                    {
                        product_id: { _is_null: $product_id_one }
                        product: {
                            collection: { manager_id: { _eq: $user_id } }
                        }
                    }
                    {
                        product_id: { _is_null: $product_id_two }
                        created_by: { _eq: $user_id }
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetReviewProducts = gql`
    query fetchToBeReviewedProducts(
        $status: [status_enum!] = [inReview]
        $user_id: bigint!
        $created_at: order_by
        $updated_at: order_by
        $due_date: order_by
        $name: order_by
        $searchStr: String!
    ) {
        products(
            where: {
                name: { _ilike: $searchStr }
                status: { _in: $status }
                approver_id: { _eq: $user_id }
            }
            order_by: {
                name: $name
                due_date: $due_date
                created_at: $created_at
                updated_at: $updated_at
            }
            limit: $limit
            offset: $offset
        ) {
            name
            id
            status
            tags
            is_delayed
            due_date
            description
            approver_id
            approver {
                email
                name
                created_at
                id
            }
            collection {
                name
                id
            }
        }
        products_aggregate(
            where: {
                name: { _ilike: $searchStr }
                status: { _in: $status }
                approver_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetRequestRevisionProducts = gql`
    query fetchRequestRevisionProducts(
        $status: [status_enum!]!
        $user_id: bigint!
        $created_at: order_by
        $updated_at: order_by
        $due_date: order_by
        $name: order_by
    ) {
        products(
            where: {
                status: { _in: $status }
                _or: [
                    { approver_id: { _eq: $user_id } }
                    { collection: { manager_id: { _eq: $user_id } } }
                ]
            }
            order_by: {
                name: $name
                due_date: $due_date
                created_at: $created_at
                updated_at: $updated_at
            }
            limit: $limit
            offset: $offset
        ) {
            name
            id
            status
            tags
            is_delayed
            due_date
            description
            approver_id
            approver {
                email
                name
                created_at
                id
            }
            collection {
                name
                id
            }
        }
        products_aggregate(
            where: {
                status: { _in: $status }
                _or: [
                    { approver_id: { _eq: $user_id } }
                    { collection: { manager_id: { _eq: $user_id } } }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetYourTodosCount = gql`
    query getYourTodosCount($user_id: bigint!) {
        todos_aggregate(where: { user_id: { _eq: $user_id } }) {
            aggregate {
                count
            }
        }
    }
`

export const GetCollectionsCounts = gql`
    query getCollectionsCounts {
        ongoing: collections_aggregate(where: { status: { _eq: inProgress } }) {
            aggregate {
                count
            }
        }
        ontime: collections_aggregate(
            where: { status: { _eq: inProgress }, is_delayed: { _eq: false } }
        ) {
            aggregate {
                count
            }
        }
        delayed: collections_aggregate(
            where: { is_delayed: { _eq: true }, status: { _eq: inProgress } }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GetProductsCounts = gql`
    query getProductsCounts {
        ongoing: products_aggregate(
            where: {
                status: {
                    _in: [inProgress, inReview, requestedForResubmission]
                }
            }
        ) {
            aggregate {
                count
            }
        }
        ontime: products_aggregate(
            where: {
                status: {
                    _in: [inProgress, inReview, requestedForResubmission]
                }
                is_delayed: { _eq: false }
            }
        ) {
            aggregate {
                count
            }
        }
        delayed: products_aggregate(
            where: {
                is_delayed: { _eq: true }
                status: {
                    _in: [inProgress, inReview, requestedForResubmission]
                }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const FetchRecentActivitiesQuery = gql`
    query dashboardActivity(
        $user_created_at: timestamptz!
        $id: bigint
        $limit: Int! = 10
        $offset: Int = 10
    ) {
        activity(
            order_by: { created_at: desc }
            limit: $limit
            where: {
                organisation_id: { _eq: $id }
                created_at: { _gte: $user_created_at }
            }
            offset: $offset
        ) {
            action
            created_at
            display_string
            entity_id
            entity_name
            id
            user_id
            user {
                other_data
                name
                id
                email
            }
        }
    }
`
