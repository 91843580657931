import * as React from 'react'
import Artwork from './artwork/artwork'
import Fabric from './fabric/fabric'
import './panel.styles.css'
import Graphic from './graphic/graphic'
import { useConfiguratorContext } from '../configurator.context'
import { Box } from 'src/components/utility'
import RadioInput from 'src/components-v2/radio-button/radio-button'
import ColorAdvanced from './color/advanced'

export interface IPanelFunctionsProps {
    type: 'artwork' | 'fabric' | 'color' | 'graphic'
}

export default function PanelFunctions(props: IPanelFunctionsProps) {
    const { colorSelectionMode, setColorSelectionMode } =
        useConfiguratorContext()
    const { type } = props
    switch (type) {
        case 'artwork':
            return <Artwork />
        case 'color':
            return (
                <Box>
                    {false && (
                        <Box
                            px="12px"
                            pb="8px"
                            display="flex"
                            gridColumnGap="8px"
                        >
                            <RadioInput
                                isChecked={colorSelectionMode === 'entireMesh'}
                                onChange={() =>
                                    setColorSelectionMode('entireMesh')
                                }
                                lable="Entire Mesh"
                            />
                            <RadioInput
                                isChecked={
                                    colorSelectionMode === 'specificArea'
                                }
                                onChange={() =>
                                    setColorSelectionMode('specificArea')
                                }
                                lable="Specific Area"
                            />
                        </Box>
                    )}
                    {/* <DivisionLine /> */}
                    <Box mt="4px">
                        <ColorAdvanced />
                    </Box>
                </Box>
            )
        case 'fabric':
            return <Fabric />
        case 'graphic':
            return <Graphic />
        default:
            return <></>
    }
}
