import * as React from 'react'
import { EntityDetails } from 'src/screens/dashboard/sub-screens/comments/cards/entityInfo'

export interface IEntityInfoListProps {
    entityInfo: TNotifcationEntityInfo
}

export default function EntityInfoList(props: IEntityInfoListProps) {
    const { entityInfo } = props
    return (
        <>
            <EntityDetails
                iconColor="#027AC3"
                textColor="links"
                entityInfo={{
                    entityId: entityInfo?.collection?.id || '',
                    entityName: 'COLLECTIONS',
                    name: entityInfo?.collection?.name || '',
                }}
            />
            <EntityDetails
                iconColor="#027AC3"
                textColor="links"
                entityInfo={{
                    entityId: entityInfo?.product_variant?.id || '',
                    entityName: 'PRODUCT_VARIANT',
                    name: entityInfo?.product_variant?.name || '',
                }}
            />
            <EntityDetails
                iconColor="#027AC3"
                textColor="links"
                entityInfo={{
                    entityId: entityInfo?.todo?.id || '',
                    entityName: 'TODOS',
                    name: entityInfo?.todo?.name || '',
                }}
            />
            <EntityDetails
                iconColor="#027AC3"
                textColor="links"
                entityInfo={{
                    entityId: entityInfo?.sub_todo_section?.id || '',
                    entityName: 'SUB_TODO_SECTIONS',
                    name: entityInfo?.sub_todo_section?.name || '',
                }}
            />
        </>
    )
}
