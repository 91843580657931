export function CollectionIco({
    size,
    color,
}: {
    size?: number
    color?: string
}) {
    return (
        <svg
            width={size ? size : 14}
            height={size ? size : 16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.09375 1.41176H11.5643C11.7593 1.41176 11.9173 1.56978 11.9173 1.76471V13.2353C11.9173 13.4302 11.7593 13.5882 11.5643 13.5882H1.85846C1.66353 13.5882 1.50551 13.4302 1.50551 13.2353V1.76471C1.50551 1.56978 1.66353 1.41176 1.85846 1.41176H3.88787V6.61764C3.88787 6.86745 4.03835 7.09267 4.26915 7.18827C4.49995 7.28387 4.76561 7.23102 4.94226 7.05438L6.31606 5.68058L8.09026 7.09994C8.27566 7.24826 8.52967 7.27717 8.74366 7.17432C8.95765 7.07147 9.09375 6.85506 9.09375 6.61764V1.41176ZM0.09375 1.76471C0.09375 0.790086 0.883836 0 1.85846 0H11.5643C12.539 0 13.329 0.790086 13.329 1.76471V13.2353C13.329 14.2099 12.539 15 11.5643 15H1.85846C0.883836 15 0.09375 14.2099 0.09375 13.2353V1.76471ZM5.12316 5.1265V1.49999H7.85846V5.33254L6.65606 4.37063C6.4103 4.17402 6.05602 4.19365 5.83348 4.41619L5.12316 5.1265Z"
                fill={color ? color : '#778CA2'}
            />
        </svg>
    )
}

export function CollectionIcoSm() {
    return (
        <svg
            width="9"
            height="10"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.15004 1.34706H7.63235C7.74931 1.34706 7.84412 1.44187 7.84412 1.55882V8.44118C7.84412 8.55813 7.74931 8.65294 7.63235 8.65294H1.80882C1.69187 8.65294 1.59706 8.55813 1.59706 8.44118V1.55882C1.59706 1.44187 1.69187 1.34706 1.80882 1.34706H3.02651V4.47055C3.02651 4.62044 3.1168 4.75557 3.25528 4.81293C3.39376 4.87029 3.55316 4.83859 3.65915 4.7326L4.48343 3.90832L5.54795 4.75993C5.65919 4.84893 5.81159 4.86628 5.93999 4.80457C6.06839 4.74286 6.15004 4.61301 6.15004 4.47055V1.34706ZM0.75 1.55882C0.75 0.974051 1.22405 0.5 1.80882 0.5H7.63235C8.21712 0.5 8.69118 0.974052 8.69118 1.55882V8.44118C8.69118 9.02595 8.21712 9.5 7.63235 9.5H1.80882C1.22405 9.5 0.75 9.02595 0.75 8.44118V1.55882ZM3.76769 3.57587V1.39997H5.40887V3.6995L4.68743 3.12235C4.53998 3.00439 4.3274 3.01616 4.19388 3.14968L3.76769 3.57587Z"
                fill="#022143"
            />
        </svg>
    )
}
