import type { Mesh } from 'three'
import { Vector2 } from 'three'
import { useConfiguratorContext } from '../configurator.context'
import type { MeshCustomMaterial } from '../panel-functions/material/custom-material'
import { fabricPresets } from '../helper/presets'
import { createMaterial } from '../helper/create-material'

export function useFabric() {
    const { highlights, setSelectedFabric, selectedFabric } =
        useConfiguratorContext()

    function updateConfig(
        code: TMapsKeys | 'uniform-scale',
        mapConfig: Config
    ) {
        const mesh = highlights[0].mesh as Mesh
        const material = mesh.material as MeshCustomMaterial
        if (highlights[0].mesh && selectedFabric) {
            if (code === 'uniform-scale') {
                setSelectedFabric(
                    (selectedFabric) =>
                        selectedFabric && {
                            ...selectedFabric,
                            maps: selectedFabric.maps.map((map) => {
                                const materialMap = material[map.code]
                                if (map.code && materialMap) {
                                    materialMap.offset = new Vector2(
                                        mapConfig?.offset?.u || 0,
                                        mapConfig?.offset?.v || 0
                                    )
                                    materialMap.repeat = new Vector2(
                                        mapConfig?.scale?.u || 0,
                                        mapConfig?.scale?.v || 0
                                    )
                                    materialMap.rotation = mapConfig.rotation
                                    return {
                                        ...map,
                                        config: {
                                            ...map.config,
                                            scale: mapConfig.scale,
                                            offset: mapConfig.offset,
                                            rotation: mapConfig.rotation,
                                        },
                                    }
                                }
                                return map
                            }),
                        }
                )
            } else {
                const materialMap = material[code]
                if (materialMap) {
                    materialMap.offset = new Vector2(
                        mapConfig?.offset.u || 0,
                        mapConfig?.offset?.v || 0
                    )
                    materialMap.repeat = new Vector2(
                        mapConfig?.scale.u || 0,
                        mapConfig?.scale?.v || 0
                    )
                    materialMap.rotation = mapConfig.rotation
                }
            }
        }
    }
    function uploadFabric(mapCode: TMapsKeys, file: string) {
        if (!selectedFabric) return
        const map = selectedFabric.maps.find((map) => map.code === mapCode)
        setSelectedFabric((selectedFabric) =>
            selectedFabric
                ? {
                      ...selectedFabric,
                      maps: selectedFabric.maps.map((map) =>
                          map.code === mapCode ? { ...map, image: file } : map
                      ),
                  }
                : selectedFabric
        )

        const mesh = highlights[0].mesh
        const material = mesh.material as MeshCustomMaterial

        material[mapCode] = createMaterial(file, {
            offset: map?.config.offset || {
                u: 0,
                v: 0,
            },
            scale: map?.config.scale || {
                u: 0,
                v: 0,
            },
            rotation: map?.config.rotation || 0,
        }).upscale
    }
    function deleteFabric() {
        const mesh = highlights[0].mesh as Mesh
        const material = mesh.material as MeshCustomMaterial
        fabricPresets[0].maps.map((map) => {
            material[map.code] = null
        })
    }
    return { updateConfig, deleteFabric, uploadFabric }
}
