export const FolderOutlineGrey = () => (
    <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.727273C0 0.325611 0.325611 0 0.727273 0H5.92208C6.11496 0 6.29995 0.0766231 6.43634 0.213013L8.30125 2.07792H15.2727C15.6744 2.07792 16 2.40353 16 2.80519V12.6753C16 13.077 15.6744 13.4026 15.2727 13.4026H0.727273C0.325611 13.4026 0 13.077 0 12.6753V0.727273ZM1.45455 1.45455V11.9481H14.5455V3.53247H8C7.80712 3.53247 7.62213 3.45584 7.48574 3.31945L5.62083 1.45455H1.45455Z"
            fill="#778CA2"
        />
    </svg>
)
