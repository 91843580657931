import { gql } from "@apollo/client";

export const AddTags = gql`
mutation addTags($objects: [tags_insert_input!] = objects) {
  insert_tags(objects: $objects) {
    affected_rows
    returning {
      tag
      id
    }
  }
}`