import { gql } from '@apollo/client'

export const deleteSubtodo = gql`
    mutation deleteSubtodo($id: bigint!, $deleted_at: timestamptz!) {
        update_sub_todos_by_pk(
            _set: { deleted_at: $deleted_at }
            pk_columns: { id: $id }
        ) {
            id
        }
    }
`

export const DeleteSubtodosByTodoId = gql`
    mutation DeleteSubtodosByTodoId(
        $deleted_at: timestamptz = ""
        $todoId: bigint = ""
    ) {
        update_sub_todos_many(
            updates: {
                where: { todo_id: { _eq: $todoId } }
                _set: { deleted_at: $deleted_at }
            }
        ) {
            returning {
                id
            }
        }
    }
`
