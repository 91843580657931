import { firebaseConfig as firebaseDev } from './firebase.dev'
import { firebaseConfig as firebaseStaging } from './firebase.staging'
import { firebaseConfig as firebaseProd } from './firebase.prod'

const dev = {
    API_ENDPOINT: 'https://virtu-api.app.fountane.com',
    GRAPHQL_ENDPOINT: 'https://virtu-api.app.fountane.com/v1/graphql',
    PUBLIC_URLS: 'http://localhost:3000',
    PDF_CLIENT_ID: '2c95607c2c87472abecf3ce6d479992e',
    firebase: {
        ...firebaseDev,
    },
    SOCKET_ENDPOINT: 'wss://virtu-api.app.fountane.com',
    HASURA_ACTION_URL: 'https://virtu-api.app.fountane.com',
}

const staging = {
    API_ENDPOINT: 'https://staging.virtu3d.io',
    GRAPHQL_ENDPOINT: 'https://staging.virtu3d.io/v1/graphql',
    PUBLIC_URLS: 'https://virtu-staging.web.app/',
    PDF_CLIENT_ID: '7a86e52edc6946a38a1b627a584c220f',
    firebase: {
        ...firebaseStaging,
    },
    SOCKET_ENDPOINT: 'wss://staging.virtu3d.io',
    HASURA_ACTION_URL: 'https://staging.virtu3d.io',
}

const prod = {
    API_ENDPOINT: 'https://api.virtu3d.io',
    GRAPHQL_ENDPOINT: 'https://api.virtu3d.io/v1/graphql',
    PUBLIC_URLS: 'https://virtu3d.io/login',
    PDF_CLIENT_ID: 'ff76c541e83d49fbb9ba5f1d463f93f4',
    firebase: {
        ...firebaseProd,
    },
    SOCKET_ENDPOINT: 'wss://api.virtu3d.io',
    HASURA_ACTION_URL: 'https://api.virtu3d.io',
}

const local = {
    API_ENDPOINT: 'http://localhost:3016',
    GRAPHQL_ENDPOINT: 'http://localhost:3015/v1/graphql',
    PUBLIC_URLS: 'http://localhost:3000',
    PDF_CLIENT_ID: '2c95607c2c87472abecf3ce6d479992e',
    firebase: {
        ...firebaseDev,
    },
    SOCKET_ENDPOINT: 'ws://localhost:3016',
    HASURA_ACTION_URL: 'http://localhost:3016',
}

/**
 * !!! TODO !!!
 * Change the instance to dev while merging
 */
export let CONFIG_VARS = staging

if (process.env['REACT_APP_ENVIORNMENT'] === 'production') {
    CONFIG_VARS = prod
}
if (process.env['REACT_APP_ENVIORNMENT'] === 'staging') {
    CONFIG_VARS = staging
}
if (process.env['REACT_APP_ENVIORNMENT'] === 'development') {
    // CHANGE IT BACK TO DEV WHILE MERGING INTO ANY OTHER BRANCH
    CONFIG_VARS = staging
}
if (process.env['REACT_APP_ENVIORNMENT'] === 'local') {
    CONFIG_VARS = local
}
