import type {
	createSliderSlicePayloadType,
	createSliderSliceStateType,
	activeNavTab,
	activeNavTabsString
} from './@types/slider-types'



const createSliderSlice = (set: Function) => ({
	// State
	clientWidth: '',
	clientOffset: '',
	activeTabString: '',
	individualContributors: false,
	yourTeam: true,
	collectionsNew:true,
	collectionsInProgess: false,
	collectionsCompleted: false,


	// Dispatch
	setClientWidth: (payload: createSliderSlicePayloadType) => {
		set((state: createSliderSliceStateType) => {
			return { ...state, clientWidth: payload.clientWidth }
		})
	},
	setClientOffset: (payload: createSliderSlicePayloadType) =>
		set((state: createSliderSliceStateType) => {
			return { ...state, clientOffset: payload.clientOffset }
		}),
	setActiveTab: (payload: activeNavTab) => {
		set((state: activeNavTab) => {
			return {
				...state,
				...payload
			}
		})
	},
	setActiveTabsString: (payload:activeNavTabsString) => {
		set((state:activeNavTabsString) => {
			return {
				...state,
				activeTabString: payload.activeTabString
			}
		})
	}
})

export default createSliderSlice
