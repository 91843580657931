import styled from 'styled-components'
import { color, layout, space, position, flexbox } from 'styled-system'

type Props = {
  children: React.ReactNode | null;
  bg?: string;
  color?: string;
  width?: string;
  height?: string;
  p?: string;
  px?: string;
  py?: string;
  m?: string;
  mx?: string;
  my?: string;
  position?: string;
  zIndex?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  pr?: string;
  pl?: string;
};

const StyledNav = styled.nav<Props>`
  ${color}
  ${space}
  ${layout}
  ${position}
  ${flexbox}
`

export default StyledNav
