import * as React from 'react'
import { useConfiguratorContext } from '../../configurator.context'
import FabricAdvanced from './advanced'
import FabricBasic from './basic'
import { Box } from 'src/components/utility'

export interface IFabricProps {}

export default function Fabric(props: IFabricProps) {
    const { advancedMode } = useConfiguratorContext()
    if (advancedMode) {
        return (
            <Box
                height={window.innerHeight < 750 ? '200px' : 'initial'}
                overflowY={window.innerHeight < 750 ? 'auto' : 'initial'}
            >
                <FabricAdvanced />
            </Box>
        )
    } else {
        return <FabricBasic />
    }
}
