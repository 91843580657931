import { Tooltip, tooltipClasses, TooltipProps, Zoom } from '@mui/material'
import styled, { css } from 'styled-components'
import { Button } from '../utility'

const TipButton = styled(Button)`
    ${(props) =>
        props.hover &&
        css`
            &:hover {
                background-color: #e8ecef;
            }
        `}
    display:flex;
    align-items: center;
    justify-content: center;
    ${(props) => css`
        & {
            padding: ${props.p};
        }
    `}
    border-radius:4px;
`

export const ToolTipButton = ({
    onClick,
    toolTip,
    hover = true,
    fontSize = '16px',
    placement = 'bottom',
    padding = '8px',
    children,
}: {
    onClick: () => void
    toolTip: string
    children: React.ReactNode | JSX.Element | string
    hover?: boolean
    placement?: 'bottom-start' | 'bottom'
    fontSize?: FontSizes
    padding?: FourPixelGridSystem
}) => {
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#022143 !important',
            fontFamily: 'Rubik',
            fontWeight: 400,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#022143',
        },
    }))

    // .MuiTooltip-popper div {
    //     font-family: "Rubik";
    //     background-color: #022143;
    //     margin-top: 8px !important;
    //     font-weight: 400;
    //     width: max-content;
    //     left: 0;
    // }

    // .MuiTooltip-popper * {
    //     fill: #022143;
    // }

    // .MuiTooltip-popper span::before {
    //     background-color: #022143;
    // } */

    return (
        <HtmlTooltip
            placement={placement}
            style={{ fontSize: fontSize + ' !important' }}
            TransitionComponent={Zoom}
            title={toolTip}
            arrow
        >
            <TipButton
                p={padding}
                bg="transparent"
                border="none"
                hover={hover}
                onClick={() => onClick()}
            >
                {children}
            </TipButton>
        </HtmlTooltip>
    )
}
