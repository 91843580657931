import type { Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export default function SubtodoMenuDropdown({
    active,
    setIsDropdownActive,
    onDelete,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    onDelete: () => void
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['subtodo-menu-button']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="28px"
            left="none"
            right="0px"
            width="150px"
            py={'4px'}
        >
            <Box ref={wrapperRef} width="100%" px="4px">
                <DropdownButtonWrapper value="delete_todo" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="#FF2C18"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                        onClick={() => {
                            onDelete()
                            setIsDropdownActive(false)
                        }}
                    >
                        Delete Section
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}
