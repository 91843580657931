import type { CollectionStatus } from 'src/services/graphql/query/@types/collections'
import {
    Completed,
    InProgress,
    InReview,
    New,
    RequestRevision,
    ToBeReviewed,
} from '../status'
import type { statuses } from 'src/helpers'

export const collectionStatus = (status: CollectionStatus) => {
    switch (status) {
        case 'new':
            return <New />
        case 'inProgress':
            return <InProgress />
        case 'complete':
            return <Completed />
    }
}

export const productAndtodoStatus = (
    status: keyof typeof statuses,
    isManager: boolean,
    isIndividualTodoCreator?: boolean,
    showDropdown?: boolean
) => {
    switch (status) {
        case 'new':
            return <New showDropdown={showDropdown} />
        case 'inProgress':
            return <InProgress showDropdown={showDropdown} />
        case 'inReview':
            return isManager || isIndividualTodoCreator ? (
                <ToBeReviewed showDropdown={showDropdown} />
            ) : (
                <InReview showDropdown={showDropdown} />
            )
        case 'complete':
            return <Completed showDropdown={showDropdown} />
        case 'requestedForResubmission':
            return <RequestRevision showDropdown={showDropdown} />
    }
}

export function getDateMonth(date: string | Date) {
    if (!date) {
        throw new Error('Invalid Date')
    } else if (typeof date === 'string') {
        const dateObject = new Date(date)
        const _date = dateObject.getDate()
        const month = dateObject.getMonth()
        return `${_date}/${month}`
    } else {
        return `${date.getDate()}/${date.getMonth()}`
    }
}
