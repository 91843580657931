import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useStore from 'src/store/hooks/useStore'
import { ProductDock } from './product-dock'
import ProductDrawer from './product-drawer'

export function DrawerContainer({ children }: { children: ReactNode }) {
    const { showDrawerDock, setShowDrawerDock, drawerTabs, clearDrawerTabs } =
        useStore()
    const location = useLocation()
    const { showDrawer } = location.state || {}

    useEffect(() => {
        if (location.pathname.includes('login')) {
            clearDrawerTabs()
        } else if (showDrawer) setShowDrawerDock(false)
        // else if (drawerTabs.length > 0) setShowDrawerDock(true)
    }, [location])

    return (
        <>
            {children}
            <ProductDrawer />
            {showDrawerDock && <ProductDock />}
        </>
    )
}
