import React, { useEffect, useState } from 'react'
import { HexColorInput, HexColorPicker, RgbColorPicker } from 'react-colorful'
import { ArrowDown } from 'src/assets/svgs-components'
import {
    checkColorFormat,
    convertColor,
} from 'src/components/color-picker/color-picker.helper'
import { EyeDropperIco } from 'src/components/svg-icons/3d-renderer/actions'
import { Box, Button, Input, Text } from 'src/components/utility'
import useEyeDropper from 'use-eye-dropper'

export default function ColorPanel({
    initialColor,
    onColorChange,
    p = '8px',
    border = 'solid',
    fireCallbackOnFirstLoad = false,
}: {
    onColorChange?: (color: string) => void
    initialColor?: string
    p?: FourPixelGridSystem
    border?: string
    fireCallbackOnFirstLoad?: boolean
}) {
    const { open, close, isSupported } = useEyeDropper()
    const [isEyeDropperOpen, setIsEyeDropperOpen] = useState(false)
    const [switcherVal, setSwitcherVal] = useState(
        initialColor ? checkColorFormat(initialColor) : 'Hex'
    )

    const initialColorValue = initialColor
        ? convertColor(initialColor)
        : { hex: '#FFFFFF', rgb: { r: 255, g: 255, b: 255 } }

    const [hexColor, setHexColor] = useState(initialColorValue.hex)
    const [rgbaColor, setRgbaColor] = useState(initialColorValue.rgb)

    const [isColorPointer, setIsColorPointer] = useState(false)

    const alternateColor = switcherVal === 'Hex' ? 'RGB' : 'Hex'
    const alternativeColorValue =
        switcherVal === 'Hex'
            ? `${convertColor(hexColor).rgb.r},${
                  convertColor(hexColor).rgb.g
              },${convertColor(hexColor).rgb.b}`
            : convertColor(`rgb(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b})`)
                  .hex

    const pickColor = () => {
        setIsEyeDropperOpen(true)
        open()
            .then((color) => {
                console.log(color)
                onColorChange && onColorChange(color.sRGBHex)
                setHexColor(color.sRGBHex)
                setRgbaColor(convertColor(color.sRGBHex).rgb)
                setIsEyeDropperOpen(false)
            })
            .catch((e) => {})
    }

    useEffect(() => {
        if (fireCallbackOnFirstLoad) {
            onColorChange && onColorChange(hexColor)
        }
    }, [])

    return (
        <Box position="relative">
            <Box id="picker" position="relative">
                <Box
                    id="color-picker-box"
                    aria-orientation="horizontal"
                    zIndex={15}
                    bg="white"
                    p={p}
                    borderRadius="8px"
                    border={border}
                    borderWidth={1}
                    borderColor="gray250"
                >
                    {switcherVal === 'Hex' ? (
                        <HexColorPicker
                            className="react-colorful__hexcolor_picker"
                            color={hexColor}
                            inputMode="text"
                            onChange={(hex) => {
                                setHexColor(hex)
                                onColorChange && onColorChange(hex)
                            }}
                        />
                    ) : (
                        <RgbColorPicker
                            color={rgbaColor}
                            onChange={(newCol) => {
                                setRgbaColor(newCol)
                                setIsColorPointer(true)
                                onColorChange &&
                                    onColorChange(
                                        convertColor(
                                            `rgb(${newCol.r},${newCol.g},${newCol.b})`
                                        ).hex
                                    )
                            }}
                        />
                    )}
                    <Box
                        border="1px solid #E8ECEF"
                        p={'8px'}
                        mt="12px"
                        borderRadius="4px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        id="hex-color-input-wrapper"
                    >
                        <Box>
                            <Button
                                bg="transparent"
                                border="solid"
                                onClick={pickColor}
                                borderColor={
                                    isEyeDropperOpen ? 'primary' : 'gray250'
                                }
                                borderRadius="4px"
                                borderWidth={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                p="0px"
                            >
                                <EyeDropperIco
                                    color={
                                        isEyeDropperOpen ? '#022143' : '#9CA3AF'
                                    }
                                />
                            </Button>
                        </Box>
                        <Box
                            border="1px solid #E8ECEF"
                            borderRadius="4px"
                            height="25px"
                            width="69px"
                            mr="12px"
                            px="4px"
                            py="10px"
                            justifyContent="space-between"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Text my="0px">{switcherVal}</Text>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                gridGap="4px"
                                onClick={() => {
                                    switcherVal === 'Hex'
                                        ? setSwitcherVal('RGB')
                                        : setSwitcherVal('Hex')
                                }}
                            >
                                <ArrowDown
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                                <ArrowDown />
                            </Box>
                        </Box>
                        <Box mr="4px">
                            {switcherVal === 'Hex' ? (
                                <HexColorInput
                                    id="hex-color-input"
                                    onInput={(e) => {
                                        setHexColor(e.currentTarget.value)
                                        onColorChange &&
                                            onColorChange &&
                                            onColorChange(e.currentTarget.value)
                                    }}
                                    color={hexColor}
                                    onChange={setHexColor}
                                    style={{
                                        maxWidth: '132px',
                                        height: '21.5px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    placeholder="Type a color"
                                    prefixed
                                    alpha
                                />
                            ) : (
                                <Input
                                    bg="white"
                                    style={{
                                        maxWidth: '132px',
                                        height: '25px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    onChange={(e) => {
                                        setIsColorPointer(false)
                                        const newRgbInput = e.target.value
                                        const [r, g, b] = newRgbInput
                                            .split(',')
                                            .map((value) =>
                                                parseInt(value.trim(), 10)
                                            )
                                        if (
                                            !isNaN(r) &&
                                            !isNaN(g) &&
                                            !isNaN(b)
                                        ) {
                                            onColorChange &&
                                                onColorChange(
                                                    convertColor(
                                                        `rgb(${r},${g},${b})`
                                                    ).hex
                                                )
                                            setRgbaColor({
                                                r: r,
                                                g: g,
                                                b: b,
                                            })
                                        }
                                    }}
                                    value={
                                        isColorPointer
                                            ? `${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`
                                            : undefined
                                    }
                                    defaultValue={`${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        gridGap="8px"
                        alignItems="center"
                        px="4px"
                    >
                        <Text fontSize={'14px'} color="textSecondary">
                            {alternateColor}:
                        </Text>
                        <Text fontSize={'14px'} color="primary">
                            {alternativeColorValue}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
