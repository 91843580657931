import React from 'react'

function CollectionBadgeIco({ color = 'white' }: { color?: string }) {
    return (
        <svg
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M5.40004 1.34706H6.88235C6.99931 1.34706 7.09412 1.44187 7.09412 1.55882V8.44118C7.09412 8.55813 6.99931 8.65294 6.88235 8.65294H1.05882C0.941869 8.65294 0.847059 8.55813 0.847059 8.44118V1.55882C0.847059 1.44187 0.941869 1.34706 1.05882 1.34706H2.27651V4.47055C2.27651 4.62044 2.3668 4.75557 2.50528 4.81293C2.64376 4.87029 2.80316 4.83859 2.90915 4.7326L3.73343 3.90832L4.79795 4.75993C4.90919 4.84893 5.06159 4.86628 5.18999 4.80457C5.31839 4.74286 5.40004 4.61301 5.40004 4.47055V1.34706ZM0 1.55882C0 0.974051 0.474051 0.5 1.05882 0.5H6.88235C7.46712 0.5 7.94118 0.974052 7.94118 1.55882V8.44118C7.94118 9.02595 7.46712 9.5 6.88235 9.5H1.05882C0.474051 9.5 0 9.02595 0 8.44118V1.55882ZM3.01769 3.57587V1.39997H4.65887V3.6995L3.93743 3.12235C3.78998 3.00439 3.5774 3.01616 3.44388 3.14968L3.01769 3.57587Z"
                fill={color}
            />
        </svg>
    )
}

export default CollectionBadgeIco
