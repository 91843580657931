import { useQuery } from '@apollo/client'
import { Box } from 'src/components/utility'
import { CardLoader } from 'src/components/collections/card-loader'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'
import { GetProductTodos } from 'src/services/graphql/entities/todo/todo.queries'
import type {
    GetProductTodosQuery,
    GetProductTodosQueryVariables,
} from 'src/generated/graphql'

function ProductTodosContainer({
    productVariantId,
}: {
    productVariantId: number
}) {
    const {
        data: productDetails,
        loading: isProductDetailsLoading,
        refetch,
    } = useQuery<GetProductTodosQuery, GetProductTodosQueryVariables>(
        GetProductTodos,
        {
            variables: {
                id: productVariantId,
            },
        }
    )
    const product = productDetails?.product_variants_by_pk
    const todos = product?.todos

    if (isProductDetailsLoading) {
        return <CardLoader skeletonCount={2} />
    }

    if (!todos) return null

    return (
        <Box
            display="flex"
            flexDirection="column"
            pt="8px"
            pl="12px"
            gridGap="8px"
            borderLeft="1px solid"
            borderColor="#8496A8"
        >
            {todos.map((todo) => (
                <TodoListCard
                    key={todo.id}
                    todo={{
                        id: todo.id,
                        name: todo.name,
                        coverPic: todo.other_data?.cover_pic,
                        collection: {
                            id: product?.collection.id,
                            name: product?.collection.name || '',
                            manager_id: product?.collection.manager_id,
                        },
                        product: {
                            id: product?.id,
                            name: product?.name || '',
                            coverPic: product?.other_data?.cover_pic,
                        },
                        dueDate: todo.due_date,
                        isDelayed: todo.is_delayed,
                        status: todo.status,
                        assignee: todo.assignee,
                    }}
                    navigateTo={`/todo/${todo.id}`}
                    refetchTodos={refetch}
                    isCascade={true}
                />
            ))}
        </Box>
    )
}

export default ProductTodosContainer
