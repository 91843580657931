import { useEffect, useRef, useState } from 'react'
import { DivisionLine } from '../division-line/division-line'
import { DropdownBadge } from '../dropdown-badge/dropdown-badge'
import { Dropdown, DropdownButtonWrapper } from '../dropdown/dropdown'
import { Box, Button, Input, Span, Text } from '../utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export const CustomDropdown = ({
    label,
    selectedText,
    items,
    onItemClicked,
    maxHeight,
    minWidth,
    maxWidth,
    specialItem,
    noStyle,
    showArrow,
    labelFontSize,
    height,
    border,
    left,
    right,
    highlightedTextFontSize,
    labelMaxWidth,
    enableSearch = false,
    showDropdown = false,
    disabled = false,
    bg = 'transparent',
    borderColor = 'none',
    hideDropdown = false,
}: {
    label: string
    selectedText: string
    items: any[]
    onItemClicked: (value: any) => void
    maxHeight?: string
    minWidth?: string
    maxWidth?: string
    specialItem?: string
    noStyle?: boolean
    showArrow?: boolean
    labelFontSize?: FontSize
    height?: string
    border?: string
    left?: string
    right?: string
    highlightedTextFontSize?: FontSize
    labelMaxWidth?: string
    enableSearch?: boolean
    showDropdown?: boolean
    disabled?: boolean
    bg?: string
    borderColor?: string
    hideDropdown?: boolean
}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(showDropdown)
    const dropdownBadge = useRef<any>(null)
    const [highlightedText, setHighlightedText] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    const wrapperRef = useClickedOutside(() => setIsDropDownActive(false))

    useEffect(() => {
        setHighlightedText(selectedText)
    }, [selectedText])

    function searchFilter(item: any) {
        if (searchQuery.length > 0) {
            return item.name.toLowerCase().includes(searchQuery.toLowerCase())
                ? true
                : false
        } else {
            return true
        }
    }

    return (
        <>
            <Box position="relative" ref={wrapperRef}>
                <DropdownBadge
                    bg={bg || 'transparent'}
                    borderWidth={1}
                    label={label}
                    highlightedText={highlightedText}
                    ref={dropdownBadge}
                    onclick={() => setIsDropDownActive(!isDropDownActive)}
                    noStyle={noStyle}
                    showArrow={showArrow}
                    highlightedTextFontSize={highlightedTextFontSize || '12px'}
                    labelFontSize={labelFontSize}
                    height={height}
                    border={border}
                    borderColor={borderColor || 'none'}
                    disabled={disabled}
                    maxWidth={labelMaxWidth}
                    px="0px"
                />
                {!hideDropdown && !disabled && isDropDownActive && (
                    <Dropdown
                        maxHeight={maxHeight ? maxHeight : 'unset'}
                        top={height}
                        left={left}
                        right={right}
                        overflowY={maxHeight ? 'scroll' : 'visible'}
                        active={isDropDownActive}
                        zIndex={15}
                    >
                        <>
                            {enableSearch && (
                                <Box
                                    display="flex"
                                    width="100%"
                                    gridGap="8px"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Input
                                        border="1px solid #DDDDDD"
                                        width="100%"
                                        bg="white"
                                        placeholder="Search"
                                        py="8px"
                                        px="12px"
                                        onChange={(e) =>
                                            setSearchQuery(e.target.value)
                                        }
                                        borderRadius="4px"
                                    />
                                </Box>
                            )}
                            {specialItem ? (
                                <>
                                    <DropdownButtonWrapper
                                        onClick={(x) => {
                                            onItemClicked(specialItem)
                                            setHighlightedText(specialItem)
                                            setIsDropDownActive(false)
                                        }}
                                        value={specialItem}
                                        width="100%"
                                        key={specialItem}
                                        minWidth={minWidth ? minWidth : 'unset'}
                                        maxWidth={maxWidth || 'unset'}
                                    >
                                        <Button
                                            height="100%"
                                            color="primary"
                                            bg="transparent"
                                            border="none"
                                            fontSize="14px"
                                        >
                                            {specialItem}
                                        </Button>
                                    </DropdownButtonWrapper>
                                    <Box width="100%" py={'8px'}>
                                        <DivisionLine />
                                    </Box>
                                </>
                            ) : null}

                            {items &&
                                (items.filter(searchFilter).length > 0 ? (
                                    items.filter(searchFilter).map((item) => {
                                        return (
                                            <DropdownButtonWrapper
                                                onClick={(x) => {
                                                    onItemClicked(item)
                                                    setHighlightedText(
                                                        item?.name
                                                            ? item.name
                                                            : item
                                                    )
                                                    setIsDropDownActive(false)
                                                }}
                                                value={item}
                                                width="100%"
                                                key={item?.id ? item.id : item}
                                                minWidth={minWidth || 'unset'}
                                                maxWidth={maxWidth || 'unset'}
                                            >
                                                <Button
                                                    height="100%"
                                                    color="primary"
                                                    bg="transparent"
                                                    border="none"
                                                    fontSize="14px"
                                                    width="100%"
                                                    textAlign="left"
                                                    className="text-overflow text-ellipsis"
                                                    opacity={
                                                        (item?.name
                                                            ? item.name
                                                            : item) ===
                                                        highlightedText
                                                            ? '0.5'
                                                            : '1'
                                                    }
                                                    title={
                                                        item?.name
                                                            ? item.name
                                                            : item
                                                    }
                                                >
                                                    <Span
                                                        className="text-overflow text-ellipsis"
                                                        overflow={'hidden'}
                                                        textAlign="left"
                                                        my={'0px'}
                                                        maxWidth="100%"
                                                    >
                                                        {item?.name
                                                            ? item.name
                                                            : item}{' '}
                                                    </Span>
                                                </Button>
                                            </DropdownButtonWrapper>
                                        )
                                    })
                                ) : (
                                    <Text
                                        fontSize="14px"
                                        textAlign="center"
                                        px="20px"
                                        my="8px"
                                    >
                                        No results found
                                    </Text>
                                ))}
                        </>
                    </Dropdown>
                )}
            </Box>
        </>
    )
}
