import { gql } from '@apollo/client'

export const GetCollectionByID = gql`
    query GetCollectionsById($id: bigint!) {
        collections_by_pk(id: $id) {
            company
            status
            created_at
            updated_at
            name
            description
            due_date
            brand
            development_cycle
            id
            tags
            manager_id
            manager {
                other_data
                organisation_members {
                    role
                }
                email
                name
                id
            }
            team {
                id
                name
                team_members {
                    user {
                        files(where: { type: { _eq: "profile_pic" } }) {
                            path
                        }
                        other_data
                        name
                        id
                        email
                        organisation_members {
                            role
                        }
                    }
                }
            }
            creator {
                name
            }
            products {
                id
                created_at
                name
                team_id
                is_delayed
                description
                due_date
                tags
                status
                approver {
                    name
                    email
                    files(where: { type: { _eq: "profile_pic" } }) {
                        path
                    }
                }
                todos {
                    name
                    due_date
                    status
                    assignee {
                        name
                        other_data
                    }
                    sub_todos {
                        todo_id
                        heading
                        description
                        created_at
                    }
                    description
                    id
                    tags
                }
                creator {
                    name
                    files(where: { type: { _eq: "profile_pic" } }) {
                        path
                    }
                }
            }
        }
    }
`
