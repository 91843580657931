import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Slider } from 'src/components/slider/slider'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Button, Text } from 'src/components/utility'
import YourProductsSlider from './your-products-slider'
import { useApolloClient, useQuery } from '@apollo/client'
import { GetProductsByApproverId } from 'src/services/graphql/query/organisations-queries'
import { useUser } from 'src/helpers'
import ProductCard from 'src/components-v2/cards/product/product.card'

interface IYourProductListProps {}

export default function YourProductList(props: IYourProductListProps) {
    const navigate = useNavigate()
    const { userId } = useUser()
    const [currentTab, setCurrentTab] = React.useState<
        | 'new'
        | 'inProgress'
        | 'complete'
        | 'inReview'
        | 'requestedForResubmission'
    >('new')
    const [productsCount, setProductsCount] = React.useState({
        new: 0,
        inProgress: 0,
        inReview: 0,
        revisionRequested: 0,
        completed: 0,
    })
    const { data, refetch } = useQuery(GetProductsByApproverId, {
        variables: {
            approver_id: Number(userId),
            status: { _eq: currentTab },
        },
        // !!TODO: Change fetch policy to make use of caching strategy
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setProductsCount({
                new: data.new.aggregate.count,
                inProgress: data.inProgress.aggregate.count,
                inReview: data.inReview.aggregate.count,
                revisionRequested: data.revisionRequested.aggregate.count,
                completed: data.completed.aggregate.count,
            })
        },
    })
    const products = data?.product_variants
    const client = useApolloClient()

    function changeTabHandler() {
        if (currentTab === 'new') {
            setCurrentTab('inProgress')
        } else if (currentTab === 'inProgress') {
            setCurrentTab('inReview')
        } else if (currentTab === 'inReview') {
            setCurrentTab('requestedForResubmission')
        } else if (currentTab === 'requestedForResubmission') {
            setCurrentTab('complete')
        } else {
            setCurrentTab('new')
        }
    }

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            // alignItems="center"
            flexDirection="column"
            gridRowGap="16px"
        >
            <Box
                display="flex"
                flexDirection="column"
                className="pointer"
                mt="4px"
                onClick={() => {
                    navigate('/collections?group=products')
                }}
            >
                <Box display="flex" alignItems="center">
                    <Text fontSize={'16px'} my="0px" mr="12px">
                        {' '}
                        Your Products{' '}
                    </Text>
                    <RightArrow />
                </Box>
            </Box>
            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                bg="white"
                borderRadius="4px"
                p="16px"
            >
                <Box
                    display="grid"
                    gridTemplateColumns={'1fr auto'}
                    alignItems="center"
                    gridGap="12px"
                >
                    <Slider
                        element={
                            <YourProductsSlider
                                currentTab={currentTab}
                                setCurrentTab={(val) => {
                                    setCurrentTab(val)
                                }}
                                products={{
                                    completed: productsCount.completed,
                                    inProgress: productsCount.inProgress,
                                    new: productsCount.new,
                                    inReview: productsCount.inReview,
                                    revisionRequested:
                                        productsCount.revisionRequested,
                                }}
                            />
                        }
                        carousel={true}
                    />
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            changeTabHandler()
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                    >
                        <RightArrow />
                    </Button>
                </Box>
                <Box
                    height="500px"
                    gridRowGap="16px"
                    display="flex"
                    flexDirection="column"
                    overflowY="auto"
                    pt="12px"
                    className="scrollbar_none"
                >
                    {products?.map((product: any) => {
                        if (product.status === currentTab) {
                            return (
                                <ProductCard
                                    entity={product}
                                    refetch={refetch}
                                    updateDate={() => {}}
                                    updateStatus={() => {}}
                                    isVariant={!product.is_primary}
                                    disableStatusUpdateFetch={false}
                                    dashboardView={true}
                                    tabView={true}
                                />
                            )
                        }
                        return <></>
                    })}
                </Box>
            </Box>
        </Box>
    )
}
