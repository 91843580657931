export function VirtuIco({
    size = 24,
    color = '#022143',
}: {
    size?: number
    color?: string
}) {
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.624 11.766L19.503 6.1l-8.635-4.804L1.235 6.8v10.971l8.39 4.702V11.766zM20.14 6.454zM.415 5.847L10.457.108a.823.823 0 01.808-.004l9.475 5.271a.823.823 0 01.01 1.434l-9.89 5.673v10.693a.823.823 0 01-1.226.719L.42 18.73A.824.824 0 010 18.011V6.562c0-.296.158-.569.415-.715z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.258 8.612a.618.618 0 01.848-.211l4.907 2.944a.618.618 0 01-.635 1.06L5.47 9.46a.618.618 0 01-.212-.848zM13.67 13.884v8.124l4.68-2.52a4.94 4.94 0 002.597-4.35V9.735l-7.276 4.15zm7.28-5.574l-8.514 4.856v9.532a.823.823 0 001.213.725l5.286-2.847a6.176 6.176 0 003.247-5.437V9.025a.823.823 0 00-1.231-.715z"
                fill={color}
            />
        </svg>
    )
}

export function VirtuIcoRound() {
    return (
        <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                y="0.333984"
                width="18"
                height="18.8196"
                rx="9"
                fill="#022143"
            />
            <rect
                y="0.333984"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <rect
                y="0.333984"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <rect
                y="0.333984"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M8.33887 9.63832L12.7922 7.08398L8.89935 4.91827L4.55682 7.39971V12.3454L8.33887 14.465V9.63832ZM4.18704 6.9697L8.71395 4.38289C8.82676 4.31843 8.96506 4.31764 9.07859 4.38081L13.35 6.75712C13.6024 6.89756 13.6048 7.2598 13.3542 7.40352L8.89568 9.96085V14.7818C8.89568 15.0655 8.59043 15.2443 8.34299 15.1056L4.18973 12.778C4.07256 12.7123 4 12.5885 4 12.4542V7.292C4 7.15879 4.07138 7.03579 4.18704 6.9697Z"
                fill="#EAFC40"
            />
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M6.37064 8.21637C6.44975 8.08452 6.62077 8.04176 6.75261 8.12087L8.96508 9.44835C9.09693 9.52746 9.13969 9.69848 9.06058 9.83033C8.98147 9.96218 8.81045 10.0049 8.6786 9.92582L6.46613 8.59834C6.33429 8.51923 6.29153 8.34822 6.37064 8.21637Z"
                fill="#EAFC40"
            />
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M10.1629 10.593V14.2558L12.272 13.1199C12.9934 12.7314 13.4432 11.9782 13.4432 11.1589V8.72242L10.1629 10.593ZM13.4449 8.08045L9.60607 10.2696V14.5666C9.60607 14.8475 9.90599 15.0266 10.1533 14.8934L12.5361 13.6101C13.4377 13.1245 14 12.183 14 11.1589V8.40291C14 8.11803 13.6924 7.93932 13.4449 8.08045Z"
                fill="#EAFC40"
            />
        </svg>
    )
}
