import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { InlineError, Modal } from 'src/components'
import { Box, Input, Span } from 'src/components/utility'
import { debounce } from 'src/helpers'
import { FetchLibraryCategories, UpdateCategoryName } from 'src/services'
import { FetchCategoriesBySearchStr } from 'src/services/graphql/query/library.queries'

export const RenameCategoryModal = ({
    setModal,
    refetchCategories,
}: {
    setModal: (x: boolean) => void
    refetchCategories: (x: boolean) => void
}) => {
    const [fetchCategories, categoriesData] = useLazyQuery(
        FetchLibraryCategories
    )
    const [UpdateCategoryNameMutation] = useMutation(UpdateCategoryName)
    const [searchParam, setSearchParam] = useSearchParams()
    const [selectedIds, setSelectedIds] = useState<any[]>([])
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>()
    const [categoryName, setCategoryName] = useState('')
    const [fetchCatergory] = useLazyQuery(FetchCategoriesBySearchStr)

    const [categoryAlreayExists, setCategoryAlreayExists] = useState(false)
    // const [searchParams, setSearchParams] = useSearchParams();
    const findingCategory = () => {
        setCategoryAlreayExists(false)
        fetchCatergory({
            variables: {
                searchStr: categoryName ? `%${categoryName}%` : `%%`,
                limit: 100,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (data: any) => {
                const _exCategories: any[] = data.category
                let found = _exCategories.find(
                    (item: any) =>
                        item.name.toLowerCase() === categoryName?.toLowerCase()
                )
                if (found) {
                    setCategoryAlreayExists(true)
                }
            },
        })
    }
    const categoryDebounce = debounce(
        findingCategory,
        300,
        setTimeoutRef,
        timeoutRef
    )
    useEffect(() => {
        if (categoryName.length < 0) return
        categoryDebounce()
    }, [categoryName])

    useEffect(() => {
        fetchCategories({
            variables: {
                whereObj: {
                    deleted_at: {
                        _is_null: true,
                    },
                },
            },
        })
    }, [])

    function renameCategory() {
        const categoryId = searchParam.get('category_id')
        if (!categoryId) return
        UpdateCategoryNameMutation({
            variables: {
                id: categoryId,
                name: categoryName,
            },
            onCompleted: () => {
                setModal(false)
                setCategoryName('')
                searchParam.set('tabName', categoryName)
                setSearchParam(searchParam)
                fetchCategories({
                    variables: {
                        whereObj: {
                            deleted_at: {
                                _is_null: true,
                            },
                        },
                    },
                })
            },
        })
    }

    return (
        <Modal
            isModal={true}
            onClick={() => renameCategory()}
            onClose={() => setModal(false)}
            primaryButtonTitle="Rename"
            secondaryButtonTitle="Cancel"
            title={`Rename  ${"'" + searchParam.get('tabName') + "'" || ''}`}
            width="600px"
            maxWidth="600px"
            disablePrimaryButton={categoryName.length > 0 ? false : true}
        >
            <Box
                p={'24px'}
                gridRowGap="12px"
                display="flex"
                flexDirection="column"
            >
                <Input
                    autoComplete="false"
                    placeholder={`Rename`}
                    bg="white"
                    borderRadius="4px"
                    px="16px"
                    py="16px"
                    minWidth="360px"
                    fontSize="14px"
                    border="solid"
                    maxLength={25}
                    minLength={3}
                    borderWidth={1}
                    borderColor={'gray350'}
                    className="placeholder-darkgray"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCategoryName(e.target.value)
                    }
                />
                <Span fontSize={'14px'} color="textSecondary">
                    {' '}
                    {25 - (categoryName?.length || 0)} characters left
                </Span>
                {categoryAlreayExists && (
                    <InlineError
                        width="auto"
                        text={'Category name already exist'}
                    />
                )}
            </Box>
        </Modal>
    )
}
