import { useApolloClient } from '@apollo/client'
import { useState, type Dispatch } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDeleteProductVariant } from 'src/components-v2/cards/hooks/useDeleteProductVariant'
import useCreateVariant from 'src/components-v2/product-variants/useCreateVariant.hook'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { DangerModal } from 'src/components/modals/danger-modal'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { GetProductsForCollection } from 'src/services'
import { GetProductVariantsByProductId } from 'src/services/graphql/entities/product/product.queries'

export default function ProductMenuDropdown({
    active,
    setIsDropdownActive,
    product,
    isDetailedPage = false,
    isCompareSection,
}: {
    active: boolean
    setIsDropdownActive:
        | Dispatch<React.SetStateAction<boolean>>
        | ((value: boolean) => void)
    product: {
        id: number
        name: string
        collection: {
            id: number
            name: string
        }
    }
    isCompareSection?: boolean
    isDetailedPage?: boolean
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['product-menu-button']
    )

    const [searchParams, setSearchParams] = useSearchParams()
    const [showRemoveProductModal, setShowRemoveProductModal] = useState(false)
    const navigate = useNavigate()
    const { deleteProductVariant } = useDeleteProductVariant()
    const { createVariantHandler } = useCreateVariant()
    const client = useApolloClient()

    function deleteProduct() {
        deleteProductVariant({
            variantId: product.id,
            refetchQueries: [
                'GetProducts',
                'GetCollections',
                'fetchCollectionById',
                'GetProductVariantsByProductId',
                {
                    query: GetProductsForCollection,
                    variables: { id: product.collection.id },
                },
            ],
            onComplete: () => {
                if (isCompareSection) {
                    searchParams.delete('compareEntity')
                    searchParams.delete('compareId')
                    setSearchParams(searchParams, { replace: true })
                } else if (isDetailedPage) {
                    navigate(-1)
                }
                toast('Product deleted successfully', {
                    className: 'custom-toaster toaster-success',
                })
            },
        })
    }

    function copyLinkHandler() {
        navigator.clipboard.writeText(window.location.href)
        toast('Link copied to clipboard', {
            className: 'custom-toaster toaster-success',
        })
        setIsDropdownActive(false)
    }

    function makeCopyHandler() {
        createVariantHandler({
            productVariantId: product.id,
            onCompleted: () => {
                client.refetchQueries({
                    include: [GetProductVariantsByProductId],
                })

                toast('Variant copied successfully', {
                    className: 'custom-toaster toaster-success',
                })
                setIsDropdownActive(false)
            },
        })
    }

    return (
        <Dropdown
            active={active}
            top="28px"
            left="none"
            right="0px"
            width="182px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        width="100%"
                        textAlign="left"
                        onClick={(e) => {
                            e.stopPropagation()
                            copyLinkHandler()
                        }}
                    >
                        Copy Link
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        width="100%"
                        textAlign="left"
                        onClick={(e) => {
                            e.stopPropagation()
                            makeCopyHandler()
                        }}
                    >
                        Make a Copy
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="#FF2C18"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        width="100%"
                        textAlign="left"
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowRemoveProductModal(true)
                        }}
                    >
                        Delete Product
                    </Button>
                </DropdownButtonWrapper>
                {showRemoveProductModal && (
                    <DangerModal
                        onRemove={deleteProduct}
                        isModal={showRemoveProductModal}
                        onClose={() => setShowRemoveProductModal(false)}
                        heading={`Are you sure you want to delete the '${product?.name}'  
                                from ${product?.collection?.name}`}
                        subHeading={
                            'Product will be deleted permanently & will not appear anywhere on the platform'
                        }
                        title={'Delete Product'}
                        primaryButtonText="Yes, Delete"
                        secondaryButtonText="Cancel"
                    />
                )}
            </Box>
        </Dropdown>
    )
}
