import React, { useEffect, useState } from 'react'
import { Dots, DropDownArrow } from 'src/components/svg-icons'
import { Box, Button, Input } from 'src/components/utility'
import type { Subtodo } from 'src/components-v2/todo/todo.types'
import SubtodoColorPicker from 'src/components-v2/todo/subtodo-card/subtodo-color-picker/subtodo-color-picker'
import SubtodoMenuDropdown from 'src/components-v2/todo/subtodo-card/subtodo-menu-dropdown'
import UploadSubtodoRefImage from 'src/components-v2/todo/subtodo-card/upload-subtodo-ref-image'
import SubtodoReferencePic from 'src/components-v2/todo/subtodo-card/subtodo-reference-pic'
import useUpdateTemplate from 'src/hooks/useUpdateTemplate.hook'
import TemplateSubtodoSection from '../template-subtodo-section/template-subtodo-section'

function TemplateSubtodoCard({
    subtodo,
    templateId,
    refetchTemplate,
    isTechPack,
    height,
    isReadOnly,
}: {
    subtodo: Subtodo
    templateId: number
    refetchTemplate: () => void
    isTechPack: boolean
    height?: string
    isReadOnly?: boolean
}) {
    const [heading, setHeading] = useState(subtodo.heading)
    const [showDropdownMenu, setShowDropdownMenu] = useState(false)
    const [showExpandedView, setShowExpandedView] = useState(true)
    const [referencePicUploadStatus, setReferencePicUploadStatus] = useState({
        isUploading: false,
    })
    const [assignReferenceToSection, setAssignReferenceToSection] = useState<
        number | null
    >(null)
    const { updateTemplateSubtodo, debouncedUpdateTemplateSubtodo } =
        useUpdateTemplate()

    useEffect(() => {
        setHeading(subtodo.heading)
    }, [subtodo.id])

    const handleSubtodoHeadingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setHeading(e.target.value)
        debouncedUpdateTemplateSubtodo({
            payload: { heading: e.target.value },
            id: subtodo.id,
            onCompleted: () => refetchTemplate(),
        })
    }

    const referencePic = subtodo.files?.[0]?.file

    return (
        <Box
            height={
                height ? height : showExpandedView ? '416px' : 'max-content'
            }
            borderRadius="4px"
            border="1px solid"
            borderColor={'#B8B8B8'}
            className="subtodo-card"
            display="grid"
            gridTemplateRows="40px 1fr"
        >
            <Box
                bg={subtodo.design_color}
                p="8px"
                pl="16px"
                display="flex"
                alignItems="center"
                style={{ borderRadius: '4px 4px 0px 0px' }}
                gridGap="8px"
            >
                <Input
                    bg="transparent"
                    value={heading}
                    onChange={handleSubtodoHeadingChange}
                    placeholder="Add Requirement Name"
                    border="none"
                    width="100%"
                    readOnly={isReadOnly}
                />
                {!isReadOnly && (
                    <>
                        <SubtodoColorPicker
                            activeColor={subtodo.design_color}
                            updateDesignColor={(color) =>
                                updateTemplateSubtodo({
                                    id: subtodo.id,
                                    payload: { design_color: color },
                                    onCompleted: () => refetchTemplate(),
                                })
                            }
                        />
                        <Box display="flex" alignItems="center" gridGap="8px">
                            <Box position="relative">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setShowDropdownMenu(!showDropdownMenu)
                                    }}
                                    bg="transparent"
                                    border="none"
                                    fontStyle="Rubik"
                                    fontSize="12px"
                                    color="#027AC3"
                                    position="relative"
                                    width="max-content"
                                    id="subtodo-menu-button"
                                >
                                    <Dots active={false} vertical />
                                </Button>
                                {showDropdownMenu && (
                                    <SubtodoMenuDropdown
                                        active={showDropdownMenu}
                                        setIsDropdownActive={
                                            setShowDropdownMenu
                                        }
                                        onDelete={() => {
                                            updateTemplateSubtodo({
                                                payload: {
                                                    deleted_at:
                                                        new Date().toISOString(),
                                                },
                                                id: subtodo.id,
                                                onCompleted: () =>
                                                    refetchTemplate(),
                                            })
                                        }}
                                    />
                                )}
                            </Box>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowExpandedView(!showExpandedView)
                                }}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="#027AC3"
                                position="relative"
                                width="max-content"
                                id="subtodo-menu-button"
                            >
                                <DropDownArrow rotate={showExpandedView} />
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            {showExpandedView && (
                <Box
                    display="grid"
                    gridTemplateColumns={isTechPack ? '156px 1fr' : '1fr'}
                    style={{ borderRadius: '0px 0px 4px 4px' }}
                    overflow={'hidden'}
                >
                    {isTechPack ? (
                        referencePic ? (
                            <SubtodoReferencePic
                                pic={referencePic}
                                picId={subtodo.files[0].id}
                                subtodoId={subtodo.id}
                                refetchTodo={refetchTemplate}
                                referencePoints={[]}
                                assignReferenceToSection={
                                    assignReferenceToSection
                                }
                                setAssignReferenceToSection={
                                    setAssignReferenceToSection
                                }
                                isFocussedView={false}
                                referencePicUploadStatus={
                                    referencePicUploadStatus
                                }
                                setReferencePicUploadStatus={
                                    setReferencePicUploadStatus
                                }
                            />
                        ) : (
                            <UploadSubtodoRefImage
                                subtodoId={subtodo.id}
                                refetchTodo={refetchTemplate}
                                isReadOnly={isReadOnly}
                                referencePicUploadStatus={
                                    referencePicUploadStatus
                                }
                                setReferencePicUploadStatus={
                                    setReferencePicUploadStatus
                                }
                            />
                        )
                    ) : null}
                    <Box
                        py="8px"
                        px="16px"
                        bg="#F8FAFB"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        gridGap="16px"
                        overflow={'auto'}
                        className="scrollbar_none"
                    >
                        {/* @ts-ignore */}
                        {subtodo.template_sub_todo_sections.map(
                            (section: any, index: any) => {
                                return (
                                    <TemplateSubtodoSection
                                        key={section.id}
                                        section={section}
                                        refetchTemplate={refetchTemplate}
                                        subtodoId={subtodo.id}
                                        templateId={templateId}
                                        isLastSection={
                                            index ===
                                            // @ts-ignore
                                            subtodo.template_sub_todo_sections
                                                .length -
                                                1
                                        }
                                        isDeletable={index !== 0}
                                        isReadOnly={isReadOnly}
                                        isTechPack={isTechPack}
                                    />
                                )
                            }
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default TemplateSubtodoCard
