import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Box, Button, Input, Text } from 'src/components/utility'
import { GetCollections } from '../compare-entity-topbar.queries'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import CollectionDropdownCard from './collection-dropdown-card'
import { debounce } from 'src/helpers'

function CollectionDropdown({
    onClose,
    onSelect,
    isCompareEntitySelector,
}: {
    onClose: () => void
    onSelect: (product: { id: number; name: string }) => void
    isCompareEntitySelector?: boolean
}) {
    const [searchInput, setSearchInput] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const { data } = useQuery(GetCollections, {
        variables: {
            searchQuery: `%${searchQuery}%`,
        },
    })

    const wrapperRef = useClickedOutside(
        () => onClose(),
        ['topbar-collection-dropdown-button']
    )
    const [timeoutRef, setTimeoutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debouncedUpdateSearch = debounce(
        (query: string) => {
            setSearchQuery(query)
        },
        400,
        setTimeoutRef,
        timeoutRef
    )

    function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchInput(e.target.value)
        debouncedUpdateSearch(e.target.value)
    }

    return (
        <Box
            position="absolute"
            width={'615px'}
            height={'314px'}
            bg="white"
            top="24px"
            left={isCompareEntitySelector ? '-160px' : '-80px'}
            zIndex={15}
            border="1px solid #E0E0E0"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            p="16px"
            overflow={'auto'}
            gridGap="16px"
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            ref={wrapperRef}
        >
            <Text my="0px" fontSize={'12px'} color="textSecondary">
                Choose collection
            </Text>
            <Box
                display="flex"
                gridGap="8px"
                borderRadius="4px"
                bg="white"
                border="1px solid #DDDDDD"
                py="8px"
                px="12px"
                width="100%"
                alignItems="center"
            >
                <SearchIco />
                <Input
                    placeholder="Search"
                    border="none"
                    bg="transparent"
                    value={searchInput}
                    onChange={searchChangeHandler}
                    width="100%"
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setSearchInput('')
                        debouncedUpdateSearch('')
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <CrossSm />
                </Button>
            </Box>
            {data && data.collections.length > 0 ? (
                data.collections.map((collection: any) => {
                    return (
                        <CollectionDropdownCard
                            key={collection.id}
                            name={collection.name}
                            manager={collection.manager}
                            onClick={() => {
                                onSelect({
                                    id: collection.id,
                                    name: collection.name,
                                })
                            }}
                        />
                    )
                })
            ) : (
                <Text textAlign="center">No collections found</Text>
            )}
        </Box>
    )
}

export default CollectionDropdown
