import React from 'react'

function VariantIco({ size, color }: { size?: string; color?: string }) {
    return (
        <svg
            width={size || '16'}
            height={size || '17'}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.01953"
                y="4.5"
                width="5.04235"
                height="7.91721"
                rx="0.5"
                stroke={color || '#022143'}
                stroke-linejoin="round"
            />
            <path
                d="M8.20703 12.7213L8.92805 12.894C9.36376 12.9983 9.81752 12.7413 9.94156 12.32L11.891 5.69882C12.015 5.27751 11.7624 4.85138 11.3267 4.74704L8.20703 4V5.06164L10.798 5.68209L8.98337 11.8456L8.20703 11.6597V12.7213Z"
                fill={color || '#022143'}
            />
            <path
                d="M10.6016 12.9999C10.7882 12.962 10.9619 12.8606 11.0816 12.7033L14.3365 8.42425C14.5849 8.09761 14.5064 7.65314 14.1609 7.4315L12.4246 6.31738L12.159 7.29108L13.3885 8.07999L11.1359 11.0414L10.6016 12.9999Z"
                fill={color || '#022143'}
            />
        </svg>
    )
}

export default VariantIco
