
import { Box } from "../utility";
import styled, { keyframes, css } from "styled-components";

const rotation = keyframes`
     0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
   `


export const Loader = styled(Box) <BoxTypes>`

        width: 14px;
        height: 14px;
        border: 2px dotted #fff;
        border-style: solid solid dotted dotted;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: ${rotation} 2s linear infinite;
        ${(props) => css`
        & {
          border: 2px dotted ${props.outline || "#fff"};
          border-style: solid solid dotted dotted;
          height: ${props.size || "14px"};
          width: ${props.size || "14px"};
        }
    `}
  `
