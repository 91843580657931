import { gql } from '@apollo/client'

export const DeleteTemplateById = gql`
    mutation DeleteTemplateById(
        $id: bigint = ""
        $deleted_at: timestamptz = ""
    ) {
        update_templates_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: $deleted_at }
        ) {
            id
        }
    }
`
export const DeleteAssignedTemplatesByTodoId = gql`
    mutation DeleteAssignedTemplatesByTodoId(
        $todoId: bigint = ""
        $deleted_at: timestamptz = ""
    ) {
        update_assigned_templates_many(
            updates: {
                where: { todo_id: { _eq: $todoId } }
                _set: { deleted_at: $deleted_at }
            }
        ) {
            returning {
                id
            }
        }
    }
`

export const CreateTodoTemplateType = gql`
    mutation CreateTodoTemplateType($object: template_types_insert_input!) {
        insert_template_types_one(object: $object) {
            id
            type
        }
    }
`

export const CreateTemplateCategory = gql`
    mutation CreateTemplateCategory(
        $object: template_categories_insert_input!
    ) {
        insert_template_categories_one(object: $object) {
            id
            name
        }
    }
`

export const UpdateTemplateDesignById = gql`
    mutation UpdateTemplateDesignById($id: bigint = "", $design: jsonb = "") {
        update_templates_by_pk(
            pk_columns: { id: $id }
            _set: { design: $design }
        ) {
            id
        }
    }
`
export const AddFavouriteEntity = gql`
    mutation AddFavouriteEntity(
        $entity_name: String = ""
        $entity_id: bigint = ""
    ) {
        insert_user_favourite_one(
            object: { entity_name: $entity_name, entity_id: $entity_id }
        ) {
            id
        }
    }
`

export const RemoveFavouriteEntity = gql`
    mutation RemoveFavouriteEntity(
        $id: bigint = ""
        $deleted_at: timestamptz = ""
    ) {
        update_user_favourite_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: $deleted_at }
        ) {
            id
        }
    }
`

export const UpdateTemplateSubtodo = gql`
    mutation UpdateTemplateSubtodo(
        $id: bigint!
        $object: template_sub_todos_set_input!
    ) {
        update_template_sub_todos_by_pk(
            pk_columns: { id: $id }
            _set: $object
        ) {
            id
        }
    }
`

export const InsertTemplateSubtodoSection = gql`
    mutation InsertTemplateSubtodoSection(
        $object: template_sub_todo_sections_insert_input!
    ) {
        insert_template_sub_todo_sections_one(object: $object) {
            id
        }
    }
`

export const UpdateTemplateSubtodoSection = gql`
    mutation UpdateTemplateSubtodoSection(
        $id: bigint!
        $payload: template_sub_todo_sections_set_input!
    ) {
        update_template_sub_todo_sections_by_pk(
            pk_columns: { id: $id }
            _set: $payload
        ) {
            id
        }
    }
`
