import React, { useEffect, useState } from 'react'
import ReactViewAdobe from 'react-adobe-embed'
import './pdf-viewer.styles.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { useLazyQuery } from '@apollo/client'
import { GetFileInfoByGID } from 'src/services'
import { Box, Button } from 'src/components/utility'
import { CONFIG_VARS } from 'src/config/constants.config'
import { SuspenseLoading } from 'src/components/suspense-loading/suspense-loading'

export function PDFViewer() {
    const [fileInfo, setFileInfo] = useState({ name: '', url: '', size: '' })
    const navigate = useNavigate()
    const [getFileInfo] = useLazyQuery(GetFileInfoByGID)
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams] = useSearchParams()

    console.log(searchParams.get('ref'))
    useEffect(() => {
        const query = searchParams.get('q')
        let URL = getLibraryFileUrl(query || '')
        if (searchParams.get('ref') === 'todo') {
            URL = getImageUrl(query || '')
        } else if (searchParams.get('ref') === 'library') {
            URL = getLibraryFileUrl(query || '')
        } else if (searchParams.get('ref') === 'self') {
            URL = query || ''
        }
        setIsLoading(true)
        getFileInfo({
            variables: {
                gid: query,
            },
            onCompleted: (data) => {
                console.log(data)
                if (data.files.length > 0) {
                    setFileInfo({
                        name: data.files[0].path.slice(1),
                        url: URL,
                        size: data.files[0].size,
                    })
                } else if (data.library_files.length > 0) {
                    setFileInfo({
                        name: data.library_files[0].path.slice(1),
                        url: URL,
                        size: data.library_files[0].size,
                    })
                }
                setIsLoading(false)
            },
            onError: () => {
                setIsLoading(false)
            },
        })
    }, [searchParams])

    if (isLoading) return <SuspenseLoading loadingText="Loading..." />
    return (
        <Box height="100%" px="12px">
            <Button
                color="links"
                bg="transparent"
                mb="12px"
                my="8px"
                border="none"
                ml="12px"
                onClick={() => navigate(-1)}
            >
                &lt; Back
            </Button>
            <ReactViewAdobe
                title={fileInfo.name}
                previewConfig={{
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showDownloadPDF: true,
                }}
                config={{
                    clientId: CONFIG_VARS.PDF_CLIENT_ID,
                    divId: 'pdf-div',
                    url: fileInfo.url,
                    fileMeta: {
                        fileName: fileInfo.name || 'unknown',
                        title: fileInfo.name || 'unknown',
                    },
                }}
            />
        </Box>
    )
}
