import * as React from 'react'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Text } from 'src/components/utility'
import type { TDashboardTeams } from '../hooks/use-teams'
import { useTeams } from '../hooks/use-teams'
import TeamsEntityList from './teams-entity-list.dashboard'

export interface ITeamsDashboardCardProps {}

export default function TeamsDashboardCard(props: ITeamsDashboardCardProps) {
    const { fetchTeams } = useTeams()
    const [teamData, setTeamData] = React.useState<{
        data: TDashboardTeams[]
        count: number
    }>({
        data: [],
        count: 0,
    })
    React.useEffect(() => {
        ;(async function () {
            const data = await fetchTeams()
            setTeamData(data)
        })()
    }, [])
    return (
        <Box width="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="3px"
            >
                <Box display="flex" alignItems="center" gridColumnGap="8px">
                    <Text my="0px">Your Teams ({teamData.count})</Text>
                    <RightArrow />
                </Box>
            </Box>
            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                borderRadius={'4px'}
                mt="16px"
            >
                <TeamsEntityList entityList={teamData.data} />
            </Box>
        </Box>
    )
}
