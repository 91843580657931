import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Button } from 'src/components/utility'
import CompareEntitySelector from './compare-entity-selector'

function CompareEntityTopbar() {
    const { todoId, productId } = useParams()
    const [searchParams] = useSearchParams()
    const compareId = searchParams.get('compareId')
    const compareEntity = searchParams.get('compareEntity')
    const navigate = useNavigate()

    const isSplitViewMode = !!compareId

    return (
        <Box
            display="grid"
            gridTemplateColumns="62px 1fr 50%"
            alignItems="center"
            height="40px"
            width="100%"
            borderBottom="1px solid"
            borderColor="textSecondary"
        >
            <Box px="12px">
                <Button
                    color="links"
                    fontSize="12px"
                    bg="transparent"
                    border="none"
                    px="0px"
                    fontFamily="Rubik"
                    my="0px"
                    height="100%"
                    onClick={() => navigate(-1)}
                >
                    &lt; Back
                </Button>
            </Box>
            <CompareEntitySelector
                todoId={todoId ? Number(todoId) : undefined}
                productId={productId ? Number(productId) : undefined}
                isSplitViewMode={isSplitViewMode}
            />
            {/* The main entitiyId is passed (if compareEntityId is empty) so as
            to prefill the dropdown badges, making it easier for user to switch
            to sibling entities */}
            <CompareEntitySelector
                key={
                    compareEntity && compareId ? compareEntity + compareId : ''
                }
                todoId={
                    compareEntity === 'todo' && compareId
                        ? Number(compareId)
                        : todoId
                        ? Number(todoId)
                        : undefined
                }
                productId={
                    compareEntity === 'product' && compareId
                        ? Number(compareId)
                        : productId
                        ? Number(productId)
                        : undefined
                }
                isSplitViewMode={isSplitViewMode}
                isCompareEntitySelector={true}
            />
        </Box>
    )
}

export default CompareEntityTopbar
