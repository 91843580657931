import { uuid } from 'src/helpers'

export const fabricPresets: TFabric[] = [
    {
        name: 'Leather',
        id: uuid(),
        baseMap: '/fabrics/leather/WHL-Y1206_BASE.jpg',
        maps: [
            {
                map: '/fabrics/leather/WHL-Y1206_BASE.jpg',
                code: 'map',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/leather/WHL-Y1206_NRM.jpg',
                code: 'normalMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/leather/WHL-Y1206_MTL.jpg',
                code: 'metalnessMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/leather/WHL-Y1206_ROUGH.jpg',
                code: 'roughnessMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
        ],
    },
    {
        name: 'Cotton',
        id: uuid(),
        baseMap: '/fabrics/cotton/12 OZ RECYCLED CANVAS_BASE.png',
        maps: [
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_BASE.png',
                code: 'map',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_NRM.png',
                code: 'normalMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_MTL.png',
                code: 'metalnessMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
            {
                map: '/fabrics/cotton/12 OZ RECYCLED CANVAS_ROUGH.png',
                code: 'roughnessMap',
                config: {
                    metalnessIntensity: 0,
                    offset: {
                        u: 1,
                        v: 1,
                    },
                    rotation: 0,
                    roughnessIntensity: 0,
                    scale: {
                        u: 1,
                        v: 1,
                    },
                },
            },
        ],
    },
]
