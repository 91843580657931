import { gql } from '@apollo/client'

export const GetProductsForCollection = gql`
    query GetProductsForCollection($id: bigint!) {
        collections_by_pk(id: $id) {
            id
            name
            manager_id
            team_id
            other_data
            products(order_by: { id: desc }) {
                id
                name
                status
                due_date
                team_id
                tags
                other_data
                product_variants {
                    id
                    created_at
                    product_id
                    is_primary
                    name
                    team_id
                    is_delayed
                    description
                    due_date
                    approver_due_date
                    tags
                    status
                    other_data
                    approver_id
                    team {
                        id
                        name
                        team_members {
                            user {
                                other_data
                                name
                                id
                            }
                        }
                    }
                    collection {
                        id
                        name
                        manager_id
                    }
                    product_variant_approver {
                        id
                        name
                        email
                        other_data
                    }
                    product_variant_creator {
                        id
                        name
                        files(where: { type: { _eq: "profile_pic" } }) {
                            path
                        }
                    }
                    totalTodos: todos_aggregate {
                        aggregate {
                            count
                        }
                    }
                    completedTodos: todos_aggregate(
                        where: { status: { _eq: complete } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                }
            }
        }
    }
`
