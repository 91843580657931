import { gql } from "@apollo/client";

export const UpdateFileCount = gql`
mutation fileRequests($action: String, $entity_name: String, $number_of_files: Int, $entity_id: bigint) {
  insert_file_actions_requests(objects: {entity_id: $entity_id, entity_name: $entity_name, number_of_files: $number_of_files, action: $action}) {
    returning {
      request_id
    }
  }
}
`