import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { convertIntoElipses } from '../list-item/list-item'

export const DrawerTabSliders = ({
    value,
    setValue,
    tabs,
}: {
    value: number
    setValue: React.Dispatch<React.SetStateAction<number>>
    tabs: { heading: string; id: number }[]
}) => {
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    }

    return (
        <Tabs value={value} onChange={handleChange} aria-label="people-tabs">
            {tabs.map((tab) => {
                return (
                    <Tab
                        key={tab.id}
                        label={convertIntoElipses(tab.heading, 26)}
                        style={{ fontSize: '12px' }}
                        value={tab.id}
                        title={tab.heading}
                        {...a11yProps(0)}
                    />
                )
            })}
        </Tabs>
    )
}
