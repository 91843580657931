import { gql } from '@apollo/client'

export const GetTeams = gql`
    query get_teams {
        teams {
            name
            id
            collections {
                due_date
                id
                name
                status
            }
            products {
                due_date
                id
                name
                todos {
                    id
                    name
                    status
                }
                status
            }
            team_members {
                id
                user {
                    email
                    name
                    id
                    files(where: { type: { _eq: "profile_pic" } }) {
                        path
                    }
                    assigned_todos {
                        name
                        status
                    }
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`

export const GetIndividualTeams = gql`
    query get_teams {
        teams {
            name
            id
            team_members {
                id
                user {
                    email
                    name
                    id
                    files(where: { type: { _eq: "profile_pic" } }) {
                        gid
                        path
                    }
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`

export const GetTeamsByUserId = gql`
    query GetTeamsByMemberId($member_id: bigint!) {
        teams(where: { team_members: { id: { _eq: $member_id } } }) {
            name
            id
            created_at
            products_aggregate {
                aggregate {
                    count
                }
            }
            team_members {
                user {
                    name
                    other_data
                }
            }
            products {
                id
                name
                todos_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`
