import React, { useEffect } from 'react'
import { Box, Button } from 'src/components/utility'
import { useTodoSectionContext } from '../todo-section/todo-section.context'
import PreviewSection from 'src/components-v2/file-preview-modal/preview-section/preview-section'
import { FileDisplay2 } from 'src/components/file-display/file-display-2'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { getThumbnail } from 'src/components/annotation-comments/annotation-comments.helper'
import { StrokedArrowLeft } from 'src/components/svg-icons'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { useSearchParams } from 'react-router-dom'

function FilePreviewSection({
    isSplitView,
    onClose,
    productId,
    isCompareSection,
    enableKeyboardNav,
}: {
    productId?: number
    isSplitView?: boolean
    onClose?: () => void
    isCompareSection?: boolean
    enableKeyboardNav: boolean
}) {
    const { activeFileId, files, sectionId, setActiveMode, setActiveFileId } =
        useTodoSectionContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeFile = files.find(
        (file) =>
            file?.id === activeFileId ||
            Number(file.file?.id) === Number(activeFileId) ||
            Number(file.library_file?.id) === Number(activeFileId)
    )

    function nextButtonClickHandler() {
        const activeFileIndex = files.findIndex(
            (file) => file.id === activeFile.id
        )
        const nextIndex =
            activeFileIndex === files.length - 1 ? 0 : activeFileIndex + 1
        scrollToSection(
            `file_display_${files[nextIndex].id}_preview_modal_${
                isCompareSection ? 'compare_section' : 'main_section'
            }`
        )
        setActiveFileId(files[nextIndex].id)
    }

    function prevButtonClickHandler() {
        const activeFileIndex = files.findIndex(
            (file) => file.id === activeFile.id
        )
        const nextIndex =
            activeFileIndex === 0 ? files.length - 1 : activeFileIndex - 1
        scrollToSection(
            `file_display_${files[nextIndex].id}_preview_modal_${
                isCompareSection ? 'compare_section' : 'main_section'
            }`
        )
        setActiveFileId(files[nextIndex].id)
    }

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (!enableKeyboardNav) return
            switch (event.key) {
                case 'ArrowRight':
                    nextButtonClickHandler()
                    break
                case 'ArrowLeft':
                    prevButtonClickHandler()
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [files, activeFile, enableKeyboardNav])

    return (
        <Box
            borderTop="1px solid"
            style={{ borderRadius: isSplitView ? '16px 16px 0px 0px' : '0px' }}
            borderColor="#E8ECEF"
            px="24px"
            py={isSplitView ? '8px' : '24px'}
            bg="#FAFCFF"
            height={isSplitView ? '100%' : '95vh'}
            minHeight={'500px'}
            display="grid"
            gridTemplateRows={isSplitView ? '1fr' : '1fr 90px'}
            flexDirection="column"
            gridGap={isSplitView ? '8px' : '16px'}
        >
            <Box
                display="grid"
                gridTemplateColumns={'auto 1fr auto'}
                alignItems="center"
                gridGap="4px"
                width={isSplitView ? 'undefined' : '80vw'}
                height={'100%'}
            >
                <Button
                    onClick={prevButtonClickHandler}
                    bg="#F2F4F6"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                >
                    <StrokedArrowLeft color="black" />
                </Button>

                {activeFile !== undefined && (
                    <PreviewSection
                        onClose={() => {
                            searchParams.delete('sectionId')
                            searchParams.delete('fileId')
                            searchParams.delete('showComments')
                            searchParams.delete('mode')
                            searchParams.delete('commentId')
                            setSearchParams(searchParams, { replace: true })
                            onClose ? onClose() : setActiveMode('sections')
                        }}
                        file={activeFile}
                        additionalParams={[
                            { key: 'entityName', value: 'sub_todo_sections' },
                            { key: 'entityId', value: sectionId },
                            { key: 'productId', value: productId || 0 },
                        ]}
                        commentsEntityName={'assigned_files'}
                        isSplitMode={isSplitView}
                        entityId={sectionId || 0}
                        productId={productId}
                    />
                )}
                <Button
                    onClick={nextButtonClickHandler}
                    bg="#F2F4F6"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                >
                    <StrokedArrowLeft color="black" rotate />
                </Button>
            </Box>
            {!isSplitView && (
                <Box
                    display="flex"
                    justifyContent={
                        isSplitView || (files && files.length > 8)
                            ? 'start'
                            : 'center'
                    }
                    overflowX="auto"
                    width={isSplitView ? '100%' : '80vw'}
                    gridGap="16px"
                    pt="4px"
                    className="scrollbar_none"
                >
                    {files?.map((file: any) => {
                        const fileData = file.library_file
                            ? {
                                  ...file.library_file,
                                  from_library: true,
                              }
                            : file.file
                            ? {
                                  ...file.file,
                                  from_library: false,
                              }
                            : null
                        if (!fileData) return <></>
                        return (
                            <FileDisplay2
                                isDownloadable
                                key={file.id}
                                onClick={() => {
                                    setActiveFileId(file.id)
                                }}
                                name={fileData.name}
                                url={
                                    fileData.from_library
                                        ? getLibraryFileUrl(fileData.gid)
                                        : getImageUrl(fileData.gid)
                                }
                                thumbnail={getThumbnail(fileData)}
                                onDelete={() => {}}
                                width="80px"
                                minWidth="80px"
                                height="84px"
                                isDeletePermitted={false}
                                selected={file.id === activeFileId}
                                librarySelect={true}
                                fileId={file.id}
                                id={`file_display_${file.id}_preview_modal_${
                                    isCompareSection
                                        ? 'compare_section'
                                        : 'main_section'
                                }`}
                            />
                        )
                    })}
                </Box>
            )}
        </Box>
    )
}

export default React.memo(FilePreviewSection)
