import { gql } from '@apollo/client'

export const GetProductById = gql`
    query getProduct($id: bigint!, $organisation_id: bigint!) {
        products_by_pk(id: $id) {
            created_at
            created_by
            approver_due_date
            approver_id
            collection_id
            status
            due_date
            is_delayed
            description
            id
            name
            tags
            team_id
            request_revision_info
            collection {
                manager {
                    other_data
                    name
                    id
                    email
                }
                id
                name
            }
            approver {
                email
                name
                id
                other_data
            }
            team {
                name
                id
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                    }
                }
            }
            todos(order_by: { id: asc }) {
                created_at
                created_by
                product_id
                due_date
                id
                name
                description
                status
                duration
                other_data
                submitted_files
                files {
                    file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                    }
                    library_file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                    }
                }
                assignee {
                    email
                    name
                    id
                    other_data
                    organisation_members(
                        where: { organisation_id: { _eq: $organisation_id } }
                    ) {
                        role
                    }
                }
                sub_todos {
                    description
                    heading
                    created_at
                    id
                    todo_id
                    other_data
                    submitted_files
                    files {
                        file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                        }
                        library_file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                        }
                    }
                }
            }
        }
    }
`

export const GetTeamMembersByEmailProductId = gql`
    query getTeamMembersByEmail($product_id: bigint!, $searchStr: String!) {
        team_members(
            where: {
                team: { products: { id: { _eq: $product_id } } }
                user: { email: { _ilike: $searchStr } }
            }
        ) {
            team_id
            user_id
            id
            user {
                email
                name
                id
                other_data
            }
        }
    }
`

export const GetCommentsCountByEntityId = gql`
    query getCommentsByEntityId($id: bigint) {
        comments(where: { entity_id: { _eq: $id } }) {
            id
        }
    }
`
