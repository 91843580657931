import { gql } from "@apollo/client";

// export const RemoveUserFromOrganisation = gql`
// mutation removeUserFromOrganisation($id: bigint!) {
//   delete_organisation_members(where: {user_id: {_eq: $id}}) {
//     affected_rows
//     returning {
//       id
//     }
//   }
// }
// `;
export const RemoveUserFromOrganisation = gql`
mutation deleteUserFromOrganisation($id:bigint,$deleted_at:timestamptz) {
  update_organisation_members(where: {user_id: {_eq: $id}}, _set: {deleted_at:$deleted_at}) {
    affected_rows
  }
}`
