import React, { useState } from 'react'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import { Box, Button } from 'src/components/utility'
import { ExpandReferencePicModal } from '../todo/subtodo-card/modals/expand-reference-pic.modal'
import { getThumbnailPictureFromGid } from 'src/helpers/get-thumbnail-picture'

function ThumbnailFullView({
    gid,
    fromLibrary,
}: {
    gid: string
    fromLibrary: boolean
}) {
    const [showThumbnailExpandView, setShowThumbnailExpandView] =
        useState(false)
    const coverPicUrl = getThumbnailPictureFromGid({ gid, fromLibrary })

    return (
        <Box position="absolute" top="0px" right="0px">
            <Button
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setShowThumbnailExpandView(!showThumbnailExpandView)
                }}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="10px"
                color="#027AC3"
                position="relative"
                width="max-content"
                id="subtodo-reference-menu-button"
            >
                <FullScreenIco size="10" color="black" />
            </Button>
            {showThumbnailExpandView && (
                <ExpandReferencePicModal
                    onClose={() => setShowThumbnailExpandView(false)}
                    url={coverPicUrl}
                />
            )}
        </Box>
    )
}

export default ThumbnailFullView
