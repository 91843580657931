import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useUpdateTodo from 'src/components-v2/cards/hooks/useUpdateTodo'
import TeamCard from 'src/components-v2/cards/team/team.card'
import type { TSearchedTeams } from 'src/components-v2/global-search/@types/search'
import { Box, Table, Tbody } from 'src/components/utility'
import type {
    TGqlTeams,
    TGqlTodos,
} from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import useStore from 'src/store/hooks/useStore'
import { useApolloClient } from '@apollo/client'
import { EmptyTodoState } from 'src/screens/dashboard/sub-screens/todos/empty-state-todo'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'
// import TeamCard from 'src/screens-v2/organisations-analytics/tab-data/cards/team-card'

export interface IContributorTabDetailsProps {
    todos: TGqlTodos[]
    refetchTodos?: () => void
    teams: TSearchedTeams[] | TGqlTeams[]
    currentTab: 'todos' | 'teams'
    isTabView: boolean
}

export default function ContributorTabDetails(
    props: IContributorTabDetailsProps
) {
    const { updateTodo } = useUpdateTodo()
    const { isTabView } = props
    const { clearDrawerTabs } = useStore()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const client = useApolloClient()

    switch (props.currentTab) {
        case 'todos':
            return (
                <Box
                    mt="24px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="8px"
                >
                    {props.todos.length > 0 ? (
                        props.todos.map((todo) => {
                            return (
                                <TodoListCard
                                    navigateTo={`/todo/${todo.id}`}
                                    onClick={() => {
                                        clearDrawerTabs()
                                    }}
                                    isFullCard={!isTabView}
                                    todo={{
                                        assignee: todo?.assignee && {
                                            id: Number(todo.assignee.id),
                                            name: todo.assignee.name,
                                            other_data:
                                                todo?.assignee?.other_data,
                                            email: todo?.assignee.email,
                                            organisation_members: todo?.assignee
                                                ?.organisation_members
                                                ? [
                                                      {
                                                          role: todo?.assignee
                                                              ?.organisation_members[0]
                                                              ?.role as
                                                              | 'admin'
                                                              | 'designer'
                                                              | 'manager',
                                                      },
                                                  ]
                                                : [],
                                        },
                                        coverPic: todo.other_data.cover_pic && {
                                            assignee_id:
                                                todo?.assignee?.id || '',
                                            fromLibrary:
                                                todo.other_data.cover_pic
                                                    ?.fromLibrary || false,
                                            gid:
                                                todo.other_data.cover_pic
                                                    ?.gid || '',
                                            name:
                                                todo.other_data.cover_pic
                                                    ?.name || '',
                                            path:
                                                todo.other_data.cover_pic
                                                    ?.path || '',
                                            thumbnail:
                                                todo.other_data.cover_pic
                                                    ?.thumbnail || '',
                                        },
                                        dueDate: todo.due_date
                                            ? new Date(
                                                  todo.due_date
                                              ).toISOString()
                                            : null,
                                        id: Number(todo.id),
                                        isDelayed: todo?.is_delayed || false,
                                        name: todo.name,
                                        product: {
                                            coverPic: todo.product?.other_data
                                                ? {
                                                      assignee_id:
                                                          todo.assignee?.id ||
                                                          '',
                                                      fromLibrary:
                                                          todo.product
                                                              ?.other_data
                                                              ?.cover_pic
                                                              ?.fromLibrary ||
                                                          false,
                                                      gid:
                                                          todo.product
                                                              ?.other_data
                                                              ?.cover_pic
                                                              ?.gid || '',
                                                      name:
                                                          todo.product
                                                              ?.other_data
                                                              ?.cover_pic
                                                              ?.name || '',
                                                      path:
                                                          todo.product
                                                              ?.other_data
                                                              ?.cover_pic
                                                              ?.path || '',
                                                      thumbnail:
                                                          todo.product
                                                              ?.other_data
                                                              ?.cover_pic
                                                              ?.thumbnail || '',
                                                  }
                                                : null,
                                            id: Number(todo.product?.id),
                                            name: todo.product?.name || '',
                                        },
                                        status: todo.status,
                                        collection: {
                                            id: Number(
                                                todo.product?.collection?.id
                                            ),
                                            manager_id: Number(
                                                todo.product?.collection
                                                    ?.manager_id
                                            ),
                                            name:
                                                todo.product?.collection
                                                    ?.name || '',
                                        },
                                    }}
                                />
                            )
                        })
                    ) : (
                        <EmptyTodoState
                            boxHeight={'500px'}
                            title={'No to-do’s yet?'}
                            description={'No To-Do’s are assigned to you.'}
                            enableCreateButton={false}
                        />
                    )}
                </Box>
            )
        case 'teams':
            return (
                <Table className="row-gap-8px" mt="16px" width="100%">
                    <Tbody width="100%">
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="8px"
                        >
                            {props.teams.length > 0 ? (
                                props.teams.map((team) => {
                                    return (
                                        <TeamCard
                                            isInTab={props.isTabView}
                                            entity={team}
                                        />
                                    )
                                })
                            ) : (
                                <EmptyTodoState
                                    boxHeight={'500px'}
                                    title={'No Teams yet?'}
                                    description={
                                        'You are not a part of any team!'
                                    }
                                    enableCreateButton={false}
                                />
                            )}
                        </Box>
                    </Tbody>
                </Table>
            )
        default:
            return <></>
    }
}
