import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { InlineError, Modal } from 'src/components'
import { Box, Text, Input, Span } from 'src/components/utility'
import { FetchFolderByName } from 'src/services'
import {
    InsertFolder,
    UpdateFolderName,
} from 'src/services/graphql/mutations/library.mutations'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { debounce } from 'src/helpers'

export const AddFolderModal = ({
    isModal,
    setModal,
    refetchFolders,
    edit = false,
    existingFolder,
    existingCategoryId,
}: {
    isModal: boolean
    setModal: (x: boolean) => void
    refetchFolders: (x: any) => void
    edit?: boolean
    existingFolder?: FolderObject
    existingCategoryId?: string
}) => {
    const [searchParams] = useSearchParams()
    const [folder, setFolderName] = useState<string>()
    const [folderAlreayExists, setFolderAlreayExists] = useState<boolean>(false)
    const [insertFolderOne, folderInserting] = useMutation(InsertFolder)
    const [updateFolder, folderUpdating] = useMutation(UpdateFolderName)
    const [fetchFolder] = useLazyQuery(FetchFolderByName)

    const categoryId =
        searchParams.get(libraryDefaultKeys.categoryId) || existingCategoryId
    const params = useParams()
    const folderId = params?.folderId
    const _errorMessage: string = 'The folder name already exists.'

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debounceFn = debounce(
        () => {
            findingFolder()
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        if (edit && existingFolder) {
            setFolderName(existingFolder.name)
        }
    }, [existingFolder, edit])

    useEffect(() => {
        if (folder) {
            debounceFn()
        } else {
            setFolderAlreayExists(false)
        }
    }, [folder])

    const findingFolder = () => {
        if (edit && existingFolder && existingFolder.name) {
            if (folder === existingFolder.name) {
                setFolderAlreayExists(false)
                return
            }
        }
        let whereObj: any = {
            name: { _ilike: `%${folder}%` },
            parent_id: { _is_null: true },
            category_id: { _eq: categoryId },
            deleted_at: { _is_null: true },
        }
        if (folderId && folderId !== '0') {
            whereObj['parent_id'] = { _eq: folderId }
        }
        setFolderAlreayExists(false)
        fetchFolder({
            variables: {
                whereObj,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (data: any) => {
                const _exFolders: any[] = data.folders
                let found = _exFolders.find(
                    (item: any) =>
                        item.name.toLowerCase() === folder?.toLowerCase()
                )
                if (found) {
                    setFolderAlreayExists(true)
                }
            },
        })
    }

    const updateFolderDetails = () => {
        try {
            let variables: any = {
                name: folder,
                id: existingFolder?.id,
            }

            updateFolder({
                variables,
                onCompleted: () => {
                    toast.success('Folder updated successfully!', {
                        className: 'custom-toaster toaster-success',
                    })
                    refetchFolders(folder)
                    setModal(false)
                },
                onError: (error) => {
                    console.error(error)
                    toast.error('Failed to update folder!', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    const addNewFolder = () => {
        if (!folder || !folder.trim()) {
            toast.error('Please add folder name.', {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        if (folder.length > 25) {
            toast.error('Characters must be less than or equal to 25.', {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        if (existingFolder?.id && edit) {
            updateFolderDetails()
            return
        }

        if (!categoryId) {
            console.error('Category id required!')
            return
        }

        if (folderAlreayExists) {
            toast.error(_errorMessage, {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        try {
            let variables: any = {
                name: folder,
                category_id: categoryId,
            }

            if (folderId && folderId !== '0') {
                variables.parent_id = folderId
            }

            insertFolderOne({
                variables,
                onCompleted: () => {
                    toast.success('Folder added successfully!', {
                        className: 'custom-toaster toaster-success',
                    })
                    refetchFolders(true)
                    setModal(false)
                },
                onError: (error) => {
                    console.error(error)
                    toast.error('Failed to add folder!', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    const title = edit ? 'Edit Folder' : 'Create New Folder'

    return (
        <Modal
            isModal={isModal}
            onClick={() => addNewFolder()}
            onClose={() => setModal(false)}
            primaryButtonTitle={edit ? 'Update' : 'Create'}
            secondaryButtonTitle="Cancel"
            title={title}
            isLoading={folderInserting.loading || folderUpdating.loading}
            disablePrimaryButton={
                !folder ||
                !folder.trim() ||
                folderInserting.loading ||
                folderUpdating.loading ||
                folderAlreayExists
            }
        >
            <Box p={'24px'} pb={'80px'}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    alignItems="start"
                    gridRowGap="12px"
                    fontSize="14px"
                >
                    <Text
                        mt="0px"
                        mb="0px"
                        fontFamily="Rubik"
                        color="primary"
                        textAlign="left"
                        width="100%"
                    >
                        Folder Name
                    </Text>
                    <Input
                        autoComplete="false"
                        placeholder="Enter"
                        bg="white"
                        borderRadius="4px"
                        px="16px"
                        py="16px"
                        minWidth="360px"
                        fontSize="14px"
                        border="solid"
                        borderWidth={1}
                        borderColor="gray350"
                        className="placeholder-darkgray"
                        type="text"
                        maxLength={25}
                        minLength={3}
                        value={folder}
                        required
                        autoFocus={true}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFolderName(e.target.value)
                        }}
                    />

                    <Span fontSize={'14px'} color="textSecondary">
                        {' '}
                        {25 - (folder?.length || 0)} characters left
                    </Span>
                    {folderAlreayExists && (
                        <InlineError width="auto" text={_errorMessage} />
                    )}
                </Box>
            </Box>
        </Modal>
    )
}
