import React, { useEffect, useReducer, useState } from 'react'
import { TodoBadge } from 'src/components-v2/badges/todo-badge'
import {
    CollectionIco,
    Dots,
    DropDownArrow,
    ProductIco,
} from 'src/components/svg-icons'
import { Box, Button, Image, Input, Text } from 'src/components/utility'
import { debounce, getFormattedDate, uuid, type statuses } from 'src/helpers'
import TodoStatus from './todo-status/todo-status'
import { useMutation } from '@apollo/client'
import { UpdateTodo } from '../../../services/graphql/entities/todo/todo.mutation'
import TodoComments from 'src/components-v2/todos/todo-activities/todo-comments/todo-comments'
import TodoActivities from 'src/components-v2/todos/todo-activities/todo-activites'
import { TodoMenuDropdown } from 'src/components/collections/todo-menu-dropdown'
import AdditionalInfoDropdown from '../../dropdowns/additional-info-dropdown/additional-info-dropdown'
import UploadProductThumbnailMenuDropdown from 'src/components-v2/upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { toast } from 'react-toastify'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import SaveTemplateModal from 'src/components/drawer/todo-tab/save-template/save-template-modal'
import SaveTemplateDetailsModal from 'src/components/drawer/todo-tab/save-template/save-template-details-modal'
import { ArrowDown } from 'src/assets/svgs-components'
import VariantsDropdown from 'src/components-v2/product-variants/variants-dropdown'
import useUpdateTodo from 'src/hooks/useUpdateTodo'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import AssignUser from 'src/components-v2/dropdown-badges/assign-user/assign-user'
import { Link } from 'react-router-dom'
import useStore from 'src/store/hooks/useStore'
import { Loader } from 'src/components/loader/loader'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import ThumbnailFullView from 'src/components-v2/thumbnail-badge/thumbnail-full-view'
import { getThumbnailPicture } from 'src/helpers/get-thumbnail-picture'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import CalendarDropdown from 'src/components-v2/cards/dropdowns/calendar'

type TodoData = {
    name: string
    due_date: string | null
    status: keyof typeof statuses
    collection?: {
        id: number
        name: string
    }
    assignee: {
        id: number
        name: string
        other_data: {
            profile_pic: string
        } | null
    }
}

function TodoHeader({
    todo,
    isSplitMode,
    refetchTodo,
    isTemplateChosen,
    isCompareSection,
    subtodos,
}: {
    todo: any
    isSplitMode?: boolean
    refetchTodo: () => void
    isTemplateChosen: boolean
    isCompareSection?: boolean
    subtodos: Array<any>
}) {
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)

    const [componentVisibility, setComponentVisibility] = useState({
        menuDropdown: false,
        additionalInfo: false,
        addThumbnailDropdown: false,
        saveTemplateModal: false,
        saveTemplateDetailsModal: false,
        variantsDropdown: false,
        expandViewModal: false,
    })

    const initialTodoData: TodoData = {
        name: todo.name,
        due_date: todo.due_date,
        status: todo.status,
        collection: todo.product_variant?.collection,
        assignee: todo.assignee,
    }

    const [updateTodoMutation] = useMutation(UpdateTodo)
    const { updateTodoDueDate, updateTodoAssignee } = useUpdateTodo()
    const { updateGlobalSearchResults } = useStore()
    const { assignFilesToSection } = useAssignFile(() => {
        refetchTodo && refetchTodo()
    })
    const { isUploading, onFileUpload } = useUploadFile(
        String(todo.id),
        'todos'
    )

    function updateTodoHandler(payload: any) {
        updateTodoMutation({
            variables: {
                id: todo.id,
                payload,
            },
            onCompleted: () => {
                refetchTodo()
            },
        })
    }

    const debouncedUpdateTodoHandler = debounce(
        updateTodoHandler,
        1500,
        setTimeOutRef,
        timeOutRef
    )

    function todoReducer(state: any, action: { type: string; payload?: any }) {
        switch (action.type) {
            case 'SET_DATA': {
                return {
                    ...state,
                    ...action.payload,
                }
            }
            case 'UPDATE_NAME': {
                return {
                    ...state,
                    name: action.payload,
                }
            }
            case 'UPDATE_DUE_DATE': {
                return {
                    ...state,
                    due_date: action.payload,
                }
            }
            case 'UPDATE_ASSIGNEE': {
                return {
                    ...state,
                    assignee: action.payload,
                }
            }
            case 'REMOVE_ASSIGNEE': {
                return {
                    ...state,
                    assignee: null,
                }
            }
            default:
                return state
        }
    }

    const [todoData, updateTodoData] = useReducer(todoReducer, initialTodoData)

    function updateDueDateHandler(due_date: string) {
        updateTodoData({
            type: 'UPDATE_DUE_DATE',
            payload: due_date,
        })
        updateTodoDueDate({
            id: todo.id,
            due_date,
            onCompleted: () => {
                updateGlobalSearchResults({
                    id: String(todo.id),
                    type: 'todos',
                    updatedKey: 'due_date',
                    value: due_date,
                })
            },
        })
    }

    function updateNameHandler(name: string) {
        updateTodoData({ type: 'UPDATE_NAME', payload: name })
        if (name.trim().length > 0) {
            debouncedUpdateTodoHandler({ name: name.trimStart() })
        }
    }

    function changeAssigneeHandler({
        type,
        user,
    }: { type: 'ASSIGN'; user: any } | { type: 'REMOVE'; user?: any }) {
        if (type === 'ASSIGN') {
            updateTodoData({
                type: 'UPDATE_ASSIGNEE',
                payload: {
                    id: Number(user.id),
                    name: user.name,
                    other_data: user.other_data?.profile_pic
                        ? {
                              profile_pic: user.other_data.profile_pic,
                          }
                        : null,
                },
            })
            updateTodoAssignee({
                todoId: todo.id,
                assignee_id: user.id,
            })
        } else {
            updateTodoData({
                type: 'REMOVE_ASSIGNEE',
            })
            updateTodoAssignee({
                todoId: todo.id,
                assignee_id: null,
            })
        }
    }

    async function uploadImageHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        todoId: number
    ) {
        setComponentVisibility({
            ...componentVisibility,
            addThumbnailDropdown: false,
        })
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)

            const file = e.target.files[0]
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: file,
                            id: uuid(),
                            name: file.name,
                            path: getLocalImageUrl(file) || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            const fileId = data?.resolvedArr[0].id
            if (fileId) {
                assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityId: String(todoId),
                    entityName: 'todos',
                    fileId: [data?.resolvedArr[0].id],
                })
            }
            setIsThumbnailUploading(false)
        } catch {
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsThumbnailUploading(false)
        }
    }

    // Update the data in the state when the user switches between the todos
    useEffect(() => {
        updateTodoData({
            type: 'SET_DATA',
            payload: {
                name: todo.name,
                due_date: todo.due_date,
                status: todo.status,
                collection: todo.product_variant?.collection,
                assignee: todo.assignee,
            },
        })
    }, [todo.id])

    const coverPicThumbnail = getThumbnailPicture({
        coverPic: todo.other_data?.cover_pic,
        parentCoverPic: todo?.product_variant?.other_data?.cover_pic,
        fallbackPlaceholder: false,
    })

    return (
        <Box
            display="flex"
            gridGap="16px"
            px="24px"
            py="12px"
            position="relative"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridGap="4px"
                p="8px"
                border="1px solid"
                borderColor={
                    componentVisibility.variantsDropdown ? 'primary' : '#E8ECEF'
                }
                borderRadius="4px"
                width="82px"
                position="relative"
            >
                <Box
                    onClick={() =>
                        setComponentVisibility({
                            ...componentVisibility,
                            addThumbnailDropdown:
                                !componentVisibility.addThumbnailDropdown,
                        })
                    }
                    className="cursor-pointer"
                    id="thumbnail-button"
                >
                    {coverPicThumbnail && (
                        <ThumbnailFullView
                            gid={
                                todo.other_data?.cover_pic?.gid ||
                                todo.product_variant?.other_data?.cover_pic?.gid
                            }
                            fromLibrary={
                                todo.other_data?.cover_pic?.fromLibrary ||
                                todo.product_variant?.other_data?.cover_pic
                                    ?.fromLibrary
                            }
                        />
                    )}
                    {isThumbnailUploading ? (
                        <Loader outline="black" size="36px" />
                    ) : (
                        <Image
                            src={coverPicThumbnail || '/image-placeholder.jpg'}
                            alt="image"
                            width="53px"
                            height="53px"
                            borderRadius="4px"
                            id="thumbnail-image"
                        />
                    )}
                    {componentVisibility.addThumbnailDropdown && (
                        <UploadProductThumbnailMenuDropdown
                            active={componentVisibility.addThumbnailDropdown}
                            onClose={() => {
                                setComponentVisibility({
                                    ...componentVisibility,
                                    addThumbnailDropdown: false,
                                })
                            }}
                            entityId={todo.id}
                            productId={todo?.product_variant?.id || 0}
                            entityName="todos"
                            onUpload={(e) => uploadImageHandler(e, todo.id)}
                            refetchProduct={refetchTodo}
                        />
                    )}
                </Box>
                <Box position="relative">
                    <Button
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ gap: '4px' }}
                        border="none"
                        bg="transparent"
                        textAlign="left"
                        p="0px"
                        id="variants-dropdown-button"
                        onClick={() =>
                            setComponentVisibility({
                                ...componentVisibility,
                                variantsDropdown:
                                    !componentVisibility.variantsDropdown,
                            })
                        }
                    >
                        <Text
                            fontSize={'10px'}
                            my="0px"
                            mt="4px"
                            style={{ wordBreak: 'break-all' }}
                            title={todo?.product_variant?.name}
                            id="variant-name-badge"
                        >
                            {todo.product_variant?.name
                                ? convertIntoElipses(
                                      todo.product_variant.name,
                                      6
                                  )
                                : ''}
                        </Text>
                        <Box pt="4px">
                            <ArrowDown id="variant-dropdown-arrow" />
                        </Box>
                    </Button>
                    {componentVisibility.variantsDropdown && (
                        <VariantsDropdown
                            onClose={() =>
                                setComponentVisibility({
                                    ...componentVisibility,
                                    variantsDropdown: false,
                                })
                            }
                            activeVariantId={todo?.product_variant?.id}
                            productId={todo?.product_variant?.product_id}
                            productName={
                                todo?.product_variant?.product?.name || ''
                            }
                            todoType={todo.types}
                            isCompareSection={isCompareSection}
                            isSplitMode={isSplitMode}
                            isTodoPage={true}
                        />
                    )}
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                py="8px"
                width="100%"
                gridGap="8px"
            >
                <Box
                    display="flex"
                    gridGap="16px"
                    width="100%"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        width="100%"
                        gridGap="16px"
                        alignItems="center"
                    >
                        <TodoBadge />
                        <Input
                            value={todoData.name}
                            placeholder="Todo name"
                            bg="transparent"
                            onChange={(e) => updateNameHandler(e.target.value)}
                            fontSize="16px"
                            color="primary"
                            border="none"
                            minWidth="100px"
                            width={`${Math.max(
                                100,
                                todoData.name.length * 8
                            )}px`}
                            maxWidth="50vw"
                            title={todoData.name}
                            className="border-on-hover"
                            px="4px"
                            py="4px"
                        />
                        {todo.product_variant?.name && (
                            <Box
                                as={Link}
                                to={`/collections/product/${todo.product_variant.id}`}
                                display="flex"
                                gridGap="8px"
                                alignItems="center"
                                style={{ textDecoration: 'none' }}
                                color="links"
                            >
                                <ProductIco color="#027AC3" />
                                <Text
                                    my="0px"
                                    width="100%"
                                    title={todo.product_variant.name}
                                    color="links"
                                    fontSize={'14px'}
                                >
                                    {isSplitMode
                                        ? convertIntoElipses(
                                              todo.product_variant.name,
                                              20
                                          )
                                        : todo.product_variant.name}
                                </Text>
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center" gridGap="8px">
                        <TodoComments
                            todoId={todo.id}
                            todoName={todo.name}
                            collectionTeam={todo?.product_variant?.team}
                        />
                        <TodoActivities todo={todo} />
                        <Box position="relative">
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        menuDropdown:
                                            !componentVisibility.menuDropdown,
                                    })
                                }}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="#027AC3"
                                position="relative"
                                width="max-content"
                            >
                                <Dots
                                    active={componentVisibility.menuDropdown}
                                />
                            </Button>
                            {componentVisibility.menuDropdown ? (
                                <TodoMenuDropdown
                                    active={componentVisibility.menuDropdown}
                                    setIsDropdownActive={(value: boolean) => {
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown: value,
                                        })
                                    }}
                                    todo={todo}
                                    isFullPage={true}
                                    onSaveAsTemplate={() => {
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            saveTemplateModal: true,
                                        })
                                    }}
                                    isTemplateChosen={isTemplateChosen}
                                    isCompareSection={isCompareSection}
                                    refetchTodo={refetchTodo}
                                />
                            ) : null}
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    gridGap="16px"
                    width="100%"
                    alignItems="center"
                >
                    {!isSplitMode && todo.product_variant?.collection && (
                        <Box
                            to={`/collections/${todo.product_variant.collection.id}`}
                            as={Link}
                            display="flex"
                            gridGap="8px"
                            alignItems="center"
                            style={{ textDecoration: 'none' }}
                            color="links"
                        >
                            <CollectionIco color="#027AC3" />
                            <Text my="0px" fontSize="14px">
                                {todo.product_variant.collection.name}
                            </Text>
                        </Box>
                    )}
                    {!isSplitMode && (
                        <AssignUser
                            assignee={todoData?.assignee}
                            onRemove={() => {
                                changeAssigneeHandler({ type: 'REMOVE' })
                            }}
                            onAssign={(user) => {
                                changeAssigneeHandler({ type: 'ASSIGN', user })
                            }}
                            role="Assignee"
                        />
                    )}
                    <Box display="flex" gridGap="8px" alignItems="center">
                        <CalendarDropdown
                            date={
                                todo?.due_date ? new Date(todo.due_date) : null
                            }
                            left={'0px'}
                            setDate={(date) => {
                                updateDueDateHandler(date.toISOString())
                            }}
                            isDelayed={isEntityDueDateDelayed(
                                todo.due_date,
                                todo.status
                            )}
                        />
                        <TodoStatus
                            todoStatus={todo.status}
                            todoId={todo.id}
                            refetchTodo={refetchTodo}
                            managerId={
                                todo.product_variant.collection?.manager_id
                            }
                        />
                    </Box>
                </Box>
                {!isSplitMode && (
                    <Box
                        display="flex"
                        justifyContent="end"
                        gridGap="8px"
                        width="100%"
                        borderBottom="1px solid #E8ECEF"
                    >
                        <Box
                            width="35px"
                            height="16px"
                            borderRadius="50px"
                            bg="#E8ECEF"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            border="1px solid #C2CFE0"
                            // @ts-ignore
                            mb="-8px"
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        additionalInfo:
                                            !componentVisibility.additionalInfo,
                                    })
                                }}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="#027AC3"
                                position="relative"
                                mt="4px"
                                width="max-content"
                                id="additional-info-dropdown-button"
                            >
                                <DropDownArrow
                                    rotate={componentVisibility.additionalInfo}
                                    color="#778CA2"
                                />
                            </Button>
                        </Box>
                        {componentVisibility.additionalInfo && (
                            <AdditionalInfoDropdown
                                creatorName={todo.creator.name}
                                createdDate={getFormattedDate(todo.created_at)}
                                updatedDate={getFormattedDate(todo.updated_at)}
                                description={todo.description}
                                onClose={() =>
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        additionalInfo: false,
                                    })
                                }
                                onDescriptionUpdate={(description) =>
                                    debouncedUpdateTodoHandler({
                                        description,
                                    })
                                }
                                tags={todo.tags}
                                onTagsUpdate={(tags) =>
                                    debouncedUpdateTodoHandler({
                                        tags,
                                    })
                                }
                            />
                        )}
                    </Box>
                )}
                {componentVisibility.saveTemplateModal && (
                    <SaveTemplateModal
                        isOpen={componentVisibility.saveTemplateModal}
                        onClose={() => {
                            setComponentVisibility({
                                ...componentVisibility,
                                saveTemplateModal: false,
                            })
                        }}
                        isTemplateCreating={false}
                        onClick={() => {
                            setComponentVisibility({
                                ...componentVisibility,
                                saveTemplateModal: false,
                                saveTemplateDetailsModal: true,
                            })
                        }}
                        subtodos={subtodos}
                    />
                )}
                {componentVisibility.saveTemplateDetailsModal && (
                    <SaveTemplateDetailsModal
                        isOpen={componentVisibility.saveTemplateDetailsModal}
                        onClose={() =>
                            setComponentVisibility({
                                ...componentVisibility,
                                saveTemplateDetailsModal: false,
                            })
                        }
                        todoId={todo.id}
                    />
                )}
            </Box>
        </Box>
    )
}

export default TodoHeader
