import * as React from 'react'
import { Box } from 'src/components/utility'
import DashboardSlider from '../dashboard-card.slider'
import { statuses } from 'src/helpers'
import TodoEntityList from './todo-entity-list.dashboard'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { useTodos } from '../hooks/use-todos'
import CollectionSearch from '../product/collection-search'
import { useSearchParams } from 'react-router-dom'
import { useCollectionFilter } from '../hooks/use-collections'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'

export interface ITodosDashboardCardProps {}

let TabList = [
    {
        label: 'All',
        value: 'all',
        count: '0',
    },
    {
        label: 'To-Do',
        value: statuses.new,
        count: '0',
    },
    {
        label: 'In Progress',
        value: statuses.inProgress,
        count: '0',
    },
    {
        label: 'Delayed',
        value: statuses.delayed,
        count: '0',
    },
    {
        label: 'Revision Requested',
        value: statuses.requestedForResubmission,
        count: '0',
    },
    {
        label: 'In Review',
        value: statuses.inReview,
        count: '0',
    },
]

export default function TodosDashboardCard(props: ITodosDashboardCardProps) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeSort, setActiveSort] = React.useState('Latest')
    const tab = searchParams.get('todoTab') as
        | 'all'
        | 'new'
        | 'inProgress'
        | 'delayed'
        | 'requestedForResubmission'

    const todoType =
        !searchParams.get('myTodos') || searchParams.get('myTodos') === 'true'
            ? "Your To-Do's"
            : "All To-do's"
    const [fetchTodoType, setFetchTodoType] = React.useState(todoType)
    const { filters } = useCollectionFilter('todos')
    const [selectedCollections, setSelectedCollections] = React.useState<
        {
            id: string
            name: string
        }[]
    >(filters['todos'] || [])
    const [tabs, setTabs] = React.useState({
        list: TabList,
        activeTab: (tab as string) || 'all',
    })
    const {
        all,
        delayed,
        inProgress,
        revisionRequested,
        toBeReviewed,
        new: newTodo,
        todos,
        complete,
        inReview,
        fetchMore,
        refetchAggregate,
        refetch,
        isLoading,
        networkStatus,
    } = useTodos({
        sort: getSort(activeSort),
        status: tab || 'all',
        myTodos: fetchTodoType === "Your To-Do's",
        collectionIds: selectedCollections.map((c) => c.id),
    })

    const { getLastOpenedTab } = useCardExpandedState()

    TabList = [
        {
            label: 'All',
            value: 'all',
            count: String(all),
        },
        {
            label: 'To-Do',
            value: statuses.new,
            count: String(newTodo),
        },
        {
            label: 'In Progress',
            value: statuses.inProgress,
            count: String(inProgress),
        },
        // {
        //     label: 'Delayed',
        //     value: statuses.delayed,
        //     count: String(delayed),
        // },
        {
            label: 'Revision Requested',
            value: statuses.requestedForResubmission,
            count: String(revisionRequested),
        },
        {
            label: 'In Review',
            value: statuses.inReview,
            count: String(inReview),
        },
        {
            label: 'To Be Reviewed',
            value: statuses.toBeReviewed,
            count: String(toBeReviewed),
        },
        {
            label: 'Completed',
            value: statuses.complete,
            count: String(complete),
        },
    ]

    function getSort(activeSort: string) {
        let tab = 'name'
        if (activeSort === 'Name') tab = 'name'
        else if (activeSort === 'Due Date') tab = 'due_date'
        else if (activeSort === 'Date Created') tab = 'created_at'
        else if (activeSort === 'Latest') tab = 'updated_at'
        else tab = 'name'
        return tab as 'name' | 'due_date' | 'created_at'
    }

    // Scroll to the section if the last opened tab is todo
    const isLastOpenedTabCollection = getLastOpenedTab('todo')
    React.useEffect(() => {
        if (isLastOpenedTabCollection) {
            scrollToSection('dashboard_todos')
        }
    }, [isLastOpenedTabCollection])

    return (
        <Box width="100%" id="dashboard_todos">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <CustomDropdown
                    label={''}
                    labelMaxWidth="200px"
                    highlightedTextFontSize="1rem"
                    selectedText={fetchTodoType}
                    onItemClicked={(item) => {
                        const isMyTodos = item === "Your To-Do's"
                        setFetchTodoType(item)
                        refetch()
                        searchParams.set('myTodos', `${isMyTodos}`)
                        setSearchParams(searchParams, { replace: true })
                        refetchAggregate({
                            myTodos: isMyTodos,
                        })
                    }}
                    items={["Your To-Do's", "All To-do's"]}
                />
                <Box display="flex" alignItems="center">
                    <CollectionSearch
                        type="todos"
                        onUnselect={(collection: {
                            id: string
                            name: string
                        }) => {
                            const ids = [collection, ...selectedCollections]
                                .filter((c) => c.id !== collection.id)
                                .map((c) => c.id)
                            setSelectedCollections((collections) =>
                                collections.filter(
                                    (c) => c.id !== collection.id
                                )
                            )
                            refetchAggregate({
                                myTodos: fetchTodoType === "Your To-Do's",
                                collectionId: ids,
                            })
                        }}
                        selectedCollections={selectedCollections}
                        onSelect={(collection: {
                            id: string
                            name: string
                        }) => {
                            const ids = [
                                collection,
                                ...selectedCollections,
                            ].map((c) => c.id)

                            setSelectedCollections([
                                ...selectedCollections,
                                collection,
                            ])
                            refetchAggregate({
                                myTodos: fetchTodoType === "Your To-Do's",
                                collectionId: ids,
                            })
                        }}
                    />
                    <CustomDropdown
                        onItemClicked={(activeSort) => {
                            setActiveSort(activeSort)
                        }}
                        selectedText={activeSort}
                        items={['Name', 'Due Date', 'Date Created', 'Latest']}
                        label="Sort:"
                    />
                </Box>
            </Box>
            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                borderRadius={'4px'}
                mt="12px"
                pt="16px"
                bg="white"
            >
                <DashboardSlider
                    tabs={TabList}
                    activeTab={tabs.activeTab}
                    setActiveTab={(activeTab) => {
                        setTabs((tabList) => ({
                            ...tabList,
                            activeTab,
                        }))
                        searchParams.set('todoTab', activeTab)
                        setSearchParams(searchParams, { replace: true })
                    }}
                />
                <TodoEntityList
                    isLoading={isLoading}
                    networkStatus={networkStatus}
                    onPageChange={() => {
                        let length = all
                        if (tabs.activeTab === 'all') length = all
                        if (tabs.activeTab === statuses.new) length = newTodo
                        if (tabs.activeTab === statuses.inProgress)
                            length = inProgress
                        if (tabs.activeTab === statuses.delayed)
                            length = delayed
                        if (
                            tabs.activeTab === statuses.requestedForResubmission
                        )
                            length = revisionRequested
                        if (todos.length < length) {
                            fetchMore()
                        }
                    }}
                    todos={todos}
                    totalTodos={
                        TabList.find((tab) => tab.value === tabs.activeTab)
                            ?.count || 0
                    }
                />
            </Box>
        </Box>
    )
}
