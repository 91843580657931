import React from 'react'

function CopyIco({ color = '#778CA2' }: { color?: string }) {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.19844 12.8C4.75844 12.8 4.38177 12.6433 4.06844 12.33C3.7551 12.0167 3.59844 11.64 3.59844 11.2V1.6C3.59844 1.16 3.7551 0.783333 4.06844 0.47C4.38177 0.156667 4.75844 0 5.19844 0H12.3984C12.8384 0 13.2151 0.156667 13.5284 0.47C13.8418 0.783333 13.9984 1.16 13.9984 1.6V11.2C13.9984 11.64 13.8418 12.0167 13.5284 12.33C13.2151 12.6433 12.8384 12.8 12.3984 12.8H5.19844ZM5.19844 11.2H12.3984V1.6H5.19844V11.2ZM1.99844 16C1.55844 16 1.18177 15.8433 0.868438 15.53C0.555104 15.2167 0.398438 14.84 0.398438 14.4V3.2H1.99844V14.4H10.7984V16H1.99844Z"
                fill={color}
            />
        </svg>
    )
}

export default CopyIco
