import * as React from 'react'
import DateFilter from './date-filter'
import { Box, Button } from 'src/components/utility'
import CollaboratorFilter from './collaborator-filter'
import TagFilter from './tags-filter'
import StatusFilter from './status-filter'
import useStore from 'src/store/hooks/useStore'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { globalSearch } from 'src/store/slice/global-search-slice'

export interface IFilterDropdownProps {}

export default function FilterDropdown(props: IFilterDropdownProps) {
    const { resetFilters, globalSearchFilters, isFilterModified } = useStore()
    const { getGlobalSearchData } = useGlobalSearch()
    function onReset() {
        const search = {
            ...globalSearch,
            results: globalSearchFilters.results,
            query: globalSearchFilters.query,
            isGlobalSearchActive: globalSearchFilters.isGlobalSearchActive,
        }
        if (JSON.stringify(search) !== JSON.stringify(globalSearchFilters)) {
            resetFilters({
                isGlobalSearchActive: true,
            })
            getGlobalSearchData({
                query: globalSearchFilters.query,
                filters: {
                    collaborators: [],
                    tags: [],
                    file_types: [],
                    status: [],
                    from: '',
                    to: '',
                },
            })
        }
    }
    return (
        <Box display="flex" gridColumnGap="12px">
            <DateFilter />
            <CollaboratorFilter />
            <TagFilter />
            <StatusFilter />
            <Button
                bg="transparent"
                color="links"
                border="none"
                onClick={() => {
                    onReset()
                }}
            >
                Reset Filters
            </Button>
        </Box>
    )
}
