import { gql } from '@apollo/client'

export const GetComments = gql`
    query getComments(
        $entity_id: bigint
        $entity_name: String
        $created_at_filter: timestamptz_comparison_exp!
    ) {
        comments(
            order_by: { created_at: desc }
            where: {
                entity_id: { _eq: $entity_id }
                entity_name: { _eq: $entity_name }
                created_at: $created_at_filter
            }
        ) {
            user {
                other_data
                name
            }
            id
            created_at
            comment
        }
    }
`
export const GetTodoComments = gql`
    query getTodoComments($entity_id_list: [bigint!]) {
        comments(where: { entity_id: { _in: $entity_id_list } }) {
            comment
            entity_id
        }
    }
`
