import * as React from 'react'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { CrossSm } from 'src/components/svg-icons'
import { Box, Button, Input } from 'src/components/utility'

export interface IFilterTagInputProps {
    onDelete: (index: number) => void
    tags: TTagsSuggestion[]
    setTags: (tag: TTagsSuggestion[]) => void
    suggestions: TTagsSuggestion[]
    onQuery: (query: string) => void
    placeholder?: string
}

export default function FilterTagInput(props: IFilterTagInputProps) {
    const {
        onDelete,
        onQuery,
        suggestions,
        tags,
        setTags,
        placeholder = 'Enter Tags',
    } = props
    const [query, setQuery] = React.useState('')

    return (
        <Box maxWidth="440px" display="flex" alignItems="center">
            <Box
                px="16px"
                py="8px"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gridRowGap="8px"
            >
                {tags.map((tag, index) => (
                    <TagComponent
                        key={index}
                        suggestion={tag}
                        onDelete={() => onDelete(Number(tag.id))}
                    />
                ))}
                <Box position="relative">
                    <Input
                        bg="transparent"
                        border="none"
                        placeholder={placeholder}
                        fontSize="14px"
                        onChange={(e) => {
                            setQuery(e.target.value)
                            onQuery(e.target.value)
                        }}
                        value={query}
                        onKeyDown={(e) => {
                            // e.key === 'Enter' && isEmailValid(query)
                            //     ? setTags((tags) => [
                            //           ...tags,
                            //           {
                            //               name: '',
                            //               email: query,
                            //               id: '',
                            //               other_data: {
                            //                   profile_pic: '',
                            //                   thumbnail: '',
                            //               },
                            //           },
                            //       ])
                            //     : ''
                            e.key === 'Backspace' &&
                                query.length <= 0 &&
                                setTags(tags.slice(0, tags.length - 1))
                        }}
                    ></Input>
                    <Dropdown
                        active={query.length > 0 && suggestions.length > 0}
                    >
                        <Box maxHeight="400px" overflowX="auto">
                            {/* <Box display="flex" flexDirection="column"> */}
                            {suggestions.map((item, index) => (
                                <DropdownButtonWrapper width="100%">
                                    <Button
                                        alignItems="center"
                                        height="100%"
                                        fontFamily="Rubik"
                                        color="primary"
                                        bg="transparent"
                                        border="none"
                                        py="10px"
                                        fontSize="14px"
                                        onClick={() => {
                                            setQuery('')
                                            setTags([...tags, item])
                                        }}
                                        // opacity={activeSort === roles.designer ? '0.5' : '1'}
                                    >
                                        <Box
                                            display="flex"
                                            gridColumnGap="12px"
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="start"
                                                gridRowGap="4px"
                                            >
                                                <Highlighted
                                                    unHighlighted="#778CA2"
                                                    search={query}
                                                    text={item.tag}
                                                />
                                            </Box>
                                        </Box>
                                    </Button>
                                </DropdownButtonWrapper>
                            ))}
                        </Box>
                        {/* </Box> */}
                    </Dropdown>
                </Box>
            </Box>
        </Box>
    )
}

function TagComponent(props: {
    suggestion: TTagsSuggestion
    onDelete: (id: number) => void
}) {
    return (
        <Box display="inline-block" mr={'8px'}>
            <Box
                px="8px"
                width="max-content"
                borderRadius="20px"
                py="4px"
                display="flex"
                fontFamily="Rubik"
                fontSize="12px"
                color="textTags"
                alignItems="center"
                justifyContent="space-between"
                bg={'#FFE395'}
            >
                {convertIntoElipses(props.suggestion.tag, 10)}
                <Button
                    onClick={() => props.onDelete(Number(props.suggestion.id))}
                    bg="transparent"
                    display="flex"
                    type="button"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CrossSm />
                </Button>
            </Box>
        </Box>
    )
}
function Suggestion(props: any) {
    return (
        <Box width="100%" color="primary" fontFamily="Rubik">
            {props.item.name}
        </Box>
    )
}
