import { TodoBadge } from 'src/components/svg-icons/dashboard/todo-badge'
import { Box, Button, Span, Text } from 'src/components/utility'
import { CollectionBadge } from 'src/components/svg-icons/dashboard/collection-badge'
import { ProductsBadge } from 'src/components/svg-icons/dashboard/products-badge'
import type { ChartConfiguration } from 'chart.js'
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from 'chart.js'

import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
    GetCollectionsCounts,
    GetProductsCounts,
    GetYourTodosCount,
} from 'src/services'
import { useUser } from 'src/helpers'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import type { StatCounts } from '../../@types/dashboard'

export const OwnerStats = () => {
    const { userId } = useUser()

    const [isCreateTodoModalVisible, setIsCreateTodoModalVisible] =
        useState(false)
    let todosCount = 0

    const [getTotalCount, totalCount] = useLazyQuery(GetYourTodosCount, {
        variables: {
            user_id: Number(userId),
        },
    })

    const collectionCounts = useQuery(GetCollectionsCounts)
    const productCounts = useQuery(GetProductsCounts)

    let cCounts: StatCounts = {
        inprogress: collectionCounts?.data?.ongoing?.aggregate?.count || 0,
        onTime: collectionCounts?.data?.ontime?.aggregate?.count || 0,
        delayed: collectionCounts?.data?.delayed?.aggregate?.count || 0,
    }

    let pCounts: StatCounts = {
        inprogress: productCounts?.data?.ongoing?.aggregate?.count || 0,
        onTime: productCounts?.data?.ontime?.aggregate?.count || 0,
        delayed: productCounts?.data?.delayed?.aggregate?.count || 0,
    }

    if (totalCount?.data?.todos_aggregate?.aggregate) {
        todosCount = totalCount?.data?.todos_aggregate.aggregate.count
    }

    const [collectionChart, setCollectionsGraph] = useState<Chart>()
    const [productsChart, setProductsGraph] = useState<Chart>()

    useEffect(() => {
        if (userId && userId !== '') {
            getTotalCount()
        }
    }, [userId])

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )

    useEffect(() => {
        if (collectionChart) {
            collectionChart.destroy()
        }
        const data = {
            labels: ['On Time', 'Delayed'],
            datasets: [
                {
                    label: 'Ongoing Collections',
                    data: [cCounts.onTime, cCounts.delayed],
                    backgroundColor: ['#39A407', '#FFBDB8'],
                    hoverOffset: 1,
                },
            ],
        }
        const config: ChartConfiguration = {
            type: 'pie',
            data: data,
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }

        const ctx: any = document.getElementById('collections')
        let chartCreated: Chart = new Chart(ctx, config)
        setCollectionsGraph(chartCreated)
    }, [cCounts.onTime, cCounts.delayed])

    useEffect(() => {
        if (productsChart) {
            productsChart.destroy()
        }

        const _data = {
            labels: ['On Time', 'Delayed'],
            datasets: [
                {
                    label: 'Ongoing Products',
                    data: [pCounts.onTime, pCounts.delayed],
                    backgroundColor: ['#39A407', '#FFBDB8'],
                    hoverOffset: 1,
                },
            ],
        }
        const _config: ChartConfiguration = {
            type: 'pie',
            data: _data,
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }

        const _ctx: any = document.getElementById('products')
        let productChartCreated: Chart = new Chart(_ctx, _config)
        setProductsGraph(productChartCreated)
    }, [pCounts.onTime, pCounts.delayed])

    return (
        <>
            <Box display="flex" gridColumnGap="24px">
                <Box
                    width="100%"
                    p="20px"
                    bg={'white'}
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    height="165px"
                    position="relative"
                >
                    <Text fontSize={'14px'} my="0px">
                        Yours To-Dos
                    </Text>
                    <Text fontSize="32px" mb="0px" mt={'8px'}>
                        {todosCount < 10 ? '0' + todosCount : todosCount}
                    </Text>
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        color="links"
                        position="absolute"
                        left="16px"
                        bottom="20px"
                        onClick={() => setIsCreateTodoModalVisible(true)}
                    >
                        + Create to-do
                    </Button>
                    <Box position="absolute" right="20px" bottom="20px">
                        <TodoBadge />
                    </Box>
                </Box>

                <Box
                    width="100%"
                    p="20px"
                    bg={'white'}
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    height="165px"
                    position="relative"
                >
                    <Text fontSize={'14px'} my="0px">
                        Ongoing Collections
                    </Text>
                    <Text fontSize="32px" mb="0px" mt={'8px'}>
                        {cCounts.inprogress < 10
                            ? '0' + cCounts.inprogress
                            : cCounts.inprogress}
                    </Text>

                    <Box position="absolute" left="20px" bottom="20px">
                        <Box
                            display="flex"
                            mb={'12px'}
                            gridColumnGap={'8px'}
                            alignItems="center"
                        >
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="50%"
                                bg="#39A407"
                            ></Box>
                            <Text color="#606060" my="0px" fontSize={'14px'}>
                                {' '}
                                On Time -{' '}
                                <Span color="primary">
                                    {' '}
                                    {cCounts.onTime < 10
                                        ? '0' + cCounts.onTime
                                        : cCounts.onTime}
                                </Span>
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            gridColumnGap={'8px'}
                            alignItems="center"
                        >
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="50%"
                                bg="#FFBDB8"
                            ></Box>
                            <Text color="#606060" my={'0px'} fontSize={'14px'}>
                                Delayed -{' '}
                                <Span color="primary">
                                    {' '}
                                    {cCounts.delayed < 10
                                        ? '0' + cCounts.delayed
                                        : cCounts.delayed}
                                </Span>
                            </Text>
                        </Box>
                    </Box>
                    <Box position="absolute" right="20px" bottom="20px">
                        {!cCounts.onTime && !cCounts.delayed ? (
                            <CollectionBadge />
                        ) : null}
                        <Box
                            width="110px"
                            display={
                                !cCounts.onTime && !cCounts.delayed
                                    ? 'none'
                                    : 'block'
                            }
                            height="110px"
                            position="relative"
                        >
                            <canvas
                                id="collections"
                                style={{ width: '100%', height: '100%' }}
                            ></canvas>
                        </Box>
                    </Box>
                </Box>

                <Box
                    width="100%"
                    p="20px"
                    bg={'white'}
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    height="165px"
                    position="relative"
                >
                    <Text fontSize={'14px'} my="0px">
                        Ongoing Products
                    </Text>
                    <Text fontSize="32px" mb="0px" mt={'8px'}>
                        {pCounts.inprogress < 10
                            ? '0' + pCounts.inprogress
                            : pCounts.inprogress}
                    </Text>

                    <Box position="absolute" left="20px" bottom="20px">
                        <Box
                            display="flex"
                            mb={'12px'}
                            gridColumnGap={'8px'}
                            alignItems="center"
                        >
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="50%"
                                bg="#39A407"
                            ></Box>
                            <Text color="#606060" my="0px" fontSize={'14px'}>
                                {' '}
                                On Time -{' '}
                                <Span color="primary">
                                    {pCounts.onTime < 10
                                        ? '0' + pCounts.onTime
                                        : pCounts.onTime}
                                </Span>
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            gridColumnGap={'8px'}
                            alignItems="center"
                        >
                            <Box
                                width="12px"
                                height="12px"
                                borderRadius="50%"
                                bg="#FFBDB8"
                            ></Box>
                            <Text color="#606060" my={'0px'} fontSize={'14px'}>
                                Delayed -{' '}
                                <Span color="primary">
                                    {pCounts.delayed < 10
                                        ? '0' + pCounts.delayed
                                        : pCounts.delayed}
                                </Span>
                            </Text>
                        </Box>
                    </Box>

                    <Box position="absolute" right="20px" bottom="20px">
                        {!pCounts.onTime && !pCounts.delayed ? (
                            <ProductsBadge />
                        ) : null}
                        <Box
                            width="110px"
                            display={
                                !pCounts.onTime && !pCounts.delayed
                                    ? 'none'
                                    : 'block'
                            }
                            height="110px"
                            position="relative"
                        >
                            <canvas
                                id="products"
                                style={{ width: '100%', height: '100%' }}
                            ></canvas>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {isCreateTodoModalVisible && (
                <CreateTodoModal
                    isOpen={isCreateTodoModalVisible}
                    onClose={() => setIsCreateTodoModalVisible(false)}
                />
            )}
        </>
    )
}
