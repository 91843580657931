import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { InlineError, Modal } from 'src/components'
import {
    FBX_LG,
    GLB_LG,
    OBJ_LG,
    PDF_LG,
    SVG_LG,
    ZFAB_LG,
    ZPRJ_LG,
} from 'src/components/svg-icons/folder-icons/large-icons'
import { FolderSmIco } from 'src/components/svg-icons/library/folder-sm'
import { Box, Button, Image, Input, Span, Text } from 'src/components/utility'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import {
    FetchFolderById,
    FetchFolderByName,
    FetchFoldersByCategory,
} from 'src/services'
import { InsertFolder } from 'src/services/graphql/mutations/library.mutations'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { debounce } from 'src/helpers'

import { ColoredButton } from 'src/components/buttons/colored-button'
import { EmptyFolderIco } from 'src/components/svg-icons/library/empty-folder'

export const FolderSelectOrAddModal = ({
    isModal,
    setModal,
    refetchFolders,
    existingCategoryId,
    existingCategoryName,
    setSelectedFolder,
}: {
    isModal: boolean
    setModal: (x: boolean) => void
    refetchFolders: (x: any) => void
    setSelectedFolder: (x: any) => void
    existingCategoryId: string
    existingCategoryName: string
}) => {
    const [folder, setFolder] = useState<any>({})
    const [insertFolderOne, folderInserting] = useMutation(InsertFolder)
    const categoryId = existingCategoryId
    const [fetchFolder, folderDetails] = useLazyQuery(FetchFolderById)
    const [createFolderMode, setCreateFolderMode] = useState<boolean>(false)
    const [folderName, setFolderName] = useState<string>()
    const [fetchFoldersList, foldersListData] = useLazyQuery(
        FetchFoldersByCategory
    )
    const [foldersList, setFoldersList] = useState([])

    const [exFetchFolder] = useLazyQuery(FetchFolderByName)
    const [folderAlreayExists, setFolderAlreayExists] = useState<boolean>(false)
    const _errorMessage: string = 'The folder name already exists.'

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debounceFn = debounce(
        () => {
            findingFolder()
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        if (folderName) {
            debounceFn()
        }
    }, [folderName])

    const findingFolder = () => {
        let whereObj: any = {
            name: { _ilike: `%${folderName}%` },
            parent_id: { _is_null: true },
            category_id: { _eq: categoryId },
            deleted_at: { _is_null: true },
        }
        if (folder.id && folder.id !== '0') {
            whereObj['parent_id'] = { _eq: folder.id }
        }
        setFolderAlreayExists(false)
        exFetchFolder({
            variables: {
                whereObj,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (data: any) => {
                const _exFolders: any[] = data.folders
                let found = _exFolders.find(
                    (item: any) =>
                        item.name.toLowerCase() === folderName?.toLowerCase()
                )
                if (found) {
                    setFolderAlreayExists(true)
                }
            },
        })
    }

    const addNewFolder = () => {
        if (!folderName || !folderName.trim()) {
            toast.error('Please add folder name.', {
                className: 'custom-toaster toaster-danger toaster-error',
            })
            return
        }

        if (folderName?.length > 25) {
            toast.error('Characters must be less than or equal to 25.', {
                className: 'custom-toaster toaster-danger toaster-error',
            })
            return
        }

        if (!categoryId) {
            console.error('Category id required!')
            return
        }

        if (folderAlreayExists) {
            toast.error(_errorMessage, {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        try {
            let variables: any = {
                name: folderName,
                category_id: categoryId,
            }

            if (folder?.id && folder.id !== '0') {
                variables.parent_id = folder.id
            }

            insertFolderOne({
                variables,
                onCompleted: () => {
                    fetchFoldersProperly()
                    foldersListData.refetch()
                    setCreateFolderMode(false)
                    setFolderName('')
                },
                onError: (error) => {
                    console.error(error)
                    toast.error('Failed to add folder!', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (categoryId && categoryId !== libraryDefaultKeys.all) {
            if (foldersListData.data) {
                setFoldersList(foldersListData.data.folders)
            }
        }
    }, [foldersListData.data])

    const fetchFoldersProperly = () => {
        const common = {
            searchStr: '%%',
            name: null,
            created_at: 'desc',
        }

        const folderWhereObj: any = {
            name: { _ilike: common.searchStr },
            parent_id: {
                _is_null: true,
            },
            deleted_at: { _is_null: true },
            category_id: {
                _eq: categoryId,
            },
        }

        if (folder?.id) {
            folderWhereObj.parent_id = {
                _is_null: false,
                _eq: folder?.id,
            }

            fetchFolder({
                variables: {
                    id: folder?.id,
                },
            })
        }

        fetchFoldersList({
            variables: {
                name: common.name,
                created_at: common.created_at,
                whereObj: folderWhereObj,
            },
        })
    }

    useEffect(() => {
        fetchFoldersProperly()
    }, [folder])

    const showThumbNail = (name: string, path: string = '') => {
        const type = name?.split('.')[name.split('.').length - 1]
        const url = getLibraryFileUrl(path)

        return (
            <>
                {' '}
                {type === 'jpeg' || type === 'jpg' || type === 'png' ? (
                    <Image
                        width="32px"
                        height="28px"
                        style={{
                            objectFit: 'cover',
                        }}
                        src={url}
                    />
                ) : (
                    <>
                        {type === 'svg' && <SVG_LG />}
                        {type === 'pdf' && <PDF_LG />}
                        {type === 'obj' && <OBJ_LG />}
                        {type === 'zfab' && <ZFAB_LG />}
                        {(type === 'glb' || type === 'gltf') && <GLB_LG />}
                        {type === 'zprj' && <ZPRJ_LG />}
                        {type === 'fbx' && <FBX_LG />}
                    </>
                )}
            </>
        )
    }

    const showFileName = (name: string, card: any) => {
        if (!card.type || card.type === 'FOLDER') {
            return name
        }
        const type = name?.split('.')[name.split('.').length - 1]
        const fileName =
            name.split('.')[0].length > 15
                ? name.split('.')[0].slice(0, 15) + '...' + type
                : name
        return fileName || name
    }

    const title = folder?.name ? folder.name : existingCategoryName
    const _folderDetails: FolderObject = folderDetails?.data?.folders[0]

    const goToFolder = () => {
        if (
            _folderDetails &&
            _folderDetails?.parent_folder?.id &&
            _folderDetails?.parent_folder?.name
        ) {
            setFolder({
                name: _folderDetails?.parent_folder?.name,
                id: _folderDetails?.parent_folder?.id,
                parent_id: '',
                parent_name: '',
            })
        } else {
            setFolder({
                name: '',
                id: '',
                parent_id: '',
                parent_name: '',
            })
        }
    }

    return (
        <Modal
            isModal={isModal}
            onClick={() => {
                setSelectedFolder(_folderDetails)
            }}
            onClose={() => setModal(false)}
            primaryButtonTitle={'Select'}
            secondaryButtonTitle="Cancel"
            title={title || ''}
            hideSecondaryButton={true}
            disableActions={createFolderMode}
            disablePrimaryButton={!folder.id}
        >
            <Box py={'24px'} width="100%">
                {createFolderMode ? (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="start"
                            alignItems="start"
                            gridRowGap="12px"
                            fontSize="14px"
                            mb={'24px'}
                            px={'24px'}
                        >
                            <Text
                                mt="0px"
                                mb="0px"
                                fontFamily="Rubik"
                                color="primary"
                                textAlign="left"
                                width="100%"
                            >
                                Create a new folder in "
                                {_folderDetails?.name || existingCategoryName}"
                            </Text>
                            <Input
                                autoComplete="false"
                                placeholder="Enter"
                                bg="white"
                                borderRadius="4px"
                                px="16px"
                                py="16px"
                                minWidth="360px"
                                fontSize="14px"
                                border="solid"
                                borderWidth={1}
                                borderColor="gray350"
                                className="placeholder-darkgray"
                                type="text"
                                maxLength={25}
                                minLength={3}
                                value={folderName}
                                required
                                autoFocus={true}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setFolderName(e.target.value)
                                }}
                            />
                            <Span fontSize={'14px'} color="textSecondary">
                                {' '}
                                {25 - (folderName?.length || 0)} characters left
                            </Span>
                            {folderAlreayExists && (
                                <InlineError
                                    width="auto"
                                    text={_errorMessage}
                                />
                            )}
                        </Box>
                        <Box
                            bg="backgroundColor"
                            py="12px"
                            display="flex"
                            gridColumnGap="16px"
                            px={'24px'}
                        >
                            <ColoredButton
                                disabled={
                                    !folderName ||
                                    !folderName.trim() ||
                                    folderAlreayExists ||
                                    folderInserting.loading
                                }
                                loader={folderInserting.loading}
                                type="button"
                                onClick={() => {
                                    addNewFolder()
                                }}
                                variant={'primary'}
                            >
                                Create
                            </ColoredButton>
                            <ColoredButton
                                type="button"
                                onClick={() => {
                                    setCreateFolderMode(false)
                                    setFolderName('')
                                }}
                                variant="secondary"
                            >
                                Cancel
                            </ColoredButton>
                        </Box>
                    </>
                ) : (
                    <Box
                        height="300px"
                        width={'100%'}
                        pb={'12px'}
                        overflowY={'scroll'}
                        className="scrollbar_none"
                    >
                        <Box
                            display="flex"
                            px={'24px'}
                            width={'100%'}
                            mb={'24px'}
                            justifyContent="space-between"
                        >
                            {_folderDetails?.id && folder?.id ? (
                                <Button
                                    color="links"
                                    fontSize="12px"
                                    mt={'0px'}
                                    mb={'0px'}
                                    bg="transparent"
                                    border="none"
                                    onClick={() => {
                                        goToFolder()
                                    }}
                                    px="0px"
                                    fontFamily="Rubik"
                                >
                                    &lt; {_folderDetails?.name}
                                </Button>
                            ) : (
                                <Box></Box>
                            )}
                            {
                                <Button
                                    height="20px"
                                    bg="transparent"
                                    border="none"
                                    fontSize="14px"
                                    color="links"
                                    px={'0px'}
                                    mt={'0px'}
                                    mb={'0px'}
                                    position="relative"
                                    onClick={() => {
                                        setCreateFolderMode(true)
                                    }}
                                >
                                    + Create Folder
                                </Button>
                            }
                        </Box>

                        {foldersList.map((data: any) => {
                            return (
                                <Box
                                    display="flex"
                                    className="pointer selectingFolder"
                                    px={'24px'}
                                    py={'8px'}
                                    onClick={() => {
                                        setFolder({
                                            name: data.name,
                                            id: data.id,
                                            parent_id: data.parent_id || '',
                                            parent_name:
                                                data?.parent_folder?.name || '',
                                        })
                                    }}
                                    alignItems="center"
                                >
                                    {!data.type || data.type === 'FOLDER' ? (
                                        <FolderSmIco />
                                    ) : (
                                        showThumbNail(
                                            data.name,
                                            data.path || data.url
                                        )
                                    )}
                                    <Text
                                        ml={'12px'}
                                        my={'0px'}
                                        title={data.name}
                                        maxWidth="250px"
                                        className="elipses"
                                        fontSize={'14px'}
                                    >
                                        {showFileName(data.name, data)}
                                    </Text>
                                </Box>
                            )
                        })}

                        {!foldersList.length && (
                            <Box
                                height={'calc(100% - 45px)'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box>
                                    <Box textAlign="center">
                                        {' '}
                                        <EmptyFolderIco />{' '}
                                    </Box>
                                    <Text
                                        fontSize={'14px'}
                                        textAlign="center"
                                        color="textSecondary"
                                    >
                                        {'No Folders'}
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Modal>
    )
}
