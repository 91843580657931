import type { storeState } from "./@types/slider-types"

export const commentsInitialState = {
    user: {
        other_data : {
           profile_pic : ""
        },
        files: [{
            path: ""
        }],
        name: ""
    },
    id: 0,
    created_at: new Date().toISOString(),
    comment: "",
}

const createCommentSlice = (set: Function) => ({
    comments: [
        commentsInitialState
    ],

    resetComments: () => {
        set((state: storeState) => {
            return {
                ...state, comments: [
                    {
                        user: {
                            files: [{
                                path: ""
                            }],
                            name: ""
                        },
                        id: 0,
                        created_at: new Date().toISOString(),
                        comment: "",
                    }
                ]
            }
        })
    },

    refreshComments: (payload: Comments[]) => {
        set((state: storeState) => {
            return { ...state, comments: payload }
        })
    },
    addComment: (payload: Comments) => {
        set((state: storeState) => {
            return { ...state, comments: [payload, ...state.comments] }
        })
    }
})

export default createCommentSlice
