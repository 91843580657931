import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tag } from 'src/components'
import { Box, Button, Text } from 'src/components/utility'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'

const AppliedFiltersTags = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const filtersHash = searchParams.get(libraryDefaultKeys.filters) || ''
    const [filterObj, setFilterObj] = useState<any>({})

    useEffect(() => {
        setFiltersProperly()
    }, [filtersHash])

    const applyFilters = (newObj: any) => {
        let encoded = Buffer.from(JSON.stringify(newObj)).toString('base64')
        searchParams.set(libraryDefaultKeys.filters, encoded)
        setSearchParams(searchParams)
    }

    const setFiltersProperly = () => {
        let finalFilters: any = ''
        if (filtersHash) {
            const buff = Buffer.from(filtersHash, 'base64')
            const preFilters = buff.toString('ascii')
            if (preFilters) {
                // console.log("Filters: ", JSON.parse(preFilters));
                finalFilters = JSON.parse(preFilters)
                setFilterObj(finalFilters)
            } else {
                setFilterObj({})
            }
        } else {
            setFilterObj({})
        }
    }

    const removeColors = (color: string) => {
        let newObj: any = { ...filterObj }
        newObj.selectedColors = newObj.selectedColors.filter(
            (item: any) => item !== color
        )
        newObj.colors[color] = null
        applyFilters(newObj)
    }

    const removeCreators = (color: string) => {
        let newObj: any = { ...filterObj }
        newObj.selectedCreators = newObj.selectedCreators.filter(
            (item: any) => item !== color
        )
        newObj.creators[color] = null
        applyFilters(newObj)
    }

    const removeFolders = (color: string) => {
        let newObj: any = { ...filterObj }
        newObj.selectedFolders = newObj.selectedFolders.filter(
            (item: any) => item !== color
        )
        newObj.folders[color] = null
        applyFilters(newObj)
    }

    const removeCompanies = (color: string) => {
        let newObj: any = { ...filterObj }
        newObj.selectedCompanies = newObj.selectedCompanies.filter(
            (item: any) => item !== color
        )
        newObj.companies[color] = null
        applyFilters(newObj)
    }

    const removeTags = (color: string) => {
        let newObj: any = { ...filterObj }
        newObj.selectedTags = newObj.selectedTags.filter(
            (item: any) => item !== color
        )
        newObj.tags[color] = null
        applyFilters(newObj)
    }

    useEffect(() => {
        if (
            !filterObj?.selectedFolders?.length &&
            !filterObj?.selectedColors?.length &&
            !filterObj?.selectedCreators?.length &&
            !filterObj?.selectedCompanies?.length &&
            !filterObj?.selectedTags?.length
        ) {
            clearFilters()
        }
    }, [filterObj])

    const clearFilters = () => {
        if (searchParams.has(libraryDefaultKeys.filters)) {
            searchParams.delete(libraryDefaultKeys.filters)
            setSearchParams(searchParams)
        }
    }

    return (
        <>
            {filtersHash && (
                <Box display="block" mb={'12px'} width={'100%'}>
                    <Box
                        display="flex"
                        width={'100%'}
                        gridColumnGap={'8px'}
                        flexWrap="wrap"
                        gridRowGap={'8px'}
                        alignItems="center"
                    >
                        <Text my={'0px'} fontSize={'12px'} mr={'8px'}>
                            {' '}
                            Filtered By:{' '}
                        </Text>

                        {filterObj?.selectedFolders?.length ? (
                            <Box
                                display="flex"
                                gridColumnGap={'8px'}
                                alignItems="center"
                            >
                                {filterObj?.selectedFolders?.map(
                                    (tag: any, key: number) => {
                                        return (
                                            <Tag
                                                key={key}
                                                element={
                                                    filterObj?.folders[tag]
                                                }
                                                color="#F5F6A9"
                                                action={true}
                                                onClick={() => {
                                                    removeFolders(tag)
                                                }}
                                            ></Tag>
                                        )
                                    }
                                )}
                            </Box>
                        ) : null}

                        {filterObj?.selectedColors?.length ? (
                            <Box
                                display="flex"
                                bg="#C8E8FF"
                                borderRadius="20px"
                                alignItems="center"
                                pl="8px"
                                flexWrap="wrap"
                            >
                                <Text my={'0px'} fontSize={'12px'} mr={'8px'}>
                                    {' '}
                                    Colors:{' '}
                                </Text>
                                {filterObj?.selectedColors.map(
                                    (tag: any, key: number) => {
                                        return (
                                            <Tag
                                                key={key}
                                                element={tag}
                                                color="#C8E8FF"
                                                action={true}
                                                onClick={() => {
                                                    removeColors(tag)
                                                }}
                                            ></Tag>
                                        )
                                    }
                                )}
                            </Box>
                        ) : null}

                        {filterObj?.selectedCreators?.length ? (
                            <Box
                                display="flex"
                                bg="#F5F6A9"
                                borderRadius="20px"
                                alignItems="center"
                                pl="8px"
                                flexWrap="wrap"
                            >
                                <Text my={'0px'} fontSize={'12px'} mr={'8px'}>
                                    {' '}
                                    Created By:{' '}
                                </Text>
                                {filterObj?.selectedCreators.map(
                                    (tag: any, key: number) => {
                                        return (
                                            <Tag
                                                key={key}
                                                element={
                                                    filterObj?.creators[tag]
                                                }
                                                color="#F5F6A9"
                                                action={true}
                                                onClick={() => {
                                                    removeCreators(tag)
                                                }}
                                            ></Tag>
                                        )
                                    }
                                )}
                            </Box>
                        ) : null}

                        {filterObj?.selectedCompanies?.length ? (
                            <Box
                                display="flex"
                                bg="#FFE2D1"
                                borderRadius="20px"
                                alignItems="center"
                                pl="8px"
                                flexWrap="wrap"
                            >
                                <Text my={'0px'} fontSize={'12px'} mr={'8px'}>
                                    {' '}
                                    Company:{' '}
                                </Text>
                                {filterObj?.selectedCompanies.map(
                                    (tag: any, key: number) => {
                                        return (
                                            <Tag
                                                key={key}
                                                element={tag}
                                                color="#FFE2D1"
                                                action={true}
                                                onClick={() => {
                                                    removeCompanies(tag)
                                                }}
                                            ></Tag>
                                        )
                                    }
                                )}
                            </Box>
                        ) : null}

                        {filterObj?.selectedTags?.length ? (
                            <Box
                                display="flex"
                                bg="#FFE2D1"
                                borderRadius="20px"
                                alignItems="center"
                                pl="8px"
                                flexWrap="wrap"
                            >
                                <Text my={'0px'} fontSize={'12px'} mr={'8px'}>
                                    {' '}
                                    Tags:{' '}
                                </Text>
                                {filterObj?.selectedTags.map(
                                    (tag: any, key: number) => {
                                        return (
                                            <Tag
                                                key={key}
                                                element={filterObj?.tags[tag]}
                                                color="#FFE2D1"
                                                action={true}
                                                onClick={() => {
                                                    removeTags(tag)
                                                }}
                                            ></Tag>
                                        )
                                    }
                                )}
                            </Box>
                        ) : null}

                        {filterObj?.selectedFolders?.length ||
                        filterObj?.selectedColors?.length ||
                        filterObj?.selectedCompanies?.length ||
                        filterObj?.selectedCreators?.length ||
                        filterObj?.selectedTags?.length ? (
                            <Button
                                height="20px"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                color="links"
                                position="relative"
                                onClick={() => {
                                    clearFilters()
                                }}
                            >
                                Clear filters
                            </Button>
                        ) : null}
                    </Box>
                </Box>
            )}
        </>
    )
}
export default AppliedFiltersTags
