import React, { useState } from 'react'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { getImageUrl, useUser } from 'src/helpers'
import TemplateCardMenuDropdown from './template-card-menu-dropdown'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import {
    AddFavouriteEntity,
    RemoveFavouriteEntity,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'
import {
    GetFavouriteTemplates,
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplates,
} from 'src/services/graphql/entities/todo/todo-templates.queries'
import type { Templates } from 'src/generated/graphql'

function TemplateCard({
    template,
    onClick,
    width,
}: {
    template: Templates
    width?: string
    onClick: ({
        isOpen,
        mode,
    }: {
        isOpen: boolean
        mode: 'preview' | 'edit' | 'delete' | 'duplicate'
    }) => void
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [addFavouriteEntity] = useMutation(AddFavouriteEntity)
    const [removeFavouriteEntity] = useMutation(RemoveFavouriteEntity)
    const { userId } = useUser()
    function addOrRemoveFavouriteHandler() {
        setIsDropdownOpen(false)
        if (!template.favourite?.id) {
            addFavouriteEntity({
                variables: {
                    entity_name: 'templates',
                    entity_id: template.id,
                },
                refetchQueries: [
                    GetTemplateCategoriesWithTemplates,
                    GetTodoTemplates,
                    GetFavouriteTemplates,
                ],
                onCompleted: () => {
                    toast('Template added to favourites', {
                        className: 'custom-toaster toaster-success',
                    })
                },
            })
        } else {
            removeFavouriteEntity({
                variables: {
                    id: template.favourite.id,
                    deleted_at: new Date().toISOString(),
                },
                refetchQueries: [
                    GetTemplateCategoriesWithTemplates,
                    GetTodoTemplates,
                    GetFavouriteTemplates,
                ],
                onCompleted: () => {
                    toast('Template removed from favourites', {
                        className: 'custom-toaster toaster-success',
                    })
                },
            })
        }
    }

    const isTemplateOwner = template.user.id === Number(userId)

    return (
        <Box
            display="flex"
            flexDirection="column"
            gridGap="8px"
            py="16px"
            px="32px"
            bg="white"
            border="1px solid #E8ECEF"
            minWidth="300px"
            width={width}
            className="cursor-pointer"
            onClick={() => onClick({ isOpen: true, mode: 'preview' })}
        >
            <Text my="0px" fontSize="14px" textAlign="left">
                {template.name}
            </Text>
            <Box
                display="flex"
                justifyContent="space-between"
                gridGap="8px"
                alignItems="start"
            >
                <Box display="flex" flexDirection="column" gridGap="8px">
                    <Text my="0px" fontSize="14px" color="#979797">
                        Used for{' '}
                        {
                            template.assigned_templates_aggregate?.aggregate
                                ?.count
                        }{' '}
                        task
                        {template.assigned_templates_aggregate?.aggregate
                            ?.count! > 1
                            ? 's'
                            : ''}
                    </Text>
                    <Box display="flex" alignItems="center" gridGap="12px">
                        <Text my="0px" fontSize="14px" color="textSecondary">
                            Created by
                        </Text>
                        <Box
                            display="flex"
                            gridColumnGap="4px"
                            alignItems="center"
                        >
                            <ProfileImg
                                size="18px"
                                name={template.user.name}
                                src={
                                    template.user.other_data?.profile_pic
                                        ? getImageUrl(
                                              template.user.other_data
                                                  ?.profile_pic
                                          )
                                        : ''
                                }
                            />
                            <Text my="0px" color="primary" fontSize="14px">
                                {template.user.name}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box position="relative">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsDropdownOpen(!isDropdownOpen)
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        id="template-menu-button"
                    >
                        <Dots vertical active={isDropdownOpen} />
                    </Button>
                    {isDropdownOpen && (
                        <TemplateCardMenuDropdown
                            active={isDropdownOpen}
                            onClose={() => setIsDropdownOpen(false)}
                            onDelete={() => {
                                setIsDropdownOpen(false)
                                onClick({ isOpen: true, mode: 'delete' })
                            }}
                            onEdit={() => {
                                setIsDropdownOpen(false)
                                onClick({ isOpen: true, mode: 'edit' })
                            }}
                            onDuplicate={() => {
                                setIsDropdownOpen(false)
                                onClick({ isOpen: true, mode: 'duplicate' })
                            }}
                            onFavourite={addOrRemoveFavouriteHandler}
                            isFavourite={!!template.favourite?.id}
                            isOwner={isTemplateOwner}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default TemplateCard
