import * as React from 'react'

export const CrossSm = ({ color, id }: { color?: string; id?: string }) => (
    <svg
        width={12}
        height={13}
        fill="none"
        id={id}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.708 2.622a.415.415 0 0 0-.586.586L5.413 6.5 2.122 9.792a.415.415 0 0 0 .586.587L6 7.087l3.292 3.292a.415.415 0 0 0 .586-.587L6.587 6.5l3.291-3.292a.415.415 0 0 0-.586-.586L6 5.913 2.708 2.622Z"
            fill={color || '#022143'}
        />
    </svg>
)
