import type { storeState } from './@types/slider-types'

const createNotificationSlice = (set: Function) => ({
    notifications: [] as Notifications[],
    resetNotifications: ({
        notifications,
    }: {
        notifications: Notifications[]
    }) => {
        return set((state: storeState) => {
            return {
                ...state,
                notifications,
            }
        })
    },
    addNewNotifications: ({
        notifications,
    }: {
        notifications: Notifications[]
    }) => {
        return set((state: storeState) => {
            return {
                ...state,
                notifications: [...state.notifications, ...notifications],
            }
        })
    },
    removeNotificationFromList: ({ id }: { id: number }) => {
        return set((state: storeState) => {
            let remainingAfterDelete = state.notifications.filter(
                (item) => item.id !== id
            )
            return {
                ...state,
                notifications: remainingAfterDelete,
            }
        })
    },
})

export default createNotificationSlice
