import { Dropdown } from 'src/components/dropdown/dropdown'
import { Tick } from 'src/components/svg-icons'
import { Box } from 'src/components/utility'
import { SUBTODO_DESIGN_COLORS } from 'src/helpers/constants'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export default function ColorPickerDropdown({
    active,
    onClose,
    activeColor,
    updateDesignColor,
}: {
    active: boolean
    onClose: () => void
    activeColor: string
    updateDesignColor: (color: string) => void
}) {
    const wrapperRef = useClickedOutside(onClose, [
        'subtodo-color-picker-container',
        'subtodo-color-picker-icon',
        'subtodo-color-picker-active-color',
    ])

    return (
        <Dropdown
            active={active}
            top="24px"
            left="none"
            right="0px"
            width="148px"
            maxHeight="76px"
        >
            <Box
                ref={wrapperRef}
                width="100%"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                gridGap="4px"
                px="16px"
            >
                {SUBTODO_DESIGN_COLORS.map((color) => {
                    return (
                        <Box
                            width="20px"
                            height="20px"
                            bg={color}
                            key={color}
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                                updateDesignColor(color)
                            }}
                        >
                            {activeColor === color && (
                                <Tick isDone color="#022143" />
                            )}
                        </Box>
                    )
                })}
            </Box>
        </Dropdown>
    )
}
