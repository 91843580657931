import { gql } from '@apollo/client'

export const GetProductTeam = gql`
    query GetProductTeam($product_id: bigint!) {
        product_variants_by_pk(id: $product_id) {
            team {
                id
                name
                team_members {
                    user {
                        id
                        email
                        name
                        other_data
                    }
                }
            }
        }
    }
`
