import React, { useState } from 'react'
import { Box, Button, Image } from 'src/components/utility'
import type { FileDetails } from '../todo.types'
import { getImageUrl } from 'src/helpers'
import { Dots } from 'src/components/svg-icons'
import ReferenceMenuDropdown from './reference-menu-dropdown'
import { ExpandReferencePicModal } from './modals/expand-reference-pic.modal'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import ReferenceCircle from 'src/components-v2/reference-circle/reference-circle'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import {
    InsertReferencePointForSection,
    InsertSubtodoSection,
} from 'src/services/graphql/entities/todo/todo.mutation'
import { Loader } from 'src/components/loader/loader'

function SubtodoReferencePic({
    pic,
    subtodoId,
    refetchTodo,
    picId,
    referencePoints,
    assignReferenceToSection,
    setAssignReferenceToSection,
    isFocussedView,
    referencePicUploadStatus,
    setReferencePicUploadStatus,
}: {
    pic: FileDetails
    subtodoId: number
    picId: number
    refetchTodo: () => void
    referencePoints: Array<{
        sectionId: number
        reference_point: {
            id: number
            coordinates: [number, number]
        }
    }>
    assignReferenceToSection: number | null
    setAssignReferenceToSection: React.Dispatch<
        React.SetStateAction<number | null>
    >
    isFocussedView?: boolean
    referencePicUploadStatus: {
        isUploading: boolean
    }
    setReferencePicUploadStatus: (status: { isUploading: boolean }) => void
}) {
    const [componentVisibility, setComponentVisibility] = useState({
        menuDropdown: false,
        expandViewModal: false,
    })
    const [annotationsComponent, setAnnotationsComponent] = useState({
        visibility: false,
        scalingFactor: {
            x: 1,
            y: 1,
        },
    })
    const [createSubtodoSection] = useMutation(InsertSubtodoSection)
    const [assignReferencePointForSection] = useMutation(
        InsertReferencePointForSection
    )
    const { todoId } = useParams()

    function handleImageClick(e: any) {
        const { offsetX, offsetY } = e.nativeEvent
        var imgX = Math.round(offsetX * annotationsComponent.scalingFactor.x)
        var imgY = Math.round(offsetY * annotationsComponent.scalingFactor.y)
        if (assignReferenceToSection) {
            assignReferencePointForSection({
                variables: {
                    object: {
                        sub_todo_id: subtodoId,
                        coordinates: [imgX, imgY],
                        sub_todo_section_id: assignReferenceToSection,
                    },
                },
                onCompleted: () => {
                    setAssignReferenceToSection(null)
                    refetchTodo()
                },
            })
        } else {
            createSubtodoSection({
                variables: {
                    object: {
                        todo_id: todoId,
                        sub_todo_id: subtodoId,
                        name: '',
                        reference_points: {
                            data: {
                                coordinates: [imgX, imgY],
                                sub_todo_id: subtodoId,
                            },
                        },
                    },
                },
                optimisticResponse: {
                    insert_sub_todo_sections_one: {
                        __typename: 'sub_todo_sections',
                        id: 'temp-id',
                        name: '',
                        reference_points: {
                            __typename: 'reference_points',
                            id: 'temp-id',
                            coordinates: [imgX, imgY],
                            sub_todo_id: subtodoId,
                        },
                    },
                },
                onCompleted: (data) => {
                    refetchTodo()
                    scrollToSection(
                        `subtodo_section_${data.insert_sub_todo_sections_one.id}`
                    )
                },
            })
        }
    }

    function handleImageLoad(e: any) {
        var ratioX = e.target.naturalWidth / e.target.offsetWidth
        var ratioY = e.target.naturalHeight / e.target.offsetHeight
        setAnnotationsComponent({
            visibility: true,
            scalingFactor: {
                x: ratioX,
                y: ratioY,
            },
        })
    }

    return (
        <Box
            bg="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gridGap="12px"
            position="relative"
        >
            <Box position="absolute" top="12px" right="8px">
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setComponentVisibility({
                            ...componentVisibility,
                            menuDropdown: !componentVisibility.menuDropdown,
                        })
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                    id="subtodo-reference-menu-button"
                >
                    <Dots active={componentVisibility.menuDropdown} vertical />
                </Button>
                {componentVisibility.menuDropdown && (
                    <ReferenceMenuDropdown
                        active={componentVisibility.menuDropdown}
                        onClose={() =>
                            setComponentVisibility({
                                ...componentVisibility,
                                menuDropdown: false,
                            })
                        }
                        subtodoId={subtodoId}
                        refetchTodo={refetchTodo}
                        picId={picId}
                        setReferencePicUploadStatus={
                            setReferencePicUploadStatus
                        }
                    />
                )}
            </Box>
            <Box position="relative">
                {referencePicUploadStatus.isUploading ? (
                    <Loader outline="black" size="42px" />
                ) : (
                    <Image
                        src={getImageUrl(pic.gid)}
                        alt={pic.name}
                        width="100%"
                        height="fit-content"
                        onClick={handleImageClick}
                        onLoad={handleImageLoad}
                    />
                )}
                {annotationsComponent.visibility &&
                    referencePoints.map((marker, index) => {
                        return (
                            <ReferenceCircle
                                key={marker.reference_point.id}
                                referenceId={marker.reference_point.id}
                                scalingFactor={
                                    annotationsComponent.scalingFactor
                                }
                                referencePoints={{
                                    x: marker.reference_point.coordinates[0],
                                    y: marker.reference_point.coordinates[1],
                                }}
                                index={index}
                                sectionId={marker.sectionId}
                                refetchTodo={refetchTodo}
                                isFocussedView={isFocussedView}
                            />
                        )
                    })}
            </Box>
            <Box position="absolute" bottom="12px" right="8px">
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setComponentVisibility({
                            ...componentVisibility,
                            expandViewModal:
                                !componentVisibility.expandViewModal,
                        })
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                    id="subtodo-reference-menu-button"
                >
                    <FullScreenIco />
                </Button>
                {componentVisibility.expandViewModal && (
                    <ExpandReferencePicModal
                        onClose={() =>
                            setComponentVisibility({
                                ...componentVisibility,
                                expandViewModal: false,
                            })
                        }
                        url={getImageUrl(pic.gid)}
                    />
                )}
            </Box>
        </Box>
    )
}

export default SubtodoReferencePic
