export function appendSearchParamsToUrl({
    url,
    searchParams,
}: {
    url: string
    searchParams: {
        key: string
        value: string | undefined
    }
}) {
    const isSearchParamsPresentInUrl = url.includes('?')
    if (!searchParams.value) return url
    const searchParamsString = `${searchParams.key}=${searchParams.value}`

    const updatedUrl = isSearchParamsPresentInUrl
        ? `${url}&${searchParamsString}`
        : `${url}?${searchParamsString}`
    return updatedUrl
}
