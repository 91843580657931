import { Dots, SuccessTickSm } from 'src/components/svg-icons'
import { Box, Button, Image, Text } from 'src/components/utility'
import { copyToClipBoard, getImageUrl, uuid } from 'src/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import useStore from 'src/store/hooks/useStore'
import { DangerModal } from 'src/components/modals/danger-modal'
import useUpdateProduct from '../hooks/useUpdateProduct'
import UploadProductThumbnailMenuDropdown from '../../upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { toast } from 'react-toastify'
import MeatBallDropdown from '../dropdowns/meatball-dropdown'
import RenameVariantsModal from 'src/components-v2/modals/rename-variants-modal'
import CalendarDropdown from '../dropdowns/calendar'
import StatusDropdown from '../dropdowns/status'
import { ProductBadge } from 'src/components-v2/badges/product-badge'
import { VariantBadge } from 'src/components-v2/badges/variant-badge'
import TeamBadge from 'src/components-v2/team-badge/team-badge'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useDeleteProductVariant } from '../hooks/useDeleteProductVariant'
import { getLibraryFileUrl, getLocalImageUrl } from 'src/helpers/get-image-url'
import { Loader } from 'src/components/loader/loader'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import type {
    GetProductVariantsByProductIdQuery,
    Status_Enum,
} from 'src/generated/graphql'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'

export default function ProductVariantCard({
    entity,
    onClick,
    refetch,
    isSplitMode,
    isActive,
    onCopy,
}: {
    entity: GetProductVariantsByProductIdQuery['product_variants'][number]
    onClick: () => void
    refetch: () => void
    isSplitMode?: boolean
    isActive?: boolean
    onCopy: () => void
}) {
    const { removeProduct, updateGlobalSearchResults } = useStore()
    const { globalSearchFilters } = useStore()
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const [isRenameModalActive, setIsRenameModalActive] = useState(false)
    const { deleteProductVariant } = useDeleteProductVariant()
    const [showThumbnailDropdown, setShowThumbnailDropdown] = useState(false)
    const { productId } = useParams()
    const navigate = useNavigate()
    const {
        updateProductVariantStatus,
        updateProductVariantDueDate,
        updateProductVariantName,
    } = useUpdateProduct()
    const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)

    const { isUploading, onFileUpload } = useUploadFile(
        String(productId),
        'product_variants'
    )
    const { assignFilesToSection } = useAssignFile(() => {
        refetch()
    })

    async function uploadImageHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        productId: number
    ) {
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)

            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: e.target.files[0],
                            id: uuid(),
                            name: e.target.files[0].name,
                            path:
                                getLocalImageUrl(e.target.files[0] || '') || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            if (data?.resolvedArr[0]) {
                assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityName: 'product_variants',
                    entityId: String(productId),
                    fileId: [data?.resolvedArr[0].id],
                })
                setIsThumbnailUploading(false)
                setShowThumbnailDropdown(false)
            }
        } catch {
            setIsThumbnailUploading(false)
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsThumbnailUploading(false)
        }
    }

    const coverPic = entity.other_data?.cover_pic
        ? entity.other_data.cover_pic.fromLibrary
            ? getLibraryFileUrl(entity.other_data.cover_pic.gid)
            : getImageUrl(entity.other_data.cover_pic.gid)
        : '/image-placeholder.jpg'

    function onDropdownClick(option: string) {
        switch (option) {
            case 'Delete':
                setIsModalActive(true)
                break
            case 'Copy Link':
                copyToClipBoard(
                    window.location.origin + '/collections/product/' + entity.id
                )
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            case 'Rename': {
                setIsRenameModalActive(true)
                break
            }
            case 'Make a Copy': {
                onCopy()
                break
            }
            default:
                break
        }
    }

    return (
        <>
            <Box onClick={onClick}>
                <Box
                    px="16px"
                    py="12px"
                    bg="white"
                    display="grid"
                    gridTemplateColumns={
                        isSplitMode
                            ? {
                                  md: '1fr 100px',
                                  xl: '1fr 100px',
                              }
                            : {
                                  md: '400px auto 100px',
                                  xl: '1fr auto 100px',
                              }
                    }
                    border="solid"
                    borderColor={isActive ? 'primary' : 'gray250'}
                    borderRadius="4px"
                    alignItems="center"
                    borderWidth={1}
                    className="cursor-pointer"
                >
                    <Box
                        display="flex"
                        minWidth={{ md: '300px', xl: '500px' }}
                        maxWidth={{ md: '300px', xl: 'max-content' }}
                        gridColumnGap="12px"
                    >
                        <Box
                            position="relative"
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowThumbnailDropdown(true)
                            }}
                        >
                            {isThumbnailUploading ? (
                                <Loader outline="black" size="36px" />
                            ) : (
                                <Image
                                    width="53px"
                                    height="53px"
                                    borderRadius="4px"
                                    src={coverPic}
                                />
                            )}
                            {showThumbnailDropdown && (
                                <UploadProductThumbnailMenuDropdown
                                    active={true}
                                    onClose={() => {
                                        setShowThumbnailDropdown(false)
                                    }}
                                    entityId={entity.id}
                                    entityName="product_variants"
                                    productId={entity.product?.id}
                                    onUpload={(e) =>
                                        uploadImageHandler(e, entity.id)
                                    }
                                    refetchProduct={refetch}
                                />
                            )}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                        >
                            <Box
                                gridColumnGap="8px"
                                alignItems="center"
                                display="flex"
                            >
                                {entity.is_primary ? (
                                    <ProductBadge />
                                ) : (
                                    <VariantBadge />
                                )}
                                <Text
                                    fontSize={{ md: '12px', xl: '14px' }}
                                    my="0px"
                                    title={entity.name}
                                >
                                    {entity.name}
                                </Text>
                            </Box>
                            {entity.team ? (
                                <TeamBadge
                                    teamName={entity.team.name}
                                    teamMembers={entity.team.team_members}
                                />
                            ) : (
                                <Text
                                    my="0px"
                                    fontSize={{ md: '12px', xl: '14px' }}
                                    color="textSecondary"
                                >
                                    No team assigned
                                </Text>
                            )}
                        </Box>
                    </Box>
                    {!isSplitMode && (
                        <Box
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                            gridRowGap={'4px'}
                            gridColumnGap={'50px'}
                        >
                            <Box
                                gridColumnGap="8px"
                                display="flex"
                                alignItems="center"
                            >
                                <SuccessTickSm />
                                <Text fontSize={{ md: '10px', xl: '12px' }}>
                                    {entity.completed_todos.aggregate?.count ||
                                        0}
                                    /{entity.total_todos.aggregate?.count || 0}{' '}
                                    To-Do's
                                </Text>
                            </Box>
                            <CalendarDropdown
                                date={entity.due_date}
                                setDate={(date) => {
                                    updateProductVariantDueDate({
                                        id: entity.id,
                                        dueDate: date.toISOString(),
                                        onCompleted: () => {
                                            updateGlobalSearchResults({
                                                id: entity.id,
                                                type: 'products',
                                                value: date.toISOString(),
                                                updatedKey: 'due_date',
                                            })
                                        },
                                    })
                                }}
                                isDelayed={isEntityDueDateDelayed(
                                    entity.due_date,
                                    entity.status as Status_Enum
                                )}
                                isListCard
                            />
                            <StatusDropdown
                                exclude={[]}
                                onStatusChange={(status) => {
                                    updateProductVariantStatus({
                                        id: entity.id,
                                        status: status,
                                        onCompleted: () => {
                                            updateGlobalSearchResults({
                                                id: entity.id,
                                                type: 'products',
                                                value: status,
                                                updatedKey: 'status',
                                            })
                                        },
                                    })
                                }}
                                status={entity.status as Status_Enum}
                            />
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="12px"
                                p="4px"
                                border="solid"
                                borderRadius="2px"
                                borderColor="primary"
                                borderWidth={1}
                                width="116px"
                            >
                                <Text
                                    fontWeight={500}
                                    width="100%"
                                    my="0px"
                                    fontSize={{ md: '8px', xl: '12px' }}
                                >
                                    Stage:{' '}
                                    <span
                                        style={{
                                            textAlign:
                                                entity?.other_data &&
                                                'product_stage' in
                                                    entity.other_data
                                                    ? 'unset'
                                                    : 'center',
                                        }}
                                    >
                                        {convertIntoElipses(
                                            String(
                                                (entity?.other_data &&
                                                    'stage' in
                                                        entity.other_data &&
                                                    entity?.other_data.stage) ||
                                                    '-'
                                            ),
                                            13
                                        )}
                                    </span>
                                </Text>
                            </Box>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsDropdownActive(!isDropdownActive)
                            }}
                            bg="white"
                            border="none"
                        >
                            <Dots active={false} />
                        </Button>
                        {isDropdownActive && (
                            <MeatBallDropdown
                                right="0px"
                                left="unset"
                                isActive={isDropdownActive}
                                onClick={(option) => onDropdownClick(option)}
                                setIsActive={(isActive) =>
                                    setIsDropdownActive(isActive)
                                }
                                type="variant"
                                onClose={() => setIsDropdownActive(false)}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            {isRenameModalActive && (
                <RenameVariantsModal
                    coverPic={coverPic}
                    name={entity.name}
                    onClose={() => {
                        setIsRenameModalActive(false)
                    }}
                    onRename={(updatedName) => {
                        updateProductVariantName({
                            id: entity.id,
                            name: updatedName,
                        })
                        setIsRenameModalActive(false)
                    }}
                    isOpen={isRenameModalActive}
                />
            )}
            <DangerModal
                title={`Delete ${entity.name} ?`}
                heading={`Are you sure you want to delete ${entity.name} from ${
                    entity.product?.name || ''
                } ?`}
                isModal={isModalActive}
                onClose={() => setIsModalActive(false)}
                onRemove={() =>
                    deleteProductVariant({
                        variantId: entity.id,
                        onComplete: () => {
                            globalSearchFilters.isGlobalSearchActive &&
                                removeProduct({
                                    id: Number(0),
                                })
                            if (Number(productId) === entity.id) {
                                navigate(-1)
                            }
                            toast('Product deleted successfully. ', {
                                className: 'custom-toaster toaster-success',
                            })
                        },
                        refetchQueries: [
                            'getProduct',
                            'getCollections',
                            'fetchCollectionById',
                            'GetProductVariantsByProductId',
                        ],
                    })
                }
                primaryButtonText="Yes, Delete"
                secondaryButtonText="Cancel"
                subHeading=""
            />
        </>
    )
}
