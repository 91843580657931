import { useLazyQuery } from '@apollo/client'
import React, { Suspense, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SuspenseLoading } from 'src/components/suspense-loading/suspense-loading'
import { InfoGreyIco } from 'src/components/svg-icons/library/info-grey'
import { Box, Button, Text } from 'src/components/utility'
import { FetchFileById } from 'src/services'
import { FetchOnlyFilesTable } from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { FolderDetails } from '../library/sub-screens/folder-details'
import { ImageZoomer } from '../library/sub-screens/zoomer/zoomer'
import { isFile3D } from 'src/helpers/create-redirection-url'
// const WithModel = React.lazy(
//     () => import('src/components/3d-renderer/3d-renderer')
// )

import { ColoredButton } from 'src/components/buttons/colored-button'
import useStore from 'src/store/hooks/useStore'
import AddLinkModal from 'src/components-v2/add-links/add-link-modal'
import { useAnnotationAttachmentsStore } from 'src/store/hooks/useAnnotationAttachmentsStore'
import { Configurator } from 'src/components/3d-renderer/configurator/configurator'
import { PREVIEW_NOT_SUPPORTED_FILE_TYPES, _3DFiles } from 'src/helpers/enums'
import UnableToPreviewSection from 'src/components-v2/file-preview-modal/preview-section/unable-to-preview-section'

export const FilePreview = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const url = searchParams.get('url')
    const name = searchParams.get('name')

    const { setUpload, isChanged } = useStore()
    let extension
    if (name) extension = name.substring(name.lastIndexOf('.'))
    const extensions = ['.jpeg', '.png', '.jpg']
    const navigate = useNavigate()
    const location = useLocation()
    const [showFolderDetails, setShowFolderDetails] = useState(false)
    const [fetchFile, fileDetails] = useLazyQuery(FetchFileById)
    const [fetchFileTable, fileTableDetails] = useLazyQuery(FetchOnlyFilesTable)
    let fileId =
        searchParams.get(libraryDefaultKeys.showFileDetails) ||
        searchParams.get('fileId')

    const { addLink, setShowLinksModal, showAddLinksModal } =
        useAnnotationAttachmentsStore()

    useEffect(() => {
        setShowFolderDetails(false)
        if (
            searchParams.has(libraryDefaultKeys.showFolderDetails) ||
            searchParams.has(libraryDefaultKeys.showFileDetails)
        ) {
            let folderId = searchParams.get(
                libraryDefaultKeys.showFolderDetails
            )
            let fileId = searchParams.get(libraryDefaultKeys.showFileDetails)
            setShowFolderDetails(
                (folderId && folderId !== '0') || (fileId && fileId !== '0')
                    ? true
                    : false
            )
        }
    }, [searchParams])

    useEffect(() => {
        if (fileId && fileId !== '0') {
            fetchFile({
                variables: {
                    id: fileId,
                },
                fetchPolicy: 'cache-and-network',
                onCompleted: (data: any) => {
                    if (!data.library_files || !data.library_files[0]) {
                        fetchFileTable({
                            variables: {
                                id: fileId,
                            },
                        })
                    }
                },
            })
        }
    }, [fileId])

    const file: FileObject = fileDetails?.data?.library_files[0] || null
    const _fileTable = fileTableDetails?.data?.files[0] || null

    const openDetails = () => {
        if (searchParams.has(libraryDefaultKeys.showFolderDetails)) {
            searchParams.delete(libraryDefaultKeys.showFolderDetails)
        }
        if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
            searchParams.delete(libraryDefaultKeys.showFileDetails)
        } else {
            searchParams.set(
                libraryDefaultKeys.showFileDetails,
                file?.id?.toString()
            )
        }
        setSearchParams(searchParams)
    }

    const goBack = () => {
        const callbackUrl = searchParams.get('callback_url')
        if (
            !isFile3D(
                _fileTable?.name.split('.')[
                    _fileTable?.name.split('.').length - 1
                ]
            )
        ) {
            navigate(-1)
        }
        if (callbackUrl) {
            navigate(callbackUrl)
        } else {
            navigate(-1)
        }
    }

    const extensionWithoutDot = extension?.substring(1)

    return (
        <>
            <Box height="100%" display="flex">
                <Box width={'100%'} pr={'0px'} pt="24px">
                    {
                        <Box
                            px={
                                extension &&
                                extensions.includes(extension.toLowerCase())
                                    ? '0px'
                                    : '24px'
                            }
                            mb={'12px'}
                        >
                            <Button
                                color="links"
                                fontSize="12px"
                                bg="transparent"
                                border="none"
                                onClick={goBack}
                                px="0px"
                                fontFamily="Rubik"
                                my="0px"
                                height="100%"
                            >
                                &lt; Back
                            </Button>
                        </Box>
                    }

                    {(file?.name || _fileTable?.name || name) && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            px={
                                extension &&
                                extensions.includes(extension.toLowerCase())
                                    ? '0px'
                                    : '24px'
                            }
                        >
                            <Box
                                width={'100%'}
                                display="flex"
                                mb="16px"
                                alignItems="center"
                            >
                                <Text
                                    fontFamily="Rubik"
                                    fontSize="20px"
                                    color="primary"
                                    position="relative"
                                    my={'0px'}
                                    lineHeight="32px"
                                    maxWidth="45%"
                                    title={
                                        name || file?.name || _fileTable?.name
                                    }
                                >
                                    {name || file?.name || _fileTable?.name}
                                </Text>
                                {file?.id && (
                                    <Box
                                        ml={'8px'}
                                        mt="4px"
                                        className="pointer"
                                        onClick={openDetails}
                                    >
                                        <InfoGreyIco />
                                    </Box>
                                )}
                            </Box>
                            {isChanged &&
                                searchParams.get('mode') === 'pan' && (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        gridColumnGap="12px"
                                    >
                                        <ColoredButton
                                            onClick={() =>
                                                setUpload({ isUpload: true })
                                            }
                                            variant="primary"
                                        >
                                            Save
                                        </ColoredButton>
                                        <ColoredButton
                                            onClick={() => console.log('')}
                                            variant="secondary"
                                        >
                                            Cancel
                                        </ColoredButton>
                                    </Box>
                                )}
                        </Box>
                    )}

                    {/* {url
                        && name && extension
                        && !extensions.includes(extension.toLowerCase())
                        && <Box className="filePreviewImage setHeight">
                            <Suspense fallback={<SuspenseLoading />}> */}
                    {/* <WithModel name={name} url={url} isResized={showFolderDetails} /> */}
                    {/* <FileUploader /> */}
                    {/* </Suspense>
                        </Box>
                    } */}

                    {extensionWithoutDot &&
                        _3DFiles.includes(
                            extensionWithoutDot.toLowerCase()
                        ) && (
                            <Box height="100%">
                                <Suspense fallback={<SuspenseLoading />}>
                                    <Configurator
                                        configuraturRef="fetch"
                                        // url={url}
                                        // isResized={showFolderDetails}
                                    />
                                </Suspense>
                            </Box>
                        )}

                    {url &&
                        extension &&
                        extensions.includes(extension.toLowerCase()) &&
                        !fileDetails.loading && (
                            <Box width={'100%'} textAlign="center">
                                <ImageZoomer url={url} />
                            </Box>
                        )}
                    {url &&
                        extensionWithoutDot &&
                        PREVIEW_NOT_SUPPORTED_FILE_TYPES.includes(
                            extensionWithoutDot.toLowerCase()
                        ) && (
                            <UnableToPreviewSection
                                extension={extensionWithoutDot}
                                url={url}
                                onOpenConfigurator={() => {
                                    // openInNewTab(url)
                                }}
                            />
                        )}
                </Box>

                {showAddLinksModal && (
                    <AddLinkModal
                        addLink={(link) => addLink(link)}
                        onClose={() => setShowLinksModal(false)}
                    />
                )}

                {false && showFolderDetails && file?.id && (
                    <Box width="auto" height={'100%'}>
                        <FolderDetails removeClose={false} />
                    </Box>
                )}
            </Box>
        </>
    )
}
