import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import type {
    GetDashboardAllProductQuery,
    GetDashboardMyProductQuery,
    Status_Enum,
} from 'src/generated/graphql'
import { getLocalStorage } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import {
    DashboardAllProductAggregate,
    DashboardYourProductAggregate,
    GetDashboardAllProducts,
    GetDashboardMyProduct,
} from 'src/services/graphql/entities/product/get-dashboard-products'
import { useCollectionFilter } from './use-collections'

export function useProducts({
    status,
    sort,
    myProducts = true,
    collectionIds = [],
}: {
    status: Status_Enum
    sort: string
    myProducts?: boolean
    collectionIds?: string[]
}) {
    const orderBy: { [key: string]: 'asc' | 'desc' } = {}
    if (sort === 'Name') orderBy['name'] = 'desc'
    if (sort === 'Due Date') orderBy['due_date'] = 'desc'
    if (sort === 'Date Created') orderBy['created_at'] = 'desc'
    if (sort === 'Last Updated') orderBy['updated_at'] = 'desc'
    const userId = JSON.parse(getLocalStorage('user') || '{}')?.id
    const { filters } = useCollectionFilter('product')

    const vars = {
        approverId: { _eq: Number(userId) },
        userId: { _eq: Number(userId) },
        status: { _eq: status },
        order_by: orderBy,
        offset: 0,
        limit: PerPage,
        pageNumber: 0,
        collectionId:
            collectionIds.length > 0
                ? {
                      _in: collectionIds,
                  }
                : {
                      _is_null: false,
                  },
    }

    if (myProducts) {
        // @ts-ignore
        delete vars.userId
    } else {
        // @ts-ignore
        delete vars.approverId
    }
    const { data, fetchMore, loading, networkStatus } = useQuery<
        GetDashboardMyProductQuery | GetDashboardAllProductQuery
    >(myProducts ? GetDashboardMyProduct : GetDashboardAllProducts, {
        variables: vars,
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })
    const [getProductAggregate, aggregate] = useLazyQuery<{
        new: AggregatedDetails
        inProgress: AggregatedDetails
        completed: AggregatedDetails
        inReview: AggregatedDetails
        revisionRequested: AggregatedDetails
    }>(
        myProducts
            ? DashboardYourProductAggregate
            : DashboardAllProductAggregate
    )

    const aggregateVars = {
        userId: { _eq: Number(userId) },
        approverId: { _eq: Number(userId) },
        id: filters['product']
            ? {
                  _in: filters['product'].map((productId) => productId.id),
              }
            : { _is_null: false },
    }

    if (myProducts) {
        // @ts-ignore
        delete aggregateVars.userId
    } else {
        // @ts-ignore
        delete aggregateVars.approverId
    }

    useEffect(() => {
        getProductAggregate({
            fetchPolicy: 'cache-and-network',
            variables: aggregateVars,
        })
    }, [filters])

    function fetchAggregate({
        myProducts = true,
        collectionId = [],
    }: {
        myProducts?: boolean
        collectionId?: string[]
    }) {
        const aggregateVars = {
            userId: myProducts ? {} : { _eq: Number(userId) },
            approverId: myProducts ? { _eq: Number(userId) } : {},
            id:
                collectionId.length > 0
                    ? {
                          _in:
                              collectionId ||
                              filters['product']?.map(
                                  (productId) => productId.id
                              ),
                      }
                    : { _is_null: false },
        }
        if (myProducts) {
            // @ts-ignore
            delete aggregateVars.userId
        } else {
            // @ts-ignore
            delete aggregateVars.approverId
        }
        getProductAggregate({
            fetchPolicy: 'cache-and-network',
            variables: aggregateVars,
        })
    }

    return {
        fetchAggregate,
        fetchMore: () =>
            fetchMore({
                variables: {
                    ...vars,
                    offset: data?.products.length,
                },
                updateQuery(previousQueryResult, options) {
                    return {
                        products: [
                            ...previousQueryResult.products,
                            ...options.fetchMoreResult.products,
                        ],
                    }
                },
            }),
        isLoading: loading,
        products: data?.products || [],
        networkStatus,
        productDetails: {
            new: aggregate.data?.new?.aggregate.count || 0,
            inProgress: aggregate.data?.inProgress?.aggregate.count || 0,
            complete: aggregate.data?.completed?.aggregate.count || 0,
            inReview: aggregate.data?.inReview?.aggregate.count || 0,
            revisionRequested:
                aggregate.data?.revisionRequested?.aggregate.count || 0,
        },
    }
}
