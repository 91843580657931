import React from 'react'
import { Box, Text } from 'src/components/utility'
import CalendarDropdown from '../../dropdowns/calendar'
import StatusDropdown from '../../dropdowns/status'
import { Link, useNavigate } from 'react-router-dom'
import { ProductIco } from 'src/components/svg-icons'
import AssignUser from 'src/components-v2/dropdown-badges/assign-user/assign-user'
import useUpdateTodo from 'src/hooks/useUpdateTodo'
import useStore from 'src/store/hooks/useStore'
import type { Status_Enum, User_Roles_Enum } from 'src/generated/graphql'

function TodoPopoverCard({
    id,
    name,
    productVariant,
    assignee,
    dueDate,
    isDelayed,
    status,
    types,
    refetch,
}: {
    id: number
    name: string
    productVariant:
        | {
              id: number
              name: string
          }
        | undefined
        | null
    assignee:
        | {
              email: string
              name: string
              id: any
              other_data: any
              organisation_members: {
                  role: User_Roles_Enum
              }[]
          }
        | null
        | undefined
    dueDate: string
    isDelayed: boolean
    status: Status_Enum
    types: string
    refetch: () => void
}) {
    const navigate = useNavigate()
    const { updateTodoStatus, updateTodoDueDate, updateTodoAssignee } =
        useUpdateTodo()
    const { globalSearchFilters, updateGlobalSearchResults } = useStore()
    return (
        <Box
            width="100%"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="#DDDDDD"
            py={'8px'}
            px={'16px'}
            display="grid"
            gridGap="24px"
            gridTemplateColumns={'1.5fr 1fr'}
            onClick={() => {
                navigate(`/todo/${id}`)
            }}
        >
            <Box display="flex" flexDirection="column" gridGap="4px">
                <Box
                    display="grid"
                    width={'100%'}
                    gridTemplateColumns={'auto minmax(50%,1fr)'}
                    overflow={'hidden'}
                    gridGap="16px"
                    alignItems="center"
                >
                    <Text
                        color="textSecondaryDarkBlue"
                        overflow={'hidden'}
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        fontSize={'14px'}
                        my="0px"
                        title={name}
                    >
                        {name}
                    </Text>

                    {productVariant && (
                        <Box
                            as={Link}
                            to={`/collections/product/${productVariant.id}`}
                            display="flex"
                            gridGap="8px"
                            alignItems="center"
                            style={{ textDecoration: 'none' }}
                            color="links"
                            width={'100%'}
                            overflow={'hidden'}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <ProductIco color="#027AC3" />
                            <Text
                                my="0px"
                                width="100%"
                                title={productVariant.name}
                                color="links"
                                fontSize={'12px'}
                                overflow={'hidden'}
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {productVariant.name}
                            </Text>
                        </Box>
                    )}
                </Box>
                <AssignUser
                    assignee={assignee}
                    onRemove={() => {
                        updateTodoAssignee({
                            todoId: id,
                            assignee_id: null,
                            onCompleted: () => {
                                refetch()
                            },
                        })
                    }}
                    role="Assignee"
                    onAssign={(user) => {
                        updateTodoAssignee({
                            todoId: id,
                            assignee_id: user.id,
                            onCompleted: () => {
                                refetch()
                            },
                        })
                    }}
                    showIcon={false}
                />
            </Box>
            <Box display="flex" gridGap="16px">
                <CalendarDropdown
                    date={new Date(dueDate)}
                    setDate={(date) => {
                        updateTodoDueDate({
                            id: id,
                            due_date: date.toISOString(),
                            onCompleted: () => {
                                refetch()
                                globalSearchFilters.isGlobalSearchActive &&
                                    updateGlobalSearchResults({
                                        id: String(id),
                                        type: 'todos',
                                        updatedKey: 'due_date',
                                        value: date.toISOString(),
                                    })
                            },
                        })
                    }}
                    isDelayed={isDelayed}
                />
                <StatusDropdown
                    exclude={['revisionRequested', 'toBeReviewed']}
                    onStatusChange={(status) => {
                        updateTodoStatus({
                            id: id,
                            status,
                            onCompleted: () => {
                                refetch()
                                globalSearchFilters.isGlobalSearchActive &&
                                    updateGlobalSearchResults({
                                        id: String(id),
                                        type: 'todos',
                                        updatedKey: 'status',
                                        value: status,
                                    })
                            },
                        })
                    }}
                    status={status}
                    isManager={false}
                />
            </Box>
            {/* {types !== 'techPack' && !!productVariant && (
                <Box
                    display="grid"
                    gridTemplateColumns={'auto 1fr'}
                    gridGap="8px"
                    overflow={'hidden'}
                >
                    <TodoWithArrowsIco />
                    <PreviousTodoBadge
                        todoId={id}
                        productVariantId={productVariant.id}
                    />
                </Box>
            )} */}
        </Box>
    )
}

export default TodoPopoverCard
