import React from 'react'
import { Box, Button } from 'src/components/utility'
import { SendIco } from 'src/components/svg-icons'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import type { Comment } from 'src/store/hooks/useCommentsStore'
import { useSearchParams } from 'react-router-dom'
import CommentsInput from './comments-input'
import CommentContainer from './comment-container'

function CommentsDropdown({
    comments,
    loading,
    setShowComments,
    height,
    width,
    editor,
    onComment,
    onDelete,
}: {
    comments: Array<Comment> | undefined
    loading: boolean
    setShowComments: React.Dispatch<React.SetStateAction<boolean>>
    height?: string
    width?: string
    editor: any
    onComment: () => void
    onDelete: (commentId: string) => void
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const wrapperRef = useClickedOutside(() => {
        setShowComments(false)
        searchParams.delete('showComments')
        setSearchParams(searchParams, { replace: true })
    }, [
        'comment-button',
        'mentions-wrapper',
        'mention-user-or-team',
        'mention-user-or-team-name',
        'mention-user-or-team-profile-img',
    ])

    return (
        <Box
            position="absolute"
            right="0px"
            top="24px"
            width={width ? width : '435px'}
            height={height ? height : '80vh'}
            bg="panelBackground"
            zIndex={15}
            borderRadius="4px"
            pt="32px"
            display="grid"
            gridTemplateRows={'1fr auto'}
            gridGap="10px"
            ref={wrapperRef}
            boxShadow="rgba(153, 155, 168, 0.25) 0px 2px 12px"
        >
            <CommentContainer
                comments={comments}
                loading={loading}
                onDelete={onDelete}
            />
            <Box
                borderTop="1px solid"
                borderColor="#B8B8B8"
                style={{ borderRadius: '4px 4px 0px 0px' }}
                p="8px"
            >
                <Box
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                    px="16px"
                    py="12px"
                    borderRadius="4px"
                    bg="white"
                >
                    <CommentsInput editor={editor} />
                    <Button
                        onClick={onComment}
                        type="submit"
                        bg="transparent"
                        display="flex"
                        alignItems="center"
                        border="none"
                    >
                        <SendIco color={'#022143'} />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default CommentsDropdown
