import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, InlineError } from 'src/components'
import { Box, Input, Span, Text } from 'src/components/utility'
import { InsertCategory } from 'src/services'
import { FetchCategoriesBySearchStr } from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { debounce } from 'src/helpers'

export const AddCategoryModal = ({
    isModal,
    setModal,
    refetchCategories,
}: {
    isModal: boolean
    setModal: (x: boolean) => void
    refetchCategories: (x: boolean) => void
}) => {
    const [category, setCategory] = useState<string>()
    const [insertCategory, categoryAdding] = useMutation(InsertCategory)
    const [searchParams, setSearchParams] = useSearchParams()
    const [categoryExisits, setCategoryAlreayExists] = useState<boolean>(false)
    const [fetchCatergory] = useLazyQuery(FetchCategoriesBySearchStr)

    const addRouterParams = (tabId: string) => {
        searchParams.set(
            libraryDefaultKeys.tab,
            `${libraryDefaultKeys.tabNamePrefix}${tabId}`
        )
        searchParams.set(libraryDefaultKeys.categoryId, tabId)
        if (searchParams.has(libraryDefaultKeys.showFolderDetails)) {
            searchParams.delete(libraryDefaultKeys.showFolderDetails)
        }
        if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
            searchParams.delete(libraryDefaultKeys.showFileDetails)
        }
        if (searchParams.has(libraryDefaultKeys.filters)) {
            searchParams.delete(libraryDefaultKeys.filters)
        }
        setSearchParams(searchParams)
    }

    const _errorMessage: string = 'The category name already exists.'

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debounceFn = debounce(
        () => {
            findingCategory()
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        if (category) {
            debounceFn()
        } else {
            setCategoryAlreayExists(false)
        }
    }, [category])

    const findingCategory = () => {
        setCategoryAlreayExists(false)
        fetchCatergory({
            variables: {
                searchStr: category ? `%${category}%` : `%%`,
                limit: 100,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (data: any) => {
                const _exCategories: any[] = data.category
                let found = _exCategories.find(
                    (item: any) =>
                        item.name.toLowerCase() === category?.toLowerCase()
                )
                if (found) {
                    setCategoryAlreayExists(true)
                }
            },
        })
    }

    const addNewCategory = () => {
        if (!category) {
            toast.error('Please add category name.', {
                className: 'custom-toaster toaster-danger toaster-error',
            })
            return
        }

        if (category.length > 25) {
            toast.error('Characters must be less than or equal to 25.', {
                className: 'custom-toaster toaster-danger toaster-error',
            })
            return
        }

        if (categoryExisits) {
            toast.error(_errorMessage, {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        try {
            insertCategory({
                variables: {
                    name: category,
                },
                onCompleted: (data: any) => {
                    toast.success('Category added successfully!', {
                        className: 'custom-toaster toaster-success',
                    })
                    refetchCategories(true)
                    setModal(false)

                    searchParams.set('tabName', category || '')
                    setSearchParams(searchParams)
                    if (data?.insert_category_one?.id) {
                        setTimeout(() => {
                            addRouterParams(data?.insert_category_one?.id)
                        }, 500)
                    }
                },
                onError: (error) => {
                    console.error(error)
                    toast.error('Failed to add category!', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Modal
            isModal={isModal}
            onClick={() => addNewCategory()}
            onClose={() => setModal(false)}
            primaryButtonTitle="Add"
            secondaryButtonTitle="Cancel"
            title="Add Category"
            isLoading={categoryAdding.loading}
            disablePrimaryButton={
                !category ||
                !category.trim() ||
                categoryAdding.loading ||
                categoryExisits
            }
        >
            <Box p={'24px'} pb={'80px'}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    alignItems="start"
                    gridRowGap="12px"
                    fontSize="14px"
                >
                    <Text
                        mt="0px"
                        mb="0px"
                        fontFamily="Rubik"
                        color="primary"
                        textAlign="left"
                        width="100%"
                    >
                        Category Name
                    </Text>
                    <Input
                        autoComplete="false"
                        placeholder="Enter"
                        bg="white"
                        borderRadius="4px"
                        px="16px"
                        py="16px"
                        minWidth="360px"
                        fontSize="14px"
                        border="solid"
                        borderWidth={1}
                        borderColor="gray350"
                        className="placeholder-darkgray"
                        type="text"
                        value={category}
                        maxLength={25}
                        minLength={3}
                        required
                        autoFocus
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCategory(e.target.value)
                        }}
                    />
                    <Span fontSize={'14px'} color="textSecondary">
                        {' '}
                        {25 - (category?.length || 0)} characters left
                    </Span>
                    {categoryExisits && (
                        <InlineError width="auto" text={_errorMessage} />
                    )}
                </Box>
            </Box>
        </Modal>
    )
}
