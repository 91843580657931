import React, { useState } from 'react'
import ProductListCard from 'src/components-v2/entity-list-card/product-list.card'
import { Box, Button, Text } from 'src/components/utility'
import type {
    GetCollectionProductsQuery,
    GetDashboardAllProductQuery,
} from 'src/generated/graphql'

type ProductType = NonNullable<GetCollectionProductsQuery>['products'][number]

function ProductVariantFilterContainer({
    onClick,
    product,
    status,
    refetch,
    isFullCard = true,
    refetchQueries = [],
}: {
    onClick?: () => void
    product:
        | ProductType
        | NonNullable<GetDashboardAllProductQuery>['products'][number]
    status: string
    isFullCard?: boolean
    refetch?: () => void
    refetchQueries?: string[]
}) {
    const [showOtherVariants, setShowOtherVariants] = useState(false)

    // Get all the base product and variants with the active status and put the base variant first if present
    const currentStatusVariantsWithBaseVariantFirstIfPresent =
        product.product_variants.reduce(
            (acc: ProductType['product_variants'], variant) => {
                if (status === 'all' || variant.status === status) {
                    if (variant.is_primary) {
                        return [variant, ...acc]
                    } else {
                        return [...acc, variant]
                    }
                }
                return acc
            },
            []
        )

    const otherVariants = product?.product_variants
        ?.filter((variant) => variant.status !== status)
        ?.sort((a, b) => (b.is_primary ? 1 : -1))

    return (
        <Box
            onClick={() => onClick && onClick()}
            display="flex"
            flexDirection="column"
            pb={
                showOtherVariants ||
                otherVariants?.length === 0 ||
                status === 'all'
                    ? '0px'
                    : '16px'
            }
        >
            {currentStatusVariantsWithBaseVariantFirstIfPresent &&
                currentStatusVariantsWithBaseVariantFirstIfPresent?.length >
                    0 && (
                    <Box display="flex" flexDirection="column">
                        {currentStatusVariantsWithBaseVariantFirstIfPresent?.map(
                            (currentStatusVariant) => {
                                return (
                                    <ProductListCard
                                        refetchQueries={refetchQueries}
                                        isFullCard={isFullCard}
                                        key={currentStatusVariant.id}
                                        isVariant={
                                            !currentStatusVariant.is_primary
                                        }
                                        product={{
                                            id: currentStatusVariant.id,
                                            name: currentStatusVariant.name,
                                            status: currentStatusVariant.status,
                                            collection:
                                                currentStatusVariant.collection,
                                            parentProductId: product.id,
                                            dueDate:
                                                currentStatusVariant.due_date,
                                            isDelayed:
                                                currentStatusVariant.is_delayed,
                                            todosCount: {
                                                total:
                                                    currentStatusVariant
                                                        .totalTodos.aggregate
                                                        ?.count || 0,
                                                completed:
                                                    currentStatusVariant
                                                        .completedTodos
                                                        .aggregate?.count || 0,
                                            },
                                            productVariantApprover:
                                                currentStatusVariant.product_variant_approver,
                                            coverPic:
                                                currentStatusVariant.other_data
                                                    ?.cover_pic,
                                        }}
                                        refetchProducts={refetch}
                                    />
                                )
                            }
                        )}
                        {status !== 'all' &&
                            otherVariants &&
                            otherVariants?.length > 0 && (
                                <Box
                                    borderLeft="1px solid #778CA2"
                                    borderBottom={
                                        showOtherVariants
                                            ? 'none'
                                            : '1px solid #778CA2'
                                    }
                                    style={{
                                        borderRadius: showOtherVariants
                                            ? '0px'
                                            : '0px 0px 0px 12px',
                                    }}
                                    pl="20px"
                                    position="relative"
                                    bg={
                                        showOtherVariants
                                            ? 'panelBackground'
                                            : 'transparent'
                                    }
                                >
                                    <Box height={'28px'}></Box>
                                    <Button
                                        position="absolute"
                                        top="6px"
                                        height="max-content"
                                        bg={
                                            showOtherVariants
                                                ? 'panelBackground'
                                                : 'backgroundColor'
                                        }
                                        width="calc(100% - 20px)"
                                        textAlign="left"
                                        border="none"
                                        onClick={() => {
                                            setShowOtherVariants(
                                                !showOtherVariants
                                            )
                                        }}
                                    >
                                        <Text
                                            my="0px"
                                            pt="12px"
                                            fontSize={'14px'}
                                            color="links"
                                        >
                                            {showOtherVariants
                                                ? 'Hide'
                                                : 'Show'}{' '}
                                            {otherVariants?.length} related{' '}
                                            {otherVariants?.length === 1
                                                ? 'base product and variant'
                                                : 'base products and variants'}{' '}
                                            with different statuses
                                        </Text>
                                    </Button>
                                    {showOtherVariants && (
                                        <Box
                                            py="24px"
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            {otherVariants?.map((variant) => {
                                                return (
                                                    <ProductListCard
                                                        refetchQueries={
                                                            refetchQueries
                                                        }
                                                        isFullCard={isFullCard}
                                                        key={variant.id}
                                                        isVariant={
                                                            !variant.is_primary
                                                        }
                                                        product={{
                                                            id: variant.id,
                                                            name: variant.name,
                                                            status: variant.status,
                                                            collection:
                                                                variant.collection,
                                                            parentProductId:
                                                                product.id,
                                                            dueDate:
                                                                variant.due_date,
                                                            isDelayed:
                                                                variant.is_delayed,
                                                            productVariantApprover:
                                                                variant.product_variant_approver,
                                                            todosCount: {
                                                                total:
                                                                    variant
                                                                        .totalTodos
                                                                        .aggregate
                                                                        ?.count ||
                                                                    0,
                                                                completed:
                                                                    variant
                                                                        .completedTodos
                                                                        .aggregate
                                                                        ?.count ||
                                                                    0,
                                                            },
                                                            coverPic:
                                                                variant
                                                                    .other_data
                                                                    ?.cover_pic,
                                                        }}
                                                        refetchProducts={
                                                            refetch
                                                        }
                                                    />
                                                )
                                            })}
                                        </Box>
                                    )}
                                </Box>
                            )}
                    </Box>
                )}
        </Box>
    )
}

export default ProductVariantFilterContainer
