import React from 'react'
import { SearchIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'

function SearchBox({
    value,
    onChange,
}: {
    value: string
    onChange: (value: string) => void
}) {
    return (
        <Box
            display="flex"
            py="8px"
            px="12px"
            border="1px solid"
            borderColor="#ddd"
            borderRadius="4px"
            width={'100%'}
        >
            <SearchIco color="#778CA2" />
            <Input
                placeholder="Search Files"
                border="none"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                bg="transparent"
                fontSize="14px"
                ml="8px"
                width="100%"
            />
        </Box>
    )
}

export default SearchBox
