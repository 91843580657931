/* eslint-disable @typescript-eslint/no-use-before-define */
import { LogoIcoSm } from 'src/components/svg-icons'
import { Box, Text, Button, Input } from 'src/components/utility'

import { DivisionLine, Loader } from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { Tag } from 'src/components'
import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GetUserByEmail, InviteUser } from 'src/services'
import { useNavigate } from 'react-router-dom'
import { getLocalStorage } from 'src/helpers'
import { toast } from 'react-toastify'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Failure } from 'src/helpers/toast-text-messages'

const AddMembers = () => {
    const searchAutoFill = useRef<HTMLInputElement | null>(null)
    const navigate = useNavigate()
    const [role, setRole] = useState<string>('No Role Selected')
    const [email, setEmail] = useState<string>('')
    const [tags, setTags] = useState<{ email: string; role: string }[]>([])
    const [isLoading, setIsLoading] = useState<boolean | null>(null)
    const [sendInviteMutation, { data, loading, error }] =
        useMutation(InviteUser)
    const [findSearchUser] = useLazyQuery(GetUserByEmail)
    const user = getLocalStorage('user')
    useEffect(() => {
        if (!user) return
        const parsedUser = JSON.parse(user)
        if (!user) {
            return navigate('/')
        } else if (
            parsedUser.role !== 'admin' &&
            parsedUser.role !== 'manager'
        ) {
            return navigate('/')
        }
    }, [])

    function addTag() {
        const emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (email.length <= 0)
            return toast(Failure.conditionChecks.email.noEmail, {
                className: 'custom-toaster toaster-error',
            })
        if (tags.find((tag) => tag.email === email))
            return toast(Failure.conditionChecks.email.alreadyAdded, {
                className: 'custom-toaster toaster-error',
            })
        if (email.match(emailRegex) === null)
            return toast('Invalid email address', {
                className: 'custom-toaster toaster-error',
            })
        if (
            role.toLowerCase() !== 'admin' &&
            role.toLowerCase() !== 'manager' &&
            role.toLowerCase() !== 'designer'
        )
            return toast('Please select a role.', {
                className: 'custom-toaster toaster-error',
            })
        findSearchUser({
            variables: {
                email: `${email}`,
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data: any) => {
                const users = data.users
                const invitees = data.invitations
                const orgMember = data.organisation_members
                if (
                    users.length > 0 ||
                    invitees.length > 0 ||
                    orgMember.length > 0
                ) {
                    toast.error(`${email} already exists.`, {
                        className: 'custom-toaster toaster-error',
                    })
                } else {
                    setTags((tags) => {
                        return [...tags, { email: email, role: role }]
                    })
                    setEmail('')
                    setRole('No Role Selected')
                }
            },
        })
    }
    async function sendInvites() {
        if (tags.length > 0) {
            setIsLoading(true)
            await sendInviteMutation({
                variables: {
                    objects: tags.map((tag) => ({
                        ...tag,
                        role: tag.role.toLowerCase(),
                    })),
                },
                onError: (e) => {
                    if (e.message.includes('Uniqueness violation')) {
                        toast(Failure.conditionChecks.email.alreadyExistinDb, {
                            className: 'custom-toaster toaster-error',
                        })
                    } else {
                        toast('Error occurred while sending invites', {
                            className: 'custom-toaster toaster-error',
                        })
                    }
                },
                onCompleted: () => {
                    setTags([])
                    toast(
                        `Sent invite to ${tags.length} user${
                            tags.length > 1 ? 's' : ''
                        }`,
                        { className: 'custom-toaster toaster-success' }
                    )
                },
            })
        }
        setIsLoading(false)
    }
    function removeTag(email: string) {
        setTags((tags) => tags.filter((tag) => tag.email !== email))
    }
    return (
        <>
            <Box height="100vh">
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    height="70%"
                    bg="white"
                    pt="72px"
                    pb="48px"
                >
                    <Box
                        bg="white"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        width="550px"
                        alignItems="center"
                    >
                        <Box height="50px">
                            <LogoIcoSm />
                        </Box>

                        <Text
                            color="primary"
                            fontSize={{ md: '20px', lg: '32px' }}
                            fontFamily="Rubik"
                            fontWeight={500}
                            // mt="80px"
                            mb="12px"
                        >
                            Add your Team Members
                        </Text>

                        <Text
                            className="text-wrap"
                            color="textSecondary"
                            fontSize="16px"
                            fontFamily="Rubik"
                            textAlign="center"
                            mt="0px"
                            mb="0px"
                        >
                            Provide email addresses of your team members
                        </Text>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                            fontSize="14px"
                            position="relative"
                            // width="360px"
                            mt="56px"
                        >
                            <Box display="flex" gridColumnGap="24px">
                                <Text
                                    width="100%"
                                    fontFamily="Rubik"
                                    color="primary"
                                    my="0px"
                                >
                                    Email Address
                                </Text>
                                <Text
                                    width="100%"
                                    my="0px"
                                    ml="48px"
                                    fontFamily="Rubik"
                                    color="primary"
                                >
                                    Role
                                </Text>
                            </Box>
                            <Box
                                display="flex"
                                gridColumnGap="24px"
                                flexDirection="column"
                                gridRowGap="16px"
                                maxHeight="116px"
                                mb="20px"
                            >
                                <form
                                    onSubmit={(
                                        e: React.FormEvent<HTMLFormElement>
                                    ) => {
                                        e.preventDefault()
                                        addTag()
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gridColumnGap="12px"
                                        alignItems="center"
                                    >
                                        <Box width="100%" position="relative">
                                            <Input
                                                autoComplete="false"
                                                type="email"
                                                borderRadius="4px"
                                                border="solid"
                                                borderWidth={1}
                                                borderColor="gray350"
                                                minWidth="330px"
                                                bg="white"
                                                px="16px"
                                                py="16px"
                                                fontSize="14px"
                                                value={email}
                                                placeholder="Email Address"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => setEmail(e.target.value)}
                                            />
                                        </Box>
                                        <Box></Box>
                                        <Box width="100%" position="relative">
                                            {/* <Input autoComplete='false'
                                                borderRadius="4px"
                                                fontSize="14px"
                                                border="solid"
                                                borderWidth={1}
                                                minWidth="244px"
                                                borderColor="gray350"
                                                bg="white"
                                                px="16px"
                                                py="16px"
                                                value={role}
                                                placeholder="Enter Role"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => setRole(e.target.value)}
                                            /> */}
                                            <Box
                                                minWidth="244px"
                                                borderRadius="4px"
                                                fontSize="14px"
                                                border="solid"
                                                bg="white"
                                                px="16px"
                                                py="16px"
                                                borderColor="gray350"
                                                borderWidth={1}
                                            >
                                                <CustomDropdown
                                                    minWidth="244px"
                                                    labelFontSize="14px"
                                                    showArrow
                                                    noStyle
                                                    label=""
                                                    selectedText={role}
                                                    items={[
                                                        'Admin',
                                                        'Manager',
                                                        'Designer',
                                                    ]}
                                                    onItemClicked={setRole}
                                                />
                                            </Box>
                                        </Box>
                                        <Button
                                            bg="transparent"
                                            border="none"
                                            width="max-content"
                                            color="links"
                                            p="0px"
                                            fontWeight={500}
                                            type="submit"
                                        >
                                            Add
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                            <Box width="98%">
                                <DivisionLine color="#DDDDDD70" width={1} />
                            </Box>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                gridColumnGap="8px"
                                gridRowGap="4px"
                                maxHeight="60px"
                                overflowY="auto"
                            >
                                {tags.map((tag, key) => {
                                    return (
                                        <Tag
                                            key={key}
                                            action={true}
                                            color="#F5F6A9"
                                            onClick={() => removeTag(tag.email)}
                                            element={
                                                <Text mt="0px" mb="0px">
                                                    {tag.email} | {tag.role}
                                                </Text>
                                            }
                                        />
                                    )
                                })}
                            </Box>

                            <Box
                                width="360px"
                                display="flex"
                                mt="40px"
                                justifyContent="start"
                                gridColumnGap="24px"
                            >
                                <ColoredButton
                                    variant="primary"
                                    width="100%"
                                    onClick={() => sendInvites()}
                                    disabled={isLoading || tags.length <= 0}
                                >
                                    <Box
                                        display="flex"
                                        gridColumnGap="8px"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        {isLoading && <Loader />} Send Invites
                                    </Box>
                                </ColoredButton>
                                <ColoredButton
                                    variant="secondary"
                                    width="100%"
                                    onClick={() => navigate('/')}
                                >
                                    Skip
                                </ColoredButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="auth-body" width="100%" height="100%" />
            </Box>
        </>
    )
}

export default AddMembers
