import { useMutation } from '@apollo/client'
import React from 'react'
import { DescriptionIco } from 'src/components/svg-icons'
import AttachmentIco from 'src/components/svg-icons/attachment-ico'
import InsertColorsIco from 'src/components/svg-icons/insert-colors-ico'
import LinkIco from 'src/components/svg-icons/link-ico'
import { Box, Button } from 'src/components/utility'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { UpdateSubtodoSection } from '../../../../services/graphql/entities/todo/todo.mutation'
import { UpdateTemplateSubtodoSection } from 'src/services/graphql/entities/todo/todo-templates.mutations'

function SubtodoSectionFieldsBar({
    isColorsPresent,
    isDescriptionPresent,
    isFilesPresent,
    isLinksPresent,
    subtodoSectionId,
    refetchTodo,
    onClick,
    sectionId,
    isTemplate,
}: {
    isColorsPresent: boolean
    isLinksPresent: boolean
    isDescriptionPresent: boolean
    isFilesPresent: boolean
    subtodoSectionId: number
    refetchTodo: () => void
    onClick?: () => void
    sectionId: number
    isTemplate?: boolean
}) {
    const [updateSubtodoSection] = useMutation(UpdateSubtodoSection)
    const [updateTemplateSubtodoSection] = useMutation(
        UpdateTemplateSubtodoSection
    )

    function filesClickHandler() {
        if (isFilesPresent) {
            scrollToSection(`files_${sectionId}`)
        } else {
            if (isTemplate) {
                updateTemplateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            other_data: {
                                files: [],
                            },
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()

                        setTimeout(
                            () => scrollToSection(`files_${sectionId}`),
                            500
                        )
                    },
                })
            } else {
                updateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            other_data: {
                                files: [],
                            },
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()

                        setTimeout(
                            () => scrollToSection(`files_${sectionId}`),
                            500
                        )
                    },
                })
            }
        }
    }

    function colorsClickHandler() {
        if (isColorsPresent) {
            scrollToSection(`colors_${sectionId}`)
        } else {
            if (isTemplate) {
                updateTemplateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            colors: [],
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()

                        setTimeout(
                            () => scrollToSection(`colors_${sectionId}`),
                            500
                        )
                    },
                })
            } else {
                updateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            colors: [],
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()

                        setTimeout(
                            () => scrollToSection(`colors_${sectionId}`),
                            500
                        )
                    },
                })
            }
        }
    }

    function descriptionClickHandler() {
        if (isDescriptionPresent) {
            scrollToSection(`description_${sectionId}`)
        } else {
            if (isTemplate) {
                updateTemplateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            description: '',
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()
                        setTimeout(
                            () => scrollToSection(`description_${sectionId}`),
                            500
                        )
                    },
                })
            } else {
                updateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            description: '',
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()
                        setTimeout(
                            () => scrollToSection(`description_${sectionId}`),
                            500
                        )
                    },
                })
            }
        }
    }

    function linksClickHandler() {
        if (isLinksPresent) {
            scrollToSection(`links_${sectionId}`)
        } else {
            if (isTemplate) {
                updateTemplateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            links: [],
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()
                        setTimeout(
                            () => scrollToSection(`links_${sectionId}`),
                            500
                        )
                    },
                })
            } else {
                updateSubtodoSection({
                    variables: {
                        id: subtodoSectionId,
                        payload: {
                            links: [],
                        },
                    },
                    onCompleted: () => {
                        refetchTodo()
                        setTimeout(
                            () => scrollToSection(`links_${sectionId}`),
                            500
                        )
                    },
                })
            }
        }
    }

    return (
        <Box
            display="flex"
            gridGap="4px"
            p="2px"
            borderRadius="12px"
            height="28px"
            width="max-content"
            border="1px solid #E8ECEF"
            bg="white"
            onClick={onClick}
        >
            <Button
                onClick={descriptionClickHandler}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                p="0px"
                title="Add description"
            >
                <DescriptionIco />
            </Button>
            <Button
                onClick={filesClickHandler}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                p="0px"
                title="Add attachments"
            >
                <AttachmentIco />
            </Button>
            <Button
                onClick={colorsClickHandler}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                p="0px"
                title="Add colors"
            >
                <InsertColorsIco />
            </Button>

            <Button
                onClick={linksClickHandler}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                p="0px"
                title="Add links"
            >
                <LinkIco />
            </Button>
        </Box>
    )
}

export default SubtodoSectionFieldsBar
