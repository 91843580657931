import { gql } from '@apollo/client'

export const InsertTodoOne = gql`
    mutation AddTodo(
        $name: String
        $description: String
        $due_date: timestamptz
        $user_id:bigint
        $product_id: bigint
        $status: status_enum! = "new"
        $tags: jsonb,
    ) {
        insert_todos_one(
            object: {
                name: $name
                description: $description
                due_date: $due_date
                user_id:$user_id
                product_id:$product_id
                status: $status,
                tags: $tags,
            }
        ) {
            status
            product_id
            name
            id
        }
    }
`;
