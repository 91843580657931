import { gql } from '@apollo/client'

export const UpdateProduct = gql`
    mutation UpdateProduct($id: bigint!, $object: products_set_input!) {
        update_products_by_pk(pk_columns: { id: $id }, _set: $object) {
            id
        }
    }
`

export const UpdateProductVariant = gql`
    mutation UpdateProductVariant(
        $id: bigint!
        $object: product_variants_set_input!
    ) {
        update_product_variants_by_pk(pk_columns: { id: $id }, _set: $object) {
            id
        }
    }
`

export const UpdateProductStatus = gql`
    mutation UpdateProductStatus($id: bigint!, $status: status_enum!) {
        update_products_by_pk(
            pk_columns: { id: $id }
            _set: { status: $status }
        ) {
            id
            status
        }
    }
`

export const UpdateProductVariantStatus = gql`
    mutation UpdateProductVariantStatus($id: bigint!, $status: status_enum!) {
        update_product_variants_by_pk(
            pk_columns: { id: $id }
            _set: { status: $status }
        ) {
            id
            status
            collection {
                id
                status
            }
        }
    }
`

export const UpdateProductVariantStatusAction = gql`
    mutation UpdateVariantStatusAction($status: String!, $variant_id: String!) {
        updateProductVariantStatus(
            arg1: { product_variant_id: $variant_id, status: $status }
        ) {
            status
            __typename
            collection {
                id
                status
                __typename
            }
        }
    }
`

export const UpdateProductVariantName = gql`
    mutation UpdateProductVariantName($id: bigint!, $name: String!) {
        update_product_variants_by_pk(
            pk_columns: { id: $id }
            _set: { name: $name }
        ) {
            id
            name
        }
    }
`

export const UpdateProductDueDate = gql`
    mutation UpdateProductDueDate($id: bigint!, $due_date: timestamptz!) {
        update_products_by_pk(
            pk_columns: { id: $id }
            _set: { due_date: $due_date }
        ) {
            id
            due_date
        }
    }
`

export const UpdateProductVariantDueDate = gql`
    mutation UpdateProductVariantDueDate(
        $id: bigint!
        $due_date: timestamptz!
    ) {
        update_product_variants_by_pk(
            pk_columns: { id: $id }
            _set: { due_date: $due_date }
        ) {
            id
            due_date
            __typename
        }
    }
`

export const CreateProductVariant = gql`
    mutation CreateProductVariant($object: product_variants_insert_input!) {
        insert_product_variants_one(object: $object) {
            id
            name
        }
    }
`

export const DeleteProductVariant = gql`
    mutation DeleteProductVariant(
        $id: bigint = ""
        $deleted_at: timestamptz = ""
    ) {
        update_product_variants_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: $deleted_at }
        ) {
            id
        }
    }
`
