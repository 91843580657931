import { MeshPhysicalMaterial } from 'three'

import { Color } from 'three'
class MeshCustomMaterial extends MeshPhysicalMaterial {
    //Custom extension

    constructor(parameters) {
        super()

        this.isMeshCustomMaterial = true

        this.defines = {
            STANDARD: '',
            PHYSICAL: '',
            CUSTOM: '',
        }

        this.type = 'MeshCustomMaterial'

        this.diffuseMultiplierBase = 0.5
        this.desaturateBase = 0.0
        this.colorizeLowlightsBase = 0.0

        this.srcColor1_Base = new Color(0xff0000)
        this.destColor1_Base = new Color(0xff0000)
        this.srcBWHue1_Base = 0.0
        this.emphasizeSrcLumSat1_Base = 0.0
        this.cutoutColor1_Base = 0.0

        this.srcColor2_Base = new Color(0xff0000)
        this.destColor2_Base = new Color(0xff0000)
        this.srcBWHue2_Base = 0.0
        this.emphasizeSrcLumSat2_Base = 0.0
        this.cutoutColor2_Base = 0.0

        this.srcColor3_Base = new Color(0xff0000)
        this.destColor3_Base = new Color(0xff0000)
        this.srcBWHue3_Base = 0.0
        this.emphasizeSrcLumSat3_Base = 0.0
        this.cutoutColor3_Base = 0.0

        this.srcColor4_Base = new Color(0xff0000)
        this.destColor4_Base = new Color(0xff0000)
        this.srcBWHue4_Base = 0.0
        this.emphasizeSrcLumSat4_Base = 0.0
        this.cutoutColor4_Base = 0.0

        this.srcColor5_Base = new Color(0xff0000)
        this.destColor5_Base = new Color(0xff0000)
        this.srcBWHue5_Base = 0.0
        this.emphasizeSrcLumSat5_Base = 0.0
        this.cutoutColor5_Base = 0.0

        this.srcColor6_Base = new Color(0xff0000)
        this.destColor6_Base = new Color(0xff0000)
        this.srcBWHue6_Base = 0.0
        this.emphasizeSrcLumSat6_Base = 0.0
        this.cutoutColor6_Base = 0.0

        this.mapLayer1 = null
        this.normalBlendingLayer1 = 1.0
        this.multiplicativeBlendingLayer1 = 0.0
        this.averageBlendingLayer1 = 0.0
        this.additiveBlendingLayer1 = 0.0
        this.subtractiveBlendingLayer1 = 0.0
        this.chemicalBlendingLayer1 = 0.0

        this.srcColor1_Layer1 = new Color(0xff0000)
        this.destColor1_Layer1 = new Color(0xff0000)
        this.srcBWHue1_Layer1 = 0.0
        this.emphasizeSrcLumSat1_Layer1 = 0.0
        this.cutoutColor1_Layer1 = 0.0

        this.srcColor2_Layer1 = new Color(0xff0000)
        this.destColor2_Layer1 = new Color(0xff0000)
        this.srcBWHue2_Layer1 = 0.0
        this.emphasizeSrcLumSat2_Layer1 = 0.0
        this.cutoutColor2_Layer1 = 0.0

        this.srcColor3_Layer1 = new Color(0xff0000)
        this.destColor3_Layer1 = new Color(0xff0000)
        this.srcBWHue3_Layer1 = 0.0
        this.emphasizeSrcLumSat3_Layer1 = 0.0
        this.cutoutColor3_Layer1 = 0.0

        this.srcColor4_Layer1 = new Color(0xff0000)
        this.destColor4_Layer1 = new Color(0xff0000)
        this.srcBWHue4_Layer1 = 0.0
        this.emphasizeSrcLumSat4_Layer1 = 0.0
        this.cutoutColor4_Layer1 = 0.0

        this.srcColor5_Layer1 = new Color(0xff0000)
        this.destColor5_Layer1 = new Color(0xff0000)
        this.srcBWHue5_Layer1 = 0.0
        this.emphasizeSrcLumSat5_Layer1 = 0.0
        this.cutoutColor5_Layer1 = 0.0

        this.srcColor6_Layer1 = new Color(0xff0000)
        this.destColor6_Layer1 = new Color(0xff0000)
        this.srcBWHue6_Layer1 = 0.0
        this.emphasizeSrcLumSat6_Layer1 = 0.0
        this.cutoutColor6_Layer1 = 0.0

        this.colorLayer1 = new Color(0xffffff)
        this.diffuseMultiplierLayer1 = 0.5
        this.desaturateLayer1 = 0.0

        this.colorizeLowlightsLayer1 = 0.0
        this.transparencyLayer1 = 0.0
        /*
		this.mapLayer1ShiftX = 0.0;
		this.mapLayer1ShiftY = 0.0;
		this.mapLayer1ScaleX = 1.0;
		this.mapLayer1ScaleY = 1.0;
		this.mapLayer1Rotate = 0.0;
		*/
        this.substancenessLayer1 = 1.0
        this.normalMapLayer1 = null
        this.normalScaleLayer1 = 1.0

        this.roughnessMapLayer1 = null
        this.roughnessLayer1 = 1.0

        this.metalnessMapLayer1 = null
        this.metalnessLayer1 = 0.0

        this.mapLayer2 = null
        this.colorLayer2 = new Color(0xffffff)
        this.transparencyLayer2 = 0.0
        this.substancenessLayer2 = 1.0
        this.normalMapLayer2 = null
        this.normalScaleLayer2 = 1.0

        this.mapLayer3 = null
        this.colorLayer3 = new Color(0xffffff)
        this.transparencyLayer3 = 0.0
        this.substancenessLayer3 = 1.0
        this.normalMapLayer3 = null
        this.normalScaleLayer3 = 1.0

        //this.colorMapBaking = false;
        //this.baking = 0;
        //this.baseColorBaking = 0.0;
        //this.ambientOcclusionBaking = 0.0;
        this.enableBaseColorBaking = false
        this.enableAmbientOcclusionBaking = false
        this.enableRoughnessBaking = false
        this.enableMetalnessBaking = false
        this.enableORMBaking = false
        this.enableNormalBaking = false

        this.setValues(parameters)
    }
    copy(source) {
        super.copy(source)

        this.defines = {
            STANDARD: '',
            PHYSICAL: '',
            CUSTOM: '',
        }

        this.diffuseMultiplierBase = source.diffuseMultiplierBase
        this.desaturateBase = source.desaturateBase
        this.colorizeLowlightsBase = source.colorizeLowlightsBase

        this.srcColor1_Base.copy(source.srcColor1_Base)
        this.destColor1_Base.copy(source.destColor1_Base)

        this.mapLayer1 = source.mapLayer1
        this.srcColor1_Layer1.copy(source.srcColor1_Layer1)
        this.destColor1_Layer1.copy(source.destColor1_Layer1)

        this.colorLayer1.copy(source.colorLayer1)
        this.diffuseMultiplierLayer1 = source.diffuseMultiplierLayer1
        this.desaturateLayer1 = source.desaturateLayer1
        this.colorizeLowlightsLayer1 = source.colorizeLowlightsLayer1
        this.transparencyLayer1 = source.transparencyLayer1
        /*
		this.mapLayer1ShiftX = source.mapLayer1ShiftX;
		this.mapLayer1ShiftY = source.mapLayer1ShiftY;
		this.mapLayer1ScaleX = source.mapLayer1ScaleX;
		this.mapLayer1ScaleY = source.mapLayer1ScaleY;
		this.mapLayer1Rotate = source.mapLayer1Rotate;
		*/
        this.normalMapLayer1 = source.normalMapLayer1
        this.normalScaleLayer1 = source.normalScaleLayer1
        this.substancenessLayer1 = source.substancenessLayer1

        this.roughnessMapLayer1 = source.roughnessMapLayer1
        this.roughnessLayer1 = source.roughnessLayer1

        this.metalnessMapLayer1 = source.metalnessMapLayer1
        this.metalnessLayer1 = source.metalnessLayer1

        this.mapLayer2 = source.mapLayer2
        this.colorLayer2.copy(source.colorLayer2)
        this.transparencyLayer2 = source.transparencyLayer2
        this.substancenessLayer2 = source.substancenessLayer2
        this.normalMapLayer2 = source.normalMapLayer2
        this.normalScaleLayer2 = source.normalScaleLayer2

        this.mapLayer3 = source.mapLayer3
        this.colorLayer3.copy(source.colorLayer3)
        this.transparencyLayer3 = source.transparencyLayer3
        this.substancenessLayer3 = source.substancenessLayer3
        this.normalMapLayer3 = source.normalMapLayer3
        this.normalScaleLayer3 = source.normalScaleLayer3

        //this.colorMapBaking = source.colorMapBaking;
        //this.baking = source.baking;
        //this.baseColorBaking = source.baseColorBaking;
        //this.ambientOcclusionBaking = source.ambientOcclusionBaking;
        this.enableBaseColorBaking = source.enableBaseColorBaking
        this.enableAmbientOcclusionBaking = source.enableAmbientOcclusionBaking
        this.enableRoughnessBaking = source.enableRoughnessBaking
        this.enableMetalnessBaking = source.enableMetalnessBaking
        this.enableORMBaking = source.enableORMBaking
        this.enableNormalBaking = source.enableNormalBaking

        return this
    }
}

export { MeshCustomMaterial }
