export const CollectionIco = () => (
    <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 1.41176H11.4706C11.6655 1.41176 11.8235 1.56978 11.8235 1.76471V13.2353C11.8235 13.4302 11.6655 13.5882 11.4706 13.5882H1.76471C1.56978 13.5882 1.41176 13.4302 1.41176 13.2353V1.76471C1.41176 1.56978 1.56978 1.41176 1.76471 1.41176H3.79412V6.61764C3.79412 6.86745 3.9446 7.09267 4.1754 7.18827C4.4062 7.28387 4.67186 7.23102 4.84851 7.05438L6.22231 5.68058L7.99651 7.09994C8.18191 7.24826 8.43592 7.27717 8.64991 7.17432C8.8639 7.07147 9 6.85506 9 6.61764V1.41176ZM0 1.76471C0 0.790086 0.790086 0 1.76471 0H11.4706C12.4452 0 13.2353 0.790086 13.2353 1.76471V13.2353C13.2353 14.2099 12.4452 15 11.4706 15H1.76471C0.790086 15 0 14.2099 0 13.2353V1.76471ZM5.02941 5.1265V1.49999H7.76471V5.33254L6.56231 4.37063C6.31655 4.17402 5.96227 4.19365 5.73973 4.41619L5.02941 5.1265Z"
            fill="#022143"
        />
    </svg>
)
