import type { User } from 'src/services/graphql/query/@types/collections'
import create from 'zustand'

type Status = 'inactive' | 'connecting' | 'active' | 'error'
const status: Status = 'inactive'

export type Comment = {
    id: string
    comment: string
    entity_name: string
    entity_id: string
    entity_info: string
    user_id: string
    created_at: string
    user: User
}

export type EntityComment = {
    entity_id: string
    entity_name: string
    comments: Comment[]
}

type Store = {
    comments: EntityComment[]
    annotation: TAnnotationComment[]
    mention: TMentionedComment[]
    all: TAnnotationComment[]
    myComments: {
        all: TAnnotationComment[]
        comments: TMentionedComment[]
        replies: TAnnotationComment[]
    }
    status: Status
    isLoading: boolean
    isRecentCommentsPresent: boolean
    dashboardCommentsPage: number
    sendMessage: (message: string) => void

    addAnnotationComment: (newComments: TAnnotationComment) => void
    addAnnotationComments: (newComments: TAnnotationComment[]) => void

    addMentionedComment: (newComments: TMentionedComment) => void
    addMentionedComments: (newComments: TMentionedComment[]) => void

    addMyComments: (newComments: {
        all: TAnnotationComment[]
        myComments: TMentionedComment[]
        myReplies: TAnnotationComment[]
    }) => void
    addMyComment: (newComments: {
        all: TAnnotationComment
        myComments: TMentionedComment
        myReplies: TAnnotationComment
    }) => void

    addComments: (newComments: EntityComment) => void
    addComment: (newComment: EntityComment) => void

    addAllComments: (
        comments: TMentionedComment[] | TAnnotationComment[]
    ) => void
    addAllComment: (comments: TMentionedComments | TAnnotationComment) => void

    removeComment: ({
        commentId,
        entity_id,
        entity_name,
    }: {
        commentId: string
        entity_id: string
        entity_name: string
    }) => void
    setComments: (comments: Comment[]) => void
    clearComments: () => void
    setStatus: (status: Status) => void
    setIsLoading: (isLoading: boolean) => void
    setSendMessage: (sendMessage: (message: string) => void) => void
    isEntityCommentsPresent: (entityId: string, entityName: string) => boolean
    setIsRecentCommentsPresent: (isPresent: boolean) => void
    setDashboardCommentsPage: (page: number) => void
}

export const useCommentsStore = create<Store>(
    (set: Function, get: Function) => ({
        comments: [],
        all: [],
        annotation: [],
        mention: [],
        myComments: {
            all: [],
            comments: [],
            replies: [],
        },
        status,
        dashboardCommentsPage: 0,
        isLoading: false,
        sendMessage: () => {},
        isRecentCommentsPresent: false,
        isEntityCommentsPresent: (entityId, entityName) => {
            const comments = get().comments
            const entityComments = comments.find(
                (comment: EntityComment) =>
                    comment.entity_id === entityId &&
                    comment.entity_name === entityName
            )
            return !!entityComments
        },
        setIsRecentCommentsPresent: (isPresent) => {
            set({ isRecentCommentsPresent: isPresent })
        },
        addAllComments: (newComment) => {
            set((state: Store) => ({
                all: [...state.all, ...newComment],
            }))
        },
        addAllComment: (newComment) => {
            set((state: Store) => ({
                all: [newComment, ...state.all],
            }))
        },

        addAnnotationComment: (newComment) => {
            set((state: Store) => ({
                annotation: [newComment, ...state.annotation],
            }))
        },
        addAnnotationComments: (newComment) => {
            set((state: Store) => ({
                annotation: [...state.annotation, ...newComment],
            }))
        },

        addMentionedComment: (newComment) => {
            set((state: Store) => {
                return {
                    mention: [newComment, ...state.mention],
                }
            })
        },
        addMentionedComments: (newComment) => {
            set((state: Store) => ({
                mention: [...state.mention, ...newComment],
            }))
        },
        addMyComments: ({ all, myComments, myReplies }) => {
            const store: Store = get()

            const comments = {
                all: [...store.myComments.all, ...all],
                comments: [...store.myComments.comments, ...myComments],
                replies: [...store.myComments.replies, ...myReplies],
            }

            set(() => ({
                myComments: comments,
            }))
        },
        addMyComment: ({ all, myComments, myReplies }) => {
            set((state: Store) => ({
                myComments: {
                    all: [all, ...state.myComments.all],
                    comments: [myComments, ...state.myComments.comments],
                    replies: [myReplies, ...state.myComments.replies],
                },
            }))
        },
        addComments: (newComments) => {
            set((state: Store) => ({
                comments: [...state.comments, newComments],
            }))
        },
        addComment: (newComment) => {
            set((state: Store) => ({
                comments: state.comments.map((comment) => {
                    if (
                        comment.entity_id === newComment.entity_id &&
                        comment.entity_name === newComment.entity_name
                    ) {
                        return {
                            ...comment,
                            comments: [
                                ...newComment.comments,
                                ...comment.comments,
                            ],
                        }
                    }
                    return comment
                }),
            }))
        },
        removeComment: ({ commentId, entity_id, entity_name }) => {
            set((state: Store) => {
                const comments = state.comments.map((comment) => {
                    if (
                        comment.entity_id === entity_id &&
                        comment.entity_name === entity_name
                    ) {
                        return {
                            ...comment,
                            comments: comment.comments.filter(
                                (comment) => comment.id !== commentId
                            ),
                        }
                    }
                    return comment
                })
                return { comments }
            })
        },
        setIsLoading: (isLoading) => set({ isLoading }),
        setComments: (comments) => set({ comments }),
        clearComments: () => set({ comments: [] }),
        setStatus: (status) => set({ status }),
        setSendMessage: (sendMessage) => set({ sendMessage }),
        setDashboardCommentsPage: (page) =>
            set({ dashboardCommentsPage: page }),
    })
)
