import { Button } from 'src/components/utility'
import { DropdownButtonWrapper, Dropdown } from 'src/components'
import type { SetStateAction } from 'react'

export const DropDownActions = ({
    active,
    onDropDownClick,
    setActiveSort,
    activeSort,
    setIsDropdownActive,
}: {
    active: Boolean
    onDropDownClick: (value: string) => void
    setActiveSort: (active: 'due_date' | 'created_at' | 'name') => void
    activeSort: string
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
}) => {
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="36px"
        >
            <>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('created_at')
                        onDropDownClick(x)
                        setIsDropdownActive(false)
                    }}
                    value="Date Created"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'created_at' ? '0.5' : '1'}
                    >
                        Date Created
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('name')
                        onDropDownClick(x)
                        setIsDropdownActive(false)
                    }}
                    value="Name A-Z"
                    width="100%"
                >
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'name' ? '0.5' : '1'}
                    >
                        Name A-Z
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    onClick={(x) => {
                        setActiveSort('due_date')
                        onDropDownClick(x)
                        setIsDropdownActive(false)
                    }}
                    value="Due Date"
                    width="100%"
                >
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        opacity={activeSort === 'due_date' ? '0.5' : '1'}
                    >
                        Due Date
                    </Button>
                </DropdownButtonWrapper>
            </>
        </Dropdown>
    )
}
