import * as React from 'react'
import { Box, Button, Input, Text } from 'src/components/utility'
import { layers, useConfiguratorContext } from '../../configurator.context'
import type { MeshCustomMaterial } from '../material/custom-material'
import * as THREE from 'three'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import './color.styles.css'
import { checkColorFormat } from 'src/components/color-picker/color-picker.helper'
import ColorPanel from './color-panel'
import { StrokedArrow, SuccessTickSm } from 'src/components/svg-icons'

import DiscardDropdown from './dropdown'
import { useAnalyseColors } from '../../hooks/use-analyse-colors'
import { captureImage } from '../../helper/create-material'
import { DivisionLine } from 'src/components/division-line/division-line'

export interface IAdvancedProps {}

export default function ColorAdvanced(props: IAdvancedProps) {
    const { highlights, colorLayers, setColorLayers } = useConfiguratorContext()

    const mesh = highlights[0].mesh as THREE.Mesh
    const material = mesh.material as MeshCustomMaterial

    const { analyseColors, detectedColors } = useAnalyseColors()

    // React.useEffect(() => {
    //     if (mesh) {
    //         // material.desaturateBase = 1
    //         // material.cutoutColor1_Base = 1
    //         // material.cutoutColor2_Base = 1
    //         // material.diffuseMultiplierBase = 1
    //         // material.colorizeLowlightsBase = 0.1
    //         // console.log({ material })
    //     }
    // }, [mesh])

    function onColorUpdate(
        value: string,
        operation: 'destination_color' | 'color',
        colorId: 1 | 2 | 3
    ) {
        const material = highlights[0].mesh.material as MeshCustomMaterial
        material.emphasizeSrcLumSat1_Base = 1
        material.srcBWHue1_Base = 0.1
        material.emphasizeSrcLumSat2_Layer1 = 0
        material.srcBWHue2_Layer1 = 0.1
        material.emphasizeSrcLumSat3_Layer1 = 0
        material.srcBWHue3_Layer1 = 0.1
        console.log({ value })
        if (operation === 'destination_color') {
            // material.srcColor1_Layer1 = new THREE.Color(value)
            material[`srcColor1_Base`] = new THREE.Color(value)
        } else if (operation === 'color') {
            // material[`destColor${colorId}_Layer1`] = new THREE.Color(value)
            material[`destColor1_Base`] = new THREE.Color(value)
        }
    }

    React.useEffect(() => {
        ;(async () => {
            if (highlights[0] && highlights[0].isActive) {
                if (!colorLayers[mesh.id]) {
                    setColorLayers({ ...colorLayers, [mesh.id]: [layers[1]] })
                }
            }
            await analyseColors(captureImage(highlights[0].mesh, 'map'), 15)
        })()
    }, [highlights])

    function handlePickerChange(id: string, isActive: boolean) {
        if (!colorLayers[mesh.id]) return
        const meshLayers = colorLayers[mesh.id]
        setColorLayers({
            ...colorLayers,
            [mesh.id]: meshLayers.map((layer) => {
                if (layer.id === id) {
                    return {
                        ...layer,
                        isSourceActive: isActive,
                    }
                }
                return layer
            }),
        })
    }

    function updateAccordionIsActive(id: string, isActive: boolean) {
        if (!colorLayers[mesh.id]) return
        const meshLayers = colorLayers[mesh.id]
        setColorLayers({
            ...colorLayers,
            [mesh.id]: meshLayers.map((layer) => {
                if (layer.id === id) {
                    return {
                        ...layer,
                        isActive: isActive,
                    }
                }
                return {
                    ...layer,
                    isActive: false,
                }
            }),
        })
    }

    function handleResetDefault() {
        if (!colorLayers[mesh.id]) return
        const meshLayers = colorLayers[mesh.id]
        if (highlights[0].isActive && meshLayers.length >= 1) {
            meshLayers.forEach((layer) => {
                material[layer.destination].setHex(16711680)
                material[layer.source].setHex(
                    new THREE.Color('#ffffff00').getHex()
                )
                material[layer.emphasis] = 1
                material[layer.sensitivity] = 0.055
            })
            setColorLayers({ ...colorLayers, [mesh.id]: [layers[1]] })
        }
    }
    function handleOnColorChange({
        color,
        colorLayer,
        id,
        layerName,
        config,
        changeTabs = false,
    }: {
        color: string
        colorLayer: TSourceColors | TDestinationColors
        config: {
            emphasis: TEmphasis
            emphasisValue: number
            sensitivity: TSensitivity
            sensitivityValue: number
        }
        layerName:
            | 'sourceColor'
            | 'destinationColor'
            | 'emphasis'
            | 'sensitivity'
        id: string
        changeTabs?: boolean
    }) {
        if (!colorLayers[mesh.id]) return
        const meshLayers = colorLayers[mesh.id]
        setColorLayers({
            ...colorLayers,
            [mesh.id]: meshLayers.map((layer) => {
                if (layer.id === id) {
                    return {
                        ...layer,
                        [layerName]: color,
                        isSourceActive: changeTabs
                            ? false
                            : layer.isSourceActive,
                    }
                }
                return layer
            }),
        })

        // @ts-ignore
        if (config) {
            material[config.emphasis] = config.emphasisValue
            material[config.sensitivity] = config.sensitivityValue
        }

        switch (layerName) {
            case 'destinationColor':
            case 'sourceColor':
                material[colorLayer] = new THREE.Color(color)
                break
            default:
                break
        }

        return
    }

    function onSensitivityOrEmphasisChange(
        layerId: string,
        layerSensitivityName: TSensitivity | TEmphasis,
        sensitvity: 'sensitivityValue' | 'emphasisValue',
        value: number
    ) {
        console.log({ sensitvity, value, layerId })
        setColorLayers({
            ...colorLayers,
            [mesh.id]: colorLayers[mesh.id].map((layer) => {
                if (String(layer.id) === String(layerId)) {
                    return {
                        ...layer,
                        [sensitvity]: value,
                    }
                }
                return layer
            }),
        })
        material[layerSensitivityName] = value
    }
    return (
        <Box>
            <Box
                px="10px"
                py="4px"
                pb="6px"
                gridColumnGap="8px"
                justifyContent="space-between"
                width="100%"
                display="flex"
            >
                <Button
                    onClick={() => handleResetDefault()}
                    color="links"
                    border="none"
                    bg="transparent"
                    fontSize="12px"
                >
                    Reset to default
                </Button>
                <Button
                    onClick={() => {
                        if (
                            colorLayers[mesh.id] &&
                            colorLayers[mesh.id].length < 6
                        ) {
                            setColorLayers({
                                ...colorLayers,
                                [mesh.id]: [
                                    ...colorLayers[mesh.id].map((layer) => ({
                                        ...layer,
                                        isActive: false,
                                    })),
                                    {
                                        ...layers[
                                            colorLayers[mesh.id].length + 1
                                        ],
                                        isActive: true,
                                    },
                                ],
                            })
                        }
                    }}
                    color="links"
                    border="none"
                    bg="transparent"
                    fontSize="12px"
                >
                    + Another Color
                </Button>
            </Box>

            {colorLayers[mesh.id] &&
                colorLayers[mesh.id].map((colorLayer, key) => {
                    return (
                        <Accordion
                            style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                boxShadow: 'none',
                                // padding: '24px 0px',
                            }}
                            expanded={colorLayer.isActive}
                            onChange={() =>
                                updateAccordionIsActive(
                                    colorLayer.id,
                                    !colorLayer.isActive
                                )
                            }
                        >
                            <AccordionSummary
                                style={{
                                    // borderTop: 'none',
                                    borderBottom: 'solid',
                                    borderColor: '#E8ECEF',
                                    borderWidth: '1px',
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Text fontSize="12px">
                                        Color Layer {key + 1}
                                    </Text>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        gridColumnGap="14px"
                                    >
                                        <Box
                                            className={
                                                colorLayer.isActive
                                                    ? 'rotate-upwards-90-model'
                                                    : 'vertical'
                                            }
                                        >
                                            <StrokedArrow />
                                        </Box>
                                        <DiscardDropdown
                                            id={String(colorLayer.id)}
                                            destination={colorLayer.destination}
                                            source={colorLayer.source}
                                            emphasis={colorLayer.emphasis}
                                            sensitivity={colorLayer.sensitivity}
                                        />
                                    </Box>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box display="flex" gridColumnGap="8px">
                                    <Picker
                                        bg={colorLayer.sourceColor}
                                        isActive={colorLayer.isSourceActive}
                                        onClick={() =>
                                            handlePickerChange(
                                                colorLayer.id,
                                                true
                                            )
                                        }
                                        title="Pick Color from mesh to override"
                                        subText={colorLayer.sourceColor}
                                    />
                                    <Picker
                                        bg={colorLayer.destinationColor}
                                        isActive={!colorLayer.isSourceActive}
                                        onClick={() =>
                                            handlePickerChange(
                                                colorLayer.id,
                                                false
                                            )
                                        }
                                        title="Override color to"
                                        subText={colorLayer.destinationColor}
                                    />
                                </Box>
                                <Text my="0px" mt="8px" fontSize="12px">
                                    Artwork Colors
                                </Text>
                                <Box
                                    mt="8px"
                                    display="grid"
                                    gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                    flexWrap="wrap"
                                    gridColumnGap="8px"
                                    gridRowGap="8px"
                                    justifyContent="center"
                                >
                                    {detectedColors.map((detectedColor) => {
                                        return (
                                            <Box
                                                height="48px"
                                                width="48px"
                                                borderRadius="4px"
                                                border="solid"
                                                borderWidth={1}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderColor={
                                                    colorLayer?.destinationColor?.toLowerCase() ===
                                                    detectedColor.color.toLowerCase()
                                                        ? 'primary'
                                                        : 'white'
                                                }
                                            >
                                                <Box
                                                    bg={detectedColor.color}
                                                    borderRadius="4px"
                                                    width="100%"
                                                    height="100%"
                                                    border="solid"
                                                    borderWidth={1}
                                                    borderColor="white"
                                                    onClick={() => {
                                                        handleOnColorChange({
                                                            color: detectedColor.color,
                                                            colorLayer:
                                                                colorLayer.source,
                                                            config: {
                                                                emphasis:
                                                                    colorLayer.emphasis,

                                                                emphasisValue:
                                                                    colorLayer.emphasisValue ||
                                                                    0.1,
                                                                sensitivityValue:
                                                                    colorLayer.sensitivityValue ||
                                                                    0.1,
                                                                sensitivity:
                                                                    colorLayer.sensitivity,
                                                            },
                                                            id: colorLayer.id,
                                                            layerName:
                                                                'sourceColor',
                                                            changeTabs: true,
                                                        })
                                                    }}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    {colorLayer?.destinationColor?.toLowerCase() ===
                                                        detectedColor.color.toLowerCase() && (
                                                        <SuccessTickSm color="#C2CFE0" />
                                                    )}
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box my="8px">
                                    {/* <Shades
                                        onClick={(color) =>
                                            handleOnColorChange({
                                                color: color,
                                                colorLayer:
                                                    colorLayer.isSourceActive
                                                        ? colorLayer.source
                                                        : colorLayer.destination,
                                                config: {
                                                    emphasis:
                                                        colorLayer.emphasis,
                                                    sensitivity:
                                                        colorLayer.sensitivity,
                                                },
                                                id: colorLayer.id,
                                                layerName:
                                                    colorLayer.isSourceActive
                                                        ? 'sourceColor'
                                                        : 'destinationColor',
                                            })
                                        }
                                        color={
                                            colorLayer.isSourceActive
                                                ? colorLayer.sourceColor
                                                : colorLayer.destinationColor
                                        }
                                    /> */}
                                </Box>
                                <Box>
                                    <ColorPanel
                                        border="none"
                                        p="0px"
                                        // key={selectedColor}
                                        initialColor={'#fff'}
                                        onColorChange={(hex) => {
                                            const hexLength = hex.replace(
                                                '#',
                                                '0'
                                            ).length
                                            const isColor =
                                                checkColorFormat(hex)
                                            let color = hex
                                            if (hexLength === 3)
                                                color = `#${hex
                                                    .replace('#', '')
                                                    .repeat(2)}`
                                            if (
                                                isColor &&
                                                (hexLength === 3 ||
                                                    hexLength === 6 ||
                                                    hexLength === 7)
                                            ) {
                                                handleOnColorChange({
                                                    color: hex,
                                                    colorLayer:
                                                        colorLayer.isSourceActive
                                                            ? colorLayer.source
                                                            : colorLayer.destination,
                                                    config: {
                                                        emphasis:
                                                            colorLayer.emphasis,
                                                        emphasisValue:
                                                            colorLayer.emphasisValue ||
                                                            0.1,
                                                        sensitivity:
                                                            colorLayer.sensitivity,
                                                        sensitivityValue:
                                                            colorLayer.sensitivityValue ||
                                                            0.1,
                                                    },
                                                    id: colorLayer.id,
                                                    layerName:
                                                        colorLayer.isSourceActive
                                                            ? 'sourceColor'
                                                            : 'destinationColor',
                                                })
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Text fontSize="12px">
                                        {colorLayer.isSourceActive
                                            ? 'Picker Sensitivity %'
                                            : 'Color Sensitivity %'}
                                    </Text>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Input
                                            type="number"
                                            value={
                                                colorLayer.isSourceActive
                                                    ? colorLayer.sensitivityValue
                                                    : colorLayer.emphasisValue
                                            }
                                            bg="transparent"
                                            onChange={(e) => {
                                                onSensitivityOrEmphasisChange(
                                                    colorLayer.id,
                                                    colorLayer.isSourceActive
                                                        ? colorLayer.sensitivity
                                                        : colorLayer.emphasis,
                                                    colorLayer.isSourceActive
                                                        ? 'sensitivityValue'
                                                        : 'emphasisValue',
                                                    Number(e.target.value)
                                                )
                                            }}
                                            border="solid"
                                            borderWidth={1}
                                            borderColor="secondaryLighterBlue"
                                            borderRadius="4px"
                                            width="71px"
                                            height="32px"
                                            step={0.001}
                                            min={0.1}
                                            max={10}
                                        />
                                        <Input
                                            mt="12px"
                                            className="intensity-slider"
                                            width="60%"
                                            value={
                                                colorLayer.isSourceActive
                                                    ? colorLayer.sensitivityValue
                                                    : colorLayer.emphasisValue
                                            }
                                            onChange={(e) => {
                                                onSensitivityOrEmphasisChange(
                                                    colorLayer.id,
                                                    colorLayer.isSourceActive
                                                        ? colorLayer.sensitivity
                                                        : colorLayer.emphasis,
                                                    colorLayer.isSourceActive
                                                        ? 'sensitivityValue'
                                                        : 'emphasisValue',
                                                    Number(e.target.value)
                                                )
                                            }}
                                            type="range"
                                            step={0.001}
                                            min={0.1}
                                            max={10}
                                            bg="trasparent"
                                        ></Input>
                                    </Box>
                                </Box>
                                <Box mt="12px">
                                    <DivisionLine color="gray250" />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
        </Box>
    )
}

function Picker({
    title,
    onClick,
    subText = '--',
    isActive,
    bg,
}: {
    title: string
    onClick: () => void
    subText?: string | null
    isActive: boolean
    bg: string | null
}) {
    return (
        <Box
            onClick={onClick}
            className="cursor-pointer"
            borderBottom="solid"
            borderColor={isActive ? 'primary' : 'gray250'}
            borderWidth={1}
            display="flex"
            flexDirection="column"
            gridRowGap="6px"
        >
            <Text
                width="80%"
                fontSize="12px"
                color={isActive ? 'primary' : 'gray250'}
            >
                {title}
            </Text>
            <Box
                width="48px"
                height="48px"
                bg={bg || 'transparent'}
                border={'solid'}
                borderColor={isActive ? 'primary' : 'gray250'}
                borderWidth={1}
                borderRadius="4px"
            ></Box>
            <Text fontSize="10px" color={isActive ? 'primary' : 'gray250'}>
                {subText || '--'}
            </Text>
        </Box>
    )
}
