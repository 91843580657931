import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Box } from 'src/components/utility'
import { DropDownActions } from './dropdown-actions'

const groups: Record<string, string> = {
    collections: 'Collections',
    products: 'Products',
    todos: 'Todos',
}

export const GroupByDropdown = ({
    disableCollection = false,
    resetPageOnChange = true,
}: {
    disableCollection?: boolean
    resetPageOnChange?: boolean
}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const activeGroup =
        searchParams.get('group') ||
        (disableCollection ? 'products' : 'collections')
    const [dropdownButtonText, setDropdownButtonText] = useState<string>(
        groups[activeGroup]
    )
    const dropdownButton = useRef<any>(null)

    function disableDropdown(event: Event) {
        if (dropdownButton !== null && dropdownButton.current !== null) {
            const isClickInsideElement = dropdownButton.current.contains(
                event.target
            )
            if (!isClickInsideElement) {
                setIsDropDownActive(false)
            }
        }
    }

    const setActiveGroup = (group: 'collections' | 'products' | 'todos') => {
        searchParams.set('group', group)
        searchParams.set('tab', 'all')
        if (resetPageOnChange) searchParams.set('page', String(1))
        setSearchParams(searchParams, { replace: true })
        setIsDropDownActive(false)
    }

    function onDropDownClick(value: string) {
        setDropdownButtonText(value)
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    return (
        <Box
            id="collection-groupby-dropdown"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            <Box
                ref={dropdownButton}
                onClick={() => setIsDropDownActive(!isDropDownActive)}
            >
                <IconDropdownButton variant="secondary">
                    <span style={{ color: 'gray' }}>Group by:</span>{' '}
                    {dropdownButtonText}
                </IconDropdownButton>
            </Box>

            {isDropDownActive && (
                <DropDownActions
                    setActiveGroup={setActiveGroup}
                    setIsDropdownActive={setIsDropDownActive}
                    onDropDownClick={onDropDownClick}
                    activeGroup={activeGroup}
                    active={isDropDownActive}
                    disableCollection={disableCollection}
                />
            )}
        </Box>
    )
}
