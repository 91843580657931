export const firebaseConfig = {
    apiKey: 'AIzaSyBuntxv1fnNuCldzNsPLEQ_FCYYOK6N6PA',
    authDomain: 'virtu-staging-822e3.firebaseapp.com',
    projectId: 'virtu-staging-822e3',
    storageBucket: 'virtu-staging-822e3.appspot.com',
    messagingSenderId: '706173673138',
    appId: '1:706173673138:web:481802f56bfa8f2eddb3ab',
    vapidKey:
        'BMOYhhawpXuZBc32KVzANEdOBvybzsi5pdIacCmMyAUyUohnw384aIDo4gmsTWHuO7M4eezyNILPHC7nCTWGH5I',
}
