import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'
import { getImageUrl } from 'src/helpers/get-image-url'
import { MentionTeamIco } from 'src/components/svg-icons/mention/mention-team'
import { Box, Button, Text } from 'src/components/utility'
import { ProfileImg } from 'src/components/profile-img/profile-img'

export const MentionList = forwardRef((props: any, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = (index: number) => {
        const item = props.items[index]
        if (item) {
            props.command({
                name: item.user.name,
                entity_id: item.user.id,
                entity_name: item.user.name === 'Team' ? 'teams' : 'users',
            })
        }
    }

    const upHandler = () => {
        setSelectedIndex(
            (selectedIndex + props.items.length - 1) % props.items.length
        )
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: any }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))

    return (
        <Box
            py="16px"
            gridRowGap="12px"
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            bg="white"
            display="flex"
            flexDirection="column"
            id="mentions-wrapper"
            maxHeight={'400px'}
            overflow={'auto'}
        >
            {props.items.length ? (
                props.items.map((item: any, index: number) => (
                    <Button
                        px="16px"
                        fontFamily="Rubik"
                        fontSize="14px"
                        color="primary"
                        display="flex"
                        alignItems="center"
                        gridColumnGap="8px"
                        border="none"
                        py="4px"
                        bg={`${index === selectedIndex ? '#E8ECEF' : 'white'}`}
                        key={index}
                        onClick={() => selectItem(index)}
                        id="mention-user-or-team"
                    >
                        {item.user.id === 0 ? (
                            <Box
                                height={'24px'}
                                display="flex"
                                alignItems="center"
                            >
                                <MentionTeamIco />
                            </Box>
                        ) : (
                            <ProfileImg
                                size="24px"
                                name={item.user.name}
                                src={
                                    item.user.other_data &&
                                    item.user.other_data.profile_pic
                                        ? getImageUrl(
                                              item.user.other_data.profile_pic
                                          )
                                        : ''
                                }
                                id="mention-user-or-team-profile-img"
                            />
                        )}

                        <Text
                            my="0px"
                            fontFamily="Rubik"
                            fontSize="14px"
                            id="mention-user-or-team-name"
                        >
                            {item.user.name}
                        </Text>
                    </Button>
                ))
            ) : (
                <Box
                    px="16px"
                    fontFamily="Rubik"
                    fontSize="14px"
                    color="primary"
                >
                    No result
                </Box>
            )}
        </Box>
    )
})
