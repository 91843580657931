import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    Checkbox,
    Dropdown,
    DropdownButtonWrapper,
    FileDisplay,
} from 'src/components'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Input } from 'src/components/utility'
import { copyToClipBoard, roles, useUser } from 'src/helpers'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import {
    createRedirectionUrl,
    isFile3D,
    isNewTabOn,
} from 'src/helpers/create-redirection-url'

export const FileCard = ({
    data,
    deleteFileModal,
    renameFileModal,
    runFavMutation,
    runDeleteFavMutation,
    isFavorite = false,
    isSelectable = false,
    runSelection,
    typeCheckbox = false,
    width = '174px',
    height = '135px',
    isFileChecked = false,
    librarySelect = false,
    callback = '/',
    highlightFile = false,
}: {
    data: FileObject
    deleteFileModal?: (fileId: number) => void
    renameFileModal?: ({
        id,
        name,
    }: {
        id: number | null
        name: string
    }) => void
    runFavMutation?: (folderId: number | null, fileId: number | null) => void
    runDeleteFavMutation?: (id: number) => void
    isFavorite?: boolean
    isSelectable?: boolean
    typeCheckbox?: boolean
    isFileChecked?: boolean
    librarySelect?: boolean
    runSelection?: (id: string | null, checked: boolean | null) => void
    width?: string
    height?: string
    callback?: string
    highlightFile?: boolean
}) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    let favId = data.favourite_id
    if (data.library_favourites && data.library_favourites[0]) {
        favId = data.library_favourites[0].id
    }

    const _isFavorite = favId ? true : isFavorite
    const { role } = useUser()
    const [isDropDown, setIsDropdown] = useState(false)
    const [fileSelected, setIsFileSelected] = useState(isFileChecked)
    const dropDownRef = useRef<any>(null)
    const _location = useLocation()
    const dropItems =
        roles.designer === role
            ? [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'Download File', id: 'DOWNLOAD_FILE' },
                  { name: 'Rename File', id: 'RENAME_FILE' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
              ]
            : [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'Download File', id: 'DOWNLOAD_FILE' },
                  { name: 'Rename File', id: 'RENAME_FILE' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  { name: 'Edit Details', id: 'EDIT_DETAILS' },
                  // { name: "Archive Folder", id: "ARCHIVED_FOLDER" },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
                  { name: 'Delete File', id: 'DELETE_FILE' },
              ]

    const openInNewTabToDownloadFile = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    useEffect(() => {
        if (fileSelected !== isFileChecked) {
            setIsFileSelected(isFileChecked)
        }
    }, [isFileChecked])

    const handleDropItems = (item: any) => {
        if (!item.id) {
            return
        }

        switch (item.id) {
            case 'VIEW_DETAILS':
                if (searchParams.has(libraryDefaultKeys.showFolderDetails)) {
                    searchParams.delete(libraryDefaultKeys.showFolderDetails)
                }
                searchParams.set(
                    libraryDefaultKeys.showFileDetails,
                    data.id.toString()
                )
                setSearchParams(searchParams)
                const ele: HTMLElement | null =
                    window.document.getElementById('layoutContainer2')
                ele?.scrollTo(0, 0)
                break

            case 'COPY_LINK':
                const url = new URL(window.location.href)
                url.searchParams.set('fileId', String(data.id))
                copyToClipBoard(url.href)
                toast.success('Link copied to clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break

            case 'DELETE_FILE':
                deleteFileModal && deleteFileModal(data.id)
                break

            case 'EDIT_DETAILS':
                navigate('/library/editfile/' + data.id)
                break

            case 'MARK_AS_FAV':
                runFavMutation && runFavMutation(null, data.id)
                break

            case 'DOWNLOAD_FILE':
                const _url = getLibraryFileUrl(data.gid || data.path)
                openInNewTabToDownloadFile(_url)
                break
            case 'RENAME_FILE':
                renameFileModal &&
                    renameFileModal({ id: data.id, name: data.name })
                break
            case 'UN_MARK_FROM_FAV':
                favId && runDeleteFavMutation && runDeleteFavMutation(favId)
                break

            default:
                break
        }
    }

    const getThumbnail = () => {
        if (data?.other_data && data?.other_data?.entity_name) {
            return getImageUrl(data.thumbnail || data.gid || data.url)
        }
        return getLibraryFileUrl(data.thumbnail || data.gid || data.url)
    }

    const openInNewTab = (url: string) => {
        searchParams.delete('fileId')
        searchParams.delete('folderId')
        setSearchParams(searchParams)
        const paramsList = [
            {
                key: 'url',
                value: encodeURIComponent(url),
            },
            {
                key: 'name',
                value: encodeURIComponent(data.name),
            },
        ]

        const params = isNewTabOn({ name: data.path })
            ? [
                  { key: 'ref', value: 'library' },
                  { key: 'gid', value: data.gid },
              ]
            : []

        let paramsString = [...paramsList, ...params]
            .map(({ key, value }, index) => {
                if (index === 0) {
                    return `?${key}=${value}`
                } else {
                    return `&${key}=${value}`
                }
            })
            .join('')
        let _url = `${createRedirectionUrl({
            path: data.path,
            gid: data.gid,
            ref: 'library',
        })}${paramsString}`

        if (data.id) {
            _url += `&fileId=${data.id}&${libraryDefaultKeys.showFileDetails}=${data.id}`
        }

        if (isFile3D(data.path.split('.')[data.path.split('.').length - 1])) {
            _url += `&newTab=1`
            window.open(window.location.origin + `${_url}`, '_blank')
        } else {
            _url += `&newTab=0`
            navigate(_url, {
                state: {
                    from: _location,
                },
            })
            if (
                !data.name.includes('.png') &&
                !data.name.includes('.jpeg') &&
                !data.name.includes('.jpg') &&
                !data.name.includes('.xlx') &&
                !data.name.includes('.xlsx') &&
                !data.name.includes('.pdf')
            ) {
                window.location.reload()
            }
        }
    }

    if (searchParams.get('fileId') === String(data?.id)) {
        console.log('fileId', data.id)
        const ele: HTMLElement | null = window.document.getElementById(
            'file_' + data.id
        )
        ele?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
    }
    return (
        <>
            <Box
                onMouseLeave={() => setIsDropdown(false)}
                position="relative"
                id={`file_${data.id}`}
                className="fileCard"
            >
                {isSelectable && !typeCheckbox && (
                    <Box position="absolute" top="8px" left="8px" zIndex={2}>
                        <Input
                            autoComplete="false"
                            type={'radio'}
                            bg="transparent"
                            className="pointer"
                            id={'radio' + data.id}
                            value={data.id}
                            onChange={(e) => {
                                runSelection &&
                                    runSelection(
                                        e.target.value,
                                        e.target.checked
                                    )
                            }}
                            name="radio1"
                        ></Input>
                    </Box>
                )}

                {isSelectable && typeCheckbox && (
                    <Box position="absolute" top="8px" left="8px" zIndex={2}>
                        <Checkbox
                            checkBoxId={data.id.toString()}
                            defautlCheck={fileSelected}
                            onChange={(x: boolean) => {
                                runSelection &&
                                    runSelection(data.id.toString(), x)
                                setIsFileSelected(x)
                            }}
                            label=""
                        />
                    </Box>
                )}

                {/* {searchParams.get('fileId') && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        className={
                            searchParams.get('fileId') === String(data?.id)
                                ? 'card-bg'
                                : ''
                        }
                        zIndex={1}
                        width="100%"
                        height="100%"
                    ></Box>
                )} */}

                {!isSelectable && !librarySelect && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top="0px"
                        left="0px"
                        right="0px"
                        bottom="0px"
                        bg={'rgba(2,122,196,0.1)'}
                        zIndex={1}
                        id="hover-file-2"
                        className="folderDropItem cursor-pointer"
                        onClick={() =>
                            openInNewTab(getLibraryFileUrl(data.gid))
                        }
                    ></Box>
                )}
                {!isSelectable ? (
                    <FileDisplay
                        redirectionUrl={createRedirectionUrl({
                            path: data.path,
                            gid: data.gid,
                            ref: 'library',
                        })}
                        width={width}
                        height={height}
                        trayColor={
                            searchParams.get('fileId') === String(data?.id)
                                ? '#e9eef2'
                                : '#fff'
                        }
                        url={getLibraryFileUrl(data.gid)}
                        key={data.name + 'file2' + data.id}
                        name={data.name}
                        selected={fileSelected}
                        librarySelect={librarySelect}
                        fileId={data.id}
                        thumbnail={getThumbnail()}
                        openFileInNewTab={isFile3D(
                            data.path.split('.')[
                                data.path.split('.').length - 1
                            ]
                        )}
                        params={
                            isNewTabOn({ name: data.path })
                                ? [
                                      { key: 'ref', value: 'library' },
                                      { key: 'gid', value: data.gid },
                                  ]
                                : undefined
                        }
                        callbackUrl={callback}
                        isUrlRequired={isNewTabOn({ name: data.path })}
                    />
                ) : (
                    <label
                        style={{ display: 'block' }}
                        className={isSelectable ? 'pointer' : ''}
                        htmlFor={'radio-' + data.id}
                    >
                        <FileDisplay
                            redirectionUrl={createRedirectionUrl({
                                path: data.path,
                                gid: data.gid,
                                ref: 'library',
                            })}
                            width={width}
                            height={height}
                            url={getLibraryFileUrl(data.gid)}
                            key={data.name + 'file2' + data.id}
                            name={data.name}
                            selected={fileSelected}
                            librarySelect={librarySelect}
                            trayColor={
                                searchParams.get('fileId') === String(data?.id)
                                    ? '#e9eef2'
                                    : '#fff'
                            }
                            fileId={data.id}
                            params={
                                isNewTabOn({ name: data.path })
                                    ? [
                                          { key: 'ref', value: 'library' },
                                          { key: 'gid', value: data.gid },
                                      ]
                                    : undefined
                            }
                            openFileInNewTab={isFile3D(
                                data.path.split('.')[
                                    data.path.split('.').length - 1
                                ]
                            )}
                            isUrlRequired={isNewTabOn({ name: data.path })}
                            thumbnail={getThumbnail()}
                            callbackUrl={callback}
                        />
                    </label>
                )}

                {!isSelectable && !librarySelect && (
                    <Box
                        position="absolute"
                        top="8px"
                        zIndex={4}
                        right="8px"
                        className="folderDropItem"
                    >
                        <Box position="relative">
                            <Button
                                onClick={() => setIsDropdown(!isDropDown)}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border="none"
                                py="8px"
                                borderRadius="4px"
                                px="8px"
                                id="folderDetailsIconId"
                                bg={'gray250'}
                            >
                                <Dots active={isDropDown} dark={true} />
                            </Button>
                            {isDropDown && (
                                <Dropdown
                                    ref={dropDownRef}
                                    top="42px"
                                    active={isDropDown}
                                    right="0px"
                                    left="unset"
                                >
                                    <>
                                        {dropItems.map((item: any) => {
                                            return (
                                                <DropdownButtonWrapper
                                                    onClick={() => {
                                                        setIsDropdown(false)
                                                        handleDropItems(item)
                                                    }}
                                                    hover={true}
                                                    width="100%"
                                                    key={item.id}
                                                >
                                                    <Button
                                                        color={
                                                            item.id ===
                                                            'DELETE_FILE'
                                                                ? 'delay'
                                                                : 'primary'
                                                        }
                                                        fontFamily="Rubik"
                                                        border="none"
                                                        bg="transparent"
                                                    >
                                                        {item.name}
                                                    </Button>
                                                </DropdownButtonWrapper>
                                            )
                                        })}
                                    </>
                                </Dropdown>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}
