import { Divider } from '@mui/material'
import * as React from 'react'
import { Slider } from 'src/components/slider/slider'
import { CollectionIco, Dots } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import TeamTabSlider from './team-tab-slider'
// import { TabPanel } from 'src/components/tab-panel/tab-panel'
import CollectionTabData from './tab-data/collection-tab-data'
import ProductTabData from './tab-data/product-tab-data'
import TeamTabData from './tab-data/team-tab-data'
import {
    useDeleteTeam,
    useFetchAnalytics,
    useFetchCollaboratorByTeamId,
    useFetchEntityCount,
    useGetCollectionsByTeamId,
    useGetProductsByTeamId,
} from 'src/screens-v2/organisations-analytics/hooks'
import TeamDropdown from 'src/screens-v2/organisations-analytics/tab-data/dropdown/team-dropdown'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getTeamsData } from 'src/screens-v2/organisations-analytics/function'
import { toast } from 'react-toastify'
import ProgressBar from 'src/components-v2/progress-bar/progress-bar'
import { TeamIcoSm } from 'src/components/svg-icons/team-ico'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { AddCollaboratorModal } from 'src/screens-v2/organisations-analytics/modal/add-collaborator/add-collaborator'
import RenameTeamModal from 'src/screens-v2/organisations-analytics/modal/rename-team'
import { DangerModal } from 'src/components/modals/danger-modal'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import type { statuses } from 'src/helpers'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useOrganizationContext } from 'src/screens-v2/organisations-analytics/organisation.context'

interface ITeamTabProps {
    tabView: boolean
}

const TeamTab: React.FunctionComponent<ITeamTabProps> = (props) => {
    const { tabView = true } = props
    const {
        drawerTabs,
        currentTabId,
        setShowDrawerDock,
        showDrawerDock,
        clearDrawerTabs,
    } = useStore()

    const [searchParams, setSearchParam] = useSearchParams()
    const [currentTab, setCurrentTab] = React.useState<{
        tab: 'collection' | 'products' | 'collaborators'
        index: number
    }>({
        tab:
            (searchParams.get('drawerTab') as
                | 'collection'
                | 'products'
                | 'collaborators') || 'collection',
        index: 0,
    })

    const [teamDropdown, setTeamDropdown] = React.useState(false)

    const [activeSort, setActiveSort] = React.useState<{
        status: (typeof statuses)[keyof typeof statuses] | ''
        sort: 'name' | 'due_date'
    }>({
        status: 'inProgress',
        sort: 'name',
    })
    const [isCollaboratorModalOpen, setCollaboratorModalOpen] =
        React.useState(false)
    // const [activeTab, setActiveTab] = React.useState<ITeams | undefined>(
    //     undefined
    // )
    const [members, setMembers] = React.useState<ITeamMembers[]>([])
    const [isRenameTeamActive, setIsRenameTeamActive] = React.useState(false)

    const { id } = useParams()

    const teamId = id || searchParams.get('id') || '0'
    const { handleTeamDelete } = useDeleteTeam()
    const { fetchTeammembers, membersById } = useFetchCollaboratorByTeamId()
    const { fetchCollectionsByTeamId, collectionsByTeamId } =
        useGetCollectionsByTeamId()
    const { getEntityCount } = useFetchEntityCount()
    const { fetchProductsByTeamId, productsByTeamId } = useGetProductsByTeamId()
    const { fetchAnalytics, analyticsOfTeams } = useFetchAnalytics('team')
    const [deleteTeamModal, setDeleteTeamModal] = React.useState(false)
    const { team, setTeam } = useOrganizationContext()
    const dropdownRef = useClickedOutside(() => setTeamDropdown(false))
    const navigate = useNavigate()

    React.useEffect(() => {
        if (teamId) {
            fetchAnalytics(teamId)
            fetchProductsByTeamId(teamId)
            fetchCollectionsByTeamId(teamId)
        }
    }, [teamId])

    React.useEffect(() => {
        setMembers(membersById)
    }, [membersById])

    React.useEffect(() => {
        if (analyticsOfTeams.id) {
            fetchTeammembers(String(analyticsOfTeams.id))
        }
    }, [analyticsOfTeams.id])

    async function dropdownClickHandler(value: string) {
        switch (value) {
            case 'rename_team':
                setIsRenameTeamActive(true)
                setTeamDropdown(false)
                break
            case 'edit_team':
                setCollaboratorModalOpen(true)
                setTeamDropdown(false)
                break
            case 'delete_team':
                setDeleteTeamModal(true)
                setTeamDropdown(false)
                break
        }
    }

    async function deleteTeam() {
        try {
            await handleTeamDelete(String(analyticsOfTeams.id))
            searchParams.set('page', searchParams.get('page') || '1')
            setSearchParam(searchParams)
            clearDrawerTabs()
            const data = await getTeamsData({
                searchParam: searchParams.get('q') || '',
                currentPage: Number(searchParams.get('page')) || 1,
                setisLoading: () => {},
                sortParam: searchParams.get('sort') || 'name',
            })
            if (data?.data) {
                drawerTabs[0]?.callback && drawerTabs[0].callback(data.data)
            }
            getEntityCount('teams')
            toast('Team Deleted Successfully', {
                className: 'custom-toaster toaster-success',
            })
            navigate('/organizations?tab=teams&sort=LTO')
            if (data) {
                searchParams.set('deleted', 'true')
                setSearchParam(searchParams)
                searchParams.delete('deleted')
                setSearchParam(searchParams)
                navigate('/organizations?tab=teams')
            }
        } catch (e) {
            console.log(e)
        }
        setTeamDropdown(false)
    }

    const completed =
        ((analyticsOfTeams?.totalCompletedCollectionsOnTime || 0) /
            analyticsOfTeams?.totalCompletedCollections || 0) * 100
    const delayed =
        (analyticsOfTeams.totalCompletedCollectionsDelayed ||
            0 / analyticsOfTeams.totalCompletedCollections ||
            0) * 100

    const overAllProgress =
        (analyticsOfTeams.totalCompletedCollectionsOnTime /
            analyticsOfTeams.totalCompletedCollections) *
        100

    function navigateToDetails() {
        setShowDrawerDock(false)
        searchParams.set('dock', 'true')
        setSearchParam(searchParams)
        clearDrawerTabs()
        navigate('/team/' + analyticsOfTeams.id)
    }

    function handleDropdownClick(
        type: 'collection' | 'products' | 'collaborators',
        item: string
    ) {
        if (type === 'collection') {
            switch (item) {
                case 'In Progress':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        status: { _eq: 'inProgress' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'Completed':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        status: { _eq: 'complete' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'In Review':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        status: { _eq: 'inReview' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'Date Created':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        order_by: {
                            created_at: 'desc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Due Date':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        order_by: {
                            due_date: 'asc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Alphabetical A-Z':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        order_by: {
                            name: 'asc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Alphabetical Z-A':
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {
                        order_by: {
                            name: 'desc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                default:
                    fetchCollectionsByTeamId(String(analyticsOfTeams.id), {})
            }
        }
        if (type === 'products') {
            switch (item) {
                case 'In Progress':
                    console.log(activeSort.sort)
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        status: { _eq: 'inProgress' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'Completed':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        status: { _eq: 'complete' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'In Review':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        status: { _eq: 'inReview' },
                        order_by: {
                            [activeSort.sort]: 'asc',
                        },
                    })
                    return
                case 'Date Created':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        order_by: {
                            created_at: 'desc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Due Date':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        order_by: {
                            due_date: 'asc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Alphabetical A-Z':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        order_by: {
                            name: 'asc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                case 'Alphabetical Z-A':
                    fetchProductsByTeamId(String(analyticsOfTeams.id), 'team', {
                        order_by: {
                            name: 'desc',
                        },
                        status:
                            activeSort.status.length > 0 &&
                            activeSort.status !== ''
                                ? {
                                      _eq: activeSort.status,
                                  }
                                : undefined,
                    })
                    return
                default:
                    fetchProductsByTeamId(
                        String(analyticsOfTeams.id),
                        'team',
                        {}
                    )
            }
        }
    }

    const totalProducts = productsByTeamId?.reduce(
        (acc: number, product: any) => {
            return acc + product.product_variants.length
        },
        0
    )

    return (
        <>
            <Box
                display="grid"
                height="calc(100vh - 100px)"
                gridTemplateRows="auto 1fr"
                gridGap="24px"
                bg="white"
                overflow="auto"
            >
                <Box px={'24px'}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridColumnGap="12px"
                            mt="16px"
                        >
                            {!tabView && (
                                <Button
                                    color="links"
                                    bg="transparent"
                                    border="none"
                                    fontSize="12px"
                                    textAlign="left"
                                    onClick={() => navigate(-1)}
                                >
                                    &lt; Back
                                </Button>
                            )}
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <Box
                                    bg="primary"
                                    px="4px"
                                    py="4px"
                                    display="flex"
                                    alignItems="center"
                                    borderRadius="4px"
                                >
                                    <TeamIcoSm color="white" />
                                    <Text
                                        my="0px"
                                        fontSize="12px"
                                        color="white"
                                    >
                                        Team
                                    </Text>
                                </Box>
                                <Text fontSize={tabView ? '24px' : '16px'}>
                                    {convertIntoElipses(
                                        analyticsOfTeams?.name,
                                        30
                                    )}
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            position="relative"
                            display="flex"
                            justifyContent="end"
                            // width="400px"
                            ref={dropdownRef}
                        >
                            <Button
                                height="max-content"
                                bg="transparent"
                                border="none"
                                onClick={() => setTeamDropdown(!teamDropdown)}
                            >
                                <Dots active={teamDropdown} />
                            </Button>
                            {teamDropdown && (
                                <TeamDropdown
                                    alignment={{ left: 'null' }}
                                    onDropDownClick={dropdownClickHandler}
                                    teamDropdown={teamDropdown}
                                />
                            )}
                        </Box>
                    </Box>
                    {tabView && <Divider />}

                    <Box display="flex" flexDirection="column">
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                gridColumnGap="12px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CollectionIco color="#022143" />
                                <Text>Collection Completed</Text>
                            </Box>
                            {tabView && (
                                <Box
                                    display="flex"
                                    gridColumnGap="12px"
                                    alignItems="center"
                                    justifyContent="center"
                                    color="links"
                                    onClick={() => navigateToDetails()}
                                >
                                    <Button
                                        color="links"
                                        bg="transparent"
                                        border="none"
                                        fontSize="12px"
                                        my="0px"
                                    >
                                        Open Detailed View
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Box
                            display="flex"
                            width={props.tabView ? '100%' : '70%'}
                            alignContent="center"
                            gridColumnGap="18px"
                            flexDirection={props.tabView ? 'column' : 'row'}
                        >
                            <Box
                                display="flex"
                                alignItems="end"
                                gridColumnGap="24px"
                                mb="12px"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gridColumnGap={tabView ? '8px' : '24px'}
                                    width="max-content"
                                >
                                    <Text
                                        my="0px"
                                        fontWeight={500}
                                        fontSize="24px"
                                    >
                                        {
                                            analyticsOfTeams?.totalCompletedCollections
                                        }
                                    </Text>
                                    <Text
                                        width="max-content"
                                        my="0px"
                                        fontSize="16px"
                                    >
                                        {Math.floor(completed)}% on time
                                    </Text>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="end" width="100%">
                                <ProgressBar
                                    overAllProgress={
                                        isNaN(overAllProgress)
                                            ? 0
                                            : overAllProgress
                                    }
                                    progress={[
                                        {
                                            value: completed,
                                            type: 'COMPLETE',
                                            label: {
                                                number: Number(
                                                    analyticsOfTeams.totalCompletedCollectionsOnTime
                                                ),
                                                label: 'Completed On time',
                                            },
                                        },
                                        {
                                            value: delayed,
                                            type: 'DELAY',
                                            label: {
                                                number: Number(
                                                    analyticsOfTeams.totalCompletedCollectionsDelayed
                                                ),
                                                label: 'Delayed',
                                            },
                                        },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{
                        borderRadius: '20px 20px 0px 0px',
                    }}
                    p="24px"
                    height="100%"
                    bg="#F0F2F3"
                >
                    {!tabView && (
                        <Box
                            mb="24px"
                            display="flex"
                            justifyContent="space-between"
                            gridColumnGap="16px"
                            alignItems="center"
                        >
                            <Box
                                mb="24px"
                                display="flex"
                                alignItems="center"
                                gridColumnGap="16px"
                            >
                                <Text my="0px">
                                    {collectionsByTeamId.length} Collection
                                    {collectionsByTeamId.length > 1 ? 's' : ''}
                                </Text>

                                <Button
                                    onClick={() =>
                                        setCollaboratorModalOpen(true)
                                    }
                                    color="links"
                                    bg="transparent"
                                    border="none"
                                >
                                    + Add Collaborators
                                </Button>
                            </Box>
                            {currentTab.tab !== 'collaborators' && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gridColumnGap="24px"
                                >
                                    <CustomDropdown
                                        height="32px"
                                        bg="white"
                                        labelFontSize="14px"
                                        highlightedTextFontSize="14px"
                                        border="solid"
                                        borderColor="secondaryLighterBlue"
                                        selectedText={'In Progress'}
                                        onItemClicked={(item) => {
                                            if (item === 'All')
                                                setActiveSort({
                                                    ...activeSort,
                                                    status: '',
                                                })
                                            if (item === 'In Progress')
                                                setActiveSort({
                                                    ...activeSort,
                                                    status: 'inProgress',
                                                })
                                            if (item === 'Completed')
                                                setActiveSort({
                                                    ...activeSort,
                                                    status: 'complete',
                                                })
                                            if (item === 'In Review')
                                                setActiveSort({
                                                    ...activeSort,
                                                    status: 'inReview',
                                                })
                                            handleDropdownClick(
                                                currentTab.tab,
                                                item
                                            )
                                        }}
                                        label="Filter By"
                                        items={[
                                            'All',
                                            'In Progress',
                                            'Completed',
                                            'In Review',
                                        ]}
                                    />
                                    <CustomDropdown
                                        height="32px"
                                        bg="white"
                                        labelFontSize="14px"
                                        highlightedTextFontSize="14px"
                                        border="solid"
                                        borderColor="secondaryLighterBlue"
                                        selectedText={'Alphabetical A-Z'}
                                        onItemClicked={(item) => {
                                            if (item === 'Due Date')
                                                setActiveSort({
                                                    ...activeSort,
                                                    sort: 'due_date',
                                                })
                                            if (item === 'Alphabetical A-Z')
                                                setActiveSort({
                                                    ...activeSort,
                                                    sort: 'name',
                                                })
                                            if (item === 'Alphabetical A-Z')
                                                setActiveSort({
                                                    ...activeSort,
                                                    sort: 'name',
                                                })
                                            handleDropdownClick(
                                                currentTab.tab,
                                                item
                                            )
                                        }}
                                        label="Sort By"
                                        items={[
                                            'Due Date',
                                            'Alphabetical A-Z',
                                            'Alphabetical Z-A',
                                        ]}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                    <Slider
                        element={
                            <TeamTabSlider
                                isTabView={tabView}
                                onAddCollaboratorClick={(isOpen) =>
                                    setCollaboratorModalOpen(isOpen)
                                }
                                collectionCount={
                                    collectionsByTeamId?.length || 0
                                }
                                productsCount={totalProducts || 0}
                                collaboratorsCount={members.length}
                                currentTab={currentTab.tab}
                                onTabChange={(tabName) =>
                                    setCurrentTab(() => tabName)
                                }
                            />
                        }
                    />
                    {currentTab.tab === 'collection' && collectionsByTeamId && (
                        <CollectionTabData
                            tabView={tabView}
                            collections={collectionsByTeamId}
                        />
                    )}
                    {currentTab.tab === 'products' && productsByTeamId && (
                        <ProductTabData
                            refetch={() => {
                                fetchProductsByTeamId(teamId)
                            }}
                            tabView={tabView}
                            product={productsByTeamId || []}
                        />
                    )}
                    {currentTab.tab === 'collaborators' && (
                        <TeamTabData
                            teamId={teamId}
                            tabView={tabView}
                            collaborators={members}
                        />
                    )}
                </Box>
            </Box>
            {
                <RenameTeamModal
                    isModalActive={isRenameTeamActive}
                    setIsModalActive={(isActive) =>
                        setIsRenameTeamActive(isActive)
                    }
                    team={{
                        name: analyticsOfTeams.name || '',
                        id: analyticsOfTeams.id || '',
                    }}
                />
            }
            {isCollaboratorModalOpen && analyticsOfTeams.name && (
                <AddCollaboratorModal
                    allowTeamChange={false}
                    onRoleUpdate={() =>
                        fetchTeammembers(String(analyticsOfTeams.id))
                    }
                    team={{
                        name: analyticsOfTeams.name,
                        id: analyticsOfTeams.id,
                        team_members: members || [],
                    }}
                    teamMembers={members || []}
                    onCollaboratorsAdded={(members) => {
                        setMembers(members)
                    }}
                    isModal={isCollaboratorModalOpen}
                    onClose={(isOpen) => setCollaboratorModalOpen(isOpen)}
                />
            )}
            <DangerModal
                title={`Delete "${analyticsOfTeams.name}"`}
                isModal={deleteTeamModal}
                onRemove={() => deleteTeam()}
                onClose={() => setDeleteTeamModal(false)}
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                subHeading="Deleting this team will remove all the associated members from its respective collections & products."
                heading={`Are you sure you want to delete '${analyticsOfTeams.name}' ?`}
            />
        </>
    )
}

export default TeamTab
