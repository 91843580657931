import styled from 'styled-components'
import {
	border,
	space,
	color,
	layout,
	shadow,
	typography,
	grid,
	flexbox,
	position
} from 'styled-system'

const Thead = styled.thead<TableTypes>`
    ${color}
    ${space}
    ${layout}
    ${border}
    ${shadow}
    ${typography}
    ${flexbox}
    ${grid}
    ${position}
`

export default Thead
