import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Checkbox, DivisionLine, Modal } from 'src/components'
import { Box, Text } from 'src/components/utility'
import { DeleteCategory, FetchLibraryCategories } from 'src/services'

export const DeleteCategoryModal = ({
    setModal,
    refetchCategories,
}: {
    setModal: (x: boolean) => void
    refetchCategories: (x: boolean) => void
}) => {
    const navigate = useNavigate()
    const [fetchCategories, categoriesData] = useLazyQuery(
        FetchLibraryCategories
    )
    const [deleteCategoriesFromTable, deletionProcess] =
        useMutation(DeleteCategory)
    const [selectedIds, setSelectedIds] = useState<any[]>([])
    const [selectedObject, setSelectedObject] = useState<any>({})
    const [accepted, setAccept] = useState<boolean>(false)
    // const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        fetchCategories({
            variables: {
                whereObj: {
                    deleted_at: {
                        _is_null: true,
                    },
                },
            },
        })
    }, [])

    const catergoryList = categoriesData?.data?.category || []

    const retunSelectedCategories = () => {
        const ids = []
        for (const key in selectedObject) {
            const element = selectedObject[key]
            if (element) {
                ids.push(key)
            }
        }
        setSelectedIds(ids)
        return ids
    }

    useEffect(() => {
        retunSelectedCategories()
    }, [selectedObject])

    const runCheckboxChange = (id: number, value: boolean) => {
        setSelectedObject({
            ...selectedObject,
            [id]: value,
        })
    }

    // const addRouterParams = (tabId: string) => {
    //     searchParams.set(libraryDefaultKeys.tab, `${libraryDefaultKeys.tabNamePrefix}${tabId}`)
    //     searchParams.set(libraryDefaultKeys.categoryId, tabId);
    //     if (searchParams.has(libraryDefaultKeys.showFolderDetails)) {
    //         searchParams.delete(libraryDefaultKeys.showFolderDetails)
    //     }
    //     if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
    //         searchParams.delete(libraryDefaultKeys.showFileDetails)
    //     }
    //     if (searchParams.has(libraryDefaultKeys.filters)) {
    //         searchParams.delete(libraryDefaultKeys.filters)
    //     }
    //     setSearchParams(searchParams)
    // }

    const deleteCategories = () => {
        if (!selectedIds.length) {
            toast.success('Please select categories to delete.', {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        try {
            deleteCategoriesFromTable({
                variables: {
                    ids: selectedIds,
                    deleted_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                },
                onCompleted: () => {
                    let sucessMsg = `Category deleted successfully.`
                    if (selectedIds && selectedIds.length > 1) {
                        sucessMsg = 'Categories deleted successfully.'
                    }
                    toast.success(sucessMsg, {
                        className: 'custom-toaster toaster-success',
                    })
                    refetchCategories(true)
                    setModal(false)
                    navigate('/library')
                    // addRouterParams("all")
                },
                onError: (error) => {
                    console.error(error)
                    toast.error('Failed to delete category.', {
                        className: 'custom-toaster toaster-error',
                    })
                },
            })
        } catch (error) {
            console.error({ error })
        }
    }

    return (
        <Modal
            isModal={true}
            onClick={() => deleteCategories()}
            onClose={() => setModal(false)}
            primaryButtonTitle="Delete"
            secondaryButtonTitle="Cancel"
            title="Delete Category"
            width="600px"
            maxWidth="600px"
            buttonVariant="colored"
            isLoading={deletionProcess.loading}
            disablePrimaryButton={selectedIds.length && accepted ? false : true}
        >
            <Box p={'24px'}>
                <Box
                    maxHeight="45vh"
                    pb={'12px'}
                    overflowY={'scroll'}
                    className="scrollbar_none"
                >
                    {catergoryList?.map((item: any, index: number) => {
                        return (
                            <Box
                                display="flex"
                                key={'cat_' + item.id}
                                pb={'12px'}
                                pt={index === 0 ? '0px' : '12px'}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <label
                                    style={{ display: 'block', width: '100%' }}
                                    className={'pointer'}
                                    htmlFor={'radiocate' + item.id}
                                >
                                    <Box>
                                        <Text my={'0px'} fontSize={'16px'}>
                                            {item.name}{' '}
                                        </Text>
                                    </Box>
                                </label>
                                <Box
                                    display="flex"
                                    minWidth={'30px'}
                                    width="30px"
                                    justifyContent="end"
                                >
                                    <Checkbox
                                        checkBoxId={'cate' + item.id}
                                        onChange={(a: boolean) => {
                                            runCheckboxChange(item.id, a)
                                        }}
                                        label=""
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                <DivisionLine color="#C2CFE0" />

                <Box
                    display="flex"
                    alignItems="start"
                    maxWidth="552px"
                    pt={'12px'}
                >
                    <Checkbox
                        checkBoxId="terms234"
                        label=""
                        onChange={(a: boolean) => {
                            setAccept(a)
                        }}
                    />
                    <label
                        style={{ display: 'block' }}
                        className={'pointer'}
                        htmlFor={'radioterms234'}
                    >
                        <Text
                            my={'0px'}
                            lineHeight="24px"
                            color="textSecondaryDarkBlue"
                            fontSize={'14px'}
                        >
                            I understand that by deleting a category, all the
                            folders and files included will also be deleted and
                            cannot be retrieved.
                        </Text>
                    </label>
                </Box>
            </Box>
        </Modal>
    )
}
