import { gql } from '@apollo/client'

export const GetOrgMembers = gql`
    query GetUsers {
        organisation_members(where: { deleted_at: { _is_null: true } }) {
            user {
                email
                name
                id
                other_data
            }
            role
            organisation_id
        }
    }
`

export const GetAdminAndManagers = gql`
    query GetUsers {
        organisation_members(
            where: { deleted_at: { _is_null: true }, role: { _neq: designer } }
        ) {
            user {
                email
                name
                id
                other_data
            }
            role
            organisation_id
        }
    }
`

export const GetTeamMembers = gql`
    query GetTeamMembers($teamId: bigint = "") {
        team_members(where: { team_id: { _eq: $teamId } }) {
            user {
                id
                name
                other_data
            }
        }
    }
`

export const GetDeactivatedUsers = gql`
    query GetDeactivatedUsers {
        organisation_members_aggregate(
            where: { deleted_at: { _is_null: false } }
        ) {
            nodes {
                role
                user {
                    email
                    id
                    name
                    other_data
                }
            }
            aggregate {
                count
            }
        }
    }
`
export const GetManagerById = gql`
    query getManagerById($id: bigint!) {
        users_by_pk(id: $id) {
            email
            id
            name
            other_data
        }
    }
`

export const SearchMemebers = gql`
    query searchMembers($query: String) {
        organisation_members(
            where: {
                _or: [
                    { user: { email: { _ilike: $query } } }
                    { user: { name: { _ilike: $query } } }
                ]
            }
        ) {
            id
            user_id
            user {
                name
                email
                other_data
                organisation_members {
                    role
                }
            }
        }
    }
`

export const GetTeamMembersByTeamId = gql`
    query GetTeamMembersByTeamId($teamId: bigint = "") {
        team_members(where: { team_id: { _eq: $teamId } }) {
            id
            user {
                id
                name
                email
                created_at
                organisation_members {
                    role
                }
                other_data
            }
        }
    }
`
