import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    ChartConfiguration,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    SubTitle,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Box, Span, Text } from 'src/components/utility'

const LibraryPerformance = ({
    durationDropdown,
    durationList,
}: {
    durationDropdown: string[]
    durationList: any
}) => {
    const tabActive = true

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )
    const [collectionChart, setCollectionsGraph] = useState<Chart>()
    const [productsChart, setProductsGraph] = useState<Chart>()
    const [duration, applyDurationFilter] = useState<string>(
        durationList.thisMonth
    )
    const activeStyle = {
        background: '#FFFFFF',
        border: '1px solid #E8ECEF',
        'border-radius': '4px',
    }

    useEffect(() => {
        if (collectionChart) {
            collectionChart.destroy()
        }

        let ctx: any = document.getElementById(
            'libraryReport'
        ) as HTMLCanvasElement | null
        // let _ctx = ctx.getContext("2d");
        // let gradient = _ctx.createLinearGradient(0, 0, 0, 500);
        // debug_log({ gradient });

        // gradient.addColorStop(0, '#000');
        // gradient.addColorStop(1, '#000');

        // _ctx.fillStyle = gradient;
        // context.fillRect(0, 0, 440,171);

        // gradient.addColorStop(0, 'rgba(196,230,255,1)');
        // gradient.addColorStop(1, 'rgba(255,255,255,1)');
        // ctx.chart.ctx;const gradient = "linear-gradient(180deg, #E5F1FF 30.04%, rgba(229, 241, 255, 0) 100%)";
        // gradient.addColorStop(0, 'rgba(224, 195, 155, 1)');
        // gradient.addColorStop(1, 'rgba(100, 100, 0,0)');

        // gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        // gradient.addColorStop(0, Utils.CHART_COLORS.blue);
        // gradient.addColorStop(0.5, Utils.CHART_COLORS.yellow);
        // gradient.addColorStop(1, Utils.CHART_COLORS.red);

        const data = {
            labels: ['JAM', 'FEB', 'MAE', 'APR'],
            datasets: [
                {
                    label: 'My First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: true,
                    borderColor: '#027AC4',
                    tension: 0.1,
                    background: '#E5F1FF',
                },
            ],
        }

        const config: ChartConfiguration = {
            type: 'line',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        grid: {
                            color: '#E8ECEF',
                        },
                        ticks: {
                            stepSize: 10,
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }

        let chartCreated: Chart = new Chart(ctx, config)
        setCollectionsGraph(chartCreated)
    }, [])

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={'20px'}
            >
                <Text fontSize={'20px'} my="0px">
                    {' '}
                    Library{' '}
                </Text>
                <Box>
                    <Box display="inline-flex">
                        <CustomDropdown
                            label=""
                            minWidth="124px"
                            left="unset"
                            right="0px"
                            noStyle={false}
                            height="32px"
                            border="1px solid #A7B9D1"
                            showArrow={true}
                            selectedText={duration}
                            items={durationDropdown}
                            onItemClicked={applyDurationFilter}
                        />
                    </Box>
                </Box>
            </Box>
            <BasicCard>
                <Box p={'24px'}>
                    <Box
                        display="flex"
                        mb={'16px'}
                        alignItems="center"
                        gridColumnGap={'48px'}
                    >
                        <Text my={'0px'} fontSize={'20px'}>
                            <Span color="textSecondary">Total: </Span>
                            13,331
                        </Text>
                        <Text my={'0px'} fontSize={'20px'}>
                            <Span color="textSecondary"> New: </Span>
                            744
                        </Text>
                    </Box>

                    <Box display="flex" gridColumnGap={'12px'}>
                        <ColoredButton
                            type="button"
                            borderRadius="40px"
                            onClick={() => {}}
                            variant={'tertiary'}
                        >
                            <Box>All Items</Box>
                        </ColoredButton>
                        <ColoredButton
                            type="button"
                            borderRadius="40px"
                            onClick={() => {}}
                            variant={'tertiaryOutline'}
                        >
                            <Box>Silhouettes</Box>
                        </ColoredButton>

                        <ColoredButton
                            type="button"
                            borderRadius="40px"
                            onClick={() => {}}
                            variant={'tertiaryOutline'}
                        >
                            <Box>Trims</Box>
                        </ColoredButton>
                        <ColoredButton
                            type="button"
                            borderRadius="40px"
                            onClick={() => {}}
                            variant={'tertiaryOutline'}
                        >
                            <Box>Textures</Box>
                        </ColoredButton>
                        <ColoredButton
                            type="button"
                            borderRadius="40px"
                            onClick={() => {}}
                            variant={'tertiaryOutline'}
                        >
                            <Box>Silhouettes</Box>
                        </ColoredButton>
                    </Box>

                    <Box>
                        <Box
                            p={'24px'}
                            position="relative"
                            width="100%"
                            display="block"
                        >
                            <canvas
                                height={'380px'}
                                id="libraryReport"
                            ></canvas>
                        </Box>
                    </Box>
                </Box>
            </BasicCard>
        </>
    )
}

export default LibraryPerformance
