import { Box } from 'src/components/utility'
import SubtodoCard from './subtodo-card'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import type { GetTodoQuery } from 'src/generated/graphql'

export const SubtodoFocussedView = ({
    activeSubtodoId,
    setActiveSubtodoId,
    onClose,
    subtodos,
    refetchTodo,
    todoId,
    isTechPack,
}: {
    activeSubtodoId: number
    setActiveSubtodoId: (subtodoId: number) => void
    subtodos: NonNullable<GetTodoQuery['todos_by_pk']>['sub_todos']
    onClose: () => void
    refetchTodo: () => void
    todoId: number
    isTechPack: boolean
}) => {
    const wrapperRef = useClickedOutside(() => onClose())

    const activeSubtodo = subtodos.find(
        (subtodo) => subtodo.id === activeSubtodoId
    )!

    const subtodoIndex = subtodos.findIndex(
        (subtodo) => subtodo.id === activeSubtodoId
    )

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="#02214370"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={15}
        >
            <Box
                width="85vw"
                height="90vh"
                bg="backgroundColor"
                borderRadius="4px"
                ref={wrapperRef}
            >
                <SubtodoCard
                    subtodo={activeSubtodo}
                    isTechPack={isTechPack}
                    refetchTodo={refetchTodo}
                    onFocus={onClose}
                    height="100%"
                    isFocussedView={true}
                    onNextSubtodo={() => {
                        setActiveSubtodoId(
                            subtodoIndex === subtodos.length - 1
                                ? subtodos[0].id
                                : subtodos[subtodoIndex + 1]?.id
                        )
                    }}
                    onPreviousSubtodo={() => {
                        setActiveSubtodoId(
                            subtodoIndex === 0
                                ? subtodos[subtodos.length - 1].id
                                : subtodos[subtodoIndex - 1]?.id
                        )
                    }}
                    todoId={todoId}
                />
            </Box>
        </Box>
    )
}
