import { gql } from "@apollo/client";

export const AddComment = gql`
mutation addComment($object: comments_insert_input = object) {
  insert_comments_one(object: $object) {
    comment
    created_at
    entity
    entity_id
    id
  }
}`