import * as React from 'react'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Button, Text } from 'src/components/utility'
import { getImageUrl, getSentenceCase, roles, useUser } from 'src/helpers'
import { useRemoveCollaborator, useUpdateRole } from '../../hooks'
import { DeleteIco } from 'src/components/svg-icons'
import { DangerModal } from 'src/components'

export interface ICollaboratorCardProps {
    collaborator: IAddCollaboratorList
    teamId?: string
    onRoleChange?: (role?: string) => void
}

export default function CollaboratorCard(props: ICollaboratorCardProps) {
    const { collaborator, teamId } = props
    const [isHovered, setIsHovered] = React.useState(false)
    const [activeRole, setActiveRole] = React.useState(collaborator.role)
    const [rolesArr, setRolesArr] = React.useState<string[]>([])
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
        React.useState(false)
    const updateFn = useUpdateRole()
    const { removeCollaborator } = useRemoveCollaborator()
    const { role } = useUser()

    function updateRole(role: string) {
        updateFn.updateRole(
            collaborator.id,
            role.toLowerCase(),
            collaborator.name
        )
        props.onRoleChange && props.onRoleChange(role)
        setActiveRole(role)
    }
    React.useEffect(() => {
        if (role === roles.admin) {
            setRolesArr(['Admin', 'Manager', 'Designer'])
        } else if (role === roles.manager) {
            if (collaborator.role !== roles.admin) {
                setRolesArr(['Manager', 'Designer'])
            } else {
                setRolesArr([])
            }
        } else {
            if (collaborator.role === roles.designer) {
                setRolesArr([])
            }
        }
    }, [role, collaborator])

    return (
        <>
            <Box
                border="solid"
                borderWidth={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="4px"
                borderColor="gray250"
                width="100%"
                px="8px"
                py="4px"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Box
                    display="flex"
                    gridColumnGap="12px"
                    alignItems="center"
                    overflow={'hidden'}
                >
                    <ProfileImg
                        size="24px"
                        src={getImageUrl(collaborator?.other_data?.profile_pic)}
                        name={collaborator?.name || collaborator?.email}
                    />
                    <Text
                        textAlign="center"
                        fontSize="14px"
                        my="0px"
                        title={collaborator?.name || collaborator?.email}
                        overflow={'hidden'}
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {collaborator?.name || collaborator?.email}
                    </Text>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                >
                    <CustomDropdown
                        items={rolesArr}
                        label=""
                        disabled={rolesArr.length <= 0}
                        selectedText={getSentenceCase(
                            collaborator?.role,
                            'NAME'
                        )}
                        onItemClicked={(role) => updateRole(role)}
                        hideDropdown={!isHovered}
                    />
                    {isHovered && (
                        <Button
                            onClick={() => setIsDeleteUserModalOpen(true)}
                            bg="transparent"
                            border="none"
                            py="0px"
                        >
                            <DeleteIco />
                        </Button>
                    )}
                </Box>
            </Box>
            <DangerModal
                heading={`Are you sure you want to remove "${collaborator.name}"`}
                isModal={isDeleteUserModalOpen}
                onRemove={() => {
                    teamId &&
                        removeCollaborator(
                            collaborator.id,
                            String(teamId),
                            props.onRoleChange && props.onRoleChange
                        )
                }}
                primaryButtonText="Remove"
                secondaryButtonText="Cancel"
                subHeading="Removing collaborator from team will revoke all the access from ongoing products/collections associated with this team."
                title="Remove Collaborator"
                onClose={() => setIsDeleteUserModalOpen(false)}
            />
        </>
    )
}
