import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'

function TodoTemplatesHeader({
    searchDetails,
    updateSearchQuery,
}: {
    searchDetails: {
        searchQuery: string
        searchType: 'category' | 'templates'
    }
    updateSearchQuery: (query: string) => void
}) {
    const navigate = useNavigate()
    return (
        <Box
            px="24px"
            py="28px"
            display="flex"
            flexDirection="column"
            gridGap="28px"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="max-content"
                gridGap="16px"
            >
                <Button
                    color="links"
                    bg="transparent"
                    border="none"
                    fontSize="12px"
                    textAlign="left"
                    onClick={() => navigate(-1)}
                >
                    &lt; Back
                </Button>
                <Text
                    fontFamily="Rubik"
                    fontSize="24px"
                    color="primary"
                    position="relative"
                    my={'0px'}
                    lineHeight="28.4px"
                >
                    Manage Templates
                </Text>
            </Box>
            <Box display="flex" justifyContent="space-between" gridGap="8px">
                <Box
                    display="flex"
                    gridGap="8px"
                    borderRadius="4px"
                    bg="white"
                    border="1px solid #DDDDDD"
                    py="8px"
                    px="12px"
                    width="552px"
                >
                    <SearchIco />
                    <Input
                        placeholder="Search templates"
                        border="none"
                        bg="transparent"
                        value={searchDetails.searchQuery}
                        onChange={(e) => updateSearchQuery(e.target.value)}
                        width="100%"
                    />
                    {searchDetails.searchQuery.length > 0 && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                updateSearchQuery('')
                            }}
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                        >
                            <CrossSm />
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default TodoTemplatesHeader
