import * as React from 'react'
import { Box, Text } from 'src/components/utility'
import CardVariants from './cards/variants'
import { Slider } from 'src/components/slider/slider'
import GlobalSearchSlider from './global-search-slider'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { debounce } from 'src/helpers'
import { useGlobalSearch } from './hooks/useGlobalSearch'
import useStore from 'src/store/hooks/useStore'
import type { TGlobalSearchResults } from './@types/search'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'

export interface IGlobalSearchProps {
    type?: 'search' | 'recent'
    query: string
    isSearchActive: boolean
    setIsSearchActive: (isActive: boolean) => void
}

export function GlobalSearch(props: IGlobalSearchProps) {
    const { type, query } = props
    const [currentTab, setCurrentTab] = React.useState('collections')
    const searchRef = useClickedOutside(
        () => props.setIsSearchActive(false),
        ['search-bar']
    )
    const { getGlobalSearchData } = useGlobalSearch()
    const { globalSearchFilters, searchResults, setGlobalSearchActive } =
        useStore()
    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()
    const debounceFn = debounce(
        getGlobalSearchData,
        400,
        setTimeOutRef,
        timeOutRef
    )
    React.useEffect(() => {
        if (query.length < 2) {
            props.setIsSearchActive(false)
            setGlobalSearchActive({ isGlobalSearchActive: false })
        }
    }, [query.length])

    React.useEffect(() => {
        if (query.length < 2)
            return setGlobalSearchActive({ isGlobalSearchActive: false })
        debounceFn({
            query: globalSearchFilters.query,
            filters: {
                collaborators:
                    globalSearchFilters.collaborators
                        .filter((collaborator) => Number(collaborator.id) !== 0)
                        .map((collaborator) => collaborator.id) || [],
                tags:
                    globalSearchFilters.tags
                        .filter((tag) => Number(tag.id) !== 0)
                        .map((tags) => tags.id) || [],
                status:
                    globalSearchFilters.status
                        .filter((status) => status.isActive)
                        .map((status) => status.id) || [],
                file_types: globalSearchFilters.file_types || [],
            },
        })
    }, [query])
    if (!props.isSearchActive) return <></>
    return (
        props.query.length > 0 && (
            <Box
                zIndex={10}
                position="absolute"
                bg="white"
                width="100%"
                maxHeight={window.innerHeight - 64 + 'px'}
                overflowY="auto"
                top="32px"
                px="16px"
                ref={searchRef}
                py="24px"
                boxShadow="0px 2px 12px 0px #999BA840;"
            >
                {type !== 'search' && <Text my="0px">Recent</Text>}

                <Slider
                    element={
                        <GlobalSearchSlider
                            currentTab={currentTab}
                            setCurrentTab={(tab) => setCurrentTab(tab)}
                            types={
                                searchResults
                                    ? Object.keys(searchResults).map((type) => {
                                          if (type === 'library_folders') return
                                          if (type === 'teams') return
                                          return {
                                              name: type,
                                              count: searchResults[
                                                  type as keyof TGlobalSearchResults
                                              ]?.count,
                                          }
                                      })
                                    : []
                            }
                        />
                    }
                />
                <Box mt="12px">
                    {currentTab === 'collections' &&
                        (searchResults?.collections.count > 0 ? (
                            searchResults?.collections?.data.map(
                                (collection) => (
                                    <CardVariants
                                        onCardClick={() =>
                                            props.setIsSearchActive(false)
                                        }
                                        entity={collection}
                                        variant="collection"
                                    />
                                )
                            )
                        ) : (
                            <EntityEmptyState entityName="Collections Found" />
                        ))}
                    {currentTab === 'products' &&
                        (searchResults?.products.count > 0 ? (
                            searchResults?.products?.data.map((product) => (
                                <CardVariants
                                    onCardClick={() =>
                                        props.setIsSearchActive(false)
                                    }
                                    entity={product}
                                    variant="product"
                                />
                            ))
                        ) : (
                            <EntityEmptyState entityName="Products Found" />
                        ))}
                    {currentTab === 'product_variants' &&
                        (searchResults?.product_variants.count > 0 ? (
                            searchResults?.product_variants?.data.map(
                                (product) => (
                                    <CardVariants
                                        type="variant"
                                        onCardClick={() =>
                                            props.setIsSearchActive(false)
                                        }
                                        entity={product}
                                        variant="product"
                                    />
                                )
                            )
                        ) : (
                            <EntityEmptyState entityName="Products Found" />
                        ))}
                    {currentTab === 'todos' &&
                        (searchResults?.todos.count > 0 ? (
                            searchResults?.todos?.data.map((todo) => (
                                <CardVariants
                                    onCardClick={() =>
                                        props.setIsSearchActive(false)
                                    }
                                    entity={todo}
                                    variant="todo"
                                />
                            ))
                        ) : (
                            <EntityEmptyState entityName="To-Do's Found" />
                        ))}
                    {currentTab === 'library_files' &&
                        (searchResults?.library_files.count > 0 ? (
                            searchResults?.library_files?.data.map((files) => (
                                <CardVariants
                                    onCardClick={() =>
                                        props.setIsSearchActive(false)
                                    }
                                    entity={files}
                                    variant="file"
                                />
                            ))
                        ) : (
                            <EntityEmptyState entityName="Files Found" />
                        ))}
                    {currentTab === 'collaborators' &&
                        (searchResults?.collaborators.count > 0 ? (
                            searchResults?.collaborators?.data.map((file) => (
                                <CardVariants
                                    onCardClick={() =>
                                        props.setIsSearchActive(false)
                                    }
                                    entity={file}
                                    variant="user"
                                />
                            ))
                        ) : (
                            <EntityEmptyState entityName="Products Found" />
                        ))}
                </Box>
            </Box>
        )
    )
}
