import React from 'react'
import ProfileImgWithName from 'src/components-v2/UI/profile-img-with-name'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { CollectionIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'

function CollectionDropdownCard({
    name,
    manager,
    onClick,
}: {
    name: string
    manager: {
        id: number
        name: string
        other_data?: {
            profile_pic?: string
        }
    } | null
    onClick: () => void
}) {
    return (
        <Box
            display="flex"
            pb="4px"
            borderBottom="1px solid"
            borderColor="gray250"
            className="cursor-pointer"
            onClick={onClick}
        >
            <Box
                overflowY="hidden"
                display="flex"
                flexDirection="column"
                gridGap="4px"
            >
                <Box display="flex" gridGap="8px" alignItems="center">
                    <CollectionIco />
                    <Text my="0px" color="textSecondary">
                        {convertIntoElipses(name, 30)}
                    </Text>
                </Box>
                <Box display="flex" gridGap="16px">
                    {manager ? (
                        <ProfileImgWithName
                            imageSrc={
                                manager.other_data?.profile_pic
                                    ? getImageUrl(
                                          manager.other_data?.profile_pic
                                      )
                                    : ''
                            }
                            name={manager.name}
                            textColor="textSecondary"
                        />
                    ) : (
                        <Text my="0px" fontSize="12px" color="textSecondary">
                            No manager
                        </Text>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default CollectionDropdownCard
