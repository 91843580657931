export const SmPencilIco = () => (
    <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.64267 0C6.2982 0 5.95373 0.133676 5.68638 0.401028L0.48329 5.60411L0.462725 5.70694L0.102828 7.51671C0.0544948 7.74387 0.256125 7.94551 0.48329 7.89717L2.29306 7.53727L2.39589 7.51671L7.59897 2.31362C8.13368 1.77892 8.13368 0.935733 7.59897 0.401028C7.33162 0.133676 6.98715 0 6.64267 0ZM6.64267 0.627249C6.80848 0.627249 6.97558 0.703085 7.13625 0.863753C7.4563 1.1838 7.4563 1.53085 7.13625 1.8509L6.89974 2.07712L5.92288 1.10026L6.1491 0.863753C6.30977 0.703085 6.47686 0.627249 6.64267 0.627249ZM5.46015 1.56298L6.43702 2.53985L2.45758 6.51928C2.24165 6.09769 1.90231 5.75835 1.48072 5.54242L5.46015 1.56298ZM1.05913 6.07712C1.45373 6.2365 1.7635 6.54627 1.92288 6.94087L0.843188 7.15681L1.05913 6.07712Z"
            fill="#022143"
        />
    </svg>
)
