import * as React from 'react'
import { Box } from 'src/components/utility'
import { MapConfigurator } from '../../maps.renderer'

export interface IFabricAdvancedProps {}

export default function FabricAdvanced(props: IFabricAdvancedProps) {
    return (
        <Box>
            <MapConfigurator />
        </Box>
    )
}
