export const InfoGreyIco = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7 5C7 4.44771 7.44771 4 8 4C8.55228 4 9 4.44771 9 5C9 5.55229 8.55228 6 8 6C7.44771 6 7 5.55229 7 5ZM8 12C8.55228 12 9 11.5523 9 11V8C9 7.44771 8.55228 7 8 7C7.44771 7 7 7.44771 7 8L7 11C7 11.5523 7.44771 12 8 12Z"
            fill="#778CA2"
        />
    </svg>
)
