import * as React from 'react'
import CollaboratorCard from './collaborator-card'
import { Box } from 'src/components/utility'

export interface IAddCollaboratorTabProps {
    collaborator: IAddCollaboratorList[]
    activeTab: string
    teamId?: string
    onRefetch: () => void
}

export default function AddCollaboratorTab(props: IAddCollaboratorTabProps) {
    const { collaborator, teamId, activeTab } = props
    const filteredCollaborator =
        activeTab !== 'all'
            ? collaborator.filter(
                  (collaborator) =>
                      collaborator.role === activeTab.toLowerCase()
              )
            : collaborator
    return (
        <Box mt="24px" display="flex" flexDirection="column" gridRowGap="10px">
            {filteredCollaborator.map((collaborator) => (
                <CollaboratorCard
                    teamId={teamId}
                    onRoleChange={props.onRefetch}
                    collaborator={collaborator}
                />
            ))}
        </Box>
    )
}
