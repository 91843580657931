export const FolderSmIco = () => (
    <svg
        width="32"
        height="28"
        viewBox="0 0 32 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.35"
            d="M27.2 27.9993H4.8C2.1488 27.9993 0 25.9102 0 23.3327V4.66602H27.2C29.8512 4.66602 32 6.75513 32 9.33268V23.3327C32 25.9102 29.8512 27.9993 27.2 27.9993Z"
            fill="#FFB800"
        />
        <path
            d="M14 4.66667H0V3.11111C0 1.39222 1.39222 0 3.11111 0H10.2013C11.5407 0 12.7291 0.857111 13.1522 2.128L14 4.66667Z"
            fill="#FFB800"
        />
    </svg>
)
