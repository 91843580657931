import * as React from 'react'
import CollectionCard from 'src/components-v2/cards/collection/collection.card'
import ProductCard from 'src/components-v2/cards/product/product.card'
import TodoCard from 'src/components-v2/cards/todo/todo.card'
import type { TGlobalSearchResults } from 'src/components-v2/global-search/@types/search'
import GlobalSearchSlider from 'src/components-v2/global-search/global-search-slider'
import { Slider } from 'src/components/slider/slider'
import { CloseIcoLg } from 'src/components/svg-icons/close-ico'
import { Box, Button, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { FolderCard } from 'src/screens/library/sub-screens/folder-card'
import { FileCard } from 'src/screens/library/sub-screens/file-card'
import { PillButton } from 'src/components/buttons/pill-button'
import FilterDropdown from './filter-dropdowns/filter-dropdown'
import CollaboratorCard from 'src/components-v2/cards/collaborator/collaborator.card'
import TeamCard from 'src/components-v2/cards/team/team.card'
import { useRecentSearch } from 'src/components-v2/global-search/hooks/useRecentSearch'
import { useNavigate } from 'react-router-dom'
import { GetCollections, GetTodos } from 'src/services'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'
import useUpdateProduct from 'src/components-v2/cards/hooks/useUpdateProduct'
import useUpdateTodo from 'src/components-v2/cards/hooks/useUpdateTodo'
import { GetTodo } from 'src/services/graphql/entities/todo/todo.queries'
import Pagination from 'src/components/pagination/pagination'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { PerPage } from 'src/helpers/enums'

interface IGlobalSearchProps {}

export default function GlobalSearch(props: IGlobalSearchProps) {
    const {
        globalSearchFilters,
        setGlobalSearchActive,
        searchResults,
        updateGlobalSearchResults,
        resetFilters,
        setGlobalSearchResults,
    } = useStore()
    const { addRecentSearch } = useRecentSearch()
    const { updateCollection } = useUpdateCollection()
    const { updateProductVariantHandler } = useUpdateProduct()
    const { updateTodo } = useUpdateTodo()
    const [pillBadge, setPillBadge] = React.useState('Teams')
    const [currentTab, setCurrentTab] = React.useState('collections')
    const [currentPage, setCurrentPage] = React.useState(1)
    const { getGlobalSearchData, searchedData } = useGlobalSearch()
    const navigate = useNavigate()
    return (
        <Box display="flex" flexDirection="column">
            <Box
                bg="white"
                display="flex"
                alignItems="center"
                gridColumnGap="20px"
                px="24px"
                py="32px"
            >
                <Button
                    onClick={() => {
                        resetFilters()
                        setGlobalSearchActive({ isGlobalSearchActive: false })
                    }}
                    bg="transparent"
                    border="none"
                >
                    <CloseIcoLg />
                </Button>
                <Box display="flex" alignItems="center">
                    <Text color="textSecondary" my="0px" fontSize="20px">
                        {globalSearchFilters.results} Search results for
                    </Text>
                    <Text ml="4px" my="0px" color="primary" fontSize="24px">
                        "{globalSearchFilters.query}"
                    </Text>
                </Box>
            </Box>
            <Box
                display="grid"
                // height="calc(85vh - 100px)"
                gridTemplateRows="auto 1fr"
                bg="LighterBlueBg"
                px="24px"
                py="24px"
                style={{
                    borderRadius: '12px 12px 0px 0px',
                    borderTop: 'solid 1px #A7B9D1',
                }}
            >
                <Box mb="24px">
                    <FilterDropdown />
                </Box>
                <Slider
                    element={
                        <GlobalSearchSlider
                            setCurrentTab={(tab) => setCurrentTab(tab)}
                            currentTab={currentTab}
                            types={
                                searchResults
                                    ? Object.keys(searchResults).map((type) => {
                                          if (
                                              type !== 'library_folders' &&
                                              type !== 'teams'
                                          ) {
                                              return {
                                                  name: type,
                                                  count:
                                                      type === 'library_files'
                                                          ? searchResults[
                                                                'library_files'
                                                            ].count +
                                                            searchResults[
                                                                'library_folders'
                                                            ].count
                                                          : type ===
                                                            'collaborators'
                                                          ? searchResults[
                                                                'collaborators'
                                                            ].count +
                                                            searchResults[
                                                                'teams'
                                                            ].count
                                                          : searchResults[
                                                                type as keyof TGlobalSearchResults
                                                            ].count,
                                              }
                                          }
                                      })
                                    : []
                            }
                        />
                    }
                />
                <Box
                    mt="24px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="8px"
                    overflowY={'auto'}
                    height="calc(85vh - 100px)"
                >
                    {currentTab === 'collections' &&
                        searchResults?.collections?.data?.map((collection) => {
                            return (
                                <CollectionCard
                                    afterDueDateUpdate={(date) => {
                                        updateGlobalSearchResults({
                                            type: 'collections',
                                            updatedKey: 'due_date',
                                            value: date.toISOString(),
                                            id: String(collection.id),
                                        })
                                    }}
                                    afterStatusUpdate={(status) => {
                                        updateGlobalSearchResults({
                                            type: 'collections',
                                            updatedKey: 'status',
                                            value: status,
                                            id: String(collection.id),
                                        })
                                    }}
                                    updateDate={(date) => {
                                        updateCollection(
                                            collection.id,
                                            {
                                                due_date: date.toISOString(),
                                            },
                                            [
                                                GetCollections,
                                                'fetchCollectionById',
                                            ],
                                            () => {
                                                console.log('Updatingg...')
                                                updateGlobalSearchResults({
                                                    type: 'collections',
                                                    updatedKey: 'due_date',
                                                    value: date.toISOString(),
                                                    id: String(collection.id),
                                                })
                                            }
                                        )
                                    }}
                                    updateStatus={(status) => {
                                        updateCollection(
                                            collection.id,
                                            {
                                                status,
                                            },
                                            [
                                                GetCollections,
                                                'fetchCollectionById',
                                            ],
                                            () => {
                                                updateGlobalSearchResults({
                                                    type: 'collections',
                                                    updatedKey: 'status',
                                                    value: status,
                                                    id: String(collection.id),
                                                })
                                            }
                                        )
                                    }}
                                    entity={collection}
                                />
                            )
                        })}
                    {currentTab === 'products' &&
                        searchResults?.products?.data?.map((product) => {
                            /**
                             * TODO: Update this to render the product card along with variants once the api is ready
                             */
                            return (
                                <ProductCard
                                    updateDate={(date) => {
                                        updateProductVariantHandler({
                                            payload: {
                                                due_date: date.toISOString(),
                                            },
                                            productId: Number(product.id),
                                            onComplete: () => {
                                                updateGlobalSearchResults({
                                                    type: 'products',
                                                    updatedKey: 'due_date',
                                                    value: date.toISOString(),
                                                    id: String(product.id),
                                                })
                                            },
                                        })
                                    }}
                                    updateStatus={(status) => {
                                        updateProductVariantHandler({
                                            payload: {
                                                status: status,
                                            },
                                            productId: Number(product.id),
                                            onComplete: () => {
                                                updateGlobalSearchResults({
                                                    type: 'products',
                                                    updatedKey: 'status',
                                                    value: status,
                                                    id: String(product.id),
                                                })
                                            },
                                        })
                                    }}
                                    entity={product}
                                />
                            )
                        })}
                    {currentTab === 'product_variants' &&
                        searchResults?.product_variants?.data?.map(
                            (product) => {
                                /**
                                 * TODO: Update this to render the product card along with variants once the api is ready
                                 */
                                return (
                                    <ProductCard
                                        isVariant={true}
                                        updateDate={(date) => {
                                            updateProductVariantHandler({
                                                payload: {
                                                    due_date:
                                                        date.toISOString(),
                                                },
                                                productId: Number(product.id),
                                                onComplete: () => {
                                                    updateGlobalSearchResults({
                                                        type: 'products',
                                                        updatedKey: 'due_date',
                                                        value: date.toISOString(),
                                                        id: String(product.id),
                                                    })
                                                },
                                            })
                                        }}
                                        updateStatus={(status) => {
                                            updateProductVariantHandler({
                                                payload: {
                                                    status: status,
                                                },
                                                productId: Number(product.id),
                                                onComplete: () => {
                                                    updateGlobalSearchResults({
                                                        type: 'products',
                                                        updatedKey: 'status',
                                                        value: status,
                                                        id: String(product.id),
                                                    })
                                                },
                                            })
                                        }}
                                        entity={product}
                                    />
                                )
                            }
                        )}
                    {currentTab === 'todos' &&
                        searchResults?.todos?.data?.map((todo) => {
                            return (
                                <TodoCard
                                    updateDate={(date) => {
                                        updateTodo({
                                            todoId: Number(todo.id),
                                            payload: {
                                                due_date: date.toISOString(),
                                            },
                                            refetchQueries: [
                                                GetTodos,
                                                {
                                                    query: GetTodo,
                                                    variables: {
                                                        id: todo.id,
                                                    },
                                                },
                                            ],
                                            onComplete: () => {
                                                updateGlobalSearchResults({
                                                    type: 'todos',
                                                    updatedKey: 'due_date',
                                                    value: date.toISOString(),
                                                    id: String(todo.id),
                                                })
                                            },
                                        })
                                    }}
                                    updateStatus={(status) => {
                                        updateTodo({
                                            todoId: Number(todo.id),
                                            payload: {
                                                status,
                                            },
                                            refetchQueries: [
                                                GetTodos,
                                                {
                                                    query: GetTodo,
                                                    variables: {
                                                        id: todo.id,
                                                    },
                                                },
                                            ],
                                            onComplete: () => {
                                                updateGlobalSearchResults({
                                                    type: 'todos',
                                                    updatedKey: 'status',
                                                    value: status,
                                                    id: String(todo.id),
                                                })
                                            },
                                        })
                                    }}
                                    entity={todo}
                                    onClick={() => {
                                        globalSearchFilters.isGlobalSearchActive &&
                                            addRecentSearch({
                                                entity_name: 'todos',
                                                entity_id: todo.id,
                                                search_query:
                                                    globalSearchFilters.query,
                                            })
                                        setGlobalSearchActive({
                                            isGlobalSearchActive: false,
                                        })
                                    }}
                                    navigateTo={`/todo/${todo.id}`}
                                />
                            )
                        })}
                    {currentTab === 'collaborators' && (
                        <Box>
                            <Box mb="24px" display="flex" gridColumnGap="12px">
                                <PillButton
                                    onClick={() => setPillBadge('Teams')}
                                    bg={
                                        pillBadge === 'Teams'
                                            ? 'primary'
                                            : 'white'
                                    }
                                    color={
                                        pillBadge === 'Teams'
                                            ? 'white'
                                            : 'primary'
                                    }
                                >
                                    Teams
                                </PillButton>
                                <PillButton
                                    onClick={() =>
                                        setPillBadge('Collaborators')
                                    }
                                    bg={
                                        pillBadge === 'Collaborators'
                                            ? 'primary'
                                            : 'white'
                                    }
                                    color={
                                        pillBadge === 'Collaborators'
                                            ? 'white'
                                            : 'primary'
                                    }
                                >
                                    Collaborators
                                </PillButton>
                            </Box>
                            {pillBadge === 'Collaborators' && (
                                <Box
                                    display="flex"
                                    gridRowGap="8px"
                                    flexDirection="column"
                                >
                                    {searchResults?.collaborators?.data?.map(
                                        (collaborator) => {
                                            return (
                                                <CollaboratorCard
                                                    entity={collaborator}
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                            )}
                            {pillBadge === 'Teams' && (
                                <Box
                                    display="flex"
                                    gridRowGap="8px"
                                    flexDirection="column"
                                >
                                    {searchResults?.teams?.data?.map((team) => {
                                        return <TeamCard entity={team} />
                                    })}
                                </Box>
                            )}
                        </Box>
                    )}

                    {currentTab === 'library_files' && (
                        <Box>
                            <Box mb="24px">
                                <Text my="0px" color="textSecondary">
                                    Folders(
                                    {
                                        searchResults?.library_folders?.data
                                            .length
                                    }
                                    )
                                </Text>
                            </Box>

                            <Box
                                display="flex"
                                flexWrap="wrap"
                                gridRowGap="24px"
                                gridColumnGap="24px"
                            >
                                {searchResults?.library_folders?.data?.map(
                                    (folder) => {
                                        return <FolderCard data={folder} />
                                    }
                                )}
                            </Box>
                            <Box my="24px" mb="24px">
                                <Text my="0px" color="textSecondary">
                                    Files(
                                    {searchResults?.library_files?.data.length})
                                </Text>
                            </Box>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                gridRowGap="24px"
                                gridColumnGap="24px"
                            >
                                {currentTab === 'library_files' &&
                                    searchResults?.library_files?.data?.map(
                                        (folder) => {
                                            return <FileCard data={folder} />
                                        }
                                    )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Pagination
                currentPage={currentPage}
                onPageChange={(page) => {
                    setCurrentPage(page)
                    getGlobalSearchData(
                        {
                            query: globalSearchFilters.query,
                            filters: {
                                collaborators: globalSearchFilters.collaborators
                                    .filter(
                                        (collaborator) =>
                                            Number(collaborator.id) !== 0
                                    )
                                    .map((collaborator) =>
                                        String(collaborator.id)
                                    ),
                                tags: globalSearchFilters.tags
                                    .filter((tag) => Number(tag.id) !== 0)
                                    .map((tag) => tag.id),
                                file_types: globalSearchFilters.file_types,
                                status: globalSearchFilters.status
                                    .filter((status) => status.isActive)
                                    .map((status) => status.id),
                                from:
                                    globalSearchFilters.dateRange || undefined,
                                to: globalSearchFilters.dateRange || undefined,
                            },
                        },
                        {
                            offset: (currentPage - 1) * PerPage,
                        }
                    )
                }}
                pageSize={PerPage}
                totalCount={globalSearchFilters.results}
            />
        </Box>
    )
}
