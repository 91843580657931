import * as React from 'react'
import type { Theme, CSSObject } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { Box } from '../utility'
import { SidebarLogo } from './sidebar-logo'
import { SideBarGroupedNavigationLinks } from './sidebar-grouped-nav-links'
import { SidebarProfile } from './sidebar-profile'
import useStore from 'src/store/hooks/useStore'
import { SideBarLinksFirsthalf, SideBarLinksSecondhalf } from './sidebar-links'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'

const openedMixin = (theme: Theme): CSSObject => {
    return {
        width: '179px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        backgroundColor: '#022143',
        color: '#000',
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    }
}

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `70px`,
    [theme.breakpoints.up('sm')]: {
        width: `70px`,
    },
    backgroundColor: '#022143',
    color: '#000',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
})

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: '179px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export function Sidebar() {
    const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false)
    const { setIsModalProfileModalOpen } = useStore()
    const { removeLastOpenedTab } = useCardExpandedState()

    const handleDrawerOpen = () => {
        setIsSidebarExpanded(true)
    }

    const handleDrawerClose = () => {
        setIsSidebarExpanded(false)
    }

    return (
        <Drawer
            variant="permanent"
            open={isSidebarExpanded}
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
        >
            <Box
                flexDirection="column"
                display="flex"
                gridRowGap="40px"
                gridColumnGap="42px"
                alignItems="center"
                bg="#022143"
                color="#000"
                onClick={() => {
                    // removeLastOpenedTab()
                }}
            >
                <SidebarLogo variant={isSidebarExpanded ? 'full' : 'icon'} />
                <SideBarGroupedNavigationLinks>
                    <>
                        <SideBarLinksFirsthalf showText={isSidebarExpanded} />
                        <SideBarLinksSecondhalf showText={isSidebarExpanded} />
                    </>
                </SideBarGroupedNavigationLinks>
            </Box>
            <SidebarProfile
                setIsModal={(val: boolean) => setIsModalProfileModalOpen(val)}
                showName={isSidebarExpanded}
            />
        </Drawer>
    )
}
