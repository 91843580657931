import { useSubscription } from '@apollo/client'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import {
    getLocalStorage,
    getSentenceCase,
    logoutUser,
    useUser,
} from 'src/helpers'
import { SubscribeToRoleChange } from 'src/services'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Box, Span } from 'src/components/utility'

export function Protected({
    path,
    children,
    ...props
}: {
    path?: string
    children?: React.ReactNode
}) {
    const location = useLocation()
    const token = getLocalStorage('authToken')
    const { userId, role, organization_id } = useUser()
    const navigate = useNavigate()

    const { data } = useSubscription(SubscribeToRoleChange, {
        skip: !userId,
        variables: {
            id: userId,
            orgId: organization_id,
        },
    })
    useEffect(() => {
        if (
            data &&
            data.organisation_members &&
            data.organisation_members.length > 0
        ) {
            if (
                data.organisation_members[0].role &&
                data.organisation_members[0].role !== role
            ) {
                logoutUser()
                navigate('/login', {
                    state: { from: location },
                })
                toast(
                    <Box>
                        You have been logged out because your role has been
                        updated to{' '}
                        <Span color="links">
                            {getSentenceCase(
                                data.organisation_members[0].role,
                                'NAME'
                            )}
                        </Span>
                        .
                    </Box>,
                    {
                        className: 'custom-toaster toaster-info',
                    }
                )
            } else if (data.organisation_members[0].deleted_at) {
                logoutUser()
                navigate('/login', {
                    state: { from: location },
                })
                toast(
                    <Box>
                        You have been removed from the organization. Please
                        contact system administrator for further information.
                    </Box>,
                    {
                        className: 'custom-toaster toaster-info',
                    }
                )
            }
        }
    }, [data])

    return token ? (
        <>{children}</>
    ) : (
        <Navigate to="/login" state={{ from: location }} {...props} />
    )
}
