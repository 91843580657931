import { useQuery } from '@apollo/client'
import { Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'
import { DueDateDropdown } from 'src/components/collections/actions/due-date.tsx/due-date-dropdown'
import { SortDropdown } from 'src/components/collections/actions/sort/sort-dropdown'
import { CardLoader } from 'src/components/collections/card-loader'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import Pagination from 'src/components/pagination/pagination'
import { Slider } from 'src/components/slider/slider'
import { TodosHeader } from 'src/components/todos/todos-header'
import { Box, Text } from 'src/components/utility'
import type {
    GetTodosByUserIdQuery,
    GetTodosByUserIdQueryVariables,
    Status_Enum,
    Status_Enum_Comparison_Exp,
} from 'src/generated/graphql'
import { statuses, useUser } from 'src/helpers'
import { GetTodosByUserId } from 'src/services/graphql/entities/todo/get-todos-by-user-id'
import { sort } from './todos.helper'

export function Todos() {
    const [dueDate, setDueDate] = useState<
        [Date | null, Date | null] | Date | null
    >(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [resourcesCount, setResourcesCount] = useState<
        Record<string, number>
    >({
        new: 0,
        inProgress: 0,
        inReview: 0,
        requestedForResubmission: 0,
        toBeReviewed: 0,
        complete: 0,
    })
    const { organization_id, userId } = useUser()

    const {
        page: currentPage = '1',
        sort: activeSort = 'created_at',
        tab: currentTab = 'new',
    } = Object.fromEntries(searchParams.entries())

    const pageLimit = 10

    const { data: todosData, loading: isTodosLoading } = useQuery<
        GetTodosByUserIdQuery,
        GetTodosByUserIdQueryVariables
    >(GetTodosByUserId, {
        skip: !organization_id || !userId,
        variables: {
            offset: (Number(currentPage) - 1) * pageLimit,
            limit: pageLimit,
            status:
                currentTab === ('toBeReviewed' as Status_Enum)
                    ? ('inReview' as Status_Enum)
                    : (currentTab as Status_Enum),
            order_by: sort[activeSort as 'due_date' | 'name' | 'created_at'],
            due_date: dueDate
                ? Array.isArray(dueDate)
                    ? {
                          _gte: dueDate[0]?.toISOString(),
                          _lt: dueDate[1]?.toISOString(),
                      }
                    : {
                          _eq: new Date(
                              new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                          ).toISOString(),
                      }
                : undefined,
            organisation_id: organization_id,
            user_id: userId,
            todo_filter:
                currentTab === 'toBeReviewed'
                    ? {
                          status: {
                              _eq: 'inReview' as Status_Enum,
                          },
                          product_variant: {
                              collection: {
                                  manager_id: {
                                      _eq: userId,
                                  },
                                  id: {
                                      _is_null: false,
                                  },
                              },
                          },
                      }
                    : {
                          status: {
                              _eq: currentTab,
                          } as Status_Enum_Comparison_Exp,
                          product_variant: {
                              collection: { id: { _is_null: false } },
                          },
                          due_date: dueDate
                              ? Array.isArray(dueDate)
                                  ? {
                                        _gte: dueDate[0]?.toISOString(),
                                        _lt: dueDate[1]?.toISOString(),
                                    }
                                  : {
                                        _eq: new Date(
                                            new Date(dueDate).setUTCHours(
                                                0,
                                                0,
                                                0,
                                                0
                                            ) - 1
                                        ).toISOString(),
                                    }
                              : undefined,
                          user_id: { _eq: userId },
                      },
        },
        fetchPolicy: 'cache-and-network',
    })

    const todosList = todosData?.todos
    const totalTodos =
        resourcesCount.new +
        resourcesCount.inProgress +
        resourcesCount.inReview +
        resourcesCount.complete

    useEffect(() => {
        if (todosData) {
            setResourcesCount({
                new: todosData.new.aggregate?.count || 0,
                inProgress: todosData.inProgress.aggregate?.count || 0,
                inReview: todosData.inReview.aggregate?.count || 0,
                toBeReviewed: todosData.toBeReviewed.aggregate?.count || 0,
                complete: todosData.totalCompleted.aggregate?.count || 0,
                requestedForResubmission:
                    todosData.requestedForResubmission.aggregate?.count || 0,
            })
        }
    }, [todosList?.length])

    return (
        <Box
            display="grid"
            height="calc(100vh - 60px)"
            gridTemplateRows="100px 1fr"
        >
            <TodosHeader />
            <Box
                bg="#F8FAFB"
                display="grid"
                height="100%"
                overflow="hidden"
                gridTemplateRows="33px 1fr"
                gridGap="20px"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                py="20px"
                px="24px"
            >
                {/* Sort and Filters */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box display="flex" gridGap="8px" alignItems="center">
                        <Text fontSize="20px" color="022143">
                            {totalTodos} To-dos
                        </Text>
                    </Box>
                    <Box display="flex" gridGap="16px" alignItems="center">
                        <SortDropdown />
                        <DueDateDropdown
                            setDueDate={setDueDate}
                            dueDate={dueDate}
                            rightOffset="0px"
                        />
                    </Box>
                </Box>
                <Box
                    maxHeight="100%"
                    overflow="hidden"
                    display="grid"
                    gridTemplateRows="33.5px 1fr"
                >
                    <Slider
                        element={
                            <Box>
                                <Tabs
                                    value={currentTab}
                                    variant="scrollable"
                                    scrollButtons={false}
                                    onChange={(_event: any, newValue: any) => {
                                        searchParams.set('tab', newValue)
                                        searchParams.set('page', String(1))
                                        setSearchParams(searchParams, {
                                            replace: true,
                                        })
                                    }}
                                    aria-label="collection-tabs"
                                >
                                    <Tab
                                        id="todos-new-tab"
                                        label={`New (${resourcesCount.new})`}
                                        value={statuses.new}
                                    />
                                    <Tab
                                        id="todos-inprogress-tab"
                                        label={`In Progress (${resourcesCount.inProgress})`}
                                        value={statuses.inProgress}
                                    />
                                    <Tab
                                        id="todos-revisionsrequested-tab"
                                        label={`Revision Requested (${resourcesCount.requestedForResubmission})`}
                                        value={
                                            statuses.requestedForResubmission
                                        }
                                    />
                                    <Tab
                                        id="todos-inreview-tab"
                                        label={`In Review (${resourcesCount.inReview})`}
                                        value={statuses.inReview}
                                    />
                                    <Tab
                                        id="todos-tobereviewed-tab"
                                        label={`To Be Reviewed (${resourcesCount.toBeReviewed})`}
                                        value={statuses.toBeReviewed}
                                    />
                                    <Tab
                                        id="todos-completed-tab"
                                        label={`Completed (${resourcesCount.complete})`}
                                        value={statuses.complete}
                                    />
                                </Tabs>
                            </Box>
                        }
                    />
                    <Box overflowY="auto" className="scrollbar_none">
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            mt="8px"
                        >
                            {todosList ? (
                                todosList.length > 0 ? (
                                    todosList.map((todo) => {
                                        return (
                                            <TodoListCard
                                                key={todo.id}
                                                todo={{
                                                    id: todo.id,
                                                    name: todo.name,
                                                    coverPic:
                                                        todo.other_data
                                                            ?.cover_pic,
                                                    collection: {
                                                        id: todo
                                                            ?.product_variant
                                                            ?.collection?.id,
                                                        name:
                                                            todo
                                                                ?.product_variant
                                                                ?.collection
                                                                ?.name || '',
                                                        manager_id:
                                                            todo
                                                                ?.product_variant
                                                                ?.collection
                                                                ?.manager_id,
                                                    },
                                                    product: {
                                                        id: todo
                                                            ?.product_variant
                                                            ?.id,
                                                        name:
                                                            todo
                                                                ?.product_variant
                                                                ?.name || '',
                                                        coverPic:
                                                            todo
                                                                ?.product_variant
                                                                ?.other_data
                                                                ?.cover_pic,
                                                    },
                                                    dueDate: todo.due_date,
                                                    isDelayed: todo.is_delayed,
                                                    status: todo.status,
                                                    assignee: todo.assignee,
                                                }}
                                                navigateTo={`/todo/${todo.id}`}
                                            />
                                        )
                                    })
                                ) : (
                                    <EntityEmptyState entityName="Todos" />
                                )
                            ) : isTodosLoading ? (
                                <CardLoader cards={4} skeletonCount={1} />
                            ) : null}
                        </Box>
                        <Pagination
                            className="pagination-bar"
                            currentPage={Number(currentPage)}
                            totalCount={resourcesCount[currentTab]}
                            pageSize={pageLimit}
                            onPageChange={(page: number) => {
                                searchParams.set('page', page + '')
                                setSearchParams(searchParams, { replace: true })
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
