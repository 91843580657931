export function PeopleIco({ color = '#C2CFE0' }: { color?: string }) {
    return (
        <svg
            width={15}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.273 0a5.1 5.1 0 00-5.091 5.09c0 1.754.895 3.31 2.25 4.228C2.05 10.341.306 12.594.036 15.274c-.04.4.29.726.691.726.402 0 .723-.327.772-.726a5.807 5.807 0 015.774-5.092 5.807 5.807 0 015.773 5.092c.05.399.37.726.772.726s.731-.327.691-.726c-.27-2.68-2.014-4.933-4.395-5.956a5.108 5.108 0 002.25-4.227A5.1 5.1 0 007.273 0zm0 1.455a3.625 3.625 0 013.636 3.636 3.625 3.625 0 01-3.636 3.636 3.625 3.625 0 01-3.637-3.636 3.625 3.625 0 013.637-3.636z"
                fill={color}
            />
        </svg>
    )
}
