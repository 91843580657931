export const LogoSm = () => (
    <svg width={130} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.414 26.57 38.426 6.975h3.81l4.9 16.113 4.98-16.113h3.81L49.94 26.57h-5.525ZM61.515 26.57V6.975h3.702V26.57h-3.702ZM72.563 26.57V6.975h8.274c.19 0 .445.009.762.027.327.01.617.036.871.082 1.17.181 2.128.567 2.872 1.157a5.209 5.209 0 0 1 1.66 2.231c.354.89.53 1.883.53 2.98 0 1.643-.407 3.05-1.224 4.22-.817 1.16-2.096 1.878-3.838 2.15l-1.633.109h-4.573v6.64h-3.701Zm11.05 0-3.865-7.974 3.81-.735 4.247 8.71h-4.192Zm-7.349-10.097h4.41c.19 0 .399-.01.626-.027.227-.019.435-.055.626-.11.499-.135.884-.362 1.157-.68.272-.326.458-.685.558-1.075.108-.399.163-.775.163-1.13 0-.353-.055-.725-.163-1.115a2.6 2.6 0 0 0-.558-1.075c-.273-.327-.658-.558-1.157-.694-.19-.055-.4-.091-.626-.11a7.869 7.869 0 0 0-.626-.027h-4.41v6.043ZM98.608 26.57V10.43H92.43V6.974h16.058v3.457h-6.178v16.14h-3.702ZM121.916 26.979c-1.578 0-2.966-.318-4.164-.953a7 7 0 0 1-2.803-2.708c-.672-1.161-1.008-2.527-1.008-4.096V7.002l3.757-.028v12.22c0 .645.108 1.23.326 1.756.218.527.517.98.898 1.361.39.381.84.676 1.348.885.517.2 1.066.3 1.646.3a4.33 4.33 0 0 0 1.661-.314 3.998 3.998 0 0 0 1.347-.884c.381-.381.676-.835.884-1.361a4.508 4.508 0 0 0 .327-1.742V6.974h3.756v12.248c0 1.57-.336 2.935-1.007 4.096a7.005 7.005 0 0 1-2.803 2.708c-1.198.635-2.586.953-4.165.953Z"
            fill="#EAFC40"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m12.833 15.688 13.17-7.555L14.49 1.728 1.647 9.068v14.627l11.186 6.269V15.688ZM.553 7.795 13.942.145c.334-.191.743-.193 1.079-.007l12.633 7.029c.746.415.753 1.486.012 1.911L14.48 16.642v14.259c0 .838-.902 1.367-1.634.957L.56 24.974A1.098 1.098 0 0 1 0 24.016V8.75c0-.394.211-.758.553-.954Z"
            fill="#EAFC40"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.011 11.482a.823.823 0 0 1 1.13-.282l6.544 3.926a.823.823 0 1 1-.848 1.412l-6.543-3.926a.824.824 0 0 1-.283-1.13ZM18.227 18.512v10.833l6.238-3.36a6.587 6.587 0 0 0 3.464-5.8V12.98l-9.702 5.533Zm9.707-7.431L16.58 17.555v12.709c0 .83.888 1.36 1.619.966l7.047-3.795a8.234 8.234 0 0 0 4.33-7.25v-8.15c0-.843-.91-1.372-1.642-.954Z"
            fill="#EAFC40"
        />
    </svg>
)
