import React, { useState } from 'react'
import RecentActivitiesDropdown from 'src/components-v2/recent-activities-dropdown/recent-activities-dropdown'
import type { RecentActivityFilters } from 'src/components-v2/recent-activities-dropdown/recent-activities.types'
import { RecentActivityIco } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import type {
    EntityData} from 'src/store/hooks/useActivitiesStore';
import {
    useActivitiesStore,
} from 'src/store/hooks/useActivitiesStore'

function ProductActivities({ product }: { product: any }) {
    const [showRecentActivity, setShowRecentActivity] = useState(false)
    const [activeFilter, setActiveFilter] =
        useState<RecentActivityFilters>('default')

    const { getEntityActivities, status } = useActivitiesStore()

    const productActivities: EntityData = getEntityActivities({
        entity_name: 'product_variants',
        entity_id: product.id,
        page: 1,
    })

    const [nextPage, setNextPage] = useState(2) // Starting the state from 2 here, because 1st page is loaded when the component loads

    const fetchMoreActivities = () => {
        if (nextPage <= productActivities.totalPages) {
            getEntityActivities({
                entity_name: 'product_variants',
                entity_id: product.id,
                page: nextPage,
            })
        }
        setNextPage((nextPage) => nextPage + 1)
    }

    return (
        <Box position="relative">
            {productActivities.total > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    top="-14px"
                    right="0"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {productActivities.total}
                    </Text>
                </Box>
            )}
            <Button
                onClick={() => setShowRecentActivity(!showRecentActivity)}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                id="recent-activity-button"
            >
                <RecentActivityIco active={showRecentActivity} />
            </Button>
            {showRecentActivity && (
                <RecentActivitiesDropdown
                    entityTitle={product.name}
                    recentActivities={productActivities.activities}
                    setShowRecentActivity={setShowRecentActivity}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    isLoading={status === 'connecting'}
                    fetchMoreActivities={fetchMoreActivities}
                    hasMoreActivities={
                        nextPage <= productActivities.totalPages
                    }
                />
            )}
        </Box>
    )
}

export default ProductActivities
