import { gql } from "@apollo/client";

export const GlobalSearch = gql`
query GlobalSearch($search: String = "") {
    collections(where: {name: {_ilike: $search}}) {
      id
      name
    }
    products(where: {name: {_ilike: $search}}) {
      id
      name
      collection_id
    }
  }
  `