import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { FileIcoLg } from 'src/components/svg-icons/file'
import { Box, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { getFormattedDate, getImageUrl, getSentenceCase } from 'src/helpers'
import { useRecentSearch } from '../hooks/useRecentSearch'

type TFileVariantProps = {
    entity: FileObject & {
        creator: {
            email: string
            name: string
            other_data: {
                profile_pic: string
                thumbnail: string
            }
        }
    }
}

export function FileVariant(props: TFileVariantProps) {
    const { entity } = props
    const { globalSearchFilters } = useStore()
    const { addRecentSearch } = useRecentSearch()
    return (
        <Box
            color="textSecondaryDarkBlue"
            className="cursor-pointer"
            display="flex"
            flexDirection="column"
            gridRowGap="8px"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            onClick={() =>
                addRecentSearch({
                    entity_name: 'library_files',
                    entity_id: entity.id,
                    search_query: globalSearchFilters.query,
                })
            }
            py="8px"
            px="4px"
        >
            <Box display="flex" gridColumnGap="12px" alignItems="center">
                <FileIcoLg />
                <Highlighted
                    search={globalSearchFilters.query}
                    text={entity.name}
                    unHighlighted="textSecondaryDarkBlue"
                />
            </Box>
            <Box display="flex" alignItems="center" gridColumnGap="16px">
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    <ProfileImg
                        fontSize="12px"
                        size="20px"
                        src={getImageUrl(
                            entity?.creator?.other_data?.thumbnail ||
                                entity?.creator?.other_data?.profile_pic
                        )}
                        name={'Y'}
                    />
                    <Text my="0px" fontSize="14px">
                        {getSentenceCase(entity?.creator?.name, 'NAME')}
                    </Text>
                </Box>
                <Text my="0px" fontSize="12px">
                    {getFormattedDate(entity.created_at, 'FORMATTED')}
                </Text>
            </Box>
        </Box>
    )
}
