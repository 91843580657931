import { gql } from '@apollo/client'

export const GetProductsByCollectionId = gql`
    query GetProductsByCollectionId($id: bigint!, $searchQuery: String = "%%") {
        products(
            where: {
                name: { _ilike: $searchQuery }
                collection_id: { _eq: $id }
            }
            order_by: { created_at: desc }
        ) {
            id
            name
            other_data
            product_variants(order_by: { created_at: asc }) {
                id
                name
                other_data
                is_primary
                product_variant_approver {
                    id
                    name
                    other_data
                }
                collection {
                    id
                    name
                }
                todos {
                    id
                    name
                    types
                }
            }
            todos {
                id
                name
                types
            }
        }
    }
`

export const GetActiveTodoWithProduct = gql`
    query GetActiveTodoWithProduct($id: bigint!) {
        todos_by_pk(id: $id) {
            id
            name
            types
            product_variant {
                id
                name
                collection {
                    id
                    name
                }
            }
        }
    }
`

export const GetActiveProductWithCollection = gql`
    query GetActiveProductWithCollection($id: bigint!) {
        product_variants_by_pk(id: $id) {
            id
            name
            collection {
                id
                name
            }
            todos {
                id
                name
                types
            }
        }
    }
`

export const GetTodosByProductId = gql`
    query GetTodosByProductId($id: bigint!, $searchQuery: String!) {
        todos(
            where: {
                product_variant_id: { _eq: $id }
                name: { _ilike: $searchQuery }
            }
            order_by: { id: asc }
        ) {
            id
            name
            types
            other_data
            product_variant {
                id
                name
                other_data
                collection {
                    id
                    name
                }
            }
            assignee {
                id
                name
                other_data
            }
        }
    }
`

export const GetCollections = gql`
    query GetCollections($searchQuery: String = "%%") {
        collections(
            order_by: { id: desc }
            where: { name: { _ilike: $searchQuery } }
        ) {
            id
            name
            manager {
                id
                name
                other_data
            }
        }
    }
`
