import type { Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export function CreateNewDropdown({
    active,
    setIsDropdownActive,
    setActiveModal,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    setActiveModal: React.Dispatch<
        React.SetStateAction<'collection' | 'product' | 'todo' | null>
    >
}) {
    const wrapperRef = useClickedOutside(() => setIsDropdownActive(false))
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="32px"
            left="none"
            right="0px"
            width="150px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper value="Collections" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            setIsDropdownActive(false)
                            setActiveModal('collection')
                        }}
                    >
                        Collection
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            setIsDropdownActive(false)
                            setActiveModal('product')
                        }}
                    >
                        Product
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="Todos" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            setIsDropdownActive(false)
                            setActiveModal('todo')
                        }}
                    >
                        Todo
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}
