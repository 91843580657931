import { gql } from "@apollo/client";

export const UpdateRequestRevisionInfo = gql`
mutation MyMutation($id: bigint!, $request_revision_info: jsonb) {
  update_products_by_pk(pk_columns: {id: $id}, _set: {request_revision_info: $request_revision_info}) {
    request_revision_info
    team_id

  }
}
`