import { NavLink, useLocation } from 'react-router-dom'
import { Box } from '../utility'
import useStore from 'src/store/hooks/useStore'

type IconLinkProps = {
    element: JSX.Element | null
    text: string
    href: string
    subScreens?: string[]
    notSubScreens?: string[]
    onClick?: () => void
    showText?: boolean
}

export const IconLink: React.FunctionComponent<IconLinkProps> = ({
    element,
    text,
    href,
    subScreens,
    notSubScreens,
    onClick,
    showText = true,
}) => {
    const location = useLocation()
    const { clearDrawerTabs, setGlobalSearchActive } = useStore()
    return (
        <NavLink
            className={(nav) => {
                if (
                    subScreens &&
                    text !== 'Settings' &&
                    subScreens.find((link) => location.pathname.includes(link))
                ) {
                    if (notSubScreens && notSubScreens.length) {
                        let found = notSubScreens.find((link) =>
                            location.pathname.includes(link)
                        )
                        if (found) {
                            return 'nav-link inactive'
                        }
                    }
                    return `nav-link active`
                } else {
                    return nav.isActive && text !== 'Settings'
                        ? 'nav-link active'
                        : 'nav-link inactive'
                }
            }}
            onClick={() => {
                clearDrawerTabs()
                setGlobalSearchActive({ isGlobalSearchActive: false })
                onClick && onClick
            }}
            to={onClick ? location.pathname : href}
        >
            <Box>{element}</Box>
            <Box ml={showText ? '0px' : '200px'}>{text}</Box>
        </NavLink>
    )
}
