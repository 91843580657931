import { useLazyQuery } from '@apollo/client'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { DangerModal } from 'src/components/modals/danger-modal'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { ProgressIcons } from 'src/components/progress-icons/progress-icons'
import { DeleteIco } from 'src/components/svg-icons'
import {
    Box,
    Button,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from 'src/components/utility'
import { getImageUrl, getSentenceCase } from 'src/helpers'
import { useRemoveCollaborator } from 'src/screens-v2/organisations-analytics/hooks'
import { GetTeamMembersByTeamId } from 'src/services'
import useStore from 'src/store/hooks/useStore'

export interface ITeamTabDataProps {
    collaborators: ITeamMembers[]
    tabView: boolean
    teamId?: string
}

export default function TeamTabData(props: ITeamTabDataProps) {
    const { tabView = false, teamId } = props
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { removeCollaborator } = useRemoveCollaborator()
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = React.useState<{
        id: string
        name: string
        isOpen: boolean
    }>({
        id: '',
        name: '',
        isOpen: false,
    })
    const [getCollaborators, collaborators] = useLazyQuery(
        GetTeamMembersByTeamId
    )
    const navigate = useNavigate()
    const { clearDrawerTabs } = useStore()
    const [segregatedCollaborators, setSegregatedCollaborators] =
        React.useState<{ [x: string]: IIndividualTeamMembers[] }>({})
    const { drawerTabs, currentTabId } = useStore()

    useEffect(() => {
        // if (!activeTab?.resource?.id) return
        if (params.id || searchParams.get('id')) {
            getCollaborators({
                fetchPolicy: 'no-cache',
                variables: {
                    id: params.id || searchParams.get('id'),
                },
                onCompleted: (data: ITeamMemberDetails) => {
                    setSegregatedCollaborators(
                        data.team_members.reduce((acc, curr) => {
                            if (acc[curr.user.organisation_members[0].role]) {
                                acc[
                                    curr.user.organisation_members[0].role
                                ].push(curr)
                            } else {
                                acc[curr.user.organisation_members[0].role] = [
                                    curr,
                                ]
                            }
                            return acc
                        }, {} as any)
                    )
                },
            })
        }
    }, [])

    useEffect(() => {
        const data = collaborators?.data as ITeamMemberDetails
        if (data?.team_members) {
            setSegregatedCollaborators(
                data?.team_members.reduce((acc, curr) => {
                    if (acc[curr.user?.organisation_members[0]?.role]) {
                        acc[curr.user?.organisation_members[0]?.role].push(curr)
                    } else {
                        acc[curr.user?.organisation_members[0]?.role] = [curr]
                    }
                    return acc
                }, {} as any)
            )
        }
    }, [collaborators])

    if (collaborators.data?.team_members.length <= 0)
        return <EntityEmptyState entityName="Collaborators" />
    return (
        <>
            <Box
                bg="white"
                border="solid"
                borderWidth={1}
                borderColor="gray250"
                borderRadius="8px"
                justifyContent="space-around"
                overflow={'hidden'}
                mt="16px"
            >
                <Table bg="white" fontFamily="Rubik" width="100%">
                    <Thead fontSize={{ md: '12px', lg: '14px' }}>
                        {tabView && (
                            <Tr
                                border="solid"
                                borderRight="none"
                                borderLeft="none"
                                borderWidth={1}
                                borderColor="gray250"
                                py="16px"
                                color="textSecondaryDarkBlue"
                            >
                                <Th fontWeight={400} textAlign="start">
                                    <Text my="0px" fontSize="14px">
                                        Name
                                    </Text>
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    <Text my="0px" fontSize="14px">
                                        Role
                                    </Text>
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    <Text my="0px" fontSize="14px">
                                        To-Do&apos; s
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Thead>
                    <Tbody>
                        {Object.keys(segregatedCollaborators).map((role) => {
                            return (
                                <>
                                    {tabView && (
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontWeight={500}
                                                    color="textSecondaryDarkBlue"
                                                    fontSize="14px"
                                                >
                                                    {getSentenceCase(
                                                        role,
                                                        'NAME'
                                                    )}{' '}
                                                    (
                                                    {
                                                        segregatedCollaborators[
                                                            role
                                                        ].length
                                                    }
                                                    )
                                                </Text>
                                            </Td>
                                        </Tr>
                                    )}
                                    {segregatedCollaborators[role].map(
                                        (collaborator) => {
                                            return (
                                                <Tr
                                                    borderBottom="solid"
                                                    borderWidth={1}
                                                    borderColor="gray250"
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        clearDrawerTabs()
                                                        navigate(
                                                            `/user/${collaborator.user.id}`
                                                        )
                                                    }}
                                                >
                                                    <Td>
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            gridColumnGap="16px"
                                                        >
                                                            <ProfileImg
                                                                size="32px"
                                                                name={
                                                                    collaborator
                                                                        ?.user
                                                                        ?.name
                                                                }
                                                                src={getImageUrl(
                                                                    collaborator
                                                                        ?.user
                                                                        ?.other_data
                                                                        ?.profile_pic ||
                                                                        ''
                                                                )}
                                                            />
                                                            <Text>
                                                                {convertIntoElipses(
                                                                    collaborator
                                                                        ?.user
                                                                        ?.name,
                                                                    20
                                                                )}
                                                            </Text>
                                                        </Box>
                                                    </Td>
                                                    <Td>
                                                        {getSentenceCase(
                                                            collaborator.user
                                                                ?.organisation_members[0]
                                                                ?.role || '',
                                                            'NAME'
                                                        )}
                                                    </Td>
                                                    <Td>
                                                        <ProgressIcons
                                                            completed={
                                                                collaborator
                                                                    .user
                                                                    ?.todosCompleted
                                                                    ?.aggregate
                                                                    .count
                                                            }
                                                            remaining={
                                                                collaborator
                                                                    .user
                                                                    ?.todosDelayed
                                                                    ?.aggregate
                                                                    .count
                                                            }
                                                        />
                                                    </Td>
                                                    <Td>
                                                        {' '}
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setIsDeleteUserModalOpen(
                                                                    {
                                                                        name: collaborator
                                                                            .user
                                                                            .name,
                                                                        id: collaborator
                                                                            .user
                                                                            .id,
                                                                        isOpen: true,
                                                                    }
                                                                )
                                                            }}
                                                            bg="transparent"
                                                            border="none"
                                                            py="0px"
                                                        >
                                                            <DeleteIco />
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            )
                                        }
                                    )}{' '}
                                </>
                            )
                        })}
                    </Tbody>
                </Table>
            </Box>

            <DangerModal
                heading={`Are you sure you want to remove "${isDeleteUserModalOpen?.name}"`}
                isModal={isDeleteUserModalOpen?.isOpen}
                onRemove={() => {
                    teamId &&
                        removeCollaborator(
                            isDeleteUserModalOpen.id,
                            String(teamId)
                        )
                }}
                primaryButtonText="Remove"
                secondaryButtonText="Cancel"
                subHeading="Removing collaborator from team will revoke all the access from ongoing products/collections associated with this team."
                title="Remove Collaborator"
                onClose={() =>
                    setIsDeleteUserModalOpen({
                        id: '',
                        name: '',
                        isOpen: false,
                    })
                }
            />
        </>
    )
}
