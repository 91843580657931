import React, { useEffect, useRef, useState } from 'react'
import { Box, Input, Button } from 'src/components/utility'
import { InputLabel } from 'src/components'
import { CloudUpload } from 'src/components/svg-icons'
import { getImageUrl, useUser } from 'src/helpers'
import { useQuery } from '@apollo/client'
import { GetUserByEmail } from 'src/services'
import { ProfileImg } from 'src/components/profile-img/profile-img'

export const UpdateProfile = ({
    setFile,
    setName,
    name,
    setSteps,
    onUploadFile,
    profileImageURL,
    setProfileImageURL,
}: {
    setFile: (file: Blob, name: string) => void
    setName: (x: string) => void
    name: string
    setSteps: () => void
    onUploadFile: () => void
    profileImageURL: string
    setProfileImageURL: (image: string) => void
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    // const userData = useUser()
    const user = useUser()

    useEffect(() => {
        setName(user.name)
    }, [user])
    const [users, setUser] = useState({
        name: '',
        email: '',
        id: '',
        files: [
            {
                path: '',
            },
        ],
    })
    const { data } = useQuery(GetUserByEmail, {
        skip: user.email.length <= 0,
        variables: {
            email: user.email,
        },
    })
    useEffect(() => {
        setProfileImageURL(getImageUrl(user.profileImage))
    }, [user])

    useEffect(() => {
        if (data && data.users && data.users.length && data.users?.[0]) {
            setUser(data.users[0])
            const normalUser: any = data.users[0]
            // if (
            //     data.users[0] &&
            //     data.users[0].files[0] &&
            //     data.users[0].files[0].path
            // ) {
            //     setProfileImageURL(
            //         getImageUrl(
            //             data.users[0].files[0].path
            //         )
            //     )
            // } else {
            // }
            if (normalUser && normalUser?.other_data?.profile_pic) {
                setProfileImageURL(
                    getImageUrl(normalUser?.other_data?.profile_pic)
                )
            } else {
                setProfileImageURL('')
            }
        }
    }, [data])

    function setImageURL(file: Blob) {
        const objectURL = URL.createObjectURL(file)
        setProfileImageURL(objectURL)
        setFile(file, name)
        onUploadFile()
    }

    function openFileDialogueBox() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }
    return (
        <Box
            px="24px"
            pt="24px"
            pb="52px"
            display="flex"
            flexDirection="column"
            gridRowGap="24px"
        >
            <Input
                autoComplete="false"
                multiple
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    e.target.files && setImageURL(e?.target?.files[0])
                }
                display="none"
                onClick={(e: any) => {
                    e.target.value = null
                }}
                ref={inputRef}
                bg="none"
                accept=".png, .jpg, .jpeg"
            />
            <Box display="flex" gridColumnGap="4px" alignItems="end">
                <ProfileImg
                    size="88px"
                    fontSize="24px"
                    name={user.name}
                    src={profileImageURL}
                ></ProfileImg>
                <Button
                    onClick={openFileDialogueBox}
                    bg="transparent"
                    p="0px"
                    border="none"
                >
                    <CloudUpload />
                </Button>
            </Box>
            <Box>
                <Box display="flex" gridColumnGap="24px">
                    <InputLabel
                        isDisabled={false}
                        isError={false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setName(e.target.value)
                        }
                        value={name}
                        label="Name"
                        placeholder="Name"
                        errorText=""
                    />
                    <InputLabel
                        isDisabled={true}
                        isError={false}
                        onChange={() => {}}
                        label="Role"
                        placeholder={
                            user.role.charAt(0).toUpperCase() +
                            user.role.slice(1)
                        }
                        errorText=""
                    />
                </Box>
                <Button
                    mt="16px"
                    color="links"
                    fontFamily="Rubik"
                    fontSize="14px"
                    bg="transparent"
                    border="none"
                    onClick={setSteps}
                >
                    Change Password
                </Button>
            </Box>
        </Box>
    )
}
