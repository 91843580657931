export function getContrastTextColor(backgroundColor: string): string {
    // Function to calculate the relative luminance of a color
    const calculateRelativeLuminance = (color: string): number => {
        const rgba = parseColor(color)
        const [r, g, b] = rgba.slice(0, 3).map((val) => {
            val /= 255.0
            return val <= 0.03928
                ? val / 12.92
                : Math.pow((val + 0.055) / 1.055, 2.4)
        })

        return 0.2126 * r + 0.7152 * g + 0.0722 * b
    }

    // Function to parse various color formats and return RGBA values
    const parseColor = (color: string): number[] => {
        if (color.startsWith('#')) {
            // Hex format
            const hexRegex =
                /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i
            const match = color.match(hexRegex)
            if (match) {
                return match.slice(1).map((val) => parseInt(val, 16))
            }
        } else if (color.startsWith('rgba(')) {
            // RGBA format
            const rgba = color.match(/\d+(\.\d+)?/g)!.map(Number)
            return rgba.slice(0, 4) // Extract RGBA values
        } else if (color.startsWith('rgb(')) {
            // RGB format
            const rgb = color.match(/\d+/g)!.map(Number)
            return [...rgb, 255] // Add alpha channel as 255 for RGB
        } else {
            // Plain color name
            const div = document.createElement('div')
            div.style.color = color
            document.body.appendChild(div)
            const rgbaColor = getComputedStyle(div).color
            document.body.removeChild(div)

            return rgbaColor.match(/\d+/g)!.map(Number)
        }

        return []
    }

    // Calculate the contrast ratio between the background color and black/white
    const contrastWithBlack = calculateRelativeLuminance(backgroundColor) / 0.05
    const contrastWithWhite =
        (1.05 + 0.05) / calculateRelativeLuminance(backgroundColor)

    // Determine the appropriate text color based on contrast ratio
    const textColor =
        contrastWithBlack > contrastWithWhite ? '#000000' : '#ffffff'

    return textColor
}
