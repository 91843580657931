export function generateInitialVariantName({
    baseProduct,
    variantNames,
}: {
    baseProduct: string
    variantNames?: string[] | undefined
}): string {
    if (!baseProduct || baseProduct === '') {
        return ''
    }
    let highestNumber = 0
    if (!variantNames || variantNames.length === 0) {
        return `${baseProduct}_01`
    }

    variantNames.forEach((name) => {
        const regex = new RegExp(`^${baseProduct}_(\\d+)$`)
        const match = name.match(regex)
        if (match) {
            const number = parseInt(match[1], 10)
            if (number > highestNumber) {
                highestNumber = number
            }
        }
    })

    const newNumber = (highestNumber + 1).toString().padStart(2, '0')
    return `${baseProduct}_${newNumber}`
}
