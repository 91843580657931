import { Divider } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import type { TSearchedTeams } from 'src/components-v2/global-search/@types/search'
import { useRecentSearch } from 'src/components-v2/global-search/hooks/useRecentSearch'
import { ProgressBar } from 'src/components/progress-bar/progress-bar'
import { ProgressIcons } from 'src/components/progress-icons/progress-icons'
import { StackedImage } from 'src/components/stacked-image/stacked-image'
import { Dots } from 'src/components/svg-icons'
import { TeamIcoSm } from 'src/components/svg-icons/team-ico'
import { Box, Button, Text } from 'src/components/utility'
import { copyToClipBoard, getImageUrl } from 'src/helpers'
import useStore from 'src/store/hooks/useStore'
import MeatBallDropdown from '../dropdowns/meatball-dropdown'
import { DangerModal } from 'src/components/modals/danger-modal'
import { useRemoveTeam } from '../hooks/useUpdateTeam'
import { toast } from 'react-toastify'
import type { TGqlTeams } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import RenameTeamModal from 'src/screens-v2/organisations-analytics/modal/rename-team'
import { convertIntoElipses } from 'src/components/list-item/list-item'

interface ITeamCardProps {
    entity: TSearchedTeams | TGqlTeams
    isInTab?: boolean
}

export default function TeamCard(props: ITeamCardProps) {
    const { entity, isInTab = false } = props
    const [isDropdownActive, setIsDropdownActive] = React.useState(false)
    const { globalSearchFilters, setGlobalSearchActive, removeTeam } =
        useStore()
    const { addRecentSearch } = useRecentSearch()
    const [isModalActive, setIsModalActive] = React.useState(false)
    const [isRenameModalActive, setIsRenameModalActive] = React.useState(false)
    const navigate = useNavigate()
    const { clearDrawerTabs } = useStore()
    const { deleteTeam } = useRemoveTeam()

    function onDropdownClick(option: string) {
        switch (option) {
            case 'Delete':
                setIsModalActive(true)
                break
            case 'Rename':
                setIsRenameModalActive(true)
                break
            case 'Copy Link':
                copyToClipBoard(window.location.host + '/team/' + entity.id)
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            default:
                break
        }
    }
    const completionRate =
        'collectionCompletionRate' in entity
            ? entity.collectionCompletionRate
            : (entity.totalCompletedCollectionsOnTime.aggregate.count /
                  entity.totalCompleted?.aggregate.count) *
              100
    return (
        <>
            <Box
                className="cursor-pointer"
                px="16px"
                py="12px"
                bg="white"
                display="grid"
                alignItems="center"
                onClick={() => {
                    clearDrawerTabs()
                    globalSearchFilters.isGlobalSearchActive &&
                        addRecentSearch({
                            entity_name: 'teams',
                            entity_id: entity.id,
                            search_query: globalSearchFilters.query,
                        })
                    setGlobalSearchActive({ isGlobalSearchActive: false })
                    navigate('/team/' + entity.id)
                }}
                gridTemplateColumns={
                    !isInTab ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'
                }
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" gridColumnGap="16px">
                    <Box
                        bg="primary"
                        p="4px"
                        borderRadius="4px"
                        display="flex"
                        alignItems="center"
                        height="max-content"
                    >
                        <TeamIcoSm color="white" />
                        <Text my="0px" color="white" fontSize="10px">
                            Team
                        </Text>
                    </Box>
                    <Text fontSize="14px" my="0px">
                        {props.isInTab
                            ? convertIntoElipses(entity.name, 12)
                            : entity.name}
                    </Text>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="32px"
                >
                    <StackedImage
                        size={
                            !isInTab
                                ? '32px'
                                : window.innerWidth < 1230
                                ? '18px'
                                : '24px'
                        }
                        width="max-content"
                        fontSize={window.innerWidth < 1230 ? '12px' : '16px'}
                        spacing={
                            !isInTab ? 15 : window.innerWidth < 1230 ? 10 : 15
                        }
                        list={entity.team_members.map((member) => {
                            return {
                                name: member?.user?.name,
                                src: getImageUrl(
                                    member.user
                                        ? 'profile_pic' in
                                          member.user?.other_data
                                            ? member.user?.other_data
                                                  ?.profile_pic
                                            : member.user?.other_data?.thumbnail
                                        : ''
                                ),
                            }
                        })}
                    />
                </Box>

                <Box display="flex" alignItems="center" gridColumnGap="16px">
                    <Text my="0px" color="textSecondary" fontSize="12px">
                        {'total_collections' in entity
                            ? entity.total_collections
                            : entity.totalCollections?.aggregate.count}{' '}
                        Collections
                    </Text>
                    <Divider
                        style={{
                            borderRight: '1px solid #E6E7E9',
                            height: '14px',
                        }}
                    />
                    <ProgressIcons
                        completed={
                            'collections' in entity
                                ? entity.collections.complete
                                    ? entity.collections.complete
                                    : 0
                                : entity.totalCompleted?.aggregate.count
                        }
                        remaining={
                            'collections' in entity
                                ? entity.collections.delayed
                                : entity.totalCompletedCollectionsDelayed
                                      ?.aggregate.count
                        }
                    />
                </Box>
                {!isInTab && (
                    <ProgressBar
                        percentage={isNaN(completionRate) ? 0 : completionRate}
                    />
                )}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                >
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            // setTeamDropdown(true)
                            setIsDropdownActive(true)
                        }}
                        bg="white"
                        border="none"
                    >
                        <Dots active={false} />
                    </Button>
                    {isDropdownActive && (
                        <MeatBallDropdown
                            type="team"
                            left="91px"
                            isActive={isDropdownActive}
                            onClick={(option) => onDropdownClick(option)}
                            setIsActive={(isActive) =>
                                setIsDropdownActive(isActive)
                            }
                            onClose={() => setIsDropdownActive(false)}
                        />
                    )}
                </Box>
            </Box>
            <RenameTeamModal
                onUpdate={() => {
                    // console.log(props.refetchData)
                    // props.refetchData && props.refetchData()
                }}
                isModalActive={isRenameModalActive}
                setIsModalActive={(isActive) =>
                    setIsRenameModalActive(isActive)
                }
                team={{
                    id: String(entity.id),
                    name: entity.name,
                }}
            />
            <DangerModal
                heading={`Delete '${entity.name}'`}
                isModal={isModalActive}
                onClose={() => setIsModalActive(false)}
                onRemove={() =>
                    deleteTeam(
                        Number(entity.id),

                        () => {
                            globalSearchFilters.isGlobalSearchActive &&
                                removeTeam({
                                    id: Number(entity.id),
                                })
                        },
                        ['getTeams']
                    )
                }
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                title={`Are you sure you want to delete '${entity.name}'?`}
                subHeading={`Deleting this will remove all the existing To-Do's associated with it. This action cannot be undone.`}
            />
        </>
    )
}
