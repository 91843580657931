import { Box } from '../utility'

export const BasicCard = ({
    children,
    removeBorder,
}: {
    children: JSX.Element
    removeBorder?: boolean
}) => {
    return (
        <Box
            maxWidth={'100%'}
            bg={removeBorder ? 'transparent' : 'white'}
            width="100%"
            height="100%"
            border={removeBorder ? 'none' : 'solid'}
            borderColor={removeBorder ? 'unset' : 'gray250'}
            borderWidth={removeBorder ? 'none' : 1}
            borderRadius={'4px'}
        >
            {children}
        </Box>
    )
}
