import axios from 'axios'
import { useState } from 'react'
import { getLocalStorage } from 'src/helpers'
import {
    ADD_RECENT_SEARCH,
    GET_RECENT_SEARCH,
} from 'src/services/api/endpoints'
import type {
    TSearchedCollaborators,
    TSearchedCollection,
    TSearchedProduct,
    TSearchedTeams,
    TSearchedTodos,
} from '../@types/search'

type TEntityNames =
    | 'library_files'
    | 'folders'
    | 'collections'
    | 'todos'
    | 'products'
    | 'product_variants'
    | 'teams'
    | 'users'

export function useRecentSearch() {
    const [recentSearch, setRecentSearch] = useState<{
        collaborators: TSearchedCollaborators[]
        collections: TSearchedCollection[]
        teams: TSearchedTeams[]
        library_files: FileObject[]
        todos: TSearchedTodos[]
        products: TSearchedProduct[]
    }>({
        collaborators: [],
        collections: [],
        teams: [],
        library_files: [],
        todos: [],
        products: [],
    })

    async function addRecentSearch({
        entity_name,
        entity_id,
        search_query,
    }: {
        entity_name: TEntityNames
        entity_id: string | number
        search_query: string
    }) {
        const recentSearch = await axios.post(
            ADD_RECENT_SEARCH,
            {
                entity_name,
                entity_id,
                search_query,
            },
            {
                headers: {
                    'x-auth-token': `${getLocalStorage('authToken')}`,
                },
            }
        )
    }
    async function fetchRecentSearch() {
        const recentSearch = await axios.get<{
            recent_searches: {
                collaborators: TSearchedCollaborators[]
                collections: TSearchedCollection[]
                teams: TSearchedTeams[]
                library_files: FileObject[]
                todos: TSearchedTodos[]
                products: TSearchedProduct[]
            }
        }>(GET_RECENT_SEARCH, {
            headers: {
                'x-auth-token': `${getLocalStorage('authToken')}`,
            },
        })
        setRecentSearch(recentSearch.data.recent_searches)
    }
    return { addRecentSearch, fetchRecentSearch, recentSearch }
}
