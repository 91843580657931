import { getImageUrl, getLibraryFileUrl } from './get-image-url'

// Overload signature for fallbackPlaceholder = true
export function getThumbnailPicture({
    coverPic,
    parentCoverPic,
    fallbackPlaceholder,
}: {
    coverPic: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    parentCoverPic?: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    fallbackPlaceholder: true
}): string

// Overload signature for fallbackPlaceholder = false or not provided
export function getThumbnailPicture({
    coverPic,
    parentCoverPic,
    fallbackPlaceholder,
}: {
    coverPic: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    parentCoverPic?: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    fallbackPlaceholder?: false
}): string | null

// Implementation
export function getThumbnailPicture({
    coverPic,
    parentCoverPic,
    fallbackPlaceholder = true,
}: {
    coverPic: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    parentCoverPic?: {
        thumbnail: string
        fromLibrary: boolean
    } | null
    fallbackPlaceholder?: boolean
}): string | null {
    if (!coverPic && !parentCoverPic)
        return fallbackPlaceholder ? '/image-placeholder.jpg' : null
    const picToUse = coverPic || parentCoverPic
    if (picToUse) {
        if (picToUse.fromLibrary) {
            return getLibraryFileUrl(picToUse.thumbnail)
        } else {
            return getImageUrl(picToUse.thumbnail)
        }
    }
    return fallbackPlaceholder ? '/image-placeholder.jpg' : null
}

export function getThumbnailPictureFromGid({
    gid,
    fromLibrary,
}: {
    gid: string
    fromLibrary: boolean
}) {
    if (!gid) return '/image-placeholder.jpg'
    return fromLibrary ? getLibraryFileUrl(gid) : getImageUrl(gid)
}
