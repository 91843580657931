import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Checkbox, Modal } from 'src/components'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { CloseIco, SearchIco } from 'src/components/svg-icons'
import { Box, Input, Text } from 'src/components/utility'
import { debounce, debugLog } from 'src/helpers'
import {
    FetchCreators,
    FetchFoldersByCategory,
    FetchLibraryCategories,
    FetchLibraryFilesTags,
} from 'src/services'
import {
    FetchColors,
    FetchCompanies,
    FetchFilesByFolderId,
} from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
;(window as any).global = window
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer

export const FiltersModal = ({
    setModal,
    refetch,
}: {
    setModal: (x: boolean) => void
    refetch: (x: boolean) => void
}) => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const filtersHash = searchParams.get(libraryDefaultKeys.filters) || ''

    const categoryId = searchParams.get(libraryDefaultKeys.categoryId)
    const folderId = params?.folderId

    /// Categories ///
    const [selected, setSelected] = useState<number>(0)
    const [selectedCategory, setSelectedCategory] = useState({
        name: '',
        id: '',
        type: '',
    })
    const [fetchCategories, categoriesData] = useLazyQuery(
        FetchLibraryCategories
    )
    const [fetchFoldersList, foldersListData] = useLazyQuery(
        FetchFoldersByCategory
    )
    const [fetchCreatorsList, creatorsList] = useLazyQuery(FetchCreators)
    const [fetchCompaniesList, companiesList] = useLazyQuery(FetchCompanies)
    const [fetchColorsList, colorsList] = useLazyQuery(FetchColors)
    const [fetchTags, tagsList] = useLazyQuery(FetchLibraryFilesTags)

    const [fetchOnlyFiles] = useLazyQuery(FetchFilesByFolderId)

    const [filterObj, setFilterObj] = useState<any>({
        colors: {},
        companies: {},
        folders: {},
        creators: {},
    })

    const [search, setSearch] = useState<string>('')
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debounceFn = debounce(
        (str: string) => {
            fetchFolders(str)
            getCreators(str)
            getCompanies(str)
            getColors()
            getTags(str)
        },
        200,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        getCreators()
        getCompanies()
        getColors()
        getTags()

        const whereObj: any = {
            deleted_at: {
                _is_null: true,
            },
        }
        if (categoryId && categoryId !== 'all') {
            whereObj['id'] = {
                _eq: categoryId,
            }
        }

        fetchCategories({
            variables: { whereObj },
            fetchPolicy: 'no-cache',
            onError: (error) => {
                debugLog('error')
                debugLog(error)
            },
            onCompleted: (data: any) => {
                const catId =
                    data?.category && data?.category[0]
                        ? data?.category[0]
                        : null
                if (catId && !folderId) {
                    setSelected(0)
                    setSelectedCategory({
                        name: catId.name,
                        id: catId.id,
                        type: 'category',
                    })
                }
            },
        })
    }, [])

    const makeArray = (runObj: any) => {
        const selectedIds = []
        for (const key in runObj) {
            const element = runObj[key]
            if (element) {
                selectedIds.push(key)
            }
        }
        return selectedIds
    }

    function applyFilters() {
        const obj: any = {
            ...filterObj,
            selectedFolders: makeArray(filterObj.folders),
            selectedCreators: makeArray(filterObj.creators),
            selectedCompanies: makeArray(filterObj.companies),
            selectedColors: makeArray(filterObj.colors),
            selectedTags: makeArray(filterObj.tags),
        }

        let encoded = Buffer.from(JSON.stringify(obj)).toString('base64')
        searchParams.set(libraryDefaultKeys.filters, encoded)

        setSearchParams(searchParams)
        toast.success('Filters applied.', {
            className: 'custom-toaster toaster-success',
        })
        refetch(true)
        setModal(false)
    }

    const runSelection = (index: number, cat: any) => {
        setSelected(index)
        setSelectedCategory(cat)
    }

    const getTags = (searchStr: string = '') => {
        let whereObj: any = {
            deleted_at: { _is_null: true },
        }

        if (categoryId && categoryId !== 'all') {
            whereObj['category_id'] = {
                _eq: categoryId,
            }
            whereObj['folder_id'] = {
                _is_null: true,
            }
        }

        if (folderId && folderId !== '0') {
            whereObj['folder_id'] = {
                _eq: folderId,
                _is_null: false,
            }
        }

        if (
            (categoryId && categoryId !== 'all') ||
            (folderId && folderId !== '0')
        ) {
            fetchOnlyFiles({
                variables: {
                    whereObj,
                },
                onCompleted: (data: any) => {
                    const _ids = data.library_files.map((item: any) => item.id)
                    let _whereObj: any = {
                        entity_name: {
                            _eq: 'library_files',
                        },
                        tag: {
                            tag: {
                                _ilike: searchStr ? `%${searchStr}%` : '%%',
                            },
                        },
                    }
                    if (_ids) {
                        _whereObj['entity_id'] = {
                            _in: _ids,
                        }
                    }
                    fetchTags({
                        variables: {
                            whereObj: _whereObj,
                        },
                        fetchPolicy: 'no-cache',
                    })
                },
            })
        } else {
            let _whereObj: any = {
                entity_name: {
                    _eq: 'library_files',
                },
                tag: {
                    tag: {
                        _ilike: searchStr ? `%${searchStr}%` : '%%',
                    },
                },
            }
            fetchTags({
                variables: {
                    whereObj: _whereObj,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }

    const getCreators = (searchStr: string = '') => {
        let whereObj: any = {
            user: {
                name: { _ilike: searchStr ? `%${searchStr}%` : '%%' },
            },
            deleted_at: { _is_null: true },
        }

        if (categoryId && categoryId !== 'all') {
            whereObj['category_id'] = {
                _eq: categoryId,
            }
        }

        if (folderId && folderId !== '0') {
            whereObj['parent_id'] = {
                _eq: folderId,
            }
        }

        let whereFileObj: any = {
            creator: {
                name: { _ilike: searchStr ? `%${searchStr}%` : '%%' },
            },
            file_type: { _eq: 'document' },
            deleted_at: { _is_null: true },
        }

        if (categoryId && categoryId !== 'all') {
            whereFileObj['category_id'] = {
                _eq: categoryId,
            }
            whereFileObj['folder_id'] = {
                _is_null: true,
            }
        }

        if (folderId && folderId !== '0') {
            whereFileObj['folder_id'] = {
                _eq: folderId,
                _is_null: false,
            }
        }

        fetchCreatorsList({
            variables: {
                whereObj,
                whereFileObj,
            },
            fetchPolicy: 'network-only',
        })
    }

    const getCompanies = (searchStr: string = '') => {
        let whereObj: any = {
            company: {
                _ilike: searchStr ? `%${searchStr}%` : '%%',
            },
            deleted_at: { _is_null: true },
        }

        if (categoryId && categoryId !== 'all') {
            whereObj['category_id'] = {
                _eq: categoryId,
            }
        }

        if (folderId && folderId !== '0') {
            whereObj['folder_id'] = {
                _eq: folderId,
            }
        }

        fetchCompaniesList({
            variables: {
                whereObj,
            },
            fetchPolicy: 'no-cache',
        })
    }

    const getColors = () => {
        let whereObj: any = {
            deleted_at: { _is_null: true },
        }

        if (categoryId && categoryId !== 'all') {
            whereObj['category_id'] = {
                _eq: categoryId,
            }
        }

        if (folderId && folderId !== '0') {
            whereObj['folder_id'] = {
                _eq: folderId,
            }
        }

        fetchColorsList({
            variables: {
                whereObj,
            },
            fetchPolicy: 'no-cache',
        })
    }

    const fetchFolders = (searchStr: string = '') => {
        if (
            selectedCategory &&
            selectedCategory?.id &&
            (!selectedCategory?.type || selectedCategory?.type !== 'other')
        ) {
            const common = {
                searchStr: searchStr ? `%${searchStr}%` : '%%',
                name: null,
                created_at: 'desc',
            }

            const folderWhereObj: any = {
                name: { _ilike: common.searchStr },
                parent_id: {
                    _is_null: true,
                },
                deleted_at: { _is_null: true },
                category_id: {
                    _eq: selectedCategory?.id,
                },
            }

            fetchFoldersList({
                variables: {
                    whereObj: folderWhereObj,
                    name: common.name,
                    created_at: common.created_at,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }

    useEffect(() => {
        fetchFolders(search)
    }, [selectedCategory])

    const standardFields = [
        { name: 'Created By', id: 'createdBy', type: 'other' },
        { name: 'Company', id: 'company', type: 'other' },
        { name: 'Colors', id: 'colors', type: 'other' },
        { name: 'Tags', id: 'tags', type: 'other' },
    ]

    const categoryFolders = foldersListData?.data?.folders || []

    const catergoryList =
        (!folderId &&
            categoriesData?.data?.category && [
                ...categoriesData?.data?.category,
                ...standardFields,
            ]) ||
        standardFields

    useEffect(() => {
        if (folderId) {
            setSelectedCategory({
                name: catergoryList[0].name,
                id: catergoryList[0].id,
                type: catergoryList[0].type || 'category',
            })
        }
    }, [folderId])

    const _runChangeCheckBox = (checked: boolean, item: any, type: string) => {
        let newObj: any = { ...filterObj }
        if (!newObj[type]) {
            newObj[type] = {}
        }
        if (type === 'colors') {
            newObj[type][item.color] = checked ? item.color : null
        }
        if (type === 'creators') {
            newObj[type][item.user.id] = checked ? item.user.name : null
        }
        if (type === 'companies') {
            newObj[type][item.company] = checked ? item.company : null
        }
        if (type === 'folders') {
            newObj[type][item.id] = checked ? item.name : null
        }
        if (type === 'tags') {
            newObj[type][item.tag_id] = checked ? item.tag.tag : null
        }
        setFilterObj(newObj)
    }

    const _runAllCheckBox = (checked: boolean, items: any, type: string) => {
        let newObj: any = { ...filterObj }
        if (!newObj[type]) {
            newObj[type] = {}
        }
        if (!newObj['selectAll']) {
            newObj['selectAll'] = {}
        }

        if (type === 'colors') {
            for (const item of items) {
                newObj[type][item.color] = checked ? item.color : null
            }
            newObj['selectAll'][type] = checked
        }
        if (type === 'creators') {
            for (const item of items) {
                newObj[type][item.user.id] = checked ? item.user.name : null
            }
            newObj['selectAll'][type] = checked
        }
        if (type === 'companies') {
            for (const item of items) {
                newObj[type][item.company] = checked ? item.company : null
            }
            newObj['selectAll'][type] = checked
        }
        if (type === 'folders') {
            for (const item of items) {
                newObj[type][item.id] = checked ? item.name : null
            }
            newObj['selectAll'][type] = checked
        }
        if (type === 'tags') {
            for (const item of items) {
                newObj[type][item.tag_id] = checked ? item.tag.tag : null
            }
            newObj['selectAll'][type] = checked
        }
        setFilterObj(newObj)
    }

    const isFilterEnabled = () => {
        return (
            makeArray(filterObj.folders).length ||
            makeArray(filterObj.creators).length ||
            makeArray(filterObj.companies).length ||
            makeArray(filterObj.colors).length ||
            makeArray(filterObj.tags).length
        )
    }

    useEffect(() => {
        setFiltersProperly()
    }, [filtersHash])

    const setFiltersProperly = () => {
        let finalFilters: any = ''
        if (filtersHash) {
            const buff = Buffer.from(filtersHash, 'base64')
            const preFilters = buff.toString('ascii')
            if (preFilters) {
                // console.log("Filters: ", JSON.parse(preFilters));
                finalFilters = JSON.parse(preFilters)
                setFilterObj(finalFilters)
            }
        }
    }

    const clearFilters = () => {
        setFilterObj({})
        runSelection(0, catergoryList[0])
        searchParams.delete(libraryDefaultKeys.filters)
        setSearchParams(searchParams)
    }

    const _type = catergoryList[selected]?.type || 'category'

    let combinedCreators: any[] = []
    if (creatorsList?.data?.folders && creatorsList?.data?.folders?.length) {
        combinedCreators = [...creatorsList.data.folders]
    }
    if (
        creatorsList?.data?.library_files &&
        creatorsList?.data?.library_files?.length
    ) {
        combinedCreators = [
            ...combinedCreators,
            ...creatorsList.data.library_files,
        ]
    }

    combinedCreators = combinedCreators.filter(
        (a: any, i: number) =>
            combinedCreators.findIndex((s) => a.user.id === s.user.id) === i
    )

    return (
        <Modal
            isModal={true}
            onClick={() => applyFilters()}
            onClose={() => setModal(false)}
            primaryButtonTitle="Apply"
            secondaryButtonTitle="Cancel"
            title="Filters"
            width="900px"
            maxWidth="900px"
            disablePrimaryButton={!isFilterEnabled()}
            optionalActionText="Clear filters"
            optionalActionOnClick={() => {
                clearFilters()
            }}
        >
            <Box height="45vh" width="100%" display="flex">
                <Box
                    width="150px"
                    minWidth="150px"
                    minHeight="100%"
                    maxHeight="100%"
                    overflowY={'scroll'}
                    className="scrollbar_none"
                    borderRight="1px solid #C2CFE0"
                    height="100%"
                >
                    <ul className="categoryList">
                        {catergoryList?.map((category: any, index: number) => {
                            return (
                                <>
                                    <li
                                        onClick={() => {
                                            runSelection(index, category)
                                        }}
                                        className={`cursor-pointer ${
                                            selected === index && 'activeList'
                                        }`}
                                        key={'category_' + index + category.id}
                                    >
                                        {category.name}
                                    </li>
                                </>
                            )
                        })}
                    </ul>
                </Box>

                <Box
                    height="100%"
                    px={'32px'}
                    py="24px"
                    width="100%"
                    className="scrollbar_none"
                    overflowY={'scroll'}
                >
                    <Box width="550px" mb={'24px'} position="relative">
                        <Box
                            position="absolute"
                            display="flex"
                            top="10px"
                            left="12px"
                        >
                            <SearchIco color="#778CA2" />
                        </Box>
                        <Input
                            autoComplete="false"
                            placeholder="Search by name"
                            bg="white"
                            borderRadius="4px"
                            px="48px"
                            py="12px"
                            fontSize="14px"
                            border="solid"
                            borderWidth={1}
                            borderColor="gray250"
                            className="placeholder-light"
                            type="text"
                            width="550px"
                            required
                            value={search}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setSearch(e.target.value)
                                debounceFn(e.target.value)
                            }}
                        />
                        {search && (
                            <Box
                                onClick={() => {
                                    setSearch('')
                                    debounceFn('')
                                }}
                                className="pointer"
                                display="flex"
                                position="absolute"
                                top="13px"
                                right="14px"
                            >
                                <CloseIco color="#778CA2" />
                            </Box>
                        )}
                    </Box>

                    <Box display="flex" gridRowGap="24px" flexWrap="wrap">
                        {_type === 'category' &&
                        categoryFolders &&
                        !folderId ? (
                            <>
                                {categoryFolders &&
                                categoryFolders?.length &&
                                !foldersListData.loading ? (
                                    <>
                                        {categoryFolders?.map((item: any) => {
                                            return (
                                                <Box
                                                    width="32%"
                                                    minWidth="32%"
                                                    key={'folderCate' + item.id}
                                                >
                                                    <Checkbox
                                                        defautlCheck={
                                                            filterObj
                                                                ?.folders?.[
                                                                item.id
                                                            ] || false
                                                        }
                                                        onChange={(
                                                            x: boolean
                                                        ) => {
                                                            _runChangeCheckBox(
                                                                x,
                                                                item,
                                                                'folders'
                                                            )
                                                        }}
                                                        label={convertIntoElipses(
                                                            item.name,
                                                            10
                                                        )}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {!foldersListData.loading && (
                                            <Box
                                                display="flex"
                                                py={'24px'}
                                                width={'100%'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    my={'0px'}
                                                    textAlign="center"
                                                >
                                                    {'No folders.'}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}

                        {_type &&
                        _type === 'other' &&
                        selectedCategory.id === 'createdBy' &&
                        creatorsList &&
                        combinedCreators ? (
                            <>
                                {combinedCreators &&
                                combinedCreators?.length ? (
                                    <>
                                        <Box
                                            width="32%"
                                            minWidth="32%"
                                            key={'combinedCreatorsAll'}
                                        >
                                            <Checkbox
                                                defautlCheck={
                                                    filterObj?.selectAll
                                                        ?.creators || false
                                                }
                                                onChange={(x: boolean) => {
                                                    _runAllCheckBox(
                                                        x,
                                                        combinedCreators,
                                                        'creators'
                                                    )
                                                }}
                                                label={'Select all'}
                                            />
                                        </Box>
                                        {combinedCreators?.map(
                                            (creator: any) => {
                                                return (
                                                    <Box
                                                        width="32%"
                                                        minWidth="32%"
                                                        key={
                                                            'creator' +
                                                            creator.user.id
                                                        }
                                                    >
                                                        <Checkbox
                                                            defautlCheck={
                                                                filterObj
                                                                    ?.creators?.[
                                                                    creator.user
                                                                        .id
                                                                ] || false
                                                            }
                                                            onChange={(
                                                                x: boolean
                                                            ) => {
                                                                _runChangeCheckBox(
                                                                    x,
                                                                    creator,
                                                                    'creators'
                                                                )
                                                            }}
                                                            label={convertIntoElipses(
                                                                creator.user
                                                                    .name,
                                                                20
                                                            )}
                                                        />
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {!creatorsList.loading && (
                                            <Box
                                                display="flex"
                                                py={'24px'}
                                                width={'100%'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    my={'0px'}
                                                    textAlign="center"
                                                >
                                                    {'No data'}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}

                        {_type &&
                        _type === 'other' &&
                        selectedCategory.id === 'colors' &&
                        colorsList ? (
                            <>
                                {colorsList?.data?.library_files &&
                                colorsList?.data?.library_files?.length ? (
                                    <>
                                        <Box
                                            width="32%"
                                            minWidth="32%"
                                            key={'colorall'}
                                        >
                                            <Checkbox
                                                defautlCheck={
                                                    filterObj?.selectAll
                                                        ?.colors || false
                                                }
                                                onChange={(x: boolean) => {
                                                    _runAllCheckBox(
                                                        x,
                                                        colorsList?.data
                                                            ?.library_files,
                                                        'colors'
                                                    )
                                                }}
                                                label={'Select all'}
                                            />
                                        </Box>

                                        {colorsList?.data?.library_files?.map(
                                            (libraryFile: any) => {
                                                const colors =
                                                    typeof libraryFile.color ===
                                                    'string'
                                                        ? JSON.parse(
                                                              libraryFile.color
                                                          )
                                                        : libraryFile.color
                                                return colors.map(
                                                    (color: any) => {
                                                        const {
                                                            id,
                                                            color: colorName,
                                                        } = color
                                                        const isChecked =
                                                            filterObj?.colors?.[
                                                                colorName
                                                            ] || false

                                                        return (
                                                            <Box
                                                                width="32%"
                                                                minWidth="32%"
                                                                key={
                                                                    'color' + id
                                                                }
                                                            >
                                                                <Checkbox
                                                                    defautlCheck={
                                                                        isChecked
                                                                    }
                                                                    onChange={(
                                                                        isChecked: boolean
                                                                    ) => {
                                                                        _runChangeCheckBox(
                                                                            isChecked,
                                                                            color,
                                                                            'colors'
                                                                        )
                                                                    }}
                                                                    label={
                                                                        colorName
                                                                    }
                                                                />
                                                            </Box>
                                                        )
                                                    }
                                                )
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {!colorsList.loading && (
                                            <Box
                                                display="flex"
                                                py={'24px'}
                                                width={'100%'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    my={'0px'}
                                                    textAlign="center"
                                                >
                                                    {'No data'}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}

                        {/* {search ? "No search results" : "No data"} */}
                        {_type &&
                        _type === 'other' &&
                        selectedCategory.id === 'company' &&
                        companiesList ? (
                            <>
                                {companiesList?.data?.library_files &&
                                companiesList?.data?.library_files?.length ? (
                                    <>
                                        <Box
                                            width="32%"
                                            minWidth="32%"
                                            key={'companyAll'}
                                        >
                                            <Checkbox
                                                defautlCheck={
                                                    filterObj?.selectAll
                                                        ?.companies || false
                                                }
                                                onChange={(x: boolean) => {
                                                    _runAllCheckBox(
                                                        x,
                                                        companiesList?.data
                                                            ?.library_files,
                                                        'companies'
                                                    )
                                                }}
                                                label={'Select all'}
                                            />
                                        </Box>
                                        {companiesList?.data?.library_files?.map(
                                            (company: any) => {
                                                return (
                                                    <Box
                                                        width="32%"
                                                        minWidth="32%"
                                                        key={
                                                            'company' +
                                                            company.id
                                                        }
                                                    >
                                                        <Checkbox
                                                            defautlCheck={
                                                                filterObj
                                                                    ?.companies?.[
                                                                    company
                                                                        .company
                                                                ] || false
                                                            }
                                                            onChange={(
                                                                x: boolean
                                                            ) => {
                                                                _runChangeCheckBox(
                                                                    x,
                                                                    company,
                                                                    'companies'
                                                                )
                                                            }}
                                                            label={convertIntoElipses(
                                                                company.company,
                                                                20
                                                            )}
                                                        />
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {!companiesList.loading && (
                                            <Box
                                                display="flex"
                                                py={'24px'}
                                                width={'100%'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    my={'0px'}
                                                    textAlign="center"
                                                >
                                                    {'No data'}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}

                        {_type &&
                        _type === 'other' &&
                        selectedCategory.id === 'tags' &&
                        tagsList ? (
                            <>
                                {tagsList?.data?.tag_relationships &&
                                tagsList?.data?.tag_relationships?.length ? (
                                    <>
                                        <Box
                                            width="32%"
                                            minWidth="32%"
                                            key={'tagsAll'}
                                        >
                                            <Checkbox
                                                defautlCheck={
                                                    filterObj?.selectAll
                                                        ?.tags || false
                                                }
                                                onChange={(x: boolean) => {
                                                    _runAllCheckBox(
                                                        x,
                                                        tagsList?.data
                                                            ?.tag_relationships,
                                                        'tags'
                                                    )
                                                }}
                                                label={'Select all'}
                                            />
                                        </Box>
                                        {tagsList?.data?.tag_relationships?.map(
                                            (_tag: any) => {
                                                return (
                                                    <Box
                                                        width="32%"
                                                        minWidth="32%"
                                                        key={
                                                            'tag2' + _tag.tag_id
                                                        }
                                                    >
                                                        <Checkbox
                                                            defautlCheck={
                                                                filterObj
                                                                    ?.tags?.[
                                                                    _tag.tag_id
                                                                ] || false
                                                            }
                                                            onChange={(
                                                                x: boolean
                                                            ) => {
                                                                _runChangeCheckBox(
                                                                    x,
                                                                    _tag,
                                                                    'tags'
                                                                )
                                                            }}
                                                            checkBoxId={
                                                                'tag2' +
                                                                _tag.tag.id
                                                            }
                                                            label={convertIntoElipses(
                                                                _tag.tag.tag,
                                                                20
                                                            )}
                                                        />
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {!tagsList.loading && (
                                            <Box
                                                display="flex"
                                                py={'24px'}
                                                width={'100%'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    my={'0px'}
                                                    textAlign="center"
                                                >
                                                    {'No data'}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
