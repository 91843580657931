export const NoTeamsIcon = () => (
    <svg
        width="56"
        height="32"
        viewBox="0 0 56 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M55.5051 25.3606C55.5015 25.3588 55.4979 25.3568 55.4943 25.3549C55.0344 25.1161 54.4679 25.2953 54.2291 25.7554C54.2285 25.7564 54.228 25.7575 54.2274 25.7584L52.6509 28.7895C44.9184 20.5502 40.414 7.206 39.9354 5.72331V1.68814C39.9354 1.1699 39.5152 0.749725 38.997 0.749725C38.4788 0.749725 38.0586 1.1699 38.0586 1.68814V4.93504H17.1601V1.71629C17.1601 1.19805 16.74 0.777877 16.2217 0.777877C15.7035 0.777877 15.2833 1.19805 15.2833 1.71629V5.72331C14.7203 7.34676 9.09918 22.9432 2.68983 28.7895L1.82649 26.3215C1.65546 25.8317 1.11975 25.5733 0.630012 25.7443C0.140278 25.9154 -0.118137 26.4511 0.0528887 26.9408L1.35728 30.6945C1.37347 30.7332 1.39236 30.7708 1.41359 30.8071C1.43458 30.856 1.45969 30.9031 1.48866 30.9478C1.54074 30.9913 1.5974 31.0291 1.65757 31.0604L1.75142 31.1543C1.80479 31.1881 1.86133 31.2163 1.92033 31.2387H2.01417C2.11059 31.2539 2.20866 31.2539 2.30508 31.2387H52.8949C52.979 31.2502 53.0642 31.2502 53.1483 31.2387H53.2327L53.3829 31.1637L53.4673 31.098L53.5799 30.9947L53.6456 30.9009L53.7113 30.8164L55.8884 26.6311C56.1334 26.1745 55.9617 25.6057 55.5051 25.3606ZM25.0897 28.9116L23.8323 26.0964C23.6224 25.6221 23.0678 25.4079 22.5936 25.6178C22.1193 25.8276 21.9051 26.3822 22.115 26.8565L23.2692 29.4559H4.75433C10.7883 23.1967 15.7713 9.9369 16.888 6.83075H37.8146C36.285 10.6407 30.5419 24.2852 25.0897 28.9116ZM38.0586 29.4371H27.2668C31.5835 25.0641 35.7126 16.4589 38.0586 11.1099V29.4371ZM39.9354 29.4371V11.1474C41.9436 16.0835 45.5659 23.7034 50.699 29.4371H39.9354Z"
            fill="#778CA2"
        />
    </svg>
)
