import axios from 'axios'
import { useState } from 'react'
import { getFileRequestId, getLocalStorage, uploadFiles } from 'src/helpers'
import type { TUploadResponse } from 'src/helpers/file-operations'
import { ASSIGN_FILE_FROM_FILES } from 'src/services/api/endpoints'

type TFiles = {
    subTodoId?: string | null
    files: { name: string; path: string; blob: Blob; id: string }[]
}
type Ttype =
    | 'attachments'
    | 'profile_pic'
    | 'cover_pic'
    | 'reference_pic'
    | 'document'

/**
 * STRUCTURE CHANGE
 * The files gets uploaded as soon as it enters the modal
 * and the using the ASSIGN API the file is assigned to its respective section
 */

export function useUploadFile(entity_id: string, entityName: string) {
    const [isUploading, setIsUploading] = useState(false)

    async function onFileUpload(
        files: TFiles,
        type: Ttype,
        onProgress: (progress: number, filePath: string) => void
    ) {
        const uploadFilePromiseArr = []
        try {
            setIsUploading(true)
            const { request_id } = await getFileRequestId({
                action: 'INSERT',
                entity_name: entityName,
                entity_id,
                number_of_files: files.files.length,
            })

            for (const file of files.files) {
                uploadFilePromiseArr.push(async () => {
                    const data: { data: TUploadResponse } = await uploadFiles(
                        file.blob,
                        entity_id,
                        file.path,
                        entityName,
                        type,
                        (progress) => {
                            onProgress(
                                (progress.loaded / progress.total) * 100,
                                file.path
                            )
                        },
                        request_id
                    )
                    return {
                        ...data,
                        path: file.path,
                    }
                })
            }
            const resolvedArr = await Promise.all(
                uploadFilePromiseArr.map(async (file) => {
                    const data = await file()
                    return data
                }) as unknown as TUploadResponse[]
            )
            setIsUploading(false)
            return {
                resolvedArr: resolvedArr.map((item) => ({
                    id: item.id,
                    path: item.path,
                    thumbnail: item.thumbnail,
                })),
            }
        } catch (e) {
            setIsUploading(false)
        } finally {
        }
    }

    return { onFileUpload, isUploading }
}

export function useAssignFile(afterUpload?: () => void) {
    async function assignFilesToSection({
        entityId,
        entityName,
        assignedAs,
        fileId,
    }: {
        entityId: string
        entityName: string
        assignedAs: 'cover_pic' | 'attachment' | 'reference_pic' | 'profile_pic'
        fileId: string[]
    }) {
        try {
            const data = await axios.post(
                ASSIGN_FILE_FROM_FILES,
                {
                    action: 'INSERT',
                    entity_id: entityId,
                    entity_name: entityName,
                    assigned_as: assignedAs,
                    file_ids: fileId,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            afterUpload && afterUpload()
            return data
        } catch (e) {
            console.log(e)
        }
    }
    return { assignFilesToSection }
}
