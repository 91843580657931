export function scrollToCard(cardId: string) {
    const card = document.getElementById(cardId) as HTMLElement
    if (card) {
        // find the card in the parentContainer and scroll to it
        const parentContainer = card.parentElement as HTMLElement
        if (parentContainer) {
            const index = Array.from(parentContainer.children).findIndex(
                (child) => child === card
            )
            // If the comment is not the latest, scroll to the card but showing half of previous card too
            if (index > 0) {
                const scrollToPosition =
                    card.offsetTop -
                    parentContainer.offsetTop -
                    card.offsetHeight / 2
                parentContainer.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth',
                })
            } else {
                const scrollToPosition =
                    card.offsetTop - parentContainer.offsetTop
                parentContainer.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth',
                })
            }
        }
    }
}
