import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import * as React from 'react'
import { Cross, StrokedArrow } from 'src/components/svg-icons'
import { Box, Button, Image, Input, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import {
    addGraphic,
    calculateCurrentUv,
    createBoundingBox,
} from '../graphic/helpers'
import { debounce, uuid } from 'src/helpers'
import ColorPanel from '../color/color-panel'
import { checkColorFormat } from 'src/components/color-picker/color-picker.helper'
import { createMaterial } from '../../helper/create-material'
import { ConfigIco } from 'src/components/svg-icons/3d-renderer/actions'
import ControlPanel from './control-panel'
import type { MeshCustomMaterial } from '../material/custom-material'
import { DivisionLine } from 'src/components/division-line/division-line'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { useSearchParams } from 'react-router-dom'
import { Checkbox } from 'src/components/checkbox/checkbox'

export interface IBasicFunctionsProps {}

const AccordionOrder: {
    label: string
    key: 'colors' | 'fabrics' | 'artwork' | 'graphics'
}[] = [
    { label: 'Color', key: 'colors' },
    { label: 'Fabric', key: 'fabrics' },
    { label: 'Artwork', key: 'artwork' },
    { label: 'Graphic', key: 'graphics' },
]

export default function BasicFunctions(props: IBasicFunctionsProps) {
    const {
        graphic,
        highlights,
        setGraphic,
        setRecentlyUsed,
        recentlyUsed,
        setArtwork,
        activeArtWork,
        setActiveArtwork,
        setAdvancedMode,
    } = useConfiguratorContext()

    const [isColorPopOverActive, setIsColorPopOverActive] =
        React.useState(false)

    const [isEditMode, setIsEditMode] = React.useState(false)
    const [selectedForDelete, setSelectedForDelete] = React.useState<string[]>(
        []
    )
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeControlPanel, setActiveControlPanel] = React.useState<{
        isActive: boolean
        mode: 'artwork' | 'fabrics' | 'graphics'
        id?: string
    }>({
        isActive: false,
        mode: 'artwork',
        id: undefined,
    })

    async function uploadGraphic(
        file: File,
        graphicConfig?: TMapConfig,
        addNew: boolean = true
    ) {
        const selectedMaterial = highlights[0]
        // Calculate UV
        const UV = calculateCurrentUv(selectedMaterial.mesh)
        const box = createBoundingBox(selectedMaterial.mesh)

        if (file && box) {
            const url = getLocalImageUrl(file)
            if (url) {
                const config = await addGraphic(
                    box,
                    selectedMaterial.mesh,
                    UV,
                    url
                )
                const scaleAndOffset = graphicConfig?.config ||
                    config || {
                        offset: {
                            u:
                                selectedMaterial.mesh.material?.map?.offset
                                    ?.x || 0,
                            v:
                                selectedMaterial.mesh.material?.map?.offset
                                    ?.y || 0,
                        },
                        repeat: {
                            u:
                                selectedMaterial.mesh.material?.map?.repeat
                                    ?.x || 0,
                            v:
                                selectedMaterial.mesh.material?.map?.repeat
                                    ?.y || 0,
                        },
                    }

                const graphicId = graphicConfig?.id || uuid()
                if (addNew) {
                    setRecentlyUsed((recentlyUsed) => ({
                        ...recentlyUsed,
                        graphics: [
                            ...recentlyUsed.graphics,
                            {
                                blob: new Blob([file]),
                                id: graphicId,
                                name: file.name,
                                path: file.webkitRelativePath,
                                config: {
                                    ...scaleAndOffset,
                                    rotation:
                                        graphicConfig?.config.rotation ||
                                        selectedMaterial.mesh.material?.map
                                            ?.rotation ||
                                        0,
                                    transparency:
                                        graphicConfig?.config.transparency || 0,
                                    substanceNess:
                                        graphicConfig?.config.substanceNess ||
                                        1,
                                },
                            },
                        ],
                    }))
                }

                setGraphic({
                    blob: new Blob([file]),
                    id: graphicId,
                    name: file.name,
                    path: file.webkitRelativePath,
                    config: {
                        ...scaleAndOffset,
                        rotation:
                            selectedMaterial.mesh.material?.map?.rotation || 0,
                        transparency: 0,
                        substanceNess: 1,
                    },
                })
            }
        }
    }

    function uploadArtwork(
        file: File | TMapConfig,
        addNewArtwork: boolean = true
    ) {
        const material = highlights[0].mesh.material as MeshCustomMaterial
        if (!file) return
        const artWorkId = 'id' in file ? file?.id : uuid()
        let artWork: TMapConfig = {
            blob: 'blob' in file ? file.blob : new Blob([file]),
            id: artWorkId,
            name: file.name,
            path:
                'webkitRelativePath' in file
                    ? file.webkitRelativePath
                    : file.path,
            config: {
                repeat: {
                    u: material.map?.repeat.x || 1,
                    v: material.map?.repeat.y || 1,
                },
                offset: {
                    u: material.map?.offset.x || 0,
                    v: material.map?.offset.y || 0,
                },
                rotation: material.map?.rotation || 0,
                substanceNess: 0,
                transparency: 0,
            },
        }

        const fileData =
            'blob' in file ? new File([file.blob], file.name) : file

        setActiveArtwork(artWork)
        if (addNewArtwork) {
            setArtwork((previousArtwork) => [...previousArtwork, artWork])
        }
        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = createMaterial(
                    getLocalImageUrl(fileData) || '',
                    {
                        offset: {
                            u: highlight.mesh.material.map?.offset?.x || 0,
                            v: highlight.mesh.material.map?.offset?.y || 0,
                        },
                        scale: {
                            u: highlight.mesh.material?.map?.repeat?.x || 0,
                            v: highlight.mesh.material?.map?.repeat?.y || 0,
                        },
                    }
                ).upscale
            }
        })
    }
    async function onUpload(
        e: React.ChangeEvent<HTMLInputElement>,
        key: 'colors' | 'fabrics' | 'artwork' | 'graphics'
    ) {
        if (!e.target.files) return
        const file = e.target.files[0]

        if (key === 'graphics') {
            uploadGraphic(file)
        }
        if (key === 'artwork') {
            uploadArtwork(file)
        }
    }

    function onColorDeleteClick(selectedArray: string[]) {
        const existingColorArray = recentlyUsed.colors
        const selectedArraySet = new Set(selectedArray.map((color) => color))
        const result = existingColorArray.filter(
            (item) => !selectedArraySet.has(item.color)
        )
        const material = highlights[0].mesh.material as MeshCustomMaterial
        material.color.setHex(0xffffff)
        setRecentlyUsed((recentlyUsed) => ({
            ...recentlyUsed,
            colors: result,
        }))
    }

    function handleFabricUpdate(fabric: TFabric) {
        highlights.map((highlight) => {
            if (highlight.isActive) {
                fabric?.maps.map((map) => {
                    highlight.mesh.material[map.code] = createMaterial(
                        map.map,
                        {
                            offset: {
                                u:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.y || 0,
                            },
                            scale: {
                                u:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.y || 0,
                            },
                        }
                    ).upscale
                })
            }
        })
    }

    return (
        <>
            <Box>
                {AccordionOrder.map((section, key) => {
                    console.log({ key, len: AccordionOrder.length - 1 })
                    return (
                        <>
                            <Accordion
                                style={{
                                    marginBottom: '0px',
                                    marginTop: '0px',
                                    boxShadow: 'none',
                                    // padding: '24px 0px',
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <Box className="vertical">
                                            <StrokedArrow />
                                        </Box>
                                    }
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                        pr="16px"
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="8px"
                                        >
                                            <Text my="0px" fontSize="12px">
                                                {section.label}
                                            </Text>
                                            {section.key === 'fabrics' && (
                                                <Button
                                                    border="none"
                                                    color="links"
                                                    p="0px"
                                                    bg="transparent"
                                                    display="flex"
                                                    alignItems="center"
                                                    gridColumnGap="8px"
                                                    fontSize="10px"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setAdvancedMode(true)
                                                        searchParams.set(
                                                            'configMode',
                                                            'Fabric'
                                                        )
                                                        setSearchParams(
                                                            searchParams
                                                        )
                                                    }}
                                                >
                                                    + Add Custom Fabric
                                                </Button>
                                            )}
                                        </Box>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="8px"
                                        >
                                            {section.key === 'colors' &&
                                                !isEditMode && (
                                                    <Button
                                                        color="links"
                                                        fontSize="10px"
                                                        p="0px"
                                                        border="none"
                                                        bg="transparent"
                                                        disabled={
                                                            recentlyUsed.colors
                                                                .length <= 0
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setIsEditMode(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                )}
                                            {section.key === 'colors' &&
                                                isEditMode && (
                                                    <Box
                                                        display="flex"
                                                        gridColumnGap="8px"
                                                        alignItems="center"
                                                    >
                                                        <Button
                                                            color="links"
                                                            fontSize="10px"
                                                            p="0px"
                                                            border="none"
                                                            bg="transparent"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setIsEditMode(
                                                                    false
                                                                )
                                                                onColorDeleteClick(
                                                                    selectedForDelete
                                                                )
                                                            }}
                                                        >
                                                            Done
                                                        </Button>
                                                        <Button
                                                            color="textSecondary"
                                                            fontSize="10px"
                                                            p="0px"
                                                            border="none"
                                                            bg="transparent"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setIsEditMode(
                                                                    false
                                                                )
                                                                setSelectedForDelete(
                                                                    []
                                                                )
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                )}
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Recents
                                        onAdjustmentClick={(id, isActive) => {
                                            if (section.key === 'colors') return
                                            setActiveControlPanel({
                                                isActive,
                                                id,
                                                mode: section.key,
                                            })
                                        }}
                                        selectedForDelete={selectedForDelete}
                                        setSelectedForDelete={(color) => {
                                            if (Array.isArray(color)) {
                                                setSelectedForDelete(color)
                                            } else {
                                                setSelectedForDelete(
                                                    (selectedForDelete) => [
                                                        ...selectedForDelete,
                                                        color,
                                                    ]
                                                )
                                            }
                                        }}
                                        isEditMode={isEditMode}
                                        isColorPopOverActive={
                                            isColorPopOverActive
                                        }
                                        id={key}
                                        onUpload={onUpload}
                                        setIsColorPopOverActive={(isActive) => {
                                            console.log({ isActive })
                                            setIsColorPopOverActive(isActive)
                                        }}
                                        onTileClick={(data) => {
                                            if (
                                                section.key === 'fabrics' &&
                                                'baseMap' in data
                                            ) {
                                                handleFabricUpdate(data)
                                            } else if (
                                                section.key === 'graphics' &&
                                                'config' in data &&
                                                !(
                                                    'roughnessIntensity' in
                                                    data.config
                                                ) &&
                                                !('baseMap' in data)
                                            ) {
                                                uploadGraphic(
                                                    new File(
                                                        [data.blob],
                                                        data.name
                                                    ),
                                                    data,
                                                    false
                                                )
                                            } else if ('config' in data) {
                                                uploadArtwork(data, false)
                                            }
                                        }}
                                        recentKey={section.key}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            {key < AccordionOrder.length - 1 && (
                                <Box px="12px">
                                    <DivisionLine color="gray350" />
                                </Box>
                            )}
                        </>
                    )
                })}
                <DivisionLine />
                <Box
                    width="100%"
                    px="16px"
                    justifyContent="end"
                    display="flex"
                    mt="12px"
                >
                    <Button
                        border="none"
                        color="links"
                        p="0px"
                        bg="transparent"
                        display="flex"
                        alignItems="center"
                        gridColumnGap="8px"
                        fontSize="12px"
                        onClick={() => {
                            searchParams.delete('configMode')
                            setSearchParams(searchParams)
                            setAdvancedMode(true)
                        }}
                    >
                        View Advanced Options <RightArrow color="#027AC3" />
                    </Button>
                </Box>
            </Box>
            <ControlPanel
                mode={activeControlPanel.mode}
                entity={{ id: activeControlPanel.id }}
                setIsControlPanelActive={(isActive) =>
                    setActiveControlPanel({
                        id: undefined,
                        mode: 'artwork',
                        isActive,
                    })
                }
                isControlPanelActive={activeControlPanel.isActive}
            />
        </>
    )
}

function Recents({
    recentKey,
    onTileClick,
    id,
    setIsColorPopOverActive,
    isColorPopOverActive,
    onUpload,
    isEditMode,
    selectedForDelete,
    setSelectedForDelete,
    onAdjustmentClick,
}: {
    recentKey: 'graphics' | 'colors' | 'fabrics' | 'artwork'
    isEditMode: boolean
    onTileClick: (config: TMapConfig | TFabric) => void
    id: number
    setIsColorPopOverActive: (val: boolean) => void
    isColorPopOverActive: boolean
    onUpload: (
        e: React.ChangeEvent<HTMLInputElement>,
        key: 'graphics' | 'colors' | 'fabrics' | 'artwork'
    ) => void
    selectedForDelete: string[]
    setSelectedForDelete: (color: string | string[]) => void
    onAdjustmentClick: (id: string, isActive: boolean) => void
}) {
    const {
        recentlyUsed,
        setRecentlyUsed,
        graphic,
        selectedColor,
        activeArtWork,
        setArtwork,
        setActiveArtwork,
        setSelectedColor,
        highlights,
        artWork,
        selectedFabric,
        setSelectedFabric,
    } = useConfiguratorContext()

    const inputRefs = AccordionOrder.map(() =>
        React.createRef<HTMLInputElement>()
    )
    const [isColorEdit, setIsColorEdit] = React.useState(false)

    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()
    const colorDebounce = debounce(
        handleOnColorChange,
        500,
        setTimeOutRef,
        timeOutRef
    )

    function handleOnColorChange(
        color: string,
        addRecent: boolean = true,
        isEdit: boolean = false,
        id?: string
    ) {
        if (color) {
            const colorId = uuid()
            if (addRecent && !isEdit) {
                setRecentlyUsed((recentlyUsed) => {
                    return {
                        ...recentlyUsed,
                        colors: [
                            ...recentlyUsed.colors,
                            { color, id: colorId },
                        ],
                    }
                })
            }
            if (isEdit && id) {
                setRecentlyUsed((recentlyUsed) => {
                    return {
                        ...recentlyUsed,
                        colors: recentlyUsed.colors.map((usedColor) => {
                            if (usedColor.id === id) {
                                return {
                                    ...usedColor,
                                    color: color,
                                }
                            }
                            return usedColor
                        }),
                    }
                })
            }
            highlights.forEach((highlight) => {
                if (highlight.mesh.material?.color && highlight.isActive)
                    return highlight.mesh.material?.color?.setHex(
                        color.replace('#', '0x')
                    )
            })
            console.log({ color, id: isEdit && id ? id : colorId })
            setSelectedColor({ color, id: isEdit && id ? id : colorId })
        }
    }

    return (
        <Box
            display="grid"
            // flexWrap="wrap"
            // maxWidth="320px"
            gridTemplateColumns={'repeat(auto-fill, minmax(50px, auto))'}
            gridTemplateRows={'auto'}
            gridColumnGap="12px"
            gridRowGap="8px"
        >
            {recentKey !== 'fabrics' && (
                <>
                    <Button
                        type="button"
                        className="dashed__border"
                        bg="transparent"
                        border="none"
                        width="48px"
                        height="48px"
                        id="add-files-dropdown-button"
                        onClick={() => {
                            if (recentKey !== 'colors') {
                                inputRefs[id].current?.click()
                            } else {
                                setIsColorEdit(false)
                                setIsColorPopOverActive(true)
                            }
                        }}
                    >
                        <PlusIcoSm />
                    </Button>
                    <Input
                        autoComplete="false"
                        type="file"
                        ref={inputRefs[id]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onUpload(e, recentKey)
                        }
                        onClick={(e: any) => {
                            e.target.value = null
                        }}
                        display="none"
                        bg="none"
                    />
                </>
            )}

            {recentKey === 'graphics' &&
                recentlyUsed.graphics.map((usedGraphic) => {
                    return (
                        <Box
                            position="relative"
                            onClick={() =>
                                usedGraphic.id !== graphic?.id &&
                                onTileClick(usedGraphic)
                            }
                            className="cursor-pointer"
                            width="50px"
                            height="50px"
                            borderRadius="4px"
                            overflow="hidden"
                        >
                            <Image
                                width="50px"
                                height="50px"
                                src={getLocalImageUrl(
                                    new File(
                                        [usedGraphic.blob],
                                        usedGraphic.name
                                    )
                                )}
                            />

                            {graphic?.id === usedGraphic.id && (
                                <Box
                                    position="absolute"
                                    display="flex"
                                    top="0px"
                                    width="100%"
                                    height="100%"
                                    bg="#00000050"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="4px"
                                >
                                    <Button
                                        bg="transparent"
                                        onClick={() => {
                                            onAdjustmentClick(
                                                usedGraphic.id,
                                                true
                                            )
                                        }}
                                        border="none"
                                    >
                                        <ConfigIco color="#fff" />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )
                })}

            {recentKey === 'colors' && (
                <>
                    {recentlyUsed.colors.map((usedColor) => {
                        const selectedForDeleteMap =
                            selectedForDelete.length > 0
                                ? new Set(selectedForDelete)
                                : new Set()
                        const isSelectedForDelete = selectedForDeleteMap.has(
                            usedColor.color
                        )
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                gridRow="4px"
                                borderRadius="4px"
                                // overflow={'hidden'}
                                onClick={() => {
                                    handleOnColorChange(
                                        usedColor.color,
                                        false,
                                        true,
                                        usedColor.id
                                    )
                                    // setIsColorPopOverActive(false)
                                }}
                                position="relative"
                            >
                                <Box
                                    width="48px"
                                    height={'48px'}
                                    bg={usedColor.color}
                                    position="relative"
                                    borderRadius="4px"
                                >
                                    {!isEditMode &&
                                        usedColor.id === selectedColor?.id && (
                                            <Box
                                                position="absolute"
                                                display="flex"
                                                top="0px"
                                                width="100%"
                                                height="100%"
                                                bg="#00000050"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="4px"
                                                onClick={() => {
                                                    setIsColorPopOverActive(
                                                        true
                                                    )
                                                }}
                                            >
                                                <Button
                                                    bg="transparent"
                                                    onClick={() => {
                                                        setIsColorPopOverActive(
                                                            true
                                                        )
                                                        setIsColorEdit(true)
                                                    }}
                                                    border="none"
                                                >
                                                    <ConfigIco color="#fff" />
                                                </Button>
                                            </Box>
                                        )}
                                </Box>
                                <Text
                                    fontSize="10px"
                                    color="primary"
                                    my="0px"
                                    mt="4px"
                                >
                                    {usedColor.color}
                                </Text>
                                {isEditMode && (
                                    <Box
                                        p="2px"
                                        // m="0px"
                                        alignItems="center"
                                        justifyContent="center"
                                        display="flex"
                                        border="none"
                                        position="absolute"
                                        top="-1px"
                                        right="-8px"
                                    >
                                        <Checkbox
                                            label={''}
                                            checked={isSelectedForDelete}
                                            onChange={(isChecked) => {
                                                if (isChecked) {
                                                    setSelectedForDelete(
                                                        usedColor.color
                                                    )
                                                    setIsColorEdit(false)
                                                } else {
                                                    setSelectedForDelete(
                                                        selectedForDelete.filter(
                                                            (color) =>
                                                                color !==
                                                                usedColor.color
                                                        )
                                                    )
                                                }
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                    {isColorPopOverActive && (
                        <Box
                            zIndex={10}
                            bg="white"
                            left="-290px"
                            top="0px"
                            position="absolute"
                            borderRadius="4px"
                        >
                            <Box
                                display="flex"
                                px="16px"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text>
                                    {isColorEdit ? 'Edit' : 'Add'} Color
                                </Text>
                                <Button
                                    onClick={() =>
                                        setIsColorPopOverActive(false)
                                    }
                                    bg="transparent"
                                    border="none"
                                    p="0px"
                                >
                                    <Cross color="#778CA2" />
                                </Button>
                            </Box>
                            <Box px="8px">
                                <ColorPanel
                                    border="none"
                                    p="0px"
                                    key={selectedColor?.color}
                                    initialColor={selectedColor?.color}
                                    onColorChange={(hex) => {
                                        const hexLength = hex.replace(
                                            '#',
                                            '0'
                                        ).length
                                        const isColor = checkColorFormat(hex)
                                        let color = hex
                                        console.log({ hexLength, isColor })
                                        if (hexLength === 3)
                                            color = `#${hex
                                                .replace('#', '')
                                                .repeat(2)}`
                                        if (
                                            isColor &&
                                            (hexLength === 3 ||
                                                hexLength === 6 ||
                                                hexLength === 7)
                                        ) {
                                            colorDebounce(
                                                hex,
                                                !isColorEdit,
                                                isColorEdit,
                                                selectedColor?.id
                                            )
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}

            {recentKey === 'artwork' &&
                artWork.map((usedArtwork, key: number) => {
                    return (
                        <Box
                            border="solid"
                            borderColor={
                                activeArtWork?.id === usedArtwork.id
                                    ? 'primary'
                                    : 'white'
                            }
                            borderRadius="4px"
                            onClick={() => {
                                if (usedArtwork.id !== activeArtWork?.id) {
                                    setActiveArtwork(usedArtwork)
                                    onTileClick(usedArtwork)
                                }
                            }}
                            borderWidth={1}
                            display="flex"
                            alignItems="center"
                            position="relative"
                        >
                            <Image
                                border="solid"
                                borderColor="white"
                                borderRadius="4px"
                                borderWidth={1}
                                width="48px"
                                height="48px"
                                src={getLocalImageUrl(
                                    new File(
                                        [usedArtwork.blob],
                                        usedArtwork.name
                                    )
                                )}
                            />
                            {activeArtWork?.id === usedArtwork.id && (
                                <Box
                                    position="absolute"
                                    display="flex"
                                    top="0px"
                                    width="100%"
                                    height="100%"
                                    bg="#00000050"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="4px"
                                >
                                    <Button
                                        bg="transparent"
                                        onClick={() => {
                                            onAdjustmentClick(
                                                activeArtWork.id,
                                                true
                                            )
                                        }}
                                        border="none"
                                    >
                                        <ConfigIco color="#fff" />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )
                })}

            {recentKey === 'fabrics' &&
                recentlyUsed.fabrics.map((usedFabric) => {
                    console.log({ usedFabric: usedFabric.name })
                    return (
                        <Box display="flex" flexDirection="column">
                            <Box
                                position="relative"
                                onClick={() => {
                                    if (usedFabric.id !== selectedFabric?.id) {
                                        onTileClick(usedFabric)
                                        setSelectedFabric(usedFabric)
                                    }
                                }}
                                className="cursor-pointer"
                                width="50px"
                                height="50px"
                                borderRadius="4px"
                                overflow="hidden"
                            >
                                <Image
                                    width="50px"
                                    height="50px"
                                    src={usedFabric.baseMap}
                                />

                                {selectedFabric?.id === usedFabric.id && (
                                    <Box
                                        position="absolute"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                        height="100%"
                                        bg="#00000050"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="4px"
                                    >
                                        <Button
                                            bg="transparent"
                                            onClick={() => {
                                                onAdjustmentClick(
                                                    usedFabric.id,
                                                    true
                                                )
                                            }}
                                            border="none"
                                        >
                                            <ConfigIco color="#fff" />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <Text fontSize="12px" my="0px" mt="4px">
                                {usedFabric.name}
                            </Text>
                        </Box>
                    )
                })}
        </Box>
    )
}
