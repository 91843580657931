import * as React from 'react'
import { CloseIco, DeleteIco } from 'src/components/svg-icons'
import {
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { Box, Button, Image, Input, Link, Text } from 'src/components/utility'
import { useArtwork } from '../../hooks/use-artwork'
import { useConfiguratorContext } from '../../configurator.context'
import { captureImage } from '../../helper/create-material'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { Slider, UVScale } from '../graphic/uvscale'
import { useGraphic } from '../../hooks/use-graphic'
import { DivisionLine } from 'src/components/division-line/division-line'
import { useFabric } from '../../hooks/use-fabric'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export interface IControlPanelProps {
    mode: 'artwork' | 'graphics' | 'fabrics'
    isControlPanelActive: boolean
    setIsControlPanelActive: (isControlPanelActive: boolean) => void
    entity: {
        id?: string
    }
    mapCode?: TMapsKeys
}

export default function ControlPanel({
    isControlPanelActive,
    setIsControlPanelActive,
    mode,
    entity,
    mapCode,
}: IControlPanelProps) {
    if (!isControlPanelActive) return <></>
    const inputRef = React.useRef<HTMLInputElement>(null)
    const {
        highlights,
        activeArtWork,
        artWork,
        graphic,
        selectedFabric,
        advancedMode,
    } = useConfiguratorContext()
    const clickedOutSideRef = useClickedOutside(() =>
        setIsControlPanelActive(false)
    )
    const [isLinkActive, setIsLinkActive] = React.useState(false)
    const { deleteArtwork, updateArtworkConfig, uploadArtwork } = useArtwork()
    const {
        updateRepeatAndOffset,
        updateSubstanceness,
        updateTransparency,
        updateRotation,
        deleteGraphic,
        uploadGraphic,
    } = useGraphic()
    const { updateConfig, deleteFabric } = useFabric()
    const [heroImage, setHeroImage] = React.useState(
        'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
    )
    const title =
        mode === 'artwork'
            ? 'Edit Artwork'
            : mode === 'graphics'
            ? 'Edit Graphic'
            : 'Edit Fabric'

    React.useEffect(() => {
        if (highlights[0].isActive) {
            let code = 'map'
            if (mode === 'graphics') code = 'mapLayer1'
            if (mode === 'fabrics') code = 'map'
            let fileUrl = ''
            if (activeArtWork && mode === 'artwork') {
                fileUrl =
                    getLocalImageUrl(
                        new File([activeArtWork.blob], activeArtWork?.name)
                    ) || ''
                setHeroImage(captureImage(highlights[0].mesh, code) || fileUrl)
            }
            if (graphic && mode === 'graphics') {
                fileUrl =
                    getLocalImageUrl(new File([graphic.blob], graphic?.name)) ||
                    ''
                setHeroImage(fileUrl)
            }
            if (selectedFabric && mode === 'fabrics') {
                fileUrl = selectedFabric.baseMap || ''
                setHeroImage(captureImage(highlights[0].mesh, code) || fileUrl)
            }
        }
    }, [highlights, activeArtWork, mode, graphic, selectedFabric])

    let UVConfig = {
        offset: {
            u: 0,
            v: 0,
        },
        repeat: {
            u: 1,
            v: 1,
        },
    }

    if (activeArtWork && mode === 'artwork') {
        UVConfig = {
            offset: {
                u: activeArtWork?.config?.offset?.u || 0,
                v: activeArtWork?.config?.offset?.v || 0,
            },
            repeat: {
                u: activeArtWork?.config?.repeat?.u || 1,
                v: activeArtWork?.config?.repeat?.v || 1,
            },
        }
    }
    if (graphic && mode === 'graphics') {
        UVConfig = {
            offset: {
                u: graphic?.config?.offset?.u || 0,
                v: graphic?.config?.offset?.v || 0,
            },
            repeat: {
                u: graphic?.config?.repeat?.u || 1,
                v: graphic?.config?.repeat?.v || 1,
            },
        }
    }
    if (selectedFabric && mode === 'fabrics') {
        UVConfig = {
            offset: {
                u: selectedFabric?.maps[0]?.config.offset?.u || 0,
                v: selectedFabric?.maps[0]?.config.offset?.v || 0,
            },
            repeat: {
                u: selectedFabric?.maps[0]?.config.scale?.u || 1,
                v: selectedFabric?.maps[0]?.config.scale?.v || 1,
            },
        }
    }

    return (
        <Box
            top="65px"
            right={advancedMode ? '360px' : '355px'}
            position="absolute"
            bg="white"
            width="310px"
            p="8px"
            px="0px"
            height="500px"
            overflow={'auto'}
            className="scrollbar_none"
            borderRadius="4px"
            ref={clickedOutSideRef}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my="12px"
                px="8px"
            >
                <Text my="0px">{title}</Text>
                <Button
                    onClick={() => setIsControlPanelActive(false)}
                    bg="transparent"
                    border="none"
                >
                    <CloseIco />
                </Button>
            </Box>
            <DivisionLine color="gray350" />
            <Box mt="4px" px="8px">
                <Image
                    className="img-clear"
                    width="100%"
                    height="100px"
                    src={heroImage}
                />
                <Box display="flex" alignItems="center" gridColumnGap="20px">
                    {mode !== 'fabrics' && (
                        <Button
                            p="0px"
                            bg="transparent"
                            border="none"
                            onClick={() =>
                                inputRef.current &&
                                inputRef.current.click &&
                                inputRef.current.click()
                            }
                        >
                            <UploadFile />
                        </Button>
                    )}
                    <Input
                        autoComplete="false"
                        type="file"
                        ref={inputRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files) {
                                const file = e.target.files[0]
                                if (mode === 'artwork') {
                                    uploadArtwork(file, { id: undefined })
                                } else if (mode === 'graphics') {
                                    uploadGraphic(file, undefined, true)
                                }
                            }
                        }}
                        onClick={(e: any) => {
                            e.target.value = null
                        }}
                        display="none"
                        bg="none"
                    />

                    <Link
                        href={heroImage}
                        download={'base_map' + '.png'}
                        bg="white"
                        style={{
                            border: 'none',
                            borderRadius: '4px',
                            padding: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                        className="download-link"
                    >
                        <Download />
                    </Link>
                    <Button
                        onClick={() => {
                            if (entity.id) {
                                if (mode === 'artwork') {
                                    deleteArtwork(entity.id)
                                    setIsControlPanelActive(false)
                                }
                                if (mode === 'graphics') {
                                    deleteGraphic(entity.id)
                                    setIsControlPanelActive(false)
                                }
                                if (mode === 'fabrics') {
                                    deleteFabric()
                                    setIsControlPanelActive(false)
                                }
                            }
                        }}
                        p="0px"
                        bg="transparent"
                        border="none"
                    >
                        <DeleteIco />
                    </Button>
                </Box>
            </Box>
            <DivisionLine color="gray350" />
            <Box
                px="8px"
                display="flex"
                flexDirection="column"
                gridRowGap="12px"
                mt="8px"
            >
                {mode === 'graphics' && (
                    <Slider
                        label="Blend with Background%"
                        minValue={0}
                        maxValue={1}
                        sensitivity={0.1}
                        unit="percentage"
                        value={graphic?.config.substanceNess || 0}
                        onChange={(value) => updateSubstanceness(value)}
                    />
                )}
                <Box>
                    <Text my="0px" mb="8px" fontSize="12px">
                        Repeat
                    </Text>
                    <UVScale
                        maxValue={1}
                        minValue={-1}
                        sensitivity={0.00001}
                        onChange={(value, key) => {
                            if (activeArtWork && mode === 'artwork') {
                                updateArtworkConfig(
                                    {
                                        ...activeArtWork.config,
                                        repeat: isLinkActive
                                            ? {
                                                  u: value,
                                                  v: value,
                                              }
                                            : {
                                                  ...activeArtWork.config
                                                      .repeat,
                                                  [key]: value,
                                              },
                                    },
                                    activeArtWork.id
                                )
                            } else if (graphic && mode === 'graphics') {
                                updateRepeatAndOffset(
                                    value,
                                    key,
                                    'repeat',
                                    isLinkActive,
                                    isLinkActive
                                )
                            } else if (selectedFabric && mode === 'fabrics') {
                                const baseMap = selectedFabric.maps[0]
                                console.log({
                                    metalnessIntensity:
                                        baseMap.config.metalnessIntensity,
                                    offset: baseMap.config.offset,
                                    scale: isLinkActive
                                        ? {
                                              u: value,
                                              v: value,
                                          }
                                        : {
                                              ...baseMap.config.scale,
                                              [key]: value,
                                          },
                                    roughnessIntensity:
                                        baseMap.config.roughnessIntensity,
                                    rotation: baseMap.config.rotation,
                                })
                                updateConfig('uniform-scale', {
                                    metalnessIntensity:
                                        baseMap.config.metalnessIntensity,
                                    offset: baseMap.config.offset,
                                    scale: isLinkActive
                                        ? {
                                              u: value,
                                              v: value,
                                          }
                                        : {
                                              ...baseMap.config.scale,
                                              [key]: value,
                                          },
                                    roughnessIntensity:
                                        baseMap.config.roughnessIntensity,
                                    rotation: baseMap.config.rotation,
                                })
                            }
                        }}
                        onLinkClick={() => setIsLinkActive(!isLinkActive)}
                        isLinkActive={isLinkActive}
                        u={UVConfig.repeat.u}
                        v={UVConfig.repeat.v}
                    />
                </Box>
                <DivisionLine color="gray250" />
                <Box>
                    <Text my="0px" mb="8px" fontSize="12px">
                        Offset
                    </Text>
                    <UVScale
                        maxValue={1}
                        minValue={-1}
                        sensitivity={0.00001}
                        onChange={(value, key) => {
                            if (activeArtWork && mode === 'artwork') {
                                updateArtworkConfig(
                                    {
                                        ...activeArtWork.config,
                                        offset: isLinkActive
                                            ? {
                                                  u: value,
                                                  v: value,
                                              }
                                            : {
                                                  ...activeArtWork.config
                                                      .offset,
                                                  [key]: value,
                                              },
                                    },
                                    activeArtWork.id
                                )
                            } else if (graphic && mode === 'graphics') {
                                updateRepeatAndOffset(
                                    value,
                                    key,
                                    'offset',
                                    isLinkActive,
                                    isLinkActive
                                )
                            } else if (selectedFabric && mode === 'fabrics') {
                                const baseMap = selectedFabric.maps[0]
                                updateConfig('uniform-scale', {
                                    metalnessIntensity:
                                        baseMap.config.metalnessIntensity,
                                    scale: baseMap.config.scale,
                                    offset: isLinkActive
                                        ? {
                                              u: value,
                                              v: value,
                                          }
                                        : {
                                              ...baseMap.config.offset,
                                              [key]: value,
                                          },
                                    roughnessIntensity:
                                        baseMap.config.roughnessIntensity,
                                    rotation: baseMap.config.rotation,
                                })
                            }
                        }}
                        onLinkClick={() => setIsLinkActive(!isLinkActive)}
                        isLinkActive={isLinkActive}
                        u={UVConfig.offset.u}
                        v={UVConfig.offset.v}
                    />
                </Box>
                {mode === 'graphics' && (
                    <Slider
                        sensitivity={0.1}
                        unit="percentage"
                        minValue={0}
                        maxValue={1}
                        label="Transparency%"
                        value={graphic?.config.transparency || 0}
                        onChange={(value) => updateTransparency(value)}
                    />
                )}
                {mode === 'graphics' && (
                    <Slider
                        label="Rotation"
                        unit="number"
                        minValue={0}
                        maxValue={360}
                        sensitivity={1}
                        value={graphic?.config?.rotation || 0}
                        onChange={(value) => updateRotation(value)}
                    />
                )}
            </Box>
        </Box>
    )
}
