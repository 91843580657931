import * as React from 'react'

export const CompanyIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h4.77a1 1 0 0 0 1-1v-.846a.615.615 0 0 1 1.23 0V19a1 1 0 0 0 1 1h4.77a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H6Zm.23 1.23h12.308v13.54h-4.307v-1.462a1 1 0 0 0-1-1h-1.692a1 1 0 0 0-1 1v1.461H6.23V5.231Zm1.847 1.232a.615.615 0 0 0 0 1.23h1.23a.615.615 0 0 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23H13a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23h1.231a.615.615 0 1 0 0-1.23h-1.23ZM8.077 8.923a.615.615 0 1 0 0 1.23h1.23a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23H13a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23h1.231a.615.615 0 1 0 0-1.23h-1.23Zm-7.384 2.462a.615.615 0 1 0 0 1.23h1.23a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23H13a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23h1.231a.615.615 0 1 0 0-1.23h-1.23Zm-7.384 2.461a.615.615 0 1 0 0 1.23h1.23a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23H13a.615.615 0 1 0 0-1.23h-1.23Zm3.692 0a.615.615 0 0 0 0 1.23h1.231a.615.615 0 1 0 0-1.23h-1.23Zm-7.384 2.462a.615.615 0 1 0 0 1.23h1.23a.615.615 0 0 0 0-1.23h-1.23Zm7.384 0a.615.615 0 0 0 0 1.23h1.231a.615.615 0 0 0 0-1.23h-1.23Z"
            fill="#778CA2"
        />
    </svg>
)
