import React from 'react'
import { productAndtodoStatus } from 'src/components/collections/collections.helper'
import { Box, Button } from 'src/components/utility'
import TodoStatusDropdown from './todo-status-dropdown'
import type { TodoStatuses } from '../../todo.types'
import useUpdateTodo from 'src/hooks/useUpdateTodo'
import useStore from 'src/store/hooks/useStore'
import { useUser } from 'src/helpers'

function TodoStatus({
    todoStatus,
    todoId,
    refetchTodo,
    managerId,
}: {
    todoStatus: TodoStatuses
    todoId: number
    refetchTodo: () => void
    managerId: number | null
}) {
    const [isStatusDropdownActive, setIsStatusDropdownActive] =
        React.useState(false)
    const { updateTodoStatus } = useUpdateTodo()
    const { updateGlobalSearchResults } = useStore()
    const { userId } = useUser()

    const isCollectionManager = managerId === Number(userId)

    return (
        <Box position="relative">
            <Button
                onClick={() => {
                    setIsStatusDropdownActive(!isStatusDropdownActive)
                }}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-btn"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                {productAndtodoStatus(
                    todoStatus,
                    isCollectionManager,
                    false,
                    true
                )}
            </Button>
            {isStatusDropdownActive ? (
                <TodoStatusDropdown
                    active={isStatusDropdownActive}
                    setIsDropdownActive={setIsStatusDropdownActive}
                    onUpdate={(status) =>
                        updateTodoStatus({
                            id: todoId,
                            status,
                            onCompleted: () => {
                                refetchTodo()
                                updateGlobalSearchResults({
                                    id: String(todoId),
                                    type: 'todos',
                                    updatedKey: 'status',
                                    value: status,
                                })
                            },
                        })
                    }
                />
            ) : null}
        </Box>
    )
}

export default TodoStatus
