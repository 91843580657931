import { IconLink } from '../icon-link/icon-link'
import { Box } from 'src/components/utility'
import {
    HomeIco,
    CollectionIco,
    LibraryIco,
    PeopleIco,
    ReportsIco,
    SupportIco,
    TodoIco,
    QuickTourIco,
    SettingsIco,
} from '../svg-icons'

import useStore from 'src/store/hooks/useStore'
import { ConfigureIco } from '../svg-icons/3d-renderer/actions'
const SideBarLinksFirsthalfList = [
    {
        id: 'sidebar-dashboard-navigation',
        text: 'Dashboard',
        href: '/',
        element: <HomeIco />,
        subScreens: [],
    },
    {
        id: 'sidebar-collections-navigation',
        text: 'Collections',
        href: '/collections',
        element: <CollectionIco />,
        subScreens: [],
    },
    {
        id: 'sidebar-todos-navigation',
        text: "To-Do's",
        href: '/todos',
        element: <TodoIco />,
        subScreens: ['/individual/todo', '/todo'],
        notSubScreens: ['/collection', '/library'],
    },
    {
        id: 'sidebar-library-navigation',
        text: 'Library',
        href: '/library',
        element: <LibraryIco />,
        subScreens: [''],
    },
    {
        id: 'sidebar-people-navigation',
        text: 'Organization',
        href: '/organizations?tab=teams&sort=LTO',
        element: <PeopleIco />,
        subScreens: ['/individual-contributor', '/people/manage-user', '/team'],
    },
    {
        id: 'sidebar-reports-navigation',
        text: 'Reports',
        href: '/reports',
        element: <ReportsIco />,
        subScreens: [],
    },
    {
        id: 'sidebar-configure-navigation',
        text: 'Configure',
        href: '/configure',
        element: <ConfigureIco />,
        subScreens: [],
    },
]

const SideBarLinksSecondhalfList = [
    {
        id: 'sidebar-support-navigation',
        text: 'Support',
        href: '/support',
        element: <SupportIco />,
    },
    {
        id: 'sidebar-quicktour-navigation',
        text: 'Quick Tour',
        href: '/6',
        element: <QuickTourIco />,
    },
    {
        id: 'sidebar-settings-navigation',
        text: 'Settings',
        href: '/7',
        element: <SettingsIco />,
    },
]
export const SideBarLinksFirsthalf = ({
    showText = true,
}: {
    showText?: boolean
}) => {
    return (
        <>
            {SideBarLinksFirsthalfList.map(
                ({ text, href, element, subScreens, notSubScreens }, key) => {
                    return (
                        <IconLink
                            element={element}
                            text={text}
                            href={href}
                            key={key}
                            subScreens={subScreens}
                            notSubScreens={notSubScreens}
                            showText={showText}
                        />
                    )
                }
            )}
        </>
    )
}

export const SideBarLinksSecondhalf = ({
    showText = true,
}: {
    showText?: boolean
}) => {
    const { setIsModalProfileModalOpen } = useStore()
    return (
        <>
            <Box
                display="flex"
                pt="28px"
                pb="28px"
                mt="12px"
                width="100%"
                borderColor="borderLine"
                borderRight="0px"
                borderLeft="0px"
                borderStyle="solid"
                borderWidth={1}
                flexDirection="column"
            >
                {SideBarLinksSecondhalfList.map(
                    ({ text, href, element }, key) => {
                        return (
                            <IconLink
                                onClick={
                                    text === 'Settings'
                                        ? () => setIsModalProfileModalOpen(true)
                                        : undefined
                                }
                                element={element}
                                text={text}
                                href={href}
                                key={key}
                                showText={showText}
                            />
                        )
                    }
                )}
            </Box>
        </>
    )
}
