import { useLazyQuery } from '@apollo/client'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tag } from 'src/components'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { CloseIco } from 'src/components/svg-icons'
import { InfoGreyIco } from 'src/components/svg-icons/library/info-grey'
import { Box, Text, Button } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'
import {
    FetchFileById,
    FetchFolderById,
} from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { TagComponent } from 'src/screens/products/sub-screens/accordian'

export const FolderDetails = ({
    removeClose = false,
}: {
    removeClose?: boolean
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [fetchFolder, folderDetails] = useLazyQuery(FetchFolderById)
    const [fetchFile, fileDetails] = useLazyQuery(FetchFileById)

    let folderId = searchParams.get(libraryDefaultKeys.showFolderDetails)
    let fileId = searchParams.get(libraryDefaultKeys.showFileDetails)

    useEffect(() => {
        if (folderId && folderId !== '0') {
            fetchFolder({
                variables: {
                    id: folderId,
                },
            })
        }
    }, [folderId])

    useEffect(() => {
        if (fileId && fileId !== '0') {
            fetchFile({
                variables: {
                    id: fileId,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }, [fileId])

    const folder = folderDetails?.data?.folders[0] || null
    const file = fileDetails?.data?.library_files[0] || null
    const isFile = (folderId === '0' || !folderId) && fileId && fileId !== '0'

    let commonObject: any = {}

    if (file || folder) {
        commonObject = isFile ? file : folder
    }
    let tags = []

    if (commonObject && commonObject.tags && commonObject.tags.length) {
        if (typeof commonObject.tags === 'string') {
            tags = JSON.parse(commonObject.tags)
        }
        if (typeof commonObject.tags === 'object') {
            tags = commonObject.tags
        }
    }

    let fileSize: string = '0 KB'

    const standardSize: number = 1024
    if (commonObject && commonObject.size) {
        console.log(commonObject)
        let kb: number = Number(Number(commonObject.size) / standardSize)
        fileSize = `${kb.toFixed(0)} KB`
        if (kb >= standardSize) {
            let mb: number = Number(Number(kb) / standardSize)
            fileSize = `${mb.toFixed(2)} MB`
            if (mb >= standardSize) {
                let gb: number = Number(Number(mb) / standardSize)
                fileSize = `${gb.toFixed(2)} GB`
            }
        }
    }

    const showImage = () => {
        return commonObject?.user?.other_data?.profile_pic
            ? getImageUrl(commonObject?.user?.other_data?.profile_pic)
            : ''
    }

    return (
        <Box
            minHeight="calc(100vh - 255px)"
            borderLeft={'1px solid #E8ECEF'}
            p={'24px'}
            pr="0px"
            width="292px"
        >
            <Box
                display="flex"
                alignItems="center"
                mb={'24px'}
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" gridColumnGap={'12px'}>
                    <InfoGreyIco />
                    <Text fontSize={'14px'} my="0px" fontWeight={500}>
                        Details
                    </Text>
                </Box>
                {!removeClose && (
                    <Button
                        bg="transparent"
                        border="none"
                        onClick={() => {
                            if (
                                searchParams.has(
                                    libraryDefaultKeys.showFolderDetails
                                )
                            ) {
                                searchParams.delete(
                                    libraryDefaultKeys.showFolderDetails
                                )
                            }
                            if (
                                searchParams.has(
                                    libraryDefaultKeys.showFileDetails
                                )
                            ) {
                                searchParams.delete(
                                    libraryDefaultKeys.showFileDetails
                                )
                            }
                            setSearchParams(searchParams)
                        }}
                    >
                        <CloseIco color="#022143" />
                    </Button>
                )}
            </Box>
            {commonObject && commonObject.id && (
                <Box>
                    <Box display="flex" mb={'20px'}>
                        <Box width="40%">
                            <Text
                                fontSize={'12px'}
                                my="0px"
                                color="textSecondary"
                            >
                                {' '}
                                {isFile ? 'File ' : 'Folder '} Name
                            </Text>
                        </Box>
                        <Box width="60%">
                            {' '}
                            <Text
                                fontSize={'12px'}
                                my="0px"
                                style={{ wordBreak: 'break-word' }}
                            >
                                {' '}
                                {commonObject.name}
                            </Text>{' '}
                        </Box>
                    </Box>
                    <Box display="flex" mb={'20px'}>
                        <Box width="40%">
                            <Text
                                fontSize={'12px'}
                                my="0px"
                                color="textSecondary"
                            >
                                Added By
                            </Text>
                        </Box>
                        <Box width="60%" display="flex" alignItems="center">
                            <ProfileImg
                                src={showImage()}
                                fontSize={'12px'}
                                name={commonObject?.user?.name || ''}
                                size="20px"
                            />
                            <Text fontSize={'12px'} ml="4px" my="0px">
                                {commonObject?.user?.name}
                            </Text>
                        </Box>
                    </Box>

                    <Box display="flex" mb={'20px'}>
                        <Box width="40%">
                            <Text
                                fontSize={'12px'}
                                my="0px"
                                color="textSecondary"
                            >
                                Created On
                            </Text>
                        </Box>
                        <Box width="60%">
                            <Text fontSize={'12px'} my="0px">
                                {' '}
                                {format(
                                    new Date(commonObject.created_at),
                                    'MMM dd, yyyy | hh:mm a'
                                )}
                            </Text>
                        </Box>
                    </Box>

                    {!isFile && fileSize && (
                        <Box display="flex" mb={'20px'}>
                            <Box width="40%">
                                <Text
                                    fontSize={'12px'}
                                    my="0px"
                                    color="textSecondary"
                                >
                                    {' '}
                                    Folder Size{' '}
                                </Text>
                            </Box>
                            <Box width="60%">
                                {' '}
                                <Text fontSize={'12px'} my="0px">
                                    {' '}
                                    {fileSize}{' '}
                                </Text>{' '}
                            </Box>
                        </Box>
                    )}

                    {isFile && (
                        <>
                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        Category{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {' '}
                                    <Text fontSize={'12px'} my="0px">
                                        {commonObject?.category?.name}
                                    </Text>{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        File Size{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {' '}
                                    <Text fontSize={'12px'} my="0px">
                                        {' '}
                                        {fileSize}{' '}
                                    </Text>{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        File Type{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {' '}
                                    <Text fontSize={'12px'} my="0px">
                                        {commonObject.type}{' '}
                                    </Text>{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        Color
                                    </Text>
                                </Box>
                                <Box
                                    width="60%"
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="wrap"
                                >
                                    {' '}
                                    {typeof commonObject.color !== 'string'
                                        ? commonObject.color.map(
                                              (color: {
                                                  name: string
                                                  color: string
                                              }) => (
                                                  <TagComponent
                                                      onUpdate={() => {}}
                                                      onDelete={() => {}}
                                                      name={color.color}
                                                      key={color.color}
                                                      isEditPermitted={false}
                                                  />
                                              )
                                          )
                                        : JSON.parse(commonObject.color).map(
                                              (color: {
                                                  name: string
                                                  color: string
                                              }) => (
                                                  <TagComponent
                                                      onUpdate={() => {}}
                                                      onDelete={() => {}}
                                                      name={color.color}
                                                      key={color.color}
                                                      isEditPermitted={false}
                                                  />
                                              )
                                          )}{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        Company{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {' '}
                                    <Text fontSize={'12px'} my="0px">
                                        {commonObject.company}{' '}
                                    </Text>{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        Brand{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {' '}
                                    <Text fontSize={'12px'} my="0px">
                                        {commonObject.brand}{' '}
                                    </Text>{' '}
                                </Box>
                            </Box>

                            <Box display="flex" mb={'20px'}>
                                <Box width="40%">
                                    <Text
                                        fontSize={'12px'}
                                        my="0px"
                                        color="textSecondary"
                                    >
                                        {' '}
                                        Tags{' '}
                                    </Text>
                                </Box>
                                <Box width="60%">
                                    {tags && tags.length ? (
                                        <Box
                                            display="flex"
                                            gridColumnGap="4px"
                                            gridRowGap={'4px'}
                                            flexWrap="wrap"
                                        >
                                            {tags.map(
                                                (
                                                    tag: {
                                                        name: string
                                                        color: string
                                                    },
                                                    key: number
                                                ) => {
                                                    return (
                                                        <Tag
                                                            key={key}
                                                            element={tag.name}
                                                            color={
                                                                tag.color ||
                                                                '#FFDDFF'
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}
