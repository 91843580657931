import React, { useState } from 'react'
import { HexColorInput, HexColorPicker, RgbColorPicker } from 'react-colorful'
import { Line } from '../line/line'
import { Box, Button, Text, Input } from '../utility'
import { ArrowDown } from 'src/assets/svgs-components'
import { isValidHexaCode } from 'src/helpers/check-is-hex-valid'
import { toast } from 'react-toastify'
import { checkColorFormat, convertColor } from './color-picker.helper'
import { Popover } from '@mui/material'
import { AnnotationColor } from '../svg-icons/3d-renderer/actions'

export default function ColorPicker({
    onAdd,
    initialColor,
    buttonIcon,
}: {
    onAdd: (color: string) => void
    initialColor?: string
    buttonIcon?: React.ReactElement | string
}) {
    const [switcherVal, setSwitcherVal] = useState(
        initialColor ? checkColorFormat(initialColor) : 'Hex'
    )
    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const initialColorValue = initialColor
        ? convertColor(initialColor)
        : { hex: '#FFFFFF', rgb: { r: 255, g: 255, b: 255 } }

    const [hexColor, setHexColor] = useState(initialColorValue.hex)
    const [rgbaColor, setRgbaColor] = useState(initialColorValue.rgb)

    const [isColorPointer, setIsColorPointer] = useState(false)

    const alternateColor = switcherVal === 'Hex' ? 'RGB' : 'Hex'
    const alternativeColorValue =
        switcherVal === 'Hex'
            ? `${convertColor(hexColor).rgb.r},${
                  convertColor(hexColor).rgb.g
              },${convertColor(hexColor).rgb.b}`
            : convertColor(`rgb(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b})`)
                  .hex

    return (
        <Box>
            <Button
                p="0px"
                border="none"
                bg="transparent"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    setIsOpen(!isOpen)
                }}
            >
                {buttonIcon || <AnnotationColor />}
            </Button>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box
                    id="color-picker-box"
                    aria-orientation="horizontal"
                    zIndex={15}
                    bg="white"
                    p={'8px'}
                    borderRadius="8px"
                    border="1px solid #E8ECEF"
                >
                    {switcherVal === 'Hex' ? (
                        <HexColorPicker
                            className="react-colorful__hexcolor_picker"
                            color={hexColor}
                            inputMode="text"
                            onChange={(hex) => {
                                setHexColor(hex)
                            }}
                        />
                    ) : (
                        <RgbColorPicker
                            color={rgbaColor}
                            onChange={(newCol) => {
                                setRgbaColor(newCol)
                                setIsColorPointer(true)
                            }}
                        />
                    )}
                    <Box
                        border="1px solid #E8ECEF"
                        p={'8px'}
                        mt="12px"
                        borderRadius="4px"
                        display="flex"
                        justifyContent="space-between"
                        // width="312px"
                        alignItems="center"
                        id="hex-color-input-wrapper"
                    >
                        <Box
                            border="1px solid #E8ECEF"
                            borderRadius="4px"
                            height="25px"
                            width="69px"
                            mr="12px"
                            px="4px"
                            py="10px"
                            justifyContent="space-between"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Text my="0px">{switcherVal}</Text>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                gridGap="4px"
                                onClick={() => {
                                    switcherVal === 'Hex'
                                        ? setSwitcherVal('RGB')
                                        : setSwitcherVal('Hex')
                                }}
                            >
                                <ArrowDown
                                    style={{
                                        transform: 'rotate(180deg)',
                                    }}
                                />
                                <ArrowDown />
                            </Box>
                        </Box>
                        <Box mr="4px">
                            {switcherVal === 'Hex' ? (
                                <HexColorInput
                                    id="hex-color-input"
                                    onInput={(e) => {
                                        setHexColor(e.currentTarget.value)
                                    }}
                                    color={hexColor}
                                    onChange={setHexColor}
                                    style={{
                                        maxWidth: '132px',
                                        height: '21.5px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    placeholder="Type a color"
                                    prefixed
                                    alpha
                                />
                            ) : (
                                <Input
                                    bg="white"
                                    style={{
                                        maxWidth: '132px',
                                        height: '25px',
                                        border: '1px solid #E8ECEF',
                                        borderRadius: '4px',
                                    }}
                                    onChange={(e) => {
                                        setIsColorPointer(false)
                                        const newRgbInput = e.target.value
                                        const [r, g, b] = newRgbInput
                                            .split(',')
                                            .map((value) =>
                                                parseInt(value.trim(), 10)
                                            )
                                        const a =
                                            newRgbInput.split(',').length === 4
                                                ? Number(
                                                      newRgbInput.split(',')[3]
                                                  )
                                                : 1
                                        if (
                                            !isNaN(r) &&
                                            !isNaN(g) &&
                                            !isNaN(b)
                                        ) {
                                            setRgbaColor({
                                                r: r,
                                                g: g,
                                                b: b,
                                            })
                                        }
                                    }}
                                    value={
                                        isColorPointer
                                            ? `${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`
                                            : undefined
                                    }
                                    defaultValue={`${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`}
                                />
                            )}
                        </Box>
                        <Line
                            color="textSecondary"
                            lineWidth="1px"
                            direction="vertical"
                            length="12px"
                        />
                        <Button
                            onMouseUp={() => {
                                switcherVal === 'Hex'
                                    ? isValidHexaCode(hexColor)
                                        ? onAdd(hexColor)
                                        : toast('HEX color code is invalid', {
                                              className:
                                                  'custom-toaster toaster-error',
                                          })
                                    : onAdd(
                                          `${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}`
                                      )
                                setIsOpen(false)
                            }}
                            id="hex-color-add-link"
                            border="none"
                            width="max-content"
                            m="0px"
                            bg="transparent"
                            color="links"
                        >
                            + Add
                        </Button>
                    </Box>
                    <Box
                        display="flex"
                        gridGap="8px"
                        alignItems="center"
                        px="4px"
                    >
                        <Text fontSize={'14px'} color="textSecondary">
                            {alternateColor}:
                        </Text>
                        <Text fontSize={'14px'} color="primary">
                            {alternativeColorValue}
                        </Text>
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}
