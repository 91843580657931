import type { storeState } from './@types/slider-types'
const createPredictiveOutputSlice = (set: Function) => ({
	isPredectiveOutput: false,
	setIsPredectiveOutput: ({ payload }: { payload: boolean }) => {
		set((state: storeState) => {
			return { ...state, isPredectiveOutput: payload }
		})
	}
})

export default createPredictiveOutputSlice
