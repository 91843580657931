import { Divider } from '@mui/material'
import * as React from 'react'
import {
    DangerModal,
    // DivisionLine,
    ProgressBar,
    ProgressIcons,
    StackedImage,
} from 'src/components'
import { Dots } from 'src/components/svg-icons'
import Group from 'src/components/svg-icons/group'
// import Group from 'src/components/svg-icons/group'
// import Group from 'src/components/svg-icons/group'
import { Box, Button, Td, Text, Tr } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import useStore from 'src/store/hooks/useStore'
import TeamDropdown from '../dropdown/team-dropdown'
import {
    useDeleteTeam,
    useFetchCollaboratorByTeamId,
    useFetchEntityCount,
} from '../../hooks'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getTeamsData } from '../../function'
import { getImageUrl } from 'src/helpers'
import RenameTeamModal from '../../modal/rename-team'
import { AddCollaboratorModal } from '../../modal/add-collaborator/add-collaborator'
import { TeamIcoSm } from 'src/components/svg-icons/team-ico'
import { convertIntoElipses } from 'src/components/list-item/list-item'

export interface ITeamCardProps {
    team: ITeams
    isCollectionTeam?: boolean
    showProgressBar?: boolean
    onClickHandler: (data: any) => void
    refetchData?: () => void
    bg?: string
    inTab?: boolean
    border?: boolean
}

function TeamCard(props: ITeamCardProps, ref: any) {
    const isCollectionTeam = props.isCollectionTeam || false
    const showProgressBar = props.showProgressBar || true
    const [teamDropdown, setTeamDropdown] = React.useState(false)
    const { addDrawerTabOne } = useStore()
    const clickedOutSideRef = useClickedOutside(() => setTeamDropdown(false))
    const [searchParams, setSearchParam] = useSearchParams()
    const [deleteTeam, setDeleteTeam] = React.useState(false)
    const [isTeamModalActive, setIsTeamModalActive] = React.useState(false)
    const { fetchTeammembers, membersById } = useFetchCollaboratorByTeamId()
    const [members, setMembers] = React.useState<ITeamMembers[]>([])
    const { getEntityCount } = useFetchEntityCount()
    const { handleTeamDelete, isTeamDeleted } = useDeleteTeam()
    const [team, setTeam] = React.useState<ITeams[]>([])

    const [isCollaboratorModalOpen, setCollaboratorModalOpen] =
        React.useState(false)

    React.useEffect(() => {
        if (props.team.id) {
            fetchTeammembers(String(props.team.id))
        }
    }, [props.team.id])

    React.useEffect(() => {
        setMembers(membersById)
    }, [membersById])

    React.useImperativeHandle(
        ref,
        () => {
            return {
                team,
            }
        },
        [team]
    )

    async function dropdownClickHandler(value: string) {
        switch (value) {
            case 'rename_team':
                setIsTeamModalActive(true)
                setTeamDropdown(false)
                break
            case 'edit_team':
                setCollaboratorModalOpen(true)
                setTeamDropdown(false)
                break
            case 'delete_team':
                setDeleteTeam(true)
                setTeamDropdown(false)
                break
        }
    }

    const isTeamDisabled =
        props.team.collections.total <= 0 && props.team.team_members.length <= 0
    async function onDeleteTeam() {
        try {
            await handleTeamDelete(String(props.team.id))
            searchParams.set('page', searchParams.get('page') || '1')
            setSearchParam(searchParams)
            const data = await getTeamsData({
                searchParam: searchParams.get('q') || '',
                currentPage: Number(searchParams.get('page')) || 1,
                setisLoading: () => {},
                sortParam: searchParams.get('sort') || 'name',
            })
            getEntityCount('teams')
            toast('Team Deleted Successfully', {
                className: 'custom-toaster toaster-success',
            })
            setDeleteTeam(false)
            if (data) setTeam(data.data as ITeams[])
        } catch (e) {}
    }

    return (
        <>
            <Tr
                className="cursor-pointer organisation-team-hover"
                border="solid"
                fontFamily="Rubik"
                bg={props.bg || 'white'}
                mt={props.inTab ? '20px' : '0px'}
                borderWidth={1}
                width="100%"
                height="56px"
                borderColor="gray250"
                onClick={(e) => {
                    addDrawerTabOne({
                        id: props.team.id.toString(),
                        type: 'teams',
                        resource: props.team,
                        callback: (data: ITeams[]) => setTeam(data),
                    })
                    searchParams.set('drawer', '1')
                    searchParams.set('id', props.team.id.toString())
                    setSearchParam(searchParams)
                }}
                ref={clickedOutSideRef}
            >
                <Td py="12px">
                    <Box>
                        {/* Future Feature */}
                        {isCollectionTeam && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gridColumnGap="8px"
                                width="max-content"
                                bg="primary"
                                borderRadius="4px"
                                px="4px"
                                py="4px"
                            >
                                <Group />
                                <Text
                                    my="0px"
                                    width="max-content"
                                    color="white"
                                    fontSize="12px"
                                    textAlign="center"
                                >
                                    Collection Team
                                </Text>
                            </Box>
                        )}

                        <Box display="flex" alignItems="center" gridGap="8px">
                            <Box
                                bg="primary"
                                px="4px"
                                py="4px"
                                display="flex"
                                alignItems="center"
                                borderRadius="4px"
                            >
                                <TeamIcoSm color="white" />
                                <Text my="0px" fontSize="12px" color="white">
                                    Team
                                </Text>
                            </Box>
                            <Text>
                                {convertIntoElipses(props.team.name, 30)}
                            </Text>
                            {/* Future Feature */}
                            {/* <Text fontSize="12px" color="links">
                            Show 03 Product Teams
                        </Text> */}
                        </Box>
                    </Box>
                </Td>
                <Td
                    height="32px"
                    py="12px"
                    alignItems="center"
                    // height="100%"
                >
                    <Box position="relative">
                        {props.team.team_members.length > 0 ? (
                            <StackedImage
                                size={'32px'}
                                spacing={18}
                                width="max-content"
                                fontSize="14px"
                                list={props.team.team_members.map((member) => ({
                                    name: member.user.name,
                                    src: getImageUrl(
                                        member.user?.other_data?.profile_pic
                                    ),
                                }))}
                            />
                        ) : (
                            <Text fontSize="14px" color="textSecondaryDarkBlue">
                                No collaborators yet
                            </Text>
                        )}
                    </Box>
                </Td>
                <Td width="170px" py="12px" pr="0px">
                    <Box display="flex" alignItems="center" gridColumnGap="0px">
                        <Box display="flex" width="max-content">
                            <Text
                                fontSize="14px"
                                width="100px"
                                color={
                                    isTeamDisabled
                                        ? 'textSecondaryDarkBlue'
                                        : 'textSecondary'
                                }
                            >
                                {props.team.collections.total} Collection
                                {props.team.collections.total > 1 ? 's' : ''}
                            </Text>
                        </Box>
                        <Box display="flex" gridColumnGap="12px">
                            <Divider
                                style={{
                                    border: '1px solid #E6E7E9',
                                }}
                            />
                            <ProgressIcons
                                isDisabled={isTeamDisabled}
                                completed={props.team.collections.complete}
                                remaining={props.team.collections.delayed}
                            />
                            {showProgressBar && (
                                <ProgressBar
                                    isDisabled={isTeamDisabled}
                                    percentage={
                                        props.team.collectionCompletionRate
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                </Td>
                <Td display="flex" py="12px">
                    <Box position="relative" display="flex" alignItems="center">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setTeamDropdown(!teamDropdown)
                            }}
                            bg="white"
                            border="none"
                        >
                            <Dots active={false} />
                        </Button>
                        {teamDropdown && (
                            <TeamDropdown
                                onDropDownClick={dropdownClickHandler}
                                teamDropdown={teamDropdown}
                            />
                        )}
                    </Box>
                </Td>
            </Tr>
            <DangerModal
                title="Delete Team"
                isModal={deleteTeam}
                onRemove={() => onDeleteTeam()}
                onClose={() => setDeleteTeam(false)}
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                subHeading={`Deleting this team will remove it from all the existing collections and products they are a part of.`}
                heading={`Are you sure you want to delete '${props.team.name}' ?`}
            ></DangerModal>
            <RenameTeamModal
                onUpdate={() => {
                    props.refetchData && props.refetchData()
                }}
                isModalActive={isTeamModalActive}
                setIsModalActive={(isActive) => setIsTeamModalActive(isActive)}
                team={props.team}
                key={props.team.name}
            />
            {isCollaboratorModalOpen && team && (
                <AddCollaboratorModal
                    allowTeamChange={false}
                    onRoleUpdate={() => fetchTeammembers(String(props.team.id))}
                    team={{
                        name: props.team.name,
                        id: props.team.id,
                        team_members: members || [],
                    }}
                    teamMembers={members || []}
                    onCollaboratorsAdded={(members) => {
                        setMembers(members)
                    }}
                    isModal={isCollaboratorModalOpen}
                    onClose={(isOpen) => setCollaboratorModalOpen(isOpen)}
                />
            )}
        </>
    )
}

// create a forwardRef for this component
// and export it as default

export default React.forwardRef(TeamCard)
