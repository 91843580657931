import type { User } from 'src/services/graphql/query/@types/collections'
import create from 'zustand'

type Status = 'inactive' | 'connecting' | 'active' | 'error'
const status: Status = 'inactive'

export type Activity = {
    id: number
    comment: string
    entity_name: string
    entity_id: string
    entity_info: string
    user_id: string
    created_at: string
    user: User
}

type EntityTypes =
    | 'collections'
    | 'products'
    | 'product_variants'
    | 'todos'
    | 'files'
    | 'library_files'

export type EntityData = {
    activities: Activity[]
    total: number
    currentPage: number
    totalPages: number
}

type EntityKey = `${EntityTypes}:${string}` // Composite key for entity_name and entity_id

type Store = {
    status: Status
    entityData: Map<EntityKey, EntityData>
    pendingRequests: Set<EntityKey>
    socketSendMessage: (message: string) => void
    addEntityActivity: ({
        entity_name,
        entity_id,
        activities,
        total,
    }: {
        entity_name: EntityTypes
        entity_id: string
        activities: Activity[]
        total: number
    }) => void
    updateEntityActivity: ({
        entity_name,
        entity_id,
        activities,
        total,
    }: {
        entity_name: EntityTypes
        entity_id: string
        activities: Activity[]
        total: number
    }) => void
    deleteEntityActivity: ({
        entity_name,
        entity_id,
    }: {
        entity_name: EntityTypes
        entity_id: string
    }) => void
    getEntityActivities: ({
        entity_name,
        entity_id,
        page,
        limit,
    }: {
        entity_name: EntityTypes
        entity_id: string
        page: number
        limit?: number
    }) => EntityData
    clearActivities: () => void
    setStatus: (status: Status) => void
    fetchEntityActivities: ({
        entity_name,
        entity_id,
        limit,
        page,
    }: {
        entity_name: EntityTypes
        entity_id: string
        limit: number
        page: number
    }) => void
    setSocketSendMessage: (socketSendMessage: (message: string) => void) => void
}

const createEntityKey = (
    entity_name: EntityTypes,
    entity_id: string
): EntityKey => `${entity_name}:${entity_id}`

const ITEMS_PER_PAGE = 10 // Hard coded the item limit

export const useActivitiesStore = create<Store>(
    (set: Function, get: Function) => ({
        entityData: new Map(),
        pendingRequests: new Set(),
        status,
        socketSendMessage: () => {},
        clearActivities: () => set({ entityData: new Map() }),
        setStatus: (status) => set({ status }),

        addEntityActivity: ({ entity_name, entity_id, activities, total }) => {
            set((state: Store) => {
                const currentData = state.entityData

                const entityKey = createEntityKey(entity_name, entity_id)

                const existingData = currentData.get(entityKey) || {
                    activities: [],
                    total: 0,
                    currentPage: 1,
                    totalPages: 1,
                }
                const updatedActivities = [
                    ...existingData.activities,
                    ...activities,
                ]

                const currentPage = Math.ceil(
                    updatedActivities.length / ITEMS_PER_PAGE
                )

                const totalPages = Math.ceil(total / ITEMS_PER_PAGE)

                currentData.set(entityKey, {
                    activities: updatedActivities,
                    total: total,
                    currentPage,
                    totalPages,
                })

                return { entityData: currentData }
            })
        },
        updateEntityActivity: ({
            entity_name,
            entity_id,
            activities,
            total,
        }) => {
            set((state: Store) => {
                const currentData = state.entityData
                const entityKey = createEntityKey(entity_name, entity_id)
                const existingData = currentData.get(entityKey) || {
                    activities: [],
                    total: 0,
                    currentPage: 1,
                    totalPages: 1,
                }
                const updatedActivities = [
                    ...activities,
                    ...existingData.activities,
                ]

                currentData.set(entityKey, {
                    activities: updatedActivities,
                    total: existingData.total + total,
                    currentPage: Math.ceil(
                        updatedActivities.length / ITEMS_PER_PAGE
                    ),
                    totalPages: Math.ceil(total / ITEMS_PER_PAGE),
                })
            })
        },
        deleteEntityActivity: ({ entity_name, entity_id }) => {
            const state: Store = get()
            const entityKey = createEntityKey(entity_name, entity_id)
            state.entityData.delete(entityKey)
        },
        fetchEntityActivities: ({
            entity_name,
            entity_id,
            limit = ITEMS_PER_PAGE,
            page,
        }) => {
            const state: Store = get()

            const requestKey: EntityKey = `${entity_name}:${entity_id}:${page}`

            if (!state.pendingRequests.has(requestKey)) {
                state.pendingRequests.add(requestKey)
                const offset = (page - 1) * limit
                state.socketSendMessage(
                    `{
                        "type": "activities",
                        "payload": {
                            "operation": "GET",
                            "variables": {
                                "eId": "${entity_id}",
                                "eName": "${entity_name}"
                            },
                            "limit": "${limit}",
                            "offset": "${offset}",
                            "devFeature":true
                        }
                    }`
                )
            }
        },
        getEntityActivities: ({
            entity_name,
            entity_id,
            page = 1,
            limit = ITEMS_PER_PAGE,
        }): EntityData => {
            const state: Store = get()

            const entityKey = createEntityKey(entity_name, entity_id)

            const entityData = state.entityData.get(entityKey)

            if (entityData) {
                if (page > entityData.totalPages) {
                    return entityData
                }
            }

            if (!entityData || page >= entityData.currentPage) {
                state.fetchEntityActivities({
                    entity_name,
                    entity_id,
                    limit,
                    page,
                })
            }

            return (
                state.entityData.get(entityKey) || {
                    total: 0,
                    activities: [],
                    currentPage: 1,
                    totalPages: 1,
                }
            )
        },
        setSocketSendMessage: (socketSendMessage) => set({ socketSendMessage }),
    })
)
