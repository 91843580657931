import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'

import { InputTitleWithIcon } from 'src/components/input-title-with-icon/input-title-with-icon'
import { Modal } from 'src/components/modals/modal'
import { CollectionIco, ProductIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'
import { initialProductTodos } from 'src/helpers/constants'
import { isStringEmpty } from 'src/helpers/is-string-empty'
import {
    CreateProduct,
    GetCollectionsName,
    GetProductsForCollection,
} from 'src/services'
import { GetCollection } from 'src/services/graphql/entities/collection/get-collection'

type ProductDetails = {
    name: string
    parentCollection: {
        id: number
        name: string
        team_id: number | null
    } | null
}

function CreateProductModal({
    isOpen,
    onClose,
    initialParentCollection,
}: {
    isOpen: boolean
    onClose: () => void
    initialParentCollection?: {
        id: number
        name: string
        team_id: number | null
    }
}) {
    const initialProductDetails = {
        name: '',
        parentCollection: initialParentCollection || null,
    }

    const [productData, setProductData] = useState<ProductDetails>(
        initialProductDetails
    )
    const [createProductMutation, { loading: isCreatingProduct }] =
        useMutation(CreateProduct)
    const navigate = useNavigate()

    const inputRef = useRef<HTMLInputElement>()

    const collectionsData = useQuery(GetCollectionsName, {
        fetchPolicy: 'no-cache',
    })
    const collections = collectionsData?.data?.collections

    const { data: collectionData } = useQuery(GetCollection, {
        fetchPolicy: 'no-cache',
        variables: {
            id: productData.parentCollection?.id
                ? Number(productData.parentCollection.id)
                : null,
        },
        skip: !productData.parentCollection?.id,
    })

    function validateFields() {
        if (!productData.name || productData.name.trim().length < 1) {
            toast('Please enter product name', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (!productData.parentCollection) {
            toast('Please select the collection', {
                className: 'custom-toaster toaster-error',
            })
        }
        return (
            productData.name.trim().length > 0 && !!productData.parentCollection
        )
    }

    function createProductHandler() {
        if (validateFields()) {
            const payload = {
                name: productData.name,
                collection_id: productData.parentCollection?.id,
                team_id: productData.parentCollection?.team_id || null,
                product_variants: {
                    data: [
                        {
                            name: productData.name,
                            collection_id: productData.parentCollection?.id,
                            team_id:
                                productData.parentCollection?.team_id || null,
                            is_primary: true,
                            due_date:
                                collectionData?.collections_by_pk.due_date ||
                                null,
                            todos: {
                                data: initialProductTodos.data.map((todo) => ({
                                    name: todo.name,
                                    types: todo.types,
                                    user_id: todo.assignee_id || null,
                                    due_date: todo.dueDate
                                        ? new Date(
                                              new Date(
                                                  todo.dueDate
                                              ).setUTCHours(23, 59, 59)
                                          ).toISOString()
                                        : null,
                                })),
                            },
                        },
                    ],
                },
            }
            createProductMutation({
                variables: {
                    object: payload,
                },
                refetchQueries: [
                    'GetCollections',
                    'GetProducts',
                    'fetchCollectionById',
                    'GetCollectionProducts',
                    {
                        query: GetProductsForCollection,
                        variables: {
                            id: productData.parentCollection?.id,
                        },
                    },
                ],
                onCompleted: (data) => {
                    onClose()
                    toast('Product created Successfully!', {
                        className: 'custom-toaster toaster-success',
                        onClick: () => {
                            navigate(
                                `/collections/product/${data.insert_products_one.id}`
                            )
                        },
                    })
                },
            })
        }
    }

    // Focus on input on modal open
    useEffect(() => {
        inputRef.current?.focus()
    }, [inputRef.current])

    return (
        <Modal
            disablePrimaryButton={
                isStringEmpty(productData.name) ||
                productData.parentCollection === null
            }
            isModal={isOpen}
            onClose={onClose}
            onClick={createProductHandler}
            primaryButtonTitle="Create Product"
            secondaryButtonTitle="Cancel"
            title="New Product"
            maxWidth="300px"
            isLoading={isCreatingProduct}
            bg="white"
        >
            <Box
                px="24px"
                height="100%"
                mb="40px"
                mt="28px"
                display="flex"
                flexDirection="column"
                gridGap="24px"
                width="100%"
            >
                <InputTitleWithIcon
                    icon={<ProductIco color="#778CA2" />}
                    title="Product Name"
                    childrenWidth="80%"
                >
                    <Input
                        autoComplete="false"
                        id="product_form_name"
                        value={productData.name}
                        bg="white"
                        ref={inputRef}
                        onChange={(e) =>
                            setProductData({
                                ...productData,
                                name: e.target.value,
                            })
                        }
                        color="primary"
                        border="none"
                        placeholder="Type Product Name"
                        className="placeholder-light"
                        width="100%"
                        fontSize="16px"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                createProductHandler()
                            }
                        }}
                    />
                </InputTitleWithIcon>
                {!initialParentCollection && (
                    <InputTitleWithIcon
                        icon={<CollectionIco />}
                        title="Collection"
                    >
                        <CustomDropdown
                            label=""
                            minWidth="180px"
                            labelMaxWidth="180px"
                            maxHeight="240px"
                            selectedText={
                                productData.parentCollection?.name ||
                                'Choose Collection'
                            }
                            items={collections || []}
                            noStyle={true}
                            showArrow={true}
                            labelFontSize="12px"
                            highlightedTextFontSize="16px"
                            maxWidth="200px"
                            onItemClicked={(value) =>
                                setProductData({
                                    ...productData,
                                    parentCollection: {
                                        id: value.id,
                                        name: value.name,
                                        team_id: value.team_id,
                                    },
                                })
                            }
                            enableSearch
                        />
                    </InputTitleWithIcon>
                )}
            </Box>
        </Modal>
    )
}

export default CreateProductModal
