import React from 'react'
import { InlineError } from 'src/components/inline-error/inline-error'
import { InputLabel } from 'src/components/input-label/input-label'
import { Box, Text } from 'src/components/utility'

export const ForgotPassword = ({
    setEmail,
    isError,
    setIsError,
}: {
    setEmail: (email: string) => void
    isError: boolean
    setIsError: (x: boolean) => void
}) => {
    return (
        <Box
            px="24px"
            pt="24px"
            pb="52px"
            display="flex"
            flexDirection="column"
            gridRowGap="24px"
        >
            <Text fontFamily="Rubik" color="textTags">
                To reset your password, provide your registered email.
            </Text>
            <InputLabel
                type="email"
                isError={false}
                placeholder="Enter email address"
                errorText=""
                isDisabled={false}
                label="Email address"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsError(false)
                    setEmail(e.target.value)
                }}
            />
            {isError && (
                <InlineError
                    width="500px"
                    text="Please enter a valid email address"
                ></InlineError>
            )}
        </Box>
    )
}
