import type { storeState } from './@types/slider-types'

const create3dRendererSlice = (set: Function) => ({
    isUpload: false,
    isChanged: false,
    // isLoading: false,
    setUpload: (payload: { isUpload: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isUpload: payload.isUpload,
            }
        })
    },
    setChanged: (payload: { isChanged: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isChanged: payload.isChanged,
            }
        })
    },
    // setLoading: (payload: { isLoading: boolean }) => {
    //     set((state: storeState) => {
    //         return {
    //             ...state,
    //             isLoading: payload.isLoading,
    //         }
    //     })
    // },
})

export default create3dRendererSlice
