import { gql } from '@apollo/client'

export const InsertTeamMembers = gql`
    mutation AddTeamMember($objects: [team_members_insert_input!]!) {
        insert_team_members(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`
