import { useState, useRef, useEffect } from 'react'
import {
    LogoIcoSm,
    PasswordHidden,
    PasswordVisible,
} from 'src/components/svg-icons'
import { Box, Text, Button, Input } from 'src/components/utility'
import { useLocation, useNavigate } from 'react-router-dom'
import { Rules } from 'src/screens-v2/signup/rules'
import Skeleton from 'react-loading-skeleton'

import { ColoredButton } from 'src/components/buttons/colored-button'
import { InlineError, Loader } from 'src/components'
import { checkActionCode, confirmPasswordReset, getAuth } from 'firebase/auth'
import axios from 'axios'
import { GET_USERNAME } from 'src/services'
import { toast } from 'react-toastify'
import { debugLog, getLocalStorage } from 'src/helpers'

const PasswordReset = () => {
    const navigate = useNavigate()
    const auth = getAuth()
    const state = useLocation()
    const [email, setEmail] = useState<string>('')
    const [userInputs, setUserInputs] = useState({
        email: '',
        password: '',
        name: '',
    })
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>()
    const [isValidPassword, setIsValidPassword] = useState<boolean>(false)
    const [isRules, setIsRules] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorStates, setErrorStates] = useState({
        email: false,
        password: false,
        name: false,
    })
    useEffect(() => {
        const user = getLocalStorage('user')
        const authToken = getLocalStorage('authToken')
        if (user && authToken && user.length > 0 && authToken.length > 0) {
            navigate('/')
        }
    }, [])

    async function resetPassword(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const oobCode = new URLSearchParams(state.search).get('oobCode')
        if (!isValidPassword)
            return setErrorStates({ ...errorStates, password: true })
        if (isValidPassword) {
            setIsLoading(true)
            if (oobCode) {
                try {
                    await confirmPasswordReset(
                        auth,
                        oobCode,
                        userInputs.password
                    )
                    toast('Password reset was successful', {
                        className: 'custom-toaster toaster-success',
                    })
                    navigate('/login')
                    setIsLoading(false)
                } catch (e) {
                    setIsLoading(false)
                    toast(
                        'Error occurred while resetting password, Please try again.',
                        {
                            className: 'custom-toaster toaster-error',
                        }
                    )
                }
            }
        } else {
            setIsLoading(false)
        }
    }

    const passwordRef = useRef<HTMLInputElement | null>(null)

    function onChangeHandler(key: string, text: string) {
        setUserInputs((userInputs) => {
            return { ...userInputs, [key]: text }
        })
        setErrorStates((errorStates) => {
            return { ...errorStates, [key]: false }
        })
    }

    useEffect(() => {
        ;(async function () {
            const oobCode = new URLSearchParams(state.search).get('oobCode')

            if (oobCode) {
                try {
                    const codeData = await checkActionCode(auth, oobCode)
                    if (codeData.data.email && codeData.data.email.length <= 0)
                        return navigate('/forgot-password')
                    setUserInputs((userInputs) => {
                        return {
                            ...userInputs,
                            email: codeData.data.email
                                ? codeData.data.email
                                : '',
                        }
                    })

                    // Getting username from backen
                    axios
                        .get(GET_USERNAME + codeData.data.email)
                        .then(({ data }: any) => {
                            setEmail(data.userName.name)
                        })
                        .catch((e) => {
                            debugLog(e)
                            setEmail(codeData.data.email || '')
                        })
                } catch (e) {
                    navigate('/forgot-password')
                    toast('Password reset link is expired', {
                        className: 'custom-toaster toaster-error',
                    })
                }
            } else {
                // navigate('/forgot-password')
            }
        })()
    }, [])

    return (
        <Box height="100vh">
            <form onSubmit={resetPassword}>
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    height="70%%"
                    bg="white"
                    py="72px"
                >
                    <Box
                        bg="white"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        width="550px"
                        alignItems="center"
                    >
                        <LogoIcoSm />

                        <Text
                            color="primary"
                            fontSize="32px"
                            fontFamily="Rubik"
                            fontWeight={500}
                            mt="80px"
                            mb="12px"
                        >
                            Reset Password
                        </Text>

                        <Text
                            className="text-wrap"
                            color="textSecondary"
                            fontSize="16px"
                            fontFamily="Rubik"
                            textAlign="center"
                            mt="0px"
                            mb="0px"
                        >
                            <span className="links relative">
                                {email.length < 0 && (
                                    <Skeleton
                                        count={1}
                                        containerClassName="loader"
                                    />
                                )}
                                {email}
                            </span>
                            , you can create a new password replacing your old
                            one.
                        </Text>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                            fontSize="14px"
                            position="relative"
                            width="360px"
                            mt="56px"
                        >
                            <Text
                                mt="0px"
                                mb="0px"
                                fontFamily="Rubik"
                                id="fp-new-password"
                                color={
                                    errorStates.password ? 'delay' : 'primary'
                                }
                            >
                                New Password
                            </Text>
                            <Box
                                bg="white"
                                border="solid"
                                borderWidth={1}
                                borderColor={
                                    errorStates.password ? 'delay' : 'gray350'
                                }
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                pr="16px"
                            >
                                <Input
                                    autoComplete="false"
                                    placeholder="Enter your password"
                                    bg="white"
                                    borderRadius="4px"
                                    px="16px"
                                    py="16px"
                                    fontSize="14px"
                                    className="placeholder-darkgray password"
                                    color="primary"
                                    border="none"
                                    id="fp-new-password-reset"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        onChangeHandler(
                                            'password',
                                            e.target.value
                                        )
                                    }}
                                    type={
                                        isPasswordVisible ? 'text' : 'password'
                                    }
                                    width="100%"
                                    ref={passwordRef}
                                    onFocus={() => setIsRules(true)}
                                    onBlur={() => setIsRules(false)}
                                    required
                                />
                                <Button
                                    bg="transparent"
                                    border="none"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    type="button"
                                    onClick={() =>
                                        setIsPasswordVisible(!isPasswordVisible)
                                    }
                                >
                                    {!isPasswordVisible ? (
                                        <PasswordHidden />
                                    ) : (
                                        <PasswordVisible />
                                    )}
                                </Button>
                            </Box>
                            {errorStates.password && (
                                <InlineError
                                    width="400px"
                                    text="Use atleast 8 characters. Include uppercase, lowercase characters along with a number and a special character."
                                />
                            )}
                            <Rules
                                isRuleVisible={isRules}
                                password={userInputs.password}
                                isValidPassword={setIsValidPassword}
                            />
                        </Box>
                        <Box
                            width="360px"
                            display="flex"
                            mt="40px"
                            justifyContent="center"
                        >
                            <ColoredButton
                                variant="primary"
                                onClick={() => {}}
                                width="100%"
                                disabled={isLoading}
                                type="submit"
                                id="fp-submit-password"
                            >
                                <Box
                                    display="flex"
                                    gridColumnGap="8px"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {isLoading && <Loader />} Change Password
                                </Box>
                            </ColoredButton>
                        </Box>
                    </Box>
                </Box>
            </form>
            <Box className="auth-body" width="100%" height="100%" />
        </Box>
    )
}

export default PasswordReset
