import React, { useState } from 'react'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Button, Text } from 'src/components/utility'

function CategoryListItem({
    name,
    onClick,
    onAdd,
}: {
    name: string
    onClick: () => void
    onAdd: () => void
}) {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <Box
            px="4px"
            bg="#F2F4F6"
            onClick={onClick}
            className="cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Text my="4px" fontSize={'14px'} color="primary">
                {name}
            </Text>
            {isHovered && (
                <Box display="flex" alignItems="center" gridGap="8px">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        bg="#F0F2F3"
                    >
                        <Text my="0px" onClick={onAdd}>
                            + Add
                        </Text>
                    </Button>
                    <RightArrow />
                </Box>
            )}
        </Box>
    )
}

export default CategoryListItem
