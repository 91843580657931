import * as React from 'react'
import { Box } from 'src/components/utility'
import TodosDashboardCard from './todos/todos.dashboard'
import CollectionDashboardCard from './collections/collections.dashboard'
import TeamsDashboardCard from './teams/teams.dashboard'
import { RecentCommentsList } from './recent-comments/recent-comments'
import ProductDashboardCard from './product/product.dashboard'
import { useUser } from 'src/helpers'

export interface IDashboardCardContainerProps {}

export default function DashboardCardContainer(
    props: IDashboardCardContainerProps
) {
    const { role } = useUser()
    let layout = [
        <TodosDashboardCard />,
        <RecentCommentsList />,
        <CollectionDashboardCard />,
        <TeamsDashboardCard />,
    ] // By Default Designer

    if (role === 'admin') {
        layout = [<CollectionDashboardCard height="700px" />]
    } else if (role === 'manager') {
        layout = [
            <TodosDashboardCard />,
            <ProductDashboardCard />,
            <CollectionDashboardCard />,
            <RecentCommentsList />,
        ]
    }

    return (
        <Box
            display="grid"
            gridGap="24px"
            gridColumnGap="24px"
            gridTemplateColumns={role === 'admin' ? '100%' : '50% 50%'}
        >
            {layout[0]}
            {layout[1]}
            {layout[2]}
            {layout[3]}
        </Box>
    )
}
