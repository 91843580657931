const createLoadingSlice = (set: Function) => ({
	isLoading: true,
	setIsLoading: (payload: boolean) => {
		set(() => {
			return {
				isLoading: payload
			}
		})
	}
})

export default createLoadingSlice
