import { Box, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { CloseIco } from '../svg-icons'

export function ProductDock() {
    const { drawerTabs, setShowDrawerDock, clearDrawerTabs } = useStore()

    const totalTabsOpen = drawerTabs.length

    return (
        <Box
            position="fixed"
            bottom="0px"
            right="0px"
            display="flex"
            style={{ borderRadius: '6px 0px 0px 0px', cursor: 'pointer' }}
            justifyContent="space-between"
            alignItems="center"
            width="205px"
            height="52px"
            bg="white"
            py="8px"
            px="24px"
            color="primary"
            boxShadow="-4px -4px 8px 0px #00000026"
            onClick={() => setShowDrawerDock(false)}
        >
            <Text
                my="0px"
                fontWeight={500}
                fontSize="16px"
            >{`${totalTabsOpen} TAB${totalTabsOpen > 1 ? 'S' : ''}`}</Text>
            <CloseIco
                onClick={(e) => {
                    e.stopPropagation()
                    clearDrawerTabs()
                    setShowDrawerDock(false)
                }}
            />
        </Box>
    )
}
