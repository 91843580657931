import * as React from 'react'
import { Box, Table, Tbody, Td, Th, Thead, Tr } from 'src/components/utility'

export interface ICollaboratorTabProps {
    // collection: Collection[]
}
// props: ICollectionTabDataProps

export default function CollaboratorTab() {
    return (
        <Box
            bg="white"
            border="solid"
            borderWidth={1}
            borderColor="gray250"
            borderRadius="8px"
            justifyContent="space-around"
            width="100%"
        >
            <Table width="100%">
                <Thead fontSize={{ md: '12px', lg: '14px' }}>
                    <Tr color="textSecondaryDarkBlue" fontFamily="Rubik">
                        <Th fontWeight={400} textAlign="left">
                            Name
                        </Th>
                        <Th fontWeight={400} textAlign="left">
                            Role
                        </Th>
                        <Th fontWeight={400} textAlign="left">
                            Teams
                        </Th>
                        <Th
                            fontWeight={400}
                            textAlign="left"
                            borderRight="solid"
                            borderWidth={3}
                            borderColor="gray250"
                        >
                            Tasks
                        </Th>
                        <Th
                            fontWeight={400}
                            textAlign="left"
                            width={{ md: 'max-content' }}
                        >
                            Completed Tasks
                        </Th>
                        <Th
                            fontWeight={400}
                            textAlign="left"
                            width={{ md: 'max-content' }}
                        >
                            Time Spent
                        </Th>
                        <Th fontWeight={400} textAlign="left">
                            On-time Tasks
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td
                            fontWeight={400}
                            textAlign="left"
                            borderRight="solid"
                            borderWidth={3}
                            borderColor="gray250"
                        >
                            <Box display="flex" alignItems="center">
                                <Box
                                    width="32px"
                                    height="32px"
                                    borderRadius="50%"
                                    bg="gray250"
                                    mr="8px"
                                />
                                <Box>
                                    <Box>John Doe</Box>
                                    <Box fontSize="12px" color="textSecondary">
                                        {/* @ts-ignore */}
                                        {/* <Box
                                            as="span"
                                            color="textSecondaryDarkBlue"
                                        >
                                            @johndoe
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    )
}
