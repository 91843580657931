import { createContext, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type TodoSectionContextValue = {
    activeMode: 'sections' | 'file_preview'
    setActiveMode: React.Dispatch<
        React.SetStateAction<'sections' | 'file_preview'>
    >
    activeFileId: number | null
    setActiveFileId: React.Dispatch<React.SetStateAction<number | null>>
    sectionId: number | null
    setSectionId: React.Dispatch<React.SetStateAction<number | null>>
    files: Array<any>
    setFiles: React.Dispatch<React.SetStateAction<any>>
    isSplitMode: boolean
    setIsSplitMode: React.Dispatch<React.SetStateAction<boolean>>
}

const TodoSectionContext = createContext<TodoSectionContextValue>({
    activeMode: 'sections',
    setActiveMode: () => {},
    activeFileId: null,
    setActiveFileId: () => {},
    sectionId: null,
    setSectionId: () => {},
    files: [],
    setFiles: () => {},
    isSplitMode: false,
    setIsSplitMode: () => {},
})

export function useTodoSectionContext() {
    return useContext(TodoSectionContext)
}

export function TodoSectionContextProvider({
    children,
}: {
    children: React.ReactNode
}) {
    const [searchParams] = useSearchParams()
    const [activeMode, setActiveMode] = useState<'sections' | 'file_preview'>(
        'sections'
    )
    const [activeFileId, setActiveFileId] = useState<number | null>(
        Number(searchParams.get('fileId')) || null
    )
    const [files, setFiles] = useState<any>([])
    const [sectionId, setSectionId] = useState<number | null>(null)
    const [isSplitMode, setIsSplitMode] = useState(false)

    return (
        <TodoSectionContext.Provider
            value={{
                activeMode,
                setActiveMode,
                activeFileId,
                setActiveFileId,
                files,
                setFiles,
                sectionId,
                setSectionId,
                isSplitMode,
                setIsSplitMode,
            }}
        >
            {children}
        </TodoSectionContext.Provider>
    )
}
