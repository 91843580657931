import { Box, Text } from 'src/components/utility'
import { defaultDropdownList, roles, useUser } from 'src/helpers'
import LibraryPerformance from './sub-screens/library-report'
import Overview from './sub-screens/overview'
import TeamPerformace from './sub-screens/team-performance'
import YourPerformance from './sub-screens/your-performance'

const Reports = () => {
    const { userId, role } = useUser()

    const dropdownArray: string[] = Object.values(defaultDropdownList)

    return (
        <>
            <Box py={'40px'} pb={'100px'} minHeight="100vh">
                <Text fontSize={'24px'} mt="0px" mb={'28px'}>
                    Reports
                </Text>

                {roles.designer !== role && (
                    <>
                        <Box mb={'36px'}>
                            <Overview
                                durationDropdown={dropdownArray}
                                durationList={defaultDropdownList}
                            />
                        </Box>
                        <Box mb={'36px'}>
                            <TeamPerformace
                                durationDropdown={dropdownArray}
                                durationList={defaultDropdownList}
                            />
                        </Box>
                    </>
                )}

                <Box mb={'36px'}>
                    <YourPerformance
                        durationDropdown={dropdownArray}
                        durationList={defaultDropdownList}
                    />
                </Box>

                {roles.designer !== role && (
                    <Box mb={'36px'}>
                        <LibraryPerformance
                            durationDropdown={dropdownArray}
                            durationList={defaultDropdownList}
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}
export default Reports
