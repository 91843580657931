import { gql } from '@apollo/client'

export const GetDashboardTodos = gql`
    query GetDashboardTodos(
        $order_by: [todos_order_by!] = {}
        $where: todos_bool_exp = {}
        $limit: Int = 10
        $offset: Int = 10
    ) {
        todos(
            order_by: $order_by
            where: $where
            limit: $limit
            offset: $offset
        ) {
            product_id
            due_date
            id
            name
            description
            status
            is_delayed
            duration
            product_variant {
                id
                name
                other_data
                collection {
                    id
                    name
                    manager_id
                }
            }
            other_data
            sub_todos {
                id
                heading
                files {
                    id
                }
            }
            submitted_files
            assignee {
                email
                name
                id
                other_data
                organisation_members {
                    role
                }
            }
        }
    }
`
export const GetDashboardTodosAggregate = gql`
    query GetDashboardTodosAggregate(
        $assignee_ids: bigint_comparison_exp = {}
        $approver_id: bigint!
        $id: bigint_comparison_exp = {}
    ) {
        all: todos_aggregate(
            where: {
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
        new: todos_aggregate(
            where: {
                status: { _eq: new }
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: todos_aggregate(
            where: {
                status: { _eq: inProgress }
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
        revisionRequested: todos_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
        complete: todos_aggregate(
            where: {
                status: { _eq: complete }
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: todos_aggregate(
            where: {
                status: { _eq: inReview }
                assignee: { id: $assignee_ids }
                product_variant: {
                    _or: [
                        {
                            collection: {
                                manager_id: { _neq: $approver_id }
                                id: $id
                            }
                        }
                        {
                            collection: {
                                manager_id: { _is_null: true }
                                id: $id
                            }
                        }
                    ]
                }
            }
        ) {
            aggregate {
                count
            }
        }
        toBeReviewed: todos_aggregate(
            where: {
                status: { _eq: inReview }
                assignee: { id: { _is_null: false } }
                product_variant: {
                    collection: { manager_id: { _eq: $approver_id }, id: $id }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        delayed: todos_aggregate(
            where: {
                is_delayed: { _eq: true }
                assignee: { id: $assignee_ids }
                product_variant: { collection: { id: $id } }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
