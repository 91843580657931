import type { WatchQueryFetchPolicy } from '@apollo/client'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { debounce, getLocalStorage, setLocalStorage } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import type { TGqlCollections } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import {
    DashboardAllCollections,
    DashboardYourCollections,
    GetMyCollectionsAggregate,
    GetYourCollectionsAggregate,
    SearchCollections,
} from 'src/services/graphql/query/dashboard/dashboard-collections'

export function useCollections({
    offset,
    sort,
    teamId,
    status,
    fetchPolicy = 'cache-and-network',
    pageLimit = PerPage,
    myCollections = false,
}: {
    offset: string
    sort: string
    teamId?: string
    status: 'inProgress' | 'new' | 'completed'
    statusChange?: boolean
    fetchPolicy?: WatchQueryFetchPolicy
    pageLimit?: number
    isSort?: boolean
    myCollections?: boolean
    isPaginating?: boolean
}) {
    // const [fetchDashboardCollections, dashboardCollections] = useLazyQuery<{
    //     collections: TGqlCollections[]
    // }>(DashboardCollections)

    let where = {}
    if (teamId) {
        where = {
            _eq: teamId,
        }
    } else {
        where = {
            _is_null: false,
        }
    }

    let systemSort = 'name'
    if (sort === 'Due Date') systemSort = 'due_date'
    if (sort === 'Date Created') systemSort = 'created_at'
    if (sort === 'Name') systemSort = 'name'

    // const [collections, setCollections] = useState<TGqlCollections[]>([])
    const userId = JSON.parse(getLocalStorage('user') || '{}')?.id
    const [getAggregate, aggregate] = useLazyQuery<{
        new: AggregatedDetails
        inProgress: AggregatedDetails
        completed: AggregatedDetails
    }>(myCollections ? GetYourCollectionsAggregate : GetMyCollectionsAggregate)

    const variables = {
        limit: pageLimit,
        offset: Number(offset) * PerPage,
        order_by: { [systemSort]: systemSort === 'name' ? 'asc' : 'desc' },
        status: {
            _eq: status,
        },
        teamId: where,
        managerId: myCollections
            ? {
                  _eq: userId,
              }
            : {},
        user_id: myCollections
            ? {}
            : {
                  _eq: userId,
              },
    }

    if (myCollections) {
        //@ts-ignore
        delete variables.user_id
    } else {
        //@ts-ignore
        delete variables.managerId
    }

    const { data, loading, fetchMore, networkStatus } = useQuery<{
        collections: TGqlCollections[]
    }>(myCollections ? DashboardYourCollections : DashboardAllCollections, {
        fetchPolicy: fetchPolicy,
        notifyOnNetworkStatusChange: true,
        variables,
        onCompleted: (data) => {
            fetchAggregate({ myCollections })
        },
        onError: () => {},
    })

    function fetchAggregate({
        myCollections = false,
    }: {
        myCollections?: boolean
    }) {
        const aggregateVars = {
            managerId: { _eq: userId },

            user: {
                id: { _eq: userId },
            },
        }
        if (myCollections) {
            // @ts-ignore
            delete aggregateVars.user
        } else {
            // @ts-ignore
            delete aggregateVars.managerId
        }
        getAggregate({
            fetchPolicy: 'no-cache',
            variables: aggregateVars,
        })
    }

    return {
        // getDashboardCollections,
        fetchMore: () =>
            fetchMore({
                variables: {
                    ...variables,
                    offset: data?.collections.length,
                },
                updateQuery(previousQueryResult, options) {
                    const accumulatedData = [
                        ...previousQueryResult.collections,
                        ...options.fetchMoreResult.collections,
                    ]
                    return {
                        collections: accumulatedData,
                    }
                },
            }),
        fetchAggregate,
        isLoading: loading,
        networkStatus,
        collections: data?.collections || [],
        collectionCounts: {
            new: aggregate.data?.new.aggregate?.count || 0,
            inProgress: aggregate.data?.inProgress.aggregate?.count || 0,
            complete: aggregate.data?.completed.aggregate?.count || 0,
        },
    }
}

export function useSearchCollection(ids?: string[]) {
    const [searchCollection, searchCollectionData] = useLazyQuery<{
        collections: {
            id: string
            name: string
        }[]
    }>(SearchCollections)

    useEffect(() => {
        searchCollection({
            variables: {
                query: '%%',
                limit: 10,
                // id: ids ? { _in: ids } : { _is_null: false },
            },
            onCompleted: (data) => {
                setCollections(data.collections)
            },
        })
    }, [])

    const [collections, setCollections] = useState<
        {
            id: string
            name: string
        }[]
    >([])

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const searchFunction = debounce(
        (query: String, ids?: string[]) => {
            searchCollection({
                variables: {
                    query: `%${query}%`,
                    limit: query.trim().length === 0 ? 10 : 100,
                    // id:
                    //     ids && ids.length > 0
                    //         ? { _in: ids }
                    //         : { _is_null: false },
                },
                onCompleted: (data) => {
                    setCollections(data.collections)
                },
            })
        },
        200,
        setTimeOutRef,
        timeOutRef
    )
    return {
        searchFunction: searchFunction as (
            query: string,
            ids?: string[]
        ) => void,
        searchedCollections: collections,
    }
}

export function useCollectionFilter(type: 'product' | 'todos') {
    const [filters, setFilters] = useState<{
        product?: {
            id: string
            name: string
        }[]
        todos?: {
            id: string
            name: string
        }[]
    }>(JSON.parse(getLocalStorage('filters') || '{}'))
    function addFilter(id: { id: string; name: string }) {
        const filters = JSON.parse(getLocalStorage('filters') || '{}')
        const filterIds = filters[type] || []
        filters[type] = [...filterIds, id]
        setFilters(filters)
        setLocalStorage('filters', JSON.stringify(filters))
    }

    function updateFilters(
        ids: {
            id: string
            name: string
        }[]
    ) {
        if (filters[type]) {
            filters[type] = ids
            setFilters(filters)
            setLocalStorage('filters', JSON.stringify(filters))
        }
    }

    // function getFilters() {
    //     const filters = JSON.parse(getLocalStorage('filters') || '{}')
    //     setFilters(filters)
    //     return filters
    // }
    return { addFilter, filters, updateFilters }
}
