import { gql } from '@apollo/client'

export const GetTags = gql`
    query getTag {
        tags {
            tag
            id
            tag_relationships {
                color
            }
        }
    }
`

export const FetchExistingsTagsByNames = gql`
    query fetchExistingsTagsByNames($tags: [String!]!) {
        tags(where: { tag: { _in: $tags } }) {
            id
            tag
        }
    }
`

export const FetchTagRelationWithEntity = gql`
    query fetchTagRelationWithEntity(
        $entity_name: String!
        $entity_ids: [bigint!]!
    ) {
        tag_relationships(
            where: {
                entity_name: { _eq: $_eq }
                entity_id: { _in: $entity_ids }
            }
        ) {
            color
            entity_id
            entity_name
            id
            tag_id
        }
    }
`

export const FetchLibraryFilesTags = gql`
    query fetchLibraryFilesTags($whereObj: tag_relationships_bool_exp!) {
        tag_relationships(where: $whereObj, distinct_on: tag_id) {
            entity_id
            entity_name
            id
            color
            tag_id
            tag {
                tag
                id
            }
        }
    }
`
export const SearchTagsByName = gql`
    query searchTagsByName($name: String) {
        tags(where: { tag: { _ilike: $name } }) {
            tag
            id
        }
    }
`
