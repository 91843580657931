import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DueDateDropdown } from 'src/components/collections/actions/due-date.tsx/due-date-dropdown'
import { GroupByDropdown } from 'src/components/collections/actions/group-by/group-by-dropdown'
import { ManagerDropdown } from 'src/components/collections/actions/manager/manager-dropdown'
import { SortDropdown } from 'src/components/collections/actions/sort/sort-dropdown'
import { Box, Button } from 'src/components/utility'

function SortAndFilter({
    activeGroup,
    setManagers,
    managers,
    setDueDate,
    dueDate,
}: {
    activeGroup: string
    setManagers: React.Dispatch<
        React.SetStateAction<
            {
                id: number
                name: string
            }[]
        >
    >
    managers: Array<{ id: number; name: string }>
    setDueDate: React.Dispatch<
        React.SetStateAction<Date | [Date | null, Date | null] | null>
    >
    dueDate: Date | [Date | null, Date | null] | null
}) {
    const resetFilters = () => {
        setManagers([])
        setDueDate(null)
        searchParams.delete('due_date')
        searchParams.delete('sort')
        searchParams.delete('manager')
        setSearchParams(searchParams)
    }

    const isFiltersActive = managers.length > 0 || dueDate
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!dueDate) return
        const date = Array.isArray(dueDate)
            ? JSON.stringify([
                  new Date(dueDate[0] || '').toISOString(),
                  new Date(dueDate[1] || '').toISOString(),
              ])
            : new Date(dueDate || '').toISOString()
        searchParams.set('due_date', date)
        setSearchParams(searchParams)
    }, [dueDate])

    useEffect(() => {
        const date = searchParams.get('due_date')
        if (date) {
            try {
                const parsed = JSON.parse(date)
                setDueDate([
                    new Date(parsed[0] || ''),
                    new Date(parsed[1] || ''),
                ])
            } catch {
                setDueDate(new Date(date))
            }
        }
    }, [searchParams.get('due_date')])

    useEffect(() => {
        const list = searchParams.get('manager')
        if (list) {
            setManagers(JSON.parse(list) as { id: number; name: string }[])
        }
    }, [searchParams.get('manager')])

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" gridGap="8px" alignItems="center">
                <GroupByDropdown />
            </Box>
            <Box display="flex" gridGap="16px" alignItems="center">
                {isFiltersActive && (
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </Button>
                )}
                <SortDropdown />
                <DueDateDropdown setDueDate={setDueDate} dueDate={dueDate} />
                <ManagerDropdown
                    setManagers={(data) => {
                        setManagers(data)
                        const managersList = data
                        searchParams.set(
                            'manager',
                            JSON.stringify(managersList)
                        )
                        setSearchParams(searchParams)
                    }}
                    managers={managers}
                />
            </Box>
        </Box>
    )
}

export default SortAndFilter
