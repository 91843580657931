import { useMemo } from 'react'
import styled from 'styled-components'
import { Span } from '../utility'

const MarkedText = styled.mark`
    color: #022143;
    // font-weight: ;
    background-color: transparent;
`

type IHighlighted = {
    text?: string
    search?: string
    unHighlighted?: string
}

export function Highlighted({
    text = '',
    search = '',
    unHighlighted = '#022143',
}: IHighlighted): JSX.Element {
    const re = useMemo(() => {
        const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g
        const escapedSearch = search.replace(SPECIAL_CHAR_RE, '\\$1')
        return new RegExp(`(${escapedSearch})`, 'i')
    }, [search])

    return (
        <Span color={unHighlighted} id="predective-output-span">
            {search === ''
                ? text
                : text
                      .split(re)
                      .filter((part) => part !== '')
                      .map((part, i) =>
                          re.test(part) ? (
                              <MarkedText key={part + i}>{part}</MarkedText>
                          ) : (
                              part
                          )
                      )}
        </Span>
    )
}
