import * as React from 'react'
import { Checkbox, Dropdown } from 'src/components'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { DropdownButton } from 'src/components/dropdown/styled.button.wrapper'
import { DropDownArrow } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import useStore from 'src/store/hooks/useStore'

export interface IStatusFilterProps {}

export default function StatusFilter(props: IStatusFilterProps) {
    const { globalSearchFilters, setDateRange, setStatus } = useStore()
    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false)
    const calendarRef = useClickedOutside(() => setIsDropdownVisible(false))
    const { getGlobalSearchData } = useGlobalSearch()

    React.useEffect(() => {
        if (isDropdownVisible) {
            getGlobalSearchData({
                query: globalSearchFilters.query,
                filters: {
                    collaborators: globalSearchFilters.collaborators
                        .filter((collaborator) => Number(collaborator.id) !== 0)
                        .map((collaborator) => String(collaborator.id)),
                    tags: globalSearchFilters.tags
                        .filter((tag) => Number(tag.id) !== 0)
                        .map((tag) => tag.name),
                    file_types: globalSearchFilters.file_types,
                    status: globalSearchFilters.status
                        .filter((status) => status.isActive)
                        .map((status) => status.id),
                    from: globalSearchFilters.dateRange || undefined,
                    to: globalSearchFilters.dateRange || undefined,
                },
            })
        }
    }, [globalSearchFilters.status])

    const statuses = globalSearchFilters.status.filter(
        (status) => status.isActive
    )
    return (
        <Box ref={calendarRef} position="relative">
            <Box
                className="cursor-pointer"
                border="solid"
                borderWidth={1}
                py="8px"
                px="16px"
                width="max-content"
                borderRadius="4px"
                borderColor="secondaryLighterBlue"
                color={statuses.length > 0 ? 'white' : 'primary'}
                bg={statuses.length > 0 ? 'primary' : 'white'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap="8px"
                position="relative"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
                <Box
                    display="flex"
                    gridColumnGap={'4px'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text my="0px">Status:</Text>
                    {statuses[0]?.label ? (
                        <Text my="0px">{statuses[0].label}</Text>
                    ) : (
                        'Select Status'
                    )}
                </Box>
                {statuses.length > 1 && (
                    <Box
                        fontSize="12px"
                        p="4px"
                        color="primary"
                        bg="white"
                        borderRadius="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        +{statuses.length - 1}
                    </Box>
                )}
                <DropDownArrow
                    color={statuses.length > 0 ? 'white' : '#022143'}
                />
            </Box>
            {isDropdownVisible && (
                <Dropdown top="42px" py="16px" active={isDropdownVisible}>
                    <>
                        {globalSearchFilters.status.map((status, key) => (
                            <DropdownButton key={key}>
                                <Checkbox
                                    onChange={(isActive) => {
                                        const statuses = [
                                            ...globalSearchFilters.status,
                                        ]
                                        statuses[key].isActive = isActive
                                        setStatus({
                                            statuses,
                                        })
                                    }}
                                    defautlCheck={status.isActive}
                                    label={
                                        status.label as
                                            | 'Completed'
                                            | 'In Progress'
                                            | 'New'
                                            | 'Revision Requested'
                                            | 'Delayed'
                                    }
                                />
                            </DropdownButton>
                        ))}
                    </>
                </Dropdown>
            )}
        </Box>
    )
}
