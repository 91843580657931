import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CollectionHeader } from 'src/components/collections/collection-header'
import { Slider } from 'src/components/slider/slider'
import { Box } from 'src/components/utility'
import Pagination from 'src/components/pagination/pagination'

import { getEntityList, pageLimit } from './collections.helper'
import SortAndFilter from './sort-and-filter'
import useFetchEntity from './useFetchEntity.hook'
import type { DueDateType, ManagerType } from './collections.types'
import CollectionsSliderElement from './collections-slider-element'
import EntityList from './entity-list/entity-list'
import { ENTITIES } from 'src/helpers/enums'
import { CardLoader } from 'src/components/collections/card-loader'

export function Collections() {
    const [searchParams, setSearchParams] = useSearchParams()

    const {
        group: activeGroup = ENTITIES.collections,
        page: currentPage = '1',
        tab: currentTab = 'all',
    } = Object.fromEntries(searchParams.entries())
    const [dueDate, setDueDate] = useState<DueDateType>(null)
    const [managers, setManagers] = useState<Array<ManagerType>>([])
    const { resourcesCount, isEntityLoading, data, refetchProduct } =
        useFetchEntity({
            dueDate,
            managers,
        })

    const entityList = getEntityList(data, activeGroup as keyof typeof ENTITIES)

    return (
        <Box display="grid" bg="#fff" gridTemplateRows="auto 1fr">
            <CollectionHeader />
            <Box
                bg="#F8FAFB"
                display="grid"
                height="calc(100vh - 140px)"
                gridTemplateRows="33px 1fr"
                gridGap="20px"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                py="20px"
                px="24px"
            >
                <SortAndFilter
                    activeGroup={activeGroup}
                    managers={managers}
                    setManagers={setManagers}
                    setDueDate={setDueDate}
                    dueDate={dueDate}
                />
                <Box
                    maxHeight="calc(100vh - 224px)"
                    display="grid"
                    gridTemplateRows="33.5px 1fr"
                >
                    <Slider
                        element={
                            <CollectionsSliderElement
                                resourcesCount={resourcesCount}
                            />
                        }
                    />
                    {entityList ? (
                        <Box overflowY="auto" className="scrollbar_none">
                            <EntityList
                                refetchProduct={() => refetchProduct()}
                                activeGroup={
                                    activeGroup as keyof typeof ENTITIES
                                }
                                entityList={entityList}
                                currentTab={currentTab}
                            />
                            <Pagination
                                className="pagination-bar"
                                currentPage={Number(currentPage)}
                                totalCount={resourcesCount[currentTab]}
                                pageSize={pageLimit}
                                onPageChange={(page: number) => {
                                    searchParams.set('page', page + '')
                                    setSearchParams(searchParams, {
                                        replace: true,
                                    })
                                }}
                            />
                        </Box>
                    ) : isEntityLoading ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            mt="8px"
                        >
                            <CardLoader skeletonCount={1} cards={5} />
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Box>
    )
}
