import React, { useState } from 'react'
import { Box, Text, Button } from '../utility'
import styled from 'styled-components'
import { Drag } from '../svg-icons'
import {
    Tag,
    DivisionLine,
    New,
    InProgress,
    RequestRevision,
    InReview,
    Completed,
} from 'src/components'
import { CompareDates, debugLog } from 'src/helpers'

type ListType = {
    heading: string
    path: string
    status: string
    tags?: {
        text: string
        color: string
    }[]
    deadline: string | ''
}

const DraggableList = styled(Box)`
    transition: none;
`

function ListDetails({ listItem, props }: { listItem: ListType; props: any }) {
    return (
        <DraggableList
            pb="12px"
            display="flex"
            flexDirection="column"
            gridRowGap="12px"
            {...props}
            bg="white"
            className="dragable-list"
            position="relative"
        >
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
            >
                <Button
                    data-movable-handle
                    px="0px"
                    height="auto"
                    bg="transparent"
                    border="none"
                    className="dragable-button"
                    position="absolute"
                >
                    <Drag />
                </Button>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Box display="flex" gridColumnGap="8px" alignItems="center">
                        <Text
                            fontFamily="Rubik"
                            fontSize="16px"
                            color="primary"
                            mt="0px"
                            mb="0px"
                        >
                            {convertIntoElipses(listItem?.heading || '')}
                        </Text>
                        <Text
                            color="textSecondary"
                            fontFamily="Rubik"
                            mt="0px"
                            mb="0px"
                            fontSize="14px"
                        >
                            {listItem?.path || ''}
                        </Text>
                        {listItem?.status.toLowerCase() === 'new' && <New />}
                        {listItem?.status.toLowerCase() === 'inprogress' && (
                            <InProgress />
                        )}
                        {listItem?.status.toLowerCase() === 'complete' && (
                            <Completed />
                        )}
                        {listItem?.status.toLowerCase() ===
                            'requestedforresubmission' && <RequestRevision />}
                        {listItem?.status.toLowerCase() === 'inreview' && (
                            <InReview />
                        )}
                    </Box>
                    <Text
                        color={
                            CompareDates(listItem?.deadline.replace("'", ''))
                                ? 'delay'
                                : 'primary'
                        }
                        fontFamily="Rubik"
                        fontSize="12px"
                    >
                        {listItem?.deadline}
                    </Text>
                </Box>
                {listItem.tags && (
                    <Box
                        display="flex"
                        gridColumnGap="4px"
                        justifyContent="start"
                        width="100%"
                    >
                        {listItem.tags &&
                            listItem?.tags?.map((tag, key) => {
                                return (
                                    <Tag
                                        key={key}
                                        element={tag.text}
                                        color={tag.color}
                                    />
                                )
                            })}
                    </Box>
                )}
            </Box>
            <DivisionLine color="gray250" width={1} />
        </DraggableList>
    )
}

export const ListItem = ({ list }: { list: UserTodos[] }) => {
    // const [items, setItems] = useState<ListType[]>([
    // 	{
    // 		heading: 'Tech Pack',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	},
    // 	{
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}, {
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}, {
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}, {
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}, {
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}, {
    // 		heading: 'Tech Pack1',
    // 		path: 'Summer 2021 / Fedora',
    // 		isNew: true,
    // 		tags: [
    // 			{ text: 'Suummer 2021', color: '#FFE395' },
    // 			{ text: 'Fedora', color: '#DDEFB2' }
    // 		],
    // 		deadline: "12 Apr '21"
    // 	}
    // ])
    debugLog(list)
    const [items, setItems] = useState<any[]>(list)
    return <></>
}

export function convertIntoElipses(name: string, length: number = 16) {
    if (name && name?.length >= length) {
        return name.slice(0, length) + '...'
    }
    return name
}
