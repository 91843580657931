import * as React from 'react'
import { Box, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { useRecentSearch } from './hooks/useRecentSearch'
import CardVariants from './cards/variants'

interface IRecentSearchProps {
    query: string
    isSearchActive: boolean
    setIsSearchActive: (isActive: boolean) => void
}

export function RecentSearch(props: IRecentSearchProps) {
    const searchRef = useClickedOutside(() => props.setIsSearchActive(false))
    const { fetchRecentSearch, recentSearch } = useRecentSearch()

    React.useEffect(() => {
        if (!props.isSearchActive) return
        fetchRecentSearch()
    }, [props.isSearchActive])

    if (!props.isSearchActive) return <></>
    return (
        props.query.length <= 0 && (
            <Box
                zIndex={15}
                position="absolute"
                bg="white"
                width="100%"
                maxHeight={window.innerHeight - 64 + 'px'}
                overflow="auto"
                top="32px"
                px="16px"
                ref={searchRef}
                py="24px"
                boxShadow="0px 2px 12px 0px #999BA840;"
            >
                <Text my="0px">Recent</Text>
                {recentSearch?.collections?.map((collection) => (
                    <CardVariants
                        onCardClick={() => props.setIsSearchActive(false)}
                        entity={collection}
                        variant="collection"
                    />
                ))}
                {recentSearch?.products?.map((product) => (
                    <CardVariants
                        onCardClick={() => props.setIsSearchActive(false)}
                        entity={product}
                        variant="product"
                    />
                ))}
                {recentSearch?.todos?.map((todo) => (
                    <CardVariants
                        onCardClick={() => props.setIsSearchActive(false)}
                        entity={todo}
                        variant="todo"
                    />
                ))}
                {recentSearch?.library_files?.map((files) => (
                    <CardVariants
                        onCardClick={() => props.setIsSearchActive(false)}
                        entity={files}
                        variant="file"
                    />
                ))}
                {recentSearch?.collaborators?.map((file) => (
                    <CardVariants
                        onCardClick={() => props.setIsSearchActive(false)}
                        entity={file}
                        variant="user"
                    />
                ))}
            </Box>
        )
    )
}
