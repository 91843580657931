export function formatColorCode(input: string) {
    // Regex pattern to match RGB or RGBA values with optional spaces after commas
    const rgbRegex =
        /(?:\(|\[)?\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:\)|\])?(?:,\s*(\d*\.?\d+))?/

    // Check if input is in RGB/RGBA format
    const match = input.match(rgbRegex)
    if (match) {
        const [, r, g, b, a] = match
        const alpha = typeof a !== 'undefined' ? `, ${a}` : '' // Include alpha if present

        return `rgb(${r},${g},${b}${alpha})`
    }

    // If not in RGB/RGBA format, assume it's a hex code
    return input
}
