import { useMutation } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { InputTitleWithIcon } from 'src/components/input-title-with-icon/input-title-with-icon'
import { Modal } from 'src/components/modals/modal'
import { CollectionIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'
import { isStringEmpty } from 'src/helpers/is-string-empty'
import { InsertNewCollection } from 'src/services/graphql/mutations/insert-collection'

function CreateCollectionModal({
    isOpen,
    onClose,
}: {
    isOpen: boolean
    onClose: () => void
}) {
    const [collectionName, setCollectionName] = useState('')
    const [insertCollectionMutation, { loading: isInsertingCollection }] =
        useMutation(InsertNewCollection)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const navigate = useNavigate()

    function createCollectionHandler(collectionName: string) {
        if (!isStringEmpty(collectionName)) {
            insertCollectionMutation({
                variables: {
                    object: {
                        name: collectionName,
                    },
                },
                refetchQueries: ['GetCollections', 'getCollectionsByUserId'],
                onCompleted: (data) => {
                    toast('Collection created Successfully!', {
                        className: 'custom-toaster toaster-success',
                        onClick: () => {
                            navigate(
                                `/collections/${data.insert_collections_one.id}`
                            )
                        },
                    })
                    onClose()
                },
            })
        } else {
            toast('Please enter collection name', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    // Focus on input field when modal loads
    useEffect(() => {
        if (inputRef.current) {
            setTimeout(() => {
                inputRef!.current!.focus()
            }, 0)
        }
    }, [inputRef.current])

    return (
        <Modal
            disablePrimaryButton={isStringEmpty(collectionName)}
            isModal={isOpen}
            onClose={onClose}
            onClick={() => createCollectionHandler(collectionName)}
            primaryButtonTitle="Create Collection"
            secondaryButtonTitle="Cancel"
            title="New Collection"
            maxWidth="300px"
            bg="white"
            isLoading={isInsertingCollection}
        >
            <Box px="24px" height="100%" mb="40px" mt="28px" width="100%">
                <InputTitleWithIcon
                    icon={<CollectionIco color="#778CA2" />}
                    title=""
                    childrenWidth="80%"
                    childrenDirection="row"
                >
                    <Input
                        ref={inputRef}
                        autoComplete="false"
                        id="collection_form_name"
                        value={collectionName}
                        bg="white"
                        onChange={(e) => setCollectionName(e.target.value)}
                        color="primary"
                        border="none"
                        fontSize={'16px'}
                        py="4px"
                        px={'4px'}
                        placeholder="Untitled Collection"
                        className="placeholder-light"
                        width="100%"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                createCollectionHandler(collectionName)
                            }
                        }}
                    />
                </InputTitleWithIcon>
            </Box>
        </Modal>
    )
}

export default CreateCollectionModal
