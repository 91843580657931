import React, { useState } from 'react'
import { Modal } from 'src/components/modals/modal'
import VariantIco from 'src/components/svg-icons/variant-ico'
import { Box, Image, Input } from 'src/components/utility'

function RenameVariantsModal({
    onClose,
    onRename,
    name,
    coverPic,
    isOpen,
}: {
    onClose: () => void
    onRename: (updatedName: string) => void
    name: string
    coverPic: string
    isOpen: boolean
}) {
    const [updatedName, setUpdatedName] = useState(name)

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={() => onRename(updatedName)}
            primaryButtonTitle="Rename"
            secondaryButtonTitle="Cancel"
            title="Rename Variant"
            disablePrimaryButton={name === updatedName}
        >
            <Box
                display="flex"
                flexDirection="row"
                width="100%"
                gridGap="12px"
                justifyContent="start"
                alignItems="start"
                borderBottom="solid"
                borderColor="gray250"
                borderWidth={1}
                pb="64px"
                px="16px"
                py="24px"
            >
                <Image
                    width="53px"
                    height="53px"
                    borderRadius="4px"
                    src={coverPic}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    gridGap="8px"
                    width="100%"
                >
                    <VariantIco />
                    <Input
                        bg="transparent"
                        value={updatedName}
                        onChange={(e) => setUpdatedName(e.target.value)}
                        border="none"
                        fontSize={'16px'}
                        width="100%"
                    />
                </Box>
            </Box>
        </Modal>
    )
}

export default RenameVariantsModal
