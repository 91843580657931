export function HomeIco() {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.483.2a.683.683 0 00-.966 0L1.664 6.053.2 7.518a.683.683 0 10.966.966l.297-.298v6.156c0 .378.306.683.683.683h11.708a.683.683 0 00.683-.682V8.184l.297.298a.683.683 0 10.966-.966L8.483.2zm4.688 6.62L8 1.648l-5.17 5.17v6.84h10.34v-6.84z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
