import * as React from 'react'
import { Checkbox, Dropdown } from 'src/components'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { useSearchCollaborators } from 'src/components-v2/global-search/hooks/useSearchCollaborators'
import { DropdownButton } from 'src/components/dropdown/styled.button.wrapper'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { DropDownArrow, SearchIco } from 'src/components/svg-icons'
import { Box, Input, Text } from 'src/components/utility'
import { getImageUrl, getSentenceCase } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import useStore from 'src/store/hooks/useStore'

export interface ICollaboratorFilterProps {}

export default function CollaboratorFilter(props: ICollaboratorFilterProps) {
    const { globalSearchFilters, setDateRange } = useStore()
    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false)
    const [collaboratorFilterArray, setCollaboratorFilterArray] =
        React.useState<{
            [name: string]: boolean
        }>({})

    const { getGlobalSearchData } = useGlobalSearch()
    const { searchCollaborator, collaborators } = useSearchCollaborators()
    React.useEffect(() => {
        if (isDropdownVisible) {
            getGlobalSearchData({
                query: globalSearchFilters.query,
                filters: {
                    collaborators: globalSearchFilters.collaborators
                        .filter((collaborator) => Number(collaborator.id) !== 0)
                        .map((collaborator) => String(collaborator.id)),
                    tags: globalSearchFilters.tags
                        .filter((tag) => Number(tag.id) !== 0)
                        .map((tag) => tag.name),
                    file_types: globalSearchFilters.file_types,
                    status: globalSearchFilters.status
                        .filter((status) => status.isActive)
                        .map((status) => status.id),
                    from: globalSearchFilters.dateRange || undefined,
                    to: globalSearchFilters.dateRange || undefined,
                },
            })
        }
    }, [globalSearchFilters.collaborators])
    const collaboratorsLength = globalSearchFilters.collaborators.filter(
        (collaborator) => Number(collaborator.id) !== 0
    ).length
    const collaboratorsFiltered = globalSearchFilters.collaborators.filter(
        (collaborator) => Number(collaborator.id) !== 0
    )
    const dropdownRef = useClickedOutside(() => setIsDropdownVisible(false))
    return (
        <Box position="relative" ref={dropdownRef}>
            <Box
                className="cursor-pointer"
                border="solid"
                borderWidth={1}
                py="8px"
                px="16px"
                width="max-content"
                borderRadius="4px"
                borderColor="secondaryLighterBlue"
                color={collaboratorsLength > 0 ? 'white' : 'primary'}
                bg={collaboratorsLength > 0 ? 'primary' : 'white'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridColumnGap="8px"
                position="relative"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
                <Box
                    display="flex"
                    gridColumnGap={'4px'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text my="0px">Collaborator:</Text>

                    <Text my="0px">
                        {collaboratorsFiltered[0]?.name || 'All'}
                    </Text>
                    {collaboratorsLength > 1 && (
                        <Box
                            fontSize="12px"
                            p="4px"
                            color="primary"
                            bg="white"
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            +{globalSearchFilters.collaborators.length - 1}
                        </Box>
                    )}
                </Box>
                <DropDownArrow
                    color={collaboratorsLength > 0 ? 'white' : '#022143'}
                />
            </Box>
            {isDropdownVisible && (
                <Dropdown py="16px" top="42px" active={isDropdownVisible}>
                    <Box>
                        <Box px="16px">
                            <Box
                                bg="white"
                                border="solid"
                                borderColor="gray250"
                                borderWidth={1}
                                borderRadius="4px"
                                px="12px"
                                py="12px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <SearchIco color="#778CA2" />
                                <Input
                                    id="search-collaborator-input"
                                    width="100%"
                                    fontSize="14px"
                                    placeholder="Search Users"
                                    border="none"
                                    bg="transparent"
                                    onChange={(e) =>
                                        searchCollaborator(e.target.value)
                                    }
                                />
                            </Box>
                        </Box>
                        <Box mt="12px" maxHeight="400px" overflowY={'auto'}>
                            {globalSearchFilters.collaborators
                                .filter(
                                    (collaborator) =>
                                        Number(collaborator.id) !== 0
                                )
                                .map((collaborator) => {
                                    console.log(collaborator)
                                    return (
                                        <DropdownButton>
                                            <CollaboratorDropdownCard
                                                onChange={(d) =>
                                                    setCollaboratorFilterArray(
                                                        d
                                                    )
                                                }
                                                list={collaboratorFilterArray}
                                                isChecked={true}
                                                entity={collaborator}
                                            />
                                        </DropdownButton>
                                    )
                                })}
                            {collaborators.map((collaborator) => {
                                console.log(collaborator)
                                if (
                                    collaboratorFilterArray[
                                        collaborator.user.email
                                    ]
                                )
                                    return <></>
                                return (
                                    <DropdownButton>
                                        <CollaboratorDropdownCard
                                            isChecked={
                                                collaboratorFilterArray[
                                                    collaborator.user.email
                                                ]
                                            }
                                            onChange={(d) =>
                                                setCollaboratorFilterArray(d)
                                            }
                                            list={collaboratorFilterArray}
                                            entity={{
                                                id: String(
                                                    collaborator.user_id
                                                ),
                                                name: collaborator.user.name,
                                                email: collaborator.user.email,
                                                profile_img:
                                                    collaborator.user.other_data
                                                        .profile_pic,
                                                role: collaborator.role,
                                            }}
                                        />
                                    </DropdownButton>
                                )
                            })}
                        </Box>
                    </Box>
                </Dropdown>
            )}
        </Box>
    )
}

function CollaboratorDropdownCard(props: {
    entity: {
        id: string
        name: string
        email: string
        role?: string
        profile_img: string
    }
    onChange: (data: { [x: string]: boolean }) => void
    list: { [name: string]: boolean }
    isChecked: boolean
}) {
    const {
        removeCollaboratorFromSearch,
        setCollaborators,
        globalSearchFilters,
    } = useStore()
    return (
        <Checkbox
            defautlCheck={props.isChecked}
            onChange={(x) => {
                if (!x) {
                    removeCollaboratorFromSearch({
                        id: String(props.entity.id),
                    })
                    const list = { ...props.list }
                    delete list[props.entity.email]
                    props.onChange({ ...list })
                } else {
                    props.onChange({
                        ...props.list,
                        [props.entity.email]: true,
                    })
                    setCollaborators({
                        collaborators: globalSearchFilters.collaborators.concat(
                            {
                                role: props?.entity?.role || '',
                                email: props.entity.email,
                                id: String(props.entity.id),
                                name: props.entity.name,
                                profile_img: props.entity.profile_img,
                            }
                        ),
                    })
                }
            }}
            label={
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gridColumnGap="8px"
                >
                    <ProfileImg
                        size="24px"
                        fontSize="14px"
                        name={props.entity.name}
                        src={getImageUrl(props.entity.profile_img)}
                    />
                    <Text my="0px">
                        {getSentenceCase(props.entity.name || '', 'NAME')}
                    </Text>
                    <Text fontSize="12px">
                        {getSentenceCase(props.entity.role || '', 'NAME')}
                    </Text>
                </Box>
            }
        />
    )
}
