const createBlock = (entityId: string, entityName: string, name: string) => {
    if (!entityName) return null
    if (!entityId) return null
    return {
        entityId,
        entityName,
        name,
    }
}

// Parses comment in form of [string] [<@{TAG+{ID}}&{NAME}>]
export const entityInfoParser = (comment: string) => {
    let parsedComment = ''
    let pattern: string[] = []
    let flag = false
    let blocks: {
        entityId: string
        entityName: string
        name: string
    }[] = []

    comment.split('/').forEach((comment) => {
        comment.split('').forEach((letter) => {
            if (letter === '<') {
                flag = true
            }
            if (flag && !letter.includes('>')) {
                pattern.push(letter)
            } else if (flag && letter.includes('>')) {
                flag = false
                const raw = pattern.join('').replace('<@', '').replace('>', '')
                const entityBlock = raw.split('&')[0]
                if (entityBlock.includes('USERS')) {
                    const block = createBlock(
                        entityBlock.replace('USERS', ''),
                        'USERS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('PRODUCTS')) {
                    const block = createBlock(
                        entityBlock.replace('PRODUCTS', ''),
                        'PRODUCTS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('PRODUCT_VARIANTS')) {
                    const block = createBlock(
                        entityBlock.replace('PRODUCT_VARIANTS', ''),
                        'PRODUCT_VARIANTS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('SUB_TODO_SECTIONS')) {
                    const block = createBlock(
                        entityBlock.replace('SUB_TODO_SECTIONS', ''),
                        'SUB_TODO_SECTIONS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('SUB_TODOS')) {
                    const block = createBlock(
                        entityBlock.replace('SUB_TODOS', ''),
                        'SUB_TODOS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('TODOS')) {
                    const block = createBlock(
                        entityBlock.replace('TODOS', ''),
                        'TODOS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('COLLECTIONS')) {
                    const block = createBlock(
                        entityBlock.replace('COLLECTIONS', ''),
                        'COLLECTIONS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }

                if (entityBlock.includes('LIBRARY_FILES')) {
                    const block = createBlock(
                        entityBlock.replace('LIBRARY_FILES', ''),
                        'LIBRARY_FILES',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (
                    entityBlock.includes('FILES') ||
                    entityBlock.includes('FILE')
                ) {
                    const block = createBlock(
                        entityBlock.replace('FILES', ''),
                        'FILES',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                if (entityBlock.includes('ASSIGNED_MEDIAS')) {
                    const block = createBlock(
                        entityBlock.replace('ASSIGNED_MEDIAS', ''),
                        'ASSIGNED_MEDIAS',
                        raw.split('&')[1]
                    )
                    block && blocks.push(block)
                }
                pattern = []
            } else {
                parsedComment += letter
            }
        })
    })
    return {
        blocks,
    }
}
