import { useQuery } from '@apollo/client'
import axios from 'axios'
import type { ChartConfiguration } from 'chart.js'
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    SubTitle,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { CollectionIco } from 'src/components/svg-icons/reports/collection'
import { Box, Span, Text } from 'src/components/utility'
import { debugLog, getLocalStorage, prependZero } from 'src/helpers'
import { durationFilter } from 'src/helpers/duration-filter'
import {
    GetCompletedCollectionsCounts,
    GET_COLLECTION_REPORTS,
} from 'src/services'
import { GetCompletedProductsCounts } from 'src/services/graphql/query/reports.queries'
import theme from 'src/_theme'

const Overview = ({
    durationDropdown,
    durationList,
}: {
    durationDropdown: string[]
    durationList: DurationFilterList
}) => {
    const approvedGreen: string = theme.colors.approved
    const textSecondary: string = theme.colors.textSecondary

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )

    // from_date

    const [collectionBarChart, setCollectionsBarGraph] = useState<Chart>()
    const [collectionDoughnutChart, setProductsDoughnutGraph] =
        useState<Chart>()

    const collectionCounts = useQuery(GetCompletedCollectionsCounts)
    const productsCounts = useQuery(GetCompletedProductsCounts)
    const rainbowLoading = collectionCounts.loading || productsCounts.loading

    const defaultDropdowns = {
        collections: 'Collections',
        products: 'Products',
    }

    let cCounts: ReportsCounts = {
        completed: collectionCounts?.data?.completed?.aggregate?.count || 0,
        onTime: collectionCounts?.data?.ontime?.aggregate?.count || 0,
        delayed: collectionCounts?.data?.delayed?.aggregate?.count || 0,
    }

    let pCounts: ReportsCounts = {
        completed: productsCounts?.data?.completed?.aggregate?.count || 0,
        onTime: productsCounts?.data?.ontime?.aggregate?.count || 0,
        delayed: productsCounts?.data?.delayed?.aggregate?.count || 0,
    }

    const [categoryFilter, applyCategoryFilter] = useState<string>('')
    const [duration, applyDurationFilter] = useState<string>('')
    const [durationValues, setDurationValues] = useState<any>({
        from: '',
        to: '',
    })
    const [counts, setDoughnutCounts] = useState<ReportsCounts>({
        completed: 0,
        onTime: 0,
        delayed: 0,
    })
    const [onTimePercentage, setOnTimePercentage] = useState<number>(0)

    const getOverviewReport = async (entity = 'collections') => {
        try {
            const queryParams: any = {
                groupBy: 'monthly',
                entity: entity.toLowerCase(),
                from: durationValues.from,
                to: durationValues.to,
            }

            const { data } = await axios.get(GET_COLLECTION_REPORTS, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
                params: queryParams,
            })

            if (data) {
                debugLog({ data })
                const c_p_data = data.data
                const labels = Object.keys(c_p_data)
                const values = Object.values(c_p_data)
                buildBarGraphChart(labels, values)
            }
        } catch (error) {
            debugLog({ error })
        }
    }

    useEffect(() => {
        applyDurationFilter(durationList.thisMonth)
        applyCategoryFilter(defaultDropdowns.collections)
    }, [])

    useEffect(() => {
        getOverviewReport(categoryFilter)
    }, [categoryFilter, durationValues])

    useEffect(() => {
        const fromToDates = durationFilter(duration)
        debugLog({
            fromToDates,
        })

        setDurationValues(fromToDates)
    }, [duration])

    const centerText = {
        zIndex: 2,
        width: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '50%',
    }

    const getPercentage = (partial: number, total: number) => {
        return Number(Number((partial / total) * 100).toFixed(0)) || 0
    }

    const buildDoughnutChart = () => {
        if (rainbowLoading) {
            return
        }

        if (collectionDoughnutChart) {
            collectionDoughnutChart.destroy()
        }

        let onTimePercentage: number = getPercentage(
            cCounts.onTime,
            cCounts.completed
        )
        let countsArray = [cCounts.onTime, cCounts.delayed]
        setDoughnutCounts(cCounts)

        if (categoryFilter === defaultDropdowns.products) {
            countsArray = [pCounts.onTime, pCounts.delayed]
            setDoughnutCounts(pCounts)
            onTimePercentage = getPercentage(pCounts.onTime, pCounts.completed)
        }

        setOnTimePercentage(onTimePercentage)
        const data = {
            labels: ['On time', 'Delayed'],
            datasets: [
                {
                    label: 'Collections Completed',
                    data: countsArray,
                    backgroundColor: [approvedGreen, 'rgba(57,164,7,0.5)'],
                    hoverOffset: 4,
                    barThickness: 34,
                },
            ],
        }

        const config: any = {
            type: 'doughnut',
            data: data,
            options: {
                maintainAspectRatio: false,
                cutout: '65%',
                rotation: -90,
                circumference: 180,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }
        const ctx: any = document.getElementById('collectionDoughnut')
        let chartCreated: Chart = new Chart(ctx, config)
        setProductsDoughnutGraph(chartCreated)
    }

    useEffect(() => {
        buildDoughnutChart()
    }, [categoryFilter, rainbowLoading])

    const buildBarGraphChart = (labels: any[], values: any[]) => {
        try {
            if (collectionBarChart) {
                collectionBarChart.destroy()
            }

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: categoryFilter,
                        data: values,
                        backgroundColor: ['rgba(57,164,7,0.5)', approvedGreen],
                        borderWidth: 0,
                        barThickness: 24,
                    },
                ],
            }

            const config: ChartConfiguration = {
                type: 'bar',
                data: data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            ticks: {
                                color: textSecondary,
                                font: {
                                    size: 12,
                                },
                                stepSize: 20,
                            },
                            grid: {
                                color: '#E8ECEF',
                            },
                        },
                        x: {
                            ticks: {
                                color: textSecondary,
                                font: {
                                    size: 12,
                                },
                                stepSize: 1,
                            },
                            grid: {
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                },
            }

            const ctx: any = document.getElementById('collectionBarChart')
            let chartCreated: Chart = new Chart(ctx, config)
            setCollectionsBarGraph(chartCreated)
        } catch (error) {
            debugLog(error)
        }
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={'20px'}
            >
                <Box display="flex" alignItems="center">
                    <Text fontSize={'20px'} my="0px">
                        {' '}
                        Overview
                    </Text>

                    <Box display="inline-flex" ml={'16px'}>
                        <CustomDropdown
                            label=""
                            noStyle={false}
                            height="32px"
                            border="1px solid #A7B9D1"
                            showArrow={true}
                            selectedText={categoryFilter}
                            items={[
                                defaultDropdowns.collections,
                                defaultDropdowns.products,
                            ]}
                            onItemClicked={applyCategoryFilter}
                        />
                    </Box>
                </Box>
                <Box>
                    <Box display="inline-flex">
                        <CustomDropdown
                            label=""
                            minWidth="124px"
                            left="unset"
                            right="0px"
                            noStyle={false}
                            height="32px"
                            border="1px solid #A7B9D1"
                            showArrow={true}
                            selectedText={duration}
                            items={durationDropdown}
                            onItemClicked={applyDurationFilter}
                        />
                    </Box>
                </Box>
            </Box>

            <BasicCard>
                <Box display="flex">
                    <Box
                        bg="rgba(242, 244, 246, 0.6)"
                        height="325px"
                        p={'32px'}
                        borderRight="1px solid #E8ECEF"
                        minWidth="310px"
                        width="310px"
                    >
                        <Box display="flex" mb={'4px'} alignItems="center">
                            <CollectionIco />
                            <Text
                                my={'0px'}
                                lineHeight="24px"
                                fontSize={'20px'}
                                ml="12px"
                            >
                                {categoryFilter} Completed
                            </Text>
                        </Box>
                        <Text
                            my={'0px'}
                            mb="24px"
                            fontSize="48px"
                            lineHeight="58px"
                        >
                            {prependZero(counts.completed)}
                        </Text>

                        <Box position="relative">
                            {onTimePercentage > 0 && (
                                <Box
                                    position="absolute"
                                    style={centerText}
                                    textAlign="center"
                                >
                                    <Text fontSize={'16px'} my={'0px'}>
                                        {onTimePercentage}%
                                    </Text>
                                    <Text
                                        fontSize={'10px'}
                                        color="textSecondary"
                                        my={'0px'}
                                    >
                                        On-time Collection Completion
                                    </Text>
                                </Box>
                            )}
                            <Box
                                height="114px"
                                position="relative"
                                width="100%"
                                display="block"
                            >
                                <canvas id="collectionDoughnut"></canvas>
                            </Box>
                        </Box>

                        {counts.completed !== 0 && (
                            <Box
                                display="flex"
                                mt={'8px'}
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    gridColumnGap={'8px'}
                                    alignItems="center"
                                >
                                    <Box
                                        height="10px"
                                        bg={approvedGreen}
                                        width="10px"
                                    >
                                        {' '}
                                    </Box>
                                    <Text my={'0px'} fontSize={'12px'}>
                                        {' '}
                                        {counts.onTime}{' '}
                                        <Span color="textSecondary">
                                            - On time
                                        </Span>{' '}
                                    </Text>
                                </Box>
                                <Box
                                    display="flex"
                                    gridColumnGap={'8px'}
                                    alignItems="center"
                                >
                                    <Box
                                        height="10px"
                                        bg="rgba(57,164,7,0.5)"
                                        width="10px"
                                    >
                                        {' '}
                                    </Box>
                                    <Text my={'0px'} fontSize={'12px'}>
                                        {' '}
                                        {counts.delayed}{' '}
                                        <Span color="textSecondary">
                                            - Delayed
                                        </Span>{' '}
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box
                        position="relative"
                        width="100%"
                        height={'261px'}
                        display="block"
                    >
                        <Text
                            my={'0px'}
                            style={{
                                position: 'absolute',
                                transform: 'rotate(-90deg) translateX(-100%)',
                                left: '-30px',
                            }}
                            color={textSecondary}
                            fontSize={'12px'}
                        >
                            {categoryFilter} Completed
                        </Text>
                        <Box
                            p={'24px'}
                            pl={'48px'}
                            position="relative"
                            width="100%"
                            display="block"
                        >
                            <canvas
                                height={'278px'}
                                id="collectionBarChart"
                            ></canvas>
                        </Box>
                    </Box>
                </Box>
            </BasicCard>
        </>
    )
}

export default Overview
