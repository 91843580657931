import styled from 'styled-components'
import {
	color,
	layout,
	space,
	border,
	flexbox,
	position,
	typography,
	grid
} from 'styled-system'
// , sans-serif
const Button = styled.button<ButtonTypes>`
    ${color}
    ${layout}
    ${space}
    ${border}
    ${position}
    ${typography}
    ${flexbox}
    ${grid}
    font-family : Rubik !important;
    cursor:pointer;
    &:disabled {
        opacity: 40%;
    }
`
export default Button
