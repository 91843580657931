import { useEffect } from 'react'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'

function useFetchComments({
    entityId,
    entityName,
}: {
    entityId: number
    entityName: string
}) {
    const { status, sendMessage, isEntityCommentsPresent } = useCommentsStore()

    useEffect(() => {
        if (
            status === 'active' &&
            !isEntityCommentsPresent(String(entityId), entityName)
        ) {
            sendMessage(`{
                        "type": "comments",
                        "payload": {
                            "operation": "GET",
                            "variables": {
                                "eId": "${entityId}",
                                "eName": "${entityName}"
                            }
                        }
                    }`)
        }
    }, [status, entityId, entityName])

    return null
}

export default useFetchComments
