import * as React from 'react'

export const SuccessTick = () => (
    <svg
        width={104}
        height={104}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.993 52c0-16.02 12.987-29.007 29.007-29.007 6.91 0 13.25 2.413 18.232 6.445a2.83 2.83 0 1 0 3.56-4.4A34.534 34.534 0 0 0 52 17.333c-19.146 0-34.666 15.521-34.666 34.667S32.853 86.667 52 86.667c19.146 0 34.667-15.521 34.667-34.667 0-3.337-.472-6.568-1.356-9.628a2.83 2.83 0 1 0-5.437 1.57A29.025 29.025 0 0 1 81.007 52c0 16.02-12.987 29.007-29.007 29.007-16.02 0-29.007-12.987-29.007-29.007Zm60.414-27.305a3.539 3.539 0 0 1 4.964 5.045l-36.7 36.142a1 1 0 0 1-1.409-.006L36.814 52.428a3.537 3.537 0 1 1 5.002-5.002l9.148 9.148 32.443-31.88Z"
            fill="#39A407"
        />
    </svg>
)

export const SuccessTickSm = ({
    color = '#39A407',
    title,
}: {
    color?: string
    title?: string
}) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        {title && <title>{title}</title>}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.306 12a6.694 6.694 0 0 1 10.901-5.207.653.653 0 1 0 .822-1.015 8 8 0 1 0 2.658 4 .653.653 0 1 0-1.255.362A6.694 6.694 0 1 1 5.306 12Zm13.942-6.301a.817.817 0 0 1 1.145 1.164l-7.925 7.805a1 1 0 0 1-1.409-.005l-2.563-2.564a.816.816 0 0 1 1.154-1.155l2.11 2.111L19.249 5.7Z"
            fill={color}
        />
    </svg>
)
