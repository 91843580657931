import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export default function TemplateCardMenuDropdown({
    active,
    onClose,
    onDelete,
    onEdit,
    onDuplicate,
    onFavourite,
    isFavourite,
    isOwner,
}: {
    active: boolean
    onClose: () => void
    onDelete: () => void
    onEdit: () => void
    onFavourite: () => void
    onDuplicate: () => void
    isFavourite: boolean
    isOwner: boolean
}) {
    const wrapperRef = useClickedOutside(onClose, ['template-menu-button'])

    return (
        <Dropdown
            active={active}
            top="28px"
            left="none"
            right="0px"
            width="220px"
        >
            <Box
                ref={wrapperRef}
                width="100%"
                onClick={(e) => e.stopPropagation()}
            >
                {/* {isOwner && (
                    <DropdownButtonWrapper value="edit_template" width="100%">
                        <Button
                            width="100%"
                            textAlign="left"
                            height="100%"
                            fontFamily="Rubik"
                            color="primary"
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                            onClick={(e) => {
                                e.stopPropagation()
                                onEdit()
                            }}
                            px="0px"
                        >
                            Edit Template
                        </Button>
                    </DropdownButtonWrapper>
                )} */}
                <DropdownButtonWrapper value="edit_template" width="100%">
                    <Button
                        width="100%"
                        textAlign="left"
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={(e) => {
                            e.stopPropagation()
                            onDuplicate()
                        }}
                        px="0px"
                    >
                        Duplicate Template
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="edit_template" width="100%">
                    <Button
                        width="100%"
                        textAlign="left"
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={(e) => {
                            e.stopPropagation()
                            onFavourite()
                        }}
                        px="0px"
                    >
                        {isFavourite
                            ? 'Remove from Favourites'
                            : 'Add to Favourites'}
                    </Button>
                </DropdownButtonWrapper>
                {isOwner && (
                    <DropdownButtonWrapper value="delete_template" width="100%">
                        <Button
                            width="100%"
                            textAlign="left"
                            fontFamily="Rubik"
                            color="danger"
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                            onClick={(e) => {
                                e.stopPropagation()
                                onDelete()
                            }}
                            px="0px"
                        >
                            Delete Template
                        </Button>
                    </DropdownButtonWrapper>
                )}
            </Box>
        </Dropdown>
    )
}
