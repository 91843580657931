import { useQuery } from '@apollo/client'
import React from 'react'
import ProductListVariantContainer from 'src/components-v2/product-variants/product-list-variant-container.tsx/product-list-variant-container'
import { CardLoader } from 'src/components/collections/card-loader'
import { Box } from 'src/components/utility'
import { GetProductsForCollection } from 'src/services'

function CollectionProductsContainer({
    collectionId,
}: {
    collectionId: number
}) {
    const {
        data: collectionData,
        loading: isCollectionLoading,
        refetch,
    } = useQuery<any>(GetProductsForCollection, {
        variables: {
            id: collectionId,
        },
    })

    const collection = collectionData?.collections_by_pk
    const products = collection?.products

    if (isCollectionLoading) {
        return <CardLoader skeletonCount={2} />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            pt="8px"
            pl="12px"
            gridGap="8px"
            borderLeft="1px solid"
            borderColor="#A7B9D1"
        >
            {products?.map((product: any) => {
                return (
                    <ProductListVariantContainer
                        refetch={refetch}
                        product={product}
                        key={product.id}
                    />
                )
            })}
        </Box>
    )
}

export default CollectionProductsContainer
