import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box } from 'src/components/utility'
import { debounce } from 'src/helpers'
import { OrgTabs } from 'src/helpers/enums'
import useStore from 'src/store/hooks/useStore'

interface IOrgSliderElementsProps {
    teamsCount: number
    collaboratorsCount: number
}

const OrganisationSliderElements: React.FunctionComponent<
    IOrgSliderElementsProps
> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [tabUpdate, setTabUpdate] = React.useState<string>('teams')
    // const { tab: currentTab = OrgTabs.teams } = Object.fromEntries(
    //     searchParams.entries()
    // )

    const { clearDrawerTabs } = useStore()
    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()
    const searchFn = debounce(updateTabData, 200, setTimeOutRef, timeOutRef)

    function updateTabData() {
        setTabUpdate(searchParams.get('tab') || 'teams')
    }

    React.useEffect(() => {
        searchFn()
    }, [searchParams.get('tab')])

    return (
        <Box>
            <Tabs
                value={tabUpdate}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    clearDrawerTabs()
                    searchParams.set('tab', newValue)
                    searchParams.set('page', String(1))
                    searchParams.delete('drawer')
                    searchParams.delete('id')
                    searchParams.delete('q')
                    searchParams.set(
                        'sort',
                        newValue === OrgTabs.teams ? 'LTO' : 'az'
                    )
                    setSearchParams(searchParams)
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab
                    label={`Teams (${props.teamsCount})`}
                    value={OrgTabs.teams}
                />
                <Tab
                    label={`Collaborators (${props.collaboratorsCount})`}
                    value={OrgTabs.collaborators}
                />
                {/* <Tab label="Analytics" value={OrgTabs.analytics} /> */}
            </Tabs>
        </Box>
    )
}

export default OrganisationSliderElements
