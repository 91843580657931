import { gql } from '@apollo/client'

export const GetCollection = gql`
    query fetchCollectionById(
        $id: bigint!
        $order_by: [products_order_by!] = { id: desc }
        $due_date: timestamptz_comparison_exp = {}
        $assignee_ids: bigint_comparison_exp = {}
    ) {
        collections_by_pk(id: $id) {
            id
            created_at
            creator {
                id
                name
            }
            description
            due_date
            is_delayed
            name
            status
            tags
            company

            updated_at

            brand
            other_data
            development_cycle
            manager {
                id
                name
                email
                other_data
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
            }
            team {
                id
                name
                team_members {
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
        }
    }
`
