import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { CollectionIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import type { TSearchedCollection } from '../@types/search'
import useStore from 'src/store/hooks/useStore'
import { getImageUrl } from 'src/helpers'
import { useRecentSearch } from '../hooks/useRecentSearch'
import { useNavigate } from 'react-router-dom'

type TCollectionCardVariantProps = {
    entity: TSearchedCollection
    onCardClick?: () => void
}

export function CollectionVariant(props: TCollectionCardVariantProps) {
    const { globalSearchFilters, setGlobalSearchActive } = useStore()
    const { entity, onCardClick } = props
    const { addRecentSearch } = useRecentSearch()
    const navigate = useNavigate()
    return (
        <Box
            color="textSecondaryDarkBlue"
            display="flex"
            flexDirection="column"
            gridRowGap="8px"
            className="cursor-pointer"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            onClick={() => {
                setGlobalSearchActive({ isGlobalSearchActive: false })
                onCardClick && onCardClick()
                addRecentSearch({
                    entity_name: 'collections',
                    entity_id: entity?.id,
                    search_query: globalSearchFilters.query,
                })
                navigate('/collections/' + entity?.id)
            }}
            py="8px"
            px="4px"
        >
            <Box display="flex" gridColumnGap="12px" alignItems="center">
                <CollectionIco color="#778CA2" />
                <Highlighted
                    search={globalSearchFilters.query}
                    text={entity?.name}
                    unHighlighted="textSecondaryDarkBlue"
                />
            </Box>
            <Box display="flex" alignItems="center" gridColumnGap="16px">
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    {entity?.manager ? (
                        <>
                            {' '}
                            <ProfileImg
                                fontSize="12px"
                                size="20px"
                                src={getImageUrl(
                                    entity.manager?.other_data.profile_pic
                                )}
                                name={entity?.manager?.name}
                            />
                            <Text my="0px" fontSize="14px">
                                {entity?.manager?.name || 'No Manager'}
                            </Text>
                        </>
                    ) : (
                        <Text my="0px" fontSize="12px">
                            No Manager{' '}
                        </Text>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
