import { ButtonVariants } from 'src/components/buttons/button-variants'
import { EmptyStateIcon } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
export const EmptyTodoState = ({
    boxHeight,
    openModal,
    title,
    description,
    enableCreateButton = false,
}: {
    boxHeight: string
    openModal?: (x: boolean) => void
    title: string
    description?: string
    enableCreateButton?: boolean
}) => {
    return (
        <>
            <Box height={boxHeight} px={'16px'}>
                <Box
                    p={'32px'}
                    height="100%"
                    display="flex"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                >
                    <Box>
                        <EmptyStateIcon />
                        {title && <Text fontSize="14px">{title}</Text>}
                        {description && (
                            <Text fontSize="12px" color="textSecondaryDarkBlue">
                                {' '}
                                {description}{' '}
                            </Text>
                        )}
                        {openModal && enableCreateButton && (
                            <ButtonVariants
                                width="150px"
                                fontSize="14px"
                                onClick={() => {
                                    openModal && openModal(true)
                                }}
                                variant="outlined"
                            >
                                {' '}
                                Create To-Do{' '}
                            </ButtonVariants>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
