import React from 'react'
import { productAndtodoStatus } from 'src/components/collections/collections.helper'
import { Box, Button } from 'src/components/utility'
import CollectionStatusDropdown from './collection-status-dropdown'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'

function CollectionStatus({
    collectionStatus,
    collectionId,
    refetchCollection,
}: {
    collectionStatus: 'new' | 'inProgress' | 'complete'
    collectionId: number
    refetchCollection: () => void
}) {
    const [isStatusDropdownActive, setIsStatusDropdownActive] =
        React.useState(false)
    const { updateCollectionStatus } = useUpdateCollection()

    return (
        <Box position="relative">
            <Button
                onClick={() => {
                    setIsStatusDropdownActive(!isStatusDropdownActive)
                }}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-btn"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                {productAndtodoStatus(collectionStatus, true, true, true)}
            </Button>
            {isStatusDropdownActive ? (
                <CollectionStatusDropdown
                    active={isStatusDropdownActive}
                    setIsDropdownActive={setIsStatusDropdownActive}
                    onUpdate={(status) =>
                        updateCollectionStatus({
                            id: collectionId,
                            status,
                            onComplete: () => refetchCollection(),
                        })
                    }
                />
            ) : null}
        </Box>
    )
}

export default CollectionStatus
