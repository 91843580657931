import CollectionBadgeIco from 'src/components/svg-icons/collection-badge-ico'
import { Box, Text } from 'src/components/utility'

export function CollectionBadge() {
    return (
        <Box
            bg="#A9B8ED"
            color="#000000"
            display="flex"
            gridGap="4px"
            borderRadius="4px"
            p="4px"
            alignItems="center"
            minWidth="max-content"
            height="max-content"
        >
            <CollectionBadgeIco color="black" />
            <Text fontSize="12px" my="0px">
                Collection
            </Text>
        </Box>
    )
}
