export const EmptyFolderIco = () => (
    <svg
        width="48"
        height="41"
        viewBox="0 0 48 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8081 4.20737L17.8086 4.20895L18.332 6H2V5.33333C2 3.23202 3.43541 2 4.66667 2H15.302C16.2744 2 17.3783 2.73186 17.8081 4.20737ZM0 6V5.33333C0 2.38667 2.08833 0 4.66667 0H15.302C17.311 0 19.0937 1.46933 19.7283 3.648L20.4156 6H40.8C44.7768 6 48 9.13367 48 13V34C48 37.8663 44.7768 41 40.8 41H7.2C3.2232 41 0 37.8663 0 34V8V6ZM21 8H18.9164H2V34C2 36.709 4.27428 39 7.2 39H40.8C43.7257 39 46 36.709 46 34V13C46 10.291 43.7257 8 40.8 8H21Z"
            fill="#778CA2"
        />
    </svg>
)
