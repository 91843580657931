import * as React from 'react'
import { DeleteIco, SettingsIco } from 'src/components/svg-icons'
import {
    ConfigIco,
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { Box, Button, Image, Input, Link, Text } from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { DivisionLine } from 'src/components/division-line/division-line'
import { uuid } from 'src/helpers'
import { captureImage, createMaterial } from '../../helper/create-material'
import * as THREE from 'three'
import type { MeshCustomMaterial } from '../material/custom-material'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import ControlPanel from '../basic-panel/control-panel'

export interface IArtworkAdvancedProps {}

export default function ArtworkAdvanced(props: IArtworkAdvancedProps) {
    const { activeArtWork, highlights, setActiveArtwork, setArtwork, artWork } =
        useConfiguratorContext()

    const inputRef = React.useRef<HTMLInputElement | undefined>()
    const [activeControlPanel, setActiveControlPanel] = React.useState<{
        isActive: boolean
        mode: 'artwork' | 'fabrics' | 'graphics'
        id?: string
    }>({
        isActive: false,
        mode: 'artwork',
        id: undefined,
    })

    const artwork = activeArtWork
        ? getLocalImageUrl(
              new File([activeArtWork.blob], activeArtWork.name)
          ) || ''
        : highlights.length === 1 && highlights[0].mesh.material.map
        ? captureImage(highlights[0].mesh)
        : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'

    function handleOnUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return
        const file = e.target.files[0]
        const material = highlights[0].mesh.material as MeshCustomMaterial
        const config = {
            repeat: {
                u: material.map?.repeat.x || 1,
                v: material.map?.repeat.y || 1,
            },
            offset: {
                u: material.map?.offset.x || 0,
                v: material.map?.offset.y || 0,
            },
            rotation: material.map?.rotation || 0,
            substanceNess: 0,
            transparency: 0,
        }
        setActiveArtwork({
            blob: new Blob([file]),
            id: uuid(),
            name: file.name,
            path: file.webkitRelativePath,
            config,
        })
        setArtwork((artwork) => [
            ...artwork,
            {
                blob: new Blob([file]),
                id: uuid(),
                name: file.name,
                path: file.webkitRelativePath,
                config,
            },
        ])

        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = createMaterial(
                    getLocalImageUrl(file) || '',
                    {
                        offset: {
                            u: highlight.mesh.material.map?.offset?.x || 0,
                            v: highlight.mesh.material.map?.offset?.y || 0,
                        },
                        scale: {
                            u: highlight.mesh.material?.map?.repeat?.x || 0,
                            v: highlight.mesh.material?.map?.repeat?.y || 0,
                        },
                    }
                ).upscale
            }
        })
    }

    function handleOnDelete() {
        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = null
                highlight.mesh.material.map = null
            }
        })
        setActiveArtwork(undefined)
    }

    function uploadArtwork(
        file: File | TMapConfig,
        addNewArtwork: boolean = true
    ) {
        const material = highlights[0].mesh.material as MeshCustomMaterial
        if (!file) return
        const artWorkId = 'id' in file ? file?.id : uuid()
        let artWork: TMapConfig = {
            blob: 'blob' in file ? file.blob : new Blob([file]),
            id: artWorkId,
            name: file.name,
            path:
                'webkitRelativePath' in file
                    ? file.webkitRelativePath
                    : file.path,
            config: {
                repeat: {
                    u: material.map?.repeat.x || 1,
                    v: material.map?.repeat.y || 1,
                },
                offset: {
                    u: material.map?.offset.x || 0,
                    v: material.map?.offset.y || 0,
                },
                rotation: material.map?.rotation || 0,
                substanceNess: 0,
                transparency: 0,
            },
        }

        const fileData =
            'blob' in file ? new File([file.blob], file.name) : file

        setActiveArtwork(artWork)
        if (addNewArtwork) {
            setArtwork((previousArtwork) => [...previousArtwork, artWork])
        }
        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = createMaterial(
                    getLocalImageUrl(fileData) || '',
                    {
                        offset: {
                            u: highlight.mesh.material.map?.offset?.x || 0,
                            v: highlight.mesh.material.map?.offset?.y || 0,
                        },
                        scale: {
                            u: highlight.mesh.material?.map?.repeat?.x || 0,
                            v: highlight.mesh.material?.map?.repeat?.y || 0,
                        },
                    }
                ).upscale
            }
        })
    }

    return (
        <Box px="16px">
            <Box>
                {false && (
                    <Box>
                        <Image
                            className="img-clear"
                            width="100%"
                            height="100px"
                            src={artwork}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="20px"
                        >
                            {false && (
                                <Button
                                    p="0px"
                                    bg="transparent"
                                    border="none"
                                    onClick={() => {}}
                                >
                                    <SettingsIco />
                                </Button>
                            )}
                            <Button
                                p="0px"
                                bg="transparent"
                                border="none"
                                onClick={() =>
                                    inputRef.current &&
                                    inputRef.current.click &&
                                    inputRef.current.click()
                                }
                            >
                                <UploadFile />
                            </Button>
                            <Input
                                autoComplete="false"
                                type="file"
                                ref={inputRef}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => handleOnUpload(e)}
                                onClick={(e: any) => {
                                    e.target.value = null
                                }}
                                display="none"
                                bg="none"
                            />

                            <Link
                                href={artwork}
                                download={'base_map' + '.png'}
                                bg="white"
                                style={{
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                                className="download-link"
                            >
                                <Download />
                            </Link>
                            <Button
                                onClick={() => handleOnDelete()}
                                p="0px"
                                bg="transparent"
                                border="none"
                            >
                                <DeleteIco />
                            </Button>
                        </Box>
                    </Box>
                )}

                <Box mb="8px">
                    <Text my="0px" fontSize="12px">
                        Recently Added
                    </Text>
                </Box>
                <Box display="flex" mb="8px" gridColumnGap="12px">
                    <>
                        <Button
                            type="button"
                            className="dashed__border"
                            bg="transparent"
                            border="none"
                            width="48px"
                            height="48px"
                            id="add-files-dropdown-button"
                            onClick={(e) => {
                                inputRef &&
                                    inputRef.current &&
                                    inputRef.current.click()
                            }}
                        >
                            <PlusIcoSm />
                        </Button>
                        <Input
                            autoComplete="false"
                            type="file"
                            ref={inputRef}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (e.target && e.target.files) {
                                    uploadArtwork(e.target.files[0])
                                }
                            }}
                            onClick={(e: any) => {
                                e.target.value = null
                            }}
                            display="none"
                            bg="none"
                        />
                    </>
                    {artWork.map((usedArtwork, key: number) => {
                        return (
                            <Box
                                border="solid"
                                borderColor={
                                    activeArtWork?.id === usedArtwork.id
                                        ? 'primary'
                                        : 'white'
                                }
                                borderRadius="4px"
                                onClick={() => {
                                    if (usedArtwork.id !== activeArtWork?.id) {
                                        setActiveArtwork(usedArtwork)
                                        uploadArtwork(usedArtwork, false)
                                    }
                                }}
                                borderWidth={1}
                                display="flex"
                                alignItems="center"
                                position="relative"
                            >
                                <Image
                                    border="solid"
                                    borderColor="white"
                                    borderRadius="4px"
                                    borderWidth={1}
                                    width="48px"
                                    height="48px"
                                    src={getLocalImageUrl(
                                        new File(
                                            [usedArtwork.blob],
                                            usedArtwork.name
                                        )
                                    )}
                                />
                                {activeArtWork?.id === usedArtwork.id && (
                                    <Box
                                        position="absolute"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                        height="100%"
                                        bg="#00000050"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="4px"
                                    >
                                        <Button
                                            bg="transparent"
                                            onClick={() => {
                                                setActiveControlPanel({
                                                    isActive: true,
                                                    id: usedArtwork.id,
                                                    mode: 'artwork',
                                                })
                                            }}
                                            border="none"
                                        >
                                            <ConfigIco color="#fff" />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                </Box>
                <DivisionLine />
                {false && (
                    <Box mt="16px">
                        <Box>
                            <Text my="0px" mb="8px" fontSize="12px">
                                Scale
                            </Text>
                            <UVValues type="repeat" />
                        </Box>
                        <Box mt="16px">
                            <Text my="0px" mb="8px" fontSize="12px">
                                Offset
                            </Text>
                            <UVValues type="offset" />
                        </Box>
                    </Box>
                )}
            </Box>
            <ControlPanel
                mode={activeControlPanel.mode}
                entity={{ id: activeControlPanel.id }}
                setIsControlPanelActive={(isActive) =>
                    setActiveControlPanel({
                        id: undefined,
                        mode: 'artwork',
                        isActive,
                    })
                }
                isControlPanelActive={activeControlPanel.isActive}
            />
        </Box>
    )
}

function UVValues({ type }: { type: 'offset' | 'repeat' }) {
    const { highlights } = useConfiguratorContext()
    const [Uvs, setUvs] = React.useState({
        u: highlights[0]?.mesh?.material?.map
            ? highlights[0]?.mesh?.material?.map[type]?.x
            : 0,
        v: highlights[0]?.mesh?.material?.map
            ? highlights[0]?.mesh?.material?.map[type]?.y
            : 0,
    })
    function handleUvChange(
        e: React.ChangeEvent<HTMLInputElement>,
        coord: 'u' | 'v' | 'uv'
    ) {
        if (coord === 'uv') {
            setUvs({
                u: e.target.value,
                v: e.target.value,
            })
        } else {
            setUvs({
                ...Uvs,
                [coord]: e.target.value,
            })
        }
        highlights.map((highlight) => {
            if (highlight.isActive && highlight.mesh.material.map) {
                highlight.mesh.material.map[type] = new THREE.Vector2(
                    coord === 'uv' ? e.target.value : Uvs.u,
                    coord === 'uv' ? e.target.value : Uvs.v
                )
                if (highlight.mesh.material.normalMap) {
                    highlight.mesh.material.normalMap[type] = new THREE.Vector2(
                        coord === 'uv' ? e.target.value : Uvs.u,
                        coord === 'uv' ? e.target.value : Uvs.v
                    )
                }
                if (highlight.mesh.material.roughnessMap) {
                    highlight.mesh.material.roughnessMap[type] =
                        new THREE.Vector2(
                            coord === 'uv' ? e.target.value : Uvs.u,
                            coord === 'uv' ? e.target.value : Uvs.v
                        )
                }
                if (highlight.mesh.material.metalnessMap) {
                    highlight.mesh.material.metalnessMap[type] =
                        new THREE.Vector2(
                            coord === 'uv' ? e.target.value : Uvs.u,
                            coord === 'uv' ? e.target.value : Uvs.v
                        )
                }
                if (highlight.mesh.material.displacementMap) {
                    highlight.mesh.material.displacementMap[type] =
                        new THREE.Vector2(
                            coord === 'uv' ? e.target.value : Uvs.u,
                            coord === 'uv' ? e.target.value : Uvs.v
                        )
                }
            }
        })
    }
    return (
        <Box display="flex" flexDirection="column" gridRowGap="12px">
            <Box display="flex" gridColumnGap="12px">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gridRowGap="4px"
                >
                    <Text my="0px" fontSize="12px">
                        U
                    </Text>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleUvChange(e, 'u')
                        }
                        bg="transparent"
                        border="solid"
                        borderRadius="4px"
                        borderColor="gray350"
                        borderWidth={1}
                        value={Uvs.u}
                        px="8px"
                        py="4px"
                        width="58px"
                        placeholder={highlights.length > 1 ? 'Mixed' : Uvs.u}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gridRowGap="4px"
                >
                    <Text my="0px" fontSize="12px">
                        V
                    </Text>
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleUvChange(e, 'v')
                        }
                        bg="transparent"
                        border="solid"
                        borderRadius="4px"
                        borderColor="gray350"
                        borderWidth={1}
                        value={Uvs.v}
                        px="8px"
                        py="4px"
                        width="58px"
                        placeholder={highlights.length > 1 ? 'Mixed' : Uvs.v}
                    />
                </Box>
            </Box>
            <Input
                mt="12px"
                className="intensity-slider"
                width="100%"
                value={Uvs.u}
                onChange={(e) => handleUvChange(e, 'uv')}
                type="range"
                min="0"
                step="0.001"
                max="1"
                bg="trasparent"
            ></Input>
        </Box>
    )
}
