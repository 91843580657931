import { Divider } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import type { TSearchedCollaborators } from 'src/components-v2/global-search/@types/search'
import { useRecentSearch } from 'src/components-v2/global-search/hooks/useRecentSearch'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { ProgressBar } from 'src/components/progress-bar/progress-bar'
import { ProgressIcons } from 'src/components/progress-icons/progress-icons'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { getImageUrl, getSentenceCase } from 'src/helpers'
import useStore from 'src/store/hooks/useStore'

interface ICollaboratorCardProps {
    entity: TSearchedCollaborators
}

export default function CollaboratorCard(props: ICollaboratorCardProps) {
    const { entity } = props
    const { globalSearchFilters, setGlobalSearchActive } = useStore()
    const { addRecentSearch } = useRecentSearch()
    const navigate = useNavigate()
    return (
        <Box
            className="cursor-pointer"
            px="16px"
            py="12px"
            bg="white"
            display="grid"
            alignItems="center"
            gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr'}
            justifyContent="space-between"
            onClick={() => {
                setGlobalSearchActive({
                    isGlobalSearchActive: false,
                })
                globalSearchFilters.isGlobalSearchActive &&
                    addRecentSearch({
                        entity_name: 'users',
                        entity_id: entity.id,
                        search_query: globalSearchFilters.query,
                    })
                navigate('/user/' + entity.id)
            }}
        >
            <Box display="flex" alignItems="center" gridColumnGap="16px">
                <ProfileImg
                    src={getImageUrl(
                        entity.other_data.thumbnail ||
                            entity.other_data.profile_pic
                    )}
                    name={entity.name}
                />
                <Text fontSize="14px" my="0px">
                    {entity.name}
                </Text>
            </Box>
            <Text fontSize="14px" my="0px">
                {getSentenceCase(entity.role, 'NAME')}
            </Text>
            <Text fontSize="14px" my="0px">
                {entity.total_teams < 10 ? '0' : ''}
                {entity.total_teams} Teams
            </Text>

            <Box display="flex" alignItems="center" gridColumnGap="16px">
                <Text my="0px" color="textSecondary" fontSize="12px">
                    {entity.todo_data.total} To-Do's
                </Text>
                <Divider
                    style={{
                        borderRight: '1px solid #E6E7E9',
                        height: '14px',
                    }}
                />
                <ProgressIcons
                    completed={entity.todo_data.complete}
                    remaining={entity.todo_data.delayed}
                />
            </Box>
            <ProgressBar percentage={entity.todoCompletionRate} />
            <Button
                onClick={(e) => {
                    e.stopPropagation()
                    // setTeamDropdown(true)
                }}
                bg="white"
                border="none"
            >
                <Dots active={false} />
            </Button>
        </Box>
    )
}
