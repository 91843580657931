import type { SyntheticEvent } from 'react'
import { useRef, useEffect, useState } from 'react'
import { handleActiveTab } from 'src/helpers/functions'
import useStore from 'src/store/hooks/useStore'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { getLocalStorage } from 'src/helpers'
import { useLocation } from 'react-router-dom'

export const SliderLinks = () => {
    const { setClientWidth, setClientOffset, setActiveTab } = useStore()
    const [user, setUser] = useState<{
        email: string
        name: string
        profileImage: boolean | string
        role: 'admin' | 'manager' | 'designer'
    } | null>()

    const [collection, setCollection] = useState<Collections | any>()
    const { state } = useLocation()
    const [value, setValue] = useState(0)

    const { filterProducts, resetProduct } = useStore()
    const { products, completed, inProgress, newProducts } = useStore(
        (state) => ({
            products: state.products,
            newProducts: state.new,
            inProgress: state.inProgress,
            completed: state.completed,
        })
    )

    useEffect(() => {
        const data = getLocalStorage('user')
        if (data) {
            setUser(JSON.parse(data))
        }
    }, [])
    useEffect(() => {
        if (state) {
            setCollection(state)
        }
    }, [state])

    useEffect(() => {
        // console.clear()
        if (collection) {
            // resetProduct({ products: collection.products })
            filterProducts({ action: 'NEW' })
        }
    }, [collection])

    const defaultTab = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        if (defaultTab && defaultTab.current) {
            setClientWidth({
                clientWidth: defaultTab.current.clientWidth + 'px',
            })

            setClientOffset({
                clientOffset: defaultTab.current.offsetLeft + 'px',
            })
        }
    }, [])

    function handleTabChange(
        tabName: 'NEW' | 'IN-PROGRESS' | 'COMPLETED',
        callback: TabChangeCallBack
    ) {
        filterProducts({ action: tabName })
        callback(tabName, setActiveTab)
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    useEffect(() => {
        handleTabChange('NEW', handleActiveTab)
    }, [])

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    }

    return (
        <Tabs value={value} onChange={handleChange} aria-label="people-tabs">
            <Tab
                onClick={(e: SyntheticEvent) =>
                    handleTabChange('NEW', handleActiveTab)
                }
                label={`New (${newProducts?.length || 0})`}
                {...a11yProps(0)}
            />
            <Tab
                onClick={(e: SyntheticEvent) =>
                    handleTabChange('IN-PROGRESS', handleActiveTab)
                }
                label={`In Progress (${inProgress?.length || 0})`}
                {...a11yProps(1)}
            />
            <Tab
                onClick={(e: SyntheticEvent) =>
                    handleTabChange('COMPLETED', handleActiveTab)
                }
                label={`Completed (${completed?.length || 0})`}
                {...a11yProps(0)}
            />
        </Tabs>
    )
}
