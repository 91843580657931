import * as THREE from 'three'
const texture = new THREE.TextureLoader()

export function createMaterial(
    path: string,
    config: {
        scale: {
            u: number
            v: number
        }
        offset: {
            u: number
            v: number
        }
        rotation?: number
    }
) {
    const plain = texture.load(path)
    const upscale = texture.load(path)
    upscale.wrapS = THREE.RepeatWrapping
    upscale.wrapT = THREE.RepeatWrapping
    upscale.repeat.set(config.scale.u, config.scale.v)
    upscale.anisotropy = 2
    upscale.colorSpace = THREE.SRGBColorSpace
    upscale.offset.set(config.offset.u, config.offset.v)
    upscale.rotation = config.rotation || 0
    upscale.flipY = false
    upscale.generateMipmaps = true
    upscale.needsUpdate = true

    plain.repeat.set(config.scale.u, config.scale.v)
    plain.anisotropy = 2
    plain.colorSpace = THREE.SRGBColorSpace
    plain.offset.set(config.offset.u, config.offset.v)
    plain.rotation = config.rotation || 0
    upscale.flipY = false
    upscale.generateMipmaps = true
    upscale.needsUpdate = true

    return { upscale, plain }
}

export function captureImage(mesh: any, code: string = 'map') {
    // Create a canvas
    try {
        var canvas = document.createElement('canvas')
        var context = canvas.getContext('2d')
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight

        if (!mesh.material[code]) return
        if (!context) return
        if (mesh.material[code]?.source?.data.currentSrc) {
            return mesh.material[code]?.source?.data.currentSrc
        }
        context?.drawImage(
            mesh.material[code]?.source?.data.nodeName
                ? mesh.material[code]?.source?.data.currentSrc
                : mesh.material[code]?.source?.data,
            0,
            0,
            canvas.width,
            canvas.height
        )
        return canvas.toDataURL()
    } catch (e) {
        console.log('failed to capture ', code)
    }
}
