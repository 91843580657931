import * as React from 'react'
import { Box, Button, Input, Text } from '../utility'
import {
    BackgroundIco,
    Pan,
    RenderImageIco,
} from '../svg-icons/3d-renderer/actions'
import { CommentAnnotationLG } from '../svg-icons/3d-renderer/comment-annotation'
import { useSearchParams } from 'react-router-dom'
import { useConfiguratorContext } from './configurator/configurator.context'
import { ColoredButton } from '../buttons/colored-button'
import { CrossSm, WarningIcoSM } from '../svg-icons'
import RadioInput from 'src/components-v2/radio-button/radio-button'
import { DivisionLine } from '../division-line/division-line'
import ColorPanel from './configurator/panel-functions/color/color-panel'
import Shades, { ShadeTile } from './configurator/panel-functions/color/shades'
import { HtmlTooltip } from '../stacked-image/stacked-image'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { checkColorFormat } from '../color-picker/color-picker.helper'

export function ReviewerOptions({
    onChange,
    onImageCapture,
}: {
    onChange: (
        mode: 'pan' | 'annotation' | 'renderImage' | 'background'
    ) => void
    onImageCapture: () => void
}) {
    const { operation, setOperation } = useConfiguratorContext()
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <>
            <Box position="absolute" left="24px" top="124px">
                <Box
                    borderRadius="4px"
                    gridRowGap="24px"
                    px="4px"
                    py="12px"
                    display="flex"
                    flexDirection="column"
                    gridGap="16px"
                    bg="white"
                    border="solid"
                    borderColor="gray250"
                    borderWidth={1}
                >
                    <Button
                        border="none"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="white"
                        onClick={() => {
                            setOperation('pan')
                            searchParams.set('mode', 'pan')
                            setSearchParams(searchParams)
                            onChange('pan')
                        }}
                    >
                        <Pan fill={operation === 'pan' ? '#022143' : '#fff'} />
                    </Button>
                    <Button
                        border="none"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="white"
                        onClick={() => {
                            setOperation('annotation')
                            searchParams.set('mode', 'annotation')
                            setSearchParams(searchParams, { replace: true })
                            onChange('annotation')
                        }}
                    >
                        <CommentAnnotationLG
                            stroke={
                                operation === 'annotation' ? '#fff' : '#778CA2'
                            }
                            fill={
                                operation === 'annotation' ? '#022143' : '#fff'
                            }
                        />
                    </Button>

                    <Box position="relative">
                        <Button
                            border="none"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bg={'white'}
                            onClick={() => {
                                setOperation('renderImage')
                                searchParams.set('mode', 'renderImage')
                                setSearchParams(searchParams, { replace: true })
                                onChange('renderImage')
                            }}
                        >
                            <RenderImageIco
                                color={
                                    operation === 'renderImage'
                                        ? '#fff'
                                        : '#778CA2'
                                }
                                fill={
                                    operation === 'renderImage'
                                        ? '#022143'
                                        : '#fff'
                                }
                            />
                        </Button>
                        {operation === 'renderImage' && (
                            <Box
                                position="absolute"
                                top="0"
                                left="60px"
                                width="max-content"
                                bg="white"
                                border="1px solid #B8B8B8"
                                p="8px"
                                borderRadius="4px"
                                display="flex"
                                flexDirection="column"
                                gridRowGap="0px"
                                px="12px"
                                overflowY="auto"
                                height={'220px'}
                                justifyContent="space-evenly"
                                zIndex={15}
                            >
                                <Dimensions />
                                <Text
                                    my="0px"
                                    fontSize="12px"
                                    color="textSecondary"
                                >
                                    TIP: Place the model in the best direction
                                    to get accurate render.
                                </Text>
                                <Box width="100%">
                                    <ColoredButton
                                        height="max-content"
                                        width="100%"
                                        variant="primary"
                                        onClick={() => {
                                            onImageCapture()
                                            setOperation('pan')
                                        }}
                                    >
                                        Capture
                                    </ColoredButton>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box
                    borderRadius="4px"
                    gridRowGap="24px"
                    px="4px"
                    py="12px"
                    display="flex"
                    flexDirection="column"
                    gridGap="16px"
                    bg="white"
                    mt="24px"
                    border="solid"
                    borderColor="gray250"
                    borderWidth={1}
                >
                    <Button
                        onClick={() =>
                            setOperation(
                                operation === 'background'
                                    ? 'pan'
                                    : 'background'
                            )
                        }
                        bg={'white'}
                        border="none"
                    >
                        <BackgroundIco isActive={operation === 'background'} />
                    </Button>
                    {operation === 'background' && <BackgroundPicker />}
                </Box>
            </Box>
        </>
    )
}

function Dimensions() {
    const { imageRenderDimensions, setImageRenderDimensions } =
        useConfiguratorContext()
    const [options, setOptions] = React.useState({
        low: true,
        medium: false,
        high: false,
    })
    const [selectedLabel, setSelectedLabel] = React.useState('2K (2048 × 1080)')

    function updateLabelAndResolution(item: string) {
        setSelectedLabel(item)
        switch (item) {
            case '2K (2048 × 1080)':
                return setImageRenderDimensions({ height: 1080, width: 2046 })
            case '4K (4096 × 2160)':
                return setImageRenderDimensions({ height: 2160, width: 4096 })
            case '8K (7680 × 4320)':
                return setImageRenderDimensions({ height: 4320, width: 7680 })
        }
    }

    return (
        <Box>
            <Box width="80%">
                <Text color="textTags" fontSize="12px" my="0px">
                    Choose Resolution
                </Text>

                <Box mt="12px" display="flex" gridColumnGap="8px">
                    <RadioInput
                        isChecked={options.low}
                        lable="Low"
                        onChange={(isChecked) => {
                            setSelectedLabel('2K (2048 × 1080)')
                            updateLabelAndResolution('2K (2048 × 1080)')
                            setOptions({
                                high: false,
                                medium: false,
                                low: isChecked,
                            })
                        }}
                    />
                    <RadioInput
                        isChecked={options.medium}
                        lable="Medium"
                        onChange={(isChecked) => {
                            setSelectedLabel('4K (4096 × 2160)')
                            updateLabelAndResolution('4K (4096 × 2160)')
                            setOptions({
                                low: false,
                                high: false,
                                medium: isChecked,
                            })
                        }}
                    />
                    <RadioInput
                        isChecked={options.high}
                        lable="High"
                        onChange={(isChecked) => {
                            setSelectedLabel('8K (7680 × 4320)')
                            updateLabelAndResolution('8K (7680 × 4320)')
                            setOptions({
                                low: false,
                                medium: false,
                                high: isChecked,
                            })
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" gridColumnGap="6px" mt="12px">
                <Text fontSize="12px" my="0px" color="textTags">
                    Resolution :
                </Text>
                <Text fontSize="12px" my="0px">
                    {selectedLabel}
                </Text>
            </Box>
            {false && (
                <Box
                    px="12px"
                    mt="12px"
                    display="flex"
                    gridColumnGap="8px"
                    alignItems="center"
                    justifyContent="center"
                    disabled={selectedLabel !== 'Custom'}
                >
                    <Box>
                        <Text
                            color={
                                selectedLabel !== 'Custom'
                                    ? 'textSecondaryDarkBlue'
                                    : 'primary'
                            }
                            fontSize="14px"
                            my="0px"
                            mb="4px"
                        >
                            Width
                        </Text>
                        <Input
                            bg="transparent"
                            border="solid"
                            borderWidth={1}
                            borderRadius="4px"
                            borderColor="secondaryLighterBlue"
                            height="32px"
                            type="number"
                            value={imageRenderDimensions.width}
                            disabled={selectedLabel !== 'Custom'}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setImageRenderDimensions({
                                    ...imageRenderDimensions,
                                    width: Number(e.target.value),
                                })
                            }}
                        />
                    </Box>
                    <Box mt="16px">
                        <CrossSm />
                    </Box>
                    <Box>
                        <Text
                            color={
                                selectedLabel !== 'Custom'
                                    ? 'textSecondaryDarkBlue'
                                    : 'primary'
                            }
                            fontSize="14px"
                            my="0px"
                            mb="4px"
                        >
                            Height
                        </Text>
                        <Input
                            bg="transparent"
                            border="solid"
                            borderWidth={1}
                            borderRadius="4px"
                            borderColor="secondaryLighterBlue"
                            height="32px"
                            type="number"
                            value={imageRenderDimensions.height}
                            disabled={selectedLabel !== 'Custom'}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setImageRenderDimensions({
                                    ...imageRenderDimensions,
                                    height: Number(e.target.value),
                                })
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

function BackgroundPicker() {
    const { backgroundAndShadows, setBackgroundAndShadows } =
        useConfiguratorContext()

    function updateBackgroundColor(color: string) {
        const isColor = checkColorFormat(color)
        const hexLength = color.replace('#', '0').length
        console.log({ color, isColor })
        if (hexLength === 3) {
            color = `#${color.replace('#', '').repeat(2)}`
        }
        if (
            isColor &&
            (hexLength === 3 || hexLength === 6 || hexLength === 7)
        ) {
            setBackgroundAndShadows({
                ...backgroundAndShadows,
                backgroundColor: color,
                activePresetColor: null,
            })
        }
    }

    function shadowSwitch(isShadowEnabled: boolean) {
        setBackgroundAndShadows({
            ...backgroundAndShadows,
            isShadowEnabled,
        })
    }

    const canvas = document.getElementById('3d-renderer-canvas')
    const canvasHeight = canvas?.clientHeight || 600

    return (
        <Box
            bg="white"
            borderRadius="4px"
            left="60px"
            position="absolute"
            top="-100px"
            width="320px"
            py="16px"
            gridRowGap="16px"
            display="flex"
            flexDirection="column"
            zIndex={10}
            border="solid"
            borderColor="#B8B8B8"
            borderWidth={1}
            maxHeight={canvasHeight * 0.9 + 'px'}
            overflowY={'auto'}
            className="scrollbar_none"
        >
            <Box px="16px" display="flex" justifyContent="space-between">
                <Text my="0px">Background</Text>
                <Button
                    fontSize="12px"
                    color="links"
                    bg="transparent"
                    border="none"
                    p="0px"
                    onClick={() => {
                        setBackgroundAndShadows({
                            presets: backgroundAndShadows.presets,
                            isShadowEnabled: false,
                            recentlyUsedColors: [],
                            backgroundColor: '#212121',
                            activePresetColor: null,
                        })
                    }}
                >
                    Reset
                </Button>
            </Box>
            <Box>
                <Text
                    px="16px"
                    my="0px"
                    mb="8px"
                    fontSize="14px"
                    fontWeight={500}
                >
                    Color
                </Text>
                <DivisionLine />
                <Box
                    mt="16px"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="16px"
                    px="16px"
                >
                    <Box display="flex" justifyContent="space-between">
                        <Box
                            display="flex"
                            gridColumnGap="8px"
                            alignItems="center"
                        >
                            <Text fontSize="12px" my="0px">
                                Shadows
                            </Text>
                            <HtmlTooltip
                                arrow
                                placement="bottom-start"
                                title={
                                    <ToolTipHeader
                                        text={
                                            'Enabling shadows in edit mode may reduce performance.'
                                        }
                                    />
                                }
                            >
                                <Box>
                                    <WarningIcoSM color="#ef8d1b" />
                                </Box>
                            </HtmlTooltip>
                        </Box>
                        <Box
                            border="solid"
                            borderRadius="40px"
                            width="38px"
                            py="4px"
                            px="4px"
                            className="cursor-pointer transtion"
                            onClick={() =>
                                shadowSwitch(
                                    !backgroundAndShadows.isShadowEnabled
                                )
                            }
                            borderColor={
                                backgroundAndShadows.isShadowEnabled
                                    ? 'textInReview'
                                    : 'textSecondaryDarkBlue'
                            }
                            position="relative"
                            display="flex"
                            alignItems="center"
                            justifyContent={
                                backgroundAndShadows.isShadowEnabled
                                    ? 'end'
                                    : 'start'
                            }
                        >
                            <Box
                                borderRadius="50%"
                                width="8px"
                                className="transtion"
                                height="8px"
                                bg={
                                    backgroundAndShadows.isShadowEnabled
                                        ? 'textInReview'
                                        : 'textSecondaryDarkBlue'
                                }
                            ></Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        gridRowGap="8px"
                        flexDirection="column"
                    >
                        <Text fontSize="12px" my="0px">
                            Presets
                        </Text>
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                            flexWrap="wrap"
                            gridColumnGap="8px"
                            gridRowGap="8px"
                            justifyContent="center"
                        >
                            {backgroundAndShadows.presets.map((preset) => {
                                return (
                                    <ShadeTile
                                        onClick={(color) => {
                                            setBackgroundAndShadows({
                                                ...backgroundAndShadows,
                                                activePresetColor: color,
                                            })
                                        }}
                                        selectedShade={
                                            backgroundAndShadows.activePresetColor?.toLowerCase() ||
                                            null
                                        }
                                        shade={preset}
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        gridRowGap="8px"
                        flexDirection="column"
                    >
                        <Text fontSize="12px" my="0px">
                            Similar Shades
                        </Text>
                        <Shades
                            color={backgroundAndShadows.backgroundColor.toLowerCase()}
                            onClick={(color) => updateBackgroundColor(color)}
                        />
                        <ColorPanel
                            p={'0px'}
                            border="none"
                            initialColor={backgroundAndShadows.backgroundColor}
                            onColorChange={(color) =>
                                updateBackgroundColor(color)
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
