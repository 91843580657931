import React, { useEffect, useRef, useState } from 'react'
import StyledNav from './styled.nav'
import {
    NotificationIco,
    LogoutIco,
    SearchIco,
    Cross,
    FilterIco,
} from '../svg-icons'
import { Button, Box, Input } from '../utility'
import { debounce, getLocalStorage, logoutUser } from 'src/helpers'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLazyQuery } from '@apollo/client'
import { GlobalSearch } from 'src/services'
import { GlobalSearch as GlobalSearchComponent } from 'src/components-v2/global-search/global-search'
import useStore from 'src/store/hooks/useStore'
import { useApolloClient } from 'src/helpers/initialize-apollo'
import Filters from 'src/components-v2/global-search/filters'
import { RecentSearch } from 'src/components-v2/global-search/recent-search'

type searchedItem = {
    name: string
    id: string
    type: string
    collection_id?: string
}

export const Nav = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [isRecentSearches, setIsRecentSearches] = useState<boolean>(false)
    const client = useApolloClient(getLocalStorage('authToken'))
    const { setGlobalSearchActive, setQuery, isFilterActive, setFilterActive } =
        useStore()
    const [globalSearchResults, setGlobalSearchResults] = useState<
        searchedItem[]
    >([])
    const [getSearchResults] = useLazyQuery(GlobalSearch)
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    function fetchSearchResults(query: string) {
        if (query.length <= 0) return
        getSearchResults({
            variables: {
                search: `%${query}%`,
            },
            onCompleted: (data: {
                collections: searchedItem[]
                products: searchedItem[]
            }) => {
                if (!data || (!data.collections && !data.products)) return
                const collections =
                    data.collections.map((collection) => ({
                        ...collection,
                        type: 'COLLECTION',
                    })) || []
                const products =
                    data.products.map((product) => ({
                        ...product,
                        type: 'PRODUCT',
                    })) || []
                setGlobalSearchResults([...collections, ...products])
            },
        })
    }
    const debounceCallback = debounce(
        fetchSearchResults,
        500,
        setTimeOutRef,
        timeOutRef
    )
    const searchAutoFill = useRef<HTMLInputElement | null>(null)

    // const subscribedNotifications = useSubscription(SubscribeToNotifications)
    const { resetNotifications, globalSearchFilters } = useStore()
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [newNotificationLength, setNewNotificationLength] = useState(0)
    let audio: HTMLAudioElement | null = null

    useEffect(() => {
        audio = new Audio('/notification_simple-02.wav')
    }, [])

    // useEffect(() => {
    //     ;(async function () {
    //         if (
    //             subscribedNotifications.data &&
    //             subscribedNotifications.data.notifications
    //         ) {
    //             resetNotifications({
    //                 notifications: subscribedNotifications.data.notifications,
    //             })
    //             const newNotificationLengthCalculated =
    //                 subscribedNotifications.data.notifications.filter(
    //                     (notification: Notifications) => !notification.opened
    //                 ).length
    //             setNewNotificationLength(newNotificationLengthCalculated)
    //             if (newNotificationLengthCalculated > 0 && audio) {
    //                 await audio.play()
    //                 audio.pause()
    //                 setTimeout(() => {
    //                     audio?.play()
    //                 }, 1000)
    //             }
    //         }
    //     })()
    // }, [subscribedNotifications.data])

    useEffect(() => {
        if (searchQuery.length >= 2) {
            debounceCallback(searchQuery)
            setFilterActive({
                isActive: false,
            })
        } else {
            setGlobalSearchActive({
                isGlobalSearchActive: false,
            })
        }
    }, [searchQuery])

    const navigate = useNavigate()

    function navigateToSearched(searchedItem: searchedItem) {
        switch (searchedItem.type) {
            case 'PRODUCT':
                navigate(
                    `/collections/product/${searchedItem.id}?name${searchedItem.name}&collectionId=${searchedItem.collection_id}`
                )
                setSearchQuery('')
                if (searchAutoFill.current) {
                    searchAutoFill.current.value = ''
                }
                break
            case 'COLLECTION':
                navigate(
                    `/collections/${searchedItem.id}?name=${searchedItem.name}`
                )
                setSearchQuery('')
                if (searchAutoFill.current) {
                    searchAutoFill.current.value = ''
                }
                break
        }
    }

    return (
        <StyledNav
            bg="#022143"
            width="100%"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="start"
            position="relative"
        >
            <Box
                display="flex"
                justifyContent="center"
                height="30px"
                width="74%"
                maxWidth="700px"
                gridColumnGap="12px"
                alignItems="center"
                ml="36px"
                bg="white"
                position="relative"
                px="8px"
                py="4px"
                borderRadius="4px"
            >
                <SearchIco color="#778CA2" />
                <Input
                    autoComplete="false"
                    bg="transparent"
                    fontFamily="Rubik"
                    fontSize="14px"
                    border="none"
                    height="100%"
                    onKeyUp={(e) => {
                        if (e.currentTarget.value.length <= 0)
                            return setGlobalSearchActive({
                                isGlobalSearchActive: false,
                            })
                        e.key === 'Enter' &&
                            globalSearchFilters.query.length > 0 &&
                            setIsSearchActive(false)
                        setGlobalSearchActive({
                            isGlobalSearchActive: true,
                        })
                    }}
                    id="search-bar"
                    onMouseUp={() => {
                        setIsRecentSearches(true)
                    }}
                    color="primary"
                    width="100%"
                    value={globalSearchFilters.query}
                    type="text"
                    placeholder="Search for Collections, Products, To-do’s etc. "
                    className="placeholder-light"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQuery({ query: e.target.value })
                        setSearchQuery(e.target.value)
                    }}
                    onKeyDown={() => {
                        setIsRecentSearches(false)
                        if (globalSearchFilters.query.length <= 0) {
                            setIsSearchActive(false)
                        }
                    }}
                    onClick={() => setIsRecentSearches(true)}
                    ref={searchAutoFill}
                />
                {searchQuery.length > 0 && (
                    <Button
                        onClick={() => {
                            setQuery({ query: '' })
                            setSearchQuery('')
                        }}
                        bg="transparent"
                        p="0px"
                        border="none"
                    >
                        <Cross color="#778CA2"></Cross>
                    </Button>
                )}
                <Button
                    onClick={() =>
                        setFilterActive({ isActive: !isFilterActive })
                    }
                    bg="transparent"
                    p="0px"
                    border="none"
                >
                    <FilterIco color="#778CA2" />
                </Button>
                <RecentSearch
                    query={globalSearchFilters.query}
                    isSearchActive={isRecentSearches}
                    setIsSearchActive={(isOpen) => setIsRecentSearches(isOpen)}
                />
                <GlobalSearchComponent
                    isSearchActive={isSearchActive}
                    setIsSearchActive={(isActive) =>
                        setIsSearchActive(isActive)
                    }
                    type="search"
                    query={globalSearchFilters.query}
                    // dataList={globalSearchResults}
                    // width="100%"
                    // objectKey="name"
                    // showRecentSearches={false}
                    // text={searchQuery}
                />
                {isFilterActive && (
                    <Filters
                        setActive={(isActive) =>
                            setFilterActive({
                                isActive: isActive,
                            })
                        }
                    />
                )}
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap="33px"
                position="absolute"
                right="36px"
            >
                <Button
                    bg="transparent"
                    border="none"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    onClick={() => {
                        setGlobalSearchActive({
                            isGlobalSearchActive: false,
                        })
                        navigate('/notifications')
                    }}
                >
                    <NotificationIco />
                    {newNotificationLength > 0 && (
                        <Box
                            position="absolute"
                            top="4px"
                            right="8px"
                            width="8px"
                            height="8px"
                            bg="delay"
                            border="solid"
                            borderWidth={1}
                            borderColor="white"
                            borderRadius="50%"
                        ></Box>
                    )}
                </Button>
                <Button
                    bg="transparent"
                    border="none"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    id="logout_id"
                    onClick={() => {
                        logoutUser()
                        navigate('/login')
                        toast(
                            'You are now logged out. Thank you for using Virtu',
                            { className: 'custom-toaster toaster-success' }
                        )
                    }}
                >
                    <LogoutIco />
                </Button>
            </Box>
            {/* <Button>Notification</Button> */}
            {/* <PredectiveOutput
                showRecentSearches={isRecentSearches}
                dataList={[
                    'Hats Collection 2021',
                    'Hats Summer Collection 2019',
                    'Baseball Hats 2018',
                ]}
                target={
                    searchAutoFill !== null && searchAutoFill.current !== null
                        ? searchAutoFill.current
                        : undefined
                }
                onClick={() => debug_log('hello')}
                text={searchQuery}
                top="60px"
                bottom="0px"
                left="32px"
            /> */}
        </StyledNav>
    )
}
