import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { CollectionIco, Dots, SuccessTickSm } from 'src/components/svg-icons'
import CalendarDropdown from '../cards/dropdowns/calendar'
import StatusDropdown from '../cards/dropdowns/status'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { ProductBadge } from '../badges/product-badge'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { VariantBadge } from '../badges/variant-badge'
import ProductTodosContainer from '../cards/product/product-todos-container'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import useUpdateProduct, {
    useDeleteProduct,
} from '../cards/hooks/useUpdateProduct'
import { useDeleteProductVariant } from '../cards/hooks/useDeleteProductVariant'
import useStore from 'src/store/hooks/useStore'
import MeatBallDropdown from '../cards/dropdowns/meatball-dropdown'
import { copyToClipBoard } from 'src/helpers'
import { toast } from 'react-toastify'
import { useRecentSearch } from '../global-search/hooks/useRecentSearch'
import { DangerModal } from 'src/components/modals/danger-modal'
import ProductTodosPopover from '../cards/product/product-todos-popover/product-todos-popover'
import ThumbnailBadge from '../thumbnail-badge/thumbnail-badge'
import { useUpdateCollection } from '../cards/hooks/useUpdateCollection'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import type { Status_Enum } from 'src/generated/graphql'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import AssignUser from '../dropdown-badges/assign-user/assign-user'

type ProductListCardProps = {
    product: {
        id: number
        name: string
        coverPic: {
            name: string
            gid: string
            path: string
            thumbnail: string
            assignee_id: string
            fromLibrary: boolean
        } | null
        collection: {
            id: number
            name: string
        }
        parentProductId: number
        status: string | null | undefined
        isDelayed: boolean
        dueDate: string | null
        todosCount: {
            total: number
            completed: number
        }
        productVariantApprover:
            | {
                  id: number
                  name: string
                  email: string
                  other_data: any
              }
            | null
            | undefined
    }
    isVariant?: boolean
    refetchProducts?: () => void
    isFullCard?: boolean
    refetchQueries?: string[]
}

function ProductListCard({
    product,
    isVariant = false,
    isFullCard = true,
    refetchProducts,
    refetchQueries = [],
}: ProductListCardProps) {
    const [componentVisibility, setComponentVisibility] = useState({
        todosList: false,
        menuDropdown: false,
        deleteModal: false,
        todosPopup: false,
        thumbnailDropdownMenu: false,
        collaboratorsModal: false,
    })
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const {
        updateGlobalSearchResults,
        setGlobalSearchActive,
        removeProduct,
        clearDrawerTabs,
        globalSearchFilters,
    } = useStore()
    const { addRecentSearch } = useRecentSearch()
    const {
        addValuesToExpanded,
        getExpandedStates,
        getLastOpenedTab,
        setLastOpened,
        removeLastOpenedTab,
    } = useCardExpandedState()

    const {
        updateProductVariantDueDate,
        updateProductVariantStatus,
        updateProductVariantHandler,
    } = useUpdateProduct(refetchQueries)
    const { updateCollectionTeamAndItsProductsTeamHandler } =
        useUpdateCollection()
    const { deleteProduct } = useDeleteProduct()
    const { deleteProductVariant } = useDeleteProductVariant()
    const eHash = searchParams.get('e_hash')
    const timerRef = useRef<NodeJS.Timeout | null>(null)
    let timeoutId: NodeJS.Timeout | null = null

    function handleTabExpand() {
        addValuesToExpanded(
            {
                id: product.id,
                isExpanded: !componentVisibility.todosList,
            },
            'product'
        )
    }

    function dropdownClickHandler(option: string) {
        switch (option) {
            case 'Delete':
                setComponentVisibility({
                    ...componentVisibility,
                    deleteModal: true,
                })
                break
            case 'Copy Link':
                copyToClipBoard(
                    window.location.host + '/collections/product/' + product.id
                )
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            default:
                break
        }
    }

    // Set the visibility of todos list on mount if it exists
    useEffect(() => {
        const data = getExpandedStates()
        if (data && data.product) {
            if (data?.product[product.id]) {
                setComponentVisibility({
                    ...componentVisibility,
                    todosList: data.product[product.id],
                })
            }
        }
    }, [eHash])

    // Scroll to last opened tab on mount if it exists
    const tab = getLastOpenedTab('product')
    // !!FIXME: Improve the code and make it more readable
    useEffect(() => {
        if (!tab || tab !== product.id) return
        if (isFullCard) {
            scrollToSection(`product_card_${tab}`)
        } else {
            const card = document.getElementById(
                `product_card_${tab}`
            ) as HTMLElement
            // Find the parent product container where scroll is needed
            const productContainer = document.getElementById(
                'dashboard_product_entity_container'
            ) as HTMLElement
            if (card && productContainer) {
                // Find the parent conatiner of the product in order to calculate the scroll position
                const parentContainer = document.getElementById(
                    `parent_product_${product.parentProductId}`
                ) as HTMLElement
                if (parentContainer) {
                    const index = Array.from(
                        productContainer.children
                    ).findIndex((child) => child === parentContainer)
                    // If the comment is not the latest, scroll to the card but showing half of previous card too
                    if (index > 0) {
                        const scrollToPosition =
                            card.offsetTop -
                            productContainer.offsetTop -
                            card.offsetHeight / 2
                        productContainer.scrollTo({
                            top: scrollToPosition,
                            behavior: 'smooth',
                        })
                    } else {
                        const scrollToPosition =
                            card.offsetTop - parentContainer.offsetTop
                        parentContainer.scrollTo({
                            top: scrollToPosition,
                            behavior: 'smooth',
                        })
                    }
                }
            }
        }
    }, [tab])

    // Mouse enter and leave handlers for todos popup
    const handleMouseEnter = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
        }
        timerRef.current = setTimeout(() => {
            setComponentVisibility({
                ...componentVisibility,
                todosPopup: true,
            })
        }, 1000) // 3 seconds
    }

    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            setComponentVisibility({
                ...componentVisibility,
                todosPopup: false,
            })
        }, 300)
    }

    // clear todo popup timer on unmount
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [])

    return (
        <>
            <Box
                display="grid"
                gridTemplateColumns={{
                    md: '1fr auto',
                    xl: `1fr ${
                        isFullCard ? 'calc(55% + 12px)' : 'max-content'
                    }`,
                }}
                gridColumnGap={'38px'}
                border="1px solid"
                borderColor={isVariant ? '#dddddd' : '#778CA2'}
                pl={isVariant ? '24px' : '16px'}
                pr={'16px'}
                py="8px"
                alignItems="center"
                borderLeft={
                    isVariant ? '1px solid #778CA2' : '8px solid #778CA2'
                }
                className={
                    String(tab) === String(product.id)
                        ? 'cursor-pointer card-hover card-bg'
                        : 'cursor-pointer card-hover'
                }
                bg={isVariant ? '#F8FBFE' : '#E9EEF2'}
                id={`product_card_${product.id}`}
                onClick={() => {
                    setLastOpened({
                        entityId: product.id,
                        type: 'product',
                    })
                    setGlobalSearchActive({
                        isGlobalSearchActive: false,
                    })
                    globalSearchFilters.isGlobalSearchActive &&
                        addRecentSearch({
                            entity_name: 'products',
                            entity_id: product.id,
                            search_query: globalSearchFilters.query,
                        })
                    navigate(`/collections/product/${product.id}`)
                    clearDrawerTabs()
                }}
            >
                <Box
                    display="grid"
                    gridTemplateColumns={'53px auto 1fr'}
                    gridGap="8px"
                    alignItems="center"
                >
                    <Box
                        width="53px"
                        height={'53px'}
                        position="relative"
                        display="flex"
                        alignItems="center"
                        gridColumn={'1/2'}
                        gridRow={'1/3'}
                    >
                        <ThumbnailBadge
                            productId={product.id}
                            entityId={product.id}
                            entityName="product_variants"
                            coverPic={product.coverPic}
                            refetch={refetchProducts}
                        />
                    </Box>
                    {isVariant ? <VariantBadge /> : <ProductBadge />}
                    <Box gridColumn={'3/4'} gridRow="1/2" overflow={'hidden'}>
                        <Text
                            fontSize={'14px'}
                            my="0px"
                            className="text-ellipsis"
                            title={product.name}
                        >
                            {product.name}
                        </Text>
                    </Box>
                    <Box width={'100%'} gridColumn={'2/4'} gridRow={'2/3'}>
                        <Box
                            to={`/collections/${product.collection?.id}`}
                            as={Link}
                            onClick={(e) => e.stopPropagation()}
                            display="grid"
                            gridTemplateColumns={'auto 1fr'}
                            gridGap="8px"
                            alignItems="center"
                            style={{ textDecoration: 'none' }}
                            color="links"
                            width={'max-content'}
                            maxWidth={'100%'}
                            overflow={'hidden'}
                        >
                            <CollectionIco color="#027AC3" />
                            <Text
                                my="0px"
                                fontSize="14px"
                                className="text-ellipsis"
                                title={product.collection?.name}
                            >
                                {product.collection?.name}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="grid"
                    gridTemplateColumns={
                        isFullCard
                            ? '112px 100px 100px 160px 80px 24px'
                            : '100px 100px'
                    }
                    gridGap="16px"
                    alignItems="center"
                    gridColumnGap={!isFullCard ? '24px' : '16px'}
                    justifyContent={!isFullCard ? 'end' : 'space-between'}
                    pr={!isFullCard ? '16px' : '0px'}
                >
                    {isFullCard && (
                        <Box
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            position="relative"
                        >
                            <HtmlTooltip
                                placement="bottom"
                                arrow
                                title={<ToolTipHeader text={'Completion'} />}
                            >
                                <Box
                                    gridColumnGap="8px"
                                    display="flex"
                                    alignItems="center"
                                    border="1px solid"
                                    borderColor="primary"
                                    borderRadius="2px"
                                >
                                    <SuccessTickSm />
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        color="primary"
                                    >
                                        {product.todosCount.completed}/
                                        {product.todosCount.total} Todos
                                    </Text>
                                </Box>
                            </HtmlTooltip>
                            {componentVisibility.todosPopup && (
                                <ProductTodosPopover
                                    variantId={product.id}
                                    totalTodos={product.todosCount.total}
                                />
                            )}
                        </Box>
                    )}
                    <CalendarDropdown
                        date={
                            product.dueDate ? new Date(product.dueDate) : null
                        }
                        left={!isFullCard ? '-245px' : '0px'}
                        setDate={(date) => {
                            updateProductVariantDueDate({
                                id: product.id,
                                dueDate: date.toISOString(),
                                onCompleted: () => {
                                    updateGlobalSearchResults({
                                        type: 'products',
                                        updatedKey: 'due_date',
                                        value: date.toISOString(),
                                        id: String(product.id),
                                    })
                                },
                            })
                        }}
                        isDelayed={isEntityDueDateDelayed(
                            product.dueDate,
                            product.status as Status_Enum
                        )}
                        isListCard
                    />
                    {product.status && (
                        <StatusDropdown
                            left={!isFullCard ? '-65px' : '0px'}
                            exclude={['toBeReviewed']}
                            onStatusChange={(status) => {
                                updateProductVariantStatus({
                                    id: String(product.id),
                                    status: status,
                                    onCompleted: () => {
                                        updateGlobalSearchResults({
                                            type: 'products',
                                            updatedKey: 'status',
                                            value: status,
                                            id: String(product.id),
                                        })
                                    },
                                })
                            }}
                            status={product.status}
                        />
                    )}
                    {isFullCard ? (
                        <Box>
                            <AssignUser
                                assignee={product.productVariantApprover}
                                onRemove={() => {
                                    updateProductVariantHandler({
                                        productId: product.id,
                                        payload: {
                                            approver_id: null,
                                        },
                                    })
                                }}
                                onAssign={(user) => {
                                    updateProductVariantHandler({
                                        productId: product.id,
                                        payload: {
                                            approver_id: user.id,
                                        },
                                    })
                                }}
                                showIcon={false}
                                role="Approver"
                            />
                        </Box>
                    ) : (
                        <Box width="112px"></Box>
                    )}
                    {isFullCard && (
                        <Button
                            color="links"
                            border="none"
                            bg="transparent"
                            fontSize={{ md: '10px', xl: '12px' }}
                            height="100%"
                            px="0px"
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: product.id,
                                    type: 'product',
                                })
                                handleTabExpand()
                                setComponentVisibility({
                                    ...componentVisibility,
                                    todosList: !componentVisibility.todosList,
                                })
                            }}
                        >
                            {componentVisibility.todosList ? 'Hide' : 'View'}{' '}
                            {product.todosCount.total}{' '}
                            {product.todosCount.total > 1 ? 'Todos' : 'Todo'}
                        </Button>
                    )}
                    {isFullCard && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setLastOpened({
                                        entityId: product.id,
                                        type: 'product',
                                    })
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        menuDropdown:
                                            !componentVisibility.menuDropdown,
                                    })
                                }}
                                bg="transparent"
                                border="none"
                            >
                                <Dots active={false} />
                            </Button>
                            {componentVisibility.menuDropdown && (
                                <MeatBallDropdown
                                    left="unset"
                                    right="0px"
                                    isActive={componentVisibility.menuDropdown}
                                    onClick={(option) => {
                                        setLastOpened({
                                            entityId: product.id,
                                            type: 'product',
                                        })
                                        dropdownClickHandler(option)
                                    }}
                                    setIsActive={(isActive) =>
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown: isActive,
                                        })
                                    }
                                    onClose={() =>
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown: false,
                                        })
                                    }
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {componentVisibility.todosList && (
                <ProductTodosContainer productVariantId={product.id} />
            )}
            {componentVisibility.deleteModal && (
                <DangerModal
                    heading={`Delete ${product.name}`}
                    isModal={componentVisibility.deleteModal}
                    onClose={() =>
                        setComponentVisibility({
                            ...componentVisibility,
                            deleteModal: false,
                        })
                    }
                    onRemove={() => {
                        if (isVariant) {
                            deleteProductVariant({
                                variantId: product.id,
                                refetchQueries: [
                                    'getProduct',
                                    'getCollections',
                                    'GetCollectionProducts',
                                    'fetchCollectionById',
                                    'GetProducts',
                                    'GetProductsForCollection',
                                ],
                                onComplete: () => {
                                    toast('Variant deleted successfully. ', {
                                        className:
                                            'custom-toaster toaster-success',
                                    })
                                },
                            })
                        } else {
                            deleteProduct(
                                product.parentProductId,
                                [
                                    'getProduct',
                                    'getCollections',
                                    'fetchCollectionById',
                                    'GetProducts',
                                    'GetCollectionProducts',
                                    'GetProductsForCollection',
                                    'getCollections',
                                ],
                                () => {
                                    globalSearchFilters.isGlobalSearchActive &&
                                        removeProduct({
                                            id: product.parentProductId,
                                        })
                                    toast('Product deleted successfully. ', {
                                        className:
                                            'custom-toaster toaster-success',
                                    })
                                }
                            )
                        }
                    }}
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    title={`Are you sure you want to delete '${product.name}?'`}
                    subHeading={`Deleting this will remove all the existing To-Do's associated with it. This action cannot be undone.`}
                />
            )}
        </>
    )
}

export default ProductListCard
