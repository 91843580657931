import React from 'react'
import { Box } from '../utility'

export const Line = ({
    lineWidth,
    length,
    color,
    direction,
}: {
    lineWidth: string
    length: string
    color: string
    direction: 'vertical' | 'horizontal'
}) => {
    return (
        <Box
            borderRadius="4px"
            height={lineWidth}
            width={length}
            bg={color}
            className={direction}
        ></Box>
    )
}
