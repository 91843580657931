import React from 'react'
import { toast } from 'react-toastify'
import { getThumbnail } from 'src/components/annotation-comments/annotation-comments.helper'
import ColorPicker from 'src/components/color-picker/color-picker'
import { FileDisplay2 } from 'src/components/file-display/file-display-2'
import { Modal } from 'src/components/modals/modal'
import {
    BrandIco,
    CompanyIco,
    PaintIco,
    TagsIco,
} from 'src/components/svg-icons'
import AttachmentIco from 'src/components/svg-icons/attachment-ico'
import { TagAutoCommplete } from 'src/components/tag-autocomplete/tag-autocomplete'
import { Tag } from 'src/components/tag/tag'
import { Box, Input, Text } from 'src/components/utility'
import { formatColorCode } from 'src/helpers/format-color-code'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'

function AddFilesToLibraryModal({
    isOpen,
    onClose,
    onClick,
    setSelectedFiles,
    selectedFiles,
    selectedFilesAdditionalDetails,
    setSelectedFilesAdditionalDetails,
}: {
    isOpen: boolean
    onClose: () => void
    onClick: () => void
    selectedFiles: Array<any>
    setSelectedFiles: (selectedFiles: Array<any>) => void
    selectedFilesAdditionalDetails: {
        company: string
        brand: string
        colors: Array<any>
        tags: Array<any>
    }
    setSelectedFilesAdditionalDetails: (selectedFilesAdditionalDetails: {
        company: string
        brand: string
        colors: Array<any>
        tags: Array<any>
    }) => void
}) {
    function validateFields() {
        if (
            selectedFiles.filter(
                (file: any) => !!file.file && !file.file.moved_to_lib
            ).length === 0
        ) {
            toast('Please select files that are not already there in library', {
                className: 'custom-toaster toaster-error',
            })
        }
        return (
            selectedFiles.filter(
                (file: any) => !!file.file && !file.file.moved_to_lib
            ).length > 0
        )
    }
    function nextClickHandler() {
        if (validateFields()) {
            onClick()
        }
    }

    return (
        <Modal
            isModal={isOpen}
            onClick={nextClickHandler}
            onClose={onClose}
            primaryButtonTitle="Next"
            secondaryButtonTitle="Cancel"
            title="Add to Library"
        >
            <Box
                width="480px"
                minWidth={'600px'}
                minHeight={'518px'}
                maxHeight={'80vh'}
                className="scrollbar_none"
                overflowY={'auto'}
                p={'16px'}
                display="flex"
                flexDirection="column"
                gridGap="16px"
            >
                <Box
                    display="grid"
                    gridGap="12px"
                    gridTemplateColumns={'auto 1fr'}
                >
                    <AttachmentIco />
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gridGap="6px"
                        zIndex={15}
                    >
                        {selectedFiles?.map((file: any, key: number) => {
                            const fileData = file.library_file
                                ? {
                                      ...file.library_file,
                                      from_library: true,
                                  }
                                : file.file
                                ? {
                                      ...file.file,
                                      from_library: false,
                                  }
                                : null
                            if (!fileData) return <></>
                            return (
                                <FileDisplay2
                                    isDownloadable
                                    key={key}
                                    onClick={() => {}}
                                    name={fileData.name}
                                    url={
                                        fileData.from_library
                                            ? getLibraryFileUrl(fileData.gid)
                                            : getImageUrl(fileData.gid)
                                    }
                                    thumbnail={getThumbnail(fileData)}
                                    onDelete={() => {
                                        setSelectedFiles(
                                            selectedFiles.filter(
                                                (f: any) => f.id !== file.id
                                            )
                                        )
                                    }}
                                    width="80px"
                                    height="84px"
                                    isDeletePermitted={false}
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData(
                                            'application/json',
                                            JSON.stringify(fileData)
                                        )
                                    }}
                                    fileId={file.id}
                                    deleteButton
                                    isPresentInLibrary={
                                        fileData.from_library ||
                                        fileData?.moved_to_lib
                                    }
                                    id={`file_display_${file.id}`}
                                />
                            )
                        })}
                    </Box>
                </Box>
                <Box
                    display="grid"
                    gridGap="12px"
                    gridTemplateColumns={'auto 1fr'}
                >
                    <CompanyIco />
                    <Input
                        autoComplete="false"
                        id="collection_form_name"
                        value={selectedFilesAdditionalDetails.company}
                        bg="transparent"
                        onChange={(e) => {
                            setSelectedFilesAdditionalDetails({
                                ...selectedFilesAdditionalDetails,
                                company: e.target.value,
                            })
                        }}
                        color="primary"
                        border="none"
                        fontSize="14px"
                        placeholder="Add Company"
                        className="placeholder-darkgray"
                    ></Input>
                </Box>
                <Box
                    display="grid"
                    gridGap="12px"
                    gridTemplateColumns={'auto 1fr'}
                >
                    <BrandIco />
                    <Input
                        autoComplete="false"
                        id="collection_form_name"
                        value={selectedFilesAdditionalDetails.brand}
                        bg="transparent"
                        onChange={(e) => {
                            setSelectedFilesAdditionalDetails({
                                ...selectedFilesAdditionalDetails,
                                brand: e.target.value,
                            })
                        }}
                        color="primary"
                        border="none"
                        fontSize="14px"
                        placeholder="Add Brand"
                        className="placeholder-darkgray"
                    ></Input>
                </Box>
                <Box
                    display="grid"
                    gridGap="12px"
                    gridTemplateColumns={'auto 1fr'}
                >
                    <PaintIco />
                    <Box
                        width="60%"
                        display="flex"
                        flexWrap="wrap"
                        gridGap="6px"
                    >
                        {selectedFilesAdditionalDetails.colors.map(
                            ({ color }) => (
                                <Tag
                                    key={color}
                                    element={color}
                                    color={formatColorCode(color)}
                                    action
                                    onClick={() => {
                                        setSelectedFilesAdditionalDetails({
                                            ...selectedFilesAdditionalDetails,
                                            colors: selectedFilesAdditionalDetails.colors.filter(
                                                (c: any) => c.color !== color
                                            ),
                                        })
                                    }}
                                />
                            )
                        )}
                        <ColorPicker
                            onAdd={(color) => {
                                setSelectedFilesAdditionalDetails({
                                    ...selectedFilesAdditionalDetails,
                                    colors: [
                                        ...selectedFilesAdditionalDetails.colors,
                                        { color: color, name: '' },
                                    ],
                                })
                            }}
                            buttonIcon={
                                <Text my="0px" color="textSecondary">
                                    Add Color
                                </Text>
                            }
                        />
                    </Box>
                </Box>
                <Box
                    display="grid"
                    gridGap="12px"
                    gridTemplateColumns={'auto 1fr'}
                >
                    <TagsIco />
                    <TagAutoCommplete
                        tags={
                            selectedFilesAdditionalDetails.tags?.map((tag) => ({
                                id: tag.tag,
                                name: tag.tag,
                                color: tag.color,
                            })) || []
                        }
                        updateTag={(tag) => {
                            setSelectedFilesAdditionalDetails({
                                ...selectedFilesAdditionalDetails,
                                tags: [
                                    ...selectedFilesAdditionalDetails.tags,
                                    { tag: tag.name, color: tag.color },
                                ],
                            })
                        }}
                        removeTag={(tagName) => {
                            setSelectedFilesAdditionalDetails({
                                ...selectedFilesAdditionalDetails,
                                tags: selectedFilesAdditionalDetails.tags.filter(
                                    (tag) => tag.tag !== tagName
                                ),
                            })
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    )
}

export default AddFilesToLibraryModal
