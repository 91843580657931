import { degToRad } from 'three/src/math/MathUtils'
import { useConfiguratorContext } from '../configurator.context'
import type { MeshCustomMaterial } from '../panel-functions/material/custom-material'
import {
    addGraphic,
    calculateCurrentUv,
    createBoundingBox,
} from '../panel-functions/graphic/helpers'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { uuid } from 'src/helpers'

export function useGraphic() {
    const { highlights, graphic, setGraphic, recentlyUsed, setRecentlyUsed } =
        useConfiguratorContext()

    function updateRepeatAndOffset(
        value: number,
        key: 'u' | 'v',
        type: 'offset' | 'repeat',
        isOffsetLinked: boolean,
        isScaleLinked: boolean
    ) {
        const mesh = highlights[0].mesh
        const material = mesh.material as MeshCustomMaterial
        if (graphic) {
            switch (type) {
                case 'offset':
                    if (isOffsetLinked) {
                        mesh.material.mapLayer1.offset.x = value
                        mesh.material.mapLayer1.offset.y = value
                        if (mesh.material.normalMapLayer1) {
                            mesh.material.normalMapLayer1.offset.x = value
                            mesh.material.normalMapLayer1.offset.y = value
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                offset: {
                                    ...graphic.config.offset,
                                    u: value,
                                    v: value,
                                },
                            },
                        })
                    } else {
                        if (key === 'u') {
                            mesh.material.mapLayer1.offset.x = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.offset.x = value
                            }
                        } else {
                            mesh.material.mapLayer1.offset.y = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.offset.y = value
                            }
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                offset: {
                                    ...graphic.config.offset,
                                    [key]: value,
                                },
                            },
                        })
                    }
                    material.mapLayer1.center.set(
                        material.mapLayer1.offset.x -
                            0.5 * (1 - material.mapLayer1.repeat.x),
                        material.mapLayer1.offset.y -
                            0.5 * (1 - material.mapLayer1.repeat.y)
                    )
                    return
                case 'repeat':
                    if (isScaleLinked) {
                        mesh.material.mapLayer1.repeat.x = value
                        mesh.material.mapLayer1.repeat.y = value
                        if (mesh.material.normalMapLayer1) {
                            mesh.material.normalMapLayer1.repeat.x = value
                            mesh.material.normalMapLayer1.repeat.y = value
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                repeat: {
                                    ...graphic.config.repeat,
                                    u: value,
                                    v: value,
                                },
                            },
                        })
                    } else {
                        if (key === 'u') {
                            mesh.material.mapLayer1.repeat.x = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.repeat.x = value
                            }
                        } else {
                            mesh.material.mapLayer1.repeat.y = value
                            if (mesh.material.normalMapLayer1) {
                                mesh.material.normalMapLayer1.repeat.y = value
                            }
                        }
                        setGraphic({
                            ...graphic,
                            config: {
                                ...graphic.config,
                                repeat: {
                                    ...graphic.config.repeat,
                                    [key]: value,
                                },
                            },
                        })
                    }
                    return
            }
        }
    }

    function updateSubstanceness(value: number) {
        if (!graphic) return
        const mesh = highlights[0].mesh
        mesh.material.substancenessLayer1 = value
        setGraphic({
            ...graphic,
            config: {
                ...graphic.config,
                substanceNess: value,
            },
        })
    }
    function updateTransparency(value: number) {
        if (!graphic) return
        const material = highlights[0].mesh.material as MeshCustomMaterial
        material.transparencyLayer1 = value
        setGraphic({
            ...graphic,
            config: {
                ...graphic.config,
                transparency: value,
            },
        })
    }
    function updateRotation(value: number) {
        if (!graphic) return
        const mesh = highlights[0].mesh
        const material = mesh.material as MeshCustomMaterial
        material.mapLayer1.rotation = degToRad(value)
        if (material.normalMapLayer1) {
            material.normalMapLayer1.rotation = degToRad(value)
        }
        setGraphic({
            ...graphic,
            config: {
                ...graphic.config,
                rotation: value,
            },
        })
    }
    function deleteGraphic(id: string) {
        setRecentlyUsed({
            ...recentlyUsed,
            graphics: recentlyUsed.graphics.filter(
                (graphic) => graphic.id !== id
            ),
        })
        const mesh = highlights[0].mesh
        const material = mesh.material as MeshCustomMaterial
        material.mapLayer1 = null
    }

    async function uploadGraphic(
        file: File,
        graphicConfig?: TMapConfig,
        addNew: boolean = true
    ) {
        const selectedMaterial = highlights[0]
        // Calculate UV
        const UV = calculateCurrentUv(selectedMaterial.mesh)
        const box = createBoundingBox(selectedMaterial.mesh)

        if (file && box) {
            const url = getLocalImageUrl(file)
            if (url) {
                const config = await addGraphic(
                    box,
                    selectedMaterial.mesh,
                    UV,
                    url
                )
                const scaleAndOffset = graphicConfig?.config ||
                    config || {
                        offset: {
                            u:
                                selectedMaterial.mesh.material?.map?.offset
                                    ?.x || 0,
                            v:
                                selectedMaterial.mesh.material?.map?.offset
                                    ?.y || 0,
                        },
                        repeat: {
                            u:
                                selectedMaterial.mesh.material?.map?.repeat
                                    ?.x || 0,
                            v:
                                selectedMaterial.mesh.material?.map?.repeat
                                    ?.y || 0,
                        },
                    }

                const graphicId = graphicConfig?.id || uuid()
                if (addNew) {
                    setRecentlyUsed((recentlyUsed) => ({
                        ...recentlyUsed,
                        graphics: [
                            ...recentlyUsed.graphics,
                            {
                                blob: new Blob([file]),
                                id: graphicId,
                                name: file.name,
                                path: file.webkitRelativePath,
                                config: {
                                    ...scaleAndOffset,
                                    rotation:
                                        graphicConfig?.config.rotation ||
                                        selectedMaterial.mesh.material?.map
                                            ?.rotation ||
                                        0,
                                    transparency:
                                        graphicConfig?.config.transparency || 0,
                                    substanceNess:
                                        graphicConfig?.config.substanceNess ||
                                        1,
                                },
                            },
                        ],
                    }))
                }

                setGraphic({
                    blob: new Blob([file]),
                    id: graphicId,
                    name: file.name,
                    path: file.webkitRelativePath,
                    config: {
                        ...scaleAndOffset,
                        rotation:
                            selectedMaterial.mesh.material?.map?.rotation || 0,
                        transparency: 0,
                        substanceNess: 1,
                    },
                })
            }
        }
    }

    return {
        updateRepeatAndOffset,
        updateSubstanceness,
        updateTransparency,
        updateRotation,
        deleteGraphic,
        uploadGraphic,
    }
}
