import { gql } from '@apollo/client'

export const InsertCategory = gql`
    mutation insertCategory($name: String!) {
        insert_category_one(object: { name: $name }) {
            deleted_at
            created_by
            created_at
            name
            id
        }
    }
`

export const InsertFolder = gql`
    mutation insertFolder(
        $category_id: bigint!
        $name: String!
        $parent_id: bigint
    ) {
        insert_folders_one(
            object: {
                category_id: $category_id
                name: $name
                parent_id: $parent_id
            }
        ) {
            category_id
            id
            name
            parent_id
        }
    }
`

export const DeleteCategory = gql`
    mutation deleteCategory($ids: [bigint!]!, $deleted_at: timestamptz!) {
        update_category(
            where: { id: { _in: $ids } }
            _set: { deleted_at: $deleted_at }
        ) {
            affected_rows
            returning {
                id
                name
            }
        }
    }
`
export const UpdateCategoryName = gql`
    mutation UpdateCategoryName($id: bigint!, $name: String) {
        update_category_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
            id
            name
        }
    }
`

export const UpdateFolderName = gql`
    mutation UpdateFolderName($id: bigint!, $name: String!) {
        update_folders_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
            name
            id
        }
    }
`

export const InsertLibraryFavourites = gql`
    mutation insertLibraryFavourites(
        $folder_id: bigint
        $library_file_id: bigint
    ) {
        insert_library_favourites(
            objects: {
                folder_id: $folder_id
                library_file_id: $library_file_id
            }
        ) {
            affected_rows
            returning {
                id
                library_file_id
                organisation_id
                folder_id
                created_at
                user_id
            }
        }
    }
`

export const DeleteLibraryFav = gql`
    mutation deleteLibraryFav($id: bigint!) {
        delete_library_favourites_by_pk(id: $id) {
            folder_id
            id
            library_file_id
            user_id
        }
    }
`

export const DeleteFolderById = gql`
    mutation deleteFolderById($id: bigint!, $deleted_at: timestamptz!) {
        update_folders(
            where: { _or: [{ id: { _eq: $id } }, { parent_id: { _eq: $id } }] }
            _set: { deleted_at: $deleted_at }
        ) {
            affected_rows
            returning {
                id
                name
                deleted_at
            }
        }
        update_library_files(
            where: { folder_id: { _eq: $id } }
            _set: { deleted_at: $deleted_at }
        ) {
            affected_rows
            returning {
                id
                name
                deleted_at
            }
        }
    }
`

export const RemoveLibraryThumbnailById = gql`
    mutation removeLibraryThumbnailById($folderId: bigint!) {
        update_folders(
            where: { id: { _eq: $folderId } }
            _set: { thumbnail: null }
        ) {
            affected_rows
            returning {
                id
                name
                thumbnail
            }
        }
    }
`

export const UpdateLibraryFile = gql`
    mutation updateLibraryFile(
        $id: bigint!
        $brand: String!
        $color: jsonb!
        $company: String!
        $tags: jsonb!
    ) {
        update_library_files(
            where: { id: { _eq: $id } }
            _set: {
                brand: $brand
                color: $color
                company: $company
                tags: $tags
            }
        ) {
            affected_rows
            returning {
                brand
                color
                company
                tags
            }
        }
    }
`

export const UpdateFolderThumbnail = gql`
    mutation updateFolderThumbnail($id: bigint!, $thumbnail: String!) {
        update_folders(
            where: { id: { _eq: $id } }
            _set: { thumbnail: $thumbnail }
        ) {
            affected_rows
            returning {
                name
                thumbnail
            }
        }
    }
`
