import { useCallback, useEffect, useRef, useState } from 'react'

type UseIntersectionObserverProps = {
    isLoading: boolean
    threshold?: number
}

function useIntersectionObserver({
    isLoading,
    threshold = 0,
}: UseIntersectionObserverProps) {
    const [isIntersecting, setIsIntersecting] = useState(false)
    const observer = useRef<IntersectionObserver | null>(null)
    const _lastElementRef = useRef<HTMLDivElement | null>(null)

    const callbackFn = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries
            const newState = entry.isIntersecting
            if (newState !== isIntersecting) {
                setIsIntersecting(newState)
            }
        },
        [isIntersecting]
    )

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new IntersectionObserver(callbackFn, {
            threshold,
        })

        if (_lastElementRef.current) {
            observer.current.observe(_lastElementRef.current)
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect()
            }
        }
    }, [isLoading, threshold, callbackFn])

    const lastElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (_lastElementRef.current) {
                observer.current?.unobserve(_lastElementRef.current)
            }

            _lastElementRef.current = node

            if (node && !isLoading) {
                observer.current?.observe(node)
            }
        },
        [isLoading]
    )

    return { isIntersecting, lastElementRef }
}

export default useIntersectionObserver
