import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { Box } from 'src/components/utility'

function ExcelPptPreviewSection({
    url,
    fileName,
    type,
}: {
    url: string
    fileName: string
    type: 'xlsx' | 'pptx'
}) {
    const docs = [
        {
            uri: url,
            fileType: 'xlsx',
            fileName,
        },
    ]

    return (
        <Box height="100%">
            <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                        disableHeader: true,
                        disableFileName: true,
                    },
                }}
            />
        </Box>
    )
}

export default ExcelPptPreviewSection
