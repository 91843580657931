import { gql } from '@apollo/client'

export const GetAnnotatedComments = gql`
    query getAnnotatedComments(
        $entity_id: bigint!
        $file_id: bigint!
        $entity_name: String!
        $type: String = "3d"
    ) {
        annotations(
            where: {
                entity_id: { _eq: $entity_id }
                file_id: { _eq: $file_id }
                entity_name: { _eq: $entity_name }
                file_dimention_type: { _eq: $type }
            }
            order_by: { created_at: asc }
        ) {
            id
            comment
            coordinates
            entity_id
            entity_name
            file_id
            metadata
            parent_id
            updated_at
            created_at
            other_data
            file_dimention_type
            user {
                id
                name
                other_data
            }
        }
    }
`
export const GetAnnotatedCommentsForLibraryFile = gql`
    query getAnnotatedCommentsForLibraryFile(
        $entity_id: bigint!
        $library_file_id: bigint!
        $entity_name: String!
        $type: String = "3d"
    ) {
        annotations(
            where: {
                entity_id: { _eq: $entity_id }
                library_file_id: { _eq: $library_file_id }
                entity_name: { _eq: $entity_name }
                file_dimention_type: { _eq: $type }
            }
            order_by: { id: asc }
        ) {
            id
            comment
            coordinates
            entity_id
            entity_name
            file_id
            library_file_id
            metadata
            parent_id
            updated_at
            created_at
            other_data
            user {
                id
                name
                other_data
            }
        }
    }
`

export const GetProductIdFromSectionId = gql`
    query getProductIdFromSections($entity_id: bigint) {
        sub_todo_sections(where: { id: { _eq: $entity_id } }) {
            todo {
                product_variant {
                    id
                    product {
                        id
                    }
                }
            }
        }
    }
`
