import React, { createRef, useEffect, useState } from 'react'
import {
    Box,
    Text,
    Image as Img,
    Button,
    Input,
    Link,
    Image,
} from 'src/components/utility'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { useConfiguratorContext } from './configurator.context'
import { DeleteIco, StrokedArrow } from 'src/components/svg-icons'
import {
    ConfigIco,
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { captureImage, createMaterial } from './helper/create-material'
import { uuid } from 'src/helpers'
import type { MeshCustomMaterial } from './panel-functions/material/custom-material'
import ControlPanel from './panel-functions/basic-panel/control-panel'
import { DivisionLine } from 'src/components/division-line/division-line'
import FabricControlPanel from './panel-functions/fabric/control-panel'
import { fabricPresets } from './helper/presets'

export const MapConfigurator = () => {
    const {
        maps,
        setMaps,
        config,
        setConfig,
        highlights,
        setActiveArtwork,
        recentlyUsed,
        setSelectedFabric,
        selectedFabric,
    } = useConfiguratorContext()
    const [isFabricControlPanel, setIsFabricControlPanelActive] = useState({
        mapCode: '',
        mapName: '',
        isActive: false,
    })
    const inputRefs = maps.map((map) => createRef())
    const [mapsStatus, setMapsStatus] = React.useState<
        { id: number; isOpen: boolean }[]
    >(
        maps.map((map) => {
            return { id: map.id, isOpen: false }
        })
    )
    const [activeControlPanel, setActiveControlPanel] = React.useState<{
        isActive: boolean
        mode: 'artwork' | 'fabrics' | 'graphics'
        id?: string
    }>({
        isActive: false,
        mode: 'artwork',
        id: undefined,
    })
    const mesh = highlights[0]?.mesh
    const material = mesh.material as MeshCustomMaterial
    useEffect(() => {
        if (!mesh) return
        setMaps((maps) => {
            return maps.map((map) => {
                return {
                    ...map,
                    file: captureImage(mesh, map.code),
                }
            })
        })
        setSelectedFabric({
            id: uuid(),
            name: '',
            baseMap: '',
            maps: fabricPresets[0].maps.map((map) => {
                return {
                    ...map,
                    config: {
                        metalnessIntensity: material.metalness,
                        offset: {
                            u: material[map.code]?.offset?.x || 0,
                            v: material[map.code]?.offset?.y || 0,
                        },
                        rotation: material[map.code]?.rotation || 0,
                        scale: {
                            u: material[map.code]?.repeat?.x || 1,
                            v: material[map.code]?.repeat?.y || 1,
                        },
                        roughnessIntensity: material.roughness,
                    },
                    map: captureImage(mesh, map.code),
                }
            }),
        })
    }, [highlights])

    useEffect(() => {
        setMaps((maps) => {
            return maps.map((map) => {
                const selectedFabricMaps = selectedFabric?.maps.find(
                    (selectedMap) => selectedMap.code === map.code
                )
                return {
                    ...map,
                    file: selectedFabricMaps?.map || '',
                }
            })
        })
    }, [selectedFabric])

    function openFileDialogueBox(ref: React.RefObject<HTMLInputElement>) {
        if (ref && ref.current) {
            ref.current.click()
        }
    }

    function updateMaterial(
        path: string,
        type:
            | 'map'
            | 'normalMap'
            | 'displacementMap'
            | 'metalnessMap'
            | 'roughnessMap'
            | 'aoMap'
    ) {
        highlights.map((highlight) => {
            const mesh = highlight.mesh
            if (mesh.material && highlight.isActive) {
                mesh.material[type] = createMaterial(path, {
                    offset: {
                        u: highlight?.mesh?.material[type]?.offset?.x || 0,
                        v: highlight?.mesh?.material[type]?.offset?.y || 0,
                    },
                    scale: {
                        u: highlight?.mesh?.material[type]?.repeat?.x || 0,
                        v: highlight?.mesh?.material[type]?.repeat?.y || 0,
                    },
                }).upscale
            }
        })
    }

    function handleDelete(
        mapCode:
            | 'map'
            | 'normalMap'
            | 'roughnessMap'
            | 'metalnessMap'
            | 'displacementMap'
            | 'aoMap'
    ) {
        highlights.map((highlight) => {
            const mesh = highlight.mesh
            if (mesh.material && highlight.isActive) {
                mesh.material[mapCode] = null
            }
        })
        setMaps((maps) =>
            maps.map((map) => {
                if (map.code === mapCode) {
                    return {
                        ...map,
                        file: null,
                    }
                }
                return map
            })
        )
    }
    function handleFabricUpdate(fabric: TFabric) {
        highlights.map((highlight) => {
            if (highlight.isActive) {
                fabric?.maps.map((map) => {
                    highlight.mesh.material[map.code] = createMaterial(
                        map.map,
                        {
                            offset: {
                                u:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.offset
                                        ?.y || 0,
                            },
                            scale: {
                                u:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.x || 0,
                                v:
                                    highlight.mesh?.material[map.code]?.repeat
                                        ?.y || 0,
                            },
                        }
                    ).upscale
                })
            }
        })
    }

    return (
        <Box style={{ zIndex: 10 }}>
            <Box px="12px">
                <Text my="0px" fontSize="12px">
                    Fabrics
                </Text>
                <Box mt="8px" mb="8px" display="flex" gridColumnGap="12px">
                    {recentlyUsed.fabrics.map((usedFabric) => {
                        return (
                            <Box>
                                <Box
                                    position="relative"
                                    onClick={() => {
                                        if (
                                            usedFabric.id !== selectedFabric?.id
                                        ) {
                                            handleFabricUpdate(usedFabric)
                                            setSelectedFabric(usedFabric)
                                        }
                                    }}
                                    className="cursor-pointer"
                                    width="50px"
                                    height="50px"
                                    borderRadius="4px"
                                    overflow="hidden"
                                >
                                    <Image
                                        width="50px"
                                        height="50px"
                                        src={usedFabric.baseMap}
                                    />

                                    {selectedFabric?.id === usedFabric.id && (
                                        <Box
                                            position="absolute"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                            height="100%"
                                            bg="#00000050"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderRadius="4px"
                                        >
                                            <Button
                                                bg="transparent"
                                                onClick={() => {
                                                    setActiveControlPanel({
                                                        isActive: true,
                                                        id: usedFabric.id,
                                                        mode: 'fabrics',
                                                    })
                                                }}
                                                border="none"
                                            >
                                                <ConfigIco color="#fff" />
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                <Text my="0px" mt="4px" fontSize="12px">
                                    {usedFabric.name}
                                </Text>
                            </Box>
                        )
                    })}
                </Box>
                <DivisionLine color="gray250" />
            </Box>

            {maps.map((map, key) => {
                return (
                    <>
                        <Accordion
                            style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                boxShadow: 'none',
                                // padding: '24px 0px',
                            }}
                            onChange={() => {
                                setMapsStatus(
                                    mapsStatus.map((mapStatus) => {
                                        return mapStatus.id === map.id
                                            ? {
                                                  ...mapStatus,
                                                  isOpen: !mapStatus.isOpen,
                                              }
                                            : { ...mapStatus, isOpen: false }
                                    })
                                )
                            }}
                            expanded={mapsStatus[key].isOpen}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <Box className="vertical">
                                        <StrokedArrow />
                                    </Box>
                                }
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                    pr="12px"
                                >
                                    <Text fontSize="12px">{map.type}</Text>
                                    <Button
                                        bg="transparent"
                                        border="none"
                                        p="0px"
                                        onClick={() => {
                                            setIsFabricControlPanelActive({
                                                isActive: true,
                                                mapCode: map.code,
                                                mapName: map.type,
                                            })
                                        }}
                                    >
                                        <ConfigIco />
                                    </Button>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Box>
                                        <Img
                                            className="img-clear"
                                            width="100%"
                                            height="100px"
                                            src={
                                                map.file
                                                    ? typeof map.file !==
                                                      'string'
                                                        ? getLocalImageUrl(
                                                              map.file
                                                          )
                                                        : map.file
                                                    : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
                                            }
                                        />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="20px"
                                        >
                                            <Button
                                                p="0px"
                                                bg="transparent"
                                                border="none"
                                                onClick={() => {
                                                    inputRefs[key] &&
                                                        openFileDialogueBox(
                                                            inputRefs[
                                                                key
                                                            ] as React.RefObject<HTMLInputElement>
                                                        )
                                                }}
                                            >
                                                <UploadFile />
                                            </Button>
                                            <Input
                                                autoComplete="false"
                                                type="file"
                                                multiple
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    if (
                                                        e.target.files &&
                                                        e.target.files.length >
                                                            0
                                                    ) {
                                                        const file =
                                                            e.target.files[0]
                                                        if (
                                                            map.code === 'map'
                                                        ) {
                                                            const material =
                                                                highlights[0]
                                                                    .mesh
                                                                    .material as MeshCustomMaterial
                                                            setActiveArtwork({
                                                                blob: file,
                                                                id: uuid(),
                                                                name: file.name,
                                                                path:
                                                                    getLocalImageUrl(
                                                                        file
                                                                    ) || '',
                                                                config: {
                                                                    repeat: {
                                                                        u:
                                                                            material
                                                                                .map
                                                                                ?.repeat
                                                                                .x ||
                                                                            1,
                                                                        v:
                                                                            material
                                                                                .map
                                                                                ?.repeat
                                                                                .y ||
                                                                            1,
                                                                    },
                                                                    offset: {
                                                                        u:
                                                                            material
                                                                                .map
                                                                                ?.offset
                                                                                .x ||
                                                                            0,
                                                                        v:
                                                                            material
                                                                                .map
                                                                                ?.offset
                                                                                .y ||
                                                                            0,
                                                                    },
                                                                    rotation:
                                                                        material
                                                                            .map
                                                                            ?.rotation ||
                                                                        0,
                                                                    substanceNess: 0,
                                                                    transparency: 0,
                                                                },
                                                            })
                                                        }
                                                        updateMaterial(
                                                            getLocalImageUrl(
                                                                file
                                                            ) || '',
                                                            map.code
                                                        )
                                                        setMaps((maps) =>
                                                            maps.map(
                                                                (foundMap) => {
                                                                    return foundMap.id ===
                                                                        map.id
                                                                        ? {
                                                                              ...foundMap,
                                                                              file: file,
                                                                          }
                                                                        : foundMap
                                                                }
                                                            )
                                                        )
                                                    }
                                                }}
                                                onClick={(e: any) => {
                                                    e.target.value = null
                                                }}
                                                display="none"
                                                ref={inputRefs[key]}
                                                bg="none"
                                            />
                                            <Link
                                                href={
                                                    map.file
                                                        ? typeof map.file !==
                                                          'string'
                                                            ? map.file
                                                            : map.file
                                                        : ''
                                                }
                                                download={map.type + '.png'}
                                                bg="white"
                                                // border="none"
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    padding: '8px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                }}
                                                className="download-link"
                                            >
                                                <Download />
                                            </Link>
                                            <Button
                                                p="0px"
                                                bg="transparent"
                                                border="none"
                                                onClick={() =>
                                                    handleDelete(map.code)
                                                }
                                            >
                                                <DeleteIco />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {map.sliderType && (
                                        <Box
                                            width="100%"
                                            className="intensity-slider-container"
                                            mt="10px"
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Text my="0px" fontSize="12px">
                                                    Intensity %
                                                </Text>
                                                <Input
                                                    type="number"
                                                    value={
                                                        config[
                                                            map.sliderType ===
                                                            'ROUGH_NESS'
                                                                ? 'roughnessIntensity'
                                                                : 'metalnessIntensity'
                                                        ]
                                                    }
                                                    bg="transparent"
                                                    onChange={(e) => {
                                                        if (
                                                            map.sliderType ===
                                                            'ROUGH_NESS'
                                                        ) {
                                                            return setConfig({
                                                                ...config,
                                                                roughnessIntensity:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                            })
                                                        } else if (
                                                            map.sliderType ===
                                                            'METAL_NESS'
                                                        ) {
                                                            return setConfig({
                                                                ...config,
                                                                metalnessIntensity:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ),
                                                            })
                                                        }
                                                    }}
                                                    border="solid"
                                                    borderWidth={1}
                                                    borderColor="secondaryLighterBlue"
                                                    borderRadius="4px"
                                                    width="71px"
                                                    height="32px"
                                                    max="100"
                                                />
                                            </Box>
                                            <Input
                                                mt="12px"
                                                className="intensity-slider"
                                                width="100%"
                                                value={
                                                    config[
                                                        map.sliderType ===
                                                        'ROUGH_NESS'
                                                            ? 'roughnessIntensity'
                                                            : 'metalnessIntensity'
                                                    ]
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        map.sliderType ===
                                                        'ROUGH_NESS'
                                                    ) {
                                                        setConfig({
                                                            ...config,
                                                            roughnessIntensity:
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    } else if (
                                                        map.sliderType ===
                                                        'METAL_NESS'
                                                    ) {
                                                        setConfig({
                                                            ...config,
                                                            metalnessIntensity:
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    }
                                                }}
                                                type="range"
                                                min="0"
                                                step="0.1"
                                                max="100"
                                                bg="trasparent"
                                            ></Input>
                                        </Box>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            })}
            {selectedFabric && (
                <ControlPanel
                    mode={'fabrics'}
                    entity={{ id: selectedFabric.id }}
                    setIsControlPanelActive={(isActive) =>
                        setActiveControlPanel({
                            id: undefined,
                            mode: 'artwork',
                            isActive,
                        })
                    }
                    isControlPanelActive={activeControlPanel.isActive}
                />
            )}
            <FabricControlPanel
                key={isFabricControlPanel.mapCode}
                isFabricControlPanel={isFabricControlPanel.isActive}
                setIsFabricControlPanelActive={(isActive) =>
                    setIsFabricControlPanelActive({
                        isActive: isActive,
                        mapCode: '',
                        mapName: '',
                    })
                }
                mapCode={isFabricControlPanel.mapCode as TMapsKeys}
                mapName={isFabricControlPanel.mapName}
            />
        </Box>
    )
}
