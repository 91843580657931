export function DropDownArrow({
    color,
    size,
    rotate,
    id,
}: {
    color?: string
    size?: number
    rotate?: boolean
    id?: string
}) {
    return (
        <svg
            id={id}
            width={size ? size : 16}
            height={size ? size : 17}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: rotate ? 'rotate(180deg)' : undefined }}
        >
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M3.09527 7.06261C2.86047 6.83419 2.86047 6.45704 3.09527 6.22862C3.3211 6.00893 3.68076 6.00893 3.90659 6.22862L7.99996 10.2107L12.0933 6.22862C12.3192 6.00893 12.6788 6.00893 12.9046 6.22862C13.1394 6.45704 13.1394 6.83419 12.9046 7.06261L8.69726 11.1556C8.30908 11.5333 7.69084 11.5333 7.30266 11.1556L3.09527 7.06261Z"
                fill={color ? color : '#022143'}
            />
        </svg>
    )
}
