import React, { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import CommentsDropdown from 'src/components-v2/comments-dropdown/comments-dropdown'
import useCommentEditor from 'src/components-v2/comments-dropdown/useCommentEditor'
import { CommentsIco } from 'src/components/svg-icons/comments-ico'
import { Box, Button, Text } from 'src/components/utility'
import { ENTITIES } from 'src/helpers/enums'
import useFetchComments from 'src/hooks/useFetchComments.hook'
import type { Team } from 'src/services/graphql/query/@types/collections'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'

function TodoComments({
    todoId,
    todoName,
    collectionTeam,
    height,
}: {
    todoId: number
    todoName: string
    collectionTeam: Team
    height?: string
}) {
    useFetchComments({
        entityId: todoId,
        entityName: ENTITIES.todos,
    })

    const location = useLocation()
    const { isCommentNotification } = location.state || {}
    const [searchParams] = useSearchParams()
    const [showComments, setShowComments] = useState(
        isCommentNotification
            ? isCommentNotification
            : searchParams.get('showComments') === '1' &&
              !searchParams.get('sectionId')
            ? true
            : false
    )

    const { comments: commentsFromSocket, status } = useCommentsStore()

    const entityComments = commentsFromSocket?.find(
        (comment: any) =>
            comment.entity_id === String(todoId) &&
            comment.entity_name === ENTITIES.todos
    )

    // Sort comments by to show latest comment come at the end (nearest to input box)
    // !!FIXME: This is a temporary solution until we have a better way to handle this from backend
    const comments = entityComments?.comments.sort(
        (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )

    const { editor, onComment, onDelete } = useCommentEditor({
        team: collectionTeam,
        entityId: todoId,
        entityName: ENTITIES.todos,
    })

    return (
        <Box position="relative">
            {comments && comments.length > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    top="-10px"
                    right="0"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {comments.length}
                    </Text>
                </Box>
            )}
            <Button
                onClick={() => {
                    setShowComments(!showComments)
                }}
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                width="max-content"
                id="comment-button"
            >
                <CommentsIco active={showComments} />
            </Button>
            {showComments && (
                <CommentsDropdown
                    comments={comments}
                    loading={status !== 'active'}
                    setShowComments={setShowComments}
                    height={height ? height : '80vh'}
                    editor={editor}
                    onComment={() => onComment(editor)}
                    onDelete={(commentId: string) => onDelete(commentId)}
                />
            )}
        </Box>
    )
}

export default TodoComments
