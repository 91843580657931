import type { Camera } from '@react-three/fiber'
import * as THREE from 'three'

export function renderImage(
    camera: Camera,
    scene: THREE.Scene,
    {
        imageWidth = 3840,
        imageHeight = 2160,
    }: { imageWidth: number; imageHeight: number }
) {
    const IMAGE_WIDTH = imageWidth
    const IMAGE_HEIGHT = imageHeight
    if (camera) {
        //@ts-ignore
        const currentAspect = camera.aspect
        //@ts-ignore
        camera.aspect = IMAGE_WIDTH / IMAGE_HEIGHT
        camera.updateProjectionMatrix()
        camera.updateMatrixWorld()

        const renderer = new THREE.WebGLRenderer({ antialias: true })
        renderer.setSize(IMAGE_WIDTH, IMAGE_HEIGHT)
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.toneMapping = THREE.LinearToneMapping
        renderer.toneMappingExposure = 1

        const width = IMAGE_WIDTH / window.devicePixelRatio
        const height = IMAGE_HEIGHT / window.devicePixelRatio
        const left = (screen.width - width) / 2
        const top = (screen.height - height) / 2

        // const output = window.open(
        //     '',
        //     '_blank',
        //     `location=no,left=${left},top=${top},width=${width},height=${height}`
        // )

        // if (output) {
        // const meta = document.createElement('meta')
        // meta.name = 'viewport'
        // meta.content =
        // 'width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0'
        // output.document.head.appendChild(meta)

        // output.document.body.style.background = '#000'
        // output.document.body.style.margin = '0px'
        // output.document.body.style.overflow = 'hidden'

        const canvas = renderer.domElement
        // @ts-ignore
        camera.aspect = currentAspect
        camera.updateProjectionMatrix()
        camera.updateMatrixWorld()

        canvas.style.width = width + 'px'
        canvas.style.height = height + 'px'
        renderer.render(scene, camera)
        return canvas.toDataURL()

        // renderer.dispose()
        // }
    }
    return ''
}
