import { CONFIG_VARS } from 'src/config/constants.config'

const { API_ENDPOINT } = CONFIG_VARS

if (!API_ENDPOINT) throw new Error('Api end point not defined ')

const API_VERSION = '/api/v1'

/**
 * @description To generate a JWT token when user is invited to a organization
 * @argument FIREBASE_JWT_TOKEN_SIGNUP_TOKEN
 * @param body
 * @method POST
 */
export const ONBOARD_USER = API_ENDPOINT + `${API_VERSION}/user/onboard`

/**
 * @description To generate a JWT from backend after firebase authentication
 * @argument FIREBASE_JWT_TOKEN_LOGIN_TOKEN
 * @param body
 * @method POST
 */
export const GENERATE_AUTH_TOKEN =
    API_ENDPOINT + `${API_VERSION}/user/generate/token`

/**
 * @description To get email for autofill
 * @param Invation_Token
 * @method POST
 */
export const GET_USER_INVITATION =
    API_ENDPOINT + `${API_VERSION}/user/invitation/`

export const DOWNLOAD_USER_IMAGE = API_ENDPOINT + `${API_VERSION}/download`
export const DOWNLOAD_LIBRARY_FILE =
    API_ENDPOINT + `${API_VERSION}/library/download`
export const DOWNLOAD_ANNOTATION_FILE_IMAGE =
    API_ENDPOINT + `${API_VERSION}/media/download`

export const UPLOAD_USER_IMAGE = API_ENDPOINT + `${API_VERSION}/file/upload`

/**
 * @description To create a new product with mendatory todos
 * @param body
 * @method POST
 */
export const CREATE_PRODUCT = API_ENDPOINT + `${API_VERSION}/create-product`

export const GET_USERNAME =
    API_ENDPOINT + `${API_VERSION}/reset-password/get-user-name?email=`

export const GET_ORGANIZATION_MEMBNERS =
    API_ENDPOINT + `${API_VERSION}/organisation/members`

export const ADD_COMMENT = API_ENDPOINT + `${API_VERSION}/comments/add-comment`

export const ADD_ANNOTATION_COMMENT =
    API_ENDPOINT + `${API_VERSION}/annotation/add-comment`

export const DELETE_COMMENT =
    API_ENDPOINT + `${API_VERSION}/comments/delete-comment`
export const DELETE_ANNOTATION_COMMENT =
    API_ENDPOINT + `${API_VERSION}/annotation/delete`

export const UPDATE_PRODUCT =
    API_ENDPOINT + `${API_VERSION}/product/update-product`

export const GET_MANAGER_TEAMS = API_ENDPOINT + `${API_VERSION}/dashboard/teams`
export const GET_TEAMS_BY_USER_ID = `${API_ENDPOINT}${API_VERSION}/get-teams/member`

/**
 * @description Fetch User by ID
 * @type UserById
 */
export const GET_USER_BY_ID = API_ENDPOINT + `${API_VERSION}/user`

/**
 * @description Subscribe to events for notification
 * @headers x-auth-token
 * @body fcm_token
 */
export const SUBSCRIBE_TO_NOTIFICATION =
    API_ENDPOINT + `${API_VERSION}/user/subscribe`

export const GET_RECENT_COMMENTS =
    API_ENDPOINT + `${API_VERSION}/comments/recent-comment`

export const MARK_ENTITY_AS_READ =
    API_ENDPOINT + `${API_VERSION}/update/actions-opened`

// Reports page EndPoints //
export const GET_COLLECTION_REPORTS = `${API_ENDPOINT}${API_VERSION}/reports/entity`

// Library Page Endpoints //
export const INSERT_FILE_TO_FOLDER = `${API_ENDPOINT}${API_VERSION}/library/file/upload`

export const DELETE_FILE = `${API_ENDPOINT}${API_VERSION}/file/delete`

export const DELETE_LIBRARY_FILE = `${API_ENDPOINT}${API_VERSION}/library/file/delete`

export const RENAME_FILE = `${API_ENDPOINT}${API_VERSION}/file/rename`
export const RENAME_LIBRARY_FILE = `${API_ENDPOINT}${API_VERSION}/library/file/rename`

export const ASSIGN_FILE_FROM_LIBRARY = `${API_ENDPOINT}${API_VERSION}/file/assign`
export const ASSIGN_FILE_FROM_FILES = `${API_ENDPOINT}${API_VERSION}/file/assign/from-file`

export const ASSIGN_FILE_TO_LIBRARY = `${API_ENDPOINT}${API_VERSION}/library/file/add`
export const GET_FILE_REQUEST_ID = `${API_ENDPOINT}${API_VERSION}/file-request-id/generate`
export const JOIN_WAITLIST = `${API_ENDPOINT}${API_VERSION}/virtu/waitlist-email`

export const GET_FILE_REQUEST_ID_FOR_ANNOTATION = `${API_ENDPOINT}${API_VERSION}/file-request-id/generate`
export const UPLOAD_FILE_TO_ANNOTATION = `${API_ENDPOINT}${API_VERSION}/media/upload`

// support ticket
export const CREATE_TICKET = `${API_ENDPOINT}${API_VERSION}/support/generate-ticket`

export const UPLOAD_VERSION = `${API_ENDPOINT}${API_VERSION}/configurator/versions/upload`

// Template creation

export const CREATE_TEMPLATE = `${API_ENDPOINT}${API_VERSION}/templates/create`
export const DUPLICATE_TEMPLATE = `${API_ENDPOINT}${API_VERSION}/templates/copy`
export const ASSING_TEMPLATE = `${API_ENDPOINT}${API_VERSION}/templates/assign`

// Todo section

export const ADD_COLOR = `${API_ENDPOINT}${API_VERSION}/colors/add`
export const DELETE_COLOR = `${API_ENDPOINT}${API_VERSION}/colors/delete`
export const ADD_LINK = `${API_ENDPOINT}${API_VERSION}/links/add`
export const DELETE_LINK = `${API_ENDPOINT}${API_VERSION}/links/delete`

// Global search

export const GLOBAL_SEARCH = `${API_ENDPOINT}${API_VERSION}/organisation/globalsearch`
export const GET_RECENT_SEARCH = `${API_ENDPOINT}${API_VERSION}/search/recent`
export const ADD_RECENT_SEARCH = `${API_ENDPOINT}${API_VERSION}/search/add-recent`

// Entity Count
export const TEAM_COUNT = `${API_ENDPOINT}${API_VERSION}/teams/count`
export const COLLABORATOR_COUNT = `${API_ENDPOINT}${API_VERSION}/members/count`

// Product Variant
export const CREATE_PRODUCT_VARIANT = `${API_ENDPOINT}${API_VERSION}/product-variant/copy`
