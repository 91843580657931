import { gql } from '@apollo/client'

export const AddTagWithRelation = gql`
    mutation AddTagWithRelation(
        $objects: [tag_relationships_insert_input!] = objects
    ) {
        insert_tag_relationships(objects: $objects) {
            returning {
                id
                color
                tag {
                    tag
                }
            }
        }
    }
`

export const DeleteExistingCreateNewTagRelation = gql`
    mutation deleteExistingCreateNewTagRelation(
        $entity_ids: [bigint!]!
        $entity_name: String!
        $objects: [tag_relationships_insert_input!] = {}
    ) {
        delete_tag_relationships(
            where: {
                entity_id: { _in: $entity_ids }
                entity_name: { _eq: $entity_name }
            }
        ) {
            affected_rows
        }
        insert_tag_relationships(objects: $objects) {
            affected_rows
        }
    }
`
