import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { FourOFourBag } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'

export const Page404 = ({ info }: { info?: string }) => {
    const navigate = useNavigate()
    return (
        <Box
            bg="white"
            flexDirection="column"
            height={'calc(100vh - 65px)'}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Text
                color="primary"
                fontWeight={300}
                my="0px"
                mt="24px"
                fontSize={'6rem'}
            >
                <FourOFourBag />
            </Text>
            <Text
                color="primary"
                fontWeight={500}
                my="0px"
                fontSize={'24px'}
                mt="16px"
            >
                Page not found!
            </Text>
            <Text
                textAlign="center"
                pt="24px"
                color="borderLine"
                my="0px"
                fontWeight={300}
                fontSize={'16px'}
            >
                {info
                    ? info
                    : 'The page you are looking for might have been removed or temporarily unavailable.'}
            </Text>
            <Box mt="32px">
                <ColoredButton onClick={() => navigate('/')} variant="primary">
                    Go back home
                </ColoredButton>
            </Box>
        </Box>
    )
}
