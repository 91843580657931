import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getFileRequestId, getLocalStorage, uploadFiles } from 'src/helpers'
import { CREATE_PRODUCT_VARIANT } from 'src/services/api/endpoints'

function useCreateVariant() {
    const [isCreating, setIsCreating] = useState(false)
    const navigate = useNavigate()

    async function uploadImageHandler(file: File, productId: number) {
        try {
            if (!file) {
                return
            }
            const { request_id } = await getFileRequestId({
                action: 'INSERT',
                entity_id: String(productId),
                entity_name: 'product_variants',
                number_of_files: 1,
            })
            if (request_id) {
                const filePath = URL.createObjectURL(file)
                await uploadFiles(
                    file,
                    productId.toString(),
                    filePath,
                    'product_variants',
                    'cover_pic',
                    (progess) => {},
                    request_id
                )
            }
        } catch {
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    async function createVariantHandler({
        productVariantId,
        name,
        thumbnailFile,
        onCompleted,
        navigateToTechPack,
    }: {
        productVariantId: number
        name?: string
        thumbnailFile?: File | null
        onCompleted?: () => void | PromiseLike<void>
        navigateToTechPack?: boolean
    }) {
        try {
            setIsCreating(true)
            const response = await axios.post(
                CREATE_PRODUCT_VARIANT,
                {
                    product_variant_id: productVariantId,
                    name,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 201) {
                if (thumbnailFile) {
                    const newVariantId = response.data.body.design.id
                    await uploadImageHandler(thumbnailFile, newVariantId)
                }
                if (onCompleted) {
                    await onCompleted()
                }
                if (navigateToTechPack) {
                    const techPackTodoId = response.data.body.todos.find(
                        (todo: { id: string; name: string; type: string }) =>
                            todo.type === 'techPack'
                    )?.id
                    if (techPackTodoId) {
                        navigate(`/todo/${techPackTodoId}`)
                    }
                } else {
                    navigate(
                        `/collections/product/${response.data.body.design.id}`
                    )
                }
            }
        } catch {
            toast('Failed to create variant', {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsCreating(false)
        }
    }

    return { isCreating, createVariantHandler }
}

export default useCreateVariant
