import * as React from 'react'
import { DivisionLine } from 'src/components/division-line/division-line'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Slider } from 'src/components/slider/slider'
import { Box, Button, Text } from 'src/components/utility'
import { getImageUrl, getSentenceCase, useUser } from 'src/helpers'
import IndividualCollaboratorTabSlider from './individual-collaborator-tab-slider'
import ContributorTabDetails from './contributor-tab-details'
import useStore from 'src/store/hooks/useStore'
import {
    useDeleteCollaborator,
    useFetchAnalytics,
    useFetchOrganistionMembers,
    useGetTeamsByUserId,
    useGetTodosByUserId,
    useTeamsByMemberId,
    useUpdateRole,
} from 'src/screens-v2/organisations-analytics/hooks'
import { Dots, TodoIco } from 'src/components/svg-icons'
import ProgressBar from 'src/components-v2/progress-bar/progress-bar'
import CollaboratorDropdown from 'src/screens-v2/organisations-analytics/tab-data/dropdown/collaborator-dropdown'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'

export interface IIndividualCollaboratorTabProps {
    tabView: boolean
}

export function IndividualCollaboratorTab(
    props: IIndividualCollaboratorTabProps
) {
    const { clearDrawerTabs, drawerTabs } = useStore()
    const location = useLocation()
    const { tabView = true } = props
    const params = useParams()
    const { fetchTodosByUserId, todosByUserId } = useGetTodosByUserId()
    const { fetchAnalytics, analyticsOfUser } = useFetchAnalytics('user')
    const navigate = useNavigate()
    const [collaboratorDropdown, setCollaboratorDropdown] =
        React.useState(false)

    const { organization_id, role } = useUser()

    const [currentTab, setCurrentTab] = React.useState<'todos' | 'teams'>(
        'todos'
    )

    const [searchParam, setSearchParam] = useSearchParams()
    const { filter, sort } = Object.fromEntries(searchParam.entries())

    const { removeUser, reactivateUser } = useDeleteCollaborator()
    const { fetchOrganistionMembers } = useFetchOrganistionMembers()
    const dropdownRef = useClickedOutside(() => setCollaboratorDropdown(false))
    const { fetchTeamsByMemberId, teams } = useTeamsByMemberId()
    const { fetchTeamsByUserId, teamsByUserId } = useGetTeamsByUserId()

    React.useEffect(() => {
        ;(async () => {
            fetchTodosByUserId(String(searchParam.get('id') || params.id))
            fetchAnalytics(String(searchParam.get('id') || params.id))
            fetchTeamsByUserId(String(searchParam.get('id') || params.id))
        })()
    }, [searchParam.get('id'), organization_id])

    const updateFn = useUpdateRole()
    async function updateRole(role: string) {
        await updateFn.updateRole(
            analyticsOfUser?.user?.id!,
            role.toLowerCase(),
            analyticsOfUser?.user?.name!
        )
        fetchAnalytics(analyticsOfUser?.user?.id!)
    }

    async function dropdownClickHandler(value: string) {
        switch (value) {
            case 'edit_user':
                setCollaboratorDropdown(false)
                break
            case 'reactivate-user':
                if (!analyticsOfUser.user) return
                reactivateUser(String(analyticsOfUser?.user.id))
                break
            case 'archive_user':
                setCollaboratorDropdown(false)
                break
            case 'remove_user':
                try {
                    removeUser(
                        String(analyticsOfUser?.user?.id),
                        analyticsOfUser?.user?.name || ''
                    )
                    const data = await fetchOrganistionMembers({
                        contributorsCurrentPage:
                            Number(searchParam.get('page')) || 1,
                        role: searchParam.get('role') || undefined,
                        searchString: searchParam.get('q') || '',
                    })
                    console.log(data)
                } catch (e) {}
                setCollaboratorDropdown(false)
                break
        }
    }

    const totalCompleted = analyticsOfUser?.totalCompletedTodos || 0
    const delayed =
        ((analyticsOfUser?.totalDelayedTodos || 0) / totalCompleted) * 100
    const completed =
        ((analyticsOfUser?.totalCompletedTodosOnTime || 0) / totalCompleted) *
        100
    const overAllProgress =
        ((analyticsOfUser?.totalCompletedTodosOnTime || 0) / totalCompleted) *
        100

    function navigateToDetails() {
        clearDrawerTabs()
        navigate('/user/' + analyticsOfUser?.user?.id)
    }

    type SortTypes = 'created_at' | 'due_date' | 'name'

    // Function to get sort object based on the sort value from params
    function getSortObject(sort: SortTypes | undefined) {
        switch (sort) {
            case 'created_at':
                return {
                    created_at: 'asc',
                } as const
            case 'due_date':
                return {
                    due_date: 'desc',
                } as const
            case 'name':
                return {
                    name: 'asc',
                } as const
            default:
                return undefined
        }
    }

    // Function to get filter object based on the filter value from params
    function getFilterObject(filter: string | undefined) {
        switch (filter) {
            case 'inProgress':
                return {
                    _eq: 'inProgress',
                } as const
            case 'complete':
                return {
                    _eq: 'complete',
                } as const
            case 'inReview':
                return {
                    _eq: 'inReview',
                } as const
            case 'delayed':
                return {
                    _eq: 'delayed',
                } as const
            case 'revisionRequested':
                return {
                    _eq: 'revisionRequested',
                } as const
            default:
                return undefined
        }
    }

    function handleDropdownClick(type: 'todo' | 'team', value: string) {
        if (type === 'team') {
        }
        if (type === 'todo') {
            switch (value) {
                case 'In Progress': {
                    searchParam.set('filter', 'inProgress')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        getSortObject(sort as SortTypes | undefined),
                        {
                            _eq: 'inProgress',
                        }
                    )
                    break
                }
                case 'Completed': {
                    searchParam.set('filter', 'complete')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        getSortObject(sort as SortTypes | undefined),
                        {
                            _eq: 'complete',
                        }
                    )
                    break
                }
                case 'In Review': {
                    searchParam.set('filter', 'inReview')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        getSortObject(sort as SortTypes | undefined),
                        {
                            _eq: 'inReview',
                        }
                    )
                    break
                }
                case 'Delayed': {
                    searchParam.set('filter', 'delayed')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        getSortObject(sort as SortTypes | undefined),
                        {
                            _eq: 'delayed',
                        }
                    )
                    break
                }
                case 'Request Revision': {
                    searchParam.set('filter', 'revisionRequested')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        getSortObject(sort as SortTypes | undefined),
                        {
                            _eq: 'revisionRequested',
                        }
                    )
                    break
                }
                case 'Date Created': {
                    searchParam.set('sort', 'created_at')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        {
                            created_at: 'asc',
                        },
                        getFilterObject(filter)
                    )
                    break
                }
                case 'Due Date': {
                    searchParam.set('sort', 'due_date')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        {
                            due_date: 'desc',
                        },
                        getFilterObject(filter)
                    )
                    break
                }
                case 'Name': {
                    searchParam.set('sort', 'name')
                    setSearchParam(searchParam, { replace: true })
                    fetchTodosByUserId(
                        String(analyticsOfUser?.user?.id),
                        {
                            name: 'asc',
                        },
                        getFilterObject(filter)
                    )
                    break
                }
            }
        }
    }

    const editRolePermission =
        role === 'admin' ? 'full' : role === 'manager' ? 'partial' : 'none'

    const userCurrentRole =
        analyticsOfUser?.user?.organisation_members[0]?.role || ''

    const allowedRoles = () => {
        if (editRolePermission === 'full') {
            return ['Admin', 'Manager', 'Designer']
        } else if (
            editRolePermission === 'partial' &&
            userCurrentRole !== 'admin'
        ) {
            return ['Manager', 'Designer']
        } else {
            return []
        }
    }

    return (
        <Box
            display="grid"
            height="calc(100vh - 100px)"
            gridTemplateRows="auto 1fr"
            gridGap="24px"
            bg="white"
            overflow="auto"
        >
            <Box mt="16px" px="24px">
                {!tabView && (
                    <Button
                        color="links"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                        textAlign="left"
                        onClick={() => navigate(-1)}
                    >
                        &lt; Back
                    </Button>
                )}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" gridColumnGap="12px" py="16px">
                        <ProfileImg
                            size="44px"
                            name={analyticsOfUser?.user?.name || ''}
                            src={getImageUrl(
                                analyticsOfUser?.user?.other_data?.profile_pic
                            )}
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="8px"
                        >
                            <Text fontSize="24px" my="0px">
                                {getSentenceCase(
                                    analyticsOfUser?.user?.name ||
                                        analyticsOfUser?.user?.email ||
                                        '',
                                    'NAME'
                                )}
                            </Text>
                            <Text
                                color="textSecondary"
                                fontSize="16px"
                                my="0px"
                            >
                                {analyticsOfUser?.user?.email || ''}
                            </Text>
                            <CustomDropdown
                                items={allowedRoles()}
                                label=""
                                disabled={
                                    editRolePermission === 'none' ||
                                    (role === 'manager' &&
                                        userCurrentRole === 'admin')
                                }
                                selectedText={getSentenceCase(
                                    userCurrentRole,
                                    'NAME'
                                )}
                                onItemClicked={(role) => {
                                    updateRole(role)
                                }}
                                highlightedTextFontSize={'16px'}
                                labelMaxWidth="max-content"
                                noStyle={true}
                                showArrow={true}
                            />
                        </Box>
                    </Box>
                    {editRolePermission !== 'none' && (
                        <Box
                            position="relative"
                            display="flex"
                            justifyContent="end"
                            width="130px"
                            ref={dropdownRef}
                        >
                            <Button
                                height="max-content"
                                bg="transparent"
                                border="none"
                                onClick={() =>
                                    setCollaboratorDropdown(
                                        !collaboratorDropdown
                                    )
                                }
                            >
                                <Dots active={collaboratorDropdown} />
                            </Button>
                            {collaboratorDropdown && (
                                <CollaboratorDropdown
                                    status={
                                        analyticsOfUser.user
                                            ?.organisation_members[0].deleted_at
                                            ? 'archived'
                                            : 'active'
                                    }
                                    collaboratorDropdown={collaboratorDropdown}
                                    onDropDownClick={(val) =>
                                        dropdownClickHandler(val)
                                    }
                                />
                            )}
                        </Box>
                    )}
                </Box>
                {tabView && <DivisionLine />}
                <Box
                    mt="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gridColumnGap="12px"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        gridColumnGap="12px"
                    >
                        <TodoIco color="#022143" />
                        <Text my="0px">To-Do's Completed</Text>
                    </Box>
                    {tabView && (
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            alignItems="center"
                            justifyContent="center"
                            color="links"
                            onClick={() => navigateToDetails()}
                        >
                            <Button
                                color="links"
                                bg="transparent"
                                border="none"
                                fontSize="12px"
                                my="0px"
                            >
                                Open Detailed View
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    mt="16px"
                    // display="flex"
                    // width="100%"
                    // flexDirection="column"
                    display="flex"
                    width={tabView ? '100%' : '70%'}
                    alignContent="center"
                    gridColumnGap="18px"
                    flexDirection={props.tabView ? 'column' : 'row'}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        gridColumnGap="24px"
                        mb="12px"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap={tabView ? '8px' : '24px'}
                            width="max-content"
                        >
                            <Text my="0px" fontWeight={500} fontSize="24px">
                                {totalCompleted || 0}
                            </Text>
                            <Text my="0px" fontSize="16px">
                                {Math.floor(completed) || 0}% on time
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems={isNaN(overAllProgress) ? 'center' : 'end'}
                        width="100%"
                    >
                        <ProgressBar
                            overAllProgress={
                                isNaN(overAllProgress) ? 0 : overAllProgress
                            }
                            progress={[
                                {
                                    value: completed,
                                    type: 'COMPLETE',
                                    label: {
                                        number: Number(
                                            analyticsOfUser?.totalCompletedTodosOnTime ||
                                                0
                                        ),
                                        label: 'Completed On time',
                                    },
                                },
                                {
                                    value: delayed,
                                    type: 'DELAY',
                                    label: {
                                        number: Number(
                                            analyticsOfUser?.totalDelayedTodos ||
                                                0
                                        ),
                                        label: 'Delayed',
                                    },
                                },
                            ]}
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                style={{
                    borderRadius: '20px 20px 0px 0px',
                }}
                p="24px"
                height="100%"
                bg="#F0F2F3"
            >
                {!tabView && (
                    <Box
                        mb="24px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gridColumnGap="16px"
                    >
                        <Text my="0px">
                            {analyticsOfUser.totalTodos} To-Do's
                        </Text>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gridColumnGap="24px"
                        >
                            {currentTab === 'todos' && (
                                <>
                                    {' '}
                                    <CustomDropdown
                                        height="32px"
                                        bg="white"
                                        labelFontSize="14px"
                                        highlightedTextFontSize="14px"
                                        border="solid"
                                        borderColor="secondaryLighterBlue"
                                        selectedText={'In Progress'}
                                        onItemClicked={(item) =>
                                            handleDropdownClick('todo', item)
                                        }
                                        label="Filter By"
                                        items={[
                                            'In Progress',
                                            'Completed',
                                            'In Review',
                                            'Delayed',
                                            'Request Revision',
                                        ]}
                                    />
                                    <CustomDropdown
                                        height="32px"
                                        bg="white"
                                        labelFontSize="14px"
                                        highlightedTextFontSize="14px"
                                        border="solid"
                                        borderColor="secondaryLighterBlue"
                                        selectedText={'Date Created'}
                                        onItemClicked={(item) =>
                                            handleDropdownClick('todo', item)
                                        }
                                        label="Sort By"
                                        items={[
                                            'Date Created',
                                            'Due Date',
                                            'Name',
                                        ]}
                                    />
                                </>
                            )}
                            {false && (
                                <CustomDropdown
                                    height="32px"
                                    bg="white"
                                    labelFontSize="14px"
                                    highlightedTextFontSize="14px"
                                    border="solid"
                                    borderColor="secondaryLighterBlue"
                                    selectedText={'In Progress'}
                                    onItemClicked={(item) =>
                                        handleDropdownClick('todo', item)
                                    }
                                    label="Filter By"
                                    items={[
                                        'Latest to Oldest',
                                        'Oldest to Latest',
                                        'No. of Collections: Least to Most',
                                        'No. of Collectrions: Most to Least',
                                        'Completion Rate:',
                                    ]}
                                />
                            )}
                        </Box>
                    </Box>
                )}

                <Slider
                    element={
                        <IndividualCollaboratorTabSlider
                            todosCount={todosByUserId.length || 0}
                            teamsCount={teamsByUserId?.length || 0}
                            onTabChange={(tab) => setCurrentTab(tab.tab)}
                            currentTab={currentTab}
                        />
                    }
                />
                <ContributorTabDetails
                    isTabView={props.tabView}
                    refetchTodos={() =>
                        fetchTodosByUserId(searchParam.get('id') || '0')
                    }
                    currentTab={currentTab}
                    todos={todosByUserId || []}
                    teams={teamsByUserId || []}
                />
            </Box>
        </Box>
    )
}
