import { ONBOARD_USER } from '../../services/api/endpoints/index'

import type { SetStateAction } from 'react'
import type { User } from 'firebase/auth'
import {
    getAuth,
    createUserWithEmailAndPassword,
    updateProfile,
    deleteUser,
} from 'firebase/auth'
import axios from 'axios'

type ErrorStates = {
    email: boolean
    password: boolean
    name: boolean
}

type UserInput = {
    email: string
    password: string
    name: string
}

export async function signupHandler(
    setErrorStates: React.Dispatch<SetStateAction<ErrorStates>>,
    setIsSignedUp: React.Dispatch<SetStateAction<boolean>>,
    setError: React.Dispatch<
        SetStateAction<{ isError: boolean; errorMessage: string }>
    >,
    setIsLoading: React.Dispatch<SetStateAction<boolean>>,
    userInputs: UserInput,
    isValidPassword: boolean
) {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    if (!isValidPassword) {
        setErrorStates((errorStates) => {
            return { ...errorStates, password: true }
        })
    }
    if (!userInputs.email.match(emailRegex)) {
        setErrorStates((errorStates) => {
            return { ...errorStates, email: true }
        })
    }
    if (userInputs.name.length <= 0) {
        setErrorStates((errorStates) => {
            return { ...errorStates, name: true }
        })
    }
    if (
        isValidPassword &&
        userInputs.email.match(emailRegex) &&
        userInputs.name.length > 0
    ) {
        setErrorStates({
            email: false,
            password: false,
            name: false,
        })

        const auth = getAuth()
        let user: User | undefined
        try {
            setIsLoading(true)
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                userInputs.email,
                userInputs.password
            )
            if (auth.currentUser) {
                await updateProfile(auth.currentUser, {
                    displayName: userInputs.name,
                })
            }
            const FIREBASE_JWT = await userCredential.user.getIdToken()
            user = userCredential.user
            await axios.post(ONBOARD_USER, {
                token: FIREBASE_JWT,
            })
            setError({ isError: false, errorMessage: '' })
            setIsSignedUp(true)
            setIsLoading(false)
        } catch (e: any) {
            setError({ isError: true, errorMessage: 'User invitation expired' })
            if (user) deleteUser(user)
            setIsSignedUp(false)
            setIsLoading(false)
        }
    }
}

export function onChangeHandler(
    setUserInputs: React.Dispatch<SetStateAction<UserInput>>,
    setErrorStates: React.Dispatch<SetStateAction<ErrorStates>>,
    key: string,
    text: string
) {
    setUserInputs((userInputs) => {
        return { ...userInputs, [key]: text }
    })
    setErrorStates((errorStates) => {
        return { ...errorStates, [key]: false }
    })
}

export function showRuleModal(
    setIsRules: React.Dispatch<SetStateAction<boolean>>,
    setMouseEnterTimer: React.Dispatch<
        SetStateAction<ReturnType<typeof setTimeout> | undefined>
    >
) {
    const timeOutRef = setTimeout(() => {
        setIsRules(true)
    }, 1000)
    setMouseEnterTimer(timeOutRef)
}
