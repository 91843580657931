import { FILE_TYPES } from './enums'

export function checkFileType(name: string) {
    const lastDotIndex = name.lastIndexOf('.')
    const fileType = lastDotIndex !== -1 ? name.slice(lastDotIndex + 1) : ''
    if (FILE_TYPES.includes(fileType)) {
        return true
    }
    return false
}
