export function MinimizeIco({
    color,
    onClick,
    size,
}: {
    color?: string
    onClick?: (e: any) => void
    size?: number
}) {
    return (
        <svg
            width={size ? size : 16}
            height={size ? size : 16}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick ? onClick : undefined}
            cursor="pointer"
        >
            <path
                d="M2.5415 18.3327L1.6665 17.4577L7.87484 11.2494H3.74984V9.99935H9.99984V16.2494H8.74984V12.1244L2.5415 18.3327ZM9.99984 9.99935V3.74935H11.2498V7.87435L17.4582 1.66602L18.3332 2.54102L12.1248 8.74935H16.2498V9.99935H9.99984Z"
                fill={color ? color : '#778CA2'}
            />
        </svg>
    )
}
