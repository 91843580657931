import axios from 'axios'
import { getLocalStorage } from 'src/helpers'
import { GET_MANAGER_TEAMS } from 'src/services'

export type TDashboardTeams = {
    id: string
    name: string
    created_at: Date
    collections: {
        complete: number
        delayed: number
        total: number
        totalCompleted: number
    }
    team_members: {
        id: string
        user: {
            id: string
            name: string
            other_data: {
                thumbnail: string
                profile_pic: string
            }
            email: string
            created_at: Date
            organisation_members: [
                {
                    role: string
                }
            ]
        }
    }[]
    collectionCompletionRate: number
}

export function useTeams() {
    const fetchTeams = async () => {
        const queryParams: any = {
            limit: 100,
            offset: 1,
        }

        const { data } = await axios.get(GET_MANAGER_TEAMS, {
            headers: {
                'x-auth-token': getLocalStorage('authToken'),
            },
            params: queryParams,
        })

        if (data.success) {
            if (data.data && data.data?.length) {
                return {
                    data: data.data as TDashboardTeams[],
                    count: data.count,
                }
            } else {
                return { data: [], count: 0 }
            }
        } else {
            return { data: [], count: 0 }
        }
    }
    return { fetchTeams }
}
