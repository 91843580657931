import React, { useState, useEffect } from 'react'
import { Box, Text } from './components/utility'

const NoInternetConnection = (props: any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true)

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state
    window.addEventListener('online', () => {
        setOnline(true)
    })

    window.addEventListener('offline', () => {
        setOnline(false)
    })

    // if user is online, return the child component else return a custom component
    return (
        <>
            {!isOnline && (
                <Box
                    position="fixed"
                    zIndex={15}
                    left="0px"
                    right="0px"
                    top="0px"
                    bottom="0px"
                    bg="rgba(0,0,0,0.7)"
                >
                    <Text color="white" textAlign="center">
                        No internet connection.{' '}
                    </Text>
                </Box>
            )}
            {props.children}
        </>
    )
}

export default NoInternetConnection
