import * as React from 'react'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { Box, Text } from 'src/components/utility'

interface IProgressBarProps {
    progress: {
        value: number
        type: 'DELAY' | 'COMPLETE' | 'IN_PROGRESS'
        label: {
            number: number
            label: string
        }
    }[]
    overAllProgress?: number
}
const progressColors = {
    DELAY: 'delay',
    COMPLETE: 'approved',
    IN_PROGRESS: 'blue',
}

export default function ProgressBar(props: IProgressBarProps) {
    const { progress, overAllProgress = 0 } = props
    return (
        <Box width="100%" display="flex" gridColumnGap="4px">
            {progress.map((item, index) => {
                return item.value > 0 ? (
                    <HtmlTooltip
                        placement="bottom-start"
                        disableInteractive={true}
                        arrow
                        title={
                            <React.Fragment>
                                <Box
                                    boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
                                    display="flex"
                                    flexDirection="column"
                                    p="0px"
                                    zIndex={10}
                                    overflowX="hidden"
                                    bg="white"
                                    borderRadius="4px"
                                    className="img-details"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        p="12px"
                                    >
                                        <Text
                                            my="0px"
                                            fontSize="12px"
                                            color="textTags"
                                        >
                                            <strong>{item.label.number}</strong>{' '}
                                            - {item.label.label}
                                        </Text>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        }
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            gridRowGap="8px"
                            width={item.value + '%'}
                        >
                            {' '}
                            <Box
                                key={index}
                                bg={progressColors[item.type]}
                                height="10px"
                                width="100%"
                            ></Box>
                            {item.value > 30 && (
                                <Text my="0px" fontSize="12px" color="textTags">
                                    <strong>{item.label.number}</strong> -{' '}
                                    {item.label.label}
                                </Text>
                            )}
                        </Box>
                    </HtmlTooltip>
                ) : (
                    <></>
                )
            })}

            {overAllProgress <= 0 && <></>}
        </Box>
    )
}
