import { type Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export default function CommentMenuDropdown({
    active,
    setIsDropdownActive,
    onDelete,
    commentId,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    onDelete: () => void
    commentId: number
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        [`comment-menu-button-${commentId}`]
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="28px"
            left="none"
            right="0px"
            width="182px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper value="Collections" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="danger"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        width="100%"
                        textAlign="left"
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete()
                            setIsDropdownActive(false)
                        }}
                    >
                        Delete Comment
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}
