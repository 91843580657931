import { NetworkStatus } from '@apollo/client'
import * as React from 'react'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'
import { CardLoader } from 'src/components/collections/card-loader'

import { Box } from 'src/components/utility'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'
import type { TGqlTodos } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import { EmptyTodoState } from 'src/screens/dashboard/sub-screens/todos/empty-state-todo'

export interface ITodoEntityListProps {
    todos: TGqlTodos[]
    onPageChange: () => void
    isLoading: boolean
    networkStatus: NetworkStatus
    totalTodos: number | string
}

const noStateTexts = {
    newTodo: 'No to-do’s yet?',
    completedTodo: 'No completed to-dos yet',
    requestRevision: 'No request revision to-dos yet',
    toBeReviewedTodo: 'No to-dos for review yet',
    toBeReviewedProduct: 'No products for review yet',
    inProgressAll: 'No to-dos in progress yet',
    inProgressReview: 'No in progress to-dos are in review yet',
    inProgressDelay: 'No in progress to-dos are in delay yet',
}

export default function TodoEntityList(props: ITodoEntityListProps) {
    const { todos, onPageChange, isLoading, networkStatus, totalTodos } = props
    const containerRef = React.useRef<HTMLDivElement>(null)
    const [isAtTop, setIsAtTop] = React.useState(true)

    const { isIntersecting, lastElementRef } = useIntersectionObserver({
        isLoading: isLoading,
        threshold: 0.5,
    })
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore

    React.useEffect(() => {
        if (isIntersecting) {
            onPageChange()
        }
    }, [isIntersecting])

    React.useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setIsAtTop(containerRef.current.scrollTop === 0)
            }
        }

        const container = containerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    return (
        <Box
            ref={containerRef}
            overflowY="auto"
            height={'500px'}
            style={{
                // If all todos are loaded, allow overscroll since there's no more data to fetch from the server
                overscrollBehavior:
                    isAtTop || Number(totalTodos) === todos.length
                        ? 'auto'
                        : 'none',
            }}
        >
            {todos.map((todo, index) => {
                const props =
                    index === todos.length - 1 ? { ref: lastElementRef } : {}
                return (
                    <Box id={`todo_card_${todo.id}`} {...props}>
                        <TodoListCard
                            refetchQueries={['GetDashboardTodosAggregate']}
                            // isTabView
                            // dashboardView
                            // updateDate={() => {}}
                            // updateStatus={() => {}}
                            // entity={todo}
                            isFullCard={false}
                            navigateTo={`/todo/${todo.id}`}
                            cardId=""
                            todo={{
                                assignee: todo.assignee && {
                                    id: Number(todo.assignee.id),
                                    name: todo.assignee.name,
                                    other_data: todo.assignee.other_data,
                                    email: todo.assignee.email,
                                    organisation_members: [
                                        {
                                            role: todo.assignee
                                                .organisation_members[0]
                                                .role as
                                                | 'admin'
                                                | 'designer'
                                                | 'manager',
                                        },
                                    ],
                                },
                                coverPic: todo.other_data.cover_pic && {
                                    assignee_id: todo?.assignee?.id || '',
                                    fromLibrary:
                                        todo.other_data.cover_pic
                                            ?.fromLibrary || false,
                                    gid: todo.other_data.cover_pic?.gid || '',
                                    name: todo.other_data.cover_pic?.name || '',
                                    path: todo.other_data.cover_pic?.path || '',
                                    thumbnail:
                                        todo.other_data.cover_pic?.thumbnail ||
                                        '',
                                },
                                dueDate: todo.due_date
                                    ? new Date(todo.due_date).toISOString()
                                    : null,
                                id: Number(todo.id),
                                isDelayed: todo?.is_delayed || false,
                                name: todo.name,
                                product: {
                                    coverPic: todo.product_variant?.other_data
                                        ? {
                                              assignee_id:
                                                  todo.assignee?.id || '',
                                              fromLibrary:
                                                  todo.product_variant
                                                      ?.other_data?.cover_pic
                                                      ?.fromLibrary || false,
                                              gid:
                                                  todo.product_variant
                                                      ?.other_data?.cover_pic
                                                      ?.gid || '',
                                              name:
                                                  todo.product_variant
                                                      ?.other_data?.cover_pic
                                                      ?.name || '',
                                              path:
                                                  todo.product_variant
                                                      ?.other_data?.cover_pic
                                                      ?.path || '',
                                              thumbnail:
                                                  todo.product_variant
                                                      ?.other_data?.cover_pic
                                                      ?.thumbnail || '',
                                          }
                                        : null,
                                    id: Number(todo.product_variant?.id),
                                    name: todo.product_variant?.name || '',
                                },
                                status: todo.status,
                                collection: {
                                    id: Number(
                                        todo.product_variant?.collection?.id
                                    ),
                                    manager_id: Number(
                                        todo.product_variant?.collection
                                            ?.manager_id
                                    ),
                                    name:
                                        todo.product_variant?.collection
                                            ?.name || '',
                                },
                            }}
                        />
                    </Box>
                )
            })}
            {isFetchingMore && <CardLoader skeletonCount={3} />}
            {todos.length <= 0 && (
                <Box>
                    <EmptyTodoState
                        boxHeight={'500px'}
                        title={noStateTexts.newTodo}
                        description={
                            'Try to assign more tasks to your teammates'
                        }
                        enableCreateButton={true}
                    />
                </Box>
            )}
        </Box>
    )
}
