import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { Box } from '../utility'

export const SideBarGroupedNavigationLinks = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const { removeLastOpenedTab } = useCardExpandedState()
    return (
        <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            onClick={() => removeLastOpenedTab()}
        >
            {children}
        </Box>
    )
}
