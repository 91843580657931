const theme = {
    colors: {
        primary: '#022143',
        secondary: '#EAFC40',
        secondaryLighterBlue: '#A7B9D1',
        LighterBlueBg: '#FAFCFF',
        borderLine: '#203C5A',
        backgroundColor: '#F8FAFB',
        textSecondary: '#817E7E',
        textSecondaryDarkBlue: '#778CA2',
        textTags: '#606060',
        links: '#027AC3',
        delay: '#FF2C18',
        approved: '#39A407',
        warning: '#EF8D1B',
        danger: '#FF2C18',
        gray100: '#DDDDDD',
        gray250: '#E8ECEF', // Lines on white
        gray350: '#C2CFE0',
        white: '#fff',
        panelBackground: '#F0F2F3',

        textStatusActive: '#0C40D0',
        textInReview: '#088981',
        textRequestRevision: '#EF8D1B',
        textCompleted: '#39A407',
        textToBeReviewed: '#9B8200',

        // Opacity
        grayOpacity10: '#1414141A',
        blueOpacity10: '#0C40D01A',
        redOpacity10: '#FF2C181A',
        tealOpacity10: '#06B1A71A',
        orangeOpacity10: '#EF8D1B1A',
        greenOpacity10: '#39A4071A',
        yellowOpacity10: '#AB90011A',

        // New Colors
        blue: '#A9B8ED',
        peach: '#F5BCAA',

        // Box Shadow
        dropDownShadow: '0px 2px 12px rgba(153, 155, 168, 0.25)',
    },
    breakpoints: {
        xs: '600px',
        sm: '768px',
        md: '960px',
        lg: '1280px',
        xl: '1461px',
    },
}

export default theme
