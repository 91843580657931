import * as React from 'react'

export const AddFileIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10.308a1 1 0 0 0 1-1V8.061l-.185-.184-3.693-3.692L14.246 4H7Zm.23 1.23h6.155v2.693a1 1 0 0 0 1 1h2.692v9.846H7.23V5.231Zm7.385.863 1.6 1.6h-1.6v-1.6Zm-2.461 4.676a.615.615 0 0 0-.615.616v1.23h-1.231a.615.615 0 1 0 0 1.231h1.23v1.23a.615.615 0 1 0 1.231 0v-1.23H14a.615.615 0 0 0 0-1.23h-1.23v-1.231a.615.615 0 0 0-.616-.616Z"
            fill="#778CA2"
        />
    </svg>
)
