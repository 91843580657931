import {
    CameraControls,
    Center,
    EnvironmentCube,
    GizmoHelper,
    GizmoViewport,
    OrbitControls,
    PivotControls,
    Resize,
} from '@react-three/drei'
import {
    useLoader,
    useThree,
    type MeshProps,
    type ThreeEvent,
} from '@react-three/fiber'

import * as React from 'react'
import { useConfiguratorContext } from './configurator.context'
import { EffectComposer, Outline } from '@react-three/postprocessing'
import { BlendFunction, Resizer, KernelSize } from 'postprocessing'
import { useAnnotationCommentsStore } from 'src/store/hooks/useAnnotationCommentsStore'
import type {
    AnnotatedComment,
    CoordinateWithCameraPosition,
} from '../annotation.types'
import AnnotationNew from '../annotation-new'
import AnnotationCommentInput from 'src/components/annotation-comments/annotation-comment-input'
import { GetProductTeam } from 'src/services/graphql/query/get-product-team'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import * as THREE from 'three'
import { renderImage } from './helper/capture-image'
import UnableToPreviewSection from 'src/components-v2/file-preview-modal/preview-section/unable-to-preview-section'
import Ground from './ground'
import { convertImageURIToObject } from './helper/conver-image-url-to-object'
import { cloneMaterial } from './helper/clone-material'
import { debounce, uuid } from 'src/helpers'

const loaders = {
    glb: GLTFLoader,
    gltf: GLTFLoader,
    obj: OBJLoader,
    fbx: FBXLoader,
    mtl: MTLLoader,
}

export interface IConfiguratorSceneProps {
    fileUrl: string
    fileName: string
    extension: keyof typeof loaders
    comments: Array<AnnotatedComment>
    refetch: () => void
    loading?: boolean
    setLoading?: (bool: boolean) => void
    setProgress?: (percentage: number) => void
    setFileExists?: (exists: boolean) => void
}

function Configurator(props: IConfiguratorSceneProps, ref: any) {
    const {
        fileUrl,
        extension,
        comments,
        loading,
        setProgress,
        setFileExists,
    } = props
    const orbitRef = React.useRef<any>()
    const { gl, scene, camera } = useThree()

    const [searchParams] = useSearchParams()
    const activeMode =
        (searchParams.get('mode') as 'pan' | 'annotation') || 'pan'
    const [timeOutRef, setTimeOutRef] =
        React.useState<ReturnType<typeof setTimeout>>()

    const shadowDebounce = debounce(
        enableOrDisableShadow,
        500,
        setTimeOutRef,
        timeOutRef
    )
    const {
        highlights,
        setHighlights,
        setSelectedColor,
        setConfig,
        setOperation,
        operation,
        imageRenderDimensions,
        setGraphic,
        backgroundAndShadows,
    } = useConfiguratorContext()

    const {
        activeAnnotationPoint,
        setActiveAnnotationPoint,
        setIsTemporaryAnnotation,
        setActiveCameraPosition,
    } = useAnnotationCommentsStore()

    const { data: teamData } = useQuery(GetProductTeam, {
        skip: !searchParams.get('productId'),
        variables: {
            product_id: searchParams.get('productId'),
        },
    })

    // Set Scene Configuration
    gl.shadowMap.enabled = true
    gl.shadowMap.type = THREE.PCFSoftShadowMap

    const model = useLoader(
        loaders[extension],
        fileUrl,
        (data) => {
            data.load(
                fileUrl,
                (data) => {},
                () => {},
                (e) => {
                    setFileExists && setFileExists(false)
                }
            )
        },
        (progress) => {
            setProgress && setProgress((progress.loaded / progress.total) * 100)
        }
    )

    if (fileUrl.length < 0) return <></>

    React.useEffect(() => {
        setOperation(activeMode)
    }, [searchParams.get('mode')])

    // Coordinates where the annotation comments should be placed
    const annotationPoints: Array<CoordinateWithCameraPosition> | undefined =
        React.useMemo(() => {
            if (comments) {
                const uniqueCoordinates = new Map()
                comments.forEach((comment) => {
                    if (comment.coordinates) {
                        const key = comment.coordinates.join(',')
                        if (!uniqueCoordinates.has(key)) {
                            uniqueCoordinates.set(key, {
                                coordinates: comment.coordinates,
                                cameraPosition:
                                    comment.metadata?.camera_position || null,
                            })
                        }
                    }
                })
                return Array.from(uniqueCoordinates.values())
            }
        }, [comments])

    async function handleSelection(e: ThreeEvent<PointerEvent>) {
        e.stopPropagation()
        const intersections = e.intersections

        if (intersections.length > 0) {
            const mesh = intersections[0].object as any
            if (mesh.name === 'ground') return

            mesh.material.wireframe = false // enable this if needed

            mesh.material.displacementScale = 0 // should be adjusted by artists

            const foundMesh = highlights.find(
                (highlight) => highlight.mesh.uuid === mesh.uuid
            )
            let material = mesh.material as THREE.MeshPhysicalMaterial
            if (material.type !== 'MeshCustomMaterial') {
                mesh.material = cloneMaterial({ oldMaterial: material })
            }
            if (
                highlights.filter((highlight) => highlight.isActive).length ===
                1
            ) {
                if (mesh.material.color) {
                    setSelectedColor({
                        color: `#${mesh.material.color.getHexString()}`,
                        id: uuid(),
                    })
                }

                if (mesh && mesh.material.map) {
                    mesh.map = JSON.parse(JSON.stringify({ ...mesh.map }))
                }
                if (mesh && mesh.material.mapLayer1) {
                    const activeArtworkObject = await convertImageURIToObject(
                        mesh.material.mapLayer1.source?.data?.currentSrc,
                        'mapLayer2.png'
                    )
                    const config = {
                        offset: {
                            u: mesh.material.mapLayer1?.offset?.x || 0.05,
                            v: mesh.material.mapLayer1?.offset?.y || 0.05,
                        },
                        repeat: {
                            u: mesh.material.mapLayer1?.repeat?.x || 0.05,
                            v: mesh.material.mapLayer1?.repeat?.y || 0.05,
                        },
                        rotation: mesh.material.mapLayer1?.rotation || 0,
                        transparency:
                            mesh.material.mapLayer1?.tranparencyLayer1 || 0,
                        substanceNess: 1,
                    }
                    setGraphic({
                        ...activeArtworkObject,
                        config,
                    })
                } else {
                    setGraphic(undefined)
                }
            }

            setConfig({
                offset: {
                    u: mesh.material.map?.offset?.x || 0,
                    v: mesh.material.map?.offset?.y || 0,
                },
                scale: {
                    u: mesh.material.map?.repeat?.x || 0,
                    v: mesh.material.map?.repeat?.y || 0,
                },
                rotation: mesh.material.rotation || 0,
                metalnessIntensity: 0,
                roughnessIntensity: 0,
            })

            if (!foundMesh) {
                setHighlights([
                    {
                        mesh: mesh as MeshProps,
                        isActive: true,
                    },
                ])
            }
            if (foundMesh) {
                return setHighlights(
                    highlights.map((highlight) => {
                        return foundMesh.mesh.uuid === highlight.mesh.uuid
                            ? {
                                  ...highlight,
                                  isActive: !highlight.isActive,
                              }
                            : highlight
                    })
                )
            }
        }
    }

    function enableOrDisableShadow() {
        if ('scene' in model) {
            model.scene.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.castShadow = backgroundAndShadows.isShadowEnabled
                    child.receiveShadow = backgroundAndShadows.isShadowEnabled
                }
            })
        } else {
            //@ts-ignore
            model?.children?.forEach((child) => {
                if (child.isMesh) {
                    child.castShadow = backgroundAndShadows.isShadowEnabled
                    child.receiveShadow = backgroundAndShadows.isShadowEnabled
                }
            })
        }
    }

    React.useEffect(() => {
        const commentId = searchParams.get('commentId')
        if (commentId) {
            const comment = comments.find(
                (comment) => Number(comment.id) === Number(commentId)
            )
            if (comment) {
                setActiveAnnotationPoint(comment.coordinates)
            }
        }
    }, [searchParams.get('commentId')])

    React.useEffect(() => {
        if (!model) return
        shadowDebounce()
    }, [model, backgroundAndShadows.isShadowEnabled])

    React.useEffect(() => {
        scene.background = new THREE.Color(
            backgroundAndShadows.activePresetColor ||
                backgroundAndShadows.backgroundColor
        )
    }, [
        backgroundAndShadows.backgroundColor,
        backgroundAndShadows.activePresetColor,
    ])

    React.useImperativeHandle(
        ref,
        () => {
            return () =>
                renderImage(camera, scene, {
                    imageHeight: imageRenderDimensions.height,
                    imageWidth: imageRenderDimensions.width,
                })
        },
        [scene, imageRenderDimensions]
    )

    const spotlight = React.useRef<THREE.SpotLight | null>(null)
    if (spotlight && spotlight.current) {
        const spotLightHelper = new THREE.SpotLightHelper(spotlight.current)
        // scene.add(spotLightHelper)
    }
    if (loading && !model) return <></>
    return (
        <>
            <EnvironmentCube
                background={false}
                files={['enviornments/PhotorealStudio_experimental_2k.hdr']}
                //@ts-ignore
                backgroundBlurriness={100}
                blur={100}
                path="/"
            />
            <mesh
                onPointerDown={(e) => {
                    if (operation === 'annotation') {
                        setIsTemporaryAnnotation(true)
                        setActiveAnnotationPoint(
                            e.intersections[0].point.toArray()
                        )
                    } else {
                        handleSelection(e)
                    }
                }}
            >
                {
                    <OrbitControls
                        ref={orbitRef as any}
                        makeDefault
                        enableDamping={false}
                        enableRotate={
                            activeMode !== 'annotation' ||
                            (activeMode === 'annotation' &&
                                !activeAnnotationPoint)
                        }
                    />
                }
                {false && (
                    <CameraControls
                        infinityDolly
                        dampingFactor={1}
                        makeDefault
                        // colliderMeshes={'scene' in model ? model.scene : model}
                        smoothTime={0.5}
                        dollySpeed={0.5}
                        verticalDragToForward={true}
                    />
                )}
                {false && (
                    <GizmoHelper
                        renderPriority={2}
                        alignment="bottom-right"
                        margin={[80, 80]}
                    >
                        <GizmoViewport />
                    </GizmoHelper>
                )}

                <spotLight
                    position={[2.227, 3.135, 1.966]}
                    intensity={60}
                    distance={16}
                    angle={0.2}
                    penumbra={0.06}
                    shadow-radius={2.0}
                    castShadow={true}
                    frustumCulled={true}
                    ref={spotlight}
                />
                <hemisphereLight
                    groundColor={0xffffff}
                    position={[0, 10, 0]}
                    intensity={0.54}
                    frustumCulled={true}
                />
                <PivotControls
                    disableAxes={true}
                    disableSliders={true}
                    disableScaling={true}
                    disableRotations={true}
                    depthTest={true}
                    anchor={[0, 0, 0]}
                    scale={1}
                >
                    <Center>
                        <Resize
                            scale={new THREE.Vector3(1, 1, 1)}
                            height
                            width
                            precise
                        >
                            <React.Suspense
                                fallback={
                                    <UnableToPreviewSection
                                        onOpenConfigurator={() => {}}
                                        url=""
                                        text="Sorry we are not able to preview this file at the moment."
                                        extension={extension}
                                        showControls={false}
                                    />
                                }
                            >
                                <primitive
                                    object={
                                        'scene' in model ? model.scene : model
                                    }
                                />
                                {false && <Ground groundPosition={[0, 0, 0]} />}
                            </React.Suspense>
                        </Resize>
                    </Center>
                </PivotControls>
                <group>
                    {operation === 'annotation' &&
                        !activeAnnotationPoint &&
                        annotationPoints
                            ?.filter(
                                (point) =>
                                    point.coordinates !== activeAnnotationPoint
                            )
                            .map((point, index) => {
                                return (
                                    <AnnotationNew
                                        coordinates={point.coordinates}
                                        key={index}
                                        onClick={() => {
                                            setIsTemporaryAnnotation(false)
                                            setActiveAnnotationPoint(
                                                point.coordinates
                                            )
                                            setActiveCameraPosition(
                                                point.cameraPosition
                                            )
                                        }}
                                    />
                                )
                            })}
                </group>
                {operation === 'annotation' && activeAnnotationPoint && (
                    <AnnotationCommentInput
                        //@ts-ignore
                        activeAnnotationPoint={activeAnnotationPoint}
                        comments={comments.filter(
                            (comment: AnnotatedComment) =>
                                JSON.stringify(comment.coordinates) ===
                                JSON.stringify(activeAnnotationPoint)
                        )}
                        refetch={
                            props.refetch ? () => props.refetch() : () => {}
                        }
                        team={teamData?.product_variants_by_pk?.team}
                        onClose={() => {
                            setActiveAnnotationPoint(null)
                            setActiveCameraPosition(null)
                        }}
                    />
                )}
            </mesh>
            <EffectComposer enabled={true} autoClear={false}>
                {operation === 'pan' ? (
                    <Outline
                        selection={highlights
                            .filter((highlight) => highlight.isActive)
                            .map((highlight) => highlight.mesh)}
                        selectionLayer={10}
                        blendFunction={BlendFunction.ALPHA}
                        edgeStrength={10}
                        pulseSpeed={0.0}
                        visibleEdgeColor={0xeafc40}
                        hiddenEdgeColor={0xeafc40}
                        //@ts-ignore
                        width={Resizer.AUTO_SIZE}
                        //@ts-ignore
                        height={Resizer.AUTO_SIZE}
                        kernelSize={KernelSize.HUGE}
                        blur={false}
                        xRay={true}
                    />
                ) : (
                    <></>
                )}
            </EffectComposer>
        </>
    )
}

const ConfiguratorScene = React.forwardRef(Configurator)
export default ConfiguratorScene
