import { useApolloClient, useLazyQuery } from '@apollo/client'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import {
    BasicSearch,
    DivisionLine,
    TransparentRightArrow,
} from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import Pagination from 'src/components/pagination/pagination'
import { Slider } from 'src/components/slider/slider'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { TabPanel } from 'src/components/tab-panel/tab-panel'
import { Box, Button, Text } from 'src/components/utility'
import { statuses, useUser } from 'src/helpers'
import {
    GetRequestRevisionProducts,
    GetReviewProducts,
    GetReviewTodos,
    GetTodos,
    GetTodosData,
} from 'src/services'
import { EmptyTodoState } from './empty-state-todo'
import { TodoProductApprovalCard } from './product-aproval-card'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import type { ToDoObject } from '../../@types/dashboard'
import useUpdateTodo from 'src/components-v2/cards/hooks/useUpdateTodo'
import { GetTodo } from 'src/services/graphql/entities/todo/todo.queries'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'

export const YourTodosList = ({
    fullPage,
    contributorId,
    allowPagination = true,
}: {
    fullPage?: boolean
    contributorId?: string
    allowPagination?: boolean
}) => {
    const { userId, organization_id } = useUser()
    const navigate = useNavigate()
    const boxHeight = '500px'
    const emptyBoxHeight =
        fullPage || contributorId ? 'calc(100vh - 250px)' : '385px'

    const [sortValue, setSortFilter] = useState<string>('Date Created')
    const [filterValue, setTodoFilter] = useState<string>('All')
    const [value, setValue] = useState(0)
    const [tabSearch, setTabSearch] = useState<string>('')

    const [loadIntiated, setLoadInitiated] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { updateTodo } = useUpdateTodo()

    //     Completed Tab
    // ->No completed to-dos yet
    // -------
    // Request Revision Tab
    // ->No request revision to-dos yet
    // -------
    // To be reviewed Tab
    //     To-Dos
    //           No To-Dos for review yet
    //      Product Approval
    //           No Products for review yet
    // ----------
    // In Progress Tab
    //     All:-
    //       No To-Dos  In Progress yet
    //    In Review:-
    //      No In Progress To-Dos are in Review yet
    //    In Delay:-
    //      No In Progress To-Dos are in Delay yet

    const noStateTexts = {
        newTodo: 'No to-do’s yet?',
        completedTodo: 'No completed to-dos yet',
        requestRevision: 'No request revision to-dos yet',
        toBeReviewedTodo: 'No to-dos for review yet',
        toBeReviewedProduct: 'No products for review yet',
        inProgressAll: 'No to-dos in progress yet',
        inProgressReview: 'No in progress to-dos are in review yet',
        inProgressDelay: 'No in progress to-dos are in delay yet',
    }

    const initialCurentPage = {
        new: 1,
        inProgress: 1,
        toBeReviewed: 1,
        toBeReviewedProducts: 1,
        requestRevision: 1,
        completed: 1,
    }
    const [currentPage, setCurrentPage] = useState<{
        new: number
        inProgress: number
        toBeReviewed: number
        toBeReviewedProducts: number
        requestRevision: number
        completed: number
    }>(initialCurentPage)

    const tabKeys = {
        all: 'all',
        productApproval: 'productApproval',
        todos: 'todos',
        delay: 'delay',
        inReview: 'inReview',
    }

    const subTabCategories = [
        // {
        //     label: "All",
        //     key: "all"
        // },
        {
            label: 'Todos',
            key: 'todos',
        },
        {
            label: 'Product Approval',
            key: 'productApproval',
        },
    ]

    const inProgressSubTabCategories = [
        {
            label: 'All',
            key: 'all',
        },
        {
            label: 'In Review',
            key: 'inReview',
        },
        {
            label: 'Delay',
            key: 'delay',
        },
    ]

    const [toBeReviewSubTabCategory, setToBeReviewSubTabCategory] =
        useState<string>(subTabCategories[0].key)
    const [inProgressSubTabCategory, setInProgressSubTabCategory] =
        useState<string>(inProgressSubTabCategories[0].key)

    const dropdownItems = {
        dateCreated: 'Date Created',
        name: 'Name',
        dueDate: 'Due Date',
        latest: 'Latest',
    }
    const sortVar = {
        created_at: sortValue === dropdownItems.dateCreated ? 'desc' : null,
        name: sortValue === dropdownItems.name ? 'asc' : null,
        due_date: sortValue === dropdownItems.dueDate ? 'asc' : null,
        updated_at: sortValue === dropdownItems.latest ? 'desc' : null,
    }

    const sameVar = {
        ...sortVar,
        user_id: contributorId ? contributorId : userId,
        product_id_one:
            filterValue === 'Independent'
                ? true
                : filterValue === 'Collection'
                ? false
                : false,
        product_id_two:
            filterValue === 'Independent'
                ? true
                : filterValue === 'Collection'
                ? false
                : true,
        searchStr: tabSearch ? `%${tabSearch}%` : `%%`,
    }
    const client = useApolloClient()
    const [getNewTodos, _newTodos] = useLazyQuery(GetTodosData, {
        variables: {
            status: [statuses.new],
            organisation_id: organization_id,
            ...sameVar,
        },
        fetchPolicy: 'cache-and-network',
    })

    const [getInprogress, _inProgressTodos] = useLazyQuery(GetTodosData, {
        variables: {
            status:
                inProgressSubTabCategory === tabKeys.delay
                    ? [statuses.inProgress]
                    : inProgressSubTabCategory === tabKeys.inReview
                    ? [statuses.inReview]
                    : [statuses.inReview, statuses.inProgress],
            is_delayed:
                inProgressSubTabCategory === tabKeys.delay
                    ? [true]
                    : [true, false],
            organisation_id: organization_id,
            ...sameVar,
        },
        fetchPolicy: 'cache-and-network',
    })

    const [getCompleted, _completedTodos] = useLazyQuery(GetTodosData, {
        variables: {
            status: [statuses.complete],
            organisation_id: organization_id,
            ...sameVar,
        },
        fetchPolicy: 'cache-and-network',
    })

    /// Special
    const [getInReview, _inReviewTodos] = useLazyQuery(GetReviewTodos, {
        variables: {
            status: [statuses.inReview],
            organisation_id: organization_id,
            ...sameVar,
        },
        fetchPolicy: 'cache-and-network',
    })

    const [getInReviewProducts, _inReviewProducts] = useLazyQuery(
        GetReviewProducts,
        {
            variables: {
                ...sortVar,
                status: [statuses.inReview],
                user_id: contributorId ? contributorId : userId,
                searchStr: tabSearch ? `%${tabSearch}%` : `%%`,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const [getRevisionTodos, _revisionTodos] = useLazyQuery(
        GetRequestRevisionProducts,
        {
            variables: {
                ...sortVar,
                status: [statuses.requestedForResubmission],
                user_id: contributorId ? contributorId : userId,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const newTodos: ToDoObject[] = _newTodos?.data?.todos || []

    console.log({ newTodos })

    const newTodosCount: number =
        _newTodos?.data?.todos_aggregate?.aggregate?.count || 0

    const inProgressTodos: ToDoObject[] = _inProgressTodos?.data?.todos || []
    const inProgressTodosCount: number =
        _inProgressTodos?.data?.todos_aggregate?.aggregate?.count || 0

    const completedTodos: ToDoObject[] = _completedTodos?.data?.todos || []
    const completedTodosCount: number =
        _completedTodos?.data?.todos_aggregate?.aggregate?.count || 0

    const inReviewTodos: ToDoObject[] = _inReviewTodos?.data?.todos || []
    const inReviewTodosCount: number =
        _inReviewTodos?.data?.todos_aggregate?.aggregate?.count || 0

    const inReviewProducts: any[] = _inReviewProducts?.data?.products || []
    const inReviewProductsCount: number =
        _inReviewProducts?.data?.products_aggregate?.aggregate?.count || 0

    const revisionTodos: any[] = _revisionTodos?.data?.products || []
    const revisionTodosCount: number =
        _revisionTodos?.data?.products_aggregate?.aggregate?.count || 0

    useEffect(() => {
        if (_newTodos.loading && !loaded) {
            setLoadInitiated(true)
        }
        if (loadIntiated && !_newTodos.loading && !loaded) {
            setLoaded(true)
        }
    }, [_newTodos.loading])

    function refethAll() {
        if (_newTodos.data) {
            _newTodos.refetch()
        }
        if (_inProgressTodos.data) {
            _inProgressTodos.refetch()
        }
        if (_completedTodos.data) {
            _completedTodos?.refetch()
        }
        if (_inReviewTodos.data) {
            _inReviewTodos.refetch()
        }
        if (_revisionTodos.data) {
            _revisionTodos.refetch()
        }
        if (_inReviewProducts.data) {
            _inReviewProducts.refetch()
        }

        // _newTodos.refetch()
        // _inProgressTodos.refetch()
        // _completedTodos?.refetch()
        // _inReviewTodos.refetch()
        // _revisionTodos.refetch()
        // _inReviewProducts.refetch()
    }

    function updateDate(date: Date, id: string) {
        updateTodo({
            todoId: Number(id),
            payload: {
                due_date: date.toISOString(),
            },
            refetchQueries: [
                GetTodos,
                {
                    query: GetTodo,
                    variables: {
                        id: id,
                    },
                },
                'GetUserTodos',
            ],
        })
    }
    function updateStatus(status: string, id: string) {
        updateTodo({
            todoId: Number(id),
            payload: {
                status,
            },
            refetchQueries: [
                GetTodos,
                {
                    query: GetTodo,
                    variables: {
                        id: id,
                    },
                },
                'GetUserTodos',
            ],
        })
    }

    useEffect(() => {
        if (
            (userId && userId !== '') ||
            (contributorId && contributorId !== '')
        ) {
            getNewTodos()
            getInprogress()
            getCompleted()
            getInReview()
            getRevisionTodos()
            getInReviewProducts()
            // refethAll()
        }
    }, [userId, contributorId])

    useEffect(() => {
        refethAll()
    }, [sortValue, filterValue])

    const { isIntersecting, lastElementRef } = useIntersectionObserver({
        isLoading: false,
        threshold: 1,
    })
    useEffect(() => {
        setCurrentPage(initialCurentPage)
    }, [tabSearch, sortValue, filterValue])

    // useEffect(() => {
    //     if (!isIntersecting) return
    //     if (value === 0) {
    //         if (newTodosCount > 100) {
    //             setCurrentPage({
    //                 ...currentPage,
    //                 new: currentPage.new + 1,
    //             })
    //         }
    //     }
    //     if (value === 1) {
    //         if (inProgressTodosCount > 100) {
    //             setCurrentPage({
    //                 ...currentPage,
    //                 inProgress: currentPage.inProgress + 1,
    //             })
    //         }
    //     }
    //     if (value === 2) {
    //         setCurrentPage({
    //             ...currentPage,
    //             toBeReviewed: currentPage.toBeReviewed + 1,
    //         })
    //     }
    //     if (value === 3) {
    //         setCurrentPage({
    //             ...currentPage,
    //             requestRevision: currentPage.requestRevision + 1,
    //         })
    //     }
    // }, [isIntersecting])

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    }
    return (
        <>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
            >
                <Box
                    display="flex"
                    width="100%"
                    mb={'12px'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Text
                            fontSize={fullPage ? '24px' : '16px'}
                            lineHeight={fullPage ? '32px' : 'unset'}
                            className={fullPage ? '' : 'pointer'}
                            id="todo_page"
                            onClick={() => {
                                navigate('/todos')
                            }}
                            my="0px"
                            mr="12px"
                        >
                            {!contributorId && 'Your '} To-Do’s
                        </Text>

                        {!contributorId && (
                            <>
                                {!fullPage ? (
                                    <Box
                                        className="pointer"
                                        onClick={() => {
                                            navigate('/todos')
                                        }}
                                    >
                                        <RightArrow />
                                    </Box>
                                ) : (
                                    <Button
                                        height="20px"
                                        bg="transparent"
                                        border="none"
                                        fontSize="14px"
                                        color="links"
                                        position="relative"
                                        p={'0px'}
                                        id="todo_page_add_todo"
                                        onClick={() => {
                                            setIsModalOpen(true)
                                        }}
                                    >
                                        + Add To-do
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gridColumnGap={{ md: '12px', lg: '12px', xl: '24px' }}
                        position="relative"
                    >
                        <CustomDropdown
                            label="Sort:"
                            selectedText={sortValue}
                            items={[
                                dropdownItems.name,
                                dropdownItems.dueDate,
                                dropdownItems.dateCreated,
                                dropdownItems.latest,
                            ]}
                            onItemClicked={setSortFilter}
                        />
                        {!contributorId && (
                            <>
                                {fullPage ? (
                                    <BasicSearch
                                        placeholder="Search Todo"
                                        setSearch={(str: string) =>
                                            setTabSearch(str)
                                        }
                                    />
                                ) : (
                                    <Button
                                        height="20px"
                                        bg="transparent"
                                        border="none"
                                        fontSize="14px"
                                        color="links"
                                        position="relative"
                                        p={'0px'}
                                        onClick={() => {
                                            setIsModalOpen(true)
                                        }}
                                    >
                                        + Add To-do
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </Box>

                <Box height="100%">
                    <BasicCard removeBorder={fullPage}>
                        <>
                            <Box
                                pt={fullPage ? '0px' : '16px'}
                                px={fullPage ? '0px' : '16px'}
                                mb="12px"
                            >
                                <Slider
                                    element={
                                        <Box pr={'36px'} position="relative">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons={false}
                                                aria-label="todos-tabs"
                                            >
                                                <Tab
                                                    label={`To-Do (${
                                                        newTodosCount || 0
                                                    })`}
                                                    {...a11yProps(0)}
                                                />
                                                <Tab
                                                    label={`In Progress (${
                                                        inProgressTodosCount ||
                                                        0
                                                    })`}
                                                    {...a11yProps(1)}
                                                />
                                                <Tab
                                                    label={`To Be Reviewed (${
                                                        inReviewTodosCount +
                                                            inReviewProductsCount ||
                                                        0
                                                    })`}
                                                    {...a11yProps(2)}
                                                />
                                                <Tab
                                                    label={`Request Revision (${
                                                        revisionTodosCount || 0
                                                    })`}
                                                    {...a11yProps(3)}
                                                />
                                                <Tab
                                                    label={`Completed (${
                                                        completedTodosCount || 0
                                                    })`}
                                                    {...a11yProps(4)}
                                                />
                                            </Tabs>
                                            {!fullPage && (
                                                <TransparentRightArrow
                                                    onClick={(): any => {
                                                        setValue((value: any) =>
                                                            value < 4
                                                                ? value + 1
                                                                : 0
                                                        )
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    }
                                />
                            </Box>

                            {!loaded ? (
                                <Box
                                    height={boxHeight}
                                    position="relative"
                                    p={fullPage ? '0px' : '16px'}
                                >
                                    <Box
                                        position="relative"
                                        width="100%"
                                        height="100%"
                                    >
                                        <Skeleton
                                            height="48px"
                                            count={6}
                                            containerClassName="loader"
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    height={boxHeight}
                                    pb={'16px'}
                                    px={fullPage ? '0px' : '16px'}
                                    className="scrollbar_none"
                                    overflowY={'auto'}
                                >
                                    <TabPanel
                                        tabName="todos"
                                        value={value}
                                        index={0}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gridRowGap="16px"
                                        >
                                            {!newTodosCount &&
                                                !_newTodos.loading && (
                                                    <EmptyTodoState
                                                        boxHeight={
                                                            emptyBoxHeight
                                                        }
                                                        title={
                                                            noStateTexts.newTodo
                                                        }
                                                        description={
                                                            'Try to assign more tasks to your teammates'
                                                        }
                                                        enableCreateButton={
                                                            contributorId
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                )}

                                            {newTodos?.map(
                                                (
                                                    item: ToDoObject,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Box
                                                            ref={lastElementRef}
                                                            key={
                                                                'newTodos' +
                                                                index
                                                            }
                                                        >
                                                            {/* <TodoCard
                                                                navigateTo={`/todo/${item.id}?section=todo`}
                                                                dashboardView={
                                                                    true
                                                                }
                                                                refetchTodo={() =>
                                                                    client.refetchQueries(
                                                                        {
                                                                            include:
                                                                                [
                                                                                    'getTodosData',
                                                                                ],
                                                                        }
                                                                    )
                                                                }
                                                                refetchProduct={() =>
                                                                    client.refetchQueries(
                                                                        {
                                                                            include:
                                                                                [
                                                                                    'getTodosData',
                                                                                ],
                                                                        }
                                                                    )
                                                                }
                                                                isTabView={true}
                                                                entity={{
                                                                    id: String(
                                                                        item.id
                                                                    ),
                                                                    name: item.name,
                                                                    due_date:
                                                                        item.due_date
                                                                            ? item.due_date
                                                                            : undefined,

                                                                    status: item.status as keyof typeof statuses,
                                                                    tags: item.tags,
                                                                    other_data:
                                                                        {
                                                                            cover_pic:
                                                                                {
                                                                                    gid:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid ||
                                                                                        item
                                                                                            ?.product
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid,

                                                                                    fromLibrary:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.fromLibrary,
                                                                                    thumbnail:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.thumbnail,
                                                                                },
                                                                        },
                                                                    assignee: {
                                                                        id: String(
                                                                            item
                                                                                .assignee
                                                                                .id
                                                                        ),
                                                                        name: item
                                                                            .assignee
                                                                            .name,
                                                                        email: item
                                                                            .assignee
                                                                            .email,
                                                                        other_data:
                                                                            {
                                                                                profile_pic:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.profile_pic,
                                                                                thumbnail:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.thumbnail,
                                                                            },
                                                                    },
                                                                    product: {
                                                                        id: String(
                                                                            item
                                                                                .product
                                                                                ?.id
                                                                        ),
                                                                        name: item
                                                                            ?.product
                                                                            ?.name,
                                                                        status: item
                                                                            ?.product
                                                                            ?.status,
                                                                        // due_date: new Date(item.product.due_date),
                                                                        collection:
                                                                            {
                                                                                id: String(
                                                                                    item
                                                                                        ?.product
                                                                                        ?.collection
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    ?.product
                                                                                    ?.collection
                                                                                    ?.name,
                                                                                due_date:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.due_date,
                                                                                is_delayed:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.is_delayed,
                                                                                manager_id:
                                                                                    String(
                                                                                        item
                                                                                            .product
                                                                                            ?.collection
                                                                                            ?.manager_id
                                                                                    ),
                                                                            },
                                                                    },
                                                                }}
                                                                updateDate={(
                                                                    date
                                                                ) =>
                                                                    updateDate(
                                                                        date,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                                updateStatus={(
                                                                    status
                                                                ) =>
                                                                    updateStatus(
                                                                        status,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                            /> */}
                                                            {newTodos?.length -
                                                                1 !==
                                                            index ? (
                                                                <DivisionLine />
                                                            ) : null}
                                                        </Box>
                                                    )
                                                }
                                            )}
                                            {allowPagination &&
                                                newTodosCount > 100 && (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="end"
                                                    >
                                                        <Box>
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={
                                                                    currentPage.new
                                                                }
                                                                totalCount={
                                                                    newTodosCount
                                                                }
                                                                pageSize={100}
                                                                onPageChange={(
                                                                    page: number
                                                                ) =>
                                                                    setCurrentPage(
                                                                        {
                                                                            ...currentPage,
                                                                            new: page,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                        </Box>
                                    </TabPanel>

                                    <TabPanel
                                        tabName="todos"
                                        value={value}
                                        index={1}
                                    >
                                        <Box mb="12px" mt="16px">
                                            {inProgressSubTabCategories.map(
                                                (item: any) => {
                                                    return (
                                                        <Button
                                                            key={
                                                                'inProgress-' +
                                                                item.key
                                                            }
                                                            onClick={() => {
                                                                setInProgressSubTabCategory(
                                                                    item.key
                                                                )
                                                            }}
                                                            borderRadius="40px"
                                                            mr="12px"
                                                            fontWeight={500}
                                                            fontFamily="Rubik"
                                                            bg={
                                                                inProgressSubTabCategory ===
                                                                item.key
                                                                    ? 'primary'
                                                                    : 'white'
                                                            }
                                                            borderWidth={1}
                                                            border="solid"
                                                            borderColor={
                                                                inProgressSubTabCategory ===
                                                                item.key
                                                                    ? ' primary'
                                                                    : 'gray250'
                                                            }
                                                            color={
                                                                inProgressSubTabCategory ===
                                                                item.key
                                                                    ? 'white'
                                                                    : 'textTags'
                                                            }
                                                            px="12px"
                                                            py="4px"
                                                        >
                                                            {item.label}
                                                        </Button>
                                                    )
                                                }
                                            )}
                                        </Box>

                                        {!inProgressTodosCount &&
                                            !_inProgressTodos.loading && (
                                                <EmptyTodoState
                                                    boxHeight={emptyBoxHeight}
                                                    title={
                                                        inProgressSubTabCategory ===
                                                        'delay'
                                                            ? noStateTexts.inProgressDelay
                                                            : inProgressSubTabCategory ===
                                                              'inReview'
                                                            ? noStateTexts.inProgressReview
                                                            : noStateTexts.inProgressAll
                                                    }
                                                />
                                            )}

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gridRowGap="16px"
                                        >
                                            {inProgressTodos?.map(
                                                (
                                                    item: ToDoObject,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Box
                                                            ref={lastElementRef}
                                                            key={
                                                                'inProgressTodos' +
                                                                index
                                                            }
                                                        >
                                                            {/* <TodoCard
                                                                navigateTo={`/todo/${item.id}?section=todo`}
                                                                dashboardView={
                                                                    true
                                                                }
                                                                refetchTodo={() =>
                                                                    getInprogress(
                                                                        {
                                                                            variables:
                                                                                {
                                                                                    status:
                                                                                        inProgressSubTabCategory ===
                                                                                        tabKeys.delay
                                                                                            ? [
                                                                                                  statuses.inProgress,
                                                                                              ]
                                                                                            : inProgressSubTabCategory ===
                                                                                              tabKeys.inReview
                                                                                            ? [
                                                                                                  statuses.inReview,
                                                                                              ]
                                                                                            : [
                                                                                                  statuses.inReview,
                                                                                                  statuses.inProgress,
                                                                                              ],
                                                                                    is_delayed:
                                                                                        inProgressSubTabCategory ===
                                                                                        tabKeys.delay
                                                                                            ? [
                                                                                                  true,
                                                                                              ]
                                                                                            : [
                                                                                                  true,
                                                                                                  false,
                                                                                              ],
                                                                                    organisation_id:
                                                                                        organization_id,
                                                                                    ...sameVar,
                                                                                },
                                                                            fetchPolicy:
                                                                                'cache-and-network',
                                                                        }
                                                                    )
                                                                }
                                                                isTabView={true}
                                                                entity={{
                                                                    id: String(
                                                                        item.id
                                                                    ),
                                                                    name: item.name,
                                                                    due_date:
                                                                        item.due_date,
                                                                    status: item.status as keyof typeof statuses,
                                                                    tags: item.tags,
                                                                    other_data:
                                                                        {
                                                                            cover_pic:
                                                                                {
                                                                                    gid:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid ||
                                                                                        item
                                                                                            .product
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid,

                                                                                    fromLibrary:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.fromLibrary,
                                                                                    thumbnail:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.thumbnail,
                                                                                },
                                                                        },
                                                                    assignee: {
                                                                        id: String(
                                                                            item
                                                                                .assignee
                                                                                .id
                                                                        ),
                                                                        name: item
                                                                            .assignee
                                                                            .name,
                                                                        email: item
                                                                            .assignee
                                                                            .email,
                                                                        other_data:
                                                                            {
                                                                                profile_pic:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.profile_pic,
                                                                                thumbnail:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.thumbnail,
                                                                            },
                                                                    },
                                                                    product: {
                                                                        id: String(
                                                                            item
                                                                                .product
                                                                                ?.id
                                                                        ),
                                                                        name: item
                                                                            .product
                                                                            ?.name,
                                                                        status: item
                                                                            .product
                                                                            ?.status,
                                                                        // due_date: new Date(item.product.due_date),
                                                                        collection:
                                                                            {
                                                                                id: String(
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    .product
                                                                                    ?.collection
                                                                                    ?.name,
                                                                                due_date:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.due_date,
                                                                                is_delayed:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.is_delayed,
                                                                                manager_id:
                                                                                    String(
                                                                                        item
                                                                                            .product
                                                                                            ?.collection
                                                                                            ?.manager_id
                                                                                    ),
                                                                            },
                                                                    },
                                                                }}
                                                                updateDate={(
                                                                    date
                                                                ) =>
                                                                    updateDate(
                                                                        date,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                                updateStatus={(
                                                                    status
                                                                ) =>
                                                                    updateStatus(
                                                                        status,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                            /> */}
                                                            {inProgressTodos?.length -
                                                                1 !==
                                                            index ? (
                                                                <DivisionLine />
                                                            ) : null}
                                                        </Box>
                                                    )
                                                }
                                            )}
                                        </Box>
                                        {allowPagination && (
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                            >
                                                <Box>
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={
                                                            currentPage.inProgress
                                                        }
                                                        totalCount={
                                                            inProgressTodosCount
                                                        }
                                                        pageSize={100}
                                                        onPageChange={(
                                                            page: number
                                                        ) =>
                                                            setCurrentPage({
                                                                ...currentPage,
                                                                inProgress:
                                                                    page,
                                                            })
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </TabPanel>

                                    <TabPanel
                                        tabName="todos"
                                        value={value}
                                        index={2}
                                    >
                                        <Box mt="16px" mb="12px">
                                            {subTabCategories.map(
                                                (item: any) => {
                                                    return (
                                                        <Button
                                                            key={
                                                                'toBeReview-' +
                                                                item.key
                                                            }
                                                            onClick={() => {
                                                                setToBeReviewSubTabCategory(
                                                                    item.key
                                                                )
                                                            }}
                                                            borderRadius="40px"
                                                            mr="12px"
                                                            fontWeight={500}
                                                            fontFamily="Rubik"
                                                            bg={
                                                                item.key ===
                                                                toBeReviewSubTabCategory
                                                                    ? 'primary'
                                                                    : 'white'
                                                            }
                                                            borderWidth={1}
                                                            border="solid"
                                                            borderColor={
                                                                item.key ===
                                                                toBeReviewSubTabCategory
                                                                    ? ' primary'
                                                                    : 'gray250'
                                                            }
                                                            color={
                                                                item.key ===
                                                                toBeReviewSubTabCategory
                                                                    ? 'white'
                                                                    : 'textTags'
                                                            }
                                                            px="12px"
                                                            py="4px"
                                                        >
                                                            {item.label} (
                                                            {item.key ===
                                                            tabKeys.todos
                                                                ? inReviewTodosCount
                                                                : inReviewProductsCount}
                                                            )
                                                        </Button>
                                                    )
                                                }
                                            )}
                                        </Box>

                                        {toBeReviewSubTabCategory !==
                                            tabKeys.todos &&
                                            !inReviewProductsCount &&
                                            !_inReviewProducts.loading && (
                                                <EmptyTodoState
                                                    boxHeight={emptyBoxHeight}
                                                    title={
                                                        noStateTexts.toBeReviewedProduct
                                                    }
                                                />
                                            )}

                                        {toBeReviewSubTabCategory ===
                                            tabKeys.todos &&
                                            !inReviewTodosCount &&
                                            !_inReviewTodos.loading && (
                                                <EmptyTodoState
                                                    boxHeight={emptyBoxHeight}
                                                    title={
                                                        noStateTexts.toBeReviewedTodo
                                                    }
                                                />
                                            )}

                                        {toBeReviewSubTabCategory !==
                                            tabKeys.todos && (
                                            <>
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    gridRowGap="16px"
                                                    mt="12px"
                                                >
                                                    {inReviewProducts?.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <Box
                                                                    key={
                                                                        'inReviewProductTodos' +
                                                                        index
                                                                    }
                                                                >
                                                                    <TodoProductApprovalCard
                                                                        data={
                                                                            item
                                                                        }
                                                                    />
                                                                    <DivisionLine />
                                                                </Box>
                                                            )
                                                        }
                                                    )}
                                                </Box>

                                                {allowPagination && (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="end"
                                                    >
                                                        <Box>
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={
                                                                    currentPage.toBeReviewedProducts
                                                                }
                                                                totalCount={
                                                                    inReviewProductsCount
                                                                }
                                                                pageSize={100}
                                                                onPageChange={(
                                                                    page: number
                                                                ) =>
                                                                    setCurrentPage(
                                                                        {
                                                                            ...currentPage,
                                                                            toBeReviewedProducts:
                                                                                page,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        {toBeReviewSubTabCategory !==
                                            tabKeys.productApproval && (
                                            <>
                                                {inReviewTodos?.map(
                                                    (
                                                        item: ToDoObject,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <Box
                                                                ref={
                                                                    lastElementRef
                                                                }
                                                                key={
                                                                    'inReviewTodos' +
                                                                    index
                                                                }
                                                            >
                                                                {/* <TodoCard
                                                                    navigateTo={`/todo/${item.id}?section=todo`}
                                                                    dashboardView={
                                                                        true
                                                                    }
                                                                    refetchTodo={() =>
                                                                        getInReview(
                                                                            {
                                                                                variables:
                                                                                    {
                                                                                        status: [
                                                                                            statuses.inReview,
                                                                                        ],
                                                                                        organisation_id:
                                                                                            organization_id,
                                                                                        ...sameVar,
                                                                                    },
                                                                                fetchPolicy:
                                                                                    'cache-and-network',
                                                                            }
                                                                        )
                                                                    }
                                                                    isTabView={
                                                                        true
                                                                    }
                                                                    entity={{
                                                                        id: String(
                                                                            item.id
                                                                        ),
                                                                        name: item.name,
                                                                        due_date:
                                                                            item.due_date,
                                                                        status: item.status as keyof typeof statuses,
                                                                        tags: item.tags,
                                                                        other_data:
                                                                            {
                                                                                cover_pic:
                                                                                    {
                                                                                        gid:
                                                                                            item
                                                                                                ?.other_data
                                                                                                ?.cover_pic
                                                                                                ?.gid ||
                                                                                            item
                                                                                                .product
                                                                                                ?.other_data
                                                                                                ?.cover_pic
                                                                                                ?.gid,

                                                                                        fromLibrary:
                                                                                            item
                                                                                                ?.other_data
                                                                                                ?.cover_pic
                                                                                                ?.fromLibrary,
                                                                                        thumbnail:
                                                                                            item
                                                                                                ?.other_data
                                                                                                ?.cover_pic
                                                                                                ?.thumbnail,
                                                                                    },
                                                                            },
                                                                        assignee:
                                                                            {
                                                                                id: String(
                                                                                    item
                                                                                        .assignee
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    ?.assignee
                                                                                    ?.name,
                                                                                email: item
                                                                                    .assignee
                                                                                    ?.email,
                                                                                other_data:
                                                                                    {
                                                                                        profile_pic:
                                                                                            item
                                                                                                ?.assignee
                                                                                                ?.other_data
                                                                                                ?.profile_pic,
                                                                                        thumbnail:
                                                                                            item
                                                                                                ?.assignee
                                                                                                ?.other_data
                                                                                                ?.thumbnail,
                                                                                    },
                                                                            },
                                                                        product:
                                                                            {
                                                                                id: String(
                                                                                    item
                                                                                        .product
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    .product
                                                                                    ?.name,
                                                                                status: item
                                                                                    .product
                                                                                    ?.status,
                                                                                // due_date: new Date(item.product.due_date),
                                                                                collection:
                                                                                    {
                                                                                        id: String(
                                                                                            item
                                                                                                .product
                                                                                                ?.collection
                                                                                                .id
                                                                                        ),
                                                                                        name: item
                                                                                            .product
                                                                                            ?.collection
                                                                                            ?.name,
                                                                                        due_date:
                                                                                            item
                                                                                                .product
                                                                                                ?.collection
                                                                                                ?.due_date,
                                                                                        is_delayed:
                                                                                            item
                                                                                                .product
                                                                                                ?.collection
                                                                                                ?.is_delayed,
                                                                                        manager_id:
                                                                                            String(
                                                                                                item
                                                                                                    .product
                                                                                                    ?.collection
                                                                                                    ?.manager_id
                                                                                            ),
                                                                                    },
                                                                            },
                                                                    }}
                                                                    updateDate={(
                                                                        date
                                                                    ) =>
                                                                        updateDate(
                                                                            date,
                                                                            String(
                                                                                item.id
                                                                            )
                                                                        )
                                                                    }
                                                                    updateStatus={(
                                                                        status
                                                                    ) =>
                                                                        updateStatus(
                                                                            status,
                                                                            String(
                                                                                item.id
                                                                            )
                                                                        )
                                                                    }
                                                                /> */}
                                                                {inReviewTodos?.length -
                                                                    1 !==
                                                                index ? (
                                                                    <DivisionLine />
                                                                ) : null}
                                                            </Box>
                                                        )
                                                    }
                                                )}
                                                {allowPagination && (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="end"
                                                    >
                                                        <Box>
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={
                                                                    currentPage.toBeReviewed
                                                                }
                                                                totalCount={
                                                                    inReviewTodosCount
                                                                }
                                                                pageSize={100}
                                                                onPageChange={(
                                                                    page: number
                                                                ) =>
                                                                    setCurrentPage(
                                                                        {
                                                                            ...currentPage,
                                                                            toBeReviewed:
                                                                                page,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </TabPanel>
                                    <TabPanel
                                        tabName="todos"
                                        value={value}
                                        index={3}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gridRowGap="16px"
                                        >
                                            {!revisionTodosCount &&
                                                !_revisionTodos.loading && (
                                                    <EmptyTodoState
                                                        boxHeight={
                                                            emptyBoxHeight
                                                        }
                                                        title={
                                                            noStateTexts.requestRevision
                                                        }
                                                    />
                                                )}

                                            {revisionTodos?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <Box
                                                            key={
                                                                'revisionTodos' +
                                                                index
                                                            }
                                                        >
                                                            <TodoProductApprovalCard
                                                                data={item}
                                                            />
                                                            {revisionTodos?.length -
                                                                1 !==
                                                            index ? (
                                                                <DivisionLine />
                                                            ) : null}
                                                        </Box>
                                                    )
                                                }
                                            )}
                                            {allowPagination &&
                                                revisionTodosCount > 100 && (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="end"
                                                    >
                                                        <Box>
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={
                                                                    currentPage.requestRevision
                                                                }
                                                                totalCount={
                                                                    revisionTodosCount
                                                                }
                                                                pageSize={100}
                                                                onPageChange={(
                                                                    page: number
                                                                ) =>
                                                                    setCurrentPage(
                                                                        {
                                                                            ...currentPage,
                                                                            requestRevision:
                                                                                page,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                        </Box>
                                    </TabPanel>
                                    <TabPanel
                                        tabName="todos"
                                        value={value}
                                        index={4}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gridRowGap="16px"
                                        >
                                            {!completedTodosCount &&
                                                !_completedTodos.loading && (
                                                    <EmptyTodoState
                                                        boxHeight={
                                                            emptyBoxHeight
                                                        }
                                                        title={
                                                            noStateTexts.completedTodo
                                                        }
                                                    />
                                                )}

                                            {completedTodos?.map(
                                                (
                                                    item: ToDoObject,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Box
                                                            ref={lastElementRef}
                                                            key={
                                                                'completedTodos' +
                                                                index
                                                            }
                                                        >
                                                            {/* <TodoCard
                                                                navigateTo={`/todo/${item.id}?section=todo`}
                                                                dashboardView={
                                                                    true
                                                                }
                                                                refetchTodo={() =>
                                                                    getCompleted(
                                                                        {
                                                                            variables:
                                                                                {
                                                                                    status: [
                                                                                        statuses.complete,
                                                                                    ],
                                                                                    organisation_id:
                                                                                        organization_id,
                                                                                    ...sameVar,
                                                                                },
                                                                            fetchPolicy:
                                                                                'cache-and-network',
                                                                        }
                                                                    )
                                                                }
                                                                isTabView={true}
                                                                entity={{
                                                                    id: String(
                                                                        item.id
                                                                    ),
                                                                    name: item.name,
                                                                    due_date:
                                                                        item.due_date,
                                                                    status: item.status as keyof typeof statuses,
                                                                    tags: item.tags,
                                                                    other_data:
                                                                        {
                                                                            cover_pic:
                                                                                {
                                                                                    gid:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid ||
                                                                                        item
                                                                                            .product
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.gid,
                                                                                    fromLibrary:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.fromLibrary,
                                                                                    thumbnail:
                                                                                        item
                                                                                            ?.other_data
                                                                                            ?.cover_pic
                                                                                            ?.thumbnail,
                                                                                },
                                                                        },
                                                                    assignee: {
                                                                        id: String(
                                                                            item
                                                                                .assignee
                                                                                .id
                                                                        ),
                                                                        name: item
                                                                            .assignee
                                                                            .name,
                                                                        email: item
                                                                            .assignee
                                                                            .email,
                                                                        other_data:
                                                                            {
                                                                                profile_pic:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.profile_pic,
                                                                                thumbnail:
                                                                                    item
                                                                                        ?.assignee
                                                                                        ?.other_data
                                                                                        ?.thumbnail,
                                                                            },
                                                                    },
                                                                    product: {
                                                                        id: String(
                                                                            item
                                                                                .product
                                                                                ?.id
                                                                        ),
                                                                        name: item
                                                                            .product
                                                                            ?.name,
                                                                        status: item
                                                                            .product
                                                                            ?.status,
                                                                        // due_date: new Date(item.product.due_date),
                                                                        collection:
                                                                            {
                                                                                id: String(
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.id
                                                                                ),
                                                                                name: item
                                                                                    .product
                                                                                    ?.collection
                                                                                    ?.name,
                                                                                due_date:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.due_date,
                                                                                is_delayed:
                                                                                    item
                                                                                        .product
                                                                                        ?.collection
                                                                                        ?.is_delayed,
                                                                                manager_id:
                                                                                    String(
                                                                                        item
                                                                                            .product
                                                                                            ?.collection
                                                                                            ?.manager_id
                                                                                    ),
                                                                            },
                                                                    },
                                                                }}
                                                                updateDate={(
                                                                    date
                                                                ) =>
                                                                    updateDate(
                                                                        date,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                                updateStatus={(
                                                                    status
                                                                ) =>
                                                                    updateStatus(
                                                                        status,
                                                                        String(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                            /> */}
                                                            {completedTodos?.length -
                                                                1 !==
                                                            index ? (
                                                                <DivisionLine />
                                                            ) : null}
                                                        </Box>
                                                    )
                                                }
                                            )}

                                            {allowPagination && (
                                                <Box
                                                    display="flex"
                                                    justifyContent="end"
                                                >
                                                    <Box>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={
                                                                currentPage.completed
                                                            }
                                                            totalCount={
                                                                completedTodosCount
                                                            }
                                                            pageSize={100}
                                                            onPageChange={(
                                                                page: number
                                                            ) =>
                                                                setCurrentPage({
                                                                    ...currentPage,
                                                                    completed:
                                                                        page,
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </TabPanel>
                                </Box>
                            )}
                        </>
                    </BasicCard>
                </Box>
            </Box>
            {isModalOpen && (
                <CreateTodoModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </>
    )
}
