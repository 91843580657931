import { Drawer } from '@mui/material'
import { Box } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { CloseIco } from '../svg-icons'
import { useNavigate } from 'react-router-dom'
import type { DrawerTab } from 'src/store/slice/@types/slider-types'
import { useCreateCollectionFormStore } from 'src/store/hooks/useCreateCollectionFormStore'
import { useCreateProductFormStore } from 'src/store/hooks/useCreateProductFormStore'
import { useCreateTodoFormStore } from 'src/store/hooks/useCreateTodoFormStore'
import { Slider } from '../slider/slider'
import { DrawerNavSliders } from './drawer-nav-sliders'
import { TransparentRightArrow } from '../transparent-right-arrow/transparent-right-arrow'
import { TransparentLeftArrow } from '../transparent-left-arrow/transparent-left-arrow'
import TeamTab from './team-tab/team-tab'
import CollaboratorTab from './collaborator-tab/collaborator-tab'
import { IndividualCollaboratorTab } from './individual-collaborator-tab/individual-collaborator-tab'
export default function ProductDrawer() {
    const {
        drawerTabs,
        clearDrawerTabs,
        currentTabId,
        setCurrentTabId,
        showDrawerDock,
        setShowDrawerDock,
    } = useStore()
    const { clearTodoForm } = useCreateTodoFormStore()
    const { clearCollectionForm } = useCreateCollectionFormStore()
    const { clearProductForm } = useCreateProductFormStore()

    const navigate = useNavigate()

    const currentTab = drawerTabs.find((tab) => tab.id === currentTabId)

    function renderCurrentTab(currentTab: DrawerTab) {
        switch (currentTab.type) {
            case 'teams':
                return <TeamTab tabView={true} />
            case 'collaborators':
                return <CollaboratorTab />
            case 'individualCollaborator':
                return <IndividualCollaboratorTab tabView={true} />
            default:
                return <Box>Failed to open the tab</Box>
        }
    }

    const navigateToNextTab = () => {
        const indexOfCurrentTab = drawerTabs.findIndex(
            (tab) => tab.id === currentTabId
        )
        setCurrentTabId(
            indexOfCurrentTab === drawerTabs.length - 1
                ? drawerTabs[0].id
                : drawerTabs[indexOfCurrentTab + 1].id
        )
    }

    const navigateToPreviousTab = () => {
        const indexOfCurrentTab = drawerTabs.findIndex(
            (tab) => tab.id === currentTabId
        )
        setCurrentTabId(
            indexOfCurrentTab === 0
                ? drawerTabs[drawerTabs.length - 1].id
                : drawerTabs[indexOfCurrentTab - 1].id
        )
    }

    return (
        <Drawer
            anchor={'right'}
            variant="persistent"
            open={!showDrawerDock && drawerTabs && drawerTabs.length > 0}
            classes={{
                paper: 'my-drawer',
            }}
        >
            <Box
                width="100%"
                display="flex"
                // gridGap="10px"

                // gridTemplateColumns="1fr 20px"
                pr="32px"
                // alignItems="center"
                justifyContent="end"
                py="12px"
                bg="#E8ECEF"
            >
                {false && (
                    <Slider
                        element={
                            <Box
                                position="relative"
                                px={drawerTabs.length > 3 ? '36px' : '0px'}
                            >
                                {drawerTabs.length > 3 && (
                                    <TransparentLeftArrow
                                        onClick={navigateToPreviousTab}
                                    />
                                )}
                                <DrawerNavSliders
                                    value={currentTabId}
                                    setValue={setCurrentTabId}
                                    tabs={drawerTabs}
                                />
                                {drawerTabs.length > 3 && (
                                    <TransparentRightArrow
                                        onClick={navigateToNextTab}
                                    />
                                )}
                            </Box>
                        }
                        carousel={true}
                    />
                )}
                {/* <MinimizeIco
                    size={16}
                    onClick={() => setShowDrawerDock(true)}
                />
                <ExpandIco size={16} onClick={navigateToDetailedPage} /> */}

                <CloseIco
                    size={16}
                    onClick={() => {
                        clearTodoForm()
                        clearProductForm()
                        clearCollectionForm()
                        clearDrawerTabs()
                    }}
                />
            </Box>
            {drawerTabs.map((tab) => (
                <div role="tabpanel" hidden={tab.id !== currentTabId}>
                    {renderCurrentTab(tab)}
                </div>
            ))}
        </Drawer>
    )
}
