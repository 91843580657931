import { createContext } from 'react'
import * as React from 'react'
import type { TPillBadges, TTabs } from './@types'

const Notification = createContext<{
    activeTab: TTabs
    setActiveTab: React.Dispatch<React.SetStateAction<TTabs>>
    activePillBadge: TPillBadges
    setActivePillBadge: React.Dispatch<React.SetStateAction<TPillBadges>>
}>({
    activeTab: 'all',
    setActiveTab: () => {},
    activePillBadge: {
        label: 'All',
        value: 'all',
    },
    setActivePillBadge: () => {},
})

type IAppProps = {
    children: JSX.Element
}

export default function NotificationContext({ children }: IAppProps) {
    const [activeTab, setActiveTab] = React.useState<TTabs>('all')
    const [activePillBadge, setActivePillBadge] = React.useState<TPillBadges>({
        label: 'All',
        value: 'all',
    })
    return (
        <Notification.Provider
            value={{
                activeTab,
                setActiveTab,
                activePillBadge,
                setActivePillBadge,
            }}
        >
            {children}
        </Notification.Provider>
    )
}

export function useNotificationContext() {
    return React.useContext(Notification)
}
