import React from 'react'

function CircleSelectedIco() {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_19054_295405)">
                <rect
                    x="2.5"
                    y="2.5"
                    width="17"
                    height="17"
                    rx="8.5"
                    stroke="black"
                    shape-rendering="crispEdges"
                />
                <circle cx="11" cy="11" r="6" fill="#203C5A" />
            </g>
            <defs>
                <filter
                    id="filter0_d_19054_295405"
                    x="0"
                    y="0"
                    width="26"
                    height="26"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_19054_295405"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_19054_295405"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default CircleSelectedIco
