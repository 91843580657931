export const MentionTeamIco = () => (
    <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.86667 0C3.44609 0 1.46667 1.97943 1.46667 4.4C1.46667 5.85521 2.18281 7.15 3.27708 7.95208C1.55179 8.80834 0.296304 10.4834 0.0458307 12.4684C-0.00487179 12.8702 0.328324 13.2 0.733333 13.2C1.13834 13.2 1.46026 12.8692 1.52647 12.4697C1.87237 10.382 3.67783 8.8 5.86667 8.8C8.0555 8.8 9.86097 10.382 10.2069 12.4697C10.2731 12.8692 10.595 13.2 11 13.2C11.405 13.2 11.7269 12.8692 11.7931 12.4697C12.139 10.382 13.9445 8.8 16.1333 8.8C18.3222 8.8 20.1276 10.382 20.4735 12.4697C20.5397 12.8692 20.8617 13.2 21.2667 13.2C21.6717 13.2 22.0049 12.8702 21.9542 12.4684C21.7037 10.4834 20.4482 8.80834 18.7229 7.95208C19.8172 7.15 20.5333 5.85521 20.5333 4.4C20.5333 1.97943 18.5539 0 16.1333 0C13.7128 0 11.7333 1.97943 11.7333 4.4C11.7333 5.85521 12.4495 7.15 13.5437 7.95208C12.4753 8.48203 11.5786 9.31849 11 10.3583C10.4214 9.31849 9.52474 8.48203 8.45625 7.95208C9.55052 7.15 10.2667 5.85521 10.2667 4.4C10.2667 1.97943 8.28724 0 5.86667 0ZM5.86667 1.46667C7.49661 1.46667 8.8 2.77005 8.8 4.4C8.8 6.02995 7.49661 7.33333 5.86667 7.33333C4.23672 7.33333 2.93333 6.02995 2.93333 4.4C2.93333 2.77005 4.23672 1.46667 5.86667 1.46667ZM16.1333 1.46667C17.7633 1.46667 19.0667 2.77005 19.0667 4.4C19.0667 6.02995 17.7633 7.33333 16.1333 7.33333C14.5034 7.33333 13.2 6.02995 13.2 4.4C13.2 2.77005 14.5034 1.46667 16.1333 1.46667Z"
            fill="#778CA2"
        />
    </svg>
)
