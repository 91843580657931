import * as React from 'react'

export const CalendarIcoSm = ({ color }: { color?: string }) => (
    <svg
        id="calendar-ico-bg"
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="calendar-ico-path"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.667 3.333a.667.667 0 1 1 1.333 0V4h4v-.667a.667.667 0 0 1 1.333 0V4h1.334c.368 0 .666.298.666.667v8a.667.667 0 0 1-.666.666H3.333a.667.667 0 0 1-.666-.667v-8c0-.368.298-.666.666-.666h1.334v-.667Zm-.934 2.933v-1.2h8.534v1.2H3.733Zm0 1.067v4.934h8.534V7.333H3.733Z"
            fill={color || '#778CA2'}
        />
    </svg>
)
