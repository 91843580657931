import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { CloseIco } from 'src/components/svg-icons'
import CopyIco from 'src/components/svg-icons/copy-ico'
import { Box, Button } from 'src/components/utility'

function Link({
    link,
    removeLink,
    showRemoveButton,
}: {
    link: { id?: string; text: string; url: string }
    removeLink: (link: { text: string; url: string }) => void
    showRemoveButton: boolean
}) {
    const [isHovered, setIsHovered] = useState(false)

    function onCopyLink() {
        if (link?.url && typeof link.url === 'string') {
            navigator.clipboard.writeText(link.url)
            toast('Link copied to clipboard', {
                className: 'custom-toaster toaster-success',
            })
        }
    }

    return (
        <Box
            display="grid"
            width={'100%'}
            gridTemplateColumns="auto auto auto 1fr"
            overflow={'hidden'}
            gridGap="4px"
            alignItems="center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Button
                height="20px"
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="#027AC3"
                position="relative"
                onClick={() => window.open(link.url, '_blank')}
                title={link.text || link.url}
                textAlign="left"
                className="text-ellipsis"
            >
                {link.text || link.url}
            </Button>
            {isHovered && (
                <Button
                    onClick={onCopyLink}
                    bg="transparent"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CopyIco />
                </Button>
            )}
            {showRemoveButton && <CloseIco onClick={() => removeLink(link)} />}
        </Box>
    )
}

export default Link
