import { uuid } from '.'

const tpsub_todos = [
    {
        id: uuid(),
        heading: 'Silhouette Reference',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Fabric/Texture',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Colors',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'color',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Trims/Hardware',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
]

const basesub_todos = [
    {
        id: uuid(),
        heading: 'Base Model',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
]

const fRendersub_todos = [
    {
        id: uuid(),
        heading: 'Texturing',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Final Render',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
]

export const initialProductTodos = {
    data: [
        {
            id: 0,
            name: 'Tech Pack',
            types: 'techPack',
            dueDate: '',
            assignee_id: '',
            sub_todos: { data: tpsub_todos },
            expanded: false,
            editableHeading: false,
            isCompleted: 'UNEDITED',
        },
        {
            id: 1,
            name: 'Base Model',
            types: 'baseModel',
            dueDate: '',
            assignee_id: '',
            sub_todos: { data: basesub_todos },
            expanded: false,
            editableHeading: false,
            isCompleted: 'UNEDITED',
        },
        {
            id: 2,
            name: 'Final Render',
            types: 'finalRender',
            dueDate: '',
            assignee_id: '',
            sub_todos: { data: fRendersub_todos },
            expanded: false,
            editableHeading: false,
            isCompleted: 'UNEDITED',
        },
    ],
}

export const TAGS_CHAR_LIMIT = 20

export const SUBTODO_DESIGN_COLORS = [
    '#D6E0EA',
    '#F5F6A9',
    '#FFE2D1',
    '#FFE395',
    '#A9F6F2',
    '#A9F6BF',
    '#D8D5FD',
    '#FFDDFF',
    '#FABBCD',
    '#FED5DD',
] as const
