import { statuses } from 'src/helpers'
import type { storeState } from './@types/slider-types'
import type { TGlobalSearchResults } from 'src/components-v2/global-search/@types/search'

export const searchResults = {
    collections: {
        count: 0,
        data: [],
    },
    products: {
        count: 0,
        data: [],
    },
    todos: {
        count: 0,
        data: [],
    },
    teams: {
        count: 0,
        data: [],
    },
    collaborators: {
        count: 0,
        data: [],
    },
    library_folders: {
        count: 0,
        data: [],
    },
    library_files: {
        count: 0,
        data: [],
    },
}

export const globalSearch = {
    collaborators: [
        {
            role: '',
            id: '0',
            name: '',
            email: '',
            profile_img: '',
        },
    ],
    tags: [
        {
            id: '',
            name: '',
        },
    ],
    file_types: [],
    isGlobalSearchActive: false,
    dateRange: null,
    results: 0,
    status: [
        {
            label: 'Completed',
            id: statuses.complete,
            isActive: false,
        },
        {
            label: 'In Progress',
            id: statuses.inProgress,
            isActive: false,
        },
        {
            label: 'New',
            id: statuses.new,
            isActive: false,
        },
        {
            label: 'Revision Requested',
            id: statuses.revisionRequested,
            isActive: false,
        },
        {
            label: 'Delayed',
            id: statuses.delayed,
            isActive: false,
        },
    ],
    query: '',
}

const createGlobalSearchSlice = (set: Function) => ({
    // State

    globalSearchFilters: JSON.parse(
        JSON.stringify(globalSearch)
    ) as typeof globalSearch,
    isFilterActive: false,
    searchResults: JSON.parse(
        JSON.stringify(searchResults)
    ) as TGlobalSearchResults,
    isFilterModified: false,
    // Dispatch
    setCollaborators: (payload: {
        collaborators: {
            id: string
            name: string
            email: string
            profile_img: string
        }[]
    }) => {
        set((state: storeState) => {
            return {
                ...state,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    collaborators: payload.collaborators,
                },
            }
        })
    },
    addCollaborators: (payload: {
        collaborator: {
            id: string
            name: string
            email: string
            profile_img: string
        }
    }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    collaborators: [
                        ...state.globalSearchFilters.collaborators,
                        payload,
                    ],
                },
            }
        })
    },
    setTags: (payload: {
        tags: {
            id: string
            name: string
        }[]
    }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    tags: payload.tags,
                },
            }
        })
    },
    setStatus: (payload: { statuses: statuses[] }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    status: payload.statuses,
                },
            }
        })
    },
    setDateRange: (payload: { dateRange: Date | string }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    dateRange: payload.dateRange,
                },
            }
        })
    },
    setQuery: (payload: { query: string }) => {
        set((state: storeState) => {
            return {
                ...state,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    query: payload.query,
                },
            }
        })
    },
    setGlobalSearchActive: (payload: { isGlobalSearchActive: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    isGlobalSearchActive: payload.isGlobalSearchActive,
                },
            }
        })
    },
    setResults: (payload: { results: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    results: payload.results,
                },
            }
        })
    },
    setGlobalSearchResults: (payload: { results: TGlobalSearchResults }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: payload.results,
            }
        })
    },
    removeCollection: (payload: { id: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    collections: {
                        ...searchResults.collections.data,
                        data: state.searchResults.collections.data.filter(
                            (collection) =>
                                Number(collection.id) !== Number(payload.id)
                        ),
                    },
                },
            }
        })
    },
    removeProduct: (payload: { id: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    products: {
                        ...state.searchResults.products,
                        data: state.searchResults.products.data.filter(
                            (product) =>
                                Number(product.id) !== Number(payload.id)
                        ),
                    },
                },
            }
        })
    },
    removeTodo: (payload: { id: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    todos: {
                        ...state.searchResults.todos,
                        data: state.searchResults.todos.data.filter(
                            (todo) => Number(todo.id) !== Number(payload.id)
                        ),
                    },
                },
            }
        })
    },
    removeTeam: (payload: { id: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    teams: {
                        ...state.searchResults.teams,
                        data: state.searchResults.teams.data.filter(
                            (todo) => Number(todo.id) !== Number(payload.id)
                        ),
                    },
                },
            }
        })
    },
    removeCollaboratorFromSearch: (payload: { id: string }) => {
        set((state: storeState) => {
            return {
                ...state,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    collaborators:
                        state.globalSearchFilters.collaborators.filter(
                            (collaborator) =>
                                String(collaborator.id) !== String(payload.id)
                        ),
                },
            }
        })
    },
    removeCollaborator: (payload: { id: string }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    collaborators: {
                        ...state.searchResults.collaborators,
                        data: state.globalSearchFilters.collaborators.filter(
                            (collaborator) =>
                                String(collaborator.id) !== String(payload.id)
                        ),
                    },
                },
            }
        })
    },
    removeTags: (payload: { id: number }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: true,
                globalSearchFilters: {
                    ...state.globalSearchFilters,
                    tags: state.globalSearchFilters.tags.filter(
                        (tag) => Number(tag.id) !== payload.id
                    ),
                },
            }
        })
    },
    setFilterActive: (payload: { isActive: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterActive: payload.isActive,
            }
        })
    },
    updateGlobalSearchResults: (payload: {
        type:
            | 'collections'
            | 'products'
            | 'product_variants'
            | 'todos'
            | 'teams'
            | 'collaborators'
            | 'library_folders'
            | 'library_files'
        updatedKey: string | any | null
        value: any
        id: string
    }) => {
        set((state: storeState) => {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    [payload.type]: {
                        ...state.searchResults[payload.type],
                        data: state.searchResults[payload.type].data.map(
                            (item) => {
                                if (String(item.id) === String(payload.id)) {
                                    return {
                                        ...item,
                                        [payload.updatedKey]: payload.value,
                                    }
                                }
                                return item
                            }
                        ),
                    },
                },
            }
        })
    },
    resetFilters: (payload?: { isGlobalSearchActive?: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: false,
                globalSearchFilters: {
                    ...(JSON.parse(
                        JSON.stringify(globalSearch)
                    ) as typeof globalSearch),
                    isGlobalSearchActive: payload?.isGlobalSearchActive
                        ? true
                        : false,
                    query: payload?.isGlobalSearchActive
                        ? state.globalSearchFilters.query
                        : '',
                },
            }
        })
    },
    setFilterModified: (payload: { isModified: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isFilterModified: payload.isModified,
            }
        })
    },
})

export default createGlobalSearchSlice
