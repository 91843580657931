import React, { useEffect, useState } from 'react'
import { Dots, DropDownArrow, MinimizeIco } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'
import SubtodoSection from './subtodo-section/subtodo-section'
import { useMutation } from '@apollo/client'
import { UpdateSubtodo } from '../../../services/graphql/entities/todo/todo.mutation'
import { debounce } from 'src/helpers'
import UploadSubtodoRefImage from './upload-subtodo-ref-image'
import SubtodoReferencePic from './subtodo-reference-pic'
import SubtodoColorPicker from './subtodo-color-picker/subtodo-color-picker'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import SubtodoMenuDropdown from './subtodo-menu-dropdown'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIco from 'src/components/svg-icons/drag-ico'
import type { GetTodoQuery } from 'src/generated/graphql'
import { SUBTODO_DESIGN_COLORS } from 'src/helpers/constants'
import { DangerModal } from 'src/components/modals/danger-modal'

function SubtodoCard({
    subtodo,
    refetchTodo,
    isTechPack,
    onFocus,
    height,
    isFocussedView,
    onNextSubtodo,
    onPreviousSubtodo,
    isReadOnly,
    todoId,
    productId,
}: {
    subtodo: NonNullable<GetTodoQuery['todos_by_pk']>['sub_todos'][number]
    refetchTodo: () => void
    isTechPack: boolean
    onFocus: () => void
    height?: string
    isFocussedView?: boolean
    onNextSubtodo?: () => void
    onPreviousSubtodo?: () => void
    isReadOnly?: boolean
    todoId: number
    productId?: number
}) {
    const [heading, setHeading] = useState(subtodo.heading)
    const [showDropdownMenu, setShowDropdownMenu] = useState(false)
    const [referencePicUploadStatus, setReferencePicUploadStatus] = useState({
        isUploading: false,
    })
    const [showDeleteSubtodoModal, setShowDeleteSubtodoModal] = useState(false)
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [updateSubtodo] = useMutation(UpdateSubtodo)
    const [showExpandedView, setShowExpandedView] = useState(true)
    const [assignReferenceToSection, setAssignReferenceToSection] = useState<
        number | null
    >(null)

    useEffect(() => {
        setHeading(subtodo.heading)
    }, [subtodo.id])

    function updateSubtodoHandler({
        payload,
        subtodoId,
    }: {
        payload: any
        subtodoId: number
    }) {
        updateSubtodo({
            variables: {
                id: subtodoId,
                payload,
            },
            onCompleted: () => {
                refetchTodo()
            },
        })
    }

    const debouncedUpdateTodoHandler = debounce(
        updateSubtodoHandler,
        1500,
        setTimeOutRef,
        timeOutRef
    )

    const handleSubtodoHeadingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setHeading(e.target.value)
        debouncedUpdateTodoHandler({
            payload: { heading: e.target.value },
            subtodoId: subtodo.id,
        })
    }

    const referencePic = subtodo.files?.[0]?.file
    const referencePoints = subtodo?.sub_todo_sections
        ?.map((section) => {
            return {
                sectionId: section.id,
                reference_point: section?.reference_points?.[0],
            }
        })
        .filter((point) => !!point.reference_point) as any

    const sortable = useSortable({
        id: subtodo.id,
    })

    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = sortable

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    useEffect(() => {
        if (showExpandedView === false) {
            setShowExpandedView(true)
        }
    }, [isDragging])

    return (
        <Box
            height={
                height ? height : showExpandedView ? '416px' : 'max-content'
            }
            borderRadius="4px"
            border="1px solid"
            borderColor={isDragging ? 'black' : '#B8B8B8'}
            className="subtodo-card"
            display="grid"
            gridTemplateRows="40px 1fr"
            ref={setNodeRef}
            style={style}
        >
            <Box
                bg={subtodo.design_color || SUBTODO_DESIGN_COLORS[0]}
                p="8px"
                pl="16px"
                display="flex"
                alignItems="center"
                style={{ borderRadius: '4px 4px 0px 0px' }}
                gridGap="8px"
            >
                {isFocussedView && (
                    <Button
                        onClick={onPreviousSubtodo}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="primary"
                        position="relative"
                        width="max-content"
                    >
                        <Text my="0px" fontSize={'12px'}>
                            Previous
                        </Text>
                    </Button>
                )}
                {!isFocussedView && !isReadOnly && (
                    <Button
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        p={'0px'}
                        style={{ cursor: 'grab' }}
                        {...attributes}
                        {...listeners}
                    >
                        <DragIco />
                    </Button>
                )}
                <Input
                    bg="transparent"
                    value={heading}
                    onChange={handleSubtodoHeadingChange}
                    placeholder="Add Requirement Name"
                    border="none"
                    width="100%"
                    readOnly={isReadOnly}
                />
                {!isReadOnly && (
                    <>
                        <SubtodoColorPicker
                            activeColor={subtodo.design_color}
                            updateDesignColor={(color) =>
                                updateSubtodoHandler({
                                    subtodoId: subtodo.id,
                                    payload: { design_color: color },
                                })
                            }
                        />
                        <Box display="flex" alignItems="center" gridGap="8px">
                            <Button
                                onClick={onFocus}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="#027AC3"
                                position="relative"
                                width="max-content"
                            >
                                {isFocussedView ? (
                                    <MinimizeIco />
                                ) : (
                                    <FullScreenIco />
                                )}
                            </Button>
                            {!isFocussedView ? (
                                <>
                                    <Box position="relative">
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setShowDropdownMenu(
                                                    !showDropdownMenu
                                                )
                                            }}
                                            bg="transparent"
                                            border="none"
                                            fontStyle="Rubik"
                                            fontSize="12px"
                                            color="#027AC3"
                                            position="relative"
                                            width="max-content"
                                            id="subtodo-menu-button"
                                        >
                                            <Dots active={false} vertical />
                                        </Button>
                                        {showDropdownMenu && (
                                            <SubtodoMenuDropdown
                                                active={showDropdownMenu}
                                                setIsDropdownActive={
                                                    setShowDropdownMenu
                                                }
                                                onDelete={() => {
                                                    setShowDeleteSubtodoModal(
                                                        true
                                                    )
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setShowExpandedView(
                                                !showExpandedView
                                            )
                                        }}
                                        bg="transparent"
                                        border="none"
                                        fontStyle="Rubik"
                                        fontSize="12px"
                                        color="#027AC3"
                                        position="relative"
                                        width="max-content"
                                        id="subtodo-menu-button"
                                    >
                                        <DropDownArrow
                                            rotate={showExpandedView}
                                        />
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    onClick={onNextSubtodo}
                                    bg="transparent"
                                    border="none"
                                    fontStyle="Rubik"
                                    fontSize="12px"
                                    color="primary"
                                    position="relative"
                                    width="max-content"
                                >
                                    <Text my="0px" fontSize={'12px'}>
                                        Next
                                    </Text>
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Box>
            {showExpandedView && (
                <Box
                    display="grid"
                    gridTemplateColumns={
                        isTechPack
                            ? isFocussedView
                                ? '30% 1fr'
                                : '156px 1fr'
                            : '1fr'
                    }
                    style={{ borderRadius: '0px 0px 4px 4px' }}
                    overflow={'hidden'}
                >
                    {isTechPack ? (
                        referencePic ? (
                            <SubtodoReferencePic
                                pic={referencePic}
                                picId={subtodo.files[0].id}
                                subtodoId={subtodo.id}
                                refetchTodo={refetchTodo}
                                referencePoints={referencePoints}
                                assignReferenceToSection={
                                    assignReferenceToSection
                                }
                                setAssignReferenceToSection={
                                    setAssignReferenceToSection
                                }
                                isFocussedView={isFocussedView}
                                referencePicUploadStatus={
                                    referencePicUploadStatus
                                }
                                setReferencePicUploadStatus={
                                    setReferencePicUploadStatus
                                }
                            />
                        ) : (
                            <UploadSubtodoRefImage
                                subtodoId={subtodo.id}
                                refetchTodo={refetchTodo}
                                isReadOnly={isReadOnly}
                                referencePicUploadStatus={
                                    referencePicUploadStatus
                                }
                                setReferencePicUploadStatus={
                                    setReferencePicUploadStatus
                                }
                            />
                        )
                    ) : null}
                    <Box
                        py="8px"
                        px="16px"
                        bg="#F8FAFB"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        gridGap="16px"
                        overflow={'auto'}
                    >
                        {subtodo.sub_todo_sections.map((section, index) => {
                            return (
                                <SubtodoSection
                                    key={section.id}
                                    section={section}
                                    refetchTodo={refetchTodo}
                                    subtodoId={subtodo.id}
                                    isLastSection={
                                        index ===
                                        subtodo.sub_todo_sections.length - 1
                                    }
                                    isDeletable={index !== 0}
                                    isReadOnly={isReadOnly}
                                    todoId={todoId}
                                    productId={productId}
                                    referencePointIndex={referencePoints.findIndex(
                                        (point: any) =>
                                            point.sectionId === section.id
                                    )}
                                    assignReferenceToSection={
                                        assignReferenceToSection
                                    }
                                    onAssignReferencePointToSection={() => {
                                        setAssignReferenceToSection(section.id)
                                    }}
                                    isTechPack={isTechPack}
                                    isFocussedView={isFocussedView}
                                />
                            )
                        })}
                    </Box>
                    {showDeleteSubtodoModal && (
                        <DangerModal
                            onRemove={() => {
                                updateSubtodoHandler({
                                    payload: {
                                        deleted_at: new Date().toISOString(),
                                    },
                                    subtodoId: subtodo.id,
                                })
                            }}
                            onClose={() => setShowDeleteSubtodoModal(false)}
                            isModal={showDeleteSubtodoModal}
                            heading={`Are you sure you want to delete section ${
                                subtodo?.heading ? `"${subtodo.heading}"` : ''
                            }?`}
                            subHeading={
                                'Deleting this section will permanently delete all the details within it.'
                            }
                            title={'Delete Section'}
                            primaryButtonText="Delete"
                            secondaryButtonText="Cancel"
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}

export default SubtodoCard
