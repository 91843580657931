import { type Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export function CompareWithDropdown({
    active,
    dropdownItems,
    setIsDropdownActive,
    setSelectedCompareEntity,
    selectedCompareEntityId,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    dropdownItems: Array<{ name: string; id: number }>
    setSelectedCompareEntity: Dispatch<
        React.SetStateAction<{
            id: number
            name: string
        } | null>
    >
    selectedCompareEntityId: number | undefined
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['todo-dropdown-button']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="32px"
            left="0px"
            width="183px"
        >
            <Box ref={wrapperRef} width="100%">
                {dropdownItems.map((item) => (
                    <DropdownButtonWrapper
                        value={item.name}
                        width="100%"
                        key={item.id}
                    >
                        <Button
                            height="100%"
                            width="100%"
                            textAlign="left"
                            fontFamily="Rubik"
                            color={
                                selectedCompareEntityId === item.id
                                    ? 'textSecondary'
                                    : 'primary'
                            }
                            bg="transparent"
                            border="none"
                            className="text-overflow text-ellipsis"
                            fontSize="14px"
                            onClick={(e) => {
                                e.stopPropagation()
                                setSelectedCompareEntity(item)
                                setIsDropdownActive(false)
                            }}
                        >
                            {item.name}
                        </Button>
                    </DropdownButtonWrapper>
                ))}
            </Box>
        </Dropdown>
    )
}
