import create from 'zustand'
import createSliderSlice from '../slice/create-slider-slice'
import createModalSlice from '../slice/modal-slice'
import createPeopleSlice from '../slice/people-slice'
import createPredictiveOutputSlice from '../slice/predictive-output-slice'
import createLoadingSlice from '../slice/loading-slice'
import createProfileSlice from '../slice/profile-slice'
import { createProductFormSlice } from '../slice/create-product-form-slice'
import createCollectionSlice from '../slice/collection-slice'
import createProductSlice from '../slice/product-slice'
import createCommentSlice from '../slice/comments-slice'
import createNotificationSlice from '../slice/notification.slice'
import create3dRendererSlice from '../slice/3d-renderer-slice'
import { createProductDrawerSlice } from '../slice/product-drawer-slice'
import createGlobalSearchSlice from '../slice/global-search-slice'

const useStore = create((set: Function) => ({
    ...createSliderSlice(set),
    ...createModalSlice(set),
    ...createPeopleSlice(set),
    ...createPredictiveOutputSlice(set),
    ...createLoadingSlice(set),
    ...createProfileSlice(set),
    ...createProductFormSlice(set),
    ...createCollectionSlice(set),
    ...createProductSlice(set),
    ...createCommentSlice(set),
    ...createNotificationSlice(set),
    ...createProductDrawerSlice(set),
    ...create3dRendererSlice(set),
    ...createGlobalSearchSlice(set),
}))

export default useStore
