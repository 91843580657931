import { useState } from 'react'
import { Modal, ProgressBar, ProgressIcons, StackedImage } from 'src/components'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { Box, Table, Tbody, Td, Th, Thead, Tr } from 'src/components/utility'
import { debugLog } from 'src/helpers'

export const ProductCompletionTeam = ({
    setModal,
    durationDropdown,
    durationList,
}: {
    setModal: (x: boolean) => void
    durationDropdown: string[]
    durationList: DurationFilterList
}) => {
    const [sortValue, setSortFilter] = useState<string>('Name')
    const [filterValue, setTodoFilter] = useState<string>('All')

    let teamList = [1, 2, 3]?.map((item: any) => {
        return {
            name: 'Vishnu',
            src: '',
        }
    })

    const teams = [1, 2, 3, 4, 5]
    return (
        <Modal
            isModal={true}
            onClick={() => {}}
            onClose={() => setModal(false)}
            primaryButtonTitle="Add"
            secondaryButtonTitle="Cancel"
            title="On-time Product Completion (Team)"
            disableActions={true}
        >
            <Box p="24px">
                <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    gridColumnGap="24px"
                    position="relative"
                    mb={'12px'}
                >
                    <CustomDropdown
                        label="Filter by:"
                        selectedText={durationList.thisMonth}
                        items={durationDropdown}
                        onItemClicked={setTodoFilter}
                    />
                    <CustomDropdown
                        label="Sort:"
                        selectedText="On-Time"
                        items={['Delay', 'On-Time']}
                        onItemClicked={setSortFilter}
                    />
                </Box>
                <Box
                    bg="white"
                    border="solid"
                    borderWidth={1}
                    borderColor="gray250"
                    borderRadius="8px"
                    justifyContent="space-around"
                    width="100%"
                >
                    <Table width="100%">
                        <Thead fontSize={{ md: '12px', lg: '14px' }}>
                            <Tr
                                color="textSecondaryDarkBlue"
                                fontFamily="Rubik"
                            >
                                <Th fontWeight={400} textAlign="left">
                                    Team
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    Collaborators
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    Products
                                </Th>
                                <Th fontWeight={400} textAlign="start">
                                    On-Time Products (%)
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {teams.map((index) => {
                                return (
                                    <Tr
                                        key={index}
                                        color="textSecondaryDarkBlue"
                                        fontFamily="Rubik"
                                        height="56px"
                                        border={'solid'}
                                        borderWidth={1}
                                        borderColor="gray350"
                                        borderLeft="none"
                                        borderRight="none"
                                        fontSize={{ md: '14px', lg: '16px' }}
                                        onClick={() => {}}
                                    >
                                        <Td color="primary" textAlign="left">
                                            Hats Team 1
                                        </Td>

                                        <Td color="primary">
                                            <Box pr={'12px'}>
                                                <StackedImage
                                                    size={'24px'}
                                                    spacing={12}
                                                    width="max-content"
                                                    fontSize="12px"
                                                    list={teamList}
                                                />
                                            </Box>
                                        </Td>
                                        <Td color="primary" textAlign="center">
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                width="100%"
                                            >
                                                <ProgressIcons
                                                    completed={6}
                                                    remaining={3}
                                                />
                                            </Box>
                                        </Td>

                                        <Td color="primary">
                                            <ProgressBar
                                                percentage={isNaN(55) ? 0 : 65}
                                                data={{}}
                                                onDeleteClick={() =>
                                                    debugLog('hello')
                                                }
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Modal>
    )
}
