import React, { useRef } from 'react'
import { Loader } from 'src/components/loader/loader'
import UploadIco from 'src/components/svg-icons/upload-ico'
import { Box, Input } from 'src/components/utility'
import { uuid } from 'src/helpers'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'

function UploadSubtodoRefImage({
    subtodoId,
    refetchTodo,
    isReadOnly,
    referencePicUploadStatus,
    setReferencePicUploadStatus,
}: {
    subtodoId: number
    refetchTodo: () => void
    isReadOnly?: boolean
    referencePicUploadStatus: {
        isUploading: boolean
    }
    setReferencePicUploadStatus: (status: { isUploading: boolean }) => void
}) {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const { isUploading, onFileUpload } = useUploadFile(
        String(subtodoId),
        'sub_todos'
    )
    const { assignFilesToSection } = useAssignFile(() => {
        refetchTodo()
    })

    function inputClickHandler() {
        if (!isReadOnly && inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }

    async function uploadRefImageHandler(file: File) {
        setReferencePicUploadStatus({ isUploading: true })
        const arr = await onFileUpload(
            {
                files: [
                    {
                        blob: file,
                        id: uuid(),
                        name: file.name,
                        path: getLocalImageUrl(file) || '',
                    },
                ],
            },
            'reference_pic',
            () => {}
        )
        const fileId = arr?.resolvedArr[0].id || '0'
        assignFilesToSection({
            assignedAs: 'reference_pic',
            entityId: String(subtodoId),
            fileId: [fileId],
            entityName: 'sub_todos',
        })
        setReferencePicUploadStatus({ isUploading: false })
    }

    function onFileDragged(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        if (isReadOnly) return
        const data = event.dataTransfer.getData('application/json')
        if (data) {
            uploadRefImageHandler(new File([JSON.parse(data)], ''))
        } else {
            const filesToUpload = event.dataTransfer.files
            uploadRefImageHandler(filesToUpload[0])
        }
    }

    return (
        <Box
            bg="#EBEBEB"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gridGap="12px"
            onClick={inputClickHandler}
            className={!isReadOnly ? 'cursor-pointer' : ''}
            onDragStart={(e) => {
                e.preventDefault()
            }}
            onDragOver={(event) => {
                event.preventDefault()
            }}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
                onFileDragged(event)
            }}
        >
            {referencePicUploadStatus.isUploading ? (
                <Loader outline="black" size="42px" />
            ) : (
                <UploadIco />
            )}
            <Input
                autoComplete="false"
                multiple
                type="file"
                onChange={(e) =>
                    e.target.files &&
                    e.target.files.length > 0 &&
                    uploadRefImageHandler(e.target.files[0])
                }
                onClick={(e: any) => {
                    e.target.value = null
                }}
                accept="image/*"
                display="none"
                ref={inputRef}
                bg="none"
            />
        </Box>
    )
}

export default UploadSubtodoRefImage
