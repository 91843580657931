import * as React from 'react'
import { Box, Input, Text } from 'src/components/utility'

export interface IRadioInputProps {
    isChecked: boolean
    onChange: (isChecked: boolean) => void
    lable?: string
}

export default function RadioInput({
    isChecked,
    onChange,
    lable = '',
}: IRadioInputProps) {
    const inputRef = React.useRef<HTMLInputElement | null>(null)
    return (
        <Box display="flex">
            <Box position="relative" display="flex" alignItems="center">
                <Input
                    ref={inputRef}
                    style={{
                        visibility: 'hidden',
                    }}
                    bg="transparent"
                    checked={isChecked}
                    onChange={(e) => {
                        onChange(!isChecked)
                    }}
                    name="radio"
                    type="radio"
                />
                <Box
                    border="solid"
                    borderWidth={1}
                    borderRadius="50%"
                    borderColor={
                        isChecked ? 'primary' : 'textSecondaryDarkBlue'
                    }
                    onClick={() => {
                        if (inputRef && inputRef.current) {
                            inputRef.current.click()
                        }
                    }}
                    width="13.5px"
                    height="13.5px"
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        width="7.5px"
                        height="7.5px"
                        borderRadius="50%"
                        bg={isChecked ? 'primary' : 'transparent'}
                    ></Box>
                </Box>
                <Text
                    my="0px"
                    fontSize="14px"
                    color={isChecked ? 'primary' : 'textSecondaryDarkBlue'}
                    className="custom-radio"
                    ml="0px"
                >
                    {lable}
                </Text>
            </Box>
        </Box>
    )
}
