import * as React from 'react'
import { VCalendar } from 'src/components'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { DropDownArrow } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { getFormattedDate } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import useStore from 'src/store/hooks/useStore'

export interface IDateFilterProps {}

export default function DateFilter(props: IDateFilterProps) {
    const { globalSearchFilters, setDateRange, isFilterActive } = useStore()
    const [isCalendarVisible, setIsCalendarVisible] = React.useState(false)
    const calendarRef = useClickedOutside(() => setIsCalendarVisible(false))
    const { getGlobalSearchData } = useGlobalSearch()
    React.useEffect(() => {
        console.log(globalSearchFilters.dateRange)
        if (!isFilterActive) {
            getGlobalSearchData({
                query: globalSearchFilters.query,
                filters: {
                    collaborators: globalSearchFilters.collaborators
                        .filter((collaborator) => Number(collaborator.id) !== 0)
                        .map((collaborator) => String(collaborator.id)),
                    tags: globalSearchFilters.tags
                        .filter((tag) => Number(tag.id) !== 0)
                        .map((tag) => tag.id),
                    file_types: globalSearchFilters.file_types,
                    status: globalSearchFilters.status
                        .filter((status) => status.isActive)
                        .map((status) => status.id),
                    from: globalSearchFilters.dateRange || undefined,
                    to: globalSearchFilters.dateRange || undefined,
                },
            })
        }
    }, [globalSearchFilters.dateRange])
    return (
        <Box
            className="cursor-pointer"
            border="solid"
            borderWidth={1}
            py="8px"
            ref={calendarRef}
            px="16px"
            width="max-content"
            borderRadius="4px"
            borderColor="secondaryLighterBlue"
            color={globalSearchFilters.dateRange ? 'white' : 'primary'}
            bg={globalSearchFilters.dateRange ? 'primary' : 'white'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap="8px"
            position="relative"
            onClick={() => setIsCalendarVisible(!isCalendarVisible)}
        >
            <Box
                display="flex"
                gridColumnGap={'4px'}
                alignItems="center"
                justifyContent="center"
            >
                <Text my="0px">Date Range:</Text>
                <Text my="0px">
                    {globalSearchFilters.dateRange
                        ? getFormattedDate(
                              globalSearchFilters.dateRange,
                              'FORMATTED'
                          )
                        : 'Due Date'}
                </Text>
            </Box>
            <DropDownArrow
                color={globalSearchFilters.dateRange ? 'white' : '#022143'}
            />
            <VCalendar
                date={globalSearchFilters.dateRange}
                disableCallback={() => setIsCalendarVisible(false)}
                setDate={(date) =>
                    setDateRange({
                        dateRange: date,
                    })
                }
                isCalendarVisible={isCalendarVisible}
                left="0px"
                top="38px"
                minDate={
                    new Date(
                        new Date('1990-01-01T00:00:00.000Z').setUTCHours(
                            23,
                            59,
                            59,
                            999
                        )
                    )
                }
            />
        </Box>
    )
}
