import { Tab, Tabs } from '@mui/material'
import * as React from 'react'

export interface IGlobalSearchSliderProps {
    currentTab: string
    setCurrentTab: (tab: string) => void
    types: (
        | {
              name: string
              count: number
          }
        | undefined
    )[]
    showTeams?: boolean
}

const KeyNames = {
    library_folders: 'Library Folders',
    teams: 'Teams',
    collections: 'Collections',
    products: 'Products',
    todos: "To-do's",
    library_files: 'Library',
    collaborators: 'Collaborators',
    product_variants: 'Product Variant',
}

export default function GlobalSearchSlider(props: IGlobalSearchSliderProps) {
    const { currentTab, setCurrentTab, types, showTeams = false } = props
    console.log(types)
    return (
        <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons={false}
            onChange={(_event: any, newValue: any) => {
                setCurrentTab(newValue)
            }}
            aria-label="collection-tabs"
            classes={{
                root: 'collection-slider-root',
            }}
        >
            {types?.map((type) => {
                if (!type) return null
                return (
                    <Tab
                        label={`${
                            KeyNames[type.name as keyof typeof KeyNames]
                        } (${type?.count})`}
                        value={type?.name}
                    />
                )
            })}
        </Tabs>
    )
}
