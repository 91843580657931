import { gql } from '@apollo/client'

const folderFragment = gql`
    fragment FolderFragment on folders {
        category_id
        created_at
        created_by
        deleted_at
        id
        name
        organisation_id
        parent_id
        size
        parent_folder: folder {
            id
            name
        }
        path
        thumbnail
        library_favourites(limit: 1) {
            folder_id
            id
        }
        noOfFolders: folders_aggregate {
            aggregate {
                count
            }
        }
        noOfFiles: library_files_aggregate {
            aggregate {
                count
            }
        }
        user {
            email
            id
            name
            other_data
        }
    }
`

const filesFragment = gql`
    fragment FilesFragment on library_files {
        added_by
        address
        category_id
        created_at
        deleted_at
        folder_id
        gid
        id
        is_authenticated
        is_public
        name
        organisation_id
        path
        type
        updated_at
        thumbnail
        company
        size
        other_data
        category {
            name
            id
        }
        color
        tags
        library_favourites(limit: 1) {
            library_file_id
            id
        }
        user: creator {
            email
            id
            name
            other_data
        }
    }
`

export const FetchLibraryCategories = gql`
    query fetchLibraryCategories($whereObj: category_bool_exp = {}) {
        category(order_by: { id: desc }, where: $whereObj) {
            id
            name
            organisation_id
            created_by
        }
    }
`

export const FetchLibraryCategoryById = gql`
    query fetchLibraryCategoryById($id: bigint!) {
        category(order_by: { id: asc }, where: { id: { _eq: $id } }) {
            id
            name
            organisation_id
            created_by
        }
    }
`

export const LibraryFavourites = gql`
    ${folderFragment}
    ${filesFragment}
    query libraryFavourites($searchStr: String = "%%") {
        library_favourites(
            where: {
                _or: [
                    {
                        folder_id: { _is_null: false }
                        folder: { name: { _ilike: $searchStr } }
                    }
                    {
                        library_file_id: { _is_null: false }
                        library_file: { name: { _ilike: $searchStr } }
                    }
                ]
            }
        ) {
            id
            user_id
            folder_id
            library_file_id
            folder {
                ...FolderFragment
            }
            library_file {
                ...FilesFragment
            }
        }
    }
`

export const FetchFoldersByCategoryId = gql`
    query FetchFoldersByCategoryId($categoryId: bigint!) {
        folders(where: { category_id: { _eq: $categoryId } }) {
            id
            name
        }
    }
`

export const FetchFoldersWithFilters = gql`
    query FetchFoldersWithFilters($whereObj: folders_bool_exp = {}) {
        folders(where: $whereObj) {
            id
            name
        }
    }
`

export const FetchFoldersByCategory = gql`
    ${folderFragment}
    query fetchFoldersByCategory(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: folders_bool_exp!
        $limit: Int! = 20
    ) {
        folders(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FolderFragment
        }
        folders_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchAllNestedFoldersByCategory = gql`
    ${folderFragment}
    query FetchAllNestedFoldersByCategory(
        $name: order_by
        $created_at: order_by = desc
        $category_id: bigint
        $searchStr: String = "%%"
    ) {
        folders(
            order_by: { created_at: $created_at, name: $name }
            where: {
                name: { _ilike: $searchStr }
                category_id: { _eq: $category_id }
                deleted_at: { _is_null: true }
            }
        ) {
            ...FolderFragment
        }
    }
`

export const FetchAllFolders = gql`
    ${folderFragment}
    query fetchAllFolders(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: folders_bool_exp!
        $limit: Int! = 20
    ) {
        folders(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FolderFragment
        }
        folders_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchFolderById = gql`
    ${folderFragment}
    query fetchFolderById($id: bigint!) {
        folders(where: { id: { _eq: $id }, deleted_at: { _is_null: true } }) {
            ...FolderFragment
        }
    }
`

export const FetchSubFoldersById = gql`
    ${folderFragment}
    query fetchSubFoldersById(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: folders_bool_exp!
        $limit: Int = 20
    ) {
        folders(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FolderFragment
        }
        folders_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchFilesByCategoryId = gql`
    ${filesFragment}
    query fetchFilesByCategoryId(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: library_files_bool_exp!
        $limit: Int = 20
    ) {
        library_files(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FilesFragment
        }
        library_files_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchFilesByFolderId = gql`
    ${filesFragment}
    query fetchFilesByFolderId(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: library_files_bool_exp!
        $limit: Int = 19
    ) {
        library_files(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FilesFragment
        }
        library_files_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchCreators = gql`
    query fetchCreators(
        $whereObj: folders_bool_exp!
        $whereFileObj: library_files_bool_exp!
    ) {
        folders(distinct_on: created_by, where: $whereObj) {
            user {
                email
                id
                name
                other_data
            }
        }
        library_files(distinct_on: added_by, where: $whereFileObj) {
            added_by
            user: creator {
                email
                name
                id
                other_data
            }
        }
    }
`

export const FetchColors = gql`
    query FetchColors($whereObj: library_files_bool_exp!) {
        library_files(distinct_on: color, where: $whereObj) {
            color
            id
            name
        }
    }
`

export const FetchCompanies = gql`
    query FetchCompanies($whereObj: library_files_bool_exp!) {
        library_files(distinct_on: company, where: $whereObj) {
            color
            id
            name
            company
        }
    }
`

export const FetchFileById = gql`
    query fetchFileById($id: bigint!) {
        library_files(where: { id: { _eq: $id } }) {
            added_by
            address
            brand
            category_id
            color
            company
            deleted_at
            folder_id
            gid
            id
            name
            path
            tags
            type
            created_at
            size
            thumbnail
            category {
                name
                id
            }
            user: creator {
                email
                id
                name
                other_data
            }
            is_authenticated
            is_public
            organisation_id
            other_data
        }
    }
`

export const FetchCategoriesBySearchStr = gql`
    query fetchCategoriesBySearchStr(
        $searchStr: String = "%%"
        $limit: Int = 100
    ) {
        category(
            where: {
                name: { _ilike: $searchStr }
                deleted_at: { _is_null: true }
            }
            limit: $limit
        ) {
            id
            name
            organisation_id
            updated_at
            created_at
            created_by
        }
    }
`

export const FetchAllCategoryFiles = gql`
    ${filesFragment}
    query fetchAllCategoryFiles(
        $name: order_by
        $created_at: order_by = desc
        $whereObj: library_files_bool_exp!
        $limit: Int = 15
    ) {
        library_files(
            order_by: { created_at: $created_at, name: $name }
            where: $whereObj
            limit: $limit
            offset: 0
        ) {
            ...FilesFragment
        }
        library_files_aggregate(where: $whereObj) {
            aggregate {
                count
            }
        }
    }
`

export const FetchFolderByName = gql`
    query fetchFolderByName($whereObj: folders_bool_exp) {
        folders(where: $whereObj) {
            parent_id
            id
            name
        }
    }
`

export const FetchOnlyFilesTable = gql`
    query fetchOnlyFilesTable($id: bigint!) {
        files(where: { id: { _eq: $id } }) {
            name
            size
            created_at
            type
            other_data
            deleted_at
            user {
                id
                name
            }
        }
    }
`
