import React, { useEffect } from 'react'
import { Box } from 'src/components/utility'
import { useTodoSectionContext } from '../todo/todo-section/todo-section.context'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import FilePreviewSection from '../todo/file-preview-section/file-preview-section'

function FilePreviewModal({
    isOpen,
    onClose,
    productId,
}: {
    isOpen: boolean
    onClose: () => void
    productId: number
}) {
    const { activeFileId } = useTodoSectionContext()
    // scroll to active file
    useEffect(() => {
        if (activeFileId) {
            scrollToSection(`file_display_${activeFileId}_preview_modal`)
        }
    }, [])

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            display={isOpen ? 'flex' : 'none'}
            bg="#02214370"
            justifyContent="center"
            alignItems="center"
            zIndex={10}
            className={''}
            borderRadius={'4px'}
        >
            <FilePreviewSection
                isSplitView={false}
                onClose={onClose}
                productId={productId}
                enableKeyboardNav={true}
            />
        </Box>
    )
}

export default FilePreviewModal
