export function getDistinctValues(inputArray: string[]): string[] {
    const distinctValuesSet = new Set(inputArray)
    return Array.from(distinctValuesSet)
}

export function getDistinctObjects(arr: Array<any>): Array<any> {
    const seenIds = new Set<number>()
    const result: Array<any> = []

    for (const obj of arr) {
        if (!seenIds.has(obj.id)) {
            seenIds.add(obj.id)
            result.push(obj)
        }
    }

    return result
}
