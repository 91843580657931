import { statuses } from './enums'

export const isEntityDueDateDelayed = (
    dueDate: string | null,
    status: keyof typeof statuses
) => {
    if (!dueDate || !status) {
        return false
    }
    if (status === statuses.complete) {
        return false
    }
    const today = new Date()
    const due = new Date(dueDate)

    // Reset hours, minutes, seconds, and milliseconds to 0
    today.setHours(0, 0, 0, 0)
    due.setHours(0, 0, 0, 0)

    return today > due
}
