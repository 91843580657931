export const Grid = ({ active }: { active?: boolean }) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 3.8a.7.7 0 0 0-.7.7v6a.7.7 0 0 0 .7.7h6a.7.7 0 0 0 .7-.7v-6a.7.7 0 0 0-.7-.7h-6Zm.7 6V5.2h4.6v4.6H5.2Zm-.7 3a.7.7 0 0 0-.7.7v6a.7.7 0 0 0 .7.7h6a.7.7 0 0 0 .7-.7v-6a.7.7 0 0 0-.7-.7h-6Zm.7 6v-4.6h4.6v4.6H5.2Zm7.6-14.3a.7.7 0 0 1 .7-.7h6a.7.7 0 0 1 .7.7v6a.7.7 0 0 1-.7.7h-6a.7.7 0 0 1-.7-.7v-6Zm1.4.7v4.6h4.6V5.2h-4.6Zm-.7 7.6a.7.7 0 0 0-.7.7v6a.7.7 0 0 0 .7.7h6a.7.7 0 0 0 .7-.7v-6a.7.7 0 0 0-.7-.7h-6Zm.7 6v-4.6h4.6v4.6h-4.6Z"
            fill={!active ? '#778CA2' : '#022143'}
        />
    </svg>
)
