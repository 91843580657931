export function TodoIco({
    size = 'sm',
    color = '#778CA2',
}: {
    size?: 'sm' | 'xs'
    color?: string
}) {
    if (size === 'sm') {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.26316 1.33333V5.77778H14.7368V1.33333H1.26316ZM1.26316 10.2222V14.6667H14.7368V10.2222H1.26316ZM0.842105 0H15.1579C15.6211 0 16 0.4 16 0.888889V6.22222C16 6.71111 15.6211 7.11111 15.1579 7.11111H0.842105C0.378947 7.11111 0 6.71111 0 6.22222V0.888889C0 0.4 0.378947 0 0.842105 0ZM0.842105 8.88889H15.1579C15.6211 8.88889 16 9.28889 16 9.77778V15.1111C16 15.6 15.6211 16 15.1579 16H0.842105C0.378947 16 0 15.6 0 15.1111V9.77778C0 9.28889 0.378947 8.88889 0.842105 8.88889Z"
                    fill={color}
                />
            </svg>
        )
    } else if (size === 'xs') {
        return (
            <svg
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.710526 1.25V3.75H8.28947V1.25H0.710526ZM0.710526 6.25V8.75H8.28947V6.25H0.710526ZM0.473684 0.5H8.52632C8.78684 0.5 9 0.725 9 1V4C9 4.275 8.78684 4.5 8.52632 4.5H0.473684C0.213158 4.5 0 4.275 0 4V1C0 0.725 0.213158 0.5 0.473684 0.5ZM0.473684 5.5H8.52632C8.78684 5.5 9 5.725 9 6V9C9 9.275 8.78684 9.5 8.52632 9.5H0.473684C0.213158 9.5 0 9.275 0 9V6C0 5.725 0.213158 5.5 0.473684 5.5Z"
                    fill={color ? color : 'white'}
                />
            </svg>
        )
    }

    return null
}
