export const Tick = ({
    isDone,
    color,
}: {
    isDone: boolean | '#778CA2'
    color?: string
}) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.924 4.41a.58.58 0 0 0-.82 0l-6.131 6.132-2.078-2.078a.58.58 0 0 0-.819.82l2.19 2.189a1 1 0 0 0 1.414 0l6.244-6.244a.58.58 0 0 0 0-.82Z"
            fill={
                !color
                    ? isDone === '#778CA2'
                        ? '#778CA2'
                        : isDone
                        ? '#39A407'
                        : '#FF2C18'
                    : color
            }
        />
    </svg>
)
