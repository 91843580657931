import * as React from 'react'
import ConfiguratorRenderer from './configurator-renderer'
import ConfiguratorContext from './configurator.context'

export interface IConfiguratorProps {
    configuraturRef: 'fetch' | 'upload'
    file?: {
        url: string
        name: string
    }
}

export function Configurator(props: IConfiguratorProps) {
    return (
        <ConfiguratorContext>
            <ConfiguratorRenderer {...props}></ConfiguratorRenderer>
        </ConfiguratorContext>
    )
}
