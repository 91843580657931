import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { getLocalStorage } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import type { TGqlTodos } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import type { DataAggregate } from 'src/services/graphql/query/@types/collections'
import {
    GetDashboardTodos,
    GetDashboardTodosAggregate,
} from 'src/services/graphql/query/dashboard/dashboard-todos'
import { useCollectionFilter } from './use-collections'

export function useTodos({
    status = 'all',
    sort = 'name',
    myTodos = false,
    collectionIds = [],
}: {
    status:
        | 'all'
        | 'new'
        | 'inProgress'
        | 'delayed'
        | 'inReview'
        | 'requestedForResubmission'
        | 'toBeReviewed'
    myTodos?: boolean
    sort: 'name' | 'due_date' | 'created_at'
    collectionIds?: string[]
}) {
    const userId =
        getLocalStorage('user').length > 0
            ? JSON.parse(getLocalStorage('user') || '{}')?.id
            : null
    const client = useApolloClient()
    let where: any = {}
    if (status === 'all') {
        where = {
            ...where,
            name: {
                _is_null: false,
            },
        }
    } else if (status === 'delayed') {
        where = {
            is_delayed: {
                _eq: true,
            },
        }
    } else if (status === 'toBeReviewed') {
        where = {
            status: {
                _eq: 'inReview',
            },
            product_variant: { collection: { manager_id: { _eq: userId } } },
        }
    } else if (status === 'inReview') {
        where = {
            status: {
                _eq: status,
            },
            // product_variant: { collection: { manager_id: { _neq: userId } } },
        }
    } else {
        where = {
            status: {
                _eq: status,
            },
        }
    }

    const d = {} as any

    if (status === 'toBeReviewed') {
        d['manager_id'] = { _eq: userId }
    } else if (status === 'inReview') {
        d['manager_id'] = { _neq: userId }
    } else {
        d['id'] = { _is_null: false }
    }

    where = {
        ...where,
        product_variant:
            collectionIds.length > 0
                ? {
                      ...where.product_variant,
                      collection_id: { _in: collectionIds },
                      [status === 'inReview' ? '_or' : 'collection']:
                          status === 'inReview'
                              ? [
                                    {
                                        collection: d,
                                    },
                                    {
                                        collection: {
                                            manager_id: { _is_null: true },
                                        },
                                    },
                                ]
                              : d,
                  }
                : {
                      ...where.product_variant,
                      id: { _is_null: false },
                      [status === 'inReview' ? '_or' : 'collection']:
                          status === 'inReview'
                              ? [
                                    {
                                        collection: d,
                                    },
                                    {
                                        collection: {
                                            manager_id: { _is_null: true },
                                        },
                                    },
                                ]
                              : d,
                  },
    }

    const variables = {
        limit: PerPage,
        offset: 0,
        order_by: {
            [sort]: 'asc',
        },
        where: !myTodos
            ? {
                  ...where,
                  assignee: { id: { _is_null: false } },
              }
            : {
                  ...where,
                  assignee: {
                      id:
                          status === 'toBeReviewed'
                              ? { _is_null: false }
                              : { _eq: userId },
                  },
              },
    }
    const { data, refetch, fetchMore, networkStatus, loading } = useQuery<{
        todos: TGqlTodos[]
    }>(GetDashboardTodos, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: (newData) => {
            client.writeQuery({
                query: GetDashboardTodos,
                data: {
                    todos: newData.todos,
                },
                overwrite: true,
            })
        },
        variables,
    })
    const { filters } = useCollectionFilter('todos')
    const [getTodoAggregate, todoAggregate] = useLazyQuery<{
        all: DataAggregate
        new: DataAggregate
        inProgress: DataAggregate
        inReview: DataAggregate
        complete: DataAggregate
        delayed: DataAggregate
        toBeReviewed: DataAggregate
        revisionRequested: DataAggregate
        totalCompleted: DataAggregate
    }>(GetDashboardTodosAggregate, {
        variables: {
            approver_id: userId,
            id:
                filters['todos'] && filters['todos'].length > 0
                    ? { _in: filters['todos'].map((todo) => todo.id) }
                    : { _is_null: false },
        },
    })

    useEffect(() => {
        getTodoAggregate({
            fetchPolicy: 'no-cache',
            variables: {
                assignee_ids: myTodos ? { _eq: userId } : { _is_null: false },
                approver_id: userId,
                id:
                    filters['todos'] && filters['todos'].length > 0
                        ? { _in: filters['todos'].map((todo) => todo.id) }
                        : { _is_null: false },
            },
        })
    }, [])

    function fetchAggregate({
        myTodos = false,
        collectionId = [],
    }: {
        myTodos: boolean
        collectionId?: string[]
    }) {
        getTodoAggregate({
            fetchPolicy: 'no-cache',
            variables: {
                assignee_ids: myTodos ? { _eq: userId } : { _is_null: false },
                approver_id: userId,
                id:
                    collectionId.length > 0
                        ? { _in: collectionId }
                        : { _is_null: false },
            },
        })
    }
    return {
        fetchMore: () =>
            fetchMore({
                variables: {
                    ...variables,
                    offset: data?.todos.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    return { todos: [...prev.todos, ...fetchMoreResult.todos] }
                },
            }),
        refetch,
        refetchAggregate: fetchAggregate,
        networkStatus,
        isLoading: loading,
        new: todoAggregate?.data?.new.aggregate.count || 0,
        all: todoAggregate?.data?.all.aggregate.count || 0,
        delayed: todoAggregate?.data?.delayed.aggregate.count || 0,
        inProgress: todoAggregate?.data?.inProgress.aggregate.count || 0,
        complete: todoAggregate?.data?.complete.aggregate.count || 0,
        inReview: todoAggregate?.data?.inReview.aggregate.count || 0,
        toBeReviewed: todoAggregate?.data?.toBeReviewed.aggregate.count || 0,
        revisionRequested:
            todoAggregate?.data?.revisionRequested.aggregate.count || 0,
        todos: data?.todos || [],
    }
}
