import React, { useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Box, Button } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

function AddFilesDropdownMenu({
    showDropdown,
    setShowDropdown,
    sectionId,
    setShowFileModal,
}: {
    showDropdown: boolean
    setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
    sectionId: number
    setShowFileModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { todoId } = useParams()
    const dropdownBadge = useRef<any>(null)
    const wrapperRef = useClickedOutside(
        () => setShowDropdown(false),
        ['add-files-dropdown-button']
    )

    return (
        <Dropdown
            ref={dropdownBadge}
            top="70px"
            left="-50%"
            active={showDropdown}
        >
            <Box ref={wrapperRef} width={'172px'}>
                <DropdownButtonWrapper
                    px={'0px'}
                    data-dropdown-focus={sectionId}
                    onClick={() => {
                        setShowDropdown(false)
                        navigate(
                            `/library/selectfiles/${sectionId}/sub_todo_sections?parentTodoId=${todoId}`,
                            {
                                state: {
                                    from: `/todo/${todoId}${
                                        searchParams.toString()
                                            ? `?${searchParams.toString()}`
                                            : ''
                                    }`,
                                },
                                replace: true,
                            }
                        )
                    }}
                    width="100%"
                >
                    <Button
                        type="button"
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                        textAlign="left"
                    >
                        Assign from Library
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    px="0px"
                    data-dropdown-focus={sectionId}
                    onClick={() => {
                        setShowFileModal(true)
                        setShowDropdown(false)
                    }}
                    width="100%"
                >
                    <Button
                        type="button"
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                        textAlign="left"
                    >
                        Upload from Computer
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}

export default AddFilesDropdownMenu
