import React from 'react'
import { Dots, SuccessTickSm } from 'src/components/svg-icons'
import { Box, Button, Image, Text } from 'src/components/utility'
import CalendarDropdown from '../dropdowns/calendar'
import StatusDropdown from '../dropdowns/status'
import { CollectionIco } from 'src/components/svg-icons/collection-ico'
import {
    HtmlTooltip,
    StackedImage,
} from 'src/components/stacked-image/stacked-image'
import type { TSearchedProduct } from 'src/components-v2/global-search/@types/search'
import { copyToClipBoard, getImageUrl, uuid } from 'src/helpers'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ProductTodosContainer from './product-todos-container'
import useStore from 'src/store/hooks/useStore'
import { useRecentSearch } from 'src/components-v2/global-search/hooks/useRecentSearch'
import type { TGqlProducts } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import { DangerModal } from 'src/components/modals/danger-modal'
import useUpdateProduct, { useDeleteProduct } from '../hooks/useUpdateProduct'
import UploadProductThumbnailMenuDropdown from '../../upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { toast } from 'react-toastify'
import MeatBallDropdown from '../dropdowns/meatball-dropdown'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { Loader } from 'src/components/loader/loader'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import { ExpandReferencePicModal } from 'src/components-v2/todo/subtodo-card/modals/expand-reference-pic.modal'
import { useDeleteProductVariant } from '../hooks/useDeleteProductVariant'
import { getLibraryFileUrl, getLocalImageUrl } from 'src/helpers/get-image-url'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { VariantBadge } from 'src/components-v2/badges/variant-badge'
import { ProductBadge } from 'src/components-v2/badges/product-badge'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import { useApolloClient } from '@apollo/client'
import ProductTodosPopover from './product-todos-popover/product-todos-popover'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'

interface IProductCardProps {
    entity: TSearchedProduct | TGqlProducts
    updateDate: (date: Date) => void
    updateStatus: (status: string) => void
    refetch?: () => void
    disableStatusUpdateFetch?: boolean
    tabView?: boolean
    dashboardView?: boolean
    isVariant?: boolean
    disableBorders?: boolean
}

const ProductCard = (props: IProductCardProps) => {
    const {
        entity,
        refetch,
        tabView = false,
        dashboardView = false,
        isVariant = false,
        disableBorders = false,
    } = props
    const client = useApolloClient()
    const [showProductTodos, setShowProductTodos] = useState(false)
    const [showThumbnailExpandView, setShowThumbnailExpandView] =
        useState(false)
    const {
        setGlobalSearchActive,
        removeProduct,
        clearDrawerTabs,
        updateGlobalSearchResults,
    } = useStore()
    const { isUploading, onFileUpload } = useUploadFile(
        String(entity?.id),
        'product_variants'
    )
    const { assignFilesToSection } = useAssignFile()
    const [searchParams] = useSearchParams()
    const { globalSearchFilters } = useStore()
    const { addRecentSearch } = useRecentSearch()
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const { deleteProduct } = useDeleteProduct()
    const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)
    const [showThumbnailDropdown, setShowThumbnailDropdown] = useState(false)
    const {
        addValuesToExpanded,
        getExpandedStates,
        getLastOpenedTab,
        setLastOpened,
        removeLastOpenedTab,
    } = useCardExpandedState()
    const { updateProductVariantDueDate, updateProductVariantStatus } =
        useUpdateProduct()
    const { deleteProductVariant } = useDeleteProductVariant()
    const [showPopup, setShowPopup] = useState(false)

    const timerRef = useRef<NodeJS.Timeout | null>(null)
    let timeoutId: NodeJS.Timeout | null = null

    const handleMouseEnter = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
        }
        timerRef.current = setTimeout(() => {
            setShowPopup(true)
        }, 1000) // 3 seconds
    }

    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            setShowPopup(false)
        }, 300)
    }

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [])

    const navigate = useNavigate()

    async function uploadImageHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        productId: number
    ) {
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)
            const file = e.target.files[0]
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: file,
                            id: uuid(),
                            name: file.name,
                            path: getLocalImageUrl(file) || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            const fileId = data?.resolvedArr[0].id
            if (fileId) {
                await assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityId: String(productId),
                    entityName: 'product_variants',
                    fileId: [data?.resolvedArr[0].id],
                })
                client.refetchQueries({
                    include: [
                        'getProduct',
                        'getDashboardAllProduct',
                        'getDashboardMyProduct',
                    ],
                })
                refetch && refetch()
            }
            setIsThumbnailUploading(false)
            setShowThumbnailDropdown(false)
        } catch {
            setIsThumbnailUploading(false)
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsThumbnailUploading(false)
        }
    }

    const coverPic = entity?.other_data?.cover_pic
        ? // @ts-ignore
          entity?.other_data.cover_pic.fromLibrary
            ? // @ts-ignore
              getLibraryFileUrl(entity?.other_data.cover_pic.thumbnail)
            : // @ts-ignore
              getImageUrl(entity?.other_data.cover_pic.thumbnail)
        : entity?.other_data
        ? 'thumbnail_data' in entity?.other_data
            ? entity.other_data?.thumbnail_data?.thumbnail
                ? getImageUrl(entity?.other_data.thumbnail_data.thumbnail.gid)
                : '/image-placeholder.jpg'
            : 'cover_pic' in entity?.other_data
            ? getImageUrl(entity?.other_data?.cover_pic?.thumbnail)
            : '/image-placeholder.jpg'
        : '/image-placeholder.jpg'

    const expandedImageUrl = entity?.other_data?.cover_pic
        ? // @ts-ignore
          entity?.other_data.cover_pic.fromLibrary
            ? // @ts-ignore
              getLibraryFileUrl(entity?.other_data.cover_pic.gid)
            : // @ts-ignore
              getImageUrl(entity?.other_data.cover_pic.gid)
        : entity?.other_data
        ? 'thumbnail_data' in entity?.other_data
            ? entity?.other_data?.thumbnail_data?.thumbnail
                ? getImageUrl(entity?.other_data.thumbnail_data.thumbnail.gid)
                : '/image-placeholder.jpg'
            : 'cover_pic' in entity?.other_data
            ? getImageUrl(entity?.other_data?.cover_pic?.thumbnail)
            : '/image-placeholder.jpg'
        : '/image-placeholder.jpg'

    const isCoverPicPresent = coverPic && coverPic !== '/image-placeholder.jpg'

    useEffect(() => {
        const data = getExpandedStates()
        if (data && data.product) {
            if (data?.product[Number(entity.id)]) {
                setShowProductTodos(data.product[Number(entity.id)])
            }
        }
    }, [searchParams.get('e_hash')])

    const tab = getLastOpenedTab('product')
    useEffect(() => {
        let timeoutId: NodeJS.Timeout
        if (tab) {
            const card = document.getElementById(`product_card_${tab}`)
            if (card) {
                card.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                timeoutId = setTimeout(removeLastOpenedTab, 1000)
            }
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    function handleTabExpand() {
        addValuesToExpanded(
            {
                id: Number(entity.id),
                isExpanded: !showProductTodos,
            },
            'product'
        )
    }

    function onDropdownClick(option: string) {
        switch (option) {
            case 'Delete':
                setIsModalActive(true)
                break
            case 'Copy Link':
                copyToClipBoard(
                    window.location.host + '/collections/product/' + entity.id
                )
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            default:
                break
        }
    }

    if (!entity) return
    let name = entity?.name || ''
    if (name) {
        if (dashboardView) {
            name = convertIntoElipses(entity.name || '', 10)
        } else {
            const screenSize = window.innerWidth
            if (screenSize <= 1450 && screenSize > 1400) {
                name = convertIntoElipses(entity.name || '', 40)
            } else if (screenSize <= 1400 && screenSize > 1250) {
                name = convertIntoElipses(entity.name || '', 30)
            } else if (screenSize > 1450) {
                name = convertIntoElipses(entity.name || '', 80)
            } else {
                name = convertIntoElipses(entity.name, 30)
            }
        }
    }
    if (!entity) return
    return (
        <>
            <Box
                border={
                    disableBorders || isVariant ? 'none' : '1px solid #778CA2'
                }
                borderLeft={
                    disableBorders
                        ? '1px solid #778CA2'
                        : !isVariant
                        ? '5px solid #778CA2'
                        : 'none'
                }
                className={
                    getLastOpenedTab('product') === entity.id
                        ? 'card-hover card-bg'
                        : 'card-hover'
                }
                borderBottom={
                    disableBorders || isVariant
                        ? '1px solid #C2CFE0'
                        : '1px solid #778CA2'
                }
                bg={isVariant ? '#F8FBFE' : ''}
            >
                <Box
                    px="16px"
                    py="12px"
                    display="grid"
                    id={`product_card_${entity.id}`}
                    onClick={() => {
                        setLastOpened({
                            entityId: Number(entity.id),
                            type: 'product',
                        })
                        setGlobalSearchActive({
                            isGlobalSearchActive: false,
                        })
                        globalSearchFilters.isGlobalSearchActive &&
                            addRecentSearch({
                                entity_name: 'products',
                                entity_id: entity.id,
                                search_query: globalSearchFilters.query,
                            })
                        navigate(`/collections/product/${entity.id}`)
                        clearDrawerTabs()
                    }}
                    gridTemplateColumns={
                        tabView
                            ? {
                                  md: `${
                                      dashboardView ? '250px' : '400px'
                                  } auto  ${
                                      dashboardView ? '0px' : '100px auto'
                                  }`,
                                  xl: `max-content auto ${
                                      dashboardView ? '0px' : 'auto auto'
                                  }`,
                              }
                            : {
                                  md: '400px auto 100px 100px',
                                  xl: 'max-content auto 100px 100px',
                              }
                    }
                    alignItems="center"
                    borderWidth={1}
                    className="cursor-pointer"
                >
                    <Box
                        display="flex"
                        minWidth={
                            tabView
                                ? { md: '200px', xl: '300px' }
                                : { md: '300px', xl: '500px' }
                        }
                        maxWidth={
                            tabView
                                ? { md: '200px', xl: '500px' }
                                : { md: '300px', xl: 'max-content' }
                        }
                        gridColumnGap="12px"
                    >
                        <Box
                            position="relative"
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: Number(entity.id),
                                    type: 'product',
                                })
                                setShowThumbnailDropdown(true)
                            }}
                        >
                            {isThumbnailUploading &&
                            coverPic === '/image-placeholder.jpg' ? (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="53px"
                                    height="53px"
                                >
                                    <Loader outline="#022143" />
                                </Box>
                            ) : (
                                <Box
                                    width="53px"
                                    height={'53px'}
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {isCoverPicPresent && (
                                        <Box
                                            position="absolute"
                                            top="0px"
                                            right="0px"
                                        >
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setLastOpened({
                                                        entityId: Number(
                                                            entity.id
                                                        ),
                                                        type: 'product',
                                                    })
                                                    setShowThumbnailExpandView(
                                                        !showThumbnailExpandView
                                                    )
                                                }}
                                                bg="transparent"
                                                border="none"
                                                fontStyle="Rubik"
                                                fontSize="10px"
                                                color="#027AC3"
                                                position="relative"
                                                width="max-content"
                                                id="subtodo-reference-menu-button"
                                            >
                                                <FullScreenIco size="10" />
                                            </Button>
                                            {showThumbnailExpandView && (
                                                <ExpandReferencePicModal
                                                    onClose={() =>
                                                        setShowThumbnailExpandView(
                                                            false
                                                        )
                                                    }
                                                    url={expandedImageUrl}
                                                />
                                            )}
                                        </Box>
                                    )}
                                    <Image
                                        width="53px"
                                        height="53px"
                                        borderRadius="4px"
                                        src={
                                            coverPic.length <= 0
                                                ? '/image-placeholder.jpg'
                                                : coverPic
                                        }
                                    />
                                    {coverPic.length >= 0 &&
                                        isThumbnailUploading && (
                                            <Box
                                                width="53px"
                                                height={'53px'}
                                                bg="#02214350"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                position="absolute"
                                            >
                                                <Loader outline="#fff" />
                                            </Box>
                                        )}
                                </Box>
                            )}
                            {showThumbnailDropdown && (
                                <UploadProductThumbnailMenuDropdown
                                    active={true}
                                    onClose={() => {
                                        setShowThumbnailDropdown(false)
                                    }}
                                    entityId={Number(entity.id)}
                                    entityName="product_variants"
                                    productId={Number(entity.id)}
                                    onUpload={(e) =>
                                        uploadImageHandler(e, Number(entity.id))
                                    }
                                    refetchProduct={() => {
                                        props.refetch && props.refetch()
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                        >
                            <Box
                                gridColumnGap="8px"
                                alignItems="center"
                                display="flex"
                            >
                                {isVariant ? (
                                    <VariantBadge />
                                ) : (
                                    <ProductBadge />
                                )}
                                <HtmlTooltip
                                    placement="bottom-start"
                                    arrow
                                    title={
                                        entity.name.length > 10 ? (
                                            <ToolTipHeader
                                                text={entity?.name}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    <Text
                                        fontSize={{
                                            md: '12px',
                                            xl: '14px',
                                        }}
                                        my="0px"
                                    >
                                        {name}
                                    </Text>
                                </HtmlTooltip>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="18px"
                                width="500px"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gridColumnGap="4px"
                                >
                                    <CollectionIco />

                                    {entity.collection?.name ? (
                                        <HtmlTooltip
                                            placement="bottom-start"
                                            arrow
                                            title={
                                                entity?.collection.name &&
                                                entity?.collection?.name
                                                    ?.length > 16 ? (
                                                    <ToolTipHeader
                                                        text={
                                                            entity?.collection
                                                                ?.name || ''
                                                        }
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        >
                                            <Text
                                                color={'textSecondaryDarkBlue'}
                                                fontSize={{
                                                    md: '10px',
                                                    xl: '12px',
                                                }}
                                                my="0px"
                                            >
                                                {convertIntoElipses(
                                                    entity.collection?.name ||
                                                        '',
                                                    16
                                                )}
                                            </Text>
                                        </HtmlTooltip>
                                    ) : (
                                        <Text
                                            color={'textSecondaryDarkBlue'}
                                            fontSize={{
                                                md: '10px',
                                                xl: '12px',
                                            }}
                                            my="0px"
                                        >
                                            No Collection
                                        </Text>
                                    )}
                                    {tabView && !dashboardView && (
                                        <Button
                                            color="links"
                                            border="none"
                                            fontSize={{
                                                md: '12px',
                                                xl: '14px',
                                            }}
                                            bg="transparent"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setLastOpened({
                                                    entityId: Number(entity.id),
                                                    type: 'product',
                                                })
                                                handleTabExpand()
                                                setShowProductTodos(
                                                    !showProductTodos
                                                )
                                            }}
                                        >
                                            {showProductTodos ? 'Hide' : 'View'}{' '}
                                            {('todos' in entity &&
                                                entity?.todos?.total) ||
                                                ('todos_aggregate' in entity &&
                                                    entity?.todos_aggregate
                                                        ?.aggregate
                                                        ?.count)}{' '}
                                            To-Do's
                                        </Button>
                                    )}
                                </Box>
                                {!tabView && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gridColumnGap="12px"
                                    >
                                        <Text
                                            fontSize={{
                                                md: '10px',
                                                xl: '12px',
                                            }}
                                            my="0px"
                                        >
                                            {convertIntoElipses(
                                                entity?.team?.name || 'No Team',
                                                16
                                            )}
                                        </Text>

                                        <StackedImage
                                            size="24px"
                                            spacing={15}
                                            fontSize="12px"
                                            list={
                                                entity.team?.team_members?.map(
                                                    (member) => {
                                                        return {
                                                            name:
                                                                member?.user
                                                                    ?.name ||
                                                                '',
                                                            src: getImageUrl(
                                                                member.user
                                                                    ?.other_data
                                                                    ?.thumbnail
                                                            ),
                                                        }
                                                    }
                                                ) || []
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        // flexDirection={dashboardView ? 'column' : 'row'}
                        gridRowGap={'4px'}
                        gridColumnGap={dashboardView ? '8px' : '50px'}
                    >
                        {!tabView && (
                            <Box
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                position="relative"
                            >
                                <Box
                                    gridColumnGap="8px"
                                    display="flex"
                                    alignItems="center"
                                    border="1px solid"
                                    borderBottom="primary"
                                    px="8px"
                                    borderRadius="2px"
                                >
                                    <SuccessTickSm />
                                    <Text
                                        fontSize={{ md: '10px', xl: '12px' }}
                                        my="0px"
                                    >
                                        {('todos' in entity &&
                                            entity.todos.totalCompleted) ||
                                            ('completedTodos' in entity &&
                                                entity.completedTodos.aggregate
                                                    .count) ||
                                            0}
                                        /
                                        {('todos' in entity &&
                                            entity.todos.total) ||
                                            ('todos_aggregate' in entity &&
                                                entity.todos_aggregate.aggregate
                                                    .count)}{' '}
                                        To-Do's
                                    </Text>
                                </Box>
                                {showPopup && (
                                    <ProductTodosPopover
                                        variantId={Number(entity.id)}
                                        totalTodos={
                                            'todos' in entity
                                                ? entity.todos.total
                                                : 'todos_aggregate' in entity
                                                ? entity.todos_aggregate
                                                      .aggregate.count
                                                : 0
                                        }
                                    />
                                )}
                            </Box>
                        )}
                        <CalendarDropdown
                            left={dashboardView ? '-255px' : '0px'}
                            date={entity.due_date}
                            setDate={(date) =>
                                updateProductVariantDueDate({
                                    id: Number(entity.id),
                                    dueDate: date.toISOString(),
                                    onCompleted: () => {
                                        updateGlobalSearchResults({
                                            type: 'products',
                                            updatedKey: 'due_date',
                                            value: date.toISOString(),
                                            id: entity.id,
                                        })
                                    },
                                })
                            }
                            isDelayed={isEntityDueDateDelayed(
                                entity.due_date
                                    ? (entity.due_date as unknown as string)
                                    : null,
                                entity.status
                            )}
                        />
                        <StatusDropdown
                            exclude={[]}
                            onStatusChange={(status) => {
                                updateProductVariantStatus({
                                    id: String(entity.id),
                                    status: status,
                                    onCompleted: () => {
                                        updateGlobalSearchResults({
                                            type: 'products',
                                            updatedKey: 'status',
                                            value: status,
                                            id: entity.id,
                                        })
                                    },
                                })
                            }}
                            status={entity.status}
                        />
                    </Box>
                    {!tabView && !dashboardView && (
                        <Button
                            color="links"
                            border="none"
                            height="100%"
                            fontSize={{ md: '12px', xl: '12px' }}
                            bg="transparent"
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: Number(entity.id),
                                    type: 'product',
                                })
                                handleTabExpand()
                                setShowProductTodos(!showProductTodos)
                            }}
                        >
                            {showProductTodos ? 'Hide' : 'View'}{' '}
                            {('todos' in entity && entity.todos.total) ||
                                ('todos_aggregate' in entity &&
                                    entity.todos_aggregate.aggregate
                                        .count)}{' '}
                            To-Do's
                        </Button>
                    )}

                    {!dashboardView && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setLastOpened({
                                        entityId: Number(entity.id),
                                        type: 'product',
                                    })
                                    setIsDropdownActive(!isDropdownActive)
                                }}
                                bg="transparent"
                                border="none"
                            >
                                <Dots active={false} />
                            </Button>
                            {isDropdownActive && (
                                <MeatBallDropdown
                                    left="-32px"
                                    isActive={isDropdownActive}
                                    onClick={(option) => {
                                        setLastOpened({
                                            entityId: Number(entity.id),
                                            type: 'product',
                                        })
                                        onDropdownClick(option)
                                    }}
                                    setIsActive={(isActive) =>
                                        setIsDropdownActive(isActive)
                                    }
                                    onClose={() => setIsDropdownActive(false)}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {showProductTodos && (
                <ProductTodosContainer productVariantId={Number(entity.id)} />
            )}
            <DangerModal
                heading={`Delete ${entity.name}`}
                isModal={isModalActive}
                onClose={() => setIsModalActive(false)}
                onRemove={() => {
                    if (isVariant) {
                        deleteProductVariant({
                            variantId: Number(entity.id),
                            refetchQueries: [
                                'getProduct',
                                'getCollections',
                                'fetchCollectionById',
                                'GetProducts',
                                'GetProductsForCollection',
                            ],
                            onComplete: () => {
                                toast('Variant deleted successfully. ', {
                                    className: 'custom-toaster toaster-success',
                                })
                            },
                        })
                    } else {
                        deleteProduct(
                            // @ts-ignore
                            Number(entity.product_id),
                            [
                                'getProduct',
                                'getCollections',
                                'fetchCollectionById',
                                'GetProducts',
                                'GetProductsForCollection',
                                'getCollections',
                            ],
                            () => {
                                globalSearchFilters.isGlobalSearchActive &&
                                    removeProduct({
                                        id: Number(entity.id),
                                    })
                                toast('Product deleted successfully. ', {
                                    className: 'custom-toaster toaster-success',
                                })
                            }
                        )
                    }
                }}
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                title={`Are you sure you want to delete '${entity.name}?'`}
                subHeading={`Deleting this will remove all the existing To-Do's associated with it. This action cannot be undone.`}
            />
        </>
    )
}

export default React.memo(ProductCard)
