import { gql } from '@apollo/client';

export const DeleteTeam = gql`
mutation deleteTeam($id:bigint!,$deleted_at:timestamptz ) {
  update_teams_by_pk(_set: {deleted_at: $deleted_at}, pk_columns: {id: $id}) {
    created_at
    id
    name
    organisation_id
    updated_at
  }
}
`
