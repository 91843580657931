import { useNavigate } from 'react-router-dom'
import { uuid } from '.'
import { useUser } from './hooks/user-hook'
import { getLocalStorage } from 'src/helpers'
import { CONFIG_VARS } from 'src/config/constants.config'

export function createNavigationUrl(type: string, id: string) {
    if (!type || !id) return
    else if (type.includes('collections')) {
        return `/collections/${id}`
    } else if (type.includes('products')) {
        return `/collections/product/${id}`
    } else if (type.includes('team')) {
        return `/team/${id}`
    } else if (type.includes('user')) {
        return `/user/${id}`
    } else if (type.includes('todos')) {
        return `/todo/${id}`
    } else if (type.includes('download')) {
        const downloadUrl = `${
            CONFIG_VARS.HASURA_ACTION_URL
        }/api/v1/download/zip/${id}?token=${getLocalStorage('authToken')}`
        return downloadUrl
    } else {
        return
    }
}

export function getNavigationData(tagString: string) {
    const tagSplit = tagString.match(/[a-zA-Z]+|[0-9]+/g)
    if (!tagSplit || tagSplit.length < 2) return null
    return {
        type: tagSplit[0].toLowerCase(),
        id: tagSplit[1],
        action:
            tagSplit[0].toLowerCase() === 'download' ? 'download' : 'navigate',
    }
}

export const NotificationParser = ({
    comment,
    getUrl,
}: {
    comment: string
    getUrl?: (url: string, type?: string) => void
}) => {
    const { role } = useUser()
    const navigate = useNavigate()
    const isCommentNotification =
        comment && comment.includes('commented') ? true : false

    function createNavigator(
        tagString: string,
        isCommentNotification: boolean
    ) {
        const navigationData = getNavigationData(tagString)
        if (!tagString || !navigationData) return () => {}
        const { type, id, action } = navigationData
        const navigationUrl = createNavigationUrl(type, id)
        if (!navigationUrl) return () => {}
        if (getUrl) {
            getUrl(
                navigationUrl,
                action === 'download' ? 'DOWNLOAD' : undefined
            )
        }
        if (action === 'download') {
            return () => {
                window.open(navigationUrl, '_blank')
            }
        } else {
            return () => {
                navigate(navigationUrl, {
                    state: {
                        from: 'notification',
                        isCommentNotification,
                    },
                })
            }
        }
    }
    let parsedComment: any[] = []
    let pattern: string[] = []
    let flag = false

    comment?.split('').forEach((letter) => {
        if (letter === '<') {
            flag = true
        }
        if (flag && !letter.includes('>')) {
            pattern.push(letter)
        } else if (flag && letter.includes('>')) {
            flag = false
            const raw = pattern.join('').replace('<@', '').replace('>', '')
            const navigateFunction = createNavigator(
                raw.split('&')[0],
                isCommentNotification
            )
            parsedComment.push(
                <a
                    style={{ textDecoration: 'none', wordBreak: 'break-all' }}
                    download={true}
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigateFunction && navigateFunction()
                    }}
                    id={`tag-${uuid()}`}
                    target={
                        raw.split('&')[0].toLowerCase().includes('DOWNLOAD')
                            ? '_blank'
                            : '_self'
                    }
                    data-user-id={raw.split('&')[0].replace('USER', '')}
                    className={
                        role.toLowerCase() === 'designer' &&
                        raw.includes('USER')
                            ? ''
                            : 'span-link'
                    }
                >
                    {raw.split('&')[1]}
                </a>
            )
            pattern = []
        } else {
            parsedComment.push(letter)
        }
    })
    return <>{parsedComment}</>
}
