import { gql } from '@apollo/client'

export const UpdateSubTodoOtherData = gql`
    mutation updateSubTodoOtherData($id: bigint!, $other_data: jsonb) {
        update_sub_todos_by_pk(
            pk_columns: { id: $id }
            _set: { other_data: $other_data }
        ) {
            other_data
        }
    }
`
