import { gql } from "@apollo/client";

export const GetManagers = gql`
query MyQuery {
    organisation_members(where: {role: {_eq: manager}}) {
      user {
        email
        name
        id
        team_members {
          team {
            name
            id
          }
        }
        assigned_todos {
          name
          status
          due_date
          tags
        }
        files(where: {type: {_eq: "profile_pic"}}) {
          path
        }
      }
      role
      organisation_id
    }
  }`

export const SearchManagers = gql`
query searchManagers($query: String,$id:bigint) {
  organisation_members(where: {user: {_or: [{email: {_ilike: $query}}, {name: {_ilike: $query}},{id: {_eq: $id}}]},_and:{role:{_neq:designer}}}) {
    user {
      name
      id
      email
      other_data
    }
  }
}
`