import { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { DangerModal } from 'src/components/modals/danger-modal'
import { Button, Box, Input } from 'src/components/utility'
import { uuid } from 'src/helpers'
import { deleteFileFromEntity } from 'src/helpers/delete-file-from-entity'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'

export default function ReferenceMenuDropdown({
    active,
    onClose,
    subtodoId,
    refetchTodo,
    picId,
    setReferencePicUploadStatus,
}: {
    active: boolean
    onClose: () => void
    subtodoId: number
    refetchTodo: () => void
    picId: number
    setReferencePicUploadStatus: (status: { isUploading: boolean }) => void
}) {
    const [showDeleteReferenceImageModal, setShowDeleteReferenceImageModal] =
        useState(false)
    const wrapperRef = useClickedOutside(onClose)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { isUploading, onFileUpload } = useUploadFile(
        String(subtodoId),
        'sub_todos'
    )
    const { assignFilesToSection } = useAssignFile(() => {
        refetchTodo()
    })

    function inputClickHandler() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }

    async function uploadImageHandler({
        files,
    }: {
        files: File[] | FileList | null
    }) {
        try {
            if (!files) {
                return
            }

            setReferencePicUploadStatus({ isUploading: true })
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: files[0],
                            id: uuid(),
                            name: files[0].name,
                            path: getLocalImageUrl(files[0]) || '',
                        },
                    ],
                },
                'reference_pic',
                () => {}
            )

            if (data?.resolvedArr) {
                assignFilesToSection({
                    assignedAs: 'reference_pic',
                    entityId: String(subtodoId),
                    entityName: 'sub_todos',
                    fileId: [data.resolvedArr[0].id],
                })
            }
        } catch {
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setReferencePicUploadStatus({ isUploading: false })
            onClose()
        }
    }

    return (
        <Dropdown
            active={active}
            top="28px"
            left="none"
            right="0px"
            width="128px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper value="save_as_template" width="100%">
                    <Button
                        height="100%"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => {
                            inputClickHandler()
                        }}
                        px="0px"
                    >
                        Replace Image
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper value="switch_to_template" width="100%">
                    <Button
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={() => setShowDeleteReferenceImageModal(true)}
                        px="0px"
                    >
                        Delete
                    </Button>
                </DropdownButtonWrapper>
                <Input
                    autoComplete="false"
                    multiple
                    type="file"
                    onChange={(e) =>
                        uploadImageHandler({ files: e.target.files })
                    }
                    onClick={(e: any) => {
                        e.target.value = null
                    }}
                    accept="image/*"
                    display="none"
                    ref={inputRef}
                    bg="none"
                />
                {showDeleteReferenceImageModal && (
                    <DangerModal
                        onRemove={() => {
                            deleteFileFromEntity({
                                entityId: String(subtodoId),
                                entityName: 'sub_todos',
                                fileId: picId,
                                onComplete: () => {
                                    refetchTodo()
                                    onClose()
                                },
                            })
                        }}
                        onClose={() => setShowDeleteReferenceImageModal(false)}
                        isModal={showDeleteReferenceImageModal}
                        heading={`Are you sure you want to delete the reference image?`}
                        subHeading={
                            'Deleting the reference image will permanently delete all the reference points added to it.'
                        }
                        title={'Delete Reference Image'}
                        primaryButtonText="Delete"
                        secondaryButtonText="Cancel"
                    />
                )}
            </Box>
        </Dropdown>
    )
}
