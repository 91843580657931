import * as React from 'react'
import { Box } from 'src/components/utility'
import DashboardSlider from '../dashboard-card.slider'
import ProductEntityList from './product-entity-list.dashboard'
import { useProducts } from '../hooks/use-products'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import CollectionSearch from './collection-search'
import { useSearchParams } from 'react-router-dom'
import { useCollectionFilter } from '../hooks/use-collections'
import { statuses } from 'src/helpers'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import type { Status_Enum } from 'src/generated/graphql'

export interface IProductDashboardCardProps {}

export default function ProductDashboardCard(
    props: IProductDashboardCardProps
) {
    const [searchParams, setSearchParams] = useSearchParams()
    const { filters } = useCollectionFilter('product')
    const tab = searchParams.get('productTab') as Status_Enum
    const [activeTab, setActiveTab] = React.useState<Status_Enum>(tab || 'new')
    const [activeSort, setActiveSort] = React.useState('Name')
    const [selectedCollections, setSelectedCollections] = React.useState<
        {
            id: string
            name: string
        }[]
    >(filters['product'] || [])

    const productType =
        !searchParams.get('myProducts') ||
        searchParams.get('myProducts') === 'true'
            ? 'Your Products'
            : 'All Products'

    const [fetchProductType, setFetchProductType] = React.useState(productType)

    const { getLastOpenedTab } = useCardExpandedState()

    const {
        fetchAggregate,
        networkStatus,
        fetchMore,
        productDetails,
        products,
        isLoading,
    } = useProducts({
        status: activeTab,
        sort: activeSort,
        collectionIds: selectedCollections.map((c) => c.id),
        myProducts: productType === 'Your Products',
    })

    React.useEffect(() => {
        fetchAggregate({
            collectionId: filters['product']
                ? filters['product'].map((product) => product.id)
                : [],
            myProducts: productType === 'Your Products',
        })
    }, [productType])

    // Scroll to the section if the last opened tab is product
    const isLastOpenedTabProduct = getLastOpenedTab('product')
    React.useEffect(() => {
        if (isLastOpenedTabProduct) {
            scrollToSection('dashboard_products')
        }
    }, [isLastOpenedTabProduct])

    return (
        <Box width="100%" id="dashboard_products">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <CustomDropdown
                    label={''}
                    labelMaxWidth="200px"
                    highlightedTextFontSize="1rem"
                    selectedText={fetchProductType}
                    onItemClicked={(item) => {
                        const isMyProducts = item === 'Your Products'
                        setFetchProductType(item)
                        searchParams.set('myProducts', `${isMyProducts}`)
                        setSearchParams(searchParams, { replace: true })
                        fetchAggregate({
                            myProducts: isMyProducts,
                        })
                    }}
                    items={['Your Products', 'All Products']}
                />
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CollectionSearch
                        type="product"
                        onUnselect={(collection: {
                            id: string
                            name: string
                        }) => {
                            setSelectedCollections((collections) =>
                                collections.filter(
                                    (c) => c.id !== collection.id
                                )
                            )
                            const ids = selectedCollections
                                .filter((c) => c.id !== collection.id)
                                .map((c) => c.id)
                            fetchAggregate({
                                myProducts:
                                    fetchProductType === 'Your Products',
                                collectionId: ids,
                            })
                        }}
                        selectedCollections={selectedCollections}
                        onSelect={(collection: {
                            id: string
                            name: string
                        }) => {
                            setSelectedCollections([
                                ...selectedCollections,
                                collection,
                            ])
                            const ids = [
                                collection,
                                ...selectedCollections,
                            ].map((c) => c.id)
                            fetchAggregate({
                                myProducts:
                                    fetchProductType === 'Your Products',
                                collectionId: ids,
                            })
                        }}
                    />
                    <CustomDropdown
                        onItemClicked={(activeSort) => {
                            setActiveSort(activeSort)
                        }}
                        selectedText={activeSort}
                        items={[
                            'Name',
                            'Due Date',
                            'Date Created',
                            'Last Updated',
                        ]}
                        label="Sort:"
                    />
                </Box>
            </Box>

            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                borderRadius={'4px'}
                mt="12px"
                pt="16px"
                bg="white"
            >
                <DashboardSlider
                    activeTab={activeTab}
                    setActiveTab={(newTab) => {
                        setActiveTab(newTab as Status_Enum)
                        searchParams.set('productTab', newTab)
                        setSearchParams(searchParams, { replace: true })
                    }}
                    tabs={[
                        {
                            label: 'New',
                            value: 'new',
                            count: productDetails.new,
                        },
                        {
                            label: 'In Progress',
                            value: statuses.inProgress,
                            count: productDetails.inProgress,
                        },
                        {
                            label: 'In Review',
                            value: statuses.inReview,
                            count: productDetails.inReview,
                        },
                        {
                            label: 'Revision Requested',
                            value: statuses.requestedForResubmission,
                            count: productDetails.revisionRequested,
                        },
                        {
                            label: 'Completed',
                            value: statuses.complete,
                            count: productDetails.complete,
                        },
                    ]}
                />
                <ProductEntityList
                    networkStatus={networkStatus}
                    isLoading={isLoading}
                    onPageChange={() => {
                        if (
                            products.length <
                            productDetails[
                                activeTab === statuses.requestedForResubmission
                                    ? 'revisionRequested'
                                    : activeTab
                            ]
                        ) {
                            fetchMore()
                        }
                    }}
                    entity={products}
                    totalProducts={
                        productDetails[
                            activeTab === statuses.requestedForResubmission
                                ? 'revisionRequested'
                                : activeTab
                        ]
                    }
                />
            </Box>
        </Box>
    )
}
