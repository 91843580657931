import { getPalette } from 'color-thief-react'
import { useState } from 'react'
import { uuid } from 'src/helpers'
export function useAnalyseColors() {
    const [detectedColors, setDetectedColors] = useState<
        { color: string; id: string }[]
    >([])
    async function analyseColors(src: string = '', count: number = 6) {
        const colors = await getPalette(src, count, 'hex', '', 1)
        const colorArray = colors.map((color) => ({
            color,
            id: uuid(),
        }))
        console.log({ colorArray })
        setDetectedColors(colorArray)
        return colorArray
    }
    return { detectedColors, analyseColors }
}
