import React, { useState } from 'react'
import { Modal } from 'src/components/modals/modal'
import { Box, Image, Input, Text } from 'src/components/utility'
import type { TodoTemplateType } from 'src/components-v2/todo-templates/todo-templates.types'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { TodoTypeDropdown } from '../save-template/todo-type-dropdown'
import { TodoCategoryDropdown } from '../save-template/todo-category-dropdown'
import { toast } from 'react-toastify'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
    CreateTemplateCategory,
    CreateTodoTemplateType,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'
import axios from 'axios'
import { DUPLICATE_TEMPLATE } from 'src/services/api/endpoints'
import { getLocalStorage } from 'src/helpers'
import {
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplateById,
    GetTodoTemplateTypeNames,
} from 'src/services/graphql/entities/todo/todo-templates.queries'
import { IconButton } from 'src/components/buttons/icon-button'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import TemplateSubtodoCard from 'src/components-v2/todo-templates/template-subtodo-card/template-subtodo-card'

function PreviewEditTemplateModal({
    isOpen,
    onClick,
    onClose,
    onDelete,
    onEdit,
    isLoading,
    template,
    mode = 'preview',
    hideFooter = false,
    todoId = null,
    templateRef = 'NEW',
    templateId,
}: {
    isOpen: boolean
    onClick?: () => void
    onClose: () => void
    onDelete?: () => void
    onEdit?: (updatedTemplate: TodoTemplateType) => void
    isLoading?: boolean
    template: any
    hideFooter?: boolean
    mode?: 'preview' | 'edit' | 'delete' | 'duplicate'
    todoId?: number | null
    templateRef?: 'TODO' | 'NEW'
    templateId?: number
}) {
    const isEditable = mode === 'edit'

    const {
        data: templateData,
        loading: isTemplateLoading,
        refetch: refetchTemplate,
    } = useQuery(GetTodoTemplateById, {
        variables: { id: templateId },
    })
    const [isTodoTypeDropdownActive, setIsTodoTypeDropdownActive] =
        useState(false)
    const [isCategoryDropdownActive, setIsCategoryDropdownActive] =
        useState(false)
    const [saveTemplateDetails, setSaveTemplateDetails] = useState<{
        name: string
        type: { id: number | null; type: string }
        category: { id: number | null; name: string }
    }>({
        name: template.name,
        type: template.template_category.template_type,
        category: template.template_category,
    })
    const client = useApolloClient()

    const [newTodoType, setNewTodoType] = useState('')
    const [newCategoryName, setNewCategoryName] = useState('')

    const [createTodoTemplateTypeMutation] = useMutation(CreateTodoTemplateType)

    const [createTodoTemplateCategoryMutation] = useMutation(
        CreateTemplateCategory
    )

    if (isTemplateLoading) return null

    const _template = templateData?.templates_by_pk

    function getTitle(mode: 'preview' | 'edit' | 'delete' | 'duplicate') {
        switch (mode) {
            case 'preview':
                return `Preview template: ${template.name}`
            case 'edit':
                return `Edit template: ${template.name}`
            case 'delete':
                return `Delete template: ${template.name}`
            case 'duplicate':
                return `Duplicate template: ${template.name}`
            default:
                return template.name
        }
    }

    function validateFields() {
        if (saveTemplateDetails.name.trim().length <= 0) {
            toast('Please enter template name', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.type.id &&
            saveTemplateDetails.type.type !== 'Others'
        ) {
            toast('Please select Todo Type', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.type.id &&
            saveTemplateDetails.type.type == 'Others' &&
            newTodoType.trim().length <= 0
        ) {
            toast('Please enter new Todo Type', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.category.id &&
            saveTemplateDetails.category.name !== 'Others'
        ) {
            toast('Please select Todo Category', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (
            !saveTemplateDetails.category.id &&
            saveTemplateDetails.category.name == 'Others' &&
            newCategoryName.trim().length <= 0
        ) {
            toast('Please select Todo Category', {
                className: 'custom-toaster toaster-error',
            })
        }

        return (
            saveTemplateDetails.name.trim().length > 0 &&
            (saveTemplateDetails.type.id ||
                (!saveTemplateDetails.type.id &&
                    saveTemplateDetails.type.type !== 'Others') ||
                (!saveTemplateDetails.type.id &&
                    saveTemplateDetails.type.type === 'Others' &&
                    newTodoType.trim().length > 0)) &&
            (saveTemplateDetails.category.id ||
                (!saveTemplateDetails.category.id &&
                    saveTemplateDetails.category.name !== 'Others') ||
                (!saveTemplateDetails.category.id &&
                    saveTemplateDetails.category.name === 'Others' &&
                    newCategoryName.trim().length > 0))
        )
    }

    async function duplicateTemplate({
        name,
        template_type_id,
        template_category_id,
        template_id,
        ref = 'NEW',
    }: {
        name: string
        template_type_id: number
        template_category_id: number
        template_id: number | null
        ref?: 'TODO' | 'NEW'
    }) {
        try {
            const payload = {
                name: name,
                template_type_id: template_type_id,
                template_category_id: template_category_id,
                reference: ref,
                template_id: template_id,
            }
            const response = await axios.post(DUPLICATE_TEMPLATE, payload, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })
            if (response.status === 200) {
                client.refetchQueries({
                    include: [
                        'GetTemplateCategoriesWithTemplates',
                        'GetTodoTemplates',
                        'GetFavoriteTemplates',
                    ],
                })
                toast('Template duplicate successfully', {
                    className: 'custom-toaster toaster-success',
                })
            }
        } catch {
            toast('Error occurred while duplicating template', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    async function duplicateClickHandler() {
        try {
            if (validateFields()) {
                if (
                    saveTemplateDetails.name.trim().length > 0 &&
                    saveTemplateDetails.type.id &&
                    saveTemplateDetails.category.id
                ) {
                    duplicateTemplate({
                        name: saveTemplateDetails.name,
                        template_type_id: saveTemplateDetails.type.id,
                        template_category_id: saveTemplateDetails.category.id,
                        template_id: template.id,
                        ref: templateRef,
                    })
                } else {
                    if (
                        saveTemplateDetails.type.id &&
                        !saveTemplateDetails.category.id &&
                        saveTemplateDetails.category.name === 'Others' &&
                        newCategoryName.trim().length > 0
                    ) {
                        const {
                            data: { insert_template_categories_one },
                        } = await createTodoTemplateCategoryMutation({
                            variables: {
                                object: {
                                    name: newCategoryName,
                                    template_type_id:
                                        saveTemplateDetails.type.id,
                                },
                            },
                            refetchQueries: [GetTodoTemplateTypeNames],
                        })
                        duplicateTemplate({
                            name: saveTemplateDetails.name,
                            template_type_id: saveTemplateDetails.type.id,
                            template_category_id:
                                insert_template_categories_one.id,
                            template_id: template.id,
                            ref: templateRef,
                        })
                    } else if (
                        !saveTemplateDetails.type.id &&
                        !saveTemplateDetails.category.id &&
                        saveTemplateDetails.type.type === 'Others' &&
                        saveTemplateDetails.category.name === 'Others' &&
                        newTodoType.trim().length > 0 &&
                        newCategoryName.trim().length > 0
                    ) {
                        const {
                            data: { insert_template_types_one },
                        } = await createTodoTemplateTypeMutation({
                            variables: {
                                object: { type: newTodoType },
                            },
                            refetchQueries: [GetTodoTemplateTypeNames],
                        })
                        const {
                            data: { insert_template_categories_one },
                        } = await createTodoTemplateCategoryMutation({
                            variables: {
                                object: {
                                    name: newCategoryName,
                                    template_type_id:
                                        insert_template_types_one.id,
                                },
                            },
                            refetchQueries: [
                                GetTemplateCategoriesWithTemplates,
                            ],
                        })
                        duplicateTemplate({
                            name: saveTemplateDetails.name,
                            template_type_id: insert_template_types_one.id,
                            template_category_id:
                                insert_template_categories_one.id,
                            template_id: template.id,
                            ref: templateRef,
                        })
                    }
                }
            } else {
                return
            }
        } catch {
            toast('Error occurred while creating template', {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            onClose()
        }
    }

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={() => {
                if (mode === 'delete') {
                    onDelete && onDelete()
                } else if (mode === 'duplicate') {
                    duplicateClickHandler()
                } else if (mode === 'preview') {
                    onClick && onClick()
                } else {
                    null
                }
            }}
            primaryButtonTitle={
                mode === 'edit'
                    ? 'Save'
                    : mode === 'delete'
                    ? 'Delete'
                    : mode === 'duplicate'
                    ? 'Rename & Save'
                    : 'Use'
            }
            secondaryButtonTitle="Cancel"
            title={getTitle(mode)}
            isLoading={isLoading}
            disablePrimaryButton={isLoading}
            bg="white"
            disableActions={hideFooter}
        >
            <Box
                width="85vw"
                height="75vh"
                overflow="hidden"
                display="flex"
                flexDirection="column"
            >
                {mode === 'duplicate' && (
                    <Box
                        width="100%"
                        height={'max-content'}
                        display="grid"
                        gridTemplateColumns={'1fr 1fr'}
                        px="16px"
                        py="24px"
                        gridGap="16px"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                        >
                            <Text color="primary" fontSize="16px" my="0px">
                                Name
                            </Text>
                            <Input
                                placeholder="Enter template name"
                                bg="#F2F4F699"
                                border="0.1px solid #00000033"
                                px="16px"
                                py="8px"
                                borderRadius="4px"
                                value={saveTemplateDetails.name}
                                onChange={(e) => {
                                    setSaveTemplateDetails({
                                        ...saveTemplateDetails,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            width="100%"
                        >
                            <Text color="primary" fontSize="16px" my="0px">
                                To-do Type
                            </Text>
                            <Box position="relative" width="100%">
                                <IconDropdownButton
                                    bg="#F2F4F699"
                                    onClick={() =>
                                        setIsTodoTypeDropdownActive(
                                            !isTodoTypeDropdownActive
                                        )
                                    }
                                    width="100%"
                                    id="todo-type-dropdown-button"
                                >
                                    <Text my="0px" textAlign="left">
                                        {saveTemplateDetails.type.type ||
                                            'Select'}
                                    </Text>
                                </IconDropdownButton>
                                {isTodoTypeDropdownActive && (
                                    <TodoTypeDropdown
                                        active={isTodoTypeDropdownActive}
                                        onDropDownClick={(type) => {
                                            setSaveTemplateDetails({
                                                ...saveTemplateDetails,
                                                type,
                                                category: {
                                                    id: null,
                                                    name: '',
                                                },
                                            })
                                            setIsTodoTypeDropdownActive(false)
                                        }}
                                        setIsDropdownActive={
                                            setIsTodoTypeDropdownActive
                                        }
                                    />
                                )}
                            </Box>
                            {saveTemplateDetails.type.type === 'Others' &&
                                saveTemplateDetails.type.id === null && (
                                    <Input
                                        placeholder="Enter To-do Type name"
                                        bg="#F2F4F699"
                                        border="0.1px solid #00000033"
                                        px="16px"
                                        py="8px"
                                        borderRadius="4px"
                                        value={newTodoType}
                                        onChange={(e) => {
                                            setNewTodoType(e.target.value)
                                        }}
                                    />
                                )}
                        </Box>
                        {(saveTemplateDetails.type?.id ||
                            saveTemplateDetails.type.type === 'Others') && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                gridGap="8px"
                            >
                                <Text color="primary" fontSize="16px" my="0px">
                                    Category
                                </Text>
                                <Box position="relative" width="100%">
                                    <IconDropdownButton
                                        bg="#F2F4F699"
                                        onClick={() => {
                                            setIsCategoryDropdownActive(
                                                !isCategoryDropdownActive
                                            )
                                        }}
                                        width="100%"
                                        id="todo-category-dropdown-button"
                                    >
                                        <Text my="0px" textAlign="left">
                                            {saveTemplateDetails.category
                                                .name || 'Select'}
                                        </Text>
                                    </IconDropdownButton>
                                    {isCategoryDropdownActive && (
                                        <TodoCategoryDropdown
                                            active={isCategoryDropdownActive}
                                            onDropDownClick={(category) => {
                                                setSaveTemplateDetails({
                                                    ...saveTemplateDetails,
                                                    category,
                                                })
                                                setIsCategoryDropdownActive(
                                                    false
                                                )
                                            }}
                                            setIsDropdownActive={
                                                setIsCategoryDropdownActive
                                            }
                                            templateTypeId={
                                                saveTemplateDetails.type.id
                                            }
                                        />
                                    )}
                                </Box>
                                {saveTemplateDetails.category.name ===
                                    'Others' &&
                                    saveTemplateDetails.category.id ===
                                        null && (
                                        <Input
                                            placeholder="Enter Category name"
                                            bg="#F2F4F699"
                                            border="0.1px solid #00000033"
                                            px="16px"
                                            py="8px"
                                            borderRadius="4px"
                                            value={newCategoryName}
                                            onChange={(e) => {
                                                setNewCategoryName(
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    )}
                            </Box>
                        )}
                    </Box>
                )}
                <Box
                    style={{ borderRadius: '12px 12px 0px 0px' }}
                    bg="#F8FAFB"
                    py="12px"
                    px="40px"
                    borderTop="1px solid #A7B9D1"
                    display="grid"
                    gridTemplateColumns={'1fr 1fr'}
                    gridGap="16px"
                    height="100%"
                    overflow="auto"
                >
                    {_template.template_sub_todos.map((subtodo: any) => {
                        return (
                            <TemplateSubtodoCard
                                key={subtodo.id}
                                subtodo={subtodo}
                                templateId={_template.id}
                                refetchTemplate={() => refetchTemplate()}
                                isTechPack={true}
                                isReadOnly={!isEditable}
                            />
                        )
                    })}
                    {isEditable && (
                        <Box
                            bg="white"
                            height="416px"
                            borderRadius="4px"
                            border="1px solid #E8ECEF"
                            p="16px"
                            display="flex"
                            gridGap="24px"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box maxWidth="245px" maxHeight="276px">
                                <Image
                                    src="/create-entity.png"
                                    alt="create entity"
                                    width="100%"
                                    height="100%"
                                />
                            </Box>
                            <IconButton
                                id="subtodo-empty-createbutton"
                                variant={'primary'}
                                iconLeft={<PlusIcoSm color="#ffffff" />}
                                onClick={() => {}}
                            >
                                Add Details
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

export default PreviewEditTemplateModal
