import React, { useState } from 'react'
import { InputLabel } from 'src/components/input-label/input-label'
import { Box } from 'src/components/utility'
import { InlineError } from 'src/components/inline-error/inline-error'
import { Rules } from 'src/screens-v2/signup/rules'

export const UpdatePassword = ({
    setSteps,
    setPassword,
    error,
    setError,
    passwords,
    setOldPassword,
    setIsValidPassword,
}: {
    setSteps: () => void
    passwords: { current: string; repeat: string }
    setOldPassword: (oldPassword: string) => void
    setPassword: ({
        current,
        repeat,
    }: {
        current: string
        repeat: string
    }) => void
    error: {
        error: boolean
        message: string
    }
    setError: (error: boolean) => void
    setIsValidPassword: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const [isRuleActive, setIsRuleActive] = useState(false)

    return (
        <Box
            px="24px"
            width="100%"
            pt="24px"
            pb="52px"
            display="flex"
            flexDirection="column"
            gridRowGap="24px"
        >
            <Box width="47%">
                <InputLabel
                    type="password"
                    isError={false}
                    placeholder="Enter old password"
                    errorText=""
                    isDisabled={false}
                    label="Old Password"
                    id="fp-old-passwords"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOldPassword(e.target.value)
                    }
                />
                {/* <Button
                    onClick={setSteps}
                    pl="0px"
                    mt="0px"
                    color="links"
                    fontFamily="Rubik"
                    fontSize="14px"
                    bg="transparent"
                    border="none"
                >
                    Forgot Password
                </Button> */}
            </Box>
            <Box display="flex" gridColumnGap="24px">
                <Box width="100%" position="relative">
                    <InputLabel
                        type="password"
                        isError={false}
                        placeholder="Enter password"
                        errorText=""
                        isDisabled={false}
                        label="New Password"
                        onFocus={() => setIsRuleActive(true)}
                        onBlur={() => setIsRuleActive(false)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setError(false)
                            setPassword({
                                ...passwords,
                                repeat: e.target.value,
                            })
                        }}
                        id="fp-new-password"
                    />
                    <Rules
                        isRuleVisible={isRuleActive}
                        password={passwords.repeat}
                        isValidPassword={setIsValidPassword}
                    />
                </Box>

                <InputLabel
                    type="password"
                    isError={false}
                    placeholder="Enter password"
                    errorText=""
                    isDisabled={false}
                    label="Repeat New Password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setError(false)
                        setPassword({ ...passwords, current: e.target.value })
                    }}
                    id="fp-repeat-new-password"
                />
            </Box>
            {error.error && (
                <InlineError width="500px" text={error.message}></InlineError>
            )}
        </Box>
    )
}
