import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box } from 'src/components/utility'

function HeaderLoader() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="24px"
            gridGap="12px"
            height="140px"
        >
            <Skeleton count={3} />
        </Box>
    )
}

export default HeaderLoader
