import { useQuery } from '@apollo/client'
import HardBreak from '@tiptap/extension-hard-break'
import Placeholder from '@tiptap/extension-placeholder'
import type { Editor } from '@tiptap/react'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
    CustomMention,
    mentionConfig,
} from 'src/components/comments/mention/mention'
import { getLocalStorage } from 'src/helpers'
import { ADD_COMMENT, DELETE_COMMENT } from 'src/services/api/endpoints'
import { GetOrgMembers } from 'src/services/graphql/query/get-users'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'

function useCommentEditor({
    team,
    entityId,
    entityName,
}: {
    team: any
    entityName: string
    entityId: number
}) {
    const { data: orgMembersData } = useQuery(GetOrgMembers)
    const users = orgMembersData?.organisation_members || []
    const { removeComment } = useCommentsStore()

    const extensions = [
        StarterKit.configure({
            hardBreak: false,
        }),
        Placeholder.configure({
            placeholder: 'Write a comment...',
        }),
        HardBreak.extend({
            addKeyboardShortcuts() {
                return {
                    'Shift-Enter': () => {
                        onComment(this.editor as Editor)
                        return true
                    },
                }
            },
        }),
        CustomMention.configure(mentionConfig({ users, team })),
    ]

    const editor = useEditor(
        {
            extensions,
            autofocus: true,
        },
        [team?.id, users]
    )

    async function onComment(editor: Editor | null) {
        if (!editor) return
        const commentText = editor.getText()
        if (commentText.trim().length === 0) {
            toast('Please enter the comment. ', {
                className: 'custom-toaster toaster-error',
            })
            return
        }
        try {
            await axios.post(
                ADD_COMMENT,
                {
                    comment: editor.getJSON().content,
                    entity_name: entityName,
                    entity_id: entityId,
                    comment_text: editor.getText(),
                    comment_html: editor.getHTML(),
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            editor?.commands.clearContent(true)
        } catch (e) {
            toast('Failed to add comment. ', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    async function onDelete(commentId: string) {
        try {
            const response = await axios.post(
                DELETE_COMMENT,
                {
                    comment_id: commentId,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                removeComment({
                    commentId,
                    entity_id: String(entityId),
                    entity_name: entityName,
                })
                toast('Comment deleted successfully. ', {
                    className: 'custom-toaster toaster-success',
                })
            }
        } catch (e) {
            toast('Failed to delete comment. ', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return { editor, onComment, onDelete }
}

export default useCommentEditor
