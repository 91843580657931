import type { SetStateAction } from 'react'
import { useState, useEffect } from 'react'
import { Tick, Cross } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import styled from 'styled-components'

const StyledRules = styled(Box)`
    box-shadow: 0px 2px 12px rgba(153, 155, 168, 0.25);
    position: absolute;
    top: 65px;
    right: 0;
    z-index: 2;
    border-radius: 4px;
`

const rules = [
    { id: 1, text: 'One uppercase character', isDone: false, regex: /[A-Z]/g },
    { id: 2, text: 'One lowercase character', isDone: false, regex: /[a-z]/g },
    { id: 3, text: 'One number', isDone: false, regex: /[0-9]/g },
    {
        id: 4,
        text: 'One special character',
        isDone: false,
        regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
    },
    {
        id: 5,
        text: 'Atleast 8 characters',
        isDone: false,
        regex: (char: string) => char.length,
    },
]

type ruleType = {
    id: number
    text: string
    isDone: boolean
    regex: RegExp | ((x: string) => number)
}

export const Rules = ({
    isRuleVisible,
    password,
    isValidPassword,
}: {
    isRuleVisible: boolean
    password: string
    isValidPassword: React.Dispatch<SetStateAction<boolean>>
}) => {
    const [isVisible, setIsVisible] = useState<string>('none')
    const [isOpen, setIsOpen] = useState<string>('')
    const [ruleList, setRuleList] = useState<ruleType[]>(rules)

    useEffect(() => {
        if (isRuleVisible) {
            setIsOpen('')
            setIsVisible('flex')
        } else {
            setIsOpen('close-modal')
            setTimeout(() => {
                setIsVisible('none')
            }, 100)
        }
    }, [isRuleVisible])

    function checkCondition(testcase: RegExp, token: string) {
        if (token.match(testcase) === null) {
            return false
        }
        return true
    }

    function refreshRules(isDone: boolean, rules: ruleType) {
        setRuleList((rulesClone) => {
            return rulesClone.map((rule) =>
                rule.id === rules.id ? { ...rule, isDone: isDone } : rule
            )
        })
    }

    useEffect(() => {
        let count = 0
        if (password !== null && password.length > 0) {
            ruleList.forEach((rules, key) => {
                if (typeof rules.regex !== 'function') {
                    if (checkCondition(rules.regex, password)) {
                        refreshRules(true, rules)
                        count += 1
                    } else {
                        refreshRules(false, rules)
                        count = 0
                    }
                } else {
                    if (rules.regex(password) >= 8) {
                        refreshRules(true, rules)
                        count += 1
                    } else {
                        refreshRules(false, rules)
                        count = 0
                    }
                }
            })
        }
        if (count === 5) {
            isValidPassword(true)
        } else {
            isValidPassword(false)
        }
    }, [password])

    return (
        <StyledRules
            bg="white"
            py="24px"
            px="16px"
            display={isVisible}
            flexDirection="column"
            gridRowGap="16px"
            className={isOpen}
        >
            {ruleList.map((rule, key) => {
                return (
                    <Box
                        key={key}
                        display="flex"
                        alignItems="center"
                        gridColumnGap="8px"
                    >
                        {password.length > 0 && !rule.isDone ? (
                            <Cross />
                        ) : password.length === 0 ? (
                            <Cross />
                        ) : (
                            <Tick isDone={password.length > 0 && rule.isDone} />
                        )}
                        <Text
                            bg="transparent"
                            fontFamily="Rubik"
                            fontSize="12px"
                            color={
                                password.length === 0
                                    ? '#FF2C18'
                                    : !rule.isDone
                                    ? '#FF2C18'
                                    : '#39A407'
                            }
                            mt="0px"
                            mb="0px"
                        >
                            {rule.text}
                        </Text>
                    </Box>
                )
            })}
        </StyledRules>
    )
}
