import 'src/components/brightness-slider/slider.style.css'
import { useConfiguratorContext } from '../../configurator.context'
import ArtworkAdvanced from './advanced'
import ArtworkBasic from './basic'
import { Box } from 'src/components/utility'

export interface IArtworkProps {}

export default function Artwork(props: IArtworkProps) {
    const { advancedMode } = useConfiguratorContext()
    if (advancedMode) {
        return (
            <Box
                height={window.innerHeight < 750 ? '200px' : 'initial'}
                overflowY={window.innerHeight < 750 ? 'auto' : 'initial'}
            >
                <ArtworkAdvanced />
            </Box>
        )
    } else {
        return <ArtworkBasic />
    }
}
