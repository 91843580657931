export function ReportsIco() {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.913 0a1.391 1.391 0 00-1.311 1.852L9.995 4.894a1.391 1.391 0 00-.871.12L6.952 3.567c.003-.03.004-.06.005-.089a1.391 1.391 0 00-2.779-.084l-2.022 1.01a1.391 1.391 0 00-1.748.177 1.391 1.391 0 102.37 1.068L4.8 4.64a1.391 1.391 0 001.38.087l2.172 1.447c-.002.029-.004.058-.004.088a1.391 1.391 0 102.702-.46l2.608-3.043A1.391 1.391 0 1013.913 0zM5.565 8.348a1.391 1.391 0 00-1.31 1.852l-2.608 3.042a1.391 1.391 0 101.056.906l2.607-3.042a1.389 1.389 0 00.614-.024l2.471 2.471a1.391 1.391 0 102.731.272l2.172-1.447a1.39 1.39 0 10-.772-1.16l-2.171 1.448a1.392 1.392 0 00-.975-.095L6.91 10.099a1.392 1.392 0 00-1.344-1.751z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
