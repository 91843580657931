import * as React from 'react'
import { useConfiguratorContext } from '../../configurator.context'
import { Box } from 'src/components/utility'
import GraphicBasic from './basic'

export default function Graphic() {
    const { advancedMode } = useConfiguratorContext()
    if (advancedMode) {
        return (
            <Box
                height={window.innerHeight < 750 ? '200px' : 'initial'}
                overflowY={window.innerHeight < 750 ? 'auto' : 'initial'}
            >
                <GraphicBasic />
            </Box>
        )
    } else {
        return <GraphicBasic />
    }
}
