import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getLocalStorage } from '../localstorage'

export function useUser() {
    const [userData, setUserData] = useState({
        userId: '',
        role: '',
        organization_id: 0,
        name: '',
        email: '',
        profileImage: '',
        created_at: new Date(),
    })
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        let userId: string = ''
        let _role: string = ''
        let name: string = ''
        let _organization_id: number = 0
        let email: string = ''
        let profileImage: string = ''
        let created_at = new Date()
        const user = getLocalStorage('user')
        if (user) {
            const _parsed = JSON.parse(user)
            userId = _parsed?.id || ''
            _role = _parsed?.role || ''
            _organization_id = Number(_parsed?.organization_id) || 0
            name = _parsed?.name || ''
            email = _parsed?.email || ''
            profileImage = _parsed?.profileImage || ''
            created_at = _parsed?.created_at
            setUserData({
                userId: userId,
                role: _role,
                organization_id: _organization_id,
                name,
                email,
                profileImage,
                created_at,
            })
        } else {
            if (
                !location.pathname.includes('/login') &&
                !location.pathname.includes('/signup') &&
                !location.pathname.includes('/forgot-password') &&
                !location.pathname.includes('/password-reset')
            ) {
                // toast('You\'ve been logged out please log back in', {
                //     className: 'custom-toaster toaster-info'
                // })
                navigate('/login', {
                    state: { from: location },
                })
            }
        }
    }, [])
    return userData
}
