import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'src/components'
import type { Link } from 'src/components/3d-renderer/annotation.types'
import { Box, Input, Text } from 'src/components/utility'
import { uuid } from 'src/helpers'
import { ensureHttps } from 'src/helpers/ensure-https'

function AddLinkModal({
    addLink,
    onClose,
}: {
    addLink: (link: Link) => void
    onClose: () => void
}) {
    const [link, setLink] = useState({
        text: '',
        url: '',
    })

    function validateAndAddUrl() {
        try {
            const formattedUrl = new URL(ensureHttps(link.url))
            addLink({
                ...link,
                text: link.text,
                url: formattedUrl.toString(),
                id: uuid(),
            })
            onClose()
        } catch (error) {
            toast.error('Enter a valid URL', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return (
        <Modal
            onClose={onClose}
            isModal={true}
            onClick={validateAndAddUrl}
            primaryButtonTitle="Add"
            secondaryButtonTitle="Cancel"
            title="Add Link"
            isLoading={false}
        >
            <Box
                px="24px"
                py="8px"
                gridGap="24px"
                width="100%"
                height="240px"
                id="add-links-modal"
            >
                <Text fontSize="14px" color="primary">
                    Text
                </Text>
                <Input
                    bg="white"
                    border="1px solid #C2CFE0"
                    width="100%"
                    height="48px"
                    borderRadius="8px"
                    px="16px"
                    color="textSecondary"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLink({ ...link, text: e.target.value })
                    }
                />
                <Text fontSize="14px" color="primary">
                    Link
                </Text>
                <Input
                    bg="white"
                    border="1px solid #C2CFE0"
                    width="100%"
                    height="48px"
                    color="links"
                    px="16px"
                    borderRadius="8px"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLink({ ...link, url: e.target.value })
                    }
                />
            </Box>
        </Modal>
    )
}

export default AddLinkModal
