import axios from 'axios'
import { toast } from 'react-toastify'
import { getLocalStorage } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import { GET_MANAGER_TEAMS } from 'src/services'
export const getTeamsData = async ({
    searchParam = '',
    currentPage,
    setisLoading,
    sortParam,
    setTeam,
}: {
    searchParam: string
    currentPage: number
    setisLoading: (isLoading: boolean) => void
    sortParam: string
    setTeam?: (data: ITeams[]) => void
}) => {
    setisLoading(true)

    try {
        const queryParams: any = {
            limit: PerPage,
            offset: currentPage,
        }

        if (searchParam) {
            queryParams.search = searchParam
        }
        if (sortParam) {
            switch (sortParam) {
                case 'LTO':
                    queryParams.sort = 'l2o'

                    break
                case 'OTL':
                    queryParams.sort = 'o2l'

                    break
                case 'LTM':
                    queryParams.sort = 'l2m'

                    break
                case 'MTL':
                    queryParams.sort = 'm2l'

                    break
                case 'LTH':
                    queryParams.sort = 'lh'

                    break
                case 'HTL':
                    queryParams.sort = 'hl'

                    break
                default:
                    queryParams.sort = 'name'
                    break
            }
        }

        const source = axios.CancelToken.source()
        const { data } = await axios.get(GET_MANAGER_TEAMS, {
            cancelToken: source.token,
            headers: {
                'x-auth-token': getLocalStorage('authToken'),
            },
            params: queryParams,
        })
        if (data.success) {
            if (data.data && data.data?.length) {
                setTeam && setTeam(data.data)
                return {
                    data: data.data,
                    count: data.count,
                    cancelSource: source,
                }
            } else {
                return { data: [], count: 0, cancelSource: source }
            }
        }
    } catch (error: any) {
        toast.error(error.message)
    }
}

export const isEmailValid = (email: string) => {
    return email.match(/^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/)
}
