import { Box, Button } from 'src/components/utility'
import { DropdownButtonWrapper, Dropdown } from 'src/components'
import type { SetStateAction } from 'react'
import { useQuery } from '@apollo/client'
import { GetTodoTemplateTypeNames } from 'src/services/graphql/entities/todo/todo-templates.queries'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export const TodoTypeDropdown = ({
    active,
    onDropDownClick,
    setIsDropdownActive,
    showOthers = true,
}: {
    active: Boolean
    onDropDownClick: (value: { id: number | null; type: string }) => void
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
    showOthers?: Boolean
}) => {
    const todoTemplateTypes = useQuery(GetTodoTemplateTypeNames, {
        fetchPolicy: 'no-cache',
    })

    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['todo-type-dropdown-button', 'todo-type-dropdown-text']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="36px"
            width="100%"
            ref={wrapperRef}
        >
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                maxHeight="200px"
                overflow={'auto'}
            >
                {todoTemplateTypes?.data?.template_types?.map(
                    ({ id, type }: { id: number; type: string }) => (
                        <DropdownButtonWrapper
                            onClick={() => {
                                onDropDownClick({ id, type })
                                setIsDropdownActive(false)
                            }}
                            value={type}
                            width="100%"
                            key={id}
                        >
                            <Button
                                fontFamily="Rubik"
                                color="primary"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                overflow="hidden"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                title={type}
                            >
                                {type}
                            </Button>
                        </DropdownButtonWrapper>
                    )
                )}
                {showOthers && (
                    <DropdownButtonWrapper
                        onClick={() => {
                            onDropDownClick({
                                id: null,
                                type: 'Others',
                            })
                        }}
                        value="Others"
                        width="100%"
                    >
                        <Button
                            fontFamily="Rubik"
                            color="primary"
                            bg="transparent"
                            border="none"
                            fontSize="14px"
                        >
                            Others
                        </Button>
                    </DropdownButtonWrapper>
                )}
            </Box>
        </Dropdown>
    )
}
