export const SmPersonIco = () => (
    <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.13636 0C2.73438 0 1.59091 1.14347 1.59091 2.54545C1.59091 3.42188 2.03835 4.20028 2.71591 4.65909C1.5252 5.17033 0.652998 6.29703 0.518179 7.63683C0.498072 7.83666 0.662806 8 0.863636 8C1.06447 8 1.22489 7.83638 1.24952 7.63707C1.42728 6.19858 2.64851 5.09091 4.13636 5.09091C5.62422 5.09091 6.84545 6.19858 7.0232 7.63707C7.04783 7.83638 7.20826 8 7.40909 8C7.60992 8 7.77466 7.83666 7.75455 7.63683C7.61973 6.29703 6.74753 5.17033 5.55682 4.65909C6.23438 4.20028 6.68182 3.42188 6.68182 2.54545C6.68182 1.14347 5.53835 0 4.13636 0ZM4.13636 0.727273C5.14489 0.727273 5.95455 1.53693 5.95455 2.54545C5.95455 3.55398 5.14489 4.36364 4.13636 4.36364C3.12784 4.36364 2.31818 3.55398 2.31818 2.54545C2.31818 1.53693 3.12784 0.727273 4.13636 0.727273Z"
            fill="#022143"
        />
    </svg>
)
