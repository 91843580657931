import { ProductIco } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'

export function ProductBadge() {
    return (
        <Box
            bg="primary"
            color="#ffffff"
            display="flex"
            gridGap="4px"
            borderRadius="4px"
            p="4px"
            alignItems="center"
            width="max-content"
            height="max-content"
        >
            <ProductIco color="#ffffff" width="13" height="10" />
            <Text fontSize="12px" my="0px">
                Product
            </Text>
        </Box>
    )
}
