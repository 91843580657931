import React, { useEffect, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { CollectionIco, Dots, ProductIco } from 'src/components/svg-icons'
import CalendarDropdown from '../../cards/dropdowns/calendar'
import StatusDropdown from '../../cards/dropdowns/status'
import { Link } from 'react-router-dom'
import { TodoBadge } from '../../badges/todo-badge'
import type { User } from 'src/services/graphql/query/@types/collections'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import useStore from 'src/store/hooks/useStore'
import useUpdateTodo from 'src/hooks/useUpdateTodo'
import { copyToClipBoard, useUser } from 'src/helpers'
import MeatBallDropdown from '../../cards/dropdowns/meatball-dropdown'
import { toast } from 'react-toastify'
import { DangerModal } from 'src/components/modals/danger-modal'
import { useDeleteTodo } from '../../cards/hooks/useUpdateTodo'
import ThumbnailBadge from '../../thumbnail-badge/thumbnail-badge'
import AssignUser from '../../dropdown-badges/assign-user/assign-user'
import DependentTodos from './dependent-todos/dependent-todos'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import type { Status_Enum } from 'src/generated/graphql'
import { scrollToCard } from 'src/helpers/scroll-to-card'
import { scrollToSection } from 'src/helpers/scroll-to-section'

export type TodoListCardProps = {
    todo: {
        id: number
        name: string
        coverPic: {
            name: string
            gid: string
            path: string
            thumbnail: string
            assignee_id: string
            fromLibrary: boolean
        } | null
        collection: {
            id: number
            name: string
            manager_id: number
        }
        product: {
            id: number
            name: string
            coverPic: {
                name: string
                gid: string
                path: string
                thumbnail: string
                assignee_id: string
                fromLibrary: boolean
            } | null
        }
        dueDate: string | null
        isDelayed: boolean
        status: string
        assignee: User | null | undefined
    }
    isFullCard?: boolean
    isCompareSection?: boolean
    navigateTo: string
    onClick?: () => void
    refetchTodos?: () => void
    isCascade?: boolean
    refetchQueries?: string[]
    cardId?: string
}

function TodoListCard({
    todo,
    navigateTo,
    onClick,
    isCompareSection = false,
    isFullCard = true,
    isCascade = false,
    refetchTodos,
    refetchQueries = [],
    cardId,
}: TodoListCardProps) {
    const [componentVisibility, setComponentVisibility] = useState({
        menuDropdown: false,
        deleteModal: false,
    })
    const { setLastOpened, getLastOpenedTab, removeLastOpenedTab } =
        useCardExpandedState()
    const { globalSearchFilters, removeTodo, updateGlobalSearchResults } =
        useStore()
    const { updateTodoStatus, updateTodoDueDate, updateTodoAssignee } =
        useUpdateTodo(refetchQueries)
    const { deleteTodo } = useDeleteTodo()

    const { userId } = useUser()
    const isCollectionManager = todo.collection.manager_id === Number(userId)

    function dropdownClickHandler(option: string) {
        switch (option) {
            case 'Delete':
                setComponentVisibility({
                    ...componentVisibility,
                    deleteModal: true,
                })
                break
            case 'Copy Link':
                copyToClipBoard(window.location.host + '/todo/' + todo.id)
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break
            default:
                break
        }
    }

    const tab = getLastOpenedTab('todo')
    useEffect(() => {
        if (!tab || tab !== todo.id) return
        if (isFullCard) {
            scrollToSection(`todo_card_${tab}`)
        } else {
            // Scrolling in dashboard
            scrollToCard(`todo_card_${tab}`)
        }
    }, [tab])

    return (
        <Box id={cardId || `todo_card_${todo.id}`}>
            <Link
                to={navigateTo}
                style={{ textDecoration: 'none', color: 'inherit' }}
                onClick={(e) => {
                    if (isCompareSection) {
                        e.preventDefault()
                    }
                }}
            >
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        md: 'minmax(180px,1fr) auto',
                        xl: `minmax(180px,1fr)  ${
                            isFullCard ? 'calc(55% + 12px)' : 'max-content'
                        }`,
                    }}
                    gridColumnGap={'38px'}
                    border="1px solid #dddddd"
                    pl={'16px'}
                    pr={'16px'}
                    py="8px"
                    alignItems={'center'}
                    onClick={() => {
                        setLastOpened({
                            entityId: todo.id,
                            type: 'todo',
                        })
                        onClick && onClick()
                    }}
                    className={
                        String(tab) === String(todo.id)
                            ? 'card-hover card-bg cursor-pointer'
                            : 'card-hover cursor-pointer'
                    }
                >
                    <Box
                        display="grid"
                        gridTemplateColumns={'53px 1fr'}
                        gridColumnGap="8px"
                        alignItems="center"
                    >
                        <ThumbnailBadge
                            coverPic={todo.coverPic}
                            parentCoverPic={todo.product.coverPic}
                            entityId={todo.id}
                            entityName="todos"
                            productId={todo.product.id}
                            refetch={refetchTodos}
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            overflow={'hidden'}
                        >
                            <Box
                                display="grid"
                                gridTemplateColumns={
                                    '54px minmax(80px,auto) minmax(120px,1fr)'
                                }
                                gridGap="16px"
                                width={'100%'}
                                overflow={'hidden'}
                                alignItems="center"
                            >
                                <TodoBadge />
                                <Text
                                    fontSize={'14px'}
                                    my="0px"
                                    className="text-ellipsis"
                                    title={todo.name}
                                >
                                    {todo.name}
                                </Text>
                                <Box width={'100%'} overflow={'hidden'}>
                                    <Box
                                        to={`/collections/product/${todo.product.id}`}
                                        as={Link}
                                        display="grid"
                                        gridTemplateColumns={'auto 1fr'}
                                        gridGap="8px"
                                        alignItems="center"
                                        style={{ textDecoration: 'none' }}
                                        color="links"
                                        width={'max-content'}
                                        maxWidth={'100%'}
                                    >
                                        <ProductIco color="#027AC3" />
                                        <Text
                                            my="0px"
                                            fontSize="14px"
                                            className="text-ellipsis"
                                            title={todo.product.name}
                                        >
                                            {todo.product.name}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                            <Box width={'100%'}>
                                <Box
                                    to={`/collections/${todo.collection.id}`}
                                    as={Link}
                                    display="grid"
                                    gridTemplateColumns={'auto 1fr'}
                                    gridGap="8px"
                                    alignItems="center"
                                    style={{ textDecoration: 'none' }}
                                    color="links"
                                    width={'max-content'}
                                    maxWidth={'100%'}
                                >
                                    <CollectionIco color="#027AC3" />
                                    <Text
                                        my="0px"
                                        fontSize="14px"
                                        className="text-ellipsis"
                                        title={todo.collection.name}
                                    >
                                        {todo.collection.name}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isFullCard
                                ? '100px 100px'
                                : isCascade
                                ? '112px 100px 100px 160px 80px 24px'
                                : '100px 100px 180px 80px 24px'
                        }
                        gridGap="16px"
                        alignItems="center"
                        gridColumnGap={!isFullCard ? '24px' : '16px'}
                        justifyContent={!isFullCard ? 'end' : 'space-between'}
                        pr={!isFullCard ? '16px' : '0px'}
                    >
                        {isFullCard && isCascade && <Box></Box>}
                        <CalendarDropdown
                            date={todo.dueDate ? new Date(todo.dueDate) : null}
                            left={!isFullCard ? '-245px' : '0px'}
                            setDate={(date) =>
                                updateTodoDueDate({
                                    id: todo.id,
                                    due_date: date.toISOString(),
                                    onCompleted: () => {
                                        globalSearchFilters.isGlobalSearchActive &&
                                            updateGlobalSearchResults({
                                                id: String(todo.id),
                                                type: 'todos',
                                                updatedKey: 'due_date',
                                                value: date.toISOString(),
                                            })
                                    },
                                })
                            }
                            isDelayed={isEntityDueDateDelayed(
                                todo.dueDate,
                                todo.status as Status_Enum
                            )}
                            isListCard
                        />
                        <StatusDropdown
                            exclude={[
                                'revisionRequested',
                                // 'requestedForResubmission',
                                'toBeReviewed',
                            ]}
                            left={!isFullCard ? '-20px' : '0px'}
                            onStatusChange={(status) =>
                                updateTodoStatus({
                                    id: todo.id,
                                    status,
                                    onCompleted: () => {
                                        globalSearchFilters.isGlobalSearchActive &&
                                            updateGlobalSearchResults({
                                                id: String(todo.id),
                                                type: 'todos',
                                                updatedKey: 'status',
                                                value: status,
                                            })
                                    },
                                })
                            }
                            isManager={isCollectionManager}
                            status={todo.status}
                        />
                        {isFullCard ? (
                            <Box>
                                <AssignUser
                                    assignee={todo.assignee}
                                    onRemove={() => {
                                        updateTodoAssignee({
                                            todoId: todo.id,
                                            assignee_id: null,
                                        })
                                    }}
                                    onAssign={(user) => {
                                        updateTodoAssignee({
                                            todoId: todo.id,
                                            assignee_id: user.id,
                                        })
                                    }}
                                    showIcon={false}
                                    role="Assignee"
                                />
                            </Box>
                        ) : (
                            <Box width="112px"></Box>
                        )}
                        {isFullCard && (
                            <DependentTodos
                                todoId={todo.id}
                                productVariantId={todo.product.id}
                            />
                        )}
                        {isFullCard && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                            >
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setLastOpened({
                                            entityId: todo.id,
                                            type: 'todo',
                                        })
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown:
                                                !componentVisibility.menuDropdown,
                                        })
                                    }}
                                    bg="transparent"
                                    border="none"
                                >
                                    <Dots active={false} />
                                </Button>

                                {componentVisibility.menuDropdown && (
                                    <MeatBallDropdown
                                        left="unset"
                                        right="0px"
                                        isActive={
                                            componentVisibility.menuDropdown
                                        }
                                        onClick={(option) => {
                                            setLastOpened({
                                                entityId: todo.id,
                                                type: 'todo',
                                            })
                                            dropdownClickHandler(option)
                                        }}
                                        setIsActive={(isActive) =>
                                            setComponentVisibility({
                                                ...componentVisibility,
                                                menuDropdown: isActive,
                                            })
                                        }
                                        onClose={() =>
                                            setComponentVisibility({
                                                ...componentVisibility,
                                                menuDropdown: false,
                                            })
                                        }
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Link>
            {componentVisibility.deleteModal && (
                <DangerModal
                    heading={`Delete ${todo.name}`}
                    isModal={componentVisibility.deleteModal}
                    onClose={() =>
                        setComponentVisibility({
                            ...componentVisibility,
                            deleteModal: false,
                        })
                    }
                    onRemove={() =>
                        deleteTodo({
                            todoId: todo.id,
                            refetchQueries: 'active',
                            onComplete: () => {
                                globalSearchFilters.isGlobalSearchActive &&
                                    removeTodo({
                                        id: todo.id,
                                    })
                                toast('Todo Deleted Successfully', {
                                    className: 'custom-toaster toaster-success',
                                })
                            },
                        })
                    }
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    title={`Are you sure you want to delete '${todo.name}?'`}
                    subHeading={`Deleting this will remove this To-Do's and it's associated data. This action cannot be undone.`}
                />
            )}
        </Box>
    )
}

export default TodoListCard
