import { Box, Text } from 'src/components/utility'

export const LoadMoreCard = ({
    setCurrentPage,
    currentPage,
    listView,
}: {
    setCurrentPage?: (page: number) => void
    currentPage?: number
    listView?: boolean
}) => {
    return (
        <>
            {setCurrentPage && (
                <Box
                    width={!listView ? '120px' : '100%'}
                    display={'flex'}
                    alignItems="center"
                    justifyContent="center"
                    height={!listView ? '135px' : '70px'}
                >
                    <Text
                        fontSize={'16px'}
                        textAlign="center"
                        color="textTags"
                        className="pointer"
                        onClick={() => {
                            setCurrentPage &&
                                setCurrentPage((currentPage || 0) + 1)
                        }}
                        my="0px"
                    >
                        Load more
                    </Text>
                </Box>
            )}
        </>
    )
}
