import { gql } from '@apollo/client'

export const InsertCollaborator = gql`
    mutation InsertTeamMembers(
        $objects: [team_members_insert_input!] = objects
    ) {
        insert_team_members(objects: $objects) {
            affected_rows
            returning {
                id
                user {
                    name
                    email
                    id
                    created_at
                    assigned_todos {
                        created_by
                        created_at
                        description
                        due_date
                        id
                        name
                        product_id
                        status
                        tags
                        updated_at
                        user_id
                    }
                    other_data
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`
