import { gql } from "@apollo/client";

export const UpdateTodoDuration = gql`
mutation updateTodoDuration($duration: interval, $id: bigint!) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {duration: $duration}) {
    duration
    status
  }
}
`

export const UpdateTodoAsignee = gql`
mutation updateTodoAsignee($id: bigint!, $user_id: bigint) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {user_id: $user_id}) {
    user_id
    assignee {
      organisation_members {
        role
      }
    }
  }
}
`;
export const UpdateTodoDueDate = gql`
mutation updateTodoDueDate($id: bigint!, $due_date: timestamptz) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {due_date: $due_date}) {
    id
  }
}

`;

export const UpdateTodoDescription = gql`
mutation updateTodoDescription($description: String!, $id: bigint!) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {
    description
    status
  }
}
`;

export const UpdateTodoTags = gql`
mutation updateTodoTags($id: bigint!, $tags: jsonb) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {tags: $tags}) {
    tags
    status
  }
}
`;
export const AddSubTodos = gql`
mutation addSubTodos($description: String, $heading: String, $other_data: jsonb, $todo_id: bigint) {
  insert_sub_todos_one(object: {description: $description, heading: $heading, other_data: $other_data, todo_id: $todo_id}) {
    id
    heading
    other_data
    description
  }
}`
