import { Line } from 'rc-progress'
import { useState } from 'react'
import { Box, Button, Image, Text } from '../utility'
import { FileMenuDropdown } from './file-menu-dropdown'
import { Checkbox } from '../checkbox/checkbox'
import CircleCross from '../svg-icons/circle-cross-ico'
import { VirtuIcoRound } from '../svg-icons/virtu-ico'
import { processFileName } from './file-display.helper'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'
import { Dots, Tick } from '../svg-icons'

export const FileDisplay2 = ({
    url,
    name,
    onDelete,
    onSelect,
    onClick,
    isDownloadable,
    width,
    height,
    bg = '#ffffff',
    showLoader = false,
    percentage = '0',
    selected = false,
    librarySelect = false,
    thumbnail,
    redirect = true,
    minWidth,
    isDeletePermitted = false,
    onDragStart,
    fileId,
    onSelectHandler,
    enableSelect = false,
    isSelected = false,
    deleteButton = false,
    isPresentInLibrary = false,
    id,
    isRenamePermitted = false,
    onRename,
}: {
    url: string
    name: string
    onDelete?: () => void
    onSelect?: (bool: boolean) => void
    onClick?: () => void
    isDownloadable?: boolean
    width?: string
    height?: string
    bg?: string
    showLoader?: boolean
    percentage?: string
    selected?: boolean
    librarySelect?: boolean
    thumbnail?: string
    redirect?: boolean
    minWidth?: string
    isDeletePermitted?: boolean
    onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
    fileId: number
    onSelectHandler?: (value: boolean) => void
    enableSelect?: boolean
    isSelected?: boolean
    deleteButton?: boolean
    isPresentInLibrary?: boolean
    id: string
    isRenamePermitted?: boolean
    onRename?: () => void
}) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isFileDropdownActive, setIsFileDropdownActive] = useState(false)
    const type = name?.split('.')[name.split('.').length - 1].toLowerCase()

    const fileName = processFileName(name)

    let _newCallBackUrl: string = window.location.pathname
    const _urlParams = window.location.search
    _newCallBackUrl = `${_newCallBackUrl}${_urlParams}`

    const downloadFileHandler = (url: string, name: string) => {
        const link = document.createElement('a')
        link.href = url
        link.download = name
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <>
            <Box
                border="solid"
                bg={bg}
                alignItems="center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                borderWidth={selected ? 2 : 1}
                borderColor={
                    selected && librarySelect
                        ? '#203C5A'
                        : selected
                        ? '#87b7f1'
                        : 'gray250'
                }
                borderRadius="4px"
                position="relative"
                id={id}
                width={width ? width : '120px'}
                height={height ? height : '120px'}
                minWidth={minWidth && minWidth}
                draggable={!!onDragStart}
                onDragStart={(e) => {
                    onDragStart && onDragStart(e)
                }}
                className={redirect ? 'cursor-pointer' : ''}
                onClick={() => onClick && onClick()}
            >
                {enableSelect && (isHovered || isSelected) && (
                    <Box position="absolute" top="4px" left="4px" zIndex={10}>
                        <Checkbox
                            checkBoxId={String(fileId)}
                            defautlCheck={isSelected}
                            onChange={(x: boolean) => {
                                onSelectHandler && onSelectHandler(x)
                            }}
                            label=""
                        />
                    </Box>
                )}
                {isPresentInLibrary && (
                    <Box
                        position="absolute"
                        top="4px"
                        left="4px"
                        zIndex={15}
                        title="File is present in library"
                    >
                        <VirtuIcoRound />
                    </Box>
                )}
                {deleteButton && !isPresentInLibrary && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete && onDelete()
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="absolute"
                        right="-12px"
                        top="-12px"
                        width="max-content"
                        id="collection-menu-button"
                    >
                        <CircleCross />
                    </Button>
                )}
                {isHovered && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top="0px"
                        left="0px"
                        width="100%"
                        height="100%"
                        bg={isHovered ? '#02214360' : 'transparent'}
                        gridColumnGap="16px"
                        id="hover-file"
                    >
                        {(isDeletePermitted || isRenamePermitted) && (
                            <Button
                                position="absolute"
                                top="4px"
                                right="0px"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsFileDropdownActive(
                                        !isFileDropdownActive
                                    )
                                }}
                                bg="transparent"
                                border="none"
                                fontStyle="Rubik"
                                fontSize="12px"
                                color="links"
                                width="max-content"
                                id={`file-menu-button-${fileId}`}
                            >
                                <Dots
                                    active={isFileDropdownActive}
                                    vertical
                                    color="white"
                                />
                            </Button>
                        )}
                    </Box>
                )}
                {isFileDropdownActive && (
                    <FileMenuDropdown
                        active={isFileDropdownActive}
                        setIsDropdownActive={setIsFileDropdownActive}
                        onDownload={() => downloadFileHandler(url, name)}
                        onDelete={() => onDelete && onDelete()}
                        onRename={() => onRename && onRename()}
                        id={fileId}
                    />
                )}

                {selected && !librarySelect && (
                    <Box
                        width="24px"
                        height="24px"
                        display="flex"
                        alignItems="center"
                        bg="links"
                        position="absolute"
                        justifyContent="center"
                        borderRadius="50%"
                        top="4px"
                        right="4px"
                    >
                        <Tick color="#fff" isDone={true}></Tick>
                    </Box>
                )}
                {type === 'jpeg' || type === 'jpg' || type === 'png' ? (
                    <Image
                        width="100%"
                        height="auto"
                        style={{
                            height: 'calc(100% - 28px)',
                            objectFit: 'cover',
                        }}
                        loading="lazy"
                        src={thumbnail || url}
                    />
                ) : (
                    <Box
                        width="100%"
                        height="100%"
                        maxHeight="calc(100% - 28px)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            <ExtensionIcon
                                size="lg"
                                type={name
                                    ?.split('.')
                                    [name.split('.').length - 1].toLowerCase()}
                            />
                        }
                    </Box>
                )}

                {showLoader && (
                    <Box
                        zIndex={5}
                        bottom="24px"
                        position="absolute"
                        width="100%"
                    >
                        <Line
                            percent={Number(percentage)}
                            strokeWidth={2}
                            trailWidth={2}
                            style={{ width: '100%' }}
                            strokeColor="#39A407"
                            trailColor="#EBECEF10"
                        />
                    </Box>
                )}
                <Box
                    border="solid"
                    bg="white"
                    borderWidth={1}
                    borderColor="gray250"
                    py="8px"
                    px="2px"
                    bottom="0px"
                    display="grid"
                    overflow={'hidden'}
                    gridTemplateColumns="auto 1fr"
                    gridGap="4px"
                    alignItems="center"
                    height="28px"
                    width="100%"
                    position="absolute"
                    zIndex={1}
                    title={name}
                >
                    <ExtensionIcon
                        size="sm"
                        type={name
                            ?.split('.')
                            [name.split('.').length - 1].toLowerCase()}
                    />

                    {!showLoader && (
                        <Text
                            className="overflow__elipses"
                            fontSize="8px"
                            my="0px"
                            fontFamily="Rubik"
                            color="textSecondaryDarkBlue"
                        >
                            {fileName}
                        </Text>
                    )}

                    {showLoader && (
                        <>
                            <Text
                                className="overflow__elipses"
                                fontSize="8px"
                                my="0px"
                                fontFamily="Rubik"
                                color="textSecondaryDarkBlue"
                            >
                                {Number(percentage) === 100
                                    ? 'Uploaded'
                                    : fileName}
                            </Text>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
