import React from 'react'
import ProfileImgWithName from 'src/components-v2/UI/profile-img-with-name'
import { VariantBadge } from 'src/components-v2/badges/variant-badge'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { CollectionIco, ProductIco } from 'src/components/svg-icons'
import { Box, Image, Text } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'

function ProductDropdownCard({
    name,
    collectionName,
    approver,
    imageUrl,
    onClick,
    isVariant,
}: {
    name: string
    collectionName: string
    approver: {
        id: number
        name: string
        other_data?: {
            profile_pic?: string
        }
    } | null
    imageUrl: string
    onClick: () => void
    isVariant?: boolean
}) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="gray250"
            className="cursor-pointer"
            onClick={onClick}
            gridGap="8px"
            py="8px"
        >
            <Box display="flex" flexDirection="column" gridGap="8px">
                <Box display="flex" gridGap="8px" alignItems="center">
                    <ProductIco />
                    <Text my="0px" color="textSecondary">
                        {name}
                    </Text>
                    {isVariant ? <VariantBadge /> : null}
                </Box>
                <Box display="flex" gridGap="16px">
                    <Box display="flex" gridGap="8px" alignItems="center">
                        <CollectionIco color="#778CA2" />
                        <Text
                            my="0px"
                            color="textSecondary"
                            fontSize={'14px'}
                            maxWidth="300px"
                            className="text-ellipsis"
                            title={collectionName}
                        >
                            {convertIntoElipses(collectionName, 30)}
                        </Text>
                    </Box>

                    {approver && (
                        <ProfileImgWithName
                            imageSrc={
                                approver.other_data?.profile_pic
                                    ? getImageUrl(
                                          approver.other_data?.profile_pic
                                      )
                                    : ''
                            }
                            name={approver.name}
                            textColor="textSecondary"
                        />
                    )}
                </Box>
            </Box>
            <Box width={'52px'} height={'52px'}>
                <Image
                    src={imageUrl ? imageUrl : '/img-placeholder.jpg'}
                    alt="image"
                    width={'100%'}
                    height={'100%'}
                    borderRadius="4px"
                />
            </Box>
        </Box>
    )
}

export default ProductDropdownCard
