import { uuid } from 'src/helpers'
import { useConfiguratorContext } from '../configurator.context'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { createMaterial } from '../helper/create-material'
import type { MeshCustomMaterial } from '../panel-functions/material/custom-material'
import { degToRad } from 'three/src/math/MathUtils'
import * as THREE from 'three'

export function useArtwork() {
    const { highlights, setArtwork, setActiveArtwork } =
        useConfiguratorContext()

    function uploadArtwork(file: File, { id }: { id?: string }) {
        const material = highlights[0].mesh.material as MeshCustomMaterial
        const config = {
            repeat: {
                u: material.map?.repeat.x || 1,
                v: material.map?.repeat.y || 1,
            },
            offset: {
                u: material.map?.offset.x || 0,
                v: material.map?.offset.y || 0,
            },
            rotation: material.map?.rotation || 0,
            substanceNess: 0,
            transparency: 0,
        }
        const artworkId = uuid()
        setActiveArtwork({
            blob: new Blob([file]),
            id: id || artworkId,
            name: file.name,
            path: file.webkitRelativePath,
            config,
        })
        setArtwork((artwork) => [
            ...artwork,
            {
                blob: new Blob([file]),
                id: id || artworkId,
                name: file.name,
                path: file.webkitRelativePath,
                config,
            },
        ])

        highlights.map((highlight) => {
            if (highlight.isActive) {
                highlight.mesh.material.map = createMaterial(
                    getLocalImageUrl(file) || '',
                    {
                        offset: {
                            u: highlight.mesh.material.map?.offset?.x || 0,
                            v: highlight.mesh.material.map?.offset?.y || 0,
                        },
                        scale: {
                            u: highlight.mesh.material?.map?.repeat?.x || 0,
                            v: highlight.mesh.material?.map?.repeat?.y || 0,
                        },
                    }
                ).upscale
            }
        })
    }
    function updateArtworkConfig(
        config: {
            offset: {
                u: number
                v: number
            }
            repeat: {
                u: number
                v: number
            }
            rotation: number
            transparency: number
            substanceNess: number
        },
        id: string
    ) {
        console.log({ config })
        highlights.map((highlight) => {
            const material = highlight.mesh.material as MeshCustomMaterial
            if (highlight.isActive && material.map) {
                material.map.offset = new THREE.Vector2(
                    config.offset.u,
                    config.offset.v
                )
                material.map.repeat = new THREE.Vector2(
                    config.repeat.u,
                    config.repeat.v
                )
                material.map.rotation = degToRad(config.rotation)
            }
        })
        setArtwork((artwork) =>
            artwork.map((art) => {
                if (art.id === id) {
                    art.config = config
                }
                return art
            })
        )
        setActiveArtwork((activeArtwork) => {
            if (activeArtwork?.id === id) {
                activeArtwork.config = config
            }
            return activeArtwork
        })
    }
    function deleteArtwork(id: string) {
        highlights.map((highlight) => {
            const material = highlight.mesh.material as MeshCustomMaterial
            if (highlight.isActive) {
                material.map = null
            }
        })
        setArtwork((artwork) => artwork.filter((art) => art.id !== id))
    }
    return {
        uploadArtwork,
        updateArtworkConfig,
        deleteArtwork,
    }
}
