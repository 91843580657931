import * as React from 'react'
import { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Input,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
} from 'src/components/utility'
import TeamCard from './cards/team-card'
import { PerPage } from 'src/helpers/enums'
import { debounce } from 'src/helpers'
import { SearchIco } from 'src/components/svg-icons'
import Pagination from 'src/components/pagination/pagination'
import { useSearchParams } from 'react-router-dom'
import { getTeamsData } from '../function'
import CircleCross from 'src/components/svg-icons/circle-cross-ico'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import useStore from 'src/store/hooks/useStore'
import type { CancelTokenSource } from 'axios'
import { useOrganizationContext } from '../organisation.context'

export default function Teams({
    onLoad,
}: {
    onLoad: (x: number, refetch: (q: string) => void) => void
}) {
    const [currentPage, setCurrentPage] = useState<number>(1)

    const { team, setTeam: setTeams } = useOrganizationContext()
    const [cancelSource, setCancelSource] = useState<
        CancelTokenSource | undefined
    >()
    const { teamListTotalCount } = useStore()
    const [totalTeamCount, setTotalTeamCount] =
        useState<number>(teamListTotalCount)
    const [initialLoader, setInitialLoader] = useState(false)
    const { addDrawerTabOne, clearDrawerTabs } = useStore()
    const [searchParam, setSearchParam] = useSearchParams()
    const [params, setParams] = useState({
        page: searchParam.get('page') || 1,
        sort: searchParam.get('sort') || 'name',
    })
    const [query, setQuery] = useState<string>(searchParam.get('q') || '')
    const sortTeam = searchParam.get('sort')

    function setCurrentPageHandler(page: number) {
        setParams({
            ...params,
            page,
        })
        handleTeamData(query, true, page)
        setCurrentPage(page)
    }
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const searchFn = debounce(handleTeamData, 500, setTimeOutRef, timeOutRef)

    async function handleTeamData(
        search: string = '',
        isSort: boolean = true,
        page: number = 1
    ) {
        searchParam.set('q', search)
        setSearchParam(searchParam)
        const data = await getTeamsData({
            searchParam:
                search.length < 0 ? searchParam.get('q') || '' : search,
            currentPage: page || currentPage,
            setisLoading: (isLoading) => setInitialLoader(isLoading),
            sortParam: isSort ? sortTeam || 'name' : '',
        })
        setCancelSource(data?.cancelSource)
        if (data) {
            onLoad(data.count, (q) => handleTeamData(q))
            setTotalTeamCount(data.count)
            setTeams(data.data)
        }
    }
    const teamMemberRef = React.useRef<{ team: ITeams[] } | null>(null)

    useEffect(() => {
        if (teamMemberRef.current && teamMemberRef.current.team.length > 0)
            setTeams(teamMemberRef.current.team)
    }, [teamMemberRef.current?.team])

    useEffect(() => {
        // if (team.length <= 0) {
        searchFn()
        // }
        if (searchParam.get('drawer') === '1') {
            if (searchParam.get('tab') === 'teams') {
                clearDrawerTabs()
                addDrawerTabOne({
                    id: searchParam.get('id') || '0',
                    type: 'teams',
                })
            } else if (searchParam.get('tab') === 'collaborators') {
                clearDrawerTabs()
                addDrawerTabOne({
                    id: searchParam.get('id') || '0',
                    type: 'collaborators',
                })
            }
        }
    }, [])

    // useEffect(() => {
    //     if (searchParam.get('tab') === 'teams') {
    //         cancelSource?.cancel()
    //         setQuery(searchParam.get('q') || '')
    //     }
    // }, [searchParam.get('q')])

    // useEffect(() => {
    //     if (searchParam.get('tab') === 'teams') {
    //         cancelSource?.cancel()
    //         searchFn(searchParam.get('q') || '')
    //     }
    // }, [params])

    // console.log({ team })

    return (
        <Box mb="48px">
            <Box
                mt="16px"
                display="flex"
                alignItems="center"
                borderRadius="4px"
                py="4px"
                bg="white"
                px="8px"
                width="300px"
                border="solid"
                borderWidth={1}
                borderColor="gray250"
            >
                <SearchIco />
                <Input
                    bg="transparent"
                    border="none"
                    type="text"
                    placeholder="Search"
                    width="100%"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                        searchFn(e.target.value, false)
                    }}
                />
                {query.length > 0 && (
                    <Button
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="transparent"
                        border="none"
                        p="0px"
                        onClick={() => {
                            searchFn('')
                            setQuery('')
                        }}
                    >
                        <CircleCross bg="#778CA2" />
                    </Button>
                )}
            </Box>
            {(!team || team?.length <= 0) && (
                <EntityEmptyState entityName="Teams" />
            )}
            {team && team?.length > 0 && (
                <Box
                    bg={totalTeamCount <= 0 ? 'transparent' : 'white'}
                    border="solid"
                    borderWidth={1}
                    borderColor="gray250"
                    borderRadius="8px"
                    justifyContent="space-around"
                    width="100%"
                    mt="8px"
                >
                    {totalTeamCount > 0 && (
                        <Table width="100%">
                            <Thead
                                border="solid"
                                borderWidth={1}
                                borderColor="gray250"
                                borderRadius="4px"
                                borderRight="none"
                                borderLeft="none"
                                borderTop="none"
                                fontSize={{ md: '12px', lg: '14px' }}
                            >
                                <Tr
                                    color="textSecondaryDarkBlue"
                                    fontFamily="Rubik"
                                >
                                    <Th fontWeight={400} textAlign="left">
                                        Team
                                    </Th>
                                    <Th fontWeight={400} textAlign="left">
                                        No. of Collaborators
                                    </Th>
                                    <Th fontWeight={400} textAlign="left">
                                        No. of Collections
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {team?.map((team: any) => (
                                    <TeamCard
                                        refetchData={() =>
                                            handleTeamData(query)
                                        }
                                        ref={teamMemberRef}
                                        key={team.id}
                                        team={team}
                                        onClickHandler={(data: any) =>
                                            console.log(data)
                                        }
                                    />
                                ))}
                            </Tbody>
                        </Table>
                    )}
                    {totalTeamCount <= 0 && (
                        <EntityEmptyState entityName="Teams Found" />
                    )}
                </Box>
            )}
            {team && team?.length > 0 && (
                <Box display="flex" justifyContent="end">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalTeamCount}
                        pageSize={PerPage}
                        onPageChange={(page: number) =>
                            setCurrentPageHandler(page)
                        }
                    />
                </Box>
            )}
        </Box>
    )
}
