import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DangerModal } from 'src/components'
import { Box, Button, Text } from 'src/components/utility'
import { getFileRequestId, getLocalStorage, useUser } from 'src/helpers'
import {
    DeleteFolderById,
    DeleteLibraryFav,
    DELETE_LIBRARY_FILE,
    InsertLibraryFavourites,
} from 'src/services'
import {
    FetchAllFolders,
    FetchFilesByCategoryId,
    FetchFoldersByCategory,
    LibraryFavourites,
} from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import AppliedFiltersTags from './sub-screens/applied-filters-tags'
import EmptyFolderView from './sub-screens/empty-folder-view'
import { FolderFilesView } from './sub-screens/folders-files-view'
import { AddFolderModal } from './sub-screens/modals/create-new-folder'
import axios from 'axios'
import { LibraryPerPage } from 'src/helpers/enums'
;(window as any).global = window
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer

export const LibraryTabData = () => {
    const { role } = useUser()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [listView, setListView] = useState(false)
    const [deleteFolder, setDeleteFolderModal] = useState(false)
    const [deleteFolderId, setDeleteFolderId] = useState<number>(0)
    const [deleteFileId, setDeleteFileModal] = useState<number>(0)
    const [open, openModal] = useState(false)
    const [editingFolder, setEditingFolder] = useState<any>()
    const [filterObj, setFilterObj] = useState<any>({})

    const [fetchFoldersList, foldersListData] = useLazyQuery(
        FetchFoldersByCategory
    )
    const [fetchAllFoldersList, allFoldersListData] =
        useLazyQuery(FetchAllFolders)
    const [fetchAllFiles, allFilesResponse] = useLazyQuery(
        FetchFilesByCategoryId
    )
    const [fetchLibraryFav, allfavList] = useLazyQuery(LibraryFavourites)
    const [insertFavourite] = useMutation(InsertLibraryFavourites)
    const [removeFavourite] = useMutation(DeleteLibraryFav)
    const [removeFolder] = useMutation(DeleteFolderById)

    const categoryId = searchParams.get(libraryDefaultKeys.categoryId)
    const searchStr = searchParams.get(libraryDefaultKeys.searchStr)
    const sortStr = searchParams.get(libraryDefaultKeys.sort)
    const filesList = allFilesResponse?.data?.library_files || []
    const filesTotalCount =
        allFilesResponse?.data?.library_files_aggregate?.aggregate?.count || 0
    const filtersHash = searchParams.get(libraryDefaultKeys.filters) || ''

    const [currentPage, setCurrentPage] = useState(1)
    const [foldersCurrentPage, setFoldersCurrentPage] = useState(1)

    const foldersList =
        allFoldersListData.data || foldersListData.data
            ? categoryId &&
              categoryId !== libraryDefaultKeys.all &&
              foldersListData.data
                ? foldersListData.data.folders
                : allFoldersListData?.data?.folders || []
            : []

    const foldersTotalCount =
        allFoldersListData.data || foldersListData.data
            ? categoryId &&
              categoryId !== libraryDefaultKeys.all &&
              foldersListData.data
                ? foldersListData.data.folders_aggregate?.aggregate?.count || 0
                : allFoldersListData?.data?.folders_aggregate?.aggregate
                      ?.count || 0
            : 0

    useEffect(() => {
        setFoldersCurrentPage(1)
        setCurrentPage(1)
    }, [])

    const setFiltersProperly = () => {
        let finalFilters: any = ''
        if (filtersHash) {
            const buff = Buffer.from(filtersHash, 'base64')
            const preFilters = buff.toString('ascii')
            if (preFilters) {
                finalFilters = JSON.parse(preFilters)
                setFilterObj(finalFilters)
            } else {
                setFilterObj({})
            }
        } else {
            setFilterObj({})
        }
    }

    useEffect(() => {
        setFiltersProperly()
    }, [filtersHash])

    useEffect(() => {
        if (searchParams.has(libraryDefaultKeys.searchStr)) {
            searchParams.delete(libraryDefaultKeys.searchStr)
            setSearchParams(searchParams)
        }
    }, [])

    useEffect(() => {
        onInit()
    }, [
        categoryId,
        searchStr,
        sortStr,
        filterObj,
        currentPage,
        foldersCurrentPage,
    ])

    const onInit = () => {
        const common = {
            searchStr: searchStr ? `%${searchStr}%` : '%%',
            name: sortStr === libraryDefaultKeys.name ? 'asc' : null,
            created_at:
                !sortStr ||
                sortStr === '' ||
                sortStr === libraryDefaultKeys.dateCreated
                    ? 'desc'
                    : null,
        }

        if (categoryId && categoryId !== libraryDefaultKeys.all) {
            console.log('ADDING CATEGORY ID')
            const folderWhereObj: any = {
                name: { _ilike: common.searchStr },
                deleted_at: { _is_null: true },
                category_id: {
                    _eq: categoryId,
                },
            }

            if (filterObj?.selectedFolders?.length) {
                folderWhereObj['id'] = {
                    _in: filterObj.selectedFolders,
                }
            }

            if (filterObj?.selectedCreators?.length) {
                folderWhereObj['created_by'] = {
                    _in: filterObj.selectedCreators,
                }
            }

            if (filterObj?.selectedTags?.length) {
                if (!folderWhereObj['library_files']) {
                    folderWhereObj['library_files'] = {}
                }
                if (!folderWhereObj['library_files']['tag_relations']) {
                    folderWhereObj['library_files']['tag_relations'] = {}
                }
                folderWhereObj['library_files']['tag_relations']['tag_id'] = {
                    _in: filterObj?.selectedTags,
                }
            }

            if (filterObj?.selectedCompanies?.length) {
                if (!folderWhereObj['library_files']) {
                    folderWhereObj['library_files'] = {}
                }
                folderWhereObj['library_files']['company'] = {
                    _in: filterObj.selectedCompanies,
                }
            }

            if (filterObj?.selectedColors?.length) {
                if (!folderWhereObj['library_files']) {
                    folderWhereObj['library_files'] = {}
                }
                folderWhereObj['library_files']['color'] = {
                    _in: filterObj.selectedColors,
                }
            }

            if (
                !searchStr &&
                !folderWhereObj['created_by'] &&
                !folderWhereObj['id'] &&
                !folderWhereObj['library_files']
            ) {
                folderWhereObj.parent_id = {
                    _is_null: true,
                }
            }

            fetchFoldersList({
                variables: {
                    name: common.name,
                    created_at: common.created_at,
                    limit: foldersCurrentPage * LibraryPerPage,
                    whereObj: folderWhereObj,
                },
                fetchPolicy: 'cache-and-network',
            })

            const fileWhereObj: any = {
                name: { _ilike: common.searchStr },
                deleted_at: { _is_null: true },
                file_type: { _eq: 'document' },
                category_id: {
                    _eq: categoryId,
                },
            }

            if (filterObj?.selectedCreators?.length) {
                if (!fileWhereObj['creator']) {
                    fileWhereObj['creator'] = {}
                }
                fileWhereObj['creator']['id'] = {
                    _in: filterObj.selectedCreators,
                }
            }

            if (filterObj?.selectedColors?.length) {
                fileWhereObj['color'] = {
                    _in: filterObj.selectedColors,
                }
            }

            if (filterObj?.selectedCompanies?.length) {
                fileWhereObj['company'] = {
                    _in: filterObj.selectedCompanies,
                }
            }

            if (filterObj?.selectedTags?.length) {
                if (!fileWhereObj['tag_relations']) {
                    fileWhereObj['tag_relations'] = {}
                }
                fileWhereObj['tag_relations']['tag_id'] = {
                    _in: filterObj?.selectedTags,
                }
            }

            if (
                !searchStr &&
                !fileWhereObj['creator'] &&
                !fileWhereObj['color'] &&
                !fileWhereObj['company'] &&
                !fileWhereObj['tag_relations']
            ) {
                fileWhereObj.folder_id = { _is_null: true }
            }

            fetchAllFiles({
                variables: {
                    name: common.name,
                    created_at: common.created_at,
                    limit: currentPage * LibraryPerPage,
                    whereObj: fileWhereObj,
                },
                fetchPolicy: 'cache-and-network',
            })
        } else {
            fetchLibraryFav({
                variables: {
                    searchStr: searchStr ? `%${searchStr}%` : '%%',
                },
                fetchPolicy: 'cache-and-network',
            })

            const whereObj: any = {
                name: { _ilike: common.searchStr },
                deleted_at: { _is_null: true },
            }

            if (filterObj?.selectedFolders?.length) {
                whereObj['id'] = {
                    _in: filterObj.selectedFolders,
                }
            }

            if (filterObj?.selectedCreators?.length) {
                whereObj['created_by'] = {
                    _in: filterObj.selectedCreators,
                }
            }

            if (filterObj?.selectedTags?.length) {
                if (!whereObj['library_files']) {
                    whereObj['library_files'] = {}
                }
                if (!whereObj['library_files']['tag_relations']) {
                    whereObj['library_files']['tag_relations'] = {}
                }
                whereObj['library_files']['tag_relations']['tag_id'] = {
                    _in: filterObj?.selectedTags,
                }
            }

            if (filterObj?.selectedCompanies?.length) {
                if (!whereObj['library_files']) {
                    whereObj['library_files'] = {}
                }
                whereObj['library_files']['company'] = {
                    _in: filterObj.selectedCompanies,
                }
            }

            if (filterObj?.selectedColors?.length) {
                if (!whereObj['library_files']) {
                    whereObj['library_files'] = {}
                }
                whereObj['library_files']['color'] = {
                    _in: filterObj.selectedColors,
                }
            }

            if (
                !searchStr &&
                !whereObj['id'] &&
                !whereObj['created_by'] &&
                !whereObj['library_files']
            ) {
                whereObj.parent_id = {
                    _is_null: true,
                }
            }

            fetchAllFoldersList({
                variables: {
                    name: common.name,
                    created_at: common.created_at,
                    limit: foldersCurrentPage * LibraryPerPage,
                    whereObj,
                },
                fetchPolicy: 'cache-and-network',
            })

            const fileWhereObj: any = {
                name: { _ilike: common.searchStr },
                deleted_at: { _is_null: true },
                file_type: { _eq: 'document' },
            }

            if (filterObj?.selectedCreators?.length) {
                if (!fileWhereObj['creator']) {
                    fileWhereObj['creator'] = {}
                }
                fileWhereObj['creator']['id'] = {
                    _in: filterObj.selectedCreators,
                }
            }

            if (filterObj?.selectedColors?.length) {
                fileWhereObj['color'] = {
                    _in: filterObj.selectedColors,
                }
            }

            if (filterObj?.selectedCompanies?.length) {
                fileWhereObj['company'] = {
                    _in: filterObj.selectedCompanies,
                }
            }

            if (filterObj?.selectedTags?.length) {
                if (!fileWhereObj['tag_relations']) {
                    fileWhereObj['tag_relations'] = {}
                }
                fileWhereObj['tag_relations']['tag_id'] = {
                    _in: filterObj?.selectedTags,
                }
            }

            if (filterObj?.selectedFolders?.length) {
                fileWhereObj['folder_id'] = {
                    _in: filterObj.selectedFolders,
                }
            }

            if (
                !searchStr &&
                !fileWhereObj['folder_id'] &&
                !fileWhereObj['tag_relations'] &&
                !fileWhereObj['company'] &&
                !fileWhereObj['color'] &&
                !fileWhereObj['creator']
            ) {
                fileWhereObj.folder_id = { _is_null: true }
            }

            fetchAllFiles({
                variables: {
                    name: common.name,
                    created_at: common.created_at,
                    whereObj: fileWhereObj,
                    limit: currentPage * LibraryPerPage,
                },
                fetchPolicy: 'cache-and-network',
            })
        }
    }

    const routerChanged = () => {
        navigate(`/library/addfiles/${categoryId}`)
    }

    useEffect(() => {
        setListView(false)
        if (searchParams.has(libraryDefaultKeys.listview)) {
            let l = searchParams.get(libraryDefaultKeys.listview)
            setListView(l === '1' ? true : false)
        }
    }, [searchParams])

    const _favList = allfavList?.data?.library_favourites || []

    const favList = _favList.map((fav: any) => {
        if (fav.library_file_id) {
            return {
                ...fav,
                favourite_id: fav.id,
                ...fav.library_file,
                type: 'FILE',
            }
        }
        if (!fav.library_file_id && fav.folder_id) {
            return {
                ...fav,
                favourite_id: fav.id,
                ...fav.folder,
                type: 'FOLDER',
            }
        }
    })

    const runFavMutation = (
        folerId: number | null = null,
        fileId: number | null = null
    ) => {
        if ((!fileId && !folerId) || (fileId && folerId)) {
            console.error('Invalid parameters')
        }
        insertFavourite({
            variables: {
                folder_id: folerId,
                library_file_id: fileId,
            },
            onCompleted: () => {
                onInit()
                toast.success('Favourite added successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                console.error('Failed to add fav')
                const message: string = error.message
                if (message.includes('Uniqueness violation')) {
                    toast.error('Folder/File already in favourites.', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                } else {
                    toast.error('Failed to favourites.', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                }
            },
        })
    }

    const runDeleteFavMutation = (id: number) => {
        removeFavourite({
            variables: {
                id: id,
            },
            onCompleted: () => {
                onInit()
                toast.success('Favourite removed successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                console.error('Failed to remove fav')
                console.error(error)
            },
        })
    }

    const runDeleteFolderMutation = (folderId: number) => {
        if (!folderId) {
            return
        }
        removeFolder({
            variables: {
                id: folderId,
                deleted_at: new Date(),
            },
            onCompleted: () => {
                foldersListData.refetch()
                toast.success('Folder deleted successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                const message: string = error.message
                toast.error(message, {
                    className: 'custom-toaster toaster-danger toaster-error',
                })
            },
        })
    }

    const runDeleteFile = async (fileId: number) => {
        try {
            if (!fileId) {
                console.error('Delete file id not found!')
                return
            }

            // delete file request_id needs to be added here
            if (categoryId && categoryId !== libraryDefaultKeys.all) {
                const { request_id } = await getFileRequestId({
                    action: 'DELETE',
                    entity_id: categoryId?.toString(),
                    entity_name: 'category',
                    number_of_files: 1,
                })

                const data = await axios.post(
                    DELETE_LIBRARY_FILE,
                    {
                        request_id: request_id,
                        id: fileId,
                    },
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (data.status === 200 && data.data.success === true) {
                    toast('File deleted successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                } else {
                    toast('Failed to delete file as it is used as asset.', {
                        className: 'custom-toaster toaster-error',
                    })
                }

                onInit()
            } else {
                // Need to generate request even if the category is all hence need to find the category_id of files and
                // generate request_id so that we can delete the file

                let all_category_id
                for (const file of filesList) {
                    if (fileId === file.id) {
                        all_category_id = file.category_id
                    }
                }

                const { request_id } = await getFileRequestId({
                    action: 'DELETE',
                    entity_id: all_category_id?.toString(),
                    entity_name: 'category',
                    number_of_files: 1,
                })

                const data = await axios.post(
                    DELETE_LIBRARY_FILE,
                    {
                        request_id: request_id,
                        id: fileId,
                    },
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (data.status === 200 && data.data.success === true) {
                    toast('File deleted successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                } else {
                    toast('Failed to delete file as it is used as asset.', {
                        className: 'custom-toaster toaster-error',
                    })
                }
                onInit()
            }
        } catch (error) {
            toast('Failed to delete file. Please try again.', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return (
        <>
            <AppliedFiltersTags />

            {(!foldersList.length &&
                !filesList.length &&
                categoryId &&
                categoryId !== libraryDefaultKeys.all) ||
            (!favList.length &&
                !foldersList.length &&
                !filesList.length &&
                categoryId &&
                categoryId === libraryDefaultKeys.all) ? (
                <Box
                    height="calc(100vh - 350px)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box>
                        <EmptyFolderView
                            onlyFiles={!categoryId ? false : true}
                            onlyFolders={!categoryId ? false : true}
                            addFileToFolder={() => {
                                routerChanged()
                            }}
                            openCreateFolderModal={openModal}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    {!(categoryId && categoryId !== libraryDefaultKeys.all) && (
                        <Box mb={'24px'}>
                            <Text
                                fontSize={'16px'}
                                color="textTags"
                                mt="0px"
                                mb="16px"
                            >
                                {' '}
                                Favourites{' '}
                            </Text>
                            {!favList || !favList.length ? (
                                <Text
                                    fontSize={'14px'}
                                    color="textSecondaryDarkBlue"
                                    mt="0px"
                                    mb="16px"
                                >
                                    {' '}
                                    All the favourites files are shown here!{' '}
                                </Text>
                            ) : (
                                <FolderFilesView
                                    deleteFolderModal={(folderId: number) => {
                                        setDeleteFolderId(folderId)
                                        setDeleteFolderModal(true)
                                    }}
                                    runDeleteFavMutation={runDeleteFavMutation}
                                    name="favourites"
                                    runFavMutation={runFavMutation}
                                    deleteFileModal={setDeleteFileModal}
                                    listView={listView}
                                    combinedData={favList}
                                />
                            )}
                        </Box>
                    )}

                    {
                        <Box mb={'24px'}>
                            <Box
                                display="flex"
                                width="100%"
                                mb="16px"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text
                                    fontSize={'16px'}
                                    lineHeight="20px"
                                    color="textTags"
                                    my="0px"
                                >
                                    Files ({filesTotalCount})
                                </Text>

                                {categoryId &&
                                    categoryId !== libraryDefaultKeys.all && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap={'24px'}
                                        >
                                            <Button
                                                height="20px"
                                                px={'0px'}
                                                bg="transparent"
                                                border="none"
                                                fontSize="14px"
                                                color="links"
                                                position="relative"
                                                onClick={() => {
                                                    routerChanged()
                                                }}
                                            >
                                                + Add Files
                                            </Button>
                                        </Box>
                                    )}
                            </Box>
                            {!filesList.length ? (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box>
                                        <EmptyFolderView
                                            onlyFiles={true}
                                            onlyFolders={false}
                                            addFileToFolder={() => {
                                                routerChanged()
                                            }}
                                            openCreateFolderModal={openModal}
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <FolderFilesView
                                    deleteFileModal={setDeleteFileModal}
                                    runDeleteFavMutation={runDeleteFavMutation}
                                    name="mainFoldersFiles"
                                    deleteFolderModal={(folderId: number) => {
                                        setDeleteFolderId(folderId)
                                        setDeleteFolderModal(true)
                                    }}
                                    listView={listView}
                                    files={filesList}
                                    runFavMutation={runFavMutation}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalCount={filesTotalCount}
                                />
                            )}
                        </Box>
                    }

                    <Box mb={'24px'}>
                        <Box
                            display="flex"
                            width="100%"
                            mb="16px"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text
                                fontSize={'16px'}
                                lineHeight="20px"
                                color="textTags"
                                my="0px"
                            >
                                Folders ({foldersTotalCount})
                            </Text>

                            {categoryId &&
                                categoryId !== libraryDefaultKeys.all && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gridColumnGap={'24px'}
                                    >
                                        <Button
                                            height="20px"
                                            bg="transparent"
                                            border="none"
                                            fontSize="14px"
                                            color="links"
                                            px={'0px'}
                                            position="relative"
                                            onClick={() => {
                                                openModal(true)
                                            }}
                                        >
                                            + Create Folder
                                        </Button>
                                    </Box>
                                )}
                        </Box>
                        {!foldersList.length ? (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box>
                                    <EmptyFolderView
                                        onlyFiles={false}
                                        onlyFolders={true}
                                        addFileToFolder={() => {
                                            routerChanged()
                                        }}
                                        openCreateFolderModal={openModal}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <FolderFilesView
                                editFolderModal={setEditingFolder}
                                deleteFileModal={setDeleteFileModal}
                                runDeleteFavMutation={runDeleteFavMutation}
                                name="mainFolders"
                                deleteFolderModal={(folderId: number) => {
                                    setDeleteFolderId(folderId)
                                    setDeleteFolderModal(true)
                                }}
                                listView={listView}
                                folders={foldersList}
                                runFavMutation={runFavMutation}
                                currentPage={foldersCurrentPage}
                                setCurrentPage={setFoldersCurrentPage}
                                totalCount={foldersTotalCount}
                            />
                        )}
                    </Box>
                </>
            )}

            {open && (
                <AddFolderModal
                    isModal={open}
                    setModal={openModal}
                    refetchFolders={() => {
                        foldersListData.refetch()
                    }}
                />
            )}

            {editingFolder && (
                <AddFolderModal
                    isModal={editingFolder ? true : false}
                    setModal={setEditingFolder}
                    edit={true}
                    existingFolder={editingFolder}
                    refetchFolders={() => {
                        foldersListData.refetch()
                    }}
                />
            )}

            {deleteFolder && (
                <DangerModal
                    isModal={deleteFolder}
                    title="Delete Folder"
                    heading="Are you sure you want to delete the folder?"
                    subHeading="By deleting a folder, all the folders and files included will also be delete and cannnot be retrieved."
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onClose={() => {
                        setDeleteFolderModal(false)
                    }}
                    onRemove={() => {
                        runDeleteFolderMutation(deleteFolderId)
                    }}
                />
            )}

            {deleteFileId ? (
                <DangerModal
                    isModal={true}
                    title="Delete File"
                    heading="Are you sure you want to delete the file?"
                    subHeading=""
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onClose={() => {
                        setDeleteFileModal(0)
                    }}
                    onRemove={() => {
                        runDeleteFile(deleteFileId)
                    }}
                />
            ) : null}
        </>
    )
}
