export const WarningIco = ({ color = '#FF2C18' }: { color?: string }) => (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.334A5.333 5.333 0 1 0 7 1.667a5.333 5.333 0 0 0 0 10.667Zm0 1.333A6.667 6.667 0 1 0 7 .334a6.667 6.667 0 0 0 0 13.333Zm.833-4.166a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0ZM7 3.667a.833.833 0 0 0-.833.834V7a.833.833 0 1 0 1.666 0V4.5A.833.833 0 0 0 7 3.667Z"
            fill={color}
        />
    </svg>
)
