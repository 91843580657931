import { useState, useRef } from 'react'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Box } from 'src/components/utility'
import { DropDownActions } from './dropdown-action'

export const ManagerDropdown = ({
    managers,
    setManagers,
}: {
    managers: { id: number; name: string }[]
    setManagers: ([{ id, name }]: { id: number; name: string }[]) => void
}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const dropdownButton = useRef<any>(null)

    const isManagersFilterActive = managers && managers.length > 0

    return (
        <Box
            id="collection-manager-dropdown"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap="29px"
            position="relative"
        >
            <Box
                ref={dropdownButton}
                onClick={() => setIsDropDownActive(!isDropDownActive)}
            >
                <IconDropdownButton isActive={isManagersFilterActive}>
                    {'Choose Collaborator'}{' '}
                    {isManagersFilterActive
                        ? managers.length === 1
                            ? `: ${managers[0].name}`
                            : `: ${managers[0].name}+${managers.length - 1}`
                        : ''}
                </IconDropdownButton>
            </Box>

            {isDropDownActive && (
                <DropDownActions
                    setIsDropdownActive={setIsDropDownActive}
                    managers={managers}
                    setManagers={(data) => setManagers(data)}
                    active={isDropDownActive}
                />
            )}
        </Box>
    )
}
