import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { DeleteProductVariant } from 'src/services/graphql/entities/product/product.mutations'

export function useDeleteProductVariant() {
    const [deleteProductMutation, _] = useMutation(DeleteProductVariant)

    function deleteProductVariant({
        variantId,
        onComplete,
        refetchQueries,
    }: {
        variantId: number
        refetchQueries?: InternalRefetchQueriesInclude
        onComplete?: () => void
    }) {
        deleteProductMutation({
            variables: {
                id: variantId,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
            onError: (e) => {
                toast('Error occurred while deleting product', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }
    return { deleteProductVariant }
}
