
export const libraryDefaultKeys = {
    tab: 'tab',
    tabNamePrefix: 'tab_',
    tabName: 'tabName',
    showFolderDetails: 'showFolderDetails',
    showFileDetails: 'showFileDetails',
    folderId: 'folder_id',
    all: 'M1lty', // UID for all tab to prevent renaming
    categoryId: 'category_id',
    listview: 'listview',
    searchStr: 'searchStr',
    name: 'Name',
    dateCreated: 'Date Created',
    sort: 'sort',
    filters: 'filters',
    showActivity: 'showActivity',
} as const
