export function UserWarningIco() {
    return (
        <svg
            width="17"
            height="19"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.39453 7.66597L5.39456 7.66598C5.82323 7.84731 6.27826 7.93952 6.74687 7.93952C7.21547 7.93952 7.6705 7.84731 8.09917 7.66598L8.09924 7.66595C8.5124 7.49084 8.88399 7.24044 9.20293 6.92117C9.52134 6.60244 9.772 6.23048 9.94695 5.81634L9.94696 5.81632C10.1281 5.38723 10.2202 4.93177 10.2202 4.46273C10.2202 3.99369 10.1281 3.53823 9.94696 3.10914L9.94693 3.10908C9.772 2.69552 9.52187 2.32356 9.20293 2.00429C8.88453 1.68555 8.51293 1.43463 8.0992 1.25949L8.09917 1.25948C7.6705 1.07815 7.21547 0.985937 6.74687 0.985937C6.27826 0.985937 5.82323 1.07815 5.39456 1.25948L5.39449 1.25951C4.98133 1.43462 4.60974 1.68502 4.2908 2.00429C3.97239 2.32302 3.72174 2.69498 3.54679 3.10912L3.54678 3.10914C3.36564 3.53823 3.27354 3.99369 3.27354 4.46273C3.27354 4.93177 3.36564 5.38723 3.54678 5.81632L3.5468 5.81638C3.72173 6.22994 3.97186 6.6019 4.2908 6.92117C4.60921 7.2399 4.9808 7.49083 5.39453 7.66597ZM3.97329 4.46273C3.97329 2.93152 5.21748 1.6862 6.74687 1.6862C8.27626 1.6862 9.52045 2.93152 9.52045 4.46273C9.52045 5.99394 8.27626 7.23926 6.74687 7.23926C5.21748 7.23926 3.97329 5.99394 3.97329 4.46273Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.2"
            />
            <path
                d="M1.95874 9.30362L1.95869 9.30368C1.62738 9.63584 1.36622 10.0228 1.18424 10.454C0.995589 10.9006 0.9 11.3751 0.9 11.8631V13.8467C0.9 14.3155 1.28101 14.6971 1.74963 14.6971H7.36186C7.55537 14.6971 7.71123 14.5399 7.71123 14.3469C7.71123 14.1537 7.55461 13.9968 7.36135 13.9968H1.74913C1.66652 13.9968 1.59925 13.9296 1.59925 13.8467V11.8636C1.59925 10.2539 2.90741 8.94452 4.51526 8.94452H8.97806C9.67054 8.94452 10.3406 9.19075 10.8652 9.63846L10.8652 9.63847C11.0121 9.76386 11.2332 9.74639 11.3586 9.59921C11.4837 9.45222 11.4663 9.23109 11.3194 9.10566C11.0002 8.83295 10.6393 8.6204 10.2476 8.47326L10.2476 8.47325C9.84195 8.32091 9.41474 8.24375 8.97806 8.24375H4.51526C4.0277 8.24375 3.55417 8.33945 3.10796 8.52831C2.67723 8.71045 2.29064 8.97139 1.95874 9.30362Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.2"
            />
            <path
                d="M6.92295 16.7458L6.92297 16.7457L10.5442 11.1728L10.5443 11.1726C10.7248 10.8957 11.0342 10.732 11.3712 10.732C11.7081 10.732 12.0176 10.8957 12.1981 11.1726L12.1982 11.1728L15.8194 16.7457L15.8194 16.7458C16.0052 17.032 16.0194 17.3823 15.8574 17.6813L15.8573 17.6815C15.6872 17.9934 15.3555 18.1859 14.9924 18.1859H7.74896C7.38642 18.1859 7.05402 17.9935 6.88491 17.6813M6.92295 16.7458L6.88491 17.6813C6.88491 17.6813 6.88491 17.6813 6.88491 17.6813M6.92295 16.7458C6.73717 17.032 6.72298 17.3823 6.88491 17.6813M6.92295 16.7458L6.88491 17.6813M15.2319 17.1274L15.2319 17.1273L11.6112 11.555C11.5627 11.4805 11.4741 11.4323 11.3707 11.4323C11.2672 11.4323 11.1786 11.4805 11.1301 11.555L7.50961 17.1271C7.50959 17.1271 7.50956 17.1272 7.50954 17.1272C7.46402 17.1978 7.46088 17.2758 7.49961 17.3475L15.2319 17.1274ZM15.2319 17.1274C15.2776 17.1976 15.2806 17.276 15.2418 17.3473L15.2319 17.1274Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.2"
            />
            <path
                d="M11.0475 15.4253C11.0475 15.6036 11.192 15.7484 11.3704 15.7484C11.5488 15.7484 11.6933 15.6036 11.6933 15.4253V13.3052C11.6933 13.1269 11.5488 12.982 11.3704 12.982C11.192 12.982 11.0475 13.1269 11.0475 13.3052V15.4253Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.2"
            />
            <path
                d="M11.0208 16.7541C11.0208 16.9473 11.1775 17.1042 11.3707 17.1042C11.564 17.1042 11.7206 16.9473 11.7206 16.7541V16.629C11.7206 16.4358 11.564 16.2789 11.3707 16.2789C11.1775 16.2789 11.0208 16.4358 11.0208 16.629V16.7541Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.2"
            />
        </svg>
    )
}
