export function isValidColor(color: string): boolean {
    // Preprocess to handle RGB and RGBA values without 'rgb()' prefix
    if (/^\d{1,3},\s*\d{1,3},\s*\d{1,3}$/.test(color)) {
        color = `rgb(${color})`
    } else if (/^\d{1,3},\s*\d{1,3},\s*\d{1,3},\s*(0|1|0?\.\d+)$/.test(color)) {
        color = `rgba(${color})`
    }

    // Check for Hex color format
    if (color.startsWith('#')) {
        return /^#([a-f\d]{3,4}){1,2}$/i.test(color)
    }
    // Check for RGB color format
    else if (color.startsWith('rgb(')) {
        return /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/.test(color)
    }
    // Check for RGBA color format
    else if (color.startsWith('rgba(')) {
        return /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$/.test(
            color
        )
    }
    // Attempt to set the color to a div and check if it was accepted
    else {
        const div = document.createElement('div')
        div.style.color = color
        return div.style.color !== ''
    }
}
