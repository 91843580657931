import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
    Authentication,
    Checkbox,
    InlineError,
    Loader,
    Modal,
} from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { PasswordHidden, PasswordVisible } from 'src/components/svg-icons'
import { Box, Button, Input, Text } from 'src/components/utility'
import { debugLog, getLocalStorage, setLocalStorage } from 'src/helpers'
import { loginHandler } from './functions'
import axios from 'axios'
import { JOIN_WAITLIST } from 'src/services/api/endpoints'

const Login = ({ setToken }: { setToken: (token: string) => void }) => {
    const navigate = useNavigate()
    const [isRememberMe, setIsRememberMe] = useState(true)
    const { state }: { state: any } = useLocation()
    const [IsPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [isContactModal, setIsContactModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [userInputs, setUserInputs] = useState({
        email: '',
        password: '',
        name: '',
    })
    const [isAuthenticated, setIsAuthenticated] = useState<string>('')

    useEffect(() => {
        const user = getLocalStorage('user')
        const authToken = getLocalStorage('authToken')
        if (user && authToken && user.length > 0 && authToken.length > 0) {
            navigate('/')
        }
    }, [])

    async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (userInputs.email.length <= 0)
            return setIsAuthenticated('Enter your email address.')
        if (!userInputs.email.match(/^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            return setIsAuthenticated('Invalid email address.')
        }
        if (userInputs.password.length <= 0) {
            return setIsAuthenticated('Please input a password.')
        }

        try {
            const user = await loginHandler(
                userInputs,
                setToken,
                setIsAuthenticated,
                setIsLoading,
                isRememberMe
            )

            if (user) {
                const userDetails = JSON.stringify({
                    name: user.name,
                    email: user.email,
                    role: user.role,
                    profileImage: user.profilePic || false,
                    id: user.id,
                    organization_id: user.organization_id,
                    created_at: user?.created_at || null,
                })

                if (isRememberMe) {
                    setLocalStorage('user', userDetails)
                } else {
                    window.sessionStorage.setItem('user', userDetails)
                }
                if (user.is_new_user && user?.role === 'admin') {
                    navigate('/add-members')
                } else {
                    if (state?.from) {
                        if (state?.from?.pathname) {
                            if (state.from.search) {
                                navigate(
                                    `${state.from.pathname}${state?.from?.search}`
                                )
                            } else {
                                navigate(state?.from?.pathname)
                            }
                        }
                    } else {
                        navigate('/')
                    }
                }
            }
        } catch (e) {
            debugLog(e)
        }
    }

    return (
        <>
            <Authentication>
                <form onSubmit={handleLogin}>
                    <Box
                        minWidth="360px"
                        display="flex"
                        gridRowGap="16px"
                        flexDirection="column"
                    >
                        <Text
                            fontFamily="Rubik"
                            fontWeight={500}
                            color="primary"
                            fontSize="32px"
                            textAlign="left"
                        >
                            Login to Virtu!
                        </Text>

                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gridRowGap="12px"
                            fontSize="14px"
                        >
                            <Text
                                mt="0px"
                                mb="0px"
                                fontFamily="Rubik"
                                color="primary"
                                textAlign="left"
                                width="100%"
                            >
                                Email Address
                            </Text>
                            <Input
                                placeholder="Enter your email"
                                bg="white"
                                borderRadius="4px"
                                px="16px"
                                py="16px"
                                minWidth="360px"
                                fontSize="14px"
                                border="solid"
                                borderWidth={1}
                                borderColor="gray350"
                                className="placeholder-darkgray"
                                onKeyDown={() => setIsAuthenticated('')}
                                id="login-email-input"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setUserInputs({
                                        ...userInputs,
                                        email: e.target.value,
                                    })
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridRowGap="12px"
                            fontSize="14px"
                            width="100%"
                            position="relative"
                        >
                            <Text
                                mt="0px"
                                mb="0px"
                                fontFamily="Rubik"
                                color="primary"
                            >
                                Password
                            </Text>
                            <Box
                                bg="white"
                                border="solid"
                                borderWidth={1}
                                borderColor="gray350"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                pr="16px"
                            >
                                <Input
                                    placeholder="Enter your password"
                                    bg="white"
                                    borderRadius="4px"
                                    px="16px"
                                    py="16px"
                                    fontSize="14px"
                                    className="placeholder-darkgray password"
                                    color="primary"
                                    border="none"
                                    id="login-password-input"
                                    onKeyDown={() => setIsAuthenticated('')}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setUserInputs({
                                            ...userInputs,
                                            password: e.target.value,
                                        })
                                    }}
                                    type={
                                        IsPasswordVisible ? 'text' : 'password'
                                    }
                                    width="100%"
                                />
                                <Button
                                    bg="transparent"
                                    border="none"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    id="hide-password-btn"
                                    onClick={() => {
                                        setIsPasswordVisible(!IsPasswordVisible)
                                    }}
                                    type="button"
                                >
                                    {!IsPasswordVisible ? (
                                        <PasswordHidden />
                                    ) : (
                                        <PasswordVisible />
                                    )}
                                </Button>
                            </Box>
                            {isAuthenticated && (
                                <InlineError
                                    width="400px"
                                    text={isAuthenticated}
                                />
                            )}
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Checkbox
                                checkBoxId="remember-me"
                                label="Remember me"
                                checked={isRememberMe}
                                onChange={(isChecked) =>
                                    setIsRememberMe(isChecked)
                                }
                            />
                            <Link
                                id="forgot-password-btn"
                                to="/forgot-password"
                                className="links"
                            >
                                Forgot password?
                            </Link>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            mt="24px"
                        >
                            <ColoredButton
                                variant="primary"
                                disabled={isLoading}
                                onClick={() => {}}
                                type="submit"
                                id="login-btn"
                            >
                                <Box
                                    display="flex"
                                    gridColumnGap="8px"
                                    color="white"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {isLoading && <Loader />} Login
                                </Box>
                            </ColoredButton>

                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gridColumnGap="4px"
                                mt="12px"
                            >
                                {/* <Text
                                    textAlign="center"
                                    fontFamily="Rubik"
                                    color="textSecondary"
                                >
                                    Don&apos; t have an account?
                                </Text> */}
                                <a
                                    aria-disabled
                                    className="links cursor-pointer"
                                    onClick={() => setIsContactModal(true)}
                                >
                                    Need an account?
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Authentication>
            <ContactModal
                isModal={isContactModal}
                setIsModal={(_) => setIsContactModal(_)}
            />
        </>
    )
}
export default Login

function ContactModal({
    isModal,
    setIsModal,
}: {
    isModal: boolean
    setIsModal: (x: boolean) => void
}) {
    const [error, setError] = useState<{
        status: 'NONE' | 'SUCCESS' | 'ERROR'
        errorText: string
    }>({
        status: 'NONE',
        errorText: '',
    })
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    async function joinWaitlist(email: string) {
        if (email.length <= 0)
            return setError({
                errorText: 'Please enter your email address.',
                status: 'ERROR',
            })
        if (
            email.length <= 0 ||
            !email.match(/^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ) {
            return setError({
                errorText: 'Invalid email address.',
                status: 'ERROR',
            })
        }
        setIsLoading(true)
        try {
            const { data } = await axios.post(JOIN_WAITLIST, {
                email,
            })
            setIsLoading(false)
            setError({
                errorText:
                    'Thank you for your interest! We have added you to our waitlist. Stay tuned!',
                status: 'SUCCESS',
            })
        } catch (e) {
            setError({
                errorText:
                    'An error occurred while submitting email, Please try again later.',
                status: 'ERROR',
            })
            setIsLoading(false)
        }
        setEmail('')
    }

    return (
        <Modal
            defaultWidth="550px"
            width="auto"
            title="Need an account?"
            onClick={() => {}}
            isModal={isModal}
            onClose={() => {
                setIsModal(false)
                setError({ errorText: '', status: 'NONE' })
            }}
            primaryButtonTitle="Close"
            disableActions
            secondaryButtonTitle=""
        >
            <Box p="24px" pr="12px" pt="12px">
                <Text fontSize="16px" fontWeight={400}>
                    If you are part of an organization please contact your
                    admin. If not,
                </Text>
                <Text fontSize="16px" fontWeight={400}>
                    Join the waitlist and our support team will reach out to you
                    shortly.
                </Text>
                <Box display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        // flexDirection="column"
                        // justifyContent=""
                        alignItems="center"
                        gridRowGap="12px"
                        gridColumnGap={'8px'}
                        fontSize="14px"
                        bg="white"
                        border="solid"
                        justifyContent="space-between"
                        borderWidth={1}
                        borderColor="gray350"
                        width={'100%'}
                        borderRadius="4px"
                        pr="8px"
                    >
                        <Input
                            placeholder="Enter your email"
                            bg="transparent"
                            borderRadius="4px"
                            px="16px"
                            py="16px"
                            minWidth="300px"
                            fontSize="14px"
                            border="none"
                            className="placeholder-darkgray"
                            value={email}
                            onKeyDown={() =>
                                setError({ status: 'NONE', errorText: '' })
                            }
                            id="waitlist-email-input"
                            required
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setEmail(e.target.value)
                            }}
                        />
                        <ColoredButton
                            disabled={isLoading}
                            loader={isLoading}
                            variant="primary"
                            onClick={() => joinWaitlist(email)}
                        >
                            Join
                        </ColoredButton>
                        {/* <Box> */}
                        {/* style={{ padding: "6px 18px", fontSize: "14px" }} */}

                        {/* </Box> */}
                    </Box>
                    <Box mt="8px">
                        {error.status === 'ERROR' && (
                            <InlineError
                                text={error.errorText}
                                width="auto"
                            ></InlineError>
                        )}
                        {error.status === 'SUCCESS' && (
                            <Text
                                color="primary"
                                mt="0px"
                                mb="0px"
                                fontSize="14px"
                                fontFamily="Rubik"
                                lineHeight="17px"
                                textAlign="left"
                            >
                                {error.errorText}
                            </Text>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
