import { Box, Text } from 'src/components/utility'
import { LogoSm } from 'src/assets/svgs-components'
import { Facebook, Linkedin, Instagram } from 'src/components/svg-icons'
export const Authentication = ({ children }: { children: JSX.Element }) => {
    return (
        <Box
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                color="secondary"
                className="auth-left-panel"
                fontFamily="Rubik"
                width="45%"
                height="100%"
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                pl="68px"
                pr="36px"
            >
                <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <LogoSm />
                    <Text
                        color="white"
                        fontWeight={300}
                        fontFamily="Rubik"
                        lineHeight="34px"
                        mt="16px"
                    >
                        Manage your 3D development process from start to finish.
                        VIRTU automates the entire process to allow you to
                        effectively integrate and scale 3D product development.
                    </Text>
                    <Text
                        color="white"
                        fontWeight={300}
                        fontFamily="Rubik"
                        lineHeight="34px"
                    >
                        Start your journey into the future today!
                    </Text>
                </Box>

                <Box
                    display="flex"
                    justifyContent="end"
                    flexDirection="column"
                    height="100%"
                    mb="56px"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gridRowGap="24px"
                    >
                        <Box display="flex" gridColumnGap="16px">
                            <Linkedin />
                            <Facebook />
                            <Instagram />
                        </Box>
                        <Box
                            my="16px"
                            color="white"
                            fontWeight={300}
                            fontFamily="Rubik"
                            fontSize="12px"
                            display="flex"
                        >
                            <Text my="0px" mr="16px">
                                Terms of Use . Privacy
                            </Text>{' '}
                            © {new Date().getFullYear()} All rights reserved -
                            Virtu
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                width="100%"
                height="100%"
                className="auth-body"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {children}
            </Box>
        </Box>
    )
}
