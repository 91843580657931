import { useState } from 'react'
import { IconButton } from '../buttons/icon-button'
import { PlusIcoSm } from '../svg-icons/plus-ico-sm'
import { Box, Text } from '../utility'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import { useUser } from 'src/helpers'

export function TodosHeader() {
    const [isCreateTodoModalOpen, setIsCreateTodoModalOpen] =
        useState<boolean>(false)
    const { userId } = useUser()
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            bg="#fff"
            alignItems="center"
            px="24px"
        >
            <Text
                fontFamily="Rubik"
                fontSize="24px"
                color="primary"
                position="relative"
                my={'0px'}
                lineHeight="32px"
            >
                My To-dos
            </Text>
            <Box position="relative">
                <IconButton
                    id="todos-createnew-button"
                    variant={'primary'}
                    iconLeft={<PlusIcoSm color="#ffffff" />}
                    onClick={() => setIsCreateTodoModalOpen(true)}
                >
                    Create New
                </IconButton>
            </Box>
            {isCreateTodoModalOpen && (
                <CreateTodoModal
                    isOpen={isCreateTodoModalOpen}
                    onClose={() => setIsCreateTodoModalOpen(false)}
                    assigneeId={Number(userId)}
                />
            )}
        </Box>
    )
}
