import React, { useEffect, useState } from 'react'
import { debounce } from 'src/helpers'
import styled from 'styled-components'
import { StrokedArrow } from '../svg-icons'
import { Box, Button, Input } from '../utility'

const CounterBoxBorder = styled(Box)`
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
`
const CounterBoxBorderActions = styled(Box)`
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
`

export const Counter = ({
    count,
    counterIncrease,
    counterDecrease,
    setCounter,
    disabled = false,
}: {
    count: number
    counterIncrease: () => void
    counterDecrease: () => void
    setCounter: (counter: number) => void
    disabled?: boolean
}) => {
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [localCount, setLocalCount] = useState(count)
    const debounceFn = debounce(
        (counter: number) => {
            setCounter(counter)
        },
        400,
        setTimeOutRef,
        timeOutRef
    )
    useEffect(() => {
        setLocalCount(count)
    }, [count])

    return (
        <Box
            display="flex"
            border="none"
            borderColor="gray350"
            borderRadius="4px"
            borderWidth={1}
            bg="white"
            width="max-content"
            className={'duration-input'}
        >
            <Input
                autoComplete="false"
                bg="white"
                py="8px"
                type="number"
                width="40px"
                border="solid"
                borderColor="gray350"
                borderRight="none"
                borderWidth={1}
                textAlign="center"
                value={localCount}
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLocalCount(Number(e.target.value))
                    debounceFn(Number(e.target.value))
                }}
            ></Input>
            <CounterBoxBorderActions
                border="solid"
                borderColor="gray350"
                borderRadius="4px"
                borderWidth={1}
                alignItems="center"
                flexDirection="column"
                display="flex"
                justifyContent="center"
                px="4px"
                py="4px"
                bg="white"
            >
                <Button
                    display="flex"
                    alignItems="center"
                    style={{ transform: 'rotate(-90deg)' }}
                    bg="transparent"
                    border="none"
                    py="0px"
                    disabled={disabled}
                    onClick={counterIncrease}
                >
                    <StrokedArrow />
                </Button>
                <Button
                    display="flex"
                    alignItems="center"
                    style={{ transform: 'rotate(90deg)' }}
                    bg="transparent"
                    border="none"
                    py="0px"
                    disabled={disabled}
                    onClick={count > 0 ? counterDecrease : () => {}}
                >
                    <StrokedArrow />
                </Button>
            </CounterBoxBorderActions>
        </Box>
    )
}
