import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { debounce, type statuses } from 'src/helpers'
import { DeleteCollectionByID } from 'src/services'
import {
    UpdateCollectionDuedate,
    UpdateCollectionFields,
    UpdateCollectionStatus,
    UpdateCollectionTeamAndItsProductsTeam,
} from 'src/services/graphql/mutations/update-collection'
import { UpdateProductTeamId } from 'src/services/graphql/mutations/update-product-status'

export function useUpdateCollection() {
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [UpdateProductTeamIdMutation] = useMutation(UpdateProductTeamId)
    const [updateCollectionStatusMutation] = useMutation(UpdateCollectionStatus)
    const [updateCollectionDueDateMutation] = useMutation(
        UpdateCollectionDuedate
    )
    const [updateCollectionTeamAndItsProductsTeam] = useMutation(
        UpdateCollectionTeamAndItsProductsTeam
    )
    const [updateCollectionMutation, updatedCollection] = useMutation<{
        update_collections_by_pk: {
            id: number
            name: string
            status: string
            tags: any[]
            team_id: string
        }
    }>(UpdateCollectionFields)

    const updateCollection = async (
        collectionId: number,
        fields: {
            name?: string
            description?: string
            due_date?: string
            team_id?: number | null
            status?: (typeof statuses)[keyof typeof statuses]
            manager_id?: number | null
            tags?: Array<any>
            brand?: string
            company?: string
        },
        refetchQueries?: InternalRefetchQueriesInclude,
        onComplete?: () => void
    ) => {
        if (fields.team_id) {
            UpdateProductTeamIdMutation({
                variables: {
                    id: collectionId,
                    product_payload: {
                        team_id: fields.team_id,
                    },
                },
            })
        }

        updateCollectionMutation({
            variables: {
                id: collectionId,
                payload: fields,
            },
            refetchQueries,
            onCompleted: () => {
                console.log(onComplete)
                if (onComplete) onComplete()
            },
        })
    }

    const debouncedUpdateCollection = debounce(
        updateCollection,
        1500,
        setTimeOutRef,
        timeOutRef
    )

    function updateCollectionStatus({
        id,
        status,
        onComplete,
    }: {
        id: number
        status: string
        onComplete?: () => void
    }) {
        updateCollectionStatusMutation({
            variables: {
                id: id,
                status: status,
            },
            refetchQueries: ['getDashboardCollections', 'fetchCollectionById'],
            optimisticResponse: {
                update_collections_by_pk: {
                    id: id,
                    status: status,
                    __typename: 'collections',
                },
            },
            onCompleted: () => {
                onComplete && onComplete()
            },
        })
    }

    function updateCollectionDueDate({
        id,
        dueDate,
        onComplete,
    }: {
        id: number
        dueDate: string
        onComplete?: () => void
    }) {
        updateCollectionDueDateMutation({
            variables: {
                id: id,
                due_date: dueDate,
            },
            refetchQueries: ['getDashboardCollections', 'fetchCollectionById'],
            optimisticResponse: {
                update_collections_by_pk: {
                    id: id,
                    due_date: dueDate,
                    __typename: 'collections',
                },
            },
            onCompleted: () => {
                onComplete && onComplete()
            },
        })
    }

    function updateCollectionTeamAndItsProductsTeamHandler({
        teamId,
        collectionId,
        onCompleted,
        refetchQueries,
    }: {
        teamId: number | null
        collectionId: number
        onCompleted?: () => void
        refetchQueries?: InternalRefetchQueriesInclude
    }) {
        updateCollectionTeamAndItsProductsTeam({
            variables: {
                team_id: teamId,
                collection_id: collectionId,
            },
            refetchQueries,
            onCompleted,
        })
    }

    return {
        updateCollection,
        updatedCollection,
        debouncedUpdateCollection,
        updateCollectionStatus,
        updateCollectionDueDate,
        updateCollectionTeamAndItsProductsTeamHandler,
    }
}

export function useDeleteCollection() {
    const [deleteCollectionMutation, _] = useMutation(DeleteCollectionByID)

    function deleteCollection(
        collectionId: number,
        refetchQueries?: InternalRefetchQueriesInclude,
        onComplete?: () => void
    ) {
        deleteCollectionMutation({
            variables: {
                id: collectionId,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }
    return { deleteCollection }
}
