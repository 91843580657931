import { gql } from '@apollo/client'

export const UpdateProductStatus = gql`
    mutation updateProductMutation(
        $id: bigint!
        $status: status_enum
        $completed_at: timestamptz
    ) {
        update_products_by_pk(
            pk_columns: { id: $id }
            _set: { status: $status, completed_at: $completed_at }
        ) {
            status
        }
    }
`

export const UpdateProductTeamId = gql`
    mutation updateProductTeamId(
        $product_payload: products_set_input
        $id: bigint!
    ) {
        update_products(
            where: { collection_id: { _eq: $id } }
            _set: $product_payload
        ) {
            returning {
                team_id
            }
        }
    }
`
