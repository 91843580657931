import { Box, Image } from '../utility'
import React from 'react'

const ProfileImgMemo = ({
    src,
    name,
    style,
    className,
    size,
    highlight = false,
    fontSize,
    id,
}: {
    src: string
    name: string
    style?: any
    className?: string
    size?: string
    fontSize?: FontSize
    highlight?: boolean
    id?: string
}) => {
    if (src.length <= 0) {
        return (
            <Box
                title={name}
                aria-label={name}
                borderRadius="50%"
                width={size || '32px'}
                height={size || '32px'}
                minWidth={size || '32px'}
                minHeight={size || '32px'}
                bg="#027AC3"
                border={highlight ? '2px solid #fff' : 'none'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                style={style}
                className={className}
                fontSize={fontSize || '16px'}
                fontFamily="Rubik"
                id={id || undefined}
            >
                {name?.slice(0, 1).toUpperCase()}
            </Box>
        )
    }
    return (
        <Image
            aria-label={name}
            title={name}
            src={src}
            border={highlight ? '2px solid #fff' : 'none'}
            borderRadius="50%"
            width={size || '32px'}
            height={size || '32px'}
            style={{
                minWidth: size || '32px',
                ...style,
            }}
            className={className + ' img-clear'}
            id={id || undefined}
        />
    )
}

export const ProfileImg = React.memo(ProfileImgMemo)
