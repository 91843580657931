import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { Dots } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { roles, useUser } from 'src/helpers'
import { DeleteCategoryModal } from './modals/delete-category'
import { RenameCategoryModal } from './modals/rename-category'

export const LibraryTitle = ({
    fetchCategories,
}: {
    fetchCategories: () => void
}) => {
    const { role } = useUser()
    const [isDropDown, setIsDropdown] = useState(false)
    const location = useLocation()
    const dropDownRef = useRef<any>(null)
    const navigate = useNavigate()
    const [open, openModal] = useState(false)
    const [openRenameCategoryModal, setOpenRenameCategoryModal] =
        useState(false)
    const [searchParams] = useSearchParams()

    const dropItems =
        roles.designer === role
            ? [
                  // { name: "View Archived", id: "VIEW_ARCHIVED" }
              ]
            : [
                  // { name: "View Archived", id: "VIEW_ARCHIVED" },
                  // { name: "User Permissions", id: "USER_PERMISSIONS" },
                  { name: 'Rename Category', id: 'RENAME_CATEGORY' },
                  { name: 'Delete Category', id: 'DELETE_CATEGORY' },
              ]

    function disableDropdown(event: any) {
        if (dropDownRef !== null && dropDownRef.current !== null) {
            const isClickInsideElement = dropDownRef.current.contains(
                event.target
            )
            if (
                !isClickInsideElement &&
                event.target?.tagName !== 'BUTTON' &&
                event.target?.tagName !== 'svg' &&
                event.target?.tagName !== 'path'
            ) {
                setIsDropdown(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)

        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text fontSize={'24px'} my="0px" mr="12px">
                    My Library
                </Text>

                {roles.designer !== role && (
                    <Box position="relative">
                        {searchParams.get('tab') &&
                            searchParams.get('tab') !== 'tab_M1ltyt' && (
                                <Button
                                    onClick={() => setIsDropdown(!isDropDown)}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="none"
                                    py="8px"
                                    borderRadius="4px"
                                    px="8px"
                                    bg={!isDropDown ? 'transparent' : 'gray250'}
                                >
                                    <Dots active={isDropDown} vertical />
                                </Button>
                            )}

                        {isDropDown && (
                            <Dropdown
                                ref={dropDownRef}
                                top="42px"
                                active={isDropDown}
                                right="0px"
                                left="unset"
                            >
                                <>
                                    {dropItems.map((item: any) => {
                                        if (
                                            item.id === 'RENAME_CATEGORY' ||
                                            !searchParams.get('tab') ||
                                            searchParams.get('tab') ===
                                                'tab_M1ltyt'
                                        ) {
                                            return <></>
                                        }
                                        return (
                                            <DropdownButtonWrapper
                                                onClick={() => {
                                                    setIsDropdown(false)
                                                    if (
                                                        item.id ===
                                                        'VIEW_ARCHIVED'
                                                    ) {
                                                        navigate(
                                                            '/library/archived'
                                                        )
                                                    }
                                                    if (
                                                        item.id ===
                                                        'USER_PERMISSIONS'
                                                    ) {
                                                        navigate(
                                                            '/library/user-permissions'
                                                        )
                                                    }
                                                    if (
                                                        item.id ===
                                                        'DELETE_CATEGORY'
                                                    ) {
                                                        openModal(true)
                                                    }
                                                    if (
                                                        item.id ===
                                                        'RENAME_CATEGORY'
                                                    ) {
                                                        if (
                                                            location.state
                                                                .tabId !== 'all'
                                                        ) {
                                                            setOpenRenameCategoryModal(
                                                                true
                                                            )
                                                        }
                                                    }
                                                }}
                                                hover={true}
                                                width="100%"
                                                key={item.id}
                                            >
                                                <Button
                                                    color={
                                                        item.id ===
                                                        'DELETE_CATEGORY'
                                                            ? 'delay'
                                                            : 'primary'
                                                    }
                                                    fontFamily="Rubik"
                                                    border="none"
                                                    bg="transparent"
                                                >
                                                    {item.name}
                                                </Button>
                                            </DropdownButtonWrapper>
                                        )
                                    })}
                                </>
                            </Dropdown>
                        )}
                    </Box>
                )}
            </Box>

            {open && roles.designer !== role && (
                <DeleteCategoryModal
                    setModal={openModal}
                    refetchCategories={fetchCategories}
                />
            )}
            {openRenameCategoryModal && roles.designer !== role && (
                <RenameCategoryModal
                    setModal={(x) => setOpenRenameCategoryModal(x)}
                    refetchCategories={fetchCategories}
                />
            )}
        </>
    )
}
