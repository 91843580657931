import * as React from 'react'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { VCalendar } from 'src/components/calendar/calendar'
import { Checkbox } from 'src/components/checkbox/checkbox'
import {
    CalendarIco,
    PeopleIco,
    TagsIco,
    WarningIcoSM,
} from 'src/components/svg-icons'
import { Box, Input, Text } from 'src/components/utility'
import { getFormattedDate } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import TagInput from 'src/screens-v2/organisations-analytics/modal/add-collaborator/tag-input'
import useStore from 'src/store/hooks/useStore'
import { globalSearch } from 'src/store/slice/global-search-slice'
import { useGlobalSearch } from './hooks/useGlobalSearch'
import { useSearchCollaborators } from './hooks/useSearchCollaborators'
import FilterTagInput from 'src/screens-v2/global-search/filter-dropdowns/filter-tag-input'
import { useSearchTags } from './hooks/useSearchTags'

interface IFiltersProps {
    setActive: (isActive: boolean) => void
}

export default function Filters(props: IFiltersProps) {
    const [isCalendarActive, setCalendarActive] = React.useState(false)
    const [date, setDate] = React.useState('')
    const {
        setTags,
        removeTags,
        setCollaborators,
        removeCollaboratorFromSearch,
        setDateRange,
        setStatus,
        setQuery,
        resetFilters,
        setGlobalSearchActive,
        globalSearchFilters,
    } = useStore()
    const { getGlobalSearchData } = useGlobalSearch()
    const calendarRef = useClickedOutside(() => setCalendarActive(false))
    const filtersRef = useClickedOutside(() => props.setActive(false))
    const { searchTags, searchedTags } = useSearchTags()
    const { searchCollaborator, collaborators } = useSearchCollaborators()
    React.useEffect(() => {
        setDateRange({ dateRange: date })
    }, [date])

    return (
        <Box
            zIndex={10}
            position="absolute"
            bg="white"
            width="100%"
            maxHeight={window.innerHeight - 64 + 'px'}
            top="32px"
            display="flex"
            ref={filtersRef}
            flexDirection="column"
            gridRowGap={window.innerHeight < 680 ? '20px' : '32px'}
            py="24px"
            pb="0px"
            boxShadow="0px 2px 12px 0px #999BA840;"
        >
            <Box px="24px">
                <Text my="0px" mb="8px">
                    Has the keyword
                </Text>
                <Input
                    bg="white"
                    px="16px"
                    py="12px"
                    width="100%"
                    fontSize={'14px'}
                    onChange={(e) => setQuery({ query: e.target.value })}
                    value={globalSearchFilters.query}
                    border="solid"
                    borderWidth={1}
                    borderRadius="4px"
                    placeholder='e.g. "Product Name"'
                    borderColor="gray350"
                />
                <Box
                    mt="8px"
                    display="flex"
                    gridColumnGap="8px"
                    alignItems="center"
                >
                    <WarningIcoSM color="#EF8D1B" />
                    <Text color="warning" fontSize="12px" my="0px">
                        Search input must have a keyword
                    </Text>
                </Box>
            </Box>
            <Box px="24px">
                <Text my="0px" mb="8px">
                    Collaborators
                </Text>
                <Box alignItems="center" display="flex">
                    <Box
                        px="8px"
                        py="8px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="backgroundColor"
                        borderRadius="4px"
                    >
                        <PeopleIco color="#778CA2" />
                    </Box>
                    <TagInput
                        placeholder="Collaborator"
                        onDelete={(id) =>
                            removeCollaboratorFromSearch({ id: String(id) })
                        }
                        onQuery={(e) => searchCollaborator(e)}
                        setTags={(tag, item) => {
                            if (
                                'user' in item &&
                                globalSearchFilters.collaborators.find(
                                    (collaborator) => {
                                        collaborator.email === item.user.email
                                    }
                                )
                            ) {
                                return
                            } else {
                                setCollaborators({
                                    collaborators: tag.map((tag) => ({
                                        role: tag.role,
                                        email:
                                            ('user' in tag && tag.user.email) ||
                                            '',
                                        id: tag.id,
                                        name:
                                            ('user' in tag && tag.user.name) ||
                                            '',
                                        profile_img:
                                            ('user' in tag &&
                                                tag.user.other_data
                                                    .profile_pic) ||
                                            '',
                                    })),
                                })
                            }
                        }}
                        suggestions={collaborators.map((collaborator) => ({
                            id: String(collaborator.user_id),
                            role: collaborator.role,
                            user: {
                                email: collaborator.user.email,
                                name: collaborator.user.name,
                                other_data: {
                                    profile_pic:
                                        collaborator.user.other_data
                                            .profile_pic,
                                    thumbnail:
                                        collaborator.user.other_data.thumbnail,
                                },
                            },
                        }))}
                        tags={globalSearchFilters.collaborators
                            .filter(
                                (collaborator) =>
                                    String(collaborator.id) !== '0'
                            )
                            .map((collaborator) => ({
                                id: String(collaborator.id),
                                role: collaborator.role,
                                user: {
                                    email: collaborator.email,
                                    name: collaborator.name,
                                    other_data: {
                                        profile_pic: collaborator.profile_img,
                                        thumbnail: collaborator.profile_img,
                                    },
                                },
                            }))}
                    />
                </Box>
            </Box>
            <Box px="24px">
                <Text my="0px" mb="8px">
                    Tags
                </Text>
                <Box alignItems="center" display="flex">
                    <Box
                        px="8px"
                        py="8px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="backgroundColor"
                        borderRadius="4px"
                    >
                        <TagsIco />
                    </Box>
                    <FilterTagInput
                        placeholder="Tags"
                        onDelete={(id) => removeTags({ id })}
                        onQuery={(e) => searchTags(e)}
                        setTags={(tag) =>
                            setTags({
                                tags: tag.map((tag) => ({
                                    name: tag.tag,
                                    id: tag.id,
                                })),
                            })
                        }
                        suggestions={searchedTags}
                        tags={globalSearchFilters.tags
                            .filter((tag) => Number(tag.id) !== 0)
                            .map((tag) => ({
                                id: tag.id,
                                tag: tag.name,
                            }))}
                    />
                </Box>
            </Box>
            <Box px="24px" ref={calendarRef}>
                <Text my="0px" mb="8px">
                    Date Range
                </Text>
                <Box alignItems="center" position="relative" display="flex">
                    <Box
                        px="8px"
                        py="8px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="backgroundColor"
                        borderRadius="4px"
                    >
                        <CalendarIco />
                    </Box>
                    <Input
                        bg="white"
                        px="16px"
                        py="8px"
                        height="100%"
                        width="max-content"
                        fontSize={'14px'}
                        value={
                            globalSearchFilters.dateRange
                                ? getFormattedDate(
                                      globalSearchFilters.dateRange,
                                      'FORMATTED'
                                  )
                                : ''
                        }
                        onClick={() => setCalendarActive(!isCalendarActive)}
                        border="none"
                        placeholder="Choose Date Range"
                    />
                    <VCalendar
                        date={globalSearchFilters.dateRange}
                        disableCallback={() =>
                            setCalendarActive(!isCalendarActive)
                        }
                        setDate={(date) => {
                            setDateRange({ dateRange: date })
                        }}
                        isCalendarVisible={isCalendarActive}
                        left="44px"
                        top={window.innerHeight < 700 ? '-340px' : '38px'}
                        minDate={
                            new Date(
                                new Date(
                                    '1990-01-01T00:00:00.000Z'
                                ).setUTCHours(23, 59, 59, 999)
                            )
                        }
                    />
                </Box>
            </Box>
            <Box px="24px">
                <Text my="0px" mb="8px">
                    Status
                </Text>
                <Box
                    display="grid"
                    gridGap="8px"
                    maxWidth={'350px'}
                    gridTemplateColumns={'auto auto'}
                >
                    {globalSearchFilters.status.map((status, index) => (
                        <Checkbox
                            checkBoxId={status.label + '__' + index}
                            label={status.label}
                            defautlCheck={status.isActive}
                            checked={status.isActive}
                            onChange={(isChecked) => {
                                const newStatuses = [
                                    ...globalSearchFilters.status,
                                ]
                                newStatuses[index].isActive = isChecked
                                setStatus({
                                    statuses: newStatuses as statuses[],
                                })
                            }}
                        />
                    ))}
                </Box>
            </Box>
            <Box
                py="16px"
                gridColumnGap="16px"
                display="flex"
                justifyContent="end"
                px="24px"
                bg="backgroundColor"
                borderTop="solid"
                borderColor="gray350"
                borderWidth={1}
            >
                <ColoredButton
                    onClick={() => resetFilters()}
                    variant="secondary"
                >
                    Reset Filters
                </ColoredButton>
                <ColoredButton
                    disabled={
                        !(
                            JSON.stringify(globalSearch) !==
                            JSON.stringify(globalSearchFilters)
                        )
                    }
                    onClick={async () => {
                        if (globalSearchFilters.query.length <= 0) return
                        await getGlobalSearchData({
                            query: globalSearchFilters.query,
                            filters: {
                                collaborators:
                                    globalSearchFilters.collaborators
                                        .filter(
                                            (collaborator) =>
                                                String(collaborator.id) !== '0'
                                        )
                                        .map((collaborator) =>
                                            String(collaborator.id)
                                        ) || [],
                                tags:
                                    globalSearchFilters.tags
                                        .filter((tag) => Number(tag.id) !== 0)
                                        .map((tag) => tag.name) || [],
                                status:
                                    globalSearchFilters.status
                                        .filter((status) => status.isActive)
                                        .map((status) => status.id) || [],
                                file_types:
                                    globalSearchFilters.file_types || [],
                                from:
                                    globalSearchFilters.dateRange || undefined,
                                to: globalSearchFilters.dateRange || undefined,
                            },
                        })
                        setGlobalSearchActive({
                            isGlobalSearchActive: true,
                        })
                        props.setActive(false)
                    }}
                    variant="primary"
                >
                    Search
                </ColoredButton>
            </Box>
        </Box>
    )
}
