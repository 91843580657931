import axios from 'axios'
import type { KeyboardEvent } from 'react'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ColorPicker from 'src/components/color-picker/color-picker'
import { Loader } from 'src/components/loader/loader'
import { DangerModal } from 'src/components/modals/danger-modal'
import { DeleteIco, PaintIco } from 'src/components/svg-icons'
import InsertColorsIco from 'src/components/svg-icons/insert-colors-ico'
import { Tag } from 'src/components/tag/tag'
import { Box, Button, Input } from 'src/components/utility'
import { getLocalStorage } from 'src/helpers'
import { formatColorCode } from 'src/helpers/format-color-code'
import { isValidColor } from 'src/helpers/is-valid-color'
import useUpdateTemplate from 'src/hooks/useUpdateTemplate.hook'
import { ADD_COLOR, DELETE_COLOR } from 'src/services/api/endpoints'

function ColorsField({
    colors,
    refetchTodo,
    sectionId,
    deleteSection,
    id,
    isReadOnly,
    isTemplate,
}: {
    colors: Array<any>
    refetchTodo: () => void
    sectionId: number
    deleteSection: () => void
    id: string
    isReadOnly?: boolean
    isTemplate?: boolean
}) {
    const [isHovered, setIsHovered] = useState(false)
    const [inputColor, setInputColor] = useState('')
    const { updateTemplateSection } = useUpdateTemplate()
    const [isDeleting, setIsDeleting] = useState(false)
    const [showDeleteColorsFieldModal, setShowDeleteColorsFieldModal] =
        useState(false)

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputColor.trim() !== '') {
            addColorHandler(inputColor.trim())
            setInputColor('')
        }
    }

    async function addColorHandler(color: string) {
        if (colors.some(({ color: clr }) => clr === color)) {
            toast('Color already exists', {
                className: 'custom-toaster toaster-error',
            })
        } else if (!isValidColor(color)) {
            toast('Invalid color format', {
                className: 'custom-toaster toaster-error',
            })
        } else {
            try {
                if (isTemplate) {
                    updateTemplateSection({
                        sectionId,
                        payload: {
                            colors: [...colors, { name: '', color }],
                        },
                    })
                } else {
                    const response = await axios.post(
                        ADD_COLOR,
                        {
                            colors: [
                                {
                                    name: '',
                                    color,
                                },
                            ],
                            entity_name: 'sub_todo_sections',
                            entity_id: sectionId,
                        },
                        {
                            headers: {
                                'x-auth-token': getLocalStorage('authToken'),
                            },
                        }
                    )
                    if (response.status === 200) {
                        refetchTodo()
                    }
                }
            } catch {
                toast('Failed to add color', {
                    className: 'toast-error',
                })
            }
        }
    }

    async function deleteColorsHandler(deleteColors: Array<string>) {
        try {
            if (isTemplate) {
                updateTemplateSection({
                    sectionId,
                    payload: {
                        colors: colors.filter(
                            ({ color }) => !deleteColors.includes(color)
                        ),
                    },
                })
            } else {
                const response = await axios.post(
                    DELETE_COLOR,
                    {
                        colors: deleteColors.map((color) => ({
                            name: null,
                            color,
                        })),
                        entity_name: 'sub_todo_sections',
                        entity_id: sectionId,
                    },
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    refetchTodo()
                }
            }
        } catch {
            toast('Failed to remove color', {
                className: 'toast-error',
            })
        }
    }

    return (
        <Box
            display="grid"
            gridTemplateColumns="auto 1fr 28px"
            gridGap="8px"
            bg="white"
            p="4px"
            borderRadius="4px"
            height="max-content"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            border={isHovered ? '1px solid #B8B8B8' : '1px solid #fff'}
            id={id}
        >
            <PaintIco />
            <Box width="100%" display="flex" flexWrap="wrap" gridGap="6px">
                {colors.map(({ color }) => (
                    <Tag
                        key={color}
                        element={color}
                        color={formatColorCode(color)}
                        action={!isReadOnly}
                        onClick={() =>
                            !isReadOnly && deleteColorsHandler([color])
                        }
                        enableCopy
                    />
                ))}
                {!isReadOnly && (
                    <Box display="flex" alignItems="center" gridGap="8px">
                        <ColorPicker
                            onAdd={(color) => {
                                addColorHandler(color)
                            }}
                            buttonIcon={<InsertColorsIco />}
                        />
                        <Input
                            bg="transparent"
                            placeholder="HEX/RGB Code"
                            value={inputColor}
                            fontSize={'12px'}
                            onChange={(e) => setInputColor(e.target.value)}
                            onKeyDown={handleKeyPress}
                            border="none"
                        />
                    </Box>
                )}
            </Box>
            {isHovered && !isReadOnly ? (
                isDeleting ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Loader outline="#FF2C18" size="18px" />
                    </Box>
                ) : (
                    <Button
                        onClick={() => setShowDeleteColorsFieldModal(true)}
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        bg="#F0F2F3"
                    >
                        <DeleteIco />
                    </Button>
                )
            ) : null}
            {showDeleteColorsFieldModal && (
                <DangerModal
                    onRemove={async () => {
                        setIsDeleting(true)
                        if (colors.length > 0) {
                            await deleteColorsHandler(
                                colors.map(({ color }) => color)
                            )
                        }
                        deleteSection()
                    }}
                    onClose={() => setShowDeleteColorsFieldModal(false)}
                    isModal={showDeleteColorsFieldModal}
                    heading={`Are you sure you want to delete “colour” details of this section?`}
                    subHeading={
                        'Deleting this section detail will permanently delete all the colours added.'
                    }
                    title={'Delete Section Detail'}
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                />
            )}
        </Box>
    )
}

export default ColorsField
