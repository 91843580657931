import { useEffect, useRef, memo } from 'react'
import type { Editor } from '@tiptap/react'
import { EditorContent } from '@tiptap/react'
import { Box } from 'src/components/utility'

function CommentsInput({
    height,
    editor,
}: {
    height?: string
    editor: Editor
}) {
    const parentDiv = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const stopPropagation = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                event.stopPropagation()
            }
        }

        const parentElement = parentDiv.current
        parentElement?.addEventListener('keydown', stopPropagation)

        return () => {
            parentElement?.removeEventListener('keydown', stopPropagation)
        }
    }, [parentDiv.current])

    // Focus on the editor when the component mounts
    useEffect(() => {
        if (editor) {
            editor?.commands?.focus()
        }
    }, [editor])

    return (
        <Box
            ref={parentDiv}
            display="flex"
            flexDirection="column"
            gridRowGap="16px"
            width="90%"
            height={height ? height : 'inherit'}
        >
            <EditorContent
                style={{
                    width: '100%',
                    height: '50px !important',
                    maxHeight: '200px',
                    textAlign: 'left',
                    overflow: 'auto',
                    wordBreak: 'break-word',
                }}
                editor={editor}
            />
        </Box>
    )
}

export default memo(CommentsInput)
