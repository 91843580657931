import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import type { SyntheticEvent } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TransparentRightArrow } from 'src/components'
import { Box, Button } from 'src/components/utility'
import { useUser } from 'src/helpers'
import { handleActiveTab } from 'src/helpers/functions'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { AddCategoryModal } from './modals/add-category-modal'

export const SliderLinks = ({
    catergoryList,
    refetch,
}: {
    catergoryList: any[]
    refetch: () => void
}) => {
    const { role } = useUser()
    const [value, setValue] = useState<any>(0)
    const [open, openModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get(libraryDefaultKeys.categoryId)
    const navigate = useNavigate()

    useEffect(() => {
        if (catergoryList && tab) {
            const findIndex = catergoryList.findIndex((c) => c.id == tab)
            if (findIndex !== -1) {
                setValue(findIndex)
            }
        } else {
            setValue(0)
        }
    }, [catergoryList, tab])

    function handleTabChange(
        e: any,
        tabId: string,
        callback: TabChangeCallBack,
        tabName: string
    ) {
        addRouterParams(tabId, tabName, '/library')
    }

    function a11yProps(id: number) {
        return {
            id: `library-tab-${id}`,
            'aria-controls': `library-tabpanel-${id}`,
        }
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    }

    const handleArrowClick = () => {
        const latestValue = value < catergoryList.length - 1 ? value + 1 : 0
        let tabId = catergoryList[latestValue].id
        addRouterParams(tabId)
        setValue(latestValue)
    }

    const addRouterParams = (
        tabId: string,
        tabName?: string,
        basePath?: string
    ) => {
        if (tabId !== 'all') {
            searchParams.set(
                libraryDefaultKeys.tab,
                `${libraryDefaultKeys.tabNamePrefix}${
                    tabId === 'all' ? 'M1ltyt' : tabId
                }`
            )
            searchParams.set(
                libraryDefaultKeys.categoryId,
                tabId === 'all' ? 'M1lty' : tabId
            )
        } else {
            if (searchParams.has(libraryDefaultKeys.tab)) {
                searchParams.delete(libraryDefaultKeys.tab)
            }
            if (searchParams.has(libraryDefaultKeys.categoryId)) {
                searchParams.delete(libraryDefaultKeys.categoryId)
            }
        }
        if (tabName) searchParams.set(libraryDefaultKeys.tabName, tabName)
        if (searchParams.has(libraryDefaultKeys.showFolderDetails)) {
            searchParams.delete(libraryDefaultKeys.showFolderDetails)
        }
        if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
            searchParams.delete(libraryDefaultKeys.showFileDetails)
        }
        if (searchParams.has(libraryDefaultKeys.filters)) {
            searchParams.delete(libraryDefaultKeys.filters)
        }
        setSearchParams(searchParams)
        navigate((basePath || location.pathname) + location.search, {
            state: {
                tabId,
            },
        })
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                {catergoryList && catergoryList.length && (
                    <>
                        <Box
                            pr={'36px'}
                            maxWidth="calc(100% - 155px)"
                            position="relative"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons={false}
                                aria-label="library-tabs"
                            >
                                {catergoryList.map(
                                    (tab: any, index: number) => {
                                        return (
                                            <Tab
                                                key={'libraryTabs' + index}
                                                onClick={(e: SyntheticEvent) =>
                                                    handleTabChange(
                                                        e,
                                                        tab.id,
                                                        handleActiveTab,
                                                        tab.name
                                                    )
                                                }
                                                label={`${tab.name}`}
                                                {...a11yProps(index)}
                                            />
                                        )
                                    }
                                )}
                            </Tabs>

                            {catergoryList.length > 5 && (
                                <TransparentRightArrow
                                    onClick={(): any => {
                                        handleArrowClick()
                                    }}
                                />
                            )}
                        </Box>
                    </>
                )}

                <Box>
                    <Button
                        id="mylibrary-addcatrgory-button"
                        minWidth="130px"
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        color="links"
                        position="relative"
                        onClick={() => {
                            openModal(true)
                        }}
                    >
                        + Add Category
                    </Button>
                </Box>
            </Box>

            {open && (
                <AddCategoryModal
                    isModal={open}
                    refetchCategories={(x) => {
                        x && refetch()
                    }}
                    setModal={(val: boolean) => {
                        openModal(val)
                    }}
                />
            )}
        </>
    )
}
