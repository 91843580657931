import { useMediaQuery } from '@mui/material'
import { Box, Image, Text } from '../utility'
import CollectionsIllustration from 'src/assets/images/Collections.svg'

export function EntityEmptyState({ entityName }: { entityName: string }) {
    const isLowResolutionHeight = useMediaQuery('(max-height:700px)')

    return (
        <Box
            display="flex"
            mt="40px"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
        >
            <Image
                width={isLowResolutionHeight ? ' 200px' : '341.1px'}
                height={isLowResolutionHeight ? '140px' : '259px'}
                src={CollectionsIllustration}
            />
            <Text
                fontSize="20px"
                mb="8px"
                mt="28px"
                color="primary"
                fontFamily="Rubik"
            >
                No {entityName}
            </Text>
        </Box>
    )
}
