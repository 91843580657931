import React from 'react'
import styled from 'styled-components'
import { Box, Button, Text } from '../utility'
import { DropDownArrow } from '../svg-icons'
import { statuses } from 'src/helpers'

interface StatusProps {
    showDropdown?: boolean
}

export const Status = styled(Text)<StatusProps>`
    padding: 2px 4px;
    font-family: 'Rubik';
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    height: max-content;
    text-align: center;
    min-width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
`

export const Statuses = ({
    type,
    showDropdown,
}: {
    type: type
    showDropdown: boolean
}) => {
    switch (type) {
        case statuses.new:
            return <New showDropdown={showDropdown} />
        case statuses.inProgress:
            return <InProgress showDropdown={showDropdown} />
        case statuses.delayed:
            return <Delay showDropdown={showDropdown} />
        case statuses.inReview:
            return <InReview showDropdown={showDropdown} />
        case statuses.revisionRequested:
            return <RequestRevision showDropdown={showDropdown} />
        case statuses.requestedForResubmission:
            return <RequestRevision showDropdown={showDropdown} />
        case statuses.complete:
            return <Completed showDropdown={showDropdown} />
        case statuses.toBeReviewed:
            return <ToBeReviewed showDropdown={showDropdown} />
        case statuses.todo:
            return <ToDo showDropdown={showDropdown} />
        case statuses.invited:
            return <Invited />
        case statuses.active:
            return <Active />
        case statuses.archived:
            return <Archived />
        default:
            return <>Invalid Status</>
    }
}

export const New = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textTags"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textTags">
                    NEW {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const InProgress = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="#0c40d0"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="#0c40d0">
                    IN PROGRESS{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const Delay = ({ showDropdown }: StatusProps) => {
    return (
        <Box position="relative" border="1px solid #EF8D1B" borderRadius="4px">
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="delay">
                    DELAY{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const InReview = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textInReview"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textInReview">
                    IN REVIEW{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const RequestRevision = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textRequestRevision"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textRequestRevision">
                    REVISION REQUESTED{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const Completed = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textCompleted"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textCompleted">
                    COMPLETED{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const ToBeReviewed = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textToBeReviewed"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textToBeReviewed">
                    TO BE REVIEWED{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}
export const ToDo = ({ showDropdown }: StatusProps) => {
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="textTags"
            borderRadius="4px"
        >
            <Button
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                position="relative"
                width="max-content"
                id="status-dropdown-button"
                display="flex"
                style={{ alignItems: 'center', gap: '4px' }}
            >
                <Status color="textTags">
                    TO-DO{' '}
                    {showDropdown ? <DropDownArrow color="#778CA2" /> : ''}
                </Status>
            </Button>
        </Box>
    )
}

export function Invited() {
    return (
        <Box
            border="solid"
            borderWidth={1}
            borderRadius="4px"
            borderColor="textStatusActive"
            color="textStatusActive"
            p="4px"
            width={{ md: 'max-content', lg: 'max-content' }}
        >
            <Text fontSize="12px" fontWeight={500} textAlign="center" my="0px">
                Invited
            </Text>
        </Box>
    )
}

export function Active() {
    return (
        <Box
            border="solid"
            borderWidth={1}
            borderColor="approved"
            borderRadius="4px"
            color="approved"
            p="4px"
            width={{ md: 'max-content', lg: 'max-content' }}
        >
            <Text fontSize="12px" fontWeight={500} textAlign="center" my="0px">
                Active
            </Text>
        </Box>
    )
}
export function Archived() {
    return (
        <Box
            border="solid"
            borderWidth={1}
            borderColor="textToBeReviewed"
            color="textToBeReviewed"
            borderRadius="4px"
            p="4px"
            width={{ md: 'max-content', lg: 'max-content' }}
        >
            <Text fontSize="12px" fontWeight={500} textAlign="center" my="0px">
                Archived
            </Text>
        </Box>
    )
}
