import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Box } from 'src/components/utility'

interface ICommentSliderProps {
    currentTab: string
    setCurrentTab: (tab: string) => void
    commentsCounts: {
        total: number
        annotation: number
        mentions: number
    }
}

export default function CommentSlider(props: ICommentSliderProps) {
    const { currentTab, setCurrentTab, commentsCounts } = props
    return (
        <Box>
            <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    setCurrentTab(newValue)
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab label={`All (${commentsCounts.total})`} value={'all'} />
                <Tab
                    label={`Mentions (${commentsCounts.mentions})`}
                    value={'mentions'}
                />
                <Tab
                    label={`Annotations (${commentsCounts.annotation})`}
                    value={'annotations'}
                />
                {/* <Tab label="Analytics" value={OrgTabs.analytics} /> */}
            </Tabs>
        </Box>
    )
}
