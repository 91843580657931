import { gql } from '@apollo/client'

export const GetCollectionsName = gql`
    query GetCollectionsName {
        collections {
            id
            name
            team_id
        }
    }
`

export const GetProductsWithCollectionId = gql`
    query getProductsWithCollectionId($collectionId: bigint!) {
        products(where: { collection_id: { _eq: $collectionId } }) {
            collection_id
            created_at
            completed_at
            id
            name
            due_date
            description
            status
            updated_at
            product_variants(where: { is_primary: { _eq: true } }) {
                id
                name
            }
        }
    }
`

export const GetTodoById = gql`
    query getTodoById($organisation_id: bigint, $id: bigint!) {
        todos(order_by: { id: asc }, where: { id: { _eq: $id } }) {
            created_at
            created_by
            due_date
            id
            name
            description
            status
            duration
            tags
            product_id
            other_data
            is_revision
            user_id
            files {
                id
                file {
                    path
                    gid
                    name
                    thumbnail
                    id
                    other_data
                }
                library_file {
                    path
                    gid
                    name
                    thumbnail
                    id
                    other_data
                }
            }
            product {
                id
                name
                due_date
                collection {
                    name
                    id
                    manager_id
                }
                approver_id
                other_data
            }
            assignee {
                email
                name
                id
                other_data
                organisation_members(
                    where: { organisation_id: { _eq: $organisation_id } }
                ) {
                    role
                    id
                    user_id
                }
            }
            sub_todos(order_by: { id: asc }) {
                description
                heading
                created_at
                id
                todo_id
                files(order_by: { created_at: desc }) {
                    id
                    file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                        created_at
                        user {
                            id
                            name
                            other_data
                        }
                    }
                    library_file {
                        path
                        gid
                        name
                        thumbnail
                        id
                        other_data
                        created_at
                        creator {
                            id
                            name
                            other_data
                        }
                    }
                }
                other_data
                submitted_files
            }
        }
    }
`
