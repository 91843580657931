import { useQuery } from '@apollo/client'
import type { ChangeEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import {
    Dropdown,
    DropdownButtonWrapper,
    FileDisplay,
    Line,
    Modal,
    Toast,
    VCalendar,
} from 'src/components'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import {
    AddFileIco,
    CalendarIco,
    CrossSm,
    DescriptionIco,
    EditIco,
    FilledCloseIco,
    PaintIco,
    PersonIco,
    PlusIco,
} from 'src/components/svg-icons'
import { Box, Button, Input, Text, TextArea } from 'src/components/utility'
import { debugLog, getFormattedDate, getImageUrl } from 'src/helpers'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import { GetTeamMemberDetailsByTeamId } from 'src/services'
import useStore from 'src/store/hooks/useStore'
import { v4 as uuid } from 'uuid'
import { Users } from './product-form'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { createRedirectionUrl } from 'src/helpers/create-redirection-url'
import { checkFileType } from 'src/helpers/check-file-type'

type subTodos = {
    id: string
    heading: string
    description: string
    file: any[]
    color: { name: string; id: string }[]
    type: string
    editableHeading: boolean
}[]

export const AccordianChildDetails = ({
    sub_todos,
    todoId,
    operation,
    updateDeletedFiles,
}: {
    sub_todos: subTodos
    todoId: number
    operation: 'EDIT' | 'CREATE'
    updateDeletedFiles: ({ path }: { path: string }) => void
}) => {
    const navigate = useNavigate()
    const _location = useLocation()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const calendarDiv = useRef<HTMLDivElement | null>(null)
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false)

    const productForm = useStore((state) => state.productForm)
    const [query, setQuery] = useState<string>('')
    const [date, setDate] = useState<Date | null>(null)
    const [users, setUsers] = useState([])
    const { updateSubTodo, removeFile, updateTodo } = useStore()
    const addProductTodos = useStore((state) => state.addProductTodos)
    // const [managers, setManagers] = useState<Users[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const { teamMembers, removeNewTodo } = useStore()
    const [showColorPicker, setShowColorPicker] = useState(false)
    const wrapperRef = useClickedOutside(() => setShowColorPicker(false))
    const { data } = useQuery(GetTeamMemberDetailsByTeamId, {
        variables: {
            id: productForm.team_id,
        },
    })
    const [color, setColor] = useState('')
    const [colorTags, setColorTags] = useState<{ id: string; name: string }[]>(
        []
    )
    const [fileModal, setFileModal] = useState<{
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        subTodoId: null,
        files: [],
    })
    const [email, setEmail] = useState('')

    const [toast, setToast] = useState<{
        message: string
        type: 'Success' | 'Error' | 'Warning' | 'Information'
    }>({
        message: '',
        type: 'Success',
    })
    const inputRefColorPicker = useRef<HTMLInputElement | null>(null)
    const [isToast, setIsToast] = useState<boolean | null>(null)
    const [selectedAssignee, setSelectedAssignee] =
        useState<ManagerUser | null>(null)
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const [isAssigneeSelected, setIsAssigneeSelected] = useState(false)

    const dropdownBadge = useRef<any>(null)
    const [subTodosList, setSubTodosList] = useState<{ [x: string]: boolean }>(
        {}
    )

    const params = useParams()
    const collectionId = params.id
    const productId = params?.productId
    const _editProduct =
        params?.productId &&
        params?.productId !== '' &&
        params?.productId !== '0'
            ? true
            : false

    useEffect(() => {
        sub_todos.forEach((todo, key) => {
            setSubTodosList((subTodo) => ({ ...subTodo, [todo.id]: false }))
        })
    }, [])

    function disableFiledropdown(id?: string) {
        if (id) {
            return sub_todos.forEach((todo, key) => {
                if (todo.id !== id) {
                    setSubTodosList((subTodo) => ({
                        ...subTodo,
                        [todo.id]: false,
                    }))
                }
            })
        }
        sub_todos.forEach((todo, key) => {
            setSubTodosList((subTodo) => ({ ...subTodo, [todo.id]: false }))
        })
    }

    useEffect(() => {
        if (data) {
            setUsers(data.team_members)
        }
    }, [data])

    useEffect(() => {
        if (date) {
            console.error(date)
            updateTodo({
                payload: {
                    id: todoId.toString(),
                    data: new Date(date).toISOString(),
                },
                action: 'dueDate',
            })
        }
    }, [date])

    function onFileDragged(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        if (!checkFileType(event.dataTransfer.files[0].name)) {
            const nameSplit = event.dataTransfer.files[0].name.split('.')
            const fileType = nameSplit[nameSplit.length - 1]
            setIsToast(true)
            setToast({
                message: `'.${fileType}' is not a valid file type`,
                type: 'Error',
            })
            return
        }
        const objURL = URL.createObjectURL(event.dataTransfer.files[0])
        setFileModal((fileModal) => ({
            ...fileModal,
            files: [
                ...fileModal.files,
                {
                    name: event.dataTransfer.files[0].name,
                    path: objURL,
                    blob: event.dataTransfer.files[0],
                    id: uuid(),
                },
            ],
        }))
    }
    function onFileInput(files: any) {
        const keys = Object.keys(files)
        keys.forEach((key: any) => {
            if (!checkFileType(files[key].name)) {
                const nameSplit = files[key].name.split('.')
                const fileType = nameSplit[nameSplit.length - 1]
                setIsToast(true)
                setToast({
                    message: `'.${fileType}' is not a valid file type`,
                    type: 'Error',
                })
                return
            }
            if (files[key]) {
                const objURL = URL.createObjectURL(files[key])
                setFileModal((fileModal) => ({
                    ...fileModal,
                    files: [
                        ...fileModal.files,
                        {
                            name: files[key].name,
                            path: objURL,
                            blob: files[key],
                            id: uuid(),
                        },
                    ],
                }))
            }
        })
    }

    function onDelete(id: string) {
        setFileModal((fileModal) => ({
            ...fileModal,
            files: fileModal.files.filter((file) => file.id !== id),
        }))
    }

    function addFilesToSubTodo(subTodoId: string | null) {
        if (subTodoId) {
            updateSubTodo({
                payload: {
                    todoId: todoId,
                    subTodoId: subTodoId,
                    data: fileModal.files,
                },
                action: 'file',
            })
            setFileModal({ subTodoId: null, files: [], isFileModal: false })
        }
    }
    function openFileDialogueBox() {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }
    function disableDropdown(event: Event | any) {
        if (dropdownBadge !== null && dropdownBadge.current !== null) {
            const isClickInsideElement = dropdownBadge.current.contains(
                event.target
            )
            if (
                !isClickInsideElement &&
                event?.target &&
                !event.target.dataset.dropdownFocus
            ) {
                disableFiledropdown()
            }
        }
    }
    const colorPicker = useRef<HTMLDivElement | null>(null)
    const colorPickerInput = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const foundTodo = addProductTodos.find(
            (foundTodo) => foundTodo.id === todoId
        )
        if (foundTodo && Number(foundTodo.assignee_id) !== 0 && data) {
            const member = data.team_members.find(
                (member: any) => member.user.id === foundTodo.assignee_id
            )
            if (member) {
                setIsAssigneeSelected(true)
                setSelectedAssignee(member)
            }
        }
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    useEffect(() => {
        setEmail(query)
    }, [query])

    function ChangeColor(e: React.ChangeEvent<HTMLInputElement>) {
        setColor(e.target.value)
    }

    const _navigateToFiles = (subTodoId: any) => {
        let _url = `/library/selectfiles/${subTodoId}/todos?parentTodoId=${todoId}&from=createTodo&collectionId=${collectionId}`
        if (_editProduct && productId) {
            _url += `&productId=${productId}`
        }
        navigate(_url)
    }

    async function addDeleteFile({ path }: { path: string }) {
        if (operation === 'EDIT') {
            updateDeletedFiles({ path })
        }
    }

    return (
        <>
            <Toast
                text={toast.message}
                variant={toast.type}
                isToastVisible={isToast}
                setIsToastVisible={setIsToast}
            />
            <Box
                display="flex"
                gridColumnGap="12px"
                justifyContent="center"
                fontFamily="Rubik"
                flexDirection="column"
            >
                <Box display="flex">
                    <Box
                        position="relative"
                        width="max-content"
                        ref={calendarDiv}
                        display="flex"
                        alignItems="center"
                        gridColumnGap="12px"
                        id="product_form_todo_due_date"
                        onClick={() => setIsCalendarVisible(true)}
                    >
                        <CalendarIco />
                        <Input
                            autoComplete="false"
                            required
                            onClick={() => setIsCalendarVisible(true)}
                            bg="white"
                            color="primary"
                            border="none"
                            value={
                                addProductTodos.find(
                                    (todo) => todo.id === todoId
                                )?.dueDate
                                    ? getFormattedDate(
                                          addProductTodos.find(
                                              (todo) => todo.id === todoId
                                          )?.dueDate || ''
                                      )
                                    : ''
                            }
                            fontSize="16px"
                            placeholder="Add Due Date"
                            id={`todo_due_date_${todoId}`}
                            onChange={() => debugLog()}
                            className="placeholder-light"
                        ></Input>
                        <VCalendar
                            maxDate={new Date(productForm.due_date)}
                            ref={calendarDiv}
                            disableCallback={() => setIsCalendarVisible(false)}
                            isCalendarVisible={isCalendarVisible}
                            setDate={setDate}
                            date={date}
                            top="28px"
                            left="32px"
                        ></VCalendar>
                    </Box>
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        gridColumnGap="12px"
                    >
                        <PersonIco />
                        {!isAssigneeSelected && (
                            <Input
                                autoComplete="false"
                                id={`product_form_todo_assignee_${todoId}`}
                                required
                                bg="white"
                                value={email}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (!isDropdownVisible)
                                        setIsDropdownVisible(true)
                                    setQuery(e.target.value)
                                    setEmail(e.target.value)
                                    if (e.target.value.length <= 0) {
                                        updateTodo({
                                            action: 'assignee_id',
                                            payload: {
                                                id: todoId.toString(),
                                                data: query,
                                            },
                                        })
                                    }
                                }}
                                color="primary"
                                border="none"
                                fontSize="16px"
                                placeholder="Add Assignee"
                                className="placeholder-light"
                            ></Input>
                        )}
                        {isAssigneeSelected && selectedAssignee && (
                            <Box
                                display="flex"
                                gridColumnGap="12px"
                                alignItems="center"
                            >
                                <ProfileImg
                                    size="20px"
                                    name={selectedAssignee.user.name}
                                    src={getImageUrl(
                                        selectedAssignee.user.other_data
                                            ?.profile_pic
                                    )}
                                />
                                <Text my="0px" color="primary">
                                    {selectedAssignee.user.name}
                                </Text>
                                <Button
                                    border="none"
                                    borderRadius="50%"
                                    bg="transparent"
                                    onClick={() => {
                                        setQuery('')
                                        setSelectedAssignee(null)
                                        setIsAssigneeSelected(false)
                                        updateTodo({
                                            action: 'assignee_id',
                                            payload: {
                                                id: todoId.toString(),
                                                data: null,
                                            },
                                        })
                                    }}
                                    // bg="#778CA2"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    // width="24px"
                                    // height="24px"
                                    // ml="8px"
                                    type="button"
                                >
                                    <FilledCloseIco />
                                </Button>
                            </Box>
                        )}
                        {/* <PredectiveOutput
                            left="32px"
                            top="24px"
                            showRecentSearches={false}
                            text={query}
                            onClick={(user: any) => {
                                // console.log(user)
                                updateTodo({
                                    payload: {
                                        id: todoId.toString(),
                                        data: user.id,
                                    },
                                    action: 'assignee_id',
                                })
                                setQuery(user.email)
                            }}
                            dataList={users}
                            objectKey="email"
                        ></PredectiveOutput> */}
                        {isDropdownVisible && (
                            <Users
                                onClickUser={(user) => {
                                    setSelectedAssignee(user)
                                    setIsDropdownVisible(false)
                                    setIsAssigneeSelected(true)
                                    updateTodo({
                                        payload: {
                                            id: todoId.toString(),
                                            data: user.user.id,
                                        },
                                        action: 'assignee_id',
                                    })
                                }}
                                users={users.filter((user: any) =>
                                    user.user.email.includes(query)
                                )}
                            />
                        )}
                    </Box>
                </Box>

                {sub_todos.map((subTodo, key) => {
                    // debugLog({subTodo})
                    return (
                        <>
                            <Box
                                key={key}
                                width="auto"
                                justifyContent="space-between"
                                mt="32px"
                                gridColumnGap="72px"
                                gridTemplateColumns="minmax(200px, 200px) 1fr"
                                display="grid"
                            >
                                <Box
                                    gridTemplateColumns="minmax(200px, 200px) 1fr"
                                    display="grid"
                                    height="100%"
                                    gridColumnGap="8px"
                                    alignItems="flex-start"
                                >
                                    <Box display="flex" alignItems="center">
                                        {subTodo.editableHeading &&
                                            isEditing && (
                                                <Input
                                                    autoComplete="false"
                                                    id="avoid_click"
                                                    placeholder={
                                                        addProductTodos.find(
                                                            (todo) =>
                                                                todo.id ===
                                                                todoId
                                                        )?.sub_todos.data[key]
                                                            .heading
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) =>
                                                        updateSubTodo({
                                                            payload: {
                                                                data: e.target
                                                                    .value,
                                                                subTodoId:
                                                                    subTodo.id,
                                                                todoId: todoId,
                                                            },
                                                            action: 'heading',
                                                        })
                                                    }
                                                    height="max-content"
                                                    border="none"
                                                    px="12px"
                                                    width="70%"
                                                    py="4px"
                                                    borderRadius="4px"
                                                    bg="gray250"
                                                    fontSize="16px"
                                                ></Input>
                                            )}
                                        {!subTodo.editableHeading ? (
                                            <Text
                                                my="0px"
                                                fontSize="16px"
                                                fontWeight={500}
                                                color="primary"
                                            >
                                                {subTodo.heading}
                                            </Text>
                                        ) : isEditing ? (
                                            ''
                                        ) : (
                                            <Text
                                                my="0px"
                                                fontSize="16px"
                                                fontWeight={500}
                                                color="primary"
                                            >
                                                {subTodo.heading}
                                            </Text>
                                        )}

                                        {subTodo.editableHeading &&
                                            (!isEditing ? (
                                                <Button
                                                    type="button"
                                                    bg="transparent"
                                                    border="none"
                                                    height="max-content"
                                                    p="0px"
                                                    ml="16px"
                                                    onClick={() =>
                                                        setIsEditing(true)
                                                    }
                                                >
                                                    <EditIco />
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    bg="transparent"
                                                    border="none"
                                                    height="max-content"
                                                    p="0px"
                                                    ml="16px"
                                                    onClick={() =>
                                                        setIsEditing(false)
                                                    }
                                                >
                                                    <CrossSm />
                                                </Button>
                                            ))}
                                    </Box>
                                </Box>

                                <Box>
                                    <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        gridColumnGap="12px"
                                    >
                                        <Box width="max-content">
                                            <DescriptionIco />
                                        </Box>
                                        <Box
                                            display="flex"
                                            mt="2px"
                                            flexDirection="column"
                                            justifyContent="center"
                                        >
                                            <Text
                                                mt="16px"
                                                fontSize="16px"
                                                my="0px"
                                                fontFamily="Rubik"
                                                color="textSecondaryDarkBlue"
                                            >
                                                Description
                                            </Text>
                                            <TextArea
                                                id={`product_form_todo_description_${subTodo.heading}`}
                                                value={
                                                    addProductTodos
                                                        .find(
                                                            (todo) =>
                                                                todo.id ===
                                                                todoId
                                                        )
                                                        ?.sub_todos.data.find(
                                                            (data) =>
                                                                data.id ===
                                                                subTodo.id
                                                        )?.description || ''
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                ) =>
                                                    updateSubTodo({
                                                        payload: {
                                                            todoId,
                                                            subTodoId:
                                                                subTodo.id,
                                                            data: e.target
                                                                .value,
                                                        },
                                                        action: 'description',
                                                    })
                                                }
                                                cols={90}
                                                rows={5}
                                                fontFamily="Rubik"
                                                bg="white"
                                                width="100%"
                                                mt="16px"
                                                color="primary"
                                                border="none"
                                                fontSize="14px"
                                                placeholder="Add to-do description"
                                                className="placeholder-light"
                                            ></TextArea>
                                        </Box>
                                    </Box>
                                    {subTodo.type === 'file' && (
                                        <Box
                                            display="flex"
                                            alignItems="flex-start"
                                            gridColumnGap="12px"
                                        >
                                            <AddFileIco />
                                            <Box
                                                position="relative"
                                                display="flex"
                                                mt="2px"
                                                flexDirection="column"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    mt="16px"
                                                    fontSize="16px"
                                                    my="0px"
                                                    fontFamily="Rubik"
                                                    color="textSecondaryDarkBlue"
                                                >
                                                    Assign File
                                                </Text>
                                                <Box
                                                    alignItems="flex-start"
                                                    gridColumnGap="12px"
                                                    display="flex"
                                                >
                                                    <Button
                                                        data-dropdown-focus={
                                                            subTodo.id
                                                        }
                                                        type="button"
                                                        mt="12px"
                                                        id={`product_form_todo_add_file_${subTodo.heading}`}
                                                        onClick={() => {
                                                            setSubTodosList(
                                                                (subTodos) => ({
                                                                    ...subTodos,
                                                                    [subTodo.id]:
                                                                        true,
                                                                })
                                                            )
                                                            disableFiledropdown(
                                                                subTodo.id
                                                            )
                                                        }}
                                                        className="dashed__border"
                                                        bg="transparent"
                                                        border="none"
                                                        width="72px"
                                                        height="72px"
                                                    >
                                                        {' '}
                                                        <PlusIco />
                                                    </Button>
                                                    {subTodosList[
                                                        subTodo.id
                                                    ] && (
                                                        <Dropdown
                                                            ref={dropdownBadge}
                                                            top="70px"
                                                            left="52px"
                                                            active={
                                                                subTodosList[
                                                                    subTodo.id
                                                                ]
                                                            }
                                                        >
                                                            <>
                                                                <DropdownButtonWrapper
                                                                    data-dropdown-focus={
                                                                        subTodo.id
                                                                    }
                                                                    width="100%"
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        height="100%"
                                                                        fontFamily="Rubik"
                                                                        color="primary"
                                                                        bg="transparent"
                                                                        border="none"
                                                                        fontSize="14px"
                                                                        id={`product_form_todo_assign_files_${subTodo.heading}`}
                                                                        onClick={() => {
                                                                            disableFiledropdown()
                                                                            _navigateToFiles(
                                                                                subTodo.id
                                                                            )
                                                                        }}
                                                                    >
                                                                        Assign
                                                                        from
                                                                        Library
                                                                    </Button>
                                                                </DropdownButtonWrapper>
                                                                <DropdownButtonWrapper
                                                                    data-dropdown-focus={
                                                                        subTodo.id
                                                                    }
                                                                    width="100%"
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        height="100%"
                                                                        fontFamily="Rubik"
                                                                        color="primary"
                                                                        bg="transparent"
                                                                        border="none"
                                                                        fontSize="14px"
                                                                        onClick={() => {
                                                                            setFileModal(
                                                                                (
                                                                                    fileModal
                                                                                ) => ({
                                                                                    ...fileModal,
                                                                                    subTodoId:
                                                                                        subTodo.id,
                                                                                    isFileModal:
                                                                                        true,
                                                                                })
                                                                            )
                                                                            disableFiledropdown()
                                                                        }}
                                                                        id={`product_form_todo_upload_files_${subTodo.heading}`}
                                                                    >
                                                                        Upload
                                                                        from
                                                                        Computer
                                                                    </Button>
                                                                </DropdownButtonWrapper>
                                                            </>
                                                        </Dropdown>
                                                    )}
                                                    <Box
                                                        mt="8px"
                                                        display="grid"
                                                        gridColumnGap="12px"
                                                        gridRowGap="12px"
                                                        gridTemplateColumns="1fr 1fr 1fr 1fr"
                                                    >
                                                        {subTodo.file.map(
                                                            (
                                                                file: FileData,
                                                                key
                                                            ) => {
                                                                if (
                                                                    file.path
                                                                        ?.length <=
                                                                    0
                                                                )
                                                                    return null
                                                                return (
                                                                    <FileDisplay
                                                                        redirectionUrl={createRedirectionUrl(
                                                                            {
                                                                                path: file.name,
                                                                                gid: file.path,
                                                                                ref: 'self',
                                                                            }
                                                                        )}
                                                                        params={[
                                                                            {
                                                                                key: 'gid',
                                                                                value:
                                                                                    file.gid ||
                                                                                    '0',
                                                                            },
                                                                        ]}
                                                                        redirect={
                                                                            false
                                                                        }
                                                                        openFileInNewTab={
                                                                            true
                                                                        }
                                                                        onDelete={() => {
                                                                            removeFile(
                                                                                {
                                                                                    payload:
                                                                                        {
                                                                                            todoId: todoId,
                                                                                            subTodoId:
                                                                                                subTodo.id,
                                                                                            fileId: file.id,
                                                                                        },
                                                                                }
                                                                            )
                                                                            addDeleteFile(
                                                                                {
                                                                                    path: file.path,
                                                                                }
                                                                            )
                                                                        }}
                                                                        key={
                                                                            key
                                                                        }
                                                                        url={
                                                                            file?.path?.includes(
                                                                                'blob'
                                                                            )
                                                                                ? file.path
                                                                                : file.is_library_file
                                                                                ? getLibraryFileUrl(
                                                                                      file.path
                                                                                  )
                                                                                : getImageUrl(
                                                                                      file.path
                                                                                  )
                                                                        }
                                                                        name={
                                                                            file.name ||
                                                                            ''
                                                                        }
                                                                    ></FileDisplay>
                                                                )
                                                            }
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    {subTodo.type === 'color' && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="12px"
                                        >
                                            <PaintIco />
                                            {/* <Input id={`product_form_color_picker_${subTodo.heading}`} width='28px' height='28px' border='none' className="visibility-none-1" bg="transparent" type="color" value={subTodo.color.length > 0 ? subTodo.color : "#000"} onChange={(e) => {}}/> */}
                                            {/* <Input autoComplete='false' id={`product_form_color_picker_${subTodo.heading}`} width='28px' height='28px' border='none' className="visibility-none-1" bg="transparent" type="color" value={subTodo.color.length > 0 ? subTodo.color : "#000"} onChange={(e) => {
                                                //     ChangeColor(e)
                                                //     updateSubTodo({
                                                //         payload: {
                                                //             todoId,
                                                //             subTodoId:
                                                //                 subTodo.id,
                                                //             data: e.target
                                                //                 .value,
                                                //         },
                                                //         action: 'color',
                                                //     })
                                            }} /> */}
                                            <Box
                                                display="flex"
                                                maxWidth="60%"
                                                gridRowGap="8px"
                                                flexWrap="wrap"
                                                alignItems="center"
                                                gridColumnGap={'8px'}
                                            >
                                                {typeof subTodo.color !==
                                                    'string' &&
                                                    subTodo.color.map(
                                                        (colorTag) => {
                                                            if (
                                                                !colorTag.name ||
                                                                colorTag.name
                                                                    ?.length <=
                                                                    0
                                                            )
                                                                return <></>
                                                            return (
                                                                <TagComponent
                                                                    key={
                                                                        colorTag.id
                                                                    }
                                                                    name={
                                                                        colorTag.name
                                                                    }
                                                                    onDelete={(
                                                                        e: MouseEvent
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        updateSubTodo(
                                                                            {
                                                                                payload:
                                                                                    {
                                                                                        todoId,
                                                                                        subTodoId:
                                                                                            subTodo.id,
                                                                                        data: subTodo.color.filter(
                                                                                            (
                                                                                                tag
                                                                                            ) =>
                                                                                                tag.id !==
                                                                                                colorTag.id
                                                                                        ),
                                                                                    },
                                                                                action: 'color',
                                                                            }
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    )}

                                                <Box position="relative">
                                                    <Box
                                                        ref={wrapperRef}
                                                        id="picker"
                                                        position="relative"
                                                    >
                                                        <Box
                                                            zIndex={5}
                                                            position="relative"
                                                            onMouseDown={() =>
                                                                setShowColorPicker(
                                                                    true
                                                                )
                                                            }
                                                            bg="white"
                                                            id="v3d_hex_colorpicker"
                                                            color="textSecondaryDarkBlue"
                                                            onClick={() => {}}
                                                        >
                                                            Add Color/HEX
                                                        </Box>
                                                        {showColorPicker && (
                                                            <Box
                                                                ref={
                                                                    colorPicker
                                                                }
                                                                id="color-picker-box"
                                                                zIndex={10}
                                                                bg="white"
                                                                p={'8px'}
                                                                borderRadius="8px"
                                                                position="absolute"
                                                                top="30px"
                                                                border="1px solid #E8ECEF"
                                                            >
                                                                <HexColorPicker
                                                                    color={
                                                                        color
                                                                    }
                                                                    inputMode="text"
                                                                    onChange={
                                                                        setColor
                                                                    }
                                                                />
                                                                <Box
                                                                    ref={
                                                                        colorPickerInput
                                                                    }
                                                                    border="1px solid #E8ECEF"
                                                                    p={'8px'}
                                                                    mt="12px"
                                                                    borderRadius="4px"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    <HexColorInput
                                                                        id="hex-color-input"
                                                                        onInput={(
                                                                            e
                                                                        ) => {
                                                                            setColor(
                                                                                e
                                                                                    .currentTarget
                                                                                    .value
                                                                            )
                                                                        }}
                                                                        color={
                                                                            color
                                                                        }
                                                                        onChange={
                                                                            setColor
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                            border: 'none',
                                                                        }}
                                                                        placeholder="Type a color"
                                                                        prefixed
                                                                        alpha
                                                                    />
                                                                    {/* <Box borderRadius='4px' bg={color} width={"24px"} height={"24px"}></Box> */}
                                                                    <Line
                                                                        color="textSecondary"
                                                                        lineWidth="1px"
                                                                        direction="vertical"
                                                                        length="12px"
                                                                    />
                                                                    <Button
                                                                        onClick={() => {
                                                                            setShowColorPicker(
                                                                                false
                                                                            )
                                                                            setColorTags(
                                                                                (
                                                                                    tags
                                                                                ) => [
                                                                                    ...tags,
                                                                                    {
                                                                                        id: uuid(),
                                                                                        name: color,
                                                                                    },
                                                                                ]
                                                                            )
                                                                            updateSubTodo(
                                                                                {
                                                                                    payload:
                                                                                        {
                                                                                            todoId,
                                                                                            subTodoId:
                                                                                                subTodo.id,
                                                                                            data: [
                                                                                                ...subTodo.color,
                                                                                                {
                                                                                                    id: uuid(),
                                                                                                    name: color,
                                                                                                },
                                                                                            ],
                                                                                        },
                                                                                    action: 'color',
                                                                                }
                                                                            )
                                                                        }}
                                                                        border="none"
                                                                        width="max-content"
                                                                        m="0px"
                                                                        bg="transparent"
                                                                        color="links"
                                                                    >
                                                                        + Add
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {/* <Input
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setColor(e.target.value)
                                                    // updateSubTodo({
                                                    //     payload: {
                                                    //         todoId,
                                                    //         subTodoId:
                                                    //             subTodo.id,
                                                    //         data: e.target
                                                    //             .value,
                                                    //     },
                                                    //     action: 'color',
                                                    // })

                                                }
                                                }
                                                id={`product_form_color_${subTodo.heading}`}
                                                bg="white"
                                                color="primary"
                                                className="placeholder-light"
                                                border="none"
                                                fontSize="16px"
                                                fontFamily="Rubik"
                                                placeholder="Add Color/HEX"
                                                maxLength={7}
                                                // value={
                                                //     addProductTodos
                                                //         .find(
                                                //             (todo) =>
                                                //                 todo.id ===
                                                //                 todoId
                                                //         )
                                                //         ?.sub_todos.data.find(
                                                //             (data) =>
                                                //                 data.id ===
                                                //                 subTodo.id
                                                //         )?.color
                                                // }
                                            ></Input>*/}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {subTodo.editableHeading && (
                                <Button
                                    type="button"
                                    onClick={() =>
                                        removeNewTodo({ payload: todoId })
                                    }
                                    width="max-content"
                                    borderRadius="4px"
                                    px="24px"
                                    py="8px"
                                    bg="transparent"
                                    borderColor="delay"
                                    border="solid"
                                    borderWidth={1}
                                    color="delay"
                                >
                                    {' '}
                                    Delete To-Do
                                </Button>
                            )}
                        </>
                    )
                })}
            </Box>

            {fileModal.isFileModal && (
                <Modal
                    onClose={() => {
                        setFileModal({
                            subTodoId: null,
                            files: [],
                            isFileModal: false,
                        })
                    }}
                    isModal={fileModal.isFileModal}
                    onClick={() => {
                        addFilesToSubTodo(fileModal.subTodoId || null)
                    }}
                    primaryButtonTitle="Upload"
                    secondaryButtonTitle="Cancel"
                    title="Upload Files"
                >
                    <Box
                        p="24px"
                        width="100%"
                        height="330px"
                        overflowY={
                            fileModal.files.length > 8 ? 'auto' : 'hidden'
                        }
                    >
                        <Box
                            height={
                                fileModal.files.length > 0 ? '55px' : '100%'
                            }
                            onDragOver={(event) => {
                                event.preventDefault()
                            }}
                            onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                                onFileDragged(event)
                            }
                            position="relative"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className="dashed__border_light"
                        >
                            <Input
                                autoComplete="false"
                                multiple
                                type="file"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    return (
                                        e.target.files &&
                                        onFileInput(e?.target?.files)
                                    )
                                }}
                                onClick={(e: any) => {
                                    e.target.value = null
                                }}
                                display="none"
                                ref={inputRef}
                                bg="none"
                            />
                            <Text
                                fontFamily="Rubik"
                                fontSize="16px"
                                color="textSecondaryDarkBlue"
                            >
                                Drag files here
                            </Text>
                            <Text
                                fontFamily="Rubik"
                                mx="12px"
                                fontSize="12px"
                                color="textSecondaryDarkBlue"
                            >
                                or
                            </Text>
                            <Button
                                p="0px"
                                type="button"
                                bg="transparent"
                                border="none"
                                color="links"
                                fontFamily="Rubik"
                                onClick={openFileDialogueBox}
                                fontWeight={500}
                            >
                                Choose files to upload
                            </Button>
                        </Box>
                        <Box
                            overflowY="auto"
                            mt="16px"
                            display="grid"
                            gridRowGap="8px"
                            gridTemplateColumns="1fr 1fr 1fr 1fr"
                            gridTemplateRows="auto"
                            flexWrap="wrap"
                        >
                            {fileModal.files.map((files, key: number) => {
                                return (
                                    <FileDisplay
                                        redirect={false}
                                        redirectionUrl={createRedirectionUrl({
                                            path: files.name,
                                            gid: files.path,
                                            ref: 'self',
                                        })}
                                        openFileInNewTab={true}
                                        onDelete={() => onDelete(files.id)}
                                        key={key}
                                        url={files.path}
                                        name={files.name}
                                    ></FileDisplay>
                                )
                            })}
                        </Box>
                    </Box>
                </Modal>
            )}
        </>
    )
}

type tag = {
    name: string
    id: string
}

export function TagComponent({
    name,
    color,
    onDelete,
    onUpdate,
    colorPickerRelativePoint = 'top',
    isEditPermitted = true,
}: {
    name: string
    color?: string
    onDelete: (e: MouseEvent) => void
    onUpdate?: (tag: tag) => void
    colorPickerRelativePoint?: 'top' | 'bottom'
    isEditPermitted?: boolean
}) {
    return (
        <Box display="flex" alignItems="center" position="relative">
            <Box
                px="8px"
                width="max-content"
                borderRadius="20px"
                py="4px"
                display="flex"
                fontFamily="Rubik"
                fontSize="12px"
                color="textTags"
                alignItems="center"
                justifyContent="space-between"
                bg={'#F0F2F3'}
                className={isEditPermitted ? 'cursor-pointer' : ''}
            >
                <Box
                    borderRadius="50%"
                    bg={color || name}
                    mr="4px"
                    width={'12px'}
                    height={'12px'}
                ></Box>
                {name}
                {isEditPermitted && (
                    <Button
                        onClick={(e: any) => onDelete(e)}
                        bg="transparent"
                        display="flex"
                        type="button"
                        justifyContent="center"
                        alignItems="center"
                        border="none"
                        id="v3d-tag-close-btn"
                    >
                        <CrossSm id="v3d-tag-close" />
                    </Button>
                )}
            </Box>
        </Box>
    )
}
