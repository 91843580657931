import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { LogoIcoSm } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { getLocalStorage } from 'src/helpers'
import { GET_USERNAME } from 'src/services'

export const PasswordResetLink = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState(null)
    const { state }: { state: any } = useLocation()
    const user = getLocalStorage('user')
    const authToken = getLocalStorage('authToken')
    useEffect(() => {
        console.log(user)
        if (user && authToken && user.length > 0 && authToken.length > 0) {
            navigate('/')
        }
    }, [user, authToken])

    useEffect(() => {
        ;(async function () {
            // debugLog(state)
            try {
                const { data } = await axios.get(GET_USERNAME + state)
                // debugLog(data)
                setEmail(data.username.name)
            } catch (e) {
                setEmail(state.email)
            }
        })()
    }, [state])

    return (
        <Box height="100vh">
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="70%"
                bg="white"
                py="72px"
            >
                <Box
                    bg="white"
                    justifyContent="center"
                    display="flex"
                    flexDirection="column"
                    width="550px"
                    alignItems="center"
                >
                    <Box mb="52px">
                        <LogoIcoSm />
                    </Box>

                    <Text
                        color="primary"
                        fontSize={{ md: '24px', xl: '32px' }}
                        fontFamily="Rubik"
                        fontWeight={500}
                    >
                        Password Reset Link Sent
                    </Text>

                    <Text
                        className="text-wrap"
                        color="primary"
                        fontSize="16px"
                        fontFamily="Rubik"
                        textAlign="center"
                        mt="0px"
                        mb="0px"
                    >
                        We have sent a password reset link to
                        <span className="link-color"> {state}</span>. Check your
                        email and click on the link to reset your password.
                    </Text>

                    <Box
                        width="100%"
                        display="flex"
                        mt="40px"
                        justifyContent="center"
                    >
                        <ColoredButton
                            variant="primary"
                            onClick={() => navigate('/login')}
                            width="360px"
                            id="fp-back-to-login"
                        >
                            Back to Login
                        </ColoredButton>
                    </Box>
                </Box>
            </Box>
            <Box className="auth-body" width="100%" height="100%" />
        </Box>
    )
}
