import React from 'react'
import { Box, Text } from 'src/components/utility'
import DependentTodoBadge from './dependent-todo-badge'
import type { GetDependentTodosQuery } from 'src/generated/graphql'
import { Loader } from 'src/components/loader/loader'

function DependentTodosPopover({
    todos,
    loading,
}: {
    todos: GetDependentTodosQuery['todos'] | undefined
    loading: boolean
}) {
    return (
        <Box
            py="8px"
            px="8px"
            display="flex"
            flexDirection="column"
            gridGap="10px"
            borderRadius="4px"
            width={'192px'}
            maxHeight={'35vh'}
            bg="white"
            boxShadow="0px 2px 12px 0px #999BA840"
            zIndex={10}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <Text my="0px" fontSize={'14px'} color="primary">
                Dependent To-Dos
            </Text>
            {todos ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    gridGap="4px"
                    width="100%"
                >
                    {todos.map((todo) => (
                        <DependentTodoBadge
                            key={todo.id}
                            status={todo.status}
                            todoId={todo.id}
                            todoName={todo.name}
                        />
                    ))}
                </Box>
            ) : loading ? (
                <Box display="flex" justifyContent="center">
                    <Loader outline="black" size="24px" />
                </Box>
            ) : null}
        </Box>
    )
}

export default DependentTodosPopover
