import * as React from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { Statuses } from 'src/components/status'
import { DropDownArrow } from 'src/components/svg-icons'
import { Box, Text } from 'src/components/utility'
import { statuses } from 'src/helpers'
import { getStatusColor } from 'src/helpers/enums'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'

interface IStatusDropdownProps {
    status: string
    onStatusChange: (status: (typeof statuses)[keyof typeof statuses]) => void
    exclude?: (typeof statuses)[keyof typeof statuses][]
    isManager?: boolean
    left?: string
}

const statusOptions = [
    {
        name: 'NEW',
        label: statuses.new,
        color: 'textTags',
    },
    {
        name: 'IN PROGRESS',
        label: statuses.inProgress,
        color: getStatusColor(statuses.inProgress),
    },
    {
        name: 'IN REVIEW',
        label: statuses.inReview,
        color: getStatusColor(statuses.inReview),
    },
    {
        name: 'REQUEST REVISION',
        label: statuses.requestedForResubmission,
        color: getStatusColor(statuses.revisionRequested),
    },
    {
        name: 'TO BE REVIEWED',
        label: statuses.toBeReviewed,
        color: getStatusColor(statuses.toBeReviewed),
    },
    {
        name: 'COMPLETED',
        label: statuses.complete,
        color: getStatusColor(statuses.complete),
    },
]

export default function StatusDropdown(props: IStatusDropdownProps) {
    const activeStatus = statusOptions.find((status) => {
        if (props.status === statuses.inReview && props.isManager) {
            return status.label === statuses.toBeReviewed
        }
        return status.label === props.status
    })
    const [isDropdownActive, setDropdownActive] = React.useState(false)
    const dropdownRef = useClickedOutside(() => setDropdownActive(false))
    return (
        <Box width={{ md: '100px', xl: '115px' }} position="relative">
            <HtmlTooltip
                placement="bottom"
                arrow
                title={<ToolTipHeader text={'Status'} />}
            >
                <Box
                    border="solid"
                    borderRadius="2px"
                    width={'100%'}
                    borderWidth={1}
                    p="4px"
                    borderColor={activeStatus?.color}
                    display="flex"
                    alignItems="center"
                    gridColumnGap="8px"
                    justifyContent="space-between"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setDropdownActive(!isDropdownActive)
                    }}
                >
                    <Text
                        fontSize={{ md: '10px', xl: '12px' }}
                        fontWeight={500}
                        color={activeStatus?.color}
                        my="0px"
                    >
                        {activeStatus?.name}
                    </Text>
                    <DropDownArrow />
                </Box>
            </HtmlTooltip>
            {isDropdownActive && (
                <Dropdown
                    top="30px"
                    left={props.left || '0px'}
                    py="8px"
                    active={isDropdownActive}
                    width={
                        props.exclude?.includes('requestedForResubmission')
                            ? '136px'
                            : '180px'
                    }
                >
                    <Box ref={dropdownRef} width="100%">
                        {statusOptions.map((status) => {
                            if (props?.exclude?.includes(status.label)) {
                                return null
                            }
                            return (
                                <DropdownButtonWrapper
                                    px="0px"
                                    width="100%"
                                    onClick={() => {
                                        setDropdownActive(false)
                                        props.onStatusChange(status.label)
                                    }}
                                >
                                    <Statuses
                                        type={status.label}
                                        showDropdown={false}
                                    />
                                </DropdownButtonWrapper>
                            )
                        })}
                    </Box>
                </Dropdown>
            )}
        </Box>
    )
}
