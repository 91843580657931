import React, { useState } from 'react'
import { DropdownBadge } from 'src/components/dropdown-badge/dropdown-badge'
import { Box, Button, Text } from 'src/components/utility'
import ProductDropdown from './product-dropdown/product-dropdown'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import TodoDropdown from './todo-dropdown/todo-dropdown'
import {
    GetActiveProductWithCollection,
    GetActiveTodoWithProduct,
} from './compare-entity-topbar.queries'
import { Cross } from 'src/components/svg-icons'
import CollectionDropdown from './collection-dropown/collection-dropdown'
import FullScreenIco from 'src/components/svg-icons/full-screen-ico'
import FullscreenTodoModal from '../modals/fullscreen-todo-modal'

function CompareEntitySelector({
    todoId,
    productId,
    isSplitViewMode,
    isCompareEntitySelector,
}: {
    todoId: number | undefined
    productId: number | undefined
    isSplitViewMode?: boolean
    isCompareEntitySelector?: boolean
}) {
    const [showDropdown, setShowDropdown] = useState({
        collection: false,
        product: false,
        todo: false,
    })
    const [showFullScreenEntityModal, setShowFullScreenEntityModal] =
        useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const [activeEntity, setActiveEntity] = useState<{
        collection: {
            id: number
            name: string
        } | null
        product: {
            id: number
            name: string
            todos: Array<{ id: number; name: string; types: string }>
        } | null
        todo: {
            id: number
            name: string
            types: string
        } | null
    }>({
        collection: null,
        product: null,
        todo: null,
    })

    // Populate the dropdown with the active collection, product and todo on page load
    useQuery(GetActiveTodoWithProduct, {
        skip: !todoId,
        variables: {
            id: Number(todoId),
        },
        onCompleted: (data) => {
            const payload: {
                collection: {
                    id: number
                    name: string
                }
                product: {
                    id: number
                    name: string
                    todos: Array<{ id: number; name: string; types: string }>
                }
                todo: {
                    id: number
                    name: string
                    types: string
                } | null
            } = {
                collection: data?.todos_by_pk?.product_variant?.collection,
                product: data?.todos_by_pk?.product_variant,
                todo: {
                    id: data?.todos_by_pk?.id,
                    name: data?.todos_by_pk?.name,
                    types: data?.todos_by_pk?.types,
                },
            }
            //Set the todo to null of compare topbar if the splitView is not open
            if (!isSplitViewMode && isCompareEntitySelector) {
                payload.todo = null
            }

            setActiveEntity(payload)
        },
    })

    // Populate the dropdown with the active collection, product and todo on page load
    useQuery(GetActiveProductWithCollection, {
        skip: !productId,
        variables: {
            id: Number(productId),
        },
        onCompleted: (data) => {
            setActiveEntity({
                collection: data?.product_variants_by_pk?.collection,
                product: {
                    id: data?.product_variants_by_pk?.id,
                    name: data?.product_variants_by_pk?.name,
                    todos: data?.product_variants_by_pk?.todos || [],
                },
                todo: null,
            })
        },
    })

    function selectCollectionHandler(collection: { id: number; name: string }) {
        setActiveEntity({
            collection,
            product: null,
            todo: null,
        })
        setShowDropdown({
            collection: false,
            product: false,
            todo: false,
        })
    }

    function selectProductHandler(product: {
        id: number
        name: string
        todos: Array<{ id: number; name: string; types: string }>
    }) {
        const { types: activeTodoType } = activeEntity.todo || {}
        const todoToNavigate = product.todos.find(
            (productTodo) => productTodo.types === activeTodoType
        )
        if (todoToNavigate) {
            setActiveEntity({
                ...activeEntity,
                product,
                todo: todoToNavigate,
            })
            setShowDropdown({
                collection: false,
                product: false,
                todo: false,
            })

            if (isCompareEntitySelector) {
                searchParams.set('compareEntity', 'todo')
                searchParams.set('compareId', todoToNavigate.id.toString())
                setSearchParams(searchParams, { replace: true })
            } else {
                navigate(
                    `/todo/${todoToNavigate.id}?${searchParams.toString()}`
                )
            }
        } else {
            setActiveEntity({
                ...activeEntity,
                product,
                todo: null,
            })
            setShowDropdown({
                collection: false,
                product: false,
                todo: false,
            })

            if (isCompareEntitySelector) {
                searchParams.set('compareEntity', 'product')
                searchParams.set('compareId', product.id.toString())
                setSearchParams(searchParams, { replace: true })
            } else {
                navigate(
                    `/collections/product/${
                        product.id
                    }?${searchParams.toString()}`
                )
            }
        }
    }

    function selectTodoHandler(todo: {
        id: number
        name: string
        types: string
    }) {
        setActiveEntity({
            ...activeEntity,
            todo,
        })
        setShowDropdown({
            collection: false,
            product: false,
            todo: false,
        })
        if (isCompareEntitySelector) {
            searchParams.set('compareEntity', 'todo')
            searchParams.set('compareId', todo.id.toString())
            setSearchParams(searchParams, { replace: true })
        } else {
            navigate(`/todo/${todo.id}` + '?' + searchParams.toString())
        }
    }

    function closeClickHandler(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        if (isCompareEntitySelector && isSplitViewMode) {
            setActiveEntity({
                collection: null,
                product: null,
                todo: null,
            })
            searchParams.delete('compareEntity')
            searchParams.delete('compareId')
            setSearchParams(searchParams, { replace: true })
        } else {
            const compareEntity = searchParams.get('compareEntity')
            const compareId = searchParams.get('compareId')
            if (compareEntity && compareId) {
                if (compareEntity === 'todo') {
                    navigate(`/todo/${compareId}`)
                } else if (compareEntity === 'product') {
                    navigate(`/collections/product/${compareId}`)
                }
            }
        }
    }

    return (
        <>
            <Box
                display="grid"
                gridTemplateColumns={'1fr auto auto'}
                height={'100%'}
                pr="16px"
                style={{
                    borderLeft: !isCompareEntitySelector
                        ? '1px solid'
                        : '0px solid',
                    borderRight:
                        isSplitViewMode && !isCompareEntitySelector
                            ? '4px solid'
                            : '1px solid',
                }}
                borderColor={
                    isSplitViewMode && !isCompareEntitySelector
                        ? 'primary'
                        : 'textSecondary'
                }
                bg={isCompareEntitySelector ? '#FAFCFF' : '#F0F2F3'}
            >
                <Box
                    display="flex"
                    gridGap="8px"
                    height="100%"
                    alignItems="center"
                    px="16px"
                >
                    {isCompareEntitySelector && (
                        <Text
                            my="0px"
                            fontSize="12px"
                            color="textSecondary"
                            fontWeight={500}
                        >
                            COMPARE WITH:
                        </Text>
                    )}
                    <Box position="relative">
                        <DropdownBadge
                            highlightedText={
                                activeEntity?.collection?.name || 'COLLECTION'
                            }
                            label=""
                            onclick={() => {
                                setShowDropdown({
                                    collection: !showDropdown.collection,
                                    product: false,
                                    todo: false,
                                })
                            }}
                            id="topbar-collection-dropdown-button"
                        />
                        {showDropdown.collection && (
                            <CollectionDropdown
                                onClose={() =>
                                    setShowDropdown({
                                        collection: false,
                                        product: false,
                                        todo: false,
                                    })
                                }
                                onSelect={(collection) => {
                                    selectCollectionHandler(collection)
                                    setShowDropdown({
                                        collection: false,
                                        product: true,
                                        todo: false,
                                    })
                                }}
                                isCompareEntitySelector={
                                    isCompareEntitySelector
                                }
                            />
                        )}
                    </Box>
                    <Box position="relative">
                        <DropdownBadge
                            highlightedText={
                                activeEntity?.product?.name || 'PRODUCT'
                            }
                            label=""
                            onclick={() => {
                                if (activeEntity.collection?.id) {
                                    setShowDropdown({
                                        collection: false,
                                        product: !showDropdown.product,
                                        todo: false,
                                    })
                                }
                            }}
                            disabled={!activeEntity.collection}
                            id="topbar-product-dropdown-button"
                        />
                        {showDropdown.product && (
                            <ProductDropdown
                                collectionName={
                                    activeEntity.collection?.name || ''
                                }
                                onClose={() =>
                                    setShowDropdown({
                                        collection: false,
                                        product: false,
                                        todo: false,
                                    })
                                }
                                onSelect={(product) => {
                                    selectProductHandler(product)
                                }}
                                collectionId={activeEntity.collection?.id}
                                isCompareEntitySelector={
                                    isCompareEntitySelector
                                }
                            />
                        )}
                    </Box>
                    <Box position="relative">
                        <DropdownBadge
                            highlightedText={
                                activeEntity?.todo?.name || 'TO-DO'
                            }
                            label=""
                            onclick={() => {
                                if (activeEntity.product?.id) {
                                    setShowDropdown({
                                        collection: false,
                                        product: false,
                                        todo: !showDropdown.todo,
                                    })
                                }
                            }}
                            disabled={!activeEntity.product}
                            id="topbar-todo-dropdown-button"
                        />
                        {showDropdown.todo && (
                            <TodoDropdown
                                onClose={() =>
                                    setShowDropdown({
                                        collection: false,
                                        product: false,
                                        todo: false,
                                    })
                                }
                                collectionName={
                                    activeEntity.collection?.name || ''
                                }
                                onSelect={(todo) => {
                                    selectTodoHandler(todo)
                                }}
                                productId={activeEntity.product?.id}
                                isCompareEntitySelector={
                                    isCompareEntitySelector
                                }
                            />
                        )}
                    </Box>
                </Box>
                {!!activeEntity.todo && (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowFullScreenEntityModal(
                                !showFullScreenEntityModal
                            )
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                    >
                        <FullScreenIco />
                    </Button>
                )}
                {isSplitViewMode && (
                    <Button
                        onClick={closeClickHandler}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                    >
                        <Cross color="gray" />
                    </Button>
                )}
            </Box>
            {showFullScreenEntityModal && !!activeEntity?.todo && (
                <FullscreenTodoModal
                    onClose={() => setShowFullScreenEntityModal(false)}
                    todoId={activeEntity?.todo?.id}
                />
            )}
        </>
    )
}

export default CompareEntitySelector
