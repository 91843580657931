import type { MeshProps } from '@react-three/fiber'
import * as React from 'react'
import { uuid } from 'src/helpers'
import { fabricPresets } from './helper/presets'

const Preset: Enviornment[] = [
    {
        file: '1stStudioHDRI_FountaneConfgtr.hdr',
        lights: [
            <directionalLight
                frustumCulled={true}
                position={[20, 30, 30]}
                intensity={1.4}
                visible={true}
                castShadow={true}
            />,
            <directionalLight
                frustumCulled={true}
                position={[-10, 0, 2]}
                intensity={0.5}
                visible={false}
                castShadow={false}
            />,
        ],
    },
    {
        file: '1stStudioHDRI_FountaneConfgtr_adjustd.hdr',
        lights: [
            <directionalLight
                frustumCulled={true}
                position={[20, 30, 30]}
                intensity={1.8}
                visible={true}
                castShadow={true}
            />,
            <directionalLight
                frustumCulled={true}
                position={[-10, 0, 2]}
                intensity={0.8}
                visible={true}
                castShadow={false}
            />,
        ],
    },
    {
        file: 'Studio_MediumContrast.hdr',
        lights: [],
    },
    {
        file: 'Studio_LowContrast.hdr',
        lights: [],
    },
]

export const layers: { [x: number]: ColorLayers } = {
    1: {
        destination: 'destColor1_Base',
        emphasis: 'emphasizeSrcLumSat1_Base',
        source: 'srcColor1_Base',
        sensitivity: 'srcBWHue1_Base',
        id: uuid(),
        isActive: true,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
    2: {
        destination: 'destColor2_Base',
        emphasis: 'emphasizeSrcLumSat2_Base',
        source: 'srcColor2_Base',
        sensitivity: 'srcBWHue2_Base',
        id: uuid(),
        isActive: false,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
    3: {
        destination: 'destColor3_Base',
        emphasis: 'emphasizeSrcLumSat3_Base',
        source: 'srcColor3_Base',
        sensitivity: 'srcBWHue3_Base',
        id: uuid(),
        isActive: false,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
    4: {
        destination: 'destColor4_Base',
        emphasis: 'emphasizeSrcLumSat4_Base',
        source: 'srcColor4_Base',
        sensitivity: 'srcBWHue4_Base',
        id: uuid(),
        isActive: false,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
    5: {
        destination: 'destColor5_Base',
        emphasis: 'emphasizeSrcLumSat5_Base',
        source: 'srcColor5_Base',
        sensitivity: 'srcBWHue5_Base',
        id: uuid(),
        isActive: false,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
    6: {
        destination: 'destColor6_Base',
        emphasis: 'emphasizeSrcLumSat6_Base',
        source: 'srcColor6_Base',
        sensitivity: 'srcBWHue6_Base',
        id: uuid(),
        isActive: false,
        isSourceActive: true,
        sensitivityValue: 0.065,
        destinationColor: null,
        emphasisValue: 0.1,
        sourceColor: null,
    },
}

const Context = React.createContext<{
    highlights: {
        //@ts-ignore
        mesh: Object3D<Object3DEventMap>
        isActive: boolean
    }[]
    setHighlights: React.Dispatch<
        React.SetStateAction<
            {
                //@ts-ignore
                mesh: Object3D<Object3DEventMap>
                isActive: boolean
            }[]
        >
    >
    maps: Maps
    setMaps: React.Dispatch<React.SetStateAction<Maps>>
    config: Config
    setConfig: React.Dispatch<React.SetStateAction<Config>>
    enviornment: Enviornment
    envList: typeof Preset
    operation: 'pan' | 'annotation' | 'renderImage' | 'background'
    setOperation: React.Dispatch<
        React.SetStateAction<
            'pan' | 'annotation' | 'renderImage' | 'background'
        >
    >
    setEnviornment: React.Dispatch<React.SetStateAction<Enviornment>>
    artWork: TMapConfig[]
    setArtwork: React.Dispatch<React.SetStateAction<TMapConfig[]>>
    activeArtWork: TMapConfig | undefined
    setActiveArtwork: React.Dispatch<
        React.SetStateAction<TMapConfig | undefined>
    >
    advancedMode: boolean
    setAdvancedMode: React.Dispatch<React.SetStateAction<boolean>>
    imageRenderDimensions: {
        width: number
        height: number
    }
    setImageRenderDimensions: React.Dispatch<
        React.SetStateAction<{
            width: number
            height: number
        }>
    >
    selectedColor: { color: string; id: string } | undefined
    setSelectedColor: React.Dispatch<
        React.SetStateAction<{ color: string; id: string } | undefined>
    >
    setColorSelectionMode: React.Dispatch<
        React.SetStateAction<'entireMesh' | 'specificArea'>
    >
    colorSelectionMode: 'entireMesh' | 'specificArea'
    setGraphic: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string
                  path: string
                  blob: Blob
                  id: string
                  config: {
                      offset: typeof UV
                      repeat: typeof UV
                      rotation: number
                      transparency: number
                      substanceNess: number
                  }
              }
            | undefined
        >
    >
    graphic:
        | {
              name: string
              path: string
              blob: Blob
              id: string
              config: {
                  offset: typeof UV
                  repeat: typeof UV
                  rotation: number
                  transparency: number
                  substanceNess: number
              }
          }
        | undefined
    graphicMaps: GraphicMaps
    setGraphicMaps: React.Dispatch<React.SetStateAction<GraphicMaps>>
    setColorLayers: React.Dispatch<
        React.SetStateAction<{ [meshId: string]: ColorLayers[] }>
    >
    colorLayers: { [meshId: string]: ColorLayers[] }
    setBackgroundAndShadows: React.Dispatch<
        React.SetStateAction<BackgroundAndShadows>
    >
    backgroundAndShadows: BackgroundAndShadows
    recentlyUsed: TRecentlyUsed
    setRecentlyUsed: React.Dispatch<React.SetStateAction<TRecentlyUsed>>
    selectedFabric: TFabric | undefined
    setSelectedFabric: React.Dispatch<React.SetStateAction<TFabric | undefined>>
}>({
    highlights: [],
    setHighlights: () => {},
    setMaps: () => {},
    operation: 'pan',
    setOperation: () => {},
    setArtwork: () => {},
    artWork: [],
    envList: Preset,
    maps: [
        {
            file: null,
            type: 'Base Map',
            code: 'map',
            sliderType: null,
            id: 1,
        },
        {
            file: null,
            type: 'Normal Map',
            code: 'normalMap',
            sliderType: null,
            id: 2,
        },
        {
            file: null,
            type: 'Roughness Map',
            code: 'roughnessMap',
            sliderType: 'ROUGH_NESS',
            id: 3,
        },
        {
            file: null,
            type: 'Metalness Map',
            code: 'metalnessMap',
            sliderType: 'METAL_NESS',
            id: 5,
        },
        {
            file: null,
            type: 'Displacement Map',
            code: 'displacementMap',
            sliderType: null,
            id: 4,
        },
    ],
    enviornment: {
        file: '',
        lights: [],
    },
    setEnviornment: () => {},
    setConfig: () => {},
    config: {
        scale: {
            u: 1,
            v: 1,
        },
        offset: {
            u: 0,
            v: 0,
        },
        rotation: 0,
        metalnessIntensity: 0,
        roughnessIntensity: 0,
    },
    advancedMode: false,
    setAdvancedMode: () => {},
    activeArtWork: undefined,
    setActiveArtwork: () => {},
    imageRenderDimensions: {
        width: 3840,
        height: 2160,
    },
    setImageRenderDimensions: () => {},
    selectedColor: undefined,
    setSelectedColor: () => {},
    setColorSelectionMode: () => {},
    colorSelectionMode: 'entireMesh',
    setGraphic: () => {},
    graphic: undefined,
    setGraphicMaps: () => {},
    graphicMaps: [],
    setColorLayers: () => {},
    colorLayers: {},
    backgroundAndShadows: {
        activePresetColor: null,
        backgroundColor: '#212121',
        isShadowEnabled: false,
        presets: [],
        recentlyUsedColors: [],
    },
    setBackgroundAndShadows: () => {},
    recentlyUsed: {
        colors: [],
        fabrics: [],
        graphics: [],
    },
    setRecentlyUsed: () => {},
    selectedFabric: undefined,
    setSelectedFabric: () => {},
})

const UV = {
    u: 0,
    v: 0,
}

export interface IConfiguratorContextProps {
    children: JSX.Element
}

export default function ConfiguratorContext(props: IConfiguratorContextProps) {
    const { children } = props
    const [operation, setOperation] = React.useState<
        'pan' | 'annotation' | 'renderImage' | 'background'
    >('pan')
    const [advancedMode, setAdvancedMode] = React.useState(false)
    const [selectedColor, setSelectedColor] = React.useState<
        { color: string; id: string } | undefined
    >()
    const [colorSelectionMode, setColorSelectionMode] = React.useState<
        'entireMesh' | 'specificArea'
    >('entireMesh')
    const [activeArtWork, setActiveArtwork] = React.useState<TMapConfig>()
    const [graphic, setGraphic] = React.useState<{
        name: string
        path: string
        blob: Blob
        id: string
        config: {
            offset: typeof UV
            repeat: typeof UV
            rotation: number
            transparency: number
            substanceNess: number
        }
    }>()
    const [artWork, setArtwork] = React.useState<TMapConfig[]>([])
    const [imageRenderDimensions, setImageRenderDimensions] = React.useState({
        height: 1080,
        width: 2046,
    })
    const [highlights, setHighlights] = React.useState<
        {
            mesh: MeshProps
            isActive: boolean
        }[]
    >([])
    const [config, setConfig] = React.useState({
        scale: {
            u: 1,
            v: 1,
        },
        offset: {
            u: 0,
            v: 0,
        },
        rotation: 0,
        metalnessIntensity: 0,
        roughnessIntensity: 0,
    })
    const [enviornment, setEnviornment] = React.useState<Enviornment>(Preset[0])
    const [graphicMaps, setGraphicMaps] = React.useState<GraphicMaps>([
        {
            file: null,
            type: 'Normal Map',
            code: 'normalMapLayer1',
            id: 1,
        },
        {
            file: null,
            type: 'Roughness Map',
            code: 'roughnessMapLayer1',
            id: 2,
        },
        {
            file: null,
            type: 'Metalness Map',
            code: 'metalnessMapLayer1',
            id: 3,
        },
    ])
    const [selectedFabric, setSelectedFabric] = React.useState<
        TFabric | undefined
    >()

    const [backgroundAndShadows, setBackgroundAndShadows] =
        React.useState<BackgroundAndShadows>({
            backgroundColor: '#212121',
            isShadowEnabled: false,
            activePresetColor: null,
            presets: [
                '#11835A',
                '#1A4DD1',
                '#8D8F94',
                '#5C39E9',
                '#EF8100',
                '#FFBDB8',
                '#022143',
                '#EAFC40',
                '#778CA2',
            ],
            recentlyUsedColors: [],
        })
    const [colorLayers, setColorLayers] = React.useState<{
        [meshId: string]: ColorLayers[]
    }>({})
    const [maps, setMaps] = React.useState<Maps>([
        {
            file: null,
            type: 'Base Map',
            code: 'map',
            sliderType: null,
            id: 1,
        },
        {
            file: null,
            type: 'Normal Map',
            code: 'normalMap',
            sliderType: null,
            id: 2,
        },
        {
            file: null,
            type: 'Roughness Map',
            code: 'roughnessMap',
            sliderType: 'ROUGH_NESS',
            id: 3,
        },
        {
            file: null,
            type: 'Metalness Map',
            code: 'metalnessMap',
            sliderType: 'METAL_NESS',
            id: 5,
        },
        {
            file: null,
            type: 'Displacement Map',
            code: 'displacementMap',
            sliderType: null,
            id: 4,
        },
    ])

    // Colors
    const [recentlyUsed, setRecentlyUsed] = React.useState<TRecentlyUsed>({
        colors: [],
        fabrics: fabricPresets,
        graphics: [],
    })

    return (
        <Context.Provider
            value={{
                envList: Preset,
                highlights,
                setHighlights,
                maps,
                setMaps,
                config,
                setConfig,
                enviornment,
                setEnviornment,
                operation,
                setOperation,
                artWork,
                setArtwork,
                advancedMode,
                setAdvancedMode,
                activeArtWork,
                setActiveArtwork,
                imageRenderDimensions,
                setImageRenderDimensions,
                selectedColor,
                setSelectedColor,
                graphic,
                setGraphic,
                graphicMaps,
                setGraphicMaps,
                colorSelectionMode,
                setColorSelectionMode,
                colorLayers,
                setColorLayers,
                backgroundAndShadows,
                setBackgroundAndShadows,
                recentlyUsed,
                setRecentlyUsed,
                selectedFabric,
                setSelectedFabric,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const useConfiguratorContext = (hex?: string) => {
    const context = React.useContext(Context)
    return context
}
