import * as React from 'react'

export const FileIco = () => (
    <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 3.411a1 1 0 0 0-1 1v8.667a1 1 0 0 0 1 1h6.205a1 1 0 0 0 1-1V6.116l-.115-.128-2.462-2.461L9.5 3.41H5Zm-.18.82h4.103v1.462a1 1 0 0 0 1 1h1.462v6.564H4.82V4.232Zm4.924.59 1.05 1.052h-1.05V4.82Z"
            fill="#778CA2"
        />
    </svg>
)

export const FileIcoLg = () => {
    return (
        <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447715 16 1 16H11.3077C11.86 16 12.3077 15.5523 12.3077 15V4.05769L12.1346 3.86538L8.44231 0.173077L8.25 0H1ZM1.23077 1.23077H7.38462V3.92308C7.38462 4.47536 7.83233 4.92308 8.38461 4.92308H11.0769V14.7692H1.23077V1.23077ZM8.61539 2.11538L10.1923 3.69231H8.61539V2.11538Z"
                fill="#778CA2"
            />
        </svg>
    )
}
