import { useEffect, useRef } from 'react'

export function useClickedOutside(
    onClickedOutside: () => void,
    exceptions?: string[],
    target: string = 'document'
) {
    const wrapperRef = useRef<HTMLDivElement | null>()
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                if (exceptions && exceptions?.includes(event.target.id)) {
                    return
                } else {
                    onClickedOutside()
                }
            }
        }
        const element = document.getElementById(target)
        if (target === 'document') {
            document.addEventListener('mousedown', handleClickOutside)
        } else if (element) {
            element.addEventListener('mousedown', handleClickOutside)
        }
        return () => {
            if (target === 'document') {
                document.removeEventListener('mousedown', handleClickOutside)
            } else if (element) {
                element.removeEventListener('mousedown', handleClickOutside)
            }
        }
    }, [wrapperRef, onClickedOutside])

    return wrapperRef
}
