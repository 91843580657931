import type { Dispatch } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

export function FileMenuDropdown({
    active,
    setIsDropdownActive,
    onDownload,
    onDelete,
    onRename,
    id,
}: {
    active: boolean
    setIsDropdownActive: Dispatch<React.SetStateAction<boolean>>
    onDownload: () => void
    onDelete: () => void
    onRename: () => void
    id: number
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        [`file-menu-button-${id}`]
    )
    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="32px"
            left="none"
            right="0px"
            width="120px"
            py="4px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper
                    value="Collections"
                    width="100%"
                    onClick={() => {
                        setIsDropdownActive(false)
                        onDownload()
                    }}
                >
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                    >
                        Download
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Collections"
                    width="100%"
                    onClick={() => {
                        setIsDropdownActive(false)
                        onRename()
                    }}
                >
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="primary"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                    >
                        Rename
                    </Button>
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        setIsDropdownActive(false)
                        onDelete()
                    }}
                >
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="danger"
                        bg="transparent"
                        border="none"
                        fontSize="12px"
                    >
                        Delete
                    </Button>
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}
