import { Divider } from '@mui/material'
import * as React from 'react'
import {
    DangerModal,
    // DivisionLine,
    ProgressBar,
    ProgressIcons,
} from 'src/components'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Dots } from 'src/components/svg-icons'
// import Group from 'src/components/svg-icons/group'
// import Group from 'src/components/svg-icons/group'
import { Box, Button, Td, Text, Tr } from 'src/components/utility'
import { getImageUrl, getSentenceCase, useUser } from 'src/helpers'
import useStore from 'src/store/hooks/useStore'
import CollaboratorDropdown from '../dropdown/collaborator-dropdown'
import {
    useDeleteCollaborator,
    useFetchEntityCount,
    useFetchOrganistionMembers,
    useInvite,
} from '../../hooks'
import { useSearchParams } from 'react-router-dom'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { Statuses } from 'src/components/status'

export interface ICollaboratorCardProps {
    contributor: IIndividualCollaborators
    onRefetch?: () => void
    onDelete: (contributor: IIndividualCollaborators[]) => void
}
function CollaboratorCard(props: ICollaboratorCardProps, ref: any) {
    const { contributor } = props
    const [collaboratorDropdown, setCollaboratorDropdown] =
        React.useState(false)
    const { role } = useUser()
    const [searchParam, setSearchParam] = useSearchParams()
    const { removeUser, reactivateUser } = useDeleteCollaborator()
    const { addDrawerTabOne } = useStore()
    const { fetchOrganistionMembers } = useFetchOrganistionMembers()
    const { cancelInvite, resendInvitation } = useInvite()
    const [deleteCollaborator, setDeleteCollaborator] = React.useState(false)
    const { getEntityCount } = useFetchEntityCount()

    async function dropdownClickHandler(value: string) {
        switch (value) {
            case 'edit_user':
                setCollaboratorDropdown(false)
                break
            case 'archive_user':
                setCollaboratorDropdown(false)
                break
            case 'remove_user':
                setDeleteCollaborator(true)
                setCollaboratorDropdown(false)
                break
            case 'reactivate-user':
                reactivateUser(String(contributor.user.id))
                props.onRefetch && props.onRefetch()
                setCollaboratorDropdown(false)
                break
            case 'resend_invite':
                resendInvitation(String(contributor.user.email))
                props.onRefetch && props.onRefetch()
                setCollaboratorDropdown(false)
                return
            case 'cancel_invite':
                cancelInvite(String(contributor.user.email))
                props.onRefetch && props.onRefetch()
                setCollaboratorDropdown(false)
                return
        }
    }

    async function onDeleteCollaborator() {
        try {
            removeUser(String(contributor.user.id), contributor.user.name)
            const data = await fetchOrganistionMembers({
                contributorsCurrentPage: Number(searchParam.get('page')) || 1,
                role: searchParam.get('role') || undefined,
                searchString: searchParam.get('q') || '',
            })
            getEntityCount('collaborator')
            if (data) props.onDelete(data.data)
        } catch (e) {}
        setDeleteCollaborator(false)
    }

    const outSideRef = useClickedOutside(() => setCollaboratorDropdown(false))
    const isAllowedToCancelOrResendInvite =
        role === 'admin' || role === 'manager'
    return (
        <>
            <Tr
                borderBottom="solid"
                className="cursor-pointer"
                borderWidth={1}
                borderColor="gray250"
                onClick={(e) => {
                    e.stopPropagation()
                    if (contributor.status?.toLowerCase() === 'invited') return
                    searchParam.set('drawer', '1')
                    searchParam.set('id', contributor.user.id.toString())
                    setSearchParam(searchParam)
                    addDrawerTabOne({
                        id: contributor.user.id.toString(),
                        type: 'individualCollaborator',
                        resource: {
                            ...contributor,
                            name: contributor.user.name,
                        },
                    })
                }}
            >
                <Td>
                    <Box
                        display="flex"
                        alignItems="center"
                        gridColumnGap="16px"
                    >
                        <ProfileImg
                            name={
                                contributor.user.name || contributor.user.email
                            }
                            src={getImageUrl(
                                contributor?.user?.other_data?.profile_pic
                            )}
                        />
                        <Text>
                            {contributor.user.name || contributor.user.email}
                        </Text>
                    </Box>
                </Td>
                <Td>{getSentenceCase(contributor.role, 'NAME')}</Td>
                <Td>{contributor.user?.teams || 0} </Td>

                <Td width="450px" py="12px" pr="0px">
                    <Box
                        display="flex"
                        gridColumnGap="8px"
                        height="max-content"
                    >
                        <Box display="flex" width="70px">
                            <Text fontSize="14px" color="textSecondary">
                                {contributor.user?.todosCount?.totalComplete ||
                                    '0'}{' '}
                                To-Do's
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                        >
                            <Divider
                                style={{
                                    border: '1px solid #E6E7E9',
                                    height: '20px',
                                }}
                            />
                            <ProgressIcons
                                completed={
                                    contributor.user?.todosCount?.complete
                                }
                                remaining={
                                    contributor.user?.todosCount?.delayed
                                }
                            />
                            <ProgressBar
                                percentage={Math.floor(
                                    contributor?.todosCompletionRate || 0
                                )}
                            />
                        </Box>
                    </Box>
                </Td>

                <Td py="12px">
                    {(props.contributor.status === 'Invited' ||
                        props.contributor.status === 'Active' ||
                        props.contributor.status === 'Archived') && (
                        <Statuses
                            showDropdown={false}
                            type={
                                (props.contributor?.status?.toLowerCase() as type) ||
                                'active'
                            }
                        />
                    )}
                </Td>

                {isAllowedToCancelOrResendInvite && (
                    <Td display="flex" py="12px">
                        <Box position="relative" ref={outSideRef}>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCollaboratorDropdown(
                                        !collaboratorDropdown
                                    )
                                }}
                                bg="white"
                                border="none"
                            >
                                <Dots active={false} />
                            </Button>
                            {collaboratorDropdown && (
                                <CollaboratorDropdown
                                    status={
                                        props.contributor.status || 'active'
                                    }
                                    collaboratorDropdown={collaboratorDropdown}
                                    onDropDownClick={(val) =>
                                        dropdownClickHandler(val)
                                    }
                                />
                            )}
                        </Box>
                    </Td>
                )}
            </Tr>

            <DangerModal
                heading="Archive User"
                isModal={deleteCollaborator}
                onRemove={() => onDeleteCollaborator()}
                onClose={() => setDeleteCollaborator(false)}
                primaryButtonText="Archive"
                secondaryButtonText="Cancel"
                subHeading={`Archiving this user will remove them from all the existing teams they are a part of. They will no longer be a part of your organization.`}
                title={`Are you sure you want to Archive '${props.contributor.user.name}'`}
            ></DangerModal>
        </>
    )
}

export default React.forwardRef(CollaboratorCard)
