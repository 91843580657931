import type {
    Collections_Order_By,
    GetCollectionsQuery,
    GetProductsQuery,
    GetTodosQuery,
    InputMaybe,
} from 'src/generated/graphql'
import type { ENTITIES } from 'src/helpers/enums'
import type { SetResourceCountInputDataType } from './collections.types'

export const setResourceCountFromData = (
    data: SetResourceCountInputDataType
) => {
    let resourceCount: {
        all: number
        new: number
        inProgress: number
        inReview?: number
        requestedForResubmission?: number
        complete: number
    } = {
        all: data.all.aggregate?.count || 0,
        new: data.new.aggregate?.count || 0,
        inProgress: data.inProgress.aggregate?.count || 0,
        complete: data.completed.aggregate?.count || 0,
    }

    if (data.inReview) {
        resourceCount = {
            ...resourceCount,
            inReview: data.inReview.aggregate?.count || 0,
        }
    }

    if (data.requestedForResubmission) {
        resourceCount = {
            ...resourceCount,
            requestedForResubmission:
                data.requestedForResubmission.aggregate?.count || 0,
        }
    }

    return resourceCount
}

export function getSortObject(
    activeSort: string
): InputMaybe<Collections_Order_By | Collections_Order_By[]> | undefined {
    if (activeSort === 'created_at') {
        return { id: 'desc' } as InputMaybe<Collections_Order_By>
    } else if (activeSort === 'name') {
        return { name: 'asc' } as InputMaybe<Collections_Order_By>
    } else if (activeSort === 'due_date') {
        return { due_date: 'asc' } as InputMaybe<Collections_Order_By>
    } else {
        return undefined
    }
}

export const pageLimit = 10

export const getEntityList = (
    data: GetCollectionsQuery | GetProductsQuery | GetTodosQuery | undefined,
    activeGroup: keyof typeof ENTITIES
) => {
    if (!data) return undefined
    switch (activeGroup) {
        case 'collections':
            if ('collections' in data) {
                return data?.collections
            }
            break
        case 'products':
            if ('products' in data) {
                return data?.products
            }
            break
        case 'todos':
            if ('todos' in data) {
                return data?.todos
            }
            break
        default:
            return undefined
    }
}
