import styled from 'styled-components'
import { space, layout, border } from 'styled-system'
import {
    variantOutlined,
    variantSecondary,
    variantPrimary,
    rippleEffect,
    variantColored,
    variantTertiary,
    variantTertiaryOutline,
    variantTertiaryOutlineTwo,
    variantGhost,
} from './variants'

export const ButtonVariants = styled.button<any>`
    border: none;
    font-family: Rubik;
    border-radius: 4px;
    padding: 12px 18px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    cursor: pointer;
    font-weight: 500;
    min-width: 120px;
    & span {
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 50%;
        z-index: -2;
        opacity: 30%;
        animation: ${rippleEffect} 1.5s ease-in-out;
        animation-fill-mode: forwards;
    }
    ${space}
    ${layout}
    ${border}
    ${variantPrimary}
    ${variantSecondary}
    ${variantOutlined}
    ${variantColored}
    ${variantTertiary}
    ${variantTertiaryOutline}
    ${variantTertiaryOutlineTwo}
    ${variantGhost}
`
