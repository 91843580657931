import { gql } from '@apollo/client'

export const NotificationAggregateAll = gql`
    query NotificationAggregateAll {
        all: notifications_aggregate(
            where: { entity_name: { _is_null: false } }
        ) {
            aggregate {
                count
            }
        }
        inReview: notifications_aggregate(
            where: {
                entity_name: { _is_null: false }
                type_details: { _eq: "inReview" }
            }
        ) {
            aggregate {
                count
            }
        }
        mentionAndAnnotation: notifications_aggregate(
            where: {
                entity_name: { _is_null: false }
                type: { _eq: "annotations" }
                type_details: { _eq: "mentions" }
            }
        ) {
            aggregate {
                count
            }
        }
        others: notifications_aggregate(
            where: { entity_name: { _is_null: false }, type: { _eq: "others" } }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const NotificationAggregateUnread = gql`
    query NotificationAggregateUnread {
        all: notifications_aggregate(where: { opened: { _eq: false } }) {
            aggregate {
                count
            }
        }
        todos: notifications_aggregate(
            where: { opened: { _eq: false }, entity_name: { _eq: "todos" } }
        ) {
            aggregate {
                count
            }
        }
        products: notifications_aggregate(
            where: { opened: { _eq: false }, entity_name: { _eq: "products" } }
        ) {
            aggregate {
                count
            }
        }
        collections: notifications_aggregate(
            where: {
                opened: { _eq: false }
                entity_name: { _eq: "collections" }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const NotificationAggregateComments = gql`
    query NotificationAggregateComments {
        all: notifications_aggregate(where: { type: { _eq: "comments" } }) {
            aggregate {
                count
            }
        }
        mentionAndAnnotation: notifications_aggregate(
            where: {
                _and: {
                    type: { _eq: "comments" }
                    _and: { type: { _eq: "annotations" } }
                }
                type_details: { _eq: "mentions" }
            }
        ) {
            aggregate {
                count
            }
        }
        others: notifications_aggregate(where: { type: { _eq: "others" } }) {
            aggregate {
                count
            }
        }
    }
`

export const NotificationAggregateStatusUpdate = gql`
    query NotificationAggregateStatusUpdate {
        all: notifications_aggregate(where: { type: { _eq: "status" } }) {
            aggregate {
                count
            }
        }
        complete: notifications_aggregate(
            where: {
                type: { _eq: "status" }
                _and: { type_details: { _eq: "complete" } }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: notifications_aggregate(
            where: {
                type: { _eq: "status" }
                type_details: { _eq: "inProgress" }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: notifications_aggregate(
            where: {
                type: { _eq: "status" }
                type_details: { _eq: "inReview" }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const NotificationAggregateOtherUpdate = gql`
    query NotificationAggregateOtherUpdate {
        all: notifications_aggregate(where: { type: { _eq: "others" } }) {
            aggregate {
                count
            }
        }
        todos: notifications_aggregate(
            where: { type: { _eq: "others" }, entity_name: { _eq: "todos" } }
        ) {
            aggregate {
                count
            }
        }
        products: notifications_aggregate(
            where: { type: { _eq: "others" }, entity_name: { _eq: "products" } }
        ) {
            aggregate {
                count
            }
        }
        collections: notifications_aggregate(
            where: {
                type: { _eq: "others" }
                entity_name: { _eq: "collections" }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
