import { Box, Text } from '../utility'

export function InputTitleWithIcon(props: {
    icon: JSX.Element
    title: string
    bg?: string
    childrenDirection?: 'row' | 'column'
    children: JSX.Element
    childrenWidth?: string
    alignItems?: ItemsPosition
}) {
    return (
        <Box
            display="flex"
            flexDirection={
                props.childrenDirection ? props.childrenDirection : 'column'
            }
            gridGap="8px"
            alignItems={
                props.alignItems
                    ? props.alignItems
                    : props.childrenDirection === 'row'
                    ? 'center'
                    : 'start'
            }
        >
            <Box
                display="flex"
                py="4px"
                px="8px"
                gridGap="10px"
                bg={props.bg ? props.bg : '#F8FAFB'}
                justifyContent="center"
                alignItems="center"
                width="max-content"
            >
                {props.icon}
                {props.title && (
                    <Text my="0px" color="#778CA2" fontSize="14px">
                        {props.title}
                    </Text>
                )}
            </Box>
            <Box
                ml={props.childrenDirection === 'row' ? '0px' : '44px'}
                width={props.childrenWidth ? props.childrenWidth : 'inherit'}
            >
                {props.children}
            </Box>
        </Box>
    )
}
