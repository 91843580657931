import VariantIco from 'src/components/svg-icons/variant-ico'
import { Box, Text } from 'src/components/utility'

export function VariantBadge() {
    return (
        <Box
            bg="#EBF390"
            color="black"
            display="flex"
            gridGap="4px"
            borderRadius="4px"
            p="4px"
            alignItems="center"
            width="max-content"
            height="max-content"
        >
            <VariantIco />
            <Text fontSize="12px" my="0px">
                Variant
            </Text>
        </Box>
    )
}
