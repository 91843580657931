import React from 'react'
import { Link } from 'react-router-dom'
import { SuccessTickSm } from 'src/components/svg-icons'
import CircleIco from 'src/components/svg-icons/circle-ico'
import InProgressIco from 'src/components/svg-icons/in-progress-ico'
import InReviewIco from 'src/components/svg-icons/in-review-ico'
import { Box, Text } from 'src/components/utility'
import type { statuses } from 'src/helpers'

function DependentTodoBadge({
    todoId,
    todoName,
    status,
}: {
    todoId: number
    todoName: string
    status: keyof typeof statuses
}) {
    function renderStatusIcon(status: keyof typeof statuses) {
        switch (status) {
            case 'new':
                return <CircleIco color={'gray'} title="To Do" />
            case 'complete':
                return <SuccessTickSm title="Completed" />
            case 'inProgress':
                return <InProgressIco title={'In Progress'} />
            case 'inReview':
                return <InReviewIco title={'In Review'} />
            case 'requestedForResubmission':
                return <InReviewIco title={'Revision Requested'} />

            default:
                return null
        }
    }

    return (
        <Box
            display="grid"
            gridGap="8px"
            gridTemplateColumns={'18px 1fr'}
            width={'100%'}
            overflow={'hidden'}
            alignItems="center"
        >
            {renderStatusIcon(status)}
            <Link
                to={`/todo/${todoId}`}
                style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    overflow: 'hidden',
                }}
            >
                <Text
                    my="0px"
                    color="links"
                    fontSize={'14px'}
                    overflow={'hidden'}
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    title={todoName}
                >
                    {todoName}
                </Text>
            </Link>
        </Box>
    )
}

export default DependentTodoBadge
