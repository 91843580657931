export const CloseIco = ({
    color,
    onClick,
    size,
}: {
    color?: string
    onClick?: (e: any) => void
    size?: number
}) => (
    <svg
        width={size ? size : 16}
        height={size ? size : 16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick ? onClick : undefined}
        cursor="pointer"
    >
        <path
            d="M3.61 2.828a.553.553 0 1 0-.782.783l4.389 4.388-4.389 4.39a.553.553 0 0 0 .783.782l4.388-4.39 4.39 4.39a.553.553 0 1 0 .782-.783L8.78 8l4.39-4.388a.553.553 0 1 0-.783-.783L8 7.217 3.611 2.828Z"
            fill={color ? color : '#778CA2'}
        />
    </svg>
)

export const CloseIcoLg = ({}) => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.41683 4.74309C5.09271 4.41897 4.56721 4.41897 4.24309 4.74309C3.91897 5.06721 3.91897 5.59271 4.24309 5.91683L10.8263 12.5L4.24309 19.0832C3.91897 19.4073 3.91897 19.9328 4.24309 20.2569C4.56721 20.581 5.09271 20.581 5.41683 20.2569L12 13.6737L18.5832 20.2569C18.9073 20.581 19.4328 20.581 19.7569 20.2569C20.081 19.9328 20.081 19.4073 19.7569 19.0832L13.1737 12.5L19.7569 5.91683C20.081 5.59271 20.081 5.06721 19.7569 4.74309C19.4328 4.41897 18.9073 4.41897 18.5832 4.74309L12 11.3263L5.41683 4.74309Z"
                fill="#778CA2"
            />
        </svg>
    )
}
