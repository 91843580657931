
import { GENERATE_AUTH_TOKEN } from 'src/services'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import type { SetStateAction } from 'react'
import { debugLog, setLocalStorage } from 'src/helpers'
import axios from 'axios'

type UserInput = {
	email: string;
	password: string;
	name: string;
};

export async function loginHandler(
	userInputs: UserInput,
	setToken: (token: string) => void,
	// setIsAuthenticated: React.Dispatch<
	// 	SetStateAction<null | 'INVALID' | 'ERROR' | 'AUTHENTICATED' | 'MANY-ATTEMPTS'>
	// >,
	setIsAuthenticated: React.Dispatch<
		SetStateAction<string>
	>,
	setIsLoading: React.Dispatch<SetStateAction<boolean>>,
	isRememberMeChecked: boolean
) {
	setIsLoading(true)
	const auth = getAuth()
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			userInputs.email,
			userInputs.password
		)
		const user = userCredential.user
		const FIREBASE_JWT = await user.getIdToken()
		const { data } = await axios.post(GENERATE_AUTH_TOKEN, {
			token: FIREBASE_JWT
		})
		if (isRememberMeChecked) {
			setLocalStorage('authToken', data.token)
		} else {
			window.sessionStorage.setItem('authToken', data.token)
		}
		setToken(data.token)
		setIsLoading(false)
		return Promise.resolve({ ...data.user, organization_id: data.org_id })
	} catch (error: any) {
		console.log({ error });

		debugLog(error.status)
		setIsLoading(false)
		debugLog(error.code)


		let errorMessage: any;
		switch (error.code) {
			case 'auth/invalid-email':
				errorMessage = 'Email provided is Invalid.';
				break;
			case 'auth/user-disabled':
				errorMessage = 'Your account has been disabled.';
				break;
			case 'auth/user-not-found':
				errorMessage = 'No account found for the provided email address.';
				break;
			case 'auth/wrong-password':
				errorMessage = 'Password is invalid.';
				break;
			case 'auth/too-many-requests':
				errorMessage = 'Too many attempts, Please try again later!';
				break;
			default:
				errorMessage = error.message;
				break;
		}

		if (error?.response?.data?.error?.message) {
			setIsAuthenticated(error?.response?.data?.error?.message)
		} else if (errorMessage) {
			setIsAuthenticated(errorMessage)
		} else {
			setIsAuthenticated("Something went wrong!")
		}

	}
}
