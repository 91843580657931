import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { Box } from 'src/components/utility'

function TodoSliderElement({
    tabs,
    currentTab,
    onTabChange,
}: {
    tabs: Array<string>
    currentTab: string
    onTabChange: (tab: string) => void
}) {
    return (
        <Box>
            <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => onTabChange(newValue)}
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                {tabs.map((tab) => (
                    <Tab label={tab} value={tab} />
                ))}
            </Tabs>
        </Box>
    )
}

export default TodoSliderElement
