import { gql } from '@apollo/client'

export const GetAggregatedTeams = gql`
    query get_teams($limit: Int!, $offset: Int!, $searchStr: String = "%%") {
        teams(
            limit: $limit
            offset: $offset
            order_by: { id: desc }
            where: { name: { _ilike: $searchStr } }
        ) {
            collectionsCompleted: collections_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            collectionsDelayed: collections_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsDelayed: products_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            totalProducts: products_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            todosCompleted: products {
                todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: false }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            todosDelayed: products {
                todos_aggregate(
                    where: {
                        is_delayed: { _eq: true }
                        status: { _eq: complete }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            team_members {
                id
                user {
                    name
                    other_data
                    files(where: { type: { _eq: "profile_pic" } }) {
                        path
                    }
                }
            }
            id
            name
        }
        teams_aggregate(where: { name: { _ilike: $searchStr } }) {
            aggregate {
                count
            }
        }
    }
`

export const GetUserById = gql`
    query GetUserByID($id: bigint!) {
        users_by_pk(id: $id) {
            email
            name
            id
            other_data
            files {
                path
            }
            created_at
            organisation_members {
                role
            }
            assigned_todos {
                status
                product_id
                tags
                duration
                due_date
                name
                product {
                    name
                    collection {
                        name
                    }
                }
            }
        }
    }
`

export const GetAllTeams = gql`
    query GetTeamsByName($name: String) {
        teams(where: { name: { _ilike: $name } }) {
            name
        }
    }
`

export const GetUsers = gql`
    query GetUsers {
        users {
            id
            name
            email
            other_data
        }
    }
`
