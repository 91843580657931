import { useQuery } from '@apollo/client'
import type {
    FetchCollectionByIdQuery,
    FetchCollectionByIdQueryVariables,
} from 'src/generated/graphql'
import { GetCollection } from 'src/services/graphql/entities/collection/get-collection'
import { SortObjects } from './collection.helper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { DueDateType, ManagerType } from '../collections/collections.types'
import { GetCollectionTodos } from 'src/services/graphql/entities/collection/get-collection-todos'
import { GetCollectionProducts } from 'src/services/graphql/entities/collection/get-collection-products'

function useFetchCollection({
    collectionId,
    dueDate,
    managers,
    entitiesGroupBy,
}: {
    collectionId: number
    dueDate?: DueDateType
    managers: Array<ManagerType>
    entitiesGroupBy: 'products' | 'todos'
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { sort: activeSort = 'created_at', tab: currentTab = 'all' } =
        Object.fromEntries(searchParams.entries())

    const {
        data: collectionData,
        loading: isCollectionLoading,
        refetch: refetchCollection,
    } = useQuery<FetchCollectionByIdQuery, FetchCollectionByIdQueryVariables>(
        GetCollection,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                id: Number(collectionId),
            },
            onCompleted: (data) => {
                //If there is no collection, navigate to 404
                if (!data.collections_by_pk) {
                    navigate('/404', { replace: true })
                    return
                }
            },
        }
    )

    const {
        data: collectionProductsData,
        loading: isCollectionProductsLoading,
    } = useQuery(GetCollectionProducts, {
        skip: entitiesGroupBy !== 'products',
        variables: {
            collectionId: Number(collectionId),
            order_by:
                SortObjects[activeSort as 'due_date' | 'name' | 'created_at'],
            due_date: dueDate
                ? Array.isArray(dueDate)
                    ? {
                          _gte: dueDate[0]?.toISOString(),
                          _lt: dueDate[1]?.toISOString(),
                      }
                    : {
                          _eq: new Date(
                              new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                          ).toISOString(),
                      }
                : undefined,
            assignee_ids:
                managers.length > 0
                    ? { _in: managers.map((manager) => manager.id) }
                    : {},
        },
        fetchPolicy: 'cache-and-network',
    })

    const { data: collectionTodosData, loading: isCollectionTodosLoading } =
        useQuery(GetCollectionTodos, {
            skip: entitiesGroupBy !== 'todos',
            variables: {
                collectionId: Number(collectionId),
                order_by:
                    SortObjects[
                        activeSort as 'due_date' | 'name' | 'created_at'
                    ],
                due_date: dueDate
                    ? Array.isArray(dueDate)
                        ? {
                              _gte: dueDate[0]?.toISOString(),
                              _lt: dueDate[1]?.toISOString(),
                          }
                        : {
                              _eq: new Date(
                                  new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                              ).toISOString(),
                          }
                    : undefined,
                assignee_ids:
                    managers.length > 0
                        ? { _in: managers.map((manager) => manager.id) }
                        : {},
            },
            fetchPolicy: 'cache-and-network',
        })

    const collection = collectionData?.collections_by_pk
    const childEntites =
        collectionProductsData?.products || collectionTodosData?.todos
    const isChildEntitiesLoading =
        isCollectionProductsLoading || isCollectionTodosLoading

    return {
        collection,
        isCollectionLoading,
        refetchCollection,
        childEntites,
        isChildEntitiesLoading,
    }
}

export default useFetchCollection
