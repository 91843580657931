import { ButtonVariants } from 'src/components/buttons/button-variants'
import { EmptyStateIcon } from 'src/components/svg-icons'
import { CollectionBadgeSM } from 'src/components/svg-icons/dashboard/collection-badge-sm'
import { Box, Text, Button } from 'src/components/utility'
import { roles } from 'src/helpers'
import CreateCollectionModal from 'src/components-v2/modals/create-collection-modal'
import { useState } from 'react'

export const EmptyStateCollection = ({
    role,
    boxHeight,
}: {
    role: string
    boxHeight: string
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    return (
        <>
            <Box height={boxHeight} px={'16px'}>
                <Box
                    p={'32px'}
                    display="flex"
                    textAlign="center"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                >
                    {role === roles.admin ? (
                        <Box>
                            <CollectionBadgeSM />
                            <Text
                                fontSize={'14px'}
                                mb="0px"
                                color={'textSecondaryDarkBlue'}
                            >
                                {' '}
                                No Collections yet!
                                <Button
                                    height="20px"
                                    bg="transparent"
                                    border="none"
                                    fontSize="14px"
                                    color="links"
                                    position="relative"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Create
                                </Button>
                            </Text>
                        </Box>
                    ) : (
                        <Box>
                            <EmptyStateIcon />
                            <Text fontSize="14px">No collections?</Text>
                            <Text fontSize="12px" color="textSecondaryDarkBlue">
                                {' '}
                                Try to assign more tasks to your teammates{' '}
                            </Text>
                            <ButtonVariants
                                width="150px"
                                fontSize="14px"
                                variant="outlined"
                                onClick={() => setIsModalOpen(true)}
                            >
                                Create Collection
                            </ButtonVariants>
                        </Box>
                    )}
                </Box>
            </Box>

            {isModalOpen && (
                <CreateCollectionModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </>
    )
}
