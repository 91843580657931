import { gql } from '@apollo/client'

export const DeleteProduct = gql`
    mutation DeleteProduct($id: bigint!, $deleted_at: timestamptz) {
        update_products_by_pk(
            _set: { deleted_at: $deleted_at }
            pk_columns: { id: $id }
        ) {
            approver_due_date
            approver_id
            id
        }
    }
`
