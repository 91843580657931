/* eslint-disable @typescript-eslint/no-use-before-define */
import type { SetStateAction } from 'react'
import { useEffect } from 'react'
import React, { useState, useRef } from 'react'
import { Box, Input, Text, Button } from 'src/components/utility'
import { Rules } from './rules'
import {
    PasswordHidden,
    PasswordVisible,
    SuccessTick,
} from 'src/components/svg-icons'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { Authentication, InlineError, Loader } from 'src/components'
import { signupHandler, onChangeHandler } from './functions'
import { getAuth } from 'firebase/auth'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Field } from './field'
import axios from 'axios'
import { GET_USER_INVITATION } from 'src/services'
const Signup = () => {
    const [isSignedUp, setIsSignedUp] = useState<boolean>(false)
    return isSignedUp ? (
        <SuccessfulSignup />
    ) : (
        <SignupForm isSignedUp={isSignedUp} setIsSignedUp={setIsSignedUp} />
    )
}
export default Signup

function SuccessfulSignup() {
    // const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    return (
        <Authentication>
            <Box display="flex" flexDirection="column" alignItems="center">
                <SuccessTick />
                <Text
                    fontFamily="Rubik"
                    fontSize="24px"
                    mt="4px"
                    mb="16px"
                    color="approved"
                >
                    Sign up Successful
                </Text>
                <Text
                    fontFamily="Rubik"
                    color="primary"
                    fontSize="14px"
                    mt="0px"
                    mb="0px"
                >
                    Your account has been created with Virtu.
                </Text>
                <Text
                    fontFamily="Rubik"
                    color="primary"
                    fontSize="14px"
                    mt="8px"
                    mb="32px"
                >
                    Please click on Login to get into your account.
                </Text>
                <ColoredButton
                    variant="primary"
                    onClick={() => navigate('/login')}
                    height="max-content"
                >
                    Login
                </ColoredButton>
            </Box>
        </Authentication>
    )
}

function SignupForm({
    setIsSignedUp,
    isSignedUp,
}: {
    setIsSignedUp: React.Dispatch<SetStateAction<boolean>>
    isSignedUp: boolean
}) {
    const { search } = useLocation()
    const navigate = useNavigate()
    const token = new URLSearchParams(search).get('token')

    const auth = getAuth()

    const [userInputs, setUserInputs] = useState({
        email: '',
        password: '',
        name: '',
    })

    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>()
    const [isValidPassword, setIsValidPassword] = useState<boolean>(false)
    const [isRules, setIsRules] = useState<boolean>(false)
    const [error, setError] = useState<{
        isError: boolean
        errorMessage: string
    }>({ isError: false, errorMessage: '' })
    // const [mouseEnterTimer, setMouseEnterTimer] =
    //     useState<ReturnType<typeof setTimeout>>()
    const [isLoading, setIsLoading] = useState(false)

    const [errorStates, setErrorStates] = useState({
        email: false,
        password: false,
        name: false,
    })

    function handleSignup(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        signupHandler(
            setErrorStates,
            setIsSignedUp,
            setError,
            setIsLoading,
            userInputs,
            isValidPassword
        )
    }

    const passwordRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (!token) return navigate('/login')
        ;(async function () {
            try {
                const {
                    data: { user },
                } = await axios.get(GET_USER_INVITATION + token)
                setUserInputs((userInputs) => {
                    return { ...userInputs, email: user.email }
                })
                setError({ errorMessage: '', isError: false })
            } catch (e) {
                setError({
                    errorMessage: 'Invalid invitation/Invitation expired',
                    isError: true,
                })
            }
        })()
    }, [])
    return (
        <Authentication>
            <form onSubmit={handleSignup}>
                <Box
                    minWidth="400px"
                    maxWidth={'400px'}
                    display="flex"
                    gridRowGap="16px"
                    flexDirection="column"
                >
                    <Text
                        fontFamily="Rubik"
                        fontWeight={500}
                        color="primary"
                        fontSize="32px"
                        textAlign="left"
                    >
                        Sign up to Virtu!
                    </Text>
                    <Field
                        errorStates={errorStates}
                        setUserInputs={setUserInputs}
                        setErrorStates={setErrorStates}
                        disabled={true}
                        type="email"
                        label="Email Address"
                        placeholder="Enter your email"
                        value={userInputs.email}
                    />
                    <Field
                        errorStates={errorStates}
                        setUserInputs={setUserInputs}
                        setErrorStates={setErrorStates}
                        type="name"
                        label="Full Name"
                        placeholder="Enter your name"
                    />

                    <Box
                        display="flex"
                        flexDirection="column"
                        gridRowGap="12px"
                        fontSize="14px"
                        position="relative"
                        width="100%"
                    >
                        <Text
                            mt="0px"
                            mb="0px"
                            fontFamily="Rubik"
                            color={errorStates.password ? 'delay' : 'primary'}
                        >
                            Set up Password
                        </Text>
                        <Box
                            bg="white"
                            border="solid"
                            borderWidth={1}
                            borderColor={
                                errorStates.password ? 'delay' : 'gray350'
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            pr="16px"
                            // onMouseEnter={() =>
                            //     showRuleModal(setIsRules, setMouseEnterTimer)
                            // }
                            // onMouseLeave={() => {
                            //     if (mouseEnterTimer) {
                            //         setIsRules(false);
                            //         clearTimeout(mouseEnterTimer);
                            //     }
                            // }}
                        >
                            <Input
                                autoComplete="false"
                                placeholder="Enter your password"
                                bg="white"
                                borderRadius="4px"
                                px="16px"
                                py="16px"
                                fontSize="14px"
                                className="placeholder-darkgray password"
                                color="primary"
                                border="none"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    onChangeHandler(
                                        setUserInputs,
                                        setErrorStates,
                                        'password',
                                        e.target.value
                                    )
                                }}
                                type={isPasswordVisible ? 'text' : 'password'}
                                width="100%"
                                ref={passwordRef}
                                onFocus={() => setIsRules(true)}
                                onBlur={() => setIsRules(false)}
                            />
                            <Button
                                bg="transparent"
                                border="none"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                type="button"
                                onClick={() =>
                                    setIsPasswordVisible(!isPasswordVisible)
                                }
                            >
                                {!isPasswordVisible ? (
                                    <PasswordHidden />
                                ) : (
                                    <PasswordVisible />
                                )}
                            </Button>
                        </Box>
                        <Box width="100%">
                            {errorStates.password && (
                                <InlineError
                                    width="100%"
                                    text="Use atleast 8 characters. Include uppercase, lowercase characters along with a number and a special character."
                                />
                            )}
                        </Box>
                        <Rules
                            isRuleVisible={isRules}
                            password={userInputs.password}
                            isValidPassword={setIsValidPassword}
                        />
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        mt="24px"
                    >
                        <ColoredButton
                            variant="primary"
                            disabled={
                                !(
                                    userInputs.email.length > 0 &&
                                    userInputs.name.length > 0 &&
                                    userInputs.password.length > 0
                                )
                            }
                            type="submit"
                            onClick={() => {}}
                        >
                            <Box
                                display="flex"
                                gridColumnGap="8px"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {isLoading && <Loader />} Sign Up
                            </Box>
                        </ColoredButton>
                        {error.isError && (
                            <Box mt="8px">
                                <InlineError
                                    width="400px"
                                    text={error.errorMessage}
                                ></InlineError>
                            </Box>
                        )}
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gridColumnGap="4px"
                        >
                            <Text
                                textAlign="center"
                                fontFamily="Rubik"
                                color="textSecondary"
                            >
                                Already have an account?
                            </Text>
                            <Link className="links" to="/login">
                                Login
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Authentication>
    )
}
