import React, { useState } from 'react'
import { Box, Button, Input, Text } from 'src/components/utility'
import { getFormattedDate } from 'src/helpers'
import { CollectionBadge } from 'src/components-v2/badges/collection-badge'
import CollectionComments from './collection-comments'
import CollectionActivities from './collection-activities'
import { Dots, DropDownArrow, TeamIco } from 'src/components/svg-icons'
import { CollectionMenuDropdown } from 'src/components/collections/collection-menu-dropdown'
import AdditionalInfoDropdown from 'src/components-v2/dropdowns/additional-info-dropdown/additional-info-dropdown'
import CollectionStatus from './collection-status'
import { useNavigate } from 'react-router-dom'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'
import TeamBadge from 'src/components-v2/team-badge/team-badge'
import { AddCollaboratorModal } from '../organisations-analytics/modal/add-collaborator/add-collaborator'
import AssignUser from 'src/components-v2/dropdown-badges/assign-user/assign-user'
import type { FetchCollectionByIdQuery } from 'src/generated/graphql'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import CalendarDropdown from 'src/components-v2/cards/dropdowns/calendar'
import useStore from 'src/store/hooks/useStore'

function CollectionHeader({
    collection,
    refetchCollection,
}: {
    collection: NonNullable<FetchCollectionByIdQuery['collections_by_pk']>
    refetchCollection: () => void
}) {
    const [componentVisibility, setComponentVisibility] = useState({
        additionalInfo: false,
        collectionMenu: false,
        teamDropdown: false,
    })

    const [name, setName] = useState(collection.name)
    const navigate = useNavigate()

    const {
        debouncedUpdateCollection,
        updateCollection,
        updateCollectionDueDate,
        updateCollectionTeamAndItsProductsTeamHandler,
    } = useUpdateCollection()

    const { updateGlobalSearchResults } = useStore()

    function updatedNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setName(e.target.value)
        if (e.target.value.trim().length > 0) {
            debouncedUpdateCollection(
                collection.id,
                {
                    name: e.target.value.trimStart(),
                },
                ['GetCollections'],
                refetchCollection
            )
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            pb="8px"
            width="100%"
            gridGap="8px"
            position="relative"
        >
            <Box display="flex" gridGap="16px" alignItems="center">
                <Box p="12px" borderRight="1px solid" borderBottom="1px solid">
                    <Button
                        color="links"
                        fontSize="12px"
                        bg="transparent"
                        border="none"
                        px="0px"
                        fontFamily="Rubik"
                        my="0px"
                        height="100%"
                        onClick={() => navigate(-1)}
                    >
                        &lt; Back
                    </Button>
                </Box>
                <Text my="0px" fontSize={'20px'}>
                    Collections
                </Text>
            </Box>
            <Box
                display="flex"
                gridGap="16px"
                width="100%"
                justifyContent="space-between"
                px="24px"
            >
                <Box
                    display="flex"
                    width="100%"
                    gridGap="16px"
                    alignItems="center"
                >
                    <CollectionBadge />
                    <Input
                        value={name}
                        title={name}
                        placeholder="Enter collection name"
                        bg="transparent"
                        width="100%"
                        onChange={(e) => {
                            updatedNameHandler(e)
                        }}
                        fontSize="16px"
                        border="none"
                        py={'4px'}
                        px="4px"
                        className="border-on-hover"
                    />
                </Box>
                <Box display="flex" alignItems="center" gridGap="8px">
                    <CollectionComments
                        collectionId={collection.id}
                        collectionName={collection.name}
                        collectionTeam={collection.team}
                    />
                    <CollectionActivities collection={collection} />
                    <Box position="relative">
                        <Button
                            onClick={() =>
                                setComponentVisibility({
                                    ...componentVisibility,
                                    collectionMenu:
                                        !componentVisibility.collectionMenu,
                                })
                            }
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="links"
                            position="relative"
                            width="max-content"
                            id="collection-menu-button"
                        >
                            <Dots active={componentVisibility.collectionMenu} />
                        </Button>
                        {componentVisibility.collectionMenu && (
                            <CollectionMenuDropdown
                                active={componentVisibility.collectionMenu}
                                collectionId={collection.id}
                                collectionName={collection.name}
                                isDetailedPage={true}
                                setIsDropdownActive={(value) =>
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        collectionMenu: value,
                                    })
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                gridGap="32px"
                alignItems="center"
                px="24px"
                position="relative"
            >
                {collection.team?.id ? (
                    <Box
                        display="flex"
                        gridGap="12px"
                        alignItems="center"
                        onClick={() => {
                            setComponentVisibility({
                                ...componentVisibility,
                                teamDropdown: true,
                            })
                        }}
                        className="cursor-pointer"
                    >
                        <Box
                            bg="#F8FAFB"
                            py="2px"
                            px="4px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="4px"
                            width={'32px'}
                            height={'32px'}
                        >
                            <TeamIco />
                        </Box>
                        <TeamBadge
                            teamName={collection.team.name}
                            teamMembers={collection.team.team_members}
                            onRemove={() => {
                                updateCollectionTeamAndItsProductsTeamHandler({
                                    teamId: null,
                                    collectionId: collection.id,
                                    onCompleted: () => {
                                        refetchCollection()
                                    },
                                })
                            }}
                        />
                    </Box>
                ) : (
                    <Box display="flex" gridGap="12px" alignItems="center">
                        <TeamIco />
                        <Button
                            bg="transparent"
                            className="input-border"
                            py={'4px'}
                            fontStyle="Rubik"
                            fontSize="14px"
                            color="textSecondary"
                            onClick={() => {
                                setComponentVisibility({
                                    ...componentVisibility,
                                    teamDropdown: true,
                                })
                            }}
                        >
                            Add Team
                        </Button>
                    </Box>
                )}
                {componentVisibility.teamDropdown && (
                    <AddCollaboratorModal
                        onRoleUpdate={() => {
                            refetchCollection()
                        }}
                        teamMembers={[]}
                        onCollaboratorsAdded={() => {}}
                        teamId={String(collection.team?.id || 0)}
                        onTeamAdded={(id) => {
                            updateCollectionTeamAndItsProductsTeamHandler({
                                teamId: id,
                                collectionId: collection.id,
                                onCompleted: () => {
                                    refetchCollection()
                                },
                            })
                            setComponentVisibility({
                                ...componentVisibility,
                                teamDropdown: false,
                            })
                        }}
                        isModal={componentVisibility.teamDropdown}
                        onClose={(value) => {
                            setComponentVisibility({
                                ...componentVisibility,
                                teamDropdown: value,
                            })
                        }}
                    />
                )}
                <AssignUser
                    assignee={collection.manager}
                    onRemove={() => {
                        updateCollection(
                            collection.id,
                            {
                                manager_id: null,
                            },
                            ['GetCollections'],
                            refetchCollection
                        )
                    }}
                    onAssign={(user) => {
                        updateCollection(
                            collection.id,
                            {
                                manager_id: Number(user.id),
                            },
                            ['GetCollections'],
                            refetchCollection
                        )
                    }}
                    role="Manager"
                />
                <CalendarDropdown
                    date={
                        collection?.due_date
                            ? new Date(collection.due_date)
                            : null
                    }
                    left={'0px'}
                    setDate={(date) => {
                        updateCollectionDueDate({
                            id: collection.id,
                            dueDate: date.toISOString(),
                            onComplete: () => {
                                updateGlobalSearchResults({
                                    type: 'collections',
                                    updatedKey: 'due_date',
                                    value: date.toISOString(),
                                    id: String(collection.id),
                                })
                            },
                        })
                    }}
                    isDelayed={isEntityDueDateDelayed(
                        collection.due_date,
                        collection.status
                    )}
                />
                <CollectionStatus
                    collectionId={collection.id}
                    collectionStatus={collection.status}
                    refetchCollection={refetchCollection}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="end"
                gridGap="8px"
                width="100%"
                borderBottom="1px solid #E8ECEF"
                px="24px"
                mb="16px"
            >
                <Box
                    width="35px"
                    height="16px"
                    borderRadius="50px"
                    bg="#E8ECEF"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid #C2CFE0"
                    // @ts-expect-error
                    mb="-8px"
                >
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setComponentVisibility({
                                additionalInfo:
                                    !componentVisibility.additionalInfo,
                                collectionMenu: false,
                                teamDropdown: false,
                            })
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                        mt="4px"
                        id="additional-info-dropdown-button"
                    >
                        <DropDownArrow
                            rotate={componentVisibility.additionalInfo}
                            color="#778CA2"
                        />
                    </Button>
                    {componentVisibility.additionalInfo && (
                        <AdditionalInfoDropdown
                            creatorName={collection.creator.name || ''}
                            createdDate={getFormattedDate(
                                collection.created_at
                            )}
                            updatedDate={getFormattedDate(
                                collection.updated_at
                            )}
                            description={collection.description || ''}
                            onClose={() =>
                                setComponentVisibility({
                                    additionalInfo: false,
                                    collectionMenu: false,
                                    teamDropdown: false,
                                })
                            }
                            onDescriptionUpdate={(description) => {
                                debouncedUpdateCollection(
                                    collection.id,
                                    { description },
                                    ['GetCollections'],
                                    refetchCollection
                                )
                            }}
                            tags={collection.tags}
                            onTagsUpdate={(tags) => {
                                updateCollection(
                                    collection.id,
                                    { tags },
                                    ['GetCollections'],
                                    refetchCollection
                                )
                            }}
                            brand={collection.brand || ''}
                            company={collection.company || ''}
                            onUpdateBrand={(brand) => {
                                debouncedUpdateCollection(
                                    collection.id,
                                    { brand: brand },
                                    ['GetCollections'],
                                    refetchCollection
                                )
                            }}
                            onUpdateCompany={(company) => {
                                debouncedUpdateCollection(
                                    collection.id,
                                    { company: company },
                                    ['GetCollections'],
                                    refetchCollection
                                )
                            }}
                            isCollection={true}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default CollectionHeader
