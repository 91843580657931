import type { SetStateAction } from 'react'

/**
 * @param e
 * @param {Ripple} setRipples
 * @description Takes `SyntheticEvent` as parameter and a setter function which will have array of `Ripples`
 */

export function handleRipple (
	e: any,
	setRipples: React.Dispatch<SetStateAction<Ripple[]>>
) {
	const xPos = e.clientX - e.target.offsetLeft
	const yPos = e.clientY - e.target.offsetTop
	const left = xPos + 'px'
	const right = yPos + 'px'
	setRipples((ripples: Ripple[]) => [
		...ripples,
		{ left: left, right: right }
	])
	setTimeout(() => {
		setRipples([])
	}, 1000)
}
