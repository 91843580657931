import { Box, Input, Text } from 'src/components/utility'
import { Dropdown } from 'src/components'
import { useState } from 'react'
import { SearchIco } from 'src/components/svg-icons'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Checkbox } from '@mui/material'
import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import { getImageUrl } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { GetOrgMembers } from 'src/services/graphql/query/get-users'

export const DropDownActions = ({
    active,
    managers,
    setManagers,
    setIsDropdownActive,
}: {
    active: Boolean
    managers: any[]
    setManagers: (data: any[]) => void
    setIsDropdownActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { data: usersData, loading: isUsersLoading } = useQuery(GetOrgMembers)

    const [searchUser, setSearchUser] = useState('')

    const wrapperRef = useClickedOutside(() => setIsDropdownActive(false))

    const searchUserFilter = (member: any) =>
        member.user.name.toLowerCase().includes(searchUser.toLowerCase())

    const users = usersData?.organisation_members

    const AddRemoveManager = ({
        event,
        id,
        name,
        action,
        thumbnail,
    }: {
        event: React.ChangeEvent<any>
        id: number
        name: string
        thumbnail: string | null
        action?: 'ADD' | 'REMOVE'
    }) => {
        if (event.target.checked || action === 'ADD') {
            setManagers([...managers, { id, name }])
            // addRecentFilters([...managers, { id, name, thumbnail }])
        } else {
            setManagers(managers.filter((manager) => manager.id !== id))
            // addRecentFilters(managers.filter((manager) => manager.id !== id))
        }
    }

    // const recentManagers = recentFiltersData?.recent_filters?.reduce(
    //     (acc: Array<string>, filter: any) => {
    //         if (
    //             filter.entity_filters?.collection_manager &&
    //             entityName === 'collections'
    //         ) {
    //             return (acc = [
    //                 ...acc,
    //                 ...filter.entity_filters.collection_manager,
    //             ])
    //         } else if (
    //             filter.entity_filters?.product_approver &&
    //             entityName === 'products'
    //         ) {
    //             return (acc = [
    //                 ...acc,
    //                 ...filter.entity_filters.product_approver,
    //             ])
    //         } else if (
    //             filter.entity_filters?.assignee &&
    //             entityName === 'todos'
    //         ) {
    //             return (acc = [...acc, ...filter.entity_filters.assignee])
    //         } else {
    //             return acc
    //         }
    //     },
    //     []
    // )

    // function addRecentFilters(managers: Array<any>) {
    //     let payload: any = {}
    //     if (entityName === 'collections') {
    //         payload.collection_manager = managers
    //     } else if (entityName === 'products') {
    //         payload.product_approver = managers
    //     } else {
    //         payload.assignee = managers
    //     }
    //     AddRecentFiltersMutation({
    //         variables: {
    //             entity_name: entityName,
    //             data: payload,
    //         },
    //     })
    // }

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            right="0"
            top="36px"
            left="unset"
            width="376px"
        >
            <Box
                display="flex"
                flexDirection="column"
                gridGap="8px"
                px="16px"
                width="100%"
                maxHeight="454px"
                ref={wrapperRef}
            >
                <Text fontSize="14px" fontWeight={400} my="0px">
                    Recent Filters
                </Text>
                {/* <Box display="flex" flexWrap="wrap" gridGap="4px">
                    {recentManagers &&
                        getDistinctObjects(recentManagers)?.map(
                            (manager: {
                                id: number
                                name: string
                                thumbnail: string | null
                            }) => {
                                return (
                                    <Box
                                        display="flex"
                                        gridGap="4px"
                                        alignItems="center"
                                        bg="#F5F6A9"
                                        py="4px"
                                        px="8px"
                                        key={manager.id}
                                        style={{ borderRadius: '70px' }}
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                            AddRemoveManager({
                                                event: e,
                                                id: Number(manager.id),
                                                name: manager.name,
                                                thumbnail: manager.thumbnail,
                                                action: 'ADD',
                                            })
                                        }}
                                    >
                                        <ProfileImg
                                            size="20px"
                                            name={manager.name}
                                            src={
                                                manager.thumbnail
                                                    ? getImageUrl(
                                                          manager.thumbnail
                                                      )
                                                    : ''
                                            }
                                        />
                                        <Text my="0px" fontSize="14px">
                                            {manager.name}
                                        </Text>
                                    </Box>
                                )
                            }
                        )}
                </Box> */}
                <Box
                    display="grid"
                    gridTemplateColumns="24px 1fr"
                    alignItems="center"
                    gridGap="4px"
                    border="1px solid #E8ECEF"
                    borderRadius="4px"
                    p="8px"
                >
                    <SearchIco />
                    <Input
                        bg="#ffffff"
                        placeholder="Search Users"
                        border="none"
                        onChange={(e) => setSearchUser(e.target.value)}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gridGap="8px"
                    overflowY="auto"
                    className="scrollbar_none"
                    maxHeight="100%"
                >
                    {!isUsersLoading ? (
                        users &&
                        users.length > 0 &&
                        users.filter(searchUserFilter).map((member: any) => {
                            return (
                                <Box
                                    key={member.user.id}
                                    display="flex"
                                    gridGap="4px"
                                    alignItems="center"
                                    py="4px"
                                    borderBottom="1px solid #DDDDDD"
                                >
                                    <Checkbox
                                        checked={managers!.find(
                                            (manager) =>
                                                manager.id === member.user.id
                                        )}
                                        onChange={(e) => {
                                            AddRemoveManager({
                                                event: e,
                                                id: member.user.id,
                                                name: member.user.name,
                                                thumbnail:
                                                    member.user.other_data
                                                        .thumbnail || null,
                                            })
                                        }}
                                    />
                                    <ProfileImg
                                        size="20px"
                                        name={member.user.name}
                                        src={
                                            member.user.other_data.thumbnail
                                                ? getImageUrl(
                                                      member.user.other_data
                                                          .thumbnail
                                                  )
                                                : ''
                                        }
                                    />
                                    <Text my="0px" fontSize="16px">
                                        {member.user.name}
                                    </Text>
                                    <Text
                                        my="0px"
                                        fontSize="14px"
                                        color="textSecondary"
                                    >
                                        {member?.role}
                                    </Text>
                                </Box>
                            )
                        })
                    ) : (
                        <Skeleton count={4} />
                    )}
                </Box>
            </Box>
        </Dropdown>
    )
}
