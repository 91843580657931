import React from 'react'

function TemplateFolderIco() {
    return (
        <svg
            width="48"
            height="43"
            viewBox="0 0 48 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.35"
                d="M40.8 42.5H7.2C3.2232 42.5 0 39.3663 0 35.5V7.5H40.8C44.7768 7.5 48 10.6337 48 14.5V35.5C48 39.3663 44.7768 42.5 40.8 42.5Z"
                fill="#FFB800"
            />
            <rect
                x="15"
                y="15.5938"
                width="18"
                height="18.8196"
                rx="9"
                fill="#022143"
            />
            <rect
                x="15"
                y="15.5938"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <rect
                x="15"
                y="15.5938"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <rect
                x="15"
                y="15.5938"
                width="18"
                height="18.8196"
                rx="9"
                fill="black"
                fill-opacity="0.2"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.3389 24.8981L27.7922 22.3437L23.8993 20.178L19.5568 22.6595V27.6052L23.3389 29.7248V24.8981ZM19.187 22.2295L23.714 19.6427C23.8268 19.5782 23.9651 19.5774 24.0786 19.6406L28.35 22.0169C28.6024 22.1573 28.6048 22.5196 28.3542 22.6633L23.8957 25.2206V30.0416C23.8957 30.3252 23.5904 30.5041 23.343 30.3654L19.1897 28.0377C19.0726 27.9721 19 27.8482 19 27.7139V22.5518C19 22.4186 19.0714 22.2956 19.187 22.2295Z"
                fill="#EAFC40"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.3706 23.4761C21.4497 23.3443 21.6208 23.3015 21.7526 23.3806L23.9651 24.7081C24.0969 24.7872 24.1397 24.9582 24.0606 25.0901C23.9815 25.2219 23.8105 25.2647 23.6786 25.1856L21.4661 23.8581C21.3343 23.779 21.2915 23.608 21.3706 23.4761Z"
                fill="#EAFC40"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1629 25.8528V29.5156L27.272 28.3796C27.9934 27.9911 28.4432 27.238 28.4432 26.4187V23.9822L25.1629 25.8528ZM28.4449 23.3402L24.6061 25.5293V29.8263C24.6061 30.1072 24.906 30.2864 25.1533 30.1532L27.5361 28.8699C28.4377 28.3842 29 27.4428 29 26.4187V23.6627C29 23.3778 28.6924 23.1991 28.4449 23.3402Z"
                fill="#EAFC40"
            />
            <path
                d="M21 8.5H0V6.16667C0 3.58833 2.08833 1.5 4.66667 1.5H15.302C17.311 1.5 19.0937 2.78567 19.7283 4.692L21 8.5Z"
                fill="#FFB800"
            />
        </svg>
    )
}

export default TemplateFolderIco
