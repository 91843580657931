import { gql } from "@apollo/client";

export const GetUserByEmail = gql`
query getUser($email: String) {
  users(where: {email: {_ilike: $email}}) {
    email
    created_at
    other_data
    name
    id
  }
  invitations(where: {email: {_ilike: $email}}) {
    id
    email
    role
  }
  organisation_members(where: {user: {email: {_ilike: $email}}}) {
    id
    deleted_at
    organisation_id
    role
  }
}
`
