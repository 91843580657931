import { Html } from '@react-three/drei'
import * as THREE from 'three'
import { Box } from '../utility'
import { CommentAnnotation } from '../svg-icons/3d-renderer/comment-annotation'
import React, { useState } from 'react'

type TAnnotation = {
    coordinates: [number, number, number]
    onClick: () => void
}

function AnnotationNew({ coordinates, onClick }: TAnnotation) {
    const [isHidden, setIsHidder] = useState(false)

    const coordinateVector = new THREE.Vector3(...coordinates)

    return (
        <Html
            position={coordinateVector}
            zIndexRange={[1, 1]}
            // occlude
            // onOcclude={(visible) => {
            //     setIsHidder(visible)
            //     return null
            // }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg={isHidden ? 'transparent' : 'white'}
                p="4px"
                border="solid"
                borderWidth={1}
                borderRadius="4px"
                borderColor="#B8B8B8"
                onClick={onClick}
            >
                <CommentAnnotation />
            </Box>
        </Html>
    )
}

export default React.memo(AnnotationNew)
