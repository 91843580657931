import { Box, Text } from 'src/components/utility'
import { useNavigate, useLocation } from 'react-router-dom'
import { ColoredButton } from 'src/components/buttons/colored-button'
import { logoutUser } from 'src/helpers'

export const PasswordResetLink = ({ email }: { email: string }) => {
    const navigate = useNavigate()
    const { state }: { state: any } = useLocation()
    return (
        <Box
            display="flex"
            justifyContent="center"
            width="100%"
            height="100%"
            bg="white"
            py="72px"
        >
            <Box
                bg="white"
                justifyContent="center"
                display="flex"
                flexDirection="column"
                width="550px"
                alignItems="center"
            >
                <Text
                    className="text-wrap"
                    color="primary"
                    fontSize="16px"
                    fontFamily="Rubik"
                    textAlign="center"
                    mt="0px"
                    mb="0px"
                >
                    We have sent a password reset link to
                    <span className="link-color"> {email}</span>. Check your
                    email and click on the link to reset your password
                </Text>

                <Box
                    width="100%"
                    display="flex"
                    mt="40px"
                    justifyContent="center"
                >
                    <ColoredButton
                        variant="primary"
                        onClick={() => {
                            logoutUser()
                            navigate('/login')
                        }}
                        width="360px"
                    >
                        Back to Login
                    </ColoredButton>
                </Box>
            </Box>
        </Box>
    )
}
