import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Text } from 'src/components/utility'
import type { TSearchedCollaborators } from '../@types/search'
import useStore from 'src/store/hooks/useStore'
import { getImageUrl, getSentenceCase } from 'src/helpers'
import { useRecentSearch } from '../hooks/useRecentSearch'
import { useNavigate } from 'react-router-dom'

type TUserVariantProps = {
    entity: TSearchedCollaborators
    onCardClick?: () => void
}

export function UserVariant(props: TUserVariantProps) {
    const { globalSearchFilters, setGlobalSearchActive } = useStore()
    const { entity, onCardClick } = props
    const { addRecentSearch } = useRecentSearch()
    const navigate = useNavigate()
    return (
        <Box
            color="textSecondaryDarkBlue"
            display="flex"
            className="cursor-pointer"
            flexDirection="column"
            gridRowGap="8px"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            onClick={() => {
                setGlobalSearchActive({ isGlobalSearchActive: false })
                onCardClick && onCardClick()
                addRecentSearch({
                    entity_name: 'users',
                    entity_id: entity.id,
                    search_query: globalSearchFilters.query,
                })
                navigate('/user/' + entity.id)
            }}
            py="8px"
            px="4px"
        >
            <Box display="flex" gridColumnGap="8px" alignItems="start">
                <ProfileImg
                    fontSize="12px"
                    size="20px"
                    src={getImageUrl(entity.other_data?.profile_pic)}
                    name={entity.name}
                />
                <Box>
                    <Highlighted
                        search={globalSearchFilters.query}
                        text={entity?.name}
                        unHighlighted="textSecondaryDarkBlue"
                    />
                    <Text my="0px" mt="8px" fontSize="12px">
                        {getSentenceCase(entity?.role || '', 'NAME')}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
