export const DeleteIco = ({ color }: { color?: string }) => {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0C6.617 0 5.465 1.049 5.272 2.4H2.574a.583.583 0 0 0-.19 0H1.1a.584.584 0 0 0-.424.173.6.6 0 0 0-.13.659.6.6 0 0 0 .325.325c.073.03.15.044.229.043h.843l.993 10.412C3.043 15.134 3.983 16 5.094 16h5.81c1.112 0 2.053-.866 2.16-1.988L14.056 3.6h.843a.585.585 0 0 0 .554-.368.608.608 0 0 0-.13-.66.59.59 0 0 0-.424-.172h-1.284a.583.583 0 0 0-.19 0H10.73C10.534 1.049 9.383 0 8 0Zm0 1.2c.74 0 1.349.507 1.523 1.2H6.478C6.65 1.707 7.258 1.2 8 1.2ZM3.13 3.6h9.739l-.983 10.296a.984.984 0 0 1-.981.904h-5.81a.985.985 0 0 1-.982-.904L3.131 3.6Zm3.48 1.991a.588.588 0 0 0-.415.182.605.605 0 0 0-.166.427v6a.608.608 0 0 0 .363.562.586.586 0 0 0 .777-.33.608.608 0 0 0 .042-.232v-6a.608.608 0 0 0-.173-.433.591.591 0 0 0-.427-.176Zm2.76 0a.588.588 0 0 0-.414.182.605.605 0 0 0-.167.427v6a.608.608 0 0 0 .17.43.592.592 0 0 0 .421.178.586.586 0 0 0 .549-.376.608.608 0 0 0 .042-.232v-6a.608.608 0 0 0-.173-.433.59.59 0 0 0-.427-.176Z"
                fill={color || '#778CA2'}
            />
        </svg>
    )
}
