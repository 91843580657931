import { useMutation } from '@apollo/client'
import * as React from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'src/components'
import { TeamIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'
import { CreateTeam } from 'src/services'

export interface ICreateTeamModalProps {
    isModalActive: boolean
    setIsModalActive: (isActive: boolean) => void
    refetch: (q: string) => void
}

export default function CreateTeamModal(props: ICreateTeamModalProps) {
    const [teamName, setTeamName] = React.useState('')
    const [createTeamMutation] = useMutation(CreateTeam)

    function handleCreateTeam() {
        createTeamMutation({
            variables: {
                name: teamName,
            },
            onCompleted: () => {
                toast.success('Team created successfully')
                props.setIsModalActive(false)
                props.refetch('')
                setTeamName('')
            },
        })
    }

    return (
        <Modal
            isModal={props.isModalActive}
            onClick={handleCreateTeam}
            primaryButtonTitle="Create Team"
            secondaryButtonTitle="Cancel"
            title="New Team"
            onClose={() => {
                props.setIsModalActive(false)
                setTeamName('')
            }}
            disablePrimaryButton={teamName.length <= 0}
            bg="white"
        >
            <Box px="16px" gridColumnGap="12px" py="24px" display="flex">
                <Box
                    p="8px"
                    display="flex"
                    alignItems="center"
                    bg="backgroundColor"
                    borderRadius="4px"
                >
                    <TeamIco color="#778CA2" />
                </Box>
                <Input
                    onChange={(e) => setTeamName(e.target.value)}
                    border="none"
                    fontSize="16px"
                    placeholder="Untitled Team"
                    bg="white"
                    value={teamName}
                />
            </Box>
        </Modal>
    )
}
