import React from 'react'
import { Box, Input, Text } from '../utility'
import styled from 'styled-components'

const StyledInput = styled(Input)`
    &:disabled::placeholder {
        color: #817e7e80 !important;
    }
`

export const InputLabel = ({
    isError,
    isDisabled,
    errorText,
    label,
    placeholder,
    onChange,
    value,
    type = 'text',
    onFocus,
    onBlur,
    id = 'input-label',
}: {
    isError: boolean
    isDisabled: boolean
    errorText: string
    label: string
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    value?: string
    type?: string
    onFocus?: () => void
    onBlur?: () => void
    id?: string
}) => {
    return (
        <Box display="flex" width="100%" flexDirection="column">
            <Text
                mx="0px"
                fontFamily="Rubik"
                fontSize="14px"
                color={isDisabled ? '#02214370' : isError ? 'delay' : 'primary'}
                my="0px"
            >
                {label}
            </Text>
            <StyledInput
                borderRadius="4px"
                border="solid"
                borderWidth={1}
                borderColor={isDisabled ? '#C2CFE070' : 'gray350'}
                width="auto"
                bg="white"
                px="16px"
                py="16px"
                fontSize="14px"
                mt="12px"
                mb="8px"
                type={type}
                value={value}
                className="placeholder-darkgray"
                disabled={isDisabled}
                placeholder={placeholder}
                onFocus={onFocus ? onFocus : () => {}}
                onBlur={onBlur ? onBlur : () => {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                }
                id={id}
            ></StyledInput>
            <Text
                mx="0px"
                my="0px"
                fontFamily="Rubik"
                fontSize="14px"
                color="delay"
            >
                {errorText}
            </Text>
        </Box>
    )
}
