import * as React from 'react'
import {
    Box,
    Button,
    Image,
    Image as Img,
    Input,
    Link,
    Text,
} from 'src/components/utility'
import { useConfiguratorContext } from '../../configurator.context'
import { uuid } from 'src/helpers'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { captureImage } from '../../helper/create-material'
import {
    ConfigIco,
    Download,
    UploadFile,
} from 'src/components/svg-icons/3d-renderer/actions'
import { DeleteIco } from 'src/components/svg-icons'
import { DivisionLine } from 'src/components/division-line/division-line'
import { addGraphic, calculateCurrentUv, createBoundingBox } from './helpers'
import ControlPanel from '../basic-panel/control-panel'
import GraphicMaps from './graphic.maps'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'
//@ts-ignore
// import { MeshCustomMaterial } from 'three/src/materials/MeshCustomMaterial.js'

export interface IFabricBasicProps {}

const UV = {
    u: 0,
    v: 0,
}

export default function GraphicBasic(props: IFabricBasicProps) {
    const { highlights, setGraphic, graphic, recentlyUsed, setRecentlyUsed } =
        useConfiguratorContext()
    const [activeControlPanel, setActiveControlPanel] = React.useState<{
        isActive: boolean
        mode: 'artwork' | 'fabrics' | 'graphics'
        id?: string
    }>({
        isActive: false,
        mode: 'artwork',
        id: undefined,
    })
    const inputRef = React.useRef<HTMLInputElement | undefined>()

    const graphicImage = graphic
        ? getLocalImageUrl(new File([graphic.blob], graphic.name)) ||
          'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
        : highlights.length === 1 && highlights[0].mesh.material.map
        ? captureImage(highlights[0].mesh, 'mapLayer1') ||
          'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'
        : 'https://i0.wp.com/sumac.com.hk/wp-content/uploads/2022/11/placeholder.png?ssl=1'

    async function handleOnUpload(
        e: React.ChangeEvent<HTMLInputElement> | File,
        graphicConfig?: TMapConfig,
        addNew: boolean = true
    ) {
        const file =
            'target' in e && e.target.files ? e.target.files[0] : (e as File)
        const mat = highlights[0].mesh.material
        const selectedMaterial = highlights[0]

        // Calculate UV
        const UV = calculateCurrentUv(selectedMaterial.mesh)
        const box = createBoundingBox(selectedMaterial.mesh)

        if (file && box) {
            const url = getLocalImageUrl(file)
            if (url) {
                const config = await addGraphic(
                    box,
                    selectedMaterial.mesh,
                    UV,
                    url
                )
                const scaleAndOffset = config || {
                    offset: {
                        u: selectedMaterial.mesh.material?.map?.offset?.x || 0,
                        v: selectedMaterial.mesh.material?.map?.offset?.y || 0,
                    },
                    repeat: {
                        u: selectedMaterial.mesh.material?.map?.repeat?.x || 0,
                        v: selectedMaterial.mesh.material?.map?.repeat?.y || 0,
                    },
                }
                const graphicId = graphicConfig?.id || uuid()
                if (addNew) {
                    setRecentlyUsed((recentlyUsed) => ({
                        ...recentlyUsed,
                        graphics: [
                            ...recentlyUsed.graphics,
                            {
                                blob: new Blob([file]),
                                id: graphicId,
                                name: file.name,
                                path: file.webkitRelativePath,
                                config: {
                                    ...scaleAndOffset,
                                    rotation:
                                        graphicConfig?.config.rotation ||
                                        selectedMaterial.mesh.material?.map
                                            ?.rotation ||
                                        0,
                                    transparency:
                                        graphicConfig?.config.transparency || 0,
                                    substanceNess:
                                        graphicConfig?.config.substanceNess ||
                                        1,
                                },
                            },
                        ],
                    }))
                }
                setGraphic({
                    blob: new Blob([file]),
                    id: graphicId,
                    name: file.name,
                    path: file.webkitRelativePath,
                    config: {
                        ...scaleAndOffset,
                        rotation:
                            selectedMaterial.mesh.material?.map?.rotation || 0,
                        transparency: 0,
                        substanceNess: 1,
                    },
                })
            }
        }
    }

    const extension = graphic?.name
        ? graphic.name.split('.')[graphic.name.split('.').length - 1]
        : ''
    return (
        <Box>
            <Text px="16px" my="0px" fontSize="12px" color="primary">
                Recently Added
            </Text>
            <Box mt="8px" px="16px">
                <Box mb="8px" display="flex" gridColumnGap="12px">
                    <>
                        <Button
                            type="button"
                            className="dashed__border"
                            bg="transparent"
                            border="none"
                            width="48px"
                            height="48px"
                            id="add-files-dropdown-button"
                            onClick={() => {
                                inputRef.current &&
                                    inputRef.current.click &&
                                    inputRef.current.click()
                            }}
                        >
                            <PlusIcoSm />
                        </Button>
                        <Input
                            autoComplete="false"
                            type="file"
                            ref={inputRef}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => handleOnUpload(e)}
                            onClick={(e: any) => {
                                e.target.value = null
                            }}
                            display="none"
                            bg="none"
                        />
                    </>
                    {recentlyUsed.graphics.map((usedGraphic) => {
                        return (
                            <Box
                                position="relative"
                                onClick={() =>
                                    usedGraphic.id !== graphic?.id &&
                                    handleOnUpload(
                                        new File(
                                            [usedGraphic.blob],
                                            usedGraphic.name
                                        ),
                                        usedGraphic,
                                        false
                                    )
                                }
                                className="cursor-pointer"
                                width="50px"
                                height="50px"
                                borderRadius="4px"
                                overflow="hidden"
                            >
                                <Image
                                    width="50px"
                                    height="50px"
                                    src={getLocalImageUrl(
                                        new File(
                                            [usedGraphic.blob],
                                            usedGraphic.name
                                        )
                                    )}
                                />

                                {graphic?.id === usedGraphic.id && (
                                    <Box
                                        position="absolute"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                        height="100%"
                                        bg="#00000050"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="4px"
                                    >
                                        <Button
                                            bg="transparent"
                                            onClick={() => {
                                                setActiveControlPanel({
                                                    isActive: true,
                                                    id: usedGraphic.id,
                                                    mode: 'graphics',
                                                })
                                            }}
                                            border="none"
                                        >
                                            <ConfigIco color="#fff" />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <DivisionLine />
            <Box px="8px" display="flex" gridColumnGap="4px">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                    alignItems="center"
                    className="cursor-pointer"
                >
                    {false && (
                        <Box
                            borderRadius="4px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            // width="max-content"
                            // alignItems="center"
                            gridRowGap="4px"
                            border="solid"
                            width="100%"
                            borderColor="white"
                            borderWidth={1}
                        >
                            {graphic?.name && (
                                <Box
                                    display="flex"
                                    gridColumnGap="8px"
                                    alignItems="center"
                                >
                                    <ExtensionIcon size="sm" type={extension} />
                                    <Text fontSize="12px">{graphic?.name}</Text>
                                </Box>
                            )}
                            {graphic?.name && <DivisionLine width={2} />}
                            <Box width="100%">
                                <Img
                                    className="img-clear"
                                    width="100%"
                                    height="100px"
                                    src={graphicImage}
                                />
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gridColumnGap="20px"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gridColumnGap="20px"
                                    >
                                        <Button
                                            p="0px"
                                            bg="transparent"
                                            border="none"
                                            onClick={
                                                () => {}
                                                // inputRef.current &&
                                                // inputRef.current.click &&
                                                // inputRef.current.click()
                                            }
                                        >
                                            <UploadFile />
                                        </Button>
                                        <Input
                                            autoComplete="false"
                                            type="file"
                                            // ref={inputRef}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => handleOnUpload(e)}
                                            onClick={(e: any) => {
                                                e.target.value = null
                                            }}
                                            display="none"
                                            bg="none"
                                        />

                                        <Link
                                            href={graphicImage}
                                            download={'base_map' + '.png'}
                                            bg="white"
                                            style={{
                                                border: 'none',
                                                borderRadius: '4px',
                                                padding: '8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                            }}
                                            className="download-link"
                                        >
                                            <Download />
                                        </Link>
                                    </Box>
                                    <Button
                                        onClick={() => {
                                            highlights[0].mesh.material.mapLayer1 =
                                                null
                                            setGraphic(undefined)
                                        }}
                                        p="0px"
                                        bg="transparent"
                                        border="none"
                                    >
                                        <DeleteIco />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {/* <Slider element={<GraphicSlider />} /> */}
                    <Box width="100%">
                        <GraphicMaps />
                    </Box>
                </Box>
            </Box>
            <ControlPanel
                mode={activeControlPanel.mode}
                entity={{ id: activeControlPanel.id }}
                setIsControlPanelActive={(isActive) =>
                    setActiveControlPanel({
                        id: undefined,
                        mode: 'graphics',
                        isActive,
                    })
                }
                isControlPanelActive={activeControlPanel.isActive}
            />
        </Box>
    )
}
