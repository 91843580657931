import { DOWNLOAD_USER_IMAGE } from 'src/services'
import { getLocalStorage } from 'src/helpers'
import { DOWNLOAD_LIBRARY_FILE } from 'src/services/api/endpoints'

/**
 * @param {String} path
 * @returns `string`
 */

export function getImageUrl(path?: string): string {
    if (!path) return ''
    if (path.length <= 0) return ''
    const firstLetter = path.slice(0, 1)
    if (firstLetter !== '/') {
        path = `/${path}`
    }
    const authToken = getLocalStorage('authToken')
    if (authToken) {
        return DOWNLOAD_USER_IMAGE + path + '?token=' + authToken
    }
    return ''
}

export function getLibraryFileUrl(path: string): string {
    const firstLetter = path.slice(0, 1)
    if (firstLetter !== '/') {
        path = `/${path}`
    }
    const authToken = getLocalStorage('authToken')
    if (authToken) {
        return DOWNLOAD_LIBRARY_FILE + path + '?token=' + authToken
    }
    return ''
}

export function getLocalImageUrl(file: File) {
    if (!file) return
    const objURL = URL.createObjectURL(file)
    return objURL
}
