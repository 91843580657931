export const CollectionBadgeSM = () => (
    <svg
        width="32"
        height="36"
        viewBox="0 0 32 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.666 3.96074H27.4307C27.8855 3.96074 28.2543 4.32945 28.2543 4.78427V31.549C28.2543 32.0038 27.8855 32.3725 27.4307 32.3725H4.78366C4.32884 32.3725 3.96013 32.0038 3.96013 31.549V4.78427C3.96013 4.32945 4.32884 3.96074 4.78366 3.96074H9.51897V16.1078C9.51897 16.6907 9.8701 17.2162 10.4086 17.4393C10.9472 17.6623 11.567 17.539 11.9792 17.1269L15.1848 13.9213L19.3246 17.2332C19.7572 17.5792 20.3498 17.6467 20.8492 17.4067C21.3485 17.1667 21.666 16.6618 21.666 16.1078V3.96074ZM0.666016 4.78427C0.666016 2.51016 2.50955 0.666626 4.78366 0.666626H27.4307C29.7048 0.666626 31.5484 2.51016 31.5484 4.78427V31.549C31.5484 33.8231 29.7048 35.6666 27.4307 35.6666H4.78366C2.50955 35.6666 0.666016 33.8231 0.666016 31.549V4.78427ZM12.4013 12.6285V4.16662H18.7837V13.1093L15.9781 10.8648C15.4047 10.406 14.578 10.4518 14.0587 10.9711L12.4013 12.6285Z"
            fill="#778CA2"
        />
    </svg>
)
