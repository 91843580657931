import React from 'react'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import TodoWithArrowsIco from 'src/components/svg-icons/todo-with-arrows-ico'
import { Box, Text } from 'src/components/utility'
import DependentTodosPopover from './dependent-todos.popover'
import { useQuery } from '@apollo/client'
import type {
    GetDependentTodosQuery,
    GetDependentTodosQueryVariables,
} from 'src/generated/graphql'
import { GetDependentTodos } from 'src/services/graphql/entities/todo/todo.queries'

function DependentTodos({
    todoId,
    productVariantId,
}: {
    todoId: number
    productVariantId: number
}) {
    const { data, loading } = useQuery<
        GetDependentTodosQuery,
        GetDependentTodosQueryVariables
    >(GetDependentTodos, {
        variables: {
            todo_id: todoId,
            product_variant_id: productVariantId,
        },
        fetchPolicy: 'cache-and-network',
    })

    const totalDependentTodos = data?.todos.length

    return (
        <HtmlTooltip
            placement="bottom-end"
            arrow
            title={
                <DependentTodosPopover todos={data?.todos} loading={loading} />
            }
        >
            <Box display="flex" gridGap="4px">
                <TodoWithArrowsIco />
                <Text my="0px" fontSize="14px" color="primary">
                    {totalDependentTodos}
                </Text>
            </Box>
        </HtmlTooltip>
    )
}

export default DependentTodos
