import Mention from '@tiptap/extension-mention'
import suggestions from './suggestions'

export const CustomMention = Mention.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            suggestion: {
                ...this.parent?.().suggestion,
                onSelect: (props: any) => {
                    const { editor, range, item } = props
                    const { id, name, type } = item.user
                    // Insert the selected item's details into the editor
                    editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .insertContentAt(range.from, {
                            type: 'mention',
                            attrs: {
                                entity_id: id,
                                name: name,
                                entity_name: type,
                            },
                        })
                        .run()
                },
            },
        }
    },
    addAttributes() {
        return {
            entity_id: {
                default: null,
            },
            entity_name: {
                default: null,
            },
            name: {
                default: null,
            },
        }
    },
    renderHTML({ node, HTMLAttributes }) {
        return [
            'span',
            {
                ...HTMLAttributes,
                'data-entity-id': node.attrs.entity_id,
                'data-entity-name': node.attrs.entity_name,
                'data-name': node.attrs.name,
                class:
                    (HTMLAttributes.class ? HTMLAttributes.class + ' ' : '') +
                    'mention',
            },
            `@${node.attrs.name}`,
        ]
    },
    renderText({ node }) {
        return `@${node.attrs.name}`
    },
})

export const mentionConfig = ({
    users,
    team,
}: {
    users: Array<any> | undefined
    team?: any
}) => ({
    HTMLAttributes: {
        class: 'mention',
    },
    suggestion: {
        items: ({ query }: { query: string }) => {
            if (!query && team) {
                return team?.team_members
                    ? [
                          {
                              user: {
                                  name: 'Team',
                                  id: team.id,
                                  email: '',
                              },
                          },
                          ...team.team_members,
                      ]
                    : [
                          {
                              user: {
                                  name: 'Team',
                                  id: team.id,
                                  email: '',
                              },
                          },
                      ]
            }
            const _members = users ? users : []
            const members = !!team
                ? [
                      {
                          user: {
                              name: 'Team',
                              id: team.id,
                              email: '',
                          },
                      },
                      ..._members,
                  ]
                : _members

            return members.filter((member: any) =>
                member.user.name.toLowerCase().includes(query.toLowerCase())
            )
        },
        ...suggestions,
    },
})
