import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useNotificationContext } from './notification.context'
import type { TTabs } from './@types'
import { useSearchParams } from 'react-router-dom'

export interface INotificationSliderProps {}

export default function NotificationSlider(props: INotificationSliderProps) {
    const { setActiveTab, activeTab, setActivePillBadge } =
        useNotificationContext()
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Tabs
            value={activeTab}
            onChange={(e: any, newTab: any) => {
                searchParams.set('tab', newTab)
                searchParams.set('filter', 'all')
                setSearchParams(searchParams, { replace: true })
                setActivePillBadge({
                    label: 'All',
                    value: 'all',
                })
                const tab = newTab as unknown as TTabs
                setActiveTab(tab)
            }}
        >
            <Tab value="all" label="All" />
            <Tab value="unread" label="Unread" />
            <Tab value="comments" label="Comments" />
            <Tab value="statusUpdate" label="Status Update" />
            <Tab value="othersUpdate" label="Others Updates" />
        </Tabs>
    )
}
