import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'

import { InputTitleWithIcon } from 'src/components/input-title-with-icon/input-title-with-icon'
import { Modal } from 'src/components/modals/modal'
import { CollectionIco, ProductIco, TodoIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'
import { useUser } from 'src/helpers'
import { isStringEmpty } from 'src/helpers/is-string-empty'
import { GetCollectionsName, GetProductsWithCollectionId } from 'src/services'
import { CreateTodo } from 'src/services/graphql/mutations/create-todo.mutation'

type TodoDetails = {
    name: string
    parentCollection: { id: number; name: string } | null
    parentProduct: { id: number; name: string } | null
}

function CreateTodoModal({
    isOpen,
    onClose,
    initialParenProduct,
    initialParentCollection,
    onComplete,
    assigneeId,
}: {
    isOpen: boolean
    onClose: () => void
    initialParenProduct?: { id: number; name: string }
    initialParentCollection?: { id: number; name: string }
    onComplete?: (todoId: number) => Promise<void>
    assigneeId?: number
}) {
    const initialTodoDetails = {
        name: '',
        parentCollection: initialParentCollection || null,
        parentProduct: initialParenProduct || null,
    }

    const [todoData, setTodoData] = useState<TodoDetails>(initialTodoDetails)
    const [isIndividualTodo, setIsIndividualTodo] = useState(false)
    const [CreateTodoMutation, { loading: isCreatingTodo }] =
        useMutation(CreateTodo)
    const navigate = useNavigate()

    const { organization_id } = useUser()
    const inputRef = useRef<HTMLInputElement | null>(null)

    const { data: collectionsData } = useQuery(GetCollectionsName, {
        fetchPolicy: 'no-cache',
    })
    const { data: productsData, loading: isProductsLoading } = useQuery(
        GetProductsWithCollectionId,
        {
            skip: !todoData.parentCollection,
            variables: {
                collectionId: todoData.parentCollection?.id,
            },
            fetchPolicy: 'no-cache',
        }
    )
    let collections = collectionsData?.collections
    const products = productsData?.products

    function validateFields() {
        if (!todoData.name || todoData.name.trim().length < 1) {
            toast('Please enter todo name', {
                className: 'custom-toaster toaster-error',
            })
        }
        if (!isIndividualTodo && !todoData.parentProduct) {
            toast('Please select the product', {
                className: 'custom-toaster toaster-error',
            })
        }
        return (
            todoData.name.trim().length > 0 &&
            (isIndividualTodo ? true : !!todoData.parentProduct)
        )
    }

    function createTodoHandler() {
        if (validateFields()) {
            CreateTodoMutation({
                variables: {
                    object: {
                        name: todoData.name,
                        status: 'new',
                        product_variant_id: todoData.parentProduct
                            ? todoData.parentProduct.id
                            : null,
                        user_id: assigneeId || null,
                    },
                },
                refetchQueries: 'active',
                onCompleted: async (data) => {
                    if (onComplete) {
                        await onComplete(data.insert_todos_one.id)
                    }
                    toast.success('Todo added successfully!', {
                        className: 'custom-toaster toaster-success',
                        onClick: () => {
                            navigate(`/todo/${data.insert_todos_one.id}`)
                        },
                    })
                    onClose()
                },
            })
        }
    }

    // Focus on input field when modal loads
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef.current])

    return (
        <Modal
            disablePrimaryButton={
                isStringEmpty(todoData.name) || todoData.parentProduct === null
            }
            isModal={isOpen}
            onClose={onClose}
            onClick={createTodoHandler}
            primaryButtonTitle="Create Todo"
            secondaryButtonTitle="Cancel"
            title="New Todo"
            maxWidth="300px"
            bg="white"
            isLoading={isCreatingTodo}
        >
            <Box
                px="24px"
                height="100%"
                mb="40px"
                mt="28px"
                display="flex"
                flexDirection="column"
                gridGap="24px"
                width="100%"
            >
                <InputTitleWithIcon
                    icon={<TodoIco color="#778CA2" />}
                    title="Todo Name"
                    childrenWidth="80%"
                >
                    <Input
                        autoComplete="false"
                        id="product_form_name"
                        value={todoData.name}
                        bg="white"
                        ref={inputRef}
                        onChange={(e) =>
                            setTodoData({
                                ...todoData,
                                name: e.target.value,
                            })
                        }
                        color="primary"
                        border="none"
                        placeholder="Type Todo Name"
                        className="placeholder-light"
                        width="100%"
                        fontSize="16px"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                createTodoHandler()
                            }
                        }}
                    />
                </InputTitleWithIcon>
                {!initialParenProduct && (
                    <InputTitleWithIcon
                        icon={<CollectionIco />}
                        title="Collection"
                        childrenDirection="row"
                    >
                        <Box position="relative">
                            <CustomDropdown
                                label=""
                                minWidth="180px"
                                maxHeight="240px"
                                selectedText={
                                    isIndividualTodo
                                        ? 'Individual'
                                        : todoData.parentCollection?.name ||
                                          'Select'
                                }
                                // specialItem="Individual" --> Individual todos are now disabled in the platform
                                items={collections || []}
                                labelFontSize="12px"
                                highlightedTextFontSize="16px"
                                maxWidth="200px"
                                noStyle={true}
                                showArrow={true}
                                onItemClicked={(value) => {
                                    if (value === 'Individual') {
                                        setIsIndividualTodo(true)
                                        setTodoData({
                                            ...todoData,
                                            parentCollection: null,
                                            parentProduct: null,
                                        })
                                    } else {
                                        setIsIndividualTodo(false)
                                        setTodoData({
                                            ...todoData,
                                            parentCollection: {
                                                id: value?.id,
                                                name: value?.name,
                                            },
                                            parentProduct: null,
                                        })
                                    }
                                }}
                                enableSearch
                            />
                        </Box>
                    </InputTitleWithIcon>
                )}

                {!isIndividualTodo && !initialParenProduct && (
                    <InputTitleWithIcon
                        icon={<ProductIco />}
                        title="Product"
                        childrenDirection="row"
                    >
                        <Box position="relative">
                            <CustomDropdown
                                label=""
                                minWidth="120px"
                                maxHeight="240px"
                                selectedText={
                                    todoData.parentProduct?.name || 'Select'
                                }
                                labelFontSize="12px"
                                highlightedTextFontSize="16px"
                                noStyle={true}
                                showArrow={true}
                                maxWidth="200px"
                                items={products || []}
                                onItemClicked={(value) => {
                                    setTodoData({
                                        ...todoData,
                                        parentProduct: {
                                            id: value?.product_variants[0].id,
                                            name: value?.product_variants[0]
                                                .name,
                                        },
                                    })
                                }}
                                enableSearch
                                disabled={
                                    !(
                                        todoData.parentCollection &&
                                        !isIndividualTodo &&
                                        !isProductsLoading
                                    )
                                }
                            />
                        </Box>
                    </InputTitleWithIcon>
                )}
            </Box>
        </Modal>
    )
}

export default CreateTodoModal
