import { useQuery } from '@apollo/client'
import React from 'react'
import ReactViewAdobe from 'react-adobe-embed'
import { SuspenseLoading } from 'src/components/suspense-loading/suspense-loading'
import { CONFIG_VARS } from 'src/config/constants.config'
import { uuid } from 'src/helpers'
import { GetFileInfoByGID } from 'src/services'

function PdfAiPreviewSection({ url, gid }: { url: string; gid: string }) {
    const { data, loading } = useQuery(GetFileInfoByGID, {
        skip: !gid,
        variables: {
            gid,
        },
    })

    if (loading) {
        return <SuspenseLoading loadingText="Loading..." />
    }

    if (data?.files.length > 0 || data?.library_files.length > 0) {
        const file = data.files?.[0] || data.library_files?.[0]
        return (
            <ReactViewAdobe
                style={{ height: '100%' }}
                title={file.path.slice(1)}
                previewConfig={{
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showDownloadPDF: true,
                    focusOnRendering: false,
                }}
                config={{
                    clientId: CONFIG_VARS.PDF_CLIENT_ID,
                    divId: `pdf-div-${uuid()}`,
                    url: url,
                    fileMeta: {
                        fileName: file.path.slice(1) || 'unknown',
                        title: file.path.slice(1) || 'unknown',
                    },
                }}
            />
        )
    }
    return null
}

export default PdfAiPreviewSection
