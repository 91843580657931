import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DangerModal } from 'src/components'
import { Box, Button, Text } from 'src/components/utility'
import { getFileRequestId, getLocalStorage, roles, useUser } from 'src/helpers'
import { LibraryPerPage } from 'src/helpers/enums'
import {
    DeleteFolderById,
    DeleteLibraryFav,
    DELETE_LIBRARY_FILE,
    InsertLibraryFavourites,
} from 'src/services'
import {
    FetchFilesByFolderId,
    FetchFolderById,
    FetchSubFoldersById,
} from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import AppliedFiltersTags from './sub-screens/applied-filters-tags'
import EmptyFolderView from './sub-screens/empty-folder-view'
import { FolderFilesView } from './sub-screens/folders-files-view'
import { AddFolderModal } from './sub-screens/modals/create-new-folder'
import SetThumbnail from './sub-screens/modals/set-thumbnail'

const InsideFolderView = () => {
    const { role } = useUser()
    const navigate = useNavigate()
    const params = useParams()

    const [open, openModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [listView, setListView] = useState(false)
    const [deleteFolder, setDeleteFolderModal] = useState(false)
    const [deleteFolderId, setDeleteFolderId] = useState<number>(0)
    const [deleteFileId, setDeleteFileModal] = useState<number>(0)
    const [editingFolder, setEditingFolder] = useState<any>()

    const [fetchFolder, folderDetails] = useLazyQuery(FetchFolderById)
    const [fetchSubFolders, foldersListData] = useLazyQuery(FetchSubFoldersById)
    const [fetchFiles, filesListData] = useLazyQuery(FetchFilesByFolderId)
    const [insertFavourite] = useMutation(InsertLibraryFavourites)
    const [removeFavourite] = useMutation(DeleteLibraryFav)
    const [removeFolder] = useMutation(DeleteFolderById)

    const categoryId = searchParams.get(libraryDefaultKeys.categoryId)
    const searchStr = searchParams.get(libraryDefaultKeys.searchStr)
    const sortStr = searchParams.get(libraryDefaultKeys.sort)
    const folderId = params?.folderId

    const [currentPage, setCurrentPage] = useState(1)
    const [foldersCurrentPage, setFoldersCurrentPage] = useState(1)

    const filtersHash = searchParams.get(libraryDefaultKeys.filters) || ''
    const [filterObj, setFilterObj] = useState<any>({})

    const setFiltersProperly = () => {
        let finalFilters: any = ''
        if (filtersHash) {
            const buff = Buffer.from(filtersHash, 'base64')
            const preFilters = buff.toString('ascii')
            if (preFilters) {
                finalFilters = JSON.parse(preFilters)
                setFilterObj(finalFilters)
            } else {
                setFilterObj({})
            }
        } else {
            setFilterObj({})
        }
    }

    useEffect(() => {
        setFiltersProperly()
    }, [filtersHash])

    useEffect(() => {
        if (folderId && folderId !== '0') {
            onInit()
            fetchCurrentFolder()
        }
    }, [folderId, searchStr, sortStr, filterObj])

    useEffect(() => {
        if (searchParams.has(libraryDefaultKeys.searchStr)) {
            searchParams.delete(libraryDefaultKeys.searchStr)
            setSearchParams(searchParams)
        }
    }, [])

    const fetchCurrentFolder = () => {
        fetchFolder({
            variables: {
                id: folderId,
            },
        })
    }

    const common = {
        searchStr: searchStr ? `%${searchStr}%` : '%%',
        name: sortStr === libraryDefaultKeys.name ? 'asc' : null,
        created_at:
            !sortStr ||
            sortStr === '' ||
            sortStr === libraryDefaultKeys.dateCreated
                ? 'desc'
                : null,
    }

    useEffect(() => {
        if (folderId && folderId !== '0') {
            getFiles()
        }
    }, [currentPage])

    useEffect(() => {
        if (folderId && folderId !== '0') {
            getFolders()
        }
    }, [foldersCurrentPage])

    const getFolders = () => {
        let whereObj: any = {
            name: { _ilike: common.searchStr },
            deleted_at: { _is_null: true },
        }

        if ((categoryId && categoryId !== '0') || folder?.category_id) {
            whereObj.category_id = { _eq: categoryId || folder?.category_id }
        }

        if (filterObj?.selectedFolders?.length) {
            whereObj['id'] = {
                _in: filterObj.selectedFolders,
            }
        }

        if (filterObj?.selectedCreators?.length) {
            whereObj['created_by'] = {
                _in: filterObj.selectedCreators,
            }
        }

        if (filterObj?.selectedTags?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            if (!whereObj['library_files']['tag_relations']) {
                whereObj['library_files']['tag_relations'] = {}
            }
            whereObj['library_files']['tag_relations']['tag_id'] = {
                _in: filterObj?.selectedTags,
            }
        }

        if (filterObj?.selectedCompanies?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            whereObj['library_files']['company'] = {
                _in: filterObj.selectedCompanies,
            }
        }

        if (filterObj?.selectedColors?.length) {
            if (!whereObj['library_files']) {
                whereObj['library_files'] = {}
            }
            whereObj['library_files']['color'] = {
                _in: filterObj.selectedColors,
            }
        }

        if (
            !searchStr &&
            !whereObj['id'] &&
            !whereObj['created_by'] &&
            !whereObj['library_files']
        ) {
            whereObj.parent_id = {
                _eq: folderId,
            }
        }

        fetchSubFolders({
            variables: {
                name: common.name,
                created_at: common.created_at,
                whereObj,
                limit: foldersCurrentPage * LibraryPerPage,
            },
            fetchPolicy: 'cache-and-network',
        })
    }

    const getFiles = () => {
        const whereObjFiles: any = {
            name: { _ilike: common.searchStr },
            deleted_at: { _is_null: true },
            file_type: { _eq: 'document' },
        }

        if ((categoryId && categoryId !== '0') || folder?.category_id) {
            whereObjFiles.category_id = {
                _eq: categoryId || folder?.category_id,
            }
        }

        if (filterObj?.selectedCreators?.length) {
            if (!whereObjFiles['creator']) {
                whereObjFiles['creator'] = {}
            }
            whereObjFiles['creator']['id'] = {
                _in: filterObj.selectedCreators,
            }
        }

        if (filterObj?.selectedColors?.length) {
            whereObjFiles['color'] = {
                _in: filterObj.selectedColors,
            }
        }

        if (filterObj?.selectedCompanies?.length) {
            whereObjFiles['company'] = {
                _in: filterObj.selectedCompanies,
            }
        }

        if (filterObj?.selectedTags?.length) {
            if (!whereObjFiles['tag_relations']) {
                whereObjFiles['tag_relations'] = {}
            }
            whereObjFiles['tag_relations']['tag_id'] = {
                _in: filterObj?.selectedTags,
            }
        }

        if (
            !searchStr &&
            !whereObjFiles['tag_relations'] &&
            !whereObjFiles['company'] &&
            !whereObjFiles['color'] &&
            !whereObjFiles['creator']
        ) {
            whereObjFiles.folder_id = {
                _eq: folderId,
            }
        }

        fetchFiles({
            variables: {
                whereObj: whereObjFiles,
                name: common.name,
                limit: currentPage * LibraryPerPage,
                created_at: common.created_at,
            },
            fetchPolicy: 'cache-and-network',
        })
    }

    const onInit = () => {
        getFolders()
        getFiles()
    }

    const folder: FolderObject = folderDetails?.data?.folders[0] || null
    const foldersList = foldersListData?.data?.folders || []
    const foldersTotalCount =
        foldersListData.data?.folders_aggregate?.aggregate?.count || 0
    const filesList = filesListData?.data?.library_files || []
    const filesTotalCount =
        filesListData?.data?.library_files_aggregate?.aggregate?.count || 0
    const emptyState = !foldersList.length && !filesList.length

    useEffect(() => {
        setListView(false)
        if (searchParams.has(libraryDefaultKeys.listview)) {
            let l = searchParams.get(libraryDefaultKeys.listview)
            setListView(l === '1' ? true : false)
        }
    }, [searchParams])

    const routerChanged = () => {
        navigate(
            `/library/addfiles/${folder.category_id}?${libraryDefaultKeys.folderId}=${folder.id}`
        )
    }

    const runFavMutation = (
        folerId: number | null = null,
        fileId: number | null = null
    ) => {
        if ((!fileId && !folerId) || (fileId && folerId)) {
            console.error('Invalid parameters')
        }
        insertFavourite({
            variables: {
                folder_id: folerId,
                library_file_id: fileId,
            },
            onCompleted: () => {
                toast.success('Favourite added successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                console.error('Failed to add fav')
                const message: string = error.message
                if (message.includes('Uniqueness violation')) {
                    toast.error('Folder/File already in favourites.', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                } else {
                    toast.error('Failed to favourites.', {
                        className:
                            'custom-toaster toaster-danger toaster-error',
                    })
                }
            },
        })
    }

    const runDeleteFavMutation = (id: number) => {
        removeFavourite({
            variables: {
                id: id,
            },
            onCompleted: () => {
                toast.success('Favourite removed successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: (error: any) => {
                console.error('Failed to remove fav')
                console.error(error)
            },
        })
    }

    const runDeleteFolderMutation = (folderId: number) => {
        if (!folderId) {
            return
        }
        removeFolder({
            variables: {
                id: folderId,
                deleted_at: new Date(),
            },
            onCompleted: () => {
                foldersListData.refetch()
                toast.success('Folder deleted successfully!', {
                    className: 'custom-toaster toaster-success',
                })
            },
            onError: () => {},
        })
    }

    const runDeleteFile = async (fileId: number) => {
        try {
            if (!fileId) {
                console.error('Delete file id not found!')
                return
            }

            // As theis is no option to delete multiple files in one go hence added number_of_files: 1
            if (folderId && categoryId) {
                const { request_id } = await getFileRequestId({
                    action: 'DELETE',
                    entity_id: folderId
                        ? folderId?.toString()
                        : categoryId?.toString(),
                    entity_name: folderId ? 'folders' : 'category',
                    number_of_files: 1,
                })

                const data = await axios.post(
                    DELETE_LIBRARY_FILE,
                    {
                        request_id: request_id,
                        id: fileId,
                    },
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )

                filesListData.refetch()
                // console.log({ data });
            }
        } catch (error) {
            console.log('Errr')
            console.log(error)
        }
    }

    const goToFolder = () => {
        if (folder.category_id) {
            if (folder.parent_id) {
                navigate(
                    `/library/folder/${folder.parent_id}?category_id=${folder.category_id}`
                )
            } else {
                navigate(
                    `/library?tab=tab_${folder.category_id}&category_id=${folder.category_id}`
                )
            }
        }
    }

    return (
        <>
            <AppliedFiltersTags />

            <Box position="relative">
                <Box mb={emptyState ? '0px' : '24px'}>
                    {folder && !searchStr && (
                        <Button
                            color="links"
                            fontSize="12px"
                            mt={'0px'}
                            mb={'24px'}
                            bg="transparent"
                            border="none"
                            onClick={() => {
                                goToFolder()
                            }}
                            px="0px"
                            fontFamily="Rubik"
                        >
                            &lt; {folder?.name}
                        </Button>
                    )}

                    {emptyState ? (
                        <Box
                            height="calc(100vh - 350px)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box>
                                <EmptyFolderView
                                    onlyFiles={true}
                                    onlyFolders={true}
                                    openCreateFolderModal={openModal}
                                    addFileToFolder={() => {
                                        routerChanged()
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box mb={'24px'}>
                                <Box
                                    display="flex"
                                    width="100%"
                                    mb="16px"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Text
                                        fontSize={'16px'}
                                        color="textTags"
                                        mt="0px"
                                        mb="0px"
                                    >
                                        {' '}
                                        Files ({filesTotalCount}){' '}
                                    </Text>
                                    {roles.designer !== role && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap={'24px'}
                                        >
                                            <Button
                                                height="20px"
                                                px={'0px'}
                                                bg="transparent"
                                                border="none"
                                                fontSize="14px"
                                                color="links"
                                                position="relative"
                                                onClick={() => {
                                                    routerChanged()
                                                }}
                                            >
                                                + Add Files
                                            </Button>
                                        </Box>
                                    )}
                                </Box>

                                {filesList.length && !searchStr ? (
                                    <SetThumbnail />
                                ) : null}

                                {!filesList.length && !filesListData.loading ? (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box>
                                            <EmptyFolderView
                                                onlyFiles={true}
                                                onlyFolders={false}
                                                addFileToFolder={() => {
                                                    routerChanged()
                                                }}
                                                openCreateFolderModal={
                                                    openModal
                                                }
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <FolderFilesView
                                        deleteFileModal={setDeleteFileModal}
                                        runDeleteFavMutation={
                                            runDeleteFavMutation
                                        }
                                        deleteFolderModal={(
                                            folderId: number
                                        ) => {
                                            setDeleteFolderId(folderId)
                                            setDeleteFolderModal(true)
                                        }}
                                        runFavMutation={runFavMutation}
                                        name="insideFolderFilesView"
                                        listView={listView}
                                        files={filesList}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalCount={filesTotalCount}
                                    />
                                )}
                            </Box>

                            <Box
                                display="flex"
                                width="100%"
                                mb="16px"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text
                                    fontSize={'16px'}
                                    color="textTags"
                                    my="0px"
                                >
                                    Folders ({foldersTotalCount})
                                </Text>
                                {roles.designer !== role && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gridColumnGap={'24px'}
                                    >
                                        <Button
                                            height="20px"
                                            bg="transparent"
                                            border="none"
                                            fontSize="14px"
                                            color="links"
                                            px={'0px'}
                                            position="relative"
                                            onClick={() => {
                                                openModal(true)
                                            }}
                                        >
                                            + Create Folder
                                        </Button>
                                    </Box>
                                )}
                            </Box>

                            <FolderFilesView
                                editFolderModal={setEditingFolder}
                                runDeleteFavMutation={runDeleteFavMutation}
                                deleteFolderModal={(folderId: number) => {
                                    setDeleteFolderId(folderId)
                                    setDeleteFolderModal(true)
                                }}
                                deleteFileModal={setDeleteFileModal}
                                runFavMutation={runFavMutation}
                                name="insideFolder"
                                listView={listView}
                                folders={foldersList}
                                currentPage={foldersCurrentPage}
                                setCurrentPage={setFoldersCurrentPage}
                                totalCount={foldersTotalCount}
                            />
                        </>
                    )}
                </Box>
            </Box>

            {open && (
                <AddFolderModal
                    isModal={open}
                    setModal={openModal}
                    refetchFolders={() => {
                        foldersListData.refetch()
                    }}
                />
            )}

            {editingFolder && (
                <AddFolderModal
                    isModal={editingFolder ? true : false}
                    setModal={setEditingFolder}
                    edit={true}
                    existingFolder={editingFolder}
                    refetchFolders={() => {
                        foldersListData.refetch()
                    }}
                />
            )}

            {deleteFolder && (
                <DangerModal
                    isModal={deleteFolder}
                    title="Delete Folder"
                    heading="Are you sure you want to delete the folder?"
                    subHeading=""
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onClose={() => {
                        setDeleteFolderModal(false)
                    }}
                    onRemove={() => {
                        runDeleteFolderMutation(deleteFolderId)
                    }}
                />
            )}

            {deleteFileId ? (
                <DangerModal
                    isModal={true}
                    title="Delete File"
                    heading="Are you sure you want to delete the file?"
                    subHeading=""
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onClose={() => {
                        setDeleteFileModal(0)
                    }}
                    onRemove={() => {
                        runDeleteFile(deleteFileId)
                    }}
                />
            ) : null}
        </>
    )
}
export default InsideFolderView
