export const CollectionBadge = () => (
    <svg
        width="72"
        height="94"
        viewBox="0 0 88 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.84 9.38666H76.2667C77.5627 9.38666 78.6133 10.4373 78.6133 11.7333V88C78.6133 89.296 77.5627 90.3466 76.2667 90.3466H11.7333C10.4373 90.3466 9.38667 89.296 9.38667 88V11.7333C9.38667 10.4373 10.4373 9.38666 11.7333 9.38666H25.2267V44C25.2267 45.661 26.2273 47.1584 27.7618 47.7941C29.2964 48.4297 31.0627 48.0784 32.2372 46.9039L41.3715 37.7696L53.168 47.2068C54.4007 48.1929 56.0895 48.3852 57.5123 47.7014C58.9352 47.0175 59.84 45.5786 59.84 44V9.38666ZM0 11.7333C0 5.25319 5.25319 0 11.7333 0H76.2667C82.7468 0 88 5.25319 88 11.7333V88C88 94.4801 82.7468 99.7333 76.2667 99.7333H11.7333C5.25319 99.7333 0 94.4801 0 88V11.7333ZM33.4401 34.0856V9.97335H51.6267V35.4556L43.6321 29.0599C41.9981 27.7527 39.6425 27.8832 38.1629 29.3628L33.4401 34.0856Z"
            fill="#C2CFE0"
        />
    </svg>
)
