import { Dropdown } from 'src/components'
import type { SetStateAction } from 'react'
import { VCalendar } from 'src/components/calendar/calendar'
import { Box, Button } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { useQuery } from '@apollo/client'
import { GetRecentFilters } from 'src/services/graphql/query/get-recent-filters'
import { getFormattedDate, useUser } from 'src/helpers'
import { useSearchParams } from 'react-router-dom'
import { PillButton } from 'src/components/buttons/pill-button'
import { getDistinctValues } from 'src/helpers/get-distinct-values-from-array'
import { useMediaQuery } from '@mui/material'

export const DropDownActions = ({
    active,
    setDate,
    setIsDropdownActive,
    date,
    rightOffset,
    selectRange,
}: {
    active: boolean
    setDate: React.Dispatch<
        SetStateAction<[Date | null, Date | null] | Date | null>
    >
    setIsDropdownActive: React.Dispatch<SetStateAction<boolean>>
    date: [Date | null, Date | null] | Date | null
    rightOffset?: string
    selectRange?: boolean
}) => {
    const { userId } = useUser()
    const [searchParams, setSearchParams] = useSearchParams()
    const entityName = searchParams.get('group') || 'collections'
    const today = new Date()
    const tomorrow = () => {
        const _date = new Date(today)
        _date.setDate(today.getDate() + 1)
        return _date
    }
    const isLowResolutionHeight = useMediaQuery('(max-height:700px)')

    const { data: recentFiltersData } = useQuery(GetRecentFilters, {
        skip: !userId,
        variables: {
            entity_name: entityName,
            filter_for: 'due_date',
        },
    })
    const wrapperRef = useClickedOutside(() => setIsDropdownActive(false))

    const recentDueDates = recentFiltersData?.recent_filters?.reduce(
        (acc: Array<string>, filter: any) => {
            if (filter.entity_filters?.due_date) {
                return (acc = [...acc, ...filter.entity_filters.due_date])
            } else {
                return acc
            }
        },
        []
    )

    async function addRecentFilters(date: string) {}

    const getEndOfWeekDate = () => {
        const dayOfWeek = today.getDay()
        const daysUntilSunday = 7 - dayOfWeek // Days remaining until Sunday
        const endOfWeekDate = new Date(today)
        endOfWeekDate.setDate(today.getDate() + daysUntilSunday)
        return endOfWeekDate
    }

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="36px"
            width="360px"
            left="unset"
            right={rightOffset ? rightOffset : '-40%'}
        >
            <Box ref={wrapperRef}>
                <Box
                    height="48px"
                    px="8px"
                    display="grid"
                    gridTemplateColumns="auto auto"
                    gridGap="8px"
                >
                    <Box display="flex" flexWrap="wrap" gridRowGap="4px">
                        <PillButton
                            onClick={() => {
                                setDate(today)
                                setIsDropdownActive(false)
                                addRecentFilters(today.toISOString())
                            }}
                        >
                            Today
                        </PillButton>
                        <PillButton
                            onClick={() => {
                                setDate(tomorrow())
                                setIsDropdownActive(false)
                                addRecentFilters(tomorrow().toISOString())
                            }}
                        >
                            Tomorrow
                        </PillButton>
                        <PillButton
                            onClick={() => {
                                setDate(getEndOfWeekDate())
                                setIsDropdownActive(false)
                                addRecentFilters(
                                    getEndOfWeekDate().toISOString()
                                )
                            }}
                        >
                            End of this week
                        </PillButton>
                        {getDistinctValues(recentDueDates)?.map(
                            (dueDate: string) => {
                                const formattedDueDate = new Date(dueDate)
                                return (
                                    <PillButton
                                        key={dueDate}
                                        onClick={() => {
                                            setDate(formattedDueDate)
                                            setIsDropdownActive(false)
                                        }}
                                    >
                                        {getFormattedDate(
                                            formattedDueDate,
                                            'PLAIN',
                                            true
                                        )}
                                    </PillButton>
                                )
                            }
                        )}
                    </Box>
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        onClick={() => {
                            setDate(null)
                            setIsDropdownActive(false)
                            searchParams.delete('due_date')
                            setSearchParams(searchParams)
                        }}
                    >
                        Reset Filter
                    </Button>
                </Box>
                <VCalendar
                    disableCallback={() => setIsDropdownActive(false)}
                    isCalendarVisible={active}
                    setDate={setDate}
                    updateDate={(date) => {
                        if (date && !Array.isArray(date)) {
                            addRecentFilters(date.toISOString())
                        }
                    }}
                    date={date}
                    top="72px"
                    left="0px"
                    minDate={
                        new Date(
                            new Date('1990-01-01T00:00:00.000Z').setUTCHours(
                                23,
                                59,
                                59,
                                999
                            )
                        )
                    }
                    selectRange={selectRange}
                    maxHeight={isLowResolutionHeight ? '240px' : 'auto'}
                />
            </Box>
        </Dropdown>
    )
}
