import { RightArrow } from '../svg-icons/dashboard/right-arrow'
import { Box, Button } from '../utility'
// import useStore from 'src/store/hooks/useStore'

export const Slider: React.FunctionComponent<ParentProps> = ({
    element,
    actions,
    overflow = 'hidden',
    paddingX = '0px',
    onCarouselElementClick,
}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            position="relative"
            width="100%"
            overflow={overflow}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="relative"
                px={paddingX}
            >
                {onCarouselElementClick && (
                    <Button
                        style={{
                            transform: 'rotate(180deg)',
                        }}
                        bg="white"
                        border="none"
                        display="flex"
                        alignItems="center"
                        onClick={() => onCarouselElementClick(-1)}
                    >
                        <RightArrow />
                    </Button>
                )}
                <Box width={actions ? '70%' : '100%'}>{element}</Box>
                {onCarouselElementClick && (
                    <Button
                        display="flex"
                        alignItems="center"
                        position="absolute"
                        right="0px"
                        bg="white"
                        border="none"
                        width="24px"
                        onClick={() => onCarouselElementClick(1)}
                    >
                        <RightArrow />
                    </Button>
                )}
                <Box>{actions}</Box>
            </Box>
            <Box
                position="absolute"
                borderColor="gray100"
                borderStyle="solid"
                borderWidth={2}
                borderRight="none"
                borderLeft="none"
                borderTop="none"
                width="100%"
                bottom="0px"
                zIndex={0}
            ></Box>
        </Box>
    )
}
