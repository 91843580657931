import { gql } from "@apollo/client";

export const UpdateSubmittedFiles = gql`
mutation updateSubmittedFiles($submitted_files: jsonb, $id: bigint!) {
  update_sub_todos_by_pk(pk_columns: {id: $id}, _set: {submitted_files: $submitted_files}) {
    submitted_files
  }
}
`
export const UpdateSubmittedFilesTodo = gql`
mutation updateSubmittedFilesTodo($submitted_files: jsonb, $id: bigint!) {
  update_todos_by_pk(pk_columns: {id: $id}, _set: {submitted_files: $submitted_files}) {
    submitted_files
  }
}`