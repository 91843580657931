export function SettingsIco() {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.027 1a.348.348 0 00-.343.291l-.338 2.072a5.835 5.835 0 00-1.16.477l-1.71-1.221a.348.348 0 00-.447.037L2.675 4.007a.348.348 0 00-.038.446l1.202 1.72a5.885 5.885 0 00-.486 1.169l-2.063.343a.348.348 0 00-.29.343V9.94a.348.348 0 00.287.343l2.064.365c.12.41.282.8.485 1.169L2.618 13.52a.348.348 0 00.037.448l1.352 1.353a.348.348 0 00.445.04l1.724-1.207c.367.2.755.36 1.164.48l.344 2.074a.348.348 0 00.343.291H9.94a.348.348 0 00.343-.287l.37-2.083a5.86 5.86 0 001.157-.485l1.736 1.217a.348.348 0 00.445-.039l1.353-1.354a.348.348 0 00.036-.449l-1.237-1.717c.197-.363.357-.748.475-1.152l2.093-.366A.347.347 0 0017 9.942V8.029a.347.347 0 00-.292-.344l-2.09-.341a5.875 5.875 0 00-.476-1.15l1.22-1.739a.348.348 0 00-.039-.445l-1.353-1.353a.348.348 0 00-.45-.036l-1.712 1.232a5.83 5.83 0 00-1.16-.483l-.364-2.082A.348.348 0 009.94 1H8.027zm.296.696h1.325l.347 1.977a.348.348 0 00.258.278c.508.127.986.328 1.422.592a.348.348 0 00.383-.015l1.628-1.17.937.937-1.159 1.651a.348.348 0 00-.014.38c.262.435.46.91.587 1.415a.348.348 0 00.28.259l1.986.325v1.324l-1.99.35a.349.349 0 00-.276.26 5.15 5.15 0 01-.585 1.414.348.348 0 00.015.383l1.176 1.63-.937.938-1.649-1.157a.348.348 0 00-.38-.012 5.178 5.178 0 01-1.42.595.349.349 0 00-.257.276l-.351 1.978H8.322l-.327-1.97a.348.348 0 00-.26-.28 5.184 5.184 0 01-1.428-.59.348.348 0 00-.378.014l-1.636 1.146-.938-.939 1.157-1.616a.348.348 0 00.014-.382 5.167 5.167 0 01-.595-1.432.348.348 0 00-.277-.258L1.696 9.65V8.323l1.957-.326a.348.348 0 00.28-.259c.129-.513.33-.992.596-1.432a.348.348 0 00-.013-.38L3.374 4.294l.938-.937 1.621 1.159a.348.348 0 00.382.015 5.158 5.158 0 011.427-.586.348.348 0 00.26-.282l.321-1.966zM9 6.217A2.788 2.788 0 006.217 9a2.788 2.788 0 002.782 2.783A2.788 2.788 0 0011.782 9a2.788 2.788 0 00-2.783-2.783zm0 .696c1.157 0 2.087.93 2.087 2.087s-.93 2.087-2.087 2.087A2.082 2.082 0 016.912 9c0-1.157.93-2.087 2.087-2.087z"
                fill="#778CA2"
                stroke="#778CA2"
                strokeWidth={0.4}
            />
        </svg>
    )
}
