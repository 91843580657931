import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Box } from 'src/components/utility'
import { individualCollaboratorTabs } from 'src/helpers/enums'

export interface IIndividualCollaboratorTabSliderProps {
    onTabChange: ({
        tab,
        index,
    }: {
        tab: 'todos' | 'teams'
        index: number
    }) => void
    todosCount: number
    teamsCount: number
    currentTab: 'todos' | 'teams'
}

export default function IndividualCollaboratorTabSlider(
    props: IIndividualCollaboratorTabSliderProps
) {
    return (
        <Box>
            <Tabs
                value={props.currentTab}
                variant="scrollable"
                scrollButtons={false}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
                onChange={(_event: any, newValue: any) => {
                    props.onTabChange({
                        tab: newValue,
                        index:
                            props.currentTab === 'teams'
                                ? 0
                                : props.currentTab === 'todos'
                                ? 1
                                : 2,
                    })
                }}
            >
                <Tab
                    onClick={() => {}}
                    label={`To-Do's (${props.todosCount})`}
                    value={individualCollaboratorTabs.todos}
                />
                <Tab
                    label={`Teams (${props.teamsCount})`}
                    value={individualCollaboratorTabs.teams}
                />
            </Tabs>
        </Box>
    )
}
