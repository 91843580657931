import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Loader } from 'src/components/loader/loader'
import { Box, Image } from 'src/components/utility'
import { uuid } from 'src/helpers'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import UploadProductThumbnailMenuDropdown from '../upload-thumbnail-menu-dropdown/upload-thumbnail-menu-dropdown'
import { getThumbnailPicture } from 'src/helpers/get-thumbnail-picture'
import ThumbnailFullView from './thumbnail-full-view'

function ThumbnailBadge({
    entityId,
    entityName,
    productId,
    refetch,
    coverPic,
    parentCoverPic,
}: {
    productId: number
    refetch?: () => void
    coverPic: {
        name: string
        gid: string
        path: string
        thumbnail: string
        assignee_id: string
        fromLibrary: boolean
    } | null
    parentCoverPic?: {
        name: string
        gid: string
        path: string
        thumbnail: string
        assignee_id: string
        fromLibrary: boolean
    } | null
    entityName: 'product_variants' | 'todos'
    entityId: number
}) {
    const [isThumbnailUploading, setIsThumbnailUploading] = useState(false)
    const [isThumbnailMenuOpen, setIsThumbnailMenuOpen] = useState(false)
    const client = useApolloClient()
    const { onFileUpload } = useUploadFile(String(entityId), entityName)
    const { assignFilesToSection } = useAssignFile(() => {
        refetch && refetch()
    })

    async function uploadImageHandler(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            if (!e.target.files) {
                return
            }
            setIsThumbnailUploading(true)
            const file = e.target.files[0]
            const data = await onFileUpload(
                {
                    files: [
                        {
                            blob: file,
                            id: uuid(),
                            name: file.name,
                            path: getLocalImageUrl(file) || '',
                        },
                    ],
                },
                'cover_pic',
                () => {}
            )

            const fileId = data?.resolvedArr[0].id
            if (fileId) {
                await assignFilesToSection({
                    assignedAs: 'cover_pic',
                    entityId: String(entityId),
                    entityName: entityName,
                    fileId: [data?.resolvedArr[0].id],
                })
                client.refetchQueries({
                    include: 'active',
                })
                refetch && refetch()
            }
            setIsThumbnailUploading(false)
            setIsThumbnailMenuOpen(false)
        } catch {
            setIsThumbnailUploading(false)
            toast("Couldn't upload file.", {
                className: 'custom-toaster toaster-error',
            })
        } finally {
            setIsThumbnailUploading(false)
        }
    }

    const coverPicThumbnail = getThumbnailPicture({
        coverPic: coverPic,
        parentCoverPic: parentCoverPic,
        fallbackPlaceholder: false,
    })

    return (
        <Box
            position="relative"
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setIsThumbnailMenuOpen(!isThumbnailMenuOpen)
            }}
        >
            {isThumbnailUploading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="53px"
                    height="53px"
                >
                    <Loader outline="#022143" />
                </Box>
            ) : (
                <Box
                    width="53px"
                    height={'53px'}
                    position="relative"
                    display="flex"
                    alignItems="center"
                >
                    {(coverPic || parentCoverPic) && (
                        <ThumbnailFullView
                            gid={(coverPic?.gid || parentCoverPic?.gid)!}
                            fromLibrary={
                                (coverPic?.fromLibrary ||
                                    parentCoverPic?.fromLibrary)!
                            }
                        />
                    )}
                    <Image
                        width="53px"
                        height="53px"
                        borderRadius="4px"
                        src={coverPicThumbnail || '/image-placeholder.jpg'}
                    />
                </Box>
            )}
            {isThumbnailMenuOpen && (
                <UploadProductThumbnailMenuDropdown
                    active={isThumbnailMenuOpen}
                    onClose={() => {
                        setIsThumbnailMenuOpen(false)
                    }}
                    entityId={entityId}
                    entityName={entityName}
                    productId={productId}
                    onUpload={(e) => uploadImageHandler(e)}
                    refetchProduct={() => {
                        refetch && refetch()
                    }}
                />
            )}
        </Box>
    )
}

export default ThumbnailBadge
