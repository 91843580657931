export const CloseIconRed = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 2.5C5.86463 2.5 2.5 5.86463 2.5 10C2.5 14.1354 5.86463 17.5 10 17.5C14.1354 17.5 17.5 14.1354 17.5 10C17.5 5.86463 14.1354 2.5 10 2.5Z"
            fill="#FF2C18"
        />
        <path
            d="M8.23325 7.40392C8.00424 7.17491 7.63293 7.17491 7.40392 7.40392C7.17491 7.63293 7.17491 8.00424 7.40392 8.23325L9.17075 10.0001L7.40392 11.7669C7.17491 11.9959 7.17491 12.3672 7.40392 12.5962C7.63293 12.8252 8.00424 12.8252 8.23325 12.5962L10.0001 10.8294L11.7669 12.5962C11.9959 12.8252 12.3672 12.8252 12.5962 12.5962C12.8252 12.3672 12.8252 11.9959 12.5962 11.7669L10.8294 10.0001L12.5962 8.23325C12.8252 8.00424 12.8252 7.63293 12.5962 7.40392C12.3672 7.17491 11.9959 7.17491 11.7669 7.40392L10.0001 9.17075L8.23325 7.40392Z"
            fill="white"
        />
    </svg>
)
