export const FilterIco = (props: { color?: string }) => {
    const { color = '#778CA2' } = props
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.82222 16V11H8.15556V12.8444H16V14.1778H8.15556V16H6.82222ZM0 14.1778V12.8444H5.48889V14.1778H0ZM4.15556 10.4889V8.66667H0V7.33333H4.15556V5.46667H5.48889V10.4889H4.15556ZM6.82222 8.66667V7.33333H16V8.66667H6.82222ZM10.5111 5V0H11.8444V1.82222H16V3.15556H11.8444V5H10.5111ZM0 3.15556V1.82222H9.17778V3.15556H0Z"
                fill={color}
            />
        </svg>
    )
}
