export function base64ToJson(base64: string) {
    try {
        return JSON.parse(atob(base64))
    } catch (e) {
        return null
    }
}
export function jsonToBase64(json: any) {
    try {
        return btoa(JSON.stringify(json))
    } catch (e) {
        return null
    }
}
