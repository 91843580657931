import type { Link } from 'src/components/3d-renderer/annotation.types'
import create from 'zustand'

type Store = {
    showAddLinksModal: boolean
    links: Link[]
    addLink: (link: Link) => void
    removeLink: (url: string) => void
    clearLinks: () => void
    setShowLinksModal: (show: boolean) => void
}

export const useAnnotationAttachmentsStore = create<Store>((set: Function) => ({
    showAddLinksModal: false,
    links: [],
    addLink: (link) => {
        set((state: Store) => ({
            links: [...state.links, link],
        }))
    },
    removeLink: (url) => {
        set((state: Store) => ({
            links: state.links.filter((link) => link.url !== url),
        }))
    },
    clearLinks: () => {
        set(() => ({
            links: [],
        }))
    },
    setShowLinksModal: (show) => {
        set(() => ({
            showAddLinksModal: show,
        }))
    },
}))
