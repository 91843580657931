import { gql } from '@apollo/client'

export const GetTeamByID = gql`
    query get_teams($id: bigint!) {
        teams_by_pk(id: $id) {
            name
            id
            collectionsCompleted: collections_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            collectionsDelayed: collections_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsDelayed: products_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            totalProducts: products_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            todosCompleted: products {
                todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: false }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            todosDelayed: products {
                todos_aggregate(
                    where: {
                        is_delayed: { _eq: true }
                        status: { _eq: complete }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            team_members {
                id
                user {
                    email
                    name
                    id
                    other_data
                    todosCompleted: assigned_todos_aggregate(
                        where: {
                            status: { _eq: complete }
                            is_delayed: { _eq: false }
                        }
                    ) {
                        aggregate {
                            count
                        }
                        nodes {
                            name
                            status
                        }
                    }
                    todosDelayed: assigned_todos_aggregate(
                        where: {
                            status: { _eq: complete }
                            is_delayed: { _eq: true }
                        }
                    ) {
                        aggregate {
                            count
                        }
                        nodes {
                            name
                            status
                        }
                    }
                    totalTodos: assigned_todos_aggregate(
                        where: { status: { _eq: complete } }
                    ) {
                        aggregate {
                            count
                        }
                        nodes {
                            name
                            status
                        }
                    }
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`

export const FetchTeamDataById = gql`
    query fetchTeamDataById($id: bigint!) {
        teams_by_pk(id: $id) {
            name
            id
            collectionsCompleted: collections_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            collectionsDelayed: collections_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            productsDelayed: products_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            totalProducts: products_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
                nodes {
                    id
                    name
                }
            }
            todosCompleted: products {
                todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: false }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            todosDelayed: products {
                todos_aggregate(
                    where: {
                        is_delayed: { _eq: true }
                        status: { _eq: complete }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`

export const GetTeamMembersByTeamId = gql`
    query getTeamMembersByTeamId($id: bigint!, $order_by: order_by = desc) {
        team_members(
            where: { team_id: { _eq: $id } }
            order_by: { id: $order_by }
        ) {
            id
            user {
                email
                name
                id
                other_data
                todosCompleted: assigned_todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: false }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
                todosDelayed: assigned_todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: true }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
                totalTodos: assigned_todos_aggregate(
                    where: { status: { _eq: complete } }
                ) {
                    aggregate {
                        count
                    }
                }
                organisation_members {
                    role
                }
            }
        }
        team_members_aggregate(where: { team_id: { _eq: $id } }) {
            aggregate {
                count
            }
        }
    }
`

export const GetTeamMemberDetailsByTeamId = gql`
    query getTeamMembers($id: bigint) {
        team_members(where: { team_id: { _eq: $id } }) {
            user {
                email
                id
                name
                other_data
            }
        }
    }
`

export const GetTeamMemberByEmail = gql`
    query getTeamMemberByEmail($team_id: bigint!, $email: String!) {
        team_members(
            where: {
                team_id: { _eq: $team_id }
                user: { email: { _eq: $email } }
            }
        ) {
            user_id
            team_id
            id
            user {
                email
            }
        }
    }
`

export const GetOrgMemberByEmail = gql`
    query getOrgMemberByEmail($searchStr: String!) {
        organisation_members(
            where: {
                _or: [
                    { user: { email: { _ilike: $searchStr } } }
                    { user: { name: { _ilike: $searchStr } } }
                ]
            }
        ) {
            role
            user_id
            user {
                email
                name
                other_data
                id
                created_at
            }
        }
    }
`

export const GetOrgAdminsAndManagers = gql`
    query getOrgMemberByEmail($searchStr: String!) {
        organisation_members(
            where: {
                _or: [
                    { user: { email: { _ilike: $searchStr } } }
                    { user: { name: { _ilike: $searchStr } } }
                ]
                role: { _neq: designer }
            }
        ) {
            role
            user_id
            user {
                email
                name
                other_data
                id
                created_at
            }
        }
    }
`

export const GetTeamDetailsById = gql`
    query getTeamDetailsById($id: bigint!) {
        teams_by_pk(id: $id) {
            name
            id
            team_members {
                user {
                    id
                    email
                    name
                    other_data
                    organisation_members {
                        role
                    }
                }
            }
        }
    }
`
