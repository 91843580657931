import * as React from 'react'
import type { TDashboardTeams } from '../hooks/use-teams'
import { Box, Text } from 'src/components/utility'
import { ProgressBar, ProgressIcons, StackedImage } from 'src/components'
import { getImageUrl } from 'src/helpers'
import { EmptyTodoState } from 'src/screens/dashboard/sub-screens/todos/empty-state-todo'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useNavigate } from 'react-router-dom'

export interface ITeamsEntityListProps {
    entityList: TDashboardTeams[]
}

export default function TeamsEntityList(props: ITeamsEntityListProps) {
    const { entityList } = props
    const navigate = useNavigate()
    return (
        <Box overflowY="auto" className="scrollbar_none" height={'550px'}>
            {entityList.map((entity) => {
                return (
                    <Box
                        border="solid"
                        borderWidth={1}
                        borderColor="gray250"
                        bg="white"
                        px="16px"
                        py="16px"
                        className="cursor-pointer"
                        onClick={() => navigate(`/team/${entity.id}`)}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Text>Team Name</Text>
                            <Box
                                display="flex"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <Text fontSize="12px">
                                    {convertIntoElipses(entity.name, 40)}
                                </Text>
                                <StackedImage
                                    width="max-content"
                                    spacing={15}
                                    size="24px"
                                    fontSize="12px"
                                    list={entity.team_members.map((member) => {
                                        return {
                                            name: member.user.name,
                                            src: getImageUrl(
                                                member.user.other_data.thumbnail
                                            ),
                                        }
                                    })}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text fontSize="12px" color="textSecondary">
                                On-Time Completion
                            </Text>
                            <Box>
                                <ProgressBar
                                    fontSize="12px"
                                    percentage={entity.collectionCompletionRate}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text fontSize="12px" color="textSecondary">
                                Collections
                            </Text>
                            <Box>
                                <ProgressIcons
                                    completed={entity.collections.complete}
                                    remaining={entity.collections.delayed}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text fontSize="12px" color="textSecondary">
                                Products
                            </Text>
                            <Box>
                                <ProgressIcons
                                    completed={entity.collections.complete}
                                    remaining={entity.collections.delayed}
                                />
                            </Box>
                        </Box>
                    </Box>
                )
            })}
            {entityList.length <= 0 && (
                <Box bg="white" height={'550px'}>
                    <EmptyTodoState
                        boxHeight={'500px'}
                        title={'No Teams Yet!'}
                        description={'Try creating teams.'}
                        enableCreateButton={true}
                    />
                </Box>
            )}
        </Box>
    )
}
