import React, { useEffect, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import TemplateFolderCard from '../template-folder-card'
import TemplateCard from '../template-card'
import type { TodoTemplateType } from '../todo-templates.types'
import PreviewEditTemplateModal from 'src/components/drawer/todo-tab/preview-edit-template-modal/preview-edit-template-modal'
import {
    DeleteTemplateById,
    UpdateTemplateDesignById,
} from 'src/services/graphql/entities/todo/todo-templates.mutations'
import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import {
    GetFavouriteTemplates,
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplates,
} from 'src/services/graphql/entities/todo/todo-templates.queries'
import SaveTemplateDetailsModal from 'src/components/drawer/todo-tab/save-template/save-template-details-modal'
import CreateTodoModal from 'src/components-v2/modals/create-todo-modal'
import axios from 'axios'
import { ASSING_TEMPLATE } from 'src/services/api/endpoints'
import { getLocalStorage } from 'src/helpers'
import type { Template_Categories } from 'src/generated/graphql'
import { GetTodo } from 'src/services/graphql/entities/todo/todo.queries'

function TemplateContainer({
    data,
    sliderTabId,
    updateSearchType,
}: {
    data: Array<Template_Categories> | undefined
    sliderTabId: number
    updateSearchType: (type: 'category' | 'templates') => void
}) {
    const [activeCategoryId, setActiveCategoryId] = useState<number | null>(
        null
    )
    const [templateModal, setTemplateModal] = useState<{
        isOpen: boolean
        mode: 'preview' | 'edit' | 'delete' | 'duplicate' | null
        activeTemplate: TodoTemplateType | null
    }>({
        isOpen: false,
        mode: null,
        activeTemplate: null,
    })

    useEffect(() => {
        if (activeCategoryId) {
            updateSearchType('templates')
        } else {
            updateSearchType('category')
        }
    }, [activeCategoryId])

    const client = useApolloClient()

    const [saveTemplateModal, setSaveTemplateModal] = useState(false)
    const [showCreateTodoModal, setShowCreateTodoModal] = useState(false)

    const activeCategory = data?.find((c) => c.id === activeCategoryId)

    const [deleteTemplateMutation] = useMutation(DeleteTemplateById)
    const [updateTemplateDesignMutation] = useMutation(UpdateTemplateDesignById)

    useEffect(() => {
        setActiveCategoryId(null)
    }, [sliderTabId])
    function deleteTemplateHandler() {
        if (!templateModal.activeTemplate) return
        deleteTemplateMutation({
            variables: {
                id: templateModal.activeTemplate.id,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries: [GetTemplateCategoriesWithTemplates],
            onCompleted: () => {
                setTemplateModal({
                    isOpen: false,
                    mode: null,
                    activeTemplate: null,
                })
                toast('Template deleted successfully', {
                    className: 'custom-toaster toaster-success',
                })
            },
        })
    }

    function editTemplateHandler(updatedTemplate: TodoTemplateType) {
        if (!templateModal.activeTemplate) return
        updateTemplateDesignMutation({
            variables: {
                id: templateModal.activeTemplate.id,
                design: updatedTemplate.design,
            },
            refetchQueries: [
                GetTemplateCategoriesWithTemplates,
                GetTodoTemplates,
                GetFavouriteTemplates,
            ],
            onCompleted: () => {
                setTemplateModal({
                    isOpen: false,
                    mode: null,
                    activeTemplate: null,
                })
                toast('Template updated successfully', {
                    className: 'custom-toaster toaster-success',
                })
            },
        })
    }

    function useTemplateHandler() {
        if (!templateModal.activeTemplate) return
        setTemplateModal({
            ...templateModal,
            isOpen: false,
        })
        setShowCreateTodoModal(true)
    }

    async function assignTemplateToTodo(todoId: number) {
        if (!templateModal.activeTemplate) return
        try {
            const response = await axios.post(
                ASSING_TEMPLATE,
                {
                    todo_id: todoId,
                    template_id: templateModal.activeTemplate.id,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                client.refetchQueries({
                    include: [GetTodo],
                })
            }
        } catch {
            toast('Error occurred while assigning template', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    const totalResults = activeCategoryId
        ? activeCategory?.templates.length
        : data?.length

    return (
        <>
            <Box display="flex" alignItems="center" gridGap="16px">
                {!!activeCategory?.id && (
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#606060"
                        position="relative"
                        minWidth="max-content"
                        onClick={() => {
                            setActiveCategoryId(null)
                        }}
                    >
                        {`< ${activeCategory.name}`}
                    </Button>
                )}
                <Text my="0px" color="textSecondary">
                    ({totalResults} result
                    {totalResults && totalResults > 1 ? 's' : null})
                </Text>
            </Box>
            {data &&
                (!activeCategory?.id ? (
                    <Box display="flex" flexWrap="wrap" gridGap="16px">
                        {data?.length > 0 ? (
                            data.map((category) => (
                                <TemplateFolderCard
                                    key={category.id}
                                    onClick={() =>
                                        setActiveCategoryId(category.id)
                                    }
                                    category={category}
                                />
                            ))
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                gridGap="8px"
                                width="100%"
                                alignItems="center"
                                mt="64px"
                            >
                                <Text color="primary" fontSize="20px" my="0px">
                                    No category?
                                </Text>
                                <Text my="0px" color="textSecondary">
                                    You have not created any category
                                </Text>
                            </Box>
                        )}
                    </Box>
                ) : !!activeCategory?.id ? (
                    <Box
                        display="grid"
                        gridTemplateColumns={
                            activeCategory.templates?.length > 0
                                ? '1fr 1fr 1fr'
                                : 'auto'
                        }
                        gridGap="16px"
                    >
                        {activeCategory.templates?.length > 0 ? (
                            activeCategory.templates.map((template) => {
                                if (!template) return null
                                return (
                                    <TemplateCard
                                        key={template.id}
                                        template={template}
                                        onClick={({ isOpen, mode }) =>
                                            setTemplateModal({
                                                isOpen,
                                                mode,
                                                activeTemplate: {
                                                    ...template,
                                                    template_category: {
                                                        id: activeCategory.id,
                                                        name: activeCategory.name,
                                                        template_type: {
                                                            id: activeCategory
                                                                .template_type
                                                                .id,
                                                            type: activeCategory
                                                                .template_type
                                                                .type,
                                                        },
                                                    },
                                                },
                                            })
                                        }
                                    />
                                )
                            })
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                gridGap="8px"
                                width="100%"
                                alignItems="center"
                                mt="64px"
                            >
                                <Text color="primary" fontSize="20px" my="0px">
                                    No template?
                                </Text>
                                <Text my="0px" color="textSecondary">
                                    You have not created any template
                                </Text>
                            </Box>
                        )}
                    </Box>
                ) : null)}
            {templateModal.isOpen &&
                templateModal.activeTemplate &&
                templateModal.mode && (
                    <PreviewEditTemplateModal
                        isOpen={!!templateModal.isOpen}
                        onClose={() => {
                            setTemplateModal({
                                isOpen: false,
                                mode: null,
                                activeTemplate: null,
                            })
                        }}
                        template={templateModal.activeTemplate}
                        onDelete={deleteTemplateHandler}
                        onEdit={(updatedTemplate) =>
                            editTemplateHandler(updatedTemplate)
                        }
                        onClick={useTemplateHandler}
                        mode={templateModal.mode}
                        templateId={templateModal.activeTemplate.id}
                        hideFooter={templateModal.mode === 'edit'}
                    />
                )}
            {saveTemplateModal && templateModal.activeTemplate && (
                <SaveTemplateDetailsModal
                    isOpen={saveTemplateModal}
                    onClose={() => setSaveTemplateModal(false)}
                    todoId={null}
                    templateRef={'NEW'}
                />
            )}
            {showCreateTodoModal && (
                <CreateTodoModal
                    isOpen={showCreateTodoModal}
                    onClose={() => setShowCreateTodoModal(false)}
                    onComplete={assignTemplateToTodo}
                />
            )}
        </>
    )
}

export default TemplateContainer
