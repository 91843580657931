import { useQuery } from '@apollo/client'
import type { ChartConfiguration } from 'chart.js'
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    SubTitle,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { DropdownBadge } from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Text } from 'src/components/utility'
import { debugLog } from 'src/helpers'
import { GetOnTimeProductCompletionTeams } from 'src/services'
import theme from 'src/_theme'
import { ProductCompletionTeam } from './modals/products-teams-modal'

const TeamPerformace = ({
    durationDropdown,
    durationList,
}: {
    durationDropdown: string[]
    durationList: DurationFilterList
}) => {
    const textSecondary: string = theme.colors.textSecondary

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )

    const defaultDropdowns = {
        top5teams: 'Top 5 Teams',
        least5teams: 'Least 5 Teams',
        numbers: 'Numbers',
        percentages: 'Percentages',
    }

    const [productTeamModal, setProductTeamModal] = useState<boolean>(false)
    const [teamSortText, applyTeamSort] = useState<string>(
        defaultDropdowns.top5teams
    )
    const [teamShowBy, applyTeamShowBy] = useState<string>(
        defaultDropdowns.numbers
    )
    const [productTeams, setProductTeams] = useState<any[]>([])
    const [productsCompletionChart, setProductsCompletionChart] =
        useState<Chart>()
    const [tasksChart, setTasksGraph] = useState<Chart>()
    const teamOntimeProducts = useQuery(GetOnTimeProductCompletionTeams)
    const [duration, applyDurationFilter] = useState<string>(
        durationList.thisMonth
    )

    const defaultConfig: any = {
        type: 'bar',
        data: {},
        options: {
            scales: {
                y: {
                    ticks: {
                        color: textSecondary,
                        font: {
                            size: 12,
                        },
                        stepSize: 1,
                    },
                    grid: {
                        display: false,
                    },
                },
                x: {
                    ticks: {
                        color: textSecondary,
                        font: {
                            size: 12,
                        },
                        stepSize: 10,
                    },
                },
            },
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    }

    const productChartData = () => {
        if (productsCompletionChart) {
            productsCompletionChart.destroy()
        }

        const teams = teamOntimeProducts?.data?.teams
        setProductTeams(teams || [])

        if (!teams || !teams.length) {
            return
        }
        debugLog({ teams })

        const sortedTeams = teams
            .slice()
            .sort(
                (x: any, y: any) =>
                    y?.productsCompleted?.aggregate.count -
                    x?.productsCompleted?.aggregate.count
            )
        debugLog({ sortedTeams })

        const firstFiveTeams = sortedTeams.slice(0, 5) || []
        const leastFiveTeams =
            sortedTeams.slice(Math.max(teams.length - 5, 5)) || []

        let teamLabels = firstFiveTeams?.map((t: any) => t.name)
        let teamCounts = firstFiveTeams?.map(
            (t: any) => t?.productsCompleted?.aggregate.count
        )
        let teamPercentages = firstFiveTeams?.map(
            (t: any) =>
                (t?.productsCompleted?.aggregate.count /
                    t?.totalProducts?.aggregate.count) *
                100
        )

        if (teamSortText === defaultDropdowns.least5teams) {
            teamLabels = leastFiveTeams?.map((t: any) => t.name).slice(0, 5)
            teamCounts = leastFiveTeams?.map(
                (t: any) => t?.productsCompleted?.aggregate.count
            )
            teamPercentages = leastFiveTeams?.map(
                (t: any) =>
                    (t?.productsCompleted?.aggregate.count /
                        t?.totalProducts?.aggregate.count) *
                    100
            )
        }

        if (teamShowBy === defaultDropdowns.percentages) {
            teamCounts = teamPercentages
        }

        if (teamLabels?.length && teamCounts?.length) {
            const data = {
                labels: teamLabels,
                datasets: [
                    {
                        label: '',
                        data: teamCounts,
                        backgroundColor: ['#088981'],
                        borderWidth: 0,
                        barThickness: 16,
                    },
                ],
            }

            const config: ChartConfiguration = {
                ...defaultConfig,
                data: data,
            }

            const ctx: any = document.getElementById('productCompletionBar')
            let chartCreated: Chart = new Chart(ctx, config)
            setProductsCompletionChart(chartCreated)
        }
    }

    useEffect(() => {
        if (teamOntimeProducts.data && !teamOntimeProducts.loading) {
            productChartData()
        }
    }, [
        teamOntimeProducts.loading,
        teamOntimeProducts.data,
        teamShowBy,
        teamSortText,
    ])

    useEffect(() => {
        if (tasksChart) {
            tasksChart.destroy()
        }

        const data = {
            labels: ['Rohan', 'Rohan', 'Deeksha', 'Armay', 'Roberty'],
            datasets: [
                {
                    label: 'My First Dataset',
                    data: [6, 88, 70, 61, 45],
                    backgroundColor: ['#088981'],
                    borderWidth: 0,
                    barThickness: 16,
                },
            ],
        }

        const config: ChartConfiguration = {
            ...defaultConfig,
            data: data,
        }

        const ctx: any = document.getElementById('taskCompletionBar')
        let chartCreated: Chart = new Chart(ctx, config)
        setTasksGraph(chartCreated)
    }, [])

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={'20px'}
            >
                <Text fontSize={'20px'} my="0px">
                    {' '}
                    Team Performance{' '}
                </Text>
                <Box>
                    <Box display="inline-flex">
                        <CustomDropdown
                            label=""
                            left="unset"
                            right="0px"
                            noStyle={false}
                            height="32px"
                            border="1px solid #A7B9D1"
                            showArrow={true}
                            selectedText={duration}
                            items={durationDropdown}
                            onItemClicked={applyDurationFilter}
                        />
                    </Box>
                </Box>
            </Box>

            <Box display="flex" gridColumnGap={'24px'}>
                <Box width="100%">
                    <BasicCard>
                        <Box p="24px" pb={'0px'}>
                            <Box
                                pb={'24px'}
                                justifyContent="space-between"
                                flexWrap="wrap"
                                display="flex"
                            >
                                <Box
                                    className="pointer"
                                    onClick={() => {
                                        setProductTeamModal(true)
                                    }}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Text my={'0px'} fontSize={'16px'}>
                                        On-time Product Completion (Team)
                                    </Text>
                                    <Box ml={'8px'}>
                                        {' '}
                                        <RightArrow />{' '}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box display="inline-flex">
                                        <CustomDropdown
                                            label=""
                                            noStyle={false}
                                            showArrow={true}
                                            selectedText={teamSortText}
                                            items={[
                                                defaultDropdowns.top5teams,
                                                defaultDropdowns.least5teams,
                                            ]}
                                            onItemClicked={applyTeamSort}
                                        />
                                    </Box>
                                    <Box display="inline-flex" ml={'16px'}>
                                        <CustomDropdown
                                            label=""
                                            noStyle={false}
                                            showArrow={true}
                                            selectedText={teamShowBy}
                                            items={[
                                                defaultDropdowns.numbers,
                                                defaultDropdowns.percentages,
                                            ]}
                                            onItemClicked={applyTeamShowBy}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                position="relative"
                                width="100%"
                                display="block"
                            >
                                <canvas
                                    height={'290px'}
                                    id="productCompletionBar"
                                ></canvas>
                            </Box>
                            <Text
                                textAlign="right"
                                color="textSecondary"
                                my={'12px'}
                                fontSize={'12px'}
                            >
                                {' '}
                                Product completion on time
                            </Text>
                        </Box>
                    </BasicCard>
                </Box>
                <Box width="100%">
                    <BasicCard>
                        <Box p="24px" pb={'0px'}>
                            <Box
                                pb={'24px'}
                                justifyContent="space-between"
                                display="flex"
                            >
                                <Box display="flex" alignItems="center">
                                    <Text my={'0px'} fontSize={'16px'}>
                                        On-time Task Completion (Individual)
                                    </Text>
                                    <Box
                                        className="pointer"
                                        ml={'8px'}
                                        onClick={() => {}}
                                    >
                                        {' '}
                                        <RightArrow />{' '}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box display="inline-flex" ml={'16px'}>
                                        <DropdownBadge
                                            label={''}
                                            highlightedText={'Top 5 Teams'}
                                            onclick={() => {}}
                                            noStyle={false}
                                            showArrow={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                position="relative"
                                width="100%"
                                display="block"
                            >
                                <canvas
                                    height={'290px'}
                                    id="taskCompletionBar"
                                ></canvas>
                            </Box>
                            <Text
                                textAlign="right"
                                color="textSecondary"
                                my={'12px'}
                                fontSize={'12px'}
                            >
                                {' '}
                                Task completion on time
                            </Text>
                        </Box>
                    </BasicCard>
                </Box>
            </Box>
            {productTeamModal && (
                <ProductCompletionTeam
                    durationDropdown={durationDropdown}
                    durationList={durationList}
                    setModal={setProductTeamModal}
                />
            )}
        </>
    )
}
export default TeamPerformace
