import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { Slider } from 'src/components/slider/slider'

type TSlderTab = {
    label: string
    value: string
    count: string | number
}

export interface IDashboardSliderProps {
    activeTab: string
    tabs: TSlderTab[]
    setActiveTab: (activeTab: string) => void
}

export default function DashboardSlider(props: IDashboardSliderProps) {
    const { tabs, activeTab, setActiveTab } = props
    return (
        <Slider
            carousel={true}
            paddingX={'12px'}
            onCarouselElementClick={
                tabs.length > 3
                    ? (value) => {
                          const length = tabs.length
                          if (value === 1) {
                              tabs.forEach((tab, index) => {
                                  if (tab.value === activeTab) {
                                      if (length === index + 1) {
                                          return setActiveTab(tabs[0].value)
                                      }
                                      return setActiveTab(tabs[index + 1].value)
                                  }
                              })
                          }
                          if (value === -1) {
                              tabs.forEach((tab, index) => {
                                  if (tab.value === activeTab) {
                                      if (index - 1 < 0) {
                                          return setActiveTab(
                                              tabs[length - 1].value
                                          )
                                      }
                                      return setActiveTab(tabs[index - 1].value)
                                  }
                              })
                          }
                      }
                    : undefined
            }
            element={
                <Tabs
                    onChange={(e: any, newTab: any) => {
                        setActiveTab(newTab)
                    }}
                    value={activeTab}
                >
                    {tabs.map((tab) => {
                        return (
                            <Tab
                                value={tab.value}
                                label={`${tab.label} (${tab.count})`}
                            />
                        )
                    })}
                </Tabs>
            }
        />
    )
}
