import styled from 'styled-components'
import { color } from 'styled-system'
import { Box } from 'src/components/utility'

type DropdonwButtonWrapperType = {
    color?: string
    hover?: 'transparent' | '#E8ECEF'
    minWidth?: string
    maxWidth?: string
}

export const DropdownButton = styled(Box)<DropdonwButtonWrapperType>`
    ${color}
    display:flex;
    align-items:center;
    min-height: 32px;
    padding-right:16px;
    padding-left:16px;
    border:none; 
    font-family:"Rubik";
    height:max-content
    background-color:transparent;
    border : none;
    font-size:14px;
    cursor:pointer;
    &:hover{
        background-color: ${(props) => props.hover};
    }
`
