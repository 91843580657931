import React, { useEffect, useState } from 'react'
import { Box, Button, Text } from '../utility'
import { ProfileImg } from '../profile-img/profile-img'
import { commentParser, getImageUrl, getLocalStorage } from 'src/helpers'
import type { AnnotatedComment } from '../3d-renderer/annotation.types'
import { format, formatDistance, isToday, subDays } from 'date-fns'
import { FilePreview } from 'src/components-v2/file-preview/file-preview'
import { createRedirectionUrl } from 'src/helpers/create-redirection-url'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import { getAnnotationFileImageUrl } from './annotation-comments.helper'
import { Dots } from '../svg-icons'
import { CommentMenuDropdown } from './comment-menu-dropdown'
import axios from 'axios'
import { DELETE_ANNOTATION_COMMENT } from 'src/services/api/endpoints'
import { toast } from 'react-toastify'
import Link from 'src/components-v2/add-links/link'
import { TagComponent } from 'src/screens/products/sub-screens/accordian'
import { formatColorCode } from 'src/helpers/format-color-code'

function CommentCard({
    parentComment,
    isRepliesVisible,
    setIsRepliesVisible,
    repliesCount,
    refetch,
}: {
    parentComment: AnnotatedComment
    refetch: () => void
    isRepliesVisible?: boolean
    setIsRepliesVisible?: (isRepliesVisible: boolean) => void
    repliesCount?: number
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [userId, setUserId] = useState('')

    const user = getLocalStorage('user')

    useEffect(() => {
        if (user) {
            const _parsed = JSON.parse(user)
            setUserId(_parsed?.id || '')
        }
    }, [user])

    const formattedCreatedAt = (createdAt: string) => {
        const commentDate = new Date(createdAt)
        if (isToday(commentDate)) {
            let distance = formatDistance(subDays(commentDate, 0), new Date(), {
                addSuffix: true,
            })
            if (distance.includes('less than a minute ago')) {
                return 'Just now'
            }
            distance = distance
                .replace('minutes', 'mins')
                .replace('hours', 'hrs')
            return distance.includes('min') || distance.includes('hr')
                ? distance
                : format(commentDate, 'hh:mm a')
        } else {
            return format(commentDate, 'MM/dd/yyyy, hh:mm a')
        }
    }

    const files = parentComment?.other_data
        ? [
              ...parentComment.other_data.assigned_media,
              ...parentComment.other_data.assigned_library_media,
              ...parentComment.other_data.assigned_file_media,
          ]
        : []

    function getFileEntityName(fileGid: string) {
        if (
            parentComment.other_data.assigned_media.find(
                (media) => media.gid === fileGid
            )
        ) {
            return 'media'
        } else if (
            parentComment.other_data.assigned_library_media.find(
                (media) => media.gid === fileGid
            )
        ) {
            return 'library_media'
        } else {
            return 'file_media'
        }
    }

    const getThumbnail = (_file: any) => {
        if (_file?.from_library) {
            return getLibraryFileUrl(
                _file?.thumbnail || _file?.gid || _file?.url
            )
        }
        return getAnnotationFileImageUrl(
            _file?.thumbnail || _file?.gid || _file?.url,
            getFileEntityName(_file?.gid)
        )
    }

    async function deleteCommentHandler(annotation_id: number) {
        try {
            const response = await axios.post(
                DELETE_ANNOTATION_COMMENT,
                {
                    annotation_id: String(annotation_id),
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                toast('Comment deleted successfully. ', {
                    className: 'custom-toaster toaster-success',
                })
                refetch()
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                toast(e.response?.data?.message || 'Failed to add comment', {
                    className: 'toaster-error',
                })
            } else {
                toast('Failed to delete comment. ', {
                    className: 'custom-toaster toaster-error',
                })
            }
        }
    }

    const isAllowedToDelete = parentComment.user.id === Number(userId)

    return (
        <Box
            p="8px"
            display="flex"
            width="100%"
            flexDirection="column"
            gridGap="12px"
            border="1px solid #B8B8B8"
            borderRadius="4px"
            mb="4px"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                width="100%"
                gridColumnGap="12px"
            >
                <Box
                    display="flex"
                    gridColumnGap="12px"
                    justifySelf="start"
                    alignItems="center"
                    mr="auto"
                >
                    <ProfileImg
                        size="20px"
                        fontSize="12px"
                        src={
                            parentComment.user.other_data?.profile_pic
                                ? getImageUrl(
                                      parentComment.user.other_data.profile_pic
                                  )
                                : ''
                        }
                        name={parentComment.user.name}
                    />
                    <Text fontSize="14px" my="0px">
                        {parentComment.user.name}
                    </Text>
                </Box>
                <Box display="flex" gridGap="2px" color="#817E7E">
                    <Text my="0px" color="textSecondary" fontSize="14px">
                        {formattedCreatedAt(
                            parentComment.created_at.toString()
                        )}
                    </Text>
                </Box>
                {isAllowedToDelete && (
                    <Box position="relative">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsMenuOpen(!isMenuOpen)
                            }}
                            bg="transparent"
                            border="none"
                            fontStyle="Rubik"
                            fontSize="12px"
                            color="#027AC3"
                            position="relative"
                            width="max-content"
                        >
                            <Dots active={isMenuOpen} />
                        </Button>
                        {isMenuOpen && (
                            <CommentMenuDropdown
                                setIsDropdownActive={setIsMenuOpen}
                                active={isMenuOpen}
                                onDelete={() =>
                                    deleteCommentHandler(parentComment.id)
                                }
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Text mb="12px" my="0px">
                {commentParser(parentComment.comment).parsed}
            </Text>
            <Box display="flex" flexDirection="column">
                {files?.length > 0 && (
                    <Box display="flex" gridGap="8px" flexWrap="wrap" mt="8px">
                        {parentComment.other_data.assigned_library_media?.map(
                            (file: any) => {
                                return (
                                    <FilePreview
                                        width="80px"
                                        height="84px"
                                        redirectionUrl={createRedirectionUrl({
                                            path: file.name,
                                            gid: file.gid,
                                            ref: 'library',
                                        })}
                                        isDownloadable
                                        key={file.id}
                                        name={file.name}
                                        url={getLibraryFileUrl(file.gid)}
                                        params={[
                                            {
                                                key: 'ref',
                                                value: 'library',
                                            },
                                            {
                                                key: 'fileId',
                                                value: file.id,
                                            },
                                            {
                                                key: 'gid',
                                                value: file.gid,
                                            },
                                        ]}
                                        fileId={file.id}
                                        thumbnail={getThumbnail(file)}
                                        isUrlRequired={false}
                                        isDeletePermitted={false}
                                        redirect={true}
                                        openFileInNewTab={false}
                                        percentage={file.progress || '0'}
                                        showLoader={false}
                                    />
                                )
                            }
                        )}
                        {parentComment.other_data.assigned_file_media?.map(
                            (file: any) => {
                                return (
                                    <FilePreview
                                        width="80px"
                                        height="84px"
                                        redirectionUrl={createRedirectionUrl({
                                            path: file.name,
                                            gid: file.gid,
                                            ref: 'todo',
                                        })}
                                        isDownloadable
                                        key={file.id}
                                        name={file.name}
                                        url={getImageUrl(file.gid)}
                                        params={[
                                            {
                                                key: 'ref',
                                                value: 'todo',
                                            },
                                            {
                                                key: 'fileId',
                                                value: file.id,
                                            },
                                            {
                                                key: 'gid',
                                                value: file.gid,
                                            },
                                        ]}
                                        fileId={file.id}
                                        thumbnail={getThumbnail(file)}
                                        isUrlRequired={false}
                                        isDeletePermitted={false}
                                        redirect={true}
                                        openFileInNewTab={false}
                                        percentage={file.progress || '0'}
                                        showLoader={false}
                                    />
                                )
                            }
                        )}
                        {parentComment.other_data.assigned_media?.map(
                            (file: any) => {
                                return (
                                    <FilePreview
                                        width="80px"
                                        height="84px"
                                        redirectionUrl={createRedirectionUrl({
                                            path: file.name,
                                            gid: file.gid,
                                            ref: 'comment',
                                        })}
                                        isDownloadable
                                        key={file.id}
                                        name={file.name}
                                        url={getAnnotationFileImageUrl(
                                            file.gid,
                                            getFileEntityName(file.gid)
                                        )}
                                        params={[
                                            {
                                                key: 'ref',
                                                value: 'comment',
                                            },
                                            {
                                                key: 'fileId',
                                                value: file.id,
                                            },
                                            {
                                                key: 'gid',
                                                value: file.gid,
                                            },
                                        ]}
                                        fileId={file.id}
                                        thumbnail={getThumbnail(file)}
                                        isUrlRequired={false}
                                        isDeletePermitted={false}
                                        redirect={true}
                                        openFileInNewTab={false}
                                        percentage={file.progress || '0'}
                                        showLoader={false}
                                    />
                                )
                            }
                        )}
                    </Box>
                )}
                {parentComment.other_data?.links?.length > 0 && (
                    <Box display="flex" gridGap="8px" flexWrap="wrap" mt="8px">
                        {parentComment.other_data.links.map(
                            ({ link, name }, index) => (
                                <Link
                                    link={{
                                        id: String(index),
                                        text: name,
                                        url: link,
                                    }}
                                    removeLink={() => {}}
                                    showRemoveButton={false}
                                />
                            )
                        )}
                    </Box>
                )}
                {parentComment.other_data?.colors?.length > 0 && (
                    <Box display="flex" gridGap="8px" flexWrap="wrap" mt="8px">
                        {parentComment.other_data?.colors.map(({ color }) => {
                            return (
                                <TagComponent
                                    onUpdate={() => {}}
                                    onDelete={(e) => {
                                        e.stopPropagation()
                                    }}
                                    name={color}
                                    color={formatColorCode(color)}
                                    key={color}
                                    colorPickerRelativePoint="bottom"
                                    isEditPermitted={false}
                                />
                            )
                        })}
                    </Box>
                )}
            </Box>
            {!!repliesCount && repliesCount > 0 && (
                <Button
                    height="20px"
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    textAlign="left"
                    p="0px"
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsRepliesVisible &&
                            setIsRepliesVisible(!isRepliesVisible)
                    }}
                >
                    {isRepliesVisible
                        ? `Hide ${repliesCount} Replies`
                        : `View ${repliesCount} Replies`}
                </Button>
            )}
        </Box>
    )
}

export default CommentCard
