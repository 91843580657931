import type { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { DeleteTodoById } from 'src/services'
import { UpdateTodoByPk } from 'src/services/graphql/mutations/update-todos-by-pk'

function useUpdateTodo() {
    const [UpdateTodoMutation, updatedTodo] = useMutation(UpdateTodoByPk)

    function updateTodo({
        todoId,
        payload,
        refetchQueries,
        onComplete,
    }: {
        todoId: number
        payload: any
        refetchQueries?:
            | InternalRefetchQueriesInclude
            | ((result: FetchResult<any>) => InternalRefetchQueriesInclude)
            | undefined
        onComplete?: () => void
    }) {
        UpdateTodoMutation({
            variables: {
                id: todoId,
                object: payload,
            },
            refetchQueries: refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }

    return {
        updateTodo,
        updatedTodo,
    }
}

export function useDeleteTodo() {
    const [deleteTodoMutation, deletedTodo] = useMutation(DeleteTodoById)

    function deleteTodo({
        todoId,
        refetchQueries,
        onComplete,
    }: {
        todoId: number
        refetchQueries?:
            | InternalRefetchQueriesInclude
            | ((result: FetchResult<any>) => InternalRefetchQueriesInclude)
            | undefined
        onComplete?: () => void
    }) {
        deleteTodoMutation({
            variables: {
                id: todoId,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries: refetchQueries,
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }

    return {
        deleteTodo,
        deletedTodo,
    }
}

export default useUpdateTodo
