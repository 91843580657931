export const setRecentSearches = (payload: string) => {
    const recentSearches = localStorage.getItem('RECENT_SEARCH')
    if (recentSearches) {
        const searchExist = JSON.parse(recentSearches).find(
            (search: string) => search === payload
        )
        if (!searchExist) {
            localStorage.setItem(
                'RECENT_SEARCH',
                JSON.stringify([...JSON.parse(recentSearches), payload])
            )
        }
    } else {
        localStorage.setItem('RECENT_SEARCH', JSON.stringify([payload]))
    }
}

export const getRecentSearches = () => {
    const searches = localStorage.getItem('RECENT_SEARCH')
    if (searches) {
        return JSON.parse(searches)
    }
    return []
}

export function setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value)
    return true
}

export function getLocalStorage(key: string) {
    const data = localStorage.getItem(key) || window.sessionStorage.getItem(key)
    if (data) {
        return data
    }
    return ''
}

export function removeLocalStorage(key: string) {
    try {
        localStorage.removeItem(key)
        return true
    } catch (e) {
        return false
    }
}
