import React, { useState } from 'react'
import { DragDropArea } from 'src/components-v2/drag-drop-modal/drag-drop-area'
import { Configurator } from 'src/components/3d-renderer/configurator/configurator'
import { Box } from 'src/components/utility'
import { getLocalImageUrl } from 'src/helpers/get-image-url'

const Configure = () => {
    const [fileModal, setFileModal] = useState<{
        isFileModal: boolean
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        files: [],
    })
    return (
        <Box height="94%" my="24px">
            {fileModal.files.length <= 0 && (
                <DragDropArea
                    multiple={false}
                    height="100%"
                    fileModal={fileModal}
                    setFileModal={setFileModal}
                />
            )}
            {fileModal.files.length > 0 && (
                <Configurator
                    // configuratorRef="configurator"
                    // name={fileModal.files[0].name}
                    configuraturRef="upload"
                    file={{
                        url:
                            getLocalImageUrl(
                                new File(
                                    [fileModal.files[0].blob],
                                    fileModal.files[0].name
                                )
                            ) || '',
                        name: fileModal.files[0].name,
                    }}
                />
            )}
        </Box>
    )
}

export default Configure
