import { gql } from '@apollo/client'
export const GetInvitations = gql`
    query GetInvitations($limit: Int!, $offset: Int!) {
        invitations(limit: $limit, offset: $offset, order_by: { id: desc }) {
            email
            id
            role
        }
        invitations_aggregate {
            aggregate {
                count
            }
        }
    }
`

export const GetInvitationsByEmail = gql`
    query getInvitationsByEmail($emails: [String!]) {
        invitations(where: { email: { _in: $emails } }) {
            email
            id
            role
        }
    }
`

export const CheckInvitationExist = gql`
    query checkInvitationExist($email: String) {
        invitations(where: { email: { _eq: $email } }) {
            email
        }
        users(where: { email: { _eq: $email } }) {
            email
        }
    }
`
