import { useEffect } from 'react'
import { NoCommentsIcon } from 'src/components/svg-icons/dashboard/no-comments'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Text } from 'src/components/utility'
import Skeleton from 'react-loading-skeleton'
import { useCommentsStore } from 'src/store/hooks/useCommentsStore'
import CommentCard from './cards/comment'
import { Slider } from 'src/components/slider/slider'
import CommentSlider from './comment-slider'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { PerPage } from 'src/helpers/enums'
import { PillOptions } from './comment-pills'
import { useSearchParams } from 'react-router-dom'

export const RecentCommentsList = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const currentTab = searchParams.get('commentTab') || 'all'
    const selectedMyCommentFilter =
        searchParams.get('commentFilter') || 'allComments'

    const {
        comments,
        annotation,
        mention,
        all,
        myComments,
        sendMessage,
        status,
        isRecentCommentsPresent,
        isLoading,
        dashboardCommentsPage,
        setDashboardCommentsPage,
        setIsLoading,
    } = useCommentsStore()

    const { getLastOpenedTab } = useCardExpandedState()

    const { isIntersecting: isIntersecting, lastElementRef: lastElementRef } =
        useIntersectionObserver({
            isLoading,
        })

    function fetchRecentComments({
        fetchType = 'dashboard_comments',
        page = 0,
        limit = PerPage,
    }: {
        fetchType?: string
        page?: number
        limit?: number
    }) {
        if (isLoading) {
            return
        }

        setIsLoading(true)
        sendMessage(
            JSON.stringify({
                type: fetchType,
                payload: {
                    operation: 'GET',
                    limit,
                    page,
                },
            })
        )
    }

    useEffect(() => {
        if (status === 'active' && !isRecentCommentsPresent && !isLoading) {
            fetchRecentComments({
                page: 0,
                limit: PerPage,
            })
        }
    }, [status, isRecentCommentsPresent])

    const isLastOpenedTabComment = getLastOpenedTab('comment')
    useEffect(() => {
        if (isLastOpenedTabComment) {
            scrollToSection('dashboard_recent_comments')
        }
    }, [isLastOpenedTabComment])

    useEffect(() => {
        if (isIntersecting) {
            setDashboardCommentsPage(dashboardCommentsPage + 1)
            fetchRecentComments({
                fetchType: 'dashboard_comments',
                page: dashboardCommentsPage + 1,
            })
        }
    }, [isIntersecting])

    const handleTabChange = (newTab: string) => {
        searchParams.set('commentTab', newTab)
        if (newTab === 'myComments') {
            searchParams.set('commentFilter', selectedMyCommentFilter)
        } else {
            searchParams.delete('commentFilter')
        }
        setSearchParams(searchParams, { replace: true })
    }

    const handleCommentFilterChange = (newFilter: string) => {
        searchParams.set('commentFilter', newFilter)
        setSearchParams(searchParams, { replace: true })
    }

    return (
        <Box width="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="3px"
                id="dashboard_recent_comments"
            >
                <Box display="flex" alignItems="center" gridColumnGap="8px">
                    <Text my="0px"> Recent Comments</Text>
                    <RightArrow />
                </Box>
            </Box>
            <Box
                border={'solid'}
                borderColor="gray250"
                borderWidth={1}
                borderRadius={'4px'}
                mt="16px"
                pt="16px"
                bg="white"
            >
                <Box bg={'white'} width="100%" height="100%">
                    <Box>
                        <Slider
                            paddingX={'12px'}
                            element={
                                <CommentSlider
                                    currentTab={currentTab}
                                    setCurrentTab={handleTabChange}
                                />
                            }
                        />
                    </Box>
                    {/* My comments filter section */}
                    {currentTab === 'myComments' && (
                        <Box p={'10px'}>
                            <PillOptions
                                onClick={({ id }) =>
                                    handleCommentFilterChange(id)
                                }
                                pills={[
                                    {
                                        id: 'allComments',
                                        label: 'All',
                                    },
                                    {
                                        id: 'myComments',
                                        label: 'My Comments',
                                    },
                                    {
                                        id: 'myReplies',
                                        label: 'My Replies',
                                    },
                                ]}
                                selectedPillId={selectedMyCommentFilter}
                            />
                        </Box>
                    )}
                    <Box
                        height={'500px'}
                        overflowY={'auto'}
                        width={'100%'}
                        overflowX={'hidden'}
                    >
                        {currentTab === 'all' &&
                            all.length > 0 &&
                            all.map((item) => {
                                if (!item) return

                                return (
                                    <CommentCard
                                        ref={lastElementRef}
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {currentTab === 'mentions' &&
                            mention.length > 0 &&
                            mention.map((item) => {
                                if (!item) return
                                return (
                                    <CommentCard
                                        ref={lastElementRef}
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {currentTab === 'annotations' &&
                            annotation.length > 0 &&
                            annotation.map((item) => {
                                if (!item) return
                                return (
                                    <CommentCard
                                        ref={lastElementRef}
                                        type={
                                            item.self_name as
                                                | 'comments'
                                                | 'annotations'
                                        }
                                        comment={item}
                                    />
                                )
                            })}
                        {/* My comments section */}
                        {currentTab === 'myComments' && (
                            <>
                                {selectedMyCommentFilter === 'allComments' &&
                                    myComments.all.length > 0 &&
                                    myComments.all.map((item) => {
                                        if (!item) return null
                                        return (
                                            <CommentCard
                                                ref={lastElementRef}
                                                type={
                                                    item.self_name as
                                                        | 'comments'
                                                        | 'annotations'
                                                }
                                                userSelfComment={true}
                                                comment={item}
                                            />
                                        )
                                    })}
                                {selectedMyCommentFilter === 'myComments' &&
                                    myComments.comments.length > 0 &&
                                    myComments.comments.map((item) => {
                                        if (!item) return null
                                        return (
                                            <CommentCard
                                                ref={lastElementRef}
                                                type={
                                                    item.self_name as
                                                        | 'comments'
                                                        | 'annotations'
                                                }
                                                userSelfComment={true}
                                                comment={item}
                                            />
                                        )
                                    })}
                                {selectedMyCommentFilter === 'myReplies' &&
                                    myComments.replies.length > 0 &&
                                    myComments.replies.map((item) => {
                                        if (!item) return null
                                        return (
                                            <CommentCard
                                                ref={lastElementRef}
                                                type={
                                                    item.self_name as
                                                        | 'comments'
                                                        | 'annotations'
                                                }
                                                userSelfComment={true}
                                                comment={item}
                                            />
                                        )
                                    })}
                            </>
                        )}
                        {isLoading ? (
                            <Box position="relative" width="100%" my="32px">
                                <Skeleton
                                    height="32px"
                                    count={6}
                                    containerClassName="loader"
                                />
                            </Box>
                        ) : all.length <= 0 ? (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minHeight="100%"
                                width="100%"
                                textAlign="center"
                            >
                                <Box>
                                    <NoCommentsIcon />
                                    <Text
                                        fontSize="14px"
                                        color="textSecondaryDarkBlue"
                                    >
                                        {' '}
                                        No comments yet!
                                    </Text>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
