import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { debugLog } from 'src/helpers'
import styled from 'styled-components'
import { PredectiveOutput } from '../predective-output/predective-output'
import { SearchIco } from '../svg-icons'
import { Box, Button, Input } from '../utility'

const StyledSearch = styled(Box)`
    transition: 0.3s ease-in-out;
    & input {
        transition: 0.3s ease-in-out;
    }
`

type DataList = {
    text: string
    state: Teams | Collections | OrganizationMembers | any
    path: string
}

export const Search = ({
    dataList,
    placeholder = 'Search Teams',
    onChange,
}: {
    dataList: DataList[]
    placeholder?: string
    onChange?: (query: string) => void
}) => {
    const [expanded, setExpanded] = useState(false)
    const inputRef = useRef<HTMLInputElement>()
    const dropdown = useRef<any>(null)
    const [query, setQuery] = useState('')
    const navigate = useNavigate()

    function navigateSearch(dataList: DataList) {
        debugLog(dataList)
        navigate(dataList.path, {
            state: dataList.state,
        })
    }

    function disableDropdown(event: Event) {
        if (dropdown !== null && dropdown.current !== null) {
            const isClickInsideElement = dropdown.current.contains(event.target)
            if (!isClickInsideElement) {
                setExpanded(false)
            }
        }
    }

    if (expanded) {
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }
    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    return (
        <StyledSearch
            ref={dropdown}
            className="search__input"
            position="relative"
            bg={expanded ? 'gray250' : 'transparent'}
            borderRadius="4px"
            display="flex"
            alignItems="center"
            p="4px"
            gridColumnGap="4px"
        >
            <Button
                className="search__input"
                onClick={() => setExpanded(true)}
                display="flex"
                alignItems="center"
                bg="transparent"
                border="none"
                p="0px"
            >
                <SearchIco id="search__input" color="#778CA2" />
            </Button>
            <Input
                autoComplete="false"
                className="search__input"
                value={query}
                ref={inputRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setQuery(e.target.value)
                    onChange && onChange(e.target.value)
                }}
                onFocus={() => setExpanded(true)}
                width={expanded ? '150px' : '0px'}
                textAlign="left"
                border="none"
                bg="transparent"
                placeholder={placeholder}
            />
            {expanded && (
                <PredectiveOutput
                    className="search__input"
                    width="auto"
                    top="35px"
                    objectKey="text"
                    target={inputRef}
                    showRecentSearches={false}
                    text={query}
                    onClick={(data: DataList) => navigateSearch(data)}
                    dataList={dataList}
                />
            )}
        </StyledSearch>
    )
}
