import create from 'zustand'

const initialProductForm: {
    name: string
    description: string
    collection: any | null
    due_date: Date | null
    team: any | null
    approver_due_date: Date | null
    manager: any | null
    tags: Array<{ id: string; name: string; color: string }>
} = {
    name: '',
    description: '',
    collection: null,
    due_date: null,
    team: null,
    approver_due_date: null,
    manager: null,
    tags: [],
}

export const useCreateProductFormStore = create((set: Function) => ({
    productForm: initialProductForm,
    setProductForm: (data: any) => {
        set((state: any) => ({
            ...state,
            productForm: data,
        }))
    },
    clearProductForm: () => {
        set((state: any) => ({
            ...state,
            productForm: initialProductForm,
        }))
    },
    updateProductName: (updatedName: string) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                name: updatedName,
            },
        }))
    },
    updateProductDescription: (updatedDescription: string) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                description: updatedDescription,
            },
        }))
    },
    updateProductDueDate: (date: Date) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                due_date: date,
            },
        }))
    },
    updateProductApproverDueDate: (date: Date) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                approver_due_date: date,
            },
        }))
    },
    updateProductTags: ({
        type,
        payload,
    }: {
        type: 'ADD' | 'DELETE' | 'UPDATE'
        payload: any
    }) => {
        if (type === 'ADD') {
            set((state: any) => ({
                ...state,
                productForm: {
                    ...state.productForm,
                    tags: [...state.productForm.tags, payload],
                },
            }))
        } else if (type === 'DELETE') {
            set((state: any) => ({
                ...state,
                productForm: {
                    ...state.productForm,
                    tags: state.productForm.tags.filter(
                        (tag: any) => tag.name !== payload
                    ),
                },
            }))
        } else if (type === 'UPDATE') {
            set((state: any) => ({
                ...state,
                productForm: {
                    ...state.productForm,
                    tags: state.productForm.tags.map((tag: any) =>
                        tag.id === payload.id ? payload : tag
                    ),
                },
            }))
        }
    },
    updateProductTeam: (team: any) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                team: team,
            },
        }))
    },
    updateProductManager: (manager: any) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                manager: manager,
            },
        }))
    },
    updateProductCollection: (collection: any) => {
        set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                collection: collection,
            },
        }))
    },
}))
