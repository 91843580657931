export const sortObject: Record<'due_date' | 'name' | 'created_at', object> = {
    due_date: {
        due_date: 'asc',
    },
    name: {
        name: 'asc',
    },
    created_at: {
        id: 'asc', // Sorting using ID instead of created_at because the three core todos are basically created at same exact time.
    },
}
