export function calculateOffsets(
    offsetX: number,
    offsetY: number,
    parentContainer: HTMLElement | null,
    COMMENT_CONTAINER_HALF_WIDTH: number,
    COMMENT_CONTAINER_HALF_HEIGHT: number
): { left: string; top?: string; bottom?: string } {
    if (!parentContainer) return { left: '0px', top: '0px' } // Ensure parentContainer is available

    // Calculate horizontal overflow
    const distanceFromOffsetXToRightEdge = parentContainer.clientWidth - offsetX
    const isOverflowingInRight =
        distanceFromOffsetXToRightEdge < COMMENT_CONTAINER_HALF_WIDTH
    const isOverflowingInLeft = offsetX < COMMENT_CONTAINER_HALF_WIDTH

    let containerLeftOffset: string
    if (isOverflowingInRight) {
        containerLeftOffset = `${
            -COMMENT_CONTAINER_HALF_WIDTH -
            (COMMENT_CONTAINER_HALF_WIDTH - distanceFromOffsetXToRightEdge)
        }px`
    } else if (isOverflowingInLeft) {
        containerLeftOffset = `${-offsetX}px`
    } else {
        containerLeftOffset = '-192px' // Default offset when not overflowing
    }

    // Calculate vertical overflow
    const distanceFromOffsetYToBottomEdge =
        parentContainer.clientHeight - offsetY
    const isOverflowingAtBottom =
        distanceFromOffsetYToBottomEdge < COMMENT_CONTAINER_HALF_HEIGHT

    let verticalOffset: { top?: string; bottom?: string } = {}
    if (isOverflowingAtBottom) {
        verticalOffset.bottom = '12px'
    } else {
        verticalOffset.top = '30px'
    }

    return { left: containerLeftOffset, ...verticalOffset }
}
