import { memo } from 'react'
import {
    FBX,
    GLB,
    JPG,
    OBJ,
    PDF,
    PNG,
    SVG,
    ZFAB,
    ZPRJ,
} from 'src/components/svg-icons'
import {
    AI,
    BLEND,
    BW,
    C4D,
    DXF,
    GLTF,
    HIP,
    MA,
    MAX,
    MB,
    PLASTICITY,
    PLY,
    PPTX,
    PSD,
    S3D,
    SBS,
    SBSAR,
    SPP,
    SPROJ,
    SSA,
    STL,
    TIF,
    UNKNOWN,
    XLSX,
    XLX,
    ZIP,
    ZPR,
    ZTL,
} from 'src/components/svg-icons/folder-icons'
import {
    AI_LG,
    BLEND_LG,
    BW_LG,
    C4D_LG,
    DXF_LG,
    FBX_LG,
    GLB_LG,
    GLTF_LG,
    HIP_LG,
    JPEG_LG,
    JPG_LG,
    MAX_LG,
    MA_LG,
    MB_LG,
    OBJ_LG,
    PDF_LG,
    PLASTICITY_LG,
    PLY_LG,
    PNG_LG,
    PPTX_LG,
    PSD_LG,
    S3D_LG,
    SBSAR_LG,
    SBS_LG,
    SPP_LG,
    SPROJ_LG,
    SSA_LG,
    STL_LG,
    SVG_LG,
    TIF_LG,
    UNKNOWN_LG,
    XLSX_LG,
    XLX_LG,
    ZFAB_LG,
    ZIP_LG,
    ZPRJ_LG,
    ZPR_LG,
    ZTL_LG,
} from 'src/components/svg-icons/folder-icons/large-icons'
import { uuid } from 'src/helpers'

export const ExtensionIcon = memo(
    ({ type, size }: { type: string; size: 'sm' | 'lg' }) => {
        if (size === 'lg') {
            switch (type) {
                case 'jpg':
                    return <JPG_LG key={uuid()} />
                case 'jpeg':
                    return <JPEG_LG key={uuid()} />
                case 'svg':
                    return <SVG_LG key={uuid()} />
                case 'pdf':
                    return <PDF_LG key={uuid()} />
                case 'obj':
                    return <OBJ_LG key={uuid()} />
                case 'zfab':
                    return <ZFAB_LG key={uuid()} />
                case 'glb':
                    return <GLB_LG key={uuid()} />
                case 'gltf':
                    return <GLTF_LG key={uuid()} />
                case 'zprj':
                    return <ZPRJ_LG key={uuid()} />
                case 'fbx':
                    return <FBX_LG key={uuid()} />
                case 'ai':
                    return <AI_LG key={uuid()} />
                case 'zip':
                    return <ZIP_LG key={uuid()} />
                case 's3d':
                    return <S3D_LG key={uuid()} />
                case 'spp':
                    return <SPP_LG key={uuid()} />
                case 'sbs':
                    return <SBS_LG key={uuid()} />
                case 'ssa':
                    return <SSA_LG key={uuid()} />
                case 'max':
                    return <MAX_LG key={uuid()} />
                case 'zpr':
                    return <ZPR_LG key={uuid()} />
                case 'ztl':
                    return <ZTL_LG key={uuid()} />
                case 'c4d':
                    return <C4D_LG key={uuid()} />
                case 'ply':
                    return <PLY_LG key={uuid()} />
                case 'hip':
                    return <HIP_LG key={uuid()} />
                case 'dxf':
                    return <DXF_LG key={uuid()} />
                case 'png':
                    return <PNG_LG key={uuid()} />
                case 'sbsar':
                    return <SBSAR_LG key={uuid()} />
                case 'bw':
                    return <BW_LG key={uuid()} />
                case 'sproj':
                    return <SPROJ_LG key={uuid()} />
                case 'pptx':
                    return <PPTX_LG key={uuid()} />
                case 'xlsx':
                    return <XLSX_LG key={uuid()} />
                case 'xlx':
                    return <XLX_LG key={uuid()} />
                case 'psd':
                    return <PSD_LG key={uuid()} />
                case 'blend':
                    return <BLEND_LG key={uuid()} />
                case 'ma':
                    return <MA_LG key={uuid()} />
                case 'mb':
                    return <MB_LG key={uuid()} />
                case 'tif':
                    return <TIF_LG key={uuid()} />
                case 'stl':
                    return <STL_LG key={uuid()} />
                case 'plasticity':
                    return <PLASTICITY_LG key={uuid()} />
                default:
                    return <UNKNOWN_LG key={uuid()} />
            }
        } else if (size === 'sm') {
            switch (type) {
                case 'jpg':
                case 'jpeg':
                    return <JPG id={uuid()} />
                case 'png':
                    return <PNG id={uuid()} />
                case 'svg':
                    return <SVG id={uuid()} />
                case 'pdf':
                    return <PDF id={uuid()} />
                case 'obj':
                    return <OBJ id={uuid()} />
                case 'zfab':
                    return <ZFAB id={uuid()} />
                case 'glb':
                    return <GLB id={uuid()} />
                case 'gltf':
                    return <GLTF id={uuid()} />
                case 'zprj':
                    return <ZPRJ id={uuid()} />
                case 'fbx':
                    return <FBX id={uuid()} />
                case 'ai':
                    return <AI id={uuid()} />
                case 'zip':
                    return <ZIP id={uuid()} />
                case 's3d':
                    return <S3D id={uuid()} />
                case 'spp':
                    return <SPP id={uuid()} />
                case 'sbs':
                    return <SBS id={uuid()} />
                case 'ssa':
                    return <SSA id={uuid()} />
                case 'max':
                    return <MAX id={uuid()} />
                case 'zpr':
                    return <ZPR id={uuid()} />
                case 'ztl':
                    return <ZTL id={uuid()} />
                case 'c4d':
                    return <C4D id={uuid()} />
                case 'ply':
                    return <PLY id={uuid()} />
                case 'hip':
                    return <HIP id={uuid()} />
                case 'dxf':
                    return <DXF id={uuid()} />
                case 'pptx':
                    return <PPTX id={uuid()} />
                case 'xlsx':
                    return <XLSX id={uuid()} />
                case 'xlx':
                    return <XLX id={uuid()} />
                case 'psd':
                    return <PSD id={uuid()} />
                case 'blend':
                    return <BLEND id={uuid()} />
                case 'ma':
                    return <MA id={uuid()} />
                case 'mb':
                    return <MB id={uuid()} />
                case 'tif':
                    return <TIF id={uuid()} />
                case 'stl':
                    return <STL id={uuid()} />
                case 'sbsar':
                    return <SBSAR id={uuid()} />
                case 'sproj':
                    return <SPROJ id={uuid()} />
                case 'plasticity':
                    return <PLASTICITY />
                case 'bw':
                    return <BW />
                default:
                    return <UNKNOWN id={uuid()} />
            }
        }
        return <UNKNOWN_LG />
    },
    (
        x: { type: string; size: 'sm' | 'lg' },
        y: { type: string; size: 'sm' | 'lg' }
    ) => {
        // return true
        return JSON.stringify(x) === JSON.stringify(y)
    }
)
