import { useEffect, useState } from 'react'
import SubtodoCard from 'src/components-v2/todo/subtodo-card/subtodo-card'
import SubtodoEmptyState from 'src/components-v2/todo/subtodo-card/subtodo-empty-state'
import TodoHeader from 'src/components-v2/todo/todo-header/todo-header'
import { Box } from 'src/components/utility'
import { GetTodo } from '../../../services/graphql/entities/todo/todo.queries'
import { useMutation, useQuery } from '@apollo/client'
import FilePreviewSection from '../file-preview-section/file-preview-section'
import { useTodoSectionContext } from './todo-section.context'
import { SubtodoFocussedView } from '../subtodo-card/subtodo-focussed-view'
import HeaderLoader from 'src/components-v2/header-loader/header-loader'
import ChooseTemplate from 'src/components/drawer/todo-tab/choose-template-section/choose-template'
import type { DragEndEvent } from '@dnd-kit/core'
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'

import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
} from '@dnd-kit/sortable'
import Masonry from 'react-responsive-masonry'
import {
    DeleleSubtodoSequences,
    UpdateSubtodoSequences,
} from '../../../services/graphql/entities/todo/todo.mutation'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { GetTodoQuery, GetTodoQueryVariables } from 'src/generated/graphql'
import { CardLoader } from 'src/components/collections/card-loader'

function TodoSection({
    todoId,
    isSplitMode,
    isCompareSection,
    activeSplitSectionId,
    onClick,
}: {
    todoId: number
    isSplitMode?: boolean
    isCompareSection?: boolean
    onClick?: () => void
    activeSplitSectionId?: number
}) {
    const { activeMode, setActiveMode, activeFileId, setIsSplitMode } =
        useTodoSectionContext()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const [focussedSubtodoId, setFocussedSubtodoId] = useState<number | null>(
        Number(searchParams.get('subtodoId')) || null
    )
    const {
        data: todoData,
        loading,
        refetch: refetchTodo,
    } = useQuery<GetTodoQuery, GetTodoQueryVariables>(GetTodo, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: todoId,
        },
        onCompleted: (data) => {
            //If todo is not found, redirect to 404
            if (!data.todos_by_pk) {
                if (!isCompareSection) {
                    navigate('/404', { replace: true })
                } else {
                    searchParams.delete('compareId')
                    searchParams.delete('compareEntity')
                    setSearchParams(searchParams, { replace: true })
                }
            }
        },
    })

    // Scroll to the active file when the active mode is sections
    useEffect(() => {
        if (isSplitMode && activeMode === 'sections' && activeFileId) {
            const element = document.getElementById(
                `file_display_${activeFileId}`
            )
            if (element) {
                element.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                })
            }
        }
    }, [isSplitMode, activeMode, activeFileId])

    const todo = todoData?.todos_by_pk
    const subtodos = todo?.sub_todos ? [...todo?.sub_todos] : []

    const isTemplateChosen = (todo && subtodos?.length > 0) || false

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
    const [updateSubtodoSequencesMutation] = useMutation(UpdateSubtodoSequences)
    const [deleteSubtodoSequencesMutation] = useMutation(DeleleSubtodoSequences)
    const [subtodoSequences, setSubtodoSequences] = useState(
        subtodos.map((subtodo) => ({
            sequence: subtodo.sub_todo_sequences?.[0]?.sequence,
            id: subtodo.id,
        })) || []
    )

    useEffect(() => {
        if (todo) {
            setSubtodoSequences(
                subtodos.map((subtodo) => ({
                    sequence: subtodo.sub_todo_sequences?.[0]?.sequence,
                    id: subtodo.id,
                }))
            )
        }
    }, [todo])

    // Set the split mode when the prop changes
    useEffect(() => {
        if (isSplitMode) {
            setIsSplitMode(isSplitMode)
        } else {
            setIsSplitMode(false)
        }

        return () => setIsSplitMode(false)
    }, [isSplitMode])

    const sequenceMapping = subtodoSequences.reduce(
        (
            acc: Record<number, number>,
            { id, sequence }: { id: number; sequence: number }
        ) => {
            acc[id] = sequence
            return acc
        },
        {}
    )

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event
        if (over && active.id !== over.id && !!subtodos) {
            const oldIndex = subtodos.findIndex((s) => s.id === active.id)
            const newIndex = subtodos.findIndex((s) => s.id === over.id)
            const updatedSubtodos = arrayMove(subtodos, oldIndex, newIndex).map(
                (subtodo, index) => ({
                    sequence: index,
                    sub_todo_id: subtodo.id,
                })
            )
            setSubtodoSequences(
                updatedSubtodos.map((s) => ({
                    id: s.sub_todo_id,
                    sequence: s.sequence,
                }))
            )
            deleteSubtodoSequencesMutation({
                variables: {
                    subtodoIds: subtodoSequences.map((s) => s.id),
                },
                onCompleted: () => {
                    updateSubtodoSequencesMutation({
                        variables: { objects: updatedSubtodos },
                        onCompleted: () => {
                            refetchTodo()
                        },
                    })
                },
            })
        }
    }

    // If subtodo count change, set active mode to sections (mainly when user changes the template)
    // When the view changes from compare to single view, set the active mode to sections
    useEffect(() => {
        if (activeMode === 'file_preview') {
            setActiveMode('sections')
        }
    }, [subtodos.length, isSplitMode])

    return (
        <Box
            display="flex"
            flexDirection="column"
            width={'100%'}
            borderRight={
                isSplitMode && !isCompareSection ? '4px solid' : 'none'
            }
            borderColor="primary"
            overflow={'auto'}
            className="scrollbar_none"
            height={'100%'}
            onClick={onClick}
            id={`todo_section`}
        >
            {todo ? (
                <TodoHeader
                    todo={todo}
                    refetchTodo={refetchTodo}
                    isSplitMode={isSplitMode}
                    isTemplateChosen={isTemplateChosen}
                    isCompareSection={isCompareSection}
                    subtodos={subtodos}
                />
            ) : loading ? (
                <HeaderLoader />
            ) : null}
            {todo ? (
                activeMode === 'sections' ? (
                    <>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            modifiers={[restrictToWindowEdges]}
                        >
                            <SortableContext
                                items={subtodoSequences}
                                strategy={rectSortingStrategy}
                            >
                                <Box
                                    p="24px"
                                    borderTop="1px solid"
                                    style={{
                                        borderRadius: '16px 16px 0px 0px',
                                    }}
                                    borderColor="secondaryLighterBlue"
                                    bg="#FAFCFF"
                                >
                                    {isTemplateChosen ? (
                                        subtodos?.length > 0 ? (
                                            <Masonry
                                                columnsCount={
                                                    isSplitMode ? 1 : 2
                                                }
                                                gutter="24px"
                                            >
                                                {subtodos
                                                    .sort((a, b) => {
                                                        return (
                                                            sequenceMapping[
                                                                a.id
                                                            ] -
                                                            sequenceMapping[
                                                                b.id
                                                            ]
                                                        )
                                                    })
                                                    .map((subtodo) => (
                                                        <SubtodoCard
                                                            subtodo={subtodo}
                                                            refetchTodo={() =>
                                                                refetchTodo({
                                                                    id: todoId,
                                                                })
                                                            }
                                                            isTechPack={
                                                                todo.types ===
                                                                'techPack'
                                                            }
                                                            onFocus={() => {
                                                                setFocussedSubtodoId(
                                                                    subtodo.id
                                                                )
                                                            }}
                                                            todoId={todo.id}
                                                            productId={
                                                                todo
                                                                    .product_variant
                                                                    ?.id
                                                            }
                                                            key={subtodo.id}
                                                        />
                                                    ))}
                                                <SubtodoEmptyState
                                                    todoId={todo.id}
                                                    refetchTodo={refetchTodo}
                                                    totalSubtodos={
                                                        subtodos.length
                                                    }
                                                />
                                            </Masonry>
                                        ) : (
                                            <SubtodoEmptyState
                                                todoId={todo.id}
                                                refetchTodo={refetchTodo}
                                                totalSubtodos={0}
                                            />
                                        )
                                    ) : (
                                        <ChooseTemplate
                                            todoId={todoId}
                                            refetchTodo={refetchTodo}
                                        />
                                    )}
                                </Box>
                            </SortableContext>
                        </DndContext>
                    </>
                ) : activeMode === 'file_preview' ? (
                    <FilePreviewSection
                        isSplitView={true}
                        isCompareSection={isCompareSection}
                        productId={todo?.product_variant?.product_id}
                        enableKeyboardNav={activeSplitSectionId === todoId}
                    />
                ) : null
            ) : loading ? (
                <CardLoader cards={3} skeletonCount={3} />
            ) : null}
            {!!focussedSubtodoId && todo && (
                <SubtodoFocussedView
                    activeSubtodoId={focussedSubtodoId}
                    onClose={() => {
                        setFocussedSubtodoId(null)
                        searchParams.delete('subtodoId')
                        setSearchParams('subtodoId')
                    }}
                    subtodos={todo.sub_todos}
                    refetchTodo={refetchTodo}
                    setActiveSubtodoId={(subtodoId) => {
                        setFocussedSubtodoId(subtodoId)
                    }}
                    todoId={todo.id}
                    isTechPack={todo.types === 'techPack'}
                />
            )}
        </Box>
    )
}

export default TodoSection
