import { gql } from '@apollo/client'

/**
 * @type Mutation
 * @requires admin_auth_token
 * @params email
 * @params role [ Admin | Manager | Designer ]
 * @returns email | role
 */

export const InviteUser = gql`
    mutation InviteUser($objects: [invitations_insert_input!] = objects) {
        insert_invitations(objects: $objects) {
            returning {
                email
                role
                id
                token
            }
        }
    }
`

export const UpdateInvitedUserRole = gql`
    mutation UpdateInvitedUserRole(
        $_eq: bigint = ""
        $role: user_roles_enum = admin
    ) {
        update_invitations(
            where: { id: { _eq: $_eq } }
            _set: { role: $role }
        ) {
            affected_rows
        }
    }
`
