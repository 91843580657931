import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box } from 'src/components/utility'
import CollectionHeader from './collection-header'
import type { DueDateType, ManagerType } from '../collections/collections.types'
import CollectionSortingAndFilter from './collection-sorting-and-filter'
import HeaderLoader from 'src/components-v2/header-loader/header-loader'
import useFetchCollection from './useFetchCollection.hook'
import { Slider } from 'src/components/slider/slider'
import CollectionSliderElement from './collection-slider-element'
import ProductVariantFilterContainer from 'src/components-v2/product-variants/product-list-variant-filter-container/product-variant-filter-container'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { CardLoader } from 'src/components/collections/card-loader'
import { ENTITIES } from 'src/helpers/enums'
import TodoListCard from 'src/components-v2/entity-list-card/todo-list.card/todo-list.card'

function Collection() {
    const { id: collectionId } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [dueDate, setDueDate] = useState<DueDateType>(null)
    const [managers, setManagers] = useState<Array<ManagerType>>([])
    const { tab: currentTab = 'all', group: activeGroup = 'products' } =
        Object.fromEntries(searchParams.entries())

    if (!collectionId || isNaN(Number(collectionId))) {
        navigate('/404')
        return null
    }

    const {
        collection,
        isCollectionLoading,
        refetchCollection,
        childEntites,
        isChildEntitiesLoading,
    } = useFetchCollection({
        collectionId: Number(collectionId),
        dueDate,
        managers,
        entitiesGroupBy: activeGroup as 'products' | 'todos',
    })

    const currentTabEntities = childEntites?.filter((entity: any) => {
        if (currentTab === 'all') {
            return true
        } else {
            // Filter products based on the current tab and variant statuses. If the product has atleast one variant with the status of the current tab, then the product will be displayed.
            if (activeGroup === 'products') {
                return entity.product_variants.some(
                    (variant: any) => variant.status === currentTab
                )
            }
            return entity.status === currentTab
        }
    })

    const resourcesCount = childEntites?.reduce(
        (acc: any, entity: any) => {
            if (activeGroup === 'products') {
                entity.product_variants.forEach((variant: any) => {
                    acc['all'] = acc['all'] + 1
                    acc[variant.status] = acc[variant.status] + 1
                })
            } else {
                acc['all'] = acc['all'] + 1
                acc[entity.status] = acc[entity.status] + 1
            }
            return acc
        },
        {
            all: 0,
            new: 0,
            inProgress: 0,
            inReview: 0,
            requestedForResubmission: 0,
            complete: 0,
        }
    ) || {
        all: 0,
        new: 0,
        inProgress: 0,
        inReview: 0,
        requestedForResubmission: 0,
        complete: 0,
    }

    return (
        <Box
            height="calc(100vh - 60px)"
            display="grid"
            gridTemplateRows="auto 1fr"
            bg="white"
        >
            {collection ? (
                <CollectionHeader
                    key={collection?.id}
                    collection={collection}
                    refetchCollection={refetchCollection}
                />
            ) : isCollectionLoading ? (
                <HeaderLoader />
            ) : null}
            <Box
                bg="#F8FAFB"
                display="grid"
                height="100%"
                overflow="hidden"
                gridTemplateRows="33px 1fr"
                gridGap="20px"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                py="20px"
                px="24px"
            >
                <CollectionSortingAndFilter
                    collectionId={collection?.id}
                    collectionName={collection?.name || ''}
                    setDueDate={setDueDate}
                    setManagers={setManagers}
                    teamId={collection?.team?.id}
                    dueDate={dueDate}
                    managers={managers}
                />
                <Box
                    maxHeight="100%"
                    overflow="hidden"
                    display="grid"
                    gridTemplateRows="33.5px 1fr"
                >
                    <Slider
                        element={
                            <CollectionSliderElement
                                currentTab={currentTab}
                                resourcesCount={resourcesCount}
                                totalProducts={resourcesCount.all}
                            />
                        }
                    />
                    <Box overflowY="auto" className="scrollbar_none">
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="8px"
                            mt="8px"
                        >
                            {currentTabEntities ? (
                                currentTabEntities.length > 0 ? (
                                    currentTabEntities.map((entity: any) => {
                                        if (activeGroup === 'products') {
                                            return (
                                                <ProductVariantFilterContainer
                                                    key={entity.id}
                                                    product={entity}
                                                    refetch={refetchCollection}
                                                    status={currentTab}
                                                />
                                            )
                                        }
                                        return (
                                            <TodoListCard
                                                key={entity.id}
                                                todo={{
                                                    ...entity,
                                                    dueDate: entity.due_date,
                                                    product: {
                                                        id: entity
                                                            .product_variant.id,
                                                        name: entity
                                                            .product_variant
                                                            .name,
                                                        coverPic:
                                                            entity
                                                                .product_variant
                                                                .other_data
                                                                ?.cover_pic,
                                                    },
                                                    collection: {
                                                        id: entity
                                                            .product_variant
                                                            ?.collection?.id,
                                                        name: entity
                                                            .product_variant
                                                            ?.collection?.name,
                                                        manager_id:
                                                            entity
                                                                .product_variant
                                                                ?.collection
                                                                ?.manager_id,
                                                    },
                                                    coverPic:
                                                        entity.other_data
                                                            ?.cover_pic,
                                                }}
                                                isFullCard={true}
                                                onClick={() => {}}
                                                navigateTo={`/todo/${entity.id}`}
                                                isCompareSection={false}
                                            />
                                        )
                                    })
                                ) : (
                                    <EntityEmptyState
                                        entityName={
                                            activeGroup === 'products'
                                                ? ENTITIES.products
                                                : ENTITIES.todos
                                        }
                                    />
                                )
                            ) : isChildEntitiesLoading ? (
                                <CardLoader />
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Collection
