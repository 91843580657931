import React, { useMemo, useState } from 'react'
import { CrossSm } from '../svg-icons'
import { Box, Button, Text } from '../utility'
import CopyIco from '../svg-icons/copy-ico'
import { toast } from 'react-toastify'
import { getContrastTextColor } from 'src/helpers/get-contrast-color'
import { extractRGB } from 'src/helpers/extract-rbg-values'

export const Tag = ({
    fontColor,
    element,
    color,
    action,
    onClick,
    enableCopy,
}: {
    element?: string | JSX.Element
    color: string
    action?: boolean
    onClick?: () => void
    fontColor?: string
    enableCopy?: boolean
}) => {
    const [isHovered, setIsHovered] = useState(false)

    function onClickHandler() {
        onClick && onClick()
    }

    function onCopyColor() {
        if (element && typeof element === 'string') {
            navigator.clipboard.writeText(extractRGB(element))
            toast('Color copied to clipboard', {
                className: 'custom-toaster toaster-success',
            })
        }
    }

    const textColor = useMemo(() => getContrastTextColor(color), [color])
    const title = typeof element === 'string' ? element : ''

    return (
        <Box
            px="8px"
            width="max-content"
            height="22px"
            borderRadius="20px"
            py="4px"
            display="flex"
            fontFamily="Rubik"
            fontSize="12px"
            gridGap="4px"
            color={fontColor || 'textTags'}
            alignItems="center"
            justifyContent="space-between"
            bg={color}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && enableCopy ? (
                <Button
                    onClick={onCopyColor}
                    bg="transparent"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CopyIco color={textColor} />
                </Button>
            ) : (
                <Box width="16px"></Box>
            )}
            <Text my="0px" color={textColor} title={title}>
                {element}
            </Text>
            {action && (
                <Button
                    onClick={onClickHandler}
                    bg="transparent"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="none"
                >
                    <CrossSm color={textColor} />
                </Button>
            )}
        </Box>
    )
}
