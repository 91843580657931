import { Line } from 'rc-progress'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DeleteIco, DownloadIco, PlusIco, Tick } from '../svg-icons'
import { Box, Button, Image, Text } from '../utility'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'

export const FileDisplay = ({
    url,
    name,
    fileId,
    onDelete,
    onSelect,
    onClick,
    isDownloadable,
    width,
    height,
    bg = '#ffffff',
    trayColor = '#ffffff',
    openFileInNewTab = true,
    redirectionUrl = '/file-preview',
    showLoader = false,
    percentage = '0',
    selected = false,
    librarySelect = false,
    thumbnail,
    params = [],
    callbackUrl = '/',
    redirect = true,
    isUrlRequired = true,
    minWidth,
    isDeletePermitted = false,
    onDragStart,
    forceDelete = true,
}: {
    url: string
    name: string
    fileId?: string | number | undefined
    onDelete?: () => void
    onSelect?: (bool: boolean) => void
    onClick?: () => void
    isDownloadable?: boolean
    width?: string
    height?: string
    bg?: string
    trayColor?: string
    openFileInNewTab?: boolean
    redirectionUrl?: string
    showLoader?: boolean
    percentage?: string
    selected?: boolean
    librarySelect?: boolean
    thumbnail?: string
    callbackUrl?: string
    params?: { key: string; value: string }[]
    redirect?: boolean
    isUrlRequired?: boolean
    minWidth?: string
    isDeletePermitted?: boolean
    forceDelete?: boolean
    onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
}) => {
    const [deleteHover, setDeleteHover] = useState(false)
    const type = name?.split('.')[name.split('.').length - 1].toLowerCase()
    const fileName =
        name.split('.')[0].length > 7
            ? name.split('.')[0].slice(0, 7) + '...' + type
            : name
    const navigate = useNavigate()
    const _location = useLocation()

    let _newCallBackUrl: string = window.location.pathname
    const _urlParams = window.location.search
    _newCallBackUrl = `${_newCallBackUrl}${_urlParams}`

    const openInNewTab = (url: string) => {
        const paramsList = [
            {
                key: 'url',
                value: encodeURIComponent(url),
            },
            {
                key: 'name',
                value: encodeURIComponent(name),
            },
        ]
        let paramsString = [...paramsList, ...params]
            .map(({ key, value }, index) => {
                if (index === 0) {
                    return `?${key}=${value}`
                } else {
                    return `&${key}=${value}`
                }
            })
            .join('')
        let _url = `${redirectionUrl}${paramsString}`

        if (fileId) {
            _url += `&fileId=${fileId}&${libraryDefaultKeys.showFileDetails}=${fileId}`
        }
        if (!isUrlRequired) {
            _url = `${redirectionUrl}`
            return navigate(_url)
        }

        if (openFileInNewTab) {
            _url += `&newTab=1`
            window.open(
                window.location.origin +
                    `${_url}` +
                    `&callback_url=${_newCallBackUrl}`,
                '_blank'
            )
        } else {
            _url += `&newTab=0`
            navigate(_url, {
                state: {
                    from: _location,
                },
            })
            if (
                !name.includes('.png') &&
                !name.includes('.jpeg') &&
                !name.includes('.jpg') &&
                !name.includes('.xlx') &&
                !name.includes('.xlsx') &&
                !name.includes('.pdf')
            ) {
                window.location.reload()
            }
        }
    }
    return (
        <>
            <Box
                onMouseLeave={() => setDeleteHover(false)}
                onMouseEnter={() => setDeleteHover(true)}
                overflow="hidden"
                border="solid"
                bg={bg}
                alignItems="center"
                borderWidth={selected ? 2 : 1}
                borderColor={
                    selected && librarySelect
                        ? '#203C5A'
                        : selected
                        ? '#87b7f1'
                        : 'gray250'
                }
                borderRadius="4px"
                position="relative"
                id={`file_display_${name.split('.')[0]}`}
                width={width ? width : '120px'}
                height={height ? height : '120px'}
                onClick={() => {
                    onClick && onClick
                    redirect && openInNewTab(url)
                }}
                minWidth={minWidth && minWidth}
                draggable={!!onDragStart}
                onDragStart={(e) => {
                    onDragStart && onDragStart(e)
                }}
            >
                {selected && !librarySelect && (
                    <Box
                        width="24px"
                        height="24px"
                        display="flex"
                        alignItems="center"
                        bg="links"
                        position="absolute"
                        justifyContent="center"
                        borderRadius="50%"
                        top="4px"
                        right="4px"
                    >
                        <Tick color="#fff" isDone={true}></Tick>
                    </Box>
                )}
                {type === 'jpeg' || type === 'jpg' || type === 'png' ? (
                    <Image
                        width="100%"
                        height="auto"
                        style={{
                            height: 'calc(100% - 28px)',
                            objectFit: 'cover',
                        }}
                        loading="lazy"
                        src={thumbnail || url}
                    />
                ) : (
                    <Box
                        width="100%"
                        height="100%"
                        maxHeight="calc(100% - 28px)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            <ExtensionIcon
                                size="lg"
                                type={name
                                    ?.split('.')
                                    [name.split('.').length - 1].toLowerCase()}
                            />
                        }
                    </Box>
                )}

                {showLoader && (
                    <Box
                        zIndex={5}
                        bottom="24px"
                        position="absolute"
                        width="100%"
                    >
                        <Line
                            percent={Number(percentage)}
                            strokeWidth={2}
                            trailWidth={2}
                            style={{ width: '100%' }}
                            strokeColor="#39A407"
                            trailColor="#EBECEF10"
                        />
                    </Box>
                )}
                <Box
                    border="solid"
                    bg={trayColor}
                    borderWidth={1}
                    borderColor="gray250"
                    p="8px"
                    gridColumnGap="4px"
                    bottom="0px"
                    display="flex"
                    alignItems="center"
                    height="28px"
                    width="100%"
                    position="absolute"
                    zIndex={1}
                    className={redirect ? 'cursor-pointer' : ''}
                    onClick={() => redirect && openInNewTab(url)}
                >
                    {
                        <ExtensionIcon
                            size="sm"
                            type={name
                                ?.split('.')
                                [name.split('.').length - 1].toLowerCase()}
                        />
                    }

                    {!showLoader && (
                        <Text
                            className="overflow__elipses"
                            fontSize="10px"
                            my="0px"
                            fontFamily="Rubik"
                            color="textSecondaryDarkBlue"
                            ml="8px"
                            title={name}
                        >
                            {fileName}
                        </Text>
                    )}

                    {showLoader && (
                        <>
                            <Text
                                className="overflow__elipses"
                                fontSize="10px"
                                my="0px"
                                fontFamily="Rubik"
                                color="textSecondaryDarkBlue"
                                ml="8px"
                            >
                                {Number(percentage) === 100
                                    ? 'Uploaded'
                                    : fileName}
                            </Text>
                        </>
                    )}
                </Box>
                {deleteHover &&
                    (isDownloadable || onDelete || onSelect) &&
                    (!showLoader || forceDelete) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="absolute"
                            top="0px"
                            left="0px"
                            width="100%"
                            height="100%"
                            bg={deleteHover ? '#02214360' : 'transparent'}
                            gridColumnGap="16px"
                            id="hover-file"
                        >
                            {deleteHover &&
                                onDelete &&
                                isDeletePermitted &&
                                showLoader &&
                                Number(percentage) === 100 && (
                                    <Button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onDelete()
                                        }}
                                        border="none"
                                        borderRadius="50%"
                                        width="32px"
                                        height="32px"
                                        bg="#02214320"
                                    >
                                        <DeleteIco color="#fff"></DeleteIco>
                                    </Button>
                                )}
                            {deleteHover && isDownloadable && (
                                <a
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '32px',
                                        height: '32px',
                                        border: 'none',
                                        borderRadius: '50%',
                                        textDecoration: 'none',
                                        backgroundColor: '#02214320',
                                    }}
                                    onClick={(e) => e.preventDefault()}
                                    type="button"
                                    href={url}
                                    download={true}
                                >
                                    <DownloadIco color="#fff" />
                                </a>
                            )}
                            {deleteHover && onSelect && (
                                <Button
                                    type="button"
                                    onClick={() => {
                                        onSelect(!selected)
                                    }}
                                    border="none"
                                    borderRadius="50%"
                                    width="32px"
                                    height="32px"
                                    bg="#02214320"
                                >
                                    {selected ? (
                                        <DeleteIco color="#fff"></DeleteIco>
                                    ) : (
                                        <PlusIco color="white"></PlusIco>
                                    )}
                                </Button>
                            )}
                        </Box>
                    )}
            </Box>
        </>
    )
}
