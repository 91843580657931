import * as React from 'react'

export const StrokedArrowLeft = ({
    color = '#778CA2',
    rotate,
}: {
    color?: string
    rotate?: boolean
}) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: rotate ? 'rotate(180deg)' : undefined }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.437 3.097a.582.582 0 1 1 .834.811L6.29 8l3.982 4.094a.582.582 0 0 1-.834.811L5.344 8.699a1 1 0 0 1 0-1.395l4.093-4.207Z"
            fill={color}
        />
    </svg>
)
