import { css, keyframes } from 'styled-components'

export const rippleEffect = keyframes`
    0% {
       transform: scale(0);
     }
     100% {
       transform: scale(100);
     }
   `

export const variantOutlined = ({ variant }: { variant: string }) =>
    variant === 'outlined' &&
    css`
        background-color: transparent;
        color: ${({ theme }) => theme.colors.primary};
        transition: 0.1s ease-in-out;
        font-weight: 500;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        &:active {
            background-color: ${({ theme }) => theme.colors.gray250};
        }
        &:hover {
            background-color: ${({ theme }) => theme.colors.gray250};
        }
        &:focus {
            background: rgba(2, 33, 67, 0.2);
        }
        &:active span {
            visibility: visible;
            animation: ${rippleEffect} 1.5s ease-in-out;
            animation-fill-mode: forwards;
        }
        & span {
            background: rgba(2, 33, 67, 0.2);
        }
    `

export const variantSecondary = ({ variant }: { variant: string }) =>
    variant === 'secondary' &&
    css`
        background-color: #e8ecef;
        color: ${({ theme }) => theme.colors.primary};
        transition: 0.1s ease-in-out;
        border: none;
        &:active {
            background-color: ${(props) => props.theme.colors.blue};
        }
        &:hover {
            background-color: #c2cfe0;
        }
        &:focus {
            background-color: #c2cfe0;
        }
        &:active span {
            visibility: visible;
            animation: ${rippleEffect} 1.5s ease-in-out;
            animation-fill-mode: forwards;
        }
        & span {
            background-color: #c2cfe0;
        }
    `

export const variantPrimary = ({ variant }: { variant: string }) =>
    variant === 'primary' &&
    css`
        background-color: #022143;
        color: ${({ theme }) => theme.colors.white};
        transition: 0.1s ease-in-out;
        &:disabled {
            opacity: 50%;
        }
        &:active {
            background-color: ${(props) => props.theme.colors.blue};
        }
        &:hover {
            background-color: #34486e;
        }
        &:focus {
            background-color: #34486e;
        }
        &:active span {
            visibility: visible;
            animation: ${rippleEffect} 1.5s ease-in-out;
            animation-fill-mode: forwards;
        }
        & span {
            background-color: rgba(255, 255, 255, 0.4);
        }
    `

export const variantColored = ({ variant }: { variant: string }) =>
    variant === 'colored' &&
    css`
        background-color: ${({ theme }) => theme.colors.delay};
        color: ${({ theme }) => theme.colors.white};
        transition: 0.1s ease-in-out;
        &:disabled {
            opacity: 50%;
        }
        &:active {
            background-color: ${({ theme }) => theme.colors.delay};
        }
        &:active span {
            visibility: visible;
            animation: ${rippleEffect} 1.5s ease-in-out;
            animation-fill-mode: forwards;
        }
        & span {
            background: rgba(255, 255, 255, 0.4);
        }
    `

export const variantTertiaryOutline = ({ variant }: { variant: string }) => {
    return (
        variant === 'tertiaryOutline' &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.colors.textTags};
            transition: 0.1s ease-in-out;
            font-weight: 400;
            border: 1px solid ${({ theme }) => theme.colors.gray250};
        `
    )
}

export const variantTertiaryOutlineTwo = ({ variant }: { variant: string }) => {
    return (
        variant === 'tertiaryOutlineTwo' &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.colors.secondaryLighterBlue};
            transition: 0.1s ease-in-out;
            font-weight: 400;
            border: 1px solid
                ${({ theme }) => theme.colors.secondaryLighterBlue};
        `
    )
}

export const variantTertiary = ({ variant }: { variant: string }) => {
    return (
        variant === 'tertiary' &&
        css`
            background-color: #027ac4;
            color: ${({ theme }) => theme.colors.white};
            transition: 0.1s ease-in-out;
            font-weight: 400;
            border: 0px solid ${({ theme }) => theme.colors.gray250};
        `
    )
}

export const variantGhost = ({ variant }: { variant: string }) => {
    return (
        variant === 'ghost' &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.colors.primary};
            transition: 0.1s ease-in-out;
            font-weight: 400;
            border: none;
        `
    )
}
