/**
 * @description to determine the status of todo/product/collection
 */

export const statuses = {
    complete: 'complete',
    inProgress: 'inProgress',
    inReview: 'inReview',
    toBeReviewed: 'toBeReviewed',
    revisionRequested: 'revisionRequested',
    requestedForResubmission: 'requestedForResubmission',
    new: 'new',
    todo: 'todo',
    invited: 'invited',
    active: 'active',
    archived: 'archived',
    delayed: 'delayed',
} as const

export const OrgTabs: {
    teams: 'teams'
    collaborators: 'collaborators'
    analytics: 'analytics'
} = {
    teams: 'teams',
    collaborators: 'collaborators',
    analytics: 'analytics',
}
export const TeamSliderTabs: {
    collection: 'collection'
    products: 'products'
    collaborators: 'collaborators'
} = {
    collection: 'collection',
    products: 'products',
    collaborators: 'collaborators',
}
export const individualCollaboratorTabs: {
    todos: 'todos'
    teams: 'teams'
} = {
    todos: 'todos',
    teams: 'teams',
}

/**
 * @description  to determine the access level of platform
 */
export const roles = {
    all: 'all',
    admin: 'admin',
    manager: 'manager',
    designer: 'designer',
}

export const defaultDropdownList: DurationFilterList = {
    thisMonth: 'This Month',
    pastMonth: 'Past Month',
    past3Months: 'Past 3 Months',
    past6Months: 'Past 6 Months',
    pastYear: 'Past Year',
    allTime: 'All Time',
}

/**
 * @description actions that can be performed on collection, product or todo
 */
export const entityActions = {
    EDIT: 'edit',
    ADD: 'add',
    DELETE: 'delete',
} as const

export const LibraryPerPage = 19
export const PerPage = 10
export const ROWS = 3
export const GRID_WIDTH = { md: '96%', lg: '96%', xl: 'calc(100% - 300px)' }
export const FILE_TYPES = [
    'glb',
    'gltf',
    'ai',
    'fbx',
    'dxf',
    'zprj',
    'pdf',
    'png',
    'jpeg',
    'jpg',
    'sbsar',
    'obj',
    'tif',
    'stl',
    'xlsx',
    'xlx',
    'zip',
    'psd',
    'zfab',
    'pptx',
    'ma',
    'mb',
    'blend',
    'svg',
    'spp',
    'sbs',
    'ssa',
    'max',
    'zpr',
    'ztl',
    'c4d',
    'hip',
    'sproj',
    'plasticity',
    'bw',
]

export const PREVIEW_NOT_SUPPORTED_FILE_TYPES = [
    'zip',
    'ma',
    'mb',
    'blend',
    'svg',
    'spp',
    'sbs',
    'ssa',
    'max',
    'zpr',
    'ztl',
    'c4d',
    'hip',
    'sproj',
    'plasticity',
    'psd',
    'bw',
    'zfab',
    'sbsar',
    'zprj',
    'tif',
]

export const _3DFiles = ['glb', 'gltf', 'fbx', 'obj', 'stl']

export const _3DFileTypes = [
    'glb',
    'gltf',
    'fbx',
    'obj',
    'stl',
    'png',
    'jpg',
    'jpeg',
]

export const PdfFileTypes = ['pdf', 'ai', 'dxf']

export enum TeamsEnum {
    'teams',
    'teamsByMemberId',
}

export function getStatusColor(
    status: (typeof statuses)[keyof typeof statuses]
) {
    const colors = {
        [statuses.complete]: 'textCompleted',
        [statuses.inProgress]: 'textStatusActive',
        [statuses.revisionRequested]: 'warning',
        [statuses.requestedForResubmission]: '#EF8D1B',
        [statuses.new]: 'grayOpacity10',
        [statuses.delayed]: 'delay',
        [statuses.toBeReviewed]: 'textToBeReviewed',
        [statuses.inReview]: 'textInReview',
        [statuses.todo]: 'textStatusActive',
        [statuses.invited]: 'textInvited',
        [statuses.active]: 'textStatusActive',
        [statuses.archived]: 'textArchived',
    }
    return colors[status]
}

export const ENTITIES = {
    collections: 'collections',
    products: 'products',
    product_variants: 'product_variants',
    todos: 'todos',
} as const
