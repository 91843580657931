const todoIntitialState = {
    created_at: '',
    due_date: '',
    files: [
        {
            path: '',
            name: '',
            blob: '',
            id: '',
            thumbnail: '',
        },
    ],

    id: 0,
    name: '',
    description: '',
    status: 'new',
    duration: '',
    product_id: '',
    created_by: '',
    submitted_files: [
        {
            path: '',
            name: '',
        },
    ],
    assignee: {
        email: '',
        name: '',
        id: 0,
        other_data: {
            profile_pic: '',
        },
        organisation_members: [
            {
                role: '',
            },
        ],
    },
    sub_todos: [
        {
            description: '',
            heading: '',
            created_at: '',
            id: 0,
            todo_id: 0,
            other_data: {
                color: [
                    {
                        id: '',
                        name: '',
                    },
                ],
                type: 'file',
            },
            files: [
                {
                    path: '',
                    blob: '',
                    id: '0',
                    name: '',
                },
            ],
            submitted_files: [
                {
                    path: '',
                    name: '',
                },
            ],
        },
    ],
}

export const IndividualProductInitialState = {
    created_at: '',
    created_by: '',
    status: 'new',
    approver_due_date: '',
    is_delayed: false,
    approver_id: 0,
    collection_id: 0,
    due_date: '',
    description: '',
    assignee: {
        email: '',
        name: '',
        id: '',
        other_data: {
            thumbnail: '',
            profile_pic: '',
        },
        organisation_members: [
            {
                role: '',
            },
        ],
    },
    collection: {
        id: '',
        name: '',
        manager: {
            other_data: {
                profile_pic: '',
            },
            name: '',
            id: 0,
            email: '',
        },
    },
    request_revision_info: {
        files: [
            {
                name: '',
                path: '',
                gid: '',
                id: '',
            },
        ],
        sections: '',
        description: '',
    },
    id: 0,
    name: '',
    tags: [
        {
            tag: '',
            color: '',
        },
    ],
    team_id: 0,

    approver: {
        email: '',
        name: '',
        id: 0,
        other_data: {
            profile_pic: '',
        },
    },
    team: {
        id: 0,
        name: '',
        team_members: [
            {
                user: {
                    other_data: {
                        profile_pic: '',
                    },
                    name: '',
                    id: 0,
                    email: '',
                },
            },
        ],
    },
    todos: [todoIntitialState, todoIntitialState, todoIntitialState],
}
