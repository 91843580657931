import { gql } from '@apollo/client'

export const CreateTodo = gql`
    mutation createTodo($object: todos_insert_input!) {
        insert_todos_one(object: $object) {
            name
            id
            status
            product_variant_id
            tags
            sub_todos {
                id
                todo_id
                heading
                description
            }
        }
    }
`
