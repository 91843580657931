import * as React from 'react'

export const PlusIcoSm = ({ color }: { color?: string }) => (
    <svg
        width={16}
        height={16}
        fill={color || '#778CA2'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.42 7.467a.533.533 0 0 0 0 1.066h4.37v4.37a.533.533 0 0 0 1.066 0v-4.37h4.37a.533.533 0 1 0 0-1.066h-4.37v-4.37a.533.533 0 1 0-1.066 0v4.37H3.42Z"
            fill={color || '#778CA2'}
        />
    </svg>
)
