import { Tab, Tabs } from '@mui/material'
import { Box } from 'src/components/utility'

interface IYourProductsSliderProps {
    currentTab:
        | 'new'
        | 'inProgress'
        | 'inReview'
        | 'requestedForResubmission'
        | 'complete'
    setCurrentTab: (
        tab:
            | 'new'
            | 'inReview'
            | 'requestedForResubmission'
            | 'inProgress'
            | 'complete'
    ) => void
    products: {
        new: number
        inProgress: number
        inReview: number
        revisionRequested: number
        completed: number
    }
}

export default function YourProductsSlider(props: IYourProductsSliderProps) {
    const { currentTab, setCurrentTab } = props
    return (
        <Box>
            <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    setCurrentTab(newValue)
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab label={`New (${props.products.new})`} value={'new'} />
                <Tab
                    label={`In Progress (${props.products.inProgress})`}
                    value={'inProgress'}
                />
                <Tab
                    label={`To Be Reviewed (${props.products.inReview})`}
                    value={'inReview'}
                />
                <Tab
                    label={`Revision Requested (${props.products.revisionRequested})`}
                    value={'requestedForResubmission'}
                />
                <Tab
                    label={`Completed (${props.products.completed})`}
                    value={'complete'}
                />
                {/* <Tab label="Analytics" value={OrgTabs.analytics} /> */}
            </Tabs>
        </Box>
    )
}
