import { useEffect, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { ColoredButton } from 'src/components/buttons/colored-button'
import useStore from 'src/store/hooks/useStore'
import SelectCategorySection from './select-category-section'
import SelectFolderSection from './select-folder-section'
import axios from 'axios'
import { ASSIGN_FILE_TO_LIBRARY } from 'src/services'
import { toast } from 'react-toastify'
import { getLocalStorage } from 'src/helpers'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import FileBadge from 'src/components-v2/file-badge/file-badge'

export const LibraryDestinationForFilesModal = ({
    isModal,
    onClose,
    entityId,
    entityName,
    additionalDetails,
    selectedFiles,
    onSuccess,
}: {
    isModal: boolean
    onClose?: () => void
    entityName: string
    entityId: number
    additionalDetails: {
        brand: string
        company: string
        colors: Array<any>
        tags: Array<any>
    }
    selectedFiles: Array<any>
    onSuccess: () => void
}) => {
    const [isOpen, setIsOpen] = useState<string>('')
    const [isVisible, setIsVisible] = useState<string>('none')
    const [showInsertInputField, setShowInsertInputField] = useState({
        category: false,
        folder: false,
    })
    const { setIsModal } = useStore()

    const [selectedCategory, setSelectedCategory] = useState<{
        id: number
        name: string
    } | null>(null)

    const [folderBreadcrumbs, setFolderBreadcrumbs] = useState<
        Array<{
            name: string
            id: number
        }>
    >([])

    function handleClose(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        setIsOpen('close-modal')
        setTimeout(() => {
            setIsVisible('none')
            setIsModal({ isModalOpen: false, name: '', id: '' })
            onClose && onClose()
        }, 100)
    }

    useEffect(() => {
        if (isModal) {
            setIsOpen('')
            setIsVisible('flex')
        }
    }, [isModal])

    const activeSection: 'folder' | 'category' = !!selectedCategory?.id
        ? 'folder'
        : 'category'

    async function addFilesToLibraryHandler({
        categoryId,
        folderId,
    }: {
        categoryId?: number
        folderId?: number
    }) {
        let formData: FormData = new FormData()
        formData.append(
            'file_ids',
            JSON.stringify(selectedFiles.map((file: any) => file.file.id))
        )
        formData.append('entity_name', entityName)
        formData.append('entity_id', entityId.toString())
        if (folderId) {
            formData.append('folder_id', folderId.toString())
        }
        if (categoryId) {
            formData.append('category_id', categoryId.toString())
        }
        formData.append('brand', additionalDetails.brand)
        formData.append('company', additionalDetails.company)
        formData.append('color', JSON.stringify(additionalDetails.colors))
        formData.append('tags', JSON.stringify(additionalDetails.tags))
        try {
            const response = await axios.post(
                ASSIGN_FILE_TO_LIBRARY,
                formData,
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                }
            )
            if (response.status === 200) {
                toast('Files added to library', {
                    className: 'custom-toaster toaster-success',
                })
                onSuccess()
                onClose && onClose()
            }
        } catch {
            toast('Failed to add files to library', {
                className: 'custom-toaster toaster-error',
            })
        }
    }

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            display={isVisible}
            bg="#02214370"
            justifyContent="center"
            alignItems="center"
            zIndex={15}
            className={isOpen}
        >
            <Box
                display="flex"
                flexDirection="column"
                width="600px"
                height={'70vh'}
                bg="backgroundColor"
                borderRadius="4px"
                p="8px"
                gridGap="8px"
            >
                <Box
                    display="grid"
                    gridTemplateColumns={'1fr auto'}
                    gridGap="16px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text my="0px" fontSize={'12px'} color="textSecondary">
                        Choose{' '}
                        {activeSection === 'category' ? 'category' : 'folder'}{' '}
                        to add to
                    </Text>
                    <Box display="flex" alignItems="center" gridGap="8px">
                        {selectedFiles?.slice(0, 2).map(({ file }) => {
                            return (
                                <FileBadge fileName={file.name} key={file.id} />
                            )
                        })}
                        {selectedFiles.length > 2 && (
                            <Text
                                my="0px"
                                fontSize="12px"
                                fontFamily="Rubik"
                                color="primary"
                            >
                                +{selectedFiles.length - 2}
                            </Text>
                        )}
                    </Box>
                </Box>
                {/* If the category is not selected, show select category section, else show select folder section  */}
                {!selectedCategory?.id ? (
                    <SelectCategorySection
                        onSelect={(selectedCategory) =>
                            setSelectedCategory(selectedCategory)
                        }
                        onAdd={(categoryId) => {
                            addFilesToLibraryHandler({
                                categoryId,
                            })
                        }}
                        showInsertInputField={showInsertInputField.category}
                        setShowInsertInputField={(value) =>
                            setShowInsertInputField({
                                ...showInsertInputField,
                                category: value,
                            })
                        }
                    />
                ) : (
                    <SelectFolderSection
                        key={`${
                            folderBreadcrumbs.length === 0
                                ? 'category'
                                : 'folder'
                        }-${
                            folderBreadcrumbs.length === 0
                                ? selectedCategory.id
                                : folderBreadcrumbs[
                                      folderBreadcrumbs.length - 1
                                  ].id
                        }`}
                        parentType={
                            folderBreadcrumbs.length === 0
                                ? 'category'
                                : 'folder'
                        }
                        parentId={
                            folderBreadcrumbs.length === 0
                                ? selectedCategory.id
                                : folderBreadcrumbs[
                                      folderBreadcrumbs.length - 1
                                  ].id
                        }
                        onSelect={(selectedFolder) =>
                            setFolderBreadcrumbs([
                                ...folderBreadcrumbs,
                                selectedFolder,
                            ])
                        }
                        onAdd={(folderId) => {
                            addFilesToLibraryHandler({
                                categoryId: selectedCategory.id,
                                folderId,
                            })
                        }}
                        breadcrumbs={[selectedCategory, ...folderBreadcrumbs]}
                        showInsertInputField={showInsertInputField.folder}
                        setShowInsertInputField={(value) =>
                            setShowInsertInputField({
                                ...showInsertInputField,
                                folder: value,
                            })
                        }
                    />
                )}
                <Box
                    mx="24px"
                    py="12px"
                    display="flex"
                    gridColumnGap="16px"
                    mt="auto"
                    alignItems="center"
                    style={{ justifySelf: 'flex-end' }}
                >
                    <ColoredButton
                        onClick={() => {
                            addFilesToLibraryHandler({
                                categoryId: selectedCategory?.id,
                                folderId:
                                    folderBreadcrumbs.length > 0
                                        ? folderBreadcrumbs[
                                              folderBreadcrumbs.length - 1
                                          ]?.id
                                        : undefined,
                            })
                        }}
                        variant="primary"
                        disabled={!selectedCategory?.id}
                    >
                        Add
                    </ColoredButton>
                    <ColoredButton onClick={handleClose} variant="secondary">
                        Cancel
                    </ColoredButton>
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        color="links"
                        position="relative"
                        maxWidth="max-content"
                        onClick={() => {
                            setShowInsertInputField({
                                category:
                                    activeSection === 'category'
                                        ? !showInsertInputField.category
                                        : false,
                                folder:
                                    activeSection === 'folder'
                                        ? !showInsertInputField.folder
                                        : false,
                            })
                        }}
                        px="0px"
                        ml={'auto'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            justifySelf: 'flex-end',
                        }}
                    >
                        <PlusIcoSm color="#027AC3" />
                        <Text my={'0px'}>
                            Add{' '}
                            {activeSection === 'category'
                                ? 'Category'
                                : 'Folder'}
                        </Text>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
