import React from 'react'
import { IconButton } from 'src/components/buttons/icon-button'
import { PlusIcoSm } from 'src/components/svg-icons/plus-ico-sm'
import { Box, Image } from 'src/components/utility'
import { InsertSubtodo } from '../../../services/graphql/entities/todo/todo.mutation'
import { useMutation } from '@apollo/client'

function SubtodoEmptyState({
    todoId,
    refetchTodo,
    totalSubtodos,
    onAddDetailsClick,
}: {
    todoId: number
    refetchTodo: () => void
    totalSubtodos: number
    onAddDetailsClick?: () => void
}) {
    const [addSubtodo] = useMutation(InsertSubtodo)
    const addSubtodoClickHandler = () => {
        const object = {
            todo_id: todoId,
            heading: '',
            design_color: '#D6E0EA',
            sub_todo_sequences: {
                data: [{ sequence: totalSubtodos }],
            },
            sub_todo_sections: {
                data: [
                    {
                        name: '',
                        todo_id: todoId,
                    },
                ],
            },
        }
        addSubtodo({
            variables: {
                object,
            },
            onCompleted: () => {
                refetchTodo()
            },
        })
    }

    return (
        <Box
            bg="white"
            height="416px"
            borderRadius="4px"
            border="1px solid #E8ECEF"
            p="16px"
            display="flex"
            gridGap="24px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Box maxWidth="245px" maxHeight="276px">
                <Image
                    src="/create-entity.png"
                    alt="create entity"
                    width="100%"
                    height="100%"
                />
            </Box>
            <IconButton
                id="subtodo-empty-createbutton"
                variant={'primary'}
                iconLeft={<PlusIcoSm color="#ffffff" />}
                onClick={() =>
                    onAddDetailsClick
                        ? onAddDetailsClick()
                        : addSubtodoClickHandler()
                }
            >
                Add Details
            </IconButton>
        </Box>
    )
}

export default SubtodoEmptyState
