import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { debounce } from 'src/helpers'
import { GetOrgMemberByEmail } from 'src/services/graphql/query/get-team-by-id'
import useStore from 'src/store/hooks/useStore'

export function useSearchCollaborators() {
    const { setCollaborators } = useStore()
    const [getSearchedCollaborators, collaborators] = useLazyQuery<{
        organisation_members: {
            role: string
            user_id: string
            user: {
                email: string
                name: string
                other_data: {
                    profile_pic: string
                    thumbnail: string
                }
                id: string
                created_at: string
            }
        }[]
    }>(GetOrgMemberByEmail)

    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const searchCollaborator = debounce(
        searchCollaborators,
        500,
        setTimeOutRef,
        timeOutRef
    )
    function searchCollaborators(query: string) {
        getSearchedCollaborators({
            variables: {
                searchStr: `%${query}%`,
            },
            onCompleted: (data) => {
                // setCollaborators({
                //     collaborators: data.organisation_members.map(
                //         (collaborator) => ({
                //             email: collaborator.user.email,
                //             id: collaborator.user_id,
                //             name: collaborator.user.name,
                //             profile_img:
                //                 collaborator.user.other_data.profile_img,
                //         })
                //     ),
                // })
            },
        })
    }

    return {
        searchCollaborator,
        collaborators: collaborators.data?.organisation_members || [],
    }
}
