import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { TransparentRightArrow } from 'src/components'
import TemplateContainer from 'src/components-v2/todo-templates/template-container/template-container'
import TemplateFavouritesContainer from 'src/components-v2/todo-templates/template-favourites-container/template-favourites-container'
import TodoTemplatesHeader from 'src/components-v2/todo-templates/todo-templates-header/todo-teamplates-header'
import { DrawerTabSliders } from 'src/components/drawer/drawer-tab-sliders'
import { Slider } from 'src/components/slider/slider'
import { TransparentLeftArrow } from 'src/components/transparent-left-arrow/transparent-left-arrow'
import { Box } from 'src/components/utility'
import type {
    GetTemplateCategoriesWithTemplatesQuery,
    GetTemplateCategoriesWithTemplatesQueryVariables,
} from 'src/generated/graphql'
import {
    GetTemplateCategoriesWithTemplates,
    GetTodoTemplateTypeNames,
} from 'src/services/graphql/entities/todo/todo-templates.queries'

function TodoTemplates() {
    const [sliderTabId, setSliderTabId] = useState(0)
    const [searchDetails, setSearchDetails] = useState<{
        searchQuery: string
        searchType: 'category' | 'templates'
    }>({
        searchQuery: '',
        searchType: 'category',
    })

    const templateTypes = useQuery<{
        template_types: Array<{ type: string; id: number }>
    }>(GetTodoTemplateTypeNames, {
        fetchPolicy: 'cache-and-network',
    })

    const sliderTabs = templateTypes?.data?.template_types
        ? [
              { heading: 'All', id: 0 },
              ...templateTypes?.data?.template_types.map((template_type) => {
                  return {
                      heading: template_type.type,
                      id: template_type.id,
                  }
              }),
          ]
        : []

    const { data: templateCategoriesData } = useQuery<
        GetTemplateCategoriesWithTemplatesQuery,
        GetTemplateCategoriesWithTemplatesQueryVariables
    >(GetTemplateCategoriesWithTemplates, {
        variables: {
            template_type_id: sliderTabId === 0 ? {} : { _eq: sliderTabId },
            category_search_str:
                searchDetails.searchType === 'category'
                    ? `%${searchDetails.searchQuery}%`
                    : '%%',
            template_search_str:
                searchDetails.searchType === 'templates'
                    ? `%${searchDetails.searchQuery}%`
                    : '%%',
        },
        fetchPolicy: 'cache-and-network',
    })

    const navigateToNextTab = () => {
        const indexOfCurrentTab = sliderTabs.findIndex(
            (tab) => tab.id === sliderTabId
        )
        setSliderTabId(
            indexOfCurrentTab === sliderTabs.length - 1
                ? 0
                : sliderTabs[indexOfCurrentTab + 1].id
        )
    }

    const navigateToPreviousTab = () => {
        const indexOfCurrentTab = sliderTabs.findIndex(
            (tab) => tab.id === sliderTabId
        )
        setSliderTabId(
            indexOfCurrentTab === 0
                ? sliderTabs[sliderTabs.length - 1].id
                : sliderTabs[indexOfCurrentTab - 1].id || 0
        )
    }

    return (
        <Box display="flex" flexDirection="column" bg="white">
            <TodoTemplatesHeader
                searchDetails={searchDetails}
                updateSearchQuery={(query) =>
                    setSearchDetails({ ...searchDetails, searchQuery: query })
                }
            />
            <TemplateFavouritesContainer />
            <Box
                display="flex"
                flexDirection="column"
                borderTop="1px solid #A7B9D1"
                style={{ borderRadius: '12px 12px 0px 0px' }}
                bg="#F8FAFB"
                pt="32px"
                px="24px"
                gridGap="16px"
            >
                <Slider
                    element={
                        <Box
                            position="relative"
                            px={sliderTabs.length > 3 ? '36px' : '0px'}
                        >
                            {sliderTabs.length > 3 && (
                                <TransparentLeftArrow
                                    onClick={navigateToPreviousTab}
                                />
                            )}
                            <DrawerTabSliders
                                value={sliderTabId}
                                setValue={setSliderTabId}
                                tabs={sliderTabs}
                            />
                            {sliderTabs.length > 3 && (
                                <TransparentRightArrow
                                    onClick={navigateToNextTab}
                                />
                            )}
                        </Box>
                    }
                    carousel={true}
                />
                <TemplateContainer
                    // @ts-ignore
                    data={templateCategoriesData?.template_categories}
                    sliderTabId={sliderTabId}
                    updateSearchType={(searchType) =>
                        setSearchDetails({ ...searchDetails, searchType })
                    }
                />
            </Box>
        </Box>
    )
}

export default TodoTemplates
