import React from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import TodoSection from '../todo-section/todo-section'
import { Cross } from 'src/components/svg-icons'

function FullscreenTodoModal({
    onClose,
    todoId,
}: {
    onClose: () => void
    todoId: number
}) {
    const wrapperRef = useClickedOutside(onClose, ['fullscreen-todo-modal'])

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="#02214370"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={15}
        >
            <Box
                width="85vw"
                height={'90%'}
                bg="backgroundColor"
                borderRadius="4px"
                ref={wrapperRef}
                overflow={'auto'}
                className="scrollbar_none"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={'16px'}
                    mt="16px"
                >
                    <Text my="0px" fontSize={'20px'}>
                        To-do
                    </Text>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onClose()
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="#027AC3"
                        position="relative"
                        width="max-content"
                    >
                        <Cross color="gray" />
                    </Button>
                </Box>
                <TodoSection todoId={todoId} />
            </Box>
        </Box>
    )
}

export default FullscreenTodoModal
