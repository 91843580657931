import { v4 as uuid } from 'uuid'

const psub_todos = [
    {
        id: uuid(),
        heading: 'Silhouette Reference',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Fabric/Texture',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Colors',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'color',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Trims/Hardware',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [],
        type: 'file',
        editableHeading: false,
    },
]

const basesub_todos = [
    {
        id: uuid(),
        heading: 'Base Model',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
]

const fRendersub_todos = [
    {
        id: uuid(),
        heading: 'Texturing',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
    {
        id: uuid(),
        heading: 'Final Render',
        description: '',
        file: [
            {
                fileName: '',
                is_library_file: false,
                path: '',
                blob: '',
                id: 0,
            },
        ],
        color: [{ id: '', name: '' }],
        type: 'file',
        editableHeading: false,
    },
]
const productForm = {
    name: '',
    tags: [],
    description: '',
    due_date: '',
    team_id: 0,
    approver_id: 0,
    collection_id: 0,
    approver_due_date: '',
    todos: {
        data: [
            {
                id: 0,
                name: 'Tech Pack',
                dueDate: '',
                assignee_id: '',
                sub_todos: { data: psub_todos },
                expanded: false,
                editableHeading: false,
                isCompleted: 'UNEDITED',
            },
            {
                id: 1,
                name: 'Base Model',
                dueDate: '',
                assignee_id: '',
                sub_todos: { data: basesub_todos },
                expanded: false,
                editableHeading: false,
                isCompleted: 'UNEDITED',
            },
            {
                id: 2,
                name: 'Final Render',
                dueDate: '',
                assignee_id: '',
                sub_todos: { data: fRendersub_todos },
                expanded: false,
                editableHeading: false,
                isCompleted: 'UNEDITED',
            },
        ],
    },
}

export const productTodos = [
    {
        id: 0,
        name: 'Tech Pack',
        dueDate: '',
        assignee_id: null,
        sub_todos: { data: psub_todos },
        expanded: false,
        editableHeading: false,
        isCompleted: 'UNEDITED',
    },
    {
        id: 1,
        name: 'Base Model',
        dueDate: '',
        assignee_id: null,
        sub_todos: { data: basesub_todos },
        expanded: false,
        editableHeading: false,
        isCompleted: 'UNEDITED',
    },
    {
        id: 2,
        name: 'Final Render',
        dueDate: '',
        assignee_id: null,
        sub_todos: { data: fRendersub_todos },
        expanded: false,
        editableHeading: false,
        isCompleted: 'UNEDITED',
    },
]

export const createProductFormSlice = (set: Function) => ({
    addProductTodos: productTodos,

    productForm,
    step: 1,
    stepLimit: 2,
    teamMembers: [],

    setStep: ({ payload }: { payload: 'NEXT' | 'PREV' }) => {
        if (payload === 'NEXT') {
            set((state: any) => ({ ...state, step: 2 }))
        } else {
            set((state: any) => ({ ...state, step: 1 }))
        }
    },
    resetStep: () => {
        set((state: any) => ({ ...state, step: 1 }))
    },
    setTeamMembers: ({ payload }: { payload: Users[] }) => {
        set((state: any) => ({ ...state, teamMembers: payload }))
    },

    updateTodo: ({
        payload,
        action,
    }: {
        payload: { id: string; data: any }
        action: 'name' | 'dueDate' | 'assignee_id' | 'expanded'
    }) => {
        set((state: any) => {
            return {
                ...state,
                addProductTodos: state.addProductTodos.map((todo: any) => {
                    return todo.id.toString() === payload.id.toString()
                        ? {
                              ...todo,
                              [action]: payload.data,
                              isCompleted: 'EDITED',
                          }
                        : todo
                }),
            }
        })
    },
    setTodos: ({ todos }: { todos: LocalTodo[] }) => {
        set((state: any) => {
            return {
                ...state,
                addProductTodos: todos,
            }
        })
    },

    updateSubTodo: ({
        payload,
        action,
    }: {
        payload: {
            todoId: string | number
            subTodoId: string | number
            data: any
        }
        action: 'heading' | 'description' | 'file' | 'color'
    }) => {
        set((state: any) => {
            if (action === 'file') {
                return {
                    ...state,
                    addProductTodos: state.addProductTodos.map((todo: any) => {
                        return todo.id == payload.todoId
                            ? {
                                  ...todo,
                                  isCompleted: 'EDITED',
                                  sub_todos: {
                                      data: todo.sub_todos.data.map(
                                          (subTodo: any) => {
                                              return subTodo.id ==
                                                  payload.subTodoId
                                                  ? {
                                                        ...subTodo,
                                                        file: [
                                                            ...subTodo.file,
                                                            ...payload.data,
                                                        ],
                                                    }
                                                  : subTodo
                                          }
                                      ),
                                  },
                              }
                            : todo
                    }),
                }
            }
            return {
                ...state,
                addProductTodos: state.addProductTodos.map((todo: any) => {
                    return todo.id == payload.todoId
                        ? {
                              ...todo,
                              isCompleted: 'EDITED',
                              sub_todos: {
                                  data: todo.sub_todos.data.map(
                                      (subTodo: any) => {
                                          return subTodo.id == payload.subTodoId
                                              ? {
                                                    ...subTodo,
                                                    [action]: payload.data,
                                                }
                                              : subTodo
                                      }
                                  ),
                              },
                          }
                        : todo
                }),
            }
        })
    },
    setProductForm: ({ product }: { product: IndividualProductLocal }) => {
        set((state: any) => {
            return {
                ...state,
                productForm: { ...product, todos: state.productForm.todos },
            }
        })
    },
    updateProductForm: ({
        payload,
        action,
    }: {
        payload: any
        action:
            | 'name'
            | 'description'
            | 'due_date'
            | 'team_id'
            | 'approver_id'
            | 'approver_due_date'
            | 'todos'
            | 'collection_id'
    }) => {
        set((state: any) => {
            return {
                ...state,
                productForm: {
                    ...state.productForm,
                    tags: state.productForm.tags || [],
                    [action]: payload,
                },
            }
        })
    },

    addTags: (
        payload: {
            id: number | string
            name: string
            color: string
        },
        action: 'ADD' | 'DELETE'
    ) => {
        if (action === 'ADD') {
            return set((state: any) => {
                // debugLog(state.productForm.tags)
                return {
                    ...state,
                    productForm: {
                        ...state.productForm,
                        tags: [...state.productForm.tags, payload],
                    },
                }
            })
        }
        return set((state: any) => ({
            ...state,
            productForm: {
                ...state.productForm,
                tags: state.productForm.tags.filter(
                    (tag: { id: number; tag: string; color: string }) =>
                        tag.id !== payload.id
                ),
            },
        }))
    },

    resetTodos: () => {
        set((state: any) => ({
            ...state,
            addProductTodos: [
                {
                    id: 0,
                    name: 'Tech Pack',
                    dueDate: '',
                    assignee_id: '',
                    sub_todos: { data: psub_todos },
                    editableHeading: false,
                },
                {
                    id: 1,
                    name: 'Base Model',
                    dueDate: '',
                    assignee_id: '',
                    sub_todos: { data: basesub_todos },
                    editableHeading: false,
                },
                {
                    id: 2,
                    name: 'Final Render',
                    dueDate: '',
                    assignee_id: '',
                    sub_todos: { data: fRendersub_todos },
                    editableHeading: false,
                },
            ],
        }))
    },

    // state.productForm.todos.data.length + 1
    addNewTodo: () => {
        set((state: any) => {
            return {
                ...state,
                addProductTodos: [
                    ...state.addProductTodos,
                    {
                        id: uuid(),
                        name: 'New Todo',
                        dueDate: '',
                        assignee_id: '',
                        sub_todos: {
                            data: [
                                {
                                    id: uuid(),
                                    heading: 'New Sub Todo',
                                    description: '',
                                    file: [],
                                    color: '',
                                    type: 'file',
                                    editableHeading: true,
                                },
                            ],
                        },
                        editableHeading: true,
                        expanded: true,
                        type: 'NEW-TODO',
                    },
                ],
            }
        })
    },
    removeNewTodo: ({ payload }: { payload: number }) => {
        set((state: any) => {
            // debugLog(payload)
            return {
                ...state,
                addProductTodos: state.addProductTodos.filter(
                    (todo: any) => todo.id !== payload
                ),
            }
        })
    },

    removeFile: ({
        payload,
    }: {
        payload: {
            todoId: number
            subTodoId: number | string
            fileId: number | string
        }
    }) => {
        set((state: any) => {
            return {
                ...state,
                addProductTodos: state.addProductTodos.map((todo: any) => {
                    return todo.id === payload.todoId
                        ? {
                              ...todo,
                              sub_todos: {
                                  data: todo.sub_todos.data.map(
                                      (subTodo: any) => {
                                          return subTodo.id ===
                                              payload.subTodoId
                                              ? {
                                                    ...subTodo,
                                                    file: subTodo.file.filter(
                                                        (file: any) =>
                                                            file.id !==
                                                            payload.fileId
                                                    ),
                                                }
                                              : subTodo
                                      }
                                  ),
                              },
                          }
                        : todo
                }),
            }
        })
    },
    resetForm: () => {
        set((state: any) => ({
            ...state,
            productForm,
        }))
    },
})
