import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IconButton } from 'src/components/buttons/icon-button'
import { CloseIco, DropDownArrow } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import { CompareWithDropdown } from './compare-with-dropdown'
import Skeleton from 'react-loading-skeleton'
import { GetTodosByProductId } from 'src/components-v2/todo/todo-topbar/compare-entity-topbar.queries'
import TodoSection from 'src/components-v2/todo/todo-section/todo-section'
import { TodoSectionContextProvider } from 'src/components-v2/todo/todo-section/todo-section.context'

function ComparePanel({
    onClose,
    productId,
}: {
    onClose: () => void
    productId: string | null
}) {
    const [selectedCompareEntity, setSelectedCompareEntity] = useState<{
        id: number
        name: string
    } | null>(null)
    const [
        showSelectCompareEntityDropdown,
        setShowSelectCompareEntityDropdown,
    ] = useState(false)

    const [searchParams] = useSearchParams()

    const { data, loading } = useQuery(GetTodosByProductId, {
        variables: {
            searchQuery: `%%`,
            id: productId,
        },
        skip: !productId,
    })

    const productTodos =
        data?.todos.map((todo: any) => {
            return { name: todo.name, id: todo.id }
        }) || []

    return (
        <Box
            width="50%"
            borderLeft="2px solid"
            borderColor="secondaryLighterBlue"
            py="16px"
            display="flex"
            flexDirection="column"
            gridGap="24px"
            bg="white"
            maxHeight="80vh"
            overflow={'auto'}
            className="scrollbar_none"
        >
            <Box display="flex" flexDirection="column" gridGap="4px" px="12px">
                <Box display="flex" gridGap="8px" alignItems="center">
                    <Text my="0px" fontSize="20px" minWidth="max-content">
                        Compare with
                    </Text>
                    {!loading ? (
                        <Box position="relative">
                            <IconButton
                                variant="ghost"
                                iconRight={<DropDownArrow />}
                                onClick={() =>
                                    setShowSelectCompareEntityDropdown(
                                        !showSelectCompareEntityDropdown
                                    )
                                }
                            >
                                <Text
                                    my="0px"
                                    fontSize="20px"
                                    maxWidth="200px"
                                    className="text-overflow text-ellipsis"
                                >
                                    {selectedCompareEntity?.name || 'Select'}
                                </Text>
                            </IconButton>
                            {showSelectCompareEntityDropdown && (
                                <CompareWithDropdown
                                    active={showSelectCompareEntityDropdown}
                                    setIsDropdownActive={
                                        setShowSelectCompareEntityDropdown
                                    }
                                    dropdownItems={productTodos}
                                    setSelectedCompareEntity={
                                        setSelectedCompareEntity
                                    }
                                    selectedCompareEntityId={
                                        selectedCompareEntity?.id
                                    }
                                />
                            )}
                        </Box>
                    ) : (
                        <Box
                            width="120px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Skeleton />
                        </Box>
                    )}
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onClose()
                        }}
                        bg="transparent"
                        border="none"
                        fontStyle="Rubik"
                        fontSize="12px"
                        color="links"
                        position="relative"
                        width="max-content"
                        id="todo-dropdown-button"
                        style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}
                    >
                        <CloseIco />
                    </Button>
                </Box>
                <Text my="0px" fontSize="14px" color="textSecondary">
                    You can drag and drop assets on comments.
                </Text>
            </Box>
            {selectedCompareEntity && selectedCompareEntity.id !== 0 && (
                <Box height={'100%'}>
                    <TodoSectionContextProvider>
                        <TodoSection
                            todoId={selectedCompareEntity.id}
                            isCompareSection={true}
                            isSplitMode={true}
                        />
                    </TodoSectionContextProvider>
                </Box>
            )}
        </Box>
    )
}

export default ComparePanel
