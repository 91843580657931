import React from 'react'
import { Box } from 'src/components/utility'
import ProductDropdownCard from './product-dropdown-card'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'

function ProductDropdownContainer({
    product,
    onSelect,
}: {
    product: any
    onSelect: (product: {
        id: number
        name: string
        todos: Array<{ id: number; name: string; types: string }>
    }) => void
}) {
    const baseProduct = product?.product_variants?.find(
        (product: any) => product.is_primary
    )
    const productVariants = product?.product_variants?.filter(
        (product: any) => !product.is_primary
    )

    const baseProductCoverPic = baseProduct?.other_data?.cover_pic
        ? baseProduct.other_data?.cover_pic?.fromLibrary
            ? getLibraryFileUrl(baseProduct?.other_data?.cover_pic?.gid)
            : getImageUrl(baseProduct?.other_data?.cover_pic?.gid)
        : '/image-placeholder.jpg'

    return (
        <Box display="flex" flexDirection="column">
            <ProductDropdownCard
                key={baseProduct.id}
                name={baseProduct.name}
                collectionName={baseProduct.collection?.name || ''}
                approver={baseProduct.product_variant_approver}
                imageUrl={baseProductCoverPic}
                onClick={() => {
                    onSelect({
                        id: baseProduct.id,
                        name: baseProduct.name,
                        todos: baseProduct.todos,
                    })
                }}
            />
            {productVariants && productVariants?.length > 0 && (
                <Box
                    display="flex"
                    flexDirection="column"
                    borderColor="#C2CFE0"
                >
                    {productVariants &&
                        productVariants.map((product: any) => {
                            const coverPic = product?.other_data?.cover_pic
                                ? product.other_data?.cover_pic?.fromLibrary
                                    ? getLibraryFileUrl(
                                          product?.other_data?.cover_pic?.gid
                                      )
                                    : getImageUrl(
                                          product?.other_data?.cover_pic?.gid
                                      )
                                : '/image-placeholder.jpg'
                            return (
                                <ProductDropdownCard
                                    key={product.id}
                                    name={product.name}
                                    collectionName={
                                        product.collection?.name || ''
                                    }
                                    approver={product.product_variant_approver}
                                    imageUrl={coverPic}
                                    onClick={() => {
                                        onSelect({
                                            id: product.id,
                                            name: product.name,
                                            todos: product.todos,
                                        })
                                    }}
                                    isVariant={true}
                                />
                            )
                        })}
                </Box>
            )}
        </Box>
    )
}

export default ProductDropdownContainer
