import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box } from 'src/components/utility'
import { statuses } from 'src/helpers'

function CollectionSliderElement({
    resourcesCount,
    currentTab,
    totalProducts,
}: {
    resourcesCount: Record<string, number>
    currentTab: string
    totalProducts: number
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Box>
            <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    searchParams.set('tab', newValue)
                    searchParams.set('page', String(1))
                    setSearchParams(searchParams, { replace: true })
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab label={`All (${totalProducts})`} value={'all'} />
                <Tab
                    label={`New (${resourcesCount.new})`}
                    value={statuses.new}
                />
                <Tab
                    label={`In Progress (${resourcesCount.inProgress})`}
                    value={statuses.inProgress}
                />
                <Tab
                    label={`In Review (${resourcesCount.inReview})`}
                    value={statuses.inReview}
                />
                <Tab
                    label={`Revision Requested (${resourcesCount.requestedForResubmission})`}
                    value={statuses.requestedForResubmission}
                />
                <Tab
                    label={`Completed (${resourcesCount.complete})`}
                    value={statuses.complete}
                />
            </Tabs>
        </Box>
    )
}

export default CollectionSliderElement
