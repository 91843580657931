import { useLazyQuery } from '@apollo/client'
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    ChartConfiguration,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    SubTitle,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { DivisionLine } from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { HoursWorkedIco } from 'src/components/svg-icons/reports/hours-worked'
import { Box, Text } from 'src/components/utility'
import { statuses, useUser } from 'src/helpers'
import { GetCompletedTodosCounts } from 'src/services/graphql/query/reports.queries'
import theme from 'src/_theme'

const HoursWorked = () => {
    const { userId, role } = useUser()
    const approvedGreen: string = theme.colors.approved

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )

    const [collectionChart, setCollectionsGraph] = useState<Chart>()

    const [getTodosCount, todoCountsData] = useLazyQuery(
        GetCompletedTodosCounts,
        {
            variables: {
                user_id: Number(userId),
                status: statuses.complete,
            },
        }
    )

    useEffect(() => {
        if (userId) {
            getTodosCount()
        }
    }, [userId])

    useEffect(() => {
        if (collectionChart) {
            collectionChart.destroy()
        }

        const data = {
            labels: ['JAM', 'FEB', 'MAE', 'APR'],
            datasets: [
                {
                    label: 'My First Dataset',
                    data: [65, 59, 80, 81],
                    backgroundColor: ['rgba(2,122,196,0.5)', approvedGreen],
                    borderWidth: 0,
                    barThickness: 32,
                },
            ],
        }

        const config: ChartConfiguration = {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        grid: {
                            color: '#E8ECEF',
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }

        const ctx: any = document.getElementById('hoursWorked')
        let chartCreated: Chart = new Chart(ctx, config)
        setCollectionsGraph(chartCreated)
    }, [])

    return (
        <>
            <BasicCard>
                <Box>
                    <Box display="flex" bg={'#fff'} height={'125px'}>
                        <Box
                            width="100%"
                            position="relative"
                            p={'24px'}
                            height="100%"
                        >
                            <Box
                                display="flex"
                                mb={'8px'}
                                alignItems="center"
                                gridColumnGap={'12px'}
                            >
                                <HoursWorkedIco />
                                <Text my={'0px'} fontSize={'16px'}>
                                    Hours Worked
                                </Text>
                            </Box>
                            <Text my={'0px'} fontSize={'40px'}>
                                120h 35m
                            </Text>
                        </Box>
                    </Box>
                    <DivisionLine />
                    <Box>
                        <Box
                            p={'24px'}
                            position="relative"
                            width="100%"
                            display="block"
                        >
                            <canvas height={'380px'} id="hoursWorked"></canvas>
                        </Box>
                    </Box>
                </Box>
            </BasicCard>
        </>
    )
}

export default HoursWorked
