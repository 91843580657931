import * as React from 'react'
import { useNotificationContext } from './notification.context'
import { Box, Text } from 'src/components/utility'
import useStore from 'src/store/hooks/useStore'
import { Slider } from 'src/components/slider/slider'
import NotificationSlider from './notification.slider'
import NotificationCard from './card/notification.card'
import type { TPillBadges, TTabs } from './@types'
import useIntersectionObserver from 'src/helpers/hooks/use-intersection-observer'
import { useSubscription } from '@apollo/client'
import { SubscribeToNotifications } from 'src/services'
import { createWhereClause } from './functions'
import { PerPage } from 'src/helpers/enums'
import { useNotificationAggregate } from './hooks/use-notifications'
import { useSearchParams } from 'react-router-dom'

export interface INotificationsProps {}

export default function Notifications(props: INotificationsProps) {
    const { notifications, addNewNotifications, resetNotifications } =
        useStore()
    const [offset, setOffset] = React.useState(0)

    const { activeTab, setActivePillBadge, activePillBadge, setActiveTab } =
        useNotificationContext()
    const { pillBadges } = useNotificationAggregate(activeTab)

    const { lastElementRef, isIntersecting } = useIntersectionObserver({
        isLoading: false,
        threshold: 1,
    })

    const [searchParams, setSearchParams] = useSearchParams()

    const subscribedNotifications = useSubscription<{
        notifications: Notifications[]
    }>(SubscribeToNotifications, {
        fetchPolicy: 'no-cache',
        onData(data) {
            const list = data.data.data?.notifications
            if (!list) return
            if (isIntersecting) {
                addNewNotifications({
                    notifications: list,
                })
            } else {
                resetNotifications({
                    notifications: list,
                })
                setOffset(0)
            }
        },
        variables: {
            offset: offset * PerPage,
            where: createWhereClause(activePillBadge, activeTab),
        },
    })
    React.useEffect(() => {
        if (!subscribedNotifications.data?.notifications) return
        if (isIntersecting) {
            addNewNotifications({
                notifications: subscribedNotifications.data?.notifications,
            })
        }
    }, [subscribedNotifications.data])

    React.useEffect(() => {
        if (!subscribedNotifications.data?.notifications) return
        setOffset(0)
        resetNotifications({
            notifications: subscribedNotifications?.data?.notifications || [],
        })
    }, [activeTab, activePillBadge])

    React.useEffect(() => {
        if (isIntersecting) {
            setOffset((prev) => prev + 1)
        }
    }, [isIntersecting])

    React.useEffect(() => {
        const activePillFilterParam = searchParams.get('filter')
        const activeTabParam = searchParams.get('tab') as TTabs
        if (activePillFilterParam) {
            const badges = pillBadges[activeTabParam || activeTab]
            console.log({ badges })
            const activeBadge = badges.find(
                (badge) => badge.value === activePillFilterParam
            )
            if (activeBadge) {
                setActivePillBadge(activeBadge as TPillBadges)
            }
        }
        if (activeTabParam) {
            setActiveTab(activeTabParam)
        }
    }, [])

    return (
        <Box>
            <Text fontSize="24px">Notifications</Text>

            <Box>
                <Slider element={<NotificationSlider />} />
                <Box display="flex" gridColumnGap="12px" mt="12px">
                    {pillBadges[activeTab].map((label) => {
                        return (
                            <Box
                                border="solid"
                                borderColor="gray250"
                                borderWidth={1}
                                className="cursor-pointer"
                                borderRadius="40px"
                                color={
                                    activePillBadge.value !== label.value
                                        ? 'textTags'
                                        : 'white'
                                }
                                fontSize="12px"
                                px="12px"
                                py="4px"
                                bg={
                                    activePillBadge.value === label.value
                                        ? 'primary'
                                        : 'white'
                                }
                                onClick={() => {
                                    searchParams.set('filter', label.value)
                                    setSearchParams(searchParams, {
                                        replace: true,
                                    })
                                    setActivePillBadge(label as TPillBadges)
                                }}
                            >
                                {label.label} ({label.count})
                            </Box>
                        )
                    })}
                </Box>
                <Box mt="12px">
                    {notifications.map((notification: Notifications) => {
                        return (
                            <NotificationCard
                                ref={lastElementRef}
                                notification={notification}
                            />
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}
