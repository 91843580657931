import React, { useState } from 'react'
import { PillButton } from 'src/components/buttons/pill-button'
import { Box } from 'src/components/utility'

type Props = {
    pills: {
        label: string
        id: string
    }[]
    onClick: ({ label, id }: { label: string; id: string }) => void
    selectedPillId?: string
}

export const PillOptions = (props: Props) => {
    const [activePill, setActivePill] = useState<{
        label: string
        id: string
    }>(
        props.pills.find((pill) => pill.id === props.selectedPillId) ||
            props.pills[0]
    )

    return (
        <Box display="flex" gridColumnGap="12px" className="pill-options">
            {props.pills.map((pill) => (
                <PillButton
                    key={pill.id}
                    onClick={() => {
                        setActivePill(pill)
                        props.onClick(pill)
                    }}
                    color={activePill.id === pill.id ? 'white' : 'primary'}
                    bg={activePill.id === pill.id ? 'primary' : 'white'}
                >
                    {pill.label}
                </PillButton>
            ))}
        </Box>
    )
}
