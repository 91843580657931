import React from 'react'
import { Box, Image, Text } from 'src/components/utility'
import CommentsEmptyImage from 'src/assets/images/comments.svg'

function CommentsEmptyState() {
    return (
        <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gridGap="8px"
            p="16px"
        >
            <Box maxWidth="245px" maxHeight="213px" pt="16px">
                <Image
                    src={CommentsEmptyImage}
                    height="100%"
                    width="100%"
                    alt="comments empty"
                />
            </Box>
            <Text my="0px" color="primary">
                No comments yet
            </Text>
        </Box>
    )
}

export default CommentsEmptyState
