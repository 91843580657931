export const GoBackLibrary = () => (
    <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.15707 0.642966C7.49969 0.290768 8.06542 0.290768 8.40805 0.642966C8.73758 0.981703 8.73758 1.52121 8.40805 1.85995L2.43488 8L8.40805 14.1401C8.73758 14.4788 8.73758 15.0183 8.40805 15.357C8.06542 15.7092 7.49969 15.7092 7.15707 15.357L0.678346 8.6973C0.30072 8.30912 0.30072 7.69088 0.678347 7.3027L7.15707 0.642966Z"
            fill="#022143"
        />
    </svg>
)
