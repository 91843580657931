export function LibraryIco() {
    return (
        <svg
            width={16}
            height={13}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.6 0C.725 0 0 .725 0 1.6v9.6c0 .875.725 1.6 1.6 1.6h12.8c.875 0 1.6-.725 1.6-1.6v-8c0-.875-.725-1.6-1.6-1.6H8L6.4 0H1.6zm0 1.6h4.137l1.6 1.6H14.4v8H1.6V1.6z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
