import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import React from 'react'
import type { NormalizedCacheObject } from '@apollo/client'
import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client'
import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { createClient } from 'graphql-ws'
import { getLocalStorage } from './localstorage'
import { CONFIG_VARS } from 'src/config/constants.config'
import { onError } from '@apollo/client/link/error'
import { logoutUser } from './functions'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

let token = getLocalStorage('authToken')

const { GRAPHQL_ENDPOINT } = CONFIG_VARS

function logoutFunction() {
    logoutUser()
    const navigate = useNavigate()
    navigate('/login')
    toast.error('Your session expired. Please login to continue', {
        className: 'custom-toaster toaster-error',
    })
}

// Initialize the client
const wsLink = new GraphQLWsLink(
    createClient({
        url: `wss://${GRAPHQL_ENDPOINT?.replace('https://', '')}`,
        connectionParams: {
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        },
    })
)

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    httpLink
)

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const isUnauthorized = graphQLErrors.some(({ extensions }) => {
            return extensions?.code === 'invalid-jwt'
        })

        if (isUnauthorized) {
            logoutFunction()
        }
    }
})

const gqlClient = new ApolloClient({
    link: errorLink.concat(authLink.concat(splitLink)),
    cache: new InMemoryCache({
        // typePolicies: {
        //     Query: {
        //         fields: {
        //             collections: offsetLimitPagination(),
        //             todos: offsetLimitPagination(),
        //             products: offsetLimitPagination(),
        //         },
        //     },
        // },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
})

// Refresh the token after a successful authentication
const authMiddleware = (token: string, logoutFunction: () => void) => {
    return new ApolloLink((operation, forward) => {
        if (token) {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
        }
        return forward(operation).map((response) => {
            if (
                response.errors &&
                response.errors.some(
                    (error) => error?.extensions?.code === 'invalid-jwt'
                )
            ) {
                logoutFunction()
            }
            return response
        })
    })
}

export const useApolloClient = (token: any) => {
    const [client, setClient] =
        React.useState<ApolloClient<NormalizedCacheObject>>(gqlClient)

    React.useEffect(() => {
        const newWsLink = new GraphQLWsLink(
            createClient({
                url: `wss://${GRAPHQL_ENDPOINT?.replace('https://', '')}`,
                connectionParams: {
                    headers: {
                        authorization: token ? `Bearer ${token}` : '',
                    },
                },
            })
        )

        const newHttpLink = createHttpLink({
            uri: GRAPHQL_ENDPOINT,
        })

        const newSplitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            newWsLink,
            newHttpLink
        )

        ;(async () => {
            const link = authMiddleware(token, logoutFunction).concat(
                newSplitLink
            )
            setClient(
                new ApolloClient({
                    link,
                    cache: new InMemoryCache({
                        // typePolicies: {
                        //     Query: {
                        //         fields: {
                        //             collections: offsetLimitPagination(['id ']),
                        //             todos: offsetLimitPagination(['id']),
                        //             products: offsetLimitPagination(['id']),
                        //         },
                        //     },
                        // },
                    }),
                    connectToDevTools:
                        process.env.NODE_ENV === 'development' ? true : false,
                })
            )
        })()
    }, [token])

    return client
}
