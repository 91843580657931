export const TasksIco = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.26316 1.33333V5.77778H14.7368V1.33333H1.26316ZM1.26316 10.2222V14.6667H14.7368V10.2222H1.26316ZM0.842105 0H15.1579C15.6211 0 16 0.4 16 0.888889V6.22222C16 6.71111 15.6211 7.11111 15.1579 7.11111H0.842105C0.378947 7.11111 0 6.71111 0 6.22222V0.888889C0 0.4 0.378947 0 0.842105 0ZM0.842105 8.88889H15.1579C15.6211 8.88889 16 9.28889 16 9.77778V15.1111C16 15.6 15.6211 16 15.1579 16H0.842105C0.378947 16 0 15.6 0 15.1111V9.77778C0 9.28889 0.378947 8.88889 0.842105 8.88889Z"
            fill="#022143"
        />
    </svg>
)
