import { debugLog } from 'src/helpers';
import { IndividualProductInitialState } from 'src/screens/products/sub-screens/individual-product/product-initial-state';
import type { storeState } from './@types/slider-types';




const productInitialState = [{
    id: 0,
    created_at: "",
    name: '',
    team_id: 0,
    status: "",
    description: '',
    due_date: '',
    tags: [
        {
            id: 0,
            name: '',
            color: ''
        }
    ],
    todos: [{
        name: '',
        due_date: '',
        status: '',
        sub_todos: {
            todo_id: 0,
            heading: '',
            description: '',
            created_at: ''
        },
        description: '',
        id: '',
        tags: [''],
    }],
    approver: {
        name: "",
        email: "",
        files: [
            {
                path: ""
            }
        ]
    },
    creator: {
        name: '',
        files: {
            path: ''
        }
    }
}]



const createProductSlice = (set: Function) => ({
    products: [IndividualProductInitialState],
    clonedProducts: [IndividualProductInitialState],
    new: [IndividualProductInitialState],
    inProgress: [IndividualProductInitialState],
    completed: [IndividualProductInitialState],


    resetProduct: ({ products }: { products: Products[] }) => {
        set((state: storeState) => {
            debugLog(products)
            return {
                ...state,
                products: products,
                clonedProducts: products,
                new: products?.filter(product => {
                    return product.status.toLowerCase() === "new"
                }),
                completed: products?.filter(product => product.status.toLowerCase() === "complete"),
                inProgress: products?.filter(product => (product.status.toLowerCase() === "inprogress" || product.status.toLowerCase() === 'inreview' || product.status.toLowerCase() === 'requestedforresubmission' || product.status.toLowerCase() === 'requestforrevision')),
            }
        })
    },
    resetProductToInitialState: () => {
        set((state: storeState) => {
            return {
                ...state,
                products: productInitialState,
                clonedProducts: productInitialState,
                new: productInitialState,
                completed: productInitialState,
                InProgress: productInitialState,
            }
        })
    },

    filterProducts: ({ action }: { action: "NEW" | "IN-PROGRESS" | "COMPLETED" }) => {
        set((state: storeState) => {
            switch (action) {
                case "NEW":
                    return {
                        ...state, products: state.new
                    }
                case "IN-PROGRESS":
                    return {
                        ...state, products: state.inProgress
                    }
                case "COMPLETED":
                    return {
                        ...state, products: state.completed
                    }
                default:
                    break;
            }
        })

    },
    addNewProduct: ({ payload }: { payload: typeof productInitialState }) => {
        set((state: storeState) => {
            return { ...state, new: [...state.new, payload] }
        })
    },

    sortProducts: ({ action }: { action: "DUE-DATE" | "DATE-CREATED" | "NAME" | "ALL" }) => {
        set((state: storeState) => {

            switch (action) {
                case "DUE-DATE":
                    return { ...state, products: state.products.sort((dateA, dateB) => +new Date(dateA.due_date) - +new Date(dateB.due_date)) }
                case "DATE-CREATED":
                    return { ...state, products: state.products.sort((dateA, dateB) => +new Date(dateB.created_at) - +new Date(dateA.created_at)) }
                case "NAME":
                    return { ...state, products: state.products.slice().sort((aName, bName) => aName.name.toLowerCase().localeCompare(bName.name.toLowerCase())) }
                case "ALL":
                    return { ...state, products: state.clonedProducts }
                default:
                    break;
            }



        })
    }

})

export default createProductSlice
