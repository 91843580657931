import create from 'zustand'

const initialTodoForm: {
    name: string
    dueDate: Date | null
    manager: any
    collection: any | null
    product: any | null
    tags: Array<{ id: string; name: string; color: string }>
    subTodos: Array<any>
} = {
    name: '',
    dueDate: null,
    manager: null,
    tags: [],
    collection: null,
    product: null,
    subTodos: [
        {
            id: Math.floor(Math.random() * 90000) + 10000,
            name: '',
            description: '',
        },
    ],
}

export const useCreateTodoFormStore = create((set: Function) => ({
    todoForm: initialTodoForm,
    updateTodoName: (updatedName: string) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                name: updatedName,
            },
        }))
    },
    updateTodoDueDate: (date: Date) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                dueDate: date,
            },
        }))
    },
    updateTodoTags: ({
        action,
        payload,
    }: {
        action: 'ADD' | 'DELETE' | 'UPDATE'
        payload: any
    }) => {
        if (action === 'ADD') {
            set((state: any) => ({
                ...state,
                todoForm: {
                    ...state.todoForm,
                    tags: [...state.todoForm.tags, payload],
                },
            }))
        } else if (action === 'DELETE') {
            set((state: any) => ({
                ...state,
                todoForm: {
                    ...state.todoForm,
                    tags: state.todoForm.tags.filter(
                        (tag: any) => tag.name !== payload
                    ),
                },
            }))
        } else if (action === 'UPDATE') {
            set((state: any) => ({
                ...state,
                todoForm: {
                    ...state.todoForm,
                    tags: state.todoForm.tags.map((tag: any) =>
                        tag.id === payload.id ? payload : tag
                    ),
                },
            }))
        }
    },
    updateTodoManager: (payload: any) => {
        set((state: any) => ({
            ...state,
            todoForm: { ...state.todoForm, manager: payload },
        }))
    },
    updateCollection: (collection: any) => {
        set((state: any) => ({
            ...state,
            todoForm: { ...state.todoForm, collection },
        }))
    },
    updateProduct: (product: any) => {
        set((state: any) => ({
            ...state,
            todoForm: { ...state.todoForm, product },
        }))
    },
    addSubtodo: (payload: any) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: [...state.todoForm.subTodos, payload],
            },
        }))
    },
    deleteSubtodo: (subtodoId: number) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: state.todoForm.subTodos.filter(
                    (subtodo: any) => subtodo.id !== subtodoId
                ),
            },
        }))
    },
    updateSubtodo: ({
        subtodoId,
        updatedSubtodoData,
    }: {
        subtodoId: number
        updatedSubtodoData: any
    }) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: state.todoForm.subTodos.map((subtodo: any) =>
                    subtodo.id === subtodoId ? updatedSubtodoData : subtodo
                ),
            },
        }))
    },
    updateSubtodoName: ({
        subtodoId,
        updatedName,
    }: {
        subtodoId: number
        updatedName: string
    }) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: state.todoForm.subTodos.map((subtodo: any) =>
                    subtodo.id === subtodoId
                        ? { ...subtodo, name: updatedName }
                        : subtodo
                ),
            },
        }))
    },
    updateSubtodoDescription: ({
        subtodoId,
        updatedDescription,
    }: {
        subtodoId: number
        updatedDescription: string
    }) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: state.todoForm.subTodos.map((subtodo: any) =>
                    subtodo.id === subtodoId
                        ? { ...subtodo, description: updatedDescription }
                        : subtodo
                ),
            },
        }))
    },
    addSubtodoFiles: ({
        subtodoId,
        filesToAdd,
    }: {
        subtodoId: number
        filesToAdd: Array<any>
    }) => {
        set((state: any) => ({
            ...state,
            todoForm: {
                ...state.todoForm,
                subTodos: state.todoForm.subTodos.map((subtodo: any) =>
                    subtodo.id === subtodoId
                        ? {
                              ...subtodo,
                              files: [...subtodo.files, ...filesToAdd],
                          }
                        : subtodo
                ),
            },
        }))
    },
    clearTodoForm: () => {
        set((state: any) => ({
            ...state,
            todoForm: initialTodoForm,
        }))
    },
}))
