import { useLazyQuery } from '@apollo/client'
import React, { useState } from 'react'
import ProfileImgWithName from 'src/components-v2/UI/profile-img-with-name'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { FilledCloseIco, PeopleIco } from 'src/components/svg-icons'
import { Box, Button, Input } from 'src/components/utility'
import { debounce, getImageUrl } from 'src/helpers'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { Users } from 'src/screens/products/sub-screens/product-form'
import { GetOrgMemberByEmail } from 'src/services/graphql/query/get-team-by-id'

function AssignUser({
    assignee,
    onAssign,
    onRemove,
    role,
    showIcon = true,
}: {
    assignee: any
    onAssign: (user: any) => void
    onRemove: () => void
    showIcon?: boolean
    role: 'Manager' | 'Approver' | 'Assignee'
}) {
    const [query, setQuery] = useState('')
    const [managers, setManagers] = useState<ManagerUser[]>([])
    const [listOpen, setListOpen] = useState<boolean>(false)
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const [findSearchUser] = useLazyQuery(GetOrgMemberByEmail)

    const debouncedFetchMembers = debounce(
        (searchStr: string) => {
            setListOpen(true)
            if (!searchStr) {
                setManagers([])
                setListOpen(false)
                return
            }
            findSearchUser({
                variables: {
                    searchStr: `%${searchStr}%`,
                },
                fetchPolicy: 'no-cache',
                onCompleted: (data: any) => {
                    const users = data?.organisation_members
                    setManagers(users)
                },
            })
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    function updateAssigneeInput(e: React.ChangeEvent<HTMLInputElement>) {
        setQuery(e.target.value)
        debouncedFetchMembers(e.target.value.toLowerCase())
    }

    return (
        <Box
            position="relative"
            display="flex"
            alignItems="center"
            gridColumnGap="4px"
            p="4px"
        >
            {showIcon && (
                <Box
                    bg="#F8FAFB"
                    py="2px"
                    px="4px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="4px"
                    width={'32px'}
                    height={'14px'}
                >
                    <PeopleIco color="#778CA2" />
                </Box>
            )}
            {!assignee?.id ? (
                <Input
                    autoComplete="false"
                    required
                    bg="transparent"
                    value={query || assignee?.email}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.preventDefault()
                        e.stopPropagation()
                        updateAssigneeInput(e)
                    }}
                    color="primary"
                    fontSize="16px"
                    placeholder={`${role} Name`}
                    className="input-border placeholder-light placeholder-text-sm"
                    py="4px"
                    px="4px"
                    width="120px"
                />
            ) : (
                <HtmlTooltip
                    placement="bottom"
                    arrow
                    title={<ToolTipHeader text={role} />}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        border="1px solid #E8ECEF"
                        borderRadius="4px"
                        gridGap="4px"
                        px="4px"
                        overflow={'hidden'}
                    >
                        <ProfileImgWithName
                            imageSrc={
                                assignee.other_data?.profile_pic
                                    ? getImageUrl(
                                          assignee.other_data.profile_pic
                                      )
                                    : ''
                            }
                            name={assignee.name}
                        />
                        <Button
                            border="none"
                            borderRadius="50%"
                            bg="transparent"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onRemove()
                                setQuery('')
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            type="button"
                            px={'0px'}
                        >
                            <FilledCloseIco />
                        </Button>
                    </Box>
                </HtmlTooltip>
            )}
            {query && listOpen && (
                <Users
                    users={managers}
                    onClickUser={(manager: ManagerUser) => {
                        setListOpen(false)
                        setQuery('')
                        onAssign(manager.user)
                    }}
                />
            )}
        </Box>
    )
}

export default AssignUser
