import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Box, Button, Input, Text } from 'src/components/utility'
import { GetProductsByCollectionId } from '../compare-entity-topbar.queries'
import { CrossSm, SearchIco } from 'src/components/svg-icons'
import { debounce } from 'src/helpers'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import ProductDropdownContainer from './product-dropdown-container'

function ProductDropdown({
    onClose,
    collectionName,
    onSelect,
    collectionId,
    isCompareEntitySelector,
}: {
    onClose: () => void
    onSelect: (product: {
        id: number
        name: string
        todos: Array<{ id: number; name: string; types: string }>
    }) => void
    collectionId: number | undefined
    collectionName: string
    isCompareEntitySelector?: boolean
}) {
    const [searchInput, setSearchInput] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const { data } = useQuery(GetProductsByCollectionId, {
        skip: !collectionId,
        variables: {
            id: collectionId,
            searchQuery: `%${searchQuery}%`,
        },
        fetchPolicy: 'no-cache',
    })

    const wrapperRef = useClickedOutside(
        () => onClose(),
        ['topbar-product-dropdown-button']
    )
    const [timeoutRef, setTimeoutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const debouncedUpdateSearch = debounce(
        (query: string) => {
            setSearchQuery(query)
        },
        400,
        setTimeoutRef,
        timeoutRef
    )

    function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchInput(e.target.value)
        debouncedUpdateSearch(e.target.value)
    }

    return (
        <Box
            position="absolute"
            width={'615px'}
            height={'314px'}
            bg="white"
            top="24px"
            left={
                isCompareEntitySelector
                    ? '-300px'
                    : collectionName.length < 20
                    ? '-150px'
                    : '-200px'
            }
            zIndex={15}
            border="1px solid #E0E0E0"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            p="16px"
            overflow={'auto'}
            gridGap="16px"
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            ref={wrapperRef}
        >
            <Text my="0px" fontSize={'12px'} color="textSecondary">
                Choose product
            </Text>
            <Box
                display="flex"
                gridGap="8px"
                borderRadius="4px"
                bg="white"
                border="1px solid #DDDDDD"
                py="8px"
                px="12px"
                width="100%"
                alignItems="center"
            >
                <SearchIco />
                <Input
                    placeholder="Search"
                    border="none"
                    bg="transparent"
                    value={searchInput}
                    onChange={searchChangeHandler}
                    width="100%"
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        setSearchInput('')
                        debouncedUpdateSearch('')
                    }}
                    bg="transparent"
                    border="none"
                    fontStyle="Rubik"
                    fontSize="12px"
                    color="#027AC3"
                    position="relative"
                    width="max-content"
                >
                    <CrossSm />
                </Button>
            </Box>
            {data && data?.products.length > 0 ? (
                data?.products?.map((product: any) => {
                    return (
                        <ProductDropdownContainer
                            product={product}
                            onSelect={onSelect}
                        />
                    )
                })
            ) : (
                <Text textAlign="center">No products found</Text>
            )}
        </Box>
    )
}

export default ProductDropdown
