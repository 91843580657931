import { gql } from '@apollo/client'

export const GetCollections = gql`
    query GetCollections(
        $offset: Int
        $limit: Int
        $status: collection_status_enum_comparison_exp = {}
        $order_by: [collections_order_by!] = { name: asc }
        $due_date: timestamptz_comparison_exp = {}
        $manager_ids: bigint_comparison_exp = {}
    ) {
        all: collections_aggregate(
            where: { due_date: $due_date, manager_id: $manager_ids }
            order_by: $order_by
        ) {
            aggregate {
                count
            }
        }
        new: collections_aggregate(
            where: {
                status: { _eq: new }
                due_date: $due_date
                manager_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: collections_aggregate(
            where: {
                status: { _eq: inProgress }
                due_date: $due_date
                manager_id: $manager_ids
            }
        ) {
            aggregate {
                count
            }
        }
        completed: collections_aggregate(
            where: {
                status: { _eq: complete }
                due_date: $due_date
                manager_id: $manager_ids
            }
            order_by: $order_by
        ) {
            aggregate {
                count
            }
        }
        collections(
            order_by: $order_by
            offset: $offset
            limit: $limit
            where: {
                status: $status
                due_date: $due_date
                manager_id: $manager_ids
            }
        ) {
            id
            name
            status
            due_date
            is_delayed
            team {
                id
                name
                team_members {
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
            total_products: product_variants_aggregate {
                aggregate {
                    count
                }
            }
            completed_products: product_variants_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const SearchCollections = gql`
    query SearchCollection($query: String, $status: collection_status_enum) {
        collections(
            where: { name: { _ilike: $query }, status: { _eq: $status } }
        ) {
            id
            name
        }
    }
`
