import * as React from 'react'

export const PaintIco = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m8.794 4-.907.906 1.165 1.166-4.469 4.47a1.954 1.954 0 0 0 0 2.785l.065.066 4.08 4.08a1.956 1.956 0 0 0 2.786 0l4.923-4.923.454-.453-6.284-6.284-.518-.517-.13-.13L8.794 4Zm1.165 2.98 5.118 5.117-1.425 1.425H6.59l-1.101-1.1a.705.705 0 0 1 0-.972l4.47-4.47Zm7.709 7.385-.519.777s-.323.517-.712 1.1a5.92 5.92 0 0 0-.453.907c-.13.324-.26.519-.26.908 0 1.036.907 1.943 1.944 1.943 1.036 0 1.943-.907 1.943-1.943 0-.39-.13-.648-.259-.972-.13-.324-.323-.648-.453-.907-.324-.583-.712-1.101-.712-1.101l-.52-.712Z"
            fill="#778CA2"
        />
    </svg>
)
