import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Modal } from 'src/components/modals/modal'
import { Box, Image } from 'src/components/utility'

export interface IImagePreviewRendererProps {
    imageSource: string
    isModalOpen: boolean
    setIsModalOpen: (isModalOpen: boolean) => void
}

export default function ImagePreviewRenderer({
    imageSource,
    isModalOpen,
    setIsModalOpen,
}: IImagePreviewRendererProps) {
    const [searchParams] = useSearchParams()
    const linkRef = React.useRef<HTMLAnchorElement | null>(null)
    return (
        <Modal
            isModal={isModalOpen}
            onClick={() => {
                if (linkRef.current) {
                    linkRef.current.click()
                }
                setIsModalOpen(false)
            }}
            onClose={() => {
                setIsModalOpen(false)
            }}
            primaryButtonTitle="Download"
            secondaryButtonTitle="Cancel"
            title="Preview Render"
        >
            <Box p="24px">
                <a
                    href={imageSource}
                    download={searchParams.get('name') + '.png' || 'image.png'}
                    ref={linkRef}
                    style={{
                        visibility: 'hidden',
                    }}
                />
                <Image
                    className="img-clear img-clear-borde"
                    src={imageSource}
                    width="600px"
                    height="auto"
                />
            </Box>
        </Modal>
    )
}
