export const entityParserV2 = ({ text }: { text: string }) => {
    const pattern = /<@([A-Z_]+)([a-zA-Z0-9-]*)&([^>]*)?>/g
    const result: { [k: string]: { id: string; name: string } } = {}

    let match
    while ((match = pattern.exec(text)) !== null) {
        const [_, type, id, name] = match

        const entityType = type.toLowerCase()

        const lastLetter = entityType[entityType.length - 1]
        result[
            lastLetter === 's'
                ? entityType.substring(0, entityType.length - 1)
                : entityType
        ] = {
            id: id,
            name: name || '',
        }
    }

    return result
}
