import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import {
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from 'src/components/utility'
import { getFormattedDate } from 'src/helpers'

export const CollectionDetailsTable = ({
    teamDetails,
}: {
    teamDetails: Collections | any
}) => {
    return (
        <Table
            overflow="hidden"
            bg="panelBackground"
            fontFamily="Rubik"
            width="100%"
        >
            <Thead color="textTags" position="relative">
                <Th
                    pb="0px"
                    width="30px"
                    fontSize="12px"
                    textAlign="start"
                    fontWeight={400}
                >
                    Products
                </Th>
                <Th
                    pl="0px"
                    pb="0px"
                    width="auto"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Created by
                </Th>
                <Th
                    pl="0px"
                    pb="0px"
                    width="auto"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Development Cycle
                </Th>
                <Th
                    pb="0px"
                    width="auto"
                    px="0px"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Company
                </Th>
                <Th
                    pb="0px"
                    width="auto"
                    px="0px"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Brand
                </Th>
                <Th
                    pb="0px"
                    width="auto"
                    px="0px"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Date Created
                </Th>
                <Th
                    pb="0px"
                    width="auto"
                    px="0px"
                    fontSize="12px"
                    textAlign="left"
                    fontWeight={400}
                >
                    Last Modified
                </Th>
            </Thead>
            <Tbody>
                <Tr cursor="default" fontSize="12px">
                    <Td width="max-content" cursor="default" pt="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            position="relative"
                            width="80px"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        000
                                    </Text>
                                    <Skeleton
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <Text my="0px" className="elipses">
                                {teamDetails && teamDetails?.products?.length}
                            </Text>
                        </Box>
                    </Td>

                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                            width="130px"
                            alignItems="center"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={teamDetails?.creator?.name}
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {teamDetails && teamDetails?.creator?.name}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                            width="130px"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={
                                            teamDetails &&
                                            teamDetails?.development_cycle
                                        }
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {(teamDetails &&
                                        teamDetails?.development_cycle) ||
                                        'Not defined'}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                            width="130px"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={
                                            (teamDetails &&
                                                teamDetails?.company) ||
                                            'Not defined'
                                        }
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {(teamDetails && teamDetails?.company) ||
                                        'Not defined'}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                            width="130px"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={
                                            (teamDetails &&
                                                teamDetails?.brand) ||
                                            'Not defined'
                                        }
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {(teamDetails && teamDetails?.brand) ||
                                        'Not defined'}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                            width="130px"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={
                                            teamDetails &&
                                            getFormattedDate(
                                                teamDetails.created_at,
                                                'FORMATTED'
                                            )
                                        }
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {teamDetails &&
                                        getFormattedDate(
                                            teamDetails.created_at,
                                            'FORMATTED'
                                        )}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                    <Td width="max-content" cursor="default" pt="0px" px="0px">
                        <Box
                            cursor="default"
                            alignSelf="flex-start"
                            height="100%"
                            display="flex"
                            justifyContent="start"
                            position="relative"
                        >
                            {!teamDetails && (
                                <>
                                    <Text
                                        fontSize="12px"
                                        my="0px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        loading...
                                    </Text>
                                    <Skeleton
                                        height="16px"
                                        count={1}
                                        containerClassName="loader"
                                    />
                                </>
                            )}
                            <HtmlTooltip
                                placement="bottom-start"
                                arrow
                                title={
                                    <ToolTipHeader
                                        text={
                                            teamDetails &&
                                            getFormattedDate(
                                                teamDetails.updated_at,
                                                'FORMATTED'
                                            )
                                        }
                                    />
                                }
                            >
                                <Text
                                    my="0px"
                                    className="elipses cursor-pointer"
                                >
                                    {teamDetails &&
                                        getFormattedDate(
                                            teamDetails.updated_at,
                                            'FORMATTED'
                                        )}
                                </Text>
                            </HtmlTooltip>
                        </Box>
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    )
}

export function ToolTipHeader({ text }: { text: string }) {
    return (
        <Box
            boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
            display="flex"
            flexDirection="column"
            p="0px"
            zIndex={10}
            overflowX="hidden"
            bg="white"
            borderRadius="4px"
            className="img-details"
            px="8px"
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            <Text color="primary">{text}</Text>
        </Box>
    )
}
