import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CloseIco } from '../svg-icons'

export const Toaster = () => {
    return (
        <ToastContainer
            closeButton={<CloseIco color="#778CA2" />}
            icon={false}
            hideProgressBar={true}
            position="top-center"
            limit={3}
            autoClose={1500}
            toastStyle={{
                width: '100%',
                alignItems: 'center',
                top: '24px',
            }}
            bodyStyle={{
                height: '100%',
                padding: '0px',
                margin: '0px',
                fontSize: '14px',
                color: '#022143',
                lineHeight: '17px',
                fontFamily: 'Rubik',
            }}
        ></ToastContainer>
    )
}
