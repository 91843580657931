import { Highlighted } from 'src/components/predective-output/highlighted-text'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { CollectionIco, ProductIco, TodoIco } from 'src/components/svg-icons'
import { Box, Image, Text } from 'src/components/utility'
import type { TSearchedTodos } from '../@types/search'
import useStore from 'src/store/hooks/useStore'
import { getImageUrl } from 'src/helpers'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { useRecentSearch } from '../hooks/useRecentSearch'
import { useNavigate } from 'react-router-dom'

type TTodoVariantProps = {
    entity: TSearchedTodos
    onCardClick?: () => void
}

export function TodoVariant(props: TTodoVariantProps) {
    const { globalSearchFilters, setGlobalSearchActive } = useStore()
    const { entity } = props
    const { addRecentSearch } = useRecentSearch()
    const navigate = useNavigate()
    if (!entity) return <></>
    return (
        <Box
            color="textSecondaryDarkBlue"
            display="flex"
            gridRowGap="8px"
            className="cursor-pointer"
            borderBottom="solid"
            borderColor="gray250"
            borderWidth={1}
            py="8px"
            px="4px"
            justifyContent="space-between"
            onClick={() => {
                setGlobalSearchActive({ isGlobalSearchActive: false })
                props.onCardClick && props.onCardClick()
                addRecentSearch({
                    entity_name: 'todos',
                    entity_id: entity.id,
                    search_query: globalSearchFilters.query,
                })
                navigate('/todo/' + entity.id)
            }}
        >
            <Box display="flex" flexDirection="column" gridRowGap="8px">
                <Box display="flex" gridColumnGap="12px" alignItems="center">
                    <TodoIco color="#778CA2" />
                    <Highlighted
                        search={globalSearchFilters.query}
                        text={entity?.name || ''}
                        unHighlighted="textSecondaryDarkBlue"
                    />
                </Box>
                <Box display="flex" alignItems="center" gridColumnGap="16px">
                    {entity.product?.collection && (
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            alignItems="center"
                        >
                            <CollectionIco color="#778CA2" />
                            <Text my="0px" fontSize="14px">
                                {convertIntoElipses(
                                    entity.product?.collection?.name,
                                    16
                                )}
                            </Text>
                        </Box>
                    )}
                    {entity.product ? (
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            alignItems="center"
                        >
                            <ProductIco color="#778CA2" />
                            <Text my="0px" fontSize="14px">
                                {convertIntoElipses(entity.product?.name, 16)}
                            </Text>
                        </Box>
                    ) : (
                        <Text my="0px" fontSize="12px">
                            No Product{' '}
                        </Text>
                    )}
                    {entity.user?.name ? (
                        <Box
                            display="flex"
                            gridColumnGap="8px"
                            alignItems="center"
                        >
                            <ProfileImg
                                fontSize="12px"
                                size="20px"
                                src={getImageUrl(
                                    entity.user?.other_data?.profile_pic
                                )}
                                name={entity.user?.name}
                            />

                            <Text my="0px" fontSize="14px">
                                {entity.user?.name}
                            </Text>
                        </Box>
                    ) : (
                        <Text my="0px" fontSize="12px">
                            No Assignee{' '}
                        </Text>
                    )}
                </Box>
            </Box>
            <Image
                borderRadius="4px"
                width="auto"
                height="52px"
                src={getImageUrl(entity.other_data?.cover_pic?.gid)}
            />
        </Box>
    )
}
