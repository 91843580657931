import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Button } from 'src/components/utility'
import { TeamSliderTabs } from 'src/helpers/enums'

export interface ITeamTabSliderProps {
    onTabChange: ({
        tab,
        index,
    }: {
        tab: 'collection' | 'products' | 'collaborators'
        index: number
    }) => void
    currentTab: 'collection' | 'products' | 'collaborators'
    isTabView: boolean
    collectionCount: number
    productsCount: number
    collaboratorsCount: number
    onAddCollaboratorClick: (isClicked: boolean) => void
}

export default function TeamTabSlider(props: ITeamTabSliderProps) {
    const { onAddCollaboratorClick, isTabView = true } = props
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs
                value={props.currentTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(_event: any, newValue: any) => {
                    searchParams.set('drawerTab', newValue)
                    setSearchParams(searchParams, { replace: true })
                    props.onTabChange({
                        tab: newValue,
                        index:
                            props.currentTab === 'collection'
                                ? 0
                                : props.currentTab === 'products'
                                ? 1
                                : 2,
                    })
                }}
                aria-label="collection-tabs"
                classes={{
                    root: 'collection-slider-root',
                }}
            >
                <Tab
                    onClick={() => {}}
                    label={`Collection (${props.collectionCount})`}
                    value={TeamSliderTabs.collection}
                />
                <Tab
                    label={`Product (${props.productsCount})`}
                    value={TeamSliderTabs.products}
                />
                <Tab
                    label={`Collaborators (${props.collaboratorsCount})`}
                    value={TeamSliderTabs.collaborators}
                />
            </Tabs>
            {isTabView && (
                <Button
                    onClick={() => onAddCollaboratorClick(true)}
                    color="links"
                    bg="transparent"
                    border="none"
                >
                    + Add Collaborators
                </Button>
            )}
        </Box>
    )
}

// create a forwardRef for this component
