import { formatDistance, subDays } from 'date-fns'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAnnotationFileImageUrl } from 'src/components/annotation-comments/annotation-comments.helper'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Image, Text } from 'src/components/utility'
import { commentParser, getImageUrl } from 'src/helpers'
import { entityInfoParser } from 'src/helpers/dashboard-entity-info-parser'
import { EntityDetails } from './entityInfo'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { _3DFiles } from 'src/helpers/enums'
import { HtmlTooltip } from 'src/components/stacked-image/stacked-image'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'

interface ICommentCardProps {
    comment: TAnnotationComment | TMentionedComment
    type: 'annotations' | 'comments'
}

export default function CommentCard(props: ICommentCardProps) {
    const { comment, type } = props
    const { getLastOpenedTab, setLastOpened, removeLastOpenedTab } =
        useCardExpandedState()
    const navigate = useNavigate()
    const tokens = comment?.entity_info?.split('/') || []
    const productToken = comment.entity_info?.split('/')[1]
    const productInfo =
        type === 'annotations' && productToken
            ? entityInfoParser(productToken)
            : undefined
    const entityInfo =
        tokens.length > 0
            ? entityInfoParser(tokens[tokens.length - 1])
            : undefined
    const fileName = entityInfo ? entityInfo.blocks[0]?.name || '' : ''

    React.useEffect(() => {
        const tab = getLastOpenedTab('comment')
        if (tab) {
            const card = document.getElementById(
                `dashboard_comment_card_${tab}`
            )
            if (card) {
                card.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                removeLastOpenedTab()
            }
        }
    }, [])

    function createNavigator(
        type: 'annotations' | 'comments',
        isLibraryFile: boolean,
        entityInfo: {
            file_id?: string
            library_file_id?: string
            gid?: string
            entity_name?: string
            entity_id?: string
            name?: 'collections' | 'todos' | 'products'
            id: string
            fileName?: string
        }
    ) {
        const filenameChunks = fileName.split('.')
        const extension = filenameChunks[filenameChunks.length - 1]
        console.log({ extension })
        const found = tokens.find((token) => token.match(/TODO/))
        let todoId = '0'
        if (found) {
            todoId = entityInfoParser(found).blocks[0].entityId
        }
        switch (type) {
            case 'annotations':
                if (_3DFiles.includes(extension)) {
                    return navigate(
                        `/file-preview?gid=${entityInfo.gid}&ref=${
                            isLibraryFile ? 'library' : ''
                        }&name=${
                            entityInfo.fileName
                        }&entityName=${entityInfo.entity_name?.toLowerCase()}&entityId=${
                            entityInfo.entity_id
                        }&fileId=${
                            entityInfo.file_id || entityInfo.library_file_id
                        }&showFileDetails=${entityInfo.id}&productId=${
                            productInfo?.blocks[0]?.entityId
                        }&mode=annotation&commentId=${comment.id}`,
                        {
                            state: {
                                coordinates: comment?.coordinates || [],
                            },
                        }
                    )
                } else {
                    navigate(
                        `/todo/${todoId}?sectionId=${
                            entityInfo.entity_id
                        }&fileId=${
                            entityInfo.file_id
                        }&mode=annotate&commentId=${
                            comment.id
                        }&isLibraryFile=${!!entityInfo.library_file_id}`
                    )
                }
                return
            case 'comments':
                if (!entityInfo.name) return
                if (entityInfo.name.toLowerCase() === 'collections') {
                    return navigate(`/collections/${entityInfo.id}`, {
                        state: {
                            isCommentNotification: true,
                        },
                    })
                } else if (entityInfo.name.toLowerCase() === 'todos') {
                    return navigate(`/todo/${entityInfo.id}`, {
                        state: {
                            isCommentNotification: true,
                        },
                    })
                }
                if (entityInfo.entity_name?.toLowerCase() === 'products') {
                    return navigate(`/collections/product/${entityInfo.id}`, {
                        state: {
                            isCommentNotification: true,
                        },
                    })
                }
                if (
                    entityInfo.entity_name?.toLowerCase() === 'product_variants'
                ) {
                    return navigate(`/collections/product/${entityInfo.id}`, {
                        state: {
                            isCommentNotification: true,
                        },
                    })
                }
        }
    }

    function createSubText(comment: TAnnotationComment | TMentionedComment) {
        if (type === 'annotations') {
            return comment.repliedComment
                ? 'replied to your annotation'
                : `annotated on "${fileName}"`
        } else {
            return comment.repliedComment
                ? 'replied to your comment'
                : 'mentioned you in the comment'
        }
    }

    const distance =
        comment.created_at || comment.created_at
            ? formatDistance(
                  subDays(new Date(comment.created_at), 0),
                  new Date(),
                  { addSuffix: true }
              )
            : ''

    if (!comment.id) return <></>
    return (
        <Box
            className={
                String(getLastOpenedTab('comment')) === String(comment.id)
                    ? 'card-hover card-bg cursor-pointer'
                    : 'card-hover cursor-pointer'
            }
            id={`dashboard_comment_card_${comment.id}`}
            border="solid"
            borderColor="gray250"
            borderWidth={1}
            px="16px"
            py="12px"
            onClick={() => {
                console.log(comment)
                setLastOpened({
                    entityId: Number(comment.id),
                    type: 'comment',
                })
                createNavigator(
                    comment.self_name as 'comments' | 'annotations',
                    type === 'annotations' && entityInfo
                        ? entityInfo.blocks[0].entityName === 'LIBRARY_FILES'
                            ? true
                            : false
                        : false,
                    {
                        file_id: comment.file_id || comment.library_file_id,
                        gid: entityInfo && entityInfo.blocks[0]?.entityId,
                        entity_id: comment.entity_id,
                        entity_name: comment.entity_name,
                        id:
                            type === 'annotations' && productInfo
                                ? productInfo.blocks[0].entityId
                                : comment.entity_id,
                        fileName,
                        name: (comment.entity_name || '') as
                            | 'collections'
                            | 'todos'
                            | 'products',
                    }
                )
            }}
            display="flex"
            gridColumnGap="8px"
        >
            <Box display="flex" alignItems="start" pt="12px">
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    <Box
                        bg="#4D7CFE"
                        borderRadius="50%"
                        width="6px"
                        height="6px"
                    />
                    <ProfileImg
                        name={comment.user?.name || ''}
                        src={getImageUrl(comment.user?.other_data?.thumbnail)}
                    />
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                gridColumnGap="8px"
                mt="12px"
                width="100%"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                >
                    <Box display="flex" gridColumnGap="8px" alignItems="center">
                        <Text
                            fontSize="14px"
                            fontWeight={500}
                            my="0px"
                            gridColumnGap="8px"
                        >
                            {comment.user?.name || ''}
                        </Text>
                        <Text my="0px" fontSize="14px">
                            {createSubText(comment)}
                        </Text>
                    </Box>
                    <Text
                        color="textSecondaryDarkBlue"
                        fontSize="12px"
                        my="0px"
                    >
                        {distance}
                    </Text>
                </Box>
                {type === 'annotations' &&
                    comment.repliedComment &&
                    'parent' in comment && (
                        <Text
                            fontSize="14px"
                            color="textSecondaryDarkBlue"
                            mt="12px"
                            mb="4px"
                        >
                            "
                            {
                                commentParser(comment?.parent.comment || '')
                                    .parsed
                            }
                            "
                        </Text>
                    )}
                <Box>
                    <Text
                        fontSize="14px"
                        my={
                            !(
                                type === 'annotations' &&
                                comment.repliedComment &&
                                'parent' in comment
                            )
                                ? '12px'
                                : '0px'
                        }
                    >
                        "{commentParser(comment?.comment || '').parsed}"
                    </Text>
                    <Box display="flex" gridColumnGap="8px">
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_media.map(
                                (media, key) => {
                                    if (key < 2) {
                                        const nameChunks = media.name.split('.')
                                        const extension =
                                            nameChunks[nameChunks.length - 1]
                                        if (
                                            extension !== 'jpg' &&
                                            extension !== 'jpeg' &&
                                            extension !== 'png'
                                        ) {
                                            return (
                                                <HtmlTooltip
                                                    placement="bottom-start"
                                                    arrow
                                                    title={
                                                        <ToolTipHeader
                                                            text={
                                                                media?.name ||
                                                                ''
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        my="4px"
                                                    >
                                                        <ExtensionIcon
                                                            size="lg"
                                                            type={extension}
                                                        />
                                                    </Box>
                                                </HtmlTooltip>
                                            )
                                        }
                                        return (
                                            <HtmlTooltip
                                                placement="bottom-start"
                                                arrow
                                                title={
                                                    <ToolTipHeader
                                                        text={media?.name || ''}
                                                    />
                                                }
                                            >
                                                <Image
                                                    src={getAnnotationFileImageUrl(
                                                        media.gid,
                                                        'media'
                                                    )}
                                                    alt={media.name}
                                                    width="48px"
                                                    height="48px"
                                                    borderRadius="4px"
                                                />
                                            </HtmlTooltip>
                                        )
                                    }
                                    return <></>
                                }
                            )}
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_file_media &&
                            comment.other_data?.assigned_media?.length <= 0 &&
                            comment.other_data?.assigned_file_media.map(
                                (media, key) => {
                                    if (key < 2) {
                                        return (
                                            <Image
                                                src={getAnnotationFileImageUrl(
                                                    media.gid,
                                                    'file_media'
                                                )}
                                                alt={media.name}
                                                width="48px"
                                                height="48px"
                                                borderRadius="4px"
                                            />
                                        )
                                    }
                                    return <></>
                                }
                            )}
                        {comment.other_data?.assigned_media &&
                            comment.other_data?.assigned_media?.length > 2 && (
                                <Box
                                    border="solid"
                                    borderRadius="4px"
                                    borderWidth={1}
                                    borderColor="textSecondaryDarkBlue"
                                    bg="gray250"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize="12px"
                                    width="48px"
                                    height="48px"
                                >
                                    +{' '}
                                    {comment.other_data.assigned_media.length +
                                        comment.other_data.assigned_file_media
                                            ?.length -
                                        2}
                                </Box>
                            )}
                    </Box>
                    <Box mt="8px" display="flex" gridColumnGap="12px">
                        {tokens.map((token: string, key) => {
                            const { blocks } = entityInfoParser(token)
                            if (key > 3) return
                            if (blocks[0]?.entityName === 'PRODUCTS')
                                return <></>
                            return (
                                <EntityDetails
                                    nameLimit={30}
                                    entityInfo={blocks[0]}
                                />
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
