import { useNavigate } from 'react-router-dom'
import {
    Delay,
    InProgress,
    InReview,
    New,
    RequestRevision,
    Tag,
    ToBeReviewed,
} from 'src/components'
import { CalendarIcoSm } from 'src/components/svg-icons'
import { Box, Span, Text } from 'src/components/utility'
import { getFormattedDate, statuses, useUser } from 'src/helpers'

export const TodoProductApprovalCard = ({ data }: { data: any }) => {
    const dateString = getFormattedDate(data.due_date)
    const navigate = useNavigate()
    const { userId, role: _role } = useUser()

    function navigateToTodo() {
        navigate(
            `/collections/product/${data.id}?name=${data.name}&collectionId=${data.collection.id}`,
            {
                state: {
                    collection: data.collection,
                    ...data,
                },
            }
        )
    }

    return (
        <>
            <Box py={'16px'}>
                <Box
                    display="flex"
                    alignItems="flex-start"
                    mb={data.tags && data.tags.length ? '8px' : '0px'}
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        maxWidth="calc(100% - 110px)"
                    >
                        <Text
                            my={'0px'}
                            className="pointer"
                            onClick={() => {
                                navigateToTodo()
                            }}
                            fontSize={'16px'}
                            mr="8px"
                        >
                            <Span mr={'8px'}> Product Approval </Span>
                            {data.name && (
                                <Span
                                    color="textSecondaryDarkBlue"
                                    fontSize={'14px'}
                                    mr={'8px'}
                                >
                                    <Span className="pointer" my={'0px'}>
                                        {' '}
                                        {data?.collection?.name}
                                    </Span>
                                    {data?.collection?.name ? ' / ' : null}
                                    <Span className="pointer" my={'0px'}>
                                        {' '}
                                        {data?.name}{' '}
                                    </Span>
                                </Span>
                            )}

                            {data.status === statuses.new && (
                                <Span display="inline-block">
                                    <New />
                                </Span>
                            )}

                            {data.status === statuses.inReview &&
                                Number(userId) !== data.approver_id && (
                                    <Span display="inline-block">
                                        <InReview />
                                    </Span>
                                )}

                            {data.status === statuses.inReview &&
                                Number(userId) === data.approver_id && (
                                    <Span display="inline-block">
                                        {' '}
                                        <ToBeReviewed />{' '}
                                    </Span>
                                )}

                            {data.is_delayed === true &&
                                data.status !== statuses.inReview && (
                                    <Span display="inline-block">
                                        <Delay />
                                    </Span>
                                )}
                            {data.is_delayed === false &&
                                data.status === statuses.inProgress && (
                                    <Span display="inline-block">
                                        <InProgress />
                                    </Span>
                                )}

                            {data.status ===
                                statuses.requestedForResubmission && (
                                <Span display="inline-block">
                                    <RequestRevision />
                                </Span>
                            )}
                        </Text>
                    </Box>

                    <Box
                        minWidth="100px"
                        display="flex"
                        justifyContent="end"
                        textAlign="right"
                    >
                        <Box
                            display="flex"
                            gridColumnGap="8px"
                            alignItems="center"
                        >
                            <CalendarIcoSm />
                            <Box position="relative">
                                <Text
                                    my="0px"
                                    fontSize="12px"
                                    color={'primary'}
                                >
                                    {dateString}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {data.tags && (
                    <Box display="flex" gridGap="8px" alignItems="center">
                        {data.tags &&
                            data.tags.map(
                                (
                                    tag: {
                                        name: string
                                        tag?: string
                                        color: string
                                    },
                                    key: number
                                ) => {
                                    if (key < 2) {
                                        return (
                                            <Tag
                                                key={key}
                                                element={tag.name || tag.tag}
                                                color={tag.color}
                                            />
                                        )
                                    }
                                }
                            )}

                        {data.tags && data.tags.length > 3 && (
                            <Box
                                width={'24px'}
                                height={'24px'}
                                borderRadius="50%"
                                border="solid"
                                borderWidth={2}
                                borderColor="gray350"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bg="white"
                                fontFamily="Rubik"
                                color="textSecondaryDarkBlue"
                                fontSize={'14px'}
                            >
                                +{data.tags.length - 2}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </>
    )
}
