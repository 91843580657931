export const sort: Record<'due_date' | 'name' | 'created_at', object> = {
    due_date: {
        due_date: 'asc',
    },
    name: {
        name: 'asc',
    },
    created_at: {
        created_at: 'desc',
    },
}
