import { gql } from '@apollo/client'

export const GetTodos = gql`
    query GetTodos(
        $limit: Int = 100
        $offset: Int = 0
        $status: status_enum_comparison_exp = {}
        $order_by: [todos_order_by!] = { name: asc }
        $due_date: timestamptz_comparison_exp = {}
        $assignee_ids: bigint_comparison_exp = {}
        $organisation_id: bigint!
    ) {
        all: todos_aggregate(
            where: {
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
        ) {
            aggregate {
                count
            }
        }
        new: todos_aggregate(
            where: {
                status: { _eq: new }
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: todos_aggregate(
            where: {
                status: { _eq: inProgress }
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: todos_aggregate(
            where: {
                status: { _eq: inReview }
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
        ) {
            aggregate {
                count
            }
        }
        completed: todos_aggregate(
            where: {
                status: { _eq: complete }
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
        ) {
            aggregate {
                count
            }
        }
        todos(
            limit: $limit
            offset: $offset
            where: {
                status: $status
                due_date: $due_date
                user_id: $assignee_ids
                product_variant: { collection: { id: { _is_null: false } } }
            }
            order_by: $order_by
        ) {
            id
            name
            other_data
            due_date
            status
            is_delayed
            product_variant {
                id
                name
                other_data
                collection {
                    id
                    name
                    manager_id
                }
            }
            assignee {
                id
                name
                email
                other_data
                organisation_members(
                    where: { organisation_id: { _eq: $organisation_id } }
                ) {
                    role
                }
            }
        }
    }
`
