import React, { useEffect, useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { CollectionBadge } from '../badges/collection-badge'
import { Dots, SuccessTickSm } from 'src/components/svg-icons'
import CalendarDropdown from '../cards/dropdowns/calendar'
import StatusDropdown from '../cards/dropdowns/status'
import type { Team } from 'src/services/graphql/query/@types/collections'
import {
    HtmlTooltip,
    StackedImage,
} from 'src/components/stacked-image/stacked-image'
import CollectionProductsContainer from '../cards/collection/collection-products-container'
import {
    useDeleteCollection,
    useUpdateCollection,
} from '../cards/hooks/useUpdateCollection'
import useStore from 'src/store/hooks/useStore'
import { AddCollaboratorModal } from 'src/screens-v2/organisations-analytics/modal/add-collaborator/add-collaborator'
import MeatBallDropdown from '../cards/dropdowns/meatball-dropdown'
import { copyToClipBoard, statuses } from 'src/helpers'
import { toast } from 'react-toastify'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { DangerModal } from 'src/components/modals/danger-modal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecentSearch } from '../global-search/hooks/useRecentSearch'
import { ENTITIES } from 'src/helpers/enums'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'
import { scrollToCard } from 'src/helpers/scroll-to-card'
import { scrollToSection } from 'src/helpers/scroll-to-section'

type CollectionListCardProps = {
    onClick?: () => void
    collection: {
        id: number
        name: string
        productsCount: {
            total: number
            completed: number
        }
        dueDate: string | null
        status: string
        isDelayed: boolean
        team: Team | null | undefined
    }
    isFullCard?: boolean
    cardId?: string
}

function CollectionListCard({
    collection,
    cardId,
    isFullCard = true,
    onClick,
}: CollectionListCardProps) {
    const [searchParams] = useSearchParams()
    const [componentVisibility, setComponentVisibility] = useState({
        productsList: false,
        menuDropdown: false,
        collaboratorsModal: false,
        deleteModal: false,
    })
    const {
        updateCollectionStatus,
        updateCollectionDueDate,
        updateCollection,
        updateCollectionTeamAndItsProductsTeamHandler,
    } = useUpdateCollection()
    const { deleteCollection } = useDeleteCollection()
    const { addRecentSearch } = useRecentSearch()
    const {
        updateGlobalSearchResults,
        removeCollection,
        globalSearchFilters,
        setGlobalSearchActive,
        clearDrawerTabs,
    } = useStore()

    const {
        addValuesToExpanded,
        getExpandedStates,
        setLastOpened,
        getLastOpenedTab,
        removeLastOpenedTab,
    } = useCardExpandedState()

    const navigate = useNavigate()
    const eHash = searchParams.get('e_hash')

    function dropdownMenuClickHandler(option: string) {
        switch (option) {
            case 'Delete':
                setComponentVisibility({
                    ...componentVisibility,
                    deleteModal: true,
                })
                return
            case 'Copy Link':
                copyToClipBoard(
                    window.location.host + '/collections/' + collection.id
                )
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                return
        }
    }

    function handleTabExpand() {
        addValuesToExpanded(
            {
                id: collection.id,
                isExpanded: !componentVisibility.productsList,
            },
            'collection'
        )
    }

    useEffect(() => {
        const data = getExpandedStates()
        if (data && data.collection) {
            if (data.collection[collection.id]) {
                setComponentVisibility({
                    ...componentVisibility,
                    productsList: data.collection[collection.id],
                })
            }
        }
    }, [eHash])

    const tab = getLastOpenedTab('collection')
    useEffect(() => {
        if (!tab || tab !== collection.id) return
        if (isFullCard) {
            scrollToSection(`collection_card_${tab}`)
        } else {
            // Scrolling in dashboard
            scrollToCard(`collection_card_${tab}`)
        }
    }, [tab])

    return (
        <Box
            key={collection.id + collection.name}
            id={cardId || `collection_card_${collection.id}`}
        >
            <Box
                display="grid"
                gridTemplateColumns={{
                    md: '1fr auto',
                    xl: `1fr  ${isFullCard ? '55%' : 'max-content'}`,
                }}
                gridColumnGap={'38px'}
                border="1px solid #dddddd"
                px={'16px'}
                py="8px"
                alignItems="center"
                className={
                    String(tab) === String(collection.id)
                        ? 'card-hover card-bg cursor-pointer'
                        : 'card-hover cursor-pointer'
                }
                onClick={() => {
                    onClick && onClick()
                    setLastOpened({
                        entityId: collection.id,
                        type: 'collection',
                    })
                    setGlobalSearchActive({
                        isGlobalSearchActive: false,
                    })
                    globalSearchFilters.isGlobalSearchActive &&
                        addRecentSearch({
                            entity_name: ENTITIES.collections,
                            entity_id: String(collection.id),
                            search_query: globalSearchFilters.query,
                        })
                    navigate(`/collections/${collection.id}`)
                    clearDrawerTabs()
                }}
            >
                <Box
                    display="grid"
                    gridTemplateColumns={'auto minmax(100px,1fr)'}
                    gridGap="24px"
                    alignItems="center"
                >
                    <CollectionBadge />
                    <Text
                        fontSize={'14px'}
                        my="0px"
                        className="text-ellipsis"
                        title={collection.name}
                    >
                        {collection.name}
                    </Text>
                </Box>
                <Box
                    display="grid"
                    gridTemplateColumns={
                        isFullCard
                            ? '112px 100px 100px 160px 80px 24px'
                            : '100px 100px'
                    }
                    gridGap="16px"
                    alignItems="center"
                    gridColumnGap={!isFullCard ? '24px' : '16px'}
                    justifyContent={!isFullCard ? 'end' : 'space-between'}
                    pr={!isFullCard ? '16px' : '0px'}
                >
                    {isFullCard && (
                        <HtmlTooltip
                            placement="bottom"
                            arrow
                            title={<ToolTipHeader text={'Completion'} />}
                        >
                            <Box
                                gridColumnGap="8px"
                                display="flex"
                                alignItems="center"
                                border="1px solid"
                                borderColor="primary"
                                borderRadius="2px"
                            >
                                <SuccessTickSm />
                                <Text fontSize="12px" my="0px" color="primary">
                                    {collection.productsCount.completed}/
                                    {collection.productsCount.total}{' '}
                                    {collection.productsCount.total > 1
                                        ? 'Products'
                                        : 'Product'}
                                </Text>
                            </Box>
                        </HtmlTooltip>
                    )}
                    <CalendarDropdown
                        date={
                            collection.dueDate
                                ? new Date(collection.dueDate)
                                : null
                        }
                        left={!isFullCard ? '-245px' : '0px'}
                        setDate={(date) => {
                            updateCollectionDueDate({
                                id: collection.id,
                                dueDate: date.toISOString(),
                                onComplete: () => {
                                    updateGlobalSearchResults({
                                        type: ENTITIES.collections,
                                        updatedKey: 'due_date',
                                        value: date.toISOString(),
                                        id: String(collection.id),
                                    })
                                },
                            })
                        }}
                        isDelayed={isEntityDueDateDelayed(
                            collection.dueDate,
                            collection.status as keyof typeof statuses
                        )}
                        isListCard
                    />
                    <StatusDropdown
                        exclude={[
                            statuses.revisionRequested,
                            statuses.requestedForResubmission,
                            statuses.inReview,
                            statuses.toBeReviewed,
                        ]}
                        left={!isFullCard ? '-20px' : '0px'}
                        onStatusChange={(status) => {
                            updateCollectionStatus({
                                id: collection.id,
                                status: status,
                                onComplete: () =>
                                    updateGlobalSearchResults({
                                        type: ENTITIES.collections,
                                        updatedKey: 'status',
                                        value: status,
                                        id: String(collection.id),
                                    }),
                            })
                        }}
                        status={collection.status}
                    />
                    {isFullCard ? (
                        !!collection.team ? (
                            <HtmlTooltip
                                placement="bottom"
                                arrow
                                title={<ToolTipHeader text={'Team'} />}
                            >
                                <Box
                                    display="flex"
                                    gridGap="4px"
                                    overflow="hidden"
                                    alignItems="center"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            collaboratorsModal: true,
                                        })
                                    }}
                                >
                                    <Text
                                        my="0px"
                                        fontSize={'12px'}
                                        title={collection.team.name}
                                        className="text-ellipsis"
                                    >
                                        {collection.team.name}
                                    </Text>
                                    <StackedImage
                                        size="24px"
                                        width="max-content"
                                        fontSize="12px"
                                        spacing={15}
                                        list={collection.team.team_members.map(
                                            (member) => ({
                                                name: member.user.name || '',
                                                src:
                                                    member.user.other_data
                                                        ?.profile_pic || '',
                                            })
                                        )}
                                        mr={'0px'}
                                    />
                                </Box>
                            </HtmlTooltip>
                        ) : (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        collaboratorsModal: true,
                                    })
                                }}
                                my="0px"
                                fontSize={'12px'}
                                color="textSecondary"
                                bg="transparent"
                                border="none"
                                textAlign="left"
                            >
                                No Team Assigned
                            </Button>
                        )
                    ) : null}
                    {isFullCard ? (
                        collection.productsCount.total > 0 ? (
                            <Button
                                color="links"
                                border="none"
                                bg="transparent"
                                fontSize="10px"
                                height="100%"
                                px="0px"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setLastOpened({
                                        entityId: collection.id,
                                        type: 'collection',
                                    })
                                    handleTabExpand()
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        productsList:
                                            !componentVisibility.productsList,
                                    })
                                }}
                            >
                                {componentVisibility.productsList
                                    ? 'Hide'
                                    : 'View'}{' '}
                                {collection.productsCount.total} Products
                            </Button>
                        ) : (
                            <Button
                                color="links"
                                border="none"
                                bg="transparent"
                                fontSize="10px"
                                disabled
                                textAlign="left"
                            >
                                No Products
                            </Button>
                        )
                    ) : null}
                    {isFullCard && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setLastOpened({
                                        entityId: Number(collection.id),
                                        type: 'collection',
                                    })
                                    setComponentVisibility({
                                        ...componentVisibility,
                                        menuDropdown:
                                            !componentVisibility.menuDropdown,
                                    })
                                }}
                                bg="transparent"
                                border="none"
                            >
                                <Dots active={false} />
                            </Button>
                            {componentVisibility.menuDropdown && (
                                <MeatBallDropdown
                                    left="unset"
                                    right="0px"
                                    isActive={componentVisibility.menuDropdown}
                                    onClick={(option) => {
                                        setLastOpened({
                                            entityId: Number(collection.id),
                                            type: 'collection',
                                        })
                                        dropdownMenuClickHandler(option)
                                    }}
                                    setIsActive={(isActive) =>
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown: isActive,
                                        })
                                    }
                                    onClose={() =>
                                        setComponentVisibility({
                                            ...componentVisibility,
                                            menuDropdown: false,
                                        })
                                    }
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {componentVisibility.productsList && (
                <CollectionProductsContainer collectionId={collection.id} />
            )}
            {componentVisibility.collaboratorsModal && (
                <AddCollaboratorModal
                    onRoleUpdate={() => {}}
                    teamMembers={[]}
                    onCollaboratorsAdded={() => {}}
                    teamId={String(collection.team?.id)}
                    onTeamAdded={(id) => {
                        updateCollectionTeamAndItsProductsTeamHandler({
                            teamId: Number(id),
                            collectionId: collection.id,
                            refetchQueries: 'active',
                        })
                        setComponentVisibility({
                            ...componentVisibility,
                            collaboratorsModal: false,
                        })
                    }}
                    isModal={componentVisibility.collaboratorsModal}
                    onClose={(isOpen) =>
                        setComponentVisibility({
                            ...componentVisibility,
                            collaboratorsModal: isOpen,
                        })
                    }
                />
            )}
            <DangerModal
                title={`Delete '${collection.name}'`}
                isModal={componentVisibility.deleteModal}
                onRemove={() => {
                    deleteCollection(
                        collection.id,
                        ['getCollections', 'GetCollections'],
                        () => {
                            globalSearchFilters.isGlobalSearchActive &&
                                removeCollection({
                                    id: collection.id,
                                })
                            toast('Collection Deleted Successfully', {
                                className: 'custom-toaster toaster-success',
                            })
                        }
                    )
                }}
                onClose={() =>
                    setComponentVisibility({
                        ...componentVisibility,
                        deleteModal: false,
                    })
                }
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                heading={`Are you sure you want to delete '${collection.name}'`}
                subHeading="Deleting this will remove all the existing To-Do's and Products associated with it. This action cannot be undone."
            />
        </Box>
    )
}

export default React.memo(CollectionListCard)
