import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './_theme'
import './styles/global.style.css'
import './styles/animations.styles.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
    <StrictMode>
        <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </StrictMode>,
    document.getElementById('root')
)

serviceWorkerRegistration.register()
