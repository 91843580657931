import { Box } from '../utility'
import { LogoIco, VirtuIco } from '../svg-icons'
import { useNavigate } from 'react-router-dom'

export const SidebarLogo = ({
    variant = 'full',
}: {
    variant?: 'icon' | 'full'
}) => {
    const navigate = useNavigate()
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="#EAFC40"
            width="100%"
            height="60px"
            className="cursor-pointer"
            onClick={() => navigate('/')}
        >
            {variant === 'full' ? <LogoIco /> : <VirtuIco />}
        </Box>
    )
}
