import { gql } from '@apollo/client'

export const GetProductVariantDetails = gql`
    query getProduct(
        $id: bigint!
        $organisation_id: bigint!
        $order_by: [todos_order_by!] = { name: asc }
        $due_date: timestamptz_comparison_exp = {}
        $assignee_ids: bigint_comparison_exp = {}
    ) {
        product_variants(
            where: { id: { _eq: $id }, collection: { id: { _is_null: false } } }
        ) {
            id
            name
            product_id
            team_id
            is_delayed
            description
            due_date
            approver_due_date
            tags
            is_primary
            status
            approver_id
            other_data
            product {
                id
                name
            }
            product_variant_approver {
                id
                name
                email
                other_data
            }
            collection {
                id
                name
                manager_id
            }
            product_variant_creator {
                id
                name
            }
            team {
                name
                id
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                    }
                }
            }
            created_at
            updated_at
            todos(
                where: { due_date: $due_date, user_id: $assignee_ids }
                order_by: $order_by
            ) {
                id
                name
                status
                due_date
                other_data
                is_delayed
                assignee {
                    email
                    name
                    id
                    other_data
                    organisation_members(
                        where: { organisation_id: { _eq: $organisation_id } }
                    ) {
                        role
                    }
                }
            }
        }
    }
`

export const GetProductTodoImages = gql`
    query GetProductTodoImages($id: bigint!) {
        product_variants_by_pk(id: $id) {
            todos {
                id
                name
                sub_todos {
                    id
                    sub_todo_sections {
                        id
                        files {
                            id
                            file {
                                id
                                gid
                                name
                                path
                                thumbnail
                            }
                            library_file {
                                id
                                gid
                                name
                                path
                                thumbnail
                            }
                        }
                    }
                    files {
                        id
                        file {
                            id
                            gid
                            name
                            path
                            thumbnail
                        }
                        library_file {
                            id
                            gid
                            name
                            path
                            thumbnail
                        }
                    }
                }
            }
        }
    }
`

export const GetProductCollectionId = gql`
    query GetProductCollectionId($id: bigint!) {
        products_by_pk(id: $id) {
            id
            collection {
                id
                name
            }
        }
    }
`

export const GetProductVariantsByProductId = gql`
    query GetProductVariantsByProductId(
        $product_id: bigint!
        $types: todo_types_enum_comparison_exp = { _is_null: true }
    ) {
        product_variants(
            where: { product_id: { _eq: $product_id } }
            order_by: { id: asc }
        ) {
            id
            created_at
            is_primary
            name
            team_id
            is_delayed
            description
            due_date
            approver_due_date
            tags
            status
            other_data
            product {
                id
                name
            }
            approver_id
            product_variant_approver {
                id
                name
                email
                other_data
            }
            collection {
                id
                name
            }
            team {
                name
                id
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                    }
                }
            }
            product_variant_creator {
                id
                name
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
            }
            total_todos: todos_aggregate {
                aggregate {
                    count
                }
            }
            completed_todos: todos_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
            todos(where: { types: $types }) {
                id
                name
            }
        }
    }
`

export const GetBaseProductVariantTechPackByProductId = gql`
    query GetBaseProductVariantTechPackByProductId($product_id: bigint!) {
        product_variants(
            where: {
                product_id: { _eq: $product_id }
                is_primary: { _eq: true }
            }
        ) {
            id
            name
            todos(where: { types: { _eq: techPack } }) {
                id
                name
                types
                creator {
                    id
                    name
                }
                created_at
                updated_at
                assignee {
                    id
                    name
                    other_data
                }
                description
                due_date
                product_variant {
                    id
                    product_id
                    name
                    other_data
                    team {
                        id
                        name
                        team_members {
                            user {
                                other_data
                                name
                                id
                            }
                        }
                    }
                    collection {
                        id
                        name
                    }
                }
                status
                tags
                other_data
                sub_todos(order_by: { created_at: asc }) {
                    id
                    heading
                    design_color
                    sub_todo_sequences {
                        id
                        sequence
                    }
                    files(
                        where: { assigned_as: { _eq: "reference_pic" } }
                        order_by: { id: desc }
                    ) {
                        id
                        file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                            created_at
                            user {
                                id
                                name
                                other_data
                            }
                        }
                    }
                    sub_todo_sections(order_by: { created_at: asc }) {
                        id
                        name
                        links
                        colors
                        reference_points {
                            id
                            coordinates
                        }
                        files(order_by: { created_at: desc }) {
                            id
                            file {
                                path
                                gid
                                name
                                thumbnail
                                id
                                other_data
                                moved_to_lib
                                created_at
                                user {
                                    id
                                    name
                                    other_data
                                }
                            }
                            library_file {
                                path
                                gid
                                name
                                thumbnail
                                id
                                other_data
                                created_at
                                creator {
                                    id
                                    name
                                    other_data
                                }
                            }
                        }
                        other_data
                        description
                    }
                }
            }
        }
    }
`

export const GetProductVariantTeamById = gql`
    query GetProductVariantTeamById($id: bigint!) {
        product_variants_by_pk(id: $id) {
            id
            team {
                id
                name
                team_members {
                    user {
                        other_data
                        name
                        id
                    }
                }
            }
        }
    }
`
