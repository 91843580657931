import { useLazyQuery } from '@apollo/client'
import type { ChartConfiguration } from 'chart.js'
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    SubTitle,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { DivisionLine } from 'src/components'
import { BasicCard } from 'src/components/basic-card/basic-card'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { HoursWorkedIco } from 'src/components/svg-icons/reports/hours-worked'
import { TasksIco } from 'src/components/svg-icons/reports/todos'
import { Box, Span, Text } from 'src/components/utility'
import { prependZero, roles, statuses, useUser } from 'src/helpers'
import { GetCompletedTodosCounts } from 'src/services/graphql/query/reports.queries'
import theme from 'src/_theme'
import HoursWorked from './hours-worked'

const YourPerformance = ({
    durationDropdown,
    durationList,
}: {
    durationDropdown: string[]
    durationList: DurationFilterList
}) => {
    const { userId, role } = useUser()
    const approvedGreen: string = theme.colors.approved
    const designerBarColor: string = theme.colors.borderLine
    const designerBarColorOpacity: string = 'rgba(32,60,90,0.5)'
    const warningColor: string = theme.colors.warning
    const warningColorOpacity: string = 'rgba(239,141,27,0.5)'
    const barColor = role !== roles.designer ? warningColor : designerBarColor
    const barColor2 =
        role !== roles.designer ? warningColorOpacity : designerBarColorOpacity
    const headerTransparent =
        role !== roles.designer ? 'rgba(242, 244, 246, 0.6)' : '#fff'
    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )

    const [taskTabActive, setTaskTabActive] = useState<boolean>(true)
    const [collectionChart, setCollectionsGraph] = useState<Chart>()
    const [productsChart, setProductsGraph] = useState<Chart>()
    const [duration, applyDurationFilter] = useState<string>(
        durationList.thisMonth
    )
    const [getTodosCount, todoCountsData] = useLazyQuery(
        GetCompletedTodosCounts,
        {
            variables: {
                user_id: Number(userId),
                status: statuses.complete,
            },
        }
    )

    const totalTasks =
        todoCountsData?.data?.totalCompleted?.aggregate?.count || 5
    const onTimeTasks = todoCountsData?.data?.ontime?.aggregate?.count || 3
    const delayedTasks = todoCountsData?.data?.delayed?.aggregate?.count || 2
    const onTimePercentage = (onTimeTasks / totalTasks) * 100 || 0

    const inActiveStyle = {
        background: '#FFFFFF',
        border: '1px solid #E8ECEF',
        'border-radius': '4px',
    }

    // borderTop={"1px solid #E8ECEF"}

    const activeStyle =
        role !== roles.designer
            ? {
                  background: 'rgba(242, 244, 246, 0.6)',
                  border: '1px solid transparent',
                  'border-radius': '0px',
              }
            : {
                  background: '#fff',
              }

    // bg={role !== roles.designer && taskTabActive ?  : '#fff'}
    // bg={role !== roles.designer && !taskTabActive ? 'rgba(242, 244, 246, 0.6)' : '#fff'}
    useEffect(() => {
        if (userId) {
            getTodosCount()
        }
    }, [userId])

    useEffect(() => {
        if (collectionChart) {
            collectionChart.destroy()
        }

        const data = {
            labels: ['JAM', 'FEB', 'MAE', 'APR'],
            datasets: [
                {
                    label: 'My First Dataset',
                    data: [65, 59, 80, 81],
                    backgroundColor: ['rgba(57,164,7,0.5)', approvedGreen],
                    borderWidth: 0,
                    barThickness: 32,
                },
            ],
        }

        const config: ChartConfiguration = {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        grid: {
                            color: '#E8ECEF',
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        }

        const ctx: any = document.getElementById('tasksCompleted')
        let chartCreated: Chart = new Chart(ctx, config)
        setCollectionsGraph(chartCreated)
    }, [])

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={'20px'}
            >
                <Text fontSize={'20px'} my="0px">
                    {' '}
                    Your Performance{' '}
                </Text>
                <Box>
                    <Box display="inline-flex">
                        <CustomDropdown
                            label=""
                            left="unset"
                            right="0px"
                            noStyle={false}
                            height="32px"
                            border="1px solid #A7B9D1"
                            showArrow={true}
                            selectedText={duration}
                            items={durationDropdown}
                            onItemClicked={applyDurationFilter}
                        />
                    </Box>
                </Box>
            </Box>

            <BasicCard>
                <Box>
                    <Box
                        display="flex"
                        bg={headerTransparent}
                        height={role !== roles.designer ? '165px' : '125px'}
                    >
                        <Box
                            width="100%"
                            position="relative"
                            className={
                                role !== roles.designer && taskTabActive
                                    ? 'pointer tabNotch'
                                    : role !== roles.designer
                                    ? 'pointer'
                                    : ''
                            }
                            style={!taskTabActive ? inActiveStyle : activeStyle}
                            onClick={() => {
                                setTaskTabActive(true)
                            }}
                            px={'24px'}
                            pt="24px"
                            height="100%"
                        >
                            <Box
                                display={
                                    role !== roles.designer ? 'block' : 'flex'
                                }
                            >
                                <Box width="100%">
                                    <Box
                                        display="flex"
                                        width="100%"
                                        mb={'8px'}
                                        alignItems="center"
                                        gridColumnGap={'12px'}
                                    >
                                        <TasksIco />
                                        <Text my={'0px'} fontSize={'16px'}>
                                            Tasks Completed
                                        </Text>
                                    </Box>

                                    <Box
                                        display="flex"
                                        width="100%"
                                        mb={'8px'}
                                        alignItems="center"
                                        gridColumnGap={'12px'}
                                    >
                                        <Text
                                            my={'0px'}
                                            lineHeight={'48px'}
                                            fontSize={'40px'}
                                        >
                                            {prependZero(totalTasks)}
                                        </Text>
                                        {totalTasks !== 0 && (
                                            <Text my={'0px'} fontSize={'16px'}>
                                                {onTimePercentage}% on time
                                            </Text>
                                        )}
                                    </Box>
                                </Box>

                                {totalTasks !== 0 && (
                                    <Box width="100%">
                                        <Box
                                            display="flex"
                                            width="100%"
                                            gridColumnGap={'4px'}
                                        >
                                            <Box
                                                height="16px"
                                                borderRadius="4px"
                                                bg={barColor}
                                                width={`${onTimePercentage}%`}
                                            >
                                                {' '}
                                            </Box>
                                            <Box
                                                height="16px"
                                                width={`${
                                                    100 - onTimePercentage
                                                }%`}
                                                bg={barColor2}
                                            >
                                                {' '}
                                            </Box>
                                        </Box>
                                        <Box
                                            display="flex"
                                            px={'24px'}
                                            mt={'8px'}
                                            justifyContent="space-between"
                                        >
                                            <Text my={'0px'} fontSize={'12px'}>
                                                {onTimeTasks}{' '}
                                                <Span color="textSecondary">
                                                    - On time
                                                </Span>{' '}
                                            </Text>
                                            <Text my={'0px'} fontSize={'12px'}>
                                                {delayedTasks}{' '}
                                                <Span color="textSecondary">
                                                    - Delayed
                                                </Span>{' '}
                                            </Text>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        {role !== roles.designer && (
                            <Box
                                width="100%"
                                position="relative"
                                className={
                                    !taskTabActive
                                        ? 'pointer tabNotch'
                                        : 'pointer'
                                }
                                onClick={() => {
                                    setTaskTabActive(false)
                                }}
                                p={'24px'}
                                style={
                                    taskTabActive ? inActiveStyle : activeStyle
                                }
                                height="100%"
                            >
                                <Box
                                    display="flex"
                                    mb={'8px'}
                                    alignItems="center"
                                    gridColumnGap={'12px'}
                                >
                                    <HoursWorkedIco />
                                    <Text my={'0px'} fontSize={'16px'}>
                                        Hours Worked
                                    </Text>
                                </Box>
                                <Text my={'0px'} fontSize={'40px'}>
                                    120h 35m
                                </Text>
                            </Box>
                        )}
                    </Box>

                    <DivisionLine />

                    <Box>
                        <Box
                            p={'24px'}
                            position="relative"
                            width="100%"
                            display="block"
                        >
                            <canvas
                                height={'380px'}
                                id="tasksCompleted"
                            ></canvas>
                        </Box>
                    </Box>
                </Box>
            </BasicCard>
            {role === roles.designer && (
                <Box mt={'36px'}>
                    <HoursWorked />
                </Box>
            )}
        </>
    )
}

export default YourPerformance
