import { gql } from "@apollo/client";

export const GetCompletedCollectionsCounts = gql`
query getCompletedCollectionsCounts($from_date: timestamptz! = "2022-01-30T18:29:59.999Z") {
  completed: collections_aggregate(
    where: {
      status: {_eq: complete},
      completed_at: {_gte: $from_date}
    }) {
    aggregate {
      count
    }
  }
  ontime: collections_aggregate(
    where: {
      status: {_eq: complete}, 
      is_delayed : {_eq : false},
      completed_at: {_gte: $from_date}
    }
  ) {
    aggregate {
      count
    }
  }
   delayed: collections_aggregate(
    where: {
      is_delayed : {_eq : true},
      status: {_eq: complete},
      completed_at: {_gte: $from_date}
    }
  ) {
    aggregate {
      count
    }
  }
}
`;


export const GetCompletedProductsCounts = gql`
query getProductsCounts {
  completed: products_aggregate(
    where: {
      status: {_eq: complete}
    }
  ) {
    aggregate {
      count
    }
  }
  ontime: products_aggregate(
    where: {
      status: {_eq: complete},
      is_delayed : {_eq : false}
    }
  ) {
    aggregate {
      count
    }
  }
  delayed: products_aggregate(
    where: {
      is_delayed : {_eq : true},
      status: {_eq: complete}
    }
  ) {
    aggregate {
      count
    }
  }
}
`;

// query MyQuery2 {
//   teams(order_by: {products_aggregate: {count: desc}}, where: {products: {id: {_is_null: false}}}) {
//     id
//     products_aggregate {
//       aggregate {
//         count
//       }
//     }
//   }
// }
// status: {_eq: $product_status},
// is_delayed: {_eq: $is_delayed}


export const GetOnTimeProductCompletionTeams = gql`
query getTeamsWithCompletedProducts(
     $product_status: status_enum = complete,
     $is_delayed: Boolean = false
) {
  teams(
    order_by: {
      products_aggregate: {count: desc}
    }, 
    where: {
      products: {
        id: {_is_null: false}, 
        status: {_eq: $product_status}, 
        is_delayed: {_eq: $is_delayed}
      }
    }
  ) {
    name
    id
    team_members(limit: 5){
      id
      user {
        name
        id
        email
      }
    }
    totalTeamMembers : team_members_aggregate {
      aggregate {
        count
      }
    }
    productsCompleted: products_aggregate(
      where: {
        status: {_eq: $product_status}, 
        is_delayed: {_eq: $is_delayed}
      }
    ) {
      aggregate {
        count
      }
    }
    totalProducts: products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const GetCompletedTodosCounts = gql`
query getTodosCounts(
  $status: status_enum = complete, 
  $_eq1: Boolean = false, 
  $user_id: bigint!
) {
  totalCompleted :  todos_aggregate(
    where: {
      status: {_eq: $status}, 
      assignee: {id: {_eq: $user_id}}
    }
  ) {
    aggregate {
      count
    }
  }
  ontime :  todos_aggregate(
    where: {
      status: {_eq: $status}, 
      is_delayed: {_eq: false}, 
      assignee: {id: {_eq: $user_id}}
    }
  ) {
    aggregate {
      count
    }
  }
  delayed :  todos_aggregate(
    where: {
      status: {_eq: $status}, 
      is_delayed: {_eq: true}, 
      assignee: {id: {_eq: $user_id}}
    }
  ) {
    aggregate {
      count
    }
  }
}

`;