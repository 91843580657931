import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { Input, Box, Text } from 'src/components/utility'
import { CheckboxTick } from 'src/components/svg-icons'

export const CheckboxStyled = styled(Input)`
    position: absolute;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    z-index: 2;
`

const PointerBox = styled(Box)`
    cursor: pointer;
`

export const Checkbox = ({
    label,
    onChange,
    defautlCheck = false,
    checkBoxId,
    checked,
}: {
    label: string | JSX.Element
    onChange?: (x: boolean) => void
    defautlCheck?: boolean
    checkBoxId?: string
    checked?: boolean
}) => {
    const [isChecked, setIsChecked] = useState<boolean>(defautlCheck)

    useEffect(() => {
        if (isChecked !== undefined) {
            setIsChecked(isChecked)
        }
    }, [isChecked])

    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked)
        }
    }, [checked])

    useEffect(() => {
        if (isChecked !== defautlCheck) {
            setIsChecked(defautlCheck)
        }
    }, [defautlCheck])

    const checkbox = useRef<HTMLInputElement>()

    return (
        <Box display="flex">
            <Box
                display="flex"
                className="cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation()
                    setIsChecked(!isChecked)
                    onChange && onChange(!isChecked)
                }}
                alignItems="center"
                gridColumnGap="12px"
            >
                <PointerBox
                    position="relative"
                    width="18px"
                    height="18px"
                    bg={isChecked ? 'primary' : 'white'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="solid"
                    borderRadius="4px"
                    borderColor={isChecked ? 'primary' : 'secondaryLighterBlue'}
                    cursor="pointer"
                    minWidth="18px"
                >
                    <CheckboxStyled
                        bg="green"
                        type="checkbox"
                        ref={checkbox}
                        checked={isChecked}
                        id={'radio-' + (checkBoxId ? checkBoxId : label)}
                        value={checkBoxId}
                        onChange={() => {}}
                    />

                    {isChecked && <CheckboxTick />}
                </PointerBox>
                <Text
                    mt="0px"
                    mb="0px"
                    fontFamily="Rubik"
                    fontSize="14px"
                    color={'primary'}
                >
                    {label}
                </Text>
            </Box>
        </Box>
    )
}
