import { StrokedArrow } from '../svg-icons'
import { Box, Button } from '../utility'

export const TransparentLeftArrow = ({ onClick }: { onClick: () => void }) => {
    return (
        <Box
            position="absolute"
            height="100%"
            className="pointer"
            onClick={onClick}
            display="flex"
            alignItems="center"
            top="0"
            left="0"
        >
            <Box
                position="relative"
                width="25px"
                height="100%"
                bg="transparent"
            ></Box>
            <Box width="25px" bg="transparent" position="absolute" zIndex={1}>
                <Button
                    border="none"
                    width="auto"
                    px="8px"
                    display="flex"
                    justifyContent="end"
                    bg="transparent"
                >
                    <StrokedArrow
                        color="#022143"
                        style={{ transform: 'rotate(180deg)' }}
                    />
                </Button>
            </Box>
        </Box>
    )
}
