import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DropdownBadge } from 'src/components'
import { CustomDropdown } from 'src/components/custom-dropdown/custom-dropdown'
import { CloseIco, Grid, List, SearchIco } from 'src/components/svg-icons'
import { RefreshIco } from 'src/components/svg-icons/library/refresh'
import { Box, Input } from 'src/components/utility'
import { debounce } from 'src/helpers'
import { FiltersModal } from './modals/filters'
import { libraryDefaultKeys } from '../library.constants'

export const Actions = ({
    setListView,
    refreshContent,
    listView,
    selectFilesView = false,
}: {
    setListView: (x: boolean) => void
    refreshContent: () => void
    listView?: boolean
    selectFilesView?: boolean
}) => {
    const dropItems = {
        name: 'Name',
        dateCreated: 'Date Created',
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [sortValue, setSortFilter] = useState<string>(dropItems.dateCreated)
    const [search, setSearch] = useState<string>('')
    const [open, openModal] = useState(false)
    const searchStr = searchParams.get(libraryDefaultKeys.searchStr)
    const showActivity = searchParams.get(libraryDefaultKeys.showActivity)
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()
    const debounceFn = debounce(
        (str: string) => {
            searchParams.set(libraryDefaultKeys.searchStr, str)
            setSearchParams(searchParams, { replace: true })
        },
        500,
        setTimeOutRef,
        timeOutRef
    )

    useEffect(() => {
        setListView(false)
        if (searchParams.has(libraryDefaultKeys.listview)) {
            const currentListView = searchParams.get(
                libraryDefaultKeys.listview
            )
            if (currentListView !== '1') {
                searchParams.delete(libraryDefaultKeys.listview)
                setSearchParams(searchParams, { replace: true })
            }
        }
    }, [])

    useEffect(() => {
        setSearch(searchStr || '')
    }, [searchStr])

    useEffect(() => {
        searchParams.set(libraryDefaultKeys.sort, sortValue)
        setSearchParams(searchParams, { replace: true })
    }, [sortValue])

    return (
        <>
            <Box
                py={'16px'}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Box position="relative">
                        <Box position="absolute" top="10px" left="12px">
                            <SearchIco color="#778CA2" />
                        </Box>
                        <Input
                            autoComplete="false"
                            placeholder="Search files & folders"
                            bg="white"
                            borderRadius="4px"
                            px="48px"
                            py="12px"
                            minWidth="480px"
                            fontSize="14px"
                            border="solid"
                            borderWidth={1}
                            borderColor="gray250"
                            className="placeholder-light"
                            type="text"
                            required
                            value={search}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setSearch(e.target.value)
                                debounceFn(e.target.value)
                            }}
                        />
                        {search && (
                            <Box
                                onClick={() => {
                                    setSearch('')
                                    debounceFn('')
                                }}
                                className="pointer"
                                display="flex"
                                position="absolute"
                                top="13px"
                                right="14px"
                            >
                                <CloseIco color="#778CA2" />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" gridColumnGap="18px">
                    <Box id="mylibrary-filters-dropdown" display="inline-flex">
                        <DropdownBadge
                            label={''}
                            highlightedText={'Filters'}
                            onclick={() => {
                                openModal(true)
                            }}
                            noStyle={false}
                            showArrow={true}
                        />
                    </Box>
                    <Box id="mylibrary-sort-dropdown" display="inline-flex">
                        <CustomDropdown
                            label="Sort:"
                            selectedText={sortValue}
                            items={[dropItems.name, dropItems.dateCreated]}
                            onItemClicked={setSortFilter}
                        />
                    </Box>
                    {!selectFilesView && (
                        <>
                            <Box
                                id="mylibrary-gridview-box"
                                display="inline-flex"
                                className="cursor-pointer"
                                onClick={() => {
                                    setListView(false)
                                    if (
                                        searchParams.has(
                                            libraryDefaultKeys.listview
                                        )
                                    ) {
                                        searchParams.delete(
                                            libraryDefaultKeys.listview
                                        )
                                        setSearchParams(searchParams, {
                                            replace: true,
                                        })
                                    }
                                }}
                            >
                                <Grid active={!listView} />
                            </Box>
                            <Box
                                id="mylibrary-listview-box"
                                display="inline-flex"
                                className="cursor-pointer"
                                onClick={() => {
                                    setListView(true)
                                    searchParams.set(
                                        libraryDefaultKeys.listview,
                                        '1'
                                    )

                                    setSearchParams(searchParams, {
                                        replace: true,
                                    })
                                }}
                            >
                                <List active={listView} />
                            </Box>
                            <Box
                                id="mylibrary-history-box"
                                display="inline-flex"
                                className="cursor-pointer"
                                onClick={() => {
                                    if (
                                        searchParams.has(
                                            libraryDefaultKeys.showActivity
                                        )
                                    ) {
                                        searchParams.delete(
                                            libraryDefaultKeys.showActivity
                                        )
                                        setSearchParams(searchParams, {
                                            replace: true,
                                        })
                                    } else {
                                        searchParams.set(
                                            libraryDefaultKeys.showActivity,
                                            '1'
                                        )
                                        setSearchParams(searchParams, {
                                            replace: true,
                                        })
                                    }
                                }}
                            >
                                <RefreshIco
                                    active={
                                        showActivity && showActivity === '1'
                                            ? true
                                            : false
                                    }
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            {open && <FiltersModal setModal={openModal} refetch={() => {}} />}
        </>
    )
}
