import { Line } from 'rc-progress'
import { Box, Text } from '../utility'

export const SuspenseLoading = ({
    loadingText = 'Sit back and relax, your file will be loaded shortly...',
    progress = 0,
}: {
    loadingText?: string
    progress?: number
}) => {
    return (
        <Box
            flexDirection="column"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="300px"
        >
            <Box className="model-loader"></Box>{' '}
            <Box mt="24px">
                <Text fontSize="20px">{loadingText}</Text>
            </Box>
            {progress ? (
                <Box display="flex" gridColumnGap="8px" alignItems="center">
                    <Line
                        percent={progress}
                        strokeWidth={1}
                        trailWidth={1}
                        style={{ width: '100%' }}
                        strokeColor="#022143"
                        trailColor="#02214310"
                    />
                    <Text fontSize="16px" color="primary">
                        {Math.floor(progress)}%
                    </Text>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    )
}
