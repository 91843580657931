import { format } from 'date-fns'
import type { SyntheticEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Dots } from 'src/components/svg-icons'
import { FolderSmIco } from 'src/components/svg-icons/library/folder-sm'
import {
    Box,
    Button,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Image,
} from 'src/components/utility'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { FileCard } from './file-card'
import { FolderCard } from './folder-card'
import {
    FBX_LG,
    GLB_LG,
    OBJ_LG,
    PDF_LG,
    SVG_LG,
    ZFAB_LG,
    ZPRJ_LG,
} from 'src/components/svg-icons/folder-icons/large-icons'
import { copyToClipBoard, roles, useUser } from 'src/helpers'
import { Dropdown, DropdownButtonWrapper } from 'src/components'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { toast } from 'react-toastify'
import { DownloadZip } from 'src/services'
import { useMutation } from '@apollo/client'
import { LoadMoreCard } from 'src/components/load-more-card/loadmore'
import { LibraryPerPage, PdfFileTypes } from 'src/helpers/enums'
import RenameFileModal from 'src/components-v2/modals/rename-file-modal'
import { ExtensionIcon } from 'src/components-v2/extension-icon/extension-icon'

export const FolderFilesView = ({
    listView = false,
    folders = [],
    files = [],
    combinedData = [],
    name,
    deleteFolderModal,
    editFolderModal,
    deleteFileModal,
    runFavMutation,
    runDeleteFavMutation,
    selectFilesFlow = false,
    setCurrentPage,
    currentPage,
    totalCount,
}: {
    listView: boolean
    folders?: FolderObject[]
    files?: FileObject[]
    combinedData?: any[]
    name: string
    deleteFolderModal?: (folderId: number) => void
    editFolderModal?: (folder: any) => void
    deleteFileModal?: (fileId: number) => void
    runFavMutation?: (folderId: number | null, fileId: number | null) => void
    runDeleteFavMutation?: (id: number) => void
    selectFilesFlow?: boolean
    setCurrentPage?: (page: number) => void
    currentPage?: number
    totalCount?: number
}) => {
    const { role } = useUser()
    const params = useParams()
    const entityName = params.entityName
    const entityId = params.entityId

    const [isDropDown, setIsDropdown] = useState(false)
    const dropDownRef = useRef<any>(null)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [renameFileModal, setRenameFileModal] = useState<{
        id: number | null
        name: string
    }>({
        id: null,
        name: '',
    })

    const showFileId = searchParams.get(libraryDefaultKeys.showFileDetails)
    const showFolderId = searchParams.get(libraryDefaultKeys.showFolderDetails)
    const parentTodoId = searchParams.get('parentTodoId')
    const [fileFolderIdFromSearchParams, setFileFolderIdFromSearchParams] =
        useState({ fileId: '', folderId: '' })

    //If the URL has file/folder Id, which is basically when a file/folder link is copied and opened, we use it for highlighting and then strip it from the URL
    useEffect(() => {
        // const fileId = searchParams.get('fileId')
        // const folderId = searchParams.get('folderId')
        // if (fileId) {
        //     setFileFolderIdFromSearchParams({
        //         ...fileFolderIdFromSearchParams,
        //         fileId,
        //     })
        //     searchParams.delete('fileId')
        // } else if (folderId) {
        //     setFileFolderIdFromSearchParams({
        //         ...fileFolderIdFromSearchParams,
        //         folderId,
        //     })
        //     searchParams.delete('folderId')
        // }
        // const newUrl = `${window.location.pathname}?${searchParams.toString()}`
        // window.history.replaceState({}, '', newUrl)
    }, [])

    const isFav = name === 'favourites' ? true : false
    const _isFavorite = isFav

    const defaultDropItems =
        roles.designer === role
            ? [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
              ]
            : [
                  { name: 'Copy Link', id: 'COPY_LINK' },
                  { name: 'View Details', id: 'VIEW_DETAILS' },
                  // { name: "Archive Folder", id: "ARCHIVED_FOLDER" },
                  !_isFavorite
                      ? { name: 'Mark as Favourite', id: 'MARK_AS_FAV' }
                      : {
                            name: 'Remove from Favourite',
                            id: 'UN_MARK_FROM_FAV',
                        },
              ]

    const dropItems: any = {
        file: [
            ...defaultDropItems,
            { name: 'Download File', id: 'DOWNLOAD_FILE' },
            roles.designer !== role && {
                name: 'Edit Details',
                id: 'EDIT_FILE',
            },
            {
                name: 'Rename File',
                id: 'RENAME_FILE',
            },
            roles.designer !== role && {
                name: 'Delete File',
                id: 'DELETE_FILE',
            },
        ],
        folder: [
            ...defaultDropItems,
            { name: 'Download Files', id: 'DOWNLOAD_FILES' },
            roles.designer !== role && {
                name: 'Edit Details',
                id: 'EDIT_FOLDER',
            },
            roles.designer !== role && {
                name: 'Delete Folder',
                id: 'DELETE_FOLDER',
            },
        ],
    }

    const [listDropdown, openListDropdown] = useState<any>({})

    const [DownloadZipMutation] = useMutation(DownloadZip)

    function disableDropdown(event: any) {
        if (dropDownRef !== null && dropDownRef.current !== null) {
            const isClickInsideElement = dropDownRef.current.contains(
                event.target
            )
            if (
                !isClickInsideElement &&
                event.target?.tagName !== 'BUTTON' &&
                event.target?.tagName !== 'svg' &&
                event.target?.tagName !== 'path'
            ) {
                setIsDropdown(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    let listViewData = []
    if (listView) {
        listViewData = combinedData.length
            ? combinedData
            : folders.length
            ? folders
            : files
    }

    const routerChanged = (card: any) => {
        if (card && card.id) {
            if (!card.type || card.type === 'FOLDER') {
                if (selectFilesFlow) {
                    let _url: string = `/library/selectfiles/${entityId}/${entityName}/${card.id}`
                    if (parentTodoId && parentTodoId !== '0') {
                        _url += `?parentTodoId=${parentTodoId}`
                    }
                    navigate(_url)
                    return
                }
                const categoryId =
                    card.category_id ||
                    searchParams.get(libraryDefaultKeys.categoryId)
                navigate(
                    `/library/folder/${card.id}?${
                        libraryDefaultKeys.categoryId
                    }=${categoryId || 'all'}`
                )
            } else {
                const pathTokens = card.path.split('.')
                const isfileTypePdfOrAi = PdfFileTypes.includes(
                    pathTokens[pathTokens.length - 1]
                )
                if (isfileTypePdfOrAi) {
                    return navigate('/pdf-viewer' + `/${card.gid}`)
                }
                const url = getLibraryFileUrl(card.path || card.url)
                const redirectionUrl = `/file-preview`
                let _url: string = `${redirectionUrl}?url=${url}&name=${encodeURIComponent(
                    card.name
                )}`
                if (card.id) {
                    _url += `&fileId=${card.id}&${libraryDefaultKeys.showFileDetails}=${card.id}`
                }
                window.open(window.location.origin + `${_url}`, '_blank')
                // navigate(`/file-preview?url=${url}&name=${card.name}`);
                //    if (!card.name.includes('.png') && !card.name.includes('.jpeg') && !card.name.includes('.jpg')) {
                //        window.location.reload();
                //    }
            }
        }
    }

    const downLoadFiles = (item: any) => {
        const noOfFiles = item?.noOfFiles?.aggregate?.count || 0
        const noOfFolders = item?.noOfFolders?.aggregate?.count || 0
        if (!noOfFiles && !noOfFolders) {
            toast("Can't download the empty folder.", {
                className: 'custom-toaster toaster-info',
            })
            return
        }

        DownloadZipMutation({
            variables: {
                entity_name: 'folders',
                entity_id: item.id,
            },
            onCompleted: (data) => {
                toast(
                    'Please wait, We have started zipping your files, you will be notified when its done.',
                    {
                        className: 'custom-toaster toaster-info',
                    }
                )
            },
            onError: (data) => {
                toast('Failed to download files. Please try again.', {
                    className: 'custom-toaster toaster-error',
                    closeButton: false,
                })
            },
        })
    }

    const showFileName = (name: string, card: any) => {
        if (!card.type || card.type === 'FOLDER') {
            return name
        }
        const type = name?.split('.')[name.split('.').length - 1]
        const fileName =
            name.split('.')[0].length > 15
                ? name.split('.')[0].slice(0, 15) + '...' + type
                : name
        return fileName || name
    }

    const handleDropItems = (dItem: any, item: any) => {
        if (!dItem.id) {
            return
        }

        switch (dItem.id) {
            case 'VIEW_DETAILS':
                if (!item.type || item.type === 'FOLDER') {
                    if (searchParams.has(libraryDefaultKeys.showFileDetails)) {
                        searchParams.delete(libraryDefaultKeys.showFileDetails)
                    }
                    searchParams.set(
                        libraryDefaultKeys.showFolderDetails,
                        item.id.toString()
                    )
                } else {
                    if (
                        searchParams.has(libraryDefaultKeys.showFolderDetails)
                    ) {
                        searchParams.delete(
                            libraryDefaultKeys.showFolderDetails
                        )
                    }
                    searchParams.set(
                        libraryDefaultKeys.showFileDetails,
                        item.id.toString()
                    )
                }
                setSearchParams(searchParams)
                const ele: HTMLElement | null =
                    window.document.getElementById('layoutContainer2')
                ele?.scrollTo(0, 0)
                break

            case 'COPY_LINK':
                const url = new URL(window.location.href)
                url.searchParams.set(item.type ? 'fileId' : 'folderId', item.id)
                copyToClipBoard(url.href)
                toast.success('Link copied to clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                break

            case 'DELETE_FOLDER':
                deleteFolderModal && deleteFolderModal(item.id)
                break

            case 'DELETE_FILE':
                deleteFileModal && deleteFileModal(item.id)
                break

            case 'DOWNLOAD_FILES':
                downLoadFiles(item)
                break

            case 'EDIT_FILE':
                navigate('/library/editfile/' + item.id)
                break

            case 'EDIT_FOLDER':
                editFolderModal && editFolderModal(item)
                break

            case 'MARK_AS_FAV':
                if (runFavMutation) {
                    if (!item.type || item.type === 'FOLDER') {
                        runFavMutation(item.id, null)
                    } else {
                        runFavMutation(null, item.id)
                    }
                }
                break

            case 'DOWNLOAD_FILE':
                const _url = getLibraryFileUrl(item.gid || item.url)
                openInNewTab(_url)
                break

            case 'RENAME_FILE':
                setRenameFileModal({ id: item.id, name: item.name })
                break

            case 'UN_MARK_FROM_FAV':
                let favId = item.favourite_id
                if (item.library_favourites && item.library_favourites[0]) {
                    favId = item.library_favourites[0].id
                }
                favId && runDeleteFavMutation && runDeleteFavMutation(favId)
                break

            default:
                break
        }
    }

    const showImage = (folder: any) => {
        return folder?.user?.other_data?.profile_pic
            ? getImageUrl(folder?.user?.other_data?.profile_pic)
            : ''
    }

    const showThumbNail = (
        name: string,
        path: string = '',
        other_data: any
    ) => {
        const type = name?.split('.')[name.split('.').length - 1]?.toLowerCase()

        let url = null
        if (other_data && other_data?.entity_name) {
            url = getImageUrl(path)
        } else {
            url = getLibraryFileUrl(path)
        }

        return (
            <>
                {' '}
                {type === 'jpeg' || type === 'jpg' || type === 'png' ? (
                    <Image
                        width="32px"
                        height="28px"
                        style={{
                            objectFit: 'cover',
                        }}
                        src={url}
                    />
                ) : (
                    <>
                        {type === 'svg' && <SVG_LG />}
                        {type === 'pdf' && <PDF_LG />}
                        {type === 'obj' && <OBJ_LG />}
                        {type === 'zfab' && <ZFAB_LG />}
                        {(type === 'glb' || type === 'gltf') && <GLB_LG />}
                        {type === 'zprj' && <ZPRJ_LG />}
                        {type === 'fbx' && <FBX_LG />}
                    </>
                )}
            </>
        )
    }

    const addClasses = (card: any, id: string) => {
        let msg: string = `tr-hover `
        if (card && card.id) {
            if (card.type && card.type !== 'FOLDER') {
                if (showFileId?.toString() === card.id.toString()) {
                    msg += ` viewdetails`
                }
                if (fileFolderIdFromSearchParams.fileId === id) {
                    msg += ` __notification__highlight-primary`
                }
            } else if (!card.type || card.type === 'FOLDER') {
                if (showFolderId?.toString() === card.id.toString()) {
                    msg += ` viewdetails`
                }
                if (fileFolderIdFromSearchParams.folderId === id) {
                    msg += ` __notification__highlight-primary`
                }
            }
            return msg
        }
    }

    return (
        <>
            {!listView && files && files.length ? (
                <>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        width="100%"
                        gridGap="24px"
                    >
                        {files.map((item: FileObject, index: number) => {
                            const highlightFile =
                                fileFolderIdFromSearchParams.fileId ===
                                String(item.id)
                            return (
                                <>
                                    <FileCard
                                        isFavorite={isFav}
                                        data={item}
                                        key={name + 'file2' + index + item.id}
                                        deleteFileModal={deleteFileModal}
                                        runDeleteFavMutation={
                                            runDeleteFavMutation
                                        }
                                        runFavMutation={runFavMutation}
                                        highlightFile={highlightFile}
                                        renameFileModal={setRenameFileModal}
                                    />
                                </>
                            )
                        })}
                        {totalCount &&
                            totalCount > LibraryPerPage &&
                            files.length < totalCount && (
                                <LoadMoreCard
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                    </Box>
                </>
            ) : null}

            {!listView && folders && folders.length ? (
                <Box display="flex" flexWrap="wrap" width="100%" gridGap="24px">
                    {folders.map((item: FolderObject, index: number) => {
                        // const highlightFolder =
                        //     fileFolderIdFromSearchParams.folderId ===
                        //     String(item.id)
                        return (
                            <Box
                                minWidth="276px"
                                key={name + 'folder2' + index + item.id}
                            >
                                <FolderCard
                                    isFavorite={isFav}
                                    runFavMutation={runFavMutation}
                                    runDeleteFavMutation={runDeleteFavMutation}
                                    deleteFolderModal={deleteFolderModal}
                                    data={item}
                                    selectFilesFlow={selectFilesFlow}
                                    // highlightFolder={highlightFolder}
                                />
                            </Box>
                        )
                    })}
                    {totalCount &&
                        totalCount > LibraryPerPage &&
                        folders.length < totalCount && (
                            <LoadMoreCard
                                listView={listView}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                </Box>
            ) : null}

            {!listView && combinedData && combinedData.length ? (
                <Box display="flex" flexWrap="wrap" width="100%" gridGap="24px">
                    {combinedData.map((item: any, index: number) => {
                        const _item: FileObject = item.type !== 'FOLDER' && item
                        const highlightFolder =
                            fileFolderIdFromSearchParams.folderId ===
                                String(item.id) && item.type === 'FOLDER'
                        const highlightFile =
                            fileFolderIdFromSearchParams.fileId ===
                                String(item.id) && item.type === 'FILE'
                        return (
                            <>
                                {item.type === 'FOLDER' ? (
                                    <Box
                                        minWidth="276px"
                                        key={'folder' + index}
                                    >
                                        <FolderCard
                                            isFavorite={isFav}
                                            runFavMutation={runFavMutation}
                                            runDeleteFavMutation={
                                                runDeleteFavMutation
                                            }
                                            deleteFolderModal={
                                                deleteFolderModal
                                            }
                                            data={item}
                                            selectFilesFlow={selectFilesFlow}
                                            // highlightFolder={highlightFolder}
                                        />
                                    </Box>
                                ) : (
                                    <FileCard
                                        isFavorite={isFav}
                                        runDeleteFavMutation={
                                            runDeleteFavMutation
                                        }
                                        data={_item}
                                        deleteFileModal={deleteFileModal}
                                        runFavMutation={runFavMutation}
                                        highlightFile={highlightFile}
                                        renameFileModal={setRenameFileModal}
                                    />
                                )}
                            </>
                        )
                    })}
                </Box>
            ) : null}

            {listView && listViewData && listViewData.length ? (
                <>
                    <Box
                        bg="white"
                        border="solid"
                        borderWidth={1}
                        borderColor="gray250"
                        borderRadius="8px"
                        justifyContent="space-around"
                        width="100%"
                    >
                        <Table className="listViewTable" width="100%">
                            <Thead
                                borderBottom="1px solid #E8ECEF"
                                fontSize={{ md: '12px', lg: '14px' }}
                            >
                                <Tr
                                    color="textSecondaryDarkBlue"
                                    fontFamily="Rubik"
                                >
                                    <Th fontWeight={400} textAlign="left">
                                        Name
                                    </Th>
                                    <Th fontWeight={400} textAlign="left">
                                        Created On
                                    </Th>
                                    <Th fontWeight={400} textAlign="left">
                                        Added By
                                    </Th>
                                    <Th fontWeight={400} textAlign="left"></Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {listViewData.map(
                                    (data: any, index: number) => {
                                        return (
                                            <Tr
                                                color="textSecondaryDarkBlue"
                                                fontFamily="Rubik"
                                                height="56px"
                                                borderWidth={1}
                                                borderColor="gray350"
                                                bg={
                                                    searchParams.get(
                                                        'fileId'
                                                    ) === String(data?.id)
                                                        ? '#e9eef2'
                                                        : '#fff'
                                                }
                                                fontSize={{
                                                    md: '14px',
                                                    lg: '16px',
                                                }}
                                                onClick={(
                                                    e: SyntheticEvent
                                                ) => {}}
                                                key={
                                                    name +
                                                    'l-users-' +
                                                    index +
                                                    data.id
                                                }
                                                borderBottom="1px solid #E8ECEF"
                                                className={addClasses(
                                                    data,
                                                    String(data.id)
                                                )}
                                                onMouseLeave={() =>
                                                    openListDropdown({})
                                                }
                                            >
                                                <Td
                                                    color="primary"
                                                    className="pointer"
                                                    onClick={() => {
                                                        routerChanged(data)
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        {!data.type ? (
                                                            <FolderSmIco />
                                                        ) : !data.thumbnail ? (
                                                            <ExtensionIcon
                                                                size="lg"
                                                                type={
                                                                    data.path.split(
                                                                        '.'
                                                                    )[
                                                                        data.path.split(
                                                                            '.'
                                                                        )
                                                                            .length -
                                                                            1
                                                                    ]
                                                                }
                                                            />
                                                        ) : (
                                                            showThumbNail(
                                                                data.name,
                                                                data.thumbnail ||
                                                                    data.path ||
                                                                    data.url,
                                                                data.other_data
                                                            )
                                                        )}
                                                        <Text
                                                            ml={'12px'}
                                                            my={'0px'}
                                                            title={data.name}
                                                            maxWidth="250px"
                                                            className="elipses"
                                                            fontSize={'14px'}
                                                        >
                                                            {showFileName(
                                                                data.name,
                                                                data
                                                            )}
                                                        </Text>
                                                    </Box>
                                                </Td>
                                                <Td
                                                    color="primary"
                                                    className="pointer"
                                                    onClick={() => {
                                                        routerChanged(data)
                                                    }}
                                                >
                                                    <Text
                                                        my={'0px'}
                                                        fontSize={'14px'}
                                                    >
                                                        {format(
                                                            new Date(
                                                                data.created_at
                                                            ),
                                                            'MMM dd, yyyy'
                                                        )}
                                                    </Text>
                                                </Td>
                                                <Td
                                                    color="primary"
                                                    className="pointer"
                                                    onClick={() => {
                                                        routerChanged(data)
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        gridColumnGap={'12px'}
                                                    >
                                                        <ProfileImg
                                                            name={
                                                                data?.user?.name
                                                            }
                                                            size="24px"
                                                            fontSize={'12px'}
                                                            src={showImage(
                                                                data
                                                            )}
                                                        />
                                                        <Text
                                                            my={'0px'}
                                                            maxWidth="150px"
                                                            title={
                                                                data?.user?.name
                                                            }
                                                            className="elipses"
                                                            fontSize={'14px'}
                                                        >
                                                            {' '}
                                                            {
                                                                data?.user?.name
                                                            }{' '}
                                                        </Text>
                                                    </Box>
                                                </Td>
                                                <Td color="primary">
                                                    <Box
                                                        display="flex"
                                                        className="listViewIcons"
                                                        justifyContent="end"
                                                        pr={'40px'}
                                                    >
                                                        <Box position="relative">
                                                            <Button
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                border="none"
                                                                py="8px"
                                                                borderRadius="4px"
                                                                px="8px"
                                                                bg={
                                                                    'transparent'
                                                                }
                                                                className={
                                                                    'threeDots'
                                                                }
                                                                onClick={() => {
                                                                    let obj: any =
                                                                        {}
                                                                    obj[
                                                                        data.id
                                                                    ] =
                                                                        !data.type ||
                                                                        data.type ===
                                                                            'FOLDER'
                                                                            ? 'folder'
                                                                            : 'file'
                                                                    openListDropdown(
                                                                        obj
                                                                    )
                                                                }}
                                                            >
                                                                <Dots
                                                                    active={
                                                                        false
                                                                    }
                                                                    vertical
                                                                />
                                                            </Button>
                                                            {listDropdown[
                                                                data.id
                                                            ] && (
                                                                <Dropdown
                                                                    ref={
                                                                        dropDownRef
                                                                    }
                                                                    top="42px"
                                                                    active={
                                                                        listDropdown[
                                                                            data
                                                                                .id
                                                                        ] ||
                                                                        false
                                                                    }
                                                                    right="0px"
                                                                    left="unset"
                                                                >
                                                                    <>
                                                                        {dropItems[
                                                                            listDropdown[
                                                                                data
                                                                                    .id
                                                                            ]
                                                                        ]?.map(
                                                                            (
                                                                                item: any
                                                                            ) => {
                                                                                return (
                                                                                    <DropdownButtonWrapper
                                                                                        onClick={() => {
                                                                                            openListDropdown(
                                                                                                {}
                                                                                            )
                                                                                            handleDropItems(
                                                                                                item,
                                                                                                data
                                                                                            )
                                                                                        }}
                                                                                        hover={
                                                                                            true
                                                                                        }
                                                                                        width="100%"
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            color={
                                                                                                item.id ===
                                                                                                    'DELETE_FOLDER' ||
                                                                                                item.id ===
                                                                                                    'DELETE_FILE'
                                                                                                    ? 'delay'
                                                                                                    : 'primary'
                                                                                            }
                                                                                            fontFamily="Rubik"
                                                                                            border="none"
                                                                                            bg="transparent"
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </Button>
                                                                                    </DropdownButtonWrapper>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </>
                                                                </Dropdown>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Td>
                                            </Tr>
                                        )
                                    }
                                )}
                            </Tbody>
                        </Table>
                    </Box>
                    {totalCount &&
                        totalCount > LibraryPerPage &&
                        listViewData.length < totalCount && (
                            <LoadMoreCard
                                listView={listView}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                </>
            ) : null}

            {renameFileModal.id && (
                <RenameFileModal
                    fileId={renameFileModal.id}
                    fileName={renameFileModal.name}
                    isLibraryFile={true}
                    isOpen={!!renameFileModal.id ? true : false}
                    onClose={() => setRenameFileModal({ id: null, name: '' })}
                />
            )}
        </>
    )
}
