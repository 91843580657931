export const ProductsBadge = () => (
    <svg
        width="72"
        height="94"
        viewBox="0 0 97 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.4707 0.307739C3.6047 0.307739 0.470703 3.44175 0.470703 7.30774V106.692C0.470703 110.558 3.60471 113.692 7.4707 113.692H80.6896C84.5556 113.692 87.6896 110.558 87.6896 106.692V57.4465C87.6896 55.59 88.4271 53.8095 89.7399 52.4968L95.185 47.0516C95.9746 46.1743 96.4115 45.0357 96.4115 43.8554V7.30774C96.4115 3.44175 93.2775 0.307739 89.4115 0.307739H7.4707ZM9.1926 16.0296C9.1926 12.1636 12.3266 9.02963 16.1926 9.02963H63.2458C67.1118 9.02963 70.2458 12.1636 70.2458 16.0296V43.8554C70.2458 45.0357 70.6828 46.1743 71.4724 47.0516L76.9175 52.4968C78.2302 53.8095 78.9677 55.59 78.9677 57.4465V97.9705C78.9677 101.836 75.8337 104.97 71.9677 104.97H16.1926C12.3266 104.97 9.1926 101.836 9.1926 97.9705V16.0296ZM78.9677 13.3906C78.9677 10.9821 80.9202 9.02963 83.3287 9.02963C85.7372 9.02963 87.6896 10.9821 87.6896 13.3906V40.2029C87.6896 41.3595 87.2302 42.4687 86.4123 43.2866C84.7093 44.9896 81.9481 44.9896 80.245 43.2866C79.4272 42.4687 78.9677 41.3595 78.9677 40.2029V13.3906Z"
            fill="#C2CFE0"
        />
    </svg>
)
