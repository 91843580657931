import { storeState } from "./@types/slider-types";

type payload = {
    isModalOpen: boolean;
    name: string;
    id: string;
};
const createModalSlice = (set: Function) => ({
	isModalOpen: false,
	isProfileModalOpen: false,
	name: '',
	id: '',
	setIsModal: (payload: payload) => {
		set((state: storeState) => {
			return {
				...state,
				isModalOpen: payload.isModalOpen,
				name: payload.name,
				id: payload.id
			}
		})
	},
	setIsModalProfileModalOpen: (payload: boolean) => {
		set((state: storeState) => {
			return {
				...state,
				isProfileModalOpen: payload
			}
		})
	}
})

export default createModalSlice
