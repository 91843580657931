export const WarningIcoSM = ({ color = '#FF2C18' }: { color?: string }) => (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 10.266a4.267 4.267 0 1 0 0-8.533 4.267 4.267 0 0 0 0 8.533Zm0 1.067A5.333 5.333 0 1 0 6 .666a5.333 5.333 0 0 0 0 10.667Zm.666-3.334a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm-.667-4.666a.667.667 0 0 0-.666.666v2a.667.667 0 0 0 1.333 0V4a.667.667 0 0 0-.667-.666Z"
            fill={color}
        />
    </svg>
)
