export const Success = {
    create: {
        product: 'Product Created Successfully.',
        todo: 'Todo Created Successfully.',
        collection: 'Collection Created Successfully.',
    },
} as const
export const Failure = {
    create: {},
    conditionChecks: {
        email: {
            inValid: 'Invalid email address',
            noEmail: 'Please enter a valid email address',
            alreadyExistinDb: 'Email address already exists',
            alreadyAdded: 'Email address already added',
        },
        role: {
            inValid: 'Invalid role selected.',
            noRole: 'Please select a valid role.',
        },
    },
    error: {
        base: 'An error occurred.',
    },
} as const

export const SuccessClass = {
    className: 'custom-toaster toaster-success',
} as const
export const ErrorClass = { className: 'custom-toaster toaster-error' } as const
