export function extractRGB(rgbString: string): string {
    const match = rgbString.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)/
    )

    if (match) {
        const [, r, g, b, a] = match
        if (a !== undefined) {
            return `${r},${g},${b},${a}`
        } else {
            return `${r},${g},${b}`
        }
    } else {
        return rgbString
    }
}
