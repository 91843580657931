import type { storeState } from './@types/slider-types.d';
import { CompareDates, statuses } from 'src/helpers';

// Test Comment

const product = [{
    id: 0,
    created_at: "",
    name: "",
    team_id: 0,
    status: "",
    description: "",
    due_date: "",
    tags: [{
        id: 0,
        tag: "",
        color: ""
    }],
    todos: [{
        name: "",
        due_date: "",
        status: "",
        sub_todos: {
            todo_id: 0,
            heading: "",
            description: "",
            created_at: "",
        },
        description: "",
        id: "",
        tags: [""],
    }],
    approver: {
        name: "",
        email: "",
        other_data: {
            profile_pic: ""
        }
    },
    creator: {
        name: "",
        other_data: {
            profile_pic: ""
        }
    }
}
]


export const collecton = [{
    company: "",
    status: "new",
    is_delayed: false,
    created_at: "",
    updated_at: "",
    name: "",
    description: "",
    due_date: "",
    id: 0,
    brand: "",
    development_cycle: "",
    tags: [{
        id: 0,
        tag: "",
        color: "",
    }],
    manager: {
        other_data: {
            profile_pic: ""
        },
        organisation_members: [{
            role: ""
        }],
        name: "",
        id: 0,
        email: ""
    },
    team: {
        id: 0,
        name: "",
        team_members: [{
            user: {
                other_data: {
                    profile_pic: ""
                },
                name: "",
                id: 0,
                email: "",
                organisation_members: [{
                    role: "Deactivated"
                }]
            }
        }]
    },
    products: product

}]




const createCollectionSlice = (set: Function) => ({
    collection: collecton,
    newCollections: [],
    inProgressCollections: [],
    completedCollections: [],
    inProgressClone: [],
    collectionCompletedCount: 0,
    collectionInProgressCount: 0,
    collectionNewCount: 0,
    isNew: true,
    isInProgress: false,
    IsCompleted: false,
    setCollections: (payload: Collections[]) => {
        set(() => {
            return {
                collection: payload,
                newCollections: payload.filter(collection => collection.status.toLowerCase() === "new"),
                completedCollections: payload.filter(collection => collection.status.toLowerCase() === "complete"),
                inProgressCollections: payload.filter(collection => collection.status.toLowerCase() === "inprogress"),
                inProgressClone: payload.filter(collection => collection.status.toLowerCase() === "inprogress")
            }
        })
    },
    sliderLinksUpdate: ({ action }: { action: "IN-PROGRESS" | "NEW" | "COMPLETED" }) => {
        set((state: storeState) => {
            return {
                ...state,
                isInProgress: action === "IN-PROGRESS" ? true : false,
                isCompleted: action === "COMPLETED" ? true : false,
                isNew: action === "NEW" ? true : false,
            }
        })
    },
    filterDelayCollections: ({ action }: { action: "ALL" | "DELAY" }) => {
        set((state: storeState) => {
            return {
                ...state,
                collection: action === "DELAY" ? state.inProgressCollections.filter(collection => CompareDates(new Date(collection.due_date))) : state.inProgressClone
            }
        })
    },
    // filterCollections: ({ action }: { action: "NEW" | "IN-PROGRESS" | "COMPLETED" }) => {
    //     set((state: storeState) => {
    //         switch (action) {
    //             case "NEW":
    //                 return {
    //                     ...state, collection: state.newCollections
    //                 }
    //             case "IN-PROGRESS":
    //                 return {
    //                     ...state, collection: state.inProgressCollections
    //                 }
    //             case "COMPLETED":
    //                 return {
    //                     ...state, collection: state.completedCollections
    //                 }
    //             default:
    //                 break;
    //         }
    //     })

    // },
    setIsInProgress: ({ payload }: { payload: boolean }) => {
        set((state: storeState) => {
            return {
                ...state,
                isInProgress: payload
            }
        })
    },
    sortCollection: (payload: "NAME" | "DATE-CREATED" | "DUE-DATE") => {
        set((state: storeState) => {
            if (payload === "NAME") {
                return {
                    collection: state.collection.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                }
            } else if (payload === "DUE-DATE") {
                return {
                    collection: state.collection.slice().sort((a, b) => {
                        return +new Date(a.due_date) - +new Date(b.due_date)
                    })
                }
            } else if (payload === "DATE-CREATED") {
                return {
                    collection: state.collection.slice().sort((a, b) => {
                        return +new Date(a.created_at) - +new Date(b.created_at)
                    })
                }
            }
        })
    },
    setCollectionOnStatus: (payload: {
        collection: Collections,
        status: "new" | "inProgress" | "complete"
    }) => {
        set((state: storeState) => {
            if (payload.status === statuses.new) {
                return {
                    ...state,
                    newCollections: payload.collection
                }
            }
            else if (payload.status === statuses.inProgress) {
                return {
                    ...state,
                    inProgressCollections: payload.collection
                }
            }
            if (payload.status === statuses.complete) {
                return {
                    ...state,
                    completedCollections: payload.collection
                }
            }

        })
    },
    setCollectionCount: (paylaod: {
        type: "new" | "inProgress" | 'complete',
        count: number
    }) => {
        set((state: storeState) => {
            if (paylaod.type === statuses.new) {
                return {
                    ...state,
                    collectionNewCount: paylaod.count,
                }
            }
            else if (paylaod.type === statuses.inProgress) {
                return {
                    ...state,
                    collectionInProgressCount: paylaod.count,
                }
            }
            else if (paylaod.type === statuses.complete) {
                return {
                    ...state,
                    collectionCompletedCount: paylaod.count,
                }
            }

        })
    }
})

export default createCollectionSlice
