import React from 'react'

function TodoWithArrowsIco() {
    return (
        <svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.37808 1.42253V5.35048H13.286V1.42253H1.37808ZM1.37808 9.27843V13.2064H13.286V9.27843H1.37808ZM1.00596 0.244141H13.6581C14.0674 0.244141 14.4023 0.597656 14.4023 1.02973V5.74327C14.4023 6.17535 14.0674 6.52886 13.6581 6.52886H1.00596C0.596628 6.52886 0.261719 6.17535 0.261719 5.74327V1.02973C0.261719 0.597656 0.596628 0.244141 1.00596 0.244141ZM1.00596 8.10004H13.6581C14.0674 8.10004 14.4023 8.45356 14.4023 8.88563V13.5992C14.4023 14.0312 14.0674 14.3848 13.6581 14.3848H1.00596C0.596628 14.3848 0.261719 14.0312 0.261719 13.5992V8.88563C0.261719 8.45356 0.596628 8.10004 1.00596 8.10004Z"
                fill="#778CA2"
            />
            <rect
                width="11.8766"
                height="11.876"
                transform="translate(6.39453 9.87695)"
                fill="white"
            />
            <path
                d="M16.6177 18.5606L15.2247 17.1677C15.0301 16.9731 15.0301 16.6568 15.2247 16.4622C15.4193 16.2676 15.7356 16.2676 15.9302 16.4622L18.1748 18.7068L16.6177 18.5606ZM16.6177 18.5606H8.84357C8.56815 18.5606 8.34464 18.7842 8.34464 19.0596C8.34464 19.335 8.56815 19.5585 8.84357 19.5585H16.6177L15.2247 20.9515C15.0301 21.1461 15.0301 21.4624 15.2247 21.657C15.4193 21.8516 15.7356 21.8516 15.9302 21.657L18.1748 19.4124L16.6177 18.5606ZM10.048 14.0714H17.8221C18.0975 14.0714 18.3211 14.2949 18.3211 14.5703C18.3211 14.8457 18.0975 15.0692 17.8221 15.0692H10.048L11.441 16.4622C11.6356 16.6568 11.6356 16.9731 11.441 17.1677C11.2463 17.3623 10.9301 17.3623 10.7355 17.1677L8.49087 14.9231L10.048 14.0714ZM10.048 14.0714L11.441 12.6784C11.6356 12.4838 11.6356 12.1675 11.441 11.9729C11.2463 11.7783 10.9301 11.7783 10.7355 11.9729L8.49087 14.2175M10.048 14.0714L8.49087 14.2175M8.49087 14.2175L8.49082 14.2175L8.52618 14.2529L8.49087 14.2175ZM18.1749 19.4123C18.3699 19.2177 18.3699 18.9014 18.1749 18.7068V19.4123Z"
                fill="#778CA2"
                stroke="#778CA2"
                stroke-width="0.1"
            />
        </svg>
    )
}

export default TodoWithArrowsIco
