import { gql } from '@apollo/client'

export const DashboardYourCollections = gql`
    query getDashboardCollections(
        $status: collection_status_enum_comparison_exp = {}
        $order_by: [collections_order_by!] = {}
        $limit: Int
        $offset: Int
        $user_id: bigint_comparison_exp = {}
        $managerId: bigint_comparison_exp = {}
    ) {
        collections(
            where: { status: $status, manager_id: $managerId }
            order_by: $order_by
            limit: $limit
            offset: $offset
        ) {
            id
            name
            due_date
            status
            is_delayed
            team {
                id
                name
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                        organisation_members {
                            role
                        }
                    }
                }
            }
            totalProducts: products_aggregate {
                aggregate {
                    count
                }
            }
            totalCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const DashboardAllCollections = gql`
    query getDashboardCollections(
        $teamId: bigint_comparison_exp = {}
        $status: collection_status_enum_comparison_exp = {}
        $order_by: [collections_order_by!] = {}
        $limit: Int
        $offset: Int
        $user_id: bigint_comparison_exp = {}
    ) {
        collections(
            where: {
                team_id: $teamId
                status: $status
                team: { team_members: { user_id: $user_id } }
            }
            order_by: $order_by
            limit: $limit
            offset: $offset
        ) {
            id
            name
            due_date
            status
            is_delayed
            team {
                id
                name
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                        organisation_members {
                            role
                        }
                    }
                }
            }
            totalProducts: products_aggregate {
                aggregate {
                    count
                }
            }
            totalCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const GetYourCollectionsAggregate = gql`
    query getCollectionsAggregate($managerId: bigint_comparison_exp = {}) {
        new: collections_aggregate(
            where: { status: { _eq: new }, manager_id: $managerId }
        ) {
            aggregate {
                count
            }
        }
        inProgress: collections_aggregate(
            where: { manager_id: $managerId, status: { _eq: inProgress } }
        ) {
            aggregate {
                count
            }
        }
        completed: collections_aggregate(
            where: { manager_id: $managerId, status: { _eq: complete } }
        ) {
            aggregate {
                count
            }
        }
    }
`
export const GetMyCollectionsAggregate = gql`
    query getCollectionsAggregate($user: users_bool_exp = {}) {
        new: collections_aggregate(
            where: {
                status: { _eq: new }
                team: { team_members: { user: $user } }
            }
        ) {
            aggregate {
                count
            }
        }
        inProgress: collections_aggregate(
            where: {
                status: { _eq: inProgress }
                team: { team_members: { user: $user } }
            }
        ) {
            aggregate {
                count
            }
        }
        completed: collections_aggregate(
            where: {
                status: { _eq: complete }
                team: { team_members: { user: $user } }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const SearchCollections = gql`
    query searchCollection($query: String, $limit: Int = 100) {
        collections(where: { name: { _ilike: $query } }, limit: $limit) {
            id
            name
        }
    }
`
