import React from 'react'
import type { StatusTypes } from 'src/components-v2/todo/todo.types'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import {
    Completed,
    InProgress,
    InReview,
    New,
    RequestRevision,
} from 'src/components/status'
import { Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

function ProductStatusDropdown({
    active,
    setIsDropdownActive,
    onUpdate,
}: {
    active: boolean
    setIsDropdownActive: React.Dispatch<React.SetStateAction<boolean>>
    onUpdate: (status: StatusTypes) => void
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['status-dropdown-btn']
    )

    return (
        <Dropdown
            active={active}
            setDropDownDisabled={setIsDropdownActive}
            top="24px"
            left="0px"
            width="max-content"
        >
            <Box ref={wrapperRef} width="100%" px="16px">
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('new')
                        setIsDropdownActive(false)
                    }}
                >
                    <New />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('inProgress')
                        setIsDropdownActive(false)
                    }}
                >
                    <InProgress />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('inReview')
                        setIsDropdownActive(false)
                    }}
                >
                    <InReview />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('requestedForResubmission')
                        setIsDropdownActive(false)
                    }}
                >
                    <RequestRevision />
                </DropdownButtonWrapper>
                <DropdownButtonWrapper
                    value="Products"
                    width="100%"
                    onClick={() => {
                        onUpdate('complete')
                        setIsDropdownActive(false)
                    }}
                >
                    <Completed />
                </DropdownButtonWrapper>
            </Box>
        </Dropdown>
    )
}

export default ProductStatusDropdown
