import styled from 'styled-components'

import {
	layout,
	space,
	border,
	position,
	color,
	flexbox,
	grid,
	typography,
    shadow
} from 'styled-system'
// transition:0.2s ease-in-out;
const Box = styled.div<BoxTypes>`
    ${layout}
    ${space}
    ${border}
    ${position}
    ${color}
    ${flexbox}
    ${grid}
    ${typography}
    ${shadow}
    box-sizing: border-box;
`

export default Box
