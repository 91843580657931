import * as React from 'react'
import { useUpdateCollection } from 'src/components-v2/cards/hooks/useUpdateCollection'
import CollectionListCard from 'src/components-v2/entity-list-card/collection-list.card'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import { Box } from 'src/components/utility'
import type { TGqlCollections } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import useStore from 'src/store/hooks/useStore'

export interface ICollectionTabDataProps {
    collections: TGqlCollections[]
    tabView: boolean
}

export default function CollectionTabData(props: ICollectionTabDataProps) {
    const { tabView = true } = props
    const { addDrawerTab, clearDrawerTabs } = useStore()
    const { updateCollection } = useUpdateCollection()

    if (props.collections?.length <= 0)
        return <EntityEmptyState entityName="Collections" />
    return (
        <Box mt="16px" display="flex" flexDirection="column" gridRowGap="8px">
            {props.collections.length > 0 ? (
                props.collections?.map((collection) => {
                    return (
                        <CollectionListCard
                            onClick={() => clearDrawerTabs()}
                            isFullCard={false}
                            collection={{
                                dueDate: collection.due_date
                                    ? new Date(
                                          collection.due_date
                                      ).toISOString()
                                    : null,
                                id: Number(collection.id),
                                name: collection.name,
                                isDelayed: collection?.is_delayed || false,
                                productsCount: {
                                    completed: Number(collection.totalProducts),
                                    total: Number(collection.totalCompleted),
                                },
                                status: collection.status,
                                team: {
                                    id: Number(collection.team.id),
                                    name: collection.team.name,
                                    team_members: collection.team.team_members,
                                },
                            }}
                        />
                    )
                })
            ) : (
                <EntityEmptyState entityName="Collections" />
            )}
        </Box>
    )
}
