import styled from 'styled-components'
import {
    flexbox,
    space,
    layout,
    position,
    color,
    border,
    typography
} from 'styled-system'

const Input = styled.input<InputTypes>`
    ${flexbox}
    ${space}
    ${layout}
    ${position}
    ${color}
    ${border}
    ${typography}
    font-family:"Rubik";
    box-sizing: border-box;
    &::disabled {
        background-color: #fff;
    }
    &{
        outline: transparent;
    }
`

export default Input
