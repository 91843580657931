import * as React from 'react'
import { Modal } from 'src/components'
import { TeamIco } from 'src/components/svg-icons'
import { Box, Input } from 'src/components/utility'
import { useUpdateTeam } from '../hooks'
import { useOrganizationContext } from '../organisation.context'

export interface IRenameTeamModalProps {
    isModalActive: boolean
    setIsModalActive: (isActive: boolean) => void
    team:
        | ITeams
        | {
              id: string
              name: string
          }
    onUpdate?: () => void
}

export default function RenameTeamModal(props: IRenameTeamModalProps) {
    const [teamName, setTeamName] = React.useState(props.team.name)
    const { updateTeam } = useUpdateTeam()
    const { setTeam } = useOrganizationContext()

    React.useEffect(() => {
        setTeamName(props.team.name)
    }, [props.team.name])

    function handleRenameTeam() {
        if (props.team.id) {
            updateTeam(
                {
                    name: teamName,
                },
                String(props.team.id),
                ['getTeams', 'GetTeamsPercentagesForTeams'],
                () => {
                    setTeam((team) => {
                        return team.map((t) => {
                            return t.id === props.team.id
                                ? {
                                      ...t,
                                      name: teamName,
                                  }
                                : t
                        })
                    })
                    props.onUpdate && props.onUpdate()
                    props.setIsModalActive(false)
                }
            )
        }
    }

    return (
        <Modal
            isModal={props.isModalActive}
            onClick={handleRenameTeam}
            primaryButtonTitle="Rename Team"
            secondaryButtonTitle="Cancel"
            title="Rename Team"
            onClose={() => {
                setTeamName(props.team.name)
                props.setIsModalActive(false)
            }}
            disablePrimaryButton={teamName.length <= 0}
            bg="white"
        >
            <Box px="16px" gridColumnGap="12px" py="24px" display="flex">
                <Box
                    p="8px"
                    display="flex"
                    alignItems="center"
                    bg="backgroundColor"
                    borderRadius="4px"
                >
                    <TeamIco color="#778CA2" />
                </Box>
                <Input
                    onChange={(e) => setTeamName(e.target.value)}
                    border="none"
                    fontSize="16px"
                    placeholder="Untitled Team"
                    bg="white"
                    value={teamName}
                />
            </Box>
        </Modal>
    )
}
