import * as React from 'react'
import { Checkbox, Dropdown } from 'src/components'
import { useGlobalSearch } from 'src/components-v2/global-search/hooks/useGlobalSearch'
import { useSearchTags } from 'src/components-v2/global-search/hooks/useSearchTags'
import { DropdownButton } from 'src/components/dropdown/styled.button.wrapper'
import { DropDownArrow, SearchIco } from 'src/components/svg-icons'
import { Box, Input, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import useStore from 'src/store/hooks/useStore'

export interface ITagFilterProps {}

export default function TagFilter(props: ITagFilterProps) {
    const { globalSearchFilters, setDateRange } = useStore()
    const [dropdownVisible, setDropdownVisible] = React.useState(false)
    const calendarRef = useClickedOutside(() => setDropdownVisible(false))
    const { searchTags, searchedTags } = useSearchTags()
    const dropdownRef = useClickedOutside(() => setDropdownVisible(false))
    const { getGlobalSearchData } = useGlobalSearch()
    const [tagFilterFilterObj, setTagFilterFilterObj] = React.useState<{
        [tag: string]: boolean
    }>({})

    React.useEffect(() => {
        if (dropdownVisible) {
            getGlobalSearchData({
                query: globalSearchFilters.query,
                filters: {
                    collaborators: globalSearchFilters.collaborators
                        .filter((collaborator) => Number(collaborator.id) !== 0)
                        .map((collaborator) => String(collaborator.id)),
                    tags: globalSearchFilters.tags
                        .filter((tag) => Number(tag.id) !== 0)
                        .map((tag) => tag.name),
                    file_types: globalSearchFilters.file_types,
                    status: globalSearchFilters.status
                        .filter((status) => status.isActive)
                        .map((status) => status.id),
                    from: globalSearchFilters.dateRange || undefined,
                    to: globalSearchFilters.dateRange || undefined,
                },
            })
        }
    }, [globalSearchFilters.tags])
    const tagsLength = globalSearchFilters.tags.filter(
        (tag) => Number(tag.id) !== 0
    ).length

    const filteredTags = globalSearchFilters.tags.filter(
        (tag) => Number(tag.id) !== 0
    )
    return (
        <Box ref={dropdownRef} position="relative">
            <Box
                className="cursor-pointer"
                border="solid"
                borderWidth={1}
                py="8px"
                px="16px"
                width="max-content"
                borderRadius="4px"
                borderColor="secondaryLighterBlue"
                color={tagsLength > 0 ? 'white' : 'primary'}
                bg={tagsLength > 0 ? 'primary' : 'white'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap="8px"
                position="relative"
                onClick={() => setDropdownVisible(true)}
            >
                <Box
                    display="flex"
                    gridColumnGap={'4px'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text my="0px">Tags:</Text>
                    {filteredTags[0]?.name ? (
                        <Text my="0px">{filteredTags[0].name}</Text>
                    ) : (
                        'Select Tags'
                    )}
                </Box>
                {tagsLength > 1 && (
                    <Box
                        fontSize="12px"
                        p="4px"
                        color="primary"
                        bg="white"
                        borderRadius="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        +{globalSearchFilters.tags.length - 1}
                    </Box>
                )}
                <DropDownArrow color={tagsLength > 0 ? 'white' : '#022143'} />
            </Box>
            {dropdownVisible && (
                <Dropdown py="16px" top="42px" active={dropdownVisible}>
                    <Box>
                        <Box px="16px">
                            <Box
                                bg="white"
                                border="solid"
                                borderColor="gray250"
                                borderWidth={1}
                                borderRadius="4px"
                                px="12px"
                                py="12px"
                                id="search-tags-input"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gridColumnGap="12px"
                            >
                                <SearchIco color="#778CA2" />
                                <Input
                                    id="search-collaborator-input"
                                    width="100%"
                                    fontSize="14px"
                                    placeholder="Search Tags"
                                    border="none"
                                    bg="transparent"
                                    onChange={(e) => searchTags(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box mt="12px" maxHeight="400px" overflowY={'auto'}>
                            {globalSearchFilters.tags
                                .filter((tag) => Number(tag.id) !== 0)
                                .map((tag) => {
                                    return (
                                        <DropdownButton
                                            borderBottom="solid"
                                            borderWidth={1}
                                            borderColor="gray100"
                                        >
                                            <TagCheckBox
                                                isChecked={true}
                                                list={tagFilterFilterObj}
                                                onChange={(d) =>
                                                    setTagFilterFilterObj(d)
                                                }
                                                tag={{
                                                    id: tag.id,
                                                    tag: tag.name,
                                                }}
                                            />
                                        </DropdownButton>
                                    )
                                })}
                            {searchedTags.map((tag) => {
                                if (tagFilterFilterObj[tag.tag]) return <></>
                                return (
                                    <DropdownButton>
                                        <TagCheckBox
                                            isChecked={
                                                tagFilterFilterObj[tag.tag]
                                            }
                                            list={tagFilterFilterObj}
                                            onChange={(d) =>
                                                setTagFilterFilterObj(d)
                                            }
                                            tag={{
                                                id: tag.id,
                                                tag: tag.tag,
                                            }}
                                        />
                                    </DropdownButton>
                                )
                            })}
                        </Box>
                    </Box>
                </Dropdown>
            )}
        </Box>
    )
}

function TagCheckBox(props: {
    tag: {
        id: string
        tag: string
    }
    onChange: (data: { [x: string]: boolean }) => void
    list: { [name: string]: boolean }
    isChecked: boolean
}) {
    const { setTags, removeTags, globalSearchFilters } = useStore()
    return (
        <Checkbox
            defautlCheck={props.isChecked}
            onChange={(x) => {
                if (!x) {
                    removeTags({ id: Number(props.tag.id) })
                    const list = { ...props.list }
                    delete list[props.tag.tag]
                    props.onChange({ ...list })
                } else {
                    props.onChange({
                        ...props.list,
                        [props.tag.tag]: true,
                    })
                    setTags({
                        tags: globalSearchFilters.tags.concat({
                            id: props.tag.id,
                            name: props.tag.tag,
                        }),
                    })
                }
            }}
            label={props.tag.tag}
        />
    )
}
