import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal } from 'src/components'
import { Box, Button, Text } from 'src/components/utility'
import { FetchFilesByFolderId, UpdateFolderThumbnail } from 'src/services'
import { FileCard } from '../file-card'

const SetThumbnail = () => {
    const [open, openModal] = useState(false)
    const [thumbnailFileId, setThumbnailFileId] = useState()
    const params = useParams()
    const folderId = params?.folderId
    const [fetchFiles, filesListData] = useLazyQuery(FetchFilesByFolderId)
    const filesList =
        filesListData?.data?.library_files.filter((file: any) =>
            file?.name?.match(/(.jpg|.png|.jpeg)/gi)
        ) || []

    const [setFolderThumbnail] = useMutation(UpdateFolderThumbnail)

    useEffect(() => {
        if (folderId && folderId !== '0') {
            let whereObjFiles: any = {
                folder_id: {
                    _eq: folderId,
                },
                deleted_at: { _is_null: true },
                file_type: { _eq: 'document' },
            }
            // console.log({s: 'l'});

            fetchFiles({
                variables: {
                    whereObj: whereObjFiles,
                    limit: 500,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }, [folderId])

    function applyThumbnail() {
        const _thumb = filesList.find(
            (ele: any) => Number(ele.id) === Number(thumbnailFileId)
        )
        if (!_thumb) {
            return
        }
        setFolderThumbnail({
            variables: {
                id: folderId,
                thumbnail: _thumb?.thumbnail,
            },
            onCompleted: () => {
                toast.success('Thumbnail added successfully!', {
                    className: 'custom-toaster toaster-success',
                })
                openModal(false)
            },
            onError: () => {},
        })
    }

    return (
        <>
            <Box
                bg="rgba(239, 141, 27, 0.1)"
                display="flex"
                alignItems="center"
                borderRadius="4px"
                py={'16px'}
                mb="16px"
                px="12px"
            >
                <Text fontSize={'14px'} my="0px">
                    *Set thumbnails for the folder in order to easily identify
                    the texture types
                </Text>

                <Button
                    height="20px"
                    bg="transparent"
                    border="none"
                    fontSize="16px"
                    color="links"
                    px={'0px'}
                    position="relative"
                    fontWeight={500}
                    ml="16px"
                    onClick={() => openModal(true)}
                >
                    Set Thumbnail
                </Button>
            </Box>

            {open && (
                <Modal
                    isModal={open}
                    onClick={() => applyThumbnail()}
                    onClose={() => openModal(false)}
                    primaryButtonTitle="Set"
                    secondaryButtonTitle="Cancel"
                    title="Set Thumbnail"
                    width="600px"
                    maxWidth="630px"
                    disablePrimaryButton={!thumbnailFileId}
                >
                    <Box
                        p={'24px'}
                        maxHeight="375px"
                        overflowY={'auto'}
                        className="scrollbar_none"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gridRowGap="16px"
                            fontSize="14px"
                        >
                            <Text
                                mt="0px"
                                mb="0px"
                                fontFamily="Rubik"
                                color="textSecondary"
                                textAlign="left"
                                width="100%"
                                fontSize={'14px'}
                            >
                                Please select an image to set thumbnail
                            </Text>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                width="100%"
                                gridGap="24px"
                            >
                                {filesList.map((file: any) => {
                                    return (
                                        <FileCard
                                            runSelection={(v: any) => {
                                                setThumbnailFileId(v)
                                            }}
                                            isSelectable={true}
                                            data={file}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )}
        </>
    )
}
export default SetThumbnail
