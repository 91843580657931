import { useEffect } from 'react'
import { useState } from 'react'

import { Modal } from 'src/components'
import {
    debugLog,
    getFileRequestId,
    getImageUrl,
    getLocalStorage,
    logoutUser,
    setLocalStorage,
    useUser,
    uuid,
} from 'src/helpers'
import { UpdateUserName } from 'src/services'
import { UpdateProfile } from './update-profile'
import { UpdatePassword } from './update-password'
import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    updatePassword,
} from 'firebase/auth'
import { ForgotPassword } from './forgot-password'
import { PasswordResetLink } from './password-reset-link'
import { useMutation } from '@apollo/client'
import useStore from 'src/store/hooks/useStore'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAssignFile, useUploadFile } from 'src/hooks/useUploadFile'
import { getLocalImageUrl } from 'src/helpers/get-image-url'
export const ProfileModal = ({
    isModal,
    setIsModal,
}: {
    isModal: boolean
    setIsModal: (x: boolean) => void
}) => {
    let user = getLocalStorage('user')
    let parsedUser = JSON.parse(user)
    const [passwords, setPasswords] = useState({
        current: '',
        repeat: '',
    })
    const [isNewFileUploaded, setIsNewFileUploaded] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const { setProfileName, setProfileImage } = useStore()
    const [UpdateUserNameMutation] = useMutation(UpdateUserName)
    const [profileImageURL, setProfileImageURL] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isValidPassword, setIsValidPassword] = useState(false)
    const [error, setError] = useState({
        error: false,
        message: '',
    })
    const { profileImage, userId } = useUser()
    const { isUploading, onFileUpload } = useUploadFile(userId, 'users')
    const { assignFilesToSection } = useAssignFile()
    const [file, setFile] = useState<{ file: Blob | null; name: string }>({
        file: null,
        name: '',
    })
    const navigate = useNavigate()

    useEffect(() => {
        setName(parsedUser.name)
    }, [user])
    const controller = new AbortController()
    const [steps, setSteps] = useState(0)
    const [name, setName] = useState(parsedUser?.name || '')

    async function uploadPic() {
        if (isNewFileUploaded && file.file) {
            const { request_id } = await getFileRequestId({
                action: 'INSERT',
                entity_id: userId.toString(),
                entity_name: 'users',
                number_of_files: 1,
            })
            try {
                // let formData = new FormData()
                // formData.append('toupload', file.file)
                // formData.append('entity_name', 'users')
                // formData.append('entity_id', userId)
                // formData.append('type', 'profile_pic')
                // setIsLoading(true)
                // const { data } = await axios.post(UPLOAD_USER_IMAGE, formData, {
                //     signal: controller.signal,
                //     headers: {
                //         'x-auth-token': getLocalStorage('authToken'),
                //         'request-id': request_id,
                //     },
                // })
                if (!file.file) return
                const data = await onFileUpload(
                    {
                        files: [
                            {
                                blob: file.file,
                                id: uuid(),
                                name: file.name,
                                path:
                                    getLocalImageUrl(
                                        new File([file.file], file.name)
                                    ) || '',
                            },
                        ],
                    },
                    'profile_pic',
                    () => {}
                )
                const uploadedFile = data?.resolvedArr
                if (uploadedFile) {
                    await assignFilesToSection({
                        assignedAs: 'profile_pic',
                        entityId: userId,
                        entityName: 'users',
                        fileId: [uploadedFile[0].id],
                    })
                    const user = getLocalStorage('user')
                    if (user) {
                        setLocalStorage(
                            'user',
                            JSON.stringify({
                                ...parsedUser,
                                profileImage: uploadedFile[0].thumbnail,
                            })
                        )
                    } else {
                        window.sessionStorage.setItem(
                            'user',
                            JSON.stringify({
                                ...parsedUser,
                                profileImage: uploadedFile[0].thumbnail,
                            })
                        )
                    }
                    setProfileImage({
                        image: getImageUrl(uploadedFile[0].thumbnail),
                    })
                    toast('Profile image updated successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                    setIsNewFileUploaded(false)
                    setIsLoading(false)
                }
            } catch (error) {
                setIsLoading(false)
                toast('Failed to updated profile image', {
                    className: 'custom-toaster toaster-error',
                })
            }
        }
        if (name !== parsedUser?.name) {
            if (name.length <= 0)
                return toast('Please enter a valid user name.', {
                    className: 'custom-toaster toaster-error',
                })
            UpdateUserNameMutation({
                variables: {
                    email: parsedUser.email,
                    name: name,
                    updated_at: new Date().toISOString(),
                },
                onCompleted: (e) => {
                    const user = getLocalStorage('user')
                    if (user) {
                        setLocalStorage(
                            'user',
                            JSON.stringify({ ...parsedUser, name: name })
                        )
                    } else {
                        window.sessionStorage.setItem(
                            'user',
                            JSON.stringify({ ...parsedUser, name: name })
                        )
                    }

                    setProfileName({ name: name })
                    toast('Username updated successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                },
                onError: (e) => {
                    toast('Failed to updated username', {
                        className: 'custom-toaster toaster-error',
                    })
                    debugLog(e)
                },
            })
        }
        setIsModal(false)
    }

    const auth = getAuth()

    async function updatePasword() {
        if (!auth || !auth.currentUser) {
            logoutUser()
            return navigate('/login')
        }
        if (oldPassword.length <= 0)
            return setError({
                error: true,
                message: 'Please enter valid passwords.',
            })
        if (passwords.current.length <= 0 || passwords.repeat.length <= 0)
            return setError({
                error: true,
                message: 'Please enter valid passwords.',
            })
        if (passwords.current !== passwords.repeat)
            return setError({
                error: true,
                message: 'Both passwords do not match.',
            })
        if (!isValidPassword)
            return setError({
                error: true,
                message:
                    'Use atleast 8 characters. Include uppercase, lowercase characters along with a number and a special character.',
            })
        try {
            setIsLoading(true)
            const userCredential = await signInWithEmailAndPassword(
                auth,
                auth?.currentUser?.email || '',
                oldPassword
            )
            if (userCredential.user) {
                await updatePassword(auth.currentUser, passwords.current)
                toast('Password updated successfully', {
                    className: 'custom-toaster toaster-success',
                })
                setSteps(0)
                setIsModal(false)
            } else {
                setError({ error: true, message: 'Invalid password.' })
            }
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
            if (e.message.includes('auth/requires-recent-login')) {
                toast('Credentials expired. Please login again.', {
                    className: 'custom-toaster toaster-error',
                })
            } else {
                setError({ error: true, message: 'Invalid old password.' })
            }
        }
    }

    async function forgotPassword() {
        if (email.length > 0) {
            await sendPasswordResetEmail(auth, email.toString())
        }
    }

    async function modalStepsHandler() {
        switch (steps) {
            case 0:
                await uploadPic()
                toast
                break
            case 1:
                updatePasword()
                break
            case 2:
                if (
                    email.length > 0 &&
                    email.match(/^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/)
                ) {
                    forgotPassword()
                    setSteps((steps) => steps + 1)
                } else {
                    setError({
                        error: true,
                        message: '',
                    })
                }
                break
            default:
                break
        }
    }

    return (
        <>
            <Modal
                backAction={
                    steps > 0
                        ? () => setSteps((step) => (step > 0 ? step - 1 : 0))
                        : undefined
                }
                disableActions={steps === 3 ? true : false}
                disablePrimaryButton={
                    (steps === 0 && name.length <= 0) || isLoading
                        ? true
                        : false
                }
                isModal={isModal}
                isLoading={isLoading}
                onClose={() => {
                    setIsModal(false)
                    setName(parsedUser?.name)
                    setProfileImage({ image: parsedUser?.profileImage || '' })
                    setProfileImageURL(
                        parsedUser?.profileImage
                            ? getImageUrl(parsedUser?.profileImage)
                            : ''
                    )
                    setSteps(0)
                    setError({ error: false, message: '' })
                    console.log(controller.abort())
                }}
                title={
                    steps === 0
                        ? 'Profile'
                        : steps === 1
                        ? 'Change Password'
                        : steps === 2
                        ? 'Forgot Password'
                        : 'Password Reset Link Sent'
                }
                primaryButtonTitle={steps == 2 ? 'Continue' : 'Update'}
                secondaryButtonTitle="Cancel"
                onClick={() => modalStepsHandler()}
            >
                {steps === 0 && (
                    <UpdateProfile
                        setProfileImageURL={(imageUrl) =>
                            setProfileImageURL(imageUrl)
                        }
                        profileImageURL={profileImageURL}
                        onUploadFile={() => setIsNewFileUploaded(true)}
                        name={name}
                        setName={setName}
                        setSteps={() => setSteps((steps) => steps + 1)}
                        setFile={(file: Blob, name) =>
                            setFile({ file: file, name: name })
                        }
                    />
                )}
                {steps === 1 && (
                    <UpdatePassword
                        setOldPassword={(oldPassword) =>
                            setOldPassword(oldPassword)
                        }
                        setSteps={() => setSteps((steps) => steps + 1)}
                        passwords={passwords}
                        setError={(error) =>
                            setError({
                                error: error,
                                message: '',
                            })
                        }
                        setIsValidPassword={setIsValidPassword}
                        error={error}
                        setPassword={({ current, repeat }) =>
                            setPasswords({ current: current, repeat: repeat })
                        }
                    />
                )}
                {steps === 2 && (
                    <ForgotPassword
                        isError={error.error}
                        setIsError={(error) =>
                            setError({
                                error: error,
                                message: '',
                            })
                        }
                        setEmail={(email) => setEmail(email)}
                    ></ForgotPassword>
                )}
                {steps === 3 && <PasswordResetLink email={email} />}
            </Modal>
        </>
    )
}
