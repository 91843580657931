import { gql } from '@apollo/client'

export const UpdateCollection = gql`
    mutation updateCollection(
        $id: bigint
        $updated_at: timestamptz
        $company: String
        $description: String
        $due_date: timestamptz
        $manager_id: bigint
        $name: String
        $team_id: bigint
        $brand: String
        $development_cycle: String
        $tags: jsonb
    ) {
        update_collections(
            where: { id: { _eq: $id } }
            _set: {
                updated_at: $updated_at
                company: $company
                description: $description
                due_date: $due_date
                manager_id: $manager_id
                name: $name
                team_id: $team_id
                brand: $brand
                development_cycle: $development_cycle
                tags: $tags
            }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const UpdateCollectionFields = gql`
    mutation UpdateCollectionFields(
        $id: bigint!
        $payload: collections_set_input!
    ) {
        update_collections_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
            name
            status
            tags
            team_id
        }
    }
`

export const UpdateCollectionStatus = gql`
    mutation UpdateCollectionStatus(
        $id: bigint!
        $status: collection_status_enum!
    ) {
        update_collections_by_pk(
            pk_columns: { id: $id }
            _set: { status: $status }
        ) {
            id
            status
        }
    }
`

export const UpdateCollectionDuedate = gql`
    mutation UpdateCollectionDuedate($id: bigint!, $due_date: timestamptz!) {
        update_collections_by_pk(
            pk_columns: { id: $id }
            _set: { due_date: $due_date }
        ) {
            id
            due_date
        }
    }
`

export const UpdateCollectionTeamAndItsProductsTeam = gql`
    mutation UpdateCollectionTeamAndItsProductsTeam(
        $team_id: bigint!
        $collection_id: bigint!
    ) {
        update_collections(
            where: { id: { _eq: $collection_id } }
            _set: { team_id: $team_id }
        ) {
            affected_rows
        }
        update_products(
            where: { collection_id: { _eq: $collection_id } }
            _set: { team_id: $team_id }
        ) {
            affected_rows
        }
        update_product_variants(
            where: { collection_id: { _eq: $collection_id } }
            _set: { team_id: $team_id }
        ) {
            affected_rows
        }
    }
`
