export function NotificationIco() {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2.25c-.83 0-1.5.67-1.5 1.5 0 .064.015.126.023.188C7.931 4.6 6 6.954 6 9.75v6.75c0 .425-.325.75-.75.75H4.5v1.5h5.39c-.084.237-.14.486-.14.75A2.262 2.262 0 0012 21.75a2.262 2.262 0 002.25-2.25c0-.264-.056-.513-.14-.75h5.39v-1.5h-.75a.737.737 0 01-.75-.75V9.96c0-2.817-1.884-5.331-4.523-6.023.008-.061.023-.123.023-.187 0-.83-.67-1.5-1.5-1.5zm-.328 3c.108-.009.217 0 .328 0h.14c2.45.073 4.36 2.22 4.36 4.71v6.54c0 .264.056.513.14.75H7.36c.084-.237.14-.486.14-.75V9.75a4.503 4.503 0 014.172-4.5zM12 18.75a.74.74 0 01.75.75.74.74 0 01-.75.75.74.74 0 01-.75-.75.74.74 0 01.75-.75z"
                fill="#C2CFE0"
            />
        </svg>
    )
}
