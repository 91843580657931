import React from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { GetProductVariantsByProductId } from 'src/services/graphql/entities/product/product.queries'
import { useApolloClient, useQuery } from '@apollo/client'
import ProductVariantCard from '../cards/product-variant/product-variant.card'
import { Loader } from 'src/components/loader/loader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CreateVariantModal from './create-variant-modal/create-variant-modal'
import useCreateVariant from './useCreateVariant.hook'
import { toast } from 'react-toastify'
import type {
    GetProductVariantsByProductIdQuery,
    GetProductVariantsByProductIdQueryVariables,
    Todo_Types_Enum_Comparison_Exp,
} from 'src/generated/graphql'
import { generateInitialVariantName } from './create-variant-modal/create-variant-modal.helper'

function VariantsDropdown({
    onClose,
    productId,
    productName,
    activeVariantId,
    todoType,
    isCompareSection,
    isSplitMode,
    isTodoPage,
}: {
    onClose: () => void
    productId: number
    productName: string
    activeVariantId: number
    todoType?: 'techPack' | 'finalRender' | 'baseModel' | 'others'
    isCompareSection?: boolean
    isSplitMode?: boolean
    isTodoPage?: boolean
}) {
    const wrapperRef = useClickedOutside(() => {
        onClose()
    }, [
        'variants-dropdown-button',
        'variant-name-badge',
        'variant-dropdown-arrow',
    ])
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [showCreateVariantModal, setShowCreateVariantModal] =
        React.useState(false)

    const {
        data: productVariantsData,
        loading,
        refetch,
    } = useQuery<
        GetProductVariantsByProductIdQuery,
        GetProductVariantsByProductIdQueryVariables
    >(GetProductVariantsByProductId, {
        variables: {
            product_id: productId,
            types: todoType
                ? ({ _eq: todoType } as Todo_Types_Enum_Comparison_Exp)
                : undefined,
        },
    })
    const client = useApolloClient()
    const { createVariantHandler } = useCreateVariant()

    if (loading) {
        return (
            <Box
                position="absolute"
                width={'70vw'}
                maxHeight={'70vh'}
                overflow={'auto'}
                className="scrollbar_none"
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Loader />
            </Box>
        )
    }

    const variants = productVariantsData?.product_variants

    const baseVariantId = variants?.find((variant) => variant.is_primary)?.id

    function variantClickHandler({
        variantId,
        todoId,
    }: {
        variantId: number
        todoId?: number
    }) {
        if (todoType && todoType !== 'others' && todoId) {
            if (isCompareSection) {
                searchParams.set('compareEntity', 'todo')
                searchParams.set('compareId', todoId.toString())
                setSearchParams(searchParams, { replace: true })
                onClose()
            } else {
                navigate(`/todo/${todoId}?${searchParams.toString()}`)
                onClose()
            }
        } else {
            if (isCompareSection) {
                searchParams.set('compareEntity', 'product')
                searchParams.set('compareId', variantId.toString())
                setSearchParams(searchParams, { replace: true })
                onClose()
            } else {
                navigate(
                    `/collections/product/${variantId}?${searchParams.toString()}`
                )
                onClose()
            }
        }
    }

    return (
        <Box
            position="absolute"
            width={isSplitMode ? '45vw' : '90vw'}
            maxHeight={'70vh'}
            overflow={'auto'}
            className="scrollbar_none"
            bg="white"
            display="flex"
            flexDirection="column"
            py="16px"
            px="8px"
            gridGap="12px"
            border="1px solid"
            borderColor="primary"
            borderRadius="4px"
            zIndex={15}
            ref={wrapperRef}
            style={{
                boxShadow: '0px 2px 12px rgba(153, 155, 168, 0.25)',
            }}
            top="24px"
            left="-24px"
            onClick={(e) => e.stopPropagation()}
        >
            <Text my="0px" fontSize={'14px'}>
                Variants({variants?.length || 0})
            </Text>
            <Button
                height="14px"
                width="120px"
                bg="transparent"
                border="none"
                fontStyle="Rubik"
                fontSize="12px"
                color="links"
                position="relative"
                minWidth="max-content"
                onClick={() => setShowCreateVariantModal(true)}
                px="0px"
            >
                + Add Variant
            </Button>
            <Box display="flex" flexDirection="column" gridGap="8px">
                {variants?.map((variant) => (
                    <ProductVariantCard
                        key={variant.id}
                        entity={variant}
                        onClick={() =>
                            variantClickHandler({
                                variantId: variant.id,
                                todoId: variant.todos[0]?.id,
                            })
                        }
                        refetch={refetch}
                        isSplitMode={isSplitMode}
                        isActive={activeVariantId === variant.id}
                        onCopy={() =>
                            createVariantHandler({
                                productVariantId: variant.id,
                                onCompleted: () => {
                                    client.refetchQueries({
                                        include: [
                                            GetProductVariantsByProductId,
                                        ],
                                    })
                                    toast('Variant copied successfully', {
                                        className:
                                            'custom-toaster toaster-success',
                                    })
                                },
                            })
                        }
                    />
                ))}
            </Box>
            {showCreateVariantModal && (
                <CreateVariantModal
                    isOpen={showCreateVariantModal}
                    onClose={() => setShowCreateVariantModal(false)}
                    baseProductId={productId}
                    productVariantId={baseVariantId}
                    navigateToTechPack={isTodoPage}
                    initialName={generateInitialVariantName({
                        baseProduct: productName,
                        variantNames: variants?.map((variant) => variant.name),
                    })}
                    productAndVariantNames={
                        !!variants
                            ? [
                                  productName,
                                  ...variants?.map((variant) => variant.name),
                              ]
                            : [productName]
                    }
                />
            )}
        </Box>
    )
}

export default VariantsDropdown
