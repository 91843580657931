export const FolderIco = () => (
    <svg
        width="48"
        height="42"
        viewBox="0 0 48 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.35"
            d="M40.8 42H7.2C3.2232 42 0 38.8663 0 35V7H40.8C44.7768 7 48 10.1337 48 14V35C48 38.8663 44.7768 42 40.8 42Z"
            fill="#FFB800"
        />
        <path
            d="M21 7H0V4.66667C0 2.08833 2.08833 0 4.66667 0H15.302C17.311 0 19.0937 1.28567 19.7283 3.192L21 7Z"
            fill="#FFB800"
        />
    </svg>
)
