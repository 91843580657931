import { Text } from 'src/components/utility'
export const InlineError = ({
    text,
    width,
}: {
    text: string
    width: string
}) => {
    return (
        <Text
            color="delay"
            mt="0px"
            mb="0px"
            fontSize="14px"
            fontFamily="Rubik"
            maxWidth={width}
            lineHeight="17px"
            textAlign="left"
        >
            {text}
        </Text>
    )
}
