import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Box, Button, Text } from 'src/components/utility'
import { scrollToSection } from 'src/helpers/scroll-to-section'
import { CrossSm } from 'src/components/svg-icons'
import { UpdateReferencePointForSection } from 'src/services/graphql/entities/todo/todo.mutation'

function ReferenceCircle({
    referenceId,
    scalingFactor,
    referencePoints,
    index,
    sectionId,
    refetchTodo,
    isAnnotationOnImage = true,
    isFocussedView,
}: {
    referenceId: number
    scalingFactor: { x: number; y: number }
    referencePoints: { x: number; y: number }
    index: number
    sectionId: number
    refetchTodo: () => void
    isAnnotationOnImage?: boolean
    isFocussedView?: boolean
}) {
    const [isHovered, setIsHovered] = useState(false)
    const [updateReferencePointMutation] = useMutation(
        UpdateReferencePointForSection
    )

    function deleteReferencePointHandler() {
        updateReferencePointMutation({
            variables: {
                id: referenceId,
                payload: {
                    deleted_at: new Date().toISOString(),
                },
            },
            onCompleted: () => {
                refetchTodo()
            },
        })
    }

    return (
        <Box
            position={isAnnotationOnImage ? 'absolute' : 'relative'}
            top={
                isAnnotationOnImage
                    ? ((referencePoints.y / scalingFactor.y) as any)
                    : 'unset'
            }
            left={
                isAnnotationOnImage
                    ? ((referencePoints.x / scalingFactor.x) as any)
                    : 'unset'
            }
            width={'18px'}
            height={'18px'}
            borderRadius={'50%'}
            bg="black"
            color="white"
            className="cursor-pointer"
            style={
                isAnnotationOnImage
                    ? { transform: 'translate(-50%, -50%)' }
                    : {}
            }
            boxShadow="2px 2px 4px 0px #00000026"
            key={referenceId}
            display="flex"
            border="2px solid white"
            justifyContent="center"
            alignItems="center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                if (isAnnotationOnImage)
                    scrollToSection(
                        isFocussedView
                            ? `subtodo_focused_section_${sectionId}`
                            : `subtodo_section_${sectionId}`
                    )
            }}
        >
            <Text fontSize={'14px'}>{index + 1}</Text>
            {isHovered && (
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteReferencePointHandler()
                    }}
                    position="absolute"
                    // @ts-ignore
                    top="-10px"
                    right="-10px"
                    width="18px"
                    height="18px"
                    style={{
                        background:
                            'linear-gradient(180deg, #D03954 0%, #C02A3C 100%)',
                    }}
                    borderRadius="50%"
                    border="none"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    px={'0px'}
                >
                    <CrossSm color="white" />
                </Button>
            )}
        </Box>
    )
}

export default ReferenceCircle
