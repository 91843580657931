import { gql } from '@apollo/client'

export const GetCollectionsByTeamId = gql`
    query getCollections(
        $teamId: bigint!
        $status: collection_status_enum_comparison_exp = {}
        $order_by: [collections_order_by!] = {}
    ) {
        collections(
            where: { team_id: { _eq: $teamId }, status: $status }
            order_by: $order_by
        ) {
            id
            name
            due_date
            status
            is_delayed
            team {
                id
                name
                team_members {
                    id
                    user {
                        email
                        name
                        other_data
                        id
                    }
                }
            }
            totalProducts: products_aggregate {
                aggregate {
                    count
                }
            }
            totalCompleted: products_aggregate(
                where: { status: { _eq: complete }, is_delayed: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const GetProductByTeamId = gql`
    query getProduct(
        $team_id: bigint_comparison_exp = {}
        $_and: [products_bool_exp!] = {}
        $order_by: [products_order_by!] = {}
    ) {
        new: products_aggregate(
            where: { team_id: $team_id, status: { _eq: new } }
        ) {
            aggregate {
                count
            }
        }
        inProgress: products_aggregate(
            where: { team_id: $team_id, status: { _eq: inProgress } }
        ) {
            aggregate {
                count
            }
        }
        completed: products_aggregate(
            where: { team_id: $team_id, status: { _eq: complete } }
        ) {
            aggregate {
                count
            }
        }
        products(
            where: { team_id: $team_id, _and: $_and }
            order_by: $order_by
        ) {
            product_variants {
                is_primary
                name
                id
                due_date
                status
                tags
                is_delayed
                product_variant_approver {
                    name
                    email
                    other_data
                    organisation_members {
                        role
                    }
                }
                other_data
                approver_id
                team {
                    id
                    name
                    team_members {
                        id
                        user {
                            id
                            name
                            email
                            other_data
                        }
                    }
                }
                collection {
                    name
                    id
                    team {
                        name
                        id
                        team_members {
                            id
                            user {
                                email
                                name
                                other_data
                                id
                            }
                        }
                    }
                }
                totalTodos: todos_aggregate {
                    aggregate {
                        count
                    }
                }
                completedTodos: todos_aggregate(
                    where: {
                        status: { _eq: complete }
                        is_delayed: { _eq: false }
                    }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`

export const GetProductsByApproverId = gql`
    query getProduct(
        $approver_id: bigint!
        $status: status_enum_comparison_exp = {}
    ) {
        new: products_aggregate(
            where: { status: { _eq: new }, approver_id: { _eq: $approver_id } }
        ) {
            aggregate {
                count
            }
        }
        inProgress: products_aggregate(
            where: {
                status: { _eq: inProgress }
                approver_id: { _eq: $approver_id }
            }
        ) {
            aggregate {
                count
            }
        }
        inReview: products_aggregate(
            where: {
                status: { _eq: inReview }
                approver_id: { _eq: $approver_id }
            }
        ) {
            aggregate {
                count
            }
        }
        revisionRequested: products_aggregate(
            where: {
                status: { _eq: requestedForResubmission }
                approver_id: { _eq: $approver_id }
            }
        ) {
            aggregate {
                count
            }
        }
        completed: products_aggregate(
            where: {
                status: { _eq: complete }
                approver_id: { _eq: $approver_id }
            }
        ) {
            aggregate {
                count
            }
        }
        product_variants(
            where: { approver_id: { _eq: $approver_id }, status: $status }
            order_by: { created_at: desc }
        ) {
            id
            name
            team_id
            is_delayed
            description
            due_date
            approver_due_date
            tags
            is_primary
            status
            approver_id
            other_data
            product_variant_approver {
                id
                name
                email
                other_data
            }
            collection {
                id
                name
                manager_id
                manager {
                    other_data
                    name
                    id
                }
                status
                is_delayed
                due_date
                team {
                    id
                    name
                    team_members {
                        user {
                            other_data
                            name
                            id
                        }
                    }
                }
            }
            product_variant_creator {
                id
                name
            }
            created_at
            updated_at
            todos {
                created_at
                created_by
                product_id
                due_date
                id
                name
                description
                status
                duration
                other_data
                is_delayed
                submitted_files
                assignee {
                    email
                    name
                    id
                    other_data
                    organisation_members {
                        role
                    }
                }
                sub_todos {
                    description
                    heading
                    created_at
                    id
                    todo_id
                    other_data
                    submitted_files
                    files {
                        file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                        }
                        library_file {
                            path
                            gid
                            name
                            thumbnail
                            id
                            other_data
                        }
                    }
                }
            }
            created_by
            approver_due_date
            approver_id
            collection_id
            request_revision_info
            team {
                name
                id
                team_members {
                    user {
                        email
                        name
                        other_data
                        id
                    }
                }
            }
        }
    }
`

export const GetTeamByUserId = gql`
    query getTeams($userId: bigint) {
        teams(where: { team_members: { user_id: { _eq: $userId } } }) {
            name
            id
            team_members {
                id
                user {
                    name
                    email
                    other_data
                }
            }
            name
            totalCollections: collections_aggregate {
                aggregate {
                    count
                }
            }
            totalCompletedCollectionsDelayed: collections_aggregate(
                where: { is_delayed: { _eq: true }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
            totalCompletedCollectionsOnTime: collections_aggregate(
                where: { is_delayed: { _eq: false }, status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
            totalCompleted: collections_aggregate(
                where: { status: { _eq: complete } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const GetTodosByUserId = gql`
    query GetUserTodos(
        $userId: bigint!
        $status: status_enum_comparison_exp = {}
        $order_by: [todos_order_by!] = {}
    ) {
        todos(
            where: { user_id: { _eq: $userId }, status: $status }
            order_by: $order_by
        ) {
            id
            name
            due_date
            status
            other_data
            is_delayed
            product: product_variant {
                collection {
                    id
                    name
                }
                id
                name
                other_data
            }
            assignee {
                id
                name
                email
                other_data
                organisation_members {
                    role
                }
            }
        }
        totalTodos: todos_aggregate(where: { user_id: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        totalCompletedTodos: todos_aggregate(
            where: { status: { _eq: complete }, user_id: { _eq: $userId } }
        ) {
            aggregate {
                count
            }
        }
        totalDelayedCollections: todos_aggregate(
            where: { is_delayed: { _eq: true }, user_id: { _eq: $userId } }
        ) {
            aggregate {
                count
            }
        }
    }
`
export const GetAnalyticsOfTeams = gql`
    query GetTeamsPercentagesForTeams($teamId: bigint!) {
        team: teams(where: { id: { _eq: $teamId } }) {
            name
            id
        }
        totalCollections: collections_aggregate(
            where: { team_id: { _eq: $teamId } }
        ) {
            aggregate {
                count
            }
        }
        totalCompletedCollections: collections_aggregate(
            where: { team_id: { _eq: $teamId }, status: { _eq: complete } }
        ) {
            aggregate {
                count
            }
        }
        collectionsCompletedOnTime: collections_aggregate(
            where: {
                team_id: { _eq: $teamId }
                status: { _eq: complete }
                is_delayed: { _eq: false }
            }
        ) {
            aggregate {
                count
            }
        }
        collectionsCompletedDelayed: collections_aggregate(
            where: {
                team_id: { _eq: $teamId }
                status: { _eq: complete }
                is_delayed: { _eq: true }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
export const GetAnalyticsOfUsers = gql`
    query GetTodoPercentagesForUser($userId: bigint) {
        organisation_members(where: { user_id: { _eq: $userId } }) {
            user {
                name
                id
                other_data
                email
                organisation_members {
                    role
                    deleted_at
                }
            }
        }
        totalTodos: todos_aggregate(where: { user_id: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        totalCompletedTodos: todos_aggregate(
            where: { user_id: { _eq: $userId }, status: { _eq: complete } }
        ) {
            aggregate {
                count
            }
        }
        totalCompletedTodosOnTime: todos_aggregate(
            where: {
                user_id: { _eq: $userId }
                is_delayed: { _eq: false }
                status: { _eq: complete }
            }
        ) {
            aggregate {
                count
            }
        }
        totalCompletedTodosDelayed: todos_aggregate(
            where: {
                user_id: { _eq: $userId }
                is_delayed: { _eq: true }
                status: { _eq: complete }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`
