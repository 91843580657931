import * as React from 'react'

export const FourOFourBag = () => (
    <svg
        width={250}
        height={102}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M43.095 91.738a2.748 2.748 0 0 1-2.018-.83c-.554-.555-.831-1.267-.831-2.138V74.524H2.849a2.747 2.747 0 0 1-2.018-.831C.277 73.139 0 72.427 0 71.556V61.94c0-.554.119-1.148.356-1.781a7.707 7.707 0 0 1 .95-1.78L36.09 10.652c1.029-1.345 2.493-2.018 4.392-2.018h15.078c.791 0 1.464.277 2.018.83.554.555.831 1.267.831 2.138v47.131h10.566c.95 0 1.662.277 2.137.831.554.554.831 1.227.831 2.019v9.972c0 .87-.277 1.583-.831 2.137a2.748 2.748 0 0 1-2.018.831H58.41V88.77c0 .871-.277 1.583-.831 2.137a2.748 2.748 0 0 1-2.018.831H43.095Zm-24.694-32.41h22.201V28.105l-22.2 31.223ZM221.151 91.738a2.748 2.748 0 0 1-2.018-.83c-.554-.555-.831-1.267-.831-2.138V74.524h-37.397a2.748 2.748 0 0 1-2.018-.831c-.554-.554-.831-1.266-.831-2.137V61.94c0-.554.119-1.148.356-1.781a7.719 7.719 0 0 1 .95-1.78l34.785-47.726c1.029-1.345 2.493-2.018 4.392-2.018h15.078c.791 0 1.464.277 2.018.83.554.555.831 1.267.831 2.138v47.131h10.566c.95 0 1.662.277 2.137.831.554.554.831 1.227.831 2.019v9.972c0 .87-.277 1.583-.831 2.137a2.748 2.748 0 0 1-2.018.831h-10.685V88.77c0 .871-.277 1.583-.831 2.137a2.748 2.748 0 0 1-2.018.831h-12.466Zm-24.693-32.41h22.2V28.105l-22.2 31.223Z"
            fill="#022143"
        />
        <path
            d="m161.332 70.445-.518-.776-.81.463c-1.608.918-4.102 1.643-6.656 2.204-1.887.414-3.736.725-5.184.96l.028-.04c.806-1.141 2.176-2.743 3.674-4.397a192.871 192.871 0 0 1 4.29-4.562c.607-.626 1.12-1.15 1.482-1.515.18-.184.323-.328.42-.426l.112-.111.028-.029.007-.007.002-.001-.707-.708.707.707.276-.276.016-.39.632-15.653.034-.853-.837-.168-.196.981.196-.98h-.001l-.003-.001-.009-.002-.036-.007-.139-.027a134.484 134.484 0 0 0-2.574-.463 193.986 193.986 0 0 0-7.318-1.093c-6.086-.793-14.3-1.589-22.789-1.589-14.487 0-28.95 2.713-32.887 3.509a2.909 2.909 0 0 0-2.155 1.843 191.893 191.893 0 0 0-4.668 14.219c-1.811 6.399-3.45 13.726-3.45 19.372a9.405 9.405 0 0 0 9.433 9.432h67.454a9.405 9.405 0 0 0 9.432-9.432c0-.794-.413-1.567-.862-2.23-.472-.697-1.119-1.462-1.819-2.264-.195-.224-.396-.452-.601-.685-1.293-1.468-2.749-3.122-4.004-5.005Zm-13.773 3.885c-.003.023-.006.026 0 .002v-.002Zm-32.64-66.371a7.326 7.326 0 0 0-7.324 7.324 7.326 7.326 0 0 0 7.324 7.324 7.326 7.326 0 0 0 7.324-7.324 7.326 7.326 0 0 0-7.324-7.324Zm21.079 0a7.322 7.322 0 0 0-7.323 7.324 7.322 7.322 0 0 0 12.502 5.179 7.322 7.322 0 0 0 0-10.358 7.323 7.323 0 0 0-5.179-2.145Zm-10.539 16.864c-17.485 0-32.115 2.802-37.245 3.917-1.652.36-2.611 2.008-2.21 3.606l1.084 4.307a3.052 3.052 0 0 0 3.606 2.23c4.868-1.05 18.488-3.629 34.765-3.629 16.276 0 29.896 2.58 34.765 3.629a3.053 3.053 0 0 0 3.606-2.23l1.083-4.307c.402-1.598-.557-3.247-2.21-3.606-5.129-1.115-19.76-3.917-37.244-3.917Z"
            fill="#EAFC40"
            stroke="#022143"
            strokeWidth={2}
        />
    </svg>
)
