import styled from 'styled-components'
import { Button } from '../utility'

export const PillButton = styled(Button)`
    background-color: ${(props) => props.bg || 'transparent'};
    padding: 4px 12px 4px 12px;
    height: max-content;
    border: 1px solid #e8ecef;
    border-radius: 40px;
    font-size: 12px;
    color: ${(props) => props.color || '#606060'};
    font-weight: 400;
    cursor: pointer;
`
