import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetCollections, GetProducts, GetTodos } from 'src/services'
import {
    getSortObject,
    pageLimit,
    setResourceCountFromData,
} from './collections.helper'
import { useUser } from 'src/helpers'
import type {
    Collection_Status_Enum_Comparison_Exp,
    GetCollectionsQuery,
    GetCollectionsQueryVariables,
    GetProductsQuery,
    GetProductsQueryVariables,
    GetTodosQuery,
    GetTodosQueryVariables,
    InputMaybe,
    Status_Enum_Comparison_Exp,
} from 'src/generated/graphql'
import { ENTITIES } from 'src/helpers/enums'

function useFetchEntity({
    dueDate,
    managers,
}: {
    dueDate?: [Date | null, Date | null] | Date | null
    managers: Array<{ id: number; name: string }>
}) {
    const [resourcesCount, setResourcesCount] = useState<
        Record<string, number>
    >({
        all: 0,
        new: 0,
        inProgress: 0,
        complete: 0,
    })
    const [searchParams] = useSearchParams()
    const { organization_id } = useUser()

    const {
        group: activeGroup = ENTITIES.collections,
        page: currentPage = '1',
        sort: activeSort = 'created_at',
        tab: currentTab = 'all',
    } = Object.fromEntries(searchParams.entries())

    const {
        data: collectionsData,
        loading: isCollectionsLoading,
        refetch: refetchCollection,
    } = useQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(
        GetCollections,
        {
            skip: activeGroup !== ENTITIES.collections,
            fetchPolicy: 'cache-and-network',
            variables: {
                offset: (Number(currentPage) - 1) * pageLimit,
                limit: pageLimit,
                status:
                    currentTab === 'all'
                        ? {}
                        : ({
                              _eq: currentTab,
                          } as InputMaybe<Collection_Status_Enum_Comparison_Exp>),
                order_by: getSortObject(activeSort),
                due_date: dueDate
                    ? Array.isArray(dueDate)
                        ? {
                              _gte: dueDate[0]?.toISOString(),
                              _lt: dueDate[1]?.toISOString(),
                          }
                        : {
                              _eq: new Date(
                                  new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                              ).toISOString(),
                          }
                    : undefined,
                manager_ids:
                    managers.length > 0
                        ? { _in: managers.map((manager) => manager.id) }
                        : undefined,
            },
        }
    )

    const {
        data: productsData,
        loading: isProductsLoading,
        refetch: refetchProduct,
    } = useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProducts, {
        skip: activeGroup !== ENTITIES.products,
        fetchPolicy: 'cache-and-network',
        variables: {
            offset: (Number(currentPage) - 1) * pageLimit,
            limit: pageLimit,
            status:
                currentTab === 'all'
                    ? {}
                    : ({
                          _eq: currentTab,
                      } as InputMaybe<Status_Enum_Comparison_Exp>),
            order_by: getSortObject(activeSort),
            due_date: dueDate
                ? Array.isArray(dueDate)
                    ? {
                          _gte: dueDate[0]?.toISOString(),
                          _lt: dueDate[1]?.toISOString(),
                      }
                    : {
                          _eq: new Date(
                              new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                          ).toISOString(),
                      }
                : undefined,
            manager_ids:
                managers.length > 0
                    ? { _in: managers.map((manager) => manager.id) }
                    : undefined,
        },
    })

    const {
        data: todosData,
        loading: isTodosLoading,
        refetch: refetchTodos,
    } = useQuery<GetTodosQuery, GetTodosQueryVariables>(GetTodos, {
        skip: activeGroup !== ENTITIES.todos,
        fetchPolicy: 'cache-and-network',
        variables: {
            offset: (Number(currentPage) - 1) * pageLimit,
            limit: pageLimit,
            status:
                currentTab === 'all'
                    ? {}
                    : ({
                          _eq: currentTab,
                      } as InputMaybe<Status_Enum_Comparison_Exp>),
            order_by: getSortObject(activeSort),
            due_date: dueDate
                ? Array.isArray(dueDate)
                    ? {
                          _gte: dueDate[0]?.toISOString(),
                          _lt: dueDate[1]?.toISOString(),
                      }
                    : {
                          _eq: new Date(
                              new Date(dueDate).setUTCHours(0, 0, 0, 0) - 1
                          ).toISOString(),
                      }
                : undefined,
            assignee_ids:
                managers.length > 0
                    ? { _in: managers.map((manager) => manager.id) }
                    : {},
            organisation_id: organization_id,
        },
    })

    const collectionsList = collectionsData?.collections
    const productsList = productsData?.products
    const todosList = todosData?.todos

    // Update resources count on data change
    useEffect(() => {
        if (activeGroup === ENTITIES.collections && collectionsData) {
            setResourcesCount(setResourceCountFromData(collectionsData))
        } else if (activeGroup === ENTITIES.products && productsData) {
            setResourcesCount(setResourceCountFromData(productsData))
        } else if (activeGroup === ENTITIES.todos && todosData) {
            setResourcesCount(setResourceCountFromData(todosData))
        }
    }, [
        collectionsList?.length,
        productsList?.length,
        todosList?.length,
        activeGroup,
    ])

    return {
        resourcesCount,
        refetchCollection,
        refetchProduct,
        refetchTodos,
        data:
            activeGroup === ENTITIES.collections
                ? collectionsData
                : activeGroup === ENTITIES.products
                ? productsData
                : todosData,
        isEntityLoading:
            activeGroup === ENTITIES.collections
                ? isCollectionsLoading
                : activeGroup === ENTITIES.products
                ? isProductsLoading
                : isTodosLoading,
    }
}

export default useFetchEntity
