import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { PillOptions } from 'src/components-v2/pill-options/pill-options'
import Pagination from 'src/components/pagination/pagination'
import { SearchIco } from 'src/components/svg-icons'
import {
    Box,
    Button,
    Input,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
} from 'src/components/utility'
import { debounce } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import { dropdownNames } from 'src/screens/people/people.constants'
import CollaboratorCard from './cards/collaborator-cards'
import { useFetchOrganistionMembers } from '../hooks'
import CircleCross from 'src/components/svg-icons/circle-cross-ico'
import useStore from 'src/store/hooks/useStore'
import { EntityEmptyState } from 'src/components/collections/entity-empty-state'
import type { CancelTokenSource } from 'axios'
import { useOrganizationContext } from '../organisation.context'

export interface ICollaboratorsProps {
    onLoad: (x: number) => void
    totalCount: number
}

function Collaborators(props: ICollaboratorsProps) {
    const [contributorsCurrentPage, setContributorsCurrentPage] =
        React.useState(1)
    const [searchParams, setSearchParams] = useSearchParams()

    const sortBy: any = searchParams.get('sort') || dropdownNames.name
    const [totalContributors, setContributorsCount] = React.useState(0)
    const { addDrawerTab, removeCurrentTab } = useStore()
    const [query, setQuery] = React.useState<string>(
        searchParams.get('q') || ''
    )

    const { individualContributors, setIndividualContributors } =
        useOrganizationContext()
    const { fetchOrganistionMembers, isLoaded } = useFetchOrganistionMembers()
    const [timeOutRef, setTimeOutRef] = React.useState()

    const searchFn = debounce(
        (val: string) => handleFetch(val),
        500,
        setTimeOutRef,
        timeOutRef
    )

    async function handleFetch(query: string, currentPage?: number) {
        searchParams.set('q', query)
        setSearchParams(searchParams)

        if (sortBy !== 'roles') {
            searchParams.delete('role')
            setSearchParams(searchParams)
        }

        const fetchedRole = searchParams.get('role')
        const role = fetchedRole
            ? fetchedRole === 'all'
                ? ''
                : fetchedRole
            : ''
        const data = await fetchOrganistionMembers({
            contributorsCurrentPage: currentPage || contributorsCurrentPage,
            role: sortBy === 'roles' ? role : '',
            searchString: query || '',
            status: searchParams.get('status') || 'all',
            sort: sortBy as 'lh' | 'hl' | 'az' | 'za',
        })
        if (data) {
            props.onLoad(data.count)
            setIndividualContributors(data.data)
            setContributorsCount(data.count)
        }
        return data?.source
    }

    React.useEffect(() => {
        let source: CancelTokenSource | undefined = undefined
        ;(async () => {
            if (searchParams.get('tab') === 'collaborators') {
                source = await handleFetch('')
                if (searchParams.get('drawer') === '1') {
                    removeCurrentTab()
                    addDrawerTab({
                        id: searchParams.get('id') || '0',
                        type: 'individualCollaborator',
                    })
                }
            }
        })()

        return () => {
            if (source) {
                source.cancel()
            }
        }
    }, [])

    const contributorRef = React.useRef<{
        contributor: IIndividualCollaborators[]
    }>()

    React.useEffect(() => {
        if (
            contributorRef.current &&
            contributorRef.current?.contributor?.length > 0
        )
            setIndividualContributors(contributorRef.current.contributor)
    }, [contributorRef.current?.contributor])

    return (
        <Box height="max-content">
            <Box
                mt="16px"
                display="flex"
                alignItems="center"
                gridColumnGap="24px"
                height="100%"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="4px"
                    py="4px"
                    bg="white"
                    px="8px"
                    width="300px"
                    border="solid"
                    borderWidth={1}
                    borderColor="gray250"
                    gridColumnGap="4px"
                >
                    <SearchIco />
                    <Input
                        bg="transparent"
                        border="none"
                        type="text"
                        width="100%"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => {
                            searchFn(e.target.value)
                            setQuery(e.target.value)
                        }}
                    />
                    {query.length > 0 && (
                        <Button
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bg="transparent"
                            border="none"
                            p="0px"
                            onClick={() => {
                                searchFn('')
                                setQuery('')
                            }}
                        >
                            <CircleCross bg="#778CA2" />
                        </Button>
                    )}
                </Box>
                <PillOptions
                    onClick={(d) => {
                        searchParams.set('status', d.id)
                        searchParams.set('page', '1')
                        setContributorsCurrentPage(1)
                        setSearchParams(searchParams)
                        handleFetch(query, 1)
                    }}
                    pills={[
                        {
                            id: 'all',
                            label: 'All',
                        },
                        {
                            id: 'active',
                            label: 'Active',
                        },
                        {
                            id: 'invited',
                            label: 'Invited',
                        },
                        {
                            id: 'archived',
                            label: 'Archived',
                        },
                    ]}
                />
            </Box>
            <Box
                bg={totalContributors <= 0 ? 'transparent' : 'white'}
                border="solid"
                borderWidth={1}
                borderColor="gray250"
                borderRadius="8px"
                justifyContent="space-around"
                width="100%"
                height="100%"
                mt="8px"
            >
                {totalContributors > 0 && (
                    <Table width="100%">
                        <Thead
                            border="solid"
                            borderWidth={1}
                            borderColor="gray250"
                            borderRadius="4px"
                            borderRight="none"
                            borderLeft="none"
                            borderTop="none"
                            fontSize={{ md: '12px', lg: '14px' }}
                        >
                            <Tr
                                color="textSecondaryDarkBlue"
                                fontFamily="Rubik"
                            >
                                <Th fontWeight={400} textAlign="left">
                                    Name
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    Role
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    Teams
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    To-Do's Completed
                                </Th>
                                <Th fontWeight={400} textAlign="left">
                                    Status
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {individualContributors?.map((contributor) => {
                                return (
                                    <CollaboratorCard
                                        onRefetch={() =>
                                            handleFetch(
                                                searchParams.get('q') || ''
                                            )
                                        }
                                        onDelete={(contributors) =>
                                            setIndividualContributors(
                                                contributors
                                            )
                                        }
                                        ref={contributorRef}
                                        contributor={contributor}
                                    />
                                )
                            })}
                        </Tbody>
                    </Table>
                )}
                {totalContributors <= 0 && (
                    <EntityEmptyState entityName="Collaborators Found!" />
                )}
            </Box>
            <Box width="100%" display="flex" justifyContent="end">
                <Pagination
                    className="pagination-bar"
                    currentPage={contributorsCurrentPage}
                    totalCount={props.totalCount}
                    pageSize={PerPage}
                    onPageChange={(page: number) => {
                        searchParams.set('page', String(page))
                        setSearchParams(searchParams)
                        handleFetch(query, page)
                        setContributorsCurrentPage(page)
                    }}
                />
            </Box>
        </Box>
    )
}

export default React.forwardRef(Collaborators)
