import { gql } from '@apollo/client'

export const GetRecentFilters = gql`
    query GetRecentFilters(
        $entity_name: String = "collections"
        $filter_for: String = "due_date"
    ) {
        recent_filters(
            where: {
                entity_name: { _eq: $entity_name }
                filter_for: { _eq: $filter_for }
            }
            limit: 3
        ) {
            id
            entity_name
            filter
        }
    }
`
