import { gql } from '@apollo/client'

export const InsertCollection = gql`
    mutation MyMutation(
        $company: String
        $description: String
        $due_date: timestamptz
        $brand: String
        $development_cycle: String
        $manager_id: bigint
        $name: String
        $team_id: bigint
        $tags: jsonb
    ) {
        insert_collections_one(
            object: {
                company: $company
                description: $description
                due_date: $due_date
                manager_id: $manager_id
                name: $name
                team_id: $team_id
                brand: $brand
                development_cycle: $development_cycle
                tags: $tags
            }
        ) {
            company
            status
            created_at
            updated_at
            name
            description
            due_date
            brand
            development_cycle
            id
            tags
            products {
                name
                status
            }
            manager {
                files(where: { type: { _eq: "profile_pic" } }) {
                    path
                }
                id
                name
            }
            creator {
                name
            }
            team {
                name
                id
                team_members {
                    user {
                        email
                        name
                        id
                        files(where: { type: { _eq: "profile_pic" } }) {
                            path
                        }
                    }
                }
            }
        }
    }
`

export const InsertNewCollection = gql`
    mutation InsertNewCollection($object: collections_insert_input = {}) {
        insert_collections_one(object: $object) {
            id
            name
        }
    }
`
