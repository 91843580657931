import * as React from 'react'

export const DownloadIco = ({ color = '#778CA2' }: { color: string }) => (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.99 3.412a.631.631 0 0 0-.623.64v9.003L8.92 11.606a.63.63 0 1 0-.893.893l2.526 2.527a.632.632 0 0 0 .893 0l2.527-2.527a.63.63 0 1 0-.893-.893l-1.449 1.449V4.053a.63.63 0 0 0-.64-.64ZM4.041 13.938a.632.632 0 0 0-.622.641v1.684a2.325 2.325 0 0 0 2.316 2.316h10.526a2.325 2.325 0 0 0 2.316-2.316V14.58a.631.631 0 1 0-1.263 0v1.684c0 .59-.464 1.053-1.053 1.053H5.736a1.043 1.043 0 0 1-1.053-1.053V14.58a.631.631 0 0 0-.641-.64Z"
            fill={color}
        />
    </svg>
)
