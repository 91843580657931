import { Dots, SuccessTickSm } from 'src/components/svg-icons'
import { Box, Button, Text } from 'src/components/utility'
import CalendarDropdown from '../dropdowns/calendar'
import StatusDropdown from '../dropdowns/status'
import { copyToClipBoard, getImageUrl, type statuses } from 'src/helpers'
import {
    HtmlTooltip,
    StackedImage,
} from 'src/components/stacked-image/stacked-image'
import type { TSearchedCollection } from 'src/components-v2/global-search/@types/search'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { memo, useEffect, useState } from 'react'
import useStore from 'src/store/hooks/useStore'
import { useRecentSearch } from 'src/components-v2/global-search/hooks/useRecentSearch'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import type { TGqlCollections } from 'src/screens-v2/organisations-analytics/@types/team.gql.typs'
import { DangerModal } from 'src/components/modals/danger-modal'
import {
    useDeleteCollection,
    useUpdateCollection,
} from '../hooks/useUpdateCollection'
import MeatBallDropdown from '../dropdowns/meatball-dropdown'
import { toast } from 'react-toastify'
import { AddCollaboratorModal } from 'src/screens-v2/organisations-analytics/modal/add-collaborator/add-collaborator'
import { ToolTipHeader } from 'src/screens/products/collection-details-table'
import { useCardExpandedState } from 'src/helpers/hooks/use-card-expanded-state'
import { CollectionBadge } from 'src/components-v2/badges/collection-badge'
import { isEntityDueDateDelayed } from 'src/helpers/is-entity-due-date-delayed'

interface ICollectionCardProps {
    entity: TSearchedCollection | TGqlCollections
    updateDate: (date: Date) => void
    tabView?: boolean
    dashboardView?: boolean
    afterDueDateUpdate?: (date: Date) => void
    afterStatusUpdate?: (
        status: (typeof statuses)[keyof typeof statuses]
    ) => void
    updateStatus: (status: (typeof statuses)[keyof typeof statuses]) => void
}

const CollectionCard = (props: ICollectionCardProps) => {
    const {
        globalSearchFilters,
        setGlobalSearchActive,
        removeCollection,
        clearDrawerTabs,
    } = useStore()

    const [searchParams] = useSearchParams()
    const [showCollectionProducts, setShowCollectionProducts] = useState(false)
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)
    const [isCollaboratorModalOpen, setCollaboratorModalOpen] = useState(false)
    const {
        addValuesToExpanded,
        getExpandedStates,
        setLastOpened,
        getLastOpenedTab,
        removeLastOpenedTab,
    } = useCardExpandedState()

    useEffect(() => {
        const data = getExpandedStates()
        if (data && data.collection) {
            if (data.collection[Number(entity.id)]) {
                setShowCollectionProducts(data.collection[Number(entity.id)])
            }
        }
    }, [searchParams.get('e_hash')])

    const tab = getLastOpenedTab('collection')
    useEffect(() => {
        let timeoutId: NodeJS.Timeout
        if (tab) {
            const card = document.getElementById(`collection_card_${tab}`)
            if (card) {
                card.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                timeoutId = setTimeout(removeLastOpenedTab, 1000)
            }
        }
        return () => clearTimeout(timeoutId)
    }, [])

    function handleTabExpand() {
        addValuesToExpanded(
            {
                id: Number(entity.id),
                isExpanded: !showCollectionProducts,
            },
            'collection'
        )
    }

    const {
        entity,
        tabView = false,
        dashboardView = false,
        afterDueDateUpdate,
        afterStatusUpdate,
    } = props
    const navigate = useNavigate()
    const { addRecentSearch } = useRecentSearch()
    const {
        updateCollection,
        updateCollectionStatus,
        updateCollectionDueDate,
    } = useUpdateCollection()

    const { deleteCollection } = useDeleteCollection()
    const totalCompletedProduct =
        ('products' in entity && entity.products.totalCompleted) ||
        ('totalCompleted' in entity && entity.totalCompleted.aggregate.count) ||
        0
    const totalProducts =
        ('products' in entity && entity.products.total) ||
        ('totalProducts' in entity && entity.totalProducts.aggregate.count) ||
        0

    function onDropdownClick(option: string) {
        switch (option) {
            case 'Delete':
                setIsModalActive(true)
                return
            case 'Copy Link':
                copyToClipBoard(
                    window.location.host + '/collections/' + entity.id
                )
                toast('Link Copied to Clipboard', {
                    className: 'custom-toaster toaster-success',
                })
                return
        }
    }

    let name = entity.name
    if (tabView) {
        name = convertIntoElipses(entity.name, 10)
    } else {
        const screenSize = window.innerWidth
        if (screenSize <= 1450 && screenSize > 1400) {
            name = convertIntoElipses(entity.name, 40)
        } else if (screenSize <= 1400 && screenSize > 1250) {
            name = convertIntoElipses(entity.name, 30)
        } else if (screenSize > 1450) {
            name = convertIntoElipses(entity.name, 64)
        } else {
            name = convertIntoElipses(entity.name, 30)
        }
    }

    return (
        <Box>
            <Box
                px="16px"
                py="12px"
                display="grid"
                gridTemplateColumns={{
                    md: `${dashboardView ? '250px' : '300px'} auto  ${
                        dashboardView ? '0px' : '100px 100px'
                    }`,
                    xl: `max-content auto 100px ${
                        dashboardView ? '0px' : '100px'
                    }`,
                }}
                border="solid"
                borderColor={showCollectionProducts ? '#8496A8' : 'gray250'}
                alignItems="center"
                borderWidth={1}
                id={`collection_card_${entity.id}`}
                className={
                    getLastOpenedTab('collection') === entity.id
                        ? 'card-hover card-bg cursor-pointer'
                        : 'card-hover cursor-pointer'
                }
                onClick={() => {
                    setLastOpened({
                        entityId: Number(entity.id),
                        type: 'collection',
                    })
                    setGlobalSearchActive({
                        isGlobalSearchActive: false,
                    })
                    globalSearchFilters.isGlobalSearchActive &&
                        addRecentSearch({
                            entity_name: 'collections',
                            entity_id: entity.id,
                            search_query: globalSearchFilters.query,
                        })
                    navigate(`/collections/${entity.id}`)
                    clearDrawerTabs()
                }}
                // justifyContent="space-between"
            >
                <Box
                    minWidth={
                        tabView
                            ? { md: '200px', xl: '300px' }
                            : { md: '300px', xl: '500px' }
                    }
                    maxWidth={{ md: '300px', xl: 'max-content' }}
                    gridColumnGap="8px"
                    alignItems="center"
                    display="flex"
                >
                    <CollectionBadge />
                    <HtmlTooltip
                        placement="bottom-start"
                        arrow
                        title={
                            entity?.name && entity?.name?.length > 16 ? (
                                <ToolTipHeader text={entity?.name || ''} />
                            ) : (
                                <></>
                            )
                        }
                    >
                        <Text fontSize={{ md: '12px', xl: '14px' }} my="0px">
                            {name}
                        </Text>
                    </HtmlTooltip>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    gridRowGap="4px"
                    gridColumnGap="50px"
                >
                    {(dashboardView || !tabView) && (
                        <Box
                            // minWidth="200px"
                            gridColumnGap="8px"
                            display="flex"
                            alignItems="center"
                        >
                            <SuccessTickSm />
                            <Text
                                fontSize={{ md: '10px', xl: '12px' }}
                                my="0px"
                            >
                                {totalCompletedProduct}/{totalProducts}{' '}
                                {totalProducts > 1 ? 'Products' : 'Product'}
                            </Text>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        justifyContent="space-evenly"
                        flexDirection={dashboardView ? 'column' : 'row'}
                        alignItems="center"
                        gridRowGap="4px"
                        gridColumnGap={'50px'}
                    >
                        <CalendarDropdown
                            date={
                                entity?.due_date
                                    ? new Date(entity.due_date)
                                    : null
                            }
                            left={dashboardView ? '-255px' : '0px'}
                            setDate={(date) =>
                                updateCollectionDueDate({
                                    id: entity.id as number,
                                    dueDate: date.toISOString(),
                                    onComplete: () => {
                                        afterDueDateUpdate &&
                                            afterDueDateUpdate(date)
                                    },
                                })
                            }
                            isDelayed={isEntityDueDateDelayed(
                                // !!!FIXME: due_date received from the DB is always string. Please update the types
                                entity?.due_date
                                    ? (entity.due_date as unknown as string)
                                    : null,
                                entity.status
                            )}
                        />
                        <StatusDropdown
                            exclude={[
                                'revisionRequested',
                                'requestedForResubmission',
                                'inReview',
                            ]}
                            onStatusChange={(status) =>
                                updateCollectionStatus({
                                    id: entity.id as number,
                                    status: status,
                                    onComplete: () =>
                                        afterStatusUpdate &&
                                        afterStatusUpdate(status),
                                })
                            }
                            status={entity.status}
                        />
                    </Box>
                    {!tabView && (
                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                            width="215px"
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: Number(entity.id),
                                    type: 'collection',
                                })
                                setCollaboratorModalOpen(true)
                            }}
                        >
                            {entity.team ? (
                                <Text
                                    fontSize={{ md: '12px', xl: '14px' }}
                                    my="0px"
                                >
                                    {convertIntoElipses(
                                        entity.team?.name || '',
                                        16
                                    )}
                                </Text>
                            ) : (
                                <Text
                                    fontSize={{ md: '12px', xl: '14px' }}
                                    color="textSecondary"
                                >
                                    No Team Assigned
                                </Text>
                            )}
                            {entity.team?.team_members &&
                            entity.team?.team_members.length > 0 ? (
                                <StackedImage
                                    size="24px"
                                    width="max-content"
                                    fontSize="12px"
                                    spacing={15}
                                    list={
                                        entity.team?.team_members?.map(
                                            (member) => {
                                                return {
                                                    name:
                                                        member.user?.name || '',
                                                    src: getImageUrl(
                                                        member.user?.other_data
                                                            ?.thumbnail
                                                    ),
                                                }
                                            }
                                        ) ?? []
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </Box>
                    )}
                </Box>
                {!dashboardView && totalProducts > 0 ? (
                    <Button
                        color="links"
                        border="none"
                        bg="transparent"
                        width="115px"
                        fontSize={{ md: '10px', xl: '12px' }}
                        height="100%"
                        onClick={(e) => {
                            e.stopPropagation()
                            setLastOpened({
                                entityId: Number(entity.id),
                                type: 'collection',
                            })
                            handleTabExpand()
                            setShowCollectionProducts(!showCollectionProducts)
                        }}
                    >
                        {showCollectionProducts ? 'Hide' : 'View'}{' '}
                        {totalProducts} Products
                    </Button>
                ) : (
                    !dashboardView && (
                        <Button
                            color="links"
                            border="none"
                            bg="transparent"
                            width="115px"
                            disabled
                            fontSize={{ md: '10px', xl: '12px' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: Number(entity.id),
                                    type: 'collection',
                                })
                            }}
                        >
                            No Products
                        </Button>
                    )
                )}
                {!dashboardView && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setLastOpened({
                                    entityId: Number(entity.id),
                                    type: 'collection',
                                })
                                setIsDropdownActive(!isDropdownActive)
                            }}
                            bg="transparent"
                            border="none"
                        >
                            <Dots active={false} />
                        </Button>
                        {isDropdownActive && (
                            <MeatBallDropdown
                                left="-32px"
                                isActive={isDropdownActive}
                                onClick={(option) => {
                                    setLastOpened({
                                        entityId: Number(entity.id),
                                        type: 'collection',
                                    })
                                    onDropdownClick(option)
                                }}
                                setIsActive={(isActive) =>
                                    setIsDropdownActive(isActive)
                                }
                                onClose={() => setIsDropdownActive(false)}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <DangerModal
                heading={`Delete '${entity.name}'`}
                isModal={isModalActive}
                onRemove={() => {
                    deleteCollection(
                        Number(entity.id),
                        ['getCollections', 'GetCollections'],
                        () => {
                            globalSearchFilters.isGlobalSearchActive &&
                                removeCollection({
                                    id: Number(entity.id),
                                })
                            toast('Collection Deleted Successfully', {
                                className: 'custom-toaster toaster-success',
                            })
                        }
                    )
                }}
                onClose={() => setIsModalActive(false)}
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                title={`Are you sure you want to delete '${entity.name}'`}
                subHeading="Deleting this will remove all the existing To-Do's and Products associated with it. This action cannot be undone."
            />

            {(!dashboardView || !tabView) && isCollaboratorModalOpen && (
                <AddCollaboratorModal
                    onRoleUpdate={() => {}}
                    teamMembers={[]}
                    onCollaboratorsAdded={(members: any) => {
                        // setMembers(members)
                    }}
                    teamId={String(entity?.team?.id || 0)}
                    onTeamAdded={(id) => {
                        updateCollection(
                            Number(entity.id),
                            {
                                team_id: id,
                            },
                            [
                                'GetCollections',
                                'getCollections',
                                'getCollectionsByUserId',
                            ]
                        )
                        setCollaboratorModalOpen(false)
                    }}
                    isModal={isCollaboratorModalOpen}
                    onClose={(isOpen) => setCollaboratorModalOpen(isOpen)}
                />
            )}
        </Box>
    )
}
export default memo(CollectionCard)
