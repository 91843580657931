import { Box, Button } from 'src/components/utility'
import { Line } from 'rc-progress'
import { DeleteIco } from 'src/components/svg-icons'
export function ProgressBar({
    percentage,
    data,
    onDeleteClick,
    fontSize,
    barWidth,
    isDisabled = false,
}: {
    percentage: number
    data?: any
    onDeleteClick?: Function
    fontSize?: FontSize
    barWidth?: string
    isDisabled?: boolean
}) {
    return (
        <Box display="flex" gridColumnGap="68px" width="100%">
            <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="100%"
                gridColumnGap="12px"
                fontSize={fontSize || '16px'}
                color={isDisabled ? 'textSecondaryDarkBlue' : 'primary'}
            >
                {percentage + '%'}{' '}
                <Line
                    percent={percentage}
                    strokeWidth={4}
                    trailWidth={4}
                    trailColor={isDisabled ? '#E6E7E9' : '#F2F2F2'}
                    strokeColor={
                        isDisabled
                            ? '#E6E7E9'
                            : percentage > 60
                            ? '#39A407'
                            : percentage < 60 && percentage > 30
                            ? '#EF8D1B'
                            : '#FF2C18'
                    }
                    style={{ width: barWidth ? barWidth : '150px' }}
                ></Line>
            </Box>
            {typeof onDeleteClick === 'function' && (
                <Button
                    className="delete-ico"
                    bg="transparent"
                    border="none"
                    onClick={() => onDeleteClick(data)}
                >
                    <DeleteIco />
                </Button>
            )}
        </Box>
    )
}
