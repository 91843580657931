import { useApolloClient, useQuery } from '@apollo/client'
import React from 'react'
import { Modal } from 'src/components/modals/modal'
import { GetProductTodoImages } from '../../services/graphql/entities/product/product.queries'
import { Loader } from 'src/components/loader/loader'
import axios from 'axios'
import { ASSIGN_FILE_FROM_LIBRARY } from 'src/services'
import { getLocalStorage } from 'src/helpers'
import { ASSIGN_FILE_FROM_FILES } from 'src/services/api/endpoints'
import { toast } from 'react-toastify'
import { Box, Text } from 'src/components/utility'
import SearchBox from '../search-box/search-box'
import { Slider } from 'src/components/slider/slider'
import TodoSliderElement from './todo-slider-element'
import { FileDisplay2 } from 'src/components/file-display/file-display-2'
import { getImageUrl, getLibraryFileUrl } from 'src/helpers/get-image-url'
import { getThumbnail } from 'src/components/annotation-comments/annotation-comments.helper'
import {
    getFilesToRender,
    getProductTodosWithFiles,
} from './assign-from-todo-modal.helper'
import type {
    GetProductTodoImagesQuery,
    GetProductTodoImagesQueryVariables,
} from 'src/generated/graphql'

function AssignFromTodoModal({
    onComplete,
    onClose,
    isOpen,
    entityId,
    entityName,
    productId,
}: {
    onComplete?: () => void
    onClose: () => void
    isOpen: boolean
    entityId: number
    productId: number
    entityName:
        | 'todos'
        | 'products'
        | 'sub_todos'
        | 'collections'
        | 'media'
        | 'product_variants'
}) {
    const [selectedTodoTab, setSelectedTodoTab] = React.useState('All')
    const [selectedImage, setSelectedImage] = React.useState<any>(null)
    const [searchValue, setSearchValue] = React.useState('')
    const allowedFileTypes = ['png', 'jpg', 'jpeg']
    const { data, loading } = useQuery<
        GetProductTodoImagesQuery,
        GetProductTodoImagesQueryVariables
    >(GetProductTodoImages, {
        skip: !productId,
        fetchPolicy: 'no-cache',
        variables: {
            id: productId,
        },
    })
    const client = useApolloClient()

    if (loading) {
        return <Loader />
    }

    const productTodosWithFiles = getProductTodosWithFiles(
        data?.product_variants_by_pk?.todos
    )

    const todoTabs = ['All', ...productTodosWithFiles.map((item) => item.todo)]

    const filteredFiles = getFilesToRender(
        productTodosWithFiles,
        selectedTodoTab,
        searchValue,
        allowedFileTypes
    )

    async function fileClickHandler(fileData: {
        id: number
        gid: string
        name: string
        from_library: boolean
    }) {
        const payload: {
            entity_name: string
            entity_id: number
            assigned_as: string
            file_ids?: number[]
            library_file_ids?: number[]
        } = {
            entity_name: entityName,
            entity_id: entityId,
            assigned_as: 'cover_pic',
        }
        if (!fileData.from_library) {
            payload.file_ids = [fileData.id]
            try {
                const response = await axios.post(
                    ASSIGN_FILE_FROM_FILES,
                    payload,
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    onComplete && onComplete()
                    client.refetchQueries({
                        include: 'active',
                    })
                    toast('Thumbnail assigned successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                }
            } catch {
                toast('Failed to assign thumbnail', {
                    className: 'custom-toaster toaster-error',
                })
            }
        } else {
            payload.library_file_ids = [fileData.id]
            try {
                const response = await axios.post(
                    ASSIGN_FILE_FROM_LIBRARY,
                    payload,
                    {
                        headers: {
                            'x-auth-token': getLocalStorage('authToken'),
                        },
                    }
                )
                if (response.status === 200) {
                    onComplete && onComplete()
                    client.refetchQueries({
                        include: 'active',
                    })
                    toast('Thumbnail assigned successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                }
            } catch {
                toast('Failed to assign thumbnail', {
                    className: 'custom-toaster toaster-error',
                })
            }
        }
        onClose()
    }

    function changeTodoTab(value: number) {
        const currentIndex = todoTabs.indexOf(selectedTodoTab)
        if (value === 1) {
            if (currentIndex < todoTabs.length - 1) {
                setSelectedTodoTab(todoTabs[currentIndex + 1])
            }
        } else if (value === -1) {
            if (currentIndex > 0) {
                setSelectedTodoTab(todoTabs[currentIndex - 1])
            }
        }
    }

    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={() => {
                fileClickHandler(selectedImage)
            }}
            primaryButtonTitle="Choose"
            secondaryButtonTitle="Cancel"
            title="Thumbnail: Select File from To-dos"
            bg="white"
            preventDefault={true}
            disablePrimaryButton={!selectedImage}
            maxWidth="40%"
            minHeight="50%"
        >
            <Box
                bg="backgroundColor"
                p="24px"
                width={'100%'}
                display="flex"
                flexDirection="column"
                gridGap="16px"
            >
                {/* Searchbox */}
                <SearchBox
                    value={searchValue}
                    onChange={(value) => setSearchValue(value)}
                />
                {/* Slider */}
                <Slider
                    element={
                        <TodoSliderElement
                            currentTab={selectedTodoTab}
                            onTabChange={(tab) => {
                                setSelectedTodoTab(tab)
                            }}
                            tabs={todoTabs}
                        />
                    }
                    carousel
                    onCarouselElementClick={
                        todoTabs.length > 4
                            ? (value) => changeTodoTab(value)
                            : undefined
                    }
                />
                {/* Files */}
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gridGap="12px"
                    maxWidth="80vw"
                    maxHeight="80vh"
                    overflow={'auto'}
                    onClick={(e) => e.preventDefault()}
                >
                    {filteredFiles?.length > 0 ? (
                        filteredFiles.map((file: any) => {
                            const fileData = file.library_file
                                ? {
                                      ...file.library_file,
                                      from_library: true,
                                  }
                                : file.file
                                ? {
                                      ...file.file,
                                      from_library: false,
                                  }
                                : null

                            return (
                                <FileDisplay2
                                    key={file.id}
                                    onClick={() => setSelectedImage(fileData)}
                                    name={fileData.name}
                                    url={
                                        fileData.from_library
                                            ? getLibraryFileUrl(fileData.gid)
                                            : getImageUrl(fileData.gid)
                                    }
                                    thumbnail={getThumbnail(fileData)}
                                    onDelete={() => {}}
                                    width="118px"
                                    height="97px"
                                    isDeletePermitted={false}
                                    fileId={file.id}
                                    id={`thumbnail_file_${file.id}`}
                                    selected={selectedImage?.id === fileData.id}
                                />
                            )
                        })
                    ) : (
                        <Text>No files found</Text>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

export default AssignFromTodoModal
