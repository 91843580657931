import { useMutation } from '@apollo/client'
import { useState } from 'react'
import type { CreateTeamMutation } from 'src/generated/graphql'
import { CreateTeam } from 'src/services/graphql/entities/team/create-team'
import { InsertTeamMembers } from 'src/services/graphql/entities/team/insert-teammembers'

export function useTeam() {
    const [createTeamMutation] = useMutation<CreateTeamMutation>(CreateTeam)
    const [addTeamMembersMutation] =
        useMutation<CreateTeamMutation>(InsertTeamMembers)
    const [createdTeamId, setCreatedTeamId] = useState<number>(0)
    async function createTeam(query: string) {
        let teamId = undefined
        await createTeamMutation({
            variables: {
                name: query,
            },
            onCompleted: (data) => {
                setCreatedTeamId(data.insert_teams_one?.id)
                teamId = data.insert_teams_one?.id
            },
        })
        return teamId
    }
    function addCollaborator(collaboratorIds: number[], teamId: number) {
        addTeamMembersMutation({
            variables: {
                objects: collaboratorIds.map((id) => ({
                    user_id: id,
                    team_id: teamId,
                })),
            },
        })
    }
    return { createdTeamId, createTeam, addCollaborator }
}
