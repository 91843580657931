import React, { useState } from 'react'
import { DropDownArrow } from 'src/components/svg-icons'
import { Box, Button } from 'src/components/utility'
import ColorPickerDropdown from './color-picker-dropdown'
import { SUBTODO_DESIGN_COLORS } from 'src/helpers/constants'

function SubtodoColorPicker({
    activeColor,
    updateDesignColor,
}: {
    activeColor: string | null | undefined
    updateDesignColor: (color: string) => void
}) {
    const [showColorDropdown, setShowColorDropdown] = useState(false)

    return (
        <Button
            bg="white"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            style={{ gap: '2px' }}
            py="2px"
            px="4px"
            className="cursor-pointer"
            position="relative"
            id="subtodo-color-picker-container"
            onClick={() => setShowColorDropdown(!showColorDropdown)}
            border="none"
        >
            <Box
                width="14px"
                height="14px"
                bg={activeColor || SUBTODO_DESIGN_COLORS[0]}
                borderRadius="50%"
                id="subtodo-color-picker-active-color"
            ></Box>
            <DropDownArrow
                rotate={showColorDropdown}
                id="subtodo-color-picker-icon"
            />
            {showColorDropdown && (
                <ColorPickerDropdown
                    active={showColorDropdown}
                    onClose={() => setShowColorDropdown(false)}
                    activeColor={activeColor || SUBTODO_DESIGN_COLORS[0]}
                    updateDesignColor={updateDesignColor}
                />
            )}
        </Button>
    )
}

export default SubtodoColorPicker
