import type { SetStateAction } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { Box } from 'src/components/utility'
import { DropdownButton } from './styled.button.wrapper'

export const Dropdown = forwardRef(
    (
        {
            children,
            active,
            width,
            overflowY,
            overflowX = 'visible',
            uniqueId = 'dropdown__focus',
            top,
            left,
            right,
            maxHeight,
            maxWidth,
            setDropDownDisabled,
            zIndex,
            py,
        }: {
            children: JSX.Element
            active: Boolean
            uniqueId?: string
            width?: string
            overflowY?: Overflow
            overflowX?: Overflow
            top?: string
            left?: string
            right?: string
            maxHeight?: string
            maxWidth?: string
            zIndex?: 0 | 1 | 2 | 3 | 4 | 5 | -1 | 10 | 15 | undefined
            setDropDownDisabled?: React.Dispatch<SetStateAction<boolean>>
            py?: FourPixelGridSystem
            px?: FourPixelGridSystem
        },
        ref: HTMLDivElement | any
    ) => {
        function disableDropdown(event: Event | any) {
            if (ref !== null && ref.current !== null) {
                const isClickInsideElement = ref.current.contains(event.target)
                // console.log({ isClickInsideElement })
                if (!isClickInsideElement && setDropDownDisabled) {
                    // console.log(event)
                    if (!event.target.id) {
                        // console.log("here checking the id")
                        setDropDownDisabled(false)
                    } else if (!event.target?.id?.includes(uniqueId)) {
                        setDropDownDisabled(false)
                    }
                }
            }
        }

        useEffect(() => {
            document.addEventListener('click', disableDropdown)
            return () => document.removeEventListener('click', disableDropdown)
        }, [])

        return (
            <Box
                boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
                ref={ref}
                display="flex"
                flexDirection="column"
                alignItems="start"
                id="dropdown_items"
                position="absolute"
                top={top || '26px'}
                left={left || '0'}
                right={right || 'unset'}
                width={width || 'max-content'}
                bg="white"
                py={py || '16px'}
                zIndex={zIndex || 5}
                borderRadius="4px"
                className={
                    active ? 'scrollbar_none dropdown-show' : 'dropdown-hide'
                }
                maxHeight={maxHeight}
                overflowY={overflowY}
                overflowX={overflowX}
                maxWidth={maxWidth || 'unset'}
            >
                {children}
            </Box>
        )
    }
)

export const DropdownButtonWrapper = ({
    onClick,
    children,
    width,
    minWidth,
    maxWidth,
    onHover,
    overflow,
    position,
    display,
    hover = true,
    value,
    name = 'dropdow_item',
    p = '0px',
    px = '0px',
    py = '0px',
}: {
    onClick?: (x: any) => void
    children: JSX.Element
    width?: string
    minWidth?: string
    maxWidth?: string
    onHover?: JSX.Element
    overflow?: Overflow
    position?: Position
    display?: string
    hover?: boolean
    value?: string
    name?: string
    p?: FourPixelGridSystem
    px?: FourPixelGridSystem
    py?: FourPixelGridSystem
}) => {
    const [isExtensionVisible, setIsExtensionVisible] = useState(false)
    return (
        <DropdownButton
            p={p}
            px={px}
            py={py}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick && onClick(value)
            }}
            hover={hover ? '#E8ECEF' : 'transparent'}
            onMouseLeave={() => setIsExtensionVisible(false)}
            onMouseEnter={() => setIsExtensionVisible(true)}
            width={width}
            id={`dropdown_button_${name}`}
            overflow={overflow}
            position={position}
            display={display}
            minWidth={minWidth || width}
            maxWidth={maxWidth || 'unset'}
        >
            {children}
            {isExtensionVisible && onHover}
        </DropdownButton>
    )
}
