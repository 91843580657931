import { commentParser } from 'src/helpers'
import type { TPillBadges, TTabs } from '../@types'

export function divideChunks(comment: string) {
    const firstChunk = comment.split('<OGCOMMENT>')
    const systemComment = firstChunk[0] || ''
    return {
        systemComment,
    }
}

export function buildComment(comment: string) {
    const chunks = divideChunks(comment)
    const separatedComment = chunks.systemComment.split('<COVERPIC>')[0]
    // const separatedCommentSplit = separatedComment.split('<PCOMMENT>')
    const systemParsed = commentParser(
        separatedComment.split('<PCOMMENT>')[0],
        'notification'
    )
    // const parentComment =
    //     separatedCommentSplit.length > 1
    //         ? commentParser(
    //               separatedCommentSplit[1].replace('</PCOMMENT>', ''),
    //               'notification'
    //           )
    //         : ''
    return { systemParsed }
}

export function createWhereClause(
    activePillBadge: TPillBadges,
    activeTab: TTabs
) {
    let where = {}
    if (activeTab === 'all') {
        where = {
            entity_name: {
                _is_null: false,
            },
        }
    } else if (activeTab === 'comments') {
        where = {
            type: {
                _eq: 'comments',
            },
        }
    } else if (activeTab === 'unread') {
        where = {
            opened: {
                _eq: false,
            },
        }
    } else if (activeTab === 'othersUpdate') {
        where = {
            type: {
                _eq: 'others',
            },
        }
    } else if (activeTab === 'statusUpdate') {
        where = {
            type: {
                _eq: 'status',
            },
        }
    }

    if (activePillBadge.value === 'all') {
        return where
    } else if (
        activePillBadge.value === 'collections' ||
        activePillBadge.value === 'products' ||
        activePillBadge.value === 'todos'
    ) {
        return {
            ...where,
            _and: {
                entity_name: {
                    _eq: activePillBadge.value,
                },
            },
        }
    } else if (
        activePillBadge.value === 'complete' ||
        activePillBadge.value === 'inProgress' ||
        activePillBadge.value === 'inReview'
    ) {
        return {
            ...where,
            _and: {
                type_details: {
                    _eq: activePillBadge.value,
                },
            },
        }
    } else if (activePillBadge.value === 'mentionAndAnnotation') {
        return {
            ...where,
            _or: {
                type: { _eq: 'annotations' },
                type_details: { _eq: 'mentions' },
            },
        }
    } else if (activePillBadge.value === 'others') {
        return {
            ...where,
            _and: {
                type: {
                    _eq: 'others',
                },
            },
        }
    }
    return where
}
