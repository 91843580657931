import React from 'react'
import { processFileName } from 'src/components/file-display/file-display.helper'
import { Box, Text } from 'src/components/utility'
import { ExtensionIcon } from '../extension-icon/extension-icon'

function FileBadge({ fileName }: { fileName: string }) {
    const displayName = processFileName(fileName)
    return (
        <Box display="flex" alignItems="center" gridGap="4px">
            <ExtensionIcon
                size="sm"
                type={fileName
                    ?.split('.')
                    [fileName.split('.').length - 1].toLowerCase()}
            />
            <Text
                className="overflow__elipses"
                fontSize="12px"
                my="0px"
                fontFamily="Rubik"
                color="primary"
            >
                {displayName}
            </Text>
        </Box>
    )
}

export default FileBadge
