import * as React from "react"
export const VirtuLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={25} fill="none">
    <path
      fill="#022143"
      fillRule="evenodd"
      d="M10.034 12.266 19.912 6.6l-8.635-4.804L1.644 7.3v10.971l8.39 4.702V12.266Zm10.515-5.312ZM.824 6.347 10.866.608a.823.823 0 0 1 .809-.004l9.474 5.271a.823.823 0 0 1 .01 1.434l-9.89 5.673v10.693a.823.823 0 0 1-1.226.719L.83 19.23a.824.824 0 0 1-.42-.719V7.062c0-.296.158-.569.414-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M10.034 12.266 19.912 6.6l-8.635-4.804L1.644 7.3v10.971l8.39 4.702V12.266Zm10.515-5.312ZM.824 6.347 10.866.608a.823.823 0 0 1 .809-.004l9.474 5.271a.823.823 0 0 1 .01 1.434l-9.89 5.673v10.693a.823.823 0 0 1-1.226.719L.83 19.23a.824.824 0 0 1-.42-.719V7.062c0-.296.158-.569.414-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M10.034 12.266 19.912 6.6l-8.635-4.804L1.644 7.3v10.971l8.39 4.702V12.266Zm10.515-5.312ZM.824 6.347 10.866.608a.823.823 0 0 1 .809-.004l9.474 5.271a.823.823 0 0 1 .01 1.434l-9.89 5.673v10.693a.823.823 0 0 1-1.226.719L.83 19.23a.824.824 0 0 1-.42-.719V7.062c0-.296.158-.569.414-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M10.034 12.266 19.912 6.6l-8.635-4.804L1.644 7.3v10.971l8.39 4.702V12.266Zm10.515-5.312ZM.824 6.347 10.866.608a.823.823 0 0 1 .809-.004l9.474 5.271a.823.823 0 0 1 .01 1.434l-9.89 5.673v10.693a.823.823 0 0 1-1.226.719L.83 19.23a.824.824 0 0 1-.42-.719V7.062c0-.296.158-.569.414-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#022143"
      fillRule="evenodd"
      d="M5.668 9.112a.618.618 0 0 1 .847-.212l4.908 2.944a.618.618 0 1 1-.636 1.06L5.88 9.958a.618.618 0 0 1-.211-.847Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M5.668 9.112a.618.618 0 0 1 .847-.212l4.908 2.944a.618.618 0 1 1-.636 1.06L5.88 9.958a.618.618 0 0 1-.211-.847Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M5.668 9.112a.618.618 0 0 1 .847-.212l4.908 2.944a.618.618 0 1 1-.636 1.06L5.88 9.958a.618.618 0 0 1-.211-.847Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M5.668 9.112a.618.618 0 0 1 .847-.212l4.908 2.944a.618.618 0 1 1-.636 1.06L5.88 9.958a.618.618 0 0 1-.211-.847Z"
      clipRule="evenodd"
    />
    <path
      fill="#022143"
      fillRule="evenodd"
      d="M14.08 14.382v8.125l4.678-2.52a4.94 4.94 0 0 0 2.598-4.35v-5.404l-7.276 4.149Zm7.28-5.573-8.515 4.856v9.531a.823.823 0 0 0 1.214.725l5.285-2.847a6.176 6.176 0 0 0 3.247-5.437V9.524a.823.823 0 0 0-1.231-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M14.08 14.382v8.125l4.678-2.52a4.94 4.94 0 0 0 2.598-4.35v-5.404l-7.276 4.149Zm7.28-5.573-8.515 4.856v9.531a.823.823 0 0 0 1.214.725l5.285-2.847a6.176 6.176 0 0 0 3.247-5.437V9.524a.823.823 0 0 0-1.231-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M14.08 14.382v8.125l4.678-2.52a4.94 4.94 0 0 0 2.598-4.35v-5.404l-7.276 4.149Zm7.28-5.573-8.515 4.856v9.531a.823.823 0 0 0 1.214.725l5.285-2.847a6.176 6.176 0 0 0 3.247-5.437V9.524a.823.823 0 0 0-1.231-.715Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M14.08 14.382v8.125l4.678-2.52a4.94 4.94 0 0 0 2.598-4.35v-5.404l-7.276 4.149Zm7.28-5.573-8.515 4.856v9.531a.823.823 0 0 0 1.214.725l5.285-2.847a6.176 6.176 0 0 0 3.247-5.437V9.524a.823.823 0 0 0-1.231-.715Z"
      clipRule="evenodd"
    />
  </svg>
)

