import React, { useState } from 'react'
import { CommentAnnotationLG } from 'src/components/svg-icons/3d-renderer/comment-annotation'
import { Box, Button, Text } from 'src/components/utility'
import type { AnnotatedComment } from '../3d-renderer/annotation.types'
import CommentCard from './comment-card'
import { useAnnotationCommentsStore } from 'src/store/hooks/useAnnotationCommentsStore'

import CommentsEmptyState from '../comments-v2/comments-empty-state'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'

function AnnotationComments({
    comments,
    team,
    refetch,
}: {
    comments: Array<AnnotatedComment>
    team: any
    refetch: () => void
}) {
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false)
    const commentsCount = comments.length
    const parentComments = comments.filter((comment) => !comment.parent_id)
    const commentBoxRef = useClickedOutside(() => setIsCommentsPanelOpen(false))

    return (
        <Box
            position="absolute"
            top="24px"
            borderRadius="4px"
            gridRowGap="24px"
            px="4px"
            py="12px"
            left="24px"
            bg="white"
            ref={commentBoxRef}
            border="solid"
            borderColor="gray250"
            borderWidth={1}
        >
            {commentsCount > 0 && (
                <Box
                    bg="primary"
                    width="18px"
                    height="18px"
                    borderRadius="50%"
                    position="absolute"
                    left="10px"
                    top="-10px"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text fontSize="10px" my="0px">
                        {comments.length}
                    </Text>
                </Box>
            )}
            <Button
                border="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="white"
                onClick={() => setIsCommentsPanelOpen(!isCommentsPanelOpen)}
            >
                <CommentAnnotationLG
                    stroke={isCommentsPanelOpen ? '#fff' : '#778CA2'}
                    fill={isCommentsPanelOpen ? '#022143' : '#fff'}
                />
            </Button>
            {isCommentsPanelOpen && (
                <Box
                    position="absolute"
                    top="0"
                    left="60px"
                    width="405px"
                    height="535px"
                    bg="white"
                    border="1px solid #B8B8B8"
                    p="8px"
                    borderRadius="4px"
                    display="grid"
                    gridTemplateRows="1fr auto"
                    overflowY="auto"
                    zIndex={15}
                >
                    <Box display="flex" flexDirection="column" overflowY="auto">
                        {parentComments?.length > 0 ? (
                            parentComments.map((comment) => {
                                const commentReplies = comments.filter(
                                    (_comment) =>
                                        _comment.parent_id === comment.id
                                )
                                return (
                                    <AnnotationCommentContainer
                                        comment={comment}
                                        commentReplies={commentReplies}
                                        key={comment.id}
                                        refetch={refetch}
                                    />
                                )
                            })
                        ) : (
                            <CommentsEmptyState />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

function AnnotationCommentContainer({
    comment,
    commentReplies,
    refetch,
}: {
    comment: AnnotatedComment
    commentReplies: AnnotatedComment[]
    refetch: () => void
}) {
    const [isReplyPanelOpen, setIsReplyPanelOpen] = useState(false)
    const {
        setActiveAnnotationPoint,
        setIsTemporaryAnnotation,
        setActiveCameraPosition,
    } = useAnnotationCommentsStore()

    return (
        <Box
            display="flex"
            width="100%"
            flexDirection="column"
            gridGap="4px"
            onClick={() => {
                if (comment.coordinates !== null) {
                    setIsTemporaryAnnotation(false)
                    setActiveAnnotationPoint(comment.coordinates)
                    setActiveCameraPosition(comment.metadata.camera_position)
                }
            }}
        >
            <CommentCard
                key={comment.id}
                parentComment={comment}
                repliesCount={commentReplies.length}
                isRepliesVisible={isReplyPanelOpen}
                setIsRepliesVisible={setIsReplyPanelOpen}
                refetch={refetch}
            />
            {isReplyPanelOpen && commentReplies.length > 0 && (
                <Box
                    pl="16px"
                    display="flex"
                    flexDirection="column"
                    gridGap="4px"
                    borderLeft="2px solid #B8B8B8"
                    ml="20px"
                    mb="4px"
                >
                    {commentReplies.map((comment) => {
                        return (
                            <CommentCard
                                parentComment={comment}
                                refetch={refetch}
                            />
                        )
                    })}
                </Box>
            )}
        </Box>
    )
}

export default AnnotationComments
