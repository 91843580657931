import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import type { SetStateAction, SyntheticEvent } from 'react'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { InputLabel, Modal, Tag } from 'src/components'
import { Box, Button, Input, Text } from 'src/components/utility'
import { debounce } from 'src/helpers'
import { CreateTeam, GetAllTeams, GetTeams } from 'src/services'
import { GetOrgMemberByEmail } from 'src/services/graphql/query/get-team-by-id'

export const CreateTeamModal = ({
    isModal,
    setIsModal,
    refetchTeam,
    onComplete,
}: {
    isModal: boolean
    setIsModal: React.Dispatch<SetStateAction<boolean>>
    refetchTeam?: (x: boolean) => void
    onComplete?: (data: Team) => void
}) => {
    const textAreaRef = useRef<HTMLInputElement | null>(null)
    const [text, setText] = useState('')
    const [teamName, setTeamName] = useState('')
    const [tagList, setTagList] = useState<any[]>([])
    const [createTeamMutation] = useMutation(CreateTeam)
    const [GetTeamsByName, teamsByName] = useLazyQuery(GetAllTeams)

    const [isLoading, setIsLoading] = useState(false)
    const teams = useQuery(GetTeams)
    const [timeOutRef, setTimeOutRef] =
        useState<ReturnType<typeof setTimeout>>()

    const [findSearchUser] = useLazyQuery(GetOrgMemberByEmail)
    const [listOpen, setListOpen] = useState<boolean>(false)
    const [members, setMembers] = useState<TeamMember[]>([])

    function fetchOrgMembers(searchStr: string) {
        setListOpen(true)
        if (!searchStr) {
            setMembers([])
            setListOpen(false)
            return
        }
        findSearchUser({
            variables: {
                searchStr: `%${searchStr}%`,
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data: any) => {
                const users = data?.organisation_members?.map((member: any) => {
                    return {
                        ...member.user,
                        role: member.role,
                    }
                })
                setMembers(users)
            },
        })
    }
    const debounceFn = debounce(
        (searchStr: string) => {
            fetchOrgMembers(searchStr)
        },
        400,
        setTimeOutRef,
        timeOutRef
    )

    function removeTag(item: any) {
        setTagList((tags) => tags.filter((tag) => item.email !== tag.email))
    }

    function addTags(newTag: any) {
        const findUser = tagList.find((tags) => tags.email === newTag.email)
        if (findUser) {
            toast('User already added.', {
                className: 'custom-toaster toaster-success',
            })
            return
        }
        setTagList((tags) => [...tags, newTag])
    }

    async function createTeam() {
        if (teamName.length <= 0)
            return toast(`Please enter a valid team name`, {
                className: 'custom-toaster toaster-error',
            })
        if (tagList.length <= 0)
            return toast(`No collaborators were added`, {
                className: 'custom-toaster toaster-error',
            })

        const { data } = await GetTeamsByName({
            variables: {
                name: teamName.toLowerCase(),
            },
        })
        if (data.teams.length > 0)
            return toast(`${teamName} team already exists`, {
                className: 'custom-toaster toaster-error',
            })

        if (teamName.length > 0 && tagList.length > 0) {
            setIsLoading(true)
            createTeamMutation({
                variables: {
                    name: teamName,
                    team_members: {
                        data: tagList.map((tag) => ({ user_id: tag.id })),
                    },
                },
                onCompleted: async (data: {
                    insert_teams_one: Team | null
                }) => {
                    // const fetchedTeam = await teams.refetch()

                    onComplete &&
                        data.insert_teams_one &&
                        onComplete(data.insert_teams_one)
                    // Redo Commit
                    refetchTeam && refetchTeam(true)
                    toast('Team created successfully', {
                        className: 'custom-toaster toaster-success',
                    })
                    setTagList([])
                    setTeamName('')
                    setIsModal(false)
                    setIsLoading(false)
                },
                onError: (e: Error) => {
                    toast('An error occurred while creating team', {
                        className: 'custom-toaster toaster-error',
                    })
                    setIsLoading(false)
                },
            })
        }
    }
    return (
        <>
            <Modal
                isModal={isModal}
                title="Create Team"
                primaryButtonTitle="Create"
                secondaryButtonTitle="Cancel"
                disablePrimaryButton={isLoading}
                isLoading={isLoading}
                onClose={() => {
                    setText('')
                    setTeamName('')
                    setTagList([])
                    setIsModal(false)
                }}
                onClick={() => createTeam()}
            >
                <Box
                    px="24px"
                    pt="24px"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gridRowGap="24px"
                >
                    <InputLabel
                        isError={false}
                        errorText=""
                        label="Name your team"
                        placeholder="Enter team name"
                        onChange={(e) => setTeamName(e.target.value)}
                        value={teamName}
                        isDisabled={false}
                    ></InputLabel>
                    <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Text
                            mx="0px"
                            fontFamily="Rubik"
                            fontSize="14px"
                            color="primary"
                            my="0px"
                        >
                            Add Teammates
                        </Text>
                        <Box
                            borderRadius="4px"
                            border="solid"
                            borderWidth={1}
                            borderColor="gray350"
                            width="auto"
                            bg="white"
                            fontSize="14px"
                            minHeight="174px"
                            mt="12px"
                            mb="8px"
                            className="placeholder-darkgray"
                            fontFamily="Rubik"
                            disabled={false}
                            position="relative"
                        >
                            <Input
                                autoComplete="false"
                                placeholder={'Enter Names / Email addresses'}
                                ref={textAreaRef}
                                border="solid"
                                borderWidth={1}
                                borderColor="gray350"
                                width="100%"
                                bg="white"
                                px="16px"
                                py="16px"
                                fontSize="14px"
                                mb="8px"
                                borderTop="none"
                                borderRight="none"
                                borderLeft="none"
                                className="placeholder-darkgray"
                                value={text}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setText(e.target.value)
                                    debounceFn(e.target.value)
                                }}
                            ></Input>

                            {text && listOpen && (
                                <Box
                                    minWidth={`240px`}
                                    boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
                                    display="flex"
                                    flexDirection="column"
                                    position="absolute"
                                    py="16px"
                                    zIndex={10}
                                    overflowX="hidden"
                                    bg="white"
                                    left={'2px'}
                                    top="52px"
                                    maxHeight={'260px'}
                                    overflowY={'scroll'}
                                >
                                    {members.map((item: any, key: number) => {
                                        return (
                                            <Button
                                                bg="transparent"
                                                fontFamily="Rubik"
                                                border="none"
                                                color="secondaryLighterBlue"
                                                height="32px"
                                                minHeight="32px"
                                                fontSize="14px"
                                                textAlign="left"
                                                px="24px"
                                                type="button"
                                                onClick={(
                                                    e: SyntheticEvent<HTMLButtonElement>
                                                ) => {
                                                    setListOpen(false)
                                                    addTags(item)
                                                    setText('')
                                                }}
                                                id="predective-output"
                                                key={key}
                                            >
                                                {item?.email}
                                            </Button>
                                        )
                                    })}
                                    {members.length <= 0 && (
                                        <Button
                                            bg="transparent"
                                            fontFamily="Rubik"
                                            border="none"
                                            color="secondaryLighterBlue"
                                            height="32px"
                                            fontSize="14px"
                                            textAlign="left"
                                            px="24px"
                                            type="button"
                                        >
                                            No results found
                                        </Button>
                                    )}
                                </Box>
                            )}
                            <Box
                                px="4px"
                                display="grid"
                                overflow="hidden"
                                gridRowGap="4px"
                                gridTemplateColumns="max-content max-content"
                                width="100%"
                                flexWrap="wrap"
                                gridColumnGap={'8px'}
                            >
                                {tagList.map((tag, key) => {
                                    return (
                                        <Tag
                                            key={key}
                                            element={tag.email}
                                            color="#F5F6A9"
                                            action={true}
                                            onClick={() => removeTag(tag)}
                                        ></Tag>
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
