import { gql } from "@apollo/client";

export const AddNewTagWithRelation = gql`
mutation addNewTagWithRelation($objects: [tag_relationships_insert_input!] =object) {
  insert_tag_relationships(objects: $objects) {
    returning {
      id
      color
      tag {
        tag
      }
    }
  }
}`