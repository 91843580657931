import type { SVGProps } from 'react'

export const StrokedArrow = ({
    color,
    onClick,
    ...props
}: {
    color?: string
    size?: string
    onClick?: () => void
} & SVGProps<SVGSVGElement>) => (
    <svg
        width={7}
        height={12}
        onClick={onClick}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="pointer"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.895 10.905a.582.582 0 0 1-.834-.811l3.982-4.093L1.06 1.907a.582.582 0 1 1 .834-.811l4.093 4.207a1 1 0 0 1 0 1.395l-4.093 4.207Z"
            fill={!color ? '#778CA2' : color}
        />
    </svg>
)
