import axios from 'axios'
import { initializeApp } from '@firebase/app'
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from '@firebase/messaging'
import { debugLog, getLocalStorage } from 'src/helpers'
import { SUBSCRIBE_TO_NOTIFICATION } from 'src/services/api/endpoints'
import { CONFIG_VARS } from './constants.config'

export const firebase = initializeApp(CONFIG_VARS.firebase)
export const messaging = getMessaging(firebase)

isSupported().then((res) => {
    console.log({ isMessaging: res })
})

export const getTokens = async (
    setTokenFound: any,
    setProgress: (progress: number) => void
) => {
    //VAPID_KEY is the Web push certificates key pair
    try {
        const token = await getToken(messaging, {
            vapidKey: CONFIG_VARS.firebase.vapidKey,
        })
        if (token) {
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
            setTokenFound(true)
            await axios.post(
                SUBSCRIBE_TO_NOTIFICATION,
                {
                    fcm_token: token,
                },
                {
                    headers: {
                        'x-auth-token': getLocalStorage('authToken'),
                    },
                    onUploadProgress: (progressEvent) => {
                        setProgress(
                            (progressEvent.loaded / progressEvent.total) * 100
                        )
                    },
                }
            )
            // debugLog(token)
            return token
        } else {
            debugLog(
                'No registration token available. Request permission to generate one.'
            )
            setTokenFound(false)
        }
    } catch (err) {
        debugLog('An error occurred while retrieving token. ', err)
        // catch error while creating client token
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            // debugLog("payload", payload)
            resolve(payload)
        })
    })
