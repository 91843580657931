import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import type { ChangeEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    DragDropArea,
    FileDisplay,
    Modal,
    TagAutoCommplete,
} from 'src/components'
import { ColoredButton } from 'src/components/buttons/colored-button'
import {
    AddFileIco,
    BrandIco,
    CompanyIco,
    FolderOutlineGrey,
    PaintIco,
    PlusIco,
    TagsIco,
} from 'src/components/svg-icons'
import { Box, Button, Input, Span, Text } from 'src/components/utility'
import {
    debugLog,
    getFileRequestId,
    getImageUrl,
    getLocalStorage,
    useUser,
} from 'src/helpers'
import { getLibraryFileUrl } from 'src/helpers/get-image-url'
import {
    AddTags,
    DeleteExistingCreateNewTagRelation,
    FetchExistingsTagsByNames,
    FetchFolderById,
    GetTodoById,
    UpdateLibraryFile,
} from 'src/services'
import {
    ASSIGN_FILE_TO_LIBRARY,
    INSERT_FILE_TO_FOLDER,
} from 'src/services/api/endpoints'
import {
    FetchAllNestedFoldersByCategory,
    FetchCategoriesBySearchStr,
    FetchFileById,
    FetchFilesByCategoryId,
    FetchLibraryCategoryById,
} from 'src/services/graphql/query/library.queries'
import { libraryDefaultKeys } from 'src/screens/library/library.constants'
import { FolderSelectOrAddModal } from './sub-screens/modals/library-folder-select'
import { createRedirectionUrl } from 'src/helpers/create-redirection-url'
import ColorPicker from 'src/components/color-picker/color-picker'
import { TagComponent } from '../products/sub-screens/accordian'

function DragDropModal({
    title = 'Upload Files',
    primaryButtonText = 'Upload',
    onClick,
    isLoading,
    isModal,
    setModal,
    setLoading,
}: {
    onClick: (files: any) => void
    isLoading: boolean
    isModal: boolean
    primaryButtonText?: string
    title?: string
    setModal: (x: boolean) => void
    setLoading?: (bool: boolean) => void
}) {
    const [fileModalData, setFileModalData] = useState<{
        subTodoId: string | null
        files: {
            name: string
            path: string
            blob: Blob
            id: string
            progress?: number
            isUploaded?: boolean
        }[]
    }>({
        subTodoId: null,
        files: [],
    })
    return (
        <Modal
            onClose={() => {
                setModal(false)
                setLoading && setLoading(false)
            }}
            isModal={isModal}
            onClick={() => {
                onClick(fileModalData)
            }}
            primaryButtonTitle={primaryButtonText}
            secondaryButtonTitle="Cancel"
            title={title}
            isLoading={isLoading}
            disablePrimaryButton={isLoading || !fileModalData.files.length}
        >
            <Box
                p="24px"
                width="100%"
                height="330px"
                overflowY={fileModalData.files.length > 8 ? 'auto' : 'hidden'}
            >
                <DragDropArea
                    onFilesAdded={(files) => {
                        // setFileModalData((fileModal) => {})
                    }}
                    height="100%"
                    fileModal={fileModalData}
                    setFileModal={setFileModalData}
                />
            </Box>
        </Modal>
    )
}

const AddFilesToLibrary = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [fileModal, setFileModal] = useState<{
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }>({
        isFileModal: false,
        subTodoId: null,
        files: [],
    })
    const [tags, setTags] = useState<
        {
            id: string
            name: string
            color: string
        }[]
    >([])

    const [form, setForm] = useState<{
        company: string
        brand: string
        color: Array<{ name: string; color: string }>
    }>({
        company: '',
        brand: '',
        color: [],
    })

    const [uploadedFilesModal, setUploadedFilesModal] = useState(false)
    const [openModal, setModal] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [openCreateFolderModal, openCreateFolder] = useState(false)

    const [searchParams] = useSearchParams()
    const [fetchFolder, folderDetails] = useLazyQuery(FetchFolderById)
    const [fetchCategory, categoryData] = useLazyQuery(FetchLibraryCategoryById)
    const [fetchTagsByNames] = useLazyQuery(FetchExistingsTagsByNames)
    const [insertNewTags] = useMutation(AddTags)
    const [setNewTagRelations] = useMutation(DeleteExistingCreateNewTagRelation)
    const [updateLibraryFileContent] = useMutation(UpdateLibraryFile)

    const [editFileDetails, setEditFileDetails] = useState<any>()
    const [categorySearchStr, setSearchCategory] = useState<string>('')
    const [categorySelected, setCategory] = useState<any>()
    const [listOpen, setListOpen] = useState<boolean>(false)
    const [searchFolderInput, setSearchFolderInput] = useState<string>('')
    const [folderSelected, setFolder] = useState<any>()

    const [fetchCategoryByStr, searchCategoryData] = useLazyQuery(
        FetchCategoriesBySearchStr
    )
    const [getTodoById, todoById] = useLazyQuery(GetTodoById)
    const [fetchFoldersList, foldersListData] = useLazyQuery(
        FetchAllNestedFoldersByCategory
    )
    const [fetchFile, fileDetails] = useLazyQuery(FetchFileById)
    const [fetchAllFiles, allFilesResponse] = useLazyQuery(
        FetchFilesByCategoryId
    )

    const folderId = searchParams.get(libraryDefaultKeys.folderId)
    const categoryId = params?.categoryId
    const editFileId = params?.editFileId
    const todoId = params?.todoId

    const [uploadedFiles, setUploadedFiles] = useState<
        {
            name: string
            path: string
            blob: Blob
            id: string
            progress: any
        }[]
    >([])

    const recieveData = (files: {
        isFileModal: boolean
        subTodoId: string | null
        files: { name: string; path: string; blob: Blob; id: string }[]
    }) => {
        const new_files = files.files
        const existing_files = fileModal.files
        const samefiles: any = {}
        let filesAlreadyExists = false

        new_files.forEach((element) => {
            samefiles[element.name] = element
            let find = existing_files.findIndex(
                (item) => item.name === element.name
            )
            if (find !== -1) {
                filesAlreadyExists = true
            }
        })

        existing_files.forEach((element) => {
            samefiles[element.name] = element
        })

        if (filesAlreadyExists) {
            const _cnfMsg = `One or more files already exists in this location. Do you want to replace the existing files with a new version?`
            const _confirmed: boolean = confirm(_cnfMsg)
            if (!_confirmed) {
                return
            }
        }

        let _vals: {
            name: string
            path: string
            blob: Blob
            id: string
        }[] = Object.values(samefiles)

        const finalFiles = filesAlreadyExists
            ? _vals
            : [...existing_files, ...new_files]
        setModal(false)
        setFileModal((fileModal) => ({
            ...fileModal,
            isFileModal: false,
            files: finalFiles,
        }))
    }

    const categoryResults = searchCategoryData?.data?.category
    const { organization_id } = useUser()
    const todo = (todoById?.data?.todos && todoById?.data?.todos[0]) || []

    const [todoFiles, setTodoFiles] = useState<any>([])
    useEffect(() => {
        if (todo?.sub_todos?.length) {
            todo?.sub_todos?.map((stodo: any) => {
                if (stodo.files && stodo.files.length) {
                    setTodoFiles([...todoFiles, ...stodo.files])
                }
            })
        }

        if (todo?.files?.length) {
            setTodoFiles([...todoFiles, ...todo.files])
        }
    }, [todo])

    useEffect(() => {
        if (todoId && todoId !== '0') {
            getTodoById({
                variables: {
                    id: todoId,
                    organisation_id: organization_id,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }, [todoId])

    useEffect(() => {
        if (folderId && folderId !== '0') {
            fetchFolder({
                variables: {
                    id: folderId,
                },
            })
        }
        if (categoryId && categoryId !== '0' && categoryId !== 'all') {
            fetchCategory({
                variables: {
                    id: categoryId,
                },
            })
        }
    }, [folderId, categoryId])

    const folder = folderDetails?.data?.folders[0]
        ? folderDetails.data.folders[0]
        : null
    const catergory = categoryData?.data?.category[0]
        ? categoryData?.data?.category[0]
        : null

    const updateFileData = () => {
        setUploading(true)
        updateLibraryFileContent({
            variables: {
                id: editFileId,
                color: form.color,
                brand: form.brand,
                company: form.company,
                tags: returnTagsString(),
            },
            onCompleted: (res: any) => {
                setUploading(false)
                if (res?.update_library_files?.affected_rows) {
                    toast.success('File updated successfully!', {
                        className: 'custom-toaster toaster-success',
                    })
                    if (editFileId) {
                        handleFileTags([editFileId])
                    }
                } else {
                    toast.error('Failed to update the file!', {
                        className: 'custom-toaster toaster-error',
                    })
                }
            },
            onError: () => {
                setUploading(false)
                toast.error('Failed to update the file!', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }

    const properNavigation = () => {
        let libraryPath = `/library`
        if (editFileId) {
            const _folderId = editFileDetails?.folder_id
            const _categoryId = editFileDetails?.category_id
            if (_folderId) libraryPath += `/folder/${_folderId}`
            if (_categoryId)
                libraryPath += `?${libraryDefaultKeys.categoryId}=${_categoryId}&${libraryDefaultKeys.tab}=tab_${_categoryId}`
            navigate(libraryPath)
            return
        }

        if (todoId) {
            navigate('/todo/' + todoId)
            return
        }

        if (categoryId && folderId) {
            libraryPath += `/folder/${folderId}?${libraryDefaultKeys.categoryId}=${categoryId}&${libraryDefaultKeys.tab}=tab_${categoryId}`
        } else if (categoryId) {
            libraryPath += `?${libraryDefaultKeys.categoryId}=${categoryId}&${libraryDefaultKeys.tab}=tab_${categoryId}`
        }

        navigate(libraryPath)
    }

    const handleFileTags = (filesUploadedData: any[] = []) => {
        const fileTagNames = tags.map((item: any) => item.name)

        fetchTagsByNames({
            variables: {
                tags: fileTagNames,
            },
            fetchPolicy: 'network-only',
            onCompleted: (data: any) => {
                const finalTags: any[] = []
                const existingTags: any = {}
                const nonExstingTags: any = []

                const tagColors: any = {}
                for (const tagObj of tags) {
                    tagColors[tagObj.name] = tagObj.color
                }

                for (const iterator of data.tags) {
                    existingTags[iterator.tag] = iterator.id
                    finalTags.push({
                        id: iterator.id,
                        color: tagColors[iterator.tag],
                        name: iterator.tag,
                    })
                }

                for (const iterator of fileTagNames) {
                    if (!existingTags[iterator]) {
                        nonExstingTags.push({
                            tag: iterator,
                        })
                    }
                }

                if (nonExstingTags && nonExstingTags.length) {
                    insertNewTags({
                        variables: {
                            objects: nonExstingTags,
                        },
                        onCompleted: (_data: any) => {
                            if (_data.insert_tags.affected_rows) {
                                const insertedTags = _data.insert_tags.returning
                                for (const iterator of insertedTags) {
                                    finalTags.push({
                                        id: iterator.id,
                                        color: tagColors[iterator.tag],
                                        name: iterator.tag,
                                    })
                                }
                                handleFileTagsRelation(
                                    filesUploadedData,
                                    finalTags
                                )
                            }
                        },
                    })
                } else {
                    handleFileTagsRelation(filesUploadedData, finalTags)
                }
            },
            onError: () => {
                handleFileTags()
            },
        })
    }

    const handleFileTagsRelation = (
        filesUploadedData: any = [],
        finalTags: any[] = tags
    ) => {
        const entityIds = filesUploadedData
        const tagRelations = []
        for (const iterator of entityIds) {
            for (const t of finalTags) {
                tagRelations.push({
                    tag_id: t.id,
                    entity_id: iterator,
                    entity_name: 'library_files',
                    color: t.color,
                })
            }
        }

        setNewTagRelations({
            variables: {
                entity_ids: entityIds,
                entity_name: 'library_files',
                objects: tagRelations,
            },
        })

        properNavigation()
    }

    const addingTodoFilesToLibrary = async () => {
        try {
            const payload: any = {
                file_ids: [],
                folder_id: null,
                category_id: null,
                brand: '',
                color: [],
                tags: [],
                company: '',
                entity_name: 'todos',
                entity_id: todoId,
            }

            let formData: FormData = new FormData()

            formData.append('entity_name', 'todos')

            if (todoId) {
                formData.append('entity_id', todoId?.toString())
            }

            let _fileIds = todoFiles
                .filter((item: any) => !item.moved_to_lib && !item.from_library)
                .map((item: any) => item.id)
            if (todoFiles && todoFiles.length) {
                payload.file_ids = _fileIds
                formData.append('file_ids', JSON.stringify(_fileIds))
            }

            if (categorySelected && categorySelected.id) {
                payload.category_id = categorySelected.id
                formData.append('category_id', categorySelected.id.toString())
            }

            if (folderSelected && folderSelected.id) {
                payload.folder_id = folderSelected.id
                formData.append('folder_id', folderSelected?.id?.toString())
            }

            if (form.company) {
                payload.company = form.company
                formData.append('company', form.company)
            }

            if (form.brand) {
                payload.brand = form.brand
                formData.append('brand', form.brand)
            }

            if (form.color) {
                payload.color = form.color
                formData.append('color', JSON.stringify(form.color))
            }

            if (tags.length) {
                payload.tags = tags
                formData.append('tags', returnTagsString())
            }

            await axios.post(ASSIGN_FILE_TO_LIBRARY, formData, {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                },
            })

            const _msg = `File${
                _fileIds.length > 1 ? 's' : ''
            } added successfully!`
            toast.success(_msg, { className: 'custom-toaster toaster-success' })
            handleFileTags(payload.file_ids)
        } catch (error: any) {
            console.log({ error })
            if (
                error?.response &&
                error.response?.data &&
                !error.response.data.success
            ) {
                toast.error(
                    error?.response?.data?.message ||
                        error?.response?.data?.error?.message ||
                        'Something went wrong.',
                    { className: 'custom-toaster toaster-error' }
                )
            } else {
                toast.error('Something went wrong.', {
                    className: 'custom-toaster toaster-error',
                })
            }
        }
    }

    const addFilesToLibraryFromOther = () => {
        if (!todoFiles || !todoFiles.length) {
            toast.error('Files not found!', {
                className: 'custom-toaster toaster-error',
            })
            return
        }

        let _fileIds = todoFiles.filter(
            (item: any) => !item.moved_to_lib && !item.from_library
        )
        let msg: string = 'Files already exist in the library.'
        if (!_fileIds || !_fileIds.length) {
            if (todoFiles.length === 1) {
                msg = 'File already exist in the library.'
            }
            toast.error(msg, { className: 'custom-toaster toaster-error' })
            return
        }

        validateFilesBeforeUpload(_fileIds, true)
    }

    const returnTagsString = () => {
        const _tags = tags.map((t: any) => {
            return {
                id: t.id,
                tag: t.name,
                name: t.name,
                color: t.color,
            }
        })
        return JSON.stringify(_tags)
    }

    const onFileUploadProgress = (progress: any, path: string) => {
        setUploadedFiles((files) =>
            files.map((file) =>
                file.path === path
                    ? {
                          ...file,
                          progress: (progress.loaded / progress.total) * 100,
                      }
                    : file
            )
        )
    }

    const uploadFiles = async (
        uploadProgress: (progress: any) => void,
        payload: any,
        header?: any
    ) => {
        try {
            const responseData = await axios.request({
                url: INSERT_FILE_TO_FOLDER,
                method: 'post',
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                    ...header,
                },
                data: payload,
                onUploadProgress: (progress: any) => {
                    uploadProgress && uploadProgress(progress)
                },
            })

            const formattedData: any = responseData.data
            return formattedData
        } catch (e) {
            debugLog(e)
        }
    }

    const validateFilesBeforeUpload = (
        payloadFiles: any[],
        todoFilesUpload: boolean
    ) => {
        // const payloadFiles = fileModal.files;

        const fileNames: any[] = payloadFiles.map((f) => f.name)

        const fileWhereObj: any = {
            name: { _in: fileNames },
            deleted_at: { _is_null: true },
            file_type: { _eq: 'document' },
            category_id: {
                _eq: categoryId,
            },
        }

        if (!todoFilesUpload) {
            if (categoryId && categoryId !== '0') {
                fileWhereObj.category_id = {
                    _eq: categoryId,
                }
            }

            if (folderId && folderId !== '0') {
                fileWhereObj.folder_id = {
                    _eq: folderId,
                }
            }
        } else {
            if (categorySelected && categorySelected.id) {
                fileWhereObj.category_id = {
                    _eq: categorySelected.id,
                }
            }

            if (folderSelected && folderSelected.id) {
                fileWhereObj.folder_id = {
                    _eq: folderSelected.id,
                }
            }
        }

        fetchAllFiles({
            variables: {
                name: null,
                created_at: 'desc',
                limit: 200,
                whereObj: fileWhereObj,
            },
            fetchPolicy: 'cache-and-network',
            onCompleted: (_filesData: any) => {
                // console.log({ _filesData });
                const existingFileNames = _filesData?.library_files?.map(
                    (f: any) => f?.name
                )
                if (_filesData?.library_files_aggregate?.aggregate?.count > 0) {
                    let _cnfMsg = `One or more files already exists in this category or folder.`
                    if (
                        _filesData?.library_files_aggregate?.aggregate
                            ?.count === 1
                    ) {
                        _cnfMsg = `${existingFileNames[0]} file already exists in this category or folder.`
                    }
                    toast.error(_cnfMsg, {
                        className: 'custom-toaster toaster-error',
                    })
                } else {
                    if (!todoFilesUpload) {
                        uploadAfterValidate()
                    } else {
                        addingTodoFilesToLibrary()
                    }
                }
            },
            onError: () => {
                if (!todoFilesUpload) {
                    uploadAfterValidate()
                } else {
                    addingTodoFilesToLibrary()
                }
            },
        })
    }

    const uploadAfterValidate = async () => {
        try {
            setUploading(true)
            const payloadFiles = fileModal.files

            const fileUpload: any[] = []
            const files: {
                name: string
                path: string
                blob: Blob
                id: string
                progress: any
            }[] = []

            const { request_id } = await getFileRequestId({
                action: 'INSERT',
                entity_id: folderId ? `${folderId}` : `${categoryId}`,
                entity_name: folderId ? 'folders' : 'category',
                number_of_files: payloadFiles.length,
            })

            for (const pf of payloadFiles) {
                let data: FormData = new FormData()
                data.append('toupload', pf.blob)
                data.append('is_public', 'false')

                if (folderId) {
                    data.append('folder_id', folderId?.toString())
                }
                if (categoryId) {
                    data.append('category_id', categoryId?.toString())
                }

                if (folderId) {
                    data.append('entity_name', 'folders')
                    data.append('entity_id', `${folderId}`)
                } else {
                    data.append('entity_name', 'category')
                    data.append('entity_id', `${categoryId}`)
                }

                if (form.company) {
                    data.append('company', form.company)
                }

                if (form.brand) {
                    data.append('brand', form.brand)
                }

                data.append('request_id', request_id)

                if (form.color) {
                    data.append('color', JSON.stringify(form.color))
                }

                if (tags.length) {
                    data.append('tags', returnTagsString())
                }

                if (pf.blob) {
                    files.push({ ...pf, progress: 0 })
                    fileUpload.push(
                        uploadFiles(
                            (progress) =>
                                onFileUploadProgress(progress, pf.path),
                            data,
                            {
                                'request-id': request_id,
                            }
                        )
                    )
                }
            }

            setUploadedFilesModal(true)
            setUploadedFiles(files)
            await Promise.all(fileUpload)
            setUploadedFilesModal(false)

            toast.success('Files added successfully!', {
                className: 'custom-toaster toaster-success',
            })
            properNavigation()
        } catch (error: any) {
            debugLog({ error })
            if (!error.response.data.success) {
                toast.error(
                    error.response.data.message ||
                        error.response.data.error.message,
                    { className: 'custom-toaster toaster-danger toaster-error' }
                )
            } else {
                toast.error('Something went wrong.', {
                    className: 'custom-toaster toaster-danger toaster-error',
                })
            }
        }

        setUploading(false)
    }

    const upload = () => {
        if (editFileId) {
            updateFileData()
            return
        }

        if (todoId) {
            addFilesToLibraryFromOther()
            return
        }

        if (isButtonDisabled()) {
            toast.error('Invalid form.', {
                className: 'custom-toaster toaster-danger toaster-error',
            })
            return
        }

        validateFilesBeforeUpload(fileModal.files, false)
    }

    function handleChange(text: string, action: string, oldColor?: string) {
        switch (action) {
            case 'COMPANY':
                setForm((form) => ({ ...form, company: text }))
                break
            case 'BRAND':
                setForm((form) => ({ ...form, brand: text }))
                break
            case 'ADD_COLOR':
                setForm((form) => ({
                    ...form,
                    color: [...form.color, { name: '', color: text }],
                }))
                break
            case 'REMOVE_COLOR':
                setForm((form) => ({
                    ...form,
                    color: form.color.filter((clr) => clr.color !== text),
                }))
                break
            case 'UPDATE_COLOR':
                setForm((form) => ({
                    ...form,
                    color: form.color.map((clr) =>
                        clr.color === oldColor ? { name: '', color: text } : clr
                    ),
                }))
                break
            default:
                break
        }
    }

    const isButtonDisabled = () => {
        return (
            !tags.length ||
            uploading ||
            !form.company ||
            !form.brand ||
            !form.color ||
            (!fileModal.files.length && !editFileId && !todoId)
        )
    }

    const getCategories = (searchStr: string, limit = 100) => {
        fetchCategoryByStr({
            variables: {
                searchStr: searchStr ? `%${searchStr}%` : `%%`,
                limit: !limit ? 100 : limit,
            },
            fetchPolicy: 'no-cache',
            onCompleted: () => {
                setListOpen(true)
            },
        })
    }

    function updateValue(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchCategory(e.target.value)
        getCategories(e.target.value)
        setCategory(null)
        setSearchFolderInput('')
    }

    useEffect(() => {
        if (categorySelected?.id) {
            getFoldersData('')
        }
    }, [categorySelected])

    useEffect(() => {
        if (editFileId && editFileId !== '0') {
            fetchFile({
                variables: {
                    id: editFileId,
                },
                fetchPolicy: 'no-cache',
                onCompleted: (data: any) => {
                    if (data) {
                        const obj = data.library_files[0]
                        if (obj && obj.id) {
                            setEditFileDetails(obj)
                            setForm({
                                color:
                                    typeof obj.color === 'string'
                                        ? JSON.parse(obj.color)
                                        : obj.color,
                                brand: obj.brand,
                                company: obj.company,
                            })
                            let tags = []
                            if (obj && obj.tags && obj.tags.length) {
                                if (typeof obj.tags === 'string') {
                                    tags = JSON.parse(obj.tags)
                                }
                                if (typeof obj.tags === 'object') {
                                    tags = obj.tags
                                }
                            }
                            setTags(tags)
                        }
                    }
                },
            })
        }
    }, [editFileId])

    const getFoldersData = (searchStr: string) => {
        fetchFoldersList({
            variables: {
                category_id: categorySelected?.id,
                searchStr: searchStr ? `%${searchStr}%` : `%%`,
                parent_id: false,
            },
            fetchPolicy: 'no-cache',
        })
    }

    function updateFolderStringValue(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFolderInput(e.target.value)
        getFoldersData(e.target.value)
    }

    const showPath = (comment: string) => {
        let parsedComment: any[] = []
        let pattern: string[] = []
        let flag = false
        comment?.split('').forEach((letter) => {
            if (letter === '<') {
                flag = true
            }
            if (flag && !letter.includes('>')) {
                pattern.push(letter)
            } else if (flag && letter.includes('>')) {
                flag = false
                const raw = pattern.join('').replace('<@', '').replace('>', '')
                parsedComment.push(raw.split('&')[1])
                pattern = []
            } else {
                parsedComment.push(letter)
            }
        })

        return <>{parsedComment}</>
    }

    const dropdownBadge = useRef<any>(null)
    const dropdownBadgeFolder = useRef<any>(null)

    function disableDropdown(event: Event) {
        if (dropdownBadge !== null && dropdownBadge.current !== null) {
            const isClickInsideElement = dropdownBadge.current.contains(
                event.target
            )
            if (!isClickInsideElement) {
                setListOpen(false)
            }
        }
        if (
            dropdownBadgeFolder !== null &&
            dropdownBadgeFolder.current !== null
        ) {
            const _isClickInsideElement = dropdownBadgeFolder.current.contains(
                event.target
            )
            if (!_isClickInsideElement) {
                // setListFoldersOpen(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    const onDelete = (id: string) => {
        setFileModal((fileModal) => ({
            ...fileModal,
            files: fileModal.files.filter((file) => file.id !== id),
        }))
    }

    return (
        <>
            <Box pt={'40px'} pb={'80px'} position="relative" minHeight="100vh">
                <Button
                    color="links"
                    fontSize="12px"
                    position="absolute"
                    top="12px"
                    mt={'0px'}
                    bg="transparent"
                    border="none"
                    onClick={() => navigate(-1)}
                    px="0px"
                    fontFamily="Rubik"
                >
                    &lt; {folder?.name || catergory?.name || 'Back'}
                </Button>

                <Text fontSize={'24px'} mt="0px" mb="16px">
                    {editFileId
                        ? 'Edit File'
                        : todoId
                        ? 'Add Files to Library'
                        : 'Add Files'}
                </Text>

                <Box
                    borderRadius="8px"
                    boxShadow="0px 2px 16px rgba(153, 155, 168, 0.12)"
                    width="100%"
                    bg="white"
                >
                    <Box
                        p="40px"
                        pb={'100px'}
                        display="flex"
                        flexDirection="column"
                        height="auto"
                        gridRowGap={'24px'}
                        gridColumnGap={'24px'}
                    >
                        <Box display="flex" gridColumnGap={'8px'}>
                            <Box>
                                <Box>
                                    <Box
                                        display="flex"
                                        gridColumnGap="12px"
                                        color="textSecondary"
                                        alignItems="center"
                                    >
                                        <AddFileIco />
                                        Assign Files
                                    </Box>
                                </Box>
                                {!editFileId && !todoId && (
                                    <Box>
                                        <Button
                                            type="button"
                                            mt="12px"
                                            onClick={() => {
                                                setModal(true)
                                            }}
                                            className="dashed__border"
                                            bg="transparent"
                                            border="none"
                                            width="72px"
                                            height="72px"
                                            ml="36px"
                                        >
                                            {' '}
                                            <PlusIco />
                                        </Button>
                                    </Box>
                                )}

                                {editFileId &&
                                    fileDetails &&
                                    fileDetails?.data?.library_files[0] && (
                                        <Box ml="36px" mt={'8px'}>
                                            <FileDisplay
                                                redirectionUrl={createRedirectionUrl(
                                                    {
                                                        path: fileDetails?.data
                                                            ?.library_files[0]
                                                            .name,
                                                        gid: fileDetails?.data
                                                            ?.library_files[0]
                                                            .gid,
                                                        ref: 'library',
                                                    }
                                                )}
                                                params={[
                                                    {
                                                        key: 'gid',
                                                        value:
                                                            fileDetails?.data
                                                                ?.library_files[0]
                                                                .gid || '0',
                                                    },
                                                ]}
                                                // redirectionUrl={(fileDetails?.data?.library_files[0].name.includes('xlsx') || fileDetails?.data?.library_files[0].name.includes('xlx')) ? `/excel-viewer?path=${fileDetails?.data?.library_files[0].path.replace('/', '')}` : PdfFileTypes.includes(fileDetails?.data?.library_files[0].name.split('.')[fileDetails?.data?.library_files[0].name.split('.').length - 1]) ? `/pdf-viewer/${fileDetails?.data?.library_files[0].path}` : '/file-preview'}
                                                name={
                                                    fileDetails?.data
                                                        ?.library_files[0].name
                                                }
                                                url={getLibraryFileUrl(
                                                    fileDetails?.data
                                                        ?.library_files[0]
                                                        ?.thumbnail || ''
                                                )}
                                                callbackUrl={`/library/addfiles/todo/${todo.id}`}
                                                fileId={
                                                    fileDetails?.data
                                                        ?.library_files[0].id
                                                }
                                                openFileInNewTab={true}
                                            />
                                        </Box>
                                    )}

                                {todoId && (
                                    <Box
                                        mt="8px"
                                        ml="36px"
                                        display="grid"
                                        gridColumnGap="12px"
                                        gridRowGap="12px"
                                        gridTemplateColumns="1fr 1fr 1fr 1fr"
                                    >
                                        {todoFiles?.map(
                                            (file: any, key: number) => {
                                                if (file.name.length <= 0)
                                                    return <></>
                                                return (
                                                    <Box
                                                        opacity={
                                                            file.moved_to_lib ||
                                                            file.from_library
                                                                ? '0.5'
                                                                : '1'
                                                        }
                                                    >
                                                        <FileDisplay
                                                            // redirectionUrl={(file.name.name.includes('xlsx') || file.name.name.includes('xlx')) ? `/excel-viewer?path=${file.name.path.replace('/', '')}` : PdfFileTypes.includes(file.name.name.split('.')[file.name.name.split('.').length - 1]) ? `/pdf-viewer/${file.name.path}` : '/file-preview'}
                                                            redirectionUrl={createRedirectionUrl(
                                                                {
                                                                    path: file.name,
                                                                    gid:
                                                                        file.gid ||
                                                                        file.path,
                                                                    ref: 'self',
                                                                }
                                                            )}
                                                            params={[
                                                                {
                                                                    key: 'gid',
                                                                    value:
                                                                        file.gid ||
                                                                        '0',
                                                                },
                                                            ]}
                                                            key={key}
                                                            name={file.name}
                                                            url={getImageUrl(
                                                                file.gid ||
                                                                    file.path
                                                            )}
                                                            callbackUrl={`/library/addfiles/todo/${todo.id}`}
                                                            fileId={file.id}
                                                            openFileInNewTab={
                                                                true
                                                            }
                                                            onDelete={() =>
                                                                setTodoFiles(
                                                                    todoFiles.filter(
                                                                        (
                                                                            todoFile: any
                                                                        ) =>
                                                                            todoFile.id !==
                                                                            file.id
                                                                    )
                                                                )
                                                            }
                                                            isDeletePermitted
                                                        />
                                                        {(file.moved_to_lib ||
                                                            file.from_library) && (
                                                            <Span
                                                                fontSize={
                                                                    '12px'
                                                                }
                                                                textAlign="center"
                                                                lineHeight="2"
                                                                display="block"
                                                                color="secondaryLighterBlue"
                                                            >
                                                                Already in
                                                                library
                                                            </Span>
                                                        )}
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </Box>
                                )}
                            </Box>

                            <Box
                                mt="32px"
                                ml="8px"
                                display="grid"
                                gridColumnGap="12px"
                                gridRowGap="12px"
                                gridTemplateColumns="1fr 1fr 1fr 1fr"
                            >
                                {fileModal?.files?.map((file, index) => {
                                    if (file.name.length <= 0) return <></>
                                    return (
                                        <FileDisplay
                                            callbackUrl={`/library/addfiles/todo/${todo.id}`}
                                            // redirectionUrl={(file.name.includes('xlsx') || file.name.includes('xlx')) ? `/excel-viewer?path=${file.name.replace('/', '')}` : PdfFileTypes.includes(file.name.split('.')[file.name.split('.').length - 1]) ? `/pdf-viewer/${file.path}` : '/file-preview'}
                                            redirectionUrl={createRedirectionUrl(
                                                {
                                                    path: file.name,
                                                    gid: file.path,
                                                    ref: 'self',
                                                }
                                            )}
                                            isDownloadable
                                            key={'key+' + index}
                                            name={file.name}
                                            url={file.path}
                                            onDelete={() => {
                                                onDelete(file.id)
                                            }}
                                            isDeletePermitted
                                            openFileInNewTab={true}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>

                        {todoId && (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gridColumnGap="12px"
                                >
                                    <Box
                                        width={'24px'}
                                        alignItems="center"
                                        textAlign="center"
                                    >
                                        <FolderOutlineGrey />
                                    </Box>

                                    <Box
                                        position="relative"
                                        ref={dropdownBadge}
                                    >
                                        <Input
                                            autoComplete="false"
                                            required
                                            bg="transparent"
                                            value={categorySearchStr}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                updateValue(e)
                                            }}
                                            onFocus={() => {
                                                getCategories('')
                                                setListOpen(true)
                                                // setListFoldersOpen(false)
                                            }}
                                            onBlur={() => {}}
                                            color="primary"
                                            border="none"
                                            fontSize="16px"
                                            placeholder="Select Category"
                                            className="placeholder-light"
                                        ></Input>

                                        {listOpen && (
                                            <Box
                                                minWidth={`240px`}
                                                boxShadow="0px 2px 12px rgba(153, 155, 168, 0.25)"
                                                display="flex"
                                                flexDirection="column"
                                                position="absolute"
                                                py="16px"
                                                zIndex={10}
                                                overflowX="hidden"
                                                bg="white"
                                                top={'35px'}
                                                left={'0px'}
                                                maxHeight={'260px'}
                                                overflowY={'auto'}
                                            >
                                                {categoryResults?.map(
                                                    (
                                                        item: any,
                                                        key: number
                                                    ) => {
                                                        return (
                                                            <Button
                                                                bg="transparent"
                                                                fontFamily="Rubik"
                                                                border="none"
                                                                color="secondaryLighterBlue"
                                                                height="32px"
                                                                minHeight="32px"
                                                                fontSize="14px"
                                                                textAlign="left"
                                                                px="24px"
                                                                type="button"
                                                                onClick={() => {
                                                                    setListOpen(
                                                                        false
                                                                    )
                                                                    setSearchCategory(
                                                                        item.name
                                                                    )
                                                                    setCategory(
                                                                        item
                                                                    )
                                                                    setSearchFolderInput(
                                                                        ''
                                                                    )
                                                                    setFolder(
                                                                        ''
                                                                    )
                                                                }}
                                                                id="predective-output"
                                                                key={key}
                                                            >
                                                                {item?.name}
                                                            </Button>
                                                        )
                                                    }
                                                )}
                                                {categoryResults?.length <=
                                                    0 && (
                                                    <Button
                                                        bg="transparent"
                                                        fontFamily="Rubik"
                                                        border="none"
                                                        color="secondaryLighterBlue"
                                                        height="32px"
                                                        fontSize="14px"
                                                        textAlign="left"
                                                        px="24px"
                                                        type="button"
                                                    >
                                                        No results found
                                                    </Button>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {categorySelected?.id && (
                                    <Box>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gridColumnGap="12px"
                                        >
                                            <Box
                                                width={'24px'}
                                                alignItems="center"
                                                textAlign="center"
                                            >
                                                <FolderOutlineGrey />
                                            </Box>
                                            {folderSelected.id && (
                                                <Text py={'0px'} my={'0px'}>
                                                    {' '}
                                                    {folderSelected.name}{' '}
                                                </Text>
                                            )}
                                            <Button
                                                bg="transparent"
                                                border="none"
                                                fontSize="16px"
                                                color="links"
                                                py="0px"
                                                textAlign="left"
                                                onClick={() => {
                                                    openCreateFolder(true)
                                                }}
                                            >
                                                {folderSelected.id
                                                    ? 'Change '
                                                    : 'Select '}{' '}
                                                Folder
                                            </Button>
                                        </Box>
                                        {folderSelected.id && (
                                            <Span
                                                pl={'36px'}
                                                fontSize={'12px'}
                                                lineHeight="2"
                                                display="block"
                                                color="secondaryLighterBlue"
                                            >
                                                {showPath(folderSelected?.path)}
                                            </Span>
                                        )}
                                    </Box>
                                )}
                            </>
                        )}

                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                        >
                            <CompanyIco />
                            <Input
                                autoComplete="false"
                                value={form.company}
                                required
                                bg="white"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    handleChange(e.target.value, 'COMPANY')
                                }}
                                color="primary"
                                border="none"
                                fontSize="16px"
                                placeholder="Add Company"
                                className="placeholder-light"
                            ></Input>
                        </Box>

                        <Box
                            display="flex"
                            alignItems="center"
                            gridColumnGap="12px"
                        >
                            <BrandIco />
                            <Input
                                autoComplete="false"
                                value={form.brand}
                                required
                                bg="white"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    handleChange(e.target.value, 'BRAND')
                                }}
                                color="primary"
                                border="none"
                                fontSize="16px"
                                placeholder="Add Brand"
                                className="placeholder-light"
                            ></Input>
                        </Box>
                        <Box>
                            <Box
                                display="flex"
                                gridColumnGap="12px"
                                alignItems="center"
                            >
                                <PaintIco />
                                {form.color?.map((color, index) => {
                                    return (
                                        <TagComponent
                                            onUpdate={(newColor) => {
                                                handleChange(
                                                    newColor.name,
                                                    'UPDATE_COLOR',
                                                    color.color
                                                )
                                            }}
                                            onDelete={(e: MouseEvent) => {
                                                e.stopPropagation()
                                                handleChange(
                                                    color.color,
                                                    'REMOVE_COLOR'
                                                )
                                            }}
                                            name={color.color}
                                            key={index}
                                        />
                                    )
                                })}
                                <ColorPicker
                                    onAdd={(color) => {
                                        handleChange(color, 'ADD_COLOR')
                                    }}
                                    buttonIcon={
                                        <Text
                                            my="0px"
                                            color="textSecondary"
                                            fontSize={'16px'}
                                        >
                                            Add Color
                                        </Text>
                                    }
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            gridColumnGap="12px"
                            color="textSecondary"
                            alignItems="center"
                        >
                            <TagsIco />
                            <TagAutoCommplete
                                placeholder="Add Tags"
                                tags={tags}
                                setTags={setTags}
                                allowNew={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        border="solid"
                        py="24px"
                        px="40px"
                        display="flex"
                        gridColumnGap="16px"
                        alignSelf="flex-end"
                        width="100%"
                        borderWidth={1}
                        borderColor="gray250"
                        borderBottom="none"
                        borderLeft="none"
                        borderRight="none"
                    >
                        <ColoredButton
                            variant="primary"
                            type="submit"
                            onClick={() => upload()}
                            loader={uploading}
                            disabled={isButtonDisabled()}
                        >
                            Save
                        </ColoredButton>
                        <ColoredButton
                            variant="secondary"
                            type="button"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </ColoredButton>
                    </Box>
                </Box>
            </Box>

            {openCreateFolderModal && (
                <FolderSelectOrAddModal
                    isModal={openCreateFolderModal}
                    setModal={openCreateFolder}
                    refetchFolders={() => {
                        foldersListData.refetch()
                    }}
                    existingCategoryId={categorySelected.id}
                    existingCategoryName={categorySelected.name}
                    setSelectedFolder={(item: any) => {
                        setFolder(item)
                        openCreateFolder(false)
                    }}
                />
            )}

            {openModal && (
                <DragDropModal
                    isLoading={false}
                    primaryButtonText="Assign"
                    title="Assign Files"
                    isModal={openModal}
                    setModal={setModal}
                    onClick={(files: any) => recieveData(files)}
                />
            )}
            {uploadedFilesModal && (
                <Modal
                    width="600px"
                    hideClose
                    onClose={() => setUploadedFilesModal(false)}
                    isModal={uploadedFilesModal}
                    disableActions
                    onClick={() => {}}
                    primaryButtonTitle=""
                    secondaryButtonTitle=""
                    title="Uploading Files"
                >
                    <Box
                        width="600px"
                        display="flex"
                        px="24px"
                        py="24px"
                        gridColumnGap="12px"
                        gridRowGap="12px"
                        overflowY="auto"
                        height={
                            uploadedFiles.filter((file) => file.name).length > 4
                                ? '300px'
                                : 'auto'
                        }
                        flexWrap="wrap"
                    >
                        {uploadedFiles.map((file) => {
                            return (
                                file.name && (
                                    <FileDisplay
                                        redirectionUrl={createRedirectionUrl({
                                            path: file.name,
                                            gid: file.path,
                                            ref: 'self',
                                        })}
                                        openFileInNewTab={true}
                                        name={file.name}
                                        url={file.path}
                                        showLoader={true}
                                        percentage={file.progress}
                                    />
                                )
                            )
                        })}
                    </Box>
                </Modal>
            )}
        </>
    )
}
export default AddFilesToLibrary
