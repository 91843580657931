import axios from 'axios'
import { debugLog } from 'src/helpers'
import { UPLOAD_USER_IMAGE } from 'src/services'
import { DELETE_FILE, GET_FILE_REQUEST_ID } from 'src/services/api/endpoints'
import { getLocalStorage } from './localstorage'
import { toast } from 'react-toastify'

export type TUploadResponse = {
    user_id: string
    gid: string
    thumbnail: string
    folder: boolean
    name: string
    id: string
    path?: string
}

export async function uploadFiles(
    file: string | any,
    entity_id: string,
    fileUrl: string,
    entity_name: string,
    type:
        | 'attachments'
        | 'profile_pic'
        | 'cover_pic'
        | 'reference_pic'
        | 'document',
    uploadProgress?: (progress: any) => void,
    request_id?: string
) {
    try {
        if (fileUrl.length > 0) {
            let data = new FormData()
            data.append('toupload', file)
            data.append('entity_name', entity_name)
            data.append('entity_id', entity_id)
            data.append('request_id', request_id || '0')
            data.append('assigned_as', type || 'attachments')
            const responseData = await axios.request<{
                data: TUploadResponse | any
            }>({
                url: UPLOAD_USER_IMAGE,
                method: 'post',
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                    'request-id': request_id || 0,
                },
                data: data,
                onUploadProgress: (progress) => {
                    uploadProgress && uploadProgress(progress)
                },
            })
            return responseData.data.data
        }
        return null
    } catch (e) {
        debugLog(e)
        toast('File upload failed', {
            className: 'custom-toaster toaster-error',
        })
    }
}

export async function deleteFile(
    path: string | null = null,
    id: number | null = null,
    request_id?: string
) {
    try {
        let payload: any = {}
        if (path) {
            payload['path'] = path
        }
        if (id) {
            payload['id'] = id
        }
        if (!path && !id) {
            return
        }
        const data = await axios.post(
            DELETE_FILE,
            { ...payload, request_id },
            {
                headers: {
                    'x-auth-token': getLocalStorage('authToken'),
                    'request-id': request_id || 0,
                },
            }
        )
        return Promise.resolve(data)
    } catch (e) {
        return Promise.reject(e)
    }
}

type FileParams = {
    action: 'INSERT' | 'DELETE'
    entity_name: string
    entity_id?: string
    number_of_files: number
}

export async function getFileRequestId({
    action,
    entity_name,
    entity_id,
    number_of_files,
}: FileParams) {
    if (number_of_files <= 0) return { request_id: 0 }
    const { data } = await axios.post(
        GET_FILE_REQUEST_ID,
        { action, entity_name, entity_id, number_of_files },
        {
            headers: {
                'x-auth-token': getLocalStorage('authToken'),
            },
        }
    )
    return { request_id: data.data.request_id }
}
