import type { GetProductTodoImagesQuery } from 'src/generated/graphql'

export const getProductTodosWithFiles = (
    todos:
        | NonNullable<
              NonNullable<GetProductTodoImagesQuery>['product_variants_by_pk']
          >['todos']
        | undefined
) => {
    if (!todos) return []

    return todos.reduce(
        (acc: Array<{ todo: string; files: Array<any> }>, todo) => {
            todo.sub_todos?.forEach((subTodo) => {
                const existingTodo = acc.find((item) => item.todo === todo.name)
                if (existingTodo) {
                    existingTodo.files = [
                        ...existingTodo.files,
                        ...subTodo.files,
                    ]
                } else {
                    acc.push({
                        todo: todo.name,
                        files: [...subTodo.files],
                    })
                }
                subTodo?.sub_todo_sections?.forEach((subTodoSection) => {
                    acc = acc.map((item) => {
                        if (item.todo === todo.name) {
                            return {
                                ...item,
                                files: [
                                    ...item.files,
                                    subTodoSection.files,
                                ].flat(),
                            }
                        }
                        return item
                    })
                })
            })

            return acc
        },
        []
    )
}

export function getFilesToRender(
    productTodosWithFiles: Array<{ todo: string; files: Array<any> }>,
    selectedTodoTab: string,
    searchValue: string,
    allowedFileTypes: Array<string>
) {
    return productTodosWithFiles
        .reduce((acc: any, item: any) => {
            if (selectedTodoTab === 'All') return [...acc, ...item.files]
            return item.todo === selectedTodoTab ? [...acc, ...item.files] : acc
        }, [])
        .filter((file: any) => {
            const fileData = file.library_file
                ? {
                      ...file.library_file,
                      from_library: true,
                  }
                : file.file
                ? {
                      ...file.file,
                      from_library: false,
                  }
                : null
            if (!fileData) return false
            if (
                !fileData.name ||
                !fileData.name.toLowerCase().includes(searchValue.toLowerCase())
            )
                return false
            const type = fileData.name
                ?.split('.')
                [fileData.name.split('.').length - 1].toLowerCase()
            return allowedFileTypes.includes(type)
        })
}
