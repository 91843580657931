import { useState } from 'react'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import { DangerModal } from '../modals/danger-modal'
import { toast } from 'react-toastify'
import { DeleteCollectionByID, GetCollections } from 'src/services'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

export function CollectionMenuDropdown({
    active,
    setIsDropdownActive,
    collectionName,
    collectionId,
    isDetailedPage = false,
}: {
    active: boolean
    setIsDropdownActive: (value: boolean) => void
    collectionName: string
    collectionId: number
    isDrawerTab?: boolean
    isDetailedPage?: boolean
}) {
    const [showDeleteCollectionModal, setShowDeleteCollectionModal] =
        useState(false)

    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['collection-menu-button']
    )
    const navigate = useNavigate()
    const [DeleteCollectionByIDMutation] = useMutation(DeleteCollectionByID)

    function deleteCollection() {
        DeleteCollectionByIDMutation({
            variables: {
                id: collectionId,
                deleted_at: new Date().toISOString(),
            },
            update: (cache) => {
                const collectionsData: Collections[] | any = cache.readQuery({
                    query: GetCollections,
                })
                if (collectionsData) {
                    cache.writeQuery({
                        query: GetCollections,
                        data: {
                            collections: collectionsData.collections.filter(
                                (collectionData: Collections) =>
                                    Number(collectionData.id) !==
                                    Number(collectionId)
                            ),
                        },
                    })
                }
            },
            refetchQueries: [GetCollections],
            onCompleted: () => {
                toast.success('Collection deleted successfully', {
                    className: 'custom-toaster toaster-success',
                })
                if (isDetailedPage) {
                    navigate('/collections')
                }
            },
            onError: () => {
                toast.error('Failed to delete collection', {
                    className: 'custom-toaster toaster-error',
                })
            },
        })
    }

    return (
        <Dropdown
            active={active}
            top="32px"
            left="none"
            right="0px"
            width="182px"
        >
            <Box ref={wrapperRef} width="100%">
                <DropdownButtonWrapper value="delete-collection" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="danger"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowDeleteCollectionModal(true)
                        }}
                    >
                        Delete Collection
                    </Button>
                </DropdownButtonWrapper>
                {showDeleteCollectionModal && (
                    <DangerModal
                        onRemove={deleteCollection}
                        onClose={() => setShowDeleteCollectionModal(false)}
                        isModal={showDeleteCollectionModal}
                        heading={`Are you sure you want to delete collection "${collectionName}"`}
                        subHeading={
                            'Deleting a collection also removes all the products of this collection.'
                        }
                        title={'Delete Collection'}
                        primaryButtonText="Yes, Delete"
                        secondaryButtonText="Cancel"
                    />
                )}
            </Box>
        </Dropdown>
    )
}
