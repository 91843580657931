import type { SetStateAction } from 'react'
import { Box, Text, Input } from 'src/components/utility'
import { onChangeHandler } from './functions'
import { InlineError } from 'src/components'

type ErrorStates = {
    email: boolean
    password: boolean
    name: boolean
}

type UserInput = {
    email: string
    password: string
    name: string
}

export const Field = ({
    label,
    errorStates,
    setUserInputs,
    setErrorStates,
    type,
    placeholder,
    disabled = false,
    value,
}: {
    errorStates: ErrorStates
    setUserInputs: React.Dispatch<React.SetStateAction<UserInput>>
    setErrorStates: React.Dispatch<SetStateAction<ErrorStates>>
    type: 'email' | 'name' | 'password'
    label: string
    placeholder: string
    disabled?: boolean
    value?: string
}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            // alignItems="center"
            gridRowGap="12px"
            fontSize="14px"
        >
            <Text
                mt="0px"
                mb="0px"
                fontFamily="Rubik"
                color={errorStates[type] ? 'delay' : 'primary'}
                textAlign="left"
                width="100%"
            >
                {label}
            </Text>
            <Input
                autoComplete="false"
                value={value}
                placeholder={placeholder}
                bg="white"
                borderRadius="4px"
                px="16px"
                py="16px"
                minWidth="360px"
                fontSize="14px"
                border="solid"
                borderWidth={1}
                borderColor={errorStates[type] ? 'delay' : 'gray350'}
                className="placeholder-darkgray"
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeHandler(
                        setUserInputs,
                        setErrorStates,
                        type,
                        e.target.value
                    )
                }}
            />
            {type === 'email' && errorStates[type] ? (
                <InlineError
                    width="auto"
                    text="Please enter a valid email address"
                />
            ) : type === 'name' && errorStates[type] ? (
                <InlineError width="auto" text="Please enter a name" />
            ) : (
                ''
            )}
        </Box>
    )
}
