import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IconDropdownButton } from 'src/components/buttons/icon-dropdown-button'
import { Box } from 'src/components/utility'
import { DropDownActions } from './dropdown-actions'

const sorts: Record<string, string> = {
    created_at: 'Date Created',
    name: 'Name A-Z',
    due_date: 'Due Date',
}

export const SortDropdown = () => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const activeSort = searchParams.get('sort') || 'created_at'
    const [dropdownButtonText, setDropdownButtonText] = useState<string>(
        sorts[activeSort]
    )
    const dropdownButton = useRef<any>(null)
    function disableDropdown(event: Event) {
        if (dropdownButton !== null && dropdownButton.current !== null) {
            const isClickInsideElement = dropdownButton.current.contains(
                event.target
            )
            if (!isClickInsideElement) {
                setIsDropDownActive(false)
            }
        }
    }

    function onDropDownClick(value: string) {
        setDropdownButtonText(value)
    }

    useEffect(() => {
        document.addEventListener('click', disableDropdown)
        return () => document.removeEventListener('click', disableDropdown)
    }, [])

    const setActiveSort = (sort: 'due_date' | 'created_at' | 'name') => {
        searchParams.set('sort', sort)
        searchParams.set('page', String(1))
        setSearchParams(searchParams, { replace: true })
    }

    return (
        <Box
            id="sort-dropdown"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap="29px"
            position="relative"
        >
            <Box
                ref={dropdownButton}
                onClick={() => setIsDropDownActive(!isDropDownActive)}
            >
                <IconDropdownButton>
                    Sort by: {dropdownButtonText}
                </IconDropdownButton>
            </Box>

            {isDropDownActive && (
                <DropDownActions
                    setActiveSort={setActiveSort}
                    activeSort={activeSort}
                    setIsDropdownActive={setIsDropDownActive}
                    onDropDownClick={onDropDownClick}
                    active={isDropDownActive}
                />
            )}
        </Box>
    )
}
