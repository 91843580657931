import { gql } from '@apollo/client'

export const GetCollectionProducts = gql`
    query GetCollectionProducts(
        $collectionId: bigint!
        $order_by: [products_order_by!] = { id: desc }
        $due_date: timestamptz_comparison_exp = {}
        $assignee_ids: bigint_comparison_exp = {}
    ) {
        products(
            where: { collection_id: { _eq: $collectionId } }
            order_by: $order_by
        ) {
            id
            name
            product_variants(
                where: { due_date: $due_date, approver_id: $assignee_ids }
                order_by: { id: desc }
            ) {
                id
                is_primary
                name
                is_delayed
                due_date
                status
                other_data
                collection {
                    id
                    name
                    manager_id
                }
                product_variant_approver {
                    id
                    name
                    email
                    other_data
                }
                totalTodos: todos_aggregate {
                    aggregate {
                        count
                    }
                }
                completedTodos: todos_aggregate(
                    where: { status: { _eq: complete } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`
