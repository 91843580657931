import React from 'react'
import SubtodoCard from 'src/components-v2/todo/subtodo-card/subtodo-card'
import { Modal } from 'src/components/modals/modal'
import { Box, Text } from 'src/components/utility'

function SaveTemplateModal({
    onClick,
    isTemplateCreating,
    onClose,
    isOpen,
    subtodos = [],
}: {
    onClick: () => void
    isTemplateCreating: boolean
    onClose: () => void
    isOpen: boolean
    subtodos?: Array<any>
}) {
    return (
        <Modal
            onClose={onClose}
            isModal={isOpen}
            onClick={onClick}
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            title="Save as Template"
            isLoading={isTemplateCreating}
            disablePrimaryButton={isTemplateCreating}
            bg="white"
        >
            <Box
                width="85vw"
                height="75vh"
                overflow="auto"
                display="grid"
                gridTemplateRows="auto 1fr auto"
            >
                <Box>
                    <Text color="primary" fontSize="24px" pl="16px" pr="48px">
                        The template will be saved in library for future use.
                    </Text>
                </Box>
                <Box
                    style={{ borderRadius: '12px 12px 0px 0px' }}
                    bg="#F8FAFB"
                    py="12px"
                    px="40px"
                    borderTop="1px solid #A7B9D1"
                    display="grid"
                    gridTemplateColumns={'1fr 1fr'}
                    gridGap="16px"
                    height="100%"
                    overflow="auto"
                >
                    {subtodos.map((subtodo) => (
                        <SubtodoCard
                            subtodo={subtodo}
                            refetchTodo={() => {}}
                            isTechPack={true}
                            onFocus={() => {}}
                            isReadOnly
                            key={subtodo.id}
                            todoId={0}
                        />
                    ))}
                </Box>
            </Box>
        </Modal>
    )
}

export default SaveTemplateModal
