import { useNavigate } from 'react-router-dom'
import { ProgressBar, ProgressIcons, StackedImage } from 'src/components'
import { convertIntoElipses } from 'src/components/list-item/list-item'
import { Box, Span, Text } from 'src/components/utility'
import { getImageUrl } from 'src/helpers'
import type { TeamObject } from '../../@types/dashboard'

export const TeamCard = ({
    team,
    role,
    itemNumber,
}: {
    team: TeamObject
    role: string
    itemNumber: number
}) => {
    const navigate = useNavigate()

    let teamList = team?.team_members?.map((_member: any) => {
        return {
            name: _member?.user?.name,
            src:
                _member?.user?.files && _member?.user?.files[0]
                    ? getImageUrl(_member?.user?.files[0]?.path)
                    : _member?.user?.other_data &&
                      _member?.user?.other_data?.profile_pic
                    ? getImageUrl(_member?.user?.other_data?.profile_pic)
                    : '',
        }
    })

    const goToTeam = () => {
        navigate(`/team/${team.id}`)
    }

    return (
        <>
            <Box
                pb={role === 'designer' ? '0px' : '16px'}
                pt={
                    role === 'designer'
                        ? '0px'
                        : itemNumber === 0
                        ? '0px'
                        : '16px'
                }
            >
                <Box
                    display="flex"
                    mb={'16px'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        fontSize={'16px'}
                        id={`team_card_${team.name}`}
                        className="pointer"
                        onClick={() => {
                            goToTeam()
                        }}
                        my="0px"
                    >
                        {window
                            ? window.innerWidth > 1420
                                ? convertIntoElipses(team?.name, 30)
                                : convertIntoElipses(team?.name, 20)
                            : convertIntoElipses(team?.name, 20)}
                    </Text>
                    <Box pr={'12px'}>
                        <StackedImage
                            size={'24px'}
                            spacing={12}
                            width="max-content"
                            fontSize="12px"
                            list={teamList}
                        />
                    </Box>
                </Box>

                <Box display="flex" mb={role !== 'admin' ? '16px' : '0px'}>
                    <Box minWidth="45%" width="45%">
                        <Span fontSize={'12px'} color="textSecondary">
                            On-Time Completion
                        </Span>
                    </Box>
                    <Box width="100%">
                        <ProgressBar
                            barWidth="100%"
                            percentage={
                                (team.productCompletionRate &&
                                    Number(
                                        team.productCompletionRate.toFixed(0)
                                    )) ||
                                0
                            }
                        />
                    </Box>
                </Box>

                {role !== 'admin' ? (
                    <>
                        <Box display="flex" mb={'16px'}>
                            <Box minWidth="45%" width="45%">
                                <Span fontSize={'12px'} color="textSecondary">
                                    Collections:
                                </Span>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <ProgressIcons
                                    completed={team?.collections?.complete || 0}
                                    remaining={team?.collections?.delayed || 0}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" mb={'16px'}>
                            <Box minWidth="45%" width="45%">
                                <Span fontSize={'12px'} color="textSecondary">
                                    Products:
                                </Span>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <ProgressIcons
                                    completed={team.products?.complete || 0}
                                    remaining={team.products?.delayed || 0}
                                />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box minWidth="45%" width="45%">
                                <Span fontSize={'12px'} color="textSecondary">
                                    Tasks:
                                </Span>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <ProgressIcons
                                    completed={team?.todos?.complete || 0}
                                    remaining={team?.todos?.delayed || 0}
                                />
                            </Box>
                        </Box>
                    </>
                ) : null}
            </Box>
        </>
    )
}
