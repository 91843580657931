export const HoursWorkedIco = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 0C5.22135 0 2.76823 1.40885 1.33333 3.5625V2C1.33333 1.63181 1.03486 1.33333 0.666667 1.33333C0.298477 1.33333 0 1.63181 0 2V5C0 5.55229 0.447715 6 1 6H4C4.36819 6 4.66667 5.70152 4.66667 5.33333C4.66667 4.96514 4.36819 4.66667 4 4.66667H2.25C3.40104 2.67969 5.53125 1.33333 8 1.33333C11.6901 1.33333 14.6667 4.3099 14.6667 8C14.6667 11.6901 11.6901 14.6667 8 14.6667C4.53463 14.6667 1.69854 12.0416 1.36594 8.66575C1.32984 8.29934 1.03486 8 0.666667 8C0.298477 8 -0.0029052 8.29952 0.0274922 8.66645C0.36721 12.7673 3.81294 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58854 12.4115 0 8 0ZM8 2.66667C7.63181 2.66667 7.33333 2.96514 7.33333 3.33333V7.66667C7.33333 8.21895 7.78105 8.66667 8.33333 8.66667H11.3333C11.7015 8.66667 12 8.36819 12 8C12 7.63181 11.7015 7.33333 11.3333 7.33333H8.66667V3.33333C8.66667 2.96514 8.36819 2.66667 8 2.66667Z"
            fill="#022143"
        />
    </svg>
)
