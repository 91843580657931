import React from 'react'
import { ProfileImg } from 'src/components/profile-img/profile-img'
import { Box, Text } from 'src/components/utility'

function ProfileImgWithName({
    imageSrc,
    name,
    textColor = 'primary',
}: {
    imageSrc: string
    name: string
    textColor?: string
}) {
    return (
        <Box
            display="flex"
            gridGap="8px"
            alignItems="center"
            overflow={'hidden'}
        >
            <ProfileImg size="20px" src={imageSrc} name={name} />{' '}
            <Text
                my="0px"
                fontFamily="Rubik"
                fontSize="14px"
                color={textColor}
                className="text-ellipsis"
                title={name}
            >
                {name}
            </Text>
        </Box>
    )
}

export default ProfileImgWithName
