export const LogoIcoSm = () => (
    <svg width={118} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M43.614 24.98 38.426 8h3.301l4.245 13.961L50.288 8h3.302L48.4 24.98h-4.787ZM58.431 24.98V8h3.208v16.98H58.43ZM68.004 24.98V8h7.169c.165 0 .385.008.66.024.283.007.535.031.755.07 1.014.158 1.843.492 2.488 1.003a4.513 4.513 0 0 1 1.438 1.933c.307.77.46 1.632.46 2.583 0 1.423-.353 2.641-1.061 3.655-.707 1.006-1.816 1.627-3.325 1.863l-1.415.094H71.21v5.755h-3.207Zm9.574 0-3.348-6.91 3.301-.637 3.68 7.547h-3.633Zm-6.367-8.75h3.82c.165 0 .346-.008.543-.023.196-.016.377-.047.542-.095.433-.117.767-.314 1.002-.59.236-.282.398-.593.484-.93.094-.347.141-.673.141-.98 0-.306-.047-.628-.141-.966a2.253 2.253 0 0 0-.484-.932c-.235-.283-.57-.483-1.002-.601a2.786 2.786 0 0 0-.542-.094 6.85 6.85 0 0 0-.543-.024h-3.82v5.235ZM90.57 24.98V10.995h-5.353V8h13.914v2.995h-5.353V24.98H90.57ZM110.766 25.333c-1.368 0-2.571-.275-3.608-.825a6.063 6.063 0 0 1-2.429-2.346c-.582-1.007-.873-2.19-.873-3.55V8.024L107.11 8v10.589c0 .558.095 1.065.283 1.52.189.457.448.85.779 1.18.338.33.727.585 1.167.766.448.173.924.26 1.427.26.519 0 .998-.09 1.438-.271.448-.181.837-.437 1.168-.767.33-.33.585-.723.766-1.179.189-.456.283-.96.283-1.51V8h3.255v10.612c0 1.36-.291 2.543-.873 3.55a6.07 6.07 0 0 1-2.429 2.346c-1.038.55-2.24.825-3.608.825Z"
            fill="#022143"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m12.833 15.688 13.17-7.555L14.49 1.728 1.647 9.068v14.627l11.186 6.269V15.688Zm14.02-7.082Zm-26.3-.81L13.942.144c.334-.191.743-.193 1.079-.007l12.633 7.029c.746.415.753 1.486.012 1.911L14.48 16.642v14.259c0 .838-.902 1.367-1.634.957L.56 24.974A1.098 1.098 0 0 1 0 24.016V8.75c0-.394.211-.758.553-.954Z"
            fill="#022143"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.012 11.483a.823.823 0 0 1 1.13-.282l6.543 3.926a.823.823 0 1 1-.847 1.412l-6.544-3.926a.823.823 0 0 1-.282-1.13ZM18.227 18.511v10.833l6.238-3.36a6.587 6.587 0 0 0 3.464-5.8v-7.206l-9.702 5.533Zm9.707-7.431L16.58 17.554v12.71c0 .83.887 1.36 1.619.966l7.047-3.796a8.234 8.234 0 0 0 4.33-7.25v-8.15c0-.843-.91-1.372-1.642-.954Z"
            fill="#022143"
        />
    </svg>
)
