import { useEffect, useState } from 'react'
import { Box } from 'src/components/utility'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'
import './zoomer.css'
import { uuid } from 'src/helpers'

export const ImageZoomer = ({
    url,
    height = 'calc(100vh - 150px)',
    enableFullScreenMode = true,
    showToolbar = true,
    setZoomFunctions,
}: {
    url: string
    height?: string
    enableFullScreenMode?: boolean
    showToolbar?: boolean
    setZoomFunctions?: React.Dispatch<
        React.SetStateAction<{
            zoomIn: () => void
            zoomOut: () => void
        }>
    >
}) => {
    const [eleViewer, setElementViewer] = useState<Viewer>()
    const [loading, setLoading] = useState<boolean>(true)
    const previewId = uuid()

    //  Zoom in and Zoom out functions
    useEffect(() => {
        if (eleViewer && setZoomFunctions) {
            setZoomFunctions({
                zoomIn: () => {
                    eleViewer.zoom(0.1)
                },
                zoomOut: () => {
                    eleViewer.zoom(-0.1)
                },
            })
        }
    }, [eleViewer])

    const showPreview = () => {
        const _ele: any = document.getElementById(`imagePreviewer_${previewId}`)
        if (_ele) {
            if (eleViewer) {
                eleViewer.destroy()
            }
            const viewer: Viewer = new Viewer(_ele, {
                inline: true,
                fullscreen: enableFullScreenMode,
                button: enableFullScreenMode,
                className: 'image-zoomer-container',
                backdrop: false,
                loop: false,
                navbar: false,
                rotatable: true,
                scalable: false,
                title: false,
                loading: true,
                transition: true,
                toolbar:
                    showToolbar === false
                        ? false
                        : {
                              zoomIn: {
                                  show: 1,
                                  size: 'large',
                              },
                              zoomOut: {
                                  show: 1,
                                  size: 'large',
                              },
                              oneToOne: 0,
                              reset: {
                                  show: 1,
                                  size: 'large',
                              },
                              prev: 0,
                              play: {
                                  show: 0,
                                  size: 'large',
                              },
                              next: 0,
                              rotateLeft: {
                                  show: 1,
                                  size: 'large',
                              },
                              rotateRight: {
                                  show: 1,
                                  size: 'large',
                              },
                              flipHorizontal: 0,
                              flipVertical: 0,
                          },
            })
            setElementViewer(viewer)
            viewer.show()
            setLoading(false)
        }
    }

    return (
        <Box
            style={{ width: '100%', height: height }}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            {loading && (
                <Box
                    width={'100%'}
                    display="flex"
                    // bg={bg}
                    borderRadius="4px"
                    alignItems="center"
                    justifyContent="center"
                    height={'100%'}
                >
                    <Box className="model-loader"></Box>
                </Box>
            )}
            <img
                id={`imagePreviewer_${previewId}`}
                width={'100%'}
                height="100%"
                onLoad={showPreview}
                hidden
                src={url}
                alt="Picture"
                onError={(e) => {
                    setLoading(false)
                }}
            />
        </Box>
    )
}
