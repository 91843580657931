import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DivisionLine } from 'src/components'
import { NoTeamsIcon } from 'src/components/svg-icons/dashboard/no-teams'
import { RightArrow } from 'src/components/svg-icons/dashboard/right-arrow'
import { Box, Button, Text } from 'src/components/utility'
import { getLocalStorage, roles, useUser } from 'src/helpers'
import { PerPage } from 'src/helpers/enums'
import { CreateTeamModal } from 'src/screens/people/sub-screens/modals/create-team-modal'
import { GET_MANAGER_TEAMS } from 'src/services'
import { TeamCard } from './team-card'
import type { TeamObject } from '../../@types/dashboard'

export const YourTeamsList = () => {
    const { role } = useUser()
    const navigate = useNavigate()
    const [teams, setTeam] = useState<TeamObject[]>([])
    const [createTeamModal, setCreateTeamModal] = useState<boolean>(false)
    const boxHeight = '600px'
    const [totalTeams, setTotalTeamCount] = useState<number>(0)

    const retunFourItems = (data: TeamObject[]): TeamObject[] => {
        let slicedArray: TeamObject[] = data
        if (role && role === roles.designer) {
            slicedArray = data.slice(0, 4)
        }
        return slicedArray?.sort((a: TeamObject, b: TeamObject) => b.id - a.id)
    }

    const fetchTeams = async () => {
        const queryParams: any = {
            limit: role && role === roles.designer ? 4 : PerPage,
            offset: 1,
        }

        const { data } = await axios.get(GET_MANAGER_TEAMS, {
            headers: {
                'x-auth-token': getLocalStorage('authToken'),
            },
            params: queryParams,
        })

        if (data.success) {
            if (data.data && data.data?.length) {
                setTeam(retunFourItems(data.data))
            } else {
                setTeam([])
            }
            setTotalTeamCount(data.count)
        }
    }

    useEffect(() => {
        if (role) {
            fetchTeams()
            if (role === roles.designer) {
                if (teams && teams.length) {
                    setTeam(retunFourItems(teams))
                }
            }
        }
    }, [role])

    return (
        <>
            {createTeamModal && (
                <CreateTeamModal
                    setIsModal={setCreateTeamModal}
                    isModal={createTeamModal}
                    refetchTeam={(x) => x && fetchTeams()}
                />
            )}

            <Box
                display="flex"
                width="100%"
                mb={'12px'}
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    className="pointer"
                    id="people_page"
                    onClick={() => {
                        navigate('/organizations?tab=teams&sort=LTO&q=')
                    }}
                    alignItems="center"
                >
                    <Text fontSize="16px" my={'4px'} mr="12px">
                        Your Teams ({totalTeams || 0})
                    </Text>
                    <RightArrow />
                </Box>
                {role !== roles.designer && (
                    <Button
                        height="20px"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        color="links"
                        position="relative"
                        onClick={() => setCreateTeamModal(true)}
                    >
                        + Create
                    </Button>
                )}
            </Box>

            {role !== roles.designer && teams.length ? (
                <Box
                    p={'24px'}
                    bg={'white'}
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    height={boxHeight}
                    overflowY={'scroll'}
                    className="scrollbar_none"
                >
                    {teams?.map((item: TeamObject, index: number) => {
                        return (
                            <Box key={'team' + index + '' + item.id}>
                                <TeamCard
                                    role={role}
                                    team={item}
                                    itemNumber={index}
                                />
                                {teams.length - 1 !== index ? (
                                    <DivisionLine />
                                ) : null}
                            </Box>
                        )
                    })}
                </Box>
            ) : null}

            {!teams.length ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={boxHeight}
                    bg={'white'}
                    border={'solid'}
                    borderColor="gray250"
                    borderWidth={1}
                    borderRadius={'4px'}
                    width="100%"
                    textAlign="center"
                >
                    <Box>
                        <NoTeamsIcon />
                        <Text fontSize="14px" color="textSecondaryDarkBlue">
                            {' '}
                            {role !== roles.designer
                                ? 'No team created?'
                                : 'No teams'}{' '}
                        </Text>
                    </Box>
                </Box>
            ) : null}

            {role === roles.designer ? (
                <Box
                    display={'flex'}
                    flexDirection="column"
                    gridColumnGap={'12px'}
                    gridRowGap={'12px'}
                    overflowY="auto"
                    height="600px"
                    className="scrollbar_none"
                >
                    {teams?.map((item: TeamObject, index: number) => {
                        return (
                            <Box
                                p={'24px'}
                                width="100%"
                                key={'team2' + index + '' + item.id}
                                bg={'white'}
                                border={'solid'}
                                borderColor="gray250"
                                borderWidth={1}
                                borderRadius={'4px'}
                            >
                                <TeamCard
                                    role={role}
                                    team={item}
                                    itemNumber={index}
                                />
                            </Box>
                        )
                    })}
                </Box>
            ) : null}
        </>
    )
}
