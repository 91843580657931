import { SVGProps } from 'react'
import { Box } from 'src/components/utility'

export const ArrowUp = ({ id }: { id?: string }) => (
    <Box my="0px" height="10px" id={id}>
        <svg
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24671 5.2461C8.09076 5.40202 7.87926 5.4896 7.65873 5.4896C7.4382 5.4896 7.22671 5.40202 7.07075 5.2461L4.33209 2.50745L1.59344 5.2461C1.51672 5.32554 1.42495 5.38889 1.32348 5.43248C1.22202 5.47607 1.11289 5.49901 1.00246 5.49997C0.892033 5.50093 0.782521 5.47989 0.680313 5.43807C0.578105 5.39625 0.485249 5.3345 0.407162 5.25641C0.329076 5.17833 0.267322 5.08547 0.225506 4.98326C0.183689 4.88105 0.162646 4.77154 0.163606 4.66111C0.164565 4.55069 0.187508 4.44156 0.231094 4.34009C0.274681 4.23862 0.338039 4.14685 0.417471 4.07014L3.74411 0.743499C3.90007 0.587587 4.11157 0.5 4.33209 0.5C4.55262 0.5 4.76412 0.587587 4.92008 0.743499L8.24671 4.07014C8.40263 4.2261 8.49021 4.43759 8.49021 4.65812C8.49021 4.87865 8.40263 5.09015 8.24671 5.2461Z"
                fill="#9CA3AF"
            />
        </svg>
    </Box>
)

export const ArrowDown = ({
    id,
    ...props
}: { id?: string } & SVGProps<SVGSVGElement>) => (
    <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id={id}
        {...props}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.407073 0.753896C0.563032 0.597984 0.77453 0.510397 0.995056 0.510397C1.21558 0.510397 1.42708 0.597984 1.58304 0.753896L4.32169 3.49255L7.06035 0.753896C7.13707 0.674464 7.22884 0.611106 7.3303 0.56752C7.43177 0.523933 7.5409 0.500991 7.65133 0.500031C7.76175 0.499072 7.87127 0.520114 7.97347 0.561931C8.07568 0.603747 8.16854 0.665501 8.24662 0.743587C8.32471 0.821674 8.38647 0.914531 8.42828 1.01674C8.4701 1.11895 8.49114 1.22846 8.49018 1.33889C8.48922 1.44931 8.46628 1.55844 8.42269 1.65991C8.37911 1.76138 8.31575 1.85314 8.23632 1.92986L4.90968 5.2565C4.75372 5.41241 4.54222 5.5 4.32169 5.5C4.10117 5.5 3.88967 5.41241 3.73371 5.2565L0.407073 1.92986C0.251161 1.7739 0.163574 1.56241 0.163574 1.34188C0.163574 1.12135 0.251161 0.909855 0.407073 0.753896Z"
            fill="#9CA3AF"
        />
    </svg>
)

export const UpDownArrow = () => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.40366 11.2539C6.55961 11.098 6.77111 11.0104 6.99164 11.0104C7.21216 11.0104 7.42366 11.098 7.57962 11.2539L10.3183 13.9926L13.0569 11.2539C13.1337 11.1745 13.2254 11.1111 13.3269 11.0675C13.4284 11.0239 13.5375 11.001 13.6479 11C13.7583 10.9991 13.8678 11.0201 13.9701 11.0619C14.0723 11.1037 14.1651 11.1655 14.2432 11.2436C14.3213 11.3217 14.383 11.4145 14.4249 11.5167C14.4667 11.6189 14.4877 11.7285 14.4868 11.8389C14.4858 11.9493 14.4629 12.0584 14.4193 12.1599C14.3757 12.2614 14.3123 12.3531 14.2329 12.4299L10.9063 15.7565C10.7503 15.9124 10.5388 16 10.3183 16C10.0978 16 9.88625 15.9124 9.73029 15.7565L6.40366 12.4299C6.24774 12.2739 6.16016 12.0624 6.16016 11.8419C6.16016 11.6214 6.24774 11.4099 6.40366 11.2539Z"
                fill="#9CA3AF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.2433 8.7461C14.0873 8.90202 13.8758 8.9896 13.6553 8.9896C13.4348 8.9896 13.2233 8.90202 13.0673 8.7461L10.3287 6.00745L7.59002 8.7461C7.5133 8.82554 7.42153 8.88889 7.32007 8.93248C7.2186 8.97607 7.10947 8.99901 6.99904 8.99997C6.88862 9.00093 6.7791 8.97989 6.6769 8.93807C6.57469 8.89625 6.48183 8.8345 6.40374 8.75641C6.32566 8.67833 6.2639 8.58547 6.22209 8.48326C6.18027 8.38105 6.15923 8.27154 6.16019 8.16111C6.16115 8.05069 6.18409 7.94156 6.22768 7.84009C6.27126 7.73862 6.33462 7.64685 6.41405 7.57014L9.74069 4.2435C9.89665 4.08759 10.1081 4 10.3287 4C10.5492 4 10.7607 4.08759 10.9167 4.2435L14.2433 7.57014C14.3992 7.7261 14.4868 7.93759 14.4868 8.15812C14.4868 8.37865 14.3992 8.59015 14.2433 8.7461Z"
                fill="#9CA3AF"
            />
        </svg>
    )
}
