import * as React from 'react'

export const PlusIco = ({ color }: { color?: string }) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-dropdown-focus
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.13 11.2a.8.8 0 1 0 0 1.6h6.556v6.555a.8.8 0 1 0 1.599 0v-6.556h6.555a.8.8 0 1 0 0-1.598h-6.555V4.645a.8.8 0 1 0-1.6 0v6.556H5.132Z"
            fill={color || '#778CA2'}
        />
    </svg>
)
