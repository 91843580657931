import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { DeleteTeam } from 'src/services'

export function useRemoveTeam() {
    const [DeleteTeamMutation] = useMutation(DeleteTeam)

    const deleteTeam = async (
        teamId: number,
        onComplete: () => void,
        refetchQueries?: InternalRefetchQueriesInclude
    ) => {
        DeleteTeamMutation({
            variables: {
                id: teamId,
                deleted_at: new Date().toISOString(),
            },
            refetchQueries: ['getTeams'],
            onCompleted: () => {
                if (onComplete) onComplete()
            },
        })
    }
    return { deleteTeam }
}
