import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Box } from 'src/components/utility'
import TodoSection from '../../components-v2/todo/todo-section/todo-section'
import CompareEntityTopbar from 'src/components-v2/todo/todo-topbar/compare-entity-topbar'
import EntityCompareSection from 'src/components-v2/entity-compare-section/entity-compare-section'
import { TodoSectionContextProvider } from 'src/components-v2/todo/todo-section/todo-section.context'

function Todo() {
    const { todoId } = useParams()
    const [searchParams] = useSearchParams()
    const { compareEntity, compareId } = Object.fromEntries(
        searchParams.entries()
    )
    const [activeSectionId, setActiveSectionId] = useState<number>()

    const isSplitMode =
        !!compareId && (compareEntity === 'todo' || compareEntity === 'product')

    return (
        <Box display="grid" gridTemplateRows="auto 1fr" height="100%">
            <CompareEntityTopbar />
            <Box
                display="grid"
                gridTemplateColumns={!!compareId ? '1fr 1fr' : '1fr'}
                height={'100%'}
                overflow={'hidden'}
            >
                <TodoSectionContextProvider key={todoId}>
                    <TodoSection
                        todoId={Number(todoId)}
                        isSplitMode={isSplitMode}
                        onClick={() => setActiveSectionId(Number(todoId))}
                        activeSplitSectionId={activeSectionId}
                    />
                </TodoSectionContextProvider>
                {isSplitMode && (
                    <EntityCompareSection
                        compareEntity={compareEntity}
                        compareId={compareId}
                        key={`${compareEntity}-${compareId}`}
                        onClick={() => setActiveSectionId(Number(compareId))}
                        activeSplitSectionId={activeSectionId}
                    />
                )}
            </Box>
        </Box>
    )
}

export default Todo
