import * as React from 'react'
import { useConfiguratorContext } from '../../configurator.context'
import { Box, Button, Text } from 'src/components/utility'
import { Cross } from 'src/components/svg-icons'
import BasicFunctions from './basic-functions'

export interface IBasicPanelProps {}

export default function BasicPanel(props: IBasicPanelProps) {
    const { highlights } = useConfiguratorContext()
    return (
        <Box
            border="solid"
            borderRadius="4px"
            borderColor="gray250"
            borderWidth={1}
            py="12px"
            minWidth={'340px'}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="16px"
                borderBottom="solid"
                borderWidth={1}
                borderColor="gray100"
            >
                <Text>Selected Mesh</Text>

                <Button border="none" bg="transparent" display="flex" p="0px">
                    <Cross color="#778CA2" />
                </Button>
            </Box>
            <BasicFunctions />
        </Box>
    )
}
