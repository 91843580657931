export function ProductIco({
    color = '#778CA2',
    width = '18',
    height = '14',
}: {
    color?: string
    width?: string
    height?: string
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.657161 14C0.350911 14 0.146745 13.8542 0.0446615 13.5625C-0.0574219 13.2708 0.0154948 13.0229 0.263411 12.8187L8.09466 6.67188V5.46875C8.09466 5.29375 8.15664 5.14062 8.2806 5.00938C8.40456 4.87812 8.55404 4.8125 8.72904 4.8125C9.22487 4.82708 9.64414 4.66302 9.98685 4.32031C10.3296 3.9776 10.5009 3.55833 10.5009 3.0625C10.5009 2.58125 10.3296 2.16927 9.98685 1.82656C9.64414 1.48385 9.23216 1.3125 8.75091 1.3125C8.26966 1.3125 7.85768 1.48385 7.51497 1.82656C7.17227 2.16927 7.00091 2.58125 7.00091 3.0625H5.68841C5.68841 2.21667 5.98737 1.49479 6.58529 0.896875C7.1832 0.298958 7.90508 0 8.75091 0C9.59674 0 10.3186 0.291667 10.9165 0.875C11.5145 1.45833 11.8134 2.17292 11.8134 3.01875C11.8134 3.73333 11.5874 4.36771 11.1353 4.92188C10.6832 5.47604 10.1072 5.85521 9.40716 6.05937V6.67188L17.2384 12.8187C17.4863 13.0229 17.5592 13.2708 17.4572 13.5625C17.3551 13.8542 17.1509 14 16.8447 14H0.657161ZM2.62591 12.6875H14.8759L8.75091 7.80937L2.62591 12.6875Z"
                fill={color}
            />
        </svg>
    )
}

export const ProductIcoSm = () => {
    return (
        <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.422461 9.5C0.225586 9.5 0.0943359 9.40625 0.0287109 9.21875C-0.0369141 9.03125 0.00996095 8.87187 0.169336 8.74062L5.20371 4.78906V4.01562C5.20371 3.90312 5.24355 3.80469 5.32324 3.72031C5.40293 3.63594 5.49902 3.59375 5.61152 3.59375C5.93027 3.60312 6.1998 3.49766 6.42012 3.27734C6.64043 3.05703 6.75059 2.7875 6.75059 2.46875C6.75059 2.15937 6.64043 1.89453 6.42012 1.67422C6.1998 1.45391 5.93496 1.34375 5.62559 1.34375C5.31621 1.34375 5.05137 1.45391 4.83105 1.67422C4.61074 1.89453 4.50059 2.15937 4.50059 2.46875H3.65684C3.65684 1.925 3.84902 1.46094 4.2334 1.07656C4.61777 0.692187 5.08184 0.5 5.62559 0.5C6.16934 0.5 6.6334 0.6875 7.01777 1.0625C7.40215 1.4375 7.59434 1.89687 7.59434 2.44062C7.59434 2.9 7.44902 3.30781 7.1584 3.66406C6.86777 4.02031 6.49746 4.26406 6.04746 4.39531V4.78906L11.0818 8.74062C11.2412 8.87187 11.2881 9.03125 11.2225 9.21875C11.1568 9.40625 11.0256 9.5 10.8287 9.5H0.422461ZM1.68809 8.65625H9.56309L5.62559 5.52031L1.68809 8.65625Z"
                fill="white"
            />
        </svg>
    )
}
