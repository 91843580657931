import { useState, type Dispatch } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    Dropdown,
    DropdownButtonWrapper,
} from 'src/components/dropdown/dropdown'
import { Button, Box } from 'src/components/utility'
import { useClickedOutside } from 'src/helpers/hooks/use-clicked-outside'
import type { Todo } from 'src/services/graphql/query/@types/collections'
import { DangerModal } from '../modals/danger-modal'
import { DeleteTodoById, GetProductsForCollection } from 'src/services'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useUser } from 'src/helpers'
import { DeleteSubtodosByTodoId } from 'src/services/graphql/mutations/delete-subtodo'
import { DeleteAssignedTemplatesByTodoId } from 'src/services/graphql/entities/todo/todo-templates.mutations'
import { GetProductTodos } from 'src/services/graphql/entities/todo/todo.queries'

export function TodoMenuDropdown({
    active,
    setIsDropdownActive,
    todo,
    isDrawerTab = false,
    isFullPage = false,
    onSaveAsTemplate,
    isTemplateChosen,
    refetchTodo,
    isCompareSection,
}: {
    active: boolean
    setIsDropdownActive:
        | Dispatch<React.SetStateAction<boolean>>
        | ((value: boolean) => void)
    todo: Todo
    isDrawerTab?: boolean
    isFullPage?: boolean
    isIndividualTodoCreator?: boolean
    onSaveAsTemplate: () => void
    isTemplateChosen?: boolean
    refetchTodo?: () => void
    isCompareSection?: boolean
}) {
    const wrapperRef = useClickedOutside(
        () => setIsDropdownActive(false),
        ['todo-dropdown-button']
    )
    const navigate = useNavigate()
    const [showRemoveTodoModal, setShowRemoveTodoModal] = useState(false)
    const [showSwitchTemplateModal, setShowSwitchTemplateModal] =
        useState(false)
    const [deleteTodoByIdMutation] = useMutation(DeleteTodoById)
    const [searchParams, setSearchParams] = useSearchParams()
    const { organization_id, userId } = useUser()

    const [deleteAssignedTemplateByTodoId] = useMutation(
        DeleteAssignedTemplatesByTodoId
    )
    const [deleteSubtodosByTodoIdMutation] = useMutation(DeleteSubtodosByTodoId)

    function deleteTodo() {
        deleteTodoByIdMutation({
            variables: {
                id: todo.id,
                deleted_at: new Date().toISOString(),
            },
            onCompleted: () => {
                if (isCompareSection) {
                    searchParams.delete('compareEntity')
                    searchParams.delete('compareId')
                    setSearchParams(searchParams, { replace: true })
                } else if (isFullPage) {
                    navigate(-1)
                }
                toast(`Todo has been deleted.`, {
                    className: 'custom-toaster toaster-success',
                })
            },
            refetchQueries: [
                'GetTodos',
                'GetProducts',
                'GetTodosByUserId',
                {
                    query: GetProductsForCollection,
                    variables: {
                        id: todo?.product?.collection?.id,
                    },
                },
                {
                    query: GetProductTodos,
                    variables: {
                        id: todo.product_id,
                        organisation_id: organization_id,
                    },
                },
            ],
        })
    }

    function resetSubtodosHandler() {
        deleteSubtodosByTodoIdMutation({
            variables: {
                todoId: todo.id,
                deleted_at: new Date().toISOString(),
            },
            onCompleted: () => {
                refetchTodo && refetchTodo()
                setShowSwitchTemplateModal(false)
                setIsDropdownActive(false)
            },
        })
    }

    function switchTemplateHandler() {
        deleteAssignedTemplateByTodoId({
            variables: {
                todoId: todo.id,
                deleted_at: new Date().toISOString(),
            },
            onCompleted: () => {
                resetSubtodosHandler()
            },
        })
    }

    return (
        <Dropdown
            active={active}
            top="32px"
            left="none"
            right="0px"
            width="max-content"
        >
            <Box ref={wrapperRef} width="100%">
                {isTemplateChosen && (
                    <>
                        <DropdownButtonWrapper value="Products" width="100%">
                            <Button
                                height="100%"
                                width="100%"
                                textAlign="left"
                                fontFamily="Rubik"
                                color="primary"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onSaveAsTemplate()
                                }}
                            >
                                Save as template
                            </Button>
                        </DropdownButtonWrapper>
                        <DropdownButtonWrapper value="Products" width="100%">
                            <Button
                                height="100%"
                                width="100%"
                                textAlign="left"
                                fontFamily="Rubik"
                                color="primary"
                                bg="transparent"
                                border="none"
                                fontSize="14px"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowSwitchTemplateModal(true)
                                }}
                            >
                                Switch template
                            </Button>
                        </DropdownButtonWrapper>
                    </>
                )}
                <DropdownButtonWrapper value="Products" width="100%">
                    <Button
                        height="100%"
                        width="100%"
                        textAlign="left"
                        fontFamily="Rubik"
                        color="danger"
                        bg="transparent"
                        border="none"
                        fontSize="14px"
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowRemoveTodoModal(true)
                        }}
                    >
                        Delete Todo
                    </Button>
                </DropdownButtonWrapper>
                {showRemoveTodoModal && (
                    <DangerModal
                        heading={`Are you sure you want to remove ${todo.name}?`}
                        isModal={showRemoveTodoModal}
                        onClose={() => {
                            setShowRemoveTodoModal(false)
                        }}
                        onRemove={deleteTodo}
                        primaryButtonText="Yes, Delete"
                        secondaryButtonText="Cancel"
                        subHeading="This To-Do will no longer be accessible "
                        title="Delete To-Do"
                    />
                )}
                {showSwitchTemplateModal && (
                    <DangerModal
                        onClose={() => {
                            setShowSwitchTemplateModal(false)
                        }}
                        title="Switch Template"
                        heading="Are you sure you want to switch the template?"
                        subHeading="Switching to a new template will reset your current progress and overwrite existing content. Please note that all unsaved changes will be lost"
                        isModal={showSwitchTemplateModal}
                        onRemove={switchTemplateHandler}
                        primaryButtonText="Switch"
                        secondaryButtonText="Cancel"
                    />
                )}
            </Box>
        </Dropdown>
    )
}
