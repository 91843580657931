import { useLazyQuery, useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DivisionLine, RecentActivity } from 'src/components'
import { Slider } from 'src/components/slider/slider'
import { Box } from 'src/components/utility'
import { FetchLibraryCategories } from 'src/services'
import { Actions } from './sub-screens/actions'
import { FolderDetails } from './sub-screens/folder-details'
import { LibraryTitle } from './sub-screens/library-title'
import { SliderLinks } from './sub-screens/slider-links'
import './library.css'
import { useUser } from 'src/helpers'
import { GRID_WIDTH } from 'src/helpers/enums'
import { SubscribeToLibraryActivities } from 'src/services/graphql/subscriptions/subscriptions'
import { libraryDefaultKeys } from './library.constants'
import { ColoredButton } from 'src/components/buttons/colored-button'

const Library = ({ children }: { children?: React.ReactNode | null }) => {
    const [searchParams] = useSearchParams()
    const params = useParams()
    const navigate = useNavigate()
    const [showFolderDetails, setShowFolderDetails] = useState(false)
    const [listView, setListView] = useState(false)
    const currentListView = searchParams.get(libraryDefaultKeys.listview)
    const showActivity = searchParams.get(libraryDefaultKeys.showActivity)

    const loadData = () => {}

    useEffect(() => {
        setShowFolderDetails(false)
        if (
            searchParams.has(libraryDefaultKeys.showFolderDetails) ||
            searchParams.has(libraryDefaultKeys.showFileDetails)
        ) {
            let folderId = searchParams.get(
                libraryDefaultKeys.showFolderDetails
            )
            let fileId = searchParams.get(libraryDefaultKeys.showFileDetails)
            setShowFolderDetails(
                (folderId && folderId !== '0') || (fileId && fileId !== '0')
                    ? true
                    : false
            )
        }

        if (currentListView === '1') {
            setListView(true)
        } else {
            setListView(false)
        }
    }, [searchParams])

    /// Categories ///
    const [fetchCategories, categoriesData] = useLazyQuery(
        FetchLibraryCategories
    )

    useEffect(() => {
        fetchCategories({
            variables: {
                whereObj: {
                    deleted_at: {
                        _is_null: true,
                    },
                },
            },
        })
    }, [])

    const constantTab = {
        name: 'All',
        id: 'all',
    }

    const catergoryList = categoriesData?.data?.category
        ? [constantTab, ...categoriesData?.data?.category]
        : [constantTab]

    const { organization_id, created_at } = useUser()

    function createComparisionString({
        type,
        id,
    }: {
        type?: 'all' | 'folders' | 'category'
        id?: string
    }) {
        console.log({ type })
        switch (type) {
            case 'all':
                return {
                    entity_name: {
                        _is_null: false,
                    },
                    entity_id: {
                        _is_null: false,
                    },
                }
            default:
                return {
                    entity_name: {
                        _eq: type,
                    },
                    entity_id: {
                        _eq: id,
                    },
                }
        }
    }

    const subscribedLibraryActivities = useSubscription(
        SubscribeToLibraryActivities,
        {
            variables: {
                id: organization_id,
                user_created_at: created_at,
                ...createComparisionString({
                    type: location.pathname.includes('folder')
                        ? 'folders'
                        : searchParams.get('category_id') &&
                          searchParams.get('category_id') !== 'M1lty'
                        ? 'category'
                        : 'all',
                    id: location.pathname.includes('folder')
                        ? params.folderId
                        : searchParams.get('category_id') || undefined,
                }),
            },
        }
    )

    return (
        <>
            <Box
                pt={'40px'}
                pb="240px"
                minHeight="100vh"
                width={
                    showActivity && showActivity === '1' ? GRID_WIDTH : '100%'
                }
            >
                <Box
                    display="grid"
                    gridTemplateColumns="1fr auto"
                    gridGap="8px"
                    alignItems="center"
                    mb="16px"
                >
                    <LibraryTitle
                        fetchCategories={() => {
                            categoriesData.refetch()
                        }}
                    />
                    <ColoredButton
                        variant="primary"
                        onClick={() => {
                            navigate('/library/todo-templates')
                        }}
                        width="max-content"
                    >
                        Manage To-do templates
                    </ColoredButton>
                </Box>

                <Slider
                    element={
                        <SliderLinks
                            catergoryList={catergoryList}
                            refetch={() => {
                                categoriesData.refetch()
                            }}
                        />
                    }
                    carousel={false}
                />

                <Actions
                    setListView={setListView}
                    listView={listView}
                    refreshContent={loadData}
                />

                <DivisionLine />

                <Box display="flex">
                    <Box
                        width="100%"
                        height="max-content"
                        pr={showFolderDetails ? '24px' : '0px'}
                        py={'16px'}
                    >
                        {children}
                    </Box>

                    {showFolderDetails ? (
                        <Box width="auto">
                            <FolderDetails />
                        </Box>
                    ) : null}
                </Box>
            </Box>

            {showActivity && showActivity === '1' && (
                <RecentActivity
                    activity={
                        subscribedLibraryActivities.data?.library_activity
                    }
                />
            )}
        </>
    )
}
export default Library
